<template>
  <div class="relative">
    <spinner v-if="loading" />
    <el-table :data="items" class="border-b-0 mb-20" sortable :stripe="true">
      <el-table-column prop="id" label="№" sortable :width="62">
      </el-table-column>
      <el-table-column
        prop="date"
        sortable
        label="Дата и время"
        class="date"
        :min-width="183"
      >
        <div slot-scope="{ row }">
          {{ row.date }}
        </div>
      </el-table-column>
      <el-table-column
        prop="phone"
        label="Телефон"
        sortable
        :min-width="$mq.tablet ? 141 : 162"
      >
      </el-table-column>
      <el-table-column
        prop="duration"
        class="sender"
        sortable
        label="Продолжительность"
        :min-width="179"
      >
      </el-table-column>
      <el-table-column
        prop="status"
        label="Статус"
        sortable
        :min-width="$mq.tablet ? 150 : 173"
      >
        <template slot-scope="{ row }">
          <span class="current_status">
            <span
              class="icon_rounded"
              :class="`icon_rounded_${getStatus(row.status).type}`"
            >
              <svg-icon
                :width="getStatus(row.status).width"
                :height="getStatus(row.status).height"
                :icon-name="getStatus(row.status).type"
              ></svg-icon>
            </span>

            {{ row.status }}
          </span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      loading: false,
      items: [
        {
          id: 1,
          date: "2021-03-15 21:45:55",
          phone: "+7 (921) 342-09-33",
          duration: "04:21",
          status: "Состоялся",
        },
        {
          id: 2,
          date: "2021-03-15 21:45:55",
          phone: "+7 (921) 342-09-33",
          duration: "04:21",
          status: "Пропущенный",
        },
      ],
    };
  },
  computed: {
    ...mapState("icons", ["question_icon", "success_icon", "cancel_icon"]),
  },
  methods: {
    getStatus(status) {
      let value;

      // eslint-disable-next-line default-case
      switch (status) {
        case "Состоялся": {
          value = this.success_icon;
          break;
        }
        case "Ошибка": {
          value = this.cancel_icon;
          break;
        }
        case "Пропущенный": {
          value = this.cancel_icon;
          break;
        }
        default: {
          value = this.question_icon;
        }
      }
      return value;
    },
  },
};
</script>
