<template>
  <div class="preTables mt-30">
    <div class="preTables__column">
      <div class="preTables__title">
        {{ text }}
        <span v-if="value" class="preTables__total">{{ value }}</span>
      </div>
      <div v-if="start && end" class="preTables__sub-title">
        Показано c {{ start }} по {{ end }}
      </div>
    </div>
    <slot class="preTables__column"> </slot>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    value: Number,
    start: Number,
    end: Number,
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.preTables {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  &__column {
    display: flex;
    flex-direction: column;
  }
  &__title {
    font-size: 18px;
    font-weight: 600;
    display: flex;
    align-items: center;
  }
  &__sub-title {
    font-size: 14px;
    font-weight: 500;
    color: $--color-white-5;
  }
}
</style>
