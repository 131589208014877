<template>
  <div>
    <div class="text-22 font-semibold leading-36 mb-10">
      {{ type === "user" ? "Для клиента" : "Для сотрудников" }}
    </div>
    <p class="text-12 leading-20 text-white-500">
      {{ subtitle }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    type: String,
    subtitle: String,
  },
};
</script>
