<template>
  <div>
    <el-switch
      v-model="item.is_active"
      active-text="Указать настройки SMTP"
      class="mb-30"
      @change="hiddenSMTP"
    >
    </el-switch>
    <el-col>
      <el-button class="mb-30">
        <a
          :href="`${user.instructions.smtp.link}`"
          target="_blank"
          class="btn-content flex items-center"
        >
          <svg-icon
            icon-name="docs-thin"
            width="14"
            height="18"
            class-name="mr-10 text-green-400"
          ></svg-icon>
          <span>Скачать инструкцию</span>
        </a>
      </el-button>
    </el-col>
    <el-form
      ref="form"
      :model="item"
      :hide-required-asterisk="true"
      :rules="rules"
      label-position="top"
      class="section_content__inputs"
    >
      <template v-if="item.is_active">
        <el-row
          :gutter="!$mq.tablet ? 30 : 0"
          type="flex"
          class="mb-20 flex-wrap"
        >
          <el-col :span="$mq.tablet ? 24 : 12">
            <el-form-item class="mb-20" prop="sender_name">
              <span slot="label"
                >Имя отправителя<span class="danger">*</span></span
              >
              <el-input
                v-model="item.sender_name"
                placeholder="Введите имя отправителя"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="$mq.tablet ? 24 : 12">
            <el-form-item class="mb-20" prop="smtp_address">
              <span slot="label"
                >Сервер исходящей почты<span class="danger">*</span></span
              >
              <el-input
                v-model="item.smtp_address"
                placeholder="Введите адрес отправителя"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row
          :gutter="!$mq.tablet ? 30 : 0"
          type="flex"
          class="mb-20 flex-wrap"
        >
          <el-col :span="$mq.tablet ? 24 : 8">
            <el-form-item class="mb-20" prop="sender_email">
              <span slot="label"
                >Адрес отправителя<span class="danger">*</span></span
              >
              <el-input
                v-model="item.sender_email"
                placeholder="Введите имя отправителя"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="$mq.tablet ? 24 : 8">
            <el-form-item class="mb-20" prop="smtp_port">
              <span slot="label">SMTP порт<span class="danger">*</span></span>
              <el-input
                v-model.number="item.smtp_port"
                type="number"
                placeholder="Введите адрес отправителя"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="$mq.tablet ? 24 : 8">
            <el-form-item class="mb-20" prop="smtp_encryption">
              <span slot="label"
                >Протокол шифрования для SMTP<span class="danger">*</span></span
              >
              <el-input
                v-model="item.smtp_encryption"
                placeholder="Введите адрес отправителя"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col>
            <el-form-item
              class="mb-20 checkbox__group"
              prop="smtp_authorization"
            >
              <el-checkbox
                v-model="item.smtp_authorization"
                label="Использовать SMTP авторизацию"
                class="mb-8"
              ></el-checkbox>
              <p class="checkbox__subtitle">
                Активируйте эту опцию, если ваш SMTP-сервер требует авторизацию
              </p>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="!$mq.tablet ? 30 : 0" type="flex" class="flex-wrap">
          <el-col :span="$mq.tablet ? 24 : 12">
            <el-form-item class="mb-20" prop="smtp_user">
              <span slot="label">SMTP логин<span class="danger">*</span></span>
              <el-input
                v-model="item.smtp_user"
                placeholder="Введите имя отправителя"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="$mq.tablet ? 24 : 12">
            <el-form-item class="mb-30" prop="smtp_password">
              <span slot="label">SMTP-пароль<span class="danger">*</span></span>
              <el-input
                v-model="item.smtp_password"
                type="password"
                placeholder="Введите адрес отправителя"
              ></el-input>
            </el-form-item>
          </el-col>
          <!-- <el-col>
            <el-button class="mb-30">
              <a
                :href="`${user.instructions.smtp.link}`"
                target="_blank"
                class="btn-content flex items-center"
              >
                <svg-icon
                  icon-name="docs-thin"
                  width="14"
                  height="18"
                  class-name="mr-10 text-green-400"
                ></svg-icon>
                <span>Скачать инструкцию</span>
              </a>
            </el-button>
          </el-col> -->
        </el-row>
      </template>
      <el-row v-if="item.is_active">
        <el-col>
          <el-button type="primary" :loading="loading.save" @click="save()"
            >Сохранить
          </el-button>
          <el-button
            type="primary"
            :loading="loading.check"
            class="bg-transparent text-white-normal"
            @click="check"
            >Проверить соединение
          </el-button>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  data() {
    return {
      switcher: null,
      isValid: false,
      item: {
        is_active: false,
        sender_name: "",
        sender_email: "",
        smtp_address: "",
        smtp_port: "",
        smtp_encryption: "",
        smtp_authorization: false,
        smtp_user: "",
        smtp_password: "",
      },
      rules: {
        sender_name: {
          required: true,
          message: "Введите имя отправителя",
          trigger: ["blur", "change"],
        },
        sender_email: {
          required: true,
          message: "Введите сервер исходящей почты",
          trigger: ["blur", "change"],
        },
        smtp_address: {
          required: true,
          message: "Введите адрес отправителя",
          trigger: ["blur", "change"],
        },
        smtp_port: {
          required: true,
          message: "Введите SMTP порт",
          trigger: ["blur", "change"],
        },
        smtp_encryption: {
          required: true,
          message: "Введите протокол шифрования для SMTP",
          trigger: ["blur", "change"],
        },
        smtp_user: {
          required: true,
          message: "Введите SMTP логин",
          trigger: ["blur", "change"],
        },
        smtp_password: {
          required: true,
          message: "Введите SMTP-пароль",
          trigger: ["blur", "change"],
        },
      },
      loading: {
        save: false,
        check: false,
      },
    };
  },

  computed: {
    ...mapState("notifications", ["smtpInfo"]),
    ...mapState("user", ["user"]),
  },

  async created() {
    await this.getSMTP();
    this.setData();
  },
  methods: {
    ...mapMutations(["setNotif"]),
    ...mapActions("notifications", ["getSMTP", "setSMTP", "checkAuthSMTP"]),

    hiddenSMTP(e) {
      if (e === false) {
        this.save(true);
      }
    },
    setData() {
      if (this.smtpInfo) {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in this.smtpInfo) {
          this.item[key] = this.smtpInfo[key];
        }
      }
    },
    async save(noActive) {
      this.$refs.form.validate(async (valid) => {
        if (noActive || valid) {
          if (!noActive) {
            this.loading.save = true;
          }
          await this.setSMTP(this.item);
          if (!noActive) {
            await this.getSMTP();
            this.setData();
            this.loading.save = false;
          }
        }
      });
    },

    async check() {
      this.loading.check = true;
      await this.checkAuthSMTP();
      this.loading.check = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";
::v-deep .el-checkbox__input {
  margin-top: auto;
}
.checkbox {
  &__subtitle {
    font-size: 12px;
    line-height: 20px;
    color: $--color-white-5;
    font-weight: 500;
    margin-top: 2px;
  }
}
</style>
