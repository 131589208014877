<template>
  <TemplateBlock
    title="Шаг 4 —  Настройка формы бронирования"
    button-text-save="Сохранить"
    @back="$emit('input', value - 1)"
    @next="save"
  >
    <template v-if="showTypeForm">
      <div class="title-widget">Тип формы</div>

      <el-radio
        :value="widget.typeBookingForm"
        label="center"
        @input="setWidget({ field: 'typeBookingForm', value: $event })"
      >
        Всплывающая форма по центру
      </el-radio>
      <div v-if="widget.typeBookingForm === 'center'" class="block">
        <el-button
          size="small"
          class="all-service"
          @click="isOpenCenter = true"
        >
          Показать форму
        </el-button>
        <p class="block__text">
          Нажмите, чтобы увидеть как форма будет появляться при клике на ячейку
          в расписании виджета
        </p>
      </div>
      <el-radio
        :value="widget.typeBookingForm"
        label="sticky"
        disabled
        @input="setWidget({ field: 'typeBookingForm', value: $event })"
      >
        Прилипающая форма справа
      </el-radio>
      <div v-if="widget.typeBookingForm === 'sticky'" class="block">
        <el-button
          size="small"
          class="all-service"
          @click="isOpenSticky = true"
        >
          Показать форму
        </el-button>
        <p class="block__text">
          Нажмите, чтобы увидеть как форма будет появляться при клике на ячейку
          в расписании виджета
        </p>
      </div>
    </template>

    <div class="title-widget last-child">Поля формы</div>
    <el-checkbox-group
      :value="widget.fieldForm"
      @input="setWidget({ field: 'fieldForm', value: $event })"
    >
      <el-checkbox label="fieldName"> Имя</el-checkbox>
      <el-checkbox label="fieldFamaly"> Фамилия</el-checkbox>
      <el-checkbox label="fieldEmail"> Email</el-checkbox>
      <el-checkbox label="fieldPhone"> Телефон </el-checkbox>
      <el-checkbox label="maskRussian">
        Маска для российских номеров
      </el-checkbox>
      <el-checkbox label="fieldComment"> Комментарий </el-checkbox>
      <el-checkbox label="fieldPromo"> Промокод </el-checkbox>
      <el-checkbox v-if="isUBRR" label="fieldСlient"> Код клиента </el-checkbox>
      <el-checkbox v-if="isUBRR" label="fieldUbrirTel"
        >Последние 7 цифр телефона
      </el-checkbox>
    </el-checkbox-group>
    <div v-for="(newField, idx) in add_params" :key="idx">
      <el-row :gutter="10">
        <el-col :span="18"
          ><el-input
            v-model="newField.param_name"
            :placeholder="newField.param_name"
            class="my-10"
          ></el-input></el-col
        ><el-col :span="6">
          <div class="list-fields__actions">
            <ButtonIcon
              icon-name="close"
              width="16"
              class="my-10"
              @click="deleteField(idx)"
            />
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- <div v-if="isVisib">
      <span>Добавить поле</span>

      <el-input
        v-model="param.param_name"
        placeholder="Новое поле"
        class="my-10"
        @blur="addNewParam(param)"
      ></el-input>
    </div>
    <el-button size="small" class="all-service" @click="isVisib = !isVisib">
      {{ isVisib ? "Скрыть поле" : "Добавить поле" }}
    </el-button> -->
    <el-drawer
      :show-close="false"
      :visible="isOpenSticky"
      direction="rtl"
      size="430px"
      class="bookform"
      @close="isOpenSticky = false"
    >
      <FormSticky is-drawer @close="isOpenSticky = false" />
    </el-drawer>
    <el-dialog
      :visible.sync="isOpenCenter"
      :show-close="false"
      @closed="isOpenCenter = false"
    >
      <FormCenter @close="isOpenCenter = false" />
    </el-dialog>
  </TemplateBlock>
</template>

<script>
import TemplateBlock from "@/views/Widgets/components/TemplateBlock.vue";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import FormSticky from "@/views/Widgets/components/FormSticky.vue";
import FormCenter from "@/views/Widgets/components/FormCenter.vue";

export default {
  name: "SettingsForm",
  components: { TemplateBlock, FormSticky, FormCenter },
  props: {
    value: {
      type: Number,
    },
  },
  data() {
    return {
      isVisib: false,
      isOpenSticky: false,
      isOpenCenter: false,
      param: {
        param_id: "",
        param_name: "",
        param_value: "",
        param_required: false,
        param_type: "input",
      },
      add_params: [],
    };
  },

  computed: {
    ...mapState("widget", ["widget", "widgetPublic"]),
    ...mapGetters("widget", ["isAddParam"]),
    ...mapGetters("user", ["isUBRR"]),

    showTypeForm() {
      return this.widget.typeSingle !== "form";
    },
  },
  created() {
    if (this.widget.hash) {
      this.widget.fieldForm = this.widgetPublic.fieldForm;
    }
    if (this.isAddParam) {
      this.add_params = this.isAddParam;
    }
  },
  methods: {
    ...mapMutations("widget", ["setWidget"]),

    ...mapActions("widget", ["saveWidget"]),
    addNewParam(p) {
      if (p.param_name) {
        this.add_params.push(p);
        this.param = { param_id: "", param_name: "", param_value: "" };
      }
    },
    deleteField(index) {
      this.add_params.splice(index, 1);
    },
    async save() {
      this.setWidget({ field: "add_data", value: this.add_params });
      await this.saveWidget();
      this.$emit("input", this.value + 1);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

::v-deep {
  .el-radio {
    margin-bottom: 10px;
  }
  .el-checkbox {
    margin-bottom: 15px;
  }
}
.block {
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 0 0 0 25px;
  margin-bottom: 20px;
  &__text {
    color: $--color-white-5;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    margin: 0 25px;
  }
}
.last-child {
  margin-top: 20px;
}
</style>
