/* eslint-disable no-undef */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/ru-RU";
import MQ from "vue-match-media/src";
import VueAwesomeSwiper from "vue-awesome-swiper";
import vueCustomElement from "vue-custom-element";
import WidgetSingle from "./components/WidgetSingle";
import VueCookies from "vue-cookies";
import VueTheMask from "vue-the-mask";
import VueYandexMetrika from "vue-yandex-metrika";
import VueGtag from "vue-gtag";

// Глобальные файлы со стилями
import "./scss/fonts.scss";
import "simplebar/dist/simplebar.min.css";
import "swiper/dist/css/swiper.css";
import "./scss/maps.scss";
import "./scss/main.scss";
import "./scss/element-icons.scss";
import "./scss/tailwind.css";

import Tooltip from "@/components/Tooltip";
import Tabs from "@/components/Tabs.vue";
import SwitchCustom from "@/components/SwitchCustom";
import InputSelect from "@/components/InputSelect.vue";
import BlockCollapse from "@/components/BlockCollapse";
import ButtonIcon from "@/components/ButtonIcon";
import Spinner from "@/components/Spinner";
import TemplateFormBron from "@/components/TemplateFormBron";

Vue.use(VueTheMask);

// Vue.use(VueYandexMetrika, {
//   id: 42973454,
//   router,
//   env:process.env.NODE_ENV,
// });

Vue.use(VueGtag);

Vue.component("Tooltip", Tooltip);
Vue.component("Tabs", Tabs);
Vue.component("SwitchCustom", SwitchCustom);
Vue.component("InputSelect", InputSelect);
Vue.component("BlockCollapse", BlockCollapse);
Vue.component("ButtonIcon", ButtonIcon);
Vue.component("Spinner", Spinner);
Vue.component("TemplateFormBron", TemplateFormBron);

// Plugins
Vue.use(ElementUI, { locale });
Vue.use(MQ);
Vue.use(VueAwesomeSwiper /* { default global options } */);
Vue.use(vueCustomElement);
Vue.use(VueCookies);

// Global components
// Icons
const SvgIcon = () => import("./components/SvgIcon");
Vue.component("SvgIcon", SvgIcon);

Vue.config.productionTip = false;

// new Vue({
//   router,
//   store,
//   render: h => h(App),
//   mq: {
//       phone: '(max-width: 640px)',
//       tablet: '(max-width: 1024px)',
//       desktop: '(max-width: 1280px)',
//       large: '(max-width: 1600px)'
//   }
// }).$mount('#app');

App.store = store;
App.router = router;
App.mq = {
  phoneMobile: "(min-width:360px)",
  phoneMobileLarge: "(min-width:480px)",
  phoneMobileLargeMax: "(max-width:480px)",
  phone: "(max-width: 640px)",
  largePhone: "(max-width:760px)",
  largePhonePlus: "(max-width:976px)",
  largePhonePlusPlus: "(min-width:993px)",
  tablet: "(max-width: 1024px)",
  desktop: "(max-width: 1280px)",
  large: "(max-width: 1600px)",
};

WidgetSingle.store = store;
WidgetSingle.router = router;
WidgetSingle.mq = {
  phoneMobile: "(min-width:360px)",
  phoneMobileLarge: "(min-width:480px)",
  phoneMobileLargeMax: "(max-width:480px)",
  phone: "(max-width: 640px)",
  largePhone: "(max-width:760px)",
  tablet: "(max-width: 1024px)",
  desktop: "(max-width: 1280px)",
  large: "(max-width: 1600px)",
};
Vue.customElement("vue-app", App);
Vue.customElement("widget-single", WidgetSingle);
