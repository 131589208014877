var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"widget",staticClass:"flex justify-center mb-30"},[(_vm.isLoading && !_vm.isMaket)?_c('div',[_c('span',[(_vm.isLoading)?_c('spinner',{attrs:{"custom-color":{ border: ("" + (_vm.custom.active_buttons_background)) }}}):_vm._e()],1),_c('span',{staticClass:"text-14",style:({
        color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
      })},[_vm._v("Подождите, пожалуйста. Загружаем расписание")])]):_c('div',[_c('TemplateFormBron',{staticClass:"form__bron mb-0",attrs:{"title":_vm.isMaket ? "Игра престолов" : _vm.service.name,"sub-title":_vm.isMaket ? "Квест в реальности" : _vm.getCategory(_vm.service.kind_of_activity),"is-hidden-close":"","style-custom":_vm.custom,"is-type-theme":_vm.isIndividualTheme,"total-price":_vm.isMaket ? 1600 : _vm.totalPrice,"list-field-show":!_vm.isMaket ? _vm.widgetPublic.fieldForm : _vm.fieldForm,"select-cell":_vm.selectCell,"is-maket":_vm.isMaket,"loading":_vm.loading,"disabled":_vm.isDisabled},on:{"booking":_vm.broni}},[(_vm.showCalendar)?_c('functional-calendar',{ref:"Calendar",staticClass:"mb-30",class:{ customPoper: _vm.isIndividualTheme },attrs:{"slot":"calendar","disabled-dates":_vm.calendarConfigs.disabledDates,"configs":_vm.calendarConfigs},on:{"update:configs":function($event){_vm.calendarConfigs=$event},"dayClicked":function($event){return _vm.clickCalendar($event)}},slot:"calendar",model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}):_vm._e(),_c('el-row',{class:_vm.$mq.phone ? 'flex-col w-full' : 'flex-row',attrs:{"gutter":_vm.$mq.large ? 10 : 20,"type":"flex"}},[(!_vm.showCalendar)?_c('el-col',{style:({
            background: _vm.isIndividualTheme ? _vm.custom.block_background : '',
          }),attrs:{"span":_vm.$mq.phone && !_vm.isMaket ? 24 : 12}},[_c('p',{staticClass:"hint"},[_vm._v("Выберите дату"),_c('span',{staticClass:"danger"},[_vm._v("*")])]),_c('el-date-picker',{class:{ customPoper: _vm.isIndividualTheme },attrs:{"popper-class":"customSelect","type":"date","format":"dd.MM.yyyy","value-format":"yyyy-MM-dd","picker-options":_vm.pickerOptions,"placeholder":"Дата"},on:{"change":function($event){return _vm.clickCalendar($event)}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1):_vm._e(),(_vm.showCalendar)?_c('el-col',{staticClass:"flex flex-col",style:({
            color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
          }),attrs:{"span":_vm.$mq.phone && !_vm.isMaket ? 24 : 12}},[_c('p',{staticClass:"hint",style:({
              color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
            })},[_vm._v(" Дата ")]),_c('span',[_vm._v(_vm._s(_vm.isCorrectDate))])]):_vm._e(),_c('el-col',{staticClass:"flex flex-col",attrs:{"span":_vm.$mq.phone && !_vm.isMaket ? 24 : 12}},[_c('p',{staticClass:"hint",style:({
              color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
            })},[_vm._v(" Выберите время"),_c('span',{staticClass:"danger"},[_vm._v("*")])]),_c('el-select',{ref:"selectCustom",class:{ customPoperSelec: _vm.isIndividualTheme },attrs:{"popper-append-to-body":false,"popper-class":"customSelect"},on:{"input":_vm.setTime},model:{value:(_vm.selectTime),callback:function ($$v) {_vm.selectTime=$$v},expression:"selectTime"}},_vm._l((_vm.listTime),function(item,index){return _c('el-option',{key:("range-" + index),class:{ customPoperSelec: _vm.isIndividualTheme },attrs:{"label":item,"value":item}})}),1)],1)],1),_c('el-row',[_c('div',{staticClass:"hl",style:({
            background: _vm.widgetPublic.typeTheme
              ? _vm.custom.background_cells_fields
              : '',
          })}),_c(_vm.originData.component,{ref:"compChild",tag:"components",attrs:{"select-cell":_vm.selectCell,"hash":_vm.service,"settings":_vm.service.BookingType,"slots-validity":_vm.slotsValidity,"custom-style":_vm.custom,"is-theme-widget":_vm.isMaket ? true : _vm.widgetPublic.typeTheme,"select-time":_vm.selectTime},on:{"setPrice":_vm.setPrice,"isDis":_vm.isDis,"setRent":_vm.setRent,"setTariff":_vm.setTariff,"setTickets":_vm.setTickets,"setRentInfo":_vm.setRentInfo,"SetForGroup":_vm.setForGroup,"setSlotParam":_vm.getSlotParam},model:{value:(_vm.person),callback:function ($$v) {_vm.person=$$v},expression:"person"}}),(_vm.showHeaderAddOptions)?_c('div',{staticClass:"title-widget",style:({
            color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
          })},[_vm._v(" Дополнительные опции ")]):_vm._e(),_c('div',{staticClass:"bk-full mb-10"},[_c('el-checkbox-group',{model:{value:(_vm.addOptCheck),callback:function ($$v) {_vm.addOptCheck=$$v},expression:"addOptCheck"}},_vm._l((_vm.listAddOptCheck),function(item,i){return _c('el-checkbox',{key:("addOptCheck-" + i),staticClass:"items-baseline",class:[
                {
                  'custom-style':
                    _vm.widgetPublic.typeTheme === 'light' ||
                    _vm.widgetPublic.typeTheme === 'dark' ||
                    _vm.widgetPublic.typeTheme === 'individual',
                },
                {
                  'single-grid':
                    _vm.widgetPublic.typeTheme === 'light' ||
                    _vm.widgetPublic.typeTheme === 'dark' ||
                    _vm.widgetPublic.typeTheme === 'individual',
                } ],attrs:{"label":item}},[_c('span',{staticClass:"custom-check__title",style:({
                  color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
                })},[_vm._v(_vm._s(item.name))]),(item.price)?_c('span',{staticClass:"custom-check__subtitle",style:({
                  color: _vm.isIndividualTheme ? _vm.custom.inactive_text_color : '',
                })},[_vm._v(" "+_vm._s(item.price)+" "+_vm._s(_vm.selectCell.currency)+" ")]):_vm._e()])}),1),_vm._l((_vm.addOptInput),function(item,i){return _c('div',{key:("input-" + i),staticClass:"input-number",style:({
              color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
            }),attrs:{"label":1}},[_c('div',{staticClass:"input-number__info"},[_c('span',{staticClass:"input-number__info-title",style:({
                  color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
                })},[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"input-number__info-subtitle",style:({
                  color: _vm.isIndividualTheme ? _vm.custom.inactive_text_color : '',
                })},[_vm._v(" "+_vm._s(item.price ? ((item.price) + " ") : "Бесплатно")+" "+_vm._s(_vm.selectCell.currency)+" ")])]),_c('el-input-number',{staticClass:"input-number__input",class:[
                {
                  'custom-style':
                    _vm.widgetPublic.typeTheme === 'light' ||
                    _vm.widgetPublic.typeTheme === 'dark' ||
                    _vm.widgetPublic.typeTheme === 'individual',
                },
                {
                  'single-grid':
                    _vm.widgetPublic.typeTheme === 'light' ||
                    _vm.widgetPublic.typeTheme === 'dark' ||
                    _vm.widgetPublic.typeTheme === 'individual',
                } ],style:({
                background:
                  _vm.widgetPublic.typeTheme === 'light' ||
                  _vm.widgetPublic.typeTheme === 'dark' ||
                  _vm.widgetPublic.typeTheme === 'individual'
                    ? _vm.custom.background_cells_fields
                    : '',
              }),attrs:{"max":item.amount,"min":0},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})],1)})],2),(_vm.slotsValidity)?_c('div',{staticClass:"my-10"},[(_vm.slotsValidity.msg)?_c('el-alert',{staticClass:"w-full",attrs:{"title":_vm.slotsValidity.msg,"type":"warning","closable":false,"center":""}}):_vm._e()],1):_vm._e()],1)],1)],1),(_vm.isShowPay)?_c('el-dialog',{attrs:{"visible":_vm.isShowPay,"show-close":false,"width":"450px"},on:{"update:visible":function($event){_vm.isShowPay=$event},"reloadWidget":function($event){_vm.isShowPay = false}}},[(_vm.dataPay.success)?_c('PayWidget',{attrs:{"widget-theme":_vm.widgetPublic.typeTheme,"custom-class":_vm.custom},on:{"close":function($event){_vm.isShowPay = false},"reloadWidget":_vm.reloadWidget}}):_vm._e(),(!_vm.dataPay.success)?_c('PayError',{attrs:{"error-title":_vm.dataPay.title,"widget-theme":_vm.widgetPublic.typeTheme,"custom-class":_vm.custom,"data-pay":_vm.dataPay.params,"msg":_vm.errorMessage},on:{"close":function($event){_vm.isShowPay = false}}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }