<!-- eslint-disable prettier/prettier -->
<template>
  <el-form ref="clientInfo" :rules="rules" :model="clientInfo">
    <el-drawer
      :show-close="false"
      :visible="isOpen"
      direction="rtl"
      :size="$mq.phone ? '96%' : '430px'"
      class="bookform"
      @close="$emit('update:isOpen', false)"
    >
      <div slot="title" class="bookform-header">
        <div v-if="loading" class="spinner__wrapp">
          <spinner v-if="loading" class="table__spinner" />
        </div>
        <div class="bookform-header__title text-white-normal">
          {{
            param.status === "update"
              ? "Редактировать бронирование"
              : "Создать новое бронирование"
          }}
        </div>
        <svg-icon
          class="bookform-header__close text-white-normal"
          icon-name="close"
          width="16"
          height="16"
          @click.native="$emit('update:isOpen', (clientInfo = null), false)"
        ></svg-icon>
      </div>
      <div class="bookform-content">
        <el-collapse>
          <div v-if="param.status === `update`" class="mt-10">
            <div
              class="mt-30 mb-10 whitespace-nowrap leading-25 inline-flex flex-col"
            >
              <h3>
                {{ param.nameService ? param.nameService : service.name }}
              </h3>
              <router-link
                target="_blank"
                :to="{
                  path: '/reserves',
                  query: { text: param.item.booking_hash },
                }"
              >
                <span class="py-3 update-form underline font-medium leading-25">
                  #{{ param.item.booking_hash }}</span
                ></router-link
              >
            </div>
            <div class="flex">
              <div class="flex justify-between my-10 w-3/4 xs:flex-col">
                <div class="w-2/3">
                  <span class="small_text">Дата</span>
                  <span class="font-medium">{{
                    getDate(param.item.reservation[0].date)
                  }}</span>
                </div>
                <div class="w-1/2">
                  <span class="small_text">Время </span>
                  <!-- <span class="font-medium">{{ param.item.time }}</span> -->
                  <span class="font-medium">{{
                    param.item.reservation[0].time || param.item.time
                  }}</span>
                </div>
                <div class="w-1/2">
                  <span class="small_text">Продолжительность </span>
                  <span class="font-medium">{{
                    param.duration_text
                      ? param.duration_text
                      : param.item.duration
                  }}</span>
                </div>
                <div>
                  <span class=""></span>
                </div>
              </div>
            </div>
            <div v-if="param.status === 'update'" class="mt-15">
              <p class="hint">Статус брони <span class="danger">*</span></p>
              <el-select
                v-model="statusName"
                value-key="name"
                class="bordered select_status w-full"
                @change="changeAvailableStatusOrder"
              >
                <div
                  v-if="slotDetails.status"
                  slot="prefix"
                  class="flex items-center h-full"
                >
                  <span
                    class="w-10 h-10 relative"
                    :style="{
                      background: slotDetails.status.color,
                    }"
                    style="border-radius: 50%; left: 10px"
                  >
                  </span>
                </div>
                <el-option
                  v-for="(item, idx) in slotDetails.available_statuses"
                  :key="idx"
                  :label="item.name"
                  :value="item"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <BlockCollapse title="Услуга" name="service">
            <ServiceBook
              ref="usernameInput"
              :is-holiday="isHoliday"
              :item-list="param"
              :slots-validity-parent="slotsValidity"
              :date-slide="dateSlide"
              :add-opt-check="addOptCheck"
              :add-opt-input="addOptInput"
              @isDisabl="isDisabl"
              @openPromPt="openPromPt"
              @setService="setService"
              @isLoad="isLoad"
              @setPrice="setPrice"
              @setRent="setRent"
              @setServParam="setServiceCheck"
              @setForGroup="setForGroup"
              @setTarifCheck="setTarifCheck"
              @setTicketCheck="setTicketCheck"
              @setTickets="setTickets"
              @setBase="setBase"
              @setDiscount="setDiscount"
              @setDiscountIndivid="setDiscountIndivid"
              @changeAddOptions="changeAddOptions"
            />
          </BlockCollapse>

          <BlockCollapse
            v-if="showHeaderAddOptions"
            title="Дополнительные опции"
            name="options"
          >
            <div>
              <el-checkbox-group v-model="addOptCheck">
                <el-checkbox
                  v-for="(item, i) in listAddOptCheck"
                  :key="`addOptCheck-${i}`"
                  :label="item"
                  @change="changeItem"
                >
                  <span class="custom-check__title">{{ item.name }}</span>
                  <span v-if="item.price" class="custom-check__subtitle">
                    {{ item.price }} {{ item.currency }}
                  </span>
                </el-checkbox>
              </el-checkbox-group>
              <div
                v-for="(item, i) in addOptInput"
                :key="`input-${i}`"
                class="input-number"
                :label="1"
              >
                <div class="input-number__info">
                  <span class="input-number__info-title">{{ item.name }}</span>
                  <span class="input-number__info-subtitle">
                    {{ item.price ? `${item.price} ₽` : "Бесплатно" }}
                  </span>
                </div>
                <el-input-number
                  v-model="item.selected"
                  class="input-number__input"
                  :max="item.amount"
                  :min="0"
                  @change="changeItem"
                />
              </div>
            </div>
          </BlockCollapse>
          <!-- <BlockCollapse title="Скидки и промокоды" name="sale">
            <div class="bk-full">
              <p class="hint">Применить</p>
              <el-select
                v-model="serviceId"
                filterable
                placeholder="Выберите услугу"
                class="w-full"
              >
                <el-option
                  v-for="item in servicesList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <el-radio v-model="typeSale" :label="1">
              Нет скидки или промокода
            </el-radio>
            <el-radio v-model="typeSale" :label="2"> Скидка </el-radio>
            <div v-if="typeSale === 2" class="flex items-center mb-12">
              <InputSelect
                v-model="numberSale"
                :list-value="listOptionsSale"
                :select-value="typesSale"
                placeholder="Скидка"
                @setValue="typesSale = $event"
              ></InputSelect>
              <el-button class="ml-20">Применить </el-button>
            </div>
            <el-radio v-model="typeSale" :label="3"> Промокод </el-radio>
            <div v-if="typeSale === 3" class="flex items-center mb-12">
              <template v-if="true">
                <el-input
                  v-model="numberSale"
                  placeholder="Промокод"
                ></el-input>
                <el-button class="ml-20">Применить </el-button>
              </template>
              <el-alert
                v-else
                :title="true ? 'Промокод применен' : 'Промокод не найден'"
                :type="true ? 'success' : 'warning'"
                show-icon
                :closable="false"
                class="w-full"
                center
              >
              </el-alert>
            </div>
          </BlockCollapse> -->
          <BlockCollapse v-if="!!listsPaysType" title="Оплата" name="pay">
            <div>
              <PaymentsBook
                v-if="isTransaction"
                :lists-pays-type="listsPaysType"
                :status="param.status"
                :param="param"
                :slot-details="slotDetails"
                :is-payment-type="isPaymentType"
                @isLoad="isLoad"
              />

              <div
                v-if="param.status === 'update' && !param.item.booking_hash"
                class="mb-8"
              >
                <div class="hl"></div>
              </div>
              <!-- <div v-for="(pay, idx) in listsPays" :key="idx" class=""> -->
              <!-- v-if="param.status === 'update' && !param.item.booking_hash"  -->
              <div
                v-if="
                  param.status !== 'update' ||
                  (serviceParam && serviceParam.resource_id !== param.hash)
                "
              >
                <el-select
                  v-model="payment_type"
                  value-key="value"
                  filterable
                  placeholder="Выберите тип оплаты"
                  class="w-full"
                >
                  <el-option
                    v-for="(item, ix) in listsPaysType"
                    :key="ix"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
                <div v-if="payment_type === 'prepayment'">
                  <p class="bk-info-pay__title w-full mb-8 mt-15">
                    Размер предоплаты <span class="danger">*</span>
                  </p>

                  <el-input
                    v-model="prePayVal"
                    disabled
                    placeholder="Введите размер предоплаты"
                  >
                    <i slot="suffix" class="el-icon-check"></i>
                  </el-input>
                </div>
              </div>
            </div>
            <div
              v-if="param.status === 'update' && !param.item.booking_hash"
              class="my-10"
            >
              <el-button class="w-full to-orders" @click="addNewPays()"
                >Добавить оплату
              </el-button>
            </div>
          </BlockCollapse>

          <BlockCollapse title="Клиент" name="client">
            <div class="bk-full">
              <p class="hint">Имя<span class="danger">*</span></p>
              <el-form-item prop="name">
                <el-input
                  v-model="clientInfo.name"
                  class="w-full"
                  placeholder="Введите имя"
                  @input="patternValue($event)"
                  @focusout.native="checkForm"
                />
              </el-form-item>
            </div>
            <div class="bk-full">
              <p class="hint">Фамилия</p>
              <el-input
                v-model="clientInfo.surname"
                class="w-full"
                placeholder="Введите фамилию"
              />
            </div>
            <div class="bk-full">
              <p class="hint">Номер телефона<span class="danger">*</span></p>
              <el-form-item prop="tel">
                <el-input
                  v-model="clientInfo.tel"
                  v-mask="'+7 (###) ###-##-##'"
                  placeholder="+7 (999) 999-99-99"
                  class="w-full"
                  @focusout.native="checkForm"
                />
              </el-form-item>
              <el-checkbox
                v-model="clientInfo.tel_notification"
                :disabled="user.sms <= 0"
              >
                <span class="custom-check__title"
                  >Уведомлять клиента по SMS</span
                >
              </el-checkbox>
              <!-- <el-checkbox v-model="clientInfo.email_notification">
                <span class="custom-check__title"
                  >Уведомлять клиента по E-mail</span
                >
              </el-checkbox> -->
            </div>
            <div v-if="user.userWidgetFilds && isUBRR">
              <div
                v-for="area in clientInfo.add_params"
                :key="`${area.id}`"
                class="bk-full"
              >
                <p class="hint">
                  {{ area.name
                  }}<span v-if="area.required" class="danger">*</span>
                </p>
                <el-form-item prop="add_params">
                  <el-input
                    v-model="area.comment"
                    v-mask="!!area.mask ? '+7 (***) ###-##-##' : 'XXXXXXXXXXXX'"
                    class="w-full"
                    :placeholder="area.name"
                  />
                </el-form-item>
              </div>
            </div>
            <!-- <el-checkbox
              v-model="clientInfo.tel_notification"
              :disabled="user.sms <= 0"
            >
              <span class="custom-check__title">Уведомлять клиента по SMS</span>
            </el-checkbox> -->
            <router-link to="/sms">
              <el-alert
                v-if="user.sms <= 0"
                center
                title="Для включения это опции необходимо приобрести пакет СМС-сообщений."
                type="warning"
                show-icon
                :closable="false"
              />
            </router-link>
            <div class="bk-full">
              <p class="hint">E-mail</p>
              <el-form-item prop="email">
                <el-input
                  v-model="clientInfo.email"
                  class="w-full"
                  placeholder="Введите e-mail"
                  @input.native="checkForm"
                />
              </el-form-item>
              <el-checkbox v-model="clientInfo.email_notification">
                <span class="custom-check__title"
                  >Уведомлять клиента по E-mail</span
                >
              </el-checkbox>
            </div>
          </BlockCollapse>
          <BlockCollapse title="Комментарии" name="comment">
            <div class="bk-full">
              <p class="hint">Источник</p>
              <el-select
                v-model="ordersource_id"
                filterable
                placeholder="Выберите источник"
                class="w-full"
              >
                <el-option
                  v-for="item in ordersSources"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
            <div v-if="param.status === 'update'">
              <div
                v-if="!!getCommets && !!getCommets.client.length"
                class="bk-full"
              >
                <p class="hint">Комментарий клиента</p>
                <div
                  v-for="(comm, idx) in getCommets.client"
                  :key="idx"
                  class="rounded-sm py-15"
                >
                  <div class="mb-10 text-white-500 text-12">
                    {{ `${comm.date} в ${comm.time}` }}
                  </div>
                  <p class="text-12 leading-20">{{ comm.comment }}</p>
                </div>
              </div>

              <div
                v-if="!!getCommets && !!getCommets.admin.length"
                class="bk-full"
              >
                <p class="hint">Комментарии администраторов</p>
                <div
                  v-for="(comm, idx) in getCommets.admin"
                  :key="idx"
                  class="rounded-sm py-15"
                >
                  <div class="mb-10 text-white-500 text-12">
                    {{ comm.user_name }} •
                    {{ `${comm.date} в ${comm.time}` }}
                  </div>
                  <p class="text-12 leading-20">{{ comm.comment }}</p>
                </div>
              </div>
              <div class="bk-full">
                <p class="hint">Комментарии администраторов</p>
                <el-input
                  v-model="textarea2"
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  placeholder="Введите новый комментарий..."
                >
                </el-input>
              </div>
            </div>
            <div v-else class="">
              <div v-if="param.status !== 'update'" class="bk-full"></div>
              <div class="bk-full">
                <p class="hint">Комментарии администраторов</p>
                <el-input
                  v-model="textarea2"
                  type="textarea"
                  :autosize="{ minRows: 2, maxRows: 4 }"
                  placeholder="Введите новый комментарий..."
                >
                </el-input>
              </div>
            </div>
          </BlockCollapse>
        </el-collapse>
      </div>
      <div class="bookform-broni">
        <el-alert
          v-if="(errMsgslotsValidity || errorMsg) && !openModal"
          center
          :title="errMsgslotsValidity || errorMsg.msg"
          type="warning"
          show-icon
          :closable="false"
        />
        <div v-if="openModal">
          <el-alert
            v-if="openModal"
            center
            :title="
              errMsgslotsValidity ||
              `Стоимость на выбранное время отличаются от стоимости в заказе! Новая стоимость:
${checkedSum} `
            "
            type="warning"
            show-icon
            :closable="false"
          />
          <el-radio-group
            v-model="keepСost"
            size="medium"
            class="w-full promptcheck"
          >
            <el-radio
              label="false"
              class="p-10 w-full rounded-sm border border-white-300"
              :class="{ check: keepСost === 'false' }"
              >Актуализировать стоимость
            </el-radio>

            <el-alert
              v-if="
                keepСost === 'false' &&
                slotDetails.payment_data[0].type !== 'payment_locally'
              "
              center
              :title="
                this.keepСost
                  ? `${slotsValidity.keep_cost[0].name} `
                  : `${slotsValidity.keep_cost[1].name}`
              "
              type="warning"
              show-icon
              :closable="false"
              class="my-5"
            />
            <div v-if="keepСost === 'false' && slotsValidity.type_of_payment">
              <el-radio-group
                v-for="(radioVal, index) in slotsValidity.type_of_payment"
                :key="index"
                v-model="addPaymentType"
                size="medium"
                class="w-full promptcheck"
              >
                <el-radio :label="radioVal.type" class="p-10 w-full rounded-sm"
                  >{{ radioVal.name }}
                </el-radio>
              </el-radio-group>
            </div>
            <el-radio
              label="true"
              class="p-10 w-full rounded-sm border border-white-300"
              :class="{ check: keepСost === 'true' }"
              >Оставить стоимость прежней
            </el-radio>
          </el-radio-group>
        </div>
        <div class="bookform-broni__total">
          <div>
            <p class="bookform-broni__total-label">Стоимость</p>
            <p
              v-if="serviceParam || totalPr"
              class="bookform-broni__total-number"
            >
              <span v-if="!!disc.discount" class="flex line-through"
                >{{ disc.price }} ₽</span
              >

              <span v-else>{{ totalPr }} </span>
            </p>
          </div>
          <el-input v-model="totalPr" placeholder="0">
            <div slot="prefix">К оплате</div>
            <div slot="suffix">₽</div>
          </el-input>
        </div>
        <!-- || (!keepСost && openModal) -->
      
        <el-button
          :disabled="
            isDisabledBookingAdd ||
            !formValid ||
            (!keepСost &&
              openModal &&
              errMsgslotsValidity !== 'Дата и время заказа уже прошли')
          "
          type="primary"
          @click="addNewBooking()"
          >{{ param.status === "update" ? "Сохранить" : "Добавить" }}</el-button
        >
      </div>
    </el-drawer>
  </el-form>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

import moment from "moment";
// import PaymentBook from "@//views/Schedule/PaymentBook";
import ServiceBook from "@//views/Schedule/ServiceBook";
import BlockCollapse from "@/components/BlockCollapse";
import PaymentsBook from "@/views/Schedule/PaymentsBook";

moment.locale("ru");

export default {
  name: "BookForm",
  components: {
    BlockCollapse,
    // PaymentBook,
    ServiceBook,
    PaymentsBook,
  },
  props: {
    isOpen: Boolean,
    param: Object,
    hash: String,
    dateSlide: String,
  },
  data() {
    return {
      lastVal: "",
      checkedSum: null,
      updatedBookInfo: {},
      keepСost: "",
      addPaymentType: null,
      openModal: false,
      hiddenRefund: true,
      clientComments: [],
      statusName: null,
      linkPay: "",
      formValid: true,
      loading: false,
      total: null,
      base_price: null,
      basePriceForCheck: null,
      serviceId: null,
      serviceParam: null,
      addOptCheck: [],
      addOptInput: [],
      serviceCheck: null,
      prePayVal: null,
      payment_type: null,
      group: null,
      rent: null,
      selectTariff: null,
      selectTickets: null,
      addPersons: 0,
      priceAddPersons: null,
      discount: 0,
      disc: "",
      totalDiscountPrice: null,
      isDiscount: null,
      prepaymentUnit: null,
      prepaymentAmount: null,
      listsPays: [
        // {
        //   payment_type: null,
        // },
      ],
      scheduleDate: moment().toDate(),
      scheduleDateOptions: {
        disabledDate: (time) => {
          return (
            time < moment().subtract(1, "day").toDate() ||
            time > moment().add(this.user.settings.limit_show_site, "day")
          );
        },
      },
      check: [],
      typeSale: "",
      typesSale: "procent",
      numberSale: "",
      priceService: 0,

      clientInfo: {
        name: "",
        surname: "",
        tel: "",
        tel_notification: true,
        email: "",
        email_notification: false,
        add_params: [],
      },
      rules: {
        name: [
          {
            required: true,
            message: "Введите имя",
            trigger: "blur",
            pattern: /\D*(^[a-zA-Zа-яА-Я]+$)\D*/,
          },
        ],
        tel: {
          required: true,
          message: "Введите корректный телефонный номер",
          trigger: "blur",
        },
        email: [
          {
            type: "email",
            message: "Введите корректный email",
            trigger: ["blur", "change"],
          },
        ],
      },
      textarea1: "",
      textarea2: "",
      ordersource_id: null,
      totalPerson: 1,
    };
  },

  computed: {
    ...mapState("widget", [
      "widgetPublic",
      "dataPay",
      "slotDetails",
      "slotsValidity",
      "errorMsg",
    ]),
    ...mapState("user", ["user", "servicesList"]),
    ...mapState("service", ["settings", "service"]),

    ...mapGetters("widget", [
      "paymentType",
      "isDisabledBookingAdd",
      "errMsgslotsValidity",
      "isPayCheck",
    ]),
    ...mapGetters("user", ["ordersSources", "isUBRR"]),

    isIndividual() {
      return (
        this.slotDetails.booking_type.booking_type ===
          "individual_does_not_depend_on_the_number_of_seats" ||
        this.slotDetails.booking_type.booking_type ===
          "individual_depends_on_the_number_of_seats"
      );
    },
    isService() {
      return (
        this.slotDetails.booking_type.booking_type ===
          "individual_depends_on_the_service_and_its_duration" ||
        this.slotDetails.booking_type.booking_type ===
          "service_tree_from_settings"
      );
    },
    isTariff() {
      return (
        this.slotDetails.booking_type.booking_type ===
        "individual_depends_on_tariff_and_number_of_seats"
      );
    },
    isTickets() {
      return (
        this.slotDetails.booking_type.booking_type ===
          "group_same_seat_limit_for_all_sessions" ||
        this.slotDetails.booking_type.booking_type ===
          "group_the_seat_limit_is_set_for_each_session"
      );
    },
    isRent() {
      return (
        this.slotDetails.booking_type.booking_type ===
          "rent_depends_only_on_time" ||
        this.slotDetails.booking_type.booking_type ===
          "rent_depends_on_time_and_place"
      );
    },
    dataPayment() {
      return this.slotDetails?.payment_data.find((e) => e);
    },

    isPaymentType() {
      return this.slotDetails?.payment_data.find((e) => e.type);
    },
    listsPaysType() {
      return this.slotDetails?.payment_type;
    },
    isTransaction() {
      return this.slotDetails.booking?.transactions.find((e) => e);
    },
    isPreUnit() {
      return this.slotDetails.prepayment_amount;
    },

    isLink() {
      return this.isTransaction?.acquiring_id;
    },

    isHoliday() {
      return this.param.item?.is_holiday || false;
    },

    isAdditional() {
      return this.serviceListCheck[0]?.additional_options
        .filter((item) => item.amount)
        .map((el) => {
          return { ...el, selected: 0 };
        });
    },
    // isAdditionalCheck() {
    //   return this.serviceListCheck[0]?.additional_options
    //     .filter((item) => !item.amount)
    //     .map((el) => {
    //       return { ...el };
    //     });
    // },

    isAdditionalBooking() {
      return this.param.status === "update"
        ? this.slotDetails.booking.book_info.add_options_input ||
            this.slotDetails.booking.book_info.additional_options
              .filter((item) => item.amount)
              .map((el) => {
                return { ...el, selected: el.selected };
              })
        : this.serviceListCheck[0]?.additional_options
            .filter((item) => item.amount)
            .map((el) => {
              return { ...el, selected: 0 };
            });
    },
    isAdditionalCheckBooking() {
      return this.param.status === "update"
        ? this.slotDetails.booking.book_info.add_options_check.map((item) => ({
            ...item,
            checked: true,
          }))
        : "";
    },
    isRefound() {
      return this.slotDetails.payment_data?.find((ref) => ref);
    },
    totalPr() {
      const sumCheck = this.addOptCheck.reduce((sum, current) => {
        return sum + current?.price;
      }, 0);
      const sumInput = this.addOptInput.reduce((sum, current) => {
        return sum + current.selected * current.price;
      }, 0);
      const placesVal = this.serviceParam?.book_info?.rent.places;
      const personVal =
        this.slotDetails.booking_type.booking_type ===
        "individual_depends_on_the_number_of_seats"
          ? this.serviceParam?.book_info.persons
          : this.serviceParam?.book_info?.rent.duration ?? 1;
      const weekendAdditionalPrice =
        this.serviceParam?.book_info?.weekend_price_additional || 0
          ? this.serviceParam?.book_info?.weekend_price_additional
          : this.param?.item?.price_additional || 0;
      const baseAdditionalPrice = this.serviceParam;

      const isForPlace =
        this.slotDetails?.booking_type?.data?.price === "for_a_place";
      if (
        this.slotDetails.booking_type.booking_type ===
        "service_tree_from_settings"
      ) {
        return 0;
      }
      if (
        this.slotDetails.booking_type.booking_type ===
        "individual_depends_on_the_service_and_its_duration"
      ) {
        // История
        return this.serviceCheck?.price; // this.total;
      }
      if (this.isTariff) {
        return this.total * this.selectTariff?.persons;
      }
      // if (
      //   this.slotDetails.booking_type.booking_type ===
      //   "individual_depends_on_the_number_of_seats_forAgroup_true"
      // ) {
      //   // Математика
      //   return this.price;
      // }
      if (
        this.slotDetails.booking_type.booking_type ===
          "individual_depends_on_the_number_of_seats" ||
        this.slotDetails.booking_type.booking_type ===
          "individual_depends_on_the_number_of_seats_forAgroup_true"
      ) {
        if (isForPlace)
          return (
            // this.slotDetails?.booking?.book_info?.price?.price
            (this.slotDetails?.booking?.book_info?.individual?.price ||
              this.serviceParam?.book_info?.total_price) *
              personVal +
            sumCheck +
            sumInput
          ); // this.serviceParam?.book_info?.total_price * personVal;
        return this.isHoliday
          ? this.group?.price +
              this.addPersons * this.priceAddPersons +
              // weekendAdditionalPrice +
              sumCheck +
              sumInput
          : (this.group?.price || this.price) +
              this.addPersons * this.priceAddPersons +
              // baseAdditionalPrice +
              sumCheck +
              sumInput;
        //+

        // weekendAdditionalPrice +
        // sumCheck +
        // sumInput
      }

      if (
        this.slotDetails.booking_type.booking_type ===
        "group_the_seat_limit_is_set_for_each_session"
      ) {
        return this.price;
      }
      if (
        this.slotDetails.booking_type.booking_type ===
        "group_same_seat_limit_for_all_sessions"
      ) {
        return this.price + sumInput;
      }
      if (
        // НЕ ДОБАВЛЯЕМ ДОБАВОЧНУЮ СТОИМОСТЬ!!!
        this.slotDetails.booking_type.booking_type ===
        "individual_does_not_depend_on_the_number_of_seats"
      ) {
        // eslint-disable-next-line no-nested-ternary
        return this.isDiscount
          ? (this.isHoliday
              ? this.serviceParam?.book_info?.total_price +
                // REMOVE
                this.serviceParam?.book_info?.weekend_price_additional -
                this.isDiscount
              : this.serviceParam?.book_info?.total_price -
                //  +
                //   this.serviceParam?.book_info?.price_additional
                this.isDiscount) *
              placesVal *
              personVal +
              sumCheck +
              sumInput
          : this.param.status && this.isHoliday
          ? this.serviceParam?.book_info?.total_price *
              // +
              // weekendAdditionalPrice
              // *
              personVal +
            sumCheck +
            sumInput
          : this.serviceParam?.book_info?.total_price *
              // this.serviceParam?.shedulePrice
              // REMOVE
              // weekendAdditionalPrice*
              personVal +
            sumCheck +
            sumInput;
      }

      const place =
        this.slotDetails.booking_type?.booking_type ===
        "rent_depends_on_time_and_place";
      if (
        this.slotDetails.booking_type.booking_type ===
        "rent_depends_on_time_and_place"
      ) {
        return this.price * placesVal;
      }
      if (
        this.slotDetails.booking_type.booking_type ===
        "rent_depends_only_on_time"
      ) {
        return this.price + sumCheck + sumInput;
      }
      // ИЗМЕНИЛ this.serviceParam?.book_info?.total_price НА this.serviceParam?.shedulePrice
      // eslint-disable-next-line no-nested-ternary
      return place && this.isDiscount
        ? (this.isHoliday
            ? this.serviceParam?.book_info?.total_price +
              this.serviceParam?.book_info?.weekend_price_additional -
              this.isDiscount
            : this.serviceParam?.book_info?.total_price -
              //  +
              //   this.serviceParam?.book_info?.price_additional
              this.isDiscount) *
            placesVal *
            personVal +
            sumCheck +
            sumInput
        : this.param.status ||
          this.slotDetails.booking_type.booking_type ===
            "group_same_seat_limit_for_all_sessions"
        ? this.serviceParam?.book_info?.total_price +
          weekendAdditionalPrice * personVal +
          sumCheck +
          sumInput
        : this.serviceParam?.book_info?.total_price +
          //   this.serviceParam?.shedulePrice +
          weekendAdditionalPrice * personVal +
          sumCheck +
          sumInput;
    },
    getCommets() {
      return this.slotDetails.booking?.comments;
    },
    isPayType() {
      return this.slotDetails;
    },

    showHeaderAddOptions() {
      return this.listAddOptCheck.length || this.addOptInput.length;
    },

    serviceListCheck() {
      const key = this.param.hash;
      return this.servicesList.filter((e) => {
        return e.hash.includes(key) ? e : "";
      });
    },
    // listAddOptCheck() {
    //   return this.serviceListCheck[0]?.additional_options
    //     ? this.serviceListCheck[0]?.additional_options.filter((e) => !e.amount)
    //     : "";
    // },
    isCommentEmpty() {
      return !!(this.param.status === "update" && this.textarea2.length <= 1);
    },
  },

  watch: {
    clientInfo: {
      handler() {
        this.checkForm();
      },
      deep: true,
      // immediate: true,
    },
    totalPr() {
      if (this.payment_type === "prepayment") {
        this.isPrePayVal();
      }
    },
    payment_type() {
      if (this.payment_type === "prepayment") {
        this.isPrePayVal();
      }
    },
    selectTariff() {
      if (this.payment_type === "prepayment") {
        this.isPrePayVal();
      }
    },
    keepСost() {
      if (this.keepСost !== "true") this.setFirstValueNewPayType();
    },
  },
  mounted() {
    // this.addOptCheck =
    //   this.param?.status === "update" ? this.isAdditionalCheckBooking : []; // this.isAdditionalCheck; //
    // this.isAdditional;
  },

  created() {
    this.setListOPtCheck();
    const isCheckPayType = this.slotDetails.payment_type.find((e) => e.value);
    this.clientInfo.add_params =
      this.param.status !== "update" && !!this.user.userWidgetFilds
        ? this.user.userWidgetFilds
        : [];

    this.totalPr =
      this.param.status !== "update"
        ? this.slotDetails.booking?.final_price
        : this.slotDetails.booking?.book_info?.total_price;
    this.payment_type =
      this.param.status !== "update"
        ? this.slotDetails?.payment_type[0]?.value
        : this.payment_type; // isCheckPayType.name; // this.slotDetails.payment_type;

    this.clientInfo.name =
      this.param.status === "update"
        ? this.slotDetails.booking.client_info.name
        : ""; // this.param?.item?.reservation[0]?.client_info?.name;

    this.clientInfo.surname =
      this.param.status === "update"
        ? this.slotDetails.booking.client_info.surname
        : "";

    this.clientInfo.tel =
      this.param.status === "update"
        ? this.param?.item?.reservation[0]?.client_info?.tel
        : "";
    this.clientInfo.tel_notification =
      this.param.status === "update"
        ? this.param?.item?.reservation[0]?.client_info?.tel_notification
        : "";
    this.clientInfo.email =
      this.param.status === "update"
        ? this.param?.item?.reservation[0]?.client_info?.email
        : "";
    this.clientInfo.email_notification =
      this.param.status === "update"
        ? this.param?.item?.reservation[0]?.client_info?.email_notification
        : "";

    this.statusName =
      this.param.status === "update" ? this.slotDetails.status.name : "";

    this.ordersource_id =
      this.param.status === "update"
        ? this.ordersSources.find(
            (e) =>
              e.name === this.param.item?.reservation[0]?.ordersource_data.name
          ).id
        : this.ordersource_id;
    this.addOptInput =
      this.param?.status === "update"
        ? this.isAdditionalBooking
        : this.isAdditional;
    // разобраться с опциями, в цену добавляются автоматом
    this.addOptCheck =
      this.param?.status === "update" ? this.isAdditionalCheckBooking : [];
    // this.isAdditionalCheck; // this.isAdditional;

    this.isMapPayment();
  },

  methods: {
    ...mapActions("paymentHistory", ["getRefundData", "getRefund"]),
    ...mapActions("widget", ["saveBooking", "checkSlotsValidity"]),
    ...mapActions("orders", [
      "getReserves",
      "sendChanges",
      "changeStatusOrder",
    ]),
    ...mapActions("widget", [
      "saveBookingForAdmin",
      "getUpdateFormAdmin",
      "getSlotDetails",
    ]),
    ...mapActions("settings", ["getSettingsSite"]),
    ...mapActions({ getScheduleIndivid: "service/getSchedule" }),
    ...mapActions("schedule", ["getSchedule"]),
    ...mapActions({ getScheduleCalendar: "schedule/getScheduleCalendar" }),

    ...mapActions("service", ["getResourceSlots", "getService", "getSettings"]),

    async changeAddOptions(value) {
      const obj = {};
      obj.hash = value;
      await this.getSettings({ hash: obj.hash, id: obj.item?.id });
      await this.getSlotDetails({
        id: obj.hash,
        date: this.param.date
          ? this.param.date
          : this.param.item.reservation[0].date, // this.param.item.date,
        time: this.param.item.time,
        bookingHash: this.param.item.booking_hash,
        // // eslint-disable-next-line no-nested-ternary
        // bookingHash: item.item
        //   ? item.item.booking_type !== "group" &&
        //     item.item.booking_type_sub !== "rent_depends_on_time_and_place"
        //     ? item.item.booking_hash
        //     : null || ""
        //   : null,
      });

      await this.getService(value);

      await this.getScheduleCalendar({ hash: obj.hash, withSlots: true });

      // this.$emit("openInBook", obj);
      this.addOptInput = this.service.additional_options
        .filter((item) => item.amount)
        .map((el) => {
          return { ...el, selected: 0 };
        });
      //   this.listAddOptCheck
      // this.addOptCheck
      this.listAddOptCheck = this.service.additional_options.filter(
        (e) => !e.amount
      );
      this.$refs.usernameInput.setPers();
      this.loading = false;
    },
    patternValue(event) {
      const regexp = this.rules.name.find((e) => e.pattern).pattern;
      if (event.match(regexp)) {
        this.lastVal = this.clientInfo.name;
      } else {
        this.clientInfo.name = this.lastVal;
      }
      if (event.length === 0) this.clientInfo.name = "";
    },
    setFirstValueNewPayType() {
      if (
        this.keepСost !== "true" &&
        this.slotsValidity.type_of_payment.length === 1
      ) {
        this.addPaymentType = this.slotsValidity.type_of_payment[0].type;
      } else {
        this.addPaymentType = null;
      }
    },

    correctBookInfo() {
      let someObj = {};
      if (this.param.status === "update") {
        if (this.keepСost !== "true") {
          const keys = this.slotsValidity.booking_type.data;
          const {
            individual,
            group,
            rent,
            service: serviceCheck,
            tickets: selectTickets,
            tariff: selectTariff,
          } = keys;
          someObj = {
            ...(group && { group }),
            ...(individual && { individual }),
            ...(rent && { rent }),
            ...(serviceCheck && { serviceCheck }),
            ...(selectTickets && { selectTickets }),
            ...(selectTariff && { selectTariff }),
          };
        }
        this.updatedBookInfo = someObj;
        const key = Object.keys(someObj);
        this.$data[key] = { ...someObj[key] };
      }
    },

    isDisabl(e) {
      // console.log(e);
    },
    setListOPtCheck() {
      let arr = [];
      if (this.serviceListCheck[0]?.additional_options) {
        arr = this.serviceListCheck[0]?.additional_options.filter(
          (e) => !e.amount
        );
      }
      this.listAddOptCheck = [];
      if (this.param.status) {
        arr.forEach((element) => {
          if (this.isAdditionalCheckBooking.length) {
            this.isAdditionalCheckBooking.forEach((e) => {
              if (e.id === element.id) {
                this.listAddOptCheck.push(e);
              }
              // else if (
              //   e.id !== element.id &&
              //   this.isAdditionalCheckBooking.length !== arr.length
              // ) {
              //   this.listAddOptCheck.push(element);
              // }
            });
          } else {
            this.listAddOptCheck.push(element);
          }
        });
        const filter1 = arr.filter((i) =>
          this.listAddOptCheck.every((w) => w.id !== i.id)
        );
        this.listAddOptCheck = [...this.listAddOptCheck, ...filter1];
        return this.listAddOptCheck;
      }
      arr.forEach((e) => {
        this.listAddOptCheck.push(e);
      });
    },
    isPrePayVal() {
      this.prepaymentUnit =
        this.slotDetails.prepayment_unit === "percent" ? "percent" : "rub";
      this.prepaymentAmount = this.slotDetails.prepayment_amount;
      this.prePayVal =
        this.prepaymentUnit === "percent"
          ? (this.totalPr * this.prepaymentAmount) / 100
          : this.prepaymentAmount;
      return this.prePayVal;
    },
    openPromPt(payload, coast) {
      if (coast) {
        this.openModal = true;
        this.checkedSum = payload;
      }
    },

    setPrice(price) {
      this.price = price;
      this.total = price;
    },
    setDiscountIndivid(params) {
      this.disc = params;
    },
    setDiscount(discount) {
      const placesVal = this.serviceParam?.book_info?.rent.places;
      const personVal = this.serviceParam?.book_info?.rent.duration || 1;

      this.discount = discount;
      const isProcent = this.discount?.unit === "procent";
      this.isDiscount =
        this.discount && isProcent
          ? (this.serviceParam?.book_info?.total_price *
              this.discount.discount) /
            100
          : this.discount?.discount;
      this.totalDiscountPrice =
        (this.serviceParam?.book_info?.total_price - this.isDiscount) *
        placesVal *
        personVal;
    },

    setBase(price) {
      this.base_price = price;
    },
    setForGroup(item) {
      this.setRent({});
      this.totalPerson = null;
      this.group = item.group;
      this.addPersons = item.group.add_persons ? item.group.add_persons : null;
      this.priceAddPersons = item.group.price_add_persons;
    },
    isMapPayment() {
      if (this.slotDetails.payment_data) {
        // this.slotDetails.payment_data.map((e) => {
        //   const obj = {};
        //   obj.hash = e.hash;
        //   obj.payment_type = e.type;
        //   return this.listsP.push(obj);
        // });
      } else {
        const obj = {};
        obj.payment_type = this.payment_type;
        return this.listsPays.push(obj);
      }
    },
    setTickets(ticket) {
      this.serviceCheck = null;
      this.selectTickets = ticket;
    },
    // convertColor(color) {
    //   const [r, g, b] = color.split(",");
    //   return {
    //     border: `1px solid rgba(${r},${g},${b},${0.5})`,
    //     background: `rgba(${r},${g},${b},${0.2})`,
    //   };
    // },

    ...mapMutations(["setNotif"]),
    copy(copyLink) {
      navigator.clipboard.writeText(copyLink);
      this.setNotif({
        title: `Данные
скопированы`,
      });
    },

    getDates(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    getTime(time) {
      return moment(time).format("HH:mm");
    },
    addNewPays() {
      this.listsPays.push({
        id: +1,
        hash: "",
        payment_type: this.payment_type,
        paid_on_place: null,
      });
    },
    changeItem() {
      this.$refs.usernameInput.setPers(
        // eslint-disable-next-line no-underscore-dangle
        this.$refs.usernameInput._data.paramsCheck
      );
    },
    async changeAvailableStatusOrder() {
      await this.changeStatusOrder({
        hash: this.param.item.booking_hash,
        status_id: this.statusName.id,
      });
      // if (this.statusName.id === "0") {
      const { week } = this.$route.query;
      await this.getScheduleIndivid({
        hash: this.serviceParam.resource_id,
        week,
      });
      this.$emit("closeForm", {
        isShowForm: false,
        isShowPay: false,
      });
      // await this.getSchedule(this.serviceParam.date);
      // }
      //
    },

    checkForm() {
      const { fields } = this.$refs.clientInfo;
      if (fields.find((f) => f.validateState === "validating")) {
        setTimeout(() => {
          this.checkForm();
        }, 500);
      }
      this.formValid = fields.every((f) => {
        const valid = f.required && f.validateState === "success";
        const notErroring = !f.required && f.validateState !== "error";
        return notErroring;
      }, true);
    },

    getDate(param) {
      const date = new Date(param);
      return date.toLocaleDateString("ru");
    },
    setServiceCheck(serv) {
      this.total = serv.price;
      this.serviceCheck = serv.service ? serv.service : serv;
    },
    setTarifCheck(tarif, totalPrice) {
      this.base_price = tarif.price;
      this.total = totalPrice;
      this.selectTariff = tarif;
    },

    isLoad(payload) {
      this.loading = payload;
    },
    setAddOptInput() {
      this.param.item.reservation[0].book_info.additional_options
        .filter((item) => item.amount)
        .map((el) => {
          return { ...el, selected: el.selected };
        });
    },
    setTicketCheck(obj) {
      this.checkSlotsValidity(obj);
    },
    async setService(serviceParam) {
      //  this.$refs.usernameInput.setPers();
      if (this.payment_type === "prepayment") {
        this.isPrePayVal();
      }
      this.loading = true;
      this.totalPerson = serviceParam.book_info.persons;
      if (
        this.slotDetails.booking_type.booking_type ===
        "individual_depends_on_tariff_and_number_of_seats"
      ) {
        this.total = serviceParam.book_info.price;
      } else if (
        this.slotDetails.booking_type.booking_type !==
          "individual_depends_on_the_number_of_seats_forAgroup_true" &&
        this.slotDetails.booking_type.booking_type !==
          "individual_does_not_depend_on_the_number_of_seats"
      ) {
        this.total = this.totalPr;
      } else if (
        this.slotDetails.booking_type.booking_type ===
        "individual_depends_on_the_number_of_seats_forAgroup_true"
      ) {
        // Математика
        this.total = this.price; // this.total;
        this.totalPr = this.total;
      }
      this.serviceParam = serviceParam;
      const id = {};
      id.hash = serviceParam.resource_id;
      this.loading = false;
    },

    setRent(rent) {
      this.rent = rent;
    },
    async sendForm() {
      this.correctBookInfo();
      let data = {
        // add_payment_type: this.addPaymentType,
        resource_id: this.serviceParam.resource_id,
        schedule_id: this.serviceParam.schedule_id,
        booking_id:
          this.param.item.booking_type_sub !== "rent_depends_on_time_and_place"
            ? this.serviceParam.booking_id
            : null, // this.param?.item?.booking_hash,
        keep_cost: this.keepСost === "true",
        date: this.serviceParam.date,
        time: this.serviceParam.time,
        client_info: this.clientInfo,
        payment_type:
          this.param.status === "update"
            ? this.slotDetails.payment_type
            : this.payment_type,
        // payment_data: this.listsPays,
        payment_data:
          this.param.status === "update"
            ? this.slotDetails.payment_data
            : {
                payment_type: this.payment_type,
                prepayment_unit: this.prepaymentUnit,
                prepayment_amount: this.prepaymentAmount,
              },
        book_info: {
          total_discount_price: this.totalDiscountPrice,
          individual: this.isIndividual
            ? {
                persons: this.totalPerson,
                price:
                  this.keepСost === "true"
                    ? this.serviceParam.book_info.price
                    : this.updatedBookInfo.individual?.price ?? this.price, // this.serviceParam.shedulePrice, // this.price,
              }
            : null,
          total_price:
            // eslint-disable-next-line no-nested-ternary
            this.param.status === "update"
              ? this.keepСost === "true"
                ? this.totalPr
                : this.checkedSum ?? this.totalPr
              : this.totalPr,
          add_options_check: this.addOptCheck,
          add_options_input: this.addOptInput,
          tariff: this.isTariff ? { ...this.selectTariff } : null,
          service: this.isService ? this.serviceCheck : null,
          tickets: this.isTickets ? this.selectTickets : null,
          rent: this.isRent ? { ...this.rent } : null,
          group: { ...this.group },
        },
        ordersource_id: this.ordersource_id,
        comments: {
          client:
            this.textarea1.length > 1
              ? [
                  {
                    date: moment().format("DD-MM-YYYY"),
                    time: moment().format("LT"),
                    comment: this.textarea1,
                  },
                ]
              : [],
          admin:
            this.textarea2.length > 1
              ? [
                  {
                    date: moment().format("DD-MM-YYYY"),
                    time: moment().format("LT"),
                    comment: this.textarea2,
                  },
                ]
              : [],
        },
      };
      if (
        this.param.status === "update" &&
        !!this.slotsValidity?.type_of_payment
      ) {
        data = { ...data, add_payment_type: this.addPaymentType };
      }
      this.loading = true;
      await this.changeMethod(data);
      if (this.errorMsg) {
        this.isShowForm = true;
        this.loading = false;
      } else {
        this.isShowForm = false;
        this.isShowPay = true;
        if (this.$route.name === "resource") {
          const week = this.$route.query.week
            ? this.$route.query.week
            : this.user.now_week;
          await this.getScheduleIndivid({ hash: data.resource_id, week });
        } else if (this.$route.name !== "orders") {
          await this.getSchedule(this.serviceParam.date);
        }
        // await this.getSettingsSite();
        this.loading = false;
        this.$emit("closeForm", {
          isShowForm: false,
          isShowPay: true,
        });
      }
    },

    async addNewBooking() {
      this.$refs.clientInfo.validate((valid) => {
        if (valid) {
          this.sendForm();
        } else {
          console.log("error submit!!");
        }
      });
    },
    async changeMethod(data) {
      if (this.param.status === "update") {
        await this.getUpdateFormAdmin(data);
        this.$emit("closeForm", {
          isShowForm: false,
          isShowPay: true,
        });
      } else {
        await this.saveBookingForAdmin(data);
      }
    },
    async refundPayment() {
      const param = this.slotDetails.payment_data.find((e) => e.hash);
      await this.getRefund({
        hash: param.hash, // this.param.item.payment[0].hash,
        amount: Number(this.isTransaction.full_amount),
      });
      this.hiddenRefund = !this.hiddenRefund;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

::v-deep .el-collapse {
  border: none;
  &-item__header,
  &-item__wrap {
    background-color: transparent;
  }
}

::v-deep .collapse {
  padding-bottom: 15px;
  border-bottom: 1px solid $--color-white-15;
  margin: 0 !important;
  &-content {
    border-top: none;
    padding: 15px 0 0;
  }
  &-title {
    padding: 20px 0 0;
    display: flex;
    align-items: baseline;
    h2 {
      font-size: 16px;
      line-height: 28px;
    }
  }
}

::v-deep .el-drawer__body {
  display: flex;
  flex-direction: column;
}

.bookform {
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $--color-white-5;
    padding: 20px 15px 0;

    &__title {
      font-weight: 600;
      font-size: 16px;
      line-height: 28px;
    }
    &__close {
      cursor: pointer;
    }
  }
  &-content {
    flex-grow: 1;
    padding: 0 15px;
  }
  &-broni {
    position: sticky;
    bottom: 0;
    box-shadow: 0px -4px 6px rgba(31, 0, 48, 0.15);
    padding: 20px 30px;
    background-color: #3c1452;
    z-index: 99;
    ::v-deep .el-button {
      width: 100%;
    }
    &__total {
      display: flex;
      justify-content: space-between;
      margin-bottom: 22px;

      &-label {
        color: $--color-white-5;
        font-size: 12px;
        line-height: 20px;
      }
      &-number {
        color: $--color-white;
        font-size: 14px;
        line-height: 24px;
      }

      ::v-deep .el-input {
        width: 200px;
        &__inner {
          text-align: end;
          padding-right: 40px;
        }
        &__prefix,
        &__suffix {
          display: flex;
          align-items: center;
          padding-bottom: 3px;
        }
        &__prefix {
          padding-left: 5px;
        }
      }
    }
  }
}

::v-deep .el-checkbox {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 20px;

  &__label,
  &-group {
    display: flex;
    flex-direction: column;
  }
  &__label {
    span {
      line-height: 1;
    }
  }
}

.custom-check {
  &__title {
    font-size: 14px;
    line-height: 24px;
    color: $--color-white;
  }
  &__subtitle {
    font-size: 12px;
    line-height: 20px;
    color: $--color-white-5;
    margin-top: 5px;
  }
}

.el-radio {
  margin-bottom: 10px;
}

.el-alert {
  margin-bottom: 15px;
}

.hint {
  margin-bottom: 8px;
}
.spinner__wrapp {
  width: 100vh;
  height: 100vh;
  position: fixed;
  z-index: 999999;
}

::v-deep .el-textarea__inner {
  background-color: $--color-light-white;
}
.update-form {
  color: rgba(255, 190, 61, 1);
}
.table__spinner {
  position: fixed;
  top: 50vh;
  left: 50%;
}

.link-pay {
  margin: 0 0 20px !important;
  align-items: flex-end;
  .bk-row__item {
    flex-grow: 1;
    padding: 0;
  }
  &__icon {
    margin-left: 24px;
    flex-shrink: 0;
  }
}

.el-alert {
  margin-bottom: 20px;
}

.hint {
  margin-bottom: 8px;
}
.payment {
  &__block {
    padding: 12px 16px;
    //background: rgba(52, 152, 219, 0.2);
    //border: 1px solid rgba(52, 152, 219, 0.5);
    align-items: center;
  }
}
.payment-link__wrap {
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 3px;
  padding: 10px;
}
.bk-info__utc {
  font-size: 12px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.5);
}
.bk-info__title {
  width: 180px !important;
}
.prompt__footer {
  padding: 30px;
  display: flex;
  justify-content: space-between;
}
.check {
  background: rgba(255, 190, 61, 1);
}
::v-deep .promptcheck .check .el-radio__label {
  color: black !important;
}
::v-deep .promptcheck .el-radio__input.is-checked .el-radio__inner {
  border: 3px;
  border-color: black !important;
  background: white !important;
}
</style>
