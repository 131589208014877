<template>
  <div class="tag">
    <slot></slot>
    <div v-if="close" class="tag__close" @click="$emit('closed')">
      <svg-icon width="8" height="8" icon-name="close"></svg-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tag",
  props: {
    close: Boolean,
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.tag {
  margin: 0 6px 10px 0;
  background: $--color-violet-400;
  border-radius: 3px;
  padding: 4px 8px;
  color: $--color-white;
  cursor: pointer;
  display: flex;
  align-items: center;
  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    padding: 5px;
    &:hover {
      background-color: red;
    }
  }
}
</style>
