function setField(state, { field, value }) {
  if (!field) return;
  state[field] = value;
}

function setFieldObj(state, { field, subField, value }) {
  if (!field || !subField) return;
  state[field][subField] = value;
}

function getError(commit, { title, type }) {
  commit(
    "setNotif",
    {
      title: title || "Ошибка при получении сохранении. Повторите еще",
      type: type || "success",
    },
    { root: true }
  );
}

export { setField, setFieldObj, getError };
