<template>
  <div class="h-full">
    <spinner v-if="isLoading" />
    <el-collapse v-else>
      <BlockCollapse title="Описание" name="descService">
        <el-form label-position="top">
          <h4 class="title">Описание</h4>
          <el-row :gutter="$mq.large ? 10 : 30" class="flex-wrap" type="flex">
            <el-col :span="$mq.large ? 24 : 12">
              <el-form-item prop="name">
                <span slot="label"
                  >Название услуги<span class="danger">*</span></span
                >
                <el-input
                  :value="service.name"
                  placeholder="Напишите название новой услуги"
                  @input="requerField({ field: 'name', value: $event })"
                  @blur="
                    autoSave({
                      field: 'name',
                    })
                  "
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="$mq.large ? 24 : 12">
              <el-form-item prop="kind_of_activity">
                <span slot="label"> Категория услуги </span>
                <el-select
                  :value="service.kind_of_activity"
                  placeholder="Выберите категория"
                  multiple
                  filterable
                  class="w-full"
                  @input="
                    requerField({
                      field: 'kind_of_activity',
                      value: $event,
                      autoSave: true,
                    })
                  "
                >
                  <el-option
                    v-for="item in typesActivity"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="Анонс услуги на виджете">
            <el-input
              :value="service.short_description"
              maxlength="100"
              show-word-limit
              placeholder="Введите анонс"
              @input="setService({ field: 'short_description', value: $event })"
              @blur="
                autoSave({
                  field: 'short_description',
                })
              "
            ></el-input>
          </el-form-item>
          <textarea-edit
            :value="service.detail_description"
            @input="setService({ field: 'detail_description', value: $event })"
            @blur="
              autoSave({
                field: 'detail_description',
              })
            "
          />
          <div class="hl"></div>
          <h4 class="title">Медиа</h4>
          <h4 class="sub-title">Фотографии</h4>
          <UploadPhoto
            :value="service.services_photos"
            @input="
              setService({
                field: 'services_photos',
                value: $event,
                autoSave: true,
              })
            "
          />
          <h4 class="sub-title">Видео</h4>
          <el-row :gutter="$mq.large ? 10 : 30" class="flex-wrap" type="flex">
            <el-col :span="$mq.large ? 24 : 8">
              <UploadPhoto
                is-logo
                :value="service.services_video_preview"
                text="Перетащите превью в эту область или загрузите"
                sub-text="Минимальный рекомендованный размер: 500x500 пикселей"
                @input="
                  setService({
                    field: 'services_video_preview',
                    value: $event,
                    autoSave: true,
                  })
                "
              />
            </el-col>
            <el-col :span="$mq.large ? 24 : 16">
              <el-row
                :gutter="$mq.large ? 10 : 30"
                class="flex-wrap"
                type="flex"
              >
                <el-col :span="24">
                  <el-form-item label="Название видео">
                    <el-input
                      :value="service.video_name"
                      placeholder="Введите название"
                      @input="
                        setService({
                          field: 'video_name',
                          value: $event,
                        })
                      "
                      @blur="
                        autoSave({
                          field: 'video_name',
                        })
                      "
                    ></el-input> </el-form-item
                ></el-col>
                <el-col :span="24">
                  <el-form-item label="Ссылка на видео">
                    <el-input
                      :value="service.video_link"
                      placeholder="Вставьте сюда ссылку"
                      @input="
                        setService({
                          field: 'video_link',
                          value: $event,
                        })
                      "
                      @blur="
                        autoSave({
                          field: 'video_link',
                        })
                      "
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-form>
      </BlockCollapse>
      <BlockCollapse title="Контактные данные" name="contactService">
        <el-form
          ref="сontactService"
          hide-required-asterisk
          label-position="top"
          :model="{ ...service }"
          :rules="rules"
          class="form"
        >
          <switch-custom
            :value="service.use_company_contacts"
            title="Использовать контактные данные из настроек компании"
            @input="
              setService({
                field: 'use_company_contacts',
                value: $event,
                autoSave: false,
              })
            "
          >
            Отметьте, если хотите использовать данные, введенные вами ранее в
            настройках компании. Просмотреть введенные данные вы можете
            <a
              class="text-white-normal"
              @click="$router.push({ path: '/settings' })"
            >
              здесь.
            </a>
          </switch-custom>
          <div class="wrapper-contacts">
            <el-form-item prop="emails">
              <span slot="label"
                >Контактный E-mail<span class="danger">*</span></span
              >
              <el-input
                :value="service.emails"
                placeholder="example@mail.ru"
                @input="setService({ field: 'emails', value: $event })"
              ></el-input>
            </el-form-item>
            <el-form-item prop="tel_req">
              <div slot="label" class="flex">
                <div>
                  Контактный номер телефона<span class="danger">*</span>
                </div>
                <el-popover
                  class="ml-8"
                  placement="top"
                  width="200"
                  trigger="hover"
                  content="Укажите телефон, который будет приходить клиенту в почтовом уведомлении при бронировании."
                >
                  <Tooltip slot="reference" />
                </el-popover>
              </div>
              <el-input
                v-mask="maskPhone"
                placeholder="+7 (999) 999-99-99"
                :value="service.tel_req"
                @input="
                  setService({
                    field: 'tel_req',
                    value: $event,
                    hash: hash,
                  })
                "
              ></el-input>
            </el-form-item>
            <ListField
              :value="service.tel"
              title-button="Добавить номер телефона"
              title-for-line="Дополнительный номер телефона"
              :mask="maskPhone"
              placeholder="+7 (999) 999-99-99"
              @input="setService({ field: 'tel', value: $event, hash: hash })"
            />
          </div>
          <h4 class="title">Адрес</h4>
          <map-info
            ref="map"
            class="mb-20"
            @validate="isValidMap = $event"
          ></map-info>
          <h4 class="title">Социальные сети и мессенджеры</h4>
          <switch-custom
            title="Использовать социальные сети и мессенджеры, указанные в
      настройках компании"
            :value="service.use_messengers_socials_from_company"
            @input="
              setService({
                field: 'use_messengers_socials_from_company',
                value: $event,
                hash: hash,
              })
            "
          >
            Ссылки на социальные сети и месседжеры будут отправляться клиенту в
            почтовых уведомлениях.
          </switch-custom>
          <div class="hl"></div>
          <el-button
            type="primary"
            class="custom-button"
            @click="submitContact()"
          >
            Сохранить
          </el-button>
        </el-form>
      </BlockCollapse>
    </el-collapse>
  </div>
</template>

<script>
import ListField from "@/components/Services/ListField.vue";
import UploadPhoto from "@/components/UploadPhoto";
import SwitchCustom from "@/components/SwitchCustom";
import TextareaEdit from "@/components/TextareaEdit";
import MapInfo from "@/views/Services/Create/components/Map.vue";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "ResourceDescription",
  components: {
    MapInfo,
    SwitchCustom,
    TextareaEdit,
    UploadPhoto,
    ListField,
  },
  data() {
    return {
      isValidMap: null,
      loading: false,
      maskPhone: "+7 (###) ###-##-##",
      isLoading: true,
      hash: null,
    };
  },
  computed: {
    ...mapState("company", ["typesActivity"]),
    ...mapState("service", ["service"]),
    ...mapState("user", ["user"]),
  },
  watch: {
    "service.use_company_contacts": function (val) {
      if (val) this.fromSetting();
    },
  },
  mounted() {
    if (this.service.use_company_contacts) this.fromSetting();
    // if (this.service.address_type !== "route") {}
  },
  created() {
    this.hash = this.service.hash;
    this.rules = {
      name: [
        { required: true, message: "заполните поле" },
        {
          min: 3,
          message: "Укажите название (минимум 3 символа)",
          trigger: ["blur"],
        },
      ],
      kind_of_activity: [
        { required: false, message: "заполните поле", trigger: "blur" },
      ],
      tel_req: [{ required: true, message: "заполните поле", trigger: "blur" }],
      emails: [{ required: true, message: "заполните поле", trigger: "blur" }],
    };
    this.namesTranslate = {
      name: "название услуги",
      kind_of_activity: "вид деятельности",
      tel_req: "телефон",
      emails: "эл. почту",
    };
    this.isLoading = false;
  },
  methods: {
    ...mapMutations("service", ["setService", "setFieldObj"]),
    ...mapMutations(["setNotif"]),
    ...mapActions("service", ["editService", "autoSave"]),
    requerField(obj) {
      // if (!this.service[obj.field]) return;
      if (
        ((typeof obj.value === "string" && !obj.value) || !obj.value.length) &&
        obj.field !== "kind_of_activity"
      ) {
        this.setNotif({
          title: `Поле обязательное для заполнения`,
          type: "warning",
        });
      }
      this.setService({ ...obj });
    },
    submitContact() {
      this.$refs.map.validateMap();
      this.$refs.сontactService.validate((valid) => {
        if (valid && this.isValidMap) {
          this.editService("сontact");
        } else {
          const str = Object.values(object)
            .map((value) => this.namesTranslate[value[0].field])
            .join(", ");

          this.setNotif({
            title: `Заполните следующие поля: ${str}.`,
            type: "warning",
          });
        }
      });
    },
    fromSetting() {
      this.setService({
        field: "tel_req",
        value: this.user.company.tel[0],
      });
      this.setService({ field: "emails", value: this.user.company.email[0] });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-collapse {
  border-top: 1px solid transparent !important;
  border-bottom: 1px solid transparent !important;
}
.sub-title {
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
}

::v-deep .el-input .el-input__count .el-input__count-inner {
  background-color: transparent;
}
@screen lg {
  .title {
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 28px;
  }
}
</style>
