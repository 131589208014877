<template>
  <div>
    <PreTables
      text="Отправленные сообщения"
      :value="historySmsInfo.totalItems"
      :start="startListing"
      :end="historySms.length"
    >
      <el-button
        class="preTables__btn preTables__send_sms"
        type="primary"
        @click="showModal = true"
      >
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.5 8.58336C16.5029 9.68325 16.2459 10.7683 15.75 11.75C15.162 12.9265 14.2581 13.916 13.1395 14.6078C12.021 15.2995 10.7319 15.6662 9.41667 15.6667C8.31678 15.6696 7.23176 15.4126 6.25 14.9167L1.5 16.5L3.08333 11.75C2.58744 10.7683 2.33047 9.68325 2.33333 8.58336C2.33384 7.26815 2.70051 5.97907 3.39227 4.86048C4.08402 3.7419 5.07355 2.838 6.25 2.25002C7.23176 1.75413 8.31678 1.49716 9.41667 1.50002H9.83333C11.5703 1.59585 13.2109 2.32899 14.441 3.55907C15.671 4.78915 16.4042 6.42973 16.5 8.16669V8.58336Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
        Отправить СМС</el-button
      >
      <div class="sms__counter w-11/12">
        <span>Показано c {{ startListing }} по {{ stopListing }} </span>
      </div>
    </PreTables>

    <SearchBar
      input-placeholder="Поиск по СМС-сообщениям/номеру"
      :export-links="[
        { link: '/api/sms/export/excel', name: 'Экспорт в Excel' },
      ]"
      @submit="(perPage = $event.perPage), createRequest()"
    />

    <div class="relative">
      <spinner v-if="isLoading" class="table__spinner" />
      <Table :current-page="currentPage" :page-size="perPage" />
    </div>
    <SendSms
      :show="showModal"
      class="lg:px-15"
      @close="showModal = false"
      @reloadSms="reloadSms"
    />
    <Pagination
      :page-size="perPage"
      :total="historySmsInfo.totalItems"
      class="custom-block"
      @change="(currentPage = $event), createRequest()"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SendSms from "@/components/SendModals/SendSms.vue";
import Pagination from "@/components/Table/Pagination.vue";
import SearchBar from "@/components/Table/SearchBar.vue";
import PreTables from "@/components/Table/PreTables.vue";
import Table from "@/components/Sms/HistorySms/Table.vue";

export default {
  components: {
    SendSms,
    Pagination,
    SearchBar,
    PreTables,
    Table,
  },
  data() {
    return {
      search: "",
      perPage: 10,
      currentPage: 1,
      isLoading: true,
      showModal: false,
    };
  },
  computed: {
    ...mapState("sms", ["historySms", "historySmsInfo"]),
    startListing() {
      if (this.currentPage === 1) return 1;
      return this.currentPage * this.perPage;
    },
    stopListing() {
      if (this.currentPage === 1) return this.perPage;
      return this.perPage * this.currentPage + this.perPage;
    },
  },
  async created() {
    await this.createRequest();
  },
  methods: {
    ...mapActions("sms", ["getHistorySMS"]),
    async createRequest(data, clear) {
      this.isLoading = true;

      if (data) {
        this.perPage = data.perPage;
        this.search = data.search.replace(/\D/g, "");
      }

      const req = {
        page: this.currentPage,
        perPage: this.perPage,
      };
      if (this.search.length > 0) req.search = this.search;

      await this.getHistorySMS(req);

      this.isLoading = false;
    },
    async reloadSms() {
      this.isLoading = true;
      const req = {
        page: this.currentPage,
        perPage: this.perPage,
      };
      if (this.search.length > 0) req.search = this.search;

      await this.getHistorySMS(req);

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.preTables {
  display: flex;
  flex-wrap: wrap;
  &__send_sms {
    &:hover,
    &:focus {
      color: $--color-white;
    }
  }
  &__btn {
    display: flex;
    background: none;
    color: $--color-white;
    ::v-deep span {
      display: flex;
      align-items: center;
      svg {
        margin-right: 8.5px;
      }
    }
  }
}
@media (max-width: 768px) {
  .preTables {
    flex-direction: column;
    &__btn {
      margin-top: 20px;
      justify-content: center;
    }
  }
}
</style>

<style lang="scss">
.table__spinner {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  .lds-ring {
    position: absolute !important;
    top: 0;
  }
}
.table_section {
  font-size: 14px;
  &__label {
    display: inline-block;
    width: 110px;
    color: rgba(255, 255, 255, 0.5);
  }
}
.sms__counter {
  color: rgba(255, 255, 255, 0.5);
}
</style>
