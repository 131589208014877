<template>
  <div>
    <div
      v-for="(item, index) in items"
      :key="index"
      class="promocode bg-white-40 rounded-sm mb-20"
    >
      <div
        class="item-head pl-25 pr-10 py-20 flex items-center xs:flex-wrap xs:flex-row lg:items-start lg:p-20 relative"
      >
        <div v-if="!$mq.tablet" class="item-head__left flex items-center">
          <el-button
            class="mr-20 px-0 w-40"
            @click="item.opened = !item.opened"
          >
            <span class="flex items-center justify-center">
              <svg-icon
                icon-name="chevron"
                width="12"
                height="8"
                class-name="text-white-normal"
                :class="{ 'rotate-180': !item.opened }"
              ></svg-icon>
            </span>
          </el-button>
        </div>
        <div class="person xs:order-1">
          <div class="person__status person__status_main">
            {{ item.role_name }}
          </div>
          <div class="person__name">{{ item.name }}</div>
        </div>
        <div v-if="!$mq.tablet" class="item__stats">
          <div class="item__stat">
            <div class="text-white-500 text-12 leading-16 mb-3">
              Отвечает за
            </div>
            <div class="font-medium">{{ item.resources.length }} услуги</div>
          </div>

          <div class="item__stat">
            <div class="text-white-500 text-12 leading-16 mb-3">E-mail:</div>
            <div class="font-semibold">
              {{ item.email }}
            </div>
          </div>
        </div>

        <div v-else class="w-full item__stat xs:order-3">
          <div class="flex xs:flex-col">
            <div
              class="item__stat--border flex items-center mb-6 xs:justify-between"
            >
              <div class="text-white-500 text-12 leading-16 pr-5">
                Отвечает за
              </div>
              <div class="text-12 font-medium leading-20">
                {{ item.resources.length }} услуг
              </div>
            </div>
            <div class="flex items-center mb-6 xs:justify-between">
              <div class="text-white-500 text-12 leading-16 pr-5">E-mail:</div>
              <div class="text-12 font-medium leading-20">
                {{ item.email }}
              </div>
            </div>
          </div>
        </div>
        <el-popover
          :key="item.id"
          class="ml-70 xs:ml-0 xs:order-2 xs:flex xs:justify-end xs:w-1/2"
          :class="{ 'pr-75': item.role_id === 1 }"
          placement="bottom-end"
          popper-class="control-poper"
          width="171"
          trigger="click"
        >
          <span
            v-if="item.role_id !== 1"
            slot="reference"
            class="px-15 flex items-center outline-none h-20 lg:pr-0"
          >
            <svg-icon
              icon-name="dots"
              class-name="text-white-500"
              width="20"
              height="4"
            ></svg-icon>
          </span>
          <div>
            <div
              class="services-drag__item-icon mobile"
              @click="$emit('correctPerson', item.id, 'correctUser')"
            >
              <svg-icon
                icon-name="edit"
                class-name="text-white-350"
                width="16"
                height="18"
              ></svg-icon>
              <span>Редактировать</span>
            </div>
            <div
              class="services-drag__item-icon services-drag__item-icon-remove mobile"
              @click="$emit('delete', item.id)"
            >
              <svg-icon
                icon-name="close"
                class-name="text-white-350"
                width="16"
                height="12"
              ></svg-icon>

              <span> Удалить</span>
            </div>
          </div>
        </el-popover>
      </div>
      <div
        v-if="item.opened"
        class="item-content px-30 pt-25 pb-30 border-t border-white-150 lg:p-20"
      >
        <el-button
          v-if="user.resources_count && item.role_id !== 1"
          :loading="loading.select"
          type="mini"
          class="mb-25"
          @click="toggleAllResourcesIn(index)"
          >{{ item.resources.length ? "Снять все" : "Выбрать все" }}</el-button
        >

        <el-checkbox-group v-model="item.resources" class="flex flex-col mb-30">
          <el-checkbox
            v-for="resource in user.resources"
            :key="resource.id"
            :disabled="item.role_id === 1"
            :label="resource.hash"
            class="mb-10"
            @change="updateResources(item)"
            >{{ resource.name }}</el-checkbox
          >
        </el-checkbox-group>
        <article v-if="item.description" class="promo-desc">
          <h3 class="text-16 font-semibold leading-28 mb-5">Описание</h3>
          <p class="text-white-700">{{ item.description }}</p>
        </article>
      </div>

      <div
        v-if="$mq.tablet"
        class="cursor-pointer border-t border-white-150 pt-12 pb-12 leading-25 flex items-center justify-center"
        @click="item.opened = !item.opened"
      >
        {{ !item.opened ? "Показать услуги" : "Скрыть" }}
        <svg-icon
          icon-name="chevron"
          class-name="text-white-normal ml-10"
          :class="{ 'rotate-180': !item.opened }"
          width="12"
          height="7"
        ></svg-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    elements: Array,
    user: Object,
  },
  data() {
    return {
      items: null,
      loading: {
        select: false,
      },
    };
  },
  created() {
    this.setData();
  },
  methods: {
    ...mapActions("personals", ["changeResources"]),
    setData() {
      this.items = this.elements;
    },
    async toggleAllResourcesIn(index) {
      const item = this.items[index];
      const resourcesCount = item.resources.length;
      this.loading.select = true;
      item.resources = [];
      if (resourcesCount >= 1) {
        item.resources = [];
      } else if (resourcesCount !== this.user.resources_count) {
        Object.values(this.user.resources).forEach((resource) => {
          item.resources.push(resource.hash);
        });
      }
      await this.changeResources(item);
      this.loading.select = false;
    },
    async updateResources(item) {
      this.loading.select = true;
      await this.changeResources(item);
      this.loading.select = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";
.person {
  width: 250px;
  @media (max-width: 480px) {
    width: 50%;
  }
  &__status {
    font-size: 12px;
    line-height: 20px;
    &_main {
      color: #ffbe3d;
    }
  }
  &__name {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
  }
}
.item {
  &__stats {
    min-width: 300px;
    display: flex;
    margin-left: auto;
  }
  &__stat {
    padding-right: 25px;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    // &--border {
    //   padding-bottom: 10px;
    //   border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    // }

    &:nth-child(2) {
      min-width: 200px;
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      @media (max-width: 480px) {
        margin-top: 15px;
        margin-left: 0px;
      }
    }
    &:last-of-type {
      border-right: none;
      padding-right: 0;
    }
  }
}
.mobile {
  font-size: 14px;
  line-height: 24px;
  min-height: 34px;

  span {
    border: none;
  }
}
.services-drag {
  &__draggable {
    border-bottom: 1px solid $--color-sm-white;
    border-top: 1px solid $--color-sm-white;
  }
  &__item {
    display: flex;
    height: 50px;
    &-handle {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 11px;
      cursor: pointer;
      border-right: 1px solid $--color-sm-white;
      border-left: 1px solid $--color-sm-white;
      height: 100%;
      flex-shrink: 0;
    }
    &-name {
      min-width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $--color-white;
      flex-grow: 1;
      margin: auto 15px;
    }
    &-icon {
      display: flex;
      align-items: center;
      padding: 0 10px;
      cursor: pointer;
      flex-shrink: 0;
      svg {
        color: #3498db;
      }
      &-remove {
        svg {
          color: #ff0000;
        }
      }
    }
  }
}
</style>
