<template>
  <div ref="widget" class="flex justify-center mb-30">
    <div v-if="isLoading && !isMaket">
      <span>
        <spinner
          v-if="isLoading"
          :custom-color="{ border: `${custom.active_buttons_background}` }"
      /></span>
      <span
        class="text-14"
        :style="{
          color: isIndividualTheme ? custom.active_text_color : '',
        }"
        >Подождите, пожалуйста. Загружаем расписание</span
      >
    </div>
    <div v-else>
      <TemplateFormBron
        class="form__bron mb-0"
        :title="isMaket ? `Игра престолов` : service.name"
        :sub-title="
          isMaket ? `Квест в реальности` : getCategory(service.kind_of_activity)
        "
        is-hidden-close
        :style-custom="custom"
        :is-type-theme="isIndividualTheme"
        :total-price="isMaket ? 1600 : totalPrice"
        :list-field-show="!isMaket ? widgetPublic.fieldForm : fieldForm"
        :select-cell="selectCell"
        :is-maket="isMaket"
        :loading="loading"
        :disabled="isDisabled"
        @booking="broni"
      >
        <functional-calendar
          v-if="showCalendar"
          ref="Calendar"
          slot="calendar"
          v-model="date"
          :disabled-dates="calendarConfigs.disabledDates"
          :configs.sync="calendarConfigs"
          class="mb-30"
          :class="{ customPoper: isIndividualTheme }"
          @dayClicked="clickCalendar($event)"
        ></functional-calendar>
        <el-row
          :gutter="$mq.large ? 10 : 20"
          :class="$mq.phone ? 'flex-col w-full' : 'flex-row'"
          type="flex"
        >
          <el-col
            v-if="!showCalendar"
            :span="$mq.phone && !isMaket ? 24 : 12"
            :style="{
              background: isIndividualTheme ? custom.block_background : '',
            }"
          >
            <p class="hint">Выберите дату<span class="danger">*</span></p>
            <el-date-picker
              v-model="date"
              popper-class="customSelect"
              type="date"
              format="dd.MM.yyyy"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              :class="{ customPoper: isIndividualTheme }"
              placeholder="Дата"
              @change="clickCalendar($event)"
            >
            </el-date-picker>
          </el-col>
          <el-col
            v-if="showCalendar"
            :span="$mq.phone && !isMaket ? 24 : 12"
            class="flex flex-col"
            :style="{
              color: isIndividualTheme ? custom.active_text_color : '',
            }"
          >
            <p
              class="hint"
              :style="{
                color: isIndividualTheme ? custom.active_text_color : '',
              }"
            >
              Дата
            </p>
            <span>{{ isCorrectDate }}</span>
          </el-col>

          <el-col :span="$mq.phone && !isMaket ? 24 : 12" class="flex flex-col">
            <p
              class="hint"
              :style="{
                color: isIndividualTheme ? custom.active_text_color : '',
              }"
            >
              Выберите время<span class="danger">*</span>
            </p>
            <el-select
              ref="selectCustom"
              v-model="selectTime"
              :popper-append-to-body="false"
              popper-class="customSelect"
              :class="{ customPoperSelec: isIndividualTheme }"
              @input="setTime"
            >
              <el-option
                v-for="(item, index) in listTime"
                :key="`range-${index}`"
                :label="item"
                :value="item"
                :class="{ customPoperSelec: isIndividualTheme }"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-row>
          <div
            class="hl"
            :style="{
              background: widgetPublic.typeTheme
                ? custom.background_cells_fields
                : '',
            }"
          ></div>
          <components
            :is="originData.component"
            ref="compChild"
            v-model="person"
            :select-cell="selectCell"
            :hash="service"
            :settings="service.BookingType"
            :slots-validity="slotsValidity"
            :custom-style="custom"
            :is-theme-widget="isMaket ? true : widgetPublic.typeTheme"
            :select-time="selectTime"
            @setPrice="setPrice"
            @isDis="isDis"
            @setRent="setRent"
            @setTariff="setTariff"
            @setTickets="setTickets"
            @setRentInfo="setRentInfo"
            @SetForGroup="setForGroup"
            @setSlotParam="getSlotParam"
          ></components>

          <div
            v-if="showHeaderAddOptions"
            class="title-widget"
            :style="{
              color: isIndividualTheme ? custom.active_text_color : '',
            }"
          >
            Дополнительные опции
          </div>
          <div class="bk-full mb-10">
            <el-checkbox-group v-model="addOptCheck">
              <el-checkbox
                v-for="(item, i) in listAddOptCheck"
                :key="`addOptCheck-${i}`"
                :label="item"
                class="items-baseline"
                :class="[
                  {
                    'custom-style':
                      widgetPublic.typeTheme === 'light' ||
                      widgetPublic.typeTheme === 'dark' ||
                      widgetPublic.typeTheme === 'individual',
                  },
                  {
                    'single-grid':
                      widgetPublic.typeTheme === 'light' ||
                      widgetPublic.typeTheme === 'dark' ||
                      widgetPublic.typeTheme === 'individual',
                  },
                ]"
              >
                <span
                  class="custom-check__title"
                  :style="{
                    color: isIndividualTheme ? custom.active_text_color : '',
                  }"
                  >{{ item.name }}</span
                >
                <span
                  v-if="item.price"
                  class="custom-check__subtitle"
                  :style="{
                    color: isIndividualTheme ? custom.inactive_text_color : '',
                  }"
                >
                  {{ item.price }} {{ selectCell.currency }}
                </span>
              </el-checkbox>
            </el-checkbox-group>
            <div
              v-for="(item, i) in addOptInput"
              :key="`input-${i}`"
              class="input-number"
              :label="1"
              :style="{
                color: isIndividualTheme ? custom.active_text_color : '',
              }"
            >
              <div class="input-number__info">
                <span
                  class="input-number__info-title"
                  :style="{
                    color: isIndividualTheme ? custom.active_text_color : '',
                  }"
                  >{{ item.name }}</span
                >
                <span
                  class="input-number__info-subtitle"
                  :style="{
                    color: isIndividualTheme ? custom.inactive_text_color : '',
                  }"
                >
                  {{ item.price ? `${item.price} ` : "Бесплатно" }}
                  {{ selectCell.currency }}
                </span>
              </div>
              <el-input-number
                v-model="item.selected"
                class="input-number__input"
                :max="item.amount"
                :min="0"
                :class="[
                  {
                    'custom-style':
                      widgetPublic.typeTheme === 'light' ||
                      widgetPublic.typeTheme === 'dark' ||
                      widgetPublic.typeTheme === 'individual',
                  },
                  {
                    'single-grid':
                      widgetPublic.typeTheme === 'light' ||
                      widgetPublic.typeTheme === 'dark' ||
                      widgetPublic.typeTheme === 'individual',
                  },
                ]"
                :style="{
                  background:
                    widgetPublic.typeTheme === 'light' ||
                    widgetPublic.typeTheme === 'dark' ||
                    widgetPublic.typeTheme === 'individual'
                      ? custom.background_cells_fields
                      : '',
                }"
              />
            </div>
          </div>
          <div v-if="slotsValidity" class="my-10">
            <el-alert
              v-if="slotsValidity.msg"
              :title="slotsValidity.msg"
              type="warning"
              :closable="false"
              class="w-full"
              center
            />
          </div>
        </el-row>
      </TemplateFormBron>
    </div>
    <el-dialog
      v-if="isShowPay"
      :visible.sync="isShowPay"
      :show-close="false"
      width="450px"
      @reloadWidget="isShowPay = false"
    >
      <PayWidget
        v-if="dataPay.success"
        :widget-theme="widgetPublic.typeTheme"
        :custom-class="custom"
        @close="isShowPay = false"
        @reloadWidget="reloadWidget"
      />
      <PayError
        v-if="!dataPay.success"
        :error-title="dataPay.title"
        :widget-theme="widgetPublic.typeTheme"
        :custom-class="custom"
        :data-pay="dataPay.params"
        :msg="errorMessage"
        @close="isShowPay = false"
      />
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { scheduleOneGrid } from "@/helpers/maket.js";
import { FunctionalCalendar } from "vue-functional-calendar";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

import GroupLimitAll from "@/views/Widgets/components/forWidget/GroupLimitAll";
import GroupLimitGeneral from "@/views/Widgets/components/forWidget/GroupLimitGeneral";
import NotLimitSeats from "@/views/Widgets/components/forWidget/NotLimitSeats.vue";
import DepSeatsPlace from "@/views/Widgets/components/forWidget/DepSeatsPlace";
import DepSeatsGroup from "@/views/Widgets/components/forWidget/DepSeatsGroup.vue";
import DepServiceDuration from "@/views/Widgets/components/forWidget/DepServiceDuration.vue";
import DepTarifSeats from "@/views/Widgets/components/forWidget/DepTarifSeats.vue";
import GroupLimitType from "@/views/Widgets/components/forWidget/GroupLimitType.vue";
import RentDepTime from "@/views/Widgets/components/forWidget/RentDepTime.vue";
import RentDepTimeSeats from "@/views/Widgets/components/forWidget/RentDepTimeSeats.vue";
import PayWidget from "@/views/Widgets/components/PayWidget.vue";
import PayError from "@/views/Widgets/components/PayError.vue";
import { setFavicon } from "@/helpers/forFavicon.js";
import { AJAX_GET, AJAX_POST } from "../../../units/ajax";

export default {
  name: "ScheduleWidgetSingleForm",
  components: {
    FunctionalCalendar,
    NotLimitSeats,
    DepSeatsPlace,
    DepSeatsGroup,
    DepServiceDuration,
    DepTarifSeats,
    GroupLimitAll,
    GroupLimitType,
    GroupLimitGeneral,
    RentDepTime,
    RentDepTimeSeats,
    PayWidget,
    PayError,
  },
  props: {
    isMaket: Boolean,
    showCalendar: Boolean,
    serviceId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      fieldForm: [
        "maskRussian",
        "fieldFamaly",
        "fieldEmail",
        "fieldComment",
        "fieldPromo",
      ],
      dis: null,
      loading: false,
      isShowPay: false,
      isLoading: true,
      selectTime: "",
      listTime: [],
      selectCell: {},
      selectTickets: null,
      selectService: null,
      selectTariff: null,
      person: 1,
      price: null,
      date: null, // new Date(),
      addOptCheck: [],
      addOptInput: [],
      workDate: [],
      pickerOptions: {
        disabledDate: (time) => {
          // this.workDate.forEach((dat) => {
          time = moment(time).format("DD-M-YYYY");
          return !this.workDate.includes(time);
          // return moment(time).format("DD-M-YYYY") < dat;
          // });
        },
      },
      calendar: {
        newStartDay: "",
        currentDate: new Date(),
        selectedDate: false,
        selectedDateTime: false,
        selectedHour: "00",
        selectedMinute: "00",
        selectedDatesItem: "",
        selectedDates: [],
        dateRange: { start: "", end: "" },
        multipleDateRange: [],
      },
      calendarConfigs: {
        dateFormat: "yyyy-mm-dd",
        limits: null,
        disabledDates: [],
        isMultiple: true,
        calendarsCount: 2,
        isDatePicker: true,
        isMultipleDatePicker: true,
        dayNames: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
        monthNames: [
          "Январь",
          "Февраль",
          "Март",
          "Апрель",
          "Май",
          "Июнь",
          "Июль",
          "Август",
          "Сентябрь",
          "Октябрь",
          "Ноябрь",
          "Декабрь",
        ],
      },
      analyticsYandex: null,
      analyticsGoogle: null,
    };
  },
  computed: {
    ...mapState("service", ["schedule"]),
    ...mapState("widget", [
      "widget",
      "widgetPublic",
      "dataPay",
      "slotsValidity",
    ]),
    ...mapState("schedule", ["scheduleCalendarDate"]),
    ...mapGetters("widget", ["getFaviconLicense", "someWidgetDesign", "isNew"]),
    ...mapGetters("company", ["getCategory"]),
    ...mapGetters("service", ["isHaveAddOpt", "addPristavka"]),

    service() {
      if (!this.isMaket) {
        return this.widgetPublic?.resources[0];
      }
      return {
        BookingType: {
          booking_type: "individual",
          booking_type_sub: "individual_does_not_depend_on_the_number_of_seats",
        },
        active: true,
        additional_options: [],
      };
    },
    isCorrectDate() {
      const dateCorrect = moment(this.date.selectedDate).format("DD.MM.YYYY");
      return dateCorrect === "Invalid date"
        ? this.date.selectedDate.replace(/\-/g, ".")
        : moment(this.date.selectedDate).format("DD.MM.YYYY");
    },
    showHeaderAddOptions() {
      return this.listAddOptCheck.length || this.addOptInput.length;
    },
    custom() {
      const designWidgetParams = this.widgetPublic.design ?? this.widget.design;
      return {
        ...designWidgetParams,
        // ...this.widgetPublic.design,
      };
    },
    isIndividualTheme() {
      // УБРАЛ ДЛЯ КАСТОМИЗАЦИИ ВИДЖЕТА ВНЕ ЗАВИСИМОСТИ ОТ  ТЕМЫ
      // this.widgetPublic.typeTheme === "individual"
      return !!this.widgetPublic.typeTheme || this.isMaket;
    },
    errorMessage() {
      return (
        this.dataPay.msg ||
        (this.dataPay.params?.error_title ?? "Попробуйте забронировать позже")
      );
    },
    isGroup() {
      return (
        this.service.BookingType.booking_type_sub ===
          "group_same_seat_limit_for_all_sessions" ||
        this.service.BookingType.booking_type_sub ===
          "group_the_seat_limit_is_set_for_each_sess" ||
        this.service.BookingType.booking_type_sub ===
          "individual_depends_on_the_number_of_seats"
      );
    },
    isIndividual() {
      return (
        this.service.BookingType.booking_type_sub ===
          "individual_does_not_depend_on_the_number_of_seats" ||
        (this.service.BookingType.booking_type_sub ===
          "individual_depends_on_the_number_of_seats" &&
          this.service.BookingType.data.price === "for_a_place")
      );
    },
    originData() {
      if (!this.isMaket) {
        switch (this.service.BookingType.booking_type_sub) {
          case "individual_does_not_depend_on_the_number_of_seats":
            return {
              component: NotLimitSeats,
              priceAfter: "",
              priceBefore: "",
              isHidePrice: true,
              totalPrice: (srcObj) => srcObj.selectCell.price,
            };
          case "individual_depends_on_the_number_of_seats":
            // eslint-disable-next-line no-case-declarations
            const isForPlace =
              this.service.BookingType.data.price === "for_a_place";

            return {
              component: isForPlace ? DepSeatsPlace : DepSeatsGroup,
              priceAfter: "от",
              priceBefore: isForPlace ? "за участника" : "за группу",
              isHidePrice: true,
              totalPrice: (srcObj) => {
                if (isForPlace)
                  return (
                    srcObj.person * srcObj.price
                    //  +
                    // srcObj.selectCell?.price_additional
                  );
                // eslint-disable-next-line no-nested-ternary
                return srcObj.group
                  ? srcObj.selectCell.is_holiday
                    ? srcObj.group.price +
                      srcObj.addPersons * srcObj.priceAddPersons +
                      srcObj.selectCell.weekend_price_additional
                    : // srcObj.selectCell?.weekend_price_additional +
                      // srcObj.selectCell?.weekend_price_additional
                      srcObj.group.price +
                      srcObj.addPersons * srcObj.priceAddPersons +
                      // srcObj.selectCell?.weekend_price_additional +
                      srcObj.selectCell?.price_additional
                  : 0;
              },
            };
          case "individual_depends_on_the_service_and_its_duration":
            return {
              component: DepServiceDuration,
              priceAfter: "",
              priceBefore: "",
              isHidePrice: false,
              // totalPrice: (srcObj) => srcObj.person * srcObj.price,
              totalPrice: (srcObj) =>
                srcObj.selectService?.price || srcObj.price,
            };
          case "individual_depends_on_tariff_and_number_of_seats":
            return {
              component: DepTarifSeats,
              priceAfter: "от",
              priceBefore: "",
              isHidePrice: true,
              totalPrice: (srcObj) => srcObj.person * srcObj.price,
            };
          case "group_same_seat_limit_for_all_sessions":
            return {
              component:
                this.service.BookingType.data.seat_limit === "general_limits"
                  ? GroupLimitGeneral
                  : GroupLimitAll,
              priceAfter: "от",
              priceBefore: "",
              isHidePrice: true,
              totalPrice: (srcObj) => {
                return srcObj.selectTickets
                  ? srcObj.selectTickets.reduce((sum, current) => {
                      return sum + current.select_ticket * current.price;
                    }, 0)
                  : 0;
              },
            };
          case "group_the_seat_limit_is_set_for_each_session":
            return {
              component: GroupLimitType,
              priceAfter: "от",
              priceBefore: "",
              isHidePrice: true,
              totalPrice: (srcObj) => {
                return srcObj.selectTickets
                  ? srcObj.selectTickets.reduce((sum, current) => {
                      return sum + current.select_ticket * current.price;
                    }, 0)
                  : 0;
              },
            };
          case "rent_depends_only_on_time":
            return {
              component: RentDepTime,
              priceAfter: "от",
              priceBefore: "за час",
              isHidePrice: true,
              totalPrice: (srcObj) => srcObj.price * srcObj.hours,
            };
          case "rent_depends_on_time_and_place":
            return {
              component: RentDepTimeSeats,
              priceAfter: "от",
              priceBefore: "за место в час",
              isHidePrice: true,

              totalPrice: (srcObj) => {
                const isProcent = srcObj?.discount?.unit === "procent";
                const isDiscount =
                  srcObj?.discount && isProcent
                    ? (srcObj?.price * srcObj?.discount?.discount) / 100
                    : srcObj?.discount?.discount;
                const isHoliday = this.selectCell.is_holiday;

                return isDiscount
                  ? (isHoliday
                      ? srcObj.price -
                        //  +
                        //   this.selectCell.weekend_price_additional
                        isDiscount
                      : srcObj.price -
                        // +
                        //   this.selectCell.price_additional
                        isDiscount) *
                      srcObj.places *
                      srcObj.hours
                  : srcObj.price;

                // (srcObj.price - isDiscount) * srcObj.hours * srcObj.places +
                // Number(isDiscount)
              },
              // srcObj.price * (srcObj.hours * srcObj.places)),
            };
          default:
            return {};
        }
      }
      return {
        component: NotLimitSeats,
        priceAfter: "",
        priceBefore: "",
        isHidePrice: true,
        totalPrice: (srcObj) => srcObj.selectCell.price,
      };
    },
    totalPrice() {
      const sumCheck = this.addOptCheck.reduce((sum, current) => {
        return sum + current.price;
      }, 0);
      const sumInput = this.addOptInput.reduce((sum, current) => {
        return sum + current.selected * current.price;
      }, 0);
      const isProcent = this.discount?.unit === "procent";
      const isDiscount =
        this.discount && isProcent
          ? (this.originData.totalPrice(this.$data) * this.discount.discount) /
            100
          : this.discount?.discount;
      return isDiscount
        ? this.originData.totalPrice(this.$data) - sumCheck + sumInput
        : this.originData.totalPrice(this.$data) + sumCheck + sumInput;
    },
    listAddOptCheck() {
      return this.service.additional_options.filter((item) => !item.amount);
    },
    isDisabled() {
      return this.slotsValidity || !this.dis
        ? this.slotsValidity?.success
        : true;
    },
  },

  async created() {
    // this.date = new Date();
    this.getPayInfo();
    this.isLoading = true;
    this.listTime = [];
    // if (!this.isMaket) {
    //   this.calendar = this.date;
    //   // this.addOptInput = this.service.additional_options
    //   //   .filter((item) => item.amount)
    //   //   .map((el) => {
    //   //     return {
    //   //       ...el,
    //   //       selected: null,
    //   //     };
    //   //   });
    //   // this.setMetrics();

    //   if (this.$mq.desktop) this.calendarConfigs.calendarsCount = 1;
    // }
    if (this.$mq.desktop) this.calendarConfigs.calendarsCount = 1;
    await this.getTypesActivity();

    if (this.isMaket) {
      this.date = new Date();

      this.setField({
        field: "schedule",
        value: {
          data: { ...scheduleOneGrid },
        },
      });
    } else {
      await this.getScheduleCalendar({
        hash: this.service.hash,
        withSlots: true,
      });
      this.date = null; // new Date();
      await this.widgetGetScheduleByPeriodOrByDate({
        hash: this.service.hash,
        widgetHash: this.$route.params.hash,
        noPaginate: true,
      });
      setFavicon({ title: this.service.name, href: this.getFaviconLicense });
      this.addOptInput = this.service.additional_options
        .filter((item) => item.amount)
        .map((el) => {
          return {
            ...el,
            selected: null,
          };
        });
      this.setMetrics();
      if (this.schedule) {
        this.calendarConfigs.limits = {
          min: Object.keys(this.scheduleCalendarDate)[0], // this.schedule.period[0],
          max: this.schedule.period[1],
        };
        Object.keys(this.scheduleCalendarDate).forEach((key) => {
          this.workDate.push(moment(key).format("DD-M-YYYY"));
          if (key === this.date) {
            this.date = new Date(this.scheduleCalendarDate[key]);
            this.calendar.currentDate = this.date;
          } else {
            // eslint-disable-next-line prefer-destructuring
            this.date = new Date(Object.keys(this.scheduleCalendarDate)[0]);
            this.calendar.currentDate = this.date;
            this.date.selectedDate = this.date;
          }
        });
        const timeObj = Object.entries(this.scheduleCalendarDate).find(
          (e) => e[0] === moment(this.date).format("YYYY-MM-DD")
        );
        this.listTime.push(...timeObj[1]);
        // eslint-disable-next-line prefer-destructuring
        this.selectTime = this.listTime[0];

        this.setSelectSell(this.selectTime);

        this.schedule.data.forEach((e) => {
          if (e.date === moment(this.date).format("YYYY-MM-DD")) {
            this.selectCell = {
              date: e.date,
              currency: this.service.currency_symbol,
              ...e.value[0],
            };

            //  = obj;
            return this.selectCell;
          }
        });
      }
    }
    this.isClass();
    this.isLoading = false;
  },

  updated() {
    if (!this.isMaket) {
      this.postMessage(this.$refs.widget.clientHeight);
    }
  },
  methods: {
    ...mapMutations("service", ["setField"]),
    ...mapActions("service", [
      "getSchedule",
      "widgetGetScheduleByPeriodOrByDate",
    ]),
    ...mapActions("company", ["getTypesActivity"]),
    ...mapActions("widget", [
      "getWidgetScheduleForm",
      "saveBooking",
      "checkSlotsValidity",
    ]),
    ...mapActions("schedule", ["getScheduleCalendar"]),
    setMetrics() {
      if (
        this.widgetPublic.analytics_yandex &&
        this.widgetPublic.analytics_google
      ) {
        this.analyticsYandex = this.widgetPublic.analytics_yandex;
        this.analyticsGoogle = this.widgetPublic.analytics_google;
      }
    },
    isClass() {
      document.body.style.setProperty(
        "--colorCustom",
        `${this.custom.active_text_color}`
      );
      document.body.style.setProperty(
        "--colorCustomBackground",
        `${this.custom.inactive_text_color}`
      );
      document.body.style.setProperty(
        "--colorCustomButton",
        `${this.custom.active_buttons_background}`
      );
      document.body.style.setProperty(
        "--colorCustomTextInactive",
        `${this.custom.active_buttons_background}`
      );
      if (this.custom.background_cells_fields) {
        document.body.style.setProperty(
          "--colorCustomInput",
          `${this.custom.background_cells_fields}`
        );
      } else {
        document.body.style.setProperty(
          "--colorCustomInput",
          "rgba(255, 255, 255, 0.1)"
        );
      }

      document.body.style.setProperty(
        "--colorCustomBlockBackground",
        `${this.custom.block_background}`
      );
    },

    setSelectSell(eTime) {
      this.schedule.data.forEach((e) => {
        if (
          // (e.date === moment(this.date.selectedDate).format("YYYY-MM-DD"))
          e.date === moment(this.date).format("YYYY-MM-DD")
          //  ||
          // moment(this.date).format("YYYY-MM-DD")
        ) {
          const asd = e.value.find((q) => q.time === eTime);
          this.selectCell = {
            date: e.date,
            currency: this.service.currency_symbol,
            ...asd,
          };
          return this.selectCell;
        }
      });
    },
    clickCalendar(param) {
      this.listTime = [];
      this.date = param.date ? moment(param.date).format("YYYY-MM-DD") : param;
      let timeObj = null;

      if (this.date === "Invalid date") {
        this.date = moment(param.date, "YYYY-MM-DD").format("L");
        timeObj = Object.entries(this.scheduleCalendarDate).find(
          (e) => moment(e[0]).format("L") === this.date
        );
      } else {
        this.date = moment(param.date ?? param).format("YYYY-MM-DD");
        timeObj = Object.entries(this.scheduleCalendarDate).find(
          (e) => e[0] === moment(this.date).format("YYYY-MM-DD")
        );
      }
      this.listTime.push(...timeObj[1]);
      this.schedule.data.forEach((e) => {
        if (e.date === moment(this.date).format("YYYY-MM-DD")) {
          this.selectTime =
            this.listTime.find((time) => time.includes(this.selectTime)) ??
            this.listTime[0];
          this.selectCell = {
            date: e.date,
            currency: this.service.currency_symbol,
            ...e.value[0],
          };
          //   this.date.selectedDate = this.date;

          return this.selectCell;
        }
      });

      this.getSlotParam({
        resourceHash: this.selectCell.resource_id,
        date: this.date,
        time: this.selectTime,
      });
      this.setParamForCheck({
        dateParam: this.date,
        timeParam: this.selectTime,
      });
    },

    setTime(eTime) {
      // this.getSlotParam({
      //   resourceHash: this.selectCell.resource_id,
      //   date: this.date,
      //   time: eTime, // this.selectTime,
      // });

      // this.setParamForCheck({
      //   dateParam: this.date,
      //   timeParam: eTime, // this.selectTime,
      // });

      // this.setSelectSell(eTime);
      // this.schedule.data.forEach((e) => {
      //   if (e.date === moment(this.date.selectedDate).format("YYYY-MM-DD")) {
      //     const asd = e.value.find((q) => q.time === eTime);
      //     this.selectCell = {
      //       date: e.date,
      //       currency: this.service.currency_symbol,
      //       ...asd,
      //     };

      //     return this.selectCell;
      //   }
      // });
      const e = {
        dateParam: this.showCalendar
          ? moment(this.selectCell.date).format("YYYY-MM-DD")
          : moment(this.date).format("YYYY-MM-DD"),
        timeParam: this.selectTime,
        timeId: "",
      };
      // const findDate = this.schedule.data
      //   .find((el) => el.date === e.dateParam)
      //   .value.find((slotId) => slotId.time === e.timeParam).id;

      // this.$refs.compChild.changePersons({ ...e });
      this.setParamForCheck({
        dateParam: this.showCalendar
          ? moment(this.selectCell.date).format("YYYY-MM-DD")
          : moment(this.date).format("YYYY-MM-DD"),
        timeParam: eTime, // this.selectTime,
      });
      // if (
      //   this.service.BookingType.booking_type_sub !==
      //   "individual_does_not_depend_on_the_number_of_seats"
      // ) {
      //   this.getSlotParam({
      //     resourceHash: this.selectCell.resource_id,
      //     date: e.dateParam || this.date,
      //     time: this.selectTime,
      //   });
      // }
    },
    setParamForCheck({ dateParam, timeParam }) {
      this.$refs.compChild.emitData({
        dateParam,
        timeParam,
      });
    },
    setDiscount(discount) {
      this.discount = discount;
    },
    setRent(rent) {
      this.rent = rent;
    },
    setTariff(tariff) {
      this.selectTariff = { ...tariff };
    },
    setTickets(tickets) {
      this.selectTickets = [...tickets];
    },
    getSlotParam(obj) {
      //    this.price = obj?.book_info?.individual?.price;
      this.checkSlotsValidity(obj);
    },
    setRentInfo(info) {
      this.rentPlacesInfo = info;
    },
    setPrice(price) {
      const addCoast = this.selectCell.is_holiday
        ? this.selectCell.weekend_price_additional
        : this.selectCell.price_additional;
      this.price = price + addCoast;
    },
    setForGroup(obj) {
      this.price = null;
      this.person = null;
      this.group = obj.group;
      this.addPersons = obj.addPersons;
      this.priceAddPersons = obj.priceAddPersons;
    },

    isDis(item) {
      this.dis = !!item;
    },
    isLoadingEmit(load) {
      this.loading = load;
    },
    async broni(clientInfo) {
      clientInfo.add_params = [];
      if (this.analyticsYandex && this.analyticsGoogle) {
        const dinamicId = this.analyticsYandex;
        const dinamicIdGoogle = this.analyticsGoogle;
        ym(`${dinamicId}`, "init", {
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
          trackHash: true,
        });
        ym(`${dinamicId}`, "reachGoal", "slotClick");
        gtag("config", `${dinamicIdGoogle}`);
        gtag("event", "select_content", {
          event_category: "bookingCell",
          event_action: "clickSlot",
          event_label: "booking",
        });
      }

      const data = {
        visitor_id: this.widgetPublic?.visitor_id,
        analyticsYandex: this.analyticsYandex,
        resource_id: this.selectCell.resource_id,
        schedule_id: this.schedule.data
          .find((el) => el.date === this.selectCell.date)
          .value.find((slotId) => slotId.time === this.selectTime).id, // this.selectCell.id,
        // eslint-disable-next-line no-nested-ternary
        date: this.showCalendar
          ? moment(this.date.selectedDate).format("DD.MM.YYYY") ===
            "Invalid date"
            ? moment(this.date.selectedDate, "YYYY-MM-DD").format("L")
            : moment(this.date.selectedDate).format("DD.MM.YYYY")
          : moment(this.date).format("YYYY-MM-DD"),
        time: this.selectTime,
        client_info: clientInfo,
        widget_hash: this.$route.params.hash,
        book_info: {
          totalPriceDiscount: clientInfo.promocode ? this.totalPrice : null,
          individual: this.isIndividual
            ? {
                price: this.price,
                persons: this.person,
              }
            : null,
          //  price: this.price,
          // person: this.person,
          total_price: clientInfo.promocode
            ? clientInfo.totalPriceDiscount
            : this.totalPrice,

          add_options_check: this.addOptCheck,
          add_options_input: this.addOptInput,
          tariff: this.selectTariff,
          service: this.selectService,
          tickets: this.selectTickets,
          rent: !this.isGroup ? { ...this.rent } : null,
          group:
            this.isGroup &&
            this.service.BookingType.data.price !== "for_a_place" //  &&
              ? // this.service.BookingType.booking_type_sub !==
                //   "group_same_seat_limit_for_all_sessions"
                {
                  ...this.group,
                  add_persons: this.addPersons,
                  price_add_persons: this.priceAddPersons,
                }
              : null,
        },
      };

      this.loading = true;
      await this.saveBooking(data);

      this.loading = false;
      this.isShowForm = false;
      this.isShowPay = true;
    },
    async getPayInfo() {
      const param = this.$route.query || this.$route.params;
      const keyPay = ["amp;success"];
      if (param.success || param[keyPay]) {
        await AJAX_GET("/api/v1/booking/get", {
          params: { booking_id: param.hash },
        }).then((response) => {
          this.setField({ field: "dataPay", value: response.data });
          this.dataPay.hidden = true;
          this.dataPay.success = response.data.is_paid;
          this.dataPay.name = response.data.name;
          this.dataPay.dateTime = response.data.date;
          this.dataPay.dateAndTime = response.data.time;
          this.dataPay.phone = response.data.phone;
          this.dataPay.price = response.data.final_price;
          this.dataPay.address = response.data.address;
          this.dataPay.currency = response.data.currency;
          this.dataPay.payment = response.data.payment;
          this.dataPay.payment_type_name = response.data.payment_type_name;
          this.dataPay.modal_text = response.data.modal_text;
          this.dataPay.consist = response.data.consist;
          this.dataPay.payment_data = response.data.payment_data;
          this.dataPay.params = response.data;
          this.isShowPay = true;
          this.$router.push({ name: "public-widget" });
        });
      }
    },
    reloadWidget() {
      this.$emit("reloadWidget");
      this.widgetGetScheduleByPeriodOrByDate({
        hash: this.service.hash,
        widgetHash: this.$route.params.hash,
      });

      // this.getSchedule({
      //   hash: this.service.hash,
      //   widgetHash: this.$route.params.hash,
      // });
    },
    postMessage(height) {
      const win = window.parent;
      win.postMessage({ hash: this.widgetPublic.hash, height }, "*");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";
:root {
  --colorCustom: "black";
  --colorCustomTextInactive: "black";
  --colorCustomBackground: "green";
  --colorCustomButton: "yellow";
  --colorCustomInput: "white";
  --colorCustomBlockBackground: "white";
}
.form {
  padding: 30px 30px 20px;
  margin-bottom: 20px;
  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: $--color-white;
    margin-bottom: 5px;
  }
  &__sub-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 40px;
  }
  &__row {
    display: flex;
    flex-direction: row;
    margin: 0 -20px;
    &-column {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding: 0 20px;

      .hl {
        margin: 25px 0 25px;
      }
      .qwerty {
        flex-grow: 1;
        margin: 0;
      }
    }
  }
}
.form {
  margin-bottom: 0 !important;
}
.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  &__info {
    display: flex;
    flex-direction: column;
    &-title {
      color: $--color-white-5;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
    }
    &-content {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }
  }
  &__button {
    margin-left: 20px;
  }
}

::v-deep {
  .el-input {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .vfc-calendar {
    border: 1px solid $--color-sm-white;
    &:nth-last-child(1) {
      border-radius: 0 3px 3px 0;
    }
    &:nth-last-child(2) {
      border-left: 1px solid rgba(255, 255, 255, 0.2);
      // border-right: 1px solid rgba(255, 255, 255, 0.2);
    }
    &:nth-last-child(3) {
      border-radius: 3px 0 0 3px;
    }
  }
}
::v-deep
  .customPoper.el-date-editor.el-input.el-input--prefix.el-input--suffix.el-date-editor--date
  > input {
  color: var(--colorCustom);
  background: var(--colorCustomInput);
  font-weight: 600;
}
::v-deep
  .customPoper.el-date-editor.el-input.el-input--prefix.el-input--suffix.el-date-editor--date
  > .el-input__prefix {
  color: var(--colorCustom);
  background: transparent; // var(--colorCustomInput);
}
::v-deep .customPoperSelec.el-select .el-input--suffix > .el-input__inner {
  color: var(--colorCustom);
  background: var(--colorCustomInput);
}
::v-deep .el-select__caret.el-input__icon.el-icon-arrow-up {
  background: var(--colorCustomInput);
  color: var(--colorCustom);
}

.custom-style.single-grid {
  .el-input__inner {
    color: var(--colorCustom);
  }
  .el-input-number__increase,
  .el-input-number__decrease {
    background: var(--colorCustomBackground);
    color: var(--colorCustom);
  }
  ::v-deep .el-input-number__increase:hover,
  ::v-deep .el-input-number__decrease:hover {
    color: var(--colorCustomButton);
  }
  ::v-deep .el-input {
    color: var(--colorCustom);
  }
}
.el-checkbox.is-checked.custom-style.single-grid
  > .el-checkbox__input.is-checked
  > .el-checkbox__inner {
  //::v-deep .el-checkbox__input {
  border-color: var(--colorCustomButton);
  color: var(--colorCustom);
  //}
}
.el-checkbox.custom-style.single-grid
  > .el-checkbox__input
  > .el-checkbox__inner {
  //::v-deep .el-checkbox__input {
  border-color: var(--colorCustom);
  color: var(--colorCustom);
  //}
}
.form__bron {
  max-width: 800px;
}

::v-deep.el-select-dropdown__item.customPoperSelec {
  background: var(--colorCustomInput);
  color: var(--colorCustom);
}

//::v-deep .el-select-dropdown .el-popper .customSelect > div {
//  color: red !important;
//}
//.el-select-dropdown__list .customSelect {
//  padding: 0px !important;
//}

::v-deep .input-check__icon {
  height: 40px;
}
::v-deep .vfc-top-date > span {
  color: var(--colorCustom);
}
::v-deep .vfc-dayNames > span {
  color: var(--colorCustom);
}
::v-deep .vfc-arrow-right,
::v-deep .vfc-arrow-left {
  border-color: var(--colorCustom) !important;
}
::v-deep .vfc-calendar {
  border-color: var(--colorCustomInput) !important;
}
::v-deep .vfc-disabled {
  border-color: var(--colorInactive) !important;
}
::v-deep .el-icon-circle-close {
  color: var(--colorCustom);
}
::v-deep .el-input__inner::placeholder {
  color: var(--colorInactive);
}
::v-deep .el-form-item__content {
  height: 40px;
}
::v-deep .el-checkbox__label {
  display: flex;
  flex-direction: column;
}
</style>
