var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custom-block form",style:({
    background: _vm.widgetTheme ? _vm.customClass.block_background : '',
    color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
  })},[_c('svg-icon',{staticClass:"form__icon",attrs:{"width":"40","height":"40","icon-name":"close-cicle"},on:{"click":function($event){return _vm.$emit('close')}}}),(_vm.errorTitle)?_c('div',{staticClass:"title-widget",style:({
      color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
    })},[_vm._v(" "+_vm._s(_vm.errorTitle)+" ")]):_c('div',{staticClass:"title-widget",style:({
      color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
    })},[_vm._v(" Что то пошло не так ")]),_c('p',{staticClass:"form__text",style:({
      color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
    })},[_vm._v(" "+_vm._s(_vm.msg)+" ")]),(
      (_vm.dataPay && _vm.dataPay.error_msg) ||
      (_vm.dataPay && _vm.dataPay.payment_error_text)
    )?_c('p',{staticClass:"form__text text-center",style:({
      color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
    })},[_vm._v(" "+_vm._s(_vm.dataPay.error_msg || _vm.dataPay.payment_error_text)+" ")]):_vm._e(),_c('el-button',{style:({
      background: _vm.widgetTheme
        ? _vm.customClass.active_buttons_background
        : '#FEBB17',
      color: _vm.widgetTheme ? _vm.customClass.button_text_color : 'black',
    }),on:{"click":_vm.getAction}},[_vm._v(_vm._s(("" + (_vm.dataPay ? _vm.dataPay.payment_error_button_text : "Хорошо"))))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }