<template>
  <el-form hide-required-asterisk label-position="top">
    <h4 v-if="!isOne" class="title">Для администраторов</h4>
    <ListForAdmin
      v-model="phone"
      popover-text="Укажите один или несколько номеров, на которые будут приходить СМС-уведомления о бронировании"
      title="Номер для SMS-уведомлений "
      title-for-line="Дополнительный номер телефона"
      title-button="Добавить номер телефона"
      is-phone
      placeholder="+7 (999) 999-99-99"
      class="w-limit"
      :suggestions="
        notifAdmin ? notifAdmin.available.phone.filter((item) => item) : []
      "
    />
    <ListForAdmin
      v-model="email"
      is-email
      popover-text="Укажите один или несколько адресов, на которые будут приходить уведомления о бронировании"
      title="Уведомления на E-mail"
      title-for-line="Дополнительный E-mail"
      placeholder="example@mail.ru"
      title-button="Добавить адрес почты"
      class="last-child w-limit"
      :suggestions="
        notifAdmin ? notifAdmin.available.email.filter((item) => item) : []
      "
    />
    <ListForAdmin
      v-if="isUBRR"
      v-model="ubrirCsv"
      is-email
      popover-text="На данный электронный адрес в 03:00 по МСК будет направляться отчёт с заказами за прошедший день в формате CSV"
      title="E-mail для отчета SCV"
      title-for-line="E-mail для отчета SCV"
      placeholder="example@mail.ru"
      title-button="Добавить адрес почты"
      :add-field-disabled="true"
      class="last-child w-limit"
      :suggestions="
        notifAdmin ? notifAdmin.available.ubrir_csv.filter((item) => item) : []
      "
    /><el-button
      v-if="isOne"
      type="primary"
      class="custom-button"
      @click="submit()"
    >
      Сохранить
    </el-button>
    <div v-if="isOne" class="hl"></div>

    <div>
      <switch-custom
        v-model="admin_sms_booking"
        title="Отправлять SMS сотрудникам при бронировании?"
      >
        Отметьте, если требуется отправлять SMS сотрудникам при бронировании
        через виджеты.
      </switch-custom>
      <switch-custom
        v-model="admin_sms_editing"
        title="Отправлять SMS сотрудникам при редактировании?"
      >
        Отметьте, если требуется отправлять SMS сотрудникам при бронировании
        через виджеты.
      </switch-custom>
      <switch-custom
        v-model="admin_sms_canceling"
        title="Отправлять SMS сотрудникам при отмене бронирования?"
      >
        Отметьте, если требуется отправлять SMS сотрудникам при бронировании
        через виджеты.
      </switch-custom>
      <switch-custom
        v-model="admin_sms_reminder"
        title="Отправлять напоминание сотрудникам в SMS?"
      >
        Отметьте, если хотите, чтобы сотрудникам приходили напоминания о
        бронировании.
      </switch-custom>
      <template v-if="admin_sms_reminder">
        <div class="checkbox-group">
          <el-checkbox v-model="admin_notification_day">
            Отправлять уведомление за 1 сутки
          </el-checkbox>
          <el-checkbox v-model="admin_notification_manual">
            Отправлять уведомление за {{ admin_duration || "N" }}
            {{ selectUnitTime }}
          </el-checkbox>
        </div>
        <InputSelect
          v-if="admin_notification_manual"
          v-model="admin_duration"
          label="Значение"
          required
          placeholder="Введите значение"
          :list-value="listDuration"
          :select-value="admin_time_unit"
          @setValue="admin_time_unit = $event"
        ></InputSelect>
      </template>
    </div>
    <div v-if="isOne" class="hl"></div>
    <el-button
      v-if="isOne"
      type="primary"
      class="custom-button"
      @click="submit('sms')"
    >
      Сохранить
    </el-button>
  </el-form>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import ListForAdmin from "@/components/Services/ListForAdmin.vue";
import { listDuration } from "@/helpers/services.js";

export default {
  name: "ForAdmin",
  components: { ListForAdmin },
  props: {
    isOne: Boolean,
  },
  data() {
    return {
      phone: [null],
      email: [null],
      ubrirCsv: [null],
      tgCode: "",
      admin_sms_booking: true,
      admin_sms_canceling: true,
      admin_sms_changeofstatus: false,
      admin_sms_editing: true,
      admin_sms_paid: false,
      admin_sms_reminder: false,
      admin_sms_withpayment: true,
      admin_notification_day: true,
      admin_notification_manual: true,
      admin_duration: "60",
      admin_time_unit: "minutes",

      keys: [
        "admin_sms_booking",
        "admin_sms_canceling",
        "admin_sms_changeofstatus",
        "admin_sms_editing",
        "admin_sms_paid",
        "admin_sms_reminder",
        "admin_sms_withpayment",
        "admin_notification_day",
        "admin_notification_manual",
        "admin_duration",
        "admin_time_unit",
      ],
    };
  },
  computed: {
    ...mapState("service", ["notifAdmin", "service", "notifClient"]),
    ...mapGetters("user", ["isUBRR"]),
    selectUnitTime() {
      return this.listDuration.find(
        (item) => item.value === this.admin_time_unit
      ).label;
    },
  },
  created() {
    this.listDuration = listDuration;
    if (this.notifClient) this.setAdminData();
  },
  methods: {
    ...mapActions("service", [
      "saveNotifAdmin",
      "saveNotifAdminSms",
      "updateTgCode",
    ]),

    setAdminData() {
      if (this.notifAdmin && this.notifAdmin.current.phone.length)
        this.phone = [...this.notifAdmin.current.phone];
      if (this.notifAdmin && this.notifAdmin.current.email.length)
        this.email = [...this.notifAdmin.current.email];
      if (this.notifAdmin && this.notifAdmin.current.ubrir_csv.length)
        this.ubrirCsv = [...this.notifAdmin.current.ubrir_csv];

      Object.keys(this.notifClient).forEach((element) => {
        if (this.keys.includes(element)) {
          this.$data[element] = this.notifClient[element];
        }
      });
    },
    async submit(key) {
      if (!key)
        await this.saveNotifAdmin({
          data: {
            phone: this.phone,
            email: this.email,
            ubrir_csv: this.ubrirCsv,
          },
        });
      if (key)
        await this.saveNotifAdminSms({
          dataSms: {
            admin_sms_booking: this.admin_sms_booking,
            admin_sms_editing: this.admin_sms_editing,
            admin_sms_canceling: this.admin_sms_canceling,
            admin_sms_changeofstatus: this.admin_sms_changeofstatus,
            admin_sms_paid: this.admin_sms_paid,
            admin_sms_reminder: this.admin_sms_reminder,
            admin_sms_withpayment: this.admin_sms_withpayment,
            admin_notification_day: this.admin_notification_day,
            admin_notification_manual: this.admin_notification_manual,
            admin_duration: this.admin_duration,
            admin_time_unit: this.admin_time_unit,
          },
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.title {
  font-size: 22px;
  line-height: 44px;
  font-weight: 600;
}
::v-deep .wrapper-is {
  width: 300px;
  &__title {
    margin-bottom: 8px;
  }
}

.hint {
  padding-bottom: 12px;
}

.last-child {
  margin-bottom: 20px;
}

.input-button {
  display: flex;
  align-items: center;
  &::v-deep .el-form-item {
    flex-grow: 1;
  }
  &::v-deep .el-button {
    margin-left: 20px;
    flex-shrink: 1;
  }
}

.w-limit {
  width: 60%;
}

@screen lg {
  .title {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 28px;
  }
  ::v-deep .wrapper-is {
    width: 100%;
  }
  .w-limit {
    width: 100%;
  }
}
</style>
