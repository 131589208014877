<template>
  <div v-loading="scheduleIsLoading" class="schedule-content">
    <div
      v-for="(item, index) in schedule"
      :key="index"
      class="schedule-content-item mb-40"
    >
      <div
        class="flex items-center mb-20 lg:mb-15 schedule-content-item__heading"
      >
        <div>
          <div
            class="text-16 leading-28 font-semibold"
            :class="[
              {
                'text-yellow-100':
                  scheduleWeekday(item.weekDay) === 'суббота' ||
                  scheduleWeekday(item.weekDay) === 'воскресенье',
              },
              theme === 'dark' || theme === 'custom'
                ? 'text-white-normal'
                : 'text-black',
            ]"
          >
            {{ scheduleDay(item.name) }}
          </div>
          <div
            class="text-12 leading-16"
            :class="
              theme === 'dark' || theme === 'custom'
                ? 'text-white-500'
                : 'text-gray-400'
            "
          >
            {{ scheduleWeekday(item.weekDay) }}
          </div>
        </div>
      </div>
      <div class="flex flex-wrap">
        <div v-if="!item.slots.length" class="text-12">Нет расписания</div>
        <div
          v-for="(slot, i) in item.slots"
          :key="i"
          class="schedule-item mr-7 flex flex-col items-center mb-17"
        >
          <div
            class="flex items-center justify-center font-semibold w-65 h-35 rounded-sm mb-5 cursor-pointer"
            :class="
              theme === 'dark'
                ? 'bg-white-150 text-white-normal'
                : theme === 'white'
                ? 'bg-gray-60 text-black'
                : 'bg-white-200 text-white-normal'
            "
          >
            {{ slot.time }}
          </div>
          <span
            class="text-12"
            :class="
              theme === 'dark'
                ? 'text-white-500'
                : theme === 'white'
                ? 'text-gray-400'
                : 'text-white-500'
            "
            >{{ slot.price }} {{ slot.currency }}</span
          >
        </div>
      </div>
    </div>

    <template v-if="JSON.stringify(schedule) === '{}'">
      <div class="text-16">Нет расписания</div>
    </template>

    <div class="flex mb-30">
      <div>
        <el-button
          type="info"
          size="small"
          class="schedule-nav-btn text-14 px-20 lg:px-14 xs:px-10"
          :style="
            theme === 'white' ? 'background-color: #B357D8; border: 0' : ''
          "
          :disabled="currentSchedulePage === 1"
          @click="loadScheduleWeekTo('prev')"
        >
          <div class="flex items-center">
            <svg-icon
              icon-name="arrow"
              class-name="text-white-normal mr-10 rotate-180"
              width="13"
              height="8"
            ></svg-icon
            >{{ $mq.tablet ? "Пред. неделя" : "Предыдущая неделя" }}
          </div></el-button
        >
      </div>

      <div v-if="currentSchedulePage !== lastSchedulePage" class="ml-auto">
        <el-button
          type="info"
          size="small"
          class="schedule-nav-btn text-14 px-20 lg:px-14 xs:px-10"
          :style="
            theme === 'white' ? 'background-color: #B357D8; border: 0' : ''
          "
          @click="loadScheduleWeekTo('next')"
          ><div class="flex items-center">
            <svg-icon
              icon-name="arrow"
              class-name="text-white-normal mr-10"
              width="13"
              height="8"
            ></svg-icon
            >{{ $mq.tablet ? "След. неделя" : "Следующая неделя" }}
          </div></el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { AJAX_GET } from "../units/ajax";
import moment from "moment";

export default {
  name: "WidgetSingle",
  props: {
    theme: {
      type: String,
      default: "dark",
    },
    ids: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      scheduleIsLoading: false,
      currentSchedulePage: null,
      lastSchedulePage: null,
      schedule: {},
    };
  },
  computed: {},
  mounted() {
    this.getScheduleByDates({
      resourceId: this.$route.params.id || this.ids,
      page: 1,
    });
  },
  methods: {
    scheduleDay(date) {
      return date.format("DD MMMM");
    },
    changeLoadingStatus() {
      this.scheduleIsLoading = !this.scheduleIsLoading;
    },
    setScheduleByDate(response) {
      this.currentSchedulePage = response.current_page;
      this.lastSchedulePage = response.last_page;

      const schedule = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const key in response.data) {
        schedule.push({
          name: moment(key),
          weekDay: moment(key),
          id: Object.keys(response.data[key])[0],
          slots: [],
        });

        // eslint-disable-next-line no-restricted-syntax
        for (const jey in response.data[key]) {
          schedule[schedule.length - 1].slots = [];
          response.data[key][jey].forEach((slot) => {
            schedule[schedule.length - 1].slots.push({
              time: slot.time,
              price: slot.price,
              currency: "₽",
            });
          });
        }
      }

      this.schedule = schedule;
      this.scheduleIsLoading = false;
    },
    getScheduleByDates(payload) {
      this.changeLoadingStatus();

      AJAX_GET(
        `/api/schedule/get?ids=[${payload.resourceId}]&toDates=1&page=${payload.page}`,
        { noCancel: true }
      ).then((response) => {
        this.setScheduleByDate(response.data);
      });
    },
    scheduleWeekday(date) {
      return date.format("dddd");
    },
    loadScheduleWeekTo(direction) {
      if (direction === "next") {
        this.getScheduleByDates({
          resourceId: this.$route.params.id || this.ids,
          page: this.currentSchedulePage + 1,
        });
      } else if (direction === "prev") {
        this.getScheduleByDates({
          resourceId: this.$route.params.id || this.ids,
          page: this.currentSchedulePage - 1,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.schedule-content-item {
  display: grid;
  grid-template-columns: 124px auto;
  .schedule-content-item__heading {
    align-items: flex-start;
  }
}
</style>
