<template>
  <Block title="Сменить пароль">
    <el-form
      ref="formPasswordSettings"
      :model="items"
      :rules="rules"
      :hide-required-asterisk="true"
      label-position="top"
      class="password"
    >
      <el-row
        :gutter="!$mq.tablet ? 30 : 0"
        type="flex"
        class="password__inputs flex-wrap"
      >
        <el-col :span="$mq.tablet ? 24 : 8">
          <el-form-item class="mb-0 lg:mb-15" prop="current_password"
            ><span slot="label"
              >Старый пароль<span class="danger">*</span></span
            >
            <el-input
              v-model="items.current_password"
              placeholder="Введите старый пароль"
              type="password"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="$mq.tablet ? 24 : 8">
          <el-form-item class="mb-0 lg:mb-15" prop="password"
            ><span slot="label"
              >Новый пароль<span class="danger">*</span>
            </span>
            <el-input
              v-model="items.password"
              placeholder="Введите новый пароль"
              type="password"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="$mq.tablet ? 24 : 8">
          <el-form-item class="mb-0" prop="password_confirmation"
            ><span slot="label"
              >Повторите новый пароль<span class="danger">*</span>
            </span>
            <el-input
              v-model="items.password_confirmation"
              placeholder="Повторите новый пароль"
              type="password"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-button
        type="primary"
        :loading="loading"
        class="password__btn bg-transparent lg:w-full"
        @click="changePassword"
        >Сменить пароль
      </el-button>
    </el-form>
  </Block>
</template>

<script>
import Block from "./Block.vue";
import { AJAX_POST } from "../../units/ajax";
import { mapState, mapMutations } from "vuex";

export default {
  components: { Block },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Введите пароль"));
      } else if (value.length < 6) {
        callback(new Error("Минимальная длина пароля 6 символов"));
      } else {
        if (this.items.password_confirmation !== "") {
          this.$refs.formPasswordSettings.validateField(
            "password_confirmation"
          );
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Введите пароль повторно"));
      } else if (value.length < 6) {
        callback(new Error("Минимальная длина пароля 6 символов"));
      } else if (value !== this.items.password) {
        callback(new Error("Пароли не совпадают"));
      } else {
        callback();
      }
    };
    return {
      items: {
        current_password: "",
        password: "",
        password_confirmation: "",
      },
      loading: false,
      rules: {
        current_password: [
          {
            required: true,
            message: "Введите текущий пароль",
            trigger: ["change", "blur"],
          },
        ],
        password: [
          {
            required: true,
            validator: validatePass,
            trigger: ["change", "blur"],
          },
        ],
        password_confirmation: [
          {
            required: true,
            validator: validatePass2,
            trigger: ["change", "blur"],
          },
        ],
      },
    };
  },
  methods: {
    ...mapMutations(["setNotif"]),
    changePassword() {
      this.$refs.formPasswordSettings.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          await AJAX_POST("/api/me/change/password", this.items)
            .then((response) => {
              if (response.data.success) {
                this.setNotif({
                  title: `Пароль сохранен`,
                });
              } else {
                this.setNotif({
                  title: response.data.msg,
                  type: "error",
                });
              }

              this.$refs.formPasswordSettings.resetFields();
            })
            .catch((err) => {
              this.setNotif({
                title: "Произошла ошибка, попробуйте позже",
                type: "error",
              });
            });
          this.loading = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.password {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  &__btn {
    width: 162px;
    height: 40px;
    margin-left: 30px;
  }
  &__inputs {
    width: calc(100% - 162px);
  }
}
@media (max-width: 1024px) {
  .password {
    flex-direction: column;
    align-items: flex-start;
    &__inputs {
      width: 100%;
    }
    &__btn {
      margin-top: 20px;
      margin-left: 0;
    }
  }
}
</style>
