<template>
  <el-radio-group v-model="item" class="flex sm:flex-col">
    <el-radio :value="0" :label="0" class="sm:mb-5">{{ text.first }}</el-radio>
    <el-radio :value="1" :label="1" class="sm:mb-5">{{ text.second }}</el-radio>
  </el-radio-group>
</template>

<script>
export default {
  props: {
    value: Number,
    text: {
      type: Object,
      default: () => ({
        first: "Использовать стандартные",
        second: "Задать свою тему и текст",
      }),
    },
  },
  computed: {
    item: {
      get() {
        return this.value;
      },
      set(num) {
        this.$emit("input", num);
      },
    },
  },
};
</script>
