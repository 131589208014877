<template>
  <div class="pt-40 pb-80 w-full max-w-lg">
    <div class="lg:px-15">
      <h1 class="text-36 font-semibold leading-48 inline-block mb-30">
        Ой! Ой! Ой!
      </h1>
      <h2 class="text-28 leading-44 font-semibold text-white-normal mb-20">
        А страницы-то такой у нас нет!
      </h2>
      <p class="text-white-500 mb-20">Код ошибки: 404</p>
      <router-link :to="{ name: 'orders' }">
        <el-button>Вернуться на главную</el-button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
};
</script>

<style scoped></style>
