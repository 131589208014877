const nameService = {
  title: "Название услуги",
  valid: true,
  validProp: "qwerty",
  placeholder: "Название услуги",
};

const durationService = {
  title: "Продолжительность услуги",
  valid: true,
  validProp: "qwerty",
  popover: false,
  placeholder: "Введите время",
};

const rangeSeats = {
  title: "Количество мест",
  valid: true,
  validProp: "qwerty",
  popover: true,
  popoverText:
    "Если будет достигнуто максимальное кол-во мест аренды, то время в расписании будет не доступно для бронирования.",
};

const basePrice = {
  title: "Базовая цена за место",
  valid: true,
  validProp: "qwerty",
  popover: true,
  popoverText:
    "Цена указывается за продолжительность услуги (размер шага). Если услуга бесплатная, укажите 0. Далее в генераторе расписания для каждого сеанса можно будет указать добавочную стоимость. ",
  placeholder: "Введите цену",
};

const holidayPrice = {
  title: "Цена в праздники",
  valid: false,
  popover: true,
  popoverText:
    "Если цена в праздничные дни, выпадающие на будние, отличается, то вы можете заполнить это поле, иначе оставьте его пустым.",
  placeholder: "Введите цену",
};

//----------------------
const indNotSeats = [
  { ...durationService },
  { ...rangeSeats },
  { ...basePrice, title: "Базовая цена за сеанс" },
  { ...holidayPrice },
];

const indSeat = [
  { ...rangeSeats, popover: false },
  {
    ...basePrice,
    popoverText:
      "Указывается базовая цена за место. Далее в генераторе расписания для каждого сеанса можно будет указать добавочную стоимость за место.",
  },
  { ...holidayPrice },
];

const indGroup = [
  { ...rangeSeats, popover: false },
  {
    ...basePrice,
    title: "Базовая цена за группу",
    popoverText:
      "Указывается базовая цена за группу. Далее в генераторе расписания для каждого сеанса можно будет указать добавочную стоимость за группу.",
  },
  { ...holidayPrice },
];

const indAddSeats = [
  { ...rangeSeats, title: "Количество доп. мест", popover: false },
  {
    ...basePrice,
    title: "Цена за доп. место",
    popover: false,
  },
  {
    ...holidayPrice,
    title: "Цена в праздники",
    popover: false,
  },
];

const indDuration = [
  { ...nameService },
  { ...durationService },
  {
    ...basePrice,
    title: "Базовая цена",
    popoverText:
      "Если услуга бесплатная, укажите 0. Далее в генераторе расписания можно будет указать минимальную стоимость сеанса.",
  },
  { ...holidayPrice },
];

const indTarif = {
  headers: [
    { ...nameService, title: "Название тарифа" },
    { ...durationService },
  ],
  forList: [
    { ...rangeSeats, popover: false },
    {
      ...basePrice,
      popover: true,
      popoverText:
        "Указывается базовая цена за группу. Далее в генераторе расписания для каждого сеанса можно будет указать добавочную стоимость за группу.",
    },
    { ...holidayPrice },
  ],
};

const groupLimit = {
  headers: [
    { ...durationService },
    {
      title: "Введите кол-во билетов",
      valid: true,
      validProp: "qwerty",
      placholder: "кол-во билетов",
    },
  ],
  forGeneral: [
    {
      ...nameService,
      title: "Название билета",
      placeholder: "Название билета",
    },
    { ...basePrice },
    { ...holidayPrice },
  ],
  forTicket: [
    {
      ...nameService,
      title: "Название билета",
      placeholder: "Название билета",
    },
    {
      title: "Количество",
      valid: true,
      validProp: "qwerty",
      placeholder: "Количество",
    },
    { ...basePrice },
    { ...holidayPrice },
  ],
};

const rent = {
  headers: [
    {
      title: "Единица измерения времени",
      valid: true,
      validProp: "qwerty",
    },
    {
      title: "Продолжительность сеанса",
      valid: true,
      validProp: "qwerty",
      popover: true,
      popoverText:
        "Фактически это размер шага, который будет использоваться при выборе времени в бронировании",
    },
    {
      title: "Минимальная аренда",
      valid: true,
      validProp: "qwerty",
      popover: true,
      popoverText: "Укажите минимальную продолжительность аренды",
    },
  ],
  general: [
    {
      title: "Длительность дней",
      valid: true,
      validProp: "qwerty",
      popover: true,
      popoverText:
        "Если ограничений по аренде по времени нет, поле До оставляем пустым",
    },
    { ...basePrice, title: "Базовая цена" },
    { ...holidayPrice },
  ],
  sale: [
    { ...rangeSeats },
    {
      title: "Размер скидки",
      valid: false,
      validProp: "qwerty",
      placholder: "Введите сумму скидки",
      popover: true,
      popoverText: `Если предусмотрена скидка за единицу времени, которая зависит от кол-ва мест, вы
можете ее указать. Если скидка за количество мест не предусмотрена – оставьте поле пустым.`,
    },
  ],
};

const headersForIndiv = [
  {
    title: "Время",
    valid: true,
    validProp: "qwerty",
    placeholder: "Введите время",
  },
  { ...basePrice, popover: false, title: "Базовая цена" },
  { ...holidayPrice, popover: false, valid: false },
];

const headersAddPrice = [
  {
    title: "Время",
    valid: true,
    validProp: "qwerty",
    placeholder: "Введите время",
  },
  {
    title: "Добавочная стоимость",
    valid: false,
    validProp: "qwerty",
    popover: true,
    popoverText:
      "Укажите значение, если хотите на выбранное время увеличить или уменьшить стоимость. Значение со знаком «-» уменьшает стоимость",
    placeholder: "Введите цену",
  },
  {
    title: "Доб. стоимость в праздники",
    valid: false,
    validProp: "qwerty",
    popover: true,
    popoverText:
      "Укажите значение, если хотите на выбранное время увеличить или уменьшить стоимость в праздничные дни. Значение со знаком «-» уменьшает стоимость",
    placeholder: "Введите цену",
  },
];

const headersGroupLimitForSeans = [
  {
    title: "Время",
    valid: true,
    validProp: "qwerty",
    placeholder: "Введите время",
  },
  { ...basePrice, popover: false, valid: false, title: "Добавочная стоимость" },
  {
    ...holidayPrice,
    popover: false,
    valid: false,
    title: "Доб. стоимость в праздники",
  },
  {
    title: "Кол-во мест",
    valid: true,
    validProp: "qwerty",
    placeholder: "Введите число",
    popover: true,
    popoverText: "Если количество мест не ограниченно, оставьте поле пустым.",
  },
];
const servicesType = {
  headers: [
    { ...durationService },
    {
      title: "Кол-во мест",
      valid: true,
      validProp: "qwerty",
      placholder: "Кол-во мест",
    },
  ],
  forGeneral: [
    {
      ...nameService,
      title: "Название подуслуги",
      placeholder: "Название подуслуги",
    },
    {
      ...basePrice,
      popover: true,
      valid: true,
      title: "Базовая цена",
    },
    {
      ...holidayPrice,
      popover: true,
      valid: true,
      title: "Цена в праздники",
    },
  ],
  forTicket: [
    {
      ...nameService,
      title: "Название билета",
      placeholder: "Название билета",
    },
    {
      title: "Количество",
      valid: true,
      validProp: "qwerty",
      placeholder: "Количество",
    },
    {
      ...basePrice,
      popover: false,
      valid: false,
      title: "Добавочная стоимость",
    },
    { ...holidayPrice },
  ],
};

export {
  indNotSeats,
  indSeat,
  indGroup,
  indAddSeats,
  indDuration,
  indTarif,
  groupLimit,
  rent,
  headersForIndiv,
  headersAddPrice,
  headersGroupLimitForSeans,
  servicesType,
};
