<template>
  <div class="list-fields">
    <div class="flex">
      <p v-if="title" class="hint-title">{{ title }}</p>
      <el-popover
        v-if="popoverText"
        class="ml-8"
        placement="top"
        width="200"
        trigger="hover"
        :content="popoverText"
      >
        <Tooltip slot="reference" />
      </el-popover>
    </div>
    <template v-for="(item, index) in listField">
      <div
        v-if="titleForLine"
        :key="`${index}-titleForLine`"
        class="hint-title"
      >
        {{ titleForLine }}
      </div>
      <div :key="index" class="list-fields__row">
        <el-input
          v-if="mask"
          v-model="listField[index]"
          v-mask="mask"
          :placeholder="placeholder"
          class="list-fields__row-input"
          @input="$emit('input', listField)"
        ></el-input>
        <el-input
          v-else
          v-model="listField[index]"
          :placeholder="placeholder"
          class="list-fields__row-input"
          @input="$emit('input', listField)"
        ></el-input>
        <ButtonIcon
          icon-name="close"
          width="16"
          :text="$mq.tablet ? textDelete : ''"
          class="list-fields__row-delete-button"
          @click="deleteField(index)"
        />
      </div>
    </template>

    <el-button @click="addField()">{{ titleButton }}</el-button>
  </div>
</template>

<script>
export default {
  name: "ListField",
  props: {
    title: {
      type: String,
      default: "",
    },
    titleForLine: {
      type: String,
      default: "",
    },
    titleButton: {
      type: String,
      default: "Добавить новое поле",
    },
    placeholder: {
      type: String,
      default: "Введите значение",
    },
    value: {
      type: Array,
      default: () => [],
    },
    textDelete: {
      type: String,
      default: "Удалить",
    },
    mask: {
      type: String,
      default: null,
    },
    popoverText: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      listField: [],
    };
  },
  watch: {
    value: {
      handler() {
        this.listField = [...this.value];
      },
      immediate: true,
    },
  },
  methods: {
    deleteField(index) {
      this.listField.splice(index, 1);
      this.$emit("input", this.listField);
    },
    addField() {
      this.listField.push("");
      this.$emit("input", this.listField);
    },
  },
};
</script>

<style lang="scss" scoped>
.list-fields {
  display: flex;
  flex-direction: column;
  margin: 0 0 40px;

  &__row {
    display: flex;
    margin-bottom: 20px;

    &-input {
      flex-grow: 1;
    }

    &-delete-button {
      margin-left: 20px;
    }
  }
}

@screen lg {
  .list-fields {
    margin: 0 0 20px;

    &__row {
      flex-direction: column;
      margin-bottom: 15px;

      &-input {
        margin-bottom: 15px;
      }

      &-delete-button {
        margin-left: 0;
        width: 100% !important;
      }
    }
  }
}
</style>
