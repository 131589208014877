import moment from "moment";

const errorMessage = "Произошла ошибка, попробуйте позже";

function formatTicketDate(date) {
  return `${moment(date).format("DD.MM.YYYY")} в ${moment(date).format(
    "HH:mm"
  )}`;
}

function throttle(callback, limit = 1000) {
  let wait = false;
  // eslint-disable-next-line func-names
  return function () {
    if (!wait) {
      wait = true;
      // eslint-disable-next-line func-names
      setTimeout(function () {
        callback.call();
        wait = false;
      }, limit);
    }
  };
}

function copyHandle(ref, callback) {
  const copyText = ref;
  copyText.select();
  copyText.setSelectionRange(0, 99999); /* For mobile devices */

  /* Copy the text inside the text field */
  document.execCommand("copy");
  copyText.blur();

  callback();
}

export { formatTicketDate, errorMessage, throttle, copyHandle };
