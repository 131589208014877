<template>
  <div class="">
    <div class="flex my-30">
      <div class="w-1/2">
        <span class="small_text">Дата и время</span>
        <span v-if="slotInfo.length < 2" class="small_text">{{
          `Цена за ${unitCahnge(rent.rent.unit).text}`
        }}</span>
        <span v-if="rent.rent.discount" class="small_text">{{
          `Скидка за ${unitCahnge(rent.rent.unit).text}
`
        }}</span>

        <span class="small_text"
          >{{ `Кол-во ${unitCahnge(rent.rent.unit).textPlurals}` }}
        </span>
        <span
          v-if="
            item.booking_type.booking_type_sub ===
            'rent_depends_on_time_and_place'
          "
          class="small_text"
          >Кол-во мест
        </span>
      </div>
      <div class="w-1/2 mb-3">
        <span class="underline">{{ rent.date_time }}</span>
        <span v-if="slotInfo.length < 2" class="small_text-outcome"
          >{{ rent.rent.price }} {{ item.currency }}
        </span>
        <span v-if="rent.rent.discount" class="small_text-outcome"
          >{{ rent.rent.discount }}
          {{ rent.rent.discount_unit === "procent" ? "%" : "₽" }}
        </span>

        <span class="small_text-outcome">{{ rent.rent.duration }} </span>
        <span
          v-if="
            item.booking_type.booking_type_sub ===
            'rent_depends_on_time_and_place'
          "
          class="small_text-outcome"
          >{{ rent.rent.places }}
        </span>
      </div>
    </div>
    <div v-if="slotInfo.length >= 2">
      <div class="flex justify-between mb-3">
        <span class="small_text w-3/12 text-10">Единицы </span>
        <span class="small_text w-2/12 text-10">Кол-во </span>
        <span class="small_text w-3/12 text-10">Стоимость </span>
        <span v-if="isDiscount" class="small_text w-2/12 text-10">Скидка </span>
        <span class="small_text w-2/12 text-10">Итого </span>
      </div>

      <div
        v-for="i in slotInfo"
        :key="i.id"
        class="flex justify-between p-10 mb-3"
        :style="{
          background: i.id % 2 == 0 ? 'rgba(255, 255, 255, 0.04)' : '',
        }"
      >
        <span class="small_text-outcome w-3/12 leading-1"
          >{{ i.time_unit }}
        </span>
        <span class="small_text-outcome w-2/12 leading-1">{{ i.count }} </span>
        <span class="small_text-outcome w-3/12 leading-1"
          >{{ i.slot_price }} {{ item.currency }}</span
        >
        <span
          v-if="rent.rent.discount_unit"
          class="small_text-outcome w-2/12 leading-1"
          >{{ rent.rent.discount }}
          {{ rent.rent.discount_unit === "procent" ? "%" : "₽" }}</span
        ><span class="small_text-outcome w-2/12 leading-1"
          >{{ i.price }} {{ item.currency }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "RentDepOnTime",
  props: { rent: { type: Object }, item: { type: Object } },
  data() {
    return {
      slotInfo: null,
    };
  },
  computed: {
    isDiscount() {
      return !!Object.entries(this.rent.slot_info)[0][1].discount;
    },
  },
  created() {
    this.slotInfo = Object.keys(this.item.consist.slot_info);
    console.log(this.slotInfo.length);
    this.slotInfo = this.slotInfo.map((element, idx) => {
      return {
        id: idx,
        ...this.item.consist.slot_info[element],
      };
    });
  },
  methods: {
    getDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },

    unitCahnge(param) {
      switch (param) {
        case "days":
          return {
            text: "день",
            textPlurals: "дней",
            textShot: "д",
            param: "day",
          };
        case "hours":
          return {
            text: "час",
            textPlurals: "часов",
            textShot: "ч",
            param: "hour",
          };
        case "hour":
          return {
            text: "час",
            textPlurals: "часов",
            textShot: "ч",
            param: "hour",
          };

        case "minutes":
          return {
            text: "минуту",
            textPlurals: "минут",
            textShot: "мин",
            param: "minute",
          };
        case "minute":
          return {
            text: "минуту",
            textPlurals: "минут",
            textShot: "мин",
            param: "minute",
          };

        default:
          return this.settings?.data?.time_unit;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.rotate {
  transform: rotate(180deg);
}
.drop-wrapp {
  display: flex;
  cursor: pointer;
}
.small_text {
  &-total {
    font-size: 12px;

    color: rgba(255, 190, 61, 1);
  }
  &-drop {
    margin-right: 8px;
    font-size: 12px;
  }
  &-outcome {
    font-weight: 500;
    opacity: 1;
    font-size: 12px;
    line-height: 20px;
    display: block;
  }
}
.utf {
  &-form {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    margin: 10px 0px;
    padding: 8px 15px;
  }
  &-icon {
    color: rgba(255, 255, 255, 0.5);
    position: relative;
    top: 3px;
  }
}
</style>
