import { setField, setFieldObj } from "@/helpers/store";
import { AJAX_GET, AJAX_DELETE, AJAX_POST, AJAX_PATCH } from "@/units/ajax";
import { el } from "date-fns/locale";

export default {
  namespaced: true,
  state: {
    stats: {},
    orders: {},
    list_links: {},
    orders_url: null,
    orders_stats: {},
    queryReserves: {},
    sort_by: {},
    isLoading: false,
  },
  getters: {},
  mutations: {
    setField,
    setFieldObj,
    setQueryReserves(state, data) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in data) {
        state.queryReserves[key] = data[key];
      }
    },
    setSortBy(state, data) {
      state.sort_by = data;
    },
  },
  actions: {
    async getStat({ state, commit }) {
      try {
        let query = "?";
        const keys = state.queryReserves;
        if (keys) {
          // eslint-disable-next-line no-restricted-syntax
          for (const key in keys) {
            if (keys[key]) {
              query += `${key}=${keys[key]}&`;
            }
          }
        }
        query = query.substring(0, query.length - 1);
        const { data } = await AJAX_GET(`/api/v1/booking/stats${query}`);
        commit("setField", {
          field: "stats",
          value: { ...data },
        });
      } catch (err) {
        console.log(err);
        // commit(
        //   "setNotif",
        //   {
        //     title: "Ошибка при получении данных. Повторите еще",
        //     type: "error",
        //   },
        //   { root: true }
        // );
      }
    },
    async getQStatistic({ state, commit }) {
      try {
        const { data } = await AJAX_GET("/api/v1/booking/qstatistic");
        commit("setField", {
          field: "list_links",
          value: data,
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getReserves({ state, commit }) {
      commit("setField", {
        field: "isLoading",
        value: true,
      });
      try {
        let query = "?";
        const keys = state.queryReserves;
        if (keys) {
          // eslint-disable-next-line no-restricted-syntax
          for (const key in keys) {
            if (keys[key]) {
              query += `${key}=${keys[key]}&`;
            }
          }
        }

        query = query.substring(0, query.length - 1);

        const { data } = await AJAX_GET(`/api/v1/booking/get/orders${query}`);
        // const { data } = await AJAX_GET(
        //   `/api/v1/booking/get/orders?page=${page}`
        // );
        if (!data.success && !data.data) {
          commit(
            "setNotif",
            {
              title: `${data.msg}`,
              type: "error",
            },
            { root: true }
          );
        }
        commit("setField", {
          field: "orders_url",
          value: query,
        });

        commit("setField", {
          field: "orders",
          value: data.data,
        });
        // eslint-disable-next-line camelcase
        const { current_page, from, to, total } = data;
        commit("setField", {
          field: "orders_stats",
          value: {
            current_page,
            from,
            to,
            total,
          },
        });
      } catch (err) {
        console.log(err);
      }
      commit("setField", {
        field: "isLoading",
        value: false,
      });
    },
    async sendChanges({ commit }, payload) {
      try {
        const { data } = await AJAX_POST("/api/reservation/edit", payload);
        if (data.success) {
          commit(
            "setNotif",
            {
              title: `Бронь изменена`,
            },
            { root: true }
          );
        } else {
          commit(
            "setNotif",
            {
              title: data.msg || `Произошла ошибка. Попробуйте снова.`,
              type: "error",
            },
            { root: true }
          );
        }
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при получении данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async changeStatusOrder({ commit }, param) {
      try {
        const { data } = await AJAX_POST(
          "/api/v1/booking/change_status",
          param
        );
        if (data.success) {
          commit(
            "setNotif",
            {
              title: data.msg || `Статус измененен`,
            },
            { root: true }
          );
        } else {
          commit(
            "setNotif",
            {
              title: data.msg || `Произошла ошибка. Попробуйте снова.`,
              type: "error",
            },
            { root: true }
          );
        }
      } catch (err) {
        console.log(err);
      }
    },

    async adminAddComment({ commit }, param) {
      try {
        const { data } = await AJAX_POST(
          "/api/v1/booking/admin_add_comment",
          param
        );
        commit(
          "setNotif",
          {
            title: data.msg,
          },
          { root: true }
        );
      } catch (err) {
        console.log(err);
      }
    },
  },
};
