<template>
  <el-dialog
    :title="title"
    :visible.sync="show"
    width="600px"
    :show-close="false"
    append-to-body
    @close="hiddenModal"
  >
    <div slot="title" class="absolute fast-add-close">
      <div class="cursor-pointer" @click="hiddenModal">
        <svg-icon
          icon-name="close"
          width="12"
          height="12"
          class-name="text-white-normal"
        ></svg-icon>
      </div>
    </div>
    <div class="title">{{ title }}</div>
    <slot />
    <div class="actions">
      <el-button @click="hiddenModal">Отмена</el-button>

      <el-button :loading="loading" type="primary" @click="$emit('send')"
        >Отправить</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "SendSms",
  props: {
    show: Boolean,
    title: String,
  },
  data() {
    return {
      phone: null,
      message: null,
      loading: false,
    };
  },
  methods: {
    hiddenModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped lang="scss">
@import "element-variables.scss";
::v-deep {
  .el-dialog {
    max-width: 100%;
    &__body {
      padding: 20px 40px 40px;
      @media (max-width: 768px) {
        padding: 20px 16px 30px;
      }
    }
  }
  .el-input {
    margin-bottom: 20px;
    &__inner {
      &:hover,
      &:focus,
      &:active {
        background: $--color-light-white;
        color: white;
      }
    }
  }
  .el-textarea {
    &__inner {
      background-color: $--color-light-white;
      top: 8px;
      &:hover,
      &:focus,
      &:active {
        background: $--color-light-white;
        color: white;
      }
    }
    .el-input__count {
      background-color: transparent;
    }
  }
}
::v-deep .hint-title__medium-white {
  margin-top: 20px;
}
::v-deep .hint {
  color: $--color-white;
  margin-bottom: 10px;
}
.title {
  color: $--color-white;
}
.actions {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}
::v-deep .textarea_group {
  position: relative;
  ::v-deep .el-textarea__inner {
    &:focus,
    &:active {
      background: $--color-sm-white;
      color: $--color-white;
    }
  }
  &__limit {
    position: absolute;
    bottom: 11px;
    right: 11px;
    font-size: 12px;
    color: $--datepicker-off-font-color;
  }
}
.limit {
  color: $--color-danger;
}
</style>
