import { setField, setFieldObj, getError } from "@/helpers/store";
import { AJAX_GET, AJAX_DELETE, AJAX_POST, AJAX_PATCH } from "@/units/ajax";
import axios from "axios";
// eslint-disable-next-line import/no-cycle
import router from "../../router";

const checkSrcFavicon = (state, field) => {
  if (!state.widgetPublic.favicon) return;
  const newSrc = state.widgetPublic.favicon.replace(
    process.env.VUE_APP_LOCAL_API,
    process.env.VUE_APP_PROD_API
  );
  return process.env.NODE_ENV === "production"
    ? state.widgetPublic.favicon
    : newSrc;
};

export default {
  namespaced: true,
  state: {
    isType: null,
    widget: {},
    widgetMulti: {},
    widgetPublic: {},
    widgetSettings: null,
    listWidget: [],
    widgetsService: [],
    slotDetails: null,
    slotsValidity: null,
    dataPay: {
      success: false,
      msg: "",
      name: "",
      phone: "",
      address: "",
    },
    errorMsg: null,
    errorMsgDate: null,
    linkPay: null,
    promocodeInfo: null,
    remainedTickets: null,
  },
  getters: {
    someWidgetDesign: (state) => state.widgetPublic,
    isNew: (state) => !state.widget.hash,
    isSingle: (state) => state.widget.service_type === "single",
    getFaviconLicense: (state) => checkSrcFavicon(state),
    isAmountSlot: (state) => state.slotDetails?.amount,
    isSlotDetails: (state) => state.slotDetails?.slots,
    paymentType: (state) => state.slotDetails?.payment_type,
    errMsgslotsValidity: (state) => state.slotsValidity?.msg,
    isDisabledBookingAdd: (state) =>
      state.slotsValidity?.success === false &&
      state.slotsValidity.msg !== "Дата и время заказа уже прошли",
    isPayCheck: (state) => state.slotDetails.payment_type[0].name, // find((e) => e),
    isCheckedService: (state) => state.widget.service_id,
    isCitiesUbrr: (state) =>
      state.widgetPublic.ubrir_cities.sort((a, b) =>
        a.name.localeCompare(b.name)
      ),
    isBranchesUbrr: (state) => state.widgetPublic.ubrir_branches,
    isAddParam: (state) => state.widgetPublic.add_data,
  },
  mutations: {
    setField,
    setFieldObj,
    setServiceWidget(state, { hash, value }) {
      state.listWidget.find((item) => item.hash === hash).service_ids = value;
    },
    setWidgetEmpty(state) {
      state.widget = {
        service_type: "single",
        service_id: null,
        service_ids: [],
        is_partner_widget: false,
        partner_name: null,
        partner_percent: null,
        typeSingle: "grid",
        isShowPrice: true,
        showCalendarOpen: false,
        typeMulti: "grid",
        showPhoto: true,
        showAddress: true,
        showTag: true,
        typeTheme: "individual",
        is_ubrir_widget: false,
        add_data: [],
        design: {
          font_size: "small",
          opacity: 45,
          block_background: "#3C1452",
          background_cells_fields: "#572F6D",
          active_text_color: "#FFFFFF",
          inactive_text_color: "#9D89A8",
          active_buttons_background: "#FFBE3D",
          button_text_color: "#000000",
        },
        typeBookingForm: "center",
        fieldForm: [
          "fieldName",
          "maskRussian",
          "fieldPhone",
          "fieldFamaly",
          "fieldEmail",
          "fieldComment",
          "fieldPromo",
          "fieldСlient",
          "fieldUbrirTel",
        ],
      };
    },
    setWidget(state, { field, value }) {
      state.widget[field] = value;
    },
    setWidgetDesign(state, { field, value }) {
      state.widget.design[field] = value;
    },
  },
  actions: {
    async getListWidget({ commit }) {
      try {
        const { data } = await AJAX_POST("/api/widgets/get/all");
        commit("setField", {
          field: "listWidget",
          value: data,
        });
      } catch (err) {
        console.log(err);
        getError(commit, {
          title: "Ошибка при получении сохранении. Повторите еще",
          type: "error",
        });
      }
    },
    async getWidget({ commit }, hash) {
      try {
        const { data } = await AJAX_POST("/api/widgets/get", { hash });
        commit("setField", {
          field: "widget",
          value: data,
        });
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при получении данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },

    async getWidgetPublic({ commit }, hash) {
      try {
        const { data } = await AJAX_POST("/api/widgets/getembed", hash);
        commit("setField", {
          field: "widgetPublic",
          value: data,
        });
      } catch (err) {
        console.log(err);
        // commit(
        //   "setNotif",
        //   {
        //     title: "Ошибка при получении данных. Повторите еще",
        //     type: "error",
        //   },
        //   { root: true }
        // );
      }
    },
    async getSettingWidget({ state, commit }) {
      try {
        if (state.getSettingWidget) return;
        const { data } = await AJAX_POST("/api/widgets/new/widget");
        commit("setField", {
          field: "widgetSettings",
          value: data,
        });
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при получении данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async getLinkPay({ state }) {
      try {
        const info = {
          reservationId: state.dataPay.booking_id,
          // paymentType: "payment_full",
          // payment: state.dataPay.payment,
        };
        const { data } = await AJAX_POST("/api/v1/booking/pay", info);
        if (data.success) {
          window.location.href = data.redirect;
        }
        commit("setField", {
          field: "widgetSettings",
          value: data,
        });
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при получении данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async saveWidget({ state, commit }) {
      try {
        const { data } = await AJAX_POST("/api/widgets/save", {
          ...state.widget,
        });
        commit("setFieldObj", {
          field: "widget",
          subField: "hash",
          value: data.hash,
        });
        commit(
          "setNotif",
          {
            title: "Виджет успешно сохранен",
          },
          { root: true }
        );
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при получении сохранении. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async addServiceWidget({ commit }, widget) {
      try {
        await AJAX_POST("/api/widgets/update/services", {
          hash: widget.hash,
          service_ids: widget.service_ids,
        });
        commit(
          "setNotif",
          {
            title: "Данный успешно сохранены",
          },
          { root: true }
        );
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при сохранении. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async deleteWidget({ state, commit }, hash) {
      try {
        const data = await AJAX_POST("/api/widgets/delete", { hash });

        commit("setField", {
          field: "listWidget",
          value: state.listWidget.filter((widget) => widget.hash !== hash),
        });
        commit(
          "setNotif",
          {
            title: data.data.msg || "Удален",
          },
          { root: true }
        );
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка удаления. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async getWidgetScheduleForm({ commit }, id) {
      try {
        const { data } = await AJAX_POST(`/api/schedule/get?ids=[${id}]`);
        // commit("setField", {
        //   field: "widget",
        //   value: data,
        // });
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при получении данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async saveBooking({ commit }, info) {
      try {
        const { data } = await AJAX_POST("/api/v1/booking/create", {
          ...info,
        });
        commit("setField", {
          field: "dataPay",
          value: data,
        });
        commit("setField", {
          field: "promocodeInfo",
          value: null,
        });
        if (data.success) {
          if (info.analyticsYandex) {
            ym(`${info.analyticsYandex}`, "reachGoal", "orderSuccess");
            gtag("event", "select_content", {
              event_category: "bookingCell",
              event_action: "bookingSuccess",
              event_label: "booking",
            });
          }

          commit(
            "setNotif",
            {
              title: data.msg || "Бронирование успешно сохранено",
              type: "success",
            },
            { root: true }
          );
        }
      } catch (err) {
        console.log(err);

        getError(commit, {
          title: "Ошибка при получении сохранении. Повторите еще",
          type: "error",
        });
      }
    },
    async saveBookingForAdmin({ commit }, param) {
      try {
        const { data } = await AJAX_POST("/api/v1/booking/admin/create", {
          ...param,
        });
        commit("setField", {
          field: "dataPay",
          value: data,
        });
        commit("setField", {
          field: "promocodeInfo",
          value: null,
        });
        if (data.success) {
          commit(
            "setNotif",
            {
              title: data.msg,
            },
            { root: true }
          );
        } else {
          commit(
            "setNotif",
            {
              title: data.msg,
              type: "error",
            },
            { root: true }
          );

          commit("setField", {
            field: "errorMsg",
            value: data,
          });
        }
      } catch (err) {
        console.log(err);
        getError(commit, {
          title: "Ошибка при получении сохранении. Повторите еще",
          type: "error",
        });
      }
    },
    async getUpdateFormAdmin({ commit }, param) {
      try {
        const { data } = await AJAX_POST("/api/v1/booking/update", {
          ...param,
        });
        // commit("setField", {
        //   field: "dataPay",
        //   value:  data,
        // });
        // commit("setField", {
        //   field: "promocodeInfo",
        //   value: null,
        // });

        if (data.success) {
          commit("setField", {
            field: "dataPay",
            value: data,
          });
          commit(
            "setNotif",
            {
              title: data.msg,
            },
            { root: true }
          );
        } else {
          commit("setField", {
            field: "dataPay",
            value: data,
          });
        }
      } catch (error) {
        console.log(error);
        getError(commit, {
          title: "Ошибка при получении сохранении. Повторите еще",
          type: "error",
        });
      }
    },
    async getSlotDetails({ commit }, param) {
      try {
        const { data } = await AJAX_POST("/api/v1/booking/get_slot_details", {
          ...param,
        });
        commit("setField", {
          field: "slotDetails",
          value: data,
        });
      } catch (error) {
        console.log(error);
        // getError(commit, {
        //   title: "Ошибка при получении сохранении. Повторите еще",
        //   type: "error",
        // });
      }
    },
    async checkSlotsValidity({ commit }, param) {
      try {
        const { data } = await AJAX_POST(
          "/api/v1/booking/check_slots_validity",
          {
            ...param,
            // resourceHash: param.hash,
            // date: param.date,
            // time: param.time,
            // cnt: param.cnt,
            // tickets: param.tickets,
            // bookingHash: param.bookingHash,
          }
        );
        commit("setField", {
          field: "slotsValidity",
          value: data,
        });
      } catch (error) {
        console.log(error);
      }
    },

    async getWidgetsService({ commit }, hash) {
      try {
        const { data } = await AJAX_POST("/api/widgets/get/byservisid", {
          servise_hash: hash,
          // hash,
        });
        commit("setField", {
          field: "widgetsService",
          value: data,
        });
      } catch (err) {
        console.log("err", err);
        getError(commit, {
          title: "Ошибка при получении. Повторите еще",
          type: "error",
        });
      }
    },
    async getRemainedTickets({ commit }, { hash, date, time }) {
      const resourceId = hash;
      const { data } = await axios.post(
        "/api/schedule/getRemainedTicketsPlaces",
        {
          resourceId,
          date,
          time,
        }
      );
      // try {
      //   const { data } = await AJAX_POST(
      //     "/api/schedule/getRemainedTicketsPlaces",
      //     {
      //       resourceId,
      //       date,
      //       time,
      //     }
      //   );
      commit("setField", {
        field: "remainedTickets",
        value: data,
      });
      // } catch (error) {
      //   console.log(error);
      // }
    },
    async checkPromoCode({ commit }, { id, date, time, code }) {
      try {
        const { data } = await AJAX_POST("/api/reservation/promocode", {
          schedule_id: id,
          date,
          time,
          code,
        });
        commit("setField", {
          field: "promocodeInfo",
          value: data,
        });
        if (!data.active) {
          console.log(data.msg);
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getScheduleByPeriodOrByDateMulti({ commit }, { hashs, date }) {
      try {
        const { data } = await AJAX_POST(
          "/api/schedule/getScheduleByPeriodOrByDateMulti",
          {
            hashs,
            date,
          }
        );

        if (data.success) {
          commit("setField", {
            field: "widgetMulti",
            value: data.schedule,
          });
          commit("setField", {
            field: "errorMsgDate",
            value: "",
          });
        }

        if (!data.success) {
          commit("setField", {
            field: "errorMsgDate",
            value: data.msg,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
  },
};
