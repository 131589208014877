<template>
  <div>
    <span class="return__text">{{
      `услуга
 : `
    }}</span>

    <div class="input-number my-10">
      <div class="input-number__info">
        <div class="">
          <el-radio-group>
            <el-radio v-model="model" class="flex">
              <p>{{ item.service.name }}</p>
              <span>{{ item.service.duration }} </span>
              <p>{{ item.service.base_price }}</p>
            </el-radio>
          </el-radio-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IndService",
  props: {
    item: {
      type: Object,
    },
    settings: { type: Object },
    count: {
      type: String,
    },
  },

  data() {
    return {
      model: null,
    };
  },
  created() {
    this.itemCount = this.item.person;
  },
};
</script>

<style lang="scss" scoped>
.return {
  &__text {
    color: #fff;
  }
}
</style>
