<template>
  <el-col
    :span="$mq.tablet ? mq.mobile : mq.desktop"
    class="large:mb-20 lg:w-full lg:mb-15"
  >
    <label class="text-12 font-medium text-white-normal flex flex-col">
      <span class="mb-7">{{ text.label }}</span>
      <el-date-picker
        v-model="date"
        type="daterange"
        format="dd.MM.yyyy"
        range-separator="По"
        start-placeholder="Любая"
        end-placeholder="Любая"
        @change="changeDate"
      >
      </el-date-picker>
    </label>
  </el-col>
</template>

<script>
export default {
  props: {
    text: Object,
    items: Array,
    mq: {
      type: Object,
      default: () => ({
        mobile: 24,
        desktop: 6,
      }),
    },
  },
  data() {
    return {
      date: null,
    };
  },
  methods: {
    changeDate() {
      this.$emit("selected", this.date);
    },
  },
};
</script>
