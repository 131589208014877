<template>
  <div
    v-loading="!slides.length"
    class="pt-40 pb-80 w-full max-w-lg lg:pt-30 lg:pb-50 lg:pt-15 lg:pb-45 lg:px-15"
  >
    <h1 class="text-22 leading-36 font-semibold text-white-normal mb-16">
      Расписание
    </h1>
    <el-date-picker
      v-model="scheduleDate"
      type="date"
      :clearable="false"
      format="dd.MM.yyyy"
      class="mb-25 schedule-datepicker lg:w-full lg:max-w-full lg:mb-20"
      :picker-options="scheduleDateOptions"
      placeholder="Выберите дату"
      @change="changeScheduleDate($event)"
    >
    </el-date-picker>
    <div class="flex mb-35">
      <div
        class="schedule-slide-prev flex items-center justify-center w-60 min-w-60 xs:w-40 xs:min-w-40 bg-white-100 rounded-l-sm border-r border-white-100 h-60 outline-none cursor-pointer text-white-normal hover:text-yellow-100"
        @click="sliderNavigate('left')"
      >
        <svg-icon
          icon-name="chevron"
          class-name="rotate-minus-90"
          width="12"
          height="7"
        ></svg-icon>
      </div>
      <swiper
        ref="sheduleDates"
        :options="swiperOption"
        class="schedule-dates flex-grow pb-5"
      >
        <!-- slides -->
        <swiper-slide
          v-for="(slide, index) in slides"
          :key="`slide-${index}`"
          :class="{ active: slide.active }"
          class="flex flex-col bg-white-100 items-center p-9 border-r border-white-100 w-60"
          @click.native="clickOnSlider(index)"
        >
          <span class="font-semibold leading-25">
            {{ slideDate(slide.date) }}
          </span>
          <span
            class="schedule-weekday text-12 font-medium leading-16 text-white-500"
          >
            {{ slide.weekDay }}
          </span>
        </swiper-slide>
      </swiper>

      <div
        class="schedule-slide-next flex items-center justify-center w-60 min-w-60 xs:w-40 xs:min-w-40 bg-white-100 rounded-r-sm border-l border-white-100 h-60 outline-none cursor-pointer text-white-normal hover:text-yellow-100"
        @click="sliderNavigate('right')"
      >
        <svg-icon
          icon-name="chevron"
          class-name="rotate-90"
          width="12"
          height="7"
        ></svg-icon>
      </div>
    </div>

    <app-schedule :schedule-date="scheduleDate">
      <template #header="props">
        <div>
          <div
            class="schedule-resource-name text-white-normal text-18 leading-30 font-semibold w-full"
          >
            {{ props.item.name }}
          </div>
          <div v-if="!$mq.tablet" class="flex mt-16">
            <router-link
              :to="{
                name: 'resource',
                params: { id: props.item.id },
                query: { tab: 'datetime' },
              }"
              class="flex items-center pr-15 flex-shrink-0 schedule-link"
            >
              <svg-icon
                icon-name="datetime"
                class-name="text-violet-600 mr-10"
                width="16"
                height="16"
              ></svg-icon>
              <span class="border-b border-white-350 schedule-link__text"
                >Время, цены, даты</span
              >
            </router-link>

            <router-link
              :to="{
                name: 'resource',
                params: { id: props.item.id },
                query: { tab: 'schedule' },
              }"
              class="flex items-center px-15 flex-shrink-0 schedule-link"
            >
              <svg-icon
                icon-name="eye"
                class-name="text-yellow-100 mr-10"
                width="20"
                height="14"
              ></svg-icon>
              <span class="border-b border-white-350 schedule-link__text"
                >Расписание</span
              >
            </router-link>

            <router-link
              :to="{
                name: 'resource',
                params: { id: props.item.id },
                query: { tab: 'settings' },
              }"
              class="flex items-center px-15 flex-shrink-0 schedule-link"
            >
              <svg-icon
                icon-name="settings"
                class-name="text-blue-100 mr-10"
                width="20"
                height="20"
              ></svg-icon>
              <span class="border-b border-white-350 schedule-link__text"
                >Настройки</span
              >
            </router-link>

            <router-link
              :to="{
                name: 'resource',
                params: { id: props.item.id },
                query: { tab: 'integration' },
              }"
              class="flex items-center px-15 flex-shrink-0 schedule-link"
            >
              <svg-icon
                icon-name="integration"
                class-name="text-green-500 mr-10"
                width="22"
                height="16"
              ></svg-icon>
              <span class="border-b border-white-350 schedule-link__text"
                >Интеграция</span
              >
            </router-link>

            <router-link
              :to="{
                name: 'resource',
                params: { id: props.item.id },
                query: { tab: 'notifications' },
              }"
              class="flex items-center px-15 flex-shrink-0 schedule-link"
            >
              <svg-icon
                icon-name="news2"
                class-name="text-red-200 mr-10"
                width="16"
                height="21"
              ></svg-icon>
              <span class="border-b border-white-350 schedule-link__text"
                >Уведомления</span
              >
            </router-link>
          </div>
        </div>

        <el-popover
          v-if="$mq.tablet"
          class="ml-auto cursor-pointer"
          placement="bottom-end"
          popper-class="control-poper"
          width="184"
          trigger="click"
        >
          <span
            slot="reference"
            class="pl-15 flex items-center outline-none h-full min-h-20"
          >
            <svg-icon
              icon-name="dots"
              class-name="text-white-500"
              width="20"
              height="4"
            ></svg-icon>
          </span>
          <div>
            <router-link
              :to="{
                name: 'resource',
                params: { id: props.item.id },
                query: { tab: 'datetime' },
              }"
              class="px-15 py-7 border-b border-white-100 flex items-center cursor-pointer h-40"
            >
              <svg-icon
                icon-name="datetime"
                class-name="text-violet-600 mr-8"
                width="16"
                height="16"
              ></svg-icon>
              Время, цены, даты
            </router-link>
            <router-link
              :to="{
                name: 'resource',
                params: { id: props.item.id },
                query: { tab: 'schedule' },
              }"
              class="px-15 py-7 flex items-center cursor-pointer h-40 border-b border-white-100"
            >
              <svg-icon
                icon-name="eye"
                class-name="text-yellow-100 mr-8"
                width="20"
                height="14"
              ></svg-icon>
              Расписание
            </router-link>
            <router-link
              :to="{
                name: 'resource',
                params: { id: props.item.id },
                query: { tab: 'settings' },
              }"
              class="px-15 py-7 flex items-center cursor-pointer h-40 border-b border-white-100"
            >
              <svg-icon
                icon-name="settings"
                class-name="text-blue-100 mr-8"
                width="20"
                height="20"
              ></svg-icon>
              Настройки
            </router-link>
            <router-link
              :to="{
                name: 'resource',
                params: { id: props.item.id },
                query: { tab: 'integration' },
              }"
              class="px-15 py-7 flex items-center cursor-pointer h-40 border-b border-white-100"
            >
              <svg-icon
                icon-name="integration"
                class-name="text-green-500 mr-8"
                width="22"
                height="16"
              ></svg-icon>
              Интеграция
            </router-link>
            <router-link
              :to="{
                name: 'resource',
                params: { id: props.item.id },
                query: { tab: 'notifications' },
              }"
              class="px-15 py-7 flex items-center cursor-pointer h-40"
            >
              <svg-icon
                icon-name="news2"
                class-name="text-red-200 mr-8"
                width="16"
                height="21"
              ></svg-icon>
              Уведомления
            </router-link>
          </div>
        </el-popover>
      </template>
    </app-schedule>
  </div>
</template>

<script>
import AppSchedule from "./AppSchedule";
import { mapState } from "vuex";
import moment from "moment";
import { AJAX_GET, AJAX_POST } from "../units/ajax";

moment.locale("ru");

export default {
  name: "WidgetMultiple",
  components: { AppSchedule },
  data() {
    return {
      scheduleDate: "",
      scheduleIsLoading: false,
      swiperOption: {
        slidesPerView: 15,
        breakpoints: {
          1400: {
            slidesPerView: 13,
          },
          1024: {
            slidesPerView: 12,
          },
          768: {
            slidesPerView: 9,
          },
          480: {
            slidesPerView: 5,
          },
        },
        /* navigation: {
                        nextEl: '.schedule-slide-next',
                        prevEl: '.schedule-slide-prev',
                    }, */
        slideToClickedSlide: true,
      },
      scheduleDateOptions: {
        disabledDate: (time) => {
          return (
            time < moment().subtract(1, "day").toDate() ||
            time > moment().add(this.user.settings.limit_show_site, "day")
          );
        },
      },
      sliderPage: 1,
      slides: [],
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    sheduleDatesSlider() {
      return this.$refs.sheduleDates.swiper;
    },
  },
  mounted() {
    this.scheduleDate = moment().toDate();
    this.getSchedule(this.scheduleDate);
    this.generateDatesSlider();
  },
  methods: {
    changeLoadingStatus() {
      this.scheduleIsLoading = !this.scheduleIsLoading;
    },
    setSchedule(payload) {
      const schedule = {};

      payload.user.resources.forEach((resource, index) => {
        if (resource.active) {
          schedule[payload.user.resources[index].id] = {
            name: payload.user.resources[index].name,
            id: payload.user.resources[index].id,
            slots: [],
          };
        }
      });

      // eslint-disable-next-line no-restricted-syntax
      for (const key in payload.schedule) {
        if (schedule[key]) {
          schedule[key].slots = [];
          payload.schedule[key].forEach((slot) => {
            schedule[key].slots.push({
              time: slot.time,
              price: slot.price,
              currency: "₽",
            });
          });
        }
      }

      this.schedule = schedule;
      this.scheduleIsLoading = false;
    },
    getSchedule(date) {
      this.changeLoadingStatus();

      const allServicesId = rootGetters["user/allServicesId"];

      AJAX_GET(
        `/api/schedule/get?ids=[${allServicesId}]&filter=day&day=${moment(
          date
        ).format("YYYY-MM-DD")}`,
        { noCancel: true }
      ).then((response) => {
        this.setSchedule({
          schedule: response.data,
          user: rootGetters["user/user"],
        });
      });
    },
    changeScheduleDate(date) {
      const slideId = this.slides.findIndex(
        (slide) =>
          moment(slide.date).format("DD.MM.YYYY") ===
          moment(date).format("DD.MM.YYYY")
      );

      if (slideId >= 0) {
        this.clickOnSlider(slideId);
      } else {
        AJAX_POST("/api/schedule/searchDate", { date }).then((response) => {
          this.sliderPage = response.data.page;
          this.generateDatesSlider().then(() => {
            // eslint-disable-next-line no-shadow
            const slideId = this.slides.findIndex(
              (slide) =>
                moment(slide.date).format("DD.MM.YYYY") ===
                moment(date).format("DD.MM.YYYY")
            );
            this.clickOnSlider(slideId);
          });
        });
      }
    },
    clickOnSlider(slideId) {
      this.slides.forEach((slide) => {
        slide.active = false;
      });

      this.slides[slideId].active = true;

      this.scheduleDate = this.slides[slideId].date;

      this.getSchedule(this.scheduleDate);
    },
    sliderNavigate(type) {
      if (type === "left") {
        if (this.sliderPage !== 1) {
          this.sliderPage--;
        }
      } else if (type === "right") {
        this.sliderPage++;
      }

      this.generateDatesSlider().then((response) => {
        this.sheduleDatesSlider.slideTo(0);
      });
    },
    slideDate(date) {
      return moment(date).format("DD.MM");
    },
    generateDatesSlider() {
      return AJAX_POST("/api/schedule/getDates", {
        page: this.sliderPage,
      }).then((response) => {
        this.slides = [];
        this.$set(this, "slides", response.data);
        this.slides[0].active = true;
      });
    },
  },
};
</script>

<style lang="scss">
.schedule-datepicker {
  max-width: 190px;
}

.schedule-weekday {
}

.schedule-link {
  &:hover {
    .schedule-link__text {
      @apply .border-white-normal;
    }
  }
}

.schedule-dates {
  /*&.swiper-container {
            @screen ssm {
                overflow: visible;
            }
        }*/

  .swiper-slide {
    cursor: pointer;
    box-sizing: border-box;
    height: 60px;
    transition: all 0.2s;

    &:hover {
      @apply .bg-yellow-100 .text-black;

      .schedule-weekday {
        @apply .text-black;
      }
    }

    &.active {
      @apply .bg-yellow-100 .text-black;

      .schedule-weekday {
        @apply .text-black;
      }

      &:after {
        content: "";
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #ffbd1a;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.schedule-resource-name {
  @screen ssm {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
