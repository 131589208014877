<template>
  <el-collapse class="filter">
    <BlockCollapse title="Фильтрация" name="filter">
      <el-row
        type="flex"
        :gutter="!$mq.tablet ? 30 : 0"
        class="w-full flex-wrap"
      >
        <Input
          :text="{ label: '№ бронирования', placeholder: 'Введите номер' }"
          class="mb-10"
        />
        <Date
          :text="{ label: 'Дата изменения' }"
          :mq="{ mobile: 12, desktop: 6 }"
        />

        <Select
          :text="{ label: 'Тип', placeholder: 'Любой тип' }"
          :items="typeOptions"
        />
        <Select
          :text="{ label: 'Действие', placeholder: 'Любой статус' }"
          :items="statusOptions"
        />
        <Select
          :text="{ label: 'Статус', placeholder: 'Все действия' }"
          :items="statusOptions"
        />
      </el-row>
      <el-row>
        <el-col :span="24" class="filter__actions filter__content">
          <el-button> Сбросить </el-button>
          <el-button
            type="primary"
            class="bg-transparent text-white-normal"
            @click="showReserves"
          >
            Применить
          </el-button>
        </el-col>
      </el-row>
    </BlockCollapse>
  </el-collapse>
</template>

<script>
import BlockCollapse from "@/components/BlockCollapse";
import Select from "@/components/Table/Select";
import Date from "@/components/Table/Date";
import Input from "@/components/Table/Input";

export default {
  components: {
    BlockCollapse,
    Select,
    Date,
    Input,
  },
  data() {
    return {
      typeOptions: [
        { value: 1, label: "type1" },
        { value: 2, label: "type2" },
      ],
      statusOptions: [
        { value: 1, label: "status1" },
        { value: 2, label: "status2" },
      ],
    };
  },
};
</script>

<style lang="scss">
@import "element-variables.scss";

.filter {
  &__content {
    padding: 0 30px;
  }
  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 30px;
    margin-top: 30px;
    border-top: 1px solid $--color-light-white;
    button {
      height: 40px;
      &:last-of-type {
        margin-left: 20px;
      }
    }
  }
}
</style>
