<template>
  <div
    :class="[
      'cell',
      size,
      { classObject: !value.active },
      { disabledSlot: !value.active },
    ]"
    @click="$emit('click')"
  >
    <div
      class="cell__time relative"
      :style="{
        background: isTypeTheme ? customStyle.background_cells_fields : '',
        opacity:
          isTypeTheme && !value.active
            ? customStyle.opacity === 100
              ? `${customStyle.opacity}`
              : `0.${customStyle.opacity}`
            : '',
        color: isTypeTheme ? customStyle.active_text_color : '',
      }"
    >
      <span v-if="value.discount" class="proc">%</span>
      {{ value.time }}
    </div>

    <span
      v-if="isMaket ? widget.isShowPrice : widgetPublic.isShowPrice"
      class="cell__price"
      :style="{
        opacity: isTypeTheme && !value.active ? `0.${customStyle.opacity}` : '',
        color: isTypeTheme ? customStyle.active_text_color : '',
      }"
    >
      {{ addPristavka ? "от" : "" }}
      {{ value.discount ? value.discount_price : value.price }}
      {{ value.currency }}
    </span>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CellSchedule",
  props: {
    showPrice: Boolean,
    addPristavka: Boolean,
    isMaket: { type: Boolean },
    value: {
      type: Object,
      default: () => {},
    },
    size: {
      type: String,
      default: "small",
    },
    customStyle: {
      type: Object,
    },
    isTypeTheme: {
      type: Boolean,
    },
  },
  data() {
    return {
      classObject: {
        color: "white",
        opacity: 1,
      },
    };
  },
  computed: {
    ...mapState("widget", ["widget", "widgetPublic"]),
  },
  created() {
    this.isDisClass();
  },
  methods: {
    isDisClass() {
      this.classObject = {
        opacity:
          this.customStyle?.opacity === 100
            ? `${this.customStyle?.opacity}`
            : `0.${this.customStyle?.opacity}`,
        color: this.customStyle?.background_cells_fields,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.small {
  margin-right: 10px;
  margin-bottom: 14px;
}

.medium {
  margin-right: 12px;
  margin-bottom: 16px;
  .cell {
    &__time {
      padding: 13px 17px;
      line-height: 22px;
      font-size: 16px;
      margin-bottom: 6px;
    }
    &__price {
      font-size: 14px;
      line-height: 16px;
    }
  }
}

.big {
  margin-right: 14px;
  margin-bottom: 18px;
  .cell {
    &__time {
      padding: 13px 17px;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 8px;
    }
    &__price {
      font-size: 16px;
      line-height: 18px;
    }
  }
}

.cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  &__time {
    color: $--color-white;
    background-color: hsla(0, 0%, 100%, 0.15);
    border-radius: 3px;
    cursor: pointer;
  }
  &__price {
    color: $--color-white-5;
    font-weight: 500;
  }
}
</style>
<style lang="scss">
@import "element-variables.scss";
.cell {
  &__time {
    padding: 8px 12px;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 5px;
  }
  &__price {
    font-size: 12px;
    line-height: 13px;
  }
}
.proc {
  position: absolute;
  top: -3px;
  left: 3px;
  font-size: 10px;
  color: #febb17;
}
</style>
