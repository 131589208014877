<template>
  <div class="wrapper px-10">
    <!-- <h1
      class="font-semibold text-white-normal text-28 leading-44 lg:text-22 py-10 pt-0 lg:leading22 mb-20 xs:mb-5"
    >
      Виджеты
    </h1> -->
    <Top title="Виджеты" :font="{ size: 22, leading: 22 }" />
    <BlockCreateWidget />
    <spinner v-if="loading && !listWidget.length" />

    <div v-if="!loading && listWidget.length">
      <div class="title">
        Виджеты услуг <span class="total">{{ listWidget.length }}</span>
      </div>
      <ItemCard
        v-for="(widget, index) in listWidget"
        :key="index"
        :elements="widget"
      />
    </div>
    <div v-if="!loading && !listWidget.length" class="qwerty">
      Созданных виджетов нет
    </div>
    <div class="custom-block card text-22">
      Интеграция по API
      <div class="hl"></div>
      <p class="integration__text">
        Вы можете интегрировать расписание услуг на свой сайт. Его можно
        получить в формате JSON, и вывести его в собственном дизайне. Механизм
        получения расписания и формирования запросов бронирования описан в
        инструкции ниже. Данная инструкция так же действительна для интеграции
        расписания с агрегаторами.
      </p>
      <div>
        <instruction-item
          class="mt-20"
          only-button
          :doc="{
            link: user.instructions.api.link,
            buttonText: `Инструкция`,
          }"
        />
      </div>
    </div>
    <!-- <integration-entry class="mt-30 lg:mt-20" multiple></integration-entry> -->
  </div>
</template>

<script>
// import IntegrationEntry from "@/components/IntegrationEntry";
import BlockCreateWidget from "@/components/BlockCreateWidget.vue";
// import ItemWidget from "@/components/ItemWidget.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import InstructionItem from "@/components/InstructionItem";
import ItemCard from "@/views/Widgets/ItemCard";
import Top from "@/components/Pages/Top";

export default {
  name: "Widgets",
  components: {
    Top,
    // IntegrationEntry,
    BlockCreateWidget,
    //  ItemWidget,
    InstructionItem,
    ItemCard,
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapState("user", ["servicesList", "user"]),
    ...mapState("widget", ["listWidget"]),
    ...mapGetters("user", ["allServicesId"]),
  },
  async created() {
    if (!this.servicesList.length) await this.getServicesList();
    await this.getListWidget();
    await this.getSettingWidget();
    this.loading = false;
  },
  methods: {
    ...mapActions("widget", ["getListWidget", "getSettingWidget"]),
    ...mapActions("user", ["getServicesList"]),
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.total {
  background: #3498db;
  font-size: 10px;
  font-weight: 700;
  padding: 2px 7px;
  border-radius: 10px;
  margin-left: 10px;
}

.title {
  display: flex;
  align-items: center;
  line-height: inherit !important;
  font-size: 18px;
}

.qwerty {
  margin: 20px 0 50px;
  padding: 50px 0;
}
.card {
  &__parent {
    display: flex;
    margin-top: 6px;
  }
}
.integration__text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
}
</style>
