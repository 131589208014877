<template>
  <el-form
    ref="mapForm"
    :hide-required-asterisk="true"
    label-position="top"
    :model="$data"
    @submit.native.prevent
  >
    <el-form-item
      prop="text"
      :rules="[{ required: true, message: 'заполните поле' }]"
      class="mb-20"
    >
      <span slot="label">Адрес<span class="danger">*</span></span>
      <el-input
        :id="`map-search_${randomID}`"
        v-model="text"
        placeholder="Начните писать название адреса"
        autocomplete="off"
        input
        @blur="changeAddress"
      ></el-input>
    </el-form-item>
    <div class="map-wrapper">
      <div class="comment-map text-12 leading-19 text-white-500 mb-10">
        Вы можете передвинуть точку на карте для более точного позиционирования
        адреса.
      </div>
      <yandex-map
        key="qwerty"
        :controls="[]"
        :settings="mapSettings"
        :coords="mapCoords"
        :options="{ autoFitToViewport: 'always' }"
        @map-was-initialized="initMapHandler"
      >
        <ymap-marker
          :key="markerKey"
          :coords="mapCoords"
          marker-id="123"
          :options="{ draggable: !isNotDraggable }"
          @dragend="onDragEnd"
        />
      </yandex-map>
    </div>
    <el-form-item
      label="Комментарий к адресу"
      :class="['mb-30', { 'disabled-text': !text }]"
    >
      <el-input
        v-model="comment"
        :disabled="!text"
        placeholder="Опишите, как добраться до адреса"
      ></el-input>
    </el-form-item>
  </el-form>
</template>

<script>
import { loadYmap, yandexMap, ymapMarker } from "vue-yandex-maps";

const tzlookup = require("tz-lookup");

export default {
  name: "MapCompany",
  components: { yandexMap, ymapMarker },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    isNotDraggable: Boolean,
  },
  data() {
    return {
      text: "",
      id: null,
      comment: "",
      input: "",
      country: "",
      city: "",
      timezone: "",
      address: "",
      street: "",
      house: "",
      markerKey: 1,
      mapCoords: [59.939095, 30.315868],
    };
  },
  computed: {
    isNewAddress() {
      return !this.id;
    },
    randomID() {
      return Math.floor(Math.random() * 1000);
    },
  },
  watch: {
    text(val) {
      if (!val) this.comment = "";
    },
    value() {
      this.setData(this.value);
    },
  },
  async mounted() {
    await loadYmap(this.mapSettings);
  },
  created() {
    if (this.value) this.setData(this.value);
    this.mapSettings = {
      apiKey: "b32e35ac-7c5f-4b34-b907-823aeaebdcca",
      lang: "ru_RU",
      coordorder: "latlong",
      version: "2.1",
    };
  },
  methods: {
    setData(value) {
      this.comment = value.address_comment;
      this.text = value.address_name;
      this.id = value.address_id;
      this.street = value;
      this.house = value;
      this.setAddressByCoords(value.map);
      this.mapCoords = [...value.map];
    },
    validateMap() {
      this.$refs.mapForm.validate((valid) => {
        this.$emit("validate", valid);
        if (valid) {
          this.$emit("input", {
            address_name: this.input,
            address_city: this.city,
            address_country: this.country,
            address_timezone: this.timezone,
            address_comment: this.comment,
            address_street: this.street,
            address_house_number: this.house,
            address_id: this.id,
            map: [...this.mapCoords],
          });

          if (this.isNewAddress) {
            this.$refs.mapForm.resetFields();
            this.mapCoords = [59.939095, 30.315868];
          }
        }
      });
    },
    initMapHandler() {
      // eslint-disable-next-line no-new
      new ymaps.SuggestView(`map-search_${this.randomID}`);
    },
    async changeAddress() {
      if (this.text || this.isDragDrop) {
        // eslint-disable-next-line no-undef
        const res = await ymaps.geocode(this.text, {
          results: 1,
        });
        this.mapCoords = res.geoObjects.get(0).geometry.getCoordinates();
        this.markerKey += 1;

        const firstGeoObject = res.geoObjects.get(0);

        this.timezone = tzlookup(this.mapCoords[0], this.mapCoords[1]);
        this.address = firstGeoObject.properties.get("name");
        this.country = firstGeoObject.getCountry();
        this.city = this.getCityName(firstGeoObject);
        this.input = firstGeoObject.getAddressLine();
        this.street = firstGeoObject.getThoroughfare();
        this.house = firstGeoObject.getPremiseNumber();
      } else {
        this.input = "";
        this.address = "";
        this.country = "";
        this.city = "";
        this.timezone = "";
        this.street = "";
        this.house = "";
      }
    },
    async setAddressByCoords(coords) {
      try {
        const res = await ymaps.geocode(coords, { results: 1 });
        const firstGeoObject = res.geoObjects.get(0);
        this.text = firstGeoObject.getAddressLine();
        this.input = firstGeoObject.getAddressLine();
        this.address = firstGeoObject.properties.get("name");
        this.country = firstGeoObject.getCountry();
        this.city = this.getCityName(firstGeoObject);
        this.street = firstGeoObject.getThoroughfare();
        this.house = firstGeoObject.getPremiseNumber();
      } catch (e) {
        console.error(e);
      }
    },
    getCityName(geoObject) {
      return geoObject.getLocalities().length
        ? geoObject.getLocalities().join(", ")
        : geoObject.getAdministrativeAreas().join(", ");
    },
    onDragEnd(event) {
      const coords = event.originalEvent.target.geometry.getCoordinates();
      this.setAddressByCoords(coords);
      this.mapCoords = coords;
      this.timezone = tzlookup(coords[0], coords[1]);
    },
  },
};
</script>

<style scoped>
.map-wrapper {
  min-width: 100%;
  height: 250px;
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 30px;
}
.disabled-text label {
  opacity: 0.5;
}
</style>
