<template>
  <div class="bk-full">
    <p
      class="hint-title"
      :style="{
        color: isThemeWidget ? customStyle.active_text_color : '',
      }"
    >
      Количество участников
    </p>
    <el-input-number
      :value="value"
      class="w-full"
      :class="{
        'custom-style': isThemeWidget,
      }"
      :style="{
        background: isThemeWidget ? customStyle.background_cells_fields : '',
      }"
      :min="1"
      :max="maxCount"
      @input="$emit('input', $event), emitData($event)"
    ></el-input-number>
  </div>
</template>

<script>
export default {
  name: "NotLimitSeats",
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    selectCell: {
      type: Object,
      default: () => {},
    },
    settings: {
      type: Object,
      default: () => {},
    },
    customStyle: { type: Object },
    isThemeWidget: { type: String },
  },
  data() {
    return {};
  },
  computed: {
    maxCount() {
      return Number(this.settings.data?.to) || 99999;
    },
  },
  created() {
    this.$emit("isDis", true);
    this.isClass();
  },
  methods: {
    changePersons(e) {
      this.emitData(e);
    },
    isClass() {
      document.body.style.setProperty(
        "--colorCustom",
        `${this.customStyle.active_text_color}`
      );
      document.body.style.setProperty(
        "--colorCustomBackground",
        `${this.customStyle.inactive_text_color}`
      );
    },

    emitData(e) {
      let dateSlot = "";
      let timeSlot = "";
      if (e.dateParam || e.timeParam) {
        const { dateParam, timeParam } = e;
        dateSlot = dateParam;
        timeSlot = timeParam;
      } else {
        dateSlot = this.selectCell.date;
        timeSlot = this.selectCell.time;
      }

      this.$emit("setSlotParam", {
        resourceHash: this.selectCell.resource_id,
        date: dateSlot, // this.selectCell.date,
        time: timeSlot, // this.selectCell.time,
        cnt: this.value, // this.hours,
        book_info: {
          individual: {
            persons: this.value, // this.hours,
            // eslint-disable-next-line no-nested-ternary
            price: this.selectCell.is_holiday
              ? this.selectCell.price_weekend + this.selectCell.price_additional
              : this.selectCell.discount
              ? this.selectCell.discount_price +
                this.selectCell.weekend_price_additional
              : this.price + this.selectCell.weekend_price_additional,
            // this.selectCell.price +
            //   this.selectCell.weekend_price_additional,
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

:root {
  --colorCustom: "black";
  --colorCustomBackground: "green";
}
.custom-style {
  ::v-deep .el-input__inner {
    color: var(--colorCustom);
  }

  ::v-deep .el-input-number__increase,
  ::v-deep .el-input-number__decrease {
    background: var(--colorCustomBackground);
    color: var(--colorCustom);
  }
  ::v-deep .el-input-number__increase:hover,
  ::v-deep .el-input-number__decrease:hover {
    color: var(--colorCustomButton);
  }
}
</style>
