<template>
  <div class="bk-full">
    <p class="title-widget">Билеты</p>

    <div class="">
      <div v-if="isAmountSlot && isGeneralLimit" class="bk-info my-5">
        <p class="bk-info__title">Осталось мест</p>
        <p class="bk-info__info">
          {{ validTick }} из
          {{ isAmountSlot.total }}
        </p>
      </div>
      <div v-for="(ticket, i) in listTicket" :key="i" class="input-number">
        <div class="input-number__info">
          <span class="input-number__info-title">{{ ticket.name }}</span>
          <span class="input-number__info-subtitle">
            <span v-if="!itemList.status" class="input-number__info-subtitle">
              {{
                isHoliday && ticket.holidays_price
                  ? ticket.holidays_price +
                    itemList.item.weekend_price_additional
                  : ticket.base_price + itemList.item.price_additional
              }}
            </span>
            <span v-else
              >{{
                isHoliday && ticket.holidays_price
                  ? ticket.holidays_price +
                    itemList.item.weekend_price_additional
                  : ticket.price + itemList.item.price_additional
              }}
            </span>
            <span class="input-number__info--currency">{{
              itemList.item.currency || itemList.currency
            }}</span>

            <span v-if="!isGeneralLimit"> •</span>
            <span v-if="!isGeneralLimit"
              >{{ ticket.tickets_remained }} шт.</span
            >
          </span>
        </div>
        <el-input-number
          v-model="ticket.select_ticket"
          class="input-number__input"
          :min="0"
          :max="Number(ticket.tickets_remained) + 1"
          :controls="true"
          @input="emitData()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { AJAX_POST } from "@/units/ajax";

import { mapActions, mapState } from "vuex";
import { el } from "date-fns/locale";

export default {
  name: "LimitAllGroup",
  props: {
    settings: {
      type: Object,
    },

    itemList: {
      type: Object,
    },
    hash: {
      type: String,
    },
    slotsValid: {
      type: Object,
    },
    dateParam: {
      type: String,
    },
  },
  data() {
    return {
      listTicket: [],
      validTick: [],
    };
  },
  computed: {
    ...mapState("widget", ["slotDetails"]),

    isHoliday() {
      return this.itemList.item.is_holiday;
    },
    isGeneralLimit() {
      return this.settings.data.seat_limit === "general_limits";
    },
    isAmountSlot() {
      return {
        total: this.slotDetails.amount.total, // this.settings.data.group_general_limits.tickets,
        remined: this.slotDetails.amount.remained, // this.settings.data.group_general_limits.tickets_remained,
      };
    },
  },
  created() {
    this.setListTickets();
    this.getSlotCheck({
      book_info: {
        tickets: this.listTicket,
        add_options_input: this.slotDetails?.booking?.book_info
          ?.add_options_input,
      },
      resourceHash: this.hash,
      date: this.dateParam, // this.itemList.item.dateBlock,
      time: this.itemList.item.time,
      cnt: null,
      tickets: this.listTicket,
      schedule_id: this.itemList.schedule_id,
      bookingHash: this.slotDetails.booking?.hash ?? null,
    });
  },
  methods: {
    ...mapActions("widget", ["saveBooking", "getRemainedTickets"]),
    setListTickets() {
      if (!this.itemList.status) {
        this.listTicket = !this.isGeneralLimit
          ? // eslint-disable-next-line no-shadow
            this.settings.data.group_ticket_type_limit.map((el, idx) => {
              return idx === 0
                ? { ...el, select_ticket: 1 }
                : { ...el, select_ticket: 0 };
            })
          : // eslint-disable-next-line no-shadow
            this.settings.data.group_general_limits.group.map((el, idx) => {
              return idx === 0
                ? {
                    ...el,
                    select_ticket: 1,
                    tickets: this.settings.data.group_general_limits.tickets,
                  }
                : {
                    ...el,
                    select_ticket: 0,
                    tickets: this.settings.data.group_general_limits.tickets,
                  };
            });
      } else {
        this.listTicket = this.slotDetails.booking.book_info.tickets.map(
          (e, idx) => {
            return { ...e, select_ticket: e.select_ticket };
          }
        );
      }
    },
    async getSlotCheck(param) {
      await AJAX_POST("/api/v1/booking/check_slots_validity", { ...param })
        .then((result) => {
          if (result.data.success === false) {
            this.$store.commit("widget/setField", {
              field: "slotsValidity",
              value: result.data,
            });
            // commit("setField", { field: "slotsValidity", value: data, });
            console.log(result.data.msg);
          } else {
            this.$store.commit("widget/setField", {
              field: "slotsValidity",
              value: result.data,
            });

            this.validTick = !this.isGeneralLimit
              ? result.data.tickets.map((e) => e)
              : result.data.tickets;
            this.listTicket = !this.isGeneralLimit
              ? this.listTicket.map((e, idx) => {
                  return idx === 0
                    ? {
                        ...e,
                        select_ticket: e.select_ticket,
                        ...this.validTick[idx],
                      }
                    : {
                        ...e,
                        select_ticket: e.select_ticket,
                        ...this.validTick[idx],
                      };
                })
              : this.listTicket.map((e, idx) => {
                  return idx === 0
                    ? {
                        ...e,
                        select_ticket: e.select_ticket,
                      }
                    : {
                        ...e,
                        select_ticket: e.select_ticket,
                      };
                });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emitData() {
      this.$emit(
        "setTickets",
        this.listTicket
          // eslint-disable-next-line no-shadow
          .filter((el) => el.select_ticket)
          .map((item) => {
            return {
              name: item.name,
              price: this.isHoliday
                ? item.holidays_price +
                  (this.itemList.item.weekend_price_additional || 0)
                : (item.base_price || item.price) +
                  this.itemList.item.price_additional,
              select_ticket: item.select_ticket,
              id: item.id,
            };
          })
      );

      this.getSlotCheck({
        book_info: {
          add_options_input: this.slotDetails?.booking?.book_info
            ?.add_options_input,

          tickets: this.listTicket
            // eslint-disable-next-line no-shadow
            .filter((el) => el)
            .map((item) => {
              return {
                name: item.name,
                price: this.isHoliday
                  ? item.holidays_price +
                    this.itemList.item.weekend_price_additional
                  : item.base_price + this.itemList.item.price_additional,
                select_ticket: item.select_ticket,
                id: item.id,
              };
            }),
        },
        bookingHash: this.slotDetails?.booking?.hash,
        tickets: this.listTicket,
        schedule_id: this.itemList.item.schedule_id,
        resourceHash: this.hash,
        date: this.dateParam,
        time: this.itemList.item.time,
        cnt: 1,
      });
      // Разобрать метод для выбора
      this.listTicket = this.listTicket.map((e, idx) => {
        return idx === 0
          ? { ...e, select_ticket: e.select_ticket, ...this.validTick[idx] }
          : { ...e, select_ticket: e.select_ticket, ...this.validTick[idx] };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.row-info {
  margin-bottom: 20px;
  &__title {
    width: auto;
    flex-grow: 1;
    color: $--color-white;
  }
  &__value {
    flex-grow: 0;
  }
}
.input-number__info-subtitle span {
  &:first-child {
    color: $--color-white;
    margin-right: 6px;
  }
  &:last-child {
    color: $--color-white-5;
    margin-left: 6px;
  }
}
.input-number__info--currency {
  color: white !important;
  margin-left: 0 !important;
}
</style>
