<template>
  <div ref="widget" class="custom-block schedule">
    <div
      class="schedule__title"
      :style="{ color: isIndividualTheme ? custom.active_text_color : '' }"
    >
      {{ isMaket ? "Игра престолов" : service.name }}
    </div>
    <div
      class="schedule__sub-title"
      :style="{
        color: isIndividualTheme ? custom.inactive_text_color : '',
      }"
    >
      {{
        isMaket
          ? "Квест в реальности"
          : getNamesKindActivity(service.kind_of_activity_name)
      }}
    </div>
    <div class="flex">
      <div class="schedule__item-tags" :title="service.age_comments">
        <tag
          :style="{
            color: isIndividualTheme ? custom.active_text_color : '',
            background: isIndividualTheme ? custom.background_cells_fields : '',
          }"
        >
          {{ service.age_limit }}</tag
        >
      </div>
      <div
        v-if="isMaket ? widget.showTag : isShowTags || service.tags"
        class="schedule__item-tags"
      >
        <tag
          v-for="(tag, i) in service.tags"
          :key="`tag-${i}`"
          :style="{
            color: isIndividualTheme ? custom.active_text_color : '',
            background: isIndividualTheme ? custom.background_cells_fields : '',
          }"
        >
          {{ tag }}
        </tag>
      </div>
    </div>
    <div v-if="isLoading && !isMaket">
      <spinner
        v-if="isLoading"
        :custom-color="{ border: `${custom.active_buttons_background}` }"
      />
      <div class="flex justify-center mt-10">
        <span
          class="text-14"
          :style="{
            color: isIndividualTheme ? custom.active_text_color : '',
          }"
          >Подождите, пожалуйста. Загружаем расписание</span
        >
      </div>
    </div>
    <template v-else-if="true">
      <div
        v-for="(item, i) in schedule.data"
        :key="i"
        class="schedule__item md:flex-col flex-row"
      >
        <div class="schedule__item-name">
          <div
            class="schedule__item-name__main"
            :style="{
              color: isIndividualTheme ? custom.active_text_color : '',
            }"
          >
            {{ getNameDate(item.date) }}
          </div>
          <div
            class="schedule__item-name__sub"
            :style="[
              {
                color: isIndividualTheme ? custom.inactive_text_color : '',
              },
              {
                color:
                  item.value[0].day_id === 6 || item.value[0].day_id === 7
                    ? custom.active_buttons_background
                    : custom.inactive_text_color,
              },
            ]"
          >
            {{ getNameDay(item.date) }}
          </div>
        </div>
        <div class="schedule__item-schedule">
          <div v-if="!item.value.length" class="custom-alert not-set-schedule">
            {{ item.value.msg }}
          </div>
          <CellSchedule
            v-for="(slot, index) in item.value"
            v-else
            :key="index"
            :value="slot"
            :is-maket="isMaket"
            :custom-style="custom"
            :size="isIndividualTheme ? custom.font_size : 'small'"
            :is-type-theme="isIndividualTheme"
            :show-price="showPrice"
            :add-pristavka="addPristavka"
            @click="onClick(item, slot)"
          />
        </div>
      </div>
      <div class="schedule__actions">
        <el-button
          type="primary"
          class="custom-button"
          :style="{
            background: isIndividualTheme
              ? custom.active_buttons_background
              : '',
            color: isIndividualTheme ? custom.button_text_color : '',
          }"
          :disabled="schedule.current_page === 1 || isMaket"
          @click="toSchedule(-1)"
        >
          <i class="el-icon-back"></i>
          {{ !$mq.phoneMobileLarge ? "Пред. неделя" : "Предыдущая неделя" }}
        </el-button>
        <el-button
          type="primary"
          class="custom-button"
          :style="{
            background: isIndividualTheme
              ? custom.active_buttons_background
              : '',
            color: isIndividualTheme ? custom.button_text_color : '',
          }"
          :disabled="schedule.current_page === schedule.last_page || isMaket"
          @click="toSchedule(1)"
        >
          {{ !$mq.phoneMobileLarge ? "След. неделя" : "Следующая неделя" }}
          <i class="el-icon-right"></i>
        </el-button>
      </div>
    </template>
    <div v-else class="qwerty">
      Вы пока не настроили расписание. <br />
      Сделать это можно в разделе Даты и цены
    </div>

    <el-dialog v-if="isShowForm" :visible.sync="isShowForm" :show-close="false">
      <TemplateFormBron
        class="w-full"
        :title="isMaket ? `Игра престолов` : service.name"
        :sub-title="
          isMaket
            ? `Квест в реальности`
            : getNamesKindActivity(service.kind_of_activity_name)
        "
        :style-custom="custom"
        :is-type-theme="isIndividualTheme"
        :total-price="isMaket ? 1600 : totalPrice"
        :list-field-show="widgetPublic.fieldForm"
        :select-cell="selectCell"
        :is-maket="isMaket"
        :loading="loading"
        :is-service="isService"
        :select-service="selectService"
        :disabled="isDisabled"
        :price-disc="priceDisc"
        @setService="setService"
        @close="isShowForm = false"
        @booking="broni"
      >
        <div class="row-info xs:text-12">
          <div
            class="row-info__title"
            :style="{
              color: isIndividualTheme ? custom.inactive_text_color : '',
            }"
          >
            Дата
          </div>
          <div
            class="row-info__value"
            :style="{
              color: isIndividualTheme ? custom.active_text_color : '',
            }"
          >
            {{ isMaket ? "04.02.2021" : formatedDate }}
          </div>
        </div>
        <div class="row-info xs:text-12">
          <div
            class="row-info__title"
            :style="{
              color: isIndividualTheme ? custom.inactive_text_color : '',
            }"
          >
            Время
          </div>
          <div
            class="row-info__value"
            :style="{
              color: isIndividualTheme ? custom.active_text_color : '',
            }"
          >
            {{ isMaket ? "10:30" : selectCell.time }}
          </div>
        </div>

        <div
          v-if="originData.isHidePrice && selectCell.price > 0"
          class="row-info xs:text-12"
        >
          <div
            class="row-info__title"
            :style="{
              color: isIndividualTheme ? custom.inactive_text_color : '',
            }"
          >
            Стоимость
          </div>

          <div
            class="row-info__value"
            :style="{
              color: isIndividualTheme ? custom.active_text_color : '',
            }"
          >
            {{ originData.priceAfter }}
            {{ isMaket ? "1 600" : selectCell.price }}
            {{ selectCell.currency }} {{ originData.priceBefore }}
            {{
              originData.component.name === "DepTarifSeats" ? "за место" : ""
            }}
          </div>
        </div>
        <div
          v-if="originData.isHidePrice && selectCell.discount"
          class="row-info xs:text-12"
        >
          <div
            class="row-info__title"
            :style="{
              color: isIndividualTheme ? custom.inactive_text_color : '',
            }"
          >
            Скидка
          </div>

          <div
            class="row-info__value"
            :style="{
              color: isIndividualTheme ? custom.active_text_color : '',
            }"
          >
            <!-- {{ originData.priceAfter }} -->
            {{
              isMaket
                ? "20%"
                : selectCell.discount
                ? selectCell.discount_value
                : selectCell.price
            }}

            {{
              selectCell.discount_type === "percent"
                ? "%"
                : `${selectCell.currency}`
            }}
            <!-- {{ originData.priceBefore }}
            {{
              originData.component.name === "DepTarifSeats" ? "за место" : ""
            }} -->
          </div>
        </div>
        <div
          class="hl"
          :style="{
            background:
              widgetPublic.typeTheme === 'light' ||
              widgetPublic.typeTheme === 'dark' ||
              widgetPublic.typeTheme === 'individual'
                ? custom.background_cells_fields
                : '',
          }"
        ></div>
        <components
          :is="originData.component"
          v-model="person"
          :select-cell="selectCell"
          :hash="service"
          :settings="service.BookingType"
          :slots-validity="slotsValidity"
          :custom-style="custom"
          :is-theme-widget="widgetPublic.typeTheme"
          @isDis="isDis"
          @setPrice="setPrice"
          @setService="setService"
          @setTariff="setTariff"
          @setTickets="setTickets"
          @setHours="setHours"
          @setPlaces="setPlaces"
          @setDiscount="setDiscount"
          @setRent="setRent"
          @setRentInfo="setRentInfo"
          @SetForGroup="setForGroup"
          @setSlotParam="getSlotParam"
          @isLoadingEmit="isLoadingEmit"
        ></components>
        <div
          v-if="showHeaderAddOptions"
          class="title-widget"
          :style="{ color: isIndividualTheme ? custom.active_text_color : '' }"
        >
          Дополнительные опции
        </div>
        <div class="bk-full">
          <el-checkbox-group v-model="addOptCheck">
            <el-checkbox
              v-for="(item, i) in listAddOptCheck"
              :key="`addOptCheck-${i}`"
              :label="item"
              :class="[
                {
                  'custom-style':
                    widgetPublic.typeTheme === 'light' ||
                    widgetPublic.typeTheme === 'dark' ||
                    widgetPublic.typeTheme === 'individual',
                },
                {
                  'single-grid':
                    widgetPublic.typeTheme === 'light' ||
                    widgetPublic.typeTheme === 'dark' ||
                    widgetPublic.typeTheme === 'individual',
                },
              ]"
            >
              <span
                class="custom-check__title"
                :style="{
                  color: isIndividualTheme ? custom.active_text_color : '',
                }"
                >{{ item.name }}</span
              >
              <span
                v-if="item.price"
                class="custom-check__subtitle"
                :style="{
                  color: isIndividualTheme ? custom.inactive_text_color : '',
                }"
              >
                {{ item.price }} {{ selectCell.currency }}
              </span>
            </el-checkbox>
          </el-checkbox-group>
          <div
            v-for="(item, i) in addOptInput"
            :key="`input-${i}`"
            class="input-number"
            :style="{
              color: isIndividualTheme ? custom.active_text_color : '',
            }"
            :label="1"
          >
            <div class="input-number__info">
              <span
                class="input-number__info-title"
                :style="{
                  color: isIndividualTheme ? custom.active_text_color : '',
                }"
                >{{ item.name }}</span
              >
              <span
                class="input-number__info-subtitle"
                :style="{
                  color: isIndividualTheme ? custom.inactive_text_color : '',
                }"
              >
                {{ item.price ? `${item.price} ` : "Бесплатно" }}
                {{ selectCell.currency }}
              </span>
            </div>
            <el-input-number
              v-model="item.selected"
              class="input-number__input"
              :class="[
                {
                  'custom-style':
                    widgetPublic.typeTheme === 'light' ||
                    widgetPublic.typeTheme === 'dark' ||
                    widgetPublic.typeTheme === 'individual',
                },
                {
                  'single-grid':
                    widgetPublic.typeTheme === 'light' ||
                    widgetPublic.typeTheme === 'dark' ||
                    widgetPublic.typeTheme === 'individual',
                },
              ]"
              :style="{
                background:
                  widgetPublic.typeTheme === 'light' ||
                  widgetPublic.typeTheme === 'dark' ||
                  widgetPublic.typeTheme === 'individual'
                    ? custom.background_cells_fields
                    : '',
              }"
              :max="item.amount"
              :min="0"
            />
          </div>
          <div v-if="slotsValidity" class="my-10">
            <el-alert
              v-if="slotsValidity.msg"
              :title="slotsValidity.msg"
              type="warning"
              :closable="false"
              class="w-full"
              center
            />
          </div>
        </div>
      </TemplateFormBron>
    </el-dialog>
    <el-dialog
      v-if="isShowPay"
      :visible.sync="isShowPay"
      :show-close="false"
      width="450px"
      :before-close="reloadWidget"
      @reloadWidget="isShowPay = false"
    >
      <PayWidget
        v-if="dataPay.success"
        :widget-theme="widgetPublic.typeTheme"
        :custom-class="custom"
        @close="isShowPay = false"
        @reloadWidget="reloadWidget"
      />
      <PayError
        v-if="!dataPay.success"
        :data-pay="dataPay.params"
        :widget-theme="widgetPublic.typeTheme"
        :custom-class="custom"
        :error-title="dataPay.params ? dataPay.params.title : dataPay.title"
        :msg="errorMessage"
        @close="isShowPay = false"
      />
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { monthNames, dayNames } from "@/helpers/date.js";
import { scheduleOneGrid } from "@/helpers/maket.js";
import { format, getDay, getDate, getMonth } from "date-fns";
import Tag from "@/components/Tag";

import CellSchedule from "@/components/CellSchedule.vue";
import NotLimitSeats from "@/views/Widgets/components/forWidget/NotLimitSeats.vue";
import ServiceTreeGiven from "@/views/Widgets/components/forWidget/ServiceTreeGiven";

import DepSeatsPlace from "@/views/Widgets/components/forWidget/DepSeatsPlace.vue";
import DepSeatsGroup from "@/views/Widgets/components/forWidget/DepSeatsGroup.vue";
import DepServiceDuration from "@/views/Widgets/components/forWidget/DepServiceDuration.vue";
import DepTarifSeats from "@/views/Widgets/components/forWidget/DepTarifSeats.vue";
import GroupLimitAll from "@/views/Widgets/components/forWidget/GroupLimitAll.vue";
import GroupLimitType from "@/views/Widgets/components/forWidget/GroupLimitType.vue";
import GroupLimitGeneral from "@/views/Widgets/components/forWidget/GroupLimitGeneral.vue";
import RentDepTime from "@/views/Widgets/components/forWidget/RentDepTime.vue";
import RentDepTimeSeats from "@/views/Widgets/components/forWidget/RentDepTimeSeats.vue";
import PayWidget from "@/views/Widgets/components/PayWidget.vue";
import PayError from "@/views/Widgets/components/PayError.vue";
import { setFavicon } from "@/helpers/forFavicon.js";
import { AJAX_GET, AJAX_POST } from "../../../units/ajax";
import { setField, setFieldObj } from "@/helpers/store";
import widgetDesign from "@/views/Widgets/components/WidgetDesign";

export default {
  name: "ScheduleWidgetSingleGrid",
  components: {
    CellSchedule,
    Tag,
    ServiceTreeGiven,
    NotLimitSeats,
    DepSeatsPlace,
    DepSeatsGroup,
    DepServiceDuration,
    DepTarifSeats,
    GroupLimitAll,
    GroupLimitType,
    GroupLimitGeneral,
    RentDepTime,
    RentDepTimeSeats,
    PayWidget,
    PayError,
  },
  props: {
    isMaket: Boolean,
    showPrice: Boolean,
  },
  data() {
    return {
      dis: null,
      discount: null,
      isLoading: true,
      isShowForm: false,
      isShowPay: false,
      selectCell: {},
      loading: false,
      places: 1,
      person: 1,
      hours: null,
      totalPerson: null,
      addOptCheck: [],
      addOptInput: [],
      price: null,
      selectService: null,
      selectTariff: null,
      selectTickets: null,
      rent: null,
      rentPlacesInfo: null,
      group: null,
      addPersons: 0,
      priceAddPersons: 0,
      analyticsYandex: null,
      analyticsGoogle: null,
    };
  },
  computed: {
    ...mapState("service", ["schedule"]),
    ...mapState("user", ["user"]),

    ...mapState("widget", [
      "widget",
      "widgetPublic",
      "dataPay",
      "slotsValidity",
    ]),
    ...mapGetters("widget", ["getFaviconLicense", "someWidgetDesign", "isNew"]),
    ...mapGetters("service", ["isHaveAddOpt", "addPristavka"]),
    isStep() {
      return this.hours / this.service.BookingType.data.session_duration;
    },
    priceDisc() {
      if (this.selectCell.discount_type === "percent") {
        return (
          this.totalPrice -
          (this.totalPrice * this.selectCell.discount_value) / 100
        );
      }
      return this.totalPrice - this.selectCell.discount_value;
    },
    unitCahnge() {
      switch (this.service.BookingType.data.time_unit) {
        case "days":
          return {
            text: "дней",
            param: "day",
            time: this.hours,
            price: this.hours,
          };
        case "hours":
          return {
            text: "часов",
            param: "hour",
            time: this.hours,
            price: this.hours,
          };
        case "minutes":
          return {
            text: "минут",
            param: "minute",
            time: this.hours,
            price: this.hours,
          };

        default:
          return this.service.BookingType.data.time_unit;
      }
    },
    isShowTags() {
      return this.widgetPublic.showTag;
    },
    service() {
      return this.widgetPublic.resources
        ? this.widgetPublic.resources[0]
        : this.user.resources.find((e) => e.hash);
    },

    isIndividualTheme() {
      // УБРАЛ ДЛЯ КАСТОМИЗАЦИИ ВИДЖЕТА ВНЕ ЗАВИСИМОСТИ ОТ  ТЕМЫ
      // this.widgetPublic.typeTheme === "individual"
      return !!this.widgetPublic.typeTheme || this.isMaket;
    },
    isWidg() {
      return this.someWidgetDesign;
    },

    custom() {
      const designWidgetParams = this.isMaket
        ? this.widget.design
        : this.widgetPublic.design;
      return {
        ...designWidgetParams,
      };
    },
    errorMessage() {
      return (
        this.dataPay.msg ||
        (this.dataPay.params?.error_title ?? "Попробуйте забронировать позже")
      );
    },
    isErrorDataPay() {
      return this.dataPay?.payment_data[0]?.status_name ===
        "Время оплаты истекло"
        ? this.dataPay?.payment_data[0]?.status_name
        : false;
    },
    isService() {
      return (
        this.service.BookingType.booking_type_sub === "service_tree_is_given" ||
        this.service.BookingType.booking_type_sub ===
          "service_tree_from_settings"
      );
    },
    isGroup() {
      return (
        this.service.BookingType.booking_type_sub ===
          "group_same_seat_limit_for_all_sessions" ||
        this.service.BookingType.booking_type_sub ===
          "group_the_seat_limit_is_set_for_each_sess" ||
        this.service.BookingType.booking_type_sub ===
          "individual_depends_on_the_number_of_seats"
      );
    },
    isIndividual() {
      return (
        this.service.BookingType.booking_type_sub ===
          "individual_does_not_depend_on_the_number_of_seats" ||
        (this.service.BookingType.booking_type_sub ===
          "individual_depends_on_the_number_of_seats" &&
          this.service.BookingType.data.price === "for_a_place")
      );
    },
    originData() {
      switch (this.service.BookingType.booking_type_sub) {
        case "service_tree_is_given":
          return {
            component: ServiceTreeGiven,
            priceAfter: "",
            priceBefore: "",
            isHidePrice: true,
            totalPrice: (srcObj) => 0, // srcObj.price,
          };
        case "service_tree_from_settings":
          return {
            component: ServiceTreeGiven,
            priceAfter: "",
            priceBefore: "",
            isHidePrice: true,
            totalPrice: (srcObj) => 0, // srcObj.price,
          };
        case "individual_does_not_depend_on_the_number_of_seats":
          return {
            component: NotLimitSeats,
            priceAfter: "",
            priceBefore: "",
            isHidePrice: true,
            totalPrice: (srcObj) =>
              srcObj.selectCell.discount
                ? srcObj.selectCell.discount_price
                : srcObj.price,
          };
        case "individual_depends_on_the_number_of_seats":
          // eslint-disable-next-line no-case-declarations
          const isForPlace =
            this.service.BookingType.data.price === "for_a_place";

          return {
            component: isForPlace ? DepSeatsPlace : DepSeatsGroup,
            priceAfter: "от",
            priceBefore: isForPlace ? "за место" : "за группу",
            isHidePrice: true,
            totalPrice: (srcObj) => {
              if (isForPlace)
                return (
                  srcObj.person * srcObj.price
                  // +
                  // srcObj.selectCell?.price_additional
                );
              // eslint-disable-next-line no-nested-ternary
              return srcObj.group
                ? srcObj.selectCell.is_holiday
                  ? srcObj.group.price +
                    srcObj.addPersons * srcObj.priceAddPersons +
                    srcObj.selectCell.weekend_price_additional
                  : // srcObj.selectCell?.weekend_price_additional +
                    // srcObj.selectCell?.weekend_price_additional
                    srcObj.group.price +
                    srcObj.addPersons * srcObj.priceAddPersons +
                    // srcObj.selectCell?.weekend_price_additional +
                    srcObj.selectCell?.price_additional
                : 0;
            },
          };
        case "individual_depends_on_the_service_and_its_duration":
          return {
            component: DepServiceDuration,
            priceAfter: "",
            priceBefore: "",
            isHidePrice: false,
            // totalPrice: (srcObj) => srcObj.person * srcObj.price,
            totalPrice: (srcObj) => srcObj.selectService?.price,
          };
        case "individual_depends_on_tariff_and_number_of_seats":
          return {
            component: DepTarifSeats,
            priceAfter: "от",
            priceBefore: "",
            isHidePrice: true,
            totalPrice: (srcObj) => srcObj.person * srcObj.price,
          };
        case "group_same_seat_limit_for_all_sessions":
          return {
            component:
              this.service.BookingType.data.seat_limit === "general_limits"
                ? GroupLimitGeneral
                : GroupLimitAll,
            priceAfter: "от",
            priceBefore: "",
            isHidePrice: true,
            totalPrice: (srcObj) => {
              return srcObj.selectTickets
                ? srcObj.selectTickets.reduce((sum, current) => {
                    return sum + current.select_ticket * current.price;
                  }, 0)
                : 0;
            },
          };
        case "group_the_seat_limit_is_set_for_each_session":
          return {
            component: GroupLimitType,
            priceAfter: "от",
            priceBefore: "",
            isHidePrice: true,
            totalPrice: (srcObj) => {
              return srcObj.selectTickets
                ? srcObj.selectTickets.reduce((sum, current) => {
                    return sum + current.select_ticket * current.price;
                  }, 0)
                : 0;
            },
          };
        case "rent_depends_only_on_time":
          return {
            component: RentDepTime,
            priceAfter: "от",
            priceBefore: `за ${this.service.BookingType.data.session_duration} ${this.unitCahnge.text}`,
            isHidePrice: true,
            totalPrice: (srcObj) =>
              this.service.BookingType.data.time_unit === "minutes"
                ? srcObj.price *
                  Math.ceil(
                    srcObj.hours /
                      this.service.BookingType.data.session_duration
                  )
                : srcObj.price * srcObj.hours,
          };
        case "rent_depends_on_time_and_place":
          return {
            component: RentDepTimeSeats,
            priceAfter: "от",
            priceBefore: "за место в час",
            isHidePrice: true,

            totalPrice: (srcObj) => {
              const isProcent = srcObj?.discount?.unit === "procent";

              const isDiscount =
                srcObj?.discount && isProcent
                  ? (srcObj?.price * srcObj?.discount?.discount) / 100
                  : srcObj?.discount?.discount;
              const isHoliday = this.selectCell.is_holiday;

              return isDiscount
                ? (isHoliday
                    ? srcObj.price - isDiscount
                    : srcObj.price - isDiscount) *
                    srcObj.places *
                    srcObj.hours
                : srcObj.price * srcObj.places ?? 1;

              // (srcObj.price - isDiscount) * srcObj.hours * srcObj.places +
              // Number(isDiscount)
            },
            // srcObj.price * (srcObj.hours * srcObj.places)),
          };
        default:
          return {};
      }
    },
    showHeaderAddOptions() {
      return this.listAddOptCheck.length || this.addOptInput.length;
    },
    totalPrice() {
      const sumCheck = this.addOptCheck.reduce((sum, current) => {
        return sum + current.price;
      }, 0);
      const sumInput = this.addOptInput.reduce((sum, current) => {
        return sum + current.selected * current.price;
      }, 0);
      const isProcent = this.discount?.unit === "procent";
      const isDiscount =
        this.discount && isProcent
          ? (this.originData.totalPrice(this.$data) * this.discount.discount) /
            100
          : this.discount?.discount;

      return isDiscount
        ? this.originData.totalPrice(this.$data) - sumCheck + sumInput
        : this.originData.totalPrice(this.$data) + sumCheck + sumInput;
    },
    formatedDate() {
      return this.selectCell.date
        ? format(new Date(this.selectCell.date), "dd.MM.yyyy")
        : "";
    },
    listAddOptCheck() {
      return this.service.additional_options.filter((item) => !item.amount);
    },
    isDisabled() {
      return this.slotsValidity || !this.dis
        ? this.slotsValidity?.success
        : true;
    },
  },
  watch: {
    isWidg: {
      handler(val) {
        //  console.log(this.isWidg);
      },
      immediate: true,
    },
  },

  async created() {
    this.getPayInfo();
    this.monthNames = monthNames;
    this.dayNames = dayNames;

    if (this.isMaket) {
      this.setField({
        field: "schedule",
        value: {
          data: { ...scheduleOneGrid },
        },
      });
    } else {
      // await this.getSchedule({
      //   hash: this.service.hash,
      //   widgetHash: this.$route.params.hash,
      // });
      await this.widgetGetScheduleByPeriodOrByDate({
        hash: this.service.hash,
        widgetHash: this.$route.params.hash,
      });
      setFavicon({ title: this.service.name, href: this.getFaviconLicense });
    }

    this.addOptInput = this.service.additional_options
      .filter((item) => item.amount)
      .map((el) => {
        return {
          ...el,
          selected: null,
        };
      });

    if (
      this.widgetPublic.analytics_yandex &&
      this.widgetPublic.analytics_google
    ) {
      this.analyticsYandex = this.widgetPublic.analytics_yandex;
      this.analyticsGoogle = this.widgetPublic.analytics_google;
    }
    this.isClass();
    this.isLoading = false;
  },
  updated() {
    this.postMessage(
      this.isShowForm
        ? this.$refs.widgetDialogModal.$refs?.dialog.clientHeight
        : this.$refs.widget.clientHeight
    );
  },
  methods: {
    ...mapMutations("service", ["setField"]),
    ...mapActions("service", [
      "getSchedule",
      "widgetGetScheduleByPeriodOrByDate",
    ]),
    ...mapActions("widget", ["saveBooking", "checkSlotsValidity"]),

    isClass() {
      document.body.style.setProperty(
        "--colorCustom",
        `${this.custom.active_text_color}`
      );
      document.body.style.setProperty(
        "--colorCustomBackground",
        `${this.custom.inactive_text_color}`
      );
      document.body.style.setProperty(
        "--colorCustomButton",
        `${this.custom.active_buttons_background}`
      );
      document.body.style.setProperty(
        "--colorCustomTextInactive",
        `${this.custom.active_buttons_background}`
      );
    },
    isDis(item) {
      this.dis = !!item;
    },

    getNamesKindActivity(names) {
      return names.join(", ");
    },
    setForGroup(obj) {
      this.price = null;
      this.person = null;
      this.group = obj.group;
      this.addPersons = obj.addPersons;
      this.priceAddPersons = obj.priceAddPersons;
    },
    getSlotParam(obj) {
      this.checkSlotsValidity(obj);
    },
    async toSchedule(diff) {
      if (this.isMaket) return;
      let page = this.schedule.current_page + diff;
      if (page < 1) page = 1;
      if (page > this.schedule.last_page) page = this.schedule.last_page;
      this.isLoading = true;
      // await this.getSchedule({ hash: this.service.hash, page });
      await this.widgetGetScheduleByPeriodOrByDate({
        hash: this.service.hash,
        page,
        widgetHash: this.$route.params.hash,
      });

      this.isLoading = false;
    },
    getNameDay(date) {
      let index = getDay(new Date(date));
      if (!index) {
        index = 6;
      } else --index;
      return this.dayNames[index].title;
    },
    getNameDate(date) {
      return `${getDate(new Date(date))} ${this.monthNames[
        getMonth(new Date(date))
      ].title.toLowerCase()}`;
    },
    onClick(day, slot) {
      if (this.isMaket) return;
      if (this.analyticsYandex && this.analyticsGoogle) {
        const dinamicId = this.analyticsYandex;
        const dinamicIdGoogle = this.analyticsGoogle;
        ym(`${dinamicId}`, "init", {
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
          trackHash: true,
        });
        ym(`${dinamicId}`, "reachGoal", "slotClick");

        gtag("config", `${dinamicIdGoogle}`);
        gtag("event", "select_content", {
          event_category: "bookingCell",
          event_action: "clickSlot",
          event_label: "booking",
        });
      }

      this.isShowForm = true;
      this.selectCell = {
        date: day.date,
        ...slot,
      };
      this.price = slot.price;
    },
    setPrice(price) {
      const addCoast = this.selectCell.is_holiday
        ? this.selectCell.weekend_price_additional
        : this.selectCell.price_additional;
      this.price = price + addCoast;
    },
    setService(service) {
      this.price = null;
      this.person = null;
      this.selectService = { ...service };
    },
    setTariff(tariff) {
      this.selectTariff = { ...tariff };
    },
    setTickets(tickets) {
      this.selectTickets = [...tickets];
    },
    setHours(hours) {
      this.hours = hours;
    },
    setPlaces(places) {
      this.places = places;
    },
    setDiscount(discount) {
      this.discount = discount;
    },
    setRent(rent) {
      this.rent = rent;
    },
    setRentInfo(info) {
      this.rentPlacesInfo = info;
    },
    isLoadingEmit(load) {
      this.loading = load;
    },
    async broni(clientInfo) {
      clientInfo.add_params = [];
      const data = {
        visitor_id: this.isWidg?.visitor_id,
        analyticsYandex: this.analyticsYandex,
        resource_id: this.selectCell.resource_id,
        schedule_id: this.selectCell.id,
        date: this.selectCell.date,
        time: this.selectCell.time,
        client_info: clientInfo,
        widget_hash: this.$route.params.hash,
        book_info: {
          totalPriceDiscount:
            clientInfo.promocode || this.selectCell.discount
              ? this.totalPrice
              : null,
          individual: this.isIndividual
            ? {
                price: this.selectCell.discount
                  ? this.selectCell.discount_price
                  : this.price,
                persons: this.person,
              }
            : null,
          //  price: this.price,
          // person: this.person,
          total_price:
            clientInfo.promocode || this.selectCell.discount
              ? clientInfo.totalPriceDiscount || this.priceDisc
              : this.totalPrice,

          // ДОБАВИЛ ЦЕНУ БЕЗ СКИДКИ
          // priceDisc: this.selectCell.discount ? this.priceDisc : null,

          add_options_check: this.addOptCheck,
          add_options_input: this.addOptInput,
          tariff: this.selectTariff,
          service: this.selectService,
          tickets: this.selectTickets,
          rent: !this.isGroup ? { ...this.rent } : null,
          //   rentPlacesInfo: this.rentPlacesInfo,
          // individual_depends_on_the_number_of_seats
          group:
            this.isGroup &&
            this.service.BookingType.data.price !== "for_a_place" //  &&
              ? // this.service.BookingType.booking_type_sub !==
                //   "group_same_seat_limit_for_all_sessions"
                {
                  ...this.group,
                  add_persons: this.addPersons,
                  price_add_persons: this.priceAddPersons,
                }
              : null,
        },
      };
      this.loading = true;
      await this.saveBooking(data);
      this.loading = false;
      this.isShowForm = false;
      this.isShowPay = true;
    },

    reloadWidget() {
      this.$emit("reloadWidget");
      this.widgetGetScheduleByPeriodOrByDate({
        hash: this.service.hash,
        widgetHash: this.$route.params.hash,
      });

      // this.getSchedule({
      //   hash: this.service.hash,
      //   widgetHash: this.$route.params.hash,
      // });
    },
    postMessage(height) {
      const win = window.parent;
      win.postMessage({ hash: this.widgetPublic.hash, height }, "*");
    },
    async getPayInfo() {
      const param = this.$route.query;
      const keyPay = ["amp;success"];
      if (param.success || param[keyPay]) {
        await AJAX_GET("/api/v1/booking/get", {
          params: { booking_id: param.hash },
        }).then((response) => {
          this.setField({ field: "dataPay", value: response.data });
          this.dataPay.hidden = true;
          this.dataPay.success = response.data.is_paid;
          this.dataPay.name = response.data.name;
          this.dataPay.dateTime = response.data.date;
          this.dataPay.dateAndTime = response.data.time;
          this.dataPay.phone = response.data.phone;
          this.dataPay.price = response.data.final_price;
          this.dataPay.address = response.data.address;
          this.dataPay.currency = response.data.currency;
          this.dataPay.payment = response.data.payment;
          this.dataPay.payment_type_name = response.data.payment_type_name;
          this.dataPay.modal_text = response.data.modal_text;
          this.dataPay.consist = response.data.consist;
          this.dataPay.payment_data = response.data.payment_data;
          this.dataPay.params = response.data;
          this.isShowPay = true;
          this.$router.push({ name: "public-widget" });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";
:root {
  --colorCustom: "black";
  --colorCustomTextInactive: "black";
  --colorCustomBackground: "green";
  --colorCustomButton: "yellow";
}
.custom-block {
  border-radius: 0;
}
@media (max-width: 520px) {
  .el-dialog {
    width: 95% !important;
  }
  .schedule__actions {
    flex-direction: row;
    justify-content: center !important;
  }
  .schedule {
    padding: 20px 10px 10px !important;
    &__title {
      font-size: 18px !important;
      line-height: 22px !important;
    }
  }
}
.schedule {
  padding: 20px 30px 30px;
  margin-bottom: 20px;
  &__title {
    font-weight: 600;
    font-size: 22px;
    line-height: 36px;
    color: $--color-white;
    margin-bottom: 0;
  }
  &__sub-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: $--color-white-5;
    margin-bottom: 30px;
  }
  &__item {
    display: flex;
    // flex-direction: row;
    margin-bottom: 18px;

    &-tags {
      display: flex;
    }

    &-name {
      flex-shrink: 0;
      width: 110px;
      margin-right: 13px;
      display: flex;
      flex-direction: column;
      align-items: start;
      @media (max-width: 720px) {
        display: inline-flex;
        width: 200px;
        flex-direction: row;
        // justify-content: space-between;
      }
      &__main {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $--color-white;
        @media (max-width: 720px) {
          margin-right: 10px;
        }
      }
      &__sub {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: $--color-white-5;
        @media (max-width: 720px) {
          padding: 3px 0;
        }
      }
    }

    &-schedule {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      flex-grow: 1;
    }
  }
  &__actions {
    display: flex;
    // flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
}

::v-deep {
  .el-checkbox {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    margin-bottom: 20px;

    &__label,
    &-group {
      display: flex;
      flex-direction: column;
    }
    &__label {
      span {
        line-height: 1;
      }
    }
  }
}

.input-number {
  margin-bottom: 20px;
}
</style>

<style lang="scss">
@import "element-variables.scss";

.disabledSlot {
  pointer-events: none;
  .schedule__item-cell__time {
    color: $--color-white-5;
  }
}
@media (max-width: 480px) {
  .el-dialog {
    width: 95% !important;
  }
  .schedule__actions {
    flex-direction: column;
  }
  .schedule__title {
    font-size: 18px;
    line-height: 22px;
  }
}

.custom-style.single-grid {
  .el-input__inner {
    color: var(--colorCustom);
  }
  .el-input-number__increase,
  .el-input-number__decrease {
    background: var(--colorInactive);
    color: var(--colorCustom);
  }
  .el-input-number__increase:hover,
  .el-input-number__decrease:hover {
    color: var(--colorCustomButton);
  }
  ::v-deep .el-input {
    color: var(--colorCustom);
  }
}
.el-checkbox.is-checked.custom-style.single-grid
  > .el-checkbox__input.is-checked
  > .el-checkbox__inner {
  //::v-deep .el-checkbox__input {
  border-color: var(--colorCustomButton);
  color: var(--colorCustom);
  //}
}
.el-checkbox.custom-style.single-grid
  > .el-checkbox__input
  > .el-checkbox__inner {
  //::v-deep .el-checkbox__input {
  border-color: var(--colorCustom);
  color: var(--colorCustom);
  //}
}
.el-dialog {
  margin-top: 5vh !important;
}
</style>
