var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.settings.data.group),function(item,index){return _c('div',{key:index,staticClass:"bk-info"},[(
        (_vm.isHoliday && item.holidays_price
          ? item.holidays_price + _vm.selectCell.weekend_price_additional
          : item.base_price + _vm.selectCell.price_additional) > 0
      )?_c('div',{staticClass:"flex justify-between w-full"},[_c('p',{staticClass:"bk-info__title",style:({
          color: _vm.isThemeWidget ? _vm.customStyle.inactive_text_color : '',
        })},[_vm._v(" "+_vm._s(item.from ? ("от " + (item.from)) : "")+" "+_vm._s(item.to ? ("до " + (item.to) + " ") : "")+" "),_c('span',{style:({
            color: _vm.isThemeWidget ? _vm.customStyle.inactive_text_color : '',
          })},[_vm._v(_vm._s(("" + (_vm.unitCahnge.text))))])]),_c('p',{staticClass:"bk-info__info",style:({
          color: _vm.isThemeWidget ? _vm.customStyle.active_text_color : '',
        })},[_vm._v(" "+_vm._s(_vm.isHoliday && item.holidays_price ? item.holidays_price + _vm.selectCell.weekend_price_additional : item.base_price + _vm.selectCell.price_additional)+" "+_vm._s(_vm.selectCell.currency)+" "+_vm._s(item.from ? ("за " + (_vm.settings.data.session_duration)) : "")+" "),_c('span',[_vm._v(_vm._s(("" + (_vm.unitCahnge.text))))])])]):_vm._e()])}),_c('p',{staticClass:"title-widget",style:({
      color: _vm.isThemeWidget ? _vm.customStyle.active_text_color : '',
    })},[_vm._v(" Количество "+_vm._s(_vm.unitCahnge.text)+" ")]),(
      _vm.settings.data.time_unit === 'hours' ||
      _vm.settings.data.time_unit === 'days'
    )?_c('el-input-number',{staticClass:"w-full mb-12",class:{ 'custom-style': _vm.isThemeWidget },style:({
      color: _vm.isThemeWidget ? _vm.customStyle.active_text_color : '',
      background: _vm.isThemeWidget ? _vm.customStyle.background_cells_fields : '',
    }),attrs:{"min":_vm.minHours,"max":_vm.maxHours,"step":_vm.step},on:{"input":_vm.changePersons},model:{value:(_vm.hours),callback:function ($$v) {_vm.hours=$$v},expression:"hours"}}):_vm._e(),(_vm.settings.data.time_unit === 'minutes')?_c('el-input-number',{staticClass:"w-full mb-12",style:({
      color: _vm.isThemeWidget ? _vm.customStyle.active_text_color : '',
      background: _vm.isThemeWidget ? _vm.customStyle.background_cells_fields : '',
    }),attrs:{"min":_vm.minMinutes,"max":_vm.maxMinutes,"step":_vm.step},on:{"input":_vm.changePersons},model:{value:(_vm.hours),callback:function ($$v) {_vm.hours=$$v},expression:"hours"}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }