<template>
  <Block title="Уведомления">
    <el-switch
      v-model="items.send_news"
      active-text="Подписка на новости"
      class="mb-20"
      @change="updateInfo"
    >
    </el-switch>
    <!-- <el-switch
      v-model="items.send_browser"
      active-text="Уведомления в браузере"
      class="mb-30"
      disabled
      @change="updateInfo"
    >
    </el-switch> -->
    <div class="notifications__telegram">
      <div class="notifications__title">Telegram-бот</div>
      <span class="notifications__subtitle"
        >Позволяет получать уведомления общего характера: о балансе, новом
        функционале, напоминания и т.п.
      </span>
    </div>
    <div class="notifications__input">
      <label
        class="text-12 font-medium text-white-normal flex flex-col input-appended"
      >
        <span class="mb-7">Ваш уникальный код для добавления бота</span>
        <span class="flex sm:block">
          <el-input
            ref="telegram_input"
            v-model="items.telegram_code"
            readonly
            placeholder="Номер счетчика"
          ></el-input>
          <div class="flex items-center sm:ml-0 ml-20 sm:mt-20">
            <el-button class="mr-10" @click="copyText">Скопировать</el-button>
            <el-button
              :loading="loading"
              type="primary"
              class="bg-transparent"
              @click="updateCode"
              >Обновить</el-button
            >
          </div>
        </span>
      </label>
      <instruction-item
        v-if="user.instructions.telegram"
        class="mt-20"
        only-button
        :doc="{
          link: user.instructions.telegram.link,
          buttonText: `Скачать инструкцию`,
        }"
      />
    </div>
  </Block>
</template>

<script>
import { AJAX_POST } from "../../units/ajax";
import Block from "./Block";
// import InstructionItem from "@/components//InstructionItem";
import InstructionItem from "@/components/InstructionItem";
import { mapState, mapMutations } from "vuex";

export default {
  components: {
    Block,
    InstructionItem,
  },
  data() {
    return {
      loading: false,
      items: {
        send_browser: null,
        send_news: null,
        telegram_code: null,
      },
    };
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  mounted() {
    this.setInfo();
  },
  methods: {
    ...mapMutations(["setNotif"]),
    setInfo() {
      this.items.send_browser = this.user.telegram_notifications.send_browser;
      this.items.send_news = this.user.telegram_notifications.send_news;
      this.items.telegram_code =
        this.user.telegram_notifications.telegram_code || 3;
    },
    updateInfo() {
      AJAX_POST("/api/me/change/telegramnotifications", this.items).then(
        (res) => {
          this.setNotif({
            title: res.data.msg || `Информация обновлена`,
            type: res.data.success ? "success" : "error",
          });
        }
      );
    },
    async updateCode() {
      this.loading = true;
      const { data } = await AJAX_POST("/api/me/change/telegram-code");
      if (data.success) {
        this.setNotif({
          title: `Код обновлен`,
        });
        this.items.telegram_code = data.code;
      }
      this.loading = false;
    },
    copyText() {
      this.$refs.telegram_input.select();
      document.execCommand("copy");
      this.setNotif({
        title: "Код скопирован",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .section_content {
  flex-direction: column;
}
.input-appended button {
  margin-left: 0;
}
.notifications {
  &__title {
    font-size: 18px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 4px;
  }
  &__subtitle {
    opacity: 0.5;
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 20px;
    display: inline-block;
  }
  &__input {
    width: 568px;
  }
  &__btn {
    height: 40px;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
}
@media (max-width: 1024px) {
  .notifications {
    &__input {
      width: 100%;
    }
  }
}
</style>
