<template>
  <div class="wrapper-cards">
    <spinner v-if="loading" />
    <template v-else>
      <div class="custom-block">
        <div class="custom-block__title">Баланс</div>
        <div class="row-info">
          <p class="row-info__title">Текущий</p>
          <p class="row-info__info gold">{{ user.balance }} ₽</p>
        </div>
        <div class="row-info">
          <p class="row-info__title">Дней осталось</p>
          <p class="row-info__info">{{ daysLeft }} дн.</p>
        </div>
        <el-form
          ref="minBalance"
          :model="minVal"
          :rules="rules"
          class="mt-auto"
        >
          <el-form-item prop="balance" class="mb-0">
            <label class="hint">Уведомлять при</label>
            <el-input
              v-model.number="minVal.balance"
              placeholder="Мин. сумма"
              type="number"
            >
              <el-button
                slot="append"
                class="btn-append"
                icon="el-icon-check"
                @click="save('minbalance')"
              ></el-button>
            </el-input>
          </el-form-item>
        </el-form>
        <el-button class="w-full" @click="$emit('showModalBalanse')">
          Пополнить баланс
        </el-button>
      </div>
      <div class="custom-block">
        <div class="custom-block__title">СМС</div>
        <div class="row-info">
          <p class="row-info__title">Остаток</p>
          <p class="row-info__info">{{ user.sms }} шт.</p>
        </div>
        <el-form ref="minSms" :model="minVal" :rules="rules" class="mt-auto">
          <el-form-item prop="sms" class="mb-0">
            <label class="hint">Уведомлять при</label>
            <el-input
              v-model.number="minVal.sms"
              placeholder="Мин. остаток"
              type="number"
            >
              <el-button
                slot="append"
                class="btn-append"
                icon="el-icon-check"
                @click="save('minsms')"
              ></el-button>
            </el-input>
          </el-form-item>
        </el-form>
        <el-button class="w-full" @click="$emit('showModalSms')">
          Купить СМС
        </el-button>
      </div>
      <!-- <div v-if="listOrder" class="custom-block">
        <div class="custom-block__title">Заказы</div>
        <template v-if="!noOrders">
          <div
            v-for="(item, i) in stats.types.slice(0, 5)"
            :key="`order-${i}`"
            class="row-info"
          >
            <p class="row-info__title">{{ item.name }}</p>
            <p class="row-info__info">{{ item.amount }}</p>
          </div>
          <el-button
            class="w-full to-orders"
            @click="$router.push({ name: 'orders' })"
          >
            К заказам
          </el-button>
        </template>
        <NoData v-else text="Нет заказов" size="small" />
      </div> -->
      <!-- <div v-if="listPayment" class="custom-block">
        <div class="custom-block__title">Оплаты</div>
        <template v-if="!noPayments">
          <div class="row-info">
            <p class="row-info__title">На сумму</p>
            <p class="row-info__info">{{ stats.total }} ₽</p>
          </div>
          <div class="row-info">
            <p class="row-info__title">Онлайн</p>
            <p class="row-info__info">{{ stats.paid_online }} ₽</p>
          </div>
          <div class="row-info">
            <p class="row-info__title">На месте</p>
            <p class="row-info__info">{{ stats.paid_locally }} ₽</p>
          </div>
          <div class="row-info">
            <p class="row-info__title">Ожидает</p>
            <p class="row-info__info">{{ stats.paid_wait }} ₽</p>
          </div>
          <div class="row-info">
            <p class="row-info__title">Не оплачено</p>
            <p class="row-info__info">{{ stats.paid_not_paid }} ₽</p>
          </div>

          <el-button
            class="w-full to-orders"
            @click="$router.push({ name: 'history-payment' })"
          >
            К оплатам
          </el-button>
        </template> -->
      <div v-if="listLink" class="custom-block">
        <div class="custom-block__title">Ссылки</div>
        <template v-if="!noPayments">
          <div v-for="(link, idx) in listLink" :key="idx" class="row-info">
            <router-link
              :to="link.link"
              target="_blank"
              class="border-b border-white-350 leading-25"
            >
              <p class="row-info__title">{{ link.name }}</p></router-link
            >
            <p class="row-info__info leading-25">{{ link.sum }} шт.</p>
          </div>

          <el-button
            class="w-full to-orders"
            @click="$router.push({ name: 'orders' })"
          >
            К заказам
          </el-button>
        </template>
        <NoData v-else text="Нет оплат" size="small" />
      </div>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import NoData from "@/components/NoData.vue";

export default {
  name: "Dashboard",
  components: {
    NoData,
  },
  data() {
    return {
      minVal: {
        balance: null,
        sms: null,
      },
      listPayment: null,
      listOrder: null,
      listLink: null,
      loading: true,
      rules: {
        balance: [
          {
            type: "number",
            required: true,
            min: 0,
            message: "Введите целое число",
            trigger: ["blur", "change"],
          },
        ],
        sms: [
          {
            type: "number",
            min: 0,
            required: true,
            message: "Введите целое число",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  computed: {
    ...mapState("user", [
      "user",
      "minBalanceNotify",
      "minSmsNotify",
      "servicesList",
    ]),
    ...mapState("orders", ["stats", "list_links"]),
    ...mapState("settings", ["cost_per_day"]),
    noOrders() {
      return !this.stats?.types;
    },
    noPayments() {
      return !this.stats;
    },
    daysLeft() {
      const value =
        // this.user.balance / (this.cost_per_day * this.servicesList.length);
        this.user.balance / (this.cost_per_day * this.user.resources_count);
      if (this.user.balance <= 0 || !value) return 0;
      return Math.floor(value);
    },
  },
  async created() {
    // await this.getStat();
    await this.getMinValue("minbalance");
    await this.getMinValue("minsms");
    this.setData();
  },
  methods: {
    ...mapActions("user", ["getMinValue", "setMinValue"]),
    ...mapActions("orders", ["getStat"]),
    setData() {
      this.listLink = this.list_links;
      this.minVal.balance = this.minBalanceNotify;
      this.minVal.sms = this.minSmsNotify;
      this.listPayment = [
        {
          title: "На сумму",
          info: this.stats?.pays?.all || 0,
        },
        {
          title: "На месте",
          info: this.stats?.pays?.all || 0,
        },
        {
          title: "Не оплачено",
          info: this.stats?.pays?.all || 0,
        },
      ];
      this.listOrder = [
        {
          title: "Все",
          info: this.stats?.orders_date?.all || 0,
        },
        {
          title: "Прошедшие",
          info: this.stats?.orders_date?.pass || 0,
        },
        {
          title: "Предстоящие",
          info: this.stats?.orders_date?.ahead || 0,
        },
        {
          title: "Сегодня",
          info: this.stats?.orders_date?.today || 0,
        },
        {
          title: "Завтра",
          info: this.stats?.orders_date?.tommorow || 0,
        },
      ];
      this.loading = false;
    },
    save(key) {
      const value = key === "minsms" ? this.minVal.sms : this.minVal.balance;
      this.setMinValue({ value, key });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.wrapper-cards {
  display: flex;
  margin: 0 -15px;
  .custom-block {
    margin: 0 15px 15px;
    width: calc(25% - 15px);
    height: 289px;
    display: flex;
    flex-direction: column;

    &__title {
      color: $--color-white;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      margin-bottom: 15px;
    }
    ::v-deep .el-button {
      margin-top: 20px;
    }
    ::v-deep .el-input-group__append {
      min-width: 40px;
      background: $--color-white-15;
      position: relative;
      padding: 0;
    }
    .btn-append {
      width: 100%;
      height: 100%;
      margin: 0;
      padding: 0;
      ::v-deep i {
        @apply text-20 text-white-normal;
      }
    }

    .to-orders {
      margin-top: auto;
    }
    .hint {
      display: block;
      margin-bottom: 8px;
    }
  }
  .custom-block:last-child {
    width: calc(50% - 15px);
  }
}
.button {
  &-add {
    height: 40px;
  }
}
.row-info {
  justify-content: space-between;
  &__title {
    width: auto !important;
  }
}
@media (max-width: 1420px) {
  .wrapper-cards {
    flex-wrap: wrap;
    .custom-block {
      width: calc(50% - 30px);
    }
    .custom-block:last-child {
      width: calc(50% - 15px);
    }
  }
}
@screen lg {
  .wrapper-cards {
    margin: 0;
    flex-direction: column;
    .custom-block {
      margin: 0;
      width: 100%;
      flex-direction: column;
      margin-bottom: 15px;
      padding: 15px;
      .to-orders {
        margin-top: 20px;
      }
    }
    .custom-block:last-child {
      width: 100%; //calc(50% - 15px);
    }
  }
}
</style>
