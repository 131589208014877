<template>
  <div class="bk-full">
    <div class="row-info">
      <div
        class="row-info__title"
        :style="{
          color: isThemeWidget ? customStyle.active_text_color : '',
        }"
      >
        Осталось билетов
      </div>
      <div
        v-if="isSlotTicket"
        class="row-info__value"
        :style="{
          color: isThemeWidget ? customStyle.active_text_color : '',
        }"
      >
        {{ slotsValidity.tickets }}
        <!-- из {{ `${slotsValidity.tickets + allSelectTicket}` }} -->
      </div>
    </div>
    <p
      class="title-widget"
      :style="{
        color: isThemeWidget ? customStyle.active_text_color : '',
      }"
    >
      Билеты
    </p>
    <div v-for="(ticket, i) in listTicket" :key="i" class="input-number">
      <div class="input-number__info">
        <span
          class="input-number__info-title"
          :style="{
            color: isThemeWidget ? customStyle.inactive_text_color : '',
          }"
          >{{ ticket.name }}</span
        >
        <span
          class="input-number__info-subtitle"
          :style="{
            color: isThemeWidget ? customStyle.active_text_color : '',
          }"
        >
          {{ isHoliday ? ticket.holidays_price : ticket.base_price }}
          {{ selectCell.currency }}
        </span>
      </div>
      <el-input-number
        v-model="ticket.select_ticket"
        class="input-number__input"
        :class="{ 'custom-style': isThemeWidget }"
        :style="{
          background: isThemeWidget ? customStyle.background_cells_fields : '',
        }"
        :min="0"
        :max="differenceSeats ? seats : ticket.select_ticket"
        :controls="true"
        @input="emitData()"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "GroupLimitGeneral",
  props: {
    selectTime: {
      type: String,
    },
    value: {
      type: [Number, String],
      default: null,
    },
    selectCell: {
      type: Object,
      default: () => {},
    },
    settings: {
      type: Object,
      default: () => {},
    },
    slotsValidity: {
      type: Object,
      default: () => {},
    },
    hash: { type: Object },
    customStyle: { type: Object },
    isThemeWidget: { type: String },
  },
  data() {
    return {
      listTicket: [],
    };
  },
  computed: {
    ...mapState("widget", ["remainedTickets"]),

    isHoliday() {
      return this.selectCell.is_holiday;
    },
    allSelectTicket() {
      return this.listTicket.reduce((sum, current) => {
        return sum + current.select_ticket;
      }, 0);
    },
    differenceSeats() {
      return this.seats - this.allSelectTicket;
    },
    seats() {
      return this.settings.data.group_general_limits?.tickets;
    },
    isSlotTicket() {
      return this.slotsValidity;
    },
    remainedPlaces() {
      return this.settings.data.group_general_limits.tickets_remained;
    },
  },
  async created() {
    this.listTicket = this.settings.data.group_general_limits.group.map(
      (el, idx) => {
        return idx === 0
          ? { ...el, select_ticket: 1, timeUnit: null }
          : { ...el, select_ticket: null, timeUnit: null };
      }
    );
    await this.getRemainedTickets({
      hash: this.hash.hash,
      date: this.selectCell?.date,
      time: this.selectCell?.time,
    });
  },
  methods: {
    ...mapActions("widget", ["saveBooking", "getRemainedTickets"]),

    unitCahnge() {
      switch (this.settings.data.time_unit) {
        case "days":
          return "day";
        case "hours":
          return "hour";
        case "minutes":
          return "minute";
        default:
          break;
      }
    },
    emitData(param) {
      let dateSlot = "";
      let timeSlot = "";
      if (param) {
        const { dateParam, timeParam } = param;
        dateSlot = dateParam;
        timeSlot = timeParam;
      }
      const qwer = this.listTicket
        .filter((el) => el.select_ticket)
        .map((item) => {
          return {
            name: item.name,
            price: this.isHoliday ? item.holidays_price : item.base_price,
            select_ticket: item.select_ticket,
            id: item.id,
          };
        });
      this.$emit("isDis", qwer.length);
      this.$emit("setTickets", qwer);
      this.$emit("setSlotParam", {
        id: this.selectCell.id,
        book_info: {
          tickets: qwer,
          cnt: this.hours,
        },
        date: param ? dateSlot : this.selectCell.date,
        time: param ? timeSlot : this.selectTime ?? this.selectCell.time,
        resourceHash: this.selectCell.resource_id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";
:root {
  --colorCustom: "white";
  --colorCustomBackground: "green";
}
.row-info {
  margin-bottom: 20px;
  &__title {
    width: auto;
    flex-grow: 1;
  }
  &__value {
    flex-grow: 0;
  }
}
.input-number__info-subtitle span {
  &:first-child {
    color: $--color-white;
    margin-right: 6px;
  }
  &:last-child {
    color: $--color-white-5;
    margin-left: 6px;
  }
}

.custom-style {
  ::v-deep .el-input__inner {
    color: var(--colorCustom);
  }
  ::v-deep .el-input-number__increase,
  ::v-deep .el-input-number__decrease {
    background: var(--colorInactive);
    color: var(--colorCustom);
  }
  ::v-deep .el-input-number__increase:hover,
  ::v-deep .el-input-number__decrease:hover {
    color: var(--colorCustomButton);
  }
}
</style>
