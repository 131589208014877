<template>
  <div class="picker">
    <p class="picker__title">{{ title }}</p>
    <div class="list-fields">
      <div class="list-fields__row">
        <div class="list-fields__item">
          <div class="picker__picker">
            <el-color-picker
              :value="value"
              @input="$emit('input', $event)"
            ></el-color-picker>
          </div>
        </div>
        <div class="list-fields__item">
          <div class="picker__color"><span>#</span> {{ value.slice(1) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ColorPick",
  props: {
    title: {
      type: String,
      default: "",
    },
    value: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.picker {
  display: flex;
  flex-direction: column;
  align-items: normal !important;
  &__title {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: $--color-white-5;
    margin-bottom: 10px;
    width: 100%;
  }
  &__picker {
    border: 2px solid $--color-white-5;
    border-radius: 3px;
    padding: 2px;
    width: 100%;
    height: 40px;
  }
  &__color {
    display: flex;
    align-items: center;
    padding: 0 12px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    width: 100%;
    height: 40px;

    span {
      color: $--color-white-5;
      margin-right: 12px;
    }
  }
}

::v-deep {
  .el-color-picker {
    height: 100%;
    &__trigger {
      height: 100%;
    }
  }
}
.list-fields {
  margin-bottom: 0;
  &__row {
    margin-bottom: 0 !important;
  }
}
</style>
