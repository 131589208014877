<template>
  <div class="bk-full">
    <p class="title-widget">Выберите услугу</p>
    <el-radio-group v-model="radio" @input="emitData($event)">
      <el-radio v-for="(service, i) in settings.data" :key="i" :label="service">
        <div class="radio__title">{{ service.name }}</div>
        <div class="radio__sub-title">
          <span>
            {{
              isHoliday && service.holidays_price
                ? service.holidays_price
                : service.base_price
            }}
            {{ itemList.currency ? itemList.currency : itemList.item.currency }}
          </span>
          •
          <span>{{ service.duration }} {{ getUnitTime(service) }}</span>
        </div>
      </el-radio>
    </el-radio-group>
  </div>
</template>

<script>
import { listDuration } from "@/helpers/services.js";

export default {
  name: "ServDuration",
  props: {
    settings: {
      type: Object,
    },
    itemList: { type: Object },
    slotDetails: { type: Object, default: () => {} },
  },
  data() {
    return {
      radio: null,
    };
  },
  computed: {
    isHoliday() {
      return this.itemList.item.is_holiday;
    },
  },
  created() {
    const checkValue = this.slotDetails?.booking?.book_info?.service.name;
    const val = this.settings.data.find((el) => el.name === checkValue);
    this.radio = this.itemList.status
      ? val
      : this.settings.data.find((el) => el);
    this.emitData(this.radio);
  },
  methods: {
    emitData(service) {
      this.$emit(
        "setPrice",
        this.isHoliday
          ? service.holidays_price // + this.itemList.item.weekend_price_additional
          : service.base_price // + this.itemList.item.price_additional
      );
      service = {
        id: service.id,
        name: service.name,
        duration: service.duration,
        unit: service.time_unit,
        price: this.isHoliday
          ? service.holidays_price // + this.itemList.item.weekend_price_additional
          : service.base_price, // + this.itemList.item.price_additional, // почему не просто price? # сделал price
      };
      console.log(service);
      this.$emit("setServiceDur", service);
    },

    getUnitTime(service) {
      return listDuration.find((el) => el.value === service.time_unit)
        .shortLabel;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.radio {
  &__title {
    margin-bottom: 4px;
  }
  &__sub-title {
    margin-bottom: 18px;
    span {
      &:first-child {
        margin-right: 6px;
      }
      &:last-child {
        color: $--color-white-5;
        margin-left: 6px;
      }
    }
  }
}
</style>
