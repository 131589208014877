<template>
  <div class="h-full">
    <spinner v-if="isLoading" />
    <!-- <div v-else-if="errorMsg" class="custom-alert not-set-schedule">
      <div>
        {{ errorMsg }}
      </div>
    </div> -->
    <el-collapse v-else>
      <BlockCollapse
        title="Настройка типа записи и ценообразования"
        name="scheduleSettings"
        sub-title="Настройте тип записи (индивидуальная, групповая, аренда или дерево услуг) и укажите цены."
      >
        <OnlineSchedule />
      </BlockCollapse>

      <BlockCollapse
        title="График работы и расписание"
        sub-title="Настройте график работы и сформируйте расписание генератором или задайте время сеансов вручную."
        :disabled="isNew || !isHaveSettings"
        name="schedule"
      >
        <Generator />
      </BlockCollapse>

      <BlockCollapse
        title="Даты праздников"
        name="weekendAddDates"
        sub-title="Укажите даты праздничных дней, в которые цена на сеансы в расписании будет браться из поля «Цена в праздники»."
        :disabled="isNew || !isHaveSettings"
      >
        <AddDates type="weekend" />
      </BlockCollapse>

      <BlockCollapse
        title="Нерабочие дни"
        name="nonworkingAddDates"
        :disabled="isNew || !isHaveSettings"
        sub-title="Укажите даты нерабочих дней, когда сеансы в расписании будут недоступны для бронирования."
      >
        <AddDates type="nonworking" />
      </BlockCollapse>

      <BlockCollapse
        title="Дополнительные сеансы"
        name="slotsDate"
        :disabled="isNew || !isHaveSettings"
        sub-title="При необходимости, вы можете добавить дополнительные сеансы на определенные даты."
      >
        <SlotsDate />
      </BlockCollapse>
    </el-collapse>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import OnlineSchedule from "@/views/Services/Create/components/OnlineSchedule";
import AddDates from "@/views/Services/Create/components/AddDates";
import SlotsDate from "@/views/Services/Create/components/SlotsDate";
import Generator from "@/views/Services/Create/components/Generator";

export default {
  name: "ResourceDatetime",
  components: {
    OnlineSchedule,
    Generator,
    AddDates,
    SlotsDate,
  },
  data() {
    return { isLoading: true };
  },
  computed: {
    ...mapState("service", [
      "service",
      "generator",
      "schedule",
      "errorMsg",
      "addDates",
      "settings",
    ]),
    ...mapGetters("service", ["isNew", "isHaveSettings"]),
  },
  created() {
    this.isLoading = false;
  },
  methods: {
    ...mapActions("service", ["getAddDates", "getSessions", "getGenerator"]),
  },
};
</script>

<style lang="scss" scoped></style>
