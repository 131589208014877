<template>
  <el-form
    ref="baseInfo"
    hide-required-asterisk
    label-position="top"
    class="regular"
  >
    <div class="flex">
      <div class="hint-title">Выберите период</div>
      <el-popover
        class="ml-8"
        placement="top"
        width="200"
        trigger="hover"
        content="Если у услуги нет даты окончания, то поле «По» необходимо оставить пустым. Если расписание и цены зависят от сезона, то можно добавить несколько временных периодов."
      >
        <Tooltip slot="reference" />
      </el-popover>
    </div>
    <div class="list-fields">
      <div class="list-fields__row items-start">
        <div class="list-fields__item">
          <el-form-item prop="basePrice">
            <div class="wrapper-calendar">
              <div class="wrapper-calendar__from">
                C<span class="danger">*</span>
              </div>
              <el-date-picker
                v-model="date[0]"
                type="date"
                placeholder="выберите дату"
                :picker-options="scheduleDateOptions"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                @input="emitData()"
              >
              </el-date-picker>
            </div>
          </el-form-item>
        </div>
        <div class="list-fields__item">
          <el-form-item prop="basePrice">
            <div class="wrapper-calendar">
              <div class="wrapper-calendar__to">По</div>
              <el-date-picker
                v-model="date[1]"
                type="date"
                placeholder="выберите дату"
                :picker-options="scheduleDateOptions"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
                @input="emitData()"
              >
              </el-date-picker>
            </div>
          </el-form-item>
        </div>
        <div v-if="isShowDeletBtn" class="list-fields__item">
          <el-button
            class="delete-button"
            icon="el-icon-close"
            @click="$emit('delete')"
          >
            Удалить период
          </el-button>
        </div>
      </div>
      <div class="list-fields__row">
        <div class="list-fields__item">
          <el-form-item class="w-full">
            <div slot="label" class="flex">
              Повторение
              <span class="danger">*</span>
              <el-popover
                class="ml-8"
                placement="top"
                width="200"
                trigger="hover"
                content="Выберите рабочий график в выпадающем списке или чуть правее, нажав на
соответствующие дни."
              >
                <Tooltip slot="reference" />
              </el-popover>
            </div>
            <el-select
              :value="typeDates"
              placeholder="Выберите категория"
              class="w-full"
              @input="workDay"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="list-fields__days">
          <div
            v-for="(day, i) in days"
            :key="i"
            :class="['day', { 'day-select': day.select }]"
            @click="toggleState(day, i)"
          >
            {{ listDays.find((item) => day.value === item.value).shortTitle }}
          </div>
        </div>
      </div>
    </div>
    <el-radio-group v-model="radio" @input="emitData()">
      <el-radio label="manySession">Несколько сеансов</el-radio>
      <el-radio label="oneSession">Один сеанс</el-radio>
    </el-radio-group>
    <ListField2
      v-if="radio === 'manySession'"
      v-model="listSessions"
      class="ListField2"
      title-button="Добавить диапазон"
      :headers="headers2"
      @input="emitData()"
    ></ListField2>
    <div v-if="radio === 'oneSession'" class="list-fields">
      <div class="list-fields__row">
        <div class="list-fields__item time-picker">
          <p class="hint-title">Начало сеанса<span class="danger">*</span></p>
          <el-time-picker v-model="time" format="HH:mm" @input="emitData()">
          </el-time-picker>
        </div>
      </div>
    </div>
  </el-form>
</template>

<script>
import ListField2 from "@/components/ListField2v2.vue";
import { listDays } from "@/helpers/services.js";
import moment from "moment";
import { format } from "date-fns";
import { mapState } from "vuex";

export default {
  name: "RegularPeriod",
  components: {
    ListField2,
  },
  props: {
    isShowDeletBtn: {
      type: Boolean,
    },
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      date: [format(new Date(), "yyyy-MM-dd"), null],
      typeDates: "everyDay",
      days: [
        { value: 1, select: true },
        { value: 2, select: true },
        { value: 3, select: true },
        { value: 4, select: true },
        { value: 5, select: true },
        { value: 6, select: true },
        { value: 7, select: true },
      ],
      radio: "manySession",
      listSessions: [
        {
          time: null,
          duration: 60,
          unit: "minutes",
        },
      ],
      time: null,
      scheduleDateOptions: {
        disabledDate: (time) => {
          return time < moment().subtract(1, "day").toDate();
        },
        firstDayOfWeek: 1,
      },
    };
  },
  computed: {
    ...mapState("service", ["settings", "generator", "schedule", "sessions"]),
  },
  watch: {
    value() {
      this.getOriginValue();
    },
  },
  created() {
    if (
      this.schedule.period &&
      this.value &&
      this.listSchedule < this.sessions.data
    ) {
      const someArr = [];
      this.value.days.forEach((val) => {
        val.schedule.forEach((t) =>
          someArr.push(
            moment(val.schedule[0].time).format("HH:mm"),
            moment(val.schedule[val.schedule.length - 1].time).format("HH:mm")
          )
        );
      });
      this.listSessions[0].time = [...new Set(someArr)];
    } else {
      this.date = [format(new Date(), "yyyy-MM-dd"), null];
    }
    this.getOriginValue();
    this.listDays = listDays;
    this.options = [
      {
        label: "Ежедневно",
        value: "everyDay",
        days: [1, 2, 3, 4, 5, 6, 7],
      },
      {
        label: "По будням",
        value: "workDay",
        days: [1, 2, 3, 4, 5],
      },
      {
        label: "По выходным",
        value: "weekend",
        days: [6, 7],
      },
      {
        label: "Выбрать дни",
        value: "selectDay",
        days: [1, 2, 3, 4, 5, 6, 7],
      },
    ];
    this.headers2 = [
      {
        title: "Укажите диапазон",
        valid: true,
        placeholder: "Введите время",
        popoverText:
          "Укажите начало и конец рабочего дня. В указанном промежутке времени, в зависимости от выбранного интервала, будут сформированы временные слоты/сеансы.",
      },
      {
        title: "Интервал между сеансами",
        valid: true,
        placeholder: "Введите время",
        popoverText:
          "Если вы оказываете услуги разной длительности по времени, например Стрижка 15 минут, а Окрашивание 60 минут, рекомендуем указать минимальный интервал между сеансами, т.е. 15 минут.",
      },
    ];
  },
  methods: {
    workDay(type) {
      this.typeDates = type;
      const optDays = this.options.find((option) => option.value === type).days;
      this.days.forEach((day, index) => {
        this.days[index].select = optDays.includes(day.value);
      });
      this.emitData();
    },
    toggleState(day, i) {
      this.days[i].select = !day.select;
      this.emitData();
    },
    emitData() {
      this.$emit("input", {
        date: this.date,
        typeDates: this.typeDates,
        radio: this.radio,
        time: this.time,
        listSessions: [...this.listSessions],
        days: [...this.days],
      });
    },
    getOriginValue() {
      if (!this.value) return;
      this.date = this.value.date;
      this.typeDates = this.value.typeDates ?? this.typeDates;
      this.radio = this.value.radio ?? this.radio;

      this.time = this.value.time;
      // [...this.value.listSessions] ?
      this.listSessions = [...this.value.listSessions];
      // [...this.value.days]
      this.days = [...this.value.daysPeriod];
    },
  },
};
</script>
<style lang="scss" scoped>
@import "element-variables.scss";

.wrapper-calendar {
  display: flex;
  align-items: center;
  &__from {
    margin-right: 12px;
  }
  &__to {
    margin: 0 12px;
  }
}

.list-fields {
  margin-bottom: 15px;

  &__row:last-child {
    margin-bottom: 0;
  }
  &__item {
    flex-grow: 0;
    flex-shrink: 0;
  }
  &__days {
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;
    .day {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      padding: 8px 20px;
      border-radius: 3px;
      color: $--color-white;
      border: 1px solid $--color-white-35;
      cursor: pointer;
      margin: 0 10px 0 0;
      &:hover {
        background-color: $--color-white-15;
      }
      &-select {
        background: #3498db;
      }
    }
  }
}

.time-picker {
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
}

::v-deep .el-radio-group {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.delete-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 24px;
  ::v-deep i {
    font-size: 22px;
    margin-bottom: 3px;
  }
  ::v-deep span {
    margin-left: 11px !important;
  }
}

::v-deep .list-fields__add-button {
  margin-bottom: 22px;
}
@screen lg {
  .list-fields {
    margin-bottom: 15px;

    &__row {
      margin-bottom: 15px;
    }
    &__days {
      .day {
        margin: 0 10px 10px 0; //
      }
    }
  }
}
</style>
