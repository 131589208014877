<template>
  <div class="mt-50">
    <PreTables text="История платежей" :value="historyPayInfo.totalItems" />
    <div class="relative">
      <spinner v-if="isLoading" class="table__spinner" />
      <el-table :data="historyPay" class="border-b-0 mb-20" :stripe="true">
        <el-table-column
          type="index"
          :index="indexMethod"
          label="№"
          sortable
          :width="62"
        >
        </el-table-column>
        <el-table-column
          prop="date"
          label="Дата создания"
          sortable
          :min-width="$mq.tablet ? 146 : 157"
        >
          <div slot-scope="{ row }">
            {{ row.created_at.date }}
            <span class="small_text">{{ row.created_at.time }}</span>
          </div>
        </el-table-column>
        <el-table-column
          prop="pack"
          label="Пакет"
          sortable
          :min-width="$mq.tablet ? 90 : 121"
        >
          <div slot-scope="{ row }">
            {{ `${row.pack} CMC` }}
          </div>
        </el-table-column>
        <el-table-column
          prop="sum"
          sortable
          :label="$mq.tablet ? 'Сумма' : 'Сумма платежа'"
          :min-width="$mq.tablet ? 102 : 160"
        >
          <div slot-scope="{ row }">
            {{ `${row.sum} ₽` }}
          </div>
        </el-table-column>
        <el-table-column
          prop="payment_type"
          sortable
          label="Тип платежа"
          :min-width="185"
        >
          <template slot-scope="{ row }">
            <span>{{ row.payment_type }}</span>
            <!-- <div v-else class="type_payment">
              Оплата по счету
              <el-button class="max-w-full">Скачать счет в PDF</el-button>
              <el-button class="max-w-full ml-0"
                >Закрывающие документы</el-button
              >
            </div> -->
          </template>
        </el-table-column>
        <el-table-column prop="status" sortable label="Статус" :min-width="195">
          <template slot-scope="{ row }">
            <div class="current_status">
              <span
                class="icon_rounded"
                :class="`icon_rounded_${getStatus(row.status).type}`"
              >
                <svg-icon
                  :width="getStatus(row.status).width"
                  :height="getStatus(row.status).height"
                  :icon-name="getStatus(row.status).type"
                ></svg-icon>
              </span>
              {{ row.status }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="date"
          label="Дата зачисления"
          sortable
          :min-width="$mq.tablet ? 146 : 157"
        >
          <div slot-scope="{ row }">
            <template v-if="row.status_id === 1">
              {{ row.updated_at.date }}
              <span class="small_text">{{ row.updated_at.time }}</span>
            </template>
            <div v-else>—</div>
          </div>
        </el-table-column>
      </el-table>
    </div>
    <el-button
      v-if="
        historyPay.length > 0 && historyPayInfo.totalItems !== historyPay.length
      "
      :loading="isLoading"
      class="bg-transparent md:mx-15 mr-auto"
      @click="loadMore"
      >Показать ещё</el-button
    >
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import PreTables from "@/components/Table/PreTables";

export default {
  components: {
    PreTables,
  },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ...mapState("sms", ["historyPay", "historyPayInfo"]),
    ...mapState("icons", ["question_icon", "success_icon", "cancel_icon"]),
  },
  async mounted() {
    await this.getHistoryPay();
    this.isLoading = false;
  },
  methods: {
    ...mapActions("sms", ["getHistoryPay"]),
    async loadMore() {
      this.isLoading = true;
      const perPage = this.historyPay.length + 10;
      await this.getHistoryPay(perPage);
      this.isLoading = false;
    },
    getStatus(status) {
      let value;
      // eslint-disable-next-line default-case
      switch (status) {
        case "Оплачен": {
          value = this.success_icon;
          break;
        }
        case "Ошибка": {
          value = this.cancel_icon;
          break;
        }
        default: {
          value = this.question_icon;
          break;
        }
      }
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
.type_payment {
  ::v-deep .el-button {
    font-size: 10px;
    height: 24px;
    background: rgba(255, 255, 255, 0.1);
    padding: 6px 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 10px 0 0;
  }
}
</style>
