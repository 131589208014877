<template>
  <div>
    <spinner v-if="isLoading" class="absolute" />
    <div v-else class="order flex justify-center">
      <div class="order__wrap">
        <div class="order__block--top xs:mx-30">
          <h3 class="text-28 xs:text-16">
            Ваш заказ #{{ service.booking_id }}
          </h3>
          <div class="flex justify-start">
            <span>Статус:</span>
            <div class="mx-10 flex align-center">
              <p
                class="w-20 h-20"
                :style="{ background: service.status.color }"
                style="border-radius: 50%; left: 20px"
              ></p>
            </div>
            <span>{{ service.status.name }} </span>
          </div>
        </div>
        <div class="order__block">
          <div class="mx-30">
            <div class="pt-16">
              <h3 class="text-16 leading-28">Услуга</h3>
            </div>
            <div class="hl"></div>
            <div>
              <h3
                class="py-30 text-22 leading-30 xs:text-16 xs:leading-12 xs:py-10"
              >
                {{ service.name }}
              </h3>
              <div
                class="flex w-2/3 justify-between mb-20 xs:flex-col xs:w-full"
              >
                <!-- <component :is="serviceType"></component> -->
                <div class="flex flex-col xs:flex-row xs:justify-between">
                  <span class="order__row-text">Дата и время </span>
                  <span>{{ `${service.date} в ${service.time}` }}</span>
                </div>
                <div class="flex flex-col xs:flex-row xs:justify-between">
                  <span class="order__row-text">Продолжительность</span>
                  <span>{{ service.duration }} </span>
                </div>
                <div class="flex flex-col xs:flex-row xs:justify-between">
                  <span class="order__row-text">Стоимость </span>
                  <span>{{ service.final_price }} {{ service.currency }} </span>
                </div>
              </div>

              <div class="order__row pb-30">
                <p class="order__row-text">Адрес</p>
                <p>{{ service.address }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="order__block">
          <div class="mx-30">
            <div class="pt-16">
              <h3 class="text-16 leading-28">Состав заказа</h3>
            </div>
            <div class="hl"></div>
            <div>
              <div class="flex w-1/2 justify-between mb-20 flex-col xs:w-full">
                <components
                  :is="componentCheck.component"
                  :service="service"
                  :is-additional-input="isAdditionalInput"
                  :is-additional-check="isAdditionalCheck"
                ></components>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            service.payment_type_name === 'Оплата онлайн' ||
            service.payment_type_name === 'Предоплата онлайн'
          "
          class="order__block"
        >
          <div class="mx-30">
            <div class="pt-16">
              <h3 class="text-16 leading-28">Оплата</h3>
            </div>
            <div class="hl"></div>
            <div class="flex justify-between mb-20 flex-col xs:w-full">
              <div
                v-if="!service.pre_payment"
                class="flex flex-row justify-between w-1/2"
              >
                <span class="order__row-text mb-10"
                  >{{
                    service.payment_data[0].status_name === "Оплачен" ||
                    service.payment_data[0].status_name === "Частично оплачен"
                      ? "Оплачено онлайн"
                      : "К оплате онлайн"
                  }}
                </span>
                <span class="order__row px-5">
                  {{
                    service.payment_data[0].status_name === "Оплачен" ||
                    service.payment_data[0].status_name === "Частично оплачен"
                      ? service.paid_amount
                      : service.full_amount
                  }}
                  {{ service.currency }}</span
                >
              </div>
              <div
                v-if="service.payment.payment_at_place"
                class="flex flex-row justify-between w-1/2"
              >
                <span class="order__row-text mb-10"
                  >{{
                    service.payment_data[0].status_name === "Оплачен"
                      ? "Оплачено на месте"
                      : "К оплате на месте"
                  }}
                </span>
                <span class="order__row px-5">
                  {{
                    service.paid_on_place > 0
                      ? service.paid_on_place
                      : service.full_amount - service.paid_amount
                  }}
                  {{ service.currency }}
                </span>
              </div>
              <div
                v-if="service.pre_payment"
                class="flex flex-row justify-between w-1/2"
              >
                <span class="order__row-text mb-10">Оплачено онлайн</span>
                <span class="order__row px-5"
                  >{{ service.pre_payment }} {{ service.currency }}
                </span>
              </div>

              <div
                v-if="service.refunds_sum"
                class="flex flex-row justify-between w-1/2"
              >
                <span class="order__row-text mb-10">Возврат</span>
                <span class="order__row px-5"
                  >{{ service.refunds_sum }} {{ service.currency }}
                </span>
              </div>

              <div class="hl"></div>
              <div class="flex flex-row justify-between pb-30 w-1/2">
                <span class="order__row-text">Итоговая стоимость </span>
                <span class="order__row px-5"
                  >{{ service.full_amount }} {{ service.currency }}</span
                >
              </div>
              <div
                v-if="
                  !isPaid &&
                  service.status.id !== 4 &&
                  service.payment_data[0].status !== 4
                "
                class="button__actions pb-30"
              >
                <el-button
                  class="block__button"
                  type="primary"
                  @click="redirectToLink(service.payment_data[0].payment_link)"
                  >Оплатить онлайн
                </el-button>
              </div>
              <div v-if="service.error_msg">
                <div class="attention mb-30">
                  <div class="text-white-normal">
                    <el-alert
                      center
                      :title="`Ошибка оплаты: ${service.error_title}, ${service.error_msg}`"
                      type="warning"
                      show-icon
                      :closable="false"
                      class="mt-15 text-16"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="order__block">
          <div class="mx-30">
            <div class="pt-16">
              <h3 class="text-16 leading-28">Организатор</h3>
            </div>
            <div class="hl"></div>
            <div>
              <div
                class="flex w-2/3 justify-between mb-20 xs:flex-col xs:w-full"
              >
                <div class="flex flex-col xs:flex-row xs:justify-between">
                  <span class="order__row-text mb-10 text-12"
                    >Название / Имя
                  </span>
                  <span>ИП Иванов Иван Иванович </span>
                </div>
                <div class="flex flex-col xs:flex-row xs:justify-between">
                  <span class="order__row-text mb-10 text-12">Телефон </span>
                  <span>8 (812) 879-00-23 </span>
                </div>
                <div class="flex flex-col xs:flex-row xs:justify-between">
                  <span class="order__row-text mb-10 text-12">E-mail </span>
                  <span>examplemail@gmail.com </span>
                </div>
              </div>
              <div class="order__row pb-30">
                <p class="order__row-text">
                  Если у вас возникли дополнительные вопросы, вы можете задать
                  их организатору по телефону,<br />
                  указанному выше.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="order__block">
          <div class="mx-30">
            <div class="pt-16">
              <h3 class="text-16 leading-28">Клиент</h3>
            </div>
            <div class="hl"></div>
            <div class="pb-30">
              <div
                class="flex w-2/3 justify-between mb-20 xs:flex-col xs:w-full"
              >
                <div class="flex flex-col xs:flex-row xs:justify-between">
                  <span class="order__row-text mb-10 text-12">Имя </span>
                  <span>{{ service.client_name }} </span>
                </div>
                <div class="flex flex-col xs:flex-row xs:justify-between">
                  <span class="order__row-text mb-10 text-12">Телефон </span>
                  <span>{{ service.tel }} </span>
                </div>
                <div class="flex flex-col xs:flex-row xs:justify-between">
                  <span class="order__row-text mb-10 text-12">E-mail </span>
                  <span>{{ service.email }}</span>
                </div>
              </div>
              <div v-if="service.client_comment" class="order__row pb-30">
                <p class="order__row-text pb-3">Комментарий</p>
                <p>
                  Идейные соображения высшего порядка, а также начало
                  повседневной работы по формированию позиции в значительной
                  степени обуславливает создание модели развития.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AJAX_GET, AJAX_POST } from "@/units/ajax";
import { setFavicon } from "@/helpers/forFavicon.js";

import ServiceOrder from "@/views/Order/components/ServiceOrder";
import RentService from "@/views/Order/components/RentService";
import GroupTickets from "@/views/Order/components/GroupTickets";
import IndividOrder from "@/views/Order/components/IndividOrder";

export default {
  name: "Order",
  components: {
    ServiceOrder,
    RentService,
    GroupTickets,
    IndividOrder,
  },
  data() {
    return {
      service: null,
      isLoading: true,
    };
  },
  computed: {
    isPaid() {
      return (
        this.service?.payment_data[0].status_name === "Оплачен" ||
        this.service?.payment_data[0].status_name ===
          "Частичный возврат средств"
      );
    },
    isAdditionalInput() {
      return this.service.consist.add_options_input;
    },
    isAdditionalCheck() {
      return this.service.consist.add_options_check;
    },
    componentCheck() {
      switch (this.service.booking_type_sub) {
        case "individual_depends_on_the_service_and_its_duration":
          return {
            component: ServiceOrder,
          };
        case "individual_depends_on_tariff_and_number_of_seats":
          return {
            component: ServiceOrder,
          };
        case "individual_depends_on_the_number_of_seats":
          return {
            component: ServiceOrder,
          };
        case "individual_does_not_depend_on_the_number_of_seats":
          return {
            component: IndividOrder,
          };

        case "rent_depends_on_time_and_place":
          return {
            component: RentService,
          };
        case "rent_depends_only_on_time":
          return {
            component: RentService,
          };
        case "group_the_seat_limit_is_set_for_each_session":
          return {
            component: GroupTickets,
          };
        case "group_same_seat_limit_for_all_sessions":
          return {
            component: GroupTickets,
          };

        default:
          return {};
      }
    },
  },

  async created() {
    await this.settingsPublic();
    await this.getInfoOrder();
  },
  methods: {
    async settingsPublic() {
      await AJAX_GET("/api/site/settings_public").then(({ data }) => {
        if (!data) return;
        const newSrc = data.favicon.replace(
          process.env.VUE_APP_LOCAL_API,
          process.env.VUE_APP_PROD_API
        );
        // eslint-disable-next-line no-unused-expressions
        process.env.NODE_ENV === "production" ? data.favicon : newSrc;
        setFavicon({
          title: `${data.title}: заказ #${this.$route.params.hash}`,
          href: newSrc,
        });
      });
    },
    async getInfoOrder() {
      await AJAX_GET("/api/v1/booking/get", {
        params: { booking_id: this.$route.params.hash },
      })
        .then(({ data }) => {
          this.service = { ...data };

          this.isLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    redirectToLink(link) {
      window.location = link;
    },

    unitChange(key) {
      switch (key) {
        case "days":
          return {
            text: "дня",
            textSecond: "часов",
            textThird: "час",
          };
        case "hours":
          return {
            text: "часа",
            textSecond: "часов",
            textThird: "час",
          };
        case "hour":
          return {
            text: "часа",
            textSecond: "часов",
            textThird: "час",
          };

        case "minutes":
          return {
            text: "минут",
            textSecond: "часов",
            textThird: "час",
          };
        case "minute":
          return {
            text: "минут",
            textSecond: "часов",
            textThird: "час",
          };
        default:
          return "No UNIT";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.order {
  font-weight: 400;
  font-family: Montserrat;
  &__wrap {
    width: 860px;
    margin-top: 100px;
    margin-bottom: 100px;
  }
  &__block {
    background: rgba(255, 255, 255, 0.04);
    border-radius: 3px;
    margin-bottom: 30px;
    &--top {
      text-align: left;
      margin-bottom: 40px;
    }
  }
  &__row {
    font-size: 12px;
    &-text {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}
.hl {
  margin-top: 20px;
  margin-bottom: 20px;
}
@media (max-width: 480px) {
  .order {
    &__wrap {
      width: 100%;
      margin-top: 30px;
      margin-bottom: 30px;
    }
  }
}
</style>
