<template>
  <div class="resource-settings">
    <el-collapse>
      <BlockCollapse title="Основные настройки" name="mainSettings">
        <switch-custom
          :value="service.active"
          title="Услуга предоставляется/активна?"
          @input="updateActive({ active: $event })"
        >
          Переключите, если требуется временно деактивировать ресурс. Расписание
          будет недоступно для бронирования, но все настройки будут сохранены.
        </switch-custom>
        <!-- <el-checkbox
          :value="service.history"
          class="custom-check"
          @input="
            setService({
              field: 'history',
              value: $event,
              autoSave: true,
            })
          "
        > 
          <span class="custom-check__title">Включить историю изменений</span>
          <span class="custom-check__subtitle">
            Отметьте, если хотите сохранять в ЛК историю изменений по броням
          </span>
        </el-checkbox>-->
      </BlockCollapse>
      <BlockCollapse title="Оплата" name="pay">
        <switch-custom
          v-model="payment.payment_at_place"
          title="Оплата по месту оказания услуг"
          @input="
            updatePayment({
              type: 'payment_at_place',
              value: $event,
              hash: hash,
            })
          "
        >
          Выберите возможные варианты оплаты.
        </switch-custom>
        <div v-if="payment.payment_at_place" class="group-ckeckbox margin-opt">
          <el-checkbox
            v-model="payment.payment_at_place_cash"
            @input="
              updatePayment({
                type: 'payment_at_place_cash',
                value: $event,
                hash: hash,
              })
            "
          >
            Наличные
          </el-checkbox>
          <el-checkbox
            v-model="payment.payment_at_place_card"
            @input="
              updatePayment({
                type: 'payment_at_place_card',
                value: $event,
                hash: hash,
              })
            "
          >
            Картой
          </el-checkbox>
        </div>
        <switch-custom
          v-model="payment.online_payment"
          title="Оплата онлайн"
          @input="
            updatePayment({ type: 'online_payment', value: $event, hash: hash })
          "
        >
          Отметьте, если хотите включить онлайн оплату при бронировании услуги.
          Настроить приемы платежей можно в разделе «Настройки профиля».
        </switch-custom>
        <div v-if="payment.online_payment" class="margin-opt">
          <el-alert
            v-if="false"
            title="Сначала вам необходимо настроить приемы платежей в разделе «Настройки профиля»"
            type="warning"
            :closable="false"
            class="w-full"
            center
          />
          <el-radio-group
            v-model="payment.online_payment_type"
            @input="
              updatePayment({
                type: 'online_payment_type',
                value: $event,
                hash: hash,
              })
            "
          >
            <el-radio label="full_payment">Полная оплата</el-radio>
            <el-radio label="prepayment_amount">Предоплата</el-radio>
          </el-radio-group>
          <InputSelect
            v-if="payment.online_payment_type === 'prepayment_amount'"
            v-model="payment.prepayment_amount"
            class="w-limit"
            label="Размер предоплаты"
            placeholder="Введите значение"
            :list-value="listCost"
            :select-value="payment.prepayment_unit"
            @blur="
              updatePayment({
                type: 'prepayment_amount',
                value: payment.prepayment_amount,
                hash: hash,
              })
            "
            @setValue="setValue"
          ></InputSelect>

          <el-checkbox
            v-model="payment.payment_required"
            class="pay-online"
            @input="
              updatePayment({
                type: 'payment_required',
                value: $event,
                hash: hash,
              })
            "
          >
            Оплата онлайн обязательна
            <el-popover
              class="ml-8"
              placement="top"
              width="200"
              trigger="hover"
              content="Отметьте, если оплата или предоплата услуги онлайн является обязательной. Если
заказ не будет оплачен в течение указанного времени, бронирование будет
автоматически отменено.
"
            >
              <Tooltip slot="reference" />
            </el-popover>
          </el-checkbox>
          <div v-if="payment.payment_required">
            <p class="hint">Период для оплаты<span class="danger">*</span></p>
            <el-input
              v-model.number="payment.payment_required_period"
              class="w-limit mb-12"
              placeholder="Введите время"
              @blur="
                updatePayment({
                  type: 'payment_required_period',
                  value: payment.payment_required_period,
                  hash: hash,
                })
              "
            >
              <div slot="suffix">мин</div>
            </el-input>

            <!-- Пока без реализации -->

            <!-- <el-checkbox
              v-model="payment.payment_required_client"
              class="pay-online"
              @input="
                updatePayment({
                  type: 'payment_required_client',
                  value: $event,
                  hash: hash,
                })
              "
            >
              Комиссию оплачивает клиент?
              <el-popover
                class="ml-8"
                placement="top"
                width="200"
                trigger="hover"
                content="Отметьте, если хотите чтобы комиссия эквайринга добавлялась к сумме оплаты."
              >
                <Tooltip slot="reference" />
              </el-popover>
            </el-checkbox> -->
          </div>
        </div>
        <!-- <switch-custom v-model="payOrder" title="Оплата по счету">
          Отметьте, если хотите включить возможность формировать счета для
          юридических лиц.
        </switch-custom> -->
        <div v-if="payOrder" class="margin-opt">
          <el-alert
            v-if="false"
            title="Сначала вам необходимо настроить приемы платежей в разделе «Настройки профиля»"
            type="warning"
            :closable="false"
            class="w-full"
            center
          />
          <p class="hint">
            Выберите юридическое лицо<span class="danger">*</span>
          </p>
          <el-select
            v-model="selectFace"
            multiple
            filterable
            placeholder="Выберите категория"
            class="w-full mb-20"
          >
            <el-option
              v-for="item in []"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <p class="hint">Наименование платежа<span class="danger">*</span></p>
          <el-input
            v-model="textarea1"
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 4 }"
            placeholder="Введите текст ..."
          >
          </el-input>
        </div>
      </BlockCollapse>
      <BlockCollapse title="Особенности" name="qualitService">
        <el-form label-position="top">
          <el-row :gutter="$mq.large ? 10 : 30" class="flex-wrap" type="flex">
            <el-col :span="$mq.large ? 24 : 12">
              <el-form-item prop="age_limit">
                <span slot="label">
                  Возрастные ограничения<span class="danger">*</span>
                </span>
                <el-select
                  :value="service.age_limit"
                  placeholder="Select"
                  class="w-full"
                  @input="
                    setService({
                      field: 'age_limit',
                      value: $event,
                      autoSave: true,
                    })
                  "
                >
                  <el-option
                    v-for="(item, i) in ageLimitList"
                    :key="`age_limit-${i}`"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="$mq.large ? 24 : 12">
              <el-form-item label="Комментарий к возрасту">
                <el-input
                  :value="service.age_comments"
                  placeholder="Введите комментарий"
                  @blur="
                    autoSave({
                      field: 'age_comments',
                    })
                  "
                  @input="setService({ field: 'age_comments', value: $event })"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="$mq.large ? 24 : 12">
              <el-form-item prop="lang">
                <span slot="label">Язык<span class="danger">*</span></span>
                <el-select
                  :value="service.language"
                  placeholder="Select"
                  class="w-full"
                  @input="
                    setService({
                      field: 'language',
                      value: $event,
                      autoSave: true,
                    })
                  "
                >
                  <el-option
                    v-for="(item, i) in languageList"
                    :key="`language-${i}`"
                    :label="item.label.name_rus"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="$mq.large ? 24 : 12">
              <el-form-item prop="currency">
                <span slot="label">Валюта<span class="danger">*</span></span>
                <el-select
                  :value="service.currency"
                  placeholder="Select"
                  class="w-full"
                  @input="
                    setService({
                      field: 'currency',
                      value: $event,
                      autoSave: true,
                    })
                  "
                >
                  <el-option
                    v-for="(item, i) in currencyList"
                    :key="`currency-${i}`"
                    :label="item.label.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="$mq.large ? 24 : 12">
              <el-form-item prop="textButton">
                <span slot="label">
                  Текст основной кнопки<span class="danger">*</span>
                </span>
                <el-select
                  placeholder="Select"
                  class="w-full"
                  :value="service.button_name"
                  @input="
                    setService({
                      field: 'button_name',
                      value: $event,
                      autoSave: true,
                    })
                  "
                >
                  <el-option
                    v-for="(item, i) in buttonNameList"
                    :key="`button_name-${i}`"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="$mq.large ? 24 : 12">
              <el-form-item prop="timezone">
                <span slot="label">
                  Часовой пояс<span class="danger">*</span>
                </span>
                <el-select
                  placeholder="Select"
                  class="w-full"
                  filterable
                  :value="service.timezone"
                  @input="
                    setService({
                      field: 'timezone',
                      value: $event,
                      autoSave: true,
                    })
                  "
                >
                  <el-option
                    v-for="(item, i) in timezoneList"
                    :key="`timezone-${i}`"
                    :label="item.label"
                    :value="item.label"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <switch-custom
            :value="service.tags_switcher"
            title="Добавить тэги"
            @input="
              setService({
                field: 'tags_switcher',
                value: $event,
                autoSave: true,
              })
            "
          >
            Для каждой услуги можно указать тэги, которые будут отображаться в
            виджете бронирования (не более 5 штук).
          </switch-custom>
          <el-form-item v-if="service.tags_switcher" label="Добавленные тэги">
            <el-select
              :value="service.tags"
              class="w-full"
              multiple
              filterable
              allow-create
              default-first-option
              @input="
                setService({ field: 'tags', value: $event, autoSave: true })
              "
            />
          </el-form-item>
        </el-form>
      </BlockCollapse>
      <BlockCollapse
        title="Дополнительные настройки"
        name="AddSettings"
        sub-title="Дополнительные настройки для отображения расписания, праздничные и выходные дни."
      >
        <AddSettings is-tab />
      </BlockCollapse>
      <BlockCollapse title="Дополнительные опции" name="addOptions">
        <el-form hide-required-asterisk label-position="top">
          <p class="header">
            Вы можете добавить дополнительные опции к услуге:
            <el-popover
              class="ml-8"
              placement="top"
              width="200"
              trigger="hover"
              content="Отметьте, если оплата или предоплата услуги онлайн является обязательной. Если она не будет оплачена в течении указанного времени, бронирование будет автоматически отменено."
            >
              <!-- <Tooltip slot="reference" /> -->
            </el-popover>
          </p>
          <AddOptions
            v-model="addOptions"
            title-button="Добавить доп. услугу"
          ></AddOptions>
          <div class="hl"></div>
          <el-button
            type="primary"
            class="custom-button"
            @click="createAddOptions(addOptions)"
          >
            Сохранить
          </el-button>
        </el-form>
      </BlockCollapse>
      <BlockCollapse v-if="isUBRR" title="Связать с УБРиР" name="addBranchId">
        <LinkUBRR />
      </BlockCollapse>
    </el-collapse>
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import { AJAX_POST } from "@/units/ajax";
import AddSettings from "@/views/Services/Create/components/AddSettings";
import AddOptions from "@/components/AddOptions.vue";
import LinkUBRR from "@/views/Services/Create/components/LinkUBRR";

export default {
  name: "ResourceSettings",
  components: { AddSettings, AddOptions, LinkUBRR },
  data() {
    return {
      payOrder: false,
      selectFace: null,
      textarea1: "",
      payment: {},
      hash: null,
      addOptions: null,
      checkTown: null,
      checkBranch: null,
    };
  },
  computed: {
    ...mapState("service", ["service"]),
    ...mapState("settings", [
      "ageLimitList",
      "languageList",
      "currencyList",
      "buttonNameList",
      "timezoneList",
    ]),
    ...mapState("user", ["user", "ubrirCities", "ubrirBranches"]),
    ...mapGetters("resource", ["resource", "resourceAddress"]),
    ...mapGetters("user", ["isUBRR"]),
    ...mapState("widget", ["widget"]),
  },
  watch: {
    checkTown() {
      this.checkBranch = "";
    },
  },
  created() {
    this.listCost = [
      {
        label: "₽",
        value: "rub",
      },
      {
        label: "%",
        value: "percent",
      },
    ];
    if (this.service.additional_options.length)
      this.addOptions = [...this.service.additional_options];
    this.payment = { ...this.service.payment };
    this.hash = this.service.hash;
  },
  methods: {
    ...mapMutations("service", ["setService"]),
    ...mapMutations(["setNotif"]),
    ...mapActions("service", [
      "autoSave",
      "updateActive",
      "createAddOptions",
      "updatePayment",
      "getSettingsSchedule",
    ]),
    ...mapActions("service", ["addBranchId"]),
    ...mapActions("settings", ["getSettingsService"]),

    async getParamsSettings() {
      const { hash } = this.$route.params;
      await this.getSettingsService();
      await this.getSettingsSchedule({ hash });
    },
    addBranch() {
      this.addBranchId({
        hash: this.$data.hash,
        branch_id: this.$data.checkBranch,
      });
    },
    saveMainSettings(action) {
      let options;

      if (action === "mainSettings") {
        options = {
          name: this.mainSettings.name,
          lang: this.mainSettings.lang,
          currency: this.mainSettings.currency,
        };
        clearTimeout(this.mainSettings.timeout);

        this.mainSettings.timeout = setTimeout(() => {
          this.$refs.mainSettingsForm.validate((valid) => {
            if (valid) {
              AJAX_POST("/api/resource/update", {
                hash: this.resource.hash,
                action,
                ...options,
              }).then((response) => {
                console.log(response);
                this.$notify({
                  title: "Сохранено",
                  type: "success",
                  showClose: false,
                  duration: 3000,
                });
              });
            }
          });
        }, 1000);
      } else if (action === "needPay") {
        options = {
          need_pay: this.mainSettings.needPay,
        };

        AJAX_POST("/api/resource/update", {
          hash: this.resource.hash,
          action,
          ...options,
        }).then((response) => {
          this.$notify({
            title: "Сохранено",
            type: "success",
            showClose: false,
            duration: 3000,
          });
        });
      }
    },
    setValue(value) {
      this.payment.prepayment_unit = value;
      this.updatePayment({
        type: "prepayment_unit",
        value,
        hash: this.hash,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.header {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 12px;
}
.group-ckeckbox {
  display: flex;
  flex-direction: column;
}

.margin-opt {
  margin-left: 56px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}

.pay-online {
  display: flex;
  &::v-deep .el-checkbox__label {
    display: flex;
  }
}

::v-deep {
  .wrapper-is {
    margin-bottom: 18px;
  }
  .wrapper-switch {
    margin-bottom: 20px;
  }
  .el-radio {
    margin-bottom: 10px;
    &-group {
      margin-bottom: 5px;
    }
  }
}

.el-checkbox {
  margin-bottom: 10px;
}

.w-limit {
  width: 350px;
  @media (max-width: 480px) {
    width: auto;
  }
}

.hint {
  font-size: 12px;
  margin-bottom: 8px;
}

::v-deep .el-textarea__inner {
  background-color: $--color-light-white;
}
::v-deep .el-select__input {
  color: $--color-white;
}
</style>
