<!-- eslint-disable prettier/prettier -->
<template>
  <div class="schedule">
    <div v-if="!isTab" class="hl"></div>
    <div class="schedule__title">Расписание</div>
    <spinner v-if="isLoading" />
    <div v-else-if="errorMsg" class="custom-alert not-set-schedule">
      {{ errorMsg }}
    </div>

    <template v-else-if="schedule">
      <div v-for="(item, i) in schedule.data" :key="i" class="schedule__item">
        <div class="schedule__item-name">
          <div class="schedule__item-name__main">
            {{ getNameDate(item.date) }}
          </div>
          <div class="schedule__item-name__sub">
            {{ getNameDay(item.date) }}
          </div>
        </div>
        <div class="schedule__item-schedule">
          <div v-if="item.value.msg" class="custom-alert not-set-schedule">
            <span>{{ item.value.msg }}</span>
          </div>
          <div
            v-for="(slot, index) in item.value"
            :key="index"
            class="schedule-item text-center cell relative flex flex-col items-center"
            :class="{
              disabledSlot: disabledTime(slot, item),
              pointsSlot:
                slot.booking_hash || slot.bookingCount || slot.is_blocked,
            }"
          >
            <div v-if="!item.value.msg">
              <SlotForSchedule
                :item="slot"
                :data="item.date"
                :name-service="slot"
                :dis="disabledTime(slot, item)"
                @openForm="formOpen"
              />
              <!-- <div
              :class="[
                'schedule__item-cell__time',
                { disabledSlot: slot.block === true },
              ]"
              @click="onReserveSlot(item, slot)"
            > -->
              <div :class="['schedule__item-cell__time']">
                <div
                  class="schedule__item-cell__time cell__time bg-white-150 text-white-normal flex items-center justify-center font-semibold w-65 h-35 rounded-sm mb-5 cursor-pointer"
                  :class="{
                    disabledSlotTime: slot.active === false,
                  }"
                >
                  <span v-if="slot.discount" class="proc">%</span>

                  {{ slot.time }}
                </div>
                <span
                  v-if="slot.currency && !isUBRR"
                  class="cell__price text-white-500 text-12"
                  >{{ slot.price }}
                  {{ slot.currency }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="schedule__actions">
        <el-button
          type="primary"
          class="custom-button"
          :disabled="schedule.current_page === 1"
          @click="toSchedule(-1)"
        >
          <i class="el-icon-back"></i>
          {{ $mq.tablet ? "Пред. неделя" : "Предыдущая неделя" }}
        </el-button>
        <el-button
          type="primary"
          class="custom-button"
          :disabled="schedule.current_page === schedule.last_page"
          @click="toSchedule(1)"
        >
          {{ $mq.tablet ? "След. неделя" : "Следующая неделя" }}

          <i class="el-icon-right"></i>
        </el-button>
      </div>
    </template>

    <div v-else class="qwerty">
      Вы пока не настроили расписание. <br />
      Сделать это можно в разделе Даты и цены
    </div>

    <book-from
      v-if="isOpenFrom"
      :is-open.sync="isOpenFrom"
      :param="params"
      :date-slide="getDate"
      @closeForm="isOpFr"
      @openInBook="formOpen($event)"
    />
    <el-dialog
      v-if="isShowPay"
      :visible.sync="isShowPay"
      :show-close="false"
      width="450px"
      @close="isShowPay = false"
    >
      <PayWidget v-if="dataPay.success" @close="isShowPay = false" />
      <PayError v-else :msg="dataPay.msg" @close="isShowPay = false" />
    </el-dialog>
  </div>
</template>

<script>
import BookFrom from "@/views/Schedule/BookFrom.vue";
import { mapState, mapActions, mapGetters } from "vuex";
import SlotForSchedule from "@/components/SlotForSchedule";
import { monthNames, dayNames } from "@/helpers/date.js";
import { format, getDay, getDate, getMonth } from "date-fns";
import moment from "moment";
import PayError from "@/views/Widgets/components/PayError";
import PayWidget from "@/views/Widgets/components/PayWidget";

export default {
  name: "Schedule",
  components: {
    SlotForSchedule,
    BookFrom,
    PayError,
    PayWidget,
  },
  props: {
    isTab: Boolean,
  },
  data() {
    return {
      params: null,
      isShowPay: false,
      isLoading: true,
      isOpenFrom: false,
    };
  },
  computed: {
    // dateConvert() {
    //   return console.log(this.item.date);
    // },
    ...mapState("service", ["service", "schedule", "errorMsg"]),
    ...mapState("user", ["user"]),
    ...mapState("widget", ["widgetPublic", "dataPay"]),
    ...mapGetters("service", ["isHaveSessions"]),
    ...mapGetters("user", ["isUBRR"]),

    datePeriod() {
      return this.schedule.period
        ? `с ${format(new Date(this.schedule.period[0]), "dd.MM.yyyy")}
      по ${format(new Date(this.schedule.period[1]), "dd.MM.yyyy")}`
        : "";
    },
  },
  async created() {
    this.isLoading = true;
    this.monthNames = monthNames;
    this.dayNames = dayNames;

    // ЕСЛИ УДАЛИТЬ await this.getSchedule({ week: this.user.now_week }) ТО НЕ БУДЕТ РАСПИСАНИЯ ПРИ РЕДАКТИРОВАНИЯ

    // if (
    //   (!this.isTab && !this.schedule.data) ||
    //   this.$route.name === "resource"
    // ) {
    //   const week = this.$route.query.week ?? this.service.nearestWeek;
    //   await this.getSchedule({ week });
    // }

    // ДОБАВИЛ ДЛЯ ОПТИМИЗАЦИИ ЗАПРОСОВ

    if (
      (this.$route.query.tab === "schedule" || !this.schedule.data) &&
      this.$route.matched.find((e) => e.path).path
    ) {
      const week = this.$route.query.week ?? this.service.nearestWeek;
      await this.getSchedule({ week });
    }
    this.isLoading = false;
  },
  methods: {
    ...mapActions("service", [
      "getSchedule",
      "getResourceSlots",
      "getSettings",
    ]),
    ...mapActions("widget", ["getSlotDetails"]),
    ...mapActions({
      getSched: "schedule/getSchedule",
    }),
    ...mapActions({ getScheduleCalendar: "schedule/getScheduleCalendar" }),

    async isOpFr(param) {
      this.isShowPay = param.isShowPay;
      this.isOpenFrom = param.isOpenFrom;
    },

    async formOpen(item) {
      this.isLoading = true;
      await this.getSettings({ hash: item.hash, id: item.item?.id });
      await this.getSlotDetails({
        id: item.hash,
        date: item.date ?? this.params.date,
        time: item.item ? item.item.time : "00:00",
        // eslint-disable-next-line no-nested-ternary
        bookingHash: item.item
          ? item.item.booking_type !== "group" &&
            item.item.booking_type_sub !== "rent_depends_on_time_and_place"
            ? item.item.booking_hash
            : null || ""
          : null,
      });
      await this.getScheduleCalendar({ hash: item.hash, withSlots: true });

      this.isOpenFrom = true;
      this.$store.commit("service/setField", {
        field: "freeTimeDate",
        value: this.schedule.data[0].date,
      });
      this.$store.commit("widget/setField", {
        field: "errorMsg",
        value: "",
      });
      this.params = item;
      this.isLoading = false;
    },
    disabledTime(slot, item) {
      // if (!slot.active) return true;
      if (
        moment(item.date).format("YYYY-DD-MM") === moment().format("YYYY-DD-MM")
      ) {
        return moment(slot.time, "HH:mm") < moment();
      }
      if (moment(item.date).format("MM") === moment().format("MM")) {
        return moment(item.date).format("DD") < moment().format("DD");
      }
      if (
        moment(item.date).format("MM") < moment().format("MM") &&
        moment(item.date).format("YYYY") <= moment().format("YYYY")
      ) {
        return true;
      }
      if (moment(item.date).format("YYYY") > moment().format("YYYY")) {
        return false;
      }
      // if (moment(item.date).format("MM") === moment().format("MM")
      // ) {
      //   if (moment(item.date).format("DD") < moment().format("DD")) {
      //     return true;
      //   }
      //   return false;
      //   // moment(item.date).format("DD") < moment().format("DD");
      // }
    },

    async toSchedule(diff) {
      let page = this.schedule.current_page + diff;
      if (page < 1) page = 1;
      if (page > this.schedule.last_page) page = this.schedule.last_page;
      this.isLoading = true;
      this.$router.replace({
        query: {
          week:
            diff === 1
              ? this.schedule.next_page ?? this.user.now_week
              : this.schedule.prew_page,
          tab: "schedule",
        },
      });
      await this.getSchedule({
        week:
          diff === 1
            ? this.schedule.next_page ?? this.user.now_week
            : this.schedule.prew_page,
      });
      this.isLoading = false;
    },
    getNameDay(date) {
      let index = getDay(new Date(date));
      if (!index) {
        index = 6;
      } else --index;
      return this.dayNames[index].title;
    },
    getNameDate(date) {
      return `${getDate(new Date(date))} ${this.monthNames[
        getMonth(new Date(date))
      ].title.toLowerCase()}`;
    },
    async getWeekSchedule(params) {
      this.isLoading = true;
      await this.getSchedule({ week: params.week });
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.schedule {
  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: $--color-white;
    margin-bottom: 30px;
  }
  &__item {
    display: flex;
    flex-direction: row;
    margin-bottom: 18px;
    @media (max-width: 456px) {
      flex-direction: column;
    }
    &-name {
      flex-shrink: 0;
      width: 110px;
      margin-right: 13px;
      display: flex;
      flex-direction: column;
      align-items: start;
      &__main {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $--color-white;
      }
      &__sub {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: $--color-white-5;
      }
    }

    &-schedule {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      flex-grow: 1;

      .custom-alert {
        display: flex;
        justify-content: center;
        border-radius: 3px;
        flex-grow: 1;
        padding: 12px 0;
        font-size: 14px;
        line-height: 24px;
        height: fit-content;
      }
      .not-schedule {
        color: $--color-white;
        background: rgba(231, 76, 60, 0.2);
        border: 1px solid rgba(231, 76, 60, 0.5);
      }
      .not-set-schedule {
        color: $--color-white-5;
        background: rgba(255, 255, 255, 0.04);
        border: 1px solid rgba(255, 255, 255, 0.2);
      }
      .is-output {
        color: $--color-white;
        background: rgba(52, 152, 219, 0.2);
        border: 1px solid rgba(52, 152, 219, 0.5);
      }
    }
    &-cell {
      margin-right: 10px;
      margin-bottom: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;
      &__time {
        color: $--color-white;
        padding: 3px 5px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        //background-color: hsla(0, 0%, 100%, 0.15);
        margin-bottom: 5px;
        border-radius: 3px;
        cursor: pointer;
      }
      &__price {
        color: $--color-white-5;
        font-weight: 500;
        font-size: 12px;
        line-height: 13px;
      }
    }
  }
  &__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 420px) {
      justify-content: center;
    }
  }
}

.services-drag {
  &__wrapper {
    padding: 5px 0;
  }
  &__item-icon {
    display: flex;
    align-items: center;
    padding: 5px 15px;
    cursor: pointer;
    svg {
      color: $--color-white-5;
    }
    span {
      margin-left: 10px;
      color: $--color-white;
      font-size: 14px;
      line-height: 24px;
    }
  }
}
.wrapper-icon {
  position: relative;
  &__point {
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    width: 7px;
    height: 7px;
    border-radius: 50%;
  }
}
.qwerty {
  text-align: center;
}
.disabledSlot {
  pointer-events: none;
  .schedule__item-cell__time {
    color: $--color-white-5;
  }
}
.icon {
  &__schedule {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
  }
  &__close {
    background: #8a949d;
  }
  &__active {
    background: #3498db;
    color: #3c1452;
  }
  &__empty {
    background: transparent;
    top: inherit;
    width: 100%;
    height: 50%;
  }
}
.disabledSlot .cell__time {
  background: rgba(51, 14, 71, 0.9);
}
.disabledSlotTime {
  color: $--color-white-5;
}
.pointsSlot {
  pointer-events: auto;
}
.proc {
  position: absolute;
  top: 0px;
  left: 5px;
  font-size: 10px;
  color: #febb17;
}
</style>
