<template>
  <!-- <div class="pt-40 pb-80 w-full max-w-lg balance lg:pt-15 px-10"> -->
  <div class="pt-40 pb-80 w-full lg:pt-15 px-10">
    <Top
      title="Персонал"
      button-text="Добавить сотрудника"
      :font="{ size: 22, leading: 22 }"
      @onClick="openModal"
    />
    <Modal
      :show="addingPersonal"
      :person="changePerson"
      :correct-user-title="correctUserTitle"
      @created="closeModal(true)"
      @close="
        changePerson = null;
        closeModal(false);
      "
    />
    <div class="relative">
      <spinner v-if="loading && !personalsError" class="table__spinner" />
      <NoData
        v-if="
          personalsError ||
          (!loading && personals.length === 0) ||
          (!loading && !personals)
        "
        :text="personalsError"
      />
      <!-- <NoData
        v-if="(!loading && personals.length === 0) || (!loading && !personals)"
        text="Сотрудников нет."
        button="Добавить сотрудника"
        @onClick="openModal"
      /> -->
      <Items
        v-if="!loading && personals"
        ref="items"
        :user="user"
        :elements="personals"
        @correctPerson="correctPerson"
        @delete="deletePersonalBy"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Items from "@/components/Personal/Items";
import Top from "@/components/Pages/Top";
import NoData from "@/components/NoData";
import Modal from "@/components/Personal/CreatePersonal";

export default {
  name: "Personal",
  components: {
    Items,
    Top,
    NoData,
    Modal,
  },
  data() {
    return {
      changePerson: {},
      correctUser: false,
      addingPersonal: false,
      loading: false,
      topOption: {
        size: 28,
        leading: 44,
        mq: { value: "lg", size: 22, leading: 36 },
      },
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("personals", ["personals", "personalsError"]),

    correctUserTitle() {
      return !this.correctUser
        ? "Добавить сотрудника"
        : "Редактирование сотрудника";
    },
  },
  created() {
    this.getItems();
  },
  methods: {
    ...mapActions("personals", ["getPersonals", "delete"]),
    correctPerson(id, param) {
      console.log({ param });
      this.correctUser = !!param;
      this.changePerson = this.personals.find((person) => person.id === id);
      this.addingPersonal = true;
    },
    async getItems() {
      if (!this.loading) this.loading = true;
      await this.getPersonals();
      this.loading = false;
      //  if (this.personals) this.$refs.items.setData();
    },
    openModal() {
      this.changePerson = null;
      this.addingPersonal = true;
    },
    closeModal(loadData) {
      if (loadData) this.getItems();
      this.correctUser = false;
      this.addingPersonal = false;
    },
    cancelAddingPersonal() {
      this.$refs.formAddingPersonal.resetFields();
      this.addingPersonal = false;
    },
    async deletePersonalBy(id) {
      this.$confirm("", "Удалить сотрудника?", {
        confirmButtonText: "Удалить",
        cancelButtonText: "Отмена",
        type: "warning",
      }).then(async () => {
        this.loading = true;
        await this.delete(id);
        setTimeout(() => {
          this.getItems();
        }, 1000);
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped></style>
