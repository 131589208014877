<template>
  <div class="create-service-wrapper">
    <el-collapse>
      <BlockCollapse
        title="Описание услуги и контактные данные"
        sub-title="Основная информация о вашей услуге и контактные данные"
        name="info"
      >
        <Info />
      </BlockCollapse>

      <BlockCollapse
        title="Настройка типа записи и ценообразования"
        name="schedule"
        :disabled="isNew"
        sub-title="Настройте тип записи (индивидуальная, групповая, аренда или дерево услуг) и укажите цены."
      >
        <OnlineSchedule />
      </BlockCollapse>

      <BlockCollapse
        title="График работы и расписание"
        name="generator"
        :disabled="isNew || !isHaveSettings"
        sub-title="Настройте график работы и сформируйте расписание генератором или задайте время сеансов вручную."
      >
        <Generator />
      </BlockCollapse>
      <BlockCollapse
        v-if="isUBRR"
        title="Связать с УБРиР"
        sub-title="Свяжите услугу с городом и отделением"
        name="linkUBRR"
        :disabled="isNew || !isHaveSettings"
      >
        <LinkUBRR />
      </BlockCollapse>

      <BlockCollapse
        :disabled="isNew"
        title="Уведомления клиентам и сотрудникам"
        name="notification"
        sub-title="Настройка уведомлений о бронировании для администраторов и клиента"
      >
        <Notification />
      </BlockCollapse>

      <BlockCollapse
        :disabled="isNew"
        title="Дополнительные настройки"
        name="AddSettings"
        sub-title="Дополнительные настройки для отображения расписания"
      >
        <AddSettings />
      </BlockCollapse>

      <BlockCollapse
        :disabled="isNew"
        title="Даты праздников"
        name="weekendAddDates"
        sub-title="Укажите даты праздничных дней, в которые цена на сеансы в расписании будет браться из поля «Цена в праздники»"
      >
        <AddDates type="weekend" />
      </BlockCollapse>

      <BlockCollapse
        :disabled="isNew"
        title="Нерабочие дни"
        name="nonworkingAddDates"
        sub-title="Укажите даты нерабочих дней, когда сеансы в расписании будут недоступны для бронирования"
      >
        <AddDates type="nonworking" />
      </BlockCollapse>

      <BlockCollapse
        :disabled="isNew"
        title="Дополнительные сеансы"
        name="slotsDate"
        sub-title="При необходимости, вы можете добавить дополнительные сеансы на определенные даты"
      >
        <SlotsDate />
      </BlockCollapse>
    </el-collapse>
  </div>
</template>

<script>
import LinkUBRR from "@/views/Services/Create/components/LinkUBRR";
import Info from "@/views/Services/Create/components/Info";
import OnlineSchedule from "@/views/Services/Create/components/OnlineSchedule";
import Generator from "@/views/Services/Create/components/Generator";
import Notification from "@/views/Services/Create/components/Notification";
import AddSettings from "@/views/Services/Create/components/AddSettings";
import AddDates from "@/views/Services/Create/components/AddDates";
import SlotsDate from "@/views/Services/Create/components/SlotsDate";
import BlockCollapse from "@/components/BlockCollapse";
import { mapState, mapGetters } from "vuex";

export default {
  name: "CreateResource",
  components: {
    LinkUBRR,
    Generator,
    OnlineSchedule,
    Info,
    Notification,
    BlockCollapse,
    AddSettings,
    AddDates,
    SlotsDate,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("service", ["service", "isBranch"]),

    ...mapGetters("service", ["isNew", "isHaveSettings"]),
    ...mapGetters("user", ["isUBRR"]),
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

::v-deep .el-collapse {
  border-bottom: none;
}
.collapse {
  &-title {
    padding: 30px 20px;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: center;

    svg {
      color: $--color-white;
      margin-right: 25px;
    }

    h2 {
      font-size: 22px;
      font-weight: 600;
      line-height: 28px;
      color: $--color-primary;
      margin-bottom: 6px;
    }
    p {
      font-size: 12px;
      line-height: 16px;
      color: $--color-white-5;
    }
  }

  &-content {
    padding: 30px;
    border-top: 1px solid $--color-light-white;
  }
}

@screen lg {
  .collapse {
    &-title {
      padding: 20px;

      h2 {
        font-size: 18px;
        line-height: 20px;
      }
    }

    &-content {
      padding: 15px;
    }
  }
}

::v-deep .input-apend-select .el-input-group__append .el-select {
  margin: 0;
}

::v-deep .el-input .el-input__count .el-input__count-inner {
  background: inherit !important;
}

::v-deep .input-apend-select {
  .el-input-group__append {
    width: 100px;
  }

  > .el-input__inner {
    text-align: right;
    padding-right: 0;
  }
}

::v-deep .el-textarea textarea {
  background-color: rgba(255, 255, 255, 0.1);
}

.create-service-wrapper {
  padding-bottom: 80px;

  .el-collapse-item.is-disabled {
    h2 {
      @apply .text-white-300;
    }
  }
}
</style>
