<template>
  <el-row :gutter="40">
    <el-col
      v-for="(block, i) in listBlock"
      :key="i"
      :span="$mq.tablet ? 24 : 8"
    >
      <div class="custom-block block">
        <div class="block__header">{{ block.header }}</div>
        <div class="block__sub-header">{{ block.subHeader }}</div>
        <el-button
          class="block__button"
          type="primary"
          :disabled="block.isDisabled"
          @click="$router.push({ ...block.router })"
        >
          {{ block.isDisabled ? "В разработке" : block.buttonText }}
        </el-button>
        <!-- <router-link
          :to="{ ...block.routerExemple }"
          class="block__link-example"
        >
          Пример
        </router-link> -->
      </div>
    </el-col>
  </el-row>
</template>

<script>
export default {
  name: "BlockCreateWidget",
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  created() {
    this.listBlock = [
      {
        header: "Виджет для сайта",
        subHeader: "Создайте виджет для вашего сайта",
        buttonText: "Создать виджет",
        router: { name: "create-widget" },
        routerExemple: {},
        isDisabled: false,
      },
      {
        header: "Мини-сайт",
        subHeader: "Создайте виджет для вашего сайта",
        buttonText: "Создать мини-сайт",
        router: {},
        routerExemple: {},
        isDisabled: true,
      },
      {
        header: "Кнопка действия",
        subHeader: "Создайте виджет для вашего сайта",
        buttonText: "Создать кнопку",
        router: {},
        routerExemple: {},
        isDisabled: true,
      },
    ];
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.custom-block {
  padding: 30px;
}

.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__header {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 5px;
  }
  &__sub-header {
    font-size: 14px;
    line-height: 24px;
    color: $--color-white-5;
    margin-bottom: 30px;
    text-align: center;
  }
  &__button {
    margin-bottom: 15px;
  }
  &__link-example {
    color: $--color-white-5;
    padding-bottom: 3px;
    border-bottom: 1px solid $--color-white-5;
  }
}
</style>
