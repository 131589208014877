import { setField, setFieldObj, getError } from "@/helpers/store";
import { AJAX_GET, AJAX_DELETE, AJAX_POST, AJAX_PATCH } from "@/units/ajax";

export default {
  namespaced: true,
  state: {
    info: null,
    defaultInfo: null,
    smtpInfo: null,
  },
  mutations: {
    setField,
    setFieldObj,
  },
  actions: {
    async getInfo({ commit }) {
      try {
        const { data } = await AJAX_GET("/api/notify/templates");
        commit("setField", { field: "info", value: data });
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при получении данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async getDefaultInfo({ commit }) {
      try {
        const { data } = await AJAX_GET("/api/notify/templates/default");
        commit("setField", { field: "defaultInfo", value: data });
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при получении данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async getSMTP({ commit }) {
      try {
        const { data } = await AJAX_POST("/api/notify/smtp");
        commit("setField", { field: "smtpInfo", value: data });
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при получении данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async setSMTP({ commit, dispatch }, value) {
      try {
        const { data } = await AJAX_POST("/api/notify/smtp/save", value);
        if (data.success) {
          commit(
            "setNotif",
            {
              title: "Данные сохранены",
            },
            { root: true }
          );
        } else {
          commit(
            "setNotif",
            {
              title: data.msg || "Ошибка при отправке данных. Повторите еще",
              type: "error",
            },
            { root: true }
          );
        }
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при отправке данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async checkAuthSMTP({ commit }) {
      try {
        const { data } = await AJAX_POST("/api/notify/smtp/test");
        if (data.success) {
          commit(
            "setNotif",
            {
              title: data.msg || "Данные сохранены",
            },
            { root: true }
          );
        } else {
          commit(
            "setNotif",
            {
              title: data.msg || "Ошибка при отправке данных. Повторите еще",
              type: "error",
            },
            { root: true }
          );
        }
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при отправке данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },
  },
};
