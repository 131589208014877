<template>
  <div class="">
    <div v-if="!isForPlace" class="flex my-30">
      <div class="w-1/2">
        <span class="small_text">Дата и время</span>
        <span class="small_text">Цена за группу</span>
        <span class="small_text">Группа </span>
        <span v-if="group.group.price_add_persons" class="small_text"
          >Цена за доп. уч.
        </span>
        <span v-if="group.group.add_persons" class="small_text"
          >Доп. участников
        </span>
      </div>
      <div class="w-1/2 mb-3">
        <span class="underline">{{ group.date_time }}</span>

        <span class="small_text-outcome"
          >{{ group.group.price }} {{ item.currency }}
        </span>
        <div class="small_text-outcome">
          <span>{{
            `От ${group.group.persons_from} до ${group.group.persons_to}  человек`
          }}</span>
        </div>
        <span v-if="group.group.price_add_persons" class="small_text-outcome"
          >{{ group.group.price_add_persons }} {{ item.currency }}
        </span>
        <span v-if="group.group.add_persons" class="small_text-outcome"
          >{{ group.group.add_persons }}
        </span>
      </div>
    </div>
    <div v-else class="flex my-30">
      <div class="w-1/2">
        <span class="small_text">Дата и время</span>
        <span class="small_text">Цена за участника</span>
        <span class="small_text">Участников </span>
      </div>
      <div class="w-1/2 mb-3">
        <span class="underline">{{ group.date_time }}</span>

        <span class="small_text-outcome"
          >{{ item.book_info.individual.price }} {{ item.currency }}
        </span>

        <span class="small_text-outcome"
          >{{ item.book_info.individual.persons }}
        </span>
      </div>
    </div>
    <div v-if="isAdditionalOptCheck || isAdditionalOpt" class="">
      <div class="mb-10 whitespace-nowrap inline-block leading-25">
        Дополнительные опции
      </div>

      <div class="flex justify-between mb-3">
        <span class="small_text w-4/12">Название </span>
        <span class="small_text w-4/12">Кол-во </span>
        <span class="small_text w-4/12">Стоимость </span>
      </div>
      <div
        v-for="(i, idx) in isAdditionalOpt"
        :key="idx"
        class="flex justify-between mb-3"
      >
        <span class="small_text-outcome w-4/12 leading-1">{{ i.name }} </span>
        <span class="small_text-outcome w-4/12 leading-1"
          >{{ i.selected }}
        </span>
        <span class="small_text-outcome w-4/12 leading-1"
          >{{ i.price !== null ? i.price : 0 }} {{ item.currency }}
        </span>
      </div>

      <div
        v-for="(i, idx) in isAdditionalOptCheck"
        :key="idx"
        class="flex justify-between mb-3"
      >
        <span class="small_text-outcome w-4/12 leading-1">{{ i.name }} </span>
        <span class="small_text-outcome w-4/12 leading-1">{{ 1 }} </span>
        <span class="small_text-outcome w-4/12 leading-1"
          >{{ i.price !== null ? i.price : 0 }} {{ item.currency }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderGroupPers",
  props: {
    group: {
      type: Object,
    },
    item: {
      type: Object,
    },
  },
  computed: {
    isForPlace() {
      return (
        this.item.booking_type.individual_depends_on_the_number_of_seats
          .price === "for_a_place"
      );
    },
    isAdditionalOptCheck() {
      return this.group.add_options_check
        ? this.group.add_options_check
        : false;
    },
    isAdditionalOpt() {
      return this.group.add_options_input
        ? this.group.add_options_input
        : false;
    },
  },
  created() {
    console.log(this.item);
  },
};
</script>
<style lang="scss" scoped>
.rotate {
  transform: rotate(180deg);
}
.drop-wrapp {
  display: flex;
  cursor: pointer;
}
.small_text {
  &-total {
    font-size: 12px;

    color: rgba(255, 190, 61, 1);
  }
  &-drop {
    margin-right: 8px;
    font-size: 12px;
  }
  &-outcome {
    font-weight: 500;
    opacity: 1;
    font-size: 12px;
    line-height: 20px;
    display: block;
  }
}
.utf {
  &-form {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    margin: 10px 0px;
    padding: 8px 15px;
  }
  &-icon {
    color: rgba(255, 255, 255, 0.5);
    position: relative;
    top: 3px;
  }
}
</style>
