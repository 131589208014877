/* eslint-disable no-useless-escape */
<template>
  <div class="pt-40 pb-80 w-full max-w-lg resource lg:pt-15">
    <div class="lg:px-15">
      <el-button size="medium" class="mb-30 lg:mb-20 px-12" @click="goBack"
        ><span class="flex items-center">
          <svg-icon
            icon-name="arrow"
            class="text-white-normal rotate-180 mr-10"
            width="13"
            height="8"
          ></svg-icon
          >{{ mode === "single" ? "К настройкам интеграции" : "К виджетам" }}
        </span></el-button
      >

      <h1
        class="text-28 leading-44 font-semibold text-white-normal mb-20 lg:text-22 lg:leading-36"
      >
        Создание стандартного виджета
      </h1>
      <el-alert
        title="Виджет создан"
        type="success"
        class="mb-40"
        :closable="false"
        show-icon
      >
      </el-alert>
    </div>

    <div class="bg-white-40">
      <div class="pt-20">
        <change-widget-mode
          v-if="mode === 'multiple'"
          class="px-30 lg:px-15"
          @onChange="onChangeMode"
        ></change-widget-mode>

        <div class="px-30 pb-40 lg:px-15">
          <div
            class="text-18 font-semibold leading-30 mb-3 lg:text-16 lg:leading-23"
          >
            Выберите внешний вид модуля
          </div>
          <p class="text-white-500 text-12 leading-16 mb-30">
            В соответствии с дизайном вашего сайта выберите параметры внешнего
            вида виджета.
          </p>
          <el-row :gutter="30">
            <el-col
              v-for="(widget, key) in widgets"
              :key="key"
              :span="$mq.desktop ? 24 : 8"
              class="xl:mb-20"
            >
              <div class="flex flex-col items-center">
                <div
                  class="relative pt-28 pb-18 px-25 rounded-sm w-full flex flex-col items-center mb-16 cursor-pointer"
                  :class="{
                    'bg-white-normal': key === 'white',
                    'bg-gray-900': key === 'standard',
                    'bg-cover bg-no-repeat bg-center': key === 'custom',
                  }"
                  :style="
                    key === 'custom'
                      ? `background-image: url(${widgetBg(
                          'transp-widget.jpeg'
                        )})`
                      : ''
                  "
                  @click="handleWidgetClick(key)"
                >
                  <div
                    v-if="widget"
                    class="bg-green-400 rounded-sm flex items-center justify-center absolute w-20 h-20 widget-check"
                  >
                    <svg-icon
                      icon-name="check"
                      class-name="text-black w-10 h-7"
                    ></svg-icon>
                  </div>
                  <div
                    class="absolute border-green-400 pointer-events-none border-2 w-full h-full top-0 left-0 rounded-sm"
                    :class="widget ? 'block' : 'hidden'"
                  ></div>
                  <div class="flex flex-wrap justify-center">
                    <div
                      v-for="(slot, i) in 4"
                      :key="i"
                      class="schedule-item flex flex-col items-center mb-13"
                      :class="{ 'mr-7': i !== 3 }"
                    >
                      <div
                        class="flex items-center justify-center font-semibold w-65 h-35 rounded-sm mb-5 cursor-pointer"
                        :class="{
                          'bg-white-150 text-white-normal': key === 'standard',
                          'bg-gray-10 text-black': key === 'white',
                          'bg-white-200 text-white-normal': key === 'custom',
                        }"
                      >
                        23:00
                      </div>
                      <span
                        class="text-white-500 text-12"
                        :class="{
                          'text-gray-300': key === 'white',
                          'text-white-500':
                            key === 'standard' || key === 'custom',
                        }"
                        >600 ₽</span
                      >
                    </div>
                  </div>
                  <div
                    class="text-16 font-semibold leading-28"
                    :class="{ 'text-black': key === 'white' }"
                  >
                    {{
                      key === "standard"
                        ? "Темный"
                        : key === "white"
                        ? "Светлый"
                        : "Темный прозрачный"
                    }}
                  </div>
                </div>
                <router-link
                  :to="{
                    name: 'widget-example',
                    query: {
                      theme:
                        key === 'standard'
                          ? 'dark'
                          : key === 'white'
                          ? 'white'
                          : 'custom',
                      ids:
                        widgetType === 1
                          ? widgetSingleResource
                          : widgetResources.toString(),
                    },
                  }"
                  class="text-12 text-white-500 leading-16 cursor-pointer underline"
                  >Пример виджета</router-link
                >
              </div>
            </el-col>
          </el-row>
        </div>

        <div class="p-30 border-t border-white-150 lg:px-15">
          <div class="flex items-center lg:flex-col lg:items-stretch">
            <el-button
              type="primary"
              class="bg-gradient-b-yellow border-0 xs:w-full mr-30 lg:mr-0 lg:mb-10"
              @click="onGetCode"
              >Получить код</el-button
            >
            <div
              v-if="!showCode"
              class="rounded-sm border border-blue-100 bg-blue-10 lg:order-1"
            >
              <div
                class="text-blue-200 py-13 px-17 leading-none lg:text-center"
              >
                Нажмите на “Получить код”, чтобы получить код виджета для вашей
                страницы
              </div>
            </div>
          </div>
          <div v-if="showCode" class="mt-30 lg:mt-10">
            <div
              class="text-18 leading-30 font-semibold mb-3 lg:text-16 lg:leading-23"
            >
              Скопируйте полученный код и вставьте на страницу Вашего сайта
            </div>
            <div class="text-white-500 text-12 leading-16 mb-30">
              Чтобы внедрить виджет с расписанием, скопируйте полученный код и
              вставьте его на внутреннюю страницу вашего сайта.
            </div>
            <div
              class="rounded-sm border border-blue-100 bg-blue-10 widget-code"
            >
              <el-input
                ref="code"
                type="textarea"
                :value="value"
                resize="none"
                class="bg-transparent max-h-500 overflow-auto text-12"
                :autosize="true"
              ></el-input>
            </div>
            <el-button class="px-15 lg:w-full mt-20" @click="copyCode">
              <div class="flex items-center justify-center">
                <svg-icon
                  icon-name="copy"
                  class-name="text-white-normal mr-8"
                  width="16"
                  height="18"
                ></svg-icon
                >Скопировать код
              </div></el-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ChangeWidgetMode from "../components/ChangeWidgetMode";
import { AJAX_POST, host } from "../units/ajax";

export default {
  name: "CreateStandardWidget",
  components: { ChangeWidgetMode },
  data() {
    return {
      widgets: {
        standard: true,
        white: false,
        custom: false,
      },
      widgetSingleResource: "",
      widgetType: "",
      widgetResources: "",
      showCode: false,
      mode: "single",
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    value() {
      return `<link href="${host}/css/app.css" rel="stylesheet">
<widget-single><\/widget-single>
<script src="${host}/js/app.js"><\/script>`;
    },
  },
  mounted() {
    this.mode = this.$route.query.mode || "single";
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    onChangeMode(data) {
      this.widgetSingleResource = data.widgetSingleResource;
      this.widgetResources = data.widgetResources;
      this.widgetType = data.widgetType;
    },
    handleWidgetClick(type) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.widgets) {
        this.widgets[key] = false;
      }
      this.widgets[type] = true;
    },
    widgetBg(img) {
      return require(`../assets/${img}`);
    },
    copyCode() {},
    onGetCode() {
      AJAX_POST("/api/widget/set", {
        type: this.widgetType === 1 ? "standart" : "multi",
        resources:
          this.widgetType === 1
            ? Array.of(this.widgetSingleResource)
            : this.widgetResources,
        // eslint-disable-next-line no-nested-ternary
        style: this.widgets.standard
          ? "dark"
          : this.widgets.white
          ? "light"
          : "dark_transparent",
      }).then((response) => {
        if (response.data.success) {
          this.showCode = true;
          this.$notify({
            title: "Виджет создан",
            type: "success",
            showClose: false,
            duration: 3000,
          });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.widget-code {
  .el-textarea__inner {
    padding: 15px 40px 21px 20px;
  }
}

.multiple-control {
  max-width: 558px;
}

.widget-check {
  top: 10px;
  right: 10px;
}
</style>
