<template>
  <div>
    <div class="m-30 content-text" v-html="userAgreeText"></div>
  </div>
</template>

<script>
import { AJAX_POST } from "../units/ajax";

export default {
  name: "UserAgree",
  data() {
    return {
      userAgreeText: null,
    };
  },
  created() {
    let id = "";
    if (this.$route.name) id = this.$route.name;
    this.getUserAgree(id);
  },
  methods: {
    getUserAgree(id) {
      AJAX_POST("/api/getstaticpage", { id }).then((response) => {
        document.title = response.data.title;
        this.userAgreeText = response.data.text;
      });
    },
  },
};
</script>

<style lang="scss">
.content-text {
  h1 {
    font-size: 16px;
  }
  h2 {
    font-size: 14px;
  }
  h3 {
    font-size: 12px;
  }
  b {
    font-weight: 900;
  }
  a {
    text-decoration: revert;
  }
}
</style>
