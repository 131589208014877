<template>
  <el-drawer
    :show-close="false"
    :visible.sync="show"
    direction="rtl"
    size="340px"
  >
    <div slot="title" class="pt-10 px-20 pb-23">
      <div class="flex items-center justify-between">
        <div class="font-semibold text-18 leading-30 text-white-normal">
          {{ editItem ? "Редактирование" : "Добавление" }} клиента
        </div>
        <div class="cursor-pointer" @click="$emit('close')">
          <svg-icon
            icon-name="close"
            width="12"
            height="12"
            class-name="text-white-normal"
          ></svg-icon>
        </div>
      </div>
    </div>
    <simplebar data-simplebar-auto-hide="false">
      <div class="h-full relative px-20">
        <el-form
          ref="item"
          hide-required-asterisk
          label-position="top"
          :rules="rules"
          :model="form"
          class="relative"
        >
          <el-col :span="24">
            <el-form-item label="Имя" class="mb-20" prop="name">
              <el-input
                v-model="form.name"
                placeholder="Введите имя"
                type="text"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Номер телефона" class="mb-20" prop="phone">
              <el-input
                v-model="form.phone"
                placeholder="Введите номер телефона"
                type="text"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Почта" class="mb-20" prop="email">
              <el-input v-model="form.email" placeholder="Введите почту" />
            </el-form-item>
          </el-col>
          <el-button type="primary" class="ml-auto block">Сохранить</el-button>
        </el-form>
      </div>
    </simplebar>
  </el-drawer>
</template>

<script>
import simplebar from "simplebar-vue";

export default {
  components: {
    simplebar,
  },
  props: {
    show: Boolean,
    editItem: Object,
  },
  data() {
    return {
      form: {
        name: null,
        email: null,
        phone: null,
      },
      rules: {
        name: [
          {
            required: true,
            message: "Введите имя клиент",
            trigger: ["blur", "change"],
          },
        ],
        phone: [
          {
            type: "phone",
            min: 7,
            required: true,
            message: "Введите номер клиент",
            trigger: ["blur", "change"],
          },
        ],
        email: [
          {
            type: "email",
            required: true,
            message: "Введите номер клиент",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  created() {
    if (this.editItem) {
      this.form.name = this.editItem.name;
      this.form.phone = this.editItem.phone;
      this.form.email = this.editItem.email;
    }
  },
};
</script>
