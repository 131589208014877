<template>
  <simplebar data-simplebar-auto-hide="true">
    <section class="sidebar text-white-normal flex flex-col">
      <header
        class="sidebar-header pt-30 pb-0 pr-35 pl-25 mb-40 lg:pl-0 lg:pr-0 lg:pt-0 lg:mb-15"
      >
        <span v-if="!$mq.tablet" class="flex items-center sidebar-logo">
          <span
            class="cursor-pointer mr-20 sidebar-burger"
            @click="collapseSidebar"
          >
            <svg-icon
              icon-name="burger"
              class-name="text-white-normal"
              width="30"
              height="16"
            ></svg-icon>
          </span>
          <router-link
            :to="{ name: 'dashboard' }"
            class="sidebar-header__logo block"
          >
            <img v-if="srcLogo" width="167" :src="srcLogo" alt="" />
            <svg-icon
              v-else
              icon-name="logo"
              width="167"
              height="28"
              class-name="sidebar-header__logo--full"
            ></svg-icon>
          </router-link>
        </span>
      </header>

      <div class="sidebar-body mb-40">
        <template v-for="(item, index) in menu">
          <el-tooltip
            :key="index"
            effect="dark"
            :content="item.label"
            placement="right"
            :disabled="!isSidebarCollapsed"
          >
            <template v-if="!item.action">
              <router-link
                :to="item.path"
                class="text-white flex items-center sidebar-link relative"
                @click.native="closeSidebar"
              >
                <div
                  class="pl-20 pr-15 sidebar-body__link flex items-center font-medium text-white leading-36 hover:bg-white-100 w-full"
                  :class="{ active: $route.path.indexOf(item.path) !== -1 }"
                >
                  <span
                    v-if="item.expandable"
                    class="cursor-pointer sidebar-expand-chevron"
                    :class="{ 'rotate-180': !item.expandable.state }"
                    @click="toggleExpand(item.id)"
                  >
                    <svg-icon
                      icon-name="chevron"
                      class-name="text-white-500"
                      width="8"
                      height="5"
                    ></svg-icon>
                  </span>
                  <span
                    v-if="item.addLink && item.addLink.value > 0"
                    class="sidebar-expand-feedback w-6 h-6 min-w-6 rounded-full bg-yellow-100"
                  ></span>

                  <!-- <template v-if="!item.action">
                <router-link
                  :to="item.path"
                  class="text-white flex items-center sidebar-link"
                  @click.native="closeSidebar"
                > -->
                  <svg-icon
                    :icon-name="item.icon"
                    width="18"
                    height="18"
                    class-name="mr-12 text-white-350 sidebar-link__icon"
                  ></svg-icon>

                  <span class="sidebar-link__label">{{ item.label }} </span>
                  <!-- </router-link>
              </template> -->

                  <template v-if="item.action.name === 'logout'">
                    <span
                      class="text-white cursor-pointer flex items-center sidebar-link"
                      @click="logout"
                    >
                      <svg-icon
                        icon-name="exit"
                        width="18"
                        height="18"
                        class-name="mr-12 text-white-350 sidebar-link__icon"
                      ></svg-icon>
                      <span class="sidebar-link__label">{{ item.label }}</span>
                    </span>
                  </template>

                  <span
                    v-if="item.count"
                    class="sidebar-expand-count leading-12 text-10 font-extrabold text-black bg-white-normal h-17 px-4 flex items-center justify-center rounded-full min-w-20"
                  >
                    {{ item.count.value }}
                  </span>

                  <a
                    v-if="item.addLink && item.addLink.value > 0"
                    :href="item.addLink.path"
                    class="ml-auto flex items-center mr-10"
                  >
                    <el-button
                      size="mini"
                      type="primary"
                      class="font-bold bg-gradient-b-yellow border-0"
                    >
                      {{ item.addLink.prefix }}{{ item.addLink.value }}
                      {{ item.addLink.suffix }}
                    </el-button>
                  </a>
                  <span
                    v-if="item.expandable"
                    class="sidebar-expand ml-15 uppercase text-10 font-semibold leading-none mt-3 cursor-pointer hover:text-yellow-300"
                    :class="{
                      'text-white-500': item.expandable.state,
                      'text-yellow-100': !item.expandable.state,
                    }"
                    @click="toggleExpand(item.id)"
                  >
                    {{ item.expandable.state ? "Свернуть" : "Развернуть" }}
                  </span>
                  <el-button
                    v-if="item.count"
                    size="mini"
                    type="warning"
                    class="font-bold min-w-20 cursor-auto"
                    :class="{ 'ml-auto': !item.addLink }"
                    >{{ item.count.prefix }} {{ item.count.value }}
                    {{ item.count.suffix }}
                  </el-button>
                  <el-button
                    v-if="item.fastAdd"
                    size="mini"
                    type="warning"
                    class="sidebar-add ml-10 z-10"
                    @click="toggleFastAdd(item.id)"
                  >
                    <div>
                      <svg-icon
                        icon-name="plus"
                        width="10"
                        height="10"
                        class-name="sidebar-add__icon"
                      ></svg-icon>
                    </div>
                  </el-button>
                </div>
              </router-link>
            </template>
          </el-tooltip>

          <div
            v-if="item.expandable && item.expandable.state"
            :key="'qwe' + index"
            class="sidebar-body-extra bg-white-70 mb-5 py-10"
          >
            <template v-for="(subitem, i) in item.expandable.content">
              <el-tooltip
                :key="i"
                class="item"
                effect="dark"
                :content="subitem.label"
                placement="right"
                :disabled="!isSidebarCollapsed"
              >
                <router-link
                  :to="subitem.path"
                  class="px-25 sidebar-body__link flex items-center font-medium text-white leading-36 hover:bg-white-100"
                  :class="{ active: $route.path.indexOf(subitem.path) !== -1 }"
                  @click.native="closeSidebar"
                >
                  <svg-icon
                    :icon-name="subitem.icon"
                    width="18"
                    height="18"
                    class-name="mr-12 text-white-350 sidebar-link__icon"
                  ></svg-icon>
                  <span class="sidebar-link__label">{{ subitem.label }}</span>
                </router-link>
              </el-tooltip>
            </template>
          </div>

          <el-dialog
            v-if="item.fastAdd"
            :key="index + 'fastAdd'"
            title="Пополнить баланс"
            append-to-body
            :visible.sync="item.fastAdd.state"
            :show-close="false"
            custom-class="max-w-md w-full filling-balance-popup"
          >
            <div
              slot="title"
              class="absolute fast-add-close"
              @click="toggleFastAdd(item.id)"
            >
              <div class="cursor-pointer">
                <svg-icon
                  icon-name="close"
                  width="12"
                  height="12"
                  class-name="text-white-normal"
                ></svg-icon>
              </div>
            </div>
          </el-dialog>
        </template>
      </div>

      <footer
        class="sidebar-footer mt-auto text-12 text-white-600 pl-20 pr-15 pb-30"
      >
        © Broni.biz, 2016 - {{ nowYear }}
      </footer>
    </section>
  </simplebar>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { AJAX_POST } from "../units/ajax";
import simplebar from "simplebar-vue";
import { getYear } from "date-fns";

export default {
  name: "AppSidebar",
  components: { simplebar },
  props: {
    collapsed: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      isSidebarCollapsed: false,
      formLabelWidth: "120px",
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("menu", ["menu", "copyright"]),
    ...mapGetters("settings", ["srcLogo"]),
    nowYear() {
      return getYear(new Date());
    },
  },
  watch: {
    collapsed(value) {
      this.isSidebarCollapsed = value;
    },
  },
  methods: {
    ...mapActions("menu", {
      toggleExpandable: "toggleExpandable",
      toggleItemFastAdd: "toggleItemFastAdd",
    }),
    collapseSidebar() {
      this.$emit("collapse");
    },
    closeSidebar() {
      this.$emit("close");
    },
    toggleExpand(menuItemId) {
      this.toggleExpandable(menuItemId);
    },
    toggleFastAdd(menuITemId) {
      this.toggleItemFastAdd(menuITemId);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

// ::v-deep .el-aside {
//   background-color: $--color-ultra-white;
// }
::v-deep .simplebar-wrapper {
  background-color: $--color-ultra-white;
}
.fast-add-close {
  top: 20px;
  right: 20px;
}

.sidebar {
  height: 100vh;
  // background: rgba(255, 255, 255, 0.04);
  &-header {
    &__user {
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 100%;
      overflow: hidden;
      margin-bottom: 6px;
    }

    &__logo {
      position: absolute;
      left: 70px;
    }
  }
  &-body {
    &__link {
      &.active {
        @apply .bg-white-100;

        .sidebar-link__icon {
          @apply .text-yellow-200;
        }
      }

      &:hover {
        .sidebar-link__icon {
          @apply .text-yellow-200;
        }
        .sidebar-add {
          @apply .bg-gradient-b-yellow .text-black;
        }
      }
    }
  }
}
</style>
