<template>
  <div class="relative">
    <spinner v-if="loading" />
    <el-table :data="items" class="border-b-0 mb-20" sortable :stripe="true">
      <el-table-column prop="id" label="№" sortable :width="62">
      </el-table-column>
      <el-table-column
        prop="date"
        sortable
        label="Дата и время"
        class="date"
        :width="176"
      >
      </el-table-column>
      <el-table-column
        prop="reservation"
        class="sender"
        sortable
        label="Бронирование"
        :min-width="179"
      >
        <div slot-scope="{ row }">
          <div class="mb-10 whitespace-nowrap inline-block border-b leading-25">
            {{ row.reservation.title }}
          </div>
          <div class="flex">
            <div>
              <span class="small_text">Дата и время</span>
              <div>{{ row.reservation.date }}</div>
            </div>
            <div class="mx-30">
              <span class="small_text">Цена</span>
              <div>{{ row.reservation.price }} ₽</div>
            </div>
            <div>
              <span class="small_text">Участников</span>
              <div>{{ row.reservation.count }}</div>
            </div>
          </div>
        </div>
      </el-table-column>
      <el-table-column prop="status" sortable label="Статус" :min-width="200">
        <template slot-scope="{ row }">
          <div class="flex">
            <div>
              <span class="small_text">Статус брони</span>
              <div>{{ row.status.status }}</div>
            </div>
            <div class="mx-30">
              <span class="small_text">Тип оплаты</span>
              <div>{{ row.status.type }}</div>
            </div>
            <div class="mr-30">
              <span class="small_text">К оплате</span>
              <div>{{ row.status.price }} ₽</div>
            </div>
            <div>
              <span class="small_text">Статус оплаты</span>
              <div>{{ row.status.payment_status }}</div>
            </div>
          </div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      items: [
        {
          id: 1,
          date: "2021-03-15 21:45:55",
          reservation: {
            title: "Охотники за сокровищами: сундук мертвеца",
            date: "31.10.2019 18:20",
            price: 2500,
            count: 5,
          },
          status: {
            status: "Новая",
            type: "Онлайн",
            price: 2500,
            payment_status: "Оплачено",
          },
        },
        {
          id: 2,
          date: "2021-03-15 21:45:55",
          reservation: {
            title: "Охотники за сокровищами: сундук мертвеца",
            date: "31.10.2019 18:20",
            price: 2500,
            count: 5,
          },
          status: {
            status: "Новая",
            type: "Онлайн",
            count: 2500,
            payment_status: "Оплачено",
          },
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.small_text {
  white-space: nowrap;
}
</style>
