<template>
  <el-tabs v-model="active">
    <el-tab-pane v-for="item in items" :key="item.name" :name="item.name">
      <div slot="label" class="tabs__label">
        {{ item.label }}
        <!-- <span v-if="item.count || item.count === ''" class="preTables__total">
          {{ item.count !== "" ? item.count : "?" }}</span
        > -->
        <span class="preTables__total">{{
          item.name === "promocode"
            ? discountItemsCount.promocodes
            : discountItemsCount.discounts
        }}</span>
      </div>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: {
    items: Array,
    value: String,
  },
  computed: {
    ...mapState("promo", ["discountItemsCount"]),
    active: {
      get() {
        return this.value;
      },
      set(e) {
        this.$emit("input", e);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs__label {
  display: flex;
  align-items: center;
  .preTables__total {
    color: white;
  }
}
</style>
