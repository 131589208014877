<template>
  <div class="wrapper-stub">
    <div class="wrapper-hint">
      <div class="wrapper-hint__text">
        Виджет на вашем сайте будет выглядеть вот так
      </div>
      <svg-icon
        class="wrapper-hint__icon"
        icon-name="arrowWidgetDown"
        class-name="wrapper-hint__icon"
      ></svg-icon>
    </div>
    <div class="custom-block">
      <div class="text">
        Завершите шаг 1 для <br />
        отображения виджета
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShowService",
  data() {
    return {};
  },
  created() {},
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.wrapper-stub {
  height: 100%;
}
.custom-block {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
}
.text {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: $--color-white-5;
  text-align: center;
}

.wrapper-hint {
  display: flex;
  align-items: start;
  flex-direction: row;
  color: $--color-white-5;
  margin-bottom: 30px;

  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    position: relative;
    top: 8px;
  }
  &__icon {
    margin-top: 15px;
    margin-left: 10px;
    width: 40px;
    height: 40px;
  }
}
</style>
