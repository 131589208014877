var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full"},[(_vm.isLoading)?_c('spinner'):_c('el-collapse',[_c('BlockCollapse',{attrs:{"title":"Описание","name":"descService"}},[_c('el-form',{attrs:{"label-position":"top"}},[_c('h4',{staticClass:"title"},[_vm._v("Описание")]),_c('el-row',{staticClass:"flex-wrap",attrs:{"gutter":_vm.$mq.large ? 10 : 30,"type":"flex"}},[_c('el-col',{attrs:{"span":_vm.$mq.large ? 24 : 12}},[_c('el-form-item',{attrs:{"prop":"name"}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v("Название услуги"),_c('span',{staticClass:"danger"},[_vm._v("*")])]),_c('el-input',{attrs:{"value":_vm.service.name,"placeholder":"Напишите название новой услуги"},on:{"input":function($event){return _vm.requerField({ field: 'name', value: $event })},"blur":function($event){return _vm.autoSave({
                    field: 'name',
                  })}}})],1)],1),_c('el-col',{attrs:{"span":_vm.$mq.large ? 24 : 12}},[_c('el-form-item',{attrs:{"prop":"kind_of_activity"}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" Категория услуги ")]),_c('el-select',{staticClass:"w-full",attrs:{"value":_vm.service.kind_of_activity,"placeholder":"Выберите категория","multiple":"","filterable":""},on:{"input":function($event){return _vm.requerField({
                    field: 'kind_of_activity',
                    value: $event,
                    autoSave: true,
                  })}}},_vm._l((_vm.typesActivity),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id}})}),1)],1)],1)],1),_c('el-form-item',{attrs:{"label":"Анонс услуги на виджете"}},[_c('el-input',{attrs:{"value":_vm.service.short_description,"maxlength":"100","show-word-limit":"","placeholder":"Введите анонс"},on:{"input":function($event){return _vm.setService({ field: 'short_description', value: $event })},"blur":function($event){return _vm.autoSave({
                field: 'short_description',
              })}}})],1),_c('textarea-edit',{attrs:{"value":_vm.service.detail_description},on:{"input":function($event){return _vm.setService({ field: 'detail_description', value: $event })},"blur":function($event){return _vm.autoSave({
              field: 'detail_description',
            })}}}),_c('div',{staticClass:"hl"}),_c('h4',{staticClass:"title"},[_vm._v("Медиа")]),_c('h4',{staticClass:"sub-title"},[_vm._v("Фотографии")]),_c('UploadPhoto',{attrs:{"value":_vm.service.services_photos},on:{"input":function($event){return _vm.setService({
              field: 'services_photos',
              value: $event,
              autoSave: true,
            })}}}),_c('h4',{staticClass:"sub-title"},[_vm._v("Видео")]),_c('el-row',{staticClass:"flex-wrap",attrs:{"gutter":_vm.$mq.large ? 10 : 30,"type":"flex"}},[_c('el-col',{attrs:{"span":_vm.$mq.large ? 24 : 8}},[_c('UploadPhoto',{attrs:{"is-logo":"","value":_vm.service.services_video_preview,"text":"Перетащите превью в эту область или загрузите","sub-text":"Минимальный рекомендованный размер: 500x500 пикселей"},on:{"input":function($event){return _vm.setService({
                  field: 'services_video_preview',
                  value: $event,
                  autoSave: true,
                })}}})],1),_c('el-col',{attrs:{"span":_vm.$mq.large ? 24 : 16}},[_c('el-row',{staticClass:"flex-wrap",attrs:{"gutter":_vm.$mq.large ? 10 : 30,"type":"flex"}},[_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"Название видео"}},[_c('el-input',{attrs:{"value":_vm.service.video_name,"placeholder":"Введите название"},on:{"input":function($event){return _vm.setService({
                        field: 'video_name',
                        value: $event,
                      })},"blur":function($event){return _vm.autoSave({
                        field: 'video_name',
                      })}}})],1)],1),_c('el-col',{attrs:{"span":24}},[_c('el-form-item',{attrs:{"label":"Ссылка на видео"}},[_c('el-input',{attrs:{"value":_vm.service.video_link,"placeholder":"Вставьте сюда ссылку"},on:{"input":function($event){return _vm.setService({
                        field: 'video_link',
                        value: $event,
                      })},"blur":function($event){return _vm.autoSave({
                        field: 'video_link',
                      })}}})],1)],1)],1)],1)],1)],1)],1),_c('BlockCollapse',{attrs:{"title":"Контактные данные","name":"contactService"}},[_c('el-form',{ref:"сontactService",staticClass:"form",attrs:{"hide-required-asterisk":"","label-position":"top","model":Object.assign({}, _vm.service),"rules":_vm.rules}},[_c('switch-custom',{attrs:{"value":_vm.service.use_company_contacts,"title":"Использовать контактные данные из настроек компании"},on:{"input":function($event){return _vm.setService({
              field: 'use_company_contacts',
              value: $event,
              autoSave: false,
            })}}},[_vm._v(" Отметьте, если хотите использовать данные, введенные вами ранее в настройках компании. Просмотреть введенные данные вы можете "),_c('a',{staticClass:"text-white-normal",on:{"click":function($event){return _vm.$router.push({ path: '/settings' })}}},[_vm._v(" здесь. ")])]),_c('div',{staticClass:"wrapper-contacts"},[_c('el-form-item',{attrs:{"prop":"emails"}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v("Контактный E-mail"),_c('span',{staticClass:"danger"},[_vm._v("*")])]),_c('el-input',{attrs:{"value":_vm.service.emails,"placeholder":"example@mail.ru"},on:{"input":function($event){return _vm.setService({ field: 'emails', value: $event })}}})],1),_c('el-form-item',{attrs:{"prop":"tel_req"}},[_c('div',{staticClass:"flex",attrs:{"slot":"label"},slot:"label"},[_c('div',[_vm._v(" Контактный номер телефона"),_c('span',{staticClass:"danger"},[_vm._v("*")])]),_c('el-popover',{staticClass:"ml-8",attrs:{"placement":"top","width":"200","trigger":"hover","content":"Укажите телефон, который будет приходить клиенту в почтовом уведомлении при бронировании."}},[_c('Tooltip',{attrs:{"slot":"reference"},slot:"reference"})],1)],1),_c('el-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.maskPhone),expression:"maskPhone"}],attrs:{"placeholder":"+7 (999) 999-99-99","value":_vm.service.tel_req},on:{"input":function($event){return _vm.setService({
                  field: 'tel_req',
                  value: $event,
                  hash: _vm.hash,
                })}}})],1),_c('ListField',{attrs:{"value":_vm.service.tel,"title-button":"Добавить номер телефона","title-for-line":"Дополнительный номер телефона","mask":_vm.maskPhone,"placeholder":"+7 (999) 999-99-99"},on:{"input":function($event){return _vm.setService({ field: 'tel', value: $event, hash: _vm.hash })}}})],1),_c('h4',{staticClass:"title"},[_vm._v("Адрес")]),_c('map-info',{ref:"map",staticClass:"mb-20",on:{"validate":function($event){_vm.isValidMap = $event}}}),_c('h4',{staticClass:"title"},[_vm._v("Социальные сети и мессенджеры")]),_c('switch-custom',{attrs:{"title":"Использовать социальные сети и мессенджеры, указанные в\n    настройках компании","value":_vm.service.use_messengers_socials_from_company},on:{"input":function($event){return _vm.setService({
              field: 'use_messengers_socials_from_company',
              value: $event,
              hash: _vm.hash,
            })}}},[_vm._v(" Ссылки на социальные сети и месседжеры будут отправляться клиенту в почтовых уведомлениях. ")]),_c('div',{staticClass:"hl"}),_c('el-button',{staticClass:"custom-button",attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitContact()}}},[_vm._v(" Сохранить ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }