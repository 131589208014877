import Vue from "vue";

import VueRouter from "vue-router";
import Orders from "@/views/Orders";
import UiKit from "@/views/Ui-Kit.vue";
import Services from "@/views/Services";
import Login from "@/views/Login";
import Schedule from "@/views/Schedule/index.vue";
import Resource from "@/views/Resource";
import Settings from "@/views/Settings";
import Balance from "@/views/Balance";
// eslint-disable-next-line import/no-cycle
import store from "@/store/index.js";
import News from "@/views/News";
import Promo from "@/views/Promo";
import Notifications from "@/views/Notifications";
import Docs from "@/views/Docs";
import HistoryPayment from "@/views/HistoryPayment";
import HistoryChanges from "@/views/HistoryChanges";
import Personal from "@/views/Personal";
import API from "@/views/API";
import SMS from "@/views/Sms";
import Support from "@/views/Support";
import SupportTicket from "@/views/SupportTicket";
import SupportAdd from "@/views/SupportAdd";
import NotFound from "@/views/NotFound";
import CreateServices from "@/views/Services/Create";
import Company from "@/views/Company";
import CreateStandardWidget from "@/views/CreateStandardWidget";
import CreateCustomWidget from "@/views/CreateCustomWidget";
import CreateVkWidget from "@/views/CreateVkWidget";
import Widgets from "@/views/Widgets";
import WidgetExample from "@/views/WidgetExample";
import Clients from "@/views/Clients";
import Client from "@/views/Client";
import Dashboard from "@/views/Dashboard";
import CreateWidget from "@/views/Widgets/CreateWidget.vue";
import PublicWidget from "@/views/Widgets/PublicWidget.vue";
import OrderPage from "@/views/Order/index.vue";
import UserAgree from "@/views/UserAgree.vue";
import UserAgreeNew from "@/views/UserAgreeNew.vue";
import UBRR from "@/views/UBRR";
import InviteFriend from "@/views/InviteFriend";

Vue.use(VueRouter);

function setReferal(to) {
  const code = to.params.id;
  if (code) {
    document.cookie = `referral=${code};max-age=${864 * 100 * 30};Path=/`;
  }
  // REMOVE COOKIE
  // document.cookie = `referral='';max-age=-1;Path=/login`;
  // }
}

const routes = [
  {
    path: "/",
    name: "orders",
    component: Orders,
    redirect: "/reserves",
  },
  {
    path: "/order/:hash",
    name: "order",
    component: OrderPage,
    props: true,
    meta: {
      plainLayout: true,
      isPublic: true,
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: Dashboard,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/About.vue"),
  },
  {
    path: "/kit",
    name: "uikit",
    component: UiKit,
  },
  {
    path: "/ubrr",
    name: "ubrr;",
    component: UBRR,
  },
  {
    path: "/useragreement",
    name: "useragreement",
    component: UserAgree,
    meta: {
      plainLayout: true,
      isPublic: true,
    },
  },
  {
    path: "/useragreementw",
    name: "useragreementw",
    component: UserAgree,
    meta: {
      plainLayout: true,
      isPublic: true,
    },
  },
  {
    path: "/privacypolicyw",
    name: "privacypolicyw",
    component: UserAgree,
    meta: {
      plainLayout: true,
      isPublic: true,
    },
  },
  {
    path: "/licenceagreement",
    name: "licenceagreement",
    component: UserAgree,
    meta: {
      plainLayout: true,
      isPublic: true,
    },
  },
  {
    path: "/serviceagreement",
    name: "serviceagreement",
    component: UserAgree,
    meta: {
      plainLayout: true,
      isPublic: true,
    },
  },
  {
    path: "/servicerules",
    name: "servicerules",
    component: UserAgree,
    meta: {
      plainLayout: true,
      isPublic: true,
    },
  },
  {
    path: "/privacypolicy",
    name: "privacypolicy",
    component: UserAgree,
    meta: {
      plainLayout: true,
      isPublic: true,
    },
  },
  {
    path: "/useragreementold",
    name: "useragreementold",
    component: UserAgree,
    meta: {
      plainLayout: true,
      isPublic: true,
    },
  },
  {
    path: "/cookiepolicy",
    name: "cookiepolicy",
    component: UserAgree,
    meta: {
      plainLayout: true,
      isPublic: true,
    },
  },
  {
    path: "/contractoffer",
    name: "contractoffer",
    component: UserAgree,
    meta: {
      plainLayout: true,
      isPublic: true,
    },
  },
  {
    path: "/scientificdevelopments",
    name: "scientificdevelopments",
    component: UserAgree,
    meta: {
      plainLayout: true,
      isPublic: true,
    },
  },
  {
    path: "/schedule",
    name: "schedule",
    component: Schedule,
  },
  {
    path: "/api",
    name: "api",
    component: API,
  },
  {
    path: "/balance",
    name: "balance",
    component: Balance,
  },
  {
    path: "/widgets",
    name: "widgets",
    component: Widgets,
  },
  {
    path: "/create-widget",
    name: "create-widget",
    component: CreateWidget,
  },
  {
    path: "/widget/:hash",
    name: "widget",
    component: CreateWidget,
  },
  {
    path: "/sms",
    name: "sms",
    component: SMS,
  },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
  },
  {
    path: "/docs",
    name: "docs",
    component: Docs,
  },
  {
    path: "/clients",
    name: "clients",
    component: Clients,
  },
  {
    path: "/client",
    name: "client",
    component: Client,
  },
  {
    path: "/services",
    name: "services",
    component: Services,
  },
  {
    path: "/notifications",
    name: "notifications",
    component: Notifications,
  },
  {
    path: "/personal",
    name: "personal",
    component: Personal,
  },
  {
    path: "/promo",
    name: "promo",
    component: Promo,
  },
  {
    path: "*",
    component: NotFound,
  },
  {
    path: "/news",
    name: "news",
    component: News,
  },
  {
    path: "/invite",
    name: "invite",
    component: InviteFriend,
  },
  {
    path: "/invite/:id",
    name: "invite",
    component: InviteFriend,
    beforeEnter: (to, from, next) => {
      setReferal(to);
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });

      return false;
    },
  },
  {
    path: "/services/create",
    name: "create-service",
    component: CreateServices,
  },
  {
    path: "/services/:hash",
    name: "edit-service",
    component: CreateServices,
  },
  {
    path: "/company",
    name: "company",
    component: Company,
  },
  {
    path: "/payments",
    name: "history-payment",
    component: HistoryPayment,
  },
  {
    path: "/changes",
    name: "history-changes",
    component: HistoryChanges,
  },
  {
    path: "/resources/:hash",
    name: "resource",
    component: Resource,
    props: true,
  },
  {
    path: "/reserves",
    name: "orders",
    component: Orders,
  },
  {
    path: "/support",
    name: "support",
    component: Support,
  },
  {
    path: "/support/ticket/:id",
    name: "support-ticket",
    component: SupportTicket,
  },
  {
    path: "/support/add",
    name: "support-add",
    component: SupportAdd,
  },
  {
    path: "/create-standard-widget",
    name: "create-standard-widget",
    component: CreateStandardWidget,
  },
  {
    path: "/create-custom-widget",
    name: "create-custom-widget",
    component: CreateCustomWidget,
  },
  {
    path: "/widget-example",
    name: "widget-example",
    component: WidgetExample,
    meta: {
      plainLayout: true,
    },
  },
  {
    path: "/wd/:hash",
    name: "public-widget",
    component: PublicWidget,
    meta: {
      plainLayout: true,
      isPublic: true,
    },
  },
  {
    path: "/create-vk-widget",
    name: "create-vk-widget",
    component: CreateVkWidget,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      plainLayout: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (
    to.path !== "/login" &&
    to.name !== "public-widget" &&
    to.name !== "order" &&
    to.name !== "useragreement" &&
    to.name !== "useragreementw" &&
    to.name !== "useragreementold" &&
    to.name !== "licenceagreement" &&
    to.name !== "serviceagreement" &&
    to.name !== "servicerules" &&
    to.name !== "privacypolicy" &&
    to.name !== "cookiepolicy" &&
    to.name !== "contractoffer" &&
    to.name !== "scientificdevelopments" &&
    to.name !== "privacypolicyw"
  ) {
    if (store.state.user.isAuth) {
      if (!store.state.user.user) {
        const isNext = await store.dispatch("user/getInfo");
        if (isNext) {
          next();
        } else {
          //  setReferal(to);
          next({
            path: "/login",
            query: { redirect: to.fullPath },
          });
        }
      } else {
        next();
      }
    } else {
      setReferal(to);
      next({
        path: "/login",
        query: { redirect: to.fullPath },
      });
    }
  } else {
    next();
  }
});

export default router;
