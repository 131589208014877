import Vue from "vue";
import Vuex from "vuex";
// import createPersistedState from "vuex-persistedstate";
import menu from "./modules/menu";
import user from "./modules/user";
import news from "./modules/news";
import resource from "./modules/resource";
import service from "./modules/service";
import company from "./modules/company";
import schedule from "./modules/schedule";
import homeFilter from "./modules/homeFilter";
import settings from "./modules/settings";
import notifications from "./modules/notifications";
import orders from "./modules/orders";
import sms from "./modules/sms";
// eslint-disable-next-line import/no-cycle
import widget from "./modules/widget";
import icons from "./modules/icons";
import promo from "./modules/promo";
import personals from "./modules/personals";
import balance from "./modules/balance";
import clients from "./modules/clients";
import invite from "./modules/invite";

// eslint-disable-next-line camelcase
import paymentHistory from "./modules/paymentHistory";

Vue.use(Vuex);

export default new Vuex.Store({
  namespaced: true,
  state: {
    notif: null,
  },
  mutations: {
    setNotif(state, payload) {
      state.notif = {
        type: "success",
        duration: 3000,
        showClose: false,
        ...payload,
      };
    },
  },
  modules: {
    menu,
    news,
    user,
    resource,
    schedule,
    homeFilter,
    service,
    company,
    settings,
    orders,
    sms,
    widget,
    notifications,
    icons,
    promo,
    paymentHistory,
    personals,
    balance,
    clients,
    invite,
  },
  // plugins: [
  //   createPersistedState({
  //     paths: ["user"],
  //   }),
  // ],
});
