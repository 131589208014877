import { setField, setFieldObj } from "@/helpers/store";
import { AJAX_GET, AJAX_DELETE, AJAX_POST, AJAX_PATCH } from "@/units/ajax";
import moment from "moment";

export default {
  namespaced: true,
  state: {
    news: [],
  },
  getters: {},
  mutations: {
    setField,
    setFieldObj,
  },
  actions: {
    async getNews({ commit }) {
      try {
        const { data } = await AJAX_GET("/api/news/get");
        commit("setField", { field: "news", value: data });
      } catch (err) {
        commit(
          "setNotif",
          {
            title: "Ошибка при получении данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },
  },
};
