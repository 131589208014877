/* eslint-disable vue/no-parsing-error */
<template>
  <div class="widget">
    <div
      :class="['widget__header', { 'opacity-zero': isShowContent }]"
      :style="{
        backgroundImage: company.cover ? `url(${company.cover})` : '',
        backgroundSize: company.cover ? 'cover' : '0.9rem 0.9rem',
      }"
    >
      <p v-if="!company.cover" class="text-cover">обложка не добавлена</p>
      <div class="widget__header-wrapper">
        <img v-if="company.company_logo" :src="company.company_logo" />
        <div v-else class="img-stub">
          Нет <br />
          логотипа
        </div>
        <div class="info">
          <p class="info-header">
            {{
              company.company_name
                ? company.company_name
                : "Название не указанно"
            }}
          </p>
          <p class="info-text">
            {{ activityCompany ? activityCompany : "Тип услуги не указан" }}
          </p>
          <p class="info-text">
            {{ addressCompany ? addressCompany : "Адресс компании не указан" }}
          </p>
        </div>
      </div>
      <div
        v-if="company.video_link"
        class="button-play"
        @click="showVideo = true"
      >
        <svg-icon icon-name="play"></svg-icon>
      </div>
    </div>
    <div :class="['widget__content', { 'opacity-zero': isShowContent }]">
      <el-tabs v-model="activeName">
        <el-tab-pane name="record">
          <div slot="label">Запись</div>
          <div class="qwerty">
            <span>
              Запись появится после
              <router-link
                class="link-service"
                target="_blank"
                :to="{ name: 'create-service' }"
              >
                добавления услуг</router-link
              >
              и настройки расписания.
            </span>
          </div>
        </el-tab-pane>
        <el-tab-pane name="description">
          <div slot="label">Описание</div>
          <div
            v-if="company.detail_description"
            class="widget__content-description"
            v-html="company.detail_description"
          />
          <div v-else class="qwerty">
            Необходимо добавить описание компании для отображения.
          </div>
        </el-tab-pane>
        <el-tab-pane name="services">
          <div slot="label">Услуги</div>
          <div class="qwerty">
            <span>
              Необходимо
              <router-link
                class="link-service"
                target="_blank"
                :to="{ name: 'create-service' }"
              >
                добавить услуги
              </router-link>
              компании для отображения
            </span>
          </div>
        </el-tab-pane>
        <el-tab-pane name="media">
          <div slot="label">Медиа</div>
          <div
            v-if="company.video_link || photosActual.length"
            class="wrapper-gallery"
          >
            <div
              v-if="company.video_link"
              class="wrapper-gallery__video"
              :style="{
                backgroundImage: company.cover
                  ? `linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url(${company.video_preview})`
                  : '',
              }"
              @click="showVideo = true"
            >
              <div class="wrapper-gallery__video-button">
                <svg-icon icon-name="play"></svg-icon>
              </div>
              Смотреть видео
            </div>
            <el-image
              v-for="(image, i) in photosActual"
              :key="i"
              class="wrapper-gallery__img"
              :src="image.src"
              :preview-src-list="company.company_photos.map((img) => img.src)"
            >
            </el-image>
          </div>
          <div v-else class="qwerty">
            Необходимо добавить данные в блоке «Медиа»
          </div>
        </el-tab-pane>
        <el-tab-pane name="contact">
          <div slot="label">Контакты</div>
          <div class="contact">
            <div class="contact__info">
              <div v-if="company.tel_req" class="contact__info-item">
                <div class="title">
                  <svg-icon icon-name="phone"></svg-icon>
                  Телефон
                </div>
                <a class="text" :href="`tel:${company.tel_req}`">
                  {{ company.tel_req }}
                </a>
                <a
                  v-for="(item, i) in company.tel"
                  :key="`${item || i}-phone-${i}`"
                  :href="`tel:${item}`"
                  class="text"
                >
                  {{ item }}
                </a>
              </div>
              <div v-if="company.email_req" class="contact__info-item">
                <div class="title">
                  <svg-icon icon-name="email"></svg-icon>
                  Почта
                </div>
                <a class="text" :href="`mailto:${company.email_req}`">
                  {{ company.email_req }}
                </a>
                <a
                  v-for="(item, i) in company.email"
                  :key="`${item || i}-email-${i}`"
                  class="text"
                  :href="`mailto:${item}`"
                >
                  {{ item }}
                </a>
              </div>
              <div v-if="isShowMessager" class="contact__info-item">
                <div class="title">
                  <svg-icon icon-name="messeger"></svg-icon>
                  Мессенджеры
                </div>
                <div class="contact__info-item__buttons">
                  <a
                    v-for="(mes, i) in company.messengers"
                    v-show="mes.show && mes.value"
                    :key="i"
                    target="_blank"
                    :href="getHref(mes)"
                    :class="['link', `link-${mes.name}`]"
                  >
                    <svg-icon :icon-name="mes.name"></svg-icon>
                  </a>
                </div>
              </div>
              <div v-if="isShowSocials" class="contact__info-item">
                <div class="title">
                  <svg-icon icon-name="socialNetwork"></svg-icon>
                  Соцсети
                </div>

                <div class="contact__info-item__buttons">
                  <a
                    v-for="(social, i) in company.socials"
                    v-show="social.show && social.value"
                    :key="i"
                    :href="social.value"
                    :class="['link', `link-${social.name}`]"
                  >
                    <svg-icon :icon-name="social.name"></svg-icon>
                  </a>
                </div>
              </div>
            </div>

            <div
              v-if="company.tel_req && company.address.length"
              class="hl"
            ></div>
            <div v-if="company.address.length" class="contact__map">
              <div v-for="(address, i) in company.address" :key="i">
                <p class="hint-title__medium-white">Адрес</p>
                <p class="contact__text">
                  {{ address.address_name }}
                </p>
                <p
                  v-if="address.address_comment"
                  class="hint-title__medium-white"
                >
                  Комментарий
                </p>
                <p v-if="address.address_comment" class="contact__text">
                  {{ address.address_comment }}
                </p>
                <MapCompany
                  class="map mb-20"
                  is-not-draggable
                  :value="address"
                ></MapCompany>
              </div>
            </div>
            <div v-else class="qwerty">
              Необходимо добавить данные в блоке «Адреса и филиалы»
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <iframe-video
      :show="showVideo"
      :video-id="getIdFromURL"
      @close="showVideo = false"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import MapCompany from "@/views/Company/components/MapCompany.vue";
import IframeVideo from "@/components/IframeVideo";

export default {
  name: "WidgetCompany",
  components: { MapCompany, IframeVideo },
  data() {
    return {
      isShowContent: false,
      activeName: "record",
      showVideo: false,
    };
  },
  computed: {
    ...mapState("company", ["company"]),
    ...mapGetters("company", [
      "activityCompany",
      "addressCompany",
      "photosActual",
    ]),
    getIdFromURL() {
      const youtubeRegexp = /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/gi;
      return this.company.video_link.replace(youtubeRegexp, "$1");
    },
    isShowMessager() {
      return this.company.messengers.filter((item) => item.show && item.value)
        .length;
    },
    isShowSocials() {
      return this.company.socials.filter((item) => item.show && item.value)
        .length;
    },
  },
  methods: {
    toService() {
      this.router.push({ name: "create-service" });
    },
    getHref(social) {
      let href = "";
      // eslint-disable-next-line default-case
      switch (social.name) {
        case "whatsapp":
          href = `https://wa.me/${social.value}`;
          break;
        case "viber":
          href = `viber://add?number=${social.value}`;
          break;
        case "telegram":
          href = `tg://resolve?domain=${social.value}`;
          break;
        case "facebook_messenger":
          href = `https://m.me/${social.value}`;
          break;
        case "vk_messenger":
          href = `https://vk.me/${social.value}`;
          break;
        default:
          href = social.value;
      }
      return href;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.widget {
  display: flex;
  flex-direction: column;
  background-color: $--color-ultra-white;
  overflow-wrap: break-word;
  position: relative;
  border-radius: 3px;
  overflow: auto;
  height: inherit;

  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 20px;
    background-repeat: no-repeat;
    background-position: center;
    background: linear-gradient(
      135deg,
      transparent 47%,
      $--color-white-15 50%,
      transparent 0%
    );

    &-wrapper {
      display: flex;
      flex-direction: row;
      min-height: 250px;

      .img-stub,
      img {
        width: 90px;
        height: 90px;
        border: 2px solid #ffffff;
        box-sizing: border-box;
        border-radius: 10px;
        margin-right: 20px;
        object-fit: contain;
      }
      .img-stub {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: $--color-white-35;
      }
      .info {
        display: flex;
        flex-direction: column;

        &-text {
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          color: $--color-white-7;
        }

        &-header {
          font-weight: 600;
          font-size: 22px;
          line-height: 36px;
          margin-bottom: 5px;
          color: $--color-white;
        }
      }
    }

    .button-play {
      position: absolute;
      bottom: 20px;
      right: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 40px;
      height: 40px;
      background: $--color-violet;
      border-radius: 3px;
      font-size: 22px;

      &:hover {
        background: $--color-ultra-white;
      }
    }
    .text-cover {
      color: $--color-white-35;
      position: absolute;
      top: 20px;
      left: 20px;
    }
  }

  &__content {
    opacity: 1;
    display: flex;
    flex-direction: column;
    transition: all 0.2s;

    &-description {
      ::v-deep h1 {
        font-size: 24px !important;
        margin-bottom: 12px;
      }
      ::v-deep h2 {
        font-size: 24px;
        margin-bottom: 12px;
      }
      ::v-deep h3 {
        font-size: 20px;
        margin-bottom: 12px;
      }
      ::v-deep strong {
        font-weight: bold;
        margin-bottom: 12px;
      }
      ::v-deep ol {
        list-style-type: decimal;
        padding-left: 17px !important;
        margin-bottom: 12px;
      }
      ::v-deep ul {
        list-style-type: disc;
        padding-left: 17px !important;
        margin-bottom: 12px;
      }
      ::v-deep p {
        margin-bottom: 12px !important;
      }
    }
  }

  .is-open {
    transform: translateX(0);
  }

  .opacity-zero {
    opacity: 0;
  }
}

.link-service {
  cursor: pointer;
  color: $--color-white;
}

::v-deep .el-tabs {
  &__header {
    margin: 0;
  }
  &__content {
    padding: 20px;
  }
  &__nav-scroll {
    margin-bottom: -15px;
  }
  &__item {
    height: 48px;
    line-height: 48px;
  }
  &__nav-prev {
    color: $--color-white;
    left: 7px;
    top: 5px;
    i {
      font-weight: bold;
      font-size: 17px;
    }
  }
  &__nav-next {
    color: $--color-white;
    right: 7px;
    top: 5px;
    i {
      font-weight: bold;
      font-size: 17px;
    }
  }
}

.contact {
  &__info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &-item {
      display: flex;
      flex-direction: column;
      color: $--color-white;
      width: 50%;
      margin-bottom: 15px;

      &__buttons {
        display: flex;
        flex-direction: row;
      }

      .title {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        font-size: 18px;
        line-height: 28px;
        font-weight: 600;
        svg {
          margin-right: 10px;
          color: $--color-white-5;
        }
      }
      .text {
        margin-bottom: 10px;
        font-size: 14px;
        line-height: 24px;
        font-weight: 500;
      }

      .link {
        color: $--color-white;
        border-radius: 3px;
        padding: 10px;
        margin-right: 10px;
        width: fit-content;
        cursor: pointer;
        &-whatsapp {
          background: #128c7e;
        }
        &-telegram {
          background: #0088cc;
        }
        &-vk_messenger {
          background: #45668e;
        }
        &-viber {
          background: #8f5db7;
        }
        &-facebook_messenger {
          background: #2196f3;
        }
        &-instagram {
          background: #e1306c;
        }
        &-vk {
          background: #45668e;
        }
        &-facebook {
          background: #3b5998;
        }
        &-odnoklassniki {
          background: #ed812b;
        }
        &-twitter {
          background: #1da1f2;
        }
      }
    }
  }
  &__text {
    margin-bottom: 15px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.hl {
  margin-top: 0;
}

.wrapper-gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -10px;
  &__video {
    width: calc(25% - 20px);
    height: 184px;
    margin: 0 10px 20px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-radius: 3px;

    &-button {
      background: #ffbe3d;
      border-radius: 20px;
      margin-bottom: 20px;

      svg {
        margin: 10px;
        position: relative;
        left: 2px;
      }
    }
  }

  &__img {
    margin: 0 10px 20px;
    width: calc(25% - 20px);
    height: fit-content;
    cursor: pointer;
    border-radius: 3px;
  }
}

.qwerty {
  margin-bottom: 0;
  text-align: center;
}

.map ::v-deep .el-form-item,
.map ::v-deep .comment-map {
  display: none;
}

@media (max-width: 1000px) {
  .contact {
    &__info {
      &-item {
        width: 50%;
        margin-bottom: 15px;
      }
    }
  }
}

@media (max-width: 800px) {
  .widget {
    &__header {
      &-wrapper {
        display: flex;
        flex-direction: column;

        .img-stub,
        img {
          margin-bottom: 40px;
        }
        .info {
          flex-direction: column;

          &-header {
            font-size: 18px;
            line-height: 28px;
          }
        }
      }

      .button-play {
        top: 70px;
        right: 20px;
      }
      .text-cover {
        top: 125px;
        left: 20px;
      }
    }
  }
  .wrapper-gallery {
    &__video,
    &__img {
      width: calc(33% - 20px);
    }
  }
}

@media (max-width: 600px) {
  .contact {
    &__info {
      &-item {
        width: 100%;
      }
    }
  }
  .wrapper-gallery {
    &__video {
      width: 100%;
    }

    &__img {
      width: calc(50% - 20px);
    }
  }
}
@media (max-width: 420px) {
  ::v-deep .el-tabs {
    &__nav-scroll {
      margin: 0 10px;
    }
  }
}
</style>
