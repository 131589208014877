var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bk-full"},[_c('p',{staticClass:"title-widget",style:({
      color: _vm.isThemeWidget ? _vm.customStyle.active_text_color : '',
    })},[_vm._v(" Выберите тариф ")]),_c('el-radio-group',{on:{"input":function($event){return _vm.emitTariff($event)}},model:{value:(_vm.radio),callback:function ($$v) {_vm.radio=$$v},expression:"radio"}},_vm._l((_vm.settings.data),function(tariff,i){return _c('el-radio',{key:i,class:{ 'custom-style': _vm.isThemeWidget },attrs:{"label":tariff}},[_c('div',{staticClass:"radio__title",style:({
          color: _vm.isThemeWidget ? _vm.customStyle.active_text_color : '',
        })},[_vm._v(" "+_vm._s(tariff.tariff_name)+" • "),_c('span',[_vm._v(_vm._s(((tariff.duration) + " минут")))])]),_c('div',{staticClass:"radio__sub-title",style:({
          color: _vm.isThemeWidget ? _vm.customStyle.active_text_color : '',
        })},[_c('span',[_vm._v(" "+_vm._s(_vm.getCost(tariff))+" "+_vm._s(_vm.selectCell.currency)+" ")]),_vm._v(" • "),_c('span',{style:({
            color: _vm.isThemeWidget ? _vm.customStyle.inactive_text_color : '',
          })},[_vm._v("за 1 место")])]),(
          tariff.data.length > 1 &&
          _vm.radio &&
          _vm.radio.tariff_name === tariff.tariff_name
        )?_c('div',{staticClass:"radio__description"},_vm._l((tariff.data),function(item,index){return _c('div',{key:index,staticClass:"bk-info"},[_c('p',{staticClass:"bk-info__title",style:({
              color: _vm.isThemeWidget ? _vm.customStyle.active_text_color : '',
            })},[_vm._v(" от "+_vm._s(item.from)+" "+_vm._s(item.to ? ("до " + (item.to)) : "")+" ")]),_c('p',{staticClass:"bk-info__info",style:({
              color: _vm.isThemeWidget ? _vm.customStyle.inactive_text_color : '',
            })},[_vm._v(" "+_vm._s(_vm.isHoliday ? item.holidays_price + _vm.selectCell.weekend_price_additional : item.base_price + _vm.selectCell.price_additional)+" "+_vm._s(_vm.selectCell.currency)+" ")])])}),0):_vm._e()])}),1),_c('p',{staticClass:"title-widget",style:({
      color: _vm.isThemeWidget ? _vm.customStyle.active_text_color : '',
    })},[_vm._v(" Количество участников ")]),_c('el-input-number',{staticClass:"w-full",class:{ 'custom-style': _vm.isThemeWidget },style:({
      background: _vm.isThemeWidget ? _vm.customStyle.background_cells_fields : '',
    }),attrs:{"value":_vm.value,"min":_vm.minCount,"max":_vm.maxCount},on:{"input":function($event){return _vm.changePersons($event)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }