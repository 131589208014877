import { setField, setFieldObj, getError } from "@/helpers/store";
import { AJAX_GET, AJAX_DELETE, AJAX_POST, AJAX_PATCH } from "@/units/ajax";

export default {
  namespaced: true,
  state: {
    pays: {},
    pays_stats: {},
    queryPayReserves: {},
    refundData: {},
    onlineTable: null,
    prepaymentTable: null,
    offlineTable: null,
    isLoading: false,
  },

  mutations: {
    setField,
    setFieldObj,
    setQueryPays(state, data) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in data) {
        state.queryPayReserves[key] = data[key];
      }
    },
  },

  actions: {
    async getPayments({ state, commit }) {
      commit("setField", {
        field: "isLoading",
        value: true,
      });
      try {
        let query = "?";
        const keys = state.queryPayReserves;
        if (keys) {
          // eslint-disable-next-line no-restricted-syntax
          for (const key in keys) {
            if (keys[key]) {
              query += `${key}=${keys[key]}&`;
            }
          }
        }
        query = query.substring(0, query.length - 1);
        const { data } = await AJAX_POST(`/api/v1/payment/get${query}`);
        // const param = page;
        // const { data } = await AJAX_POST("/api/v1/payment/get", param);

        commit("setField", {
          field: "pays",
          value: data.data,
        });
        // eslint-disable-next-line camelcase
        const { current_page, from, to, total } = data;
        commit("setField", {
          field: "pays_stats",
          value: {
            current_page,
            from,
            to,
            total,
          },
        });
      } catch (error) {
        console.log(error);
      }
      commit("setField", {
        field: "isLoading",
        value: false,
      });
    },
    async getRefundData({ state, commit }, param) {
      commit("setField", {
        field: "isLoading",
        value: true,
      });

      try {
        const { data } = await AJAX_POST(`/api/v1/payment/getrefunddata`, {
          hash: param,
        });
        commit("setField", {
          field: "refundData",
          value: data.book_info,
        });

        if (!data.success) {
          commit(
            "setNotif",
            {
              title: data.msg,
              type: "error",
            },
            { root: true }
          );
        }
        // if (data.success && data.msg) {
        //   commit(
        //     "setNotif",
        //     {
        //       title: data.msg,
        //       type: "error",
        //     },
        //     { root: true }
        //   );
        // }
      } catch (error) {
        if (error.msg) {
          commit(
            "setNotif",
            {
              title: error.msg,
              type: "error",
            },
            { root: true }
          );
        }
      }
      commit("setField", {
        field: "isLoading",
        value: false,
      });
    },
    async getRefund({ state, commit }, { hash, amount }) {
      try {
        const { data } = await AJAX_POST(`/api/v1/payment/refund`, {
          hash,
          amount,
        });

        if (data.success) {
          commit(
            "setNotif",
            {
              title: data.msg,
            },
            { root: true }
          );
        } else {
          commit(
            "setNotif",
            {
              title: data.msg,
              type: "error",
            },
            { root: true }
          );
        }
      } catch (error) {
        console.log(error);
        commit(
          "setNotif",
          {
            title: error.msg,
            type: "error",
          },
          { root: true }
        );
      }
      commit("setField", {
        field: "isLoading",
        value: false,
      });
    },
    async getPaymentOnPlace({ commit }, params) {
      try {
        const { data } = await AJAX_POST("/api/v1/payment/onplace", params);
        commit(
          "setNotif",
          {
            title: data.msg,
          },
          { root: true }
        );
      } catch (error) {
        commit(
          "setNotif",
          {
            title: error.msg,
            type: "error",
          },
          { root: true }
        );
        console.log(error);
      }
    },
    async getNewPaymentLink({ commit }, hash) {
      try {
        const { data } = await AJAX_POST(
          "/api/v1/payment/newpaymentlink",
          hash
        );
        if (data.success) {
          commit(
            "setNotif",
            {
              title: data.msg,
            },
            { root: true }
          );
        }
        if (!data.success) {
          commit(
            "setNotif",
            {
              title: data.msg,
              type: "error",
            },
            { root: true }
          );
        }
      } catch (error) {
        console.log(error);
        commit(
          "setNotif",
          {
            title: error.msg,
            type: "error",
          },
          { root: true }
        );
      }
    },
  },
};
