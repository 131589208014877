<template>
  <el-form hide-required-asterisk label-position="top">
    <h4 v-if="!isOne" class="title">Для клиентов</h4>
    <switch-custom
      v-model="client_sms_booking"
      title="Отправлять SMS клиенту при бронировании?"
    >
      Отметьте, если требуется отправлять SMS клиенту при бронировании через
      виджеты.
    </switch-custom>
    <switch-custom
      v-model="client_sms_editing"
      title="Отправлять SMS клиенту при редактировании?"
    >
      Отметьте, если требуется отправить SMS клиенту при редактировании
      бронирования через личный кабинет.
    </switch-custom>
    <switch-custom
      v-model="client_sms_canceling"
      title="Отправлять SMS клиенту при отмене бронирования?"
    >
      Отметьте, если требуется отправить SMS клиенту при отмене бронирования.
    </switch-custom>
    <switch-custom
      v-model="client_sms_reminder"
      title="Отправлять напоминание клиенту в SMS?"
    >
      Отметьте, если хотите, чтобы клиенту приходили напоминания о бронировании.
    </switch-custom>
    <template v-if="client_sms_reminder">
      <div class="checkbox-group">
        <el-checkbox v-model="notification_day">
          Отправлять уведомление за 1 сутки
        </el-checkbox>
        <el-checkbox v-model="notification_manual">
          Отправлять уведомление за {{ duration || "N" }}
          {{ selectUnitTime }}
        </el-checkbox>
      </div>
      <InputSelect
        v-if="notification_manual"
        v-model="duration"
        label="Значение"
        required
        placeholder="Введите значение"
        :list-value="listDuration"
        :select-value="time_unit"
        @setValue="time_unit = $event"
      ></InputSelect>
    </template>
    <div v-if="isOne" class="hl"></div>
    <el-button
      v-if="isOne"
      type="primary"
      class="custom-button"
      @click="submit"
    >
      Сохранить
    </el-button>
  </el-form>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { listDuration } from "@/helpers/services.js";

export default {
  name: "ForClient",
  props: {
    isOne: Boolean,
  },
  data() {
    return {
      client_sms_booking: true,
      client_sms_editing: true,
      client_sms_canceling: true,
      client_sms_reminder: true,
      notification_day: true,
      notification_manual: true,
      duration: 120,
      time_unit: "minutes",
    };
  },
  computed: {
    ...mapState("service", ["notifClient"]),
    selectUnitTime() {
      return this.listDuration.find((item) => item.value === this.time_unit)
        .label;
    },
  },
  created() {
    this.listDuration = listDuration;
    if (this.notifClient) this.setClientData();
  },
  methods: {
    ...mapActions("service", ["saveNotifClient"]),
    setClientData() {
      this.client_sms_booking = this.notifClient.client_sms_booking;
      this.client_sms_editing = this.notifClient.client_sms_editing;
      this.client_sms_canceling = this.notifClient.client_sms_canceling;
      this.client_sms_reminder = this.notifClient.client_sms_reminder;
      this.notification_day = this.notifClient.notification_day;
      this.notification_manual = this.notifClient.notification_manual;
      this.duration = this.notifClient.duration;
      this.time_unit = this.notifClient.time_unit;
    },
    async submit() {
      await this.saveNotifClient({
        data: {
          client_sms_booking: this.client_sms_booking,
          client_sms_editing: this.client_sms_editing,
          client_sms_canceling: this.client_sms_canceling,
          client_sms_reminder: this.client_sms_reminder,
          notification_day: this.notification_day,
          notification_manual: this.notification_manual,
          duration: this.duration,
          time_unit: this.time_unit,
        },
        isEditAll: true,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.title {
  font-size: 22px;
  line-height: 44px;
  font-weight: 600;
}
::v-deep .wrapper-is {
  width: 300px;
  &__title {
    margin-bottom: 8px;
  }
}

.checkbox-group {
  display: flex;
  flex-direction: column;
  margin-left: 55px;
  & .el-checkbox {
    margin-bottom: 18px;
  }
}

.wrapper-is {
  margin-left: 55px;
  width: auto;
}

@screen lg {
  .title {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 28px;
  }
  ::v-deep .wrapper-is {
    width: 100%;
    max-width: 350px;
  }
}
</style>
