<template>
  <div class="bk-full">
    <p
      class="title-widget"
      :style="{
        color: isThemeWidget ? customStyle.active_text_color : '',
      }"
    >
      Выберите услугу
    </p>
    <el-radio-group v-model="radio" @input="emitData($event)">
      <el-radio
        v-for="(service, i) in settings.data"
        :key="i"
        :label="service"
        :class="{ 'custom-style': isThemeWidget }"
      >
        <div
          class="radio__title"
          :style="{
            color: isThemeWidget ? customStyle.inactive_text_color : '',
          }"
        >
          {{ service.name }}
        </div>
        <div class="radio__sub-title">
          <span
            :style="{
              color: isThemeWidget ? customStyle.active_text_color : '',
            }"
          >
            {{
              isHoliday && service.holidays_price
                ? +service.holidays_price + selectCell.weekend_price_additional
                : +service.base_price + selectCell.price_additional
            }}
            {{ selectCell.currency }}
          </span>
          •
          <span
            :style="{
              color: isThemeWidget ? customStyle.active_text_color : '',
            }"
            >{{ service.duration }} {{ getUnitTime(service) }}</span
          >
        </div>
      </el-radio>
    </el-radio-group>
  </div>
</template>

<script>
import { listDuration } from "@/helpers/services.js";

export default {
  name: "DepServiceDuration",
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    selectCell: {
      type: Object,
      default: () => {},
    },
    settings: {
      type: Object,
      default: () => {},
    },
    customStyle: { type: Object },
    isThemeWidget: { type: String },
  },
  data() {
    return {
      radio: null,
    };
  },
  computed: {
    isHoliday() {
      return this.selectCell.is_holiday;
    },
  },
  created() {
    this.radio = this.settings.data.find((el, idx) => {
      return idx === 0;
    });
    this.emitData(this.radio);
    this.$emit("isDis", true);
    this.isClass();
  },
  methods: {
    isClass() {
      document.body.style.setProperty(
        "--colorCustom",
        `${this.customStyle.active_text_color}`
      );
      document.body.style.setProperty(
        "--colorCustomBackground",
        `${this.customStyle.inactive_text_color}`
      );
    },
    emitData(service) {
      this.$emit(
        "setPrice",
        this.isHoliday && service.holidays_price
          ? service.holidays_price + this.selectCell.weekend_price_additional
          : service.base_price + this.selectCell.price_additional
      );

      service = {
        id: service.id,
        name: service.name,
        duration: service.duration,
        unit: service.time_unit,
        price:
          this.isHoliday && service.holidays_price
            ? service.holidays_price + this.selectCell.weekend_price_additional
            : service.base_price + this.selectCell.price_additional,
        // this.isHoliday ? service.holidays_price : service.base_price,
      };
      this.$emit("setService", service);
      this.$emit("setSlotParam", {
        book_info: { service: { ...service } },
        resourceHash: this.selectCell.resource_id,
        date: this.selectCell.date,
        time: this.selectCell.time,
        service: { ...service },
      });
    },
    getUnitTime(service) {
      return listDuration.find((el) => el.value === service.time_unit)
        .shortLabel;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.radio {
  &__title {
    margin-bottom: 4px;
  }
  &__sub-title {
    margin-bottom: 18px;
    span {
      &:first-child {
        margin-right: 6px;
      }
      &:last-child {
        color: $--color-white-5;
        margin-left: 6px;
      }
    }
  }
}
:root {
  --colorCustom: "white";
  --colorCustomBackground: "green";
}
.custom-style {
  ::v-deep .el-input__inner {
    color: var(--colorCustom);
  }
  ::v-deep .el-input-number__increase,
  ::v-deep .el-input-number__decrease {
    background: var(--colorCustomBackground);
    color: var(--colorCustom);
  }
  ::v-deep .el-radio__inner {
    border-color: var(--colorCustom);
  }
  // ::v-deep .el-radio__inner:hover {
  //   border-color: var(--colorInactive);
  // }
  ::v-deep .el-radio__input.is-checked > .el-radio__inner {
    border-color: var(--colorCustomButton);
    background: var(--colorCustomButton);
  }
}
::v-deep .el-radio:hover.custom-style > .el-radio__input > .el-radio__inner {
  border-color: var(--colorInactive);
}
::v-deep
  .el-radio:hover.custom-style
  > .el-radio__input.is-checked
  > .el-radio__inner {
  border-color: var(--colorCustomButton);
  background: var(--colorCustomButton);
}
</style>
