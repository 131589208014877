/* eslint-disable guard-for-in */
<template>
  <div class="pt-40 pb-80 w-full support-ticket-wrapper">
    <div class="lg:px-10">
      <ButtonIcon
        icon-name="back"
        text="К списку вопросов"
        class="mb-12"
        @click="$router.push({ name: 'support' })"
      />

      <div
        class="flex justify-between items-end mb-30 ssm:flex-col ssm:items-start"
      >
        <div class="ssm:mb-10">
          <h1 class="text-28 font-semibold leading-44 inline-block mb-4">
            {{ dialog.thread }}
          </h1>
          <div class="text-white-500 text-12 leading-16">
            <span class="text-yellow-100">ID {{ $route.params.id }}</span
            ><span class="mx-7">•</span>Создано
            {{ dialog.created_at ? formatDate(dialog.created_at.date) : null }}
          </div>
        </div>
        <el-button
          v-if="!dialog.closed"
          type="info"
          class="mb-5"
          @click="closeTicket"
          >Закрыть вопрос</el-button
        >
      </div>
      <div
        v-if="dialog.have_new"
        class="flex items-center text-12 leading-16 mb-20"
      >
        <span
          class="rounded-full w-20 h-20 min-w-20 flex items-center justify-center bg-green-400 mr-6"
        >
          <svg-icon
            icon-name="confirmed"
            class-name="text-black"
            width="10"
            height="8"
          ></svg-icon>
        </span>

        Есть новый ответ
      </div>
      <div
        v-else-if="dialog.closed"
        class="flex items-center text-12 leading-16 mb-20"
      >
        <span
          class="rounded-full w-20 h-20 min-w-20 flex items-center justify-center bg-white-200 mr-6"
        >
          <svg-icon
            icon-name="error"
            class-name="text-white-700"
            width="8"
            height="8"
          ></svg-icon>
        </span>
        Вопрос закрыт
      </div>
      <div v-else class="flex items-center text-12 leading-16 mb-20">
        <span
          class="rounded-full w-20 h-20 min-w-20 flex items-center justify-center bg-yellow-100 mr-6"
        >
          <svg-icon
            icon-name="waiting"
            class-name="text-black"
            width="10"
            height="10"
          ></svg-icon>
        </span>
        На рассмотрении
      </div>

      <!-- <el-alert
        v-if="!dialog.have_new && !dialog.closed"
        title="Спасибо за ваше обращение! Наши операторы ответят вам в ближайшее время."
        type="success"
        class="mb-20"
        :closable="false"
        show-icon
      >
      </el-alert> -->

      <div
        ref="support-dialog"
        class="support-dialog flex flex-col items-start"
      >
        <div
          v-for="(message, i) in dialog.messages"
          :key="i"
          class="pt-20 pb-25 relative px-30 rounded-sm mb-10 lg:px-15 support-message"
          :class="
            message.from === 'client'
              ? 'rounded-tl-none bg-white-40 ml-12'
              : 'rounded-tr-none support-message_reply bg-blue-15 mr-12 self-end'
          "
        >
          <div
            class="absolute"
            :class="
              message.from === 'client'
                ? 'support-triangle'
                : 'support-reply-triangle'
            "
          ></div>
          <div class="text-white-500 text-12 leading-16 mb-10">
            {{ message.from === "client" ? user.name : "Специалист"
            }}<span class="mx-7">•</span>{{ formatDate(message.created_at) }}
          </div>
          <div class="leading-25" v-html="message.text"></div>
          <div v-if="message.images && message.images.length">
            <div class="text-white-500 text-12 leading-16 mb-10">Вложения</div>
            <el-button
              v-for="(file, index) in message.images"
              :key="index"
              type="mini"
              class="relative preview-button"
            >
              <el-image
                :key="file.name"
                src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
                class="absolute w-full h-full top-0 left-0"
                fit="none"
                :preview-src-list="filesOf(message)"
              >
              </el-image>
              {{ file.name }}</el-button
            >
          </div>
        </div>
      </div>

      <support-add-form
        v-if="!dialog.closed"
        ref="support-dialog-form"
        inline
        message-title="Отправить сообщение"
        class="mt-50"
        @createMessage="onCreateMessage"
      >
        <div slot="title"></div>
      </support-add-form>
    </div>
  </div>
</template>

<script>
import SupportAddForm from "../components/SupportAddForm";
import { mapState } from "vuex";
import { AJAX_POST } from "../units/ajax";
import moment from "moment";
import { errorMessage, formatTicketDate } from "../units/main";

export default {
  name: "SupportTicket",
  components: { SupportAddForm },
  data() {
    return {
      dialog: {
        thread: null,
        messages: [],
        created_at: null,
        have_new: 0,
      },
    };
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  mounted() {
    AJAX_POST("/api/support/chat", { id: this.$route.params.id }).then(
      (response) => {
        this.dialog.thread = response.data.thread;
        this.dialog.created_at = response.data.created_at;
        this.dialog.have_new = response.data.have_new;

        // eslint-disable-next-line no-restricted-syntax
        for (const key in response.data.messages) {
          this.dialog.messages.push(response.data.messages[key]);
        }

        // eslint-disable-next-line array-callback-return
        this.dialog.messages.sort((a, b) => {
          if (a.created_at < b.created_at) return -1;
          if (a.created_at > b.created_at) return 1;
        });
      }
    );

    setTimeout(() => {
      this.$refs["support-dialog"].scroll(
        0,
        this.$refs["support-dialog"].scrollHeight
      );
      this.$refs["support-dialog-form"].$el.scrollIntoView(false);
    }, 1000);
  },
  methods: {
    formatDate(date) {
      return formatTicketDate(date);
    },
    filesOf(message) {
      return message.images.map((file) => file.url);
    },
    onCreateMessage(message) {
      this.dialog.messages.push({
        from: "client",
        text: message,
        created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
      });

      setTimeout(() => {
        this.$refs["support-dialog"].scroll(
          0,
          this.$refs["support-dialog"].scrollHeight
        );
      }, 500);
    },
    closeTicket() {
      this.$confirm("", "Действительно хотите закрыть вопрос?", {
        confirmButtonText: "Да",
        cancelButtonText: "Отмена",
        type: "warning",
      }).then(() => {
        AJAX_POST("/api/support/ticket/close", {
          id: this.$route.params.id,
        }).then((response) => {
          if (response.data.success) {
            this.$router.push({ name: "support" });
          } else {
            this.$notify({
              title: errorMessage,
              type: "error",
              showClose: false,
              duration: 2000,
            });
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
.support-triangle {
  width: 0;
  height: 0;
  top: 0;
  right: 100%;
  border-top: 12px solid rgba(255, 255, 255, 0.04);
  border-left: 12px solid transparent;
}

.support-reply-triangle {
  width: 0;
  height: 0;
  top: 0;
  left: 100%;
  border-top: 12px solid rgba(52, 152, 219, 0.15);
  border-right: 12px solid transparent;
}

.support-dialog {
  max-height: 1000px;
  overflow: auto;
}

.support-message {
  max-width: 446px;
}

.support-message-reply {
}

.support-ticket-wrapper {
  max-width: 653px;
}

.form-support {
  max-width: 653px;

  textarea {
    height: 120px;
  }
}
</style>
