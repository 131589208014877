<template>
  <div>
    <p class="title-widget">Количество участников</p>
    <el-radio-group v-model="group" @input="emitData($event)">
      <el-radio v-for="(item, i) in settings.data.group" :key="i" :label="item">
        <div class="radio__title">
          Группа от {{ item.from }} до {{ item.to }} человек
        </div>
        <div class="radio__sub-title">
          {{
            item.holidays_price && isHoliday
              ? item.holidays_price
              : item.base_price
          }}

          {{ itemList.item.currency }}
        </div>
      </el-radio>
    </el-radio-group>

    <p v-if="isShowAddSeats" class="title-widget">Дополнительные участники</p>
    <div v-if="isShowAddSeats" class="input-number">
      <div class="input-number__info">
        <span class="input-number__info-title">Количество</span>
        <span class="input-number__info-subtitle">
          {{ priceAddPersons }} {{ itemList.item.currency }}
        </span>
      </div>
      <el-input-number
        v-model="addPersons"
        class="input-number__input"
        :max="maxCount"
        :min="0"
        @input="changePersons"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "SeatGroup",

  props: {
    settings: { type: Object, default: () => {} },
    itemList: { type: Object, default: () => {} },
    slotDetails: { type: Object, default: () => {} },
  },
  data() {
    return { group: null, priceAddPersons: null, addPersons: null };
  },
  computed: {
    isShowAddSeats() {
      if (
        this.settings.data.group.length === 1 &&
        this.settings.data.include_surcharge_for_seats
      )
        return true;
      if (this.group && this.settings.data.include_surcharge_for_seats) {
        const lastChild = this.settings.data.group[
          this.settings.data.group.length - 1
        ];
        return this.group.id === lastChild.id;
      }
      return false;
    },
    isHoliday() {
      return this.itemList.item.is_holiday;
    },
    maxCount() {
      const lastChild = this.settings.data.group_surcharge[
        this.settings.data.group_surcharge.length - 1
      ];
      return Number(lastChild.to) || 99999;
    },
  },
  created() {
    const checkValue = this.slotDetails?.booking?.book_info?.group.id;
    const val = this.settings.data.group.find((el) => el.id === checkValue);
    this.addPersons =
      this.itemList.status === "update"
        ? this.slotDetails?.booking?.book_info?.group?.add_persons
        : "";
    this.group =
      this.itemList.status === "update"
        ? val
        : this.settings.data.group.find((el, idx) => idx === 0);
    this.emitData();
  },
  methods: {
    changePersons(value) {
      const findInterval = this.settings.data.group_surcharge.find((el) => {
        const to = el.to ? Number(el.to) : this.maxCount;
        return this.addPersons <= to;
      });
      this.priceAddPersons = this.isHoliday
        ? findInterval.holidays_price
        : findInterval.base_price;
      this.emitData();
    },
    emitData(value) {
      const group = this.group
        ? {
            price: this.isHoliday
              ? this.group.holidays_price
              : // +
                //   this.itemList.item.weekend_price_additional
                this.group.base_price,
            //+
            // this.itemList.item.price_additional +
            persons_from: this.group.from,
            persons_to: this.group.to,
            id: this.group.id,
          }
        : null;
      this.$emit("SetForGroup", {
        group,
        priceAddPersons: this.priceAddPersons,
        addPersons: this.addPersons,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "element-variables.scss";

::v-deep {
  .el-radio {
    margin-bottom: 12px;
    &-group {
      margin-bottom: 18px;
    }
  }
}
.radio__title {
  margin-bottom: 6px;
}

.bk-info {
  margin-bottom: 6px;
  &__title {
    flex-grow: 1;
    width: auto;
  }
  &__info {
    flex-grow: 0;
  }
}
</style>
