<template>
  <div class="bg-white-40 rounded-sm mb-40 settings__section">
    <div class="px-30 pt-20 pb-25 border-b border-white-150 lg:p-15">
      <div class="text-18 leading-30 font-semibold text-white-normal mb-2">
        Системы оплаты
      </div>
      <div class="text-12 text-white-500 lg:mb-20">
        Выберите систему оплаты, с помощью которой будут происходить оплаты
      </div>
      <template v-if="$mq.tablet">
        <el-select
          v-model="paymentSettings.system"
          placeholder="Select"
          class="lg:w-full"
          @change="setPaymentSettings"
        >
          <el-option
            v-for="item in paymentSettings.systems"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </template>
    </div>
    <div class="flex">
      <div
        v-if="!$mq.tablet"
        class="px-30 pb-25 pt-25 border-r border-white-150 settings-payment"
      >
        <el-radio
          v-model="paymentSettings.system"
          label="none"
          class="mb-10"
          @change="setPaymentSettings"
          >Не использовать</el-radio
        >

        <el-radio
          v-model="paymentSettings.system"
          label="tinkoff"
          class="mb-10"
          @change="setPaymentSettings"
          >Тинькофф</el-radio
        >
        <instruction-item
          v-if="
            paymentSettings.system === 'tinkoff' && user.instructions.tinkoff
          "
          class="mb-20"
          only-button
          :doc="{
            link: user.instructions.tinkoff.link,
            buttonText: `Инструкция по Тинькофф`,
          }"
        />
        <el-radio
          v-model="paymentSettings.system"
          label="sberbank"
          class="mb-10"
          @change="setPaymentSettings"
          >СберБанк</el-radio
        >

        <instruction-item
          v-if="paymentSettings.system === 'sberbank' && user.instructions.sber"
          class="mb-20"
          only-button
          :doc="{
            link: user.instructions.sber.link,
            buttonText: `Инструкция по Сбербанк`,
          }"
        />
        <el-radio
          v-model="paymentSettings.system"
          label="yandex"
          class="mb-10"
          @change="setPaymentSettings"
          >ЮKassa
        </el-radio>
        <instruction-item
          v-if="
            paymentSettings.system === 'yandex' && user.instructions.yookassa
          "
          class="mb-20"
          only-button
          :doc="{
            link: user.instructions.yookassa.link,
            buttonText: `Инструкция по ЮКаssа`,
          }"
        />
        <el-radio
          v-if="user.is_ubrir_referral || user.is_ubrir"
          v-model="paymentSettings.system"
          label="paykeeper"
          class="mb-10"
          @change="setPaymentSettings"
          >УБРиР (PayKeeper)
        </el-radio>
        <instruction-item
          v-if="
            paymentSettings.system === 'paykeeper' &&
            user.instructions.paykeeper &&
            (user.is_ubrir_referral || user.is_ubrir)
          "
          class="mb-20"
          only-button
          :doc="{
            link: user.instructions.paykeeper.link,
            buttonText: `Инструкция по PayKeeper`,
          }"
        />

        <el-radio
          v-if="!user.is_ubrir_referral && !user.is_ubrir"
          v-model="paymentSettings.system"
          label="paykeeper"
          class="mb-10"
          @change="setPaymentSettings"
          >PayKeeper
        </el-radio>
        <instruction-item
          v-if="
            paymentSettings.system === 'paykeeper' &&
            user.instructions.paykeep &&
            !user.is_ubrir_referral &&
            !user.is_ubrir
          "
          class="mb-20"
          only-button
          :doc="{
            link: user.instructions.paykeep.link,
            buttonText: `Инструкция по PayKeeper`,
          }"
        />
      </div>
      <div class="px-40 pb-30 pt-25 lg:p-15 settings-payment-form">
        <el-form
          v-if="
            paymentSettings.system == 'tinkoff' ||
            paymentSettings.system == 'yandex'
          "
          ref="formPaymentSettings"
          :model="paymentSettings.details"
          :hide-required-asterisk="true"
          label-position="top"
        >
          <el-row
            v-if="paymentSettings.system !== 'sberbank'"
            :gutter="30"
            type="flex"
            class="flex-wrap"
          >
            <el-col :span="$mq.tablet ? 24 : 12">
              <el-form-item
                ><div class="flex">
                  <span class="pb-5 text-12 leading-20">{{
                    paymentSettings.system === "tinkoff"
                      ? "Terminal ID"
                      : "Shop ID"
                  }}</span>
                  <span class="danger">*</span>
                </div>

                <el-input
                  v-model="paymentSettings.details.shopID"
                  :placeholder="
                    paymentSettings.system === 'tinkoff'
                      ? 'Введите Terminal ID'
                      : 'Введите Shop ID'
                  "
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col
              v-if="paymentSettings.system === 'tinkoff'"
              :span="$mq.tablet ? 24 : 12"
            >
              <!-- <el-form-item> -->
              <el-form-item>
                <div class="flex">
                  <span class="pb-5 text-12 leading-20">Пароль</span>
                  <span class="danger">*</span>
                </div>
                <el-input
                  v-model="paymentSettings.details.password"
                  type="password"
                  placeholder="Введите пароль"
                >
                </el-input>
              </el-form-item>
              <!-- </el-form-item> -->
            </el-col>
            <el-col
              v-if="paymentSettings.system === 'yandex'"
              :span="$mq.tablet ? 24 : 12"
            >
              <el-form-item>
                <div class="flex">
                  <span class="pb-5 text-12 leading-20">Ключ</span>
                  <span class="danger">*</span>
                </div>

                <el-input
                  v-model="paymentSettings.details.key"
                  type="password"
                  placeholder="Введите ключ"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-checkbox
            v-model="paymentSettings.useChecks"
            label="Использовать чеки"
            class="lg:mb-25 mb-20"
          ></el-checkbox>
          <el-row
            v-if="paymentSettings.useChecks"
            :gutter="30"
            type="flex"
            class="flex-wrap"
          >
            <el-col :span="$mq.tablet ? 24 : 12">
              <el-form-item>
                <div class="flex">
                  <span class="pb-7 text-12">Система налогообложения</span>
                  <span class="danger">*</span>
                </div>

                <el-select
                  v-model="paymentSettings.details.tax"
                  placeholder="Выбрать"
                  class="lg:w-full"
                >
                  <el-option
                    v-for="(item, idx) in taxSystem"
                    :key="`item.id-${idx}`"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="$mq.tablet ? 24 : 12">
              <el-form-item v-if="paymentSettings.useChecks">
                <div class="flex">
                  <span class="pb-7 text-12">Ставка налога </span>
                  <span class="danger">*</span>
                </div>

                <el-select
                  v-model="paymentSettings.details.taxAmount"
                  placeholder="Выбрать"
                  class="lg:w-full"
                >
                  <el-option
                    v-for="item in taxRate"
                    :key="item.value"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-checkbox
            v-model="paymentSettings.useChecks"
            label="Использовать чеки"
            class="lg:mb-25"
          ></el-checkbox> -->
          <el-button v-if="$mq.tablet" class="ml-auto pl-15 pr-15 mb-20 w-full">
            <div class="btn-content flex items-center justify-center">
              <svg-icon
                icon-name="docs-thin"
                width="14"
                height="18"
                class-name="mr-10 text-green-400"
              ></svg-icon>
              <span v-if="paymentSettings.system === 'yandex'"
                >Инструкция по Яндекс.Касса</span
              >
              <span v-if="paymentSettings.system === 'tinkoff'"
                >Инструкция по Тинькофф</span
              >
            </div>
          </el-button>
        </el-form>
        <el-form
          v-if="paymentSettings.system == 'paykeeper'"
          ref="formPaymentSettings"
          :model="paymentSettings.details"
          :hide-required-asterisk="true"
          label-position="top"
        >
          <el-row
            v-if="paymentSettings.system == 'paykeeper'"
            type="flex"
            :gutter="30"
            class="flex-wrap"
          >
            <el-col :span="$mq.tablet ? 24 : 12">
              <div class="flex">
                <span class="pb-7 text-12">Логин</span>
                <span class="danger">*</span>
              </div>

              <el-form-item label="" class="">
                <el-input
                  v-model="paymentSettings.details.login"
                  placeholder="Введите ключ"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="$mq.tablet ? 24 : 12">
              <div class="flex">
                <span class="pb-7 text-12">Пароль</span>
                <span class="danger">*</span>
              </div>
              <el-form-item class="">
                <el-input
                  v-model="paymentSettings.details.password"
                  type="password"
                  placeholder="Введите пароль"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="30">
            <el-col :span="$mq.tablet ? 24 : 12">
              <div class="flex">
                <span class="pb-7 text-12">Сервер</span>
                <span class="danger">*</span>
              </div>

              <el-form-item label="" class="">
                <el-input
                  v-model="paymentSettings.details.paykeeper_server"
                  placeholder="Введите сервер"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="$mq.tablet ? 24 : 12">
              <div class="flex">
                <span class="pb-7 text-12">Секретное слово</span>
                <span class="danger">*</span>
              </div>
              <el-form-item class="">
                <el-input
                  v-model="paymentSettings.details.secret_word"
                  type="password"
                  placeholder="Введите секретное слово"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <!-- <el-checkbox
            v-model="paymentSettings.useChecks"
            label="Фискализация"
            class="lg:mb-25 mb-20"
          ></el-checkbox>
          <el-row v-if="paymentSettings.useChecks" :gutter="30">
            <el-col :span="$mq.tablet ? 24 : 12">
              <div class="flex">
                <span class="pb-7 text-12">Ставка налога</span>
                <span class="danger">*</span>
              </div>

              <el-form-item>
                <el-select
                  v-model="paymentSettings.details.taxAmount"
                  placeholder="Выбрать"
                  class="lg:w-full"
                >
                  <el-option
                    v-for="item in taxRate"
                    :key="item.value"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row> -->
        </el-form>
        <el-form
          v-if="paymentSettings.system == 'sberbank'"
          ref="formPaymentSettings"
          :model="paymentSettings.details"
          :hide-required-asterisk="true"
          label-position="top"
        >
          <el-row
            v-if="paymentSettings.system == 'sberbank'"
            type="flex"
            :gutter="30"
            class="flex-wrap"
          >
            <el-col :span="$mq.tablet ? 24 : 12">
              <div class="flex">
                <span class="pb-7 text-12">Логин</span>
                <span class="danger">*</span>
              </div>

              <el-form-item label="" class="">
                <el-input
                  v-model="paymentSettings.details.login"
                  placeholder="Введите ключ"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="$mq.tablet ? 24 : 12">
              <div class="flex">
                <span class="pb-7 text-12">Пароль</span>
                <span class="danger">*</span>
              </div>
              <el-form-item class="">
                <el-input
                  v-model="paymentSettings.details.password"
                  type="password"
                  placeholder="Введите пароль"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-checkbox
            v-model="paymentSettings.useChecks"
            label="Использовать чеки"
            class="lg:mb-25 mb-20"
          ></el-checkbox>
          <el-row v-if="paymentSettings.useChecks" :gutter="30">
            <el-col :span="$mq.tablet ? 24 : 12">
              <div class="flex">
                <span class="pb-7 text-12">Система налогообложения </span>
                <span class="danger">*</span>
              </div>

              <el-form-item>
                <el-select
                  v-model="paymentSettings.details.taxAmount"
                  placeholder="Выбрать"
                  class="lg:w-full"
                >
                  <el-option
                    v-for="item in taxSystem"
                    :key="item.value"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="$mq.tablet ? 24 : 12">
              <div class="flex">
                <span class="pb-7 text-12">Ставка налога</span>
                <span class="danger">*</span>
              </div>

              <el-form-item>
                <el-select
                  v-model="paymentSettings.details.taxAmount"
                  placeholder="Выбрать"
                  class="lg:w-full"
                >
                  <el-option
                    v-for="item in taxRate"
                    :key="item.value"
                    :label="item.name"
                    :value="item.id"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <!-- <el-checkbox
            v-model="paymentSettings.useChecks"
            label="Использовать чеки"
            class="lg:mb-25"
          ></el-checkbox> -->

          <el-button v-if="$mq.tablet" class="ml-auto pl-15 pr-15 mb-20 w-full">
            <div class="btn-content flex items-center justify-center">
              <svg-icon
                icon-name="docs-thin"
                width="14"
                height="18"
                class-name="mr-10 text-green-400"
              ></svg-icon>
              <span v-if="paymentSettings.system === 'yandex'"
                >Инструкция по СберБанк</span
              >
            </div>
          </el-button>
        </el-form>
        <div v-if="paymentSettings.system === 'none'" class="text-18">
          <span> Выберите систему оплаты </span>
          <h3 class="text-12">
            Выберите данный пункт, если вы не планируете принимать оплаты
            онлайн.
          </h3>
        </div>
        <div v-if="paymentSettings.system">
          <el-button
            type="primary"
            :loading="loading"
            class="bg-transparent font-white lg:w-full mt-20"
            @click="savePaymentSystem"
            >Применить
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { AJAX_POST } from "../../units/ajax";
import InstructionItem from "@/components/InstructionItem";

export default {
  components: {
    InstructionItem,
  },
  data() {
    return {
      loading: false,
      timezones: [
        {
          value: "1",
          label: "Москва, Россия",
        },
        {
          value: "2",
          label: "Минск, Беларусь",
        },
      ],
      paymentSettings: {
        taxes: [
          {
            value: 1,
            label: "Налогообложение 1",
          },
          {
            value: 2,
            label: "Налогообложение 2",
          },
          {
            value: 3,
            label: "Налогообложение 3",
          },
          {
            value: 4,
            label: "Налогообложение 4",
          },
          {
            value: 5,
            label: "Налогообложение 5",
          },
          {
            value: 6,
            label: "Налогообложение 6",
          },
        ],
        taxesAmount: [
          {
            value: 1,
            label: "5 %",
          },
          {
            value: 2,
            label: "10 %",
          },
          {
            value: 3,
            label: "12 %",
          },
          {
            value: 4,
            label: "15 %",
          },
          {
            value: 5,
            label: "25 %",
          },
          {
            value: 6,
            label: "35 %",
          },
        ],
        useChecks: false,
        details: {
          taxAmount: "", // 1,
          tax: 1,
          key: "",
          shopID: "",
          use_receipt: false,
          password: "",
          login: "",
          secret_word: "",
          paykeeper_server: "",
        },
        system: "none",
        systems: [
          {
            enable: false,
            value: "none",
            label: "Не использовать",
          },
          {
            enable: false,
            value: "yandex",
            label: "ЮKassa",
          },
          {
            enable: false,
            value: "sberbank",
            label: "СберБанк",
          },
          {
            enable: false,
            value: "tinkoff",
            label: "Тинькофф",
          },
          {
            enable: false,
            value: "paykeeper",
            label: "paykeeper",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState("user", ["user", "taxSystem", "taxRate"]),
  },
  created() {
    this.setCurrentSystem();
    this.setPaymentSettings(true);
  },
  methods: {
    ...mapMutations(["setNotif"]),
    ...mapActions("user", ["getInfo"]),
    setCurrentSystem() {
      const { payments } = this.user;
      // eslint-disable-next-line no-restricted-syntax
      for (const key in payments) {
        if (payments[key].enabled) this.paymentSettings.system = key;
      }
    },
    async savePaymentSystem() {
      if (this.paymentSettings.system !== "none") this.loading = true;
      let data =
        this.paymentSettings.system !== "sberbank"
          ? {
              system: this.paymentSettings.system,
              use_receipt: this.paymentSettings.useChecks,
              tax_system: this.paymentSettings.details.tax,
              tax_rate: this.paymentSettings.details.taxAmount,
            }
          : {
              system: this.paymentSettings.system,
              password: this.paymentSettings.details.password,
              login: this.paymentSettings.details.login,
              use_receipt: this.paymentSettings.useChecks,
              tax_system: this.paymentSettings.details.tax,
              tax_rate: this.paymentSettings.details.taxAmount,
            };
      if (this.paymentSettings.system === "none") {
        data = {
          system: this.paymentSettings.system,
          use_receipt: this.paymentSettings.useChecks,
          // tax_system: this.paymentSettings.details.tax,
          // tax_rate: this.paymentSettings.details.taxAmount,
        };
      }
      if (this.paymentSettings.system === "tinkoff") {
        data.terminal = this.paymentSettings.details.shopID;
        data.password = this.paymentSettings.details.password;
      } else if (this.paymentSettings.system === "yandex") {
        data.shop_id = this.paymentSettings.details.shopID;
        data.key = this.paymentSettings.details.key;
      }
      if (this.paymentSettings.system === "paykeeper") {
        data = {
          system: this.paymentSettings.system,
          login: this.paymentSettings.details.login,
          password: this.paymentSettings.details.password,
          secret_word: this.paymentSettings.details.secret_word,
          paykeeper_server: this.paymentSettings.details.paykeeper_server,
          use_receipt: this.paymentSettings.useChecks,
          tax_rate: this.paymentSettings.details.taxAmount,
        };
      }
      await AJAX_POST("/api/me/change/paymentSystem", data)
        .then(async (response) => {
          if (response.data.success === false) {
            this.setNotif({
              title: `${response.data.msg}`,
              type: "error",
            });
          } else {
            this.setNotif({
              title: "Система оплаты изменена",
            });
            await this.getInfo();
          }
        })
        .catch((err) => {
          console.log("work", err);
          this.setNotif({
            title: "Произошла ошибка, попробуйте позже",
            type: "error",
          });
        });
      this.loading = false;
    },
    setPaymentSettings(created) {
      const { system } = this.paymentSettings;
      if (system === "none" && created) return;
      if (system !== "none") {
        const current = this.paymentSettings.details;
        const loadedInfo = this.user.payments[system];
        current.taxAmount = loadedInfo.tax_rate;
        current.tax = loadedInfo?.tax_system;
        current.key = loadedInfo.key;
        current.shopID = loadedInfo.shop_id || loadedInfo.terminal;
        current.login = loadedInfo.login;
        current.password = loadedInfo.password;
        current.paykeeper_server = loadedInfo.paykeeper_server;
        current.secret_word = loadedInfo.secret_word;
        this.paymentSettings.useChecks = loadedInfo.use_receipt;
      } else this.savePaymentSystem();
    },
  },
};
</script>
