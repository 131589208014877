import { render, staticRenderFns } from "./Thread.vue?vue&type=template&id=97de7f74&scoped=true&"
import script from "./Thread.vue?vue&type=script&lang=js&"
export * from "./Thread.vue?vue&type=script&lang=js&"
import style0 from "./Thread.vue?vue&type=style&index=0&id=97de7f74&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97de7f74",
  null
  
)

export default component.exports