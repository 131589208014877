<template>
  <component :is="prop ? 'el-form-item' : 'div'" class="wrapper-is">
    <div v-if="label" slot="label" class="wrapper-is__title">
      <div class="wrapper-is__title-text">
        {{ label }}<span v-if="required" class="danger">*</span>
      </div>
      <el-popover
        v-if="tooltipText"
        class="ml-8"
        placement="top"
        width="200"
        trigger="hover"
        :content="tooltipText"
      >
        <Tooltip slot="reference" />
      </el-popover>
    </div>

    <div class="input-select">
      <el-input
        :value="value"
        class="w-limit"
        :placeholder="placeholder ? placeholder : 'Число'"
        type="text"
        @input="$emit('input', $event)"
        @blur="$emit('blur', $event)"
      >
      </el-input>
      <el-select
        :value="selectValue"
        placeholder="Select"
        class="select"
        @input="$emit('setValue', $event)"
      >
        <el-option
          v-for="item in listValue"
          :key="`time-${item.value}`"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
  </component>
</template>

<script>
export default {
  name: "InputSelect",
  props: {
    label: {
      type: String,
      default: "",
    },
    required: Boolean,
    tooltipText: {
      type: String,
      default: "",
    },
    prop: {
      type: String,
      default: "",
    },
    value: {
      type: [String, Number],
      default: 0,
    },
    selectValue: {
      type: [String, Number],
    },
    listValue: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.wrapper-is {
  display: flex;
  flex-direction: column;
  &__title {
    display: flex;
    &-text {
      // margin-bottom: 10px;
      font-size: 12px;
    }
  }
}

.input-select {
  display: flex;
  background-color: $--color-light-white;
  border-radius: 3px;
  max-width: 350px;
  @media (max-width: 480px) {
    width: 220px;
  }
}

::v-deep .el-input-group__prepend,
::v-deep .el-input__inner {
  background-color: transparent;
  border-radius: 3px 0 0 3px;
}

.select ::v-deep .el-input__inner {
  border-radius: 0 3px 3px 0;
  flex-shrink: 0;
  width: 85px;
  border-left: 1px solid $--color-white-15 !important;
}

.select ::v-deep .el-input__suffix {
  right: 5px;
}

::v-deep .el-select .el-input__inner:focus,
::v-deep .el-select .el-input.is-focus .el-input__inner {
  border-left: 1px solid $--color-white-15;
}
</style>
