<template>
  <div>
    <div
      class="lg:px-15 lg:flex lg:items-center lg:justify-between mb-20 xs:px-0"
    >
      <div>
        <h2 class="text-white-normal text-18 leading-28 font-semibold">
          Всего записей:
          {{ orders_stats.total }}
        </h2>
        <div class="text-white-500 leading-25 lg:text-12 lg:leading-16">
          Показано c {{ orders_stats.from || 1 }} по {{ orders_stats.to || 10 }}
        </div>
      </div>
    </div>
    <SearchBar
      input-placeholder="Поиск по заказам"
      :per-page-additional-filter="filterStatus"
      :export-links="exportLinks"
      :reset-filter-search="resetFilterSearch"
      @changeStatusFilter="$emit('changeStatusFilter', null)"
      @submit="createReq"
    />

    <section class="reserves-all relative">
      <div v-if="isLoading" class="preloader__wrap">
        <spinner v-if="isLoading" class="table__spinner" />
      </div>
      <div v-if="$mq.tablet" class="mobile-reserves">
        <NoData v-if="!isLoading && !orders.length" text="Нет данных" />
        <template v-if="orders.length">
          <div
            v-for="(reserve, index) in orders"
            :key="index"
            class="mobile-reserve bg-white-40 px-15 pt-15 pb-20 mb-20"
          >
            <div class="flex justify-between">
              <div
                class="text-16 font-semibold leading-23 text-yellow-100 mb-5"
              >
                {{ reserve.client_name }}
              </div>
              <el-popover
                class="ml-70 xs:ml-0 xs:order-2 xs:flex xs:justify-end xs:w-1/2"
                placement="bottom-end"
                popper-class="control-poper"
                width="171"
                trigger="click"
              >
                <span
                  slot="reference"
                  class="px-15 flex items-center outline-none h-20 lg:pr-0"
                >
                  <svg-icon
                    icon-name="dots"
                    class-name="text-white-500"
                    width="20"
                    height="4"
                  ></svg-icon>
                </span>
                <div>
                  <div
                    class="services-drag__item-icon mobile"
                    @click="onEditReserve(reserve)"
                  >
                    <svg-icon
                      title="Редактировать"
                      icon-name="edit"
                      width="16"
                      height="18"
                      :style="{
                        color: '#3498db',
                      }"
                    />
                    <span class="ml-8">Редактировать</span>
                  </div>
                  <div
                    class="services-drag__item-icon mobile"
                    @click="$emit('show', { key: 'comments', data: reserve })"
                  >
                    <svg-icon
                      title="Комментарии"
                      icon-name="feedback"
                      width="16"
                      height="16"
                      :style="{
                        color: '#3498db',
                      }"
                    />
                    <span class="ml-8">Комментарии</span>
                  </div>
                  <div
                    class="services-drag__item-icon mobile"
                    @click="$emit('show', { key: 'orderList', data: reserve })"
                  >
                    <svg-icon
                      title="Состав заказа"
                      icon-name="reserves"
                      width="16"
                      height="16"
                      :style="{
                        color: '#3498db',
                      }"
                    />
                    <span class="ml-8">Состав заказа</span>
                  </div>
                  <div
                    class="services-drag__item-icon mobile"
                    @click="
                      $emit('show', {
                        key: 'sendEmail',
                        data: reserve.client_email,
                      })
                    "
                  >
                    <svg-icon
                      title="Отправить email"
                      icon-name="mail2"
                      width="16"
                      height="16"
                      :style="{
                        color: '#3498db',
                      }"
                    />
                    <span class="ml-8">Отправить email</span>
                  </div>
                  <div
                    class="services-drag__item-icon mobile"
                    @click="
                      $emit('show', {
                        key: 'sendSms',
                        data: reserve.client_tel,
                      })
                    "
                  >
                    <svg-icon
                      title="Отправить SMS"
                      icon-name="phone"
                      width="16"
                      height="18"
                      :style="{
                        color: '#3498db',
                      }"
                    />
                    <span class="ml-8">Отправить SMS</span>
                  </div>
                </div>
              </el-popover>
            </div>

            <div
              class="flex items-center mb-16 flex-wrap xs:flex-col xs:items-baseline"
            >
              <a
                :href="`mailto:${reserve.client_email}`"
                class="text-white-700 border-b border-white-350 mr-20 mb-5"
                >{{ reserve.client_email }}</a
              >
              <a
                :href="`tel:${reserve.client_tel}`"
                class="text-white-700 border-b border-white-350 mb-5"
                >{{ reserve.client_tel }}</a
              >
              <!-- <router-link
                to="/"
                class="text-white-700 border-white-350 mb-5"
                >{{ reserve.resource_name }}</router-link
              > -->

              <router-link
                class="services__item-name mb-5 xs:ml-0 ml-20"
                :to="{
                  name: 'resource',
                  params: { id: reserve.id, hash: reserve.resource_id },
                  query: { tab: 'schedule' },
                }"
              >
                <span :title="reserve.resource_name"
                  >{{ reserve.resource_name }}
                </span></router-link
              >
              <!-- <a
                :href="`tel:${reserve.client_tel}`"
                class="text-white-700 border-b border-white-350 mb-5"
                >{{ reserve.resource_name }}</a
              > -->
            </div>
            <div class="mb-20">
              <div class="mobile-reserves-stat mb-5">
                <div class="text-white-500 text-12 leading-16">Номер брони</div>
                <div class="leading-20 break-all">{{ reserve.id }}</div>
              </div>
              <div class="mobile-reserves-stat mb-5">
                <div class="text-white-500 text-12 leading-16">
                  Дата и время заказа
                </div>
                <div class="leading-20">
                  {{ getDate(reserve.created_at) }}
                  {{ getTime(reserve.created_at) }}
                  <!-- <span class="small_text text-12 leading-20">
                    {{ reserve.time }}
                  </span> -->
                </div>
              </div>
              <div class="mobile-reserves-stat mb-5">
                <div class="text-white-500 text-12 leading-16">
                  Дата и время брони
                </div>
                <div class="leading-20">
                  {{ getDate(reserve.date) }} {{ reserve.time }}
                  <!-- <span class="small_text text-12 leading-20">
                    {{ reserve.time }}
                  </span> -->
                </div>
              </div>

              <div class="mobile-reserves-stat mb-5">
                <div class="text-white-500 text-12 leading-16">Итого</div>
                <div class="leading-20">
                  {{ reserve.book_info.total_price }} руб.
                </div>
              </div>
              <div class="mobile-reserves-stat">
                <div
                  v-if="reserve.payment_data.length"
                  class="text-white-500 text-12 leading-16"
                >
                  Статус оплаты
                </div>
                <div>
                  <div class="flex items-center leading-20">
                    <template v-if="reserve.payment">
                      <!-- <svg-icon
                        :icon-name="checkIcon(reserve.payment_data).icon"
                        width="12"
                        height="12"
                        class-name="`text-green-400 mr-7 `"
                        :style="{
                          color: `${checkIcon(reserve.payment_data).color}`,
                        }"
                      ></svg-icon> -->
                      <span
                        class="w-10 h-10 relative mr-5"
                        :style="{
                          background: reserve.payment_data.find(
                            (e) => e.status_color
                          )
                            ? reserve.payment_data.find((e) => e.status_color)
                                .status_color
                            : '',
                        }"
                        style="border-radius: 50%"
                      >
                      </span>
                      <span
                        v-if="reserve.payment_data.find((e) => e.status_name)"
                        >{{
                          reserve.payment_data.find((e) => e.status_name)
                            .status_name
                            ? reserve.payment_data.find((e) => e.status_name)
                                .status_name
                            : ""
                        }}</span
                      >
                    </template>
                    <template v-else> Нет оплаты </template>
                  </div>
                </div>
              </div>
            </div>
            <el-button class="w-full" @click="onEditReserve(reserve)">
              Редактировать
            </el-button>
          </div>
        </template>
        <!-- <el-drawer
          v-if="mobileDetails"
          :show-close="false"
          :visible.sync="mobileDetails"
          direction="ltr"
          size="100%"
        >
          <div slot="title">
            <div class="bg-violet-800 px-25 py-10">
              <div class="flex items-center justify-between">
                <div>
                  <div class="text-12 leading-16 text-white-500 mb-3">
                    Детали бронирования
                  </div>
                  <div class="text-16 leading-20 text-yellow-100 font-semibold">
                    №
                    {{ mobileDetailReserve.hash }}
                  </div>
                </div>
                <div @click="mobileDetails = false">
                  <svg-icon
                    icon-name="close"
                    width="18"
                    height="18"
                    class-name="text-white-normal"
                  ></svg-icon>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-gradient-b-violet pt-15 pb-20 min-h-full">
            <div class="px-15 pt-15 pb-20 bg-white-100 mb-20">
              <div class="text-18 font-semibold leading-30 mb-10">
                Бронирование
              </div>
              <div class="mobile-reserves-stat mb-5">
                <div class="text-white-500 text-12 leading-16">Номер брони</div>
                <div class="leading-20 break-all">
                  {{ mobileDetailReserve.hash }}
                </div>
              </div>
              <div class="mobile-reserves-stat mb-5">
                <div class="text-white-500 text-12 leading-16">
                  Дата и время брони
                </div>
                <div class="leading-20">
                  {{ getDate(mobileDetailReserve.date) }}
                </div>
              </div>
              <div class="mobile-reserves-stat mb-16">
                <div class="text-white-500 text-12 leading-16">
                  Статус оплаты
                </div>
                <div>
                  <div class="flex items-center leading-20">
                    <template v-if="mobileDetailReserve.payment">
                      <svg-icon
                        icon-name="check"
                        width="12"
                        height="8"
                        class-name="text-green-400 mr-7"
                      ></svg-icon>
                      Оплачено
                    </template>
                    <template v-else> Нет оплаты </template>
                  </div>
                </div>
              </div>
              <el-button size="mini" class="mt-auto" type="warning">
                <div class="flex items-center">
                  <svg-icon
                    icon-name="feedback"
                    class-name="text-white-normal mr-7"
                    width="13"
                    height="13"
                  ></svg-icon>
                  Комментарий админа
                </div>
              </el-button>
            </div>

            <div class="px-15 pt-15 pb-20 bg-white-100 mb-20">
              <div class="text-18 font-semibold leading-30 mb-10">Клиент</div>
              <div
                class="mobile-reserves-stat mobile-reserves-stat_client mb-5"
              >
                <div class="text-white-500 text-12 leading-16">Телефон</div>
                <div>
                  <a
                    :href="`tel:${mobileDetailReserve.client_tel}`"
                    class="inline-flex leading-20 border-b border-white-350"
                    >{{ mobileDetailReserve.client_tel }}</a
                  >
                </div>
              </div>
              <div
                class="mobile-reserves-stat mobile-reserves-stat_client mb-16"
              >
                <div class="text-white-500 text-12 leading-16">E-mail</div>
                <div>
                  <a
                    :href="`mailto:${mobileDetailReserve.client_email}`"
                    class="inline-flex leading-20 border-b border-white-350"
                    >{{ mobileDetailReserve.client_email }}</a
                  >
                </div>
              </div>
              <el-button size="mini" class="mt-auto" type="warning">
                <div class="flex items-center">
                  <svg-icon
                    icon-name="feedback"
                    class-name="text-white-normal mr-7"
                    width="13"
                    height="13"
                  ></svg-icon>
                  Комментарий клиента
                </div>
              </el-button>
            </div>

            <div class="px-15 pt-15 pb-20 bg-white-100 mb-20">
              <div class="text-18 font-semibold leading-30 mb-10">Заказ</div>
              <a
                href="#"
                class="inline-flex leading-20 border-b border-white-350 mb-12"
                >Охотники за сокровищами</a
              >
              <div class="mobile-reserves-stat mobile-reserves-stat_offer mb-5">
                <div class="text-white-500 text-12 leading-16">
                  Дата и время события
                </div>
                <div class="leading-20">
                  {{ getDate(mobileDetailReserve.book_info.date) }}
                </div>
              </div>

              <div class="mobile-reserves-stat mobile-reserves-stat_offer mb-5">
                <div class="text-white-500 text-12 leading-16">Цена</div>
                <div class="leading-20">
                  {{ mobileDetailReserve.book_info.price }} руб.
                </div>
              </div>

              <div class="mobile-reserves-stat mobile-reserves-stat_offer mb-5">
                <div class="text-white-500 text-12 leading-16">Игроков</div>
                <div class="leading-20">
                  {{ mobileDetailReserve.book_info.count }}
                </div>
              </div>
              <div
                class="mobile-reserves-stat mobile-reserves-stat_offer mb-20"
              >
                <div class="text-white-500 text-12 leading-16">
                  Источник заказа
                </div>
                <div
                  class="leading-20 overflow-hidden mobile-reserves-stat__origin"
                >
                  {{ mobileDetailReserve.origin }}
                </div>
              </div>

              <label>
                <div class="text-12 mb-7 leading-16">Статус заказа</div>
                <el-select
                  v-model="mobileDetailReserve.status.id"
                  class="bordered"
                  :class="statusClass(mobileDetailReserve.status.id)"
                  @change="changeStatus(mobileDetailReserve)"
                >
                  <el-option
                    v-for="item in statuses"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </label>
            </div>

            <div class="px-15 pt-15 pb-20 bg-white-100 mb-20">
              <div class="text-18 font-semibold leading-30 mb-10">Оплата</div>
              <div class="mobile-reserves-stat mb-5">
                <div class="text-white-500 text-12 leading-16">Тип оплаты</div>
                <div class="leading-20 break-all">Онлайн</div>
              </div>
              <div class="mobile-reserves-stat mb-5">
                <div class="text-white-500 text-12 leading-16">
                  Итого к оплате
                </div>
                <div class="leading-20">
                  {{ mobileDetailReserve.book_info.total_price }} руб.
                </div>
              </div>
              <div class="mobile-reserves-stat mb-16">
                <div class="text-white-500 text-12 leading-16">
                  Статус оплаты
                </div>
                <div>
                  <div class="flex items-center leading-20">
                    <template v-if="mobileDetailReserve.payment">
                      <svg-icon
                        icon-name="check"
                        width="12"
                        height="8"
                        class-name="text-green-400 mr-7"
                      ></svg-icon>
                      Оплачено
                    </template>
                    <template v-else> Нет оплаты </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="px-15">
              <div>
                <el-button
                  type="info"
                  class="w-full mb-15"
                  @click="showEditSidebar = true"
                >
                  <span class="flex justify-center items-center">
                    <svg-icon
                      icon-name="edit"
                      class-name="text-white-normal mr-10 min-w-17"
                      width="17"
                      height="17"
                    ></svg-icon>
                    Редактировать бронирование
                  </span>
                </el-button>
              </div>
              <div>
                <el-button class="w-full mb-15" @click="mobileDetails = false">
                  <span class="flex justify-center items-center">
                    <svg-icon
                      icon-name="arrow"
                      class-name="text-white-normal mr-10 rotate-180 "
                      width="13"
                      height="8"
                    ></svg-icon>
                    Вернуться назад
                  </span>
                </el-button>
              </div>
            </div>
          </div>
        </el-drawer> -->
      </div>
      <simplebar v-else data-simplebar-auto-hide="false" class="mb-50">
        <no-data
          v-if="!isLoading && !orders.length"
          size="big-full"
          text="Нет заказов"
        />
        <div v-else class="reserves-content">
          <el-table
            v-if="orders.length"
            :data="orders"
            :stripe="true"
            @sort-change="setSort"
          >
            <el-table-column label="" :min-width="60" :width="60">
              <div
                slot="header"
                class="table__icon cursor-pointer"
                @click="$emit('show', 'settings')"
              >
                <svg-icon
                  icon-name="settings"
                  width="18"
                  height="18"
                ></svg-icon>
              </div>
              <template slot-scope="{ row }">
                <div
                  class="table__icon cursor-pointer"
                  @click="onEditReserve(row)"
                >
                  <svg-icon
                    icon-name="edit-2"
                    width="20"
                    height="20"
                  ></svg-icon>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="columns.includes(1)"
              :index="indexMethod"
              label="№"
              :width="62"
            >
              <template slot-scope="{ row }">{{ row.num }}</template>
            </el-table-column>

            <el-table-column
              v-if="columns.includes(1)"
              prop="number"
              label="Дата"
              :min-width="188"
              sortable="custom"
            >
              <template slot-scope="{ row }">
                <span class="item__id">#{{ row.id }}</span>
                <div v-if="row.comments" class="my-10">
                  <p>{{ getDate(row.created_at) }}</p>
                  <p class="small_text">{{ getTime(row.created_at) }}</p>

                  <!-- <div v-for="r in row.comments" :key="r.id" class="my-10">
                    <p v-if="r[0]">{{ getDate(r[0].date) }}</p>
                    <p v-if="r[0]" class="small_text">{{ r[0].time }}</p>
                  </div> -->
                </div>
                <el-button
                  class="w-full h-30"
                  @click="$emit('show', { key: 'comments', data: row })"
                  ><span>Комментарии</span></el-button
                >
              </template>
              <!-- <el-popover
                  v-if="row.client_info.moder_comment"
                  placement="top"
                  title="Комментарий"
                  width="200"
                  trigger="hover"
                >
                  <div
                    v-for="(comment, index) in row.client_info.moder_comment"
                    :key="index"
                  >
                    {{ comment.text }}
                  </div>
                  <br />
                  <el-button
                    slot="reference"
                    size="mini"
                    class="mt-auto"
                    type="warning"
                  >
                    <div class="flex items-center">
                      <svg-icon
                        icon-name="feedback"
                        class-name="text-white-normal mr-7"
                        width="13"
                        height="13"
                      ></svg-icon>
                      Комментарий админа
                    </div>
                  </el-button>
                </el-popover> -->
            </el-table-column>
            <el-table-column
              v-if="columns.includes(2)"
              prop="client"
              :min-width="230"
              label="Клиент"
            >
              <template slot-scope="{ row }">
                <!--  @click="$emit('show', 'clientInfo')"  -->
                <span
                  class="text-yellow-100 border-b border-white-350 leading-25 cursor-pointer"
                >
                  {{ row.client_name }} {{ row.client_surname }}
                </span>
                <div class="mt-10 mb-5">
                  <a
                    :href="`mailto:${row.client_email}`"
                    class="text-white-normal border-b border-white-350 leading-25 mb-5"
                    >{{ row.client_email }}</a
                  >
                </div>
                <div>
                  <a
                    :href="`tel:${row.client_tel}`"
                    class="text-white-normal border-b border-white-350 leading-25 mb-14"
                    >{{ row.client_tel }}</a
                  >
                </div>

                <el-popover
                  placement="bottom-end"
                  popper-class="control-poper"
                  trigger="click"
                >
                  <el-button
                    slot="reference"
                    class="mt-20 px-12 flex button_icon"
                    ><svg-icon
                      icon-name="additionally"
                      class-name="text-white-normal mr-7"
                      width="20"
                      height="20"
                    ></svg-icon
                    ><span>Дополнительно</span></el-button
                  >

                  <div>
                    <div
                      class="actions-item"
                      @click="
                        $emit('show', {
                          key: 'sendSms',
                          data: row.client_tel,
                        })
                      "
                    >
                      Отправить SMS
                    </div>
                    <div
                      class="actions-item"
                      @click="
                        $emit('show', {
                          key: 'sendEmail',
                          data: row.client_email,
                        })
                      "
                    >
                      Отправить E-mail
                    </div>
                    <!-- <div class="actions-item" @click="deleteSource(row.hash)">
                      Добавить напоминание
                    </div> -->
                  </div>
                </el-popover>

                <!-- <div>
                  <el-popover
                    v-if="row.comments.client[0].comment"
                    placement="top"
                    title="Комментарий"
                    width="200"
                    trigger="hover"
                    :content="row.comments.client[0].comment"
                  >
                    <el-button
                      slot="reference"
                      size="mini"
                      class="mt-auto"
                      type="warning"
                    >
                      <div class="flex items-center">
                        <svg-icon
                          icon-name="additionally"
                          class-name="text-white-normal mr-7"
                          width="13"
                          height="13"
                        ></svg-icon>
                        Комментарий клиента
                      </div>
                    </el-button>
                  </el-popover>
                </div> -->
              </template>
            </el-table-column>
            <el-table-column
              v-if="columns.includes(3)"
              prop="bron"
              :min-width="284"
              sortable
              label="Бронирование"
            >
              <template slot-scope="{ row }">
                <router-link
                  :to="{
                    name: 'resource',
                    params: { hash: row.resource_id },
                    query: { tab: 'schedule' },
                  }"
                  class="border-b border-white-350 leading-25"
                  :class="{
                    'del-resource': row.resource_deleted,
                  }"
                >
                  <span v-if="row.resource_deleted">Удалена:</span>
                  {{ row.resource_name }}
                </router-link>
                <div class="flex flex-col mt-10">
                  <div class="flex mb-5">
                    <span class="small_text">Дата и время</span>
                    <div class="border-b border-white-350 leading-25">
                      <!-- <a class="cursor-pointer" @click="setParamRouter(row)">
                        {{ getDate(row.date) }} {{ row.time }}
                      </a> -->

                      <router-link
                        class="mt-15"
                        target="_blank"
                        :to="{
                          path: '/reserves',
                          query: {
                            names: `${row.resource_id}`,
                            service_time: `${row.time}`,
                            service_from: `${row.date}`,
                            service_to: `${row.date}`,
                          },
                        }"
                        ><span
                          >{{ getDate(row.date) }} {{ row.time }}
                        </span></router-link
                      >
                    </div>
                  </div>
                  <div class="flex mb-5">
                    <span class="small_text">Стоимость</span>
                    <span>
                      {{ row.book_info.total_price }} {{ row.currency }}
                    </span>
                  </div>
                </div>
                <el-button
                  slot="reference"
                  class="mt-20 px-12 flex button_icon"
                  @click="$emit('show', { key: 'orderList', data: row })"
                  ><span>Состав заказа</span></el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              v-if="columns.includes(4)"
              prop="status"
              sortable
              :min-width="211"
              label="Статус"
            >
              <template slot-scope="{ row }">
                <el-select
                  v-model="row.status.name"
                  value-key="name"
                  class="bordered select_status my-5"
                  @change="changeStat(row, row.status.name)"
                >
                  <div slot="prefix" class="flex items-center h-full">
                    <span
                      class="w-10 h-10 relative"
                      :style="{ background: row.status.color }"
                      style="border-radius: 50%; left: 20px"
                    >
                    </span>
                  </div>

                  <el-option
                    v-for="(item, idx) in row.available_statuses"
                    :key="idx"
                    :label="item.name_short"
                    :value="item"
                  >
                  </el-option>
                </el-select>

                <div v-if="row.booking_error_reason" class="my-5 py-3 text-12">
                  <div class="my-5">
                    <InfoError :item="row" />
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              v-if="columns.includes(5)"
              prop="payment"
              sortable
              :min-width="267"
              label="Оплата"
            >
              <div
                v-if="row.payment"
                slot-scope="{ row }"
                class="text-14 leading-25"
              >
                <div
                  v-for="(p, idx) in row.payment_data"
                  :key="idx"
                  class="mb-7"
                >
                  <span class="pr-5">{{ p.type_name }}</span>
                  <router-link
                    class="border-b border-white-350 leading-25"
                    target="_blank"
                    :to="{
                      path: '/payments',
                      query: { text: p.hash },
                    }"
                    >#{{ p.hash }}
                  </router-link>

                  <div class="flex items-center">
                    <span class="small_text">К оплате</span>
                    <span
                      >{{
                        p.type_name === "Предоплата онлайн"
                          ? `${p.prepayment} ${row.currency}`
                          : `${p.full_amount} ${row.currency}
`
                      }}
                    </span>
                  </div>
                  <div class="flex items-center">
                    <span class="small_text">Оплачено</span>
                    <span
                      :class="{
                        'small_text w-auto': !row.payed_summ,
                      }"
                      >{{
                        row.payed_summ
                          ? `${p.paid_amount} ${row.currency}`
                          : "—"
                      }}
                      <!-- {{ row.payed_summ ? `${row.payed_summ} ${row.currency}` : "—" }}
 -->
                    </span>
                  </div>
                  <template>
                    <div class="my-10">
                      <div class="w-full">
                        <div
                          class="w-full text-center flex justify-center py-3 text-12"
                          :style="convertColor(p.status_color_rgba)"
                        >
                          {{ p.status_name }}
                        </div>
                      </div>
                      <div class="w-full">
                        <el-button
                          v-if="
                            p.type_name !== 'Оплата на месте' && p.can_refund
                          "
                          slot="reference"
                          class="mt-10 px-12 flex button_icon w-full justify-center"
                          @click="
                            $emit('show', { key: 'returnCash', data: row })
                          "
                          ><span>Оформить возврат</span>
                        </el-button>

                        <el-button
                          v-else-if="
                            p.status_name !== 'Возврат средств' &&
                            p.type !== 'payment_locally' &&
                            p.status_name !== 'Частично оплачен' &&
                            (p.status_name !== 'Оплачен' ||
                              p.status_name === 'Время оплаты истекло')
                          "
                          slot="reference"
                          class="mt-10 px-10 flex button_icon w-full justify-center"
                          @click="
                            `${
                              p.status_name === 'Время оплаты истекло' ||
                              ((p.type === 'full_payment' ||
                                p.type === 'prepayment') &&
                                p.payment_link.length === 0)
                                ? getNewLink(p.hash)
                                : copy(p.payment_link)
                            }`
                          "
                          ><AppLink
                            :link-pay="row"
                            :title-link="`${
                              p.status_name === 'Время оплаты истекло' ||
                              ((p.type === 'full_payment' ||
                                p.type === 'prepayment') &&
                                p.payment_link.length === 0)
                                ? 'Создать ссылку'
                                : 'Копировать ссылку на оплату'
                            }`"
                            class="text-12"
                            @isLinkpay="sendLink"
                          />
                        </el-button>
                      </div>

                      <!-- <div
                        v-for="(l, index) in row.payment_data"
                        :key="index"
                        class="w-full"
                      >
                        <el-button
                          v-if="
                            row.payment_data.length &&
                            row.payment_data[0].type_name !==
                              'Оплата на месте' &&
                            l.can_refund
                          "
                          slot="reference"
                          class="mt-10 px-12 flex button_icon w-full justify-center"
                          @click="
                            $emit('show', { key: 'returnCash', data: row })
                          "
                          ><span>Оформить возврат</span></el-button
                        >
                        <el-button
                          v-else-if="
                            row.payment_data.length &&
                            row.payment_data[0].status_name !==
                              'Возврат средств' &&
                            row.payment_data[0].type !== 'payment_locally' &&
                            row.payment_data[0].status_name !==
                              'Частично оплачен' &&
                            (row.payment_data[0].status_name !== 'Оплачен' ||
                              row.payment_data[0].status_name ===
                                'Время оплаты истекло')
                          "
                          slot="reference"
                          class="mt-10 px-10 flex button_icon w-full justify-center"
                          @click="
                            `${
                              row.payment_data[0].status_name ===
                                'Время оплаты истекло' ||
                              ((row.payment_data[0].type === 'full_payment' ||
                                row.payment_data[0].type === 'prepayment') &&
                                row.payment_data[0].payment_link.length === 0)
                                ? getNewLink(l.hash)
                                : copy(l.payment_link)
                            }`
                          "
                        >
                          <AppLink
                            :link-pay="row"
                            :title-link="`${
                              row.payment_data[0].status_name ===
                                'Время оплаты истекло' ||
                              ((row.payment_data[0].type === 'full_payment' ||
                                row.payment_data[0].type === 'prepayment') &&
                                row.payment_data[0].payment_link.length === 0)
                                ? 'Создать ссылку'
                                : 'Копировать ссылку на оплату'
                            }`"
                            class="text-12"
                            @isLinkpay="sendLink"
                          />
                        </el-button>
                      </div> -->
                    </div>
                  </template>
                  <div class="my-5">
                    <InfoError :item="row" />
                  </div>
                  <div class="flex items-center">
                    <PayInPlacePoppup
                      :row="row"
                      :pay-data="row.payment_data[idx]"
                      :paym="row.payment[idx]"
                      :idx-param="idx"
                      @setPrePay="setData"
                    />
                  </div>
                </div>
                <div class="flex items-center"></div>

                <div class="flex items-center flex-col w-full">
                  <template>
                    <!-- <div
                      v-for="(status, idx) in row.payment_data"
                      :key="`status-${idx}`"
                      class="w-full"
                    >
                      <div
                        class="w-full text-center flex justify-center py-3"
                        :style="convertColor(status.status_color_rgba)"
                      >
                        {{ status.status_name }}
                      </div>
                    </div> -->
                    <!-- <span v-for="p in row.payment_data" :key="p.id">{{
                      p.payment_link
                    }}</span> -->
                    <!-- <div class="">{{ row.payment_status }}</div> -->
                    <!-- <div
                      v-for="status in statusesPay"
                      :key="status.value"
                      class="w-full text-center flex justify-center"
                      :class="{
                        statusPay: status.value === row.payment_status,
                      }"
                      :style="{
                        background: status.class,
                        border:
                          status.value === row.payment_status
                            ? `1px solid ${status.border}`
                            : '',
                      }"
                    >
                      {{ status }}
                      <i class="el-icon-s-finance"></i> -->
                    <!-- <span
                    v-if="status.value === row.payment_status" slot="suffix"
                    class="px-7" >
                    <svg-icon
                          icon-name="check"
                          width="12"
                          height="8"
                          class-name="text-green-400 mr-7"
                        ></svg-icon
                      > -->
                    <!-- </span>

                      <span>
                        {{
                          status.value === row.payment_status
                            ? status.label
                            : ""
                        }}</span
                      > 
                    </div> -->

                    <!-- <div
                      v-for="(l, index) in row.payment_data"
                      :key="index"
                      class="w-full"
                    >
                      <el-button
                        v-if="
                          row.payment_data.length &&
                          row.payment_data[0].type_name !== 'Оплата на месте' &&
                          l.can_refund
                        "
                        slot="reference"
                        class="mt-20 px-12 flex button_icon w-full justify-center"
                        @click="$emit('show', { key: 'returnCash', data: row })"
                        ><span>Оформить возврат</span></el-button
                      > -->
                    <!-- <el-button
                        v-else-if="
                          row.payment_data.length &&
                          row.payment_data[0].status_name !==
                            'Возврат средств' &&
                          row.payment_data[0].type !== 'payment_locally' &&
                          row.payment_data[0].status_name !==
                            'Время оплаты истекло'
                        "
                        slot="reference"
                        class="mt-10 px-10 flex button_icon w-full justify-center"
                        @click="copy(l.payment_link)"
                      >
                        <AppLink
                          :link-pay="row"
                          title-link="Копировать ссылку на оплату"
                          @isLinkpay="sendLink"
                        />
                      </el-button> -->
                    <!-- <el-button
                        v-else-if="
                          row.payment_data.length &&
                          row.payment_data[0].status_name !==
                            'Возврат средств' &&
                          row.payment_data[0].type !== 'payment_locally' &&
                          row.payment_data[0].status_name !==
                            'Частично оплачен' &&
                          (row.payment_data[0].status_name !== 'Оплачен' ||
                            row.payment_data[0].status_name ===
                              'Время оплаты истекло')
                        "
                        slot="reference"
                        class="mt-10 px-10 flex button_icon w-full justify-center"
                        @click="
                          `${
                            row.payment_data[0].status_name ===
                            'Время оплаты истекло'
                              ? getNewLink(l.hash)
                              : copy(l.payment_link)
                          }`
                        "
                      >
                        <AppLink
                          :link-pay="row"
                          :title-link="`${
                            row.payment_data[0].status_name ===
                            'Время оплаты истекло'
                              ? 'Создать ссылку'
                              : 'Копировать ссылку на оплату'
                          }`"
                          @isLinkpay="sendLink"
                        />
                      </el-button> 
                    </div>-->
                  </template>
                </div>
              </div>
            </el-table-column>
            <el-table-column
              v-if="columns.includes(6)"
              prop="source"
              sortable
              :min-width="169"
              label="Источник заказа"
            >
              <template slot-scope="{ row }">
                <div v-if="row.ordersource_data.length" class="mb-10">
                  {{ row.ordersource_data[0].value }}
                </div>
                <el-button
                  class="w-full h-30"
                  @click="$emit('show', { key: 'utm', data: row })"
                >
                  Детали
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </simplebar>
      <footer class="reserves-footer">
        <Pagination
          class="w-full custom-block"
          background
          hide-on-single-page
          :current-page="+pagination.current"
          :page-size="showParam"
          :total="orders_stats.total"
          @change="setPagin"
        />
      </footer>
    </section>
    <BookFrom
      v-if="showEditSidebar"
      :is-open.sync="showEditSidebar"
      :param="editableReserve"
      :date-slide="editableReserve.date"
      @closeForm="closeForm"
    />
    <el-dialog
      v-if="isShowPay"
      :visible.sync="isShowPay"
      :show-close="false"
      width="450px"
      @close="isShowPay = false"
    >
      <PayWidget v-if="dataPay.success" @close="isShowPay = false" />
      <PayError v-else :msg="dataPay.msg" @close="isShowPay = false" />
    </el-dialog>
    <!-- 
    <el-drawer
      :show-close="false"
      :visible.sync="showEditSidebar"
      direction="rtl"
      size="340px"
    >
      <div slot="title" class="bg-violet-900 pt-10 px-20 pb-23">
        <div class="flex items-center justify-between">
          <div class="font-semibold text-18 leading-30 text-white-normal">
            Редактирование заказа
          </div>
          <div class="cursor-pointer" @click="showEditSidebar = false">
            <svg-icon
              icon-name="close"
              width="12"
              height="12"
              class-name="text-white-normal"
            ></svg-icon>
          </div>
        </div>
      </div>

      <edit-reserve-form
        :reserve="editableReserve"
        @onSave="saveChanges"
      ></edit-reserve-form> 
    </el-drawer>-->
  </div>
</template>

<script>
import simplebar from "simplebar-vue";
// import EditReserveForm from "./EditReserveForm";
import { AJAX_GET, AJAX_POST, host } from "../units/ajax";
import moment from "moment";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import SearchBar from "@/components/Table/SearchBar";
import NoData from "@/components/NoData";
import Pagination from "@/components/Table/Pagination";
import AppLink from "@/components/AppLink";
import InfoError from "@/components/InfoError";
import PayInPlacePoppup from "@/components/PayInPlacePoppup";
import BookFrom from "@/views/Schedule/BookFrom";
import PayWidget from "@/views/Widgets/components/PayWidget";
import PayError from "@/views/Widgets/components/PayError";

export default {
  name: "AppReserves",
  components: {
    //  EditReserveForm,
    BookFrom,
    simplebar,
    SearchBar,
    NoData,
    Pagination,
    AppLink,
    PayInPlacePoppup,
    InfoError,
    PayWidget,
    PayError,
  },
  props: {
    client: {
      type: Boolean,
      default: false,
    },
    resetFilterSearch: {
      type: String,
    },
    rel: Boolean,
    columns: Array,
  },
  data() {
    return {
      isErrorInfo: false,
      linkPay: null,
      isShowPay: false,
      sortBy: {},
      statusOrders: null,
      showEditSidebar: false,
      search: "",
      totalReserves: "",
      mobileDetails: false,
      showParam: 10,
      page: 1,
      showParams: [
        {
          value: 10,
          label: 10,
        },
        {
          value: 20,
          label: 20,
        },
        {
          value: 30,
          label: 30,
        },
        {
          value: 40,
          label: 40,
        },
        {
          value: 50,
          label: 50,
        },
      ],
      reserves: [],
      pagination: {
        total: null,
        current: 1,
      },
      mobileDetailReserve: null,
      editableReserve: null,
      statusesPay: [
        {
          value: 0,
          label: "Ожидает платежа",
          class: "rgba(52, 152, 219, 0.2)",
          border: "rgba(52, 152, 219, 0.5)",
        },
        {
          value: 1,
          label: "Оплачен",
          class: "rgba(53, 199, 112, 0.2)",
          border: "rgba(53, 199, 112, 0.5)",
        },
        {
          value: 2,
          label: "Ошибка при оплате",
          class: "rgba(231, 76, 60, 0.2)",
          border: "rgba(231, 76, 60, 0.5)",
        },
        {
          value: 3,
          label: "Возврат средств",
          class: "rgba(233, 154, 27, 0.2)",
          border: "rgba(233, 154, 27, 0.5)",
        },
      ],
      statuses: [
        {
          value: 1,
          label: "Новая",
          class: "bg-green-500",
        },
        {
          value: 3,
          label: "Завершена",
          class: "bg-blue-100",
        },
        {
          value: 2,
          label: "Подтверждена",
          class: "bg-violet-500",
        },
        {
          value: 0,
          label: "Отменена",
          class: "bg-gray-100",
        },
        {
          value: 5,
          label: "Блокирована",
          class: "bg-red-200",
        },
        {
          value: 4,
          label: "Автоотмена",
          class: "bg-yellow-400",
        },
        {
          value: 6,
          label: "Ожидает оплаты",
          class: "bg-yellow-200",
        },
      ],
      filterStatus: [
        {
          name: "Все",
          value: 0,
        },
        {
          name: "Прошедшие",
          value: 1,
        },
        {
          name: "Предстоящие",
          value: 2,
        },
        {
          name: "Сегодня",
          value: 3,
        },
        {
          name: "Завтра",
          value: 4,
        },
      ],
      exportLinks: [
        {
          link: "/api/v1/booking/export/excel",
          name: "Экспорт в Excel",
          title: "Экспорт заказов в Excel. Максимальное кол-во строк: 1000.",
        },
        {
          link: "/api/v1/booking/export/ics",
          name: "Экспорт в ISC",
          title:
            "Экспорт заказов в формате .ics (подходит для календарей, в том числе для Google Calendar).",
        },
      ],
    };
  },
  computed: {
    ...mapState("widget", ["widgetPublic", "dataPay"]),
    ...mapGetters("homeFilter", {
      filteredReserves: "filteredReserves",
      filteredUrl: "filteredUrl",
    }),
    ...mapState("orders", [
      "orders",
      "orders_stats",
      "isLoading",
      "orders_url",
    ]),

    startListing() {
      if (this.page === 1 || this.page === undefined) return 1;
      return this.page * this.showParam;
    },

    isPaginPage() {
      const curr = this.orders_stats.total / this.showParam;
      return Math.ceil(curr);
    },

    exportExcelUrl() {
      return this.filteredUrl
        ? `${host}/api/reservation/export/excel?${this.filteredUrl}`
        : `${host}/api/reservation/export/excel`;
    },
  },
  watch: {
    rel: {
      handler(val) {
        this.setData();
      },
      deep: true,
    },
  },
  created() {
    const param = {
      paginate: Number(this.$route.query.paginate),
    };
    const pagiNpage = param.paginate || 10;
    this.pagination.current = this.$route.query.page;
    this.createReq({
      page: this.$route.query.page || 1,
      perPage: pagiNpage,
      search: this.$route.query.text || "",
      names: this.$route.query.names || "",
      bron_from: this.$route.query.from || "",
      bron_to: this.$route.query.to || "",
      month: this.$route.query.month || "",
      statuses: this.$route.query.statuses || "",
      payment_status: this.$route.query.payment_status || "",
      filter_source: this.$route.query.filter_source || "",
      service_time: this.$route.query.service_time || "",
      service_from: this.$route.query.service_from || "",
      service_to: this.$route.query.service_to || "",
    });
  },
  methods: {
    ...mapActions("orders", ["getStat"]),
    ...mapActions("widget", ["getSlotDetails"]),
    ...mapActions("service", [
      "getResourceSlots",
      "getSettings",
      // "getScheduleAdminCreate",
    ]),
    ...mapActions("paymentHistory", ["getNewPaymentLink", "getPaymentOnPlace"]),
    ...mapActions({ getScheduleCalendar: "schedule/getScheduleCalendar" }),
    // checkIcon(item) {
    //   const param = item.find((e) => e.hash);
    //   switch (param) {
    //     case 0:
    //       return {
    //         color: param.status_color,
    //       };
    //     case 1:
    //       return {
    //         color: param.status_color,
    //       };

    //     default:
    //       return {
    //         color: param.status_color,
    //       };
    //   }
    // },
    sendEmail(p) {
      this.$emit("show", {
        key: "sendEmail",
        data: p,
      });
    },

    async setParamRouter(row) {
      const query = {
        page: `${this.page}`,
        names: `${row.resource_id}`,
        service_time: `${row.time}`,
        service_from: `${row.date}`,
        service_to: `${row.date}`,
      };
      this.setQueryReserves({ page: this.page, ...query });
      this.$router.push({ query }).catch((err) => {});
      await this.setData();
      this.$router.go();
    },
    changeSortVal(param) {
      switch (param) {
        case "ascending":
          return "asc";
        case "descending":
          return "desc";
        case null:
          return " ";
        default:
      }
    },
    async setSort(q) {
      switch (q.column.label) {
        case "Дата":
          this.sortBy = {
            field: "date",
            direction: this.changeSortVal(q.order), // q.order === "ascending" ? "asc" : "desc",
          };
          break;
        case "Бронирование":
          this.sortBy = {
            field: "start",
            direction: this.changeSortVal(q.order),
          };
          break;
        case "Статус":
          this.sortBy = {
            field: "order_status",
            direction: this.changeSortVal(q.order),
          };
          break;
        case "Оплата":
          this.sortBy = {
            field: "pay_status",
            direction: this.changeSortVal(q.order),
          };
          break;
        default:
      }
      const query = {
        "sort_by[field]": this.sortBy.field,
        "sort_by[direction]": this.sortBy.direction,
      };
      this.setQueryReserves({ page: this.page, ...query });
      await this.setData();
    },

    async closeForm(e) {
      if (e.isShowForm === false) {
        if (e.isShowPay) {
          this.isShowPay = true;
        }
        this.showEditSidebar = false;
        await this.setData();
      }
    },

    openCorrectBooking() {},
    sendLink(payload) {
      this.linkPay = payload;
    },

    async getNewLink(hash) {
      await this.getNewPaymentLink({ hash });
      await this.setData();
    },
    indexMethod(index) {
      // eslint-disable-next-line eqeqeq
      if (this.page == 1 || this.page === undefined) return 1 + index;
      return this.startListing - this.showParam + 1 + index;
    },
    payAtPlace(pay) {
      const payType = pay.find((e) => e.type);
      switch (payType.type) {
        case "full_payment":
          return pay[0]?.paid_on_place;
        case "payment_locally":
          return pay[0]?.full_amount;
        default:
          return pay[0]?.full_amount;
      }
    },
    redirectToLink(link) {
      window.location = link;
    },

    copy(linkPay) {
      navigator.clipboard.writeText(linkPay);
      this.setNotif({ title: `Данные скопированы` });
    },
    convertColor(color) {
      const [r, g, b] = color.split(",");
      return {
        border: `1px solid rgba(${r},${g},${b},${0.5})`,
        background: `rgba(${r},${g},${b},${0.2})`,
      };
    },

    async setPagin(val) {
      this.page = val;
      const obj = {
        "filter[paginate]": this.showParam,
        "filter[show]": this.search,
      };
      const sortBy = {
        field: "date",
        direction: "asc", // или "desc"
      };
      // obj["filter[text]"] = this.search && JSON.stringify(this.search);
      const query = {
        page: `${val}`,
        paginate: `${this.showParam}`,
        names: this.$route.query.names || "",
        bron_from: this.$route.query.from || "",
        bron_to: this.$route.query.to || "",
        month: this.$route.query.month || "",
        statuses: this.$route.query.statuses || "",
        payment_status: this.$route.query.payment_status || "",
        filter_source: this.$route.query.filter_source || "",
        search: this.$route.query.text || "",
        sort_by: {
          field: `${this.sortBy.field}`,
          direction: `${this.sortBy.direction}`,
        },
      };
      this.$router
        .push({
          query,
        })
        .catch((err) => {});
      this.setQueryReserves({ page: this.page, ...obj });
      // this.createReq();
      await this.setData();
    },

    ...mapActions("homeFilter", {
      setFilteredReserves: "setFilteredReserves",
      setFilteredUrl: "setFilteredUrl",
    }),
    ...mapActions("orders", [
      "getReserves",
      "sendChanges",
      "changeStatusOrder",
    ]),
    ...mapMutations(["setNotif"]),
    ...mapMutations("orders", ["setQueryReserves", "setSortBy"]),
    getDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    getTime(time) {
      return moment(time).format("HH:mm");
    },

    async setData() {
      await this.getReserves();
      await this.getStat();
    },
    async createReq(items) {
      this.$emit("getStatParams");

      const { perPage, search, filter } = items;
      // this.page = items;
      this.showParam = perPage;
      this.search = filter;
      this.page = items.page;
      const obj = {
        "filter[paginate]": perPage,
        "filter[show]": filter,
      };
      // obj["filter[text]"] = search && JSON.stringify(search);
      obj["filter[text]"] = search;
      obj["filter[names]"] = items.names;
      obj["filter[bron_from]"] = items.bron_from;
      obj["filter[bron_to]"] = items.bron_to;
      obj["filter[bron_month]"] = items.month;
      obj["filter[statuses]"] = items.statuses;
      obj["filter[payment_status]"] = items.payment_status;
      obj["filter[source]"] = items.filter_source;
      obj["filter[service_time]"] = items.service_time;
      obj["filter[service_from]"] = items.service_from;
      obj["filter[service_to]"] = items.service_to;

      const query = {
        page: `${this.page}`,
        paginate: `${perPage}`,
        text: `${obj["filter[text]"]}`,
        names: `${obj["filter[names]"]}`,
        bron_from: `${obj["filter[bron_from]"]}`,
        bron_to: `${obj["filter[bron_to]"]}`,
        month: `${obj["filter[bron_month]"]}`,
        statuses: `${obj["filter[statuses]"]}`,
        payment_status: `${obj["filter[payment_status]"]}`,
        filter_source: `${obj["filter[source]"]}`,
        service_time: `${obj["filter[service_time]"]}`,
        service_from: `${obj["filter[service_from]"]}`,
        service_to: `${obj["filter[service_to]"]}`,
      };
      this.$router
        .push({
          query,
        })
        .catch((err) => {});

      this.setQueryReserves({ page: this.page, ...obj });

      this.setData();
    },

    async changeStat(reserve, statusId) {
      await this.changeStatusOrder({
        hash: reserve.id,
        status_id: statusId.id,
      });
      await this.getStat();
      await this.getReserves();
    },
    changeStatus(reserve, statusId) {
      const data = {
        date: reserve.date,
        status: reserve.status_id,
        schedule_id: reserve.schedule_id,
        reservation_id: reserve.id,
        first_name: reserve.client_info.first_name,
        last_name: reserve.client_info.last_name,
        phone: reserve.client_tel,
        email: reserve.client_info.email,
        clientComments: reserve.comments.client[0].comment,
        moderComments: reserve.client_info.moder_comment,
      };

      this.sendChanges(data);
    },
    statusClass(status) {
      return this.statuses.find((item) => item.value === status).class;
    },

    openMobileDetails(reserveIndex, reserve) {
      // this.mobileDetails = true;
      // this.mobileDetailReserve = null;
      // this.mobileDetailReserve = this.orders[reserveIndex];
    },
    async onEditReserve(reserve) {
      await this.getSettings({
        hash: reserve.resource_id,
        id: reserve.schedule_id,
      });
      await this.getScheduleCalendar({
        hash: reserve.resource_id,
        withSlots: true,
      });
      await this.getSlotDetails({
        id: reserve.resource_id,
        date: reserve.date,
        time: reserve.time,
        bookingHash: reserve.id,
      });
      this.showEditSidebar = true;
      this.editableReserve = {
        ...reserve,
        hash: reserve.resource_id,
        nameService: reserve.resource_name,
        currency: reserve.currency,
        item: {
          booking_hash: reserve.id,
          schedule_id: reserve.schedule_id,
          time: reserve.time,
          hash: reserve.resource_id,
          is_holiday: reserve.is_holiday,
          price: reserve.book_info.price,
          statusColor: reserve,
          price_additional: 0,
          reservation: [
            {
              client_info: {
                name: reserve.client_name,
                email: reserve.client_email,
                tel: reserve.client_tel,
              },
              date: reserve.date,
              duration: reserve.consist.rent?.duration,
              book_info: reserve.book_info,
              ordersource_data: { name: reserve.ordersource_data[0]?.value },
            },
          ],
        },
        status: "update",
        payment_type: reserve.payment[0],
      };
    },
    saveChanges(data) {
      const reserve = this.orders.find((qwerty) => {
        return qwerty.id === data.reservation_id;
      });
      if (reserve) {
        reserve.date = data.date;
        reserve.time = data.time;
        reserve.status_id = data.status;
        reserve.client_info = { ...data };
      }

      this.sendChanges(data);
    },
    async getStats() {
      await this.getStat();
    },
  },
};
</script>

<style lang="scss">
.item {
  &__id {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    line-height: 24px;
  }
}
.reserve-edit-wrapper {
  .save-button {
    bottom: 20px;
  }
}

.reserves {
  &-search {
    width: 100%;
    max-width: 320px;

    .el-input--prefix {
      .el-input__inner {
        padding-left: 45px;

        &:focus,
        &:active {
          + .el-input__prefix {
            .svg-icon {
              color: black;
            }
          }
        }
      }
    }

    .el-input__prefix {
      display: flex;
      align-items: center;
      left: 15px;
    }

    @screen large {
      flex-basis: 50%;
      max-width: 100%;
    }
  }
}
.mobile-reserves-stat {
  display: grid;
  grid-template-columns: 140px 1fr;
  grid-gap: 25px;
  align-items: center;
  grid-template-rows: auto;

  &_client {
    grid-template-columns: 65px 1fr;
  }

  &__origin {
    text-overflow: ellipsis;
  }

  &_offer {
    grid-template-columns: 145px 1fr;
  }
}
.button {
  &_icon {
    max-height: 30px;
    padding: 0 12px;
    align-items: center;
    span {
      align-items: center;
      display: flex;
    }
  }
}
</style>

<style lang="scss" scoped>
.statusPay {
  display: flex;
  justify-content: center;
  height: 48px;
  line-height: 10px;
  margin: 10px 0px;
  padding: 16px;
}
.small_text {
  width: 120px;
  font-size: 14px;
  line-height: 24px;
  margin-right: 5px;

  &.w-auto {
    width: auto !important;
  }
}

.table__icon {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
::v-deep .el-table td {
  vertical-align: top;
}
::v-deep .select {
  &_status {
    .el-input__inner {
      @apply px-45;
    }
  }
}

.block {
  border-radius: 3px;
  padding: 16px;
  background: #000;
  &__btn {
    margin-top: 15px;
    height: 30px;
    border: 1px solid rgba(255, 190, 61, 1);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    min-width: 218px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__text {
    font-size: 14px;
    line-height: 24px;
    color: white;
    margin-bottom: 8px;
  }
}
.dashed {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
}
.del-resource {
  color: rgba(231, 76, 60, 1);
  border-bottom: transparent;
}
.preloader__wrap {
  background: rgba(255, 255, 255, 0.1);
  position: absolute;
  top: 1vh; // 40vh;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  z-index: 9999;
}
.services__item-name {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid rgba(255, 255, 255, 0.35);
  cursor: pointer;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.7);
  // color: $--color-white;
  flex-grow: 1;
  // margin: auto 15px;
}
.mobile {
  font-size: 14px;
  line-height: 24px;
  min-height: 34px;

  span {
    border: none;
  }
}
.services-drag__item-icon.mobile {
  padding: 0px 10px;
}
</style>
