<template>
  <Block title="Источники заказа">
    <div class="text-18 leading-28 font-semibold text-white-normal mb-30">
      Предустановленные источники
    </div>
    <div class="source relative">
      <spinner v-if="loading.table1" class="table__spinner" />
      <el-row class="w-full flex-wrap" type="flex">
        <el-col :span="$mq.tablet ? 24 : 12" class="lg:mb-20 source__items">
          <el-table :data="sourceItemsTab1" :stripe="true">
            <el-table-column prop="name" label="Название" min-width="220">
            </el-table-column>
            <el-table-column prop="domen" label="Домен" min-width="110">
            </el-table-column>
            <el-table-column label="Комиссия" min-width="90">
              <div slot-scope="{ row }" class="text-center">
                {{ row.commission }}%
              </div>
            </el-table-column>
          </el-table>
        </el-col>

        <el-col :span="$mq.tablet ? 24 : 12" class="source__items">
          <el-table :data="sourceItemsTab2" :stripe="true">
            <el-table-column prop="name" label="Название" min-width="220">
            </el-table-column>
            <el-table-column prop="domen" label="Домен" min-width="110">
            </el-table-column>
            <el-table-column label="Комиссия" min-width="90">
              <div slot-scope="{ row }" class="text-center">
                {{ row.commission }}%
              </div>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </div>
    <div class="text-18 leading-28 font-semibold text-white-normal mb-20">
      Добавленные источники
    </div>
    <div class="relative mb-30">
      <spinner v-if="loading.table2" class="table__spinner" />
      <el-table
        :data="items"
        style="max-width: 1140px"
        class="border-b-0"
        sortable
        :stripe="true"
      >
        <el-table-column prop="name" label="Название" sortable>
        </el-table-column>
        <el-table-column prop="domen" sortable label="Домен"> </el-table-column>
        <el-table-column sortable prop="commission" label="Комиссия"
          ><div slot-scope="{ row }">
            {{ `${row.commission}%` }}
          </div>
        </el-table-column>
        <el-table-column :width="60">
          <div slot-scope="{ row }" class="table__options">
            <el-popover
              placement="bottom-end"
              popper-class="control-poper"
              trigger="click"
            >
              <span slot="reference" class="services-drag__item-handle">
                <svg-icon
                  icon-name="dots"
                  class-name="text-white-500"
                  width="20"
                  height="4"
                ></svg-icon>
              </span>
              <div>
                <div class="actions-item" @click="editService(row)">
                  <svg-icon icon-name="edit" width="16" height="18"></svg-icon>
                  Редактировать
                </div>

                <div class="actions-item" @click="deleteSource(row.id)">
                  <svg-icon icon-name="close" width="16" height="12"></svg-icon>
                  Удалить
                </div>
              </div>
            </el-popover>
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div>
      <div class="text-18 leading-28 font-semibold text-white-normal mb-20">
        Добавить источник
      </div>
      <el-form
        ref="addSource"
        :model="item"
        :rules="rules"
        :hide-required-asterisk="true"
        label-position="top"
        class="source"
      >
        <el-row
          :gutter="!$mq.tablet ? 30 : 0"
          type="flex"
          class="source__inputs flex-wrap"
        >
          <el-col :span="$mq.tablet ? 24 : 8">
            <el-form-item class="mb-0 lg:mb-15" prop="name">
              <span slot="label">Название<span class="danger">*</span></span>
              <el-input
                v-model="item.name"
                placeholder="Введите название источника"
                type="text"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="$mq.tablet ? 24 : 8">
            <el-form-item class="mb-0 lg:mb-15" prop="domen">
              <span slot="label">Домен</span>
              <el-input
                v-model="item.domen"
                placeholder="Введите домен"
                type="text"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="$mq.tablet ? 24 : 8">
            <el-form-item class="mb-0" prop="commission">
              <span slot="label">Комиссия<span class="danger">*</span></span>
              <el-input
                v-model.number="item.commission"
                placeholder="Введите комиссию"
                type="number"
              >
                <template slot="append"
                  ><span class="text-white-500 pr-15">%</span></template
                ></el-input
              >
            </el-form-item>
          </el-col>
        </el-row>
        <el-button
          type="primary"
          :loading="loading.button"
          class="source__btn bg-transparent lg:w-full"
          @click="createSource"
          >Добавить
        </el-button>
      </el-form>
    </div>
    <el-drawer
      :show-close="false"
      :visible.sync="showEdit"
      direction="rtl"
      size="340px"
    >
      <div slot="title" class="bg-violet-900 pt-10 px-20 pb-23">
        <div class="flex items-center justify-between">
          <div class="font-semibold text-18 leading-30 text-white-normal">
            Редактирование источника
          </div>
          <div class="cursor-pointer" @click="showEdit = false">
            <svg-icon
              icon-name="close"
              width="12"
              height="12"
              class-name="text-white-normal"
            ></svg-icon>
          </div>
        </div>
      </div>
      <EditSource :item="editItem" @update="updateSource"></EditSource>
    </el-drawer>
  </Block>
</template>

<script>
import Block from "./Block";
import { AJAX_POST } from "@/units/ajax";
import EditSource from "./EditSource";
import { mapActions, mapMutations } from "vuex";

export default {
  components: {
    Block,
    EditSource,
  },
  data() {
    return {
      showEdit: false,
      editItem: null,
      min: "5",
      loading: {
        table1: true,
        table2: true,
        button: false,
      },
      rules: {
        name: [
          {
            required: true,
            message: "Введите название источника",
            trigger: ["change", "blur"],
          },
        ],
        domen: [
          {
            required: false,
            message: "Введите домен",
            trigger: ["change", "blur"],
          },
        ],
        commission: [
          {
            required: true,
            message: "Введите комиссию",
            trigger: ["change", "blur"],
          },
        ],
      },
      items: [],
      item: {
        name: "",
        domen: "",
        commission: 0,
      },
      sourceItemsTab1: null,
      sourceItemsTab2: null,
    };
  },
  watch: {
    item: {
      handler(val) {
        this.item.commission = Math.max(0, val.commission);
      },
      deep: true,
    },
  },

  async mounted() {
    await this.getIntegration();
    await this.getSourceTable1();
    await this.getSourceTable2();
    await this.getAllTextRate();
    await this.getAllTaxSystem();
  },
  methods: {
    ...mapMutations(["setNotif"]),

    ...mapActions("user", [
      "getAllTextRate",
      "getAllTaxSystem",
      "getIntegration",
    ]),

    editService(item) {
      this.showEdit = true;
      this.editItem = item;
    },

    async getSourceTable1() {
      this.loading.table1 = true;
      await AJAX_POST("/api/defaultordersource/get/all", {
        noCancel: true,
      }).then((res) => {
        const arr = res.data;
        const half = Math.trunc(res.data.length / 2);
        this.sourceItemsTab1 = arr.slice(0, half);
        this.sourceItemsTab2 = arr.slice(half);
        this.loading.table1 = false;
      });
    },
    async getSourceTable2() {
      if (!this.loading.table2) this.loading.table2 = true;
      await AJAX_POST("/api/ordersource/get/all", { noCancel: true }).then(
        (res) => {
          this.items = res.data;
          this.loading.table2 = false;
        }
      );
    },
    createSource() {
      this.$refs.addSource.validate(async (valid) => {
        if (valid) {
          this.loading.button = true;
          AJAX_POST("/api/ordersource/add", this.item).then((res) => {
            this.$notify({
              title: res.data.msg || `Источник добавлен`,
              type: res.data.success ? "success" : "error",
              showClose: false,
              duration: 3000,
            });

            // await AJAX_POST("/api/ordersource/add", this.item).then((res) => {
            // if (res.data.success) {
            //   this.setNotif
            // }
            // this.$notify({
            //   title: res.data.msg || `Источник добавлен`,
            //   type: res.status === 200 ? "success" : "error", // res.data.success ? "success" : "error",
            //   showClose: false,
            //   duration: 3000,
            // });
            this.loading.button = false;
            this.$refs.addSource.resetFields();
            this.getSourceTable2();
          });
        }
      });
    },
    updateSource(el) {
      this.showEdit = false;
      this.editItem = {};
      this.loading.table2 = true;
      AJAX_POST("/api/ordersource/update", el).then((res) => {
        this.$notify({
          title: res.data.msg || `Источник обновлён`,
          type: res.data.success ? "success" : "error",
          showClose: false,
          duration: 3000,
        });
        this.getSourceTable2();
      });
    },
    deleteSource(id) {
      this.$confirm("", "Удалить источник?", {
        confirmButtonText: "Удалить",
        cancelButtonText: "Отмена",
        type: "warning",
      }).then(() => {
        this.loading.table2 = true;
        AJAX_POST("/api/ordersource/delete", { id }).then((res) => {
          this.$notify({
            title: res.data.msg || `Источник удален`,
            type: res.data.success ? "success" : "error",
            showClose: false,
            duration: 3000,
          });
          this.getSourceTable2();
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .section_content {
  flex-direction: column;
}
.settings__section {
  margin-bottom: 0;
}
.source {
  display: flex;
  justify-content: space-between;
  &__inputs {
    width: calc(100% - 111px);
  }
  &__btn {
    // margin-left: 30px;
    margin-top: auto;
  }
  &__items {
    width: calc(50% - 20px);
    margin-bottom: 50px;
    &:first-of-type {
      margin-right: 40px;
    }
  }
  &__item {
    width: 100%;
    padding: 10px 15px;
    &:nth-of-type(2n) {
      background: rgba(255, 255, 255, 0.04);
    }
  }
}
.table__options {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 1024px) {
  .source {
    flex-direction: column;
    &__items {
      width: 100%;
      &:first-of-type {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
    &__inputs {
      width: 100%;
      margin-bottom: 20px;
    }
    &__btn {
      width: 111px;
    }
  }
}
::v-deep .el-notification {
  background: rgba(53, 199, 112, 0.2) !important;
}
</style>
