<template>
  <!-- <div class="wrapper md:px-16"> -->
  <div class="pt-40 pb-80 w-full lg:pt-15 px-10">
    <h1
      class="font-semibold text-white-normal text-28 leading-44 lg:text-22 lg:leading22 mb-30 xs:mb-5"
    >
      Дашборд
    </h1>
    <spinner v-if="isLoading" />
    <div v-else class="wrapper__content">
      <div
        v-if="isShowProgress && !isFastartCompleat"
        class="progress custom-block"
      >
        <div v-if="!isFastartCompleat" class="progress-header">
          <h4>Быстрый старт</h4>
          <ButtonIcon
            icon-name="close"
            class="close"
            :width="$mq.tablet ? '12' : '16'"
            @click="hiddenProgress"
          />
        </div>
        <p class="hint">
          Добро пожаловать! Заполните все необходимые поля для максимально
          эффективного использования платформы
        </p>
        <el-steps
          :active="dashboardStep - 1"
          process-status="process"
          finish-status="success"
          :direction="$mq.tablet ? 'vertical' : 'horizontal'"
        >
          <el-step
            v-for="(step, index) in allSteps"
            :key="index"
            :title="step.value"
            :class="[
              { seans: [1, 2, 3].includes(index) },
              { rezult: index === 4 },
            ]"
          >
          </el-step>
        </el-steps>
        <div v-if="dashboardStep !== 5" class="flex mt-20 justify-between">
          <div
            v-for="(step, index) in allSteps"
            :key="index"
            class="flex"
            :class="[
              { seans: [1, 2, 3].includes(index) },
              { rezult: index === 4 },
            ]"
          >
            <el-button
              v-if="index === dashboardStep - 1"
              type="primary"
              @click="toPage"
              >Выполнить</el-button
            >
          </div>
          <!-- <el-button type="primary" @click="toPage">Выполнить</el-button> -->
        </div>
      </div>
      <div class="custom-block company">
        <img
          v-if="company && company.company_logo"
          :src="company.company_logo"
          class="company__img"
        />
        <div v-else class="company__img-stub">
          Нет <br />
          логотипа
        </div>
        <div class="company__info">
          <p class="company__info-header">
            {{
              company && company.company_name
                ? company.company_name
                : "Название компании"
            }}
          </p>
          <p class="company__info-text">
            {{
              company && company.kind_of_activity[0]
                ? company.kind_of_activity[0].name
                : "Категория компании"
            }}
          </p>
          <div class="company__info-tags">
            <el-button
              v-if="!company"
              type="primary"
              class="button-edit"
              @click="toCompany"
            >
              Добавить компанию
            </el-button>
            <el-button @click="$router.push({ name: 'services' })">
              Услуги
              <span
                class="company__info-tags-total"
                :class="{
                  'company__info-tags-total_no-data': !user.resources_count,
                }"
                >{{ user.resources_count }}</span
              >
            </el-button>
            <el-button @click="$router.push({ name: 'widgets' })">
              Виджеты
              <span
                class="company__info-tags-total"
                :class="{
                  'company__info-tags-total_no-data': !user.widgets_count,
                }"
                >{{ user.widgets_count }}</span
              >
            </el-button>
            <!-- <el-button> Мини-сайт </el-button> -->
            <template v-if="company && company.company_name">
              <el-button
                v-if="$mq.tablet"
                class="button-edit"
                @click="toCompany"
              >
                <svg-icon icon-name="edit" width="16" class="mr-12"></svg-icon>
                Редактировать
              </el-button>
              <ButtonIcon
                v-else
                icon-name="edit"
                width="16"
                @click="toCompany"
              />
            </template>
          </div>
        </div>
      </div>
      <Cards
        @showModalBalanse="isShowModalBalanse = true"
        @showModalSms="isShowModalSms = true"
      />
    </div>

    <el-dialog
      :visible.sync="isShowModalBalanse"
      append-to-body
      :show-close="false"
      custom-class="max-w-md w-full filling-balance-popup"
    >
      <div
        slot="title"
        class="absolute fast-add-close"
        @click="isShowModalBalanse = false"
      >
        <div class="cursor-pointer">
          <svg-icon
            icon-name="close"
            width="12"
            height="12"
            class-name="text-white-normal"
          ></svg-icon>
        </div>
      </div>
      <filling-balance modal></filling-balance>
    </el-dialog>
    <el-dialog
      :visible.sync="isShowModalSms"
      append-to-body
      :show-close="false"
      custom-class="max-w-md w-full filling-balance-popup"
    >
      <div
        slot="title"
        class="absolute fast-add-close"
        @click="isShowModalSms = false"
      >
        <div class="cursor-pointer">
          <svg-icon
            icon-name="close"
            width="12"
            height="12"
            class-name="text-white-normal"
          ></svg-icon>
        </div>
      </div>
      <FillingSms modal></FillingSms>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapActions, mapMutations } from "vuex";
import FillingBalance from "@/components/FillingBalance";
import FillingSms from "@/components/FillingSms";
import Cards from "@/components/Dashboard/Cards.vue";

export default {
  name: "Dashboard",
  components: {
    FillingBalance,
    FillingSms,
    Cards,
  },
  data() {
    return {
      allSteps: null,
      activeStep: 0,
      isShowProgress: false,
      isShowModalBalanse: false,
      isShowModalSms: false,
      isLoading: true,
    };
  },
  computed: {
    ...mapState("user", [
      "user",
      "servicesList",
      "dashboardStep",
      "minBalanceNotify",
      "hiddenProgressBlock",
      "showProgressBar",
    ]),

    company() {
      return this.user.company;
    },
    isFastartCompleat() {
      return this.user.fast_start_completed;
    },
  },
  beforeCreate() {
    this.always = 10;
  },
  async created() {
    const obj = { "filter[paginate]": 1, "filter[show]": null };
    obj["filter[text]"] = null;
    obj["filter[names]"] = null;
    obj["filter[bron_from]"] = null;
    obj["filter[bron_to]"] = null;
    obj["filter[bron_month]"] = null;
    this.setQueryReserves({ page: 1, ...obj });
    await this.getDasboardStep();
    await this.getSettings();
    await this.getIntegration();
    await this.getQStatistic();
    this.showProgress();
    this.isShowProgress = this.showProgressBar;
    this.always = 11;
    this.activeStep = this.dashboardStep;
  },
  methods: {
    ...mapActions("settings", ["getSettings"]),
    ...mapActions("orders", ["getStat", "getQStatistic"]),
    ...mapActions("user", [
      "getDasboardStep",
      "getMinBalance",
      "setMinBalance",
      "closeProgressBlock",
      "getIntegration",
    ]),
    ...mapMutations("orders", ["setQueryReserves"]),

    async hiddenProgress() {
      this.isShowProgress = false;
      await this.closeProgressBlock();
    },
    showProgress() {
      if (this.hiddenProgressBlock) {
        this.allSteps = [
          {
            id: 1,
            value: "Добавить компанию",
            routerName: "company",
          },
          {
            id: 2,
            value: "Добавить услуги",
            routerName: "create-service",
          },
          {
            id: 3,
            value: "Настроить даты и цены",
            routerName: "create-service",
          },
          {
            id: 4,
            value: "Разместить виджеты",
            // routerName: "create-service",
            routerName: "widgets",
          },
          {
            id: 5,
            value: "Принимайте заказы",
            routerName: "create-service",
          },
        ];
        this.isShowProgress = true;
      }
      if (this.isLoading) this.isLoading = false;
    },
    toPage() {
      const name = this.allSteps.find((step) => step.id === this.activeStep)
        .routerName;
      this.$router.push({ name });
    },
    toCompany() {
      this.$router.push({ name: "company" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.seans ::v-deep .el-step {
  &__title {
    text-align: center;
  }
  &__main {
    transform: translateX(-44%);
  }
}

.rezult ::v-deep .el-step__main {
  position: absolute;
  width: max-content;
  transform: translateX(-75%);
}

.progress {
  border: 2px solid $--color-light-white;
  padding: 20px 30px 30px;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      color: $--color-white;
      font-weight: 600;
      font-size: 22px;
      line-height: 36px;
    }
  }
  .hint {
    color: $--color-white-5;
    margin-bottom: 30px;
  }
  ::v-deep .el-steps {
    margin-bottom: 12px;
  }
  &-action {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}

.company {
  display: flex;
  flex-direction: row;
  padding: 30px;
  &__img-stub,
  &__img {
    width: 120px;
    height: 120px;
    border: 2px solid #ffffff;
    box-sizing: border-box;
    border-radius: 10px;
    margin-right: 30px;
    object-fit: contain;
  }
  &__img-stub {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $--color-white-35;
  }
  &__info {
    display: flex;
    flex-direction: column;

    &-header {
      font-weight: 600;
      font-size: 22px;
      line-height: 36px;
      margin-bottom: 5px;
      color: $--color-white;
    }

    &-text {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $--color-white-5;
      margin-bottom: 12px;
    }

    &-tags {
      display: flex;
      ::v-deep .el-button {
        margin-right: 12px;
      }
      &-total {
        background: #3498db;
        color: $--color-white;
        border-radius: 9px;
        padding: 2px 7px;
        font-weight: bold;
        font-size: 10px;
        line-height: 12px;
        margin-left: 10px;
        min-width: 21px;
        &_no-data {
          background: $--color-white-15;
        }
      }
    }
  }
}
.gold {
  color: #ffbe3d;
}

.button-edit ::v-deep span {
  display: flex;
  align-items: center;
  justify-content: center;
}
.button {
  &-edit ::v-deep span {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-add,
  &-edit {
    height: 40px;
  }
}

@media (max-width: 1380px) and (min-width: 1025px) {
  ::v-deep .el-step {
    &__main {
      max-width: 120px;
      transform: translateX(-33%) !important;
      margin-top: 10px;
      .el-step__title {
        line-height: 24px;
      }
    }
    &:last-of-type {
      .el-step__main {
        text-align: right;
        transform: translateX(-66%) !important;
      }
    }
    &:first-of-type {
      .el-step__main {
        transform: translate(0) !important;
      }
    }
  }
}
@screen lg {
  .seans ::v-deep .el-step {
    &__title {
      text-align: start;
    }
    &__main {
      transform: none;
    }
  }

  .rezult ::v-deep .el-step__main {
    position: relative;
    width: none;
    transform: none;
  }

  ::v-deep .el-step {
    &__head {
      padding: 0 0 20px;
    }
    &__main {
      display: flex;
      align-items: center;
      padding-left: 40px !important;
      padding-bottom: 20px !important;
    }
    &__title {
      padding-bottom: 0px !important;
    }
    &.is-vertical .el-step__line {
      left: 19px;
    }
  }
  .progress {
    border: none;
    padding: 20px 15px 15px;

    .close {
      width: 30px;
      height: 30px;
    }
  }

  .company {
    flex-direction: column;
    padding: 15px;
    &__img-stub,
    &__img {
      margin-bottom: 12px;
    }

    &__info {
      &-header {
        font-size: 18px;
      }
      &-text {
        font-size: 14px;
      }

      &-tags {
        flex-direction: column;
        ::v-deep .el-button {
          margin-right: 0;
          margin-bottom: 12px;
          margin-left: 0;
        }
      }
    }
  }
}
</style>
