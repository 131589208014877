<template>
  <label
    class="text-14 leading-28 font-medium text-white-normal flex flex-col lg:mr-0 lg:w-full"
    :class="{ input_disable: typeText === 0 }"
  >
    <span class="mb-10">Тема письма</span>
    <span class="flex relative">
      <div class="overlap"></div>
      <el-input
        v-model="item"
        :disabled="typeText === 0"
        placeholder="Введите тему"
      ></el-input>
    </span>
  </label>
</template>

<script>
export default {
  props: {
    value: String,
    typeText: Number,
    defaultParam: String,
  },
  computed: {
    item: {
      get() {
        return this.value;
      },
      set(e) {
        this.$emit("input", e);
      },
    },
  },
  watch: {
    typeText() {
      if (this.typeText === 0) {
        this.item = this.defaultParam;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "element-variables.scss";

::v-deep .el-input__inner {
  &:hover,
  &:focus,
  &:active {
    background: $--color-light-white;
    color: white;
  }
}
.input_disable {
  ::v-deep .el-input__inner {
    color: $--color-white-5 !important;
  }
}
</style>
