<template>
  <el-col v-if="user && admin" :span="24" class="flex flex-col">
    <template v-if="!$mq.phone && !isModal">
      <el-row type="flex" class="section mb-40">
        <Header type="user" :subtitle="subtitle" class="section__item" />
        <Header
          type="admin"
          :subtitle="subtitle"
          class="section__item section__mobile"
        />
      </el-row>
      <el-row type="flex" class="section">
        <RadioGroup
          v-model="user.is_custom"
          class="section__item"
          @input="setText"
        />
        <RadioGroup
          v-model="admin.is_custom"
          class="section__item section__mobile"
          @input="setText"
        />
      </el-row>
      <el-row type="flex" class="section">
        <Thread
          v-model="user.thread"
          :type-text="user.is_custom"
          :default-param="defaultItemsUser.thread"
          class="section__item"
        />
        <Thread
          v-model="admin.thread"
          :type-text="admin.is_custom"
          :default-param="defaultItemsAdmin.thread"
          class="section__item section__mobile"
        />
      </el-row>
      <el-row type="flex" class="section">
        <Email
          v-model="user.email"
          :type-text="user.is_custom"
          :default-param="defaultItemsUser.email"
          class="section__item"
        />
        <Email
          v-model="admin.email"
          :type-text="admin.is_custom"
          :default-param="defaultItemsAdmin.email"
          class="section__item section__mobile"
        />
      </el-row>
      <el-row type="flex" class="section">
        <SendSwitch
          v-model="user.is_send"
          text="Отправлять Email клиенту"
          class="section__item"
        />
        <SendSwitch
          v-model="admin.is_send"
          text="Отправлять Email сотрудникам"
          class="section__item section__mobile"
        />
      </el-row>
      <el-row type="flex" class="section">
        <RadioGroup
          v-model="user.is_custom_sms"
          :text="{
            first: 'Использовать стандартный шаблон',
            second: 'Задать свой текст',
          }"
          class="section__item"
          @input="setText"
        />
        <RadioGroup
          v-model="admin.is_custom_sms"
          :text="{
            first: 'Использовать стандартный шаблон',
            second: 'Задать свой текст',
          }"
          class="section__item"
          @input="setText"
        />
      </el-row>

      <el-row type="flex" class="section">
        <Sms
          v-model="user.sms"
          :type-text="user.is_custom_sms"
          :default-param="defaultItemsUser.sms"
          class="section__item"
        />
        <Sms
          v-model="admin.sms"
          :type-text="admin.is_custom_sms"
          :default-param="defaultItemsAdmin.sms"
          class="section__item section__mobile"
        />
      </el-row>
      <el-row type="flex" class="section">
        <SendSwitch
          v-model="user.is_send_sms"
          text="Отправлять SMS клиенту"
          class="section__item"
        />

        <SendSwitch
          v-model="admin.is_send_sms"
          text="Отправлять SMS сотрудникам"
          class="section__item section__mobile"
        />
      </el-row>

      <el-row type="flex" class="section">
        <div class="section__item">
          <el-button
            type="primary"
            :loading="loadingUser"
            class="section__btn"
            @click="save('user')"
            >Сохранить</el-button
          >
        </div>
        <div class="section__item">
          <el-button
            type="primary"
            :loading="loadingAdmin"
            class="section__item section__btn section__mobile"
            @click="save('admin')"
            >Сохранить</el-button
          >
        </div>
      </el-row>
    </template>

    <template v-if="$mq.phone && !isModal">
      <el-row type="flex" class="section mb-40">
        <Header type="user" :subtitle="subtitle" class="section__item" />
      </el-row>

      <el-row type="flex" class="section">
        <RadioGroup
          v-model="user.is_custom"
          class="section__item"
          @input="setText"
        />
      </el-row>
      <el-row type="flex" class="section">
        <Thread
          v-model="user.thread"
          :type-text="user.is_custom"
          :default-param="defaultItemsUser.thread"
          class="section__item"
        />
      </el-row>
      <el-row type="flex" class="section">
        <Email
          v-model="user.email"
          :type-text="user.is_custom"
          class="section__item"
        />
      </el-row>
      <el-row type="flex" class="section">
        <SendSwitch
          v-model="user.is_send"
          text="Отправлять Email клиенту"
          class="section__item"
        />
      </el-row>

      <el-row type="flex" class="section">
        <Sms
          v-model="user.sms"
          :type-text="user.is_custom"
          class="section__item"
        />
      </el-row>

      <el-row type="flex" class="section">
        <div class="section__item">
          <el-button
            type="primary"
            :loading="loadingUser"
            class="section__btn"
            @click="save('user')"
            >Сохранить</el-button
          >
        </div>
      </el-row>

      <el-row type="flex" class="section mb-40">
        <Header type="admin" :subtitle="subtitle" class="section__item" />
      </el-row>
      <el-row type="flex" class="section">
        <RadioGroup
          v-model="admin.is_custom"
          class="section__item"
          @input="setText"
        />
      </el-row>
      <el-row type="flex" class="section">
        <Thread
          v-model="admin.thread"
          :type-text="admin.is_custom"
          :default-param="defaultItemsAdmin.thread"
          class="section__item"
        />
      </el-row>
      <el-row type="flex" class="section">
        <Email
          v-model="admin.email"
          :type-text="admin.is_custom"
          class="section__item"
        />
      </el-row>
      <el-row type="flex" class="section">
        <SendSwitch
          v-model="admin.is_send"
          text="Отправлять Email клиенту"
          class="section__item"
        />
      </el-row>

      <el-row type="flex" class="section">
        <Sms
          v-model="admin.sms"
          :type-text="admin.is_custom"
          class="section__item"
        />
      </el-row>

      <el-row type="flex" class="section">
        <div class="section__item">
          <el-button
            type="primary"
            :loading="loadingUser"
            class="section__btn"
            @click="save('admin')"
            >Сохранить</el-button
          >
        </div>
      </el-row>
    </template>
    <template v-if="isModal">
      <el-row type="flex" class="section modal">
        <RadioGroup
          v-model="modal.is_custom"
          :text="{
            first: 'Использовать стандартный',
            second: 'Задать свой текст',
          }"
          class="section__item"
          @input="setModalText"
        />
      </el-row>
      <el-row class="section modal">
        <Email
          v-model="modal.modal_text_client"
          text="Текст в модальном окне"
          :type-text="modal.is_custom"
          class="section__item"
        />
      </el-row>
      <el-row type="flex" class="section">
        <div class="section__item">
          <el-button
            type="primary"
            :loading="loadingUser"
            class="section__btn"
            @click="save('modal')"
            >Сохранить</el-button
          >
        </div>
      </el-row>
    </template>
  </el-col>
</template>

<script>
import { mapState } from "vuex";
import RadioGroup from "@/components/Notifications/Booking/RadioGroup";
import Header from "@/components/Notifications/Booking/Header";
import Thread from "@/components/Notifications/Booking/Thread";
import Email from "@/components/Notifications/Booking/Email";
import SendSwitch from "@/components/Notifications/Booking/SendSwitch";
import Sms from "@/components/Notifications/Booking/Sms";

export default {
  components: {
    RadioGroup,
    Header,
    Thread,
    Email,
    SendSwitch,
    Sms,
  },
  props: {
    subtitle: String,
    modalSection: Boolean,
    itemsAdmin: Object,
    itemsUser: Object,
    defaultItemsAdmin: Object,
    defaultItemsUser: Object,
    loadingAdmin: Boolean,
    loadingUser: Boolean,
    isModal: Boolean,
    type: String,
  },
  data() {
    return {
      admin: {
        email: null,
        is_custom: false,
        is_custom_sms: false,
        is_send: null,
        is_send_sms: false,
        sms: null,
        thread: null,
      },
      user: {
        email: null,
        is_custom: false,
        is_custom_sms: false,
        is_send: null,
        is_send_sms: false,
        sms: null,
        thread: null,
      },
      modal: {
        is_custom: null,
        modal_text_client: null,
      },
      switcher: false,
      textTypes: ["sms", "thread", "email"],
    };
  },
  computed: {
    ...mapState("notifications", ["info", "defaultInfo"]),
  },

  created() {
    if (this.isModal) {
      this.setModalText("created");
    } else {
      this.setText("created");
    }
  },
  methods: {
    setModalText(hook) {
      const created = hook === "created";
      const modal = created
        ? this.itemsUser || this.defaultItemsUser
        : this.modal;

      if (this.itemsUser && this.defaultItemsUser) {
        if (modal && modal.is_custom === 1) {
          if (created) this.modal.is_custom = this.itemsUser.is_custom;
          this.modal.modal_text_client = this.itemsUser.modal_text_client;
        } else {
          if (created) this.modal.is_custom = this.defaultItemsUser.is_custom;
          this.modal.modal_text_client = this.defaultItemsUser.modal_text_client;
        }
      }

      // if (this.itemsUser && this.defaultItemsUser) {
      //   if (modal && modal.is_custom === 1) {
      //     if (created) {
      //       this.modal.is_custom = this.itemsUser.is_custom;
      //       this.modal.modal_text_client = this.itemsUser.modal_text_client;
      //     } else {
      //       this.modal = {};
      //       this.modal.is_custom = Number(this.itemsUser.is_custom);
      //       this.modal.modal_text_client = this.itemsUser.modal_text_client;
      //     }
      //   } else if (created) {
      //     this.modal.is_custom = this.defaultItemsUser.is_custom;
      //     this.modal.modal_text_client = this.defaultItemsUser.modal_text_client;
      //   } else if (modal && modal.is_custom !== 1) {
      //     this.modal = {};
      //     this.modal.is_custom = this.defaultItemsUser.is_custom;
      //     this.modal.modal_text_client = this.defaultInfo.modal.modal_text_client;
      //   }
      // }

      setTimeout(() => {
        this.modal.modal_text_client += " ";
      });
    },
    setText(hook) {
      let user;
      let admin;
      if (hook === "created") {
        user = this.itemsUser || this.defaultItemsUser;
        admin = this.itemsAdmin || this.defaultItemsAdmin;
      } else {
        user = this.user;
        admin = this.admin;
      }
      if (
        this.itemsAdmin &&
        this.itemsUser &&
        this.defaultItemsAdmin &&
        this.defaultItemsUser
      ) {
        if (user) {
          this.setData(this.itemsUser, "user", hook);
        } else this.setData(this.defaultItemsUser, "user", hook);

        if (admin) {
          this.setData(this.itemsAdmin, "admin", hook);
        } else this.setData(this.defaultItemsAdmin, "admin", hook);
      }
    },
    setData(value, role, hook) {
      const arr =
        hook === "created"
          ? [
              ...this.textTypes,
              "is_custom",
              "is_send",
              "is_custom_sms",
              "is_send_sms",
            ]
          : this.textTypes;
      arr.forEach((el) => {
        this.$data[role][el] = value[el];
        setTimeout(() => {
          if (this.textTypes.includes(el)) this.$data[role][el] += " ";
        });
      });
    },
    replaceString(string) {
      return string.replace(/\s+$/g, " ");
    },
    save(role) {
      if (!this.isModal) {
        this.textTypes.forEach((el) => {
          let item = this.$data[role][el];
          item = this.replaceString(item);
        });
      } else {
        let item = this.modal.modal_text_client;
        item = this.replaceString(item);
      }
      this.$emit("save", { items: this.$data[role], key: role });
    },
  },
};
</script>

<style lang="scss" scoped>
.code-textarea {
  textarea {
    height: 100%;
  }
}
.section {
  margin-bottom: 30px;
  justify-content: space-between;
  &__item {
    width: calc(50% - 20px);
    min-height: 100%;
  }
  &.modal {
    .section__item {
      width: 100%;
    }
  }
  &__btn {
    width: auto;
    height: 40px;
  }
}
.textarea_sms {
  margin-bottom: 0;
}
::v-deep .overlap {
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  @apply rounded-sm border border-blue-100 bg-blue-10;
}
@media (max-width: 768px) {
  .section {
    &__item {
      width: 100%;
    }
  }
}
</style>
<style lang="scss">
@import "element-variables.scss";
.notification {
  width: auto;
}
.textarea_default .textarea {
  min-width: 121px;
}
.notifications-page .code-textarea .el-textarea__inner {
  max-height: 100% !important;
  padding: 12px 20px !important;
  min-height: 72px !important;
}
.input {
  &_disable {
    position: relative;
    .overlap {
      display: block;
      position: absolute;
    }
  }
}
.textarea {
  textarea {
    font-size: 14px;
  }
  &_default {
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.25);
    height: calc(100% - 38px);
    min-height: 78px;
    @apply rounded-sm;
    textarea {
      min-height: 100% !important;
      width: calc(100% + 28px);
    }
  }
  &_disable {
    position: relative;
    textarea,
    textarea:hover {
      color: rgba(255, 255, 255, 0.5);
    }
    .overlap {
      display: block;
      position: absolute;
    }
  }
}
</style>
