<template>
  <div class="clients-table relative sm:px-16">
    <spinner v-if="loading" class="table__spinner" />
    <el-table
      :data="clients"
      :default-sort="{ prop: 'index', order: 'descending' }"
      class="border-b-0 mb-40"
      :stripe="true"
    >
      <el-table-column type="index" label="№" prop="id" min-width="60">
        <div slot-scope="{ row }" class="flex flex-col items-center">
          {{ row.id }}
          <div class="table__icon" @click="onEdit(row)">
            <svg-icon icon-name="edit-2" width="20" height="20"></svg-icon>
          </div>
        </div>
      </el-table-column>
      <el-table-column prop="date" label="Дата" :min-width="111" sortable>
        <div slot-scope="{ row }">
          {{ row.date }}
          <div class="small_text">
            {{ row.time }}
          </div>
        </div>
      </el-table-column>
      <el-table-column prop="name" label="ФИО" :min-width="250" sortable>
        <div slot-scope="{ row }" class="flex items-center">
          <div class="table__icon table__icon_border p-7">
            <svg-icon icon-name="sms" width="15" height="15"></svg-icon>
          </div>
          <div class="underline">{{ row.name }}</div>
        </div>
      </el-table-column>
      <el-table-column prop="phone" label="Телефон" sortable :min-width="220">
        <div slot-scope="{ row }" class="flex items-center">
          <div class="table__icon table__icon_border p-5">
            <svg-icon
              icon-name="additionally"
              width="20"
              height="20"
            ></svg-icon>
          </div>
          <div>{{ row.phone }}</div>
        </div>
      </el-table-column>
      <el-table-column prop="email" label="E-mail" sortable :min-width="220">
        <div slot-scope="{ row }" class="flex items-center">
          <div class="table__icon table__icon_border p-4">
            <svg-icon
              icon-name="additionally"
              width="20"
              height="20"
            ></svg-icon>
          </div>

          <div>{{ row.email }}</div>
        </div>
      </el-table-column>
      <el-table-column prop="reserve" label="Бронирования" :width="268">
        <el-button class="w-full">
          <div class="table__btn">
            Бронирования
            <span class="icon_rounded icon_rounded_question w-18 h-18">
              <svg-icon width="5" height="9" icon-name="question"></svg-icon>
            </span>
          </div>
        </el-button>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    clients: Object,
    loading: Boolean,
  },
  methods: {
    onEdit(row) {
      this.$emit("edit", row);
    },
  },
};
</script>

<style lang="scss" scoped>
.table {
  &__btn {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon_rounded {
      margin-left: 10px;
    }
  }
}
</style>
<style lang="scss">
.table {
  &__icon {
    &_border {
      display: inline-block;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 3px;
      margin-right: 14px;
    }
  }
}
</style>
