var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._l((_vm.settings.data.group),function(item,index){return _c('div',{key:index,staticClass:"bk-info"},[_c('p',{staticClass:"bk-info__title",style:({
        color: _vm.isThemeWidget ? _vm.customStyle.active_text_color : '',
      })},[_vm._v(" от "+_vm._s(item.from)+" до "+_vm._s(item.to)+" мест ")]),_c('p',{staticClass:"bk-info__info",style:({
        color: _vm.isThemeWidget ? _vm.customStyle.active_text_color : '',
      })},[_vm._v(" "+_vm._s(_vm.isHoliday && item.holidays_price ? item.holidays_price + _vm.selectCell.weekend_price_additional : item.base_price + _vm.selectCell.price_additional)+" "+_vm._s(_vm.selectCell.currency)+" "+_vm._s("за место")+" ")])])}),_c('div',{staticClass:"bk-full"},[_c('p',{staticClass:"hint-title",style:({
        color: _vm.isThemeWidget ? _vm.customStyle.active_text_color : '',
      })},[_vm._v(" Количество участников ")]),_c('el-input-number',{staticClass:"w-full",class:{ 'custom-style': _vm.isThemeWidget },style:({
        background: _vm.isThemeWidget ? _vm.customStyle.background_cells_fields : '',
      }),attrs:{"value":_vm.value,"min":_vm.minCount,"max":_vm.maxCount},on:{"input":function($event){return _vm.changePersons($event)}}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }