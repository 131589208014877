<template>
  <div class="relative">
    <spinner v-if="loading" />
    <el-table :data="items" class="border-b-0 mb-20" sortable :stripe="true">
      <el-table-column prop="id" label="№" sortable :width="62" />
      <el-table-column
        prop="date"
        sortable
        label="Дата отправки"
        class="date"
        :width="170"
      />
      <el-table-column prop="email" sortable label="E-mail" :min-width="240" />
      <el-table-column
        prop="name"
        sortable
        label="Отправитель"
        :min-width="140"
      />
      <el-table-column
        prop="text"
        sortable
        label="Текст письма"
        :min-width="640"
      />
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      items: [
        {
          id: 1,
          date: "2021-03-15 21:45:55",
          name: "Пользователь",
          text: `С другой стороны дальнейшее развитие различных форм деятельности играет важную роль в формировании направлений прогрессивного развития. Таким образом укрепление и развитие структуры представляет собой интересный эксперимент проверки соответствующий условий активизации. Не следует, однако забывать, что реализация намеченных плановых заданий способствует подготовки и реализации системы обучения кадров, соответствует насущным потребностям.`,
          email: "longmailexample@mail.ru",
        },
        {
          id: 2,
          date: "2021-03-15 21:45:55",
          name: "Пользователь",
          text: `С другой стороны дальнейшее развитие различных форм деятельности играет важную роль в формировании направлений прогрессивного развития. Таким образом укрепление и развитие структуры представляет собой интересный эксперимент проверки соответствующий условий активизации. Не следует, однако забывать, что реализация намеченных плановых заданий способствует подготовки и реализации системы обучения кадров, соответствует насущным потребностям.`,
          email: "longmailexample@mail.ru",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.small_text {
  white-space: nowrap;
}
</style>
