<template>
  <Block title="Контактные данные">
    <UploadPhoto
      v-model="item.avatar"
      :is-logo="true"
      :value="item.avatar"
      text="Перетащите фотографию в эту область или загрузите ее"
      class="section_content__upload"
      @input="saveContactInfo('avatar')"
    />
    <el-form
      ref="formContacts"
      :model="item"
      :hide-required-asterisk="true"
      :rules="formContactsRules"
      label-position="top"
      class="section_content__inputs"
    >
      <el-row
        :gutter="!$mq.tablet ? 30 : 0"
        type="flex"
        class="mb-20 flex-wrap"
      >
        <el-col :span="$mq.tablet ? 24 : 12">
          <el-form-item class="mb-20 required" prop="name">
            <span slot="label">Имя<span class="danger">*</span></span>
            <el-input
              v-model="item.name"
              placeholder="Введите имя"
              @blur="saveContactInfo"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="$mq.tablet ? 24 : 12">
          <el-form-item class="mb-20 required" prop="email">
            <span slot="label"
              >Ваш E-mail
              <span class="danger">*</span>
            </span>
            <el-input
              v-model="item.email"
              type="email"
              placeholder="Введите E-mail"
              @blur="saveContactInfo"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="$mq.tablet ? 24 : 12">
          <el-form-item class="lg:mb-20 required" prop="phone">
            <span slot="label"
              >Ваш телефон
              <!-- <span class="danger">*</span> -->
            </span>
            <el-input
              v-model="item.phone"
              placeholder="Введите телефон"
              @blur="saveContactInfo"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="$mq.tablet ? 24 : 12">
          <el-form-item label="Домен осн. сайта (без WWW)" prop="domain">
            <el-input
              v-model="item.domain"
              class="mb-0"
              placeholder="broni.biz"
              @blur="saveContactInfo"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </Block>
</template>

<script>
import UploadPhoto from "@/components/UploadPhoto.vue";
import Block from "./Block";
import { mapState, mapMutations } from "vuex";
import { AJAX_POST } from "../../units/ajax";

export default {
  components: { UploadPhoto, Block },
  data() {
    return {
      item: {
        name: "",
        phone: "",
        email: "",
        domain: "",
        avatar: null,
      },
      formContactsRules: {
        name: [
          {
            required: true,
            message: "Укажите имя",
            trigger: ["blur", "change"],
          },
        ],
        phone: [
          {
            min: 8,
            required: false,
            message: "Укажите телефон",
            trigger: ["blur", "change"],
          },
        ],
        email: [
          {
            type: "email",
            required: true,
            message: "Укажите правильный E-mail",
            trigger: ["change", "blur"],
          },
        ],
        domain: [
          {
            required: false,
            message: "Укажите домен",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  mounted() {
    this.setUserContactsData();
  },
  methods: {
    ...mapMutations(["setNotif"]),
    setData() {
      this.$emit("input", this.items);
    },
    setUserContactsData() {
      this.item.name = this.user.name;
      this.item.email = this.user.email;
      this.item.phone = this.user.phone;
      this.item.domain = this.user.site;
      this.item.avatar = this.user.avatar ? this.user.avatar.path : null;
    },
    saveContactInfo(type) {
      // if (type === "avatar" && !this.item.avatar) return;
      this.$refs.formContacts.validate((valid) => {
        if (valid) {
          AJAX_POST("/api/me/change", this.item)
            .then((response) => {
              this.setNotif({
                title: `Данные сохранены`,
              });
            })
            .catch((err) => {
              this.setNotif({
                title: `Произошла ошибка, попробуйте позже`,
                type: "error",
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.section_content {
  &__upload {
    min-width: 156px;
    min-height: 156px;
    width: 156px;
    height: 156px;
    margin-right: 40px;
  }
  &__inputs {
    width: calc(100% - 156px);
  }
}
::v-deep .wrapper-upload {
  height: 100%;
  &__text {
    width: 110px;
  }
}
::v-deep .wrapper-logo {
  padding-bottom: 0;
  &__logo {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 1024px) {
  ::v-deep .wrapper-logo {
    &__logo {
      width: 156px;
      height: 156px;
      margin: 0 auto;
    }
  }
  .section_content {
    &__upload {
      width: 100%;
    }
    &__inputs {
      width: 100%;
      margin-top: 20px;
    }
  }
}
</style>
