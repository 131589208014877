<template>
  <Block title="Интеграция с Битрикс24" class="analytic">
    <el-row type="flex" :gutter="!$mq.tablet ? 30 : 0" class="w-full flex-wrap">
      <el-col :span="$mq.tablet ? 24 : 24" class="lg:mb-20 my-15"
        ><p>Экспортировать клиентов в контрагенты в Битрикс24</p>
      </el-col>
      <el-col :span="$mq.tablet ? 24 : 14" class="lg:mb-20">
        <div>
          <label
            class="text-12 font-medium text-white-normal flex flex-col input-appended"
          >
            <span class="mb-7">Вебхук для вызова Rest API </span>
            <span class="flex">
              <el-input v-model="importUrl" placeholder="Вебхук"></el-input>
            </span>
          </label>
        </div>
      </el-col>
      <el-col :span="$mq.tablet ? 24 : 10">
        <span class="flex justify-between">
          <div class="">
            <el-button
              type="primary"
              class="bg-transparent mt-20"
              @click="setIntegrationBitrix"
              >{{ importUrl ? "Обновить" : "Применить" }}</el-button
            >
          </div>
          <instruction-item
            class="mt-20"
            only-button
            :doc="{
              link: user.instructions.bitrix24.link,
              buttonText: `Скачать инструкцию`,
            }"
        /></span>
      </el-col>
    </el-row>
  </Block>
</template>

<script>
import Block from "./Block";
import { mapState, mapMutations } from "vuex";
import { AJAX_POST } from "../../units/ajax";
import InstructionItem from "@/components/InstructionItem";

export default {
  name: "ItegrationBitrix",
  components: {
    Block,
    InstructionItem,
  },
  data() {
    return {
      importUrl: null,
      instructions: {
        yandex: null,
        google: null,
      },
    };
  },

  computed: { ...mapState("user", ["user", "import_url"]) },
  watch: {
    import_url() {
      this.setUrl();
    },
  },
  mounted() {
    // this.setUrl();
    this.setAnalytic();
    this.setUrl();
  },
  methods: {
    ...mapMutations(["setNotif"]),

    setAnalytic() {
      this.instructions.google = this.user.additional.analytics.google;
    },
    setUrl() {
      this.importUrl = this.import_url;
    },
    setIntegrationBitrix() {
      if (this.importUrl) {
        this.updateIntegration();
      } else {
        this.addIntegration();
      }
    },
    addIntegration() {
      AJAX_POST("/api/integration/bitrix/add", {
        import_url: this.importUrl,
      }).then((response) => {
        if (response.data.success) {
          this.setNotif({
            title: `${response.data.msg}`,
          });
        }
      });
    },
    updateIntegration() {
      AJAX_POST("/api/integration/bitrix/update", {
        import_url: this.importUrl,
      }).then((response) => {
        if (response.data.success) {
          this.setNotif({
            title: `${response.data.msg}`,
          });
        }
      });
    },
  },
};
</script>
