<template>
  <el-form
    ref="baseInfo"
    :rules="rules"
    :model="{ day, schedule }"
    hide-required-asterisk
    label-position="top"
    class="regular"
  >
    <div class="list-fields">
      <div class="list-fields__row">
        <div class="list-fields__item">
          <el-form-item prop="day">
            <div slot="label" class="flex">
              Дата<span class="danger">*</span>
            </div>
            <el-date-picker
              v-model="day"
              :clearable="false"
              type="date"
              placeholder="выберите дату"
              :picker-options="scheduleDateOptions"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              @blur="emitData()"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div v-if="isShowDeletBtn" class="list-fields__item mb-3 lg:w-full">
          <el-button
            class="delete-button w-full"
            icon="el-icon-close"
            @click="$emit('delete'), $emit('formValid', true)"
          >
            Удалить дату
          </el-button>
        </div>
        <div v-else class="list-fields__item"></div>
        <div v-if="schedule.length > 1" class="list-fields__actions">
          <div class="list-fields__actions-stub"></div>
        </div>
      </div>
      <div
        v-for="(item, index) in schedule"
        :key="`${item}-${index}`"
        class="list-fields__row"
      >
        <div class="list-fields__item">
          <el-form-item
            :prop="`schedule.${index}.time`"
            :rules="rules.time"
            class="w-full"
          >
            <p class="hint-title">Время<span class="danger">*</span></p>
            <el-time-picker
              v-model="item.time"
              format="HH:mm"
              placeholder="Введите время"
              @input="emitData()"
            />
          </el-form-item>
        </div>
        <div class="list-fields__item">
          <el-form-item
            :prop="`schedule.${index}.price`"
            :rules="rules.price"
            class="w-full"
          >
            <div slot="label" class="flex">
              <div>
                {{ isAddCost ? "Добавочная стоимость" : "Стоимость"
                }}<span class="danger">*</span>
              </div>
            </div>
            <el-input
              v-model.number="item.price"
              placeholder="Введите цену"
              @input="emitData()"
            >
              <div slot="suffix">₽</div>
            </el-input>
          </el-form-item>
        </div>
        <div class="list-fields__item mb-5 lg:w-full">
          <el-button
            class="delete-button w-full"
            icon="el-icon-close"
            @click="deleteField(index)"
          >
            Удалить время
          </el-button>
          <!-- <ButtonIcon
            text="Удалить"
            icon-name="close"

            width="16"
            @click="deleteField(index)"
          /> -->
        </div>
      </div>

      <el-button class="add-button" @click="addField()">
        Добавить время
      </el-button>
    </div>
  </el-form>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import {} from "date-fns";
import moment from "moment";

export default {
  name: "ListSlotsDate",
  components: {},
  props: {
    isShowDeletBtn: {
      type: Number,
    },
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      formValid: null,
      day: null,
      schedule: [{ time: null, price: null }],

      scheduleDateOptions: {
        disabledDate: (time) => {
          return time < moment().subtract(1, "day").toDate();
        },
      },
      rules: {
        day: [
          { required: true, min: 1, message: "Введите имя", trigger: "blur" },
        ],
        price: [{ required: true, message: "Введите имя" }],
        time: [{ required: true, message: "Введите имя" }],
      },
    };
  },
  computed: {
    ...mapState("service", ["settings", "addDates"]),
    ...mapGetters("service", ["isHaveSettings", "isAddCost"]),
  },
  watch: {
    value() {
      this.getOriginValue();
    },
  },
  created() {
    this.getOriginValue();
  },

  methods: {
    ...mapActions("service", ["saveAddDates"]),
    ...mapMutations("service", ["setSettings"]),
    // checkForm() {
    //   const { fields } = this.$refs.baseInfo;
    //   if (fields.find((f) => f.validateState === "validating")) {
    //     setTimeout(() => {
    //       this.checkForm();
    //     }, 500);
    //   }
    //   this.formValid = fields.every((f) => {
    //     const valid = f.required && f.validateState === "success";
    //     const notErroring = !f.required && f.validateState !== "error";
    //     return notErroring;
    //   }, true);
    // },
    // checkForm() {
    //   this.$refs.baseInfo.validate((valid) => {
    //     if (valid) {
    //       console.log("+");
    //       this.$emit("formValid", true);
    //     }
    //     this.$emit("formValid", false);

    //     console.log("error");
    //   });
    // },
    emitData() {
      this.$emit("input", {
        day: this.day,
        schedule: [...this.schedule],
      });

      this.$refs.baseInfo.validate((valid) => {
        if (valid) {
          this.$emit("formValid", true);
        } else {
          this.$emit("formValid", false);
        }
      });
    },
    deleteField(index) {
      this.schedule.splice(index, 1);
      this.emitData();
    },
    getOriginValue() {
      if (!this.value) return;
      this.day = this.value.day;
      this.schedule = [...this.value.schedule];
    },
    addField() {
      this.schedule.push({ time: null, price: null });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.add-button {
  margin: 0 10px;
}
::v-deep .el-input__suffix {
  display: flex;
  align-items: center;
}
::v-deep .el-form-item.is-error {
  .el-form-item__error {
    display: none;
  }
  input {
    border: 1px solid $--color-danger;
  }
}
@media (max-width: 1024px) {
  .list-slot {
    width: 100% !important;
  }
}
</style>
