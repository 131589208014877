<template>
  <div class="card">
    <WidgetService
      v-for="(service, i) in listService"
      :key="i"
      :service="service"
    />
  </div>
</template>

<script>
import WidgetService from "@/views/Services/Create/components/WidgetService.vue";

export default {
  name: "ScheduleWidgetSingleGrid",
  components: { WidgetService },
  props: {
    isMaket: Boolean,
  },
  data() {
    return {
      isLoading: true,
      listService: [
        {
          name:
            "Астрономия (индивидуальная, цена не зависит от кол-ва мест, регулярное)",
          kind_of_activity: [11],
          short_description: "Частные уроки от преподавателя высшей категории",
          detail_description:
            "<p>Уроки ведет лучший астролог по версии Азимут в 2019 году.</p>",
          video_name: "Олени",
          video_link: "https://www.youtube.com/watch?v=SYm04J5iCvo",
          use_company_contacts: false,
          emails: "admin2@broni.biz",
          tel_req: "+7 (952) 362-43-00",
          tel: ["+7 (952) 362-43-01"],
          address_type: "noAddress",
          address_from_settings_id: 34131,
          address: "",
          address_route: [
            {
              address_route_name: "",
              address_route_duration: 0,
              address_route_unit: "minutes",
              address_route_city: "",
              address_route_country: "",
              address_route_timezone: "",
              map: [],
            },
            {
              address_route_name: "",
              address_route_duration: 0,
              address_route_unit: "minutes",
              address_route_city: "",
              address_route_country: "",
              address_route_timezone: "",
              map: [],
            },
          ],
          age_limit: "0+",
          language: "ru",
          currency: "rub",
          button_name: "bronirovat",
          tags_switcher: true,
          tags: ["до 5 человек", "Классика"],
          services_photos: [
            {
              id: 2586,
              src:
                "https://hsto.org/webt/p8/0_/3h/p80_3hbeg1tvnja98f1mdyiiyj0.jpeg",
            },
          ],
        },
        {
          name:
            "Астрономия (индивидуальная, цена не зависит от кол-ва мест, регулярное)",
          kind_of_activity: [11],
          short_description: "Частные уроки от преподавателя высшей категории",
          detail_description:
            "<p>Уроки ведет лучший астролог по версии Азимут в 2019 году.</p>",
          video_name: "Олени",
          video_link: "https://www.youtube.com/watch?v=SYm04J5iCvo",
          use_company_contacts: false,
          emails: "admin2@broni.biz",
          tel_req: "+7 (952) 362-43-00",
          tel: ["+7 (952) 362-43-01"],
          address_type: "noAddress",
          address_from_settings_id: 34131,
          address: "",
          address_route: [
            {
              address_route_name: "",
              address_route_duration: 0,
              address_route_unit: "minutes",
              address_route_city: "",
              address_route_country: "",
              address_route_timezone: "",
              map: [],
            },
            {
              address_route_name: "",
              address_route_duration: 0,
              address_route_unit: "minutes",
              address_route_city: "",
              address_route_country: "",
              address_route_timezone: "",
              map: [],
            },
          ],
          age_limit: "0+",
          language: "ru",
          currency: "rub",
          button_name: "bronirovat",
          tags_switcher: true,
          tags: ["до 5 человек", "Классика"],
          services_photos: [
            {
              id: 2591,
              src:
                "https://hsto.org/webt/p8/0_/3h/p80_3hbeg1tvnja98f1mdyiiyj0.jpeg",
            },
          ],
        },
      ],
    };
  },
  computed: {},
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.card {
  display: flex;
  flex-direction: row;
}

::v-deep .widget {
  width: 320px;
  margin: 0 30px 20px 0;
}
</style>
