<template>
  <!-- <div class="wrapper"> -->
  <div class="pt-40 pb-80 w-full lg:pt-15 px-10">
    <!-- <h1
      class="font-semibold text-white-normal text-28 leading-44 lg:text-22 lg:px-15 py-10 pt-0 lg:leading22 mb-20 xs:mb-5"
    >
      SMS-сообщения
    </h1> -->
    <Top title="  SMS-сообщения" :font="{ size: 22, leading: 22 }" />
    <MinSms />
    <FillingSms class="wrapper__sms mt-40 md:px-16" />
    <HistorySms class="mt-50" />
    <HistoryPay class="mt-50" />
  </div>
</template>

<script>
import Top from "@/components/Pages/Top";
import FillingSms from "@/components/FillingSms";
import HistorySms from "@/components/Sms/HistorySms.vue";
import HistoryPay from "@/components/Sms/HistoryPay.vue";
import MinSms from "@/components/Sms/MinSms.vue";

export default {
  name: "SMS",
  components: { FillingSms, HistorySms, HistoryPay, MinSms, Top },
};
</script>

<style scoped lang="scss">
@import "element-variables.scss";
::v-deep .el {
  &-input__inner {
    &:focus {
      color: $--color-white;
      background: rgba(255, 255, 255, 0.2);
      ~ .el-input-group__append {
        background: rgba(255, 255, 255, 0.15);
      }
    }
  }
}

::v-deep .check_status {
  height: 30px;
}
</style>

<style lang="scss">
.wrapper__sms {
  background: rgba(255, 255, 255, 0.04);
  padding: 30px;
}
.preTables {
  &__total {
    background: #3498db;
    font-size: 10px;
    line-height: 12px;
    font-weight: 700;
    padding: 2px 7px;
    border-radius: 10px;
    margin-left: 10px;
    height: 16px;
    min-width: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.icon_rounded {
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  &_question {
    background-color: #8a949d;
  }
  &_success {
    background-color: #35c770;
  }
  &_cancel {
    background-color: #e74c3c;
  }
}
.current_status {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 12px;
  .icon_rounded {
    margin-right: 10px;
  }
}
.small_text {
  opacity: 0.5;
  font-size: 12px;
  line-height: 20px;
  display: block;
}
</style>
