<template>
  <div>
    <div>
      <div class="wrapper-text">
        <el-alert
          title="Расписание успешно сгенерировано! После проверки, не забудьте его сохранить."
          type="success"
          show-icon
          :closable="false"
        />
      </div>
      <div v-if="isBind" class="wrapper-text">
        <el-alert
          title="Обратите внимание, что расписание редактируется у родительской услуги!"
          type="warning"
          show-icon
          :closable="false"
        >
        </el-alert>
      </div>
      <p v-if="isBind" class="hint-title">
        Теперь вы можете добавить сеансы вручную для каждого дня недели,
        вернуться к редактированию графика работы или сохранить расписание и
        перейти к следующему шагу.
      </p>
      <p v-else class="hint-title">
        Теперь вы можете отредактировать сеансы вручную, вернуться к
        редактированию графика работы или сохранить расписание и перейти к
        следующему шагу.
      </p>
      <el-collapse v-if="isDates" class="first-wrapper">
        <BlockCollapse
          v-for="(date, i) in data"
          :key="i"
          :title="getDateText(date.date)"
          :name="`date-${i}`"
        >
          <SlotSchedule
            v-model="date.schedule"
            :headers="headers"
            :base-price="basePrice"
            :holidays-price="holidaysPrice"
          />
        </BlockCollapse>
      </el-collapse>
      <el-collapse v-else class="first-wrapper">
        <BlockCollapse
          v-for="(period, i) in data"
          :key="i"
          :title="getPeriod(period)"
          :name="`period-${i}`"
        >
          <el-collapse class="second-wrapper">
            <BlockCollapse
              v-for="(day, index) in period.days"
              :key="index"
              :title="listDays.find((item) => day.value === item.value).title"
              :name="'' + index + day.value + i"
            >
              <SlotScheduleGroup
                v-if="isGroupLimit"
                v-model="day.schedule"
                :headers="headers"
                :base-price="basePrice"
                :holidays-price="holidaysPrice"
                :is-bind="isBind"
                :number-seats="settings.data.tickets"
              />
              <SlotSchedule
                v-else
                v-model="day.schedule"
                :generator-sessions="generator"
                :period-days="period.days"
                :data-session="data"
                :headers="headers"
                :base-price="basePrice"
                :holidays-price="holidaysPrice"
                :is-bind="isBind"
                :is-manual="isManual"
                :is-service-booking-type="isServiceBookingType"
                :day-week="
                  listDays.find((item) => day.value === item.value).value
                "
              />
            </BlockCollapse>
          </el-collapse>
        </BlockCollapse>
      </el-collapse>
    </div>
  </div>
</template>

<script>
import BlockCollapse from "@/components/BlockCollapse";
import SlotSchedule from "@/components/SlotSchedule.vue";
import SlotScheduleGroup from "@/components/SlotScheduleGroup.vue";

import {
  format,
  add,
  set,
  isBefore,
  isEqual,
  getMinutes,
  getHours,
  getDate,
  getMonth,
  getYear,
} from "date-fns";
import { monthNames } from "@/helpers/date.js";
import {
  headersForIndiv,
  headersAddPrice,
  headersGroupLimitForSeans,
} from "@/helpers/typeSchedule.js";
import { listDays } from "@/helpers/services.js";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";
import { AJAX_POST } from "@/units/ajax";
import moment from "moment";

export default {
  name: "Sessions",
  components: {
    BlockCollapse,
    SlotSchedule,
    SlotScheduleGroup,
  },
  data() {
    return {
      data: [],
      hashBindService: null,
    };
  },
  computed: {
    ...mapState("service", [
      "schedule",
      "settings",
      "sessions",
      "generator",
      "listSchedule",
      "periodError",
    ]),
    ...mapGetters("service", [
      "basePrice",
      "holidaysPrice",
      "isHaveSessions",
      "isAddCost",
    ]),
    isServiceBookingType() {
      return this.settings.booking_type === "service";
    },
    isRegular() {
      return this.sessions.schedule_type === "regular";
    },
    isDates() {
      return this.sessions.schedule_type === "dates";
    },
    isManual() {
      return this.sessions.schedule_type === "manual";
    },
    isBind() {
      return this.sessions.schedule_type === "bind";
    },
    isGroupLimit() {
      return (
        this.settings.booking_type_sub ===
        "group_the_seat_limit_is_set_for_each_session"
      );
    },
    headers() {
      if (this.isGroupLimit) return headersGroupLimitForSeans;
      return this.isAddCost ? headersAddPrice : headersForIndiv;
    },
  },
  created() {
    this.listDays = listDays;
    this.monthNames = monthNames;
  },
  mounted() {
    if (this.isHaveSessions) {
      this.data = this.sessions.data;
    } else {
      this.callFunction();
    }
  },
  methods: {
    ...mapMutations("service", ["setField", "setFieldObj"]),
    ...mapMutations(["setNotif"]),
    ...mapActions("service", ["saveSessions", "saveGenerator", "getGenerator"]),

    callFunction(name) {
      const nameFunction = `${
        name || this.generator.schedule_type || this.sessions.schedule_type
      }Formatting`;

      this[nameFunction]();
    },
    async saveData() {
      let data;
      if (this.isDates) {
        data = this.data.map((date) => {
          const schedule = date.schedule.map((item) => {
            // удалил
            // format(new Date(item.time), "HH:mm")
            // format(date.date, "yyyy-MM-dd")
            // из-за ошибки fns
            return {
              ...item,
              time: this.isBind ? item.time : moment(item.time).format("HH:mm"),
              // format(new Date(item.time), "HH:mm"),
            };
          });
          return {
            date: moment(date.date).format("yyyy-MM-DD"),
            // format(date.date, "yyyy-MM-dd"),
            schedule,
            old_date: date.old_date,
          };
        });
      } else {
        data = this.data.map((period, idx) => {
          const days = period.days.map((day) => {
            const schedule = day.schedule.map((opt) => {
              return {
                ...opt,
                time: this.isBind
                  ? opt.time
                  : format(new Date(opt.time), "HH:mm"),
              };
            });
            return {
              ...day,
              schedule,
            };
          });
          return {
            ...period,
            days,
          };
        });
      }
      this.setFieldObj({
        field: "sessions",
        subField: "data",
        value: [...this.data],
      });

      // if (this.generator.schedule_type === "bind") {
      //   data = this.generator.data.value;
      // }

      await this.saveGenerator(
        this.generator.schedule_type === "bind"
          ? this.hashBindService ?? this.generator.data.bind
          : this.generator.data[this.sessions.schedule_type]
      ); // this.generator.data.value; // this.listSchedule);
      if (!this.periodError) await this.saveSessions(data);
    },
    diffPeriod() {
      const diff = this.generator.data.value.map((e) => {
        return e.date;
      });
    },

    regularFormatting() {
      // this.diffPeriod();
      // const diff = this.generator.data.value.map((e) => {
      //   return e.date;
      // });
      this.data = this.generator.data.regular.map((period, idx) => {
        let days = period.days.filter((day) => day.select);
        days = days.map((day) => {
          const schedule =
            period.radio === "manySession"
              ? this.getScheduleMany(period)
              : this.getScheduleOne(period);
          return {
            value: day.value,
            schedule,
          };
        });
        return {
          date: period.date,
          old_date: this.generator.old_period
            ? this.generator?.old_period[idx]
            : null,
          days,
        };
      });
    },
    datesFormatting() {
      let arr = [];
      arr = this.generator.data.dates.calendar.selectedDates.map((date) => {
        let schedule = [];
        if (this.generator.data.dates.radio === "manySession") {
          schedule = this.getScheduleMany(this.generator.data.dates);
        } else {
          schedule = this.getScheduleOne(this.generator.data.dates);
        }
        return {
          date: new Date(date.date),
          schedule,
        };
      });
      this.data = arr.map((per, idx) => {
        return {
          ...per,
          old_date: this.generator.old_period
            ? this.generator.old_period[idx]
            : null,
        };
      });
      //   el.selectedDates.map((date) => {
      //     let schedule = [];
      //     if (this.generator.data.dates.radio === "manySession") {
      //       schedule = this.getScheduleMany(this.generator.data.dates);
      //     } else {
      //       schedule = this.getScheduleOne(this.generator.data.dates);
      //     }
      //     return {
      //       date: new Date(date.date),
      //       schedule,
      //     };
      //   })
    },
    manualFormatting() {
      this.data = this.generator.data.manual.map((period) => {
        const days = [];
        for (let i = 1; i < 8; i++) {
          days.push({
            value: i,
            schedule: [
              {
                time: null,
                price: this.isAddCost ? null : this.basePrice,
                holidays_price: this.isAddCost ? null : this.holidaysPrice,
              },
            ],
          });
        }
        return {
          date: period.date,
          days,
        };
      });
    },
    async bindFormatting() {
      try {
        const { data } = await AJAX_POST("/api/schedule/getScheduleType", {
          id: this.generator.data.bind,
        });
        const scheduleData = await AJAX_POST(
          "/api/schedule/getResourceSchedule",
          {
            hash: this.generator.data.bind,
            child_hash: this.$route.params.hash,
          }
        );
        this.hashBindService = this.generator.data.bind;
        this.setFieldObj({
          field: "generator",
          subField: "data",
          value: data.data,
        });
        this.data = scheduleData.data.data;
        //   this.callFunction(data.active_schedule_type);
        const dataS = this.data.map((period, idx) => {
          const days = period.days.map((day) => {
            const schedule = day.schedule.map((opt) => {
              return {
                ...opt,
                time: opt.time,
              };
            });
            return {
              ...day,
              schedule,
            };
          });
          return {
            ...period,
            days,
          };
        });
      } catch (err) {
        console.log(err);
        // this.setNotif({
        //   title: "Ошибка при получении данных. Повторите еще",
        //   type: "error",
        // });
      }
    },
    getPeriod(value) {
      let period = `Период с ${format(new Date(value.date[0]), "dd.MM.yyyy")}`;
      if (value.date[1])
        period += ` по ${format(new Date(value.date[1]), "dd.MM.yyyy")}`;
      return period;
    },
    getDateText(date) {
      return `${getDate(new Date(date))} ${this.monthNames[
        getMonth(new Date(date))
      ].title.toLowerCase()} ${getYear(new Date(date))}`;
    },
    getScheduleMany(period) {
      const schedule = [];
      period.listSessions.forEach((session) => {
        let startTime = set(new Date(), {
          hours: getHours(new Date(session.time[0])),
          minutes: getMinutes(new Date(session.time[0])),
        });
        const finishTime = set(new Date(), {
          hours: getHours(new Date(session.time[1])),
          minutes: getMinutes(new Date(session.time[1])),
        });
        while (
          isBefore(startTime, finishTime) ||
          isEqual(finishTime, startTime)
        ) {
          schedule.push({
            time: startTime,
            price: this.isAddCost ? null : this.basePrice,
            holidays_price: this.isAddCost ? null : this.holidaysPrice,
          });
          const obj = {};
          obj[session.unit] = session.duration;
          // startTime = add(new Date(startTime), {
          //   // `${session.duration}`: `${ session.duration}`
          //   hours: session.duration,
          // });
          startTime = add(new Date(startTime), obj, {
            ...startTime,
            ...obj,
          });
        }
      });
      return [...schedule];
    },
    getScheduleOne(period) {
      return [
        {
          time: period.time,
          price: this.isAddCost ? null : this.basePrice,
          holidays_price: this.isAddCost ? null : this.holidaysPrice,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.wrapper-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;

  ::v-deep .el-alert {
    margin-right: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    &__content {
      color: $--color-white;
    }
  }
}

.hint-title {
  padding-bottom: 30px;
}

.first-wrapper {
  margin: 0 -30px -30px;
  border-top: 1px solid $--color-white-15;
  border-bottom: none;
  ::v-deep .collapse-content {
    padding-top: 0;
    border-top: none;
  }
  ::v-deep .collapse-title {
    padding-top: 15px;
    padding-bottom: 15px;
    svg {
      margin-right: 20px;
    }
  }
  ::v-deep .el-collapse-item {
    margin-bottom: 0 !important;
    border-bottom: 1px solid $--color-white-15;

    &:last-child {
      border-bottom: none;
    }

    &__header {
      background-color: transparent;
      h2 {
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 0;
      }
    }
    &__wrap {
      background-color: transparent;
    }
  }
}
.second-wrapper {
  border: none;

  ::v-deep .collapse-content {
    @media (max-width: 456px) {
      padding: 15px 0 25px 15px;
    }
    //padding: 15px 0 25px 45px;
  }
  ::v-deep .collapse-title {
    padding: 8px 0 8px 15px;

    h2 {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $--color-white;
    }
  }
  ::v-deep .el-collapse-item {
    margin-top: 5px !important;
    border: none;
    @media (max-width: 456px) {
      &__content {
        padding-right: 10px;
      }
    }

    &__header {
      background-color: $--color-ultra-white;
    }
  }
}

::v-deep .list-fields {
  margin-bottom: 0;
}
</style>
