import { setField, setFieldObj } from "@/helpers/store";
import { AJAX_GET, AJAX_DELETE, AJAX_POST, AJAX_PATCH } from "@/units/ajax";
import moment from "moment";

export default {
  namespaced: true,
  state: {
    pay: null,
    historyPay: [],
    historySms: {},
    historyPayInfo: {
      totalItems: null,
      totalPages: null,
    },
    historySmsInfo: {
      totalItems: null,
      totalPages: null,
    },
    notification: null,
    exportSmsFile: null,
    exportEmailFile: null,
  },
  getters: {},
  mutations: {
    setField,
    setFieldObj,
  },
  actions: {
    async getHistoryPay({ state, commit }, perPage = 15) {
      const api = `/api/pay/history?type=3&page=1&perPage=${perPage}`;
      try {
        const { data } = await AJAX_GET(api, { noCancel: true });
        commit("setField", {
          field: "pay",
          value: data,
        });

        const historyPay =
          data.data &&
          data.data.map((el) => ({
            created_at: {
              date: moment(el.created_at).format("DD.MM.YYYY"),
              time: moment(el.created_at).format("HH:mm:ss"),
            },
            updated_at: {
              date: moment(el.updated_at).format("DD.MM.YYYY"),
              time: moment(el.updated_at).format("HH:mm:ss"),
            },
            pack: el.sms_count,
            sum: el.amount,
            payment_type: el.type_price_name,
            status: el.status_name,
            status_id: el.status,
          }));
        commit("setField", {
          field: "historyPayInfo",
          value: {
            totalItems: data.total,
            totalPages: data.last_page,
          },
        });
        commit("setField", {
          field: "historyPay",
          value: historyPay,
        });
      } catch (err) {
        console.error(api, err);
        commit(
          "setNotif",
          {
            title: "Ошибка при получении данных.",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async getHistorySMS({ commit }, req) {
      let api = `/api/sms/history?page=${req.page}&perPage=${req.perPage}`;
      if (req.search) api += `&search=${req.search}`;

      try {
        const { data } = await AJAX_GET(api, { noCancel: true });

        data.data =
          data.data &&
          data.data.map((el) => {
            return {
              loading: false,
              clicked: false,
              subTitle: false,
              ...el,
              date: {
                date: moment(el.created_at.date).format("DD.MM.YYYY"),
                time: moment(el.created_at.date).format("HH:mm:ss"),
              },
            };
          });
        commit("setField", {
          field: "historySmsInfo",
          value: {
            totalItems: data.totalSms,
            totalPages: data.totalPages,
          },
        });
        commit("setField", {
          field: "historySms",
          value: data,
        });
      } catch (err) {
        console.error(api, err);
        commit(
          "setNotif",
          {
            title: "Ошибка при получении данных.",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async sendSms({ commit }, value) {
      try {
        const { data } = await AJAX_POST("/api/sms/send", value);
        if (data.success) {
          commit("setField", {
            field: "exportSmsFile",
            value: data,
          });
          commit(
            "setNotif",
            {
              title: `Отправлено сообщение`,
            },
            { root: true }
          );
        } else {
          commit(
            "setNotif",
            {
              title: data.msg || "Ошибка отправки, попробуйте позже",
              type: "error",
            },
            { root: true }
          );
        }
      } catch (err) {
        commit(
          "setNotif",
          {
            title: "Ошибка отправки, попробуйте позже",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async sendMail({ commit }, value) {
      try {
        const { data } = await AJAX_POST("/api/mail/send", value);
        if (data.success) {
          commit("setField", {
            field: "exportEmailFile",
            value: data,
          });
          commit(
            "setNotif",
            {
              title: data.msg,
            },
            { root: true }
          );
        } else {
          commit(
            "setNotif",
            {
              title: data.msg || "Ошибка отправки, попробуйте позже",
              type: "error",
            },
            { root: true }
          );
        }
      } catch (err) {
        commit(
          "setNotif",
          {
            title: "Ошибка отправки, попробуйте позже",
            type: "error",
          },
          { root: true }
        );
      }
    },
  },
};
