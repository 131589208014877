<template>
  <div v-if="isLogo" class="wrapper-logo">
    <div v-if="value" class="wrapper-logo__logo">
      <img :src="value" alt="" />
      <div class="wrapper-logo__logo-overflow">
        <i class="el-icon-camera text-20 mr-20" @click="$refs.logo.click()"></i>
        <i class="el-icon-delete text-20" @click="clearLogo"></i>
      </div>
    </div>
    <div
      v-show="!value"
      class="wrapper-logo__upload wrapper-upload"
      @dragover="dragover"
      @drop="drop($event, 'logo')"
    >
      <input
        :id="refKey"
        ref="logo"
        type="file"
        multiple
        accept="image/*"
        @change="uploadImage('logo')"
      />

      <label :for="refKey">
        <span class="wrapper-upload__text">
          {{ text }}, <span class="wrapper-upload__link">нажав сюда</span>
        </span>
        <span v-if="subText"> {{ subText }}.</span>
      </label>
    </div>
  </div>
  <div v-else>
    <div
      class="wrapper-upload"
      @dragover="dragover"
      @drop="drop($event, 'file')"
    >
      <input
        :id="refKey"
        ref="file"
        type="file"
        multiple
        accept="image/*"
        @change="uploadImage('file')"
      />
      <label :for="refKey">
        <span class="wrapper-upload__text">
          {{ text }},
          <span class="wrapper-upload__link">нажав сюда</span>
        </span>
        <span v-if="subText"> {{ subText }}.</span>
      </label>
    </div>
    <div class="gallery">
      <div
        v-for="(image, i) in value"
        v-show="image.src"
        :key="i"
        class="gallery__img"
      >
        <img :src="image.src" alt="" />
        <div class="gallery__img-overflow" @click="deletePhoto(image, i)">
          <i class="el-icon-delete"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadPhoto",
  props: {
    isLogo: Boolean,
    value: {
      type: [String, Array],
      default: () => [],
    },
    subText: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default:
        "Перетащите фотографию в эту область или загрузите ее, нажав сюда",
    },
  },
  data() {
    return {
      refKey: Math.floor(Math.random() * Math.floor(1000)),
      newPhotos: [],
    };
  },
  methods: {
    dragover(event) {
      event.preventDefault();
    },
    drop(event, key) {
      event.preventDefault();
      this.$refs[key].files = event.dataTransfer.files;
      this.uploadImage(key);
    },
    encodeImage(input, isLastEl) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.newPhotos.push({
          id: null,
          src: e.target.result,
        });
      };
      reader.onloadend = () => {
        if (isLastEl) {
          this.$emit("input", [...this.value, ...this.newPhotos]);
          this.newPhotos = [];
        }
      };
      reader.readAsDataURL(input);
    },
    clearLogo() {
      this.$emit("input", null);
      this.$refs.logo.value = "";
    },
    encodeLogo(input) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.$emit("input", e.target.result);
      };
      reader.onloadend = () => {};
      reader.readAsDataURL(input);
    },
    uploadImage(key) {
      this.checkSize(key);
      if (key === "logo") {
        console.log(this.$refs.logo.files[0]);
        if (this.checkSize(this.$refs.logo.files[0]))
          this.encodeLogo(this.$refs.logo.files[0]);
      } else if (this.$refs.file.files.length) {
        let lastEl = {};
        this.$refs.file.files.forEach((file, index) => {
          if (this.checkSize(file, true)) lastEl = { file, index };
        });
        this.$refs.file.files.forEach((file, index) => {
          if (this.checkSize(file))
            this.encodeImage(file, lastEl.index === index);
        });
      }
    },
    checkSize(file, isCheck = false) {
      if (!file) return false;
      const sizeMB = Math.round((file.size * 100) / (1024 * 1024)) / 100;
      if (sizeMB > 2) {
        if (isCheck)
          this.$notify({
            title: `Фотографиия ${file.name} превышает допустимый размер в 2 MB`,
            type: "warning",
            duration: 3000,
            showClose: false,
            customClass: "warning",
          });
        return false;
      }
      return true;
    },
    deletePhoto(image, i) {
      if (image.id) {
        this.$emit(
          "input",
          this.value.map((img) => {
            console.log(img);
            if (image.id === img.id) {
              return { id: img.id, src: null };
            }
            return img;
          })
        );
      } else {
        this.$emit(
          "input",
          this.value.filter((img, index) => index !== i)
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.wrapper-logo {
  display: flex;
  height: 100%;
  padding-bottom: 22px;
  &__logo {
    width: 100%;
    height: 100%;
    border: 10px solid #3c1452;
    cursor: pointer;
    position: relative;

    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }

    &-overflow {
      display: none;
      align-items: center;
      // padding-bottom: 25px;
      justify-content: center;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
    }

    &:hover {
      .wrapper-logo__logo-overflow {
        display: flex;
      }
    }
  }
  &__upload {
    flex-grow: 1;
    min-height: 120px;
    height: 100% !important;
  }
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  margin: 6px -6px 24px;

  &__img {
    width: 100px;
    height: 100px;
    margin: 6px;
    border: 10px solid #3c1452;
    cursor: pointer;
    position: relative;

    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
    }

    &-overflow {
      display: flex;
      visibility: hidden;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.4);
      transition: all 0.3s;
      opacity: 0;
    }

    &:hover {
      .gallery__img-overflow {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.wrapper-upload {
  color: $--color-white-35;
  border: 1px dashed $--color-white-15;
  font-weight: 500;
  font-size: 12px;
  height: 120px;
  position: relative;
  padding: 10px;
  cursor: pointer;
  border-radius: 3px;
  input {
    opacity: 0;
    position: absolute;
    cursor: pointer;
    height: 1px;
    width: 1px;
  }

  label {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-align: center;
  }

  &__link {
    color: white;
    cursor: pointer;

    label {
      cursor: pointer;
    }
    input {
      display: none;
      cursor: pointer;
    }
  }
}

@screen lg {
  .wrapper-logo {
    margin-bottom: 30px;
    height: auto;
    padding-bottom: 0;
    &__logo {
      width: 120px;
      height: 120px;
      margin: 0 12px 0 0;
    }
  }
  .gallery {
    display: flex;
    flex-wrap: wrap;
    margin: 6px -6px 12px;
  }
}
</style>
