<template>
  <div class="pt-40 pb-80 w-full lg:pt-15">
    <Top
      title="Клиенты"
      button-text="Добавить клиента"
      @onClick="showModal = true"
    />
    <SearchBar
      :date-block="true"
      input-placeholder="Поиск по клиентам"
      :export-links="[{ name: 'Экспорт в Excel', link: '/' }]"
    />
    <Table :loading="loading" :clients="clients" @edit="onEdit" />
    <Pagination />
    <CreateClient :show="showModal" :edit-item="editItem" @close="closeModal" />
  </div>
</template>

<script>
import Top from "@/components/Pages/Top";
import Table from "@/components/Clients/Table";
import SearchBar from "@/components/Table/SearchBar";
import Pagination from "@/components/Table/Pagination";
import CreateClient from "@/components/Clients/CreateClient";
import { mapActions, mapState } from "vuex";

export default {
  name: "Clients",
  components: {
    Top,
    Table,
    SearchBar,
    Pagination,
    CreateClient,
  },
  data() {
    return {
      showModal: false,
      loading: true,
      editItem: null,
      filter: {
        year: "",
        month: null,
      },
      years: [
        {
          value: "2020",
        },
        {
          value: "2019",
        },
        {
          value: "2018",
        },
      ],
      monthes: [],
    };
  },
  computed: {
    ...mapState("clients", ["clients"]),
  },
  async created() {
    await this.getClients();
    this.loading = false;
  },
  methods: {
    ...mapActions("clients", ["getClients"]),
    onEdit(row) {
      this.editItem = row;
      this.showModal = true;
    },
    closeModal() {
      if (this.editItem) this.editItem = null;
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss">
.clients-table {
  .el-table td {
    vertical-align: middle;
  }
}

.clients-filter {
  max-width: 1043px;
}
</style>
