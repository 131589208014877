<template>
  <el-form
    ref="questionForm"
    v-loading="loading"
    class="bg-white-40 rounded-sm pt-20 px-10 pb-30 form-support lg:px-15"
    :model="questionForm"
    hide-required-asterisk
    label-position="top"
    :rules="questionFormRules"
    @keyup.native.ctrl.enter="onAskQuestion()"
    @keypress.ctrl.10.13.native="onAskQuestion()"
  >
    <slot name="title">
      <el-form-item label="Выберите тему вопроса" class="mb-10">
        <el-select v-model="thread" placeholder="" class="w-full">
          <el-option
            v-for="item in threads"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </slot>
    <el-form-item :label="messageTitle" class="mb-10" prop="question">
      <el-input
        v-model="questionForm.question"
        type="textarea"
        resize="none"
        class="textarea"
      ></el-input>
    </el-form-item>

    <el-upload
      drag
      class="upload-demo mb-20"
      accept="image/png, image/jpeg"
      with-credentials
      :action="uploadUrl"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :before-remove="beforeRemove"
      :on-success="successUpload"
      :on-error="errorUpload"
      multiple
      :limit="10"
      :on-exceed="handleExceed"
      :file-list="fileListDefault"
    >
      <div class="flex xs:flex-col">
        <el-button type="info" class="mr-15 custom__button"
          >Прикрепить файл</el-button
        >
        <div slot="tip" class="el-upload__tip text-12 text-white-500">
          .png или .jpg размером не более 3 Мб
        </div>
      </div>
    </el-upload>

    <el-button
      type="primary"
      class="bg-gradient-b-yellow border-0 ssm:w-full"
      @click="onAskQuestion"
      >Отправить
    </el-button>
    <span class="el-upload__tip text-12 text-white-500 px-10"
      >Быстрая отправка: ctrl+enter</span
    >
  </el-form>
</template>

<script>
import { AJAX_GET, AJAX_POST, host } from "../units/ajax";

export default {
  name: "SupportAddForm",
  props: {
    inline: {
      type: Boolean,
      default: false,
    },
    messageTitle: {
      type: String,
      default: "Напишите ваш вопрос",
    },
  },
  data() {
    return {
      thread: 1,
      threads: [],
      loading: false,
      fileListDefault: [],
      uploadedFiles: [],
      questionForm: {
        question: "",
      },
      questionFormRules: {
        question: [
          {
            required: true,
            min: 1,
            message: "Введите вопрос",
            trigger: ["change", "blur"],
          },
        ],
      },
    };
  },
  computed: {
    uploadUrl() {
      return `${host}/api/support/uploadImg`;
    },
  },
  mounted() {
    if (!this.inline) {
      AJAX_GET("/api/support/threads").then((response) => {
        // eslint-disable-next-line no-restricted-syntax
        for (const key in response.data) {
          this.threads.push({ value: +key, label: response.data[key] });
        }
      });
    }
  },
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    successUpload(file) {
      this.uploadedFiles.push(file.id);
    },
    errorUpload(err) {
      this.$message.error(err.message);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.error(
        `Максимальное кол-во файлов - 10, вы выбрали ${files.length} файлов`
      );
    },
    beforeRemove(file, fileList) {
      return this.$confirm("", `Отменить загрузку ${file.name}`, {
        confirmButtonText: "Да",
        cancelButtonText: "Отмена",
        type: "warning",
      });
    },
    onAskQuestion() {
      this.$refs.questionForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          const params = !this.inline
            ? {
                message: this.questionForm.question,
                thread: this.thread,
                images: this.uploadedFiles,
              }
            : {
                id: this.$route.params.id,
                message: this.questionForm.question,
                images: this.uploadedFiles,
              };
          AJAX_POST("/api/support/send", params).then((response) => {
            this.$notify({
              title: "Сообщение отправлено",
              type: "success",
              showClose: false,
              duration: 2000,
            });
            this.loading = false;
            this.$emit("createMessage", this.questionForm.question);
            this.$refs.questionForm.resetFields();
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.textarea {
  background: $--color-light-white;
}
.custom__button {
  @media (max-width: 480px) {
    max-width: 260px;
    margin-bottom: 5px;
  }
}
</style>
