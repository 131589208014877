<template>
  <div>
    <div class="bk-full">
      <p class="hint">Услуга<span class="danger">*</span></p>
      <el-select
        v-model="serviceId"
        value-key="index"
        filterable
        placeholder="Выберите услугу"
        class="w-full"
        @change="setId"
      >
        <el-option
          v-for="(item, index) in servicesList"
          :key="index"
          :label="item.name"
          :value="item.hash"
          default-first-option="item.name"
        >
        </el-option>
      </el-select>
    </div>
    <div class="bk-row">
      <div class="bk-row__item">
        <p class="hint">Дата<span class="danger">*</span></p>
        <el-date-picker
          v-model="scheduleDate"
          type="date"
          :clearable="false"
          format="dd.MM.yyyy"
          :picker-options="scheduleDateOptions"
          placeholder="Выберите дату"
          @change="changeScheduleDate($event, hash)"
        />
      </div>
      <div v-if="isSlotDetails" class="bk-row__item">
        <p class="hint">Время<span class="danger">*</span></p>
        <el-select
          v-model="scheduleTime"
          value-key="time"
          filterable
          placeholder="Выберите время"
          class="w-full"
          @change="setPers"
        >
          <el-option
            v-for="(slot, inx) in correctIsSlotDetails"
            :key="inx"
            :label="slot.time"
            :value="slot"
            default-first-option="item.time"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div
      v-if="
        slotDetails.booking_type.booking_type === 'service_tree_from_settings'
      "
    >
      <ServicesTreeGiven
        :settings="settings"
        :item-list="itemList"
        :slot-details="slotDetails.amount"
        :hash="itemList.hash"
        :date-param="scheduleDate"
        @setServices="setServices"
        @setSlotParam="setSlotParam"
      />
    </div>
    <div
      v-if="
        slotDetails.booking_type.booking_type ===
        'group_the_seat_limit_is_set_for_each_session'
      "
    >
      <LimitTypeGroup
        :settings="settings"
        :item-list="itemList"
        :slot-details="slotDetails.amount"
        :hash="itemList.hash"
        :date-param="scheduleDate"
        @setTickets="setTickets"
        @setSlotParam="setSlotParam"
      />
    </div>
    <div
      v-if="
        slotDetails.booking_type.booking_type ===
        'group_same_seat_limit_for_all_sessions'
      "
      class="bk-full"
    >
      <LimitAllGroup
        :settings="settings"
        :item-list="itemList"
        :slot-details="slotDetails.amount"
        :hash="itemList.hash"
        :date-param="scheduleDate"
        @setTickets="setTickets"
        @setSlotParam="setSlotParam"
      />
    </div>
    <div
      v-if="
        (slotDetails.booking_type.booking_type ===
          'individual_depends_on_the_number_of_seats' &&
          !isForPlace) ||
        (slotDetails.booking_type.booking_type ===
          'individual_depends_on_the_number_of_seats_forAgroup_true' &&
          !isForPlace)
      "
    >
      <SeatGroup
        :settings="settings"
        :item-list="itemList"
        :slot-details="slotDetails"
        @SetForGroup="setForGroup"
      />
    </div>
    <div
      v-if="
        slotDetails.booking_type.booking_type ===
        'individual_depends_on_the_service_and_its_duration'
      "
      class="bk-full"
    >
      <ServDuration
        :settings="settings"
        :item-list="itemList"
        :slot-details="slotDetails"
        @setServiceDur="setServiceDur"
      />
    </div>
    <div
      v-if="
        slotDetails.booking_type.booking_type ===
        'individual_depends_on_tariff_and_number_of_seats'
      "
      class="bk-full"
    >
      <TarifSeat
        :settings="settings"
        :item-list="itemList"
        :slot-details="slotDetails"
        :date-param="scheduleDate"
        @setServTarif="setServTarif"
      />
    </div>

    <div
      v-if="
        slotDetails.booking_type.booking_type === 'rent_depends_only_on_time'
      "
    >
      <RenDepTim
        :settings="settings"
        :item-list="itemList"
        :slot-details="slotDetails"
        :slots-validity="slotsValidityParent"
        @setRent="setRent"
      />
    </div>
    <div
      v-if="
        slotDetails.booking_type.booking_type ===
        'rent_depends_on_time_and_place'
      "
    >
      <RenDepTimSeats
        :settings="settings"
        :item-list="itemList"
        :slot-details="slotDetails"
        :slots-validity="slotsValidity"
        @renTimeSeat="setRenTimeSeat"
        @setDiscount="setDiscountValue"
      />
    </div>

    <div
      v-if="
        slotDetails.booking_type.booking_type !== 'rent_depends_only_on_time' &&
        slotDetails.booking_type.booking_type !==
          'rent_depends_on_time_and_place' &&
        slotDetails.booking_type.booking_type ===
          'individual_depends_on_the_number_of_seats' &&
        isForPlace
      "
      class="bk-info-group mb-10"
    >
      <div
        v-for="(item, idx) in slotDetails.booking_type.booking_type !==
        'individual_depends_on_the_number_of_seats'
          ? settings.data.group
          : isSettingsDataParams"
        :key="idx"
        class="bk-info"
      >
        <p class="bk-info__title text-12">
          {{ item.from ? `от ${item.from} ` : "" }}
          {{ item.to ? `до  ${item.to} ` : "" }}
          {{
            slotDetails.booking_type.booking_type !==
            "individual_depends_on_the_number_of_seats"
              ? `${unitCahnge.text}`
              : `участников`
          }}
        </p>
        <p>
          {{ item.base_price }} {{ itemList.item.currency }}
          {{
            slotDetails.booking_type.booking_type !==
            "individual_depends_on_the_number_of_seats"
              ? `${unitCahnge.textShot}`
              : `за участника`
          }}
        </p>
      </div>
    </div>
    <div
      v-if="
        (slotDetails.booking_type.booking_type !==
          'rent_depends_only_on_time' &&
          slotDetails.booking_type.booking_type !==
            'rent_depends_on_time_and_place' &&
          slotDetails.booking_type.booking_type ===
            'individual_does_not_depend_on_the_number_of_seats' &&
          slotDetails.booking_type.booking_type !==
            'individual_depends_on_the_service_and_its_duration') ||
        (slotDetails.booking_type.booking_type ===
          'individual_depends_on_the_number_of_seats' &&
          isForPlace)
      "
      class="bk-full"
    >
      <p class="hint">Количество участников</p>
      <el-input-number
        v-model="totalPerson"
        class="w-full"
        :min="Number(maxPerson.from)"
        :max="Number(maxPerson.to)"
        @input="setPers"
      ></el-input-number>
    </div>
    <div
      v-if="
        isGroup &&
        slotDetails.booking_type.booking_type !==
          'individual_depends_on_the_service_and_its_duration' &&
        slotDetails.booking_type.booking_type !==
          'individual_depends_on_the_number_of_seats' &&
        isForPlace
      "
      class="bk-info-group"
    >
      <div
        v-for="group in isSettingsDataParams"
        :key="group.id"
        class="bk-info"
      >
        <p class="bk-info">
          {{ group.from ? `от ${group.from}` : "" }}
          {{ group.to ? `до  ${group.to} ` : "" }}
          {{
            slotDetails.booking_type.booking_type !==
            "individual_depends_on_the_number_of_seats"
              ? `${unitCahnge.text}`
              : `участников`
          }}
        </p>
        <p class="bk-info">
          {{ isHoliday ? group.holidays_price : group.base_price }}
          {{ itemList.item.currency }}./{{
            slotDetails.booking_type.booking_type !==
            "individual_depends_on_the_number_of_seats"
              ? `${unitCahnge.textShot}`
              : `участника`
          }}
        </p>
      </div>
    </div>
    <div
      v-if="
        isGroup &&
        slotDetails.booking_type.booking_type !==
          'rent_depends_on_time_and_place' &&
        slotDetails.booking_type.booking_type !==
          'individual_depends_on_the_service_and_its_duration' &&
        slotDetails.booking_type.booking_type !==
          'individual_depends_on_the_number_of_seats' &&
        slotDetails.booking_type.booking_type !==
          'individual_depends_on_the_number_of_seats_forAgroup_true' &&
        slotDetails.booking_type.booking_type !==
          'group_the_seat_limit_is_set_for_each_session' &&
        slotDetails.booking_type.booking_type !== 'rent_depends_only_on_time'
      "
      class="hl"
    ></div>

    <div
      v-if="
        isTymeUnit &&
        slotDetails.booking_type.booking_type !==
          'rent_depends_on_time_and_place' &&
        slotDetails.booking_type.booking_type !==
          'individual_does_not_depend_on_the_number_of_seats' &&
        slotDetails.booking_type.booking_type !==
          'individual_depends_on_the_service_and_its_duration' &&
        slotDetails.booking_type.booking_type !==
          'individual_depends_on_the_number_of_seats' &&
        slotDetails.booking_type.booking_type !==
          'individual_depends_on_the_number_of_seats_forAgroup_true' &&
        slotDetails.booking_type.booking_type !==
          'group_same_seat_limit_for_all_sessions' &&
        slotDetails.booking_type.booking_type !==
          'group_the_seat_limit_is_set_for_each_session' &&
        slotDetails.booking_type.booking_type !== 'rent_depends_only_on_time' &&
        slotDetails.booking_type.booking_type !== 'service_tree_from_settings'
      "
      class="bk-full"
    >
      <p class="hint">
        Количество {{ unitCahnge.text }}
        <span class="danger">*</span>
      </p>
      <el-input-number
        v-model="totalTime"
        class="w-full"
        :min="minHours"
        :step="step"
        @input="setPers"
      ></el-input-number>
    </div>
    <div
      v-if="
        isAmountSlot.total &&
        slotDetails.booking_type.booking_type !==
          'rent_depends_on_time_and_place' &&
        slotDetails.booking_type.booking_type !==
          'group_same_seat_limit_for_all_sessions' &&
        slotDetails.booking_type.booking_type !==
          'group_the_seat_limit_is_set_for_each_session' &&
        slotDetails.booking_type.booking_type !== 'service_tree_from_settings'
      "
      class="bk-info"
    >
      <p class="bk-info__title">Осталось мест</p>
      <p
        v-if="isAmountSlot.remained || isAmountSlot.total"
        class="bk-info__info"
      >
        {{ isAmountSlot.remained }} из
        {{ isAmountSlot.total }}
      </p>
    </div>
    <div
      v-if="
        isAmountSlot.total &&
        slotDetails.booking_type.booking_type !==
          'rent_depends_on_time_and_place' &&
        slotDetails.booking_type.booking_type !==
          'group_same_seat_limit_for_all_sessions' &&
        slotDetails.booking_type.booking_type !==
          'group_the_seat_limit_is_set_for_each_session' &&
        slotDetails.booking_type.booking_type !== 'service_tree_from_settings'
      "
      class="hl"
    ></div>
    <div
      v-if="
        isAmountSlot.total &&
        slotDetails.booking_type.booking_type !==
          'rent_depends_on_time_and_place' &&
        slotDetails.booking_type.booking_type !==
          'group_same_seat_limit_for_all_sessions' &&
        slotDetails.booking_type.booking_type !==
          'group_the_seat_limit_is_set_for_each_session' &&
        slotDetails.booking_type.booking_type !== 'service_tree_from_settings'
      "
      class="bk-full"
    >
      <p class="hint">Количество мест<span class="danger">*</span></p>
      <el-input-number
        v-model="totalSeats"
        :min="1"
        :max="isAmountSlot.total"
        :step="step"
        class="w-full"
        @input="setPers"
      ></el-input-number>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { listDuration } from "@/helpers/services.js";
import ServicesTreeGiven from "@/views/Schedule/ServicesTreeGiven";
import SeatGroup from "@/views/Schedule/SeatGroup";
import ServDuration from "@/views/Schedule/ServDuration";
import TarifSeat from "@/views/Schedule/TarifSeat";
import LimitAllGroup from "@/views/Schedule/LimitAllGroup";
import LimitTypeGroup from "@/views/Schedule/LimitTypeGroup";
import RenDepTimSeats from "@/views/Schedule/RenDepTimSeats";
import RenDepTim from "@/views/Schedule/RenDepTim";
import moment from "moment";

moment.locale("ru");

export default {
  name: "ServiceBook",
  components: {
    SeatGroup,
    ServDuration,
    TarifSeat,
    LimitAllGroup,
    LimitTypeGroup,
    RenDepTimSeats,
    RenDepTim,
    ServicesTreeGiven,
  },
  props: {
    itemList: Object,
    dateSlide: String,
    isHoliday: Boolean,
    addOptCheck: Array,
    addOptInput: Array,
    slotsValidityParent: Object,
  },
  data() {
    const vm = this;

    return {
      val: "",
      hash: null,
      total: null,
      serviceId: null,
      shedulId: null,
      freeTimeReserv: null,
      scheduleDate: null,
      radio: null,
      person: null,
      tickets: null,
      services: null,
      paramsCheck: null,
      scheduleTime: {
        time: null,
        schedule_id: null,
      },
      dateForPrice: null,
      startSliceIdx: null,
      disabledDates: [],
      scheduleDateOptions: {
        disabledDate(time) {
          time = moment(time).format("YYYY-MM-DD");
          return !vm.disabledDates.includes(time);
          // &&
          // time < moment().subtract(1, "day").toDate()
        },
      },
      totalPerson: 1,
      totalTime: 1,
      totalSeats: 1,
      basePrice: null,
      dataPar: null,
      groupParam: null,
      tarifParam: null,
      discountParam: null,
    };
  },
  computed: {
    ...mapState("service", ["schedule", "service"]),
    ...mapState("widget", ["slotDetails", "slotsValidity"]),
    ...mapState("user", ["servicesList"]),
    ...mapState("schedule", ["schedule", "scheduleCalendarDate"]),
    ...mapState("settings", [
      "ageLimitList",
      "languageList",
      "currencyList",
      "buttonNameList",
      "timezoneList",
    ]),
    ...mapState("service", ["freeTime", "settings"]),
    ...mapState("orders", ["orders", "orders_stats", "isLoading"]),

    ...mapGetters("service", ["isFreeTime"]),
    ...mapGetters("widget", [
      "isAmountSlot",
      "isSlotDetails",
      "errMsgslotsValidity",
    ]),

    correctIsSlotDetails() {
      return Object.values(this.isSlotDetails).find((e) => e);
    },

    minHours() {
      return Number(this.settings?.data?.group?.[0].from) || 1;
    },
    maxPerson() {
      let arr = [];
      if (this.settings.data.group) {
        this.settings.data.group.map((i) => arr.push(i.to));
        arr = Math.max.apply(null, arr);
      }
      if (
        this.slotDetails.booking_type.booking_type ===
        "individual_does_not_depend_on_the_number_of_seats"
      ) {
        arr.push(this.settings.data.to ? this.settings.data.to : 100000);
        arr = Math.max.apply(null, arr);
      }
      return this.settings?.data.group
        ? {
            to: this.settings?.data?.to || arr,
            from:
              this.settings?.data?.from ||
              this.settings.data.group.find((i) => i.from).from ||
              10,
          }
        : { to: arr ?? 1, from: this.settings?.data?.from ?? 1 };
    },
    isSettingsDataParams() {
      return this.settings.data.group;
    },
    // getDate() {
    //   return this.scheduleDate.toLocaleDateString("fr-CA");
    // },
    fromTime() {
      return this.settings?.from;
    },
    isGroup() {
      return this.settings?.data?.group;
    },
    step() {
      return this.settings?.data?.session_duration
        ? Number(this.settings?.data?.session_duration)
        : 1;
    },
    isTymeUnit() {
      return this.settings?.data?.time_unit;
    },
    isForPlace() {
      return this.slotDetails?.booking_type?.data?.price === "for_a_place";
    },

    isIndNumSeat() {
      return (
        this.slotDetails?.booking_type?.booking_type ===
          "individual_does_not_depend_on_the_number_of_seats" ||
        this.slotDetails?.booking_type?.booking_type ===
          "individual_depends_on_the_number_of_seats"
      );
    },
    unitCahnge() {
      switch (this.isTymeUnit) {
        case "days":
          return {
            text: "дней",
            textShot: "д",
            param: "day",
            time: this.hours,
          };
        case "hours":
          return {
            text: "часов",
            textShot: "ч",
            param: "hour",
            time: this.hours,
          };
        case "minutes":
          return {
            text: "минут",
            textShot: "мин",
            param: "minute",
            time: this.hours,
          };
        case "minute":
          return {
            text: "минут",
            textShot: "мин",
            param: "minute",
            time: this.hours,
          };

        default:
          return this.settings?.data?.time_unit;
      }
    },
  },
  watch: {
    totalTime() {
      const t = this.totalTime;
      if (this.isGroup) {
        const price = this.settings.data.group.find((e) => {
          if (!e.to) return e;
          return (e.to >= t || e.to === null) && e.from <= t ? e : "";
        });
        this.basePrice = this.isHoliday
          ? price.holidays_price
          : price.base_price || 0;
        return this.basePrice;
      }
    },
    totalPerson() {
      const t = this.totalPerson || 1;
      if (this.isGroup) {
        const price = this.settings.data.group.find((e) => {
          return (e.to >= t || e.to === null) && e.from <= t ? e : "";
        });
        this.basePrice = this.isHoliday
          ? price.holidays_price
          : price.base_price || 0;
        return this.basePrice;
      }
    },
    addOptCheck: {
      handler() {
        //  this.setPers();
      },
      immediate: true,
    },
    slotsValidity() {
      this.setPromPtCheck();
    },
  },
  created() {
    this.totalTime =
      this.itemList.status === "update"
        ? this.itemList.item.reservation[0]?.book_info?.rent?.duration
        : this.totalTime;
    this.hash = this.itemList.hash;
    this.date = this.dateSlide;

    this.basePrice =
      // eslint-disable-next-line no-nested-ternary
      this.itemList.status === "update"
        ? this.isPriceUpdate(this.slotDetails.booking_type.booking_type).price
        : this.itemList?.item?.is_holiday
        ? this.itemList?.item?.price_weekend ||
          this.slotDetails?.booking_type?.data?.holidays_price
        : this.itemList?.item?.price ||
          this.slotDetails?.booking_type?.data.base_price;
    this.scheduleDate =
      this.dateSlide === undefined ? this.itemList.date : this.dateSlide;
    this.scheduleTime.time = this.itemList?.item?.time;
    this.radio =
      this.itemList.status === "update"
        ? this.slotDetails.booking?.book_info.service
        : "";
    this.rent =
      this.itemList.status === "update"
        ? this.slotDetails?.booking?.book_info.rent
        : "";
    this.totalPerson =
      this.itemList.status === "update"
        ? this.slotDetails.booking?.book_info?.individual?.persons
        : this.totalPerson;
    // this.itemList?.item?.time; // this.settings?.data.price;
    this.scheduleTime.schedule_id =
      this.itemList?.item?.id === undefined
        ? this.itemList?.item?.resource_id ?? this.itemList?.item?.schedule_id
        : this.itemList?.item?.id; // || this.itemList?.item?.schedule_id;
    this.serviceId = this.itemList.hash;
    this.appendDate();
  },

  methods: {
    priceFromTime() {
      const timeUnitMinutes =
        this.slotDetails.booking_type.data.time_unit === "minutes";
      // this.totalTime = timeUnitMinutes ? 1 : this.totalTime;
      const valIndex = timeUnitMinutes
        ? this.totalTime / this.step
        : this.totalTime;
      let selectTime = this.slotDetails.slots[this.dateForPrice].slice(
        this.startSliceIdx,
        this.startSliceIdx + valIndex
      );

      selectTime = selectTime.map((element, idx) => {
        return {
          time: element.time,
          additional: element.price_additional,
          maxRent: "",
          range: element.price_range.find((el) => {
            const to = el.to ? Number(el.to) : 1000;
            return this.totalTime <= to;
          })?.price,
          idx,
        };
      });
      // selectTime.reduce((sum, current) => {
      //   return console.log(
      //     sum.range + sum.additional,
      //     current.range + current.additional
      //   );
      // },0);

      const isProcent = this.discountParam?.unit === "procent";
      const priceForSelectTime = selectTime.reduce((sum, current) => {
        let isDiscount = null;
        if (this.discountParam) {
          isDiscount =
            isProcent && this.discountParam
              ? ((current.range + current.additional) *
                  this.discountParam.discount) /
                100
              : this.discountParam.discount;
        }
        if (timeUnitMinutes) {
          return sum + (current.range + current.additional - isDiscount);
          // return (
          //   (current.range + current.additional - isDiscount) *
          //   Math.ceil(this.totalTime / this.step)
          // );
        }
        return sum + (current.range + current.additional - isDiscount);
      }, 0);
      return priceForSelectTime;
    },
    isDisabl(e) {
      console.log(e);
    },
    appendDate() {
      Object.keys(this.scheduleCalendarDate).forEach((el) => {
        this.disabledDates.push(el);
      });
      this.$refs.picker.unmountPicker();
      this.$refs.picker.mountPicker();
    },
    ...mapActions("service", [
      "getResourceSlots",
      // "getScheduleAdminCreate",
    ]),
    ...mapActions("widget", ["getSlotDetails", "checkSlotsValidity"]),
    ...mapActions("orders", [
      "getReserves",
      "sendChanges",
      "changeStatusOrder",
    ]),

    isPriceUpdate(key) {
      // console.log({
      //   price: this.slotDetails.booking.book_info.individual
      //     ? this.slotDetails.booking.book_info.individual.price
      //     : this.slotDetails.booking.book_info.group.price,
      // });
      switch (key) {
        case "individual_does_not_depend_on_the_number_of_seats":
          return { price: this.slotDetails.booking.book_info.individual.price };
        case "individual_depends_on_the_number_of_seats":
          return {
            price: this.slotDetails.booking.book_info.individual
              ? this.slotDetails.booking.book_info.individual.price
              : this.slotDetails.booking.book_info.group.price,
          };
        case "individual_depends_on_the_service_and_its_duration":
          return { price: this.slotDetails.booking.book_info.service.price };
        case "individual_depends_on_tariff_and_number_of_seats":
          return { price: this.slotDetails.booking.book_info.tariff.price };
        case "group_same_seat_limit_for_all_sessions":
          return {
            price: this.slotDetails.booking.book_info.total_price,
          };
        case "group_the_seat_limit_is_set_for_each_session":
          return { price: this.slotDetails.booking.book_info.total_price };
        case "rent_depends_only_on_time":
          return { price: this.slotDetails.booking.book_info.rent.price };
        case "rent_depends_on_time_and_place":
          return { price: this.slotDetails.booking.book_info.rent.price };

        default:
          return { price: this.slotDetails.booking.book_info.individual.price };
      }
    },

    setBookInfoForChek(key, params) {
      const forAgroup = key.data.price === "for_a_group";
      if (key.booking_type === "individual_depends_on_the_number_of_seats") {
        if (forAgroup) {
          key.booking_type =
            "individual_depends_on_the_number_of_seats_forAgroup_true";
        }
      }

      switch (key.booking_type) {
        case "rent_depends_only_on_time":
          this.dataPar = { rent: params.book_info.rent };
          break;
        case "rent_depends_on_time_and_place":
          this.dataPar = { rent: params.book_info.rent };
          break;
        case "individual_does_not_depend_on_the_number_of_seats":
          this.dataPar = {
            individual: {
              persons: this.totalPerson,
              price: this.basePrice,
              duration: this.slotDetails.booking_type.data.service_duration,
              unit: this.slotDetails.booking_type.data.time_unit,
            },
          };
          break;
        case "individual_depends_on_the_number_of_seats":
          this.dataPar = {
            individual: { persons: this.totalPerson, price: this.basePrice },
          };
          break;
        case "individual_depends_on_the_number_of_seats_forAgroup_true":
          this.dataPar = {
            group: {
              ...this.groupParam,
            },
          };
          break;
        case "individual_depends_on_the_service_and_its_duration":
          this.dataPar = {
            service: this.tickets,
          };
          break;
        case "individual_depends_on_tariff_and_number_of_seats":
          this.dataPar = { tariff: this.tarifParam };
          break;
        case "group_same_seat_limit_for_all_sessions":
          this.dataPar = { tickets: this.tickets };
          break;
        case "service_tree_from_settings":
          this.dataPar = this.services;
          break;
        case "group_the_seat_limit_is_set_for_each_session":
          this.dataPar = { tickets: this.tickets };
          break;
        default:
          return params.book_info;
      }
    },
    setPromPtCheck() {
      if (this.slotDetails.status) {
        const coast = !!this.slotsValidity?.keep_cost;
        if (this.slotsValidity.sum) {
          const value = this.slotsValidity.sum;
          this.$emit("openPromPt", value, coast);
        }
      }
    },
    setForGroup(obj) {
      this.totalPerson = null;
      this.basePrice = null;
      this.groupParam = {
        ...obj.group,
        add_persons: obj.addPersons,
        price_add_persons: obj.priceAddPersons,
      };

      this.$emit("setForGroup", {
        group: {
          ...obj.group,
          add_persons: obj.addPersons,
          price_add_persons: obj.priceAddPersons,
        },
      });
      // this.emitData({ base_price: this.groupParam.price });

      this.setPers();
    },
    setTickets(ticket) {
      const totalSum = ticket.reduce((sum, curr) => {
        return sum + curr.select_ticket * curr.price;
      }, 0);
      this.tickets = ticket;
      this.$emit("setTickets", ticket);
      this.emitData({ base_price: totalSum });
      this.$emit("setServParam", { base_price: null });
      //  this.$emit("setPrice", totalSum);
    },
    setSlotParam(obj) {
      this.$emit("setTicketCheck", obj);
    },
    setServices(obj) {
      this.services = obj;
      this.emitData(obj);
    },
    setServiceDur(obj) {
      this.tickets = obj;
      this.emitData(obj);
    },
    setServTarif(obj, qwe) {
      this.tarifParam = obj;
      this.$emit("setTarifCheck", obj, qwe);
      this.emitData({ base_price: obj.price });
    },
    setDiscountValue(discount) {
      this.discountParam = discount;
      this.$emit("setDiscount", discount);
    },
    emitData(service) {
      this.totalPerson = null;
      this.basePrice =
        this.isHoliday && service.holidays_price
          ? service.holidays_price
          : service.base_price;
      if (
        this.slotDetails.booking_type.booking_type !==
        "individual_depends_on_tariff_and_number_of_seats"
      ) {
        this.$emit(
          "setPrice",
          this.isHoliday && service.holidays_price
            ? service.holidays_price +
                (this.itemList.item.price_weekend === undefined
                  ? 0
                  : this.itemList.item.price_weekend)
            : service.base_price +
                (this.itemList.item.price_additional === undefined
                  ? 0
                  : this.itemList.item.price_additional)
        );
      }

      if (
        this.slotDetails.booking_type.booking_type !==
        "individual_depends_on_tariff_and_number_of_seats"
      ) {
        this.$emit("setServParam", service);
      }
      if (
        this.slotDetails.booking_type.booking_type !==
          "service_tree_from_settings" &&
        this.slotDetails.booking_type.booking_type !==
          "individual_depends_on_the_service_and_its_duration" &&
        this.slotDetails.booking_type.booking_type !==
          "group_same_seat_limit_for_all_sessions"
      ) {
        this.$emit("setServParam", services);
      }
      this.setPers();
    },

    getUnitTime(service) {
      return listDuration.find((el) => el.value === service.time_unit)
        .shortLabel;
    },

    async changeScheduleDate(e, hash) {
      this.scheduleDate = moment(this.scheduleDate).format("YYYY-MM-DD");
      const id = this.serviceId;
      const date = moment(this.scheduleDate).format("YYYY-MM-DD");

      await this.getSlotDetails({
        id,
        date,
        time: this.itemList.status ? this.itemList.item.time : "00:00",
        bookingHash: this.itemList.item.booking_hash,
      });
      this.$store.commit("service/setField", {
        field: "freeTimeDate",
        value: moment(e).format("YYYY-MM-DD"),
      });

      // this.scheduleTime.time,
      // this.slotDetails.slots[this.scheduleDate].forEach((slot) => {
      //   if (this.scheduleTime.time === slot.time) {
      //     // eslint-disable-next-line no-unused-expressions
      //     return this.scheduleTime.time;
      //   }
      //   // this.scheduleTime.time =
      //   return slot.time;
      //   // this.scheduleTime.time;
      // });
      this.setPers();
    },
    setRent(rent) {
      this.$emit("setRent", rent);
      this.totalTime = rent.duration;
      this.setPers();
    },
    setRenTimeSeat(payload) {
      // this.$emit("setPrice", payload);
      this.$emit("setRent", payload);
      this.totalTime = payload.duration;
      this.totalSeats = payload.places;
      this.setPers();
    },
    async changeStat(reserve, statusId) {
      this.$store.commit("widget/setField", { field: "errorMsg", value: "" });

      await this.changeStatusOrder({
        hash: reserve.id,
        status_id: statusId.id,
      });
      await this.getReserves();
    },

    async setId() {
      const hash = this.serviceId;
      const date = moment(this.scheduleDate).format("YYYY-MM-DD");
      this.$nextTick();
      this.$emit("isLoad", true);
      await this.getSlotDetails({
        id: hash,
        date,
        time: "00:00",
        bookingHash:
          this.$route.params.hash !== hash && this.itemList.status === "update"
            ? ""
            : this.itemList?.item?.booking_hash,
      });

      this.scheduleDate = null;
      this.scheduleTime = null;
      if (
        this.$route.params.hash !== hash &&
        this.itemList.status === "update"
      ) {
        this.$emit("changeAddOptions", hash);
        this.scheduleDate = date;
        // await this.getSlotDetails({
        //   id: hash,
        //   date,
        //   time: this.itemList.status ? this.itemList.item.time : "00:00",
        // });
      }
      this.setPers();
    },

    async setPers() {
      let sheduleId = null;
      let shedulePrice = null;
      const sumCheck = this.addOptCheck.reduce((sum, current) => {
        return sum + current?.price;
      }, 0);
      const sumInput = this.addOptInput.reduce((sum, current) => {
        return sum + current.selected * current.price;
      }, 0);
      // добавлять добавочную стоимость при обновлении???
      if (this.slotDetails.slots) {
        Object.entries(this.slotDetails.slots).forEach((sl) => {
          const date = sl[0];
          this.dateForPrice = date;
          const qwe = this.slotDetails.slots[date].some((dat, idx) => {
            if (this.scheduleTime?.time === dat.time) {
              this.startSliceIdx = idx;
              const findInterval = dat.price_range.find((el) => {
                const to = el.to ? Number(el.to) : 1;
                // remove this.totalTime for individual_depends_on_the_number_of_seats
                return this.isForPlace
                  ? this.totalPerson <= to
                  : this.totalTime <= to;
              });
              if (
                this.slotDetails.booking_type.booking_type ===
                "service_tree_from_settings"
              ) {
                this.basePrice = 0;
              }
              if (
                this.slotDetails.booking_type.booking_type ===
                "individual_depends_on_the_number_of_seats_forAgroup_true"
              ) {
                const findIntervalGroup = dat.price_range.find((el) => {
                  const to = el.to ? Number(el.to) : 1;
                  return this.groupParam.persons_from <= to;
                });
                this.basePrice = findIntervalGroup.price;
              }

              if (
                this.itemList.status &&
                this.slotDetails.booking_type.booking_type ===
                  "individual_does_not_depend_on_the_number_of_seats"
              ) {
                this.basePrice =
                  this.itemList.item.price ??
                  this.itemList.consist.individual.price ??
                  this.itemList.price;
                // ?? this.scheduleTime?.schedule_id;
                const isDiscount = dat.price_range.find((el) => el.discount);
                if (isDiscount) {
                  this.$emit("setDiscountIndivid", isDiscount);
                  this.basePrice = this.itemList.item.book_info.individual.price; // this.itemList.item.book_info.total_price;
                }
              } else if (
                this.slotDetails.booking_type.booking_type ===
                  "individual_depends_on_the_number_of_seats" &&
                this.isForPlace
              ) {
                // Литература
                this.basePrice = +findInterval?.price + dat?.price_additional;
              } else if (
                this.slotDetails.booking_type.booking_type !==
                  "individual_depends_on_tariff_and_number_of_seats" ||
                this.slotDetails.booking_type.booking_type ===
                  "individual_depends_on_the_number_of_seats_forAgroup_true"
              ) {
                const isDiscount = dat.price_range.find((el) => el.discount);

                if (isDiscount) this.$emit("setDiscountIndivid", isDiscount);
                // Математика Экология
                const somePar = !isDiscount
                  ? this.basePrice
                  : isDiscount.discount_price; // this.itemList.item.discount_price;
                // this.basePrice;
                this.basePrice = somePar;
                // findIntervalGroup?.price + dat?.price_additional;
              } else if (
                this.slotDetails.booking_type.booking_type ===
                "individual_depends_on_tariff_and_number_of_seats"
              ) {
                // this.basePrice = this.itemList.status
                //   ? this.itemList.item.price
                //   : findInterval?.price;
                this.basePrice += 0; // dat.price_additional; // + sumInput;
              } else {
                this.basePrice += dat.price_additional; // + sumInput;
              }
              this.$emit("setPrice", this.basePrice);
              sheduleId = dat.schedule_id ?? this.scheduleTime?.schedule_id;

              const priceAdditional = dat.price_additional;
              if (
                this.slotDetails.booking_type.booking_type ===
                  "individual_depends_on_tariff_and_number_of_seats" &&
                dat.price_range
              ) {
                dat.price_range.forEach((tar) => {
                  if (tar.id === this.tarifParam.id) {
                    shedulePrice = tar.data[0].price; // + priceAdditional;
                    return shedulePrice;
                  }
                });
              }
              if (
                dat.price_range &&
                this.slotDetails.booking_type.booking_type !==
                  "individual_depends_on_tariff_and_number_of_seats"
              ) {
                dat.price_range.forEach((price) => {
                  shedulePrice = price.price + priceAdditional;
                  return shedulePrice;
                });
              }
              if (
                this.slotDetails.booking_type.booking_type ===
                  "group_the_seat_limit_is_set_for_each_session" ||
                this.slotDetails.booking_type.booking_type ===
                  "group_same_seat_limit_for_all_sessions"
              ) {
                // remove sum + curr.select_ticket * (curr.price + priceAdditional)

                const totalS = this.tickets.reduce((sum, curr) => {
                  return sum + curr.select_ticket * curr.price;
                }, 0);
                this.$emit("setPrice", totalS);
              }
              return true;
            }
            return false;
          });
          if (qwe === false) {
            sheduleId =
              sheduleId ?? this.slotDetails?.slots[date][0]?.schedule_id;

            // Убрал из-за ошибки при редактировании и смене услуги
            //   this.scheduleTime.time = null;
            this.scheduleTime = {
              time:
                this.slotDetails?.slots[date][0]?.time ??
                this.slotDetails.booking.time,
              schedule_id: sheduleId,
            };
          }
        });
      }

      this.$store.commit("widget/setField", { field: "errorMsg", value: "" });
      const dataParams = {
        resource_id: this.serviceId,
        schedule_id: sheduleId,
        shedulePrice,
        booking_id: this.itemList.item.booking_hash,
        date: this.scheduleDate,
        time: this.scheduleTime?.time,
        book_info: {
          persons: this.totalPerson, // this.isIndNumSeat ? this.totalPerson : this.person,
          rent: {
            duration: this.totalTime,
            unit: this.unitCahnge?.param,
            places: this.totalSeats,
            price: this.basePrice,
          },
          price: this.basePrice,
          // eslint-disable-next-line prettier/prettier
          weekend_price_additional: this.itemList?.item
            ?.weekend_price_additional,
          price_additional: this.itemList?.item?.price_additional,
          total_price:
            (this.slotDetails.booking_type.booking_type !==
              "individual_does_not_depend_on_the_number_of_seats" &&
              this.slotDetails.booking_type.booking_type !==
                "individual_depends_on_the_service_and_its_duration" &&
              this.slotDetails.booking_type.booking_type ===
                "individual_depends_on_the_number_of_seats" &&
              this.slotDetails.booking_type.booking_type ===
                "individual_depends_on_the_number_of_seats_forAgroup_true") ||
            this.slotDetails.booking_type.booking_type ===
              "rent_depends_only_on_time"
              ? this.basePrice *
                (this.slotDetails.booking_type.booking_type ===
                  "individual_depends_on_the_number_of_seats" ||
                this.slotDetails.booking_type.booking_type ===
                  "individual_depends_on_the_number_of_seats_forAgroup_true"
                  ? this.totalPerson
                  : this.totalTime)
              : this.basePrice,
        },
      };

      this.setBookInfoForChek(this.slotDetails.booking_type, dataParams);
      this.$emit("setService", dataParams);
      this.$emit("isLoad", true);
      const data =
        this.slotDetails.booking_type.booking_type ===
        "individual_does_not_depend_on_the_number_of_seats"
          ? {
              individual: {
                persons: this.totalPerson,
                price: this.basePrice,
                duration: this.slotDetails.booking_type.data.service_duration,
                unit: this.slotDetails.booking_type.data.time_unit,
              },
            }
          : null;
      // REMOVE tickets 143.5)
      // {
      //     tickets:
      //       this.slotDetails.booking_type.booking_type ===
      //       "rent_depends_on_time_and_place"
      //         ? this.totalSeats
      //         : this.tickets,
      //   };
      const params = {
        ...data,
        book_info: {
          ...this.dataPar,
          add_options_input: this.addOptInput,
          add_options_check: this.addOptCheck,
          bookingHash:
            this.itemList.item.booking_type_sub !==
            "rent_depends_on_time_and_place"
              ? this.itemList.item.booking_hash
              : null,
        },
        schedule_id: dataParams.schedule_id,
        resourceHash: this.serviceId,
        date: this.scheduleDate || this.itemList.date,
        time: this.scheduleTime.time,
        bookingHash:
          this.itemList.item.booking_type_sub !==
          "rent_depends_on_time_and_place"
            ? this.itemList.item.booking_hash
            : null,
      };
      this.paramsCheck = params;
      if (
        this.slotDetails.booking_type.booking_type ===
          "rent_depends_on_time_and_place" ||
        this.slotDetails.booking_type.booking_type ===
          "rent_depends_only_on_time" ||
        this.slotDetails.booking_type.booking_type ===
          "individual_depends_on_the_number_of_seats"
      ) {
        const correctPriceRent = this.priceFromTime();
        this.$emit("setPrice", correctPriceRent);
      }
      await this.checkSlotsValidity({
        ...params,
      });
      // this.setPromPtCheck();

      // if (this.slotDetails.status) {
      //   const coast = !!this.slotsValidity?.keep_cost;
      //   if (this.slotsValidity.sum) {
      //     const value = this.slotsValidity.sum;
      //     this.$emit("openPromPt", value, coast);
      //   }
      // }
      // let hhjk = this.slotDetails.slots[this.dateForPrice].slice(
      //   this.startSliceIdx,
      //   this.startSliceIdx + this.totalTime
      // );

      this.$emit("isLoad", false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

::v-deep .el-input-number__increase,
.el-input-number__decrease {
  color: $--color-white;
}

.hint {
  margin-bottom: 8px;
}

.hl {
  margin: 15px -30px;
}
.bk-info {
  justify-content: space-between;
}
.bk-info-group {
  display: flex;
  flex-direction: column;
}
::v-deep .select {
  &_status {
    .el-input__inner {
      @apply px-45;
      border-color: transparent !important;
    }
    background: rgba(255, 255, 255, 0.1);
  }
}
.el-alert {
  width: 100%;
}
.radio__time {
  color: rgba(255, 255, 255, 0.5);
  margin-left: 6px;
}
</style>
