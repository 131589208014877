import { setField, setFieldObj, getError } from "@/helpers/store";
import { AJAX_GET, AJAX_DELETE, AJAX_POST, AJAX_PATCH } from "@/units/ajax";

export default {
  namespaced: true,
  state: {
    promocodeItems: null,
    discountItems: null,
    discountItemsCount: null,
  },
  mutations: {
    setField,
    setFieldObj,
  },
  actions: {
    async getPromoDisCount({ commit }) {
      try {
        const { data } = await AJAX_POST("/api/promodiscount/count");
        commit("setField", {
          field: "discountItemsCount",
          value: data,
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getItems({ commit }, key) {
      try {
        commit("setField", {
          field: `${key}Items`,
          value: null,
        });

        const url = key === "discount" ? `${key}/getall` : `${key}/get`;
        const { data } = await AJAX_GET(`/api/${url}`);

        if (data.length > 0) {
          const value = data.map((item) => ({
            opened: false,
            edit: false,
            ...item,
          }));

          // if (key === "discount") {
          //   value.forEach((item) => {
          //     const { resources } = item;
          //     item.resources = [];
          //     resources.forEach((el) => {
          //       item.resources.push(el.id);
          //     });
          //   });
          // }
          commit("setField", {
            field: `${key}Items`,
            value,
          });
        } else {
          commit("setField", {
            field: `${key}Items`,
            value: [],
          });
        }
      } catch (err) {
        console.log(err);

        commit(
          "setNotif",
          {
            title: "Произошла ошибка, попробуйте еще раз",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async deleteItem({ commit }, item) {
      try {
        const { data } = await AJAX_POST(`/api/${item.key}/delete`, {
          hash: item.id,
        });
        if (data.success) {
          commit(
            "setNotif",
            {
              title: `Успешно удалено`,
            },
            { root: true }
          );
        } else {
          commit(
            "setNotif",
            {
              title: data.msg || `Произошла ошибка, попробуйте позже`,
              type: "error",
            },
            { root: true }
          );
        }
      } catch (err) {
        commit(
          "setNotif",
          {
            title: `Произошла ошибка, попробуйте позже`,
            type: "error",
          },
          { root: true }
        );
      }
    },
    async changeStatus({ commit }, item) {
      try {
        const { data } = await AJAX_POST(`/api/${item.key}/switchActive`, {
          hash: item.id,
          active: item.active,
        });
        if (data.success) {
          commit(
            "setNotif",
            {
              title: `Статус изменён`,
            },
            { root: true }
          );
        } else {
          commit(
            "setNotif",
            {
              title: data.msg || `Произошла ошибка, попробуйте позже`,
              type: "error",
            },
            { root: true }
          );
        }
      } catch (err) {
        commit(
          "setNotif",
          {
            title: `Произошла ошибка, попробуйте позже`,
            type: "error",
          },
          { root: true }
        );
      }
    },
    async changeResources({ commit }, item) {
      try {
        const { data } = await AJAX_POST(`/api/${item.key}/edit/resources`, {
          hash: item.hash,
          resources: item.resources,
        });
        if (data.success) {
          commit(
            "setNotif",
            {
              title: `Сохранено`,
            },
            { root: true }
          );
        } else {
          commit(
            "setNotif",
            {
              title: data.msg || `Произошла ошибка, попробуйте позже`,
              type: "error",
            },
            { root: true }
          );
        }
      } catch (err) {
        commit(
          "setNotif",
          {
            title: `Произошла ошибка, попробуйте позже`,
            type: "error",
          },
          { root: true }
        );
      }
    },
    async promoDiscountAll({ commit }, item) {
      try {
        const data = await AJAX_POST(`/api/${item.key}/switch_all`, {
          active: item.flag,
        });

        commit(
          "setNotif",
          {
            title: `${item.flag ? "Не активны" : "Активны"}`,
          },
          {
            root: true,
          }
        );
      } catch (error) {
        console.log(error);
        commit(
          "setNotif",
          {
            title: `Ошибка`,
            type: "error",
          },
          {
            root: true,
          }
        );
      }
    },
  },
};
