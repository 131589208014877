<template>
  <el-form
    ref="mapForm"
    :hide-required-asterisk="true"
    label-position="top"
    :model="{ text }"
    :rules="rules"
    @submit.native.prevent
  >
    <Tabs
      :value="service.address_type"
      :list-link="listOptionsAddress"
      @input="setService({ field: 'address_type', value: $event })"
    />
    <el-form-item v-if="isFromSetting" class="mb-20">
      <p class="describe-text">
        Вы можете выбрать один из ранее добавленных адресов или филиалов в
        разделе Компания.
      </p>
      <p class="hint">Выберите адрес<span class="danger">*</span></p>
      <el-select
        :value="service.address_from_settings_id"
        multiple
        class="w-full"
        placeholder="Выберите адрес"
        @input="
          setService({ field: 'address_from_settings_id', value: $event })
        "
      >
        <el-option
          v-for="item in isListAddressServices.length
            ? isListAddressServices
            : listAddress"
          :key="item.address_id"
          :label="item.address_name"
          :value="item.address_id"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <div v-if="isSetAddress">
      <p class="describe-text">
        Вы можете указать адрес услуги вручную и задать точку на карте.
      </p>
      <ItemAddress
        v-for="(item, i) in service.address"
        :key="`${i}-address`"
        :index="i"
        :list-address="isListAddressServices"
      />
      <el-button @click="addAddress"> Добавить адрес </el-button>
    </div>
    <div v-if="isNotAddress">
      <p class="describe-text">
        Вам подойдет этот вариант, если услуга оказывается онлайн или по адресу
        клиента.
      </p>
      <switch-custom
        :value="service.service_provided_online"
        title="Услуга оказывается онлайн?"
        @input="setService({ field: 'service_provided_online', value: $event })"
      />
      <switch-custom
        :value="service.service_provided_exit"
        title="Услуга оказывается на выезде?"
        @input="setService({ field: 'service_provided_exit', value: $event })"
      />
      <switch-custom
        :value="service.request_address_on_form"
        title="Запрашивать адрес клиента в форме бронирования?"
        @input="setService({ field: 'request_address_on_form', value: $event })"
      />
    </div>
    <div v-if="isRoute">
      <p class="describe-text">
        Вы можете добавить маршрут - несколько адресов и временные интервалы
        между ними.
      </p>
      <div class="list-fields">
        <div
          v-for="(adrs, index) in service.address_route"
          :key="index"
          class="list-fields__row"
        >
          <div class="list-fields__item">
            <InputAddress
              :label="index ? `Точка маршрута N${index}` : 'Старт маршрута'"
              :value="adrs"
              @input="setRoute($event, index)"
            />
          </div>
          <div class="list-fields__item input-select">
            <InputSelect
              v-if="index"
              :value="adrs.address_route_duration"
              prop="arrival"
              label="Прибытие через"
              :list-value="listDuration"
              :select-value="adrs.address_route_unit"
              placeholder="Введите время"
              @input="
                setServiceRoute({
                  index,
                  field: 'address_route_duration',
                  value: $event,
                })
              "
              @setValue="
                setServiceRoute({
                  index,
                  field: 'address_route_unit',
                  value: $event,
                })
              "
            ></InputSelect>
          </div>
          <div class="list-fields__actions">
            <el-button
              v-if="index && service.address_route.length > 1"
              class="delete-button"
              icon="el-icon-close"
              @click="deleteField(index)"
            ></el-button>
            <div v-else class="list-fields__actions-stub"></div>
          </div>
        </div>
        <el-button class="add-button" @click="addItemRoute()">
          Добавить точку маршрута
        </el-button>
      </div>
    </div>
  </el-form>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import Tabs from "@/components/Tabs.vue";
import InputAddress from "@/views/Services/Create/components/inputAddress";
import ItemAddress from "@/views/Services/Create/components/ItemAddress";
import { mapSettings } from "@/helpers/SettingsYandex.js";

const tzlookup = require("tz-lookup");

export default {
  name: "Map",
  components: { Tabs, InputAddress, ItemAddress },
  data() {
    return {
      markerKey: 1,
      mapCoords: [59.939095, 30.315868],
    };
  },
  computed: {
    ...mapState("service", ["service"]),
    ...mapGetters("service", ["isNew", "isListAddressServices"]),
    ...mapGetters("user", ["listAddress"]),
    mapSettings() {
      return mapSettings;
    },
    isSetAddress() {
      return this.service.address_type === "writeAddress";
    },
    isFromSetting() {
      return this.service.address_type === "fromSetting";
    },
    isNotAddress() {
      return this.service.address_type === "noAddress";
    },
    isRoute() {
      return this.service.address_type === "route";
    },
  },
  created() {
    this.listOptionsAddress = [
      {
        title: "Из настроек",
        value: "fromSetting",
      },
      {
        title: "Указать адрес",
        value: "writeAddress",
      },
      {
        title: "Без адреса",
        value: "noAddress",
      },
      {
        title: "Маршрут",
        value: "route",
      },
    ];
    this.listDuration = [
      {
        label: "мин",
        value: "minutes",
      },
      {
        label: "часы",
        value: "hors",
      },
    ];
    this.rules = {
      arrival: [{ required: true, message: "заполните поле", trigger: "blur" }],
    };
    if (!this.service.address_from_settings_id.length)
      this.setService({
        field: "address_from_settings_id",
        value: [this.listAddressInService[0].address_id],
      });
  },
  methods: {
    ...mapMutations("service", [
      "setService",
      "setServiceRoute",
      "addAddress",
      "addItemRoute",
      "deleteField",
    ]),
    validateMap() {
      this.$refs.mapForm.validate((valid) => {
        this.$emit("validate", true);

        // if (this.isNotAddress || this.isFromSetting) {
        //   this.$emit("validate", true);
        // } else if (this.isRoute) {
        // } else this.$emit("validate", valid);
      });
    },
    getCityName(geoObject) {
      return geoObject.getLocalities().length
        ? geoObject.getLocalities().join(", ")
        : geoObject.getAdministrativeAreas().join(", ");
    },
    setRoute(value, index) {
      const qwerty = Object.entries(value).map((item) => {
        return { key: item[0], value: item[1] };
      });
      qwerty.forEach((item) => {
        this.setServiceRoute({
          index,
          field: item.key,
          value: item.value,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.describe-text {
  color: $--color-white-5;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 30px;
}
.hint {
  margin-bottom: 12px;
  font-size: 12px;
}

::v-deep .tabs {
  margin-bottom: 20px;
}
.list-fields__item {
  width: none;
}
.input-select {
  width: 22px;
}
.add-button {
  margin: 0 10px;
}
.delete-button {
  padding-left: 13px;
  padding-right: 13px;
  ::v-deep i {
    font-size: 22px;
    margin-bottom: 3px;
  }
}
</style>
