<template>
  <div class="list-fields">
    <div
      v-for="(item, index) in listField"
      :key="index"
      class="list-fields__row"
    >
      <div class="list-fields__item time-picker">
        <p class="hint-title flex">
          {{ headers[0].title }}
          <span v-if="headers[0].valid" class="danger">*</span>
          <el-popover
            v-if="headers[0].popoverText"
            class="ml-8"
            placement="top"
            width="200"
            trigger="hover"
            :content="headers[0].popoverText"
          >
            <Tooltip slot="reference" />
          </el-popover>
        </p>
        <el-time-picker
          v-model="item.time"
          is-range
          range-separator="По"
          start-placeholder="Любое"
          format="HH:mm"
          end-placeholder="Любое"
          @input="emitData()"
        >
        </el-time-picker>
      </div>
      <div class="list-fields__item input-select">
        <InputSelect
          v-model="item.duration"
          :tooltip-text="headers[1].popoverText"
          prop="durationService"
          :label="headers[1].title"
          :list-value="listDuration"
          :select-value="item.unit"
          :placeholder="headers[1].placeholder"
          @setValue="listField[index].unit = $event"
          @input="emitData()"
        ></InputSelect>
      </div>
      <div class="list-fields__actions">
        <ButtonIcon icon-name="close" width="16" @click="deleteField(index)" />
      </div>
    </div>
    <el-button class="list-fields__add-button" @click="addField()">{{
      titleButton
    }}</el-button>
  </div>
</template>

<script>
import { listDuration } from "@/helpers/services.js";
import moment from "moment";

export default {
  name: "ListField2v2",
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    titleButton: {
      type: String,
      default: "Добавить новое поле",
    },
    placeholder: {
      type: String,
      default: "Введите значение",
    },
    headers: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      listField: [
        {
          time: [null],
          duration: 60,
          unit: "minutes",
        },
      ],
    };
  },
  watch: {
    value() {
      this.listField = [...this.value];
    },
  },
  created() {
    this.listDuration = listDuration.filter((item) => item.value !== "days");
    this.listField = [...this.value];
    // this.listField[0].time = [
    //   "Sat Apr 16 2022" +
    //     " " +
    //     `${this.listField[0].time[0]}` +
    //     " " +
    //     "GMT+0300 (Москва, стандартное время)",
    //   "Sat Apr 16 2022" +
    //     " " +
    //     `${this.listField[0].time[1]}` +
    //     " " +
    //     "GMT+0300(Москва, стандартное время)",
    // ];
    this.emitData();
  },
  methods: {
    deleteField(index) {
      this.listField.splice(index, 1);
      this.emitData();
    },
    addField() {
      this.listField.push({
        time: null,
        duration: 60,
        unit: "minutes",
      });
    },
    emitData() {
      this.$emit("input", [...this.listField]);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-select ::v-deep .el-form-item {
  width: 100%;
}

.time-picker {
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
}
::v-deep .wrapper-is__title-text {
  margin-bottom: 2px;
}
</style>
