var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.scheduleIsLoading || _vm.isLoad),expression:"scheduleIsLoading || isLoad"}],staticClass:"schedule"},[_vm._l((_vm.schedule),function(item,i){return _c('div',{key:i,staticClass:"schedule__item"},[_c('div',{staticClass:"schedule__item-name"},[_c('el-popover',{staticClass:"dots my-8",attrs:{"placement":"bottom-start","popper-class":"control-poper","trigger":"click"}},[_c('span',{attrs:{"slot":"reference"},slot:"reference"},[_c('svg-icon',{attrs:{"icon-name":"dots","class-name":"text-white-500","width":"20","height":"30"}})],1),_c('div',{staticClass:"services-drag__wrapper"},[_c('router-link',{staticClass:"services-drag__item-icon",attrs:{"to":{
              name: 'resource',
              params: { id: item.id, hash: item.ids },
              query: { tab: 'schedule' },
            }}},[_c('div',{staticClass:"wrapper-icon"},[(!_vm.isResourceBull(_vm.user.resources, item.ids).schedule)?_c('div',{staticClass:"wrapper-icon__point"}):_vm._e(),_c('svg-icon',{attrs:{"icon-name":"eye","width":"20","height":"14"}})],1),_c('span',[_vm._v("Расписание ")])]),_c('router-link',{staticClass:"services-drag__item-icon",attrs:{"to":{
              name: 'resource',
              params: { id: item.id, hash: item.ids },
              query: { tab: 'description' },
            }}},[_c('div',{staticClass:"wrapper-icon"},[_c('svg-icon',{attrs:{"icon-name":"warning-outline","width":"20","height":"20"}})],1),_c('span',[_vm._v("Описание")])]),_c('router-link',{staticClass:"services-drag__item-icon",attrs:{"to":{
              name: 'resource',
              params: { id: item.id, hash: item.ids },
              query: { tab: 'datetime' },
            }}},[_c('div',{staticClass:"wrapper-icon"},[(!_vm.isResourceBull(_vm.user.resources, item.ids).schedule)?_c('div',{staticClass:"wrapper-icon__point"}):_vm._e(),_c('svg-icon',{attrs:{"icon-name":"datetime","width":"20","height":"16"}})],1),_c('span',[_vm._v("Даты и цены")])]),_c('router-link',{staticClass:"services-drag__item-icon",attrs:{"to":{
              name: 'resource',
              params: { id: item.id, hash: item.ids },
              query: { tab: 'settings' },
            }}},[_c('svg-icon',{attrs:{"icon-name":"settings","width":"22","height":"20"}}),_c('span',[_vm._v("Настройки")])],1),_c('router-link',{staticClass:"services-drag__item-icon",attrs:{"to":{
              name: 'resource',
              params: { id: item.id, hash: item.ids },
              query: { tab: 'notifications' },
            }}},[_c('div',{staticClass:"wrapper-icon"},[(!_vm.isResourceBull(_vm.user.resources, item.ids).notifications)?_c('div',{staticClass:"wrapper-icon__point"}):_vm._e(),_c('svg-icon',{attrs:{"icon-name":"news2","width":"22","height":"21"}})],1),_c('span',[_vm._v("Уведомления")])]),_c('router-link',{staticClass:"services-drag__item-icon",attrs:{"to":{
              name: 'resource',
              params: { id: item.id, hash: item.ids },
              query: { tab: 'integration' },
            }}},[_c('div',{staticClass:"wrapper-icon"},[(!_vm.isResourceBull(_vm.user.resources, item.ids).widgets)?_c('div',{staticClass:"wrapper-icon__point"}):_vm._e(),_c('svg-icon',{attrs:{"icon-name":"integration","width":"22","height":"16"}})],1),_c('span',[_vm._v("Виджеты")])])],1)]),_c('span',{staticClass:"schedule__item-title"},[_vm._v(" "+_vm._s(item.name))])],1),_c('div',{staticClass:"mb-10"},[(_vm.relatedServices(item).name)?_c('div',{staticClass:"inline-flex items-center"},[_c('svg-icon',{attrs:{"icon-name":"overlap","width":"20","height":"20"}}),_c('span',{staticClass:"items__bind"},[_vm._v(_vm._s(_vm.relatedServices(item).relat === "child" ? "Дочерняя услуга:" : "Родительская услуга:")+" "),_c('span',_vm._l((_vm.relatedServices(item).name),function(serv,idx){return _c('span',{key:serv.id},[_c('router-link',{staticClass:"services-drag__item-icon inline-flex underline px-5",attrs:{"to":{
                  name: 'resource',
                  params: { hash: serv.hash },
                  query: { tab: 'schedule' },
                },"target":"_blank"}},[_vm._v(_vm._s(serv.name)+_vm._s(_vm.relatedServices(item).name.length - 1 === idx ? " " : ","))])],1)}),0)])],1):_vm._e()]),_c('div',{staticClass:"schedule__item-schedule"},[(!!item.msg[0])?_c('div',{staticClass:"custom-alert not-set-schedule"},[_vm._v(" "+_vm._s(item.msg[0])+" ")]):_vm._e(),_vm._l((item.slots),function(slot,index){return _c('div',{key:index,staticClass:"schedule-item text-center cell relative mr-7 flex flex-col items-center mb-17",class:{
          disabledSlot: _vm.disabledTime(slot),
          pointsSlot:
            slot ||
            slot.clientHash ||
            slot.bookingCount ||
            slot.is_blocked ||
            slot.booking_hash,
        }},[(!item.msg[0])?_c('div',[_c('SlotStatus',{attrs:{"item":slot,"data":_vm.dateSlide,"dis":_vm.disabledTime(slot),"name-service":item.name,"hash":item.ids},on:{"openForm":_vm.openFormInSlot}}),_c('div',[_c('div',{staticClass:"cell__time bg-white-150 text-white-normal flex items-center justify-center font-semibold w-65 h-35 rounded-sm mb-5 cursor-pointer",class:{ disabledSlotTime: slot.active === false }},[_vm._v(" "+_vm._s(slot.time)+" ")]),_c('span',{staticClass:"cell__price text-white-500 text-12"},[_vm._v(_vm._s(slot.price)+" "+_vm._s(slot.currency.toUpperCase()))])])],1):_vm._e()])})],2)])}),_c('el-dialog',{attrs:{"title":"Бронирование услуги","visible":_vm.reserveSlot.state,"width":"100%","show-close":false,"custom-class":"reserve-slot-form bg-gray-1100"},on:{"update:visible":function($event){return _vm.$set(_vm.reserveSlot, "state", $event)},"closed":function($event){_vm.reserveSlot.data = null}}},[_c('div',{staticClass:"absolute fast-add-close",attrs:{"slot":"title"},on:{"click":function($event){_vm.reserveSlot.state = false}},slot:"title"},[_c('div',{staticClass:"cursor-pointer"},[_c('svg-icon',{attrs:{"icon-name":"close","width":"12","height":"12","class-name":"text-white-normal"}})],1)]),_c('form-reserve',{attrs:{"data":{ resource: _vm.resource, slot: _vm.reserveSlot.data }},on:{"onReserve":function($event){_vm.reserveSlot.state = false}}})],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }