<template>
  <el-dialog
    :visible.sync="show"
    :show-close="false"
    :before-close="handleClose"
    custom-class="max-w-lg w-full max-w-xs"
  >
    <div slot="title" class="absolute fast-add-close" @click="$emit('close')">
      <div class="cursor-pointer">
        <svg-icon
          icon-name="close"
          width="12"
          height="12"
          class-name="text-white-normal"
        ></svg-icon>
      </div>
    </div>
    <div class="p-40 pt-0">
      <div class="pt-20 text-white-normal text-18 mb-5">
        {{ title }}
      </div>
      <div v-if="subtitle" class="small_text mt-20">
        {{ subtitle }}
      </div>
      <slot></slot>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    title: String,
    subtitle: String,
  },
  methods: {
    handleClose(done) {
      this.$emit("close");
      done();
    },
  },
};
</script>

<style lang="scss" scoped>
.small_text {
  line-height: 24px;
  @apply text-14 font-medium text-white-normal;
}
</style>

<style>
.modal__wrapper {
  background: rgba(0, 0, 0, 0.2);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
.modal__wrapper .modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  height: 260px;
  background: white;
  border-radius: 20px;
}
.modal__wrapper .modal .modal__body {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}
.modal__wrapper .modal .modal__body {
  margin: 20px;
}
.modal__wrapper .modal .modal__body {
  font-size: 25px;
  position: relative;
  top: -30px;
}

.modal__wrapper .modal .modal__body p {
  position: relative;
  top: -10px;
  font-size: 13px;
}

.modal__wrapper .modal .modal__buttons {
  position: absolute;
  bottom: 30px;
  right: 10px;
}
.modal__wrapper .modal .modal__buttons .btn_footer {
  border: none;
  padding: 10px 15px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 10px;
  width: 145px;
  height: 45px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  margin: 0 10px;
  color: rgba(0, 0, 0, 0.5);
}
.modal__wrapper .modal .modal__buttons button.btn-purple {
  background: rgba(150, 39, 234, 1);
  color: white;
}
</style>
