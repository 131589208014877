<template>
  <!-- <div class="pt-40 pb-80 w-full max-w-lg settings lg:pt-15 px-10"> -->
  <div class="pt-40 pb-80 w-full lg:pt-15 px-10">
    <Top title="Инструкции" :font="{ size: 22, leading: 22 }" />
    <!-- <div>
      <h1
        class="font-semibold text-white-normal text-28 leading-44 lg:text-22 lg:leading22 mb-20 xs:mb-5"
      >
        Инструкции
      </h1>
    </div> -->
    <spinner v-if="loading" />
    <doc-items :docs="docs"></doc-items>
  </div>
</template>

<script>
import { AJAX_GET } from "../units/ajax";
import DocItems from "../components/DocItems";
import Top from "@/components/Pages/Top";

export default {
  name: "Docs",
  components: { DocItems, Top },
  data() {
    return {
      docs: [],
      loading: true,
    };
  },
  created() {
    AJAX_GET("/api/instructions/get")
      .then((response) => {
        response.data.instructions_page.forEach((doc) => {
          this.docs.push(doc);
        });
        this.loading = false;
      })
      .catch((err) => {
        console.log(err);
        this.loading = false;
      });
  },
};
</script>

<style scoped lang="scss">
.docs-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 20px;
}
</style>
