<template>
  <div class="relative">
    <spinner v-if="loading" />
    <el-table :data="items" class="border-b-0 mb-20" sortable :stripe="true">
      <el-table-column prop="id" label="№" sortable :width="62" />
      <el-table-column
        prop="date"
        sortable
        label="Создан"
        class="date"
        :width="170"
      />
      <el-table-column
        prop="reservation"
        class="sender"
        sortable
        label="Ссылка на чат"
        :min-width="313"
      >
        <div slot-scope="{ row }">
          <a :href="row.link" target="_blank">{{ row.link }}</a>
        </div>
      </el-table-column>
      <el-table-column prop="name" sortable label="Имя" :min-width="130" />
      <el-table-column prop="phone" sortable label="Телефон" :min-width="160" />
      <el-table-column prop="email" sortable label="E-mail" :min-width="240" />
      <el-table-column
        prop="id_visit"
        sortable
        label="ID визита"
        :width="108"
      />
      <el-table-column
        prop="consultant"
        sortable
        label="Консультант"
        :width="108"
      />
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      items: [
        {
          id: 1,
          date: "2021-03-15 21:45:55",
          link:
            "htps:wpr_bitrix24.ru/workgroups/group/678/tasks/task/view/26646/",
          name: "Екатерина",
          phone: "+7 (921) 341-09-00",
          email: "longmailexample@mail.ru",
          id_visit: "32452900",
          consultant: "Operator 1",
        },
        {
          id: 2,
          date: "2021-03-15 21:45:55",
          link:
            "htps:wpr_bitrix24.ru/workgroups/group/678/tasks/task/view/26646/",
          name: "Екатерина",
          phone: "+7 (921) 341-09-00",
          email: "longmailexample@mail.ru",
          id_visit: "32452900",
          consultant: "Operator 1",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.small_text {
  white-space: nowrap;
}
</style>
