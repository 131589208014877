<template>
  <section class="notification">
    <BookingTemplate
      ref="template"
      type="booking_reminder"
      subtitle="Отправляется клиенту как напоминание."
      class="notification__block"
      :items-admin="items.admin"
      :items-user="items.user"
      :default-items-user="defaultItems.user"
      :default-items-admin="defaultItems.admin"
      :loading-admin="loading.admin"
      :loading-user="loading.user"
      @save="save"
    />
  </section>
</template>

<script>
import BookingTemplate from "@/components/Notifications/Booking/BookingTemplate";

export default {
  components: {
    BookingTemplate,
  },
  props: {
    items: Object,
    defaultItems: Object,
    loading: Object,
  },
  data() {
    return {};
  },
  methods: {
    save(item) {
      this.$emit("save", {
        ...item.items,
        type: `booking_reminder_${item.key}`,
      });
    },
  },
};
</script>
