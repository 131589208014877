import { AJAX_GET, AJAX_POST } from "@/units/ajax";
import { setField } from "@/helpers/store";

export default {
  namespaced: true,
  state: {
    resource: {},
    isFilled: {},
  },
  getters: {
    resource: (state) => state.resource,
    resourceAddress: (state) => {
      return {
        country: state.resource.country,
        city: state.resource.city,
        address: state.resource.address,
        timezone: state.resource.timezone,
      };
    },
  },
  mutations: {
    setField,

    setResource(state, resource) {
      state.resource = resource;
    },
    getIsFilled(state, payload) {
      state.isFilled = payload;
    },
  },
  actions: {
    setResource({ commit }, resourceId) {
      AJAX_GET(`/api/me/resource?id=${resourceId}`, { noCancel: true }).then(
        (response) => {
          commit("setResource", response.data);
        }
      );
    },
    getIsFilled({ commit }, resourceId) {
      AJAX_GET(`/api/resource/isFilled/${resourceId}`).then((res) => {
        commit("getIsFilled", res.data);
      });
    },
  },
};
