<template>
  <Modal class="modal" :show="show" @close="$emit('close')">
    <div v-if="data.ordersource_data.length">
      <h2 class="pt-20 text-white-normal text-18 mb-5">Источник</h2>
      <div
        v-for="(ordersource, index) in data.ordersource_data"
        :key="index"
        class="custom-block"
      >
        <div>
          {{ ordersource.name }}:
          <span class="custom__text" :title="ordersource.value">
            {{ ordersource.value }}</span
          >
        </div>
      </div>
    </div>
    <div v-if="data.visitor_data.length">
      <h2 class="pt-20 text-white-normal text-18 mb-5">Данные клиента</h2>
      <div
        v-for="visitor in data.visitor_data"
        :key="visitor.name"
        class="custom-block"
      >
        <div>
          {{ visitor.name }}:
          <span class="custom__text" :title="visitor.value">
            {{ visitor.value }}</span
          >
        </div>
      </div>
    </div>

    <div v-if="data.utm_data.length" class="">
      <h2 class="pt-20 text-white-normal text-18 mb-5">UTM</h2>
      <div v-for="(utm, idx) in data.utm_data" :key="idx" class="custom-block">
        <div>
          {{ utm.name }}:
          <span class="custom__text" :title="utm.value">{{ utm.value }}</span>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";

export default {
  components: {
    Modal,
  },
  props: {
    show: Boolean,
    data: {
      type: [Object, Array],
    },
  },
  mounted() {
    this.setReserveInfo();
  },
  methods: {
    setReserveInfo() {
      console.log(this.data, "UTM");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

::v-deep .el-dialog {
  width: 378px;
  padding-top: 10px;
}
.custom-block {
  line-height: 24px;
  @apply mb-0 py-1 px-15 text-white-normal text-14;
}
.custom__text {
  font-weight: 300;
  padding-bottom: 3px;
  &-substr {
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    max-height: 50px;
  }
}
</style>
