<template>
  <div class="pt-40 pb-80 w-full max-w-lg resource lg:pt-15">
    <div class="lg:px-15">
      <el-button size="medium" class="mb-30 lg:mb-20 px-12" @click="goBack"
        ><span class="flex items-center">
          <svg-icon
            icon-name="arrow"
            class="text-white-normal rotate-180 mr-10"
            width="13"
            height="8"
          ></svg-icon
          >{{ mode === "single" ? "К настройкам интеграции" : "К виджетам" }}
        </span></el-button
      >

      <h1
        class="text-28 leading-44 font-semibold text-white-normal mb-20 lg:text-22 lg:leading-36"
      >
        Создание виджета с индивидуальным дизайном
      </h1>
      <el-alert
        title="Виджет создан"
        type="success"
        class="mb-40"
        :closable="false"
        show-icon
      >
      </el-alert>
    </div>

    <div>
      <el-row type="flex" class="flex-wrap" :gutter="30">
        <el-col :span="$mq.tablet ? 24 : $mq.desktop ? 12 : 16">
          <div class="bg-white-40 rounded-sm">
            <div class="pt-20 px-30 pb-40 lg:px-15">
              <change-widget-mode
                v-if="mode === 'multiple'"
              ></change-widget-mode>
              <h3 class="text-18 leading-30 mb-20">
                Настройте внешний вид модуля
              </h3>
              <div>
                <el-row :gutter="30" type="flex" class="flex-wrap">
                  <el-col :span="$mq.desktop ? 24 : 12" class="mb-30">
                    <div class="flex flex-col items-center">
                      <span class="text-12 leading-16 mb-8">Цвет - время</span>
                      <div class="w-full flex relative">
                        <div
                          class="absolute color-picker-sign text-black z-50 text-12 leading-16 pointer-events-none"
                        >
                          {{ colors.time }}
                        </div>
                        <el-color-picker
                          v-model="colors.time"
                        ></el-color-picker>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="$mq.desktop ? 24 : 12" class="mb-30">
                    <div class="flex flex-col items-center">
                      <span class="text-12 leading-16 mb-8">Цвет - цена</span>
                      <div class="w-full flex relative">
                        <div
                          class="absolute color-picker-sign text-black z-50 text-12 leading-16 pointer-events-none"
                        >
                          {{ colors.price }}
                        </div>
                        <el-color-picker
                          v-model="colors.price"
                        ></el-color-picker>
                      </div>
                    </div>
                  </el-col>
                </el-row>

                <el-row :gutter="30" type="flex" class="flex-wrap">
                  <el-col :span="$mq.desktop ? 24 : 12" class="mb-30">
                    <div class="flex flex-col items-center">
                      <span class="text-12 leading-16 mb-8"
                        >Цвет фона ячейки</span
                      >
                      <div class="w-full flex relative">
                        <div
                          class="absolute color-picker-sign text-black z-50 text-12 leading-16 pointer-events-none"
                        >
                          {{ colors.bg }}
                        </div>
                        <el-color-picker v-model="colors.bg"></el-color-picker>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="$mq.desktop ? 24 : 12" class="xl:mb-30">
                    <div class="flex flex-col items-center">
                      <span class="text-12 leading-16 mb-8"
                        >Цвет кнопок смены недели</span
                      >
                      <div class="w-full flex relative">
                        <div
                          class="absolute color-picker-sign text-black z-50 text-12 leading-16 pointer-events-none"
                        >
                          {{ colors.changeWeek }}
                        </div>
                        <el-color-picker
                          v-model="colors.changeWeek"
                        ></el-color-picker>
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <el-row
                  :gutter="30"
                  type="flex"
                  class="flex-wrap mb-30 xl:mb-0"
                >
                  <el-col :span="$mq.desktop ? 24 : 12" class="xl:mb-30">
                    <div class="flex flex-col items-center">
                      <span class="text-12 leading-16 mb-8"
                        >Цвет текста кнопок смены недели</span
                      >
                      <div class="w-full flex relative">
                        <div
                          class="absolute color-picker-sign text-black z-50 text-12 leading-16 pointer-events-none"
                        >
                          {{ colors.changeWeekText }}
                        </div>
                        <el-color-picker
                          v-model="colors.changeWeekText"
                        ></el-color-picker>
                      </div>
                    </div>
                  </el-col>
                  <el-col :span="$mq.desktop ? 24 : 12" class="xl:mb-30">
                    <div class="flex flex-col items-center">
                      <span class="text-12 leading-16 mb-8"
                        >Цвет фона сайта</span
                      >
                      <div class="w-full flex relative">
                        <div
                          class="absolute color-picker-sign text-black z-50 text-12 leading-16 pointer-events-none"
                        >
                          {{ colors.widgetBg }}
                        </div>
                        <el-color-picker
                          v-model="colors.widgetBg"
                        ></el-color-picker>
                      </div>
                    </div>
                  </el-col>
                </el-row>
                <el-row :gutter="30" type="flex" class="flex-wrap">
                  <el-col :span="$mq.desktop ? 24 : 12" class="xl:mb-30">
                    <div class="flex flex-col px-10">
                      <span class="text-12 leading-16 mb-8 self-center"
                        >Прозрачность фона</span
                      >
                      <el-slider v-model="opacity.value" :marks="opacity.marks">
                      </el-slider>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div v-if="!$mq.tablet" class="p-30 border-t border-white-150">
              <div class="flex items-center xl:flex-col xl:items-stretch">
                <el-button
                  type="primary"
                  class="bg-gradient-b-yellow border-0 xs:w-full mr-30 xl:mr-0 xl:mb-20"
                  @click="showCode = true"
                  >Получить код</el-button
                >
                <div
                  v-if="!showCode"
                  class="rounded-sm border border-blue-100 bg-blue-10"
                >
                  <div
                    class="text-blue-200 py-13 px-17 leading-none xl:text-center"
                  >
                    Нажмите на “Получить код”, чтобы получить код виджета
                  </div>
                </div>
              </div>
              <div v-if="showCode" class="mt-30">
                <div class="text-18 leading-30 font-semibold mb-3">
                  Скопируйте полученный код и вставьте на страницу Вашего сайта
                </div>
                <div class="text-white-500 text-12 leading-16 mb-30">
                  Чтобы внедрить виджет с расписанием, скопируйте полученный код
                  и вставьте его на внутреннюю страницу вашего сайта.
                </div>
                <div
                  class="rounded-sm border border-blue-100 bg-blue-10 widget-code"
                >
                  <el-input
                    ref="code"
                    type="textarea"
                    :value="value"
                    resize="none"
                    class="bg-transparent max-h-500 overflow-auto text-12"
                    :autosize="true"
                  ></el-input>
                </div>
                <el-button class="px-15 lg:w-full mt-20" @click="copyCode">
                  <div class="flex items-center justify-center">
                    <svg-icon
                      icon-name="copy"
                      class-name="text-white-normal mr-8"
                      width="16"
                      height="18"
                    ></svg-icon
                    >Скопировать код
                  </div></el-button
                >
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="$mq.tablet ? 24 : $mq.desktop ? 12 : 8">
          <div
            class="px-30 pt-20 pb-30 lg:px-15"
            :style="{ backgroundColor: colors.widgetBg }"
          >
            <h3 class="text-14 leading-28 mb-20">
              Ваше расписание будет выглядеть так:
            </h3>
            <div class="grid col-gap-7 row-gap-22 schedule-item__wrapper">
              <div
                v-for="(slot, index) in 20"
                :key="index"
                class="schedule-item flex flex-col items-center"
              >
                <div
                  class="relative flex items-center justify-center font-semibold w-full h-35 rounded-sm mb-5 cursor-pointer"
                  :style="{ color: colors.time }"
                >
                  <span class="relative z-20">23:00</span>
                  <div
                    class="absolute z-10 w-full h-full top-0 left-0 rounded-sm"
                    :style="{
                      backgroundColor: colors.bg,
                      opacity: `${opacity.value}%`,
                    }"
                  ></div>
                </div>
                <span class="text-12" :style="{ color: colors.price }"
                  >600 ₽</span
                >
              </div>
            </div>
            <div class="flex w-full mt-40">
              <el-button
                type="info"
                size="small"
                class="schedule-nav-btn text-14 px-20 lg:px-14 xs:px-10 border-0 w-full"
                :style="{
                  backgroundColor: colors.changeWeek,
                  color: colors.changeWeekText,
                }"
                ><div class="flex items-center justify-center">
                  <svg-icon
                    icon-name="arrow"
                    class-name="text-white-normal mr-10"
                    :style="{ color: colors.changeWeekText }"
                    width="13"
                    height="8"
                  ></svg-icon
                  >{{
                    $mq.tablet ? "Кнопка смены недели" : "Кнопка смены недели"
                  }}
                </div></el-button
              >
            </div>
          </div>
          <div
            v-if="$mq.tablet"
            class="p-30 border-t border-white-150 lg:px-15"
          >
            <div class="flex items-center xl:flex-col xl:items-stretch">
              <el-button
                type="primary"
                class="bg-gradient-b-yellow border-0 xs:w-full mr-30 xl:mr-0 xl:mb-20"
                @click="showCode = true"
                >Получить код</el-button
              >
              <div
                v-if="!showCode"
                class="rounded-sm border border-blue-100 bg-blue-10"
              >
                <div
                  class="text-blue-200 py-13 px-17 leading-none xl:text-center"
                >
                  Нажмите на “Получить код”, чтобы получить код виджета
                </div>
              </div>
            </div>
            <div v-if="showCode" class="mt-30">
              <div class="text-18 leading-30 font-semibold mb-3">
                Скопируйте полученный код и вставьте на страницу Вашего сайта
              </div>
              <div class="text-white-500 text-12 leading-16 mb-30">
                Чтобы внедрить виджет с расписанием, скопируйте полученный код и
                вставьте его на внутреннюю страницу вашего сайта.
              </div>
              <div
                class="rounded-sm border border-blue-100 bg-blue-10 widget-code"
              >
                <el-input
                  ref="code"
                  type="textarea"
                  :value="value"
                  resize="none"
                  class="bg-transparent max-h-500 overflow-auto text-12"
                  :autosize="true"
                ></el-input>
              </div>
              <el-button class="px-15 lg:w-full mt-20" @click="copyCode">
                <div class="flex items-center justify-center">
                  <svg-icon
                    icon-name="copy"
                    class-name="text-white-normal mr-8"
                    width="16"
                    height="18"
                  ></svg-icon
                  >Скопировать код
                </div></el-button
              >
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import ChangeWidgetMode from "../components/ChangeWidgetMode";

export default {
  name: "CreateCustomWidget",
  components: { ChangeWidgetMode },
  data() {
    return {
      opacity: {
        value: 100,
        marks: {
          0: "0%",
          25: "25%",
          50: "50%",
          75: "75%",
          100: "100%",
        },
      },
      mode: "single",
      showCode: false,
      colors: {
        time: "#FFBD1A",
        price: "#3498DB",
        bg: "#B357D8",
        changeWeek: "#3498DB",
        changeWeekText: "#000000",
        widgetBg: "#1E0B54",
      },
    };
  },
  computed: {
    value() {
      return '<iframe id="scheduleWidget" width="100%" src="https://broni.biz/order/?questId=24&userId=9&theme=gray" frameborder="0" scrolling="no" height="750"> Ваш браузер не поддерживает плавающие фреймы! </iframe><script>function widgetHeight(){let frame=document.getElementById("scheduleWidget"),height=0,listener=function(event){if(event.data.constructor !== Object || event.data.type=="height"){height=parseInt(event.data.height);if(height!=frame.height){frame.style.height=height+"px"}}if(event.data.type=="scroll"){window.scrollTo({"top":document.getElementById("scheduleWidget").getBoundingClientRect().top + pageYOffset - 50})}};function addEvent(elem,evnt,func){if(elem.addEventListener){elem.addEventListener(evnt,func,!1)}else if(elem.attachEvent){elem.attachEvent("on"+evnt,func)}else{elem["on"+evnt]=func}}addEvent(window,"message",listener)}widgetHeight() <\/script>';
    },
  },
  mounted() {
    this.mode = this.$route.query.mode || "single";
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    copyCode() {},
  },
};
</script>

<style scoped lang="scss">
.schedule-item__wrapper {
  grid-template-columns: repeat(auto-fill, minmax(0, 69px));
}
</style>
