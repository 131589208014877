<template>
  <div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="70%"
      :show-close="false"
      :before-close="handleClose"
    >
      <div class="p-40">
        <h3 class="mb-30 text-18 text-white-normal">Выберите новый тариф</h3>
        <div
          slot="title"
          class="absolute fast-add-close"
          @click="$emit('close')"
        >
          <div class="cursor-pointer">
            <svg-icon
              icon-name="close"
              width="12"
              height="12"
              class-name="text-white-normal"
            ></svg-icon>
          </div>
        </div>
        <spinner v-if="isLoading" />

        <div v-else class="flex justify-between my-35 md:flex-col flex-wrap">
          <TariffCard
            v-for="card in tariffs"
            :key="card.id"
            :tariff="card"
            :checked="checked === card.id ? true : false"
            :class="{ tariffCheck: checked === card }"
            class="my-5"
            @click="checkNewTariff(card)"
          />
        </div>
        <span class="text-white-500 text-12 leading-20"
          >После выбора тарифа вам необходимо будет внести оплату за месяц
          вперед. После успешной оплаты последующие списания будут происходить
          ежемесячно.
        </span>
        <span slot="footer" class="dialog-footer flex justify-between mt-35">
          <el-button @click="$emit('close')">Отмена</el-button>
          <el-button type="primary" @click="sendCheckTariff()"
            >Сохранить</el-button
          >
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TariffCard from "@/components/TariffCard";
import { mapActions } from "vuex";

export default {
  name: "TariffChangeForm",
  components: {
    TariffCard,
  },
  props: {
    tariffs: {
      type: Array,
    },
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    checkedTariff: {
      type: Object,
    },
  },
  data() {
    return {
      checked: 1,
      loading: false,
    };
  },
  computed: {
    isLoading() {
      return this.tariffs.length === 0;
    },
  },
  created() {
    this.checked = Number(this.checkedTariff.tariff);
  },
  methods: {
    ...mapActions("user", ["changeUserTariff"]),

    checkNewTariff(e) {
      this.checked = e.id;
    },
    handleClose(done) {
      this.$emit("close");
      done();
    },
    sendCheckTariff() {
      this.changeUserTariff(this.checked);
    },
  },
};
</script>
