<template>
  <div>
    <div class="flex flex-col justify-between">
      <div class="flex justify-between">
        <span class="order__row-text mb-10">Цена </span>
        <span class="order__row px-5"
          >{{ service.consist.individual.price }} {{ service.currency }}
        </span>
      </div>
      <div class="flex justify-between">
        <span class="order__row-text mb-10">Участников </span>
        <span class="order__row px-5"
          >{{ service.consist.individual.persons }}
        </span>
      </div>
      <div v-if="isAdditionalInput">
        <div
          v-for="(item, idx) in isAdditionalInput"
          :key="idx"
          class="flex flex-row justify-between"
        >
          <span class="order__row-text mb-10"
            >{{ ` ${item.name}, ${item.selected}  шт.` }}
          </span>
          <span class="order__row px-5"
            >{{ item.price }} {{ service.currency }}
          </span>
        </div>
      </div>
      <div class="hl"></div>
      <div class="flex flex-row justify-between pb-30">
        <span class="order__row-text">Итоговая стоимость </span>
        <span>{{ service.final_price }} {{ service.currency }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IndividOrder",
  props: {
    service: {
      type: Object,
      default: () => {},
    },
    isAdditionalCheck: { type: Array, default: () => {} },
    isAdditionalInput: { type: Array, default: () => {} },
  },
};
</script>

<style lang="scss" scoped>
.order {
  font-weight: 400;
  font-family: Montserrat;
  &__wrap {
    // width: 860px;
    margin-top: 100px;
    margin-bottom: 100px;
  }
  &__block {
    background: rgba(255, 255, 255, 0.04);
    border-radius: 3px;
    margin-bottom: 30px;
    &--top {
      text-align: left;
      margin-bottom: 40px;
    }
  }
  &__row {
    font-size: 12px;
    &-text {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}
</style>
