var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-40 pb-80 w-full max-w-lg resource lg:pt-15"},[_c('div',{staticClass:"lg:px-15"},[_c('el-button',{staticClass:"mb-30 lg:mb-20 px-12",attrs:{"size":"medium"},on:{"click":_vm.goBack}},[_c('span',{staticClass:"flex items-center"},[_c('svg-icon',{staticClass:"text-white-normal rotate-180 mr-10",attrs:{"icon-name":"arrow","width":"13","height":"8"}}),_vm._v(_vm._s(_vm.mode === "single" ? "К настройкам интеграции" : "К виджетам")+" ")],1)]),_c('h1',{staticClass:"text-28 leading-44 font-semibold text-white-normal mb-20 lg:text-22 lg:leading-36"},[_vm._v(" Создание стандартного виджета ")]),_c('el-alert',{staticClass:"mb-40",attrs:{"title":"Виджет создан","type":"success","closable":false,"show-icon":""}})],1),_c('div',{staticClass:"bg-white-40"},[_c('div',{staticClass:"pt-20"},[(_vm.mode === 'multiple')?_c('change-widget-mode',{staticClass:"px-30 lg:px-15",on:{"onChange":_vm.onChangeMode}}):_vm._e(),_c('div',{staticClass:"px-30 pb-40 lg:px-15"},[_c('div',{staticClass:"text-18 font-semibold leading-30 mb-3 lg:text-16 lg:leading-23"},[_vm._v(" Выберите внешний вид модуля ")]),_c('p',{staticClass:"text-white-500 text-12 leading-16 mb-30"},[_vm._v(" В соответствии с дизайном вашего сайта выберите параметры внешнего вида виджета. ")]),_c('el-row',{attrs:{"gutter":30}},_vm._l((_vm.widgets),function(widget,key){return _c('el-col',{key:key,staticClass:"xl:mb-20",attrs:{"span":_vm.$mq.desktop ? 24 : 8}},[_c('div',{staticClass:"flex flex-col items-center"},[_c('div',{staticClass:"relative pt-28 pb-18 px-25 rounded-sm w-full flex flex-col items-center mb-16 cursor-pointer",class:{
                  'bg-white-normal': key === 'white',
                  'bg-gray-900': key === 'standard',
                  'bg-cover bg-no-repeat bg-center': key === 'custom',
                },style:(key === 'custom'
                    ? ("background-image: url(" + (_vm.widgetBg(
                        'transp-widget.jpeg'
                      )) + ")")
                    : ''),on:{"click":function($event){return _vm.handleWidgetClick(key)}}},[(widget)?_c('div',{staticClass:"bg-green-400 rounded-sm flex items-center justify-center absolute w-20 h-20 widget-check"},[_c('svg-icon',{attrs:{"icon-name":"check","class-name":"text-black w-10 h-7"}})],1):_vm._e(),_c('div',{staticClass:"absolute border-green-400 pointer-events-none border-2 w-full h-full top-0 left-0 rounded-sm",class:widget ? 'block' : 'hidden'}),_c('div',{staticClass:"flex flex-wrap justify-center"},_vm._l((4),function(slot,i){return _c('div',{key:i,staticClass:"schedule-item flex flex-col items-center mb-13",class:{ 'mr-7': i !== 3 }},[_c('div',{staticClass:"flex items-center justify-center font-semibold w-65 h-35 rounded-sm mb-5 cursor-pointer",class:{
                        'bg-white-150 text-white-normal': key === 'standard',
                        'bg-gray-10 text-black': key === 'white',
                        'bg-white-200 text-white-normal': key === 'custom',
                      }},[_vm._v(" 23:00 ")]),_c('span',{staticClass:"text-white-500 text-12",class:{
                        'text-gray-300': key === 'white',
                        'text-white-500':
                          key === 'standard' || key === 'custom',
                      }},[_vm._v("600 ₽")])])}),0),_c('div',{staticClass:"text-16 font-semibold leading-28",class:{ 'text-black': key === 'white' }},[_vm._v(" "+_vm._s(key === "standard" ? "Темный" : key === "white" ? "Светлый" : "Темный прозрачный")+" ")])]),_c('router-link',{staticClass:"text-12 text-white-500 leading-16 cursor-pointer underline",attrs:{"to":{
                  name: 'widget-example',
                  query: {
                    theme:
                      key === 'standard'
                        ? 'dark'
                        : key === 'white'
                        ? 'white'
                        : 'custom',
                    ids:
                      _vm.widgetType === 1
                        ? _vm.widgetSingleResource
                        : _vm.widgetResources.toString(),
                  },
                }}},[_vm._v("Пример виджета")])],1)])}),1)],1),_c('div',{staticClass:"p-30 border-t border-white-150 lg:px-15"},[_c('div',{staticClass:"flex items-center lg:flex-col lg:items-stretch"},[_c('el-button',{staticClass:"bg-gradient-b-yellow border-0 xs:w-full mr-30 lg:mr-0 lg:mb-10",attrs:{"type":"primary"},on:{"click":_vm.onGetCode}},[_vm._v("Получить код")]),(!_vm.showCode)?_c('div',{staticClass:"rounded-sm border border-blue-100 bg-blue-10 lg:order-1"},[_c('div',{staticClass:"text-blue-200 py-13 px-17 leading-none lg:text-center"},[_vm._v(" Нажмите на “Получить код”, чтобы получить код виджета для вашей страницы ")])]):_vm._e()],1),(_vm.showCode)?_c('div',{staticClass:"mt-30 lg:mt-10"},[_c('div',{staticClass:"text-18 leading-30 font-semibold mb-3 lg:text-16 lg:leading-23"},[_vm._v(" Скопируйте полученный код и вставьте на страницу Вашего сайта ")]),_c('div',{staticClass:"text-white-500 text-12 leading-16 mb-30"},[_vm._v(" Чтобы внедрить виджет с расписанием, скопируйте полученный код и вставьте его на внутреннюю страницу вашего сайта. ")]),_c('div',{staticClass:"rounded-sm border border-blue-100 bg-blue-10 widget-code"},[_c('el-input',{ref:"code",staticClass:"bg-transparent max-h-500 overflow-auto text-12",attrs:{"type":"textarea","value":_vm.value,"resize":"none","autosize":true}})],1),_c('el-button',{staticClass:"px-15 lg:w-full mt-20",on:{"click":_vm.copyCode}},[_c('div',{staticClass:"flex items-center justify-center"},[_c('svg-icon',{attrs:{"icon-name":"copy","class-name":"text-white-normal mr-8","width":"16","height":"18"}}),_vm._v("Скопировать код ")],1)])],1):_vm._e()])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }