<template>
  <div>
    <spinner v-if="!stats.types" />
    <template v-else>
      <div
        v-if="!$mq.tablet"
        class="border-b border-white-150 mb-30 -mx-30"
      ></div>
      <div class="collapse-content px-30 pb-30 font-medium">
        <div class="flex lg:flex-col lg:px-0 lg:pb-20">
          <div class="flex flex-col">
            <div
              class="stat-item-content text-14 leading-36 text-white-normal font-semibold lg:text-12 lg:font-medium lg:leading-25 mb-10"
            >
              Статистика оплат
            </div>
            <div
              class="stat-items border-white-200 flex flex-wrap lg:px-15 lg:border-white-150 lg:border-b flex items-center lg:pb-15"
            >
              <div class="stat-item mr-30">
                <div
                  class="stat-item__title font-medium text-12 leading-16 text-white-500"
                >
                  На сумму
                </div>
                <div
                  class="stat-item-content text-18 leading-36 text-white-normal font-semibold lg:text-14 lg:font-medium lg:leading-25"
                >
                  {{ stats.total || 0 }}
                  <span class="font-medium text-14">руб.</span>
                </div>
              </div>
              <div class="stat-item mr-30">
                <div
                  class="stat-item__title font-medium text-12 leading-16 text-white-500"
                >
                  Онлайн
                </div>
                <div
                  class="stat-item-content text-18 leading-36 text-white-normal font-semibold lg:text-14 lg:font-medium lg:leading-25"
                >
                  {{ stats.paid_online || 0 }}
                  <span class="font-medium text-14">руб.</span>
                </div>
              </div>
              <div class="stat-item mr-30">
                <div
                  class="stat-item__title font-medium text-12 leading-16 text-white-500"
                >
                  На месте
                </div>
                <div
                  class="stat-item-content text-18 leading-36 text-white-normal font-semibold lg:text-14 lg:font-medium lg:leading-25"
                >
                  {{ stats.paid_locally || 0 }}
                  <span class="font-medium text-14">руб.</span>
                </div>
              </div>
              <div class="stat-item mr-30">
                <div
                  class="stat-item__title font-medium text-12 leading-16 text-white-500"
                >
                  Ожидает
                </div>
                <div
                  class="stat-item-content text-18 leading-36 text-white-normal font-semibold lg:text-14 lg:font-medium lg:leading-25"
                >
                  {{ stats.paid_wait || 0 }}
                  <span class="font-medium text-14">руб.</span>
                </div>
              </div>
              <div class="stat-item">
                <div
                  class="stat-item__title font-medium text-12 leading-16 text-white-500"
                >
                  Не оплачено
                </div>
                <div
                  class="stat-item-content text-18 leading-36 text-white-normal font-semibold lg:text-14 lg:font-medium lg:leading-25"
                >
                  {{ stats.paid_not_paid || 0 }}
                  <span class="font-medium text-14">руб.</span>
                </div>
              </div>
            </div>
          </div>
          <div class="border-r border-white-150 mx-40 lg:mb-10"></div>
          <div class="flex flex-col">
            <div
              class="stat-item-content text-14 leading-36 text-white-normal font-semibold lg:text-12 lg:font-medium lg:leading-25 mb-10 lg:mb-0"
            >
              Статистика заказов
            </div>
            <div
              class="stat-items flex flex-wrap lg:flex-col lg:px-15 lg:pt-15"
            >
              <div
                v-for="item in stats.types"
                :key="item.id"
                class="stat-item stat-item-count mr-30 lg:flex lg:items-center lg:mr-0 lg:mb-5 lg:grid"
              >
                <div
                  class="stat-item__title font-medium text-12 leading-16 text-white-500 lg:text-white-normal lg:flex lg:items-center lg:text-14"
                >
                  <span
                    v-if="item.id"
                    class="stat-dot w-10 h-10 rounded-full mr-7 hidden lg:block"
                    :class="getTypeClass(item.id)"
                  ></span>
                  {{ item.name }}
                </div>
                <div
                  class="stat-item-content text-18 leading-36 lg:leading-25 text-white-normal font-semibold flex items-center lg:text-14 lg:font-medium"
                >
                  <span
                    v-if="item.id"
                    class="stat-dot w-10 h-10 rounded-full mr-7 lg:hidden"
                    :class="getTypeClass(item.id)"
                  ></span
                  >{{ item.amount || 0 }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    stats: [Array, Object],
  },
  methods: {
    getTypeClass(id) {
      let value;
      switch (id) {
        case 0:
          value = "bg-blue-100";
          break;
        case 1:
          value = "bg-violet-500";
          break;
        case 2:
          value = "bg-yellow-100";
          break;
        case 3:
          value = "bg-green-500";
          break;
        case 4:
          value = "bg-blue-300";
          break;
        case 5:
          value = "bg-gray-100";
          break;
        case 6:
          value = "bg-yellow-400";
          break;
        case 7:
          value = "bg-red-200";
          break;
        default:
          value = "bg-blue-100";
      }
      return value;
    },
  },
};
</script>
