var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"bk-full"},[_vm._m(0),_c('el-select',{staticClass:"w-full",attrs:{"value-key":"index","filterable":"","placeholder":"Выберите услугу"},on:{"change":_vm.setId},model:{value:(_vm.serviceId),callback:function ($$v) {_vm.serviceId=$$v},expression:"serviceId"}},_vm._l((_vm.servicesList),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.name,"value":item.hash,"default-first-option":"item.name"}})}),1)],1),_c('div',{staticClass:"bk-row"},[_c('div',{staticClass:"bk-row__item"},[_vm._m(1),_c('el-date-picker',{attrs:{"type":"date","clearable":false,"format":"dd.MM.yyyy","picker-options":_vm.scheduleDateOptions,"placeholder":"Выберите дату"},on:{"change":function($event){return _vm.changeScheduleDate($event, _vm.hash)}},model:{value:(_vm.scheduleDate),callback:function ($$v) {_vm.scheduleDate=$$v},expression:"scheduleDate"}})],1),(_vm.isSlotDetails)?_c('div',{staticClass:"bk-row__item"},[_vm._m(2),_c('el-select',{staticClass:"w-full",attrs:{"value-key":"time","filterable":"","placeholder":"Выберите время"},on:{"change":_vm.setPers},model:{value:(_vm.scheduleTime),callback:function ($$v) {_vm.scheduleTime=$$v},expression:"scheduleTime"}},_vm._l((_vm.correctIsSlotDetails),function(slot,inx){return _c('el-option',{key:inx,attrs:{"label":slot.time,"value":slot,"default-first-option":"item.time"}})}),1)],1):_vm._e()]),(
      _vm.slotDetails.booking_type.booking_type === 'service_tree_from_settings'
    )?_c('div',[_c('ServicesTreeGiven',{attrs:{"settings":_vm.settings,"item-list":_vm.itemList,"slot-details":_vm.slotDetails.amount,"hash":_vm.itemList.hash,"date-param":_vm.scheduleDate},on:{"setServices":_vm.setServices,"setSlotParam":_vm.setSlotParam}})],1):_vm._e(),(
      _vm.slotDetails.booking_type.booking_type ===
      'group_the_seat_limit_is_set_for_each_session'
    )?_c('div',[_c('LimitTypeGroup',{attrs:{"settings":_vm.settings,"item-list":_vm.itemList,"slot-details":_vm.slotDetails.amount,"hash":_vm.itemList.hash,"date-param":_vm.scheduleDate},on:{"setTickets":_vm.setTickets,"setSlotParam":_vm.setSlotParam}})],1):_vm._e(),(
      _vm.slotDetails.booking_type.booking_type ===
      'group_same_seat_limit_for_all_sessions'
    )?_c('div',{staticClass:"bk-full"},[_c('LimitAllGroup',{attrs:{"settings":_vm.settings,"item-list":_vm.itemList,"slot-details":_vm.slotDetails.amount,"hash":_vm.itemList.hash,"date-param":_vm.scheduleDate},on:{"setTickets":_vm.setTickets,"setSlotParam":_vm.setSlotParam}})],1):_vm._e(),(
      (_vm.slotDetails.booking_type.booking_type ===
        'individual_depends_on_the_number_of_seats' &&
        !_vm.isForPlace) ||
      (_vm.slotDetails.booking_type.booking_type ===
        'individual_depends_on_the_number_of_seats_forAgroup_true' &&
        !_vm.isForPlace)
    )?_c('div',[_c('SeatGroup',{attrs:{"settings":_vm.settings,"item-list":_vm.itemList,"slot-details":_vm.slotDetails},on:{"SetForGroup":_vm.setForGroup}})],1):_vm._e(),(
      _vm.slotDetails.booking_type.booking_type ===
      'individual_depends_on_the_service_and_its_duration'
    )?_c('div',{staticClass:"bk-full"},[_c('ServDuration',{attrs:{"settings":_vm.settings,"item-list":_vm.itemList,"slot-details":_vm.slotDetails},on:{"setServiceDur":_vm.setServiceDur}})],1):_vm._e(),(
      _vm.slotDetails.booking_type.booking_type ===
      'individual_depends_on_tariff_and_number_of_seats'
    )?_c('div',{staticClass:"bk-full"},[_c('TarifSeat',{attrs:{"settings":_vm.settings,"item-list":_vm.itemList,"slot-details":_vm.slotDetails,"date-param":_vm.scheduleDate},on:{"setServTarif":_vm.setServTarif}})],1):_vm._e(),(
      _vm.slotDetails.booking_type.booking_type === 'rent_depends_only_on_time'
    )?_c('div',[_c('RenDepTim',{attrs:{"settings":_vm.settings,"item-list":_vm.itemList,"slot-details":_vm.slotDetails,"slots-validity":_vm.slotsValidityParent},on:{"setRent":_vm.setRent}})],1):_vm._e(),(
      _vm.slotDetails.booking_type.booking_type ===
      'rent_depends_on_time_and_place'
    )?_c('div',[_c('RenDepTimSeats',{attrs:{"settings":_vm.settings,"item-list":_vm.itemList,"slot-details":_vm.slotDetails,"slots-validity":_vm.slotsValidity},on:{"renTimeSeat":_vm.setRenTimeSeat,"setDiscount":_vm.setDiscountValue}})],1):_vm._e(),(
      _vm.slotDetails.booking_type.booking_type !== 'rent_depends_only_on_time' &&
      _vm.slotDetails.booking_type.booking_type !==
        'rent_depends_on_time_and_place' &&
      _vm.slotDetails.booking_type.booking_type ===
        'individual_depends_on_the_number_of_seats' &&
      _vm.isForPlace
    )?_c('div',{staticClass:"bk-info-group mb-10"},_vm._l((_vm.slotDetails.booking_type.booking_type !==
      'individual_depends_on_the_number_of_seats'
        ? _vm.settings.data.group
        : _vm.isSettingsDataParams),function(item,idx){return _c('div',{key:idx,staticClass:"bk-info"},[_c('p',{staticClass:"bk-info__title text-12"},[_vm._v(" "+_vm._s(item.from ? ("от " + (item.from) + " ") : "")+" "+_vm._s(item.to ? ("до " + (item.to) + " ") : "")+" "+_vm._s(_vm.slotDetails.booking_type.booking_type !== "individual_depends_on_the_number_of_seats" ? ("" + (_vm.unitCahnge.text)) : "участников")+" ")]),_c('p',[_vm._v(" "+_vm._s(item.base_price)+" "+_vm._s(_vm.itemList.item.currency)+" "+_vm._s(_vm.slotDetails.booking_type.booking_type !== "individual_depends_on_the_number_of_seats" ? ("" + (_vm.unitCahnge.textShot)) : "за участника")+" ")])])}),0):_vm._e(),(
      (_vm.slotDetails.booking_type.booking_type !==
        'rent_depends_only_on_time' &&
        _vm.slotDetails.booking_type.booking_type !==
          'rent_depends_on_time_and_place' &&
        _vm.slotDetails.booking_type.booking_type ===
          'individual_does_not_depend_on_the_number_of_seats' &&
        _vm.slotDetails.booking_type.booking_type !==
          'individual_depends_on_the_service_and_its_duration') ||
      (_vm.slotDetails.booking_type.booking_type ===
        'individual_depends_on_the_number_of_seats' &&
        _vm.isForPlace)
    )?_c('div',{staticClass:"bk-full"},[_c('p',{staticClass:"hint"},[_vm._v("Количество участников")]),_c('el-input-number',{staticClass:"w-full",attrs:{"min":Number(_vm.maxPerson.from),"max":Number(_vm.maxPerson.to)},on:{"input":_vm.setPers},model:{value:(_vm.totalPerson),callback:function ($$v) {_vm.totalPerson=$$v},expression:"totalPerson"}})],1):_vm._e(),(
      _vm.isGroup &&
      _vm.slotDetails.booking_type.booking_type !==
        'individual_depends_on_the_service_and_its_duration' &&
      _vm.slotDetails.booking_type.booking_type !==
        'individual_depends_on_the_number_of_seats' &&
      _vm.isForPlace
    )?_c('div',{staticClass:"bk-info-group"},_vm._l((_vm.isSettingsDataParams),function(group){return _c('div',{key:group.id,staticClass:"bk-info"},[_c('p',{staticClass:"bk-info"},[_vm._v(" "+_vm._s(group.from ? ("от " + (group.from)) : "")+" "+_vm._s(group.to ? ("до " + (group.to) + " ") : "")+" "+_vm._s(_vm.slotDetails.booking_type.booking_type !== "individual_depends_on_the_number_of_seats" ? ("" + (_vm.unitCahnge.text)) : "участников")+" ")]),_c('p',{staticClass:"bk-info"},[_vm._v(" "+_vm._s(_vm.isHoliday ? group.holidays_price : group.base_price)+" "+_vm._s(_vm.itemList.item.currency)+"./"+_vm._s(_vm.slotDetails.booking_type.booking_type !== "individual_depends_on_the_number_of_seats" ? ("" + (_vm.unitCahnge.textShot)) : "участника")+" ")])])}),0):_vm._e(),(
      _vm.isGroup &&
      _vm.slotDetails.booking_type.booking_type !==
        'rent_depends_on_time_and_place' &&
      _vm.slotDetails.booking_type.booking_type !==
        'individual_depends_on_the_service_and_its_duration' &&
      _vm.slotDetails.booking_type.booking_type !==
        'individual_depends_on_the_number_of_seats' &&
      _vm.slotDetails.booking_type.booking_type !==
        'individual_depends_on_the_number_of_seats_forAgroup_true' &&
      _vm.slotDetails.booking_type.booking_type !==
        'group_the_seat_limit_is_set_for_each_session' &&
      _vm.slotDetails.booking_type.booking_type !== 'rent_depends_only_on_time'
    )?_c('div',{staticClass:"hl"}):_vm._e(),(
      _vm.isTymeUnit &&
      _vm.slotDetails.booking_type.booking_type !==
        'rent_depends_on_time_and_place' &&
      _vm.slotDetails.booking_type.booking_type !==
        'individual_does_not_depend_on_the_number_of_seats' &&
      _vm.slotDetails.booking_type.booking_type !==
        'individual_depends_on_the_service_and_its_duration' &&
      _vm.slotDetails.booking_type.booking_type !==
        'individual_depends_on_the_number_of_seats' &&
      _vm.slotDetails.booking_type.booking_type !==
        'individual_depends_on_the_number_of_seats_forAgroup_true' &&
      _vm.slotDetails.booking_type.booking_type !==
        'group_same_seat_limit_for_all_sessions' &&
      _vm.slotDetails.booking_type.booking_type !==
        'group_the_seat_limit_is_set_for_each_session' &&
      _vm.slotDetails.booking_type.booking_type !== 'rent_depends_only_on_time' &&
      _vm.slotDetails.booking_type.booking_type !== 'service_tree_from_settings'
    )?_c('div',{staticClass:"bk-full"},[_c('p',{staticClass:"hint"},[_vm._v(" Количество "+_vm._s(_vm.unitCahnge.text)+" "),_c('span',{staticClass:"danger"},[_vm._v("*")])]),_c('el-input-number',{staticClass:"w-full",attrs:{"min":_vm.minHours,"step":_vm.step},on:{"input":_vm.setPers},model:{value:(_vm.totalTime),callback:function ($$v) {_vm.totalTime=$$v},expression:"totalTime"}})],1):_vm._e(),(
      _vm.isAmountSlot.total &&
      _vm.slotDetails.booking_type.booking_type !==
        'rent_depends_on_time_and_place' &&
      _vm.slotDetails.booking_type.booking_type !==
        'group_same_seat_limit_for_all_sessions' &&
      _vm.slotDetails.booking_type.booking_type !==
        'group_the_seat_limit_is_set_for_each_session' &&
      _vm.slotDetails.booking_type.booking_type !== 'service_tree_from_settings'
    )?_c('div',{staticClass:"bk-info"},[_c('p',{staticClass:"bk-info__title"},[_vm._v("Осталось мест")]),(_vm.isAmountSlot.remained || _vm.isAmountSlot.total)?_c('p',{staticClass:"bk-info__info"},[_vm._v(" "+_vm._s(_vm.isAmountSlot.remained)+" из "+_vm._s(_vm.isAmountSlot.total)+" ")]):_vm._e()]):_vm._e(),(
      _vm.isAmountSlot.total &&
      _vm.slotDetails.booking_type.booking_type !==
        'rent_depends_on_time_and_place' &&
      _vm.slotDetails.booking_type.booking_type !==
        'group_same_seat_limit_for_all_sessions' &&
      _vm.slotDetails.booking_type.booking_type !==
        'group_the_seat_limit_is_set_for_each_session' &&
      _vm.slotDetails.booking_type.booking_type !== 'service_tree_from_settings'
    )?_c('div',{staticClass:"hl"}):_vm._e(),(
      _vm.isAmountSlot.total &&
      _vm.slotDetails.booking_type.booking_type !==
        'rent_depends_on_time_and_place' &&
      _vm.slotDetails.booking_type.booking_type !==
        'group_same_seat_limit_for_all_sessions' &&
      _vm.slotDetails.booking_type.booking_type !==
        'group_the_seat_limit_is_set_for_each_session' &&
      _vm.slotDetails.booking_type.booking_type !== 'service_tree_from_settings'
    )?_c('div',{staticClass:"bk-full"},[_vm._m(3),_c('el-input-number',{staticClass:"w-full",attrs:{"min":1,"max":_vm.isAmountSlot.total,"step":_vm.step},on:{"input":_vm.setPers},model:{value:(_vm.totalSeats),callback:function ($$v) {_vm.totalSeats=$$v},expression:"totalSeats"}})],1):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"hint"},[_vm._v("Услуга"),_c('span',{staticClass:"danger"},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"hint"},[_vm._v("Дата"),_c('span',{staticClass:"danger"},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"hint"},[_vm._v("Время"),_c('span',{staticClass:"danger"},[_vm._v("*")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"hint"},[_vm._v("Количество мест"),_c('span',{staticClass:"danger"},[_vm._v("*")])])}]

export { render, staticRenderFns }