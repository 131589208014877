<template>
  <div class="relative">
    <spinner v-if="loading" class="table__spinner" />
    <Table type="client" />
  </div>
</template>

<script>
import Table from "@/components/Sms/HistorySms/Table.vue";
import { mapActions } from "vuex";

export default {
  components: {
    Table,
  },
  data() {
    return {
      loading: true,
    };
  },
  async created() {
    await this.getHistorySMS({ page: 1, perPage: 50 });
    this.loading = false;
  },
  methods: {
    ...mapActions("sms", ["getHistorySMS"]),
  },
};
</script>
