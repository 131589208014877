import { setField, setFieldObj } from "@/helpers/store";
import { AJAX_GET, AJAX_DELETE, AJAX_POST, AJAX_PATCH } from "@/units/ajax";

export default {
  namespaced: true,
  state: {
    referralHistory: [],
    referralHistoryTotal: null,
    referralInfo: {},
  },
  getters: {},
  mutations: {
    setField,
    setFieldObj,
  },
  actions: {
    async getReferralHistory({ commit }, perPage = 15) {
      const api = `/api/pay/referralhistory?page=1&perPage=${perPage}`;
      try {
        const { data } = await AJAX_GET(api, { noCancel: true });
        commit("setField", {
          field: "referralHistory",
          value: data.data,
        });
        commit("setField", {
          field: "referralHistoryTotal",
          value: data.total,
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getReferralInfo({ commit }) {
      try {
        const { data } = await AJAX_POST("/api/referral/get");
        commit("setField", {
          field: "referralInfo",
          value: data,
        });
      } catch (error) {
        console.log(error);
      }
    },
    async sendReferral({ commit }, email) {
      try {
        const { data } = await AJAX_POST("/api/referral/send", { email });
        commit(
          "setNotif",
          {
            title: `${data.msg}`,
            type: `${!data.success ? "error" : ""}`,
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
      }
    },
  },
};
