<template>
  <div>
    <div
      v-for="(item, index) in settings.data.group"
      :key="index"
      class="bk-info"
    >
      <p class="bk-info__title">
        {{ item.from ? `от ${item.from}` : "" }}
        {{ item.to ? `до  ${item.to} ` : "" }}часов
      </p>
      <p class="bk-info__info">
        {{
          isHoliday && item.holidays_price
            ? item.holidays_price
            : item.base_price
        }}
        {{ itemList.item.currency }}
        {{ `за ${settings.data.session_duration} ${unitCahnge.short}` }}
      </p>
    </div>

    <p class="title-widget">Количество часов</p>

    <el-input-number
      v-model="duration"
      class="w-full mb-12"
      :min="Number(minRent)"
      :max="Number(maxPerson.to)"
      :step="step"
      @input="changeVal"
    ></el-input-number>
    <p class="title-widget">Количество мест</p>
    <div class="bk-info">
      <p class="bk-info__title">Осталось мест</p>
      <p v-if="slotsValidity" class="bk-info__info">
        {{ slotsValidity.tickets }} из {{ remainedTickets.total }}
      </p>
    </div>
    <el-input-number
      v-model="places"
      :min="Number(maxPerson.from)"
      :max="Number(remainedTickets.total)"
      class="w-full"
      @input="changeVal"
    ></el-input-number>
    <div v-if="settings.data.group_2" class="my-5">
      <div v-for="sale in settings.data.group_2" :key="sale.id" class="bk-info">
        <p class="bk-info__title">
          {{ sale.from ? `от ${sale.from}` : "" }}
          {{ sale.to ? `до  ${sale.to} ` : "" }}
          <span>{{ `мест` }}</span>
        </p>
        <p class="bk-info__info">
          {{ `скидка: ${sale.discount}` }}
          {{ convertSale(sale.discount_unit) }}
          {{ `за ${settings.data.session_duration} ${unitCahnge.short}` }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RenDepTimSeats",
  props: {
    itemList: {
      type: Object,
    },
    settings: {
      type: Object,
    },
    slotsValidity: {
      type: Object,
    },
  },
  data() {
    return {
      duration: 1,
      places: 1,
      price: null,
      unit: null,
      discount: null,
      discount_unit: null,
    };
  },
  computed: {
    unitCahnge() {
      switch (this.settings.data.time_unit) {
        case "days":
          return {
            text: "дней",
            short: "день",
            param: "day",
          };
        case "hours":
          return {
            text: "часов",
            short: "час",
            param: "hour",
          };
        case "minutes":
          return {
            text: "минут",
            short: "минут",
            param: "minute",
          };

        default:
          return this.settings.data.time_unit;
      }
    },
    isHoliday() {
      return this.itemList.item.is_holiday;
    },
    step() {
      return Number(this.settings.data.session_duration);
    },
    remainedTickets() {
      return {
        remained: this.settings.data.seats,
        total: this.settings.data.seats,
      };
    },
    minRent() {
      return this.settings.data.minimum_rent || 1;
    },
    maxPerson() {
      let arr = [];
      if (this.settings.data.group) {
        this.settings.data.group.map((i) => arr.push(i.to));
        arr = Math.max.apply(null, arr);
      }
      return this.settings?.data.group
        ? {
            to: this.settings?.data?.to || arr,
            from: this.settings?.data?.from || 1,
          }
        : 1;
    },
  },
  created() {
    //  console.log(this.itemList.book_info?.rent?.duration);
    this.duration =
      this.itemList.book_info?.rent?.duration ??
      this.settings.data.minimum_rent;
    this.places = this.itemList.book_info?.rent?.places ?? 1;
  },

  methods: {
    convertSale(param) {
      switch (param) {
        case "procent":
          return "%";
        case "rub":
          return "₽";
        default:
          return "0";
      }
    },
    changeVal() {
      const findIntervalPrice = this.settings.data.group.find((el) => {
        const to = el.to ? Number(el.to) : 99999;
        return this.duration <= to;
      });

      this.price = this.isHoliday
        ? findIntervalPrice.holidays_price +
          this.itemList.item.weekend_price_additional
        : findIntervalPrice.base_price + this.itemList.item.price_additional;
      const findInterval = this.settings.data.group_2.find((el) => {
        const to = el.to ? Number(el.to) : 99999;
        return this.places <= to;
      });
      if (findInterval.discount) {
        this.discount = findInterval.discount;
        this.discount_unit = findInterval.discount_unit;
        this.$emit("setDiscount", {
          discount: this.discount,
          unit: this.discount_unit,
        });
      }
      this.unit = this.unitCahnge.param;
      this.$emit("renTimeSeat", this.$data);
    },
  },
};
</script>

<style lang="scss" scoped></style>
