<template>
  <div class="list-fields">
    <div
      v-for="(item, index) in listField"
      :key="index"
      class="list-fields__row"
    >
      <div class="list-fields__item">
        <el-form-item prop="basePrice" class="w-full">
          <div slot="label" class="flex">
            <div>
              {{ headers[0].title }}
              <span v-if="headers[0].valid" class="danger">*</span>
            </div>
            <el-popover
              v-if="headers[0].popover"
              class="ml-8"
              placement="top"
              width="200"
              trigger="hover"
              :content="headers[0].popoverText"
            >
              <Tooltip slot="reference" />
            </el-popover>
          </div>
          <el-input
            v-model="item.name"
            placeholder="Введите название услуги"
            @input="emitData()"
          >
          </el-input>
        </el-form-item>
      </div>
      <div class="list-fields__item">
        <InputSelect
          v-model="item.duration"
          prop="durationService"
          label="Продолжительность"
          :list-value="listDuration"
          :select-value="item.time_unit"
          @setValue="listField[index].time_unit = $event"
          @input="emitData()"
        ></InputSelect>
      </div>
      <div class="list-fields__item">
        <el-form-item prop="basePrice" class="w-full">
          <div slot="label" class="flex">
            <div>
              {{ headers[2].title }}
              <span v-if="headers[2].valid" class="danger">*</span>
            </div>
            <el-popover
              v-if="headers[2].popover"
              class="ml-8"
              placement="top"
              width="200"
              trigger="hover"
              :content="headers[2].popoverText"
            >
              <Tooltip slot="reference" />
            </el-popover>
          </div>
          <el-input
            v-model.number="item.base_price"
            placeholder="Введите цену"
            @input="emitData()"
          >
            <div slot="suffix">₽</div>
          </el-input>
        </el-form-item>
      </div>
      <div class="list-fields__item">
        <el-form-item prop="basePrice" class="w-full">
          <div slot="label" class="flex">
            <div>
              {{ headers[3].title }}
              <span v-if="headers[3].valid" class="danger">*</span>
            </div>
            <el-popover
              v-if="headers[3].popover"
              class="ml-8"
              placement="top"
              width="200"
              trigger="hover"
              :content="headers[3].popoverText"
            >
              <Tooltip slot="reference" />
            </el-popover>
          </div>
          <el-input
            v-model.number="item.holidays_price"
            placeholder="Введите цену"
            @input="emitData()"
          >
            <div slot="suffix">₽</div>
          </el-input>
        </el-form-item>
      </div>

      <div class="list-fields__actions lg:w-full">
        <ButtonIcon
          icon-name="close"
          width="16"
          :class="$mq.tablet ? 'w-max' : 'w-auto'"
          :text="$mq.tablet ? 'Удалить услугу' : ''"
          @click="deleteField(index)"
        />
      </div>
    </div>
    <el-button class="list-fields__add-button" @click="addField()">{{
      titleButton
    }}</el-button>
  </div>
</template>

<script>
import InputSelect from "@/components/InputSelect.vue";
import { listDuration } from "@/helpers/services.js";

export default {
  name: "ListField4",
  components: { InputSelect },
  props: {
    title: {
      type: String,
      default: "",
    },
    titleButton: {
      type: String,
      default: "Добавить новое поле",
    },
    placeholder: {
      type: String,
      default: "Введите значение",
    },
    headers: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      listField: [
        {
          id: null,
          name: "",
          duration: 60,
          time_unit: "minutes",
          base_price: null,
          holidays_price: null,
        },
      ],
    };
  },
  watch: {
    value() {
      this.listField = [...this.value];
    },
  },
  created() {
    this.listDuration = listDuration;
    this.listField = [...this.value];
  },
  mounted() {},
  methods: {
    deleteField(index) {
      this.listField.splice(index, 1);
      this.emitData();
    },
    addField() {
      this.listField.push({
        id: null,
        name: "",
        duration: 60,
        time_unit: "minutes",
        base_price: null,
        holidays_price: null,
      });
    },
    emitData() {
      this.$emit("input", [...this.listField]);
    },
  },
};
</script>

<style lang="scss" scoped></style>
