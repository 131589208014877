<template>
  <el-form
    ref="baseInfo"
    hide-required-asterisk
    label-position="top"
    class="regular"
  >
    <p class="hint-title__medium-white">
      Данный вариант подходит, если услуга оказывается на регулярной основе.
    </p>
    <template v-for="(item, i) in list">
      <RegularPeriod
        :key="`RegularPeriod-${i}`"
        :is-show-delet-btn="list.length > 1"
        :value="item ? { ...item } : null"
        class="regular-period"
        title-button="Добавить места"
        @input="setData($event, i)"
        @delete="deleteItem(i)"
      ></RegularPeriod>
    </template>
    <el-button class="custom-button__outline" @click="addField()">
      Добавить период
    </el-button>
    <div v-if="isHaveSessions" class="attention">
      <div class="text-white-normal mb-10">
        <el-alert
          center
          :title="`${alertMsg}`"
          type="warning"
          show-icon
          :closable="false"
          class="mt-15 text-16"
        />
      </div>
    </div>
  </el-form>
</template>

<script>
import RegularPeriod from "@/views/Services/Create/components/generation/RegularPeriod";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

export default {
  name: "Regular",
  components: {
    RegularPeriod,
  },
  data() {
    return {
      list: [null],
    };
  },
  computed: {
    ...mapState("service", [
      "settings",
      "generator",
      "sessions",
      "alertMsg",
      "listSchedule",
    ]),
    ...mapGetters("service", ["isHaveSessions"]),
  },
  created() {
    // if (this.listSchedule.length > this.sessions.data.length) {
    //   console.log("more");
    //   this.list = this.listSchedule.map((element, idx) => {
    //     console.log({
    //       ...element,

    //       daysPeriod: this.generator.data.regular[idx].days,
    //       listSessions: this.generator.data.regular[idx].listSessions,
    //     });
    //     return {
    //       ...element,
    //       daysPeriod: this.generator.data.regular[idx].days,
    //       listSessions: this.generator.data.regular[idx].listSessions,
    //     };
    //   });
    //   console.log(this.list);
    // } else {
    //   console.log("low");
    //   this.list = this.sessions.data.map((element, idx) => {
    //     return {
    //       ...element,
    //       daysPeriod: this.generator.data.regular[idx].days,
    //       listSessions: this.generator.data.regular[idx].listSessions,
    //     };
    //   });
    // }

    if (this.sessions.data.length) {
      this.list = this.sessions.data.map((element, idx) => {
        return {
          ...element,
          daysPeriod: this.generator.data.regular[idx].days,
          listSessions: this.generator.data.regular[idx].listSessions,
        };
      });
    } else {
      this.list = this.listSchedule
        ? this.listSchedule.map((element, idx) => {
            return {
              ...element,
              daysPeriod: this.generator.data.regular[idx].days,
              listSessions: this.generator.data.regular[idx].listSessions,
            };
          })
        : [];

      // }
      // if (this.generator.data.regular) {
      //   this.list = [...this.generator.data.regular];
    }
  },
  methods: {
    ...mapActions("service", ["saveGenerator"]),
    ...mapMutations("service", ["setGenerator"]),

    async saveData() {
      const param = this.generator.schedule_type ?? this.sessions.schedule_type;
      this.setGenerator({
        field: param,
        value: [...this.list.filter((item) => item)],
      });
      // REMOVE TO DEL request

      // await this.saveGenerator([...this.list.filter((item) => item)]);
    },

    addField(el) {
      this.list.push(el);
    },
    deleteItem(index) {
      this.list.splice(index, 1);
    },
    setData(value, index) {
      this.list.splice(index, 1, { ...value });
      this.$store.commit("service/setField", {
        field: "listSchedule",
        value: this.list,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "element-variables.scss";

.hint-title__medium-white {
  padding-bottom: 30px;
}

.regular-period:last-child {
  margin-bottom: 20px;
}

@screen lg {
  .hint-title__medium-white {
    padding-bottom: 25px;
  }
}
</style>
