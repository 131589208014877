<template>
  <div class="wrapper" :style="{ custom }">
    <div v-if="isLoading">
      <spinner v-if="isLoading" />
      <div class="flex justify-center mt-10">
        <span
          class="text-14"
          :style="{
            color: isIndividualTheme ? custom.active_text_color : 'black',
          }"
          >Подождите, пожалуйста. Загружаем данные</span
        >
      </div>
    </div>
    <div v-else-if="widgetPublic.success">
      <component
        :is="showWidgetComponent"
        :style="custom"
        :show-price="widgetPublic.isShowPrice"
        :show-calendar="widgetPublic.showCalendarOpen"
        @reloadWidget="reloadWidget"
      />
    </div>
    <div v-else>
      <el-alert
        :title="widgetPublic.msg || 'Ошибка'"
        type="warning"
        show-icon
        :closable="false"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import ShowService from "@/views/Widgets/components/ShowService.vue";
import ScheduleWidgetSingleGrid from "@/views/Widgets/components/ScheduleWidgetSingleGrid.vue";
import ScheduleWidgetSingleForm from "@/views/Widgets/components/ScheduleWidgetSingleForm.vue";
import ScheduleWidgetManyGrid from "@/views/Widgets/components/ScheduleWidgetManyGrid.vue";
import ScheduleWidgetManyCard from "@/views/Widgets/components/ScheduleWidgetManyCard.vue";
import ScheduleWidgetServicesForm from "@/views/Widgets/components/ScheduleWidgetServicesForm";
import FormSticky from "@/views/Widgets/components/FormSticky.vue";
import FormCenter from "@/views/Widgets/components/FormCenter.vue";

export default {
  name: "PublicWidget",
  components: {
    ShowService,
    ScheduleWidgetSingleGrid,
    ScheduleWidgetSingleForm,
    ScheduleWidgetManyGrid,
    ScheduleWidgetManyCard,
    ScheduleWidgetServicesForm,
    FormSticky,
    FormCenter,
  },
  data() {
    return {
      isIndividualTheme: false,
      isLoading: true,
      custom: {
        // height: "100vh",
      },
    };
  },
  computed: {
    ...mapState("widget", ["widgetPublic"]),
    ...mapState("service", ["service", "schedule"]),
    isSingle() {
      return this.widgetPublic.service_type === "single";
    },
    isServicesType() {
      // TEST MAKET
      return this.widgetPublic.service_type === "services";
    },
    showWidgetComponent() {
      if (this.isSingle) {
        return this.widgetPublic.typeSingle === "grid"
          ? ScheduleWidgetSingleGrid
          : ScheduleWidgetSingleForm;
      }
      if (this.widgetPublic.typeMulti === "tree") {
        return ScheduleWidgetServicesForm;
      }
      return this.widgetPublic.typeMulti === "grid"
        ? ScheduleWidgetManyGrid
        : ScheduleWidgetManyCard;
    },
    isDarkTheme() {
      return (
        this.widgetPublic.typeTheme === "dark" ||
        this.widgetPublic.typeTheme === "light" ||
        this.widgetPublic.typeTheme === "individual"
      );
    },
    isWhiteTheme() {
      return this.widgetPublic.typeTheme === "light";
    },
  },
  watch: {
    isDarkTheme() {
      this.custom = {
        // height: "100vh",
        background: `${this.widgetPublic.design.block_background}`,
        color: `${this.widgetPublic.design.button_text_color}`,
      };
    },
  },
  async created() {
    this.isLoading = true;
    const host = window.document.referrer;
    const { hash } = this.$route.params;
    const referer = window.document.referrer;
    const url = window.location.href;
    // if (this.$route.query.day) {
    //   this.$router.replace(`?day=${this.$route.query.day}`);
    // }
    await this.getWidgetPublic({ hash, host, referrer: host, url });

    // if (this.isSingle) {
    //   await this.getService(this.widgetPublic.service_id);
    //   await this.getSettings(this.widgetPublic.service_id);
    // }
    // remome commit this.$router.push({ name: "public-widget" });
    this.isLoading = false;
  },
  methods: {
    ...mapActions("widget", ["getWidget", "getWidgetPublic"]),
    ...mapActions("service", ["getService"]),

    async reloadWidget() {
      this.isLoading = true;
      const host = window.document.referrer;
      const { hash } = this.$route.params;
      const referer = window.document.referrer;
      const url = window.location.href;
      await this.getWidgetPublic({ hash, host, referrer: host, url });
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";
.wrapper {
  padding-top: 0;
  padding-bottom: 0;
}
</style>
