/* eslint-disable no-restricted-syntax */
export default {
  namespaced: true,
  state: {
    menu: [
      {
        id: 0,
        idLabel: "dashboard",
        icon: "dashboard",
        label: "Дашборд",
        path: "/dashboard",
        action: false,
        count: false,
        fastAdd: false,
        expandable: false,
        addLink: false,
      },
      {
        id: 1,
        idLabel: "schedule",
        icon: "schedule2",
        label: "Расписание",
        path: "/schedule",
        action: false,
        count: false,
        fastAdd: false,
        expandable: false,
        addLink: false,
      },
      {
        id: 2,
        idLabel: "reserves",
        icon: "reserves",
        label: "Заказы",
        path: "/reserves",
        action: false,
        count: {
          prefix: "",
          value: 0,
          suffix: "шт",
        },
        fastAdd: false,
        expandable: false,
        addLink: false,
      },
      {
        id: 6,
        idLabel: "services",
        icon: "lock2",
        label: "Услуги",
        path: "/services",
        action: false,
        count: {
          prefix: "",
          value: 0,
          suffix: "шт",
        },
        fastAdd: false,
        expandable: false,
        addLink: false,
      },
      // {
      //   id: 18,
      //   icon: "clients",
      //   label: "Клиенты",
      //   path: "/clients",
      //   action: false,
      //   fastAdd: false,
      //   expandable: false,
      // },
      {
        id: 16,
        icon: "payments",
        idLabel: "payments",
        label: "Оплаты",
        path: "/payments",
        action: false,
        fastAdd: false,
        expandable: false,
        addLink: false,
      },
      // {
      //   id: 17,
      //   icon: "history-payment-3",
      //   label: "История изменений",
      //   path: "/changes",
      //   action: false,
      //   fastAdd: false,
      //   expandable: false,
      // },
      {
        id: "company",
        idLabel: "company",
        icon: "union",
        label: "Компания",
        path: "/company",
        action: false,
        fastAdd: false,
        expandable: false,
        addLink: false,
      },
      {
        id: 15,
        idLabel: "widgets",
        icon: "integration",
        label: "Виджеты",
        path: "/widgets",
        action: false,
        count: false,
        fastAdd: false,
        expandable: false,
        addLink: false,
      },
      {
        id: 4,
        idLabel: "notifications",
        icon: "notifications2",
        label: "Шаблоны уведомлений",
        path: "/notifications",
        action: false,
        count: false,
        fastAdd: false,
        expandable: false,
        addLink: false,
      },
      {
        id: 3,
        idLabel: "promo",
        icon: "promo",
        label: "Скидки и промокоды",
        path: "/promo",
        action: false,
        count: false,
        fastAdd: false,
        expandable: false,
        addLink: false,
      },
      {
        id: 5,
        idLabel: "person",
        icon: "person",
        label: "Персонал",
        path: "/personal",
        action: false,
        count: false,
        fastAdd: false,
        expandable: false,
        addLink: false,
      },
      // {
      //   id: 7,
      //   idLabel: "balance",
      //   icon: "balance",
      //   label: "Баланс",
      //   path: "/balance",
      //   action: false,
      //   count: {
      //     prefix: "",
      //     value: 0,
      //     suffix: "руб",
      //   },
      //   fastAdd: {
      //     state: false,
      //   },
      //   expandable: false,
      //   addLink: false,
      // },
      {
        id: 9,
        idLabel: "support",
        icon: "feedback",
        label: "Поддержка",
        path: "/support",
        action: false,
        count: {
          prefix: "",
          value: 0,
          suffix: "",
        },
        fastAdd: false,
        expandable: false,
        addLink: false,
      },
      {
        id: 11,
        idLabel: "docs",
        icon: "docs",
        label: "Инструкции",
        path: "/docs",
        action: false,
        count: false,
        fastAdd: false,
        expandable: false,
        addLink: false,
      },
      // {
      //   id: 8,
      //   idLabel: "mail",
      //   icon: "mail2",
      //   label: "CМС",
      //   path: "/sms",
      //   action: false,
      //   count: {
      //     prefix: "",
      //     value: 0,
      //     suffix: "шт",
      //   },
      //   fastAdd: {
      //     state: false,
      //   },
      //   expandable: false,
      //   addLink: false,
      // },
      {
        id: 10,
        idLabel: "news",
        icon: "news2",
        label: "Что нового",
        path: "/news",
        action: false,
        count: {
          prefix: "",
          value: 0,
          suffix: "",
        },
        fastAdd: false,
        expandable: false,
        addLink: false,
      },
      {
        id: 11,
        idLabel: "inviteFriend",
        icon: "gift",
        label: "Пригласи друга!",
        path: "/invite",
        action: false,
        // count: {
        //   prefix: "",
        //   value: 0,
        //   suffix: "",
        // },
        fastAdd: false,
        expandable: false,
        addLink: false,
      },
      // {
      //   id: 12,
      //   idLabel: "api",
      //   icon: "api",
      //   label: "API",
      //   path: "/api",
      //   action: false,
      //   count: false,
      //   fastAdd: false,
      //   expandable: false,
      //   addLink: false,
      // },
      // {
      //   id: 13,
      //   idLabel: "settings",
      //   icon: "settings",
      //   label: "Настройки профиля",
      //   path: "/settings",
      //   action: false,
      //   count: false,
      //   fastAdd: false,
      //   expandable: false,
      //   addLink: false,
      // },
      // {
      //   id: 14,
      //   idLabel: "exit",
      //   icon: "exit",
      //   label: "Выйти",
      //   path: false,
      //   action: {
      //     name: "logout",
      //   },
      //   count: false,
      //   fastAdd: false,
      //   expandable: false,
      //   addLink: false,
      // },
    ],
    copyright: {
      label: "Web-Progress",
      path: "https://web-pro-spb.ru/",
    },
  },
  getters: {
    menu: (state) => state.menu,
    copyright: (state) => state.copyright,
    menuItem: (state) => (menuItemName) => {
      return state.menu.find((item) => item.label === menuItemName);
    },
    mobileMenu: (state) => {
      const labels = ["dashboard", "schedule", "reserves", "services"];
      const mobileMenu = [];
      labels.forEach((label) => {
        const target = state.menu.find((item) => {
          return item.idLabel === label;
        });

        if (target) {
          mobileMenu.push(target);
        }
      });

      return mobileMenu;
    },
  },
  mutations: {
    toggleExpandable(state, menuItemId) {
      const target = state.menu.find((item) => item.id === menuItemId);
      target.expandable.state = !target.expandable.state;
    },
    toggleItemFastAdd(state, menuItemId) {
      const target = state.menu.find((item) => item.id === menuItemId);
      target.fastAdd.state = !target.fastAdd.state;
    },
    setResourcesCount(state, resourceCount) {
      const target = state.menu.find((item) => item.idLabel === "resources");
      target.count.value = resourceCount;
    },
    incrementCount(state, menuItem) {
      const target = state.menu.find((item) => item.idLabel === menuItem);
      if (target.count) {
        target.count.value++;
      }
    },
    setMenuCounts(state, countObj) {
      // eslint-disable-next-line guard-for-in
      for (const key in countObj) {
        const target = state.menu.find((item) => item.idLabel === key);

        if (target) {
          target.count.value = countObj[key];
        }
      }
    },
    setAddLinksCounts(state, countObj) {
      // eslint-disable-next-line guard-for-in
      for (const key in countObj) {
        const target = state.menu.find((item) => item.idLabel === key);

        if (target && countObj[key] > 0) {
          target.addLink = {
            path: `/${key}`,
            prefix: "+",
            value: countObj[key],
            suffix: "ответ",
          };
        } else {
          target.addLink = false;
        }
      }
    },
    decrementCount(state, menuItem) {
      const target = state.menu.find((item) => item.idLabel === menuItem);
      if (target.count) {
        target.count.value--;
      }
    },
  },
  actions: {
    toggleExpandable({ commit }, menuItemId) {
      commit("toggleExpandable", menuItemId);
    },
    toggleItemFastAdd({ commit }, menuItemId) {
      commit("toggleItemFastAdd", menuItemId);
    },
  },
};
