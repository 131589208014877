var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custom-block form"},[_c('div',{staticClass:"form__title"},[_c('div',{staticClass:"form__title-text",style:({
        color: _vm.isMaket
          ? _vm.styleCustom.active_text_color || _vm.custom.active_text_color
          : '',
      })},[_vm._v(" Бронирование услуги ")]),_c('svg-icon',{staticClass:"form__title-close",attrs:{"icon-name":"close","width":"13"},nativeOn:{"click":function($event){return _vm.close()}}})],1),_c('div',{staticClass:"form__sub-title",style:({
      color: _vm.isMaket
        ? _vm.styleCustom.active_text_color || _vm.custom.active_text_color
        : '',
    })},[_vm._v(" Обзорная экскурсия «История и пространство Древнего Пскова» ")]),(_vm.showCalendar)?_c('functional-calendar',{staticClass:"mb-30",attrs:{"configs":_vm.calendarConfigs},on:{"update:configs":function($event){_vm.calendarConfigs=$event}},model:{value:(_vm.calendar),callback:function ($$v) {_vm.calendar=$$v},expression:"calendar"}}):_vm._e(),_c('div',{staticClass:"form__row"},[_c('div',{staticClass:"form__row-column"},[_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title",style:({
            color:
              _vm.isIndividualTheme || _vm.isMaket ? _vm.custom.inactive_text_color : '',
          })},[_vm._v(" Дата ")]),_c('div',{staticClass:"row-info__value",style:({
            color:
              _vm.isIndividualTheme || _vm.isMaket ? _vm.custom.active_text_color : '',
          })},[_vm._v(" 04.02.2021 ")])]),_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title",style:({
            color:
              _vm.isIndividualTheme || _vm.isMaket ? _vm.custom.inactive_text_color : '',
          })},[_vm._v(" Время ")]),_c('div',{staticClass:"row-info__value",style:({
            color:
              _vm.isIndividualTheme || _vm.isMaket ? _vm.custom.active_text_color : '',
          })},[_vm._v(" 10:30 ")])]),_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title",style:({
            color:
              _vm.isIndividualTheme || _vm.isMaket ? _vm.custom.inactive_text_color : '',
          })},[_vm._v(" Стоимость ")]),_c('div',{staticClass:"row-info__value",style:({
            color:
              _vm.isIndividualTheme || _vm.isMaket ? _vm.custom.active_text_color : '',
          })},[_vm._v(" 1 600 ₽ ")])]),_c('div',{staticClass:"hl",style:({
          background: _vm.isMaket
            ? _vm.styleCustom.background_cells_fields ||
              _vm.custom.background_cells_fields
            : '',
        })}),_c('div',{staticClass:"input-number"},[_c('div',{staticClass:"input-number__info"},[_c('span',{staticClass:"input-number__info-title",style:({
              color:
                _vm.isIndividualTheme || _vm.isMaket ? _vm.custom.active_text_color : '',
            })},[_vm._v("Стандартный")]),_c('span',{staticClass:"input-number__info-subtitle",style:({
              color:
                _vm.isIndividualTheme || _vm.isMaket
                  ? _vm.custom.inactive_text_color
                  : '',
            })},[_vm._v("Неограниченно")])]),_c('el-input-number',{staticClass:"input-number__input",class:{
            'custom-style': _vm.isTypeTheme || _vm.isMaket,
          },style:({
            background:
              _vm.isTypeTheme || _vm.isMaket
                ? _vm.styleCustom.background_cells_fields ||
                  _vm.custom.background_cells_fields
                : '',
          }),attrs:{"min":1},model:{value:(_vm.ticketStandart),callback:function ($$v) {_vm.ticketStandart=$$v},expression:"ticketStandart"}})],1),_c('div',{staticClass:"input-number"},[_c('div',{staticClass:"input-number__info"},[_c('span',{staticClass:"input-number__info-title",style:({
              color:
                _vm.isIndividualTheme || _vm.isMaket ? _vm.custom.active_text_color : '',
            })},[_vm._v("Расширенный")]),_c('span',{staticClass:"input-number__info-subtitle",style:({
              color:
                _vm.isIndividualTheme || _vm.isMaket
                  ? _vm.custom.inactive_text_color
                  : '',
            })},[_vm._v("240 шт.")])]),_c('el-input-number',{staticClass:"input-number__input",class:{
            'custom-style': _vm.isTypeTheme || _vm.isMaket,
          },style:({
            background:
              _vm.isTypeTheme || _vm.isMaket
                ? _vm.styleCustom.background_cells_fields ||
                  _vm.custom.background_cells_fields
                : '',
          }),attrs:{"min":1},model:{value:(_vm.ticketExtended),callback:function ($$v) {_vm.ticketExtended=$$v},expression:"ticketExtended"}})],1),_c('div',{staticClass:"input-number"},[_c('div',{staticClass:"input-number__info"},[_c('span',{staticClass:"input-number__info-title",style:({
              color:
                _vm.isIndividualTheme || _vm.isMaket ? _vm.custom.active_text_color : '',
            })},[_vm._v("Полный")]),_c('span',{staticClass:"input-number__info-subtitle",style:({
              color:
                _vm.isIndividualTheme || _vm.isMaket
                  ? _vm.custom.inactive_text_color
                  : '',
            })},[_vm._v("10 шт.")])]),_c('el-input-number',{staticClass:"input-number__input",class:{
            'custom-style': _vm.isTypeTheme || _vm.isMaket,
          },style:({
            background:
              _vm.isTypeTheme || _vm.isMaket
                ? _vm.styleCustom.background_cells_fields ||
                  _vm.custom.background_cells_fields
                : '',
          }),attrs:{"min":1},model:{value:(_vm.ticketFull),callback:function ($$v) {_vm.ticketFull=$$v},expression:"ticketFull"}})],1)]),_c('div',{staticClass:"form__row-column"},[(_vm.setCheckValue('fieldName'))?_c('el-input',{class:{
          'custom-style': _vm.isTypeTheme || _vm.isMaket,
        },style:({
          background:
            _vm.isTypeTheme || _vm.isMaket
              ? _vm.styleCustom.background_cells_fields ||
                _vm.custom.background_cells_fields
              : '',
        }),attrs:{"placeholder":"Имя*"}}):_vm._e(),(_vm.setCheckValue('fieldFamaly'))?_c('el-input',{class:{
          'custom-style': _vm.isTypeTheme || _vm.isMaket,
        },style:({
          background:
            _vm.isTypeTheme || _vm.isMaket
              ? _vm.styleCustom.background_cells_fields ||
                _vm.custom.background_cells_fields
              : '',
        }),attrs:{"placeholder":"Фамилия"}}):_vm._e(),(_vm.setCheckValue('fieldEmail'))?_c('el-input',{class:{
          'custom-style': _vm.isTypeTheme || _vm.isMaket,
        },style:({
          background:
            _vm.isTypeTheme || _vm.isMaket
              ? _vm.styleCustom.background_cells_fields ||
                _vm.custom.background_cells_fields
              : '',
        }),attrs:{"placeholder":"E-mail*"}}):_vm._e(),(_vm.setCheckValue('fieldPhone'))?_c('el-input',{class:{
          'custom-style': _vm.isTypeTheme || _vm.isMaket,
        },style:({
          background:
            _vm.isTypeTheme || _vm.isMaket
              ? _vm.styleCustom.background_cells_fields ||
                _vm.custom.background_cells_fields
              : '',
        }),attrs:{"placeholder":"Номер телефона*"}}):_vm._e(),(_vm.setCheckValue('fieldComment'))?_c('el-input',{class:{
          'custom-style': _vm.isTypeTheme || _vm.isMaket,
        },style:({
          background:
            _vm.isTypeTheme || _vm.isMaket
              ? _vm.styleCustom.background_cells_fields ||
                _vm.custom.background_cells_fields
              : '',
        }),attrs:{"placeholder":"Комментарий"}}):_vm._e(),(_vm.setCheckValue('fieldPromo'))?_c('el-input',{class:{
          'custom-style': _vm.isTypeTheme || _vm.isMaket,
        },style:({
          background:
            _vm.isTypeTheme || _vm.isMaket
              ? _vm.styleCustom.background_cells_fields ||
                _vm.custom.background_cells_fields
              : '',
        }),attrs:{"placeholder":"Промо-код"}}):_vm._e(),(_vm.setCheckValue('fieldСlient') && _vm.isUBRR)?_c('el-input',{class:{
          'custom-style': _vm.isTypeTheme || _vm.isMaket,
        },style:({
          background:
            _vm.isTypeTheme || _vm.isMaket
              ? _vm.styleCustom.background_cells_fields ||
                _vm.custom.background_cells_fields
              : '',
        }),attrs:{"placeholder":"Код клиента"}}):_vm._e(),(_vm.setCheckValue('fieldUbrirTel') && _vm.isUBRR)?_c('el-input',{class:{
          'custom-style': _vm.isTypeTheme || _vm.isMaket,
        },style:({
          background:
            _vm.isTypeTheme || _vm.isMaket
              ? _vm.styleCustom.background_cells_fields ||
                _vm.custom.background_cells_fields
              : '',
        }),attrs:{"placeholder":"Последние 7 цифр телефона*"}}):_vm._e(),(_vm.add_params)?_c('div',_vm._l((_vm.add_params),function(item){return _c('div',{key:item.param_id,staticClass:"mb-20",class:{
            'custom-style': _vm.isTypeTheme || _vm.isMaket,
          },style:({
            background:
              _vm.isTypeTheme || _vm.isMaket
                ? _vm.styleCustom.background_cells_fields ||
                  _vm.custom.background_cells_fields
                : '',
          })},[_c('el-input',{class:{
              'custom-style': _vm.isTypeTheme,
            },style:({
              background: _vm.isTypeTheme
                ? _vm.styleCustom.background_cells_fields ||
                  _vm.custom.background_cells_fields
                : '',
            }),attrs:{"placeholder":item.param_name},model:{value:(item.param_value),callback:function ($$v) {_vm.$set(item, "param_value", $$v)},expression:"item.param_value"}})],1)}),0):_vm._e()],1)]),_c('div',{staticClass:"hl"}),_c('div',{staticClass:"form__row"},[_c('p',{staticClass:"form__row-column hint-title__medium-white",style:({
        color:
          _vm.isTypeTheme || _vm.isMaket
            ? _vm.styleCustom.inactive_text_color || _vm.custom.inactive_text_color
            : '',
      })},[_vm._v(" Нажимая на кнопку “Забронировать”, вы автоматически соглашаетесь с условиями пользовательского соглашения ")]),_c('div',{staticClass:"form__row-column actions"},[_c('div',{staticClass:"actions__info",style:({
          // background: isIndividualTheme
          //   ? custom.active_buttons_background
          //   : '',
          color: _vm.isIndividualTheme ? _vm.custom.button_text_color : '',
        })},[_c('p',{staticClass:"actions__info-title",style:({
            color:
              _vm.isTypeTheme || _vm.isMaket
                ? _vm.styleCustom.active_text_color || _vm.custom.active_text_color
                : '',
          })},[_vm._v(" К оплате ")]),_c('p',{staticClass:"actions__info-content",style:({
            color:
              _vm.isTypeTheme || _vm.isMaket
                ? _vm.styleCustom.active_text_color || _vm.custom.active_text_color
                : '',
          })},[_vm._v(" 1 600 ₽ ")])]),_c('el-button',{staticClass:"actions__button",style:({
          // background: isIndividualTheme
          //   ? custom.active_buttons_background
          //   : '',
          color: _vm.isIndividualTheme ? _vm.custom.button_text_color : '',
        }),attrs:{"type":"primary"}},[_vm._v(" Забронировать ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }