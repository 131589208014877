<template>
  <el-collapse-item
    :name="name"
    :disabled="disabled"
    :class="['collapse', { disabled: disabled }]"
  >
    <div slot="title" class="collapse-title">
      <svg-icon icon-name="chevron" width="12" height="12"></svg-icon>
      <div>
        <h2>{{ title }}</h2>
        <p v-if="subTitle">{{ subTitle }}</p>
      </div>
    </div>
    <div class="collapse-content">
      <slot />
    </div>
  </el-collapse-item>
</template>

<script>
export default {
  name: "BlockCollapse",
  props: {
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    disabled: Boolean,
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.collapse {
  margin-bottom: 30px !important;
  border-bottom: none;

  &-title {
    padding: 20px 30px;
    font-weight: 600;
    font-size: 18px;
    display: flex;
    align-items: center;
    flex-grow: 1;

    svg {
      color: $--color-white;
      margin-right: 25px;
    }

    h2 {
      font-size: 22px;
      font-weight: 600;
      line-height: 28px;
      color: $--color-white;
      transition: all 0.5s;
    }
    p {
      font-size: 12px;
      line-height: 20px;
      color: $--color-white-5;
      font-weight: 500;
      margin-top: 6px;
    }
  }

  &-content {
    padding: 30px;
    border-top: 1px solid $--color-light-white;
  }

  .is-active h2 {
    color: $--color-primary;
  }
}

.disabled {
  opacity: 0.5;
}
@screen lg {
  .collapse {
    margin-bottom: 20px;
    &-title {
      padding: 20px;

      h2 {
        font-size: 18px;
        line-height: 20px;
      }
    }

    &-content {
      padding: 15px;
    }
  }
}
</style>
