<template>
  <div class="instructions">
    <div class="instructions__title">{{ title }}</div>
    <no-data v-if="noData" size="small" text="Инструкций пока нет" />
    <div class="instructions__wrapper">
      <template v-for="(doc, index) in docs" class="">
        <instruction-item
          v-if="doc.video_link || doc.link"
          :key="index"
          :only-button="onlyButton"
          :doc="doc"
        />
      </template>
    </div>
  </div>
</template>

<script>
import InstructionItem from "@/components/InstructionItem";
import NoData from "@/components/NoData";

export default {
  name: "Instructions",
  components: {
    InstructionItem,
    NoData,
  },
  props: {
    docs: {
      type: [Array, Object],
      default: () => [],
    },
    onlyButton: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Инструкции",
    },
  },
  data() {
    return {
      noData: false,
    };
  },
  created() {
    this.checkData();
  },
  methods: {
    checkData() {
      let count = 0;
      this.docs.forEach((el) => {
        if (!el.link && !el.video_link) count += 1;
      });
      if (count > 0) this.noData = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.instructions {
  border-radius: 3px;
  background-color: $--color-ultra-white;
  padding: 20px 30px 0;
  &__wrapper {
    justify-content: space-between;
  }
  ::v-deep &__item {
    width: calc(50% - 8px);
  }

  &__title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: $--color-white;
    margin-bottom: 20px;
  }

  &__wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @media (max-width: 470px) {
      flex-direction: column;
    }
  }
}
</style>
