<template>
  <div>
    <span class="return__text">{{ `или выберите количество ${count}: ` }}</span>
    <div class="input-number my-10">
      <div class="input-number__info">
        <span class="input-number__info-title">{{ count }}</span>
      </div>
      <el-input-number
        v-model="itemCount"
        class="input-number__input"
        :max="item.rent.duration"
        :min="0"
        @input="$emit('setItemCount', itemCount)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "RentDepOnlTime",
  props: {
    item: {
      type: Object,
    },
    count: String,
  },
  data() {
    return {
      itemCount: null,
    };
  },
  created() {
    this.itemCount = this.item.rent.duration;
  },
};
</script>

<style lang="scss" scoped>
.return {
  &__text {
    color: #fff;
  }
}
</style>
