<template>
  <div class="">
    <div class="custom-block" :class="{ 'more-elements': moreElements }">
      <div class="search">
        <el-input
          ref="search"
          v-model="search"
          class="search__input max-w-full max"
          :placeholder="inputPlaceholder"
          @blur="submit"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
          <i
            v-show="search && search.length > 0"
            slot="suffix"
            class="el-input__icon el-icon-close pointer-events-auto relative cursor-pointer"
            style="left: 10px"
            @click="(search = ''), submit()"
          ></i>
        </el-input>
        <div
          class="search__dropdown"
          :class="{ 'w-auto': perPageAdditionalFilter }"
        >
          <span>{{
            perPageAdditionalFilter ? "Показывать" : "Показывать по"
          }}</span>
          <el-select
            v-if="perPageAdditionalFilter"
            v-model="filter"
            class="search__filter"
            @change="submit()"
          >
            <el-option
              v-for="(item, index) in perPageAdditionalFilter"
              :key="`${index}-additional`"
              :value="item.value"
              :label="item.name"
              >{{ item.name }}</el-option
            >
          </el-select>
          <span v-if="perPageAdditionalFilter">по</span>
          <el-select
            v-model="perPage"
            class="search__perpage w-full"
            @change="submit()"
          >
            <el-option
              v-for="(el, index) in pagOptions"
              :key="`${index}-pag`"
              :value="el"
              >{{ el }}</el-option
            >
          </el-select>
        </div>
        <div v-if="dateBlock" class="search__date">
          Запись создана
          <el-date-picker
            v-model="date"
            type="daterange"
            format="dd.MM.yyyy"
            range-separator="По"
            start-placeholder="Любая"
            end-placeholder="Любая"
            class="ml-10"
            style="width: 240px"
          />
        </div>
        <div v-if="exportLinks" class="search__exports">
          <!-- 'disable-links': index === 1 -->
          <router-link
            v-for="(item, index) in exportLinks"
            :key="item.link"
            :to="{
              path: item.link,
              query: {
                'filter[names]': $route.query.names,
                'filter[statuses]': $route.query.statuses,
                'filter[bron_from]': $route.query.from
                  ? $route.query.from
                  : $route.query.bron_from,
                'filter[bron_to]': $route.query.to
                  ? $route.query.to
                  : $route.query.bron_to,
                'filter[payment_status]': $route.query.payment_status,
                'filter[service_time]': $route.query.service_time,
              },
            }"
            target="_blank"
            :class="{ 'ml-15': index > 0 }"
            class="search__export"
          >
            <span>{{ item.name }}</span>

            <span class="icon_rounded icon_rounded_question">
              <el-popover
                class="ml-8"
                placement="top"
                width="200"
                trigger="hover"
                :content="`${item.title}`"
              >
                <Tooltip slot="reference" />
              </el-popover>
              <!-- <svg-icon width="5" height="9" icon-name="question"></svg-icon> -->
            </span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { AJAX_GET, AJAX_POST } from "@/units/ajax.js";

export default {
  props: {
    exportLinks: Array,
    inputPlaceholder: String,
    dateBlock: Boolean,
    perPageAdditionalFilter: Array,
    resetFilterSearch: String,
  },
  data() {
    return {
      pagOptions: [10, 25, 50, 100],
      search: "",
      perPage: 10,
      filter: null,
      date: null,
    };
  },
  computed: {
    moreElements() {
      let count = 0;
      Object.values(this.$props).forEach((el) => {
        if (el || el?.length > 0) count += 1;
      });
      return count > 2;
    },
  },
  watch: {
    resetFilterSearch() {
      if (this.resetFilterSearch === "clear") {
        this.search = "";
        this.perPage = 10;
        this.filter = null;
        this.$emit("changeStatusFilter", null);
      }
    },
  },
  created() {
    const param = Number(this.$route.query.paginate);
    this.search = this.$route.query.search_filter;

    if (param) {
      this.perPage = param;
    } else {
      this.perPage = 10;
    }
  },
  methods: {
    submit() {
      //   if (this.search) {
      this.$emit("submit", this.$data);
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

::v-deep .el-input {
  &__inner {
    &:hover,
    &:focus,
    &:active {
      background: $--color-light-white;
      color: white;
    }
  }
}
.custom-block {
  background: rgba(255, 255, 255, 0.04);
}
.search {
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: content-box;
  &__left {
    display: flex;
  }
  &__date {
    display: flex;
    align-items: center;
    max-height: 40px;
    margin-right: 20px;
  }
  &__input {
    width: 320px;
    min-height: 40px;
    ::v-deep .el-input__inner {
      padding-left: 40px;
      min-height: 100%;
    }
    max-height: 40px;
  }
  &__filter {
    min-width: 200px;
    margin: 0 10px;
  }
  &__perpage {
    min-width: 65px;
  }
  &__dropdown {
    display: flex;
    align-items: center;
    margin: 0 30px;
    max-height: 40px;
    span {
      white-space: nowrap;
    }
    .el-select {
      margin-left: 10px;
      width: 78px;
      ::v-deep .el-input__suffix {
        right: 5px;
      }
    }
  }
  &__exports {
    max-height: 40px;
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  &__export {
    display: flex;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.35);
    padding: 13px 20px;
    font-size: 14px;
    border-radius: 3px;
    height: 100%;
    .icon_rounded {
      width: 17px;
      height: 17px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      &_question {
        background-color: #3498db;
        min-width: 17px;
      }
    }
    span {
      display: flex;
      align-items: center;
      &:first-of-type {
        margin-right: 10px;
      }
    }
  }
}
.more-elements {
  .search {
    &__input {
      width: 200px;
    }
  }
}
@media (max-width: 1550px) {
  .more-elements {
    .search {
      &__input {
        margin-bottom: 20px;
      }
      &__exports {
        margin-left: 0;
      }
    }
  }
}
@media (max-width: 900px) {
  .more-elements {
    .search {
      &__input {
        width: 100%;
      }
    }
  }
  .search {
    flex-direction: column;
    height: auto;
    &__input {
      width: 100%;
    }
    &__date,
    &__input,
    &__dropdown {
      margin-bottom: 20px;
      max-height: none;
      flex-wrap: wrap;
    }
    &__dropdown {
      margin-left: 0;
    }
    &__exports {
      margin: 0;
      max-height: none;
      flex-wrap: wrap;
    }
  }
}
@media (max-width: 525px) {
  .search {
    &__dropdown {
      flex-direction: column;
      align-items: flex-start;
      & > * {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      ::v-deep .el-select {
        margin-left: 0;
      }
    }
    &__exports {
      justify-content: space-between;
      & > * {
        margin-left: 0;
        width: 100%;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
.disable-links {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.5;
}
</style>
