<template>
  <el-form
    ref="baseInfo"
    hide-required-asterisk
    label-position="top"
    class="address"
  >
    <Tabs
      v-if="isNew && !company.address.length"
      v-model="activeTab"
      :list-link="listOptionsAddress"
    />
    <el-button
      v-if="isNew && company.address.length"
      :class="['add-button', { 'add-button__mb': isShowMap }]"
      @click="isShowMap = !isShowMap"
    >
      Добавить адрес
      <i v-show="isShowMap" class="el-icon-arrow-up"></i>
      <i v-show="!isShowMap" class="el-icon-arrow-down"></i>
    </el-button>
    <MapCompany
      v-show="'writeAddress' === activeTab && isShowMap"
      ref="MapCompany"
      class="mb-20"
      :value="company.address[index]"
      @validate="isValidMap = $event"
      @input="addAddress"
    />
    <div
      v-if="!isNew && dataServices && dataServices.count"
      class="services-list"
    >
      <h5 class="services-list__title">Услуги</h5>
      <div v-for="(service, i) in dataServices.data" :key="i">
        <p class="services-list__name-service">{{ service.name }}</p>
        <p class="hint-title">{{ getCategory(service.kind_of_activity) }}</p>
      </div>
    </div>
    <el-button
      v-show="isShowMap"
      type="primary"
      class="custom-button"
      @click="submit()"
    >
      Сохранить
    </el-button>
    <el-button v-if="!isNew && isOneEl" @click="deleteAddress()">
      Удалить
    </el-button>
  </el-form>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import MapCompany from "@/views/Company/components/MapCompany.vue";
import Tabs from "@/components/Tabs.vue";

export default {
  name: "AddressData",
  components: {
    MapCompany,
    Tabs,
  },
  props: {
    isNew: Boolean,
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isValidMap: false,
      isShowMap: true,
      activeTab: "writeAddress",
    };
  },
  computed: {
    ...mapState("company", ["company"]),
    ...mapGetters("company", ["getCategory"]),
    isOneEl() {
      return this.company.address.length > 1;
    },
    dataServices() {
      return this.company.address[this.index].linked_services;
    },
  },
  created() {
    this.listOptionsAddress = [
      {
        title: "С адресом",
        value: "writeAddress",
      },
      {
        title: "Без адреса",
        value: "fromSetting",
      },
    ];
  },
  methods: {
    ...mapMutations("company", ["setDataCompany"]),
    ...mapActions("company", ["editInfoCompany"]),
    submit() {
      this.$refs.MapCompany.validateMap();
      if (this.isValidMap) {
        this.editInfoCompany("address");
      } else {
        this.$notify({
          title: `Заполните адрес услуги`,
          type: "warning",
          duration: 3000,
          showClose: false,
          customClass: "warning",
        });
      }
    },
    deleteAddress() {
      this.setDataCompany({
        field: "address",
        value: this.company.address.filter(
          (item, index) => index !== this.index
        ),
      });
      this.editInfoCompany("address");
    },
    addAddress(value) {
      if (this.isNew) {
        this.setDataCompany({
          field: "address",
          value: [...this.company.address, value],
        });
        this.isShowMap = false;
      } else {
        this.company.address.splice(this.index, 1, value);
        this.setDataCompany({
          field: "address",
          value: this.company.address,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.services-list {
  font-family: Montserrat;
  font-style: normal;
  color: $--color-white;
  margin-top: 10px;
  margin-bottom: 30px;

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 15px;
  }
  &__name-service {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
  .hint-title {
    padding-bottom: 10px;
    color: $--color-white-5;
  }
}

.add-button {
  border-color: $--color-primary;
  color: $--color-white;

  &__mb {
    margin-bottom: 30px;
  }
}

@screen lg {
}
</style>
