<template>
  <div
    :class="[
      'custom-block',
      'form-sticky',
      'form',
      {
        'w-full': isDrawer,
      },
    ]"
  >
    <div class="form__title">
      <div class="form__title-text">Бронирование услуги</div>
      <svg-icon
        icon-name="close"
        class="form__title-close"
        width="13"
        @click.native="close()"
      />
    </div>
    <div class="form__description">
      Обзорная экскурсия «История и пространство Древнего Пскова»
    </div>
    <div class="row-info">
      <div class="row-info__title">Дата</div>
      <div class="row-info__value">04.02.2021</div>
    </div>
    <div class="row-info">
      <div class="row-info__title">Время</div>
      <div class="row-info__value">10:30</div>
    </div>
    <div class="row-info">
      <div class="row-info__title">Стоимость</div>
      <div class="row-info__value">1 600 ₽</div>
    </div>
    <div class="hl"></div>
    <div class="form__sub-title">Выберите тариф</div>
    <el-radio v-model="typeSale" label="Standart"> Стандартный тариф </el-radio>
    <el-radio v-model="typeSale" label="Full"> Полный тариф </el-radio>
    <el-radio v-model="typeSale" class="last-child" label="Extend">
      Расширенный тариф
    </el-radio>
    <div class="bk-full">
      <p class="hint">Количество участников</p>
      <el-input-number
        v-model="totalPerson"
        class="w-full"
        :min="1"
      ></el-input-number>
    </div>
    <div class="form__sub-title">Контактные данные</div>
    <el-input placeholder="Имя*"></el-input>
    <el-input placeholder="Фамилия"></el-input>
    <el-input placeholder="E-mail*"></el-input>
    <el-input placeholder="Номер телефона*"></el-input>
    <el-input placeholder="Комментарий"></el-input>
    <el-input placeholder="Промо-код"></el-input>
    <p class="hint-title__medium-white">
      Нажимая на кнопку “Забронировать”, вы автоматически соглашаетесь с
      условиями пользовательского соглашения
    </p>
    <div class="form__row actions">
      <div class="form__row-column">
        <div class="actions__info">
          <p class="actions__info-title">К оплате</p>
          <p class="actions__info-content">1 600 ₽</p>
        </div>
      </div>
      <div class="form__row-column actions__button">
        <el-button type="primary">Забронировать</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FormSticky",
  components: {},
  props: {
    isMaket: Boolean,
    showCalendar: Boolean,
    isDrawer: Boolean,
  },
  data() {
    return {
      totalPerson: null,
      typeSale: "Standart",
    };
  },
  computed: {},
  created() {
    this.listTime = [
      {
        value: "11:00",
        label: "11:00",
      },
    ];
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.form {
  padding: 20px;
  margin-bottom: 0;
  &__title {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    &-text {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: $--color-white;
      margin-bottom: 5px;
      flex-grow: 1;
    }
    &-close {
      flex-shrink: 0;
      margin-left: 12px;
      color: $--color-white-5;
      cursor: pointer;
    }
  }
  &__description {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 40px;
  }
  &__sub-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 15px;
  }
  &__row {
    display: flex;
    flex-direction: row;
    margin: 0 -20px;
    &-column {
      display: flex;
      flex-direction: column;
      padding: 0 20px;
    }
  }
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 20px;
  margin-top: 12px;
  border-top: 1px solid $--color-white-15;
  &__info {
    display: flex;
    flex-direction: column;
    &-title {
      color: $--color-white-5;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
    }
    &-content {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }
  }
  &__button {
    margin-left: auto;
  }
}

::v-deep {
  .el-input {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .el-radio {
    margin-bottom: 10px;
  }

  .vfc-calendar {
    border: 1px solid $--color-sm-white;
    &:nth-last-child(1) {
      border-radius: 0 3px 3px 0;
    }
    &:nth-last-child(2) {
      border-left: 1px solid rgba(255, 255, 255, 0.2);
      // border-right: 1px solid rgba(255, 255, 255, 0.2);
    }
    &:nth-last-child(3) {
      border-radius: 3px 0 0 3px;
    }
  }
}

.w-full {
  width: 100% !important;
}
.hl {
  margin: 20px -0;
}
.last-child {
  margin-bottom: 20px;
}
</style>
