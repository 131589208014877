<template>
  <div class="pt-40 pb-80 w-full lg:pt-15 px-10">
    <div
      class="flex justify-between items-center mb-10 xs:px-0 ssm:flex-col ssm:items-start xs:mb-5"
    >
      <Top title="  Скидки и промокоды" :font="{ size: 22, leading: 22 }" />
    </div>
    <Tabs v-model="activeTab" :items="tabs" />
    <component
      :is="activeTab"
      ref="component"
      :active-tab="activeTab"
      @loaded="isLoading = false"
      @setCount="setCount"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Top from "@/components/Pages/Top";
import Tabs from "@/components/Promo/Tabs";
import Promocode from "@/components/Promo/Promocode";
import Discount from "@/components/Promo/Discount";

export default {
  name: "Promo",
  components: { Top, Tabs, Promocode, Discount },
  data() {
    return {
      isLoading: true,
      activeTab: "promocode",
      tabs: [
        {
          label: "Промокоды",
          count: "",
          name: "promocode",
        },
        {
          label: "Скидки",
          count: "",
          name: "discount",
        },
      ],
    };
  },
  computed: { ...mapState("promo", ["discountItemsCount"]) },
  methods: {
    show() {
      this.$refs.component.showCreateModal();
    },
    setCount(item) {
      if (item) {
        const tab = this.tabs.filter((el) => el.name === item.key)[0];
        tab.count = item.count || 0;
      }
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .promocode-head {
  &__left {
    flex-basis: 254px;

    @screen xxl {
    }
  }
}

::v-deep .promocode-stat {
  display: flex;
  justify-content: flex-end;
  min-width: 52%;
  // grid-template-columns: 65px 70px 83px 225px;
  grid-gap: 30px;

  @screen xxl {
    grid-gap: 20px;
  }
}

::v-deep .promo-desc {
  max-width: 600px;
}

::v-deep.form-sale {
  width: 100%;
  max-width: 525px;
}

::v-deep .promo-tooltip {
  @screen lg {
    right: 20px;
    top: 15px;
  }
}
</style>
