<template>
  <div class="list-fields">
    <div
      v-for="(item, index) in listField"
      :key="index"
      class="list-fields__row"
    >
      <div class="list-fields__item">
        <DoubleInput
          :headers="headers[0]"
          class="double-input"
          :value="{
            from: item.from,
            to: item.to,
          }"
          @input="setNumber({ index, value: $event })"
        ></DoubleInput>
      </div>
      <div class="list-fields__item">
        <el-form-item prop="basePrice" class="w-full">
          <div slot="label" class="flex">
            <div>
              {{ headers[1].title }}
            </div>
            <span v-if="headers[1].valid" class="danger">*</span>
            <el-popover
              v-if="headers[1].popover"
              class="ml-8"
              placement="top"
              width="200"
              trigger="hover"
              :content="headers[1].popoverText"
            >
              <Tooltip slot="reference" />
            </el-popover>
          </div>
          <el-input
            v-model.number="item.base_price"
            placeholder="Введите цену"
            @input="emitData()"
          >
            <div slot="suffix">₽</div>
          </el-input>
        </el-form-item>
      </div>
      <div class="list-fields__item">
        <el-form-item prop="basePrice" class="w-full">
          <div slot="label" class="flex">
            <div>
              {{ headers[2].title }}
            </div>
            <span v-if="headers[2].valid" class="danger">*</span>
            <el-popover
              v-if="headers[2].popover"
              class="ml-8"
              placement="top"
              width="200"
              trigger="hover"
              :content="headers[2].popoverText"
            >
              <Tooltip slot="reference" />
            </el-popover>
          </div>
          <el-input
            v-model.number="item.holidays_price"
            placeholder="Введите цену"
            @input="emitData()"
          >
            <div slot="suffix">₽</div>
          </el-input>
        </el-form-item>
      </div>
      <div v-if="listField.length > 1" class="list-fields__actions lg:w-full">
        <ButtonIcon
          icon-name="close"
          width="16"
          :class="$mq.tablet ? 'w-max' : 'w-auto'"
          :text="$mq.tablet ? 'Удалить цены' : ''"
          @click="deleteField(index)"
        />
      </div>
    </div>
    <el-button class="list-fields__add-button" @click="addField()">
      {{ titleButton }}
    </el-button>
  </div>
</template>

<script>
import DoubleInput from "@/components/DoubleInput.vue";

export default {
  name: "ListField3",
  components: {
    DoubleInput,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    titleButton: {
      type: String,
      default: "Добавить новое поле",
    },
    placeholder: {
      type: String,
      default: "Введите значение",
    },
    headers: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      listField: [
        {
          id: null,
          from: 1,
          to: null,
          base_price: null,
          holidays_price: null,
        },
      ],
    };
  },
  watch: {
    value() {
      this.listField = [...this.value];
    },
  },
  created() {
    this.listField = [...this.value];
  },
  methods: {
    setNumber({ index, value }) {
      this.listField[index].from = value.from;
      this.listField[index].to = value.to;
      this.emitData();
    },
    deleteField(index) {
      this.listField.splice(index, 1);
      this.emitData();
    },
    addField() {
      this.listField.push({
        id: null,
        from: null,
        to: null,
        base_price: null,
        holidays_price: null,
      });
      this.emitData();
    },
    emitData() {
      this.$emit("input", [...this.listField]);
    },
  },
};
</script>

<style lang="scss" scoped></style>
