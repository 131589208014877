<template>
  <el-table
    :data="historySms.data"
    class="border-b-0 mb-20 overflow__text"
    sortable
    :stripe="true"
  >
    <div>{{ historySms }}</div>

    <el-table-column
      type="index"
      :index="indexMethod"
      label="№"
      sortable
      :width="62"
    >
    </el-table-column>
    <el-table-column
      prop="sum"
      sortable
      label="Отправлено"
      class="date"
      :width="183"
    >
      <div slot-scope="{ row }">
        {{ row.date.date }}
        <span class="small_text">{{ row.date.time }}</span>
      </div>
    </el-table-column>
    <el-table-column
      prop="phone"
      label="Телефон"
      sortable
      :width="$mq.tablet ? 141 : 162"
    >
    </el-table-column>
    <el-table-column
      prop="user"
      class="sender"
      sortable
      label="Отправитель"
      :width="179"
    >
      <template slot-scope="{ row }">
        {{ row.is_system === 1 ? "Система" : "Пользователь" }}
        <span v-if="row.is_system !== 1" class="small_text">
          {{ row.user }}
        </span>
      </template>
    </el-table-column>
    <el-table-column prop="text" sortable label="Текст" :min-width="300">
      <template slot-scope="{ row }"
        ><span class="overflow__text" :title="row.text">{{
          row.text
        }}</span></template
      >
    </el-table-column>
    <el-table-column prop="price" sortable label="Стоимость" :min-width="180">
      <template slot-scope="{ row }">
        <div class="table_section">
          <span class="table_section__label"> Кол-во SMS</span>
          <span class=""> {{ row.count }}</span>
        </div>
        <div class="table_section">
          <span class="table_section__label"> Символов</span>
          <span class=""> {{ row.strlen }}</span>
        </div>
        <div class="table_section">
          <span class="table_section__label"> Стоимость</span>
          <span class=""> {{ row.price }} ₽</span>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      prop="status"
      label="Статус"
      sortable
      :width="$mq.tablet ? 150 : 173"
    >
      <template slot-scope="{ row }">
        <span class="current_status">
          <span
            class="icon_rounded"
            :class="`icon_rounded_${getStatus(row.status).type}`"
          >
            <svg-icon
              :width="getStatus(row.status).width"
              :height="getStatus(row.status).height"
              :icon-name="getStatus(row.status).type"
            ></svg-icon>
          </span>
          {{ row.status }}
        </span>
        <span v-if="row.status_msg" class="table_section__label text-12">{{
          row.status_msg
        }}</span>
        <el-button
          v-if="getStatus(row.status).type == 'question' && !row.status_msg"
          :loading="row.loading"
          type="mini"
          class="w-full"
          @click="checkStatusOf(row)"
        >
          Проверить
        </el-button>
        <span v-if="row.subTitle" class="table_section__label font-medium">{{
          row.subTitle
        }}</span>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { AJAX_POST } from "@/units/ajax";

export default {
  props: {
    currentPage: {
      type: Number,
    },
    pageSize: {
      type: Number,
    },
  },
  computed: {
    ...mapState("sms", ["historySms"]),
    ...mapState("icons", ["question_icon", "success_icon", "cancel_icon"]),
    startListing() {
      if (this.currentPage === 1) return 1;
      return this.currentPage * this.pageSize;
    },
  },

  created() {
    if (this.type === "client") this.getHistorySMS({ perPage: 100 });
  },
  methods: {
    ...mapActions("sms", ["getHistorySMS"]),

    tableRowClassName({ row }) {
      if (row.prop === "text") {
        return "overflow__text";
      }
      return "";
    },
    indexMethod(index) {
      // const curpage = this.page.pagination.pagedata.page;
      // const honventpage = this.page.pagination.pagedata.limit;
      // return index + 1 + (curpage - 1) * limitpage;
      if (this.currentPage === 1) return 1 + index;
      return this.startListing - this.pageSize + 1 + index;
    },
    getStatus(status) {
      let value;

      // eslint-disable-next-line default-case
      switch (status) {
        case "Доставлено": {
          value = this.success_icon;
          break;
        }
        case "Ошибка": {
          value = this.cancel_icon;
          break;
        }
        case "Не доставлено": {
          value = this.cancel_icon;
          break;
        }

        default: {
          value = this.question_icon;
        }
      }
      return value;
    },
    async checkStatusOf(sms) {
      sms.clicked = true;
      sms.loading = true;
      sms.subtitle = "";
      AJAX_POST("/api/sms/check", { id: sms.id }).then((res) => {
        if (res.data.debug.result.status[0] === "duplicate") {
          sms.status = "Не доставлено";
          sms.subTitle = "Дубликат";
        }
      });
      await this.getHistorySMS({
        page: this.currentPage,
        perPage: 100,
      });
      sms.loading = false;
    },
  },
};
</script>
<style lang="scss">
.overflow__text {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  cursor: pointer;
}
</style>
