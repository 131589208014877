<template>
  <div>
    <slot name="top" />
    <div class="relative md:px-0">
      <spinner v-if="loading.page" class="table__spinner" />
      <NoData
        v-if="items && items.length === 0"
        :text="`Нет ${typeDiscount ? 'скидок' : 'промокодов'}`"
      />
      <div
        v-for="(item, index) in items"
        v-else
        :key="item.hash"
        class="promocode bg-white-40 rounded-sm mb-20"
      >
        <div
          class="promocode-head pl-25 pr-10 py-20 flex items-center lg:items-start lg:p-20 relative"
        >
          <div
            v-if="!$mq.tablet"
            class="promocode-head__left flex items-center mr-40"
          >
            <el-button
              class="mr-20 px-0 w-40"
              @click="item.opened = !item.opened"
            >
              <span class="flex items-center justify-center">
                <svg-icon
                  icon-name="chevron"
                  width="12"
                  height="8"
                  class-name="text-white-normal"
                  :class="{ 'rotate-180': !item.opened }"
                ></svg-icon>
              </span>
            </el-button>
            <div>
              <div class="flex items-center mb-3">
                <div
                  class="bg-green-400 w-8 h-8 min-w-8 mr-5 rounded-full"
                  :class="{ 'bg-red-200': !item.active }"
                ></div>
                <div
                  class="text-green-400 text-12 leading-16"
                  :class="{ 'text-red-200': !item.active }"
                >
                  <template v-if="typeDiscount">
                    {{ item.active ? "Активна" : "Неактивна" }}
                  </template>
                  <template v-else>
                    {{ item.active ? "Активен" : "Неактивен" }}
                  </template>
                </div>
              </div>
              <div class="text-uppercase font-semibold">
                {{ item.code }}
              </div>
            </div>
          </div>

          <div v-if="!$mq.tablet" class="promocode-stat">
            <div>
              <div class="text-white-500 text-12 leading-16 mb-3">
                Скидка, {{ item.type === 1 ? "%" : "р." }}
              </div>
              <div class="font-semibold">
                {{ item.value }}
              </div>
            </div>

            <div v-if="item.count">
              <div class="text-white-500 text-12 leading-16 mb-3">
                Кол-во, шт.
              </div>
              <div class="font-semibold">
                {{ item.count }}
              </div>
            </div>
            <div>
              <div class="text-white-500 text-12 leading-16 mb-3">
                {{ typeDiscount ? "Активна" : "Активен" }} для
              </div>
              <div class="font-semibold">
                {{ item.resources.length }} услуги
              </div>
            </div>
            <div>
              <div class="text-white-500 text-12 leading-16 mb-3">
                Срок действия
              </div>

              <div class="font-semibold">
                {{
                  promoWorkDate(item.work_from) +
                  " - " +
                  promoWorkDate(item.work_to)
                }}
              </div>
            </div>
          </div>

          <div v-if="$mq.tablet" class="w-full">
            <div class="flex items-center mb-4">
              <span
                class="rounded-full w-8 h-8 mr-5"
                :class="item.active ? 'bg-green-400' : 'bg-red-200'"
              ></span>
              <div
                class="text-green-400 text-12 leading-16"
                :class="{ 'text-red-200': !item.active }"
              >
                <template v-if="typeDiscount">
                  {{ item.active ? "Активна" : "Неактивна" }}
                </template>
                <template v-else>
                  {{ item.active ? "Активен" : "Неактивен" }}
                </template>
              </div>
            </div>
            <div class="text-18 font-semibold leading-30 uppercase mb-13">
              {{ item.code }}
            </div>
            <div>
              <div class="flex items-center mb-6 xs:justify-between">
                <div class="text-white-500 text-12 leading-16 pr-5">
                  Скидка, {{ item.type === 1 ? "%" : "р." }}
                </div>
                <div class="text-12 leading-20">
                  {{ item.value }}
                </div>
              </div>
              <div class="flex items-center mb-6 xs:justify-between">
                <div class="text-white-500 text-12 leading-16 pr-5">
                  Кол-во, шт.
                </div>
                <div class="text-12 leading-20">
                  {{ item.count }}
                </div>
              </div>

              <div class="flex items-center mb-6 xs:justify-between">
                <div class="text-white-500 text-12 leading-16 pr-5">
                  {{ typeDiscount ? "Активна" : "Активен" }} для
                </div>
                <div class="text-12 leading-20">
                  {{ item.resources.length }} услуги
                </div>
              </div>

              <div class="flex items-center xs:justify-between">
                <div class="text-white-500 text-12 leading-16 pr-5">
                  Срок действия
                </div>
                <div class="text-12 leading-20">
                  {{
                    promoWorkDate(item.work_from) +
                    " - " +
                    promoWorkDate(item.work_to)
                  }}
                </div>
              </div>
            </div>
          </div>

          <el-popover
            :key="item.hash"
            class="ml-auto cursor-pointer lg:absolute promo-tooltip"
            placement="bottom-end"
            popper-class="control-poper"
            width="171"
            trigger="click"
          >
            <span
              slot="reference"
              class="px-15 flex items-center outline-none h-20 lg:pr-0"
            >
              <svg-icon
                icon-name="dots"
                class-name="text-white-500"
                width="20"
                height="4"
              ></svg-icon>
            </span>
            <div>
              <div
                class="px-10 py-7 border-b border-white-100 flex items-center h-40"
              >
                <el-switch
                  v-model="item.active"
                  :active-text="typeDiscount ? 'Активна' : 'Активен'"
                  @change="changeItemStatus($event, item.hash)"
                >
                </el-switch>
              </div>
              <div
                class="services-drag__item-icon mobile"
                @click="item.edit = true"
              >
                <svg-icon
                  icon-name="edit"
                  :style="{
                    color: '#3498db',
                  }"
                  width="16"
                  height="16"
                ></svg-icon>
                <span>Редактировать</span>
              </div>
              <div
                class="services-drag__item-icon services-drag__item-icon-remove mobile"
                @click="onDelete(item.hash)"
              >
                <svg-icon icon-name="close" width="16" height="12"></svg-icon>
                <span>Удалить</span>
              </div>
            </div>
          </el-popover>
          <create-promo
            :promo="item"
            :show="item.edit"
            :type="type"
            :title="`Редактирование ${typeDiscount ? 'скидки' : 'промокода'}`"
            @cancelAdding="item.edit = false"
            @editPromo="onEditPromo"
            @close="item.edit = false"
          />
        </div>
        <div
          v-if="item.opened"
          class="promocode-content px-30 pt-25 pb-30 border-t border-white-150 lg:p-20"
        >
          <div class="text-16 leading-28 font-semibold mb-15">
            {{ typeDiscount ? "Активна" : "Активен" }} для
          </div>
          <el-button
            v-if="user.resources_count"
            :loading="loading.select"
            type="mini"
            class="mb-25"
            @click="toggleAllResourcesIn(index)"
            >{{
              user.resources_count === item.resources.length
                ? "Снять все"
                : "Выбрать все"
            }}</el-button
          >
          <el-checkbox-group
            v-model="item.resources"
            class="flex flex-col mb-30"
          >
            <el-checkbox
              v-for="resource in sortArr"
              :key="resource.id"
              :label="resource.hash"
              class="mb-10"
              @change="changeItemResources(item)"
              >{{ resource.name }}</el-checkbox
            >
          </el-checkbox-group>
          <article v-if="item.description" class="promo-desc">
            <h3 class="text-16 font-semibold leading-28 mb-5">Описание</h3>
            <p class="text-white-700">{{ item.description }}</p>
          </article>
        </div>

        <div
          v-if="$mq.tablet"
          class="cursor-pointer border-t border-white-150 pt-12 pb-12 leading-25 flex items-center justify-center"
          @click="item.opened = !item.opened"
        >
          {{ !item.opened ? "Развернуть" : "Свернуть" }}
          <svg-icon
            icon-name="chevron"
            class-name="text-white-normal ml-10"
            :class="{ 'rotate-180': !item.opened }"
            width="12"
            height="7"
          ></svg-icon>
        </div>
      </div>
    </div>

    <div class="max-w-lg">
      <create-promo
        :promo="item"
        :show="showModal"
        :type="type"
        :title="`Создание ${typeDiscount ? 'скидки' : 'промокода'}`"
        @cancelAdding="item.edit = false"
        @editPromo="onEditPromo"
        @close="showModal = false"
        @createdPromo="createPromo"
      />
    </div>
  </div>
</template>

<script>
import CreatePromo from "@/components/Promo/CreatePromo";
import { mapState, mapMutations, mapActions } from "vuex";
import moment from "moment";
import NoData from "@/components/NoData";

export default {
  components: {
    CreatePromo,
    NoData,
  },
  props: {
    elements: Array,
    type: String,
  },
  data() {
    return {
      items: [],
      sortArr: [],

      listOptionsAddress: [
        {
          title: "С адресом",
          value: "writeAddress",
        },
        {
          title: "Без адресом",
          value: "fromSetting",
        },
      ],
      usePromo: true,
      showModal: false,
      useSwitch: true,
      loading: {
        page: true,
        select: false,
      },
      formSale: {
        type: 1,
        amount: "",
        dates: "",
        times: "",
      },
      addingPromoOpened: false,
      formSaleRules: {
        type: [{ required: false, message: "Укажите тип", trigger: "change" }],
        amount: [
          {
            required: true,
            message: "Укажите размер скидки",
            trigger: ["blur", "change"],
          },
        ],
        dates: [
          {
            type: "date",
            required: true,
            message: "Укажите срок действия",
            trigger: "change",
          },
        ],
        times: [
          {
            type: "date",
            required: true,
            message: "Укажите время действия",
            trigger: "change",
          },
        ],
      },
    };
  },

  computed: {
    ...mapState("user", ["user"]),
    ...mapState("promo", ["discountItemsCount"]),
    addingPromoText() {
      return this.addingPromoOpened ? "Скрыть" : "Добавить промо-код";
    },
    typeDiscount() {
      return this.type === "discount";
    },
  },
  async created() {
    await this.getInfo();
    await this.getPromoDisCount();
    await this.setData();
    this.sortResource();
  },
  methods: {
    ...mapMutations(["setNotif"]),
    ...mapActions("promo", [
      "getPromoDisCount",
      "changeResources",
      "changeStatus",
      "deleteItem",
    ]),
    ...mapActions("user", ["getInfo"]),
    sortResource() {
      this.sortArr = this.user.resources.sort(
        (a, b) => a.position - b.position
      );
    },

    setData() {
      this.items = this.elements;
      this.loading.page = false;
    },
    showCreateModal() {
      this.showModal = true;
    },

    toggleAllResourcesIn(index) {
      const resourcesCount = this.items[index].resources.length;
      this.loading.select = true;
      this.items[index].resources = [];
      if (resourcesCount !== this.user.resources_count) {
        Object.values(this.user.resources).forEach((resource) => {
          this.items[index].resources.push(resource.id);
        });
      }
      this.changeItemResources(this.items[index]);
    },
    onEditPromo(item) {
      const target = this.items.find((p) => p.hash === item.hash);

      // eslint-disable-next-line no-restricted-syntax
      for (const key in target) {
        target[key] = item[key];
      }

      target.edit = false;
    },
    promoWorkDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    async changeItemResources(item) {
      this.loading.select = true;
      await this.changeResources({
        key: this.type,
        hash: item.hash,
        resources: item.resources,
      });
      this.loading.select = false;
    },
    changeItemStatus(status, id) {
      this.changeStatus({
        key: this.type,
        id,
        active: +status,
      });
    },
    onDelete(hash) {
      this.$confirm("", "Вы действительно хотите удалить?", {
        confirmButtonText: "Удалить",
        cancelButtonText: "Отмена",
        type: "warning",
      }).then(async () => {
        this.loading.page = true;
        await this.deleteItem({
          key: this.type,
          id: hash,
        });
        await this.$parent.getData();
      });
    },
    createPromo() {
      this.showModal = false;
      this.loading.page = true;
      this.$parent.getData();
    },
    toggleAddingPromo() {
      this.addingPromoOpened = !this.addingPromoOpened;
    },
    onCancelAdding() {
      this.$refs.addingPromoForm.resetFields();
      this.addingPromoOpened = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";
.services-drag {
  &__draggable {
    border-bottom: 1px solid $--color-sm-white;
    border-top: 1px solid $--color-sm-white;
  }
  &__item {
    display: flex;
    height: 50px;
    &-handle {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 11px;
      cursor: pointer;
      border-right: 1px solid $--color-sm-white;
      border-left: 1px solid $--color-sm-white;
      height: 100%;
      flex-shrink: 0;
    }
    &-name {
      min-width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $--color-white;
      flex-grow: 1;
      margin: auto 15px;
    }
    &-icon {
      display: flex;
      align-items: center;
      padding: 0 10px;
      cursor: pointer;
      flex-shrink: 0;
      svg {
        color: #3498db;
      }
      &-remove {
        svg {
          color: #ff0000;
        }
      }
      span {
        margin-left: 10px;
        border-bottom: 1px solid $--color-white-35;
      }
    }
  }
}
.mobile {
  font-size: 14px;
  line-height: 24px;
  min-height: 34px;

  span {
    border: none;
  }
}
</style>
