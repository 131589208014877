var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center justify-between xs:flex-col xs:items-start xs:justify-start mb-20"},[_c('h1',{staticClass:"font-semibold text-white-normal",class:[
      ("text-" + (_vm.font.size) + " leading-" + (_vm.font.leading)),
      _vm.font.mq
        ? ((_vm.font.mq.value) + ":text-" + (_vm.font.mq.size) + " " + (_vm.font.mq.value) + ":leading" + (_vm.font.mq.size))
        : null,
      _vm.font.secondMq
        ? ((_vm.font.mq.value) + ":text-" + (_vm.font.mq.size) + " " + (_vm.font.mq.value) + ":leading" + (_vm.font.mq.size))
        : null ]},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.buttonText)?_c('el-button',{staticClass:"h-40 xs:mt-20",attrs:{"type":"primary"},on:{"click":function($event){return _vm.$emit('onClick')}}},[_c('span',{staticClass:"flex items-center justify-center"},[_c('svg-icon',{staticClass:"mr-10",attrs:{"icon-name":"plus","width":"10","height":"10"}}),_vm._v(" "+_vm._s(_vm.buttonText)+" ")],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }