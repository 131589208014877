export default {
  namespaced: true,
  state: {
    filteredReserves: "",
    filteredUrl: "",
  },
  getters: {
    filteredReserves: (state) => state.filteredReserves,
    filteredUrl: (state) => state.filteredUrl,
  },
  mutations: {
    setFilteredReserves(state, reserves) {
      state.filteredReserves = reserves;
    },
    setFilteredUrl(state, url) {
      state.filteredUrl = url;
    },
  },
  actions: {
    setFilteredReserves({ commit }, reserves) {
      commit("setFilteredReserves", reserves);
    },
    setFilteredUrl({ commit }, url) {
      commit("setFilteredUrl", url);
    },
  },
};
