<template>
  <el-form
    ref="mapForm"
    :hide-required-asterisk="true"
    label-position="top"
    :model="$data"
    @submit.native.prevent
  >
    <el-form-item prop="text" class="mb-20">
      <span slot="label">{{ label }}<span class="danger">*</span></span>
      <el-input
        :id="`map-search_${randomID}`"
        v-model="text"
        placeholder="Начните писать название адреса"
        autocomplete="off"
        @blur="changeAddress"
      ></el-input>
      <yandex-map
        :settings="mapSettings"
        :coords="mapCoords"
        @map-was-initialized="initMapHandler"
      />
    </el-form-item>
  </el-form>
</template>

<script>
import { yandexMap } from "vue-yandex-maps";
import { mapSettings } from "@/helpers/SettingsYandex.js";

const tzlookup = require("tz-lookup");

export default {
  name: "InputAddress",
  components: { yandexMap },
  props: {
    value: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      text: "",
      input: "",
      country: "",
      city: "",
      timezone: "",
      address: "",
      markerKey: 1,
      mapCoords: [59.939095, 30.315868],
    };
  },
  computed: {
    isNewAddress() {
      return !this.value;
    },
    randomID() {
      return Math.floor(Math.random() * 1000);
    },
  },
  watch: {
    value() {
      this.setData(this.value);
    },
  },
  created() {
    this.mapSettings = mapSettings;
    if (this.value) this.setData(this.value);
  },
  methods: {
    setData(value) {
      this.text = value.address_name;
      this.setAddressByCoords(value.map);
      this.mapCoords = [...value.map];
    },
    validateMap() {
      this.$refs.mapForm.validate((valid) => {
        this.$emit("validate", valid);
        if (valid) {
          this.emitData();
          if (this.isNewAddress) {
            this.$refs.mapForm.resetFields();
            this.mapCoords = [59.939095, 30.315868];
          }
        }
      });
    },
    initMapHandler() {
      // eslint-disable-next-line no-new
      new ymaps.SuggestView(`map-search_${this.randomID}`);
    },
    async changeAddress() {
      if (this.text || this.isDragDrop) {
        // eslint-disable-next-line no-undef
        const res = await ymaps.geocode(this.text, {
          results: 1,
        });
        this.mapCoords = res.geoObjects.get(0).geometry.getCoordinates();
        this.markerKey += 1;

        const firstGeoObject = res.geoObjects.get(0);

        this.timezone = tzlookup(this.mapCoords[0], this.mapCoords[1]);
        this.address = firstGeoObject.properties.get("name");
        this.country = firstGeoObject.getCountry();
        this.city = this.getCityName(firstGeoObject);

        this.input = `${this.city}, ${this.country}, ${this.address}`;
      } else {
        this.input = "";
        this.address = "";
        this.country = "";
        this.city = "";
        this.timezone = "";
      }
      this.emitData();
    },
    async setAddressByCoords(coords) {
      try {
        const res = await ymaps.geocode(coords, { results: 1 });
        const firstGeoObject = res.geoObjects.get(0);
        this.text = firstGeoObject.getAddressLine();
        this.input = firstGeoObject.getAddressLine();
        this.address = firstGeoObject.properties.get("name");
        this.country = firstGeoObject.getCountry();
        this.city = this.getCityName(firstGeoObject);
      } catch (e) {
        console.error(e);
      }
    },
    getCityName(geoObject) {
      return geoObject.getLocalities().length
        ? geoObject.getLocalities().join(", ")
        : geoObject.getAdministrativeAreas().join(", ");
    },
    emitData() {
      this.$emit("input", {
        address_route_name: this.input,
        address_route_city: this.city,
        address_route_country: this.country,
        address_route_timezone: this.timezone,
        map: [...this.mapCoords],
      });
    },
  },
};
</script>

<style scoped>
.el-form {
  width: 100%;
}
</style>
