<template>
  <div>
    <div class="flex justify-between mb-20 flex-col xs:w-full">
      <div class="flex flex-row justify-between">
        <span class="order__row-text mb-10">{{
          `Цена за ${unitChange(service.consist.rent.unit).textThird}`
        }}</span>
        <span class="order__row px-5"
          >{{ service.consist.rent.price }} {{ service.currency }}
        </span>
      </div>
      <div v-if="isTimePlace" class="flex flex-row justify-between">
        <span class="order__row-text mb-10">
          {{ `Скидка за ${unitChange(service.consist.rent.unit).textThird}` }}
        </span>
        <span class="order__row px-5">
          {{ service.consist.rent.discount }}
          {{
            service.consist.rent.discount_unit === "procent"
              ? "%"
              : service.currency
          }}
        </span>
      </div>
      <div class="flex flex-row justify-between">
        <span class="order__row-text mb-10">
          {{
            `Кол-во ${unitChange(service.consist.rent.unit).textSecond}`
          }}</span
        >
        <span class="order__row px-5">
          {{ service.consist.rent.duration }}
        </span>
      </div>
      <div v-if="isTimePlace" class="flex flex-row justify-between">
        <span class="order__row-text mb-10"> Кол-во мест </span>
        <span class="order__row px-5"> {{ service.consist.rent.places }} </span>
      </div>
      <div
        v-for="(item, idx) in isAdditionalInput"
        :key="idx"
        class="flex flex-row justify-between"
      >
        <span class="order__row-text mb-10"
          >{{ ` ${item.name}, ${item.selected}  шт.` }}
        </span>
        <span class="order__row px-5"
          >{{ item.price }} {{ service.currency }}
        </span>
      </div>

      <div class="hl"></div>
      <div class="flex flex-row justify-between pb-30">
        <span class="order__row-text">Итоговая стоимость </span>
        <span>{{ service.final_price }} {{ service.currency }}</span>
      </div>
      <!-- <div class="button__actions pb-30">
                <el-button class="block__button" type="primary" @click="sendPay"
                  >Оплатить онлайн
                </el-button>
              </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "RentService",
  props: {
    service: {
      type: Object,
      default: () => {},
    },
    isAdditionalInput: {
      type: Array,
      default: () => {},
    },
  },
  computed: {
    isTimePlace() {
      return this.service.booking_type_sub === "rent_depends_on_time_and_place";
    },
  },
  methods: {
    unitChange(key) {
      switch (key) {
        case "days":
          return {
            text: "дня",
            textSecond: "дней",
            textThird: "день",
          };
        case "hours":
          return {
            text: "часа",
            textSecond: "часов",
            textThird: "час",
          };
        case "hour":
          return {
            text: "часа",
            textSecond: "часов",
            textThird: "час",
          };

        case "minutes":
          return {
            text: "минут",
            textSecond: "минут",
            textThird: "минут",
          };
        case "minute":
          return {
            text: "минут",
            textSecond: "минут",
            textThird: "минут",
          };
        default:
          return "No UNIT";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.order {
  font-weight: 400;
  font-family: Montserrat;
  &__wrap {
    // width: 860px;
    margin-top: 100px;
    margin-bottom: 100px;
  }
  &__block {
    background: rgba(255, 255, 255, 0.04);
    border-radius: 3px;
    margin-bottom: 30px;
    &--top {
      text-align: left;
      margin-bottom: 40px;
    }
  }
  &__row {
    font-size: 12px;
    &-text {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}
</style>
