<template>
  <BookingTemplate
    ref="template"
    class="notification__block"
    is-modal
    type="modal"
    subtitle="Отправляется клиенту при отмене бронирования."
    :items-user="items"
    :default-items-user="defaultItems"
    :loading-user="loading"
    @save="save"
  />
</template>

<script>
import BookingTemplate from "@/components/Notifications/Booking/BookingTemplate";

export default {
  components: {
    BookingTemplate,
  },
  props: {
    items: Object,
    defaultItems: Object,
    loading: Boolean,
    isModal: Boolean,
  },
  methods: {
    save(item) {
      this.$emit("save", {
        ...item.items,
        type: `modal`,
      });
    },
  },
};
</script>
