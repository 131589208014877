<template>
  <div class="flex flex-col w-full">
    <div
      class="border flex justify-center items-center rounded-sm text-center mb-30 min-h-200"
    >
      CALENDAR
    </div>
    <div
      class="border flex justify-center items-center rounded-sm text-center mb-30"
    >
      <div class="flex flex-col w-full">
        <span>Car seats: 18</span>
        <div class="wrapper">
          <div class="person__item">
            <div
              v-for="(item, idx) in seats"
              :key="idx"
              class="item_pos text-yellow-400"
            >
              <p class="w-25 h-35 m-12 cursor-pointer" @click="clickSeat(item)">
                <span>{{ item }} </span>
                <img src="@/assets/seat.png" alt="" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="border flex justify-center items-center rounded-sm text-center mb-30 min-h-200"
    >
      FORM
    </div>
  </div>
</template>

<script>
export default {
  name: "UBRR",
  data() {
    return {
      seats: null,
    };
  },
  created() {
    this.seats = 18;
  },
  methods: {
    clickSeat(index) {
      console.log(index);
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  background-image: url("../assets/micro1.png");
  background-repeat: no-repeat;
  background-size: 654px;
  position: relative;
  background-position: 10px 0px;
  width: 674px;
  height: 266px;
}
.person__item {
  width: 490px;
  display: flex;
  position: absolute;
  flex-wrap: wrap;
  left: 28px;
}
.person__item > div:nth-child(9) {
  // color: red;
  // background: aquamarine;
  margin-right: 350px;
}
.person__item > div:last-child {
  // color: red;
  // background: aquamarine;
  margin-left: 57px;
}
</style>
