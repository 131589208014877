<template>
  <section>
    <div
      v-for="(doc, index) in docs"
      :key="index"
      class="bg-white-40 rounded-sm px-30 pt-20 pb-30 mb-20"
    >
      <instructions :title="doc.category" :docs="doc.instruction" />
    </div>
  </section>
</template>

<script>
import Instructions from "@/components/Instructions";

export default {
  name: "DocItems",
  components: { Instructions },
  props: {
    docs: {
      type: [Object, Array],
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

::v-deep .instructions {
  padding: 0;
  background: none;
}
</style>
