<template>
  <RightSidebar
    title="Комментарии"
    button-text="Добавить комментарий"
    :show="show"
    :disable="isDisabled"
    @close="$emit('close')"
    @addNewComment="newCommentAdd"
  >
    <simplebar data-simplebar-auto-hide="false">
      <div class="h-full relative">
        <el-form
          hide-required-asterisk
          label-position="top"
          class="relative h-full"
        >
          <div class="h-full flex flex-col">
            <el-form-item
              v-if="clientComments.length"
              class="mb-20 pb-15 border-b border-white-100"
            >
              <div slot="label" class="text-18 leading-28 mb-8">Клиента</div>

              <div
                v-for="(comm, idx) in clientComments"
                :key="idx"
                class="rounded-sm py-15"
              >
                <div class="mb-10 text-white-500 text-12">
                  {{ `${comm.date} в ${comm.time}` }}
                </div>
                <p class="text-12 leading-20">{{ comm.comment }}</p>
              </div>
            </el-form-item>
            <div
              v-if="!moderComments.length && !clientComments.length"
              class="p-12 border text-center border-white-300 rounded-sm block"
            >
              <span class="text-white-300">Пока комментариев нет </span>
            </div>
            <el-form-item v-if="moderComments.length" class="py-30">
              <div slot="label" class="text-18 leading-28 mb-8">
                Администраторов
              </div>
              <div
                v-for="(comment, index) in moderComments"
                v-show="moderComments.length"
                :key="index"
                class="rounded-sm pt-15 pb-20 mb-10"
              >
                <div class="mb-10 text-white-500 text-12">
                  {{ comment.user_name }} • {{ comment.date }} в
                  {{ comment.time }}
                </div>
                <p class="text-12 leading-20">
                  {{ comment.comment }}
                </p>
              </div>
            </el-form-item>
            <el-form-item class="mb-20 mt-auto"
              ><div class="flex">
                <p class="hint">Комментарий администратора</p>
                <span class="danger">*</span>
              </div>
              <el-input
                v-model="newComment"
                type="textarea"
                placeholder="Добавить комментарий"
                resize="none"
                class="bg-white-100 rounded-sm block"
                :autosize="{ minRows: 3 }"
              ></el-input>
            </el-form-item>
          </div>
        </el-form>
      </div>
    </simplebar>
  </RightSidebar>
</template>

<script>
import simplebar from "simplebar-vue";
import { mapState, mapActions } from "vuex";
import { formatTicketDate } from "@/units/main";
import RightSidebar from "@/components/Table/RightSidebar";

export default {
  name: "EditReserveForm",
  components: {
    simplebar,
    RightSidebar,
  },
  props: {
    reserve: {
      type: [Object, Array],
      required: true,
    },
    show: {
      type: Boolean,
    },
  },
  data() {
    return {
      newComment: "",
      loading: false,
      clientComments: [],
      moderComments: [],
      times: [],
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    isDisabled() {
      return this.newComment.length < 3;
    },
  },
  mounted() {
    this.setReserveInfo();
  },
  methods: {
    ...mapActions("orders", ["adminAddComment"]),
    ...mapActions("orders", ["getReserves"]),

    commentDate(date) {
      return formatTicketDate(date);
    },
    setReserveInfo() {
      // this.clientComments = this.reserve.comments.client[0].comment;
      this.reserve.comments.client.forEach((comment) => {
        this.clientComments.push(comment);
      });

      this.moderComments = [];
      this.reserve.comments.admin.forEach((comment) => {
        this.moderComments.push(comment);
      });
    },
    async newCommentAdd() {
      const param = {
        bookingHash: this.reserve.id,
        comment: this.newComment,
      };
      await this.adminAddComment(param);
      await this.getReserves();
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar__content {
  [data-simplebar] {
    height: 100%;
  }
}
::v-deep .simplebar-content-wrapper {
  .simplebar-content {
    height: 100%;
  }
}
::v-deep .sidebar {
  &__title,
  &__close-icon {
    color: rgba(255, 255, 255, 0.5);
  }
  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
  }
}
</style>
