<template>
  <Block title="Системы аналитики" class="analytic">
    <el-row type="flex" :gutter="!$mq.tablet ? 30 : 0" class="w-full flex-wrap">
      <el-col :span="$mq.tablet ? 24 : 12" class="lg:mb-20">
        <div>
          <label
            class="text-12 font-medium text-white-normal flex flex-col input-appended"
          >
            <span class="mb-7">Номер счётчика Яндекс Метрики</span>
            <span class="flex">
              <el-input
                v-model="analyticsSettings.yandex"
                placeholder="Номер счетчика"
              ></el-input>
              <el-button
                type="primary"
                :loading="loading.yandex"
                class="bg-transparent analytic__btn"
                @click="(loading.yandex = true), saveAnalytitcs('yandex')"
                >Применить</el-button
              >
            </span>
          </label>
          <instruction-item
            v-if="user.instructions.metrica"
            class="mt-20"
            only-button
            :doc="{
              link: user.instructions.metrica.link,
              buttonText: `Скачать инструкцию`,
            }"
          />
        </div>
      </el-col>
      <el-col :span="$mq.tablet ? 24 : 12">
        <div>
          <label
            class="text-12 font-medium text-white-normal flex flex-col input-appended"
          >
            <span class="mb-7"
              >Идентификатор отслеживания Google Analytics</span
            >
            <span class="flex">
              <el-input
                v-model="analyticsSettings.google"
                placeholder="Номер счетчика"
              ></el-input>
              <el-button
                type="primary"
                :loading="loading.google"
                class="bg-transparent analytic__btn"
                @click="(loading.google = true), saveAnalytitcs('google')"
                >Применить</el-button
              >
            </span>
          </label>
          <instruction-item
            v-if="user.instructions.google"
            class="mt-20"
            only-button
            :doc="{
              link: user.instructions.google.link,
              buttonText: `Скачать инструкцию`,
            }"
          />
        </div>
      </el-col>
    </el-row>
  </Block>
</template>

<script>
import Block from "./Block";
import { mapState, mapMutations } from "vuex";
import { AJAX_POST } from "../../units/ajax";
import InstructionItem from "@/components/InstructionItem";

export default {
  components: {
    Block,
    InstructionItem,
  },
  data() {
    return {
      loading: {
        yandex: false,
        google: false,
      },
      analyticsSettings: {
        yandex: "",
        google: "",
      },
      instructions: {
        yandex: null,
        google: null,
      },
    };
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  mounted() {
    this.setAnalytic();
  },
  methods: {
    ...mapMutations(["setNotif"]),
    saveAnalytitcs(type) {
      AJAX_POST("/api/me/change/analytic", {
        yandex: this.analyticsSettings.yandex,
        google: this.analyticsSettings.google,
      }).then((response) => {
        if (response.data.success) {
          this.setNotif({
            title: `Данные сохранены`,
          });
        } else {
          this.setNotif({
            title: "Произошла ошибка, попробуйте позже",
            type: "error",
          });
        }
        if (this.loading.google) this.loading.google = false;
        if (this.loading.yandex) this.loading.yandex = false;
      });
    },
    setAnalytic() {
      this.analyticsSettings.google = this.user.additional.analytics.google;
      this.analyticsSettings.yandex = this.user.additional.analytics.yandex;
    },
  },
};
</script>
