<template>
  <el-form
    ref="baseInfo"
    hide-required-asterisk
    label-position="top"
    class="regular"
  >
    <p class="hint-title__medium-white">
      Данный вариант подходит, если услуга разовая или проводится в определенные
      даты.
    </p>
    <div class="flex mb-12">
      <div>Выберите даты<span class="danger">*</span></div>
      <el-popover
        class="ml-8"
        placement="top"
        width="200"
        trigger="hover"
        content="Укажите телефон, который будет приходить клиенту в почтовом уведомлении при бронировании."
      >
        <Tooltip slot="reference" />
      </el-popover>
    </div>
    <functional-calendar
      v-model="calendar"
      :configs.sync="calendarConfigs"
      class="mb-20"
    ></functional-calendar>
    <el-radio-group v-model="radio">
      <el-radio label="manySession">Несколько сеансов</el-radio>
      <el-radio label="oneSession">Один сеанс</el-radio>
    </el-radio-group>
    <div v-if="calendar.selectedDates">
      <p class="hint">Выбранные даты</p>

      <div class="dates flex flex-wrap max-h-500 overflow-y-scroll">
        <tag
          v-for="(date, i) in calendar.selectedDates"
          :key="i"
          close
          @closed="deleteTag(i)"
          >{{ formattingDate(date.date) }}</tag
        >
      </div>
    </div>
    <ListField2
      v-if="radio === 'manySession'"
      v-model="listSessions"
      class="ListField2"
      title-button="Добавить диапазон"
      :headers="headers2"
    ></ListField2>
    <div v-if="radio === 'oneSession'" class="list-fields">
      <div class="list-fields__row">
        <div class="list-fields__item time-picker">
          <p class="hint-title">Начало сеанса<span class="danger">*</span></p>
          <el-time-picker v-model="time" format="HH:mm" />
        </div>
      </div>
    </div>
    <div v-if="isHaveSessions" class="attention">
      <div class="text-white-normal mb-10">
        <el-alert
          center
          :title="`${alertMsg}`"
          type="warning"
          show-icon
          :closable="false"
          class="mt-15 text-16"
        />
      </div>
    </div>
  </el-form>
</template>

<script>
import ListField2 from "@/components/ListField2v2.vue";
import { FunctionalCalendar } from "vue-functional-calendar";
import { getDate, getMonth, format } from "date-fns";
import { monthNames } from "@/helpers/date.js";
import Tag from "@/components/Tag";

import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

export default {
  name: "Dates",
  components: { ListField2, FunctionalCalendar, Tag },
  data() {
    return {
      radio: "manySession",
      time: null,
      listSessions: [
        {
          time: null,
          duration: 30,
          unit: "minutes",
        },
      ],
      calendar: {},
      calendarConfigs: {
        dateFormat: "yyyy-mm-dd",
        disabledDates: ["beforeToday"],
        isMultiple: true,
        calendarsCount: 3,
        isMultipleDatePicker: true,
        dayNames: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
        monthNames: [
          "Январь",
          "Февраль",
          "Март",
          "Апрель",
          "Май",
          "Июнь",
          "Июль",
          "Август",
          "Сентябрь",
          "Октябрь",
          "Ноябрь",
          "Декабрь",
        ],
      },
    };
  },
  computed: {
    ...mapState("service", ["generator", "alertMsg"]),
    ...mapGetters("service", ["isHaveSessions"]),
  },
  created() {
    this.headers2 = [
      {
        title: "Укажите диапазон",
        valid: true,
        placeholder: "Введите время",
      },
      {
        title: "Интервал между сеансами",
        valid: true,
        placeholder: "Введите время",
      },
    ];
    if (this.generator.data.dates) {
      const { time, radio, listSessions, calendar } = this.generator.data.dates;
      this.calendar = { ...calendar };
      this.time = time || null;
      this.radio = radio;
      this.listSessions = [...listSessions];
    }
    this.monthNames = monthNames;
  },
  methods: {
    ...mapActions("service", ["saveGenerator"]),
    ...mapMutations("service", ["setGenerator"]),
    formattingDate(date) {
      return `${getDate(new Date(date))} ${this.monthNames[
        getMonth(new Date(date))
      ].title.toLowerCase()}`;
    },
    deleteTag(i) {
      this.calendar.selectedDates = this.calendar.selectedDates.filter(
        (date, index) => i !== index
      );
    },
    async saveData() {
      const param = this.generator.schedule_type ?? this.sessions.schedule_type;

      this.setGenerator({
        field: param,
        value: {
          time: this.time,
          radio: this.radio,
          listSessions: [...this.listSessions],
          calendar: this.calendar,
        },
      });
      this.$emit("paramDates", {
        field: param,
        value: {
          time: this.time,
          radio: this.radio,
          listSessions: [...this.listSessions],
          calendar: this.calendar,
        },
      });
      // REMOVE TO DEL request

      // await this.saveGenerator({
      //   time: this.time,
      //   radio: this.radio,
      //   listSessions: [...this.listSessions],
      //   calendar: this.calendar,
      // });
    },
    deleteItem(index) {
      this.list.splice(index, 1);
    },
    setData(value, index) {
      this.list.splice(index, 1, { ...value });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.time-picker {
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
}

.list-fields {
  margin-bottom: 15px;

  &__row:last-child {
    margin-bottom: 0;
  }
  &__item {
    flex-grow: 0;
    flex-shrink: 0;
  }
  &__days {
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;
    .day {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      padding: 8px 20px;
      border-radius: 3px;
      color: $--color-white;
      border: 1px solid $--color-white-35;
      cursor: pointer;
      margin: 0 10px 0 0;
      &:hover {
        background-color: $--color-white-15;
      }
      &-select {
        background: #3498db;
      }
    }
  }
}

::v-deep .el-radio-group {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.hint-title__medium-white {
  padding-bottom: 30px;
}

::v-deep .vfc {
  &-content {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin: 0 10px 10px;
  }
  &-main-container {
    background-color: transparent;
    box-shadow: none;
    border-radius: none;
    border: none;
  }
  &-top-date span {
    color: $--color-white;
  }
  &-arrow-right,
  &-arrow-left {
    border-color: $--color-white !important;
  }
  &-dayNames {
    padding-bottom: 10px;
    border-bottom: 1px solid $--color-sm-white;
    .vfc-day {
      color: $--color-white-5;
    }
  }
  &-calendar {
    border: 1px solid $--color-sm-white;
    &:nth-last-child(1) {
      border-radius: 0 3px 3px 0;
    }
    &:nth-last-child(2) {
      border-left: none;
      border-right: none;
    }
    &:nth-last-child(3) {
      border-radius: 3px 0 0 3px;
    }
  }
  &-marked {
    background: #3498db !important;
  }
  &-week .vfc-day {
    cursor: pointer;
    &:last-child {
      color: $--color-white;
    }
  }
  &-hide {
    color: $--color-white-5 !important;
  }
}

@screen lg {
  .hint-title__medium-white {
    padding-bottom: 25px;
  }
  .list-fields {
    margin-bottom: 15px;

    &__row {
      margin-bottom: 15px;
    }
    &__days {
      .day {
        margin: 0 10px 10px 0; //
      }
    }
  }
}
</style>
