var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-40 pb-80 w-full lg:pt-15 px-10"},[_c('h1',{staticClass:"font-semibold text-white-normal text-28 leading-44 lg:text-22 lg:leading22 mb-30 xs:mb-5"},[_vm._v(" Дашборд ")]),(_vm.isLoading)?_c('spinner'):_c('div',{staticClass:"wrapper__content"},[(_vm.isShowProgress && !_vm.isFastartCompleat)?_c('div',{staticClass:"progress custom-block"},[(!_vm.isFastartCompleat)?_c('div',{staticClass:"progress-header"},[_c('h4',[_vm._v("Быстрый старт")]),_c('ButtonIcon',{staticClass:"close",attrs:{"icon-name":"close","width":_vm.$mq.tablet ? '12' : '16'},on:{"click":_vm.hiddenProgress}})],1):_vm._e(),_c('p',{staticClass:"hint"},[_vm._v(" Добро пожаловать! Заполните все необходимые поля для максимально эффективного использования платформы ")]),_c('el-steps',{attrs:{"active":_vm.dashboardStep - 1,"process-status":"process","finish-status":"success","direction":_vm.$mq.tablet ? 'vertical' : 'horizontal'}},_vm._l((_vm.allSteps),function(step,index){return _c('el-step',{key:index,class:[
            { seans: [1, 2, 3].includes(index) },
            { rezult: index === 4 } ],attrs:{"title":step.value}})}),1),(_vm.dashboardStep !== 5)?_c('div',{staticClass:"flex mt-20 justify-between"},_vm._l((_vm.allSteps),function(step,index){return _c('div',{key:index,staticClass:"flex",class:[
            { seans: [1, 2, 3].includes(index) },
            { rezult: index === 4 } ]},[(index === _vm.dashboardStep - 1)?_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.toPage}},[_vm._v("Выполнить")]):_vm._e()],1)}),0):_vm._e()],1):_vm._e(),_c('div',{staticClass:"custom-block company"},[(_vm.company && _vm.company.company_logo)?_c('img',{staticClass:"company__img",attrs:{"src":_vm.company.company_logo}}):_c('div',{staticClass:"company__img-stub"},[_vm._v(" Нет "),_c('br'),_vm._v(" логотипа ")]),_c('div',{staticClass:"company__info"},[_c('p',{staticClass:"company__info-header"},[_vm._v(" "+_vm._s(_vm.company && _vm.company.company_name ? _vm.company.company_name : "Название компании")+" ")]),_c('p',{staticClass:"company__info-text"},[_vm._v(" "+_vm._s(_vm.company && _vm.company.kind_of_activity[0] ? _vm.company.kind_of_activity[0].name : "Категория компании")+" ")]),_c('div',{staticClass:"company__info-tags"},[(!_vm.company)?_c('el-button',{staticClass:"button-edit",attrs:{"type":"primary"},on:{"click":_vm.toCompany}},[_vm._v(" Добавить компанию ")]):_vm._e(),_c('el-button',{on:{"click":function($event){return _vm.$router.push({ name: 'services' })}}},[_vm._v(" Услуги "),_c('span',{staticClass:"company__info-tags-total",class:{
                'company__info-tags-total_no-data': !_vm.user.resources_count,
              }},[_vm._v(_vm._s(_vm.user.resources_count))])]),_c('el-button',{on:{"click":function($event){return _vm.$router.push({ name: 'widgets' })}}},[_vm._v(" Виджеты "),_c('span',{staticClass:"company__info-tags-total",class:{
                'company__info-tags-total_no-data': !_vm.user.widgets_count,
              }},[_vm._v(_vm._s(_vm.user.widgets_count))])]),(_vm.company && _vm.company.company_name)?[(_vm.$mq.tablet)?_c('el-button',{staticClass:"button-edit",on:{"click":_vm.toCompany}},[_c('svg-icon',{staticClass:"mr-12",attrs:{"icon-name":"edit","width":"16"}}),_vm._v(" Редактировать ")],1):_c('ButtonIcon',{attrs:{"icon-name":"edit","width":"16"},on:{"click":_vm.toCompany}})]:_vm._e()],2)])]),_c('Cards',{on:{"showModalBalanse":function($event){_vm.isShowModalBalanse = true},"showModalSms":function($event){_vm.isShowModalSms = true}}})],1),_c('el-dialog',{attrs:{"visible":_vm.isShowModalBalanse,"append-to-body":"","show-close":false,"custom-class":"max-w-md w-full filling-balance-popup"},on:{"update:visible":function($event){_vm.isShowModalBalanse=$event}}},[_c('div',{staticClass:"absolute fast-add-close",attrs:{"slot":"title"},on:{"click":function($event){_vm.isShowModalBalanse = false}},slot:"title"},[_c('div',{staticClass:"cursor-pointer"},[_c('svg-icon',{attrs:{"icon-name":"close","width":"12","height":"12","class-name":"text-white-normal"}})],1)]),_c('filling-balance',{attrs:{"modal":""}})],1),_c('el-dialog',{attrs:{"visible":_vm.isShowModalSms,"append-to-body":"","show-close":false,"custom-class":"max-w-md w-full filling-balance-popup"},on:{"update:visible":function($event){_vm.isShowModalSms=$event}}},[_c('div',{staticClass:"absolute fast-add-close",attrs:{"slot":"title"},on:{"click":function($event){_vm.isShowModalSms = false}},slot:"title"},[_c('div',{staticClass:"cursor-pointer"},[_c('svg-icon',{attrs:{"icon-name":"close","width":"12","height":"12","class-name":"text-white-normal"}})],1)]),_c('FillingSms',{attrs:{"modal":""}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }