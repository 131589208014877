var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"min-h-full h-auto pt-30 bg-cover bg-no-repeat bg-center",class:_vm.theme === 'dark'
      ? 'bg-gray-1000'
      : _vm.theme === 'white'
      ? 'bg-white-normal'
      : '',style:(_vm.theme === 'custom'
      ? ("background-image: url(" + (_vm.widgetBg('transparent-widget-bg.jpeg')) + ")")
      : '')},[_c('div',{staticClass:"flex items-start"},[_c('div',{staticClass:"flex items-center h-50 justify-center px-40 mr-45 back-btn cursor-pointer rounded-sm",class:_vm.theme === 'dark'
          ? 'bg-gray-800'
          : _vm.theme === 'white'
          ? 'bg-gray-500 text-black'
          : 'bg-white-150',on:{"click":_vm.goBack}},[_c('svg-icon',{attrs:{"icon-name":"arrow","class-name":"rotate-180 mr-10","width":"13","height":"8"}}),_vm._v("Вернуться назад ")],1),_c('div',{staticClass:"w-full max-w-lg"},[_c('div',{staticClass:"text-28 leading-44 font-semibold mb-40",class:_vm.theme === 'white' ? 'text-black' : ''},[_vm._v(" Пример стандартного темного виджета ")]),_c('div',{staticClass:"text-18 leading-30 font-semibold mb-25",class:_vm.theme === 'dark' || _vm.theme === 'custom'
            ? 'text-yellow-100'
            : 'text-violet-500'},[_vm._v(" Для одной услуги ")]),_c('widget-single',{attrs:{"ids":_vm.$route.query.ids,"theme":_vm.$route.query.theme}}),_c('div',{staticClass:"text-18 leading-30 font-semibold text-yellow-100 mb-25"},[_vm._v(" Для нескольких услуг ")]),_c('widget-multiple')],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }