<template>
  <RightSidebar title="Состав заказа" :show="sShow" @close="$emit('close')">
    <div slot-scope>
      <div class="mb-10 whitespace-nowrap inline-block underline leading-25">
        {{ reserve.resource_name }}
      </div>
      <div
        v-if="
          (isBookingType === 'individual' ||
            isBookingTypeSub ===
              'individual_depends_on_tariff_and_number_of_seats') &&
          isBookingTypeSub !==
            'individual_depends_on_tariff_and_number_of_seats' &&
          isBookingTypeSub !==
            'individual_does_not_depend_on_the_number_of_seats' &&
          isBookingTypeSub !== 'individual_depends_on_the_number_of_seats' &&
          isBookingTypeSub !==
            'individual_depends_on_the_service_and_its_duration'
        "
        class="flex flex-col"
      >
        <div class="flex my-30">
          <div class="w-1/2">
            <span class="small_text">Дата и время</span>
            <span class="small_text">Тариф</span>
            <span class="small_text">Цена </span>
            <span class="small_text">Участников </span>
          </div>
          <div class="w-1/2 mb-3">
            <span class="underline"
              >{{ getDate(reserve.date) }} {{ reserve.time }}</span
            >
            <span class="small_text-outcome"
              >{{ reserve.consist.tariff }}
            </span>
            <span class="small_text-outcome"></span>
            <span class="small_text-outcome">{{ reserve.consist.person }}</span>
          </div>
        </div>
      </div>
      <div
        v-if="
          isBookingTypeSub ===
          'individual_does_not_depend_on_the_number_of_seats'
        "
        class="flex flex-col"
      >
        <OrderNotSeat :item="reserve" :rent="reserve.consist" />
      </div>

      <div
        v-if="
          isBookingTypeSub === 'rent_depends_only_on_time' ||
          isBookingTypeSub === 'rent_depends_on_time_and_place'
        "
        class="flex flex-col"
      >
        <RentDepOnTime :item="reserve" :rent="reserve.consist" />
      </div>
      <div
        v-if="
          isBookingTypeSub === 'group_same_seat_limit_for_all_sessions' ||
          isBookingTypeSub === 'group_the_seat_limit_is_set_for_each_session'
        "
        class=""
      >
        <OrderGroupSameSeat
          :item="reserve"
          :tickets="reserve.book_info.tickets"
        />
      </div>
      <div
        v-if="
          isBookingTypeSub ===
          'individual_depends_on_tariff_and_number_of_seats'
        "
        class="flex flex-col"
      >
        <OrderTarifSeats :item="reserve" :tariff="reserve.consist" />
      </div>
      <div
        v-if="
          isBookingTypeSub ===
          'individual_depends_on_the_service_and_its_duration'
        "
      >
        <OrderServiceDur :item="reserve" :service="reserve.consist" />
      </div>
      <div
        v-if="isBookingTypeSub === 'individual_depends_on_the_number_of_seats'"
      >
        <OrderGroupPers :item="reserve" :group="reserve.consist" />
      </div>
      <!--             isBookingType === 'rent' ||
 -->
      <div class="flex flex-col">
        <div>
          <div
            v-if="
              isBookingTypeSub !==
                'individual_depends_on_the_number_of_seats' &&
              isBookingTypeSub !==
                'individual_depends_on_the_service_and_its_duration' &&
              isBookingTypeSub !==
                'individual_depends_on_tariff_and_number_of_seats' &&
              isBookingType === 'group_same_seat_limit_for_all_sessions'
            "
            class="w-1/2"
          >
            <span class="small_text">Дата и время</span>
            <span
              v-if="
                isBookingTypeSub !== 'individual_depends_on_the_number_of_seats'
              "
              class="small_text"
              >Цена
            </span>

            <span
              v-if="
                isBookingTypeSub ===
                'individual_does_not_depend_on_the_number_of_seats'
              "
              class="small_text"
              >Участников </span
            ><span
              v-if="
                isBookingTypeSub ===
                'individual_does_not_depend_on_the_number_of_seats'
              "
              class="small_text"
              >Продолжительность
            </span>

            <span
              v-if="
                !!unitCahnge &&
                !isBookingTypeSub ===
                  'individual_does_not_depend_on_the_number_of_seats'
              "
              class="small_text"
              >Кол-во {{ unitCahnge(reserve.book_info.rent.unit).text }}
            </span>
            <span
              v-if="isBookingTypeSub === 'rent_depends_on_time_and_place'"
              class="small_text"
              >Кол-во мест
            </span>
          </div>
          <div
            v-if="
              isBookingTypeSub !==
                'individual_depends_on_the_number_of_seats' &&
              isBookingTypeSub !==
                'individual_depends_on_the_service_and_its_duration' &&
              isBookingTypeSub !==
                'individual_depends_on_tariff_and_number_of_seats' &&
              isBookingType === 'group_same_seat_limit_for_all_sessions'
            "
            class="w-1/2 mb-3"
          >
            <span class="underline"
              >{{ getDate(reserve.date) }} {{ reserve.time }}</span
            >
            <span
              v-if="
                isBookingTypeSub !==
                  'individual_depends_on_the_number_of_seats' &&
                isBookingTypeSub !==
                  'individual_depends_on_the_service_and_its_duration' &&
                isBookingTypeSub !==
                  'individual_depends_on_tariff_and_number_of_seats' &&
                isBookingTypeSub !== 'group_same_seat_limit_for_all_sessions'
              "
              class="small_text-outcome"
              >{{ reserve.consist.price.price || reserve.consist.price }}

              <!-- {{ reserve.consist.price }} -->
            </span>

            <span
              v-if="
                isBookingTypeSub === 'individual_depends_on_the_number_of_seats'
              "
              class="small_text-outcome"
              >{{ reserve.consist }}</span
            >

            <span
              v-if="
                isBookingTypeSub !==
                'individual_does_not_depend_on_the_number_of_seats'
              "
              class="small_text-outcome"
              >{{ reserve.consist.persons }}
            </span>
            <!-- <span
              v-if="
                isBookingTypeSub !==
                'individual_does_not_depend_on_the_number_of_seats'
              "
              class="small_text-outcome"
              >{{
                reserve.booking_type
                  .individual_does_not_depend_on_the_number_of_seats
                  .service_duration
              }}
              {{
                unitCahnge(
                  reserve.booking_type
                    .individual_does_not_depend_on_the_number_of_seats.time_unit
                ).text
              }}
            </span> -->

            <span
              v-if="
                !!unitCahnge &&
                isBookingTypeSub ==
                  'individual_does_not_depend_on_the_number_of_seats'
              "
              class="small_text-outcome"
              >{{ reserve.book_info.rent.duration }}</span
            >
            <span
              v-if="isBookingTypeSub === 'rent_depends_on_time_and_place'"
              class="small_text-outcome"
              >{{ reserve.book_info.rent.places }}</span
            >
          </div>
        </div>
      </div>

      <div
        v-if="
          isBookingType === 'group' &&
          isBookingType === 'group_same_seat_limit_for_all_sessions'
        "
        class="flex flex-col"
      >
        <div class="flex my-30">
          <div class="w-1/2">
            <span class="small_text">Дата и время</span>
          </div>
          <div class="w-1/2 mb-3">
            <span class="underline"
              >{{ getDate(reserve.date) }} {{ reserve.time }}</span
            >
          </div>
        </div>
        <!-- <span>{{ reserve }}</span> -->
        <div
          v-if="
            isBookingType !== 'rent' &&
            isBookingType === 'group' &&
            isBookingTypeSub !==
              'individual_depends_on_the_service_and_its_duration'
          "
        >
          <div
            v-for="(i, idx) in reserve.book_info.ticket"
            :key="idx"
            class="mb-10 flex"
          >
            <div class="w-1/2 mb-3">
              <span class="small_text">Тариф</span>
              <span class="small_text">Цена </span>
              <span class="small_text">Участников </span>
            </div>
            <!-- <div class="w-1/2 mb-3">
            <span class="small_text">Тариф</span>
            <span class="small_text">Цена </span>
            <span class="small_text">Участников </span>
          </div> -->

            <div class="w-1/2 mb-3">
              <span class="small_text-outcome">{{ i.name }} </span>
              <span class="small_text-outcome">{{ i.price }}</span>
              <span class="small_text-outcome">{{ i.select_ticket }}</span>
            </div>
          </div>
        </div>
        <!-- <div class="flex">
            <div class="w-1/2">
              <span class="small_text">Цена</span>
            </div>
            <div>{{ reserve.book_info.total_price }} ₽</div>
          </div>
          <div class="flex">
            <div class="w-1/2">
              <span class="small_text">Участников</span>
            </div>
            <div>{{ reserve.book_info.person }}</div>
          </div> -->
      </div>
      <div
        v-if="
          isAdditionalOpt.length &&
          isAdditionalOpt[0].selected >= 1 &&
          reserve.booking_type.booking_type_sub !==
            'individual_depends_on_the_number_of_seats'
        "
        class="mb-10"
      >
        <div class="mb-10 whitespace-nowrap inline-block leading-25">
          Дополнительные опции
        </div>

        <div class="flex justify-between mb-3">
          <span class="small_text w-4/12">Название </span>
          <span class="small_text w-4/12">Кол-во </span>
          <span class="small_text w-4/12">Стоимость </span>
        </div>

        <div
          v-for="(i, idx) in isAdditionalOpt.filter((el) => {
            return el.selected > 0;
          })"
          :key="idx"
          class="flex justify-between mb-3"
        >
          <span class="small_text-outcome w-4/12 leading-1">{{ i.name }} </span>
          <span class="small_text-outcome w-4/12 leading-1"
            >{{ i.selected }}
          </span>
          <span class="small_text-outcome w-4/12 leading-1"
            >{{ i.price }} {{ reserve.currency }}
          </span>
        </div>
        <div
          v-for="(i, idx) in isAdditionalOptCheck"
          :key="idx"
          class="flex justify-between mb-3"
        >
          <span class="small_text-outcome w-4/12 leading-1">{{ i.name }} </span>
          <span class="small_text-outcome w-4/12 leading-1">{{ 1 }} </span>
          <span class="small_text-outcome w-4/12 leading-1"
            >{{ i.price !== null ? i.price : 0 }} {{ reserve.currency }}
          </span>
        </div>
      </div>
      <div class="">
        <!-- <div class="mb-10 whitespace-nowrap inline-block leading-25">
          Источник
        </div>
        <div class="mb-10 flex">
          <div class="w-1/2 mb-3">
            <span class="small_text">Ресурс </span>
            <span class="small_text">Сотрудник </span>
            <span class="small_text">Как узнал </span>
            <span class="small_text">UTM метки </span>
          </div>

          <div class="w-1/2 mb-3">
            <span class="small_text-outcome">broni.biz </span>
            <span class="small_text-outcome">Иванов Сергей </span>
            <span class="small_text-outcome">Рекламная компания </span>
            <div class="drop-wrapp" @click="openUtf = !openUtf">
              <span class="small_text-drop">
                {{ openUtf ? "Скрыть" : "Показать" }}
              </span>
              <div class="">
                <svg-icon
                  icon-name="chevron"
                  width="12"
                  height="12"
                  class="utf-icon"
                  :class="{
                    rotate: !openUtf,
                  }"
                ></svg-icon>
              </div>
            </div>
          </div>
          <div v-if="openUtf" class="utf-form">
            ad_id: 8681022781, <br />
            block: other, <br />
            device: desktop, position: 1, utm_term: экскурсии по городу казань,
            utm_medium: cpc,
          </div>
        </div> -->
        <div class="">
          <div class="mb-10 whitespace-nowrap inline-block leading-25">
            Оплата
          </div>
          <div class="mb-10 flex">
            <div class="w-1/2 mb-3">
              <span class="small_text">К оплате </span>
              <span class="small_text">Промокод </span>
              <span class="small_text">Скидка </span>
              <span class="small_text">Итого к оплате </span>
            </div>

            <div class="w-1/2 mb-3">
              <span class="small_text-outcome"
                >{{ reserve.consist.total_price }} {{ reserve.currency }}</span
              >
              <span class="small_text-outcome">{{
                reserve.consist.promocode_name
                  ? reserve.consist.promocode_name
                  : "-"
              }}</span>
              <span class="small_text-outcome"
                >{{ reserve.consist.discount ? reserve.consist.discount : "-" }}
                <span v-if="reserve.consist.discount">{{
                  reserve.currency
                }}</span>
              </span>
              <span class="small_text-total"
                >{{ reserve.book_info.total_price }} {{ reserve.currency }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </RightSidebar>
</template>

<script>
import RightSidebar from "@/components/Table/RightSidebar";
import OrderTarifSeats from "@/components/Orders/ClientInfo/OrderComponent/OrderTarifSeats";
import OrderNotSeat from "@/components/Orders/ClientInfo/OrderComponent/OrderNotSeat";
import OrderGroupSameSeat from "@/components/Orders/ClientInfo/OrderComponent/OrderGroupSameSeat";
import moment from "moment";
import OrderServiceDur from "@/components/Orders/ClientInfo/OrderComponent/OrderServiceDur.vue";
import OrderGroupPers from "@/components/Orders/ClientInfo/OrderComponent/OrderGroupPers";
import RentDepOnTime from "@/components/Orders/ClientInfo/OrderComponent/RentDepOnTime";

export default {
  name: "OrderList",
  components: {
    RightSidebar,
    OrderTarifSeats,
    OrderServiceDur,
    OrderGroupPers,
    OrderGroupSameSeat,
    OrderNotSeat,
    RentDepOnTime,
  },
  props: {
    reserve: {
      type: [Object, Array],
    },
    sShow: {
      type: Boolean,
    },
  },
  data() {
    return {
      openUtf: false,
      bookInfo: null,
    };
  },
  computed: {
    isBookingType() {
      return this.reserve.booking_type?.booking_type;
      // return this.reserve.booking_type[0]?.booking_type_sub;
      // .find((e) => e.booking_type_sub);
    },
    isBookingTypeSub() {
      return this.reserve.booking_type?.booking_type_sub;
    },
    isAdditionalOpt() {
      return (
        this.reserve.book_info?.additional_options ||
        this.reserve.book_info?.add_options_input
      );

      // ||        this.reserve.book_info?.add_options_input
    },
    isAdditionalOptCheck() {
      return this.reserve.book_info?.add_options_check;
    },
    isTarifIndivid() {
      return this.reserve.booking_type.individual_depends_on_tariff_and_number_of_seats.filter(
        (e) => e
      );
    },
  },
  mounted() {
    this.setReserveInfo();
  },
  methods: {
    unitCahnge(params) {
      // switch (this.reserve.book_info.rent.unit)
      switch (params) {
        case "days":
          return {
            text: "дней",
          };
        case "hours":
          return {
            text: "часов",
          };
        case "hour":
          return {
            text: "часов",
          };
        case "minutes":
          return {
            text: "минут",
          };
        case "minute":
          return { text: "минут" };

        default:
          return this.reserve.book_info.rent.unit;
      }
    },
    setReserveInfo() {
      console.log(this.reserve, "ORDERLIST");
    },
    getDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
  },
};
</script>

<style lang="scss" scoped>
.rotate {
  transform: rotate(180deg);
}
.drop-wrapp {
  display: flex;
  cursor: pointer;
}
.small_text {
  &-total {
    font-size: 12px;

    color: rgba(255, 190, 61, 1);
  }
  &-drop {
    margin-right: 8px;
    font-size: 12px;
  }
  &-outcome {
    font-weight: 500;
    opacity: 1;
    font-size: 12px;
    line-height: 20px;
    display: block;
  }
}
.utf {
  &-form {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    margin: 10px 0px;
    padding: 8px 15px;
  }
  &-icon {
    color: rgba(255, 255, 255, 0.5);
    position: relative;
    top: 3px;
  }
}
</style>
