<template>
  <div class="mt-10 mb-40">
    <el-radio-group
      v-model="widgetType"
      class="flex w-full radio-filled mb-40 multiple-control lg:mb-20"
      @change="sendDataToParent"
    >
      <el-radio-button :label="1" border class="m-0 flex-grow">{{
        $mq.desktop ? "Одна услуга" : "Виджет для одной услуги"
      }}</el-radio-button>
      <el-radio-button :label="2" border class="m-0 flex-grow">{{
        $mq.desktop ? "Несколько услуг" : "Виджет для нескольких услуг"
      }}</el-radio-button>
      <!--<el-radio-button :label="3" border class="m-0 flex-grow">Аренда</el-radio-button>-->
    </el-radio-group>

    <div>
      <div v-if="widgetType === 1">
        <el-form
          ref="widgetSingleResource"
          :model="widgetSingleResource"
          :hide-required-asterisk="true"
          label-position="top"
          :rules="widgetSingleResourceRules"
        >
          <el-form-item prop="selected" label="Выберите услугу из списка">
            <el-select
              v-model="widgetSingleResource.selected"
              placeholder="Выберите услугу"
              class="w-full"
              @change="sendDataToParent"
            >
              <el-option
                v-for="item in user.resources"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="widgetType === 2">
        <div>
          <div class="text-18 leading-30 font-semibold mb-13">
            Выберите услуги
          </div>
          <el-button
            size="medium"
            class="mb-20 xs:w-full px-12"
            @click="toggleAllResources"
            >{{
              user.resources_count === widgetResources.selected.length
                ? "Снять все"
                : "Выбрать все"
            }}</el-button
          >
          <el-form
            ref="widgetResources"
            :model="widgetResources"
            :hide-required-asterisk="true"
            label-position="top"
            :rules="widgetResourcesRules"
          >
            <el-form-item prop="selected" class="mb-0">
              <el-checkbox-group
                v-model="widgetResources.selected"
                class="flex flex-col"
                @change="sendDataToParent"
              >
                <el-checkbox
                  v-for="(resource, index) in user.resources"
                  :key="index"
                  :label="resource.id"
                  class="mb-10"
                  >{{ resource.name }}</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "ChangeWidgetMode",
  data() {
    return {
      widgetType: 1,
      widgetResourcesRules: {
        selected: [
          {
            type: "array",
            required: true,
            message: "Выберите хотя бы одну услугу",
            trigger: "change",
          },
        ],
      },
      widgetSingleResourceRules: {
        selected: [
          {
            required: true,
            message: "Выберите хотя бы одну услугу",
            trigger: "change",
          },
        ],
      },
      widgetResources: {
        selected: [],
      },
      widgetSingleResource: {
        selected: "",
      },
    };
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  mounted() {
    this.toggleAllResources();
    this.widgetSingleResource.selected = this.user.resources[0].id;
    this.sendDataToParent();
  },
  methods: {
    toggleAllResources() {
      if (this.user.resources_count === this.widgetResources.selected.length) {
        this.widgetResources.selected = [];
      } else {
        this.widgetResources.selected = [];
        this.user.resources.forEach((resource) => {
          this.widgetResources.selected.push(resource.id);
        });
      }
    },
    sendDataToParent() {
      this.$emit("onChange", {
        widgetType: this.widgetType,
        widgetResources: this.widgetResources.selected,
        widgetSingleResource: this.widgetSingleResource.selected,
      });
    },
  },
};
</script>

<style scoped></style>
