const scheduleOneGrid = [
  {
    date: "2021-04-19",
    value: [
      {
        price: 1000,
        time: "10:00",
        active: true,
      },
      {
        price: 1200,
        time: "10:30",
        active: true,
      },
      {
        price: 3400,
        time: "11:00",
        active: true,
      },
      {
        price: 3400,
        time: "11:30",
        active: true,
      },
      {
        price: 3400,
        time: "12:00",
        active: true,
      },
      {
        price: 3400,
        time: "12:30",
        active: true,
      },
      {
        price: 3400,
        time: "13:00",
        active: true,
      },
    ],
  },
  {
    date: "2021-04-20",
    value: [
      {
        price: 1000,
        time: "10:00",
        active: true,
      },
      {
        price: 1200,
        time: "10:30",
        active: true,
      },
      {
        price: 3400,
        time: "11:00",
        active: true,
      },
      {
        price: 3400,
        time: "11:30",
        active: true,
      },
      {
        price: 3400,
        time: "12:00",
        active: true,
      },
      {
        price: 3400,
        time: "12:30",
        active: true,
      },
      {
        price: 3400,
        time: "13:00",
        active: true,
      },
    ],
  },
  {
    date: "2021-04-21",
    value: [
      {
        price: 1000,
        time: "10:00",
        active: true,
      },
      {
        price: 1200,
        time: "10:30",
        active: true,
      },
      {
        price: 3400,
        time: "11:00",
        active: true,
      },
      {
        price: 3400,
        time: "11:30",
        active: true,
      },
      {
        price: 3400,
        time: "12:00",
        active: true,
      },
      {
        price: 3400,
        time: "12:30",
        active: true,
      },
      {
        price: 3400,
        time: "13:00",
        active: true,
      },
    ],
  },
  {
    date: "2021-04-22",
    value: [
      {
        price: 1000,
        time: "10:00",
        active: true,
      },
      {
        price: 1200,
        time: "10:30",
        active: true,
      },
      {
        price: 3400,
        time: "11:00",
        active: true,
      },
      {
        price: 3400,
        time: "11:30",
        active: true,
      },
      {
        price: 3400,
        time: "12:00",
        active: true,
      },
      {
        price: 3400,
        time: "12:30",
        active: true,
      },
      {
        price: 3400,
        time: "13:00",
        active: true,
      },
    ],
  },
  {
    date: "2021-04-23",
    value: [
      {
        price: 1000,
        time: "10:00",
        active: true,
      },
      {
        price: 1200,
        time: "10:30",
        active: true,
      },
      {
        price: 3400,
        time: "11:00",
        active: true,
      },
      {
        price: 3400,
        time: "11:30",
        active: true,
      },
      {
        price: 3400,
        time: "12:00",
        active: true,
      },
      {
        price: 3400,
        time: "12:30",
        active: true,
      },
      {
        price: 3400,
        time: "13:00",
        active: true,
      },
    ],
  },
  {
    date: "2021-04-24",
    value: [
      {
        price: 1000,
        time: "10:00",
        active: true,
      },
      {
        price: 1200,
        time: "10:30",
        active: true,
      },
      {
        price: 3400,
        time: "11:00",
        active: true,
      },
      {
        price: 3400,
        time: "11:30",
        active: true,
      },
      {
        price: 3400,
        time: "12:00",
        active: true,
      },
      {
        price: 3400,
        time: "12:30",
        active: true,
      },
      {
        price: 3400,
        time: "13:00",
        active: true,
      },
    ],
  },
  {
    date: "2021-04-25",
    value: [
      {
        price: 1000,
        time: "10:00",
        active: true,
      },
      {
        price: 1200,
        time: "10:30",
        active: true,
      },
      {
        price: 3400,
        time: "11:00",
        active: true,
      },
      {
        price: 3400,
        time: "11:30",
        active: true,
      },
      {
        price: 3400,
        time: "12:00",
        active: true,
      },
      {
        price: 3400,
        time: "12:30",
        active: true,
      },
      {
        price: 3400,
        time: "13:00",
        active: true,
      },
    ],
  },
];
const scheduleMultiGrid = [
  {
    active: true,
    name: "Тестовая услуга 1",
    address: "Россия, Москва, Кремлёвская набережная",
    photo: [
      {
        src: "/api/storage/app/media/nature-1.jpeg",
      },
    ],
    tags: ["Теги"],
    age_limit: "6+",
    schedule: [
      { price: 1000, time: "10:00", active: true },
      {
        price: 1000,
        time: "11:00",
        active: true,
      },
      {
        price: 1000,
        time: "12:00",
        active: true,
      },
    ],
  },
  {
    active: true,
    name: "Тестовая услуга 2",
    address: "Россия, Санкт-Петербург, улица Седова, 12",
    photo: [
      {
        src: "/api/storage/app/media/nature-2.jpeg",
      },
    ],
    tags: ["Теги"],
    age_limit: "6+",
    schedule: [
      { price: 1000, time: "15:00", active: true },
      {
        price: 1000,
        time: "16:00",
        active: true,
      },
      {
        price: 1000,
        time: "17:00",
        active: true,
      },
    ],
  },
];

export { scheduleOneGrid, scheduleMultiGrid };
