<template>
  <div
    class="rounded-sm mb-40 lg:mb-20 filling-balance custom-block px-0"
    :class="{ 'bg-white-40': modal }"
  >
    <div
      class="px-30 pb-30 lg:pt-15 lg:px-15 lg:pb-20"
      :class="modal ? 'border-0' : 'border-b border-white-150'"
    >
      <div class="flex items-center text-18 text-white-normal">
        Пополнить баланс
      </div>
      <Tabs
        v-model="activeTab"
        :list-link="listOptionsAddress"
        class="tabs mt-15 w-1/2"
      />

      <div v-if="activeTab === 'PayOnline'">
        <div class="text-white-500 text-12 leading-19 mb-24 whitespace-normal">
          {{
            `Чтобы пополнить баланс укажите сумму пополнения или количество дней, на
        которое вы желаете продлить период пользования системой бронирования,
        далее нажмите кнопку «Оплатить». Минимальная сумма пополнения баланса – ${
          isMonthTariff
            ? user.tariff.tariff_price.split(" ", 1).join()
            : user.minimum_balance_replenishment
        }`
          }}
          {{ user.tariff.tariff_currency }}
        </div>
        <div
          :class="modal ? 'flex-col items-start' : 'flex-row items-end'"
          class="flex lg:flex-col lg:items-start"
        >
          <label
            class="text-12 font-medium text-white-normal block flex flex-col lg:mr-0 lg:w-full lg:mb-20"
            :class="{ 'w-full mr-0 mb-20': modal }"
          >
            <span class="mb-7 flex items-center">
              <el-radio v-model="filling.type" :label="1"
                >Пополнить в рублях</el-radio
              >
            </span>
            <span class="flex">
              <el-input
                v-model.number="balance.currency"
                placeholder="Сумма в рублях"
                min="100"
                type="number"
                :disabled="filling.type === 2"
                @blur="correctCurrency()"
              >
                <template slot="append"
                  ><span class="text-white-500 pr-15">₽</span></template
                >
              </el-input>
            </span>
          </label>
          <span
            class="text-14 px-20 lg:pl-0 lg:pb-0 lg:mb-20 lg:pr-0 text-white-normal flex items-center lg:w-full whitespace-no-wrap"
            :class="modal ? 'pl-0 pr-0 pb-0 w-full mb-20' : 'pb-10'"
            >или
            <span
              class="lg:block h-2 bg-white-100 w-full lg:ml-7"
              :class="modal ? 'block ml-7' : 'hidden'"
            ></span
          ></span>
          <label
            class="text-12 font-medium text-white-normal block flex flex-col lg:mr-0 lg:w-full lg:mb-0"
            :class="{ 'w-full mb-0 mr-0': modal }"
          >
            <span class="mb-7 flex items-center">
              <el-radio v-model="filling.type" :label="2">
                {{
                  isOneServiceTariff
                    ? "Пополнить в днях"
                    : "Пополнить в месяцах"
                }}
              </el-radio>
            </span>
            <span class="flex">
              <el-input
                v-model.number="balance.days"
                :max="5"
                placeholder="Сумма в рублях"
                type="number"
                :disabled="filling.type === 1"
              >
                <template slot="append"
                  ><span class="text-white-500 pr-15">{{
                    isOneServiceTariff ? "дней" : "месяцев"
                  }}</span></template
                >
              </el-input>
            </span>
          </label>
        </div>
      </div>
      <div v-if="activeTab === 'PayAccount'">
        <div class="text-white-500 text-12 leading-19 mb-24 whitespace-normal">
          {{
            `Чтобы сформировать счет на оплату укажите сумму. Счет будет сформирован в PDF файл и вы сможете его скачать. – ${
              isMonthTariff
                ? user.tariff.tariff_price.split(" ", 1).join()
                : user.minimum_balance_replenishment
            }`
          }}
          {{ user.tariff.tariff_currency }}
        </div>
        <div
          :class="modal ? 'flex-col items-start' : 'flex-row items-end'"
          class="flex lg:flex-col lg:items-start"
        >
          <label
            class="text-12 font-medium text-white-normal block flex flex-col lg:mr-0 lg:w-full lg:mb-20"
            :class="{ 'w-full mr-0 mb-20': modal }"
          >
            <span class="mb-7 flex items-center">
              Укажите сумму<span class="danger">*</span>
            </span>
            <span class="flex">
              <el-input
                v-model.number="balance.currency"
                placeholder="Сумма в рублях"
                min="100"
                type="number"
                :disabled="filling.type === 2"
                @blur="correctCurrency()"
              >
                <template slot="append"
                  ><span class="text-white-500 pr-15">₽</span></template
                >
              </el-input>
            </span>
          </label>
          <span
            class="text-14 px-20 lg:pl-0 lg:pb-0 lg:mb-20 lg:pr-0 text-white-normal flex items-center lg:w-full whitespace-no-wrap"
            :class="modal ? 'pl-0 pr-0 pb-0 w-full mb-20' : 'pb-10'"
          >
            <span
              class="lg:block h-2 bg-white-100 w-full lg:ml-7"
              :class="modal ? 'block ml-7' : 'hidden'"
            ></span
          ></span>
          <label
            class="text-12 font-medium text-white-normal block flex flex-col lg:mr-0 lg:w-full lg:mb-0"
            :class="{ 'w-full mb-0 mr-0': modal }"
          >
            <span class="mb-7 flex items-center">
              Плательщик <span class="danger">*</span>
            </span>
            <span class="flex">
              <el-input
                v-model.number="balance.payer_name"
                placeholder="Укажите юр. лицо"
                type="text"
              >
                <template slot="append"
                  ><span class="text-white-500 pr-15"></span
                ></template>
              </el-input>
            </span>
          </label>
        </div>
      </div>
    </div>

    <div
      class="px-30 py-20 flex items-center lg:flex-wrap lg:px-15"
      :class="{ 'pt-0': modal }"
    >
      <div class="mr-30">
        <div class="text-white-500 text-12 leading-16">Услуг</div>
        <div class="text-22 leading-36 font-semibold text-white-normal">
          {{ user.resources_count }}
        </div>
      </div>
      <div class="mr-35">
        <div class="text-white-500 text-12 leading-16">
          {{ isOneServiceTariff ? "Дней" : "Месяцев" }}
        </div>
        <div class="text-22 leading-36 font-semibold text-white-normal">
          {{ balanceDays }}
        </div>
      </div>
      <div>
        <div class="text-white-500 text-12 leading-16">К оплате</div>
        <div class="text-22 leading-36">
          <span class="font-semibold text-yellow-100">
            {{ balanceSum }}
          </span>
          <span class="leading-25 text-14 text-white-normal"> руб. </span>
        </div>
      </div>

      <el-button
        type="primary"
        class="border-0 ml-auto lg:w-full lg:mt-20"
        :disabled="
          $data.activeTab === 'PayAccount'
            ? !$data.balance.payer_name.length
            : false
        "
        @click="payBalance"
      >
        <div class="flex items-center lg:justify-center">
          <svg-icon
            icon-name="pay"
            class-name="text-black mr-7"
            width="17"
            height="14"
          ></svg-icon
          >Оплатить
        </div>
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Tabs from "@/components/Tabs.vue";
import { AJAX_GET, AJAX_POST } from "../units/ajax";

export default {
  name: "FillingBalance",
  components: {
    Tabs,
  },
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeTab: "PayOnline",
      listOptionsAddress: [
        {
          title: "Оплатить онлайн",
          value: "PayOnline",
        },
        {
          title: "Оплатить по счету",
          value: "PayAccount",
        },
      ],
      minDaysVal: null,
      tariffPrice: null,
      resourceCount: 1,
      balance: {
        currency: 100,
        days: 1,
        payer_name: "",
        costPerDay: null,
      },
      filling: {
        type: 1,
      },
    };
  },

  computed: {
    ...mapState("user", ["user"]),
    balanceSum() {
      if (this.filling.type === 2) {
        const qwe = this.isOneServiceTariff
          ? this.tariffPrice * this.balance.days * this.resourceCount // this.user.resources_count
          : this.tariffPrice * this.balance.days;
        if (qwe < this.user.minimum_balance_replenishment) {
          return this.user.minimum_balance_replenishment;
        }
        return this.isOneServiceTariff
          ? this.tariffPrice * this.balance.days * this.resourceCount // this.user.resources_count
          : this.tariffPrice * this.balance.days;

        // this.balance.days *
        // this.balance.costPerDay *
        // this.user.resources_count
      }

      return this.balance.currency;
    },
    balanceDays() {
      // т.е. 12 р за услугу и 10 услуг и минимальна 450 - должно быть
      // 450/12*10=3,7 - т.е. должно быть на 3 дня
      if (this.filling.type === 1) {
        if (this.isOneServiceTariff) {
          return Math.floor(
            this.balanceSum /
              // (this.tariffPrice * this.resourceCount) === 0
              //   ? 1
              // :
              (this.tariffPrice * this.resourceCount)
          );
        }
        if (this.isMonthTariff) {
          return Math.floor(this.balanceSum / this.tariffPrice);
        }
        // return Math.floor(
        //   this.balance.currency /
        //     this.balance.costPerDay /
        //     (this.user.resources_count || 1)
        // );
      }

      return this.balance.days;
    },
    isOneServiceTariff() {
      return this.user.tariff.tariff === 1 || this.user.tariff.tariff === 2;
    },
    isMonthTariff() {
      return (
        this.user.tariff.tariff === 3 ||
        this.user.tariff.tariff === 4 ||
        this.user.tariff.tariff === 7
      );
    },
  },
  watch: {
    balanceDays() {
      if (this.balance.days <= this.minDaysVal) {
        this.balance.days = this.minDaysVal;
      }
    },
  },
  created() {
    this.resourceCount =
      this.user.resources_count < 1 ? 1 : this.user.resources_count;
  },
  mounted() {
    this.getSettings();
    this.balance.costPerDay = this.user.tariff.custom_price_by_service
      ? this.user.tariff.custom_price_by_service
      : 1;
    this.tariffPrice = this.user.tariff.tariff_price.split(" ", 1).join();
    if (this.isMonthTariff) {
      this.balance.currency = this.tariffPrice;
    } else {
      this.balance.currency = this.user.minimum_balance_replenishment;
    }
    this.balance.days = this.balanceDays;
    this.minDaysVal = this.balanceDays;
  },
  methods: {
    ...mapActions("balance", ["getHistoryPay"]),
    correctCurrency() {
      // eslint-disable-next-line no-unused-expressions, no-nested-ternary
      this.balance.currency < this.user.minimum_balance_replenishment
        ? this.isMonthTariff
          ? (this.balance.currency = this.tariffPrice)
          : (this.balance.currency = this.user.minimum_balance_replenishment)
        : this.isMonthTariff
        ? (this.balance.currency = this.tariffPrice)
        : this.balance.currency;
    },
    getSettings() {
      AJAX_GET("/api/pay/settings", { noCancel: true }).then((response) => {
        // response.data.cost_per_day;
      });
    },
    payBalance() {
      const key = this.activeTab === "PayOnline" ? "" : "/create_invoice";
      const payerName =
        this.activeTab !== "PayOnline" ? this.balance.payer_name : "";
      let obj = {
        amount: this.balanceSum,
        // replenishment: this.filling === 1 ? "amount" : "days",
      };
      obj = payerName.length
        ? { ...obj, payer_name: payerName }
        : { ...obj, replenishment: this.filling === 1 ? "amount" : "days" };
      if (this.balanceSum) {
        AJAX_POST(`/api/pay${key}`, {
          ...obj,
        }).then((response) => {
          if (!payerName) {
            window.location.href = response.data.redirect;
          } else if (payerName) {
            this.getHistoryPay();
            this.$notify({
              type: "success",
              title: response.data.msg,
            });
          }
        });
      } else {
        this.$notify({
          title: "Укажите сумму или кол-во дней",
          type: "error",
          showClose: false,
          duration: 3000,
        });
      }
      this.balance.payer_name = "";
    },
  },
};
</script>

<style lang="scss">
.filling-balance {
  .el-input__inner {
    min-width: 287px;

    @screen xl {
      min-width: 0;
    }
  }
}
</style>
