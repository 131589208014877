var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"clients-table relative sm:px-16"},[(_vm.loading)?_c('spinner',{staticClass:"table__spinner"}):_vm._e(),_c('el-table',{staticClass:"border-b-0 mb-40",attrs:{"data":_vm.clients,"default-sort":{ prop: 'index', order: 'descending' },"stripe":true}},[_c('el-table-column',{attrs:{"type":"index","label":"№","prop":"id","min-width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"flex flex-col items-center"},[_vm._v(" "+_vm._s(row.id)+" "),_c('div',{staticClass:"table__icon",on:{"click":function($event){return _vm.onEdit(row)}}},[_c('svg-icon',{attrs:{"icon-name":"edit-2","width":"20","height":"20"}})],1)])}}])}),_c('el-table-column',{attrs:{"prop":"date","label":"Дата","min-width":111,"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{},[_vm._v(" "+_vm._s(row.date)+" "),_c('div',{staticClass:"small_text"},[_vm._v(" "+_vm._s(row.time)+" ")])])}}])}),_c('el-table-column',{attrs:{"prop":"name","label":"ФИО","min-width":250,"sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"table__icon table__icon_border p-7"},[_c('svg-icon',{attrs:{"icon-name":"sms","width":"15","height":"15"}})],1),_c('div',{staticClass:"underline"},[_vm._v(_vm._s(row.name))])])}}])}),_c('el-table-column',{attrs:{"prop":"phone","label":"Телефон","sortable":"","min-width":220},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"table__icon table__icon_border p-5"},[_c('svg-icon',{attrs:{"icon-name":"additionally","width":"20","height":"20"}})],1),_c('div',[_vm._v(_vm._s(row.phone))])])}}])}),_c('el-table-column',{attrs:{"prop":"email","label":"E-mail","sortable":"","min-width":220},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return _c('div',{staticClass:"flex items-center"},[_c('div',{staticClass:"table__icon table__icon_border p-4"},[_c('svg-icon',{attrs:{"icon-name":"additionally","width":"20","height":"20"}})],1),_c('div',[_vm._v(_vm._s(row.email))])])}}])}),_c('el-table-column',{attrs:{"prop":"reserve","label":"Бронирования","width":268}},[_c('el-button',{staticClass:"w-full"},[_c('div',{staticClass:"table__btn"},[_vm._v(" Бронирования "),_c('span',{staticClass:"icon_rounded icon_rounded_question w-18 h-18"},[_c('svg-icon',{attrs:{"width":"5","height":"9","icon-name":"question"}})],1)])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }