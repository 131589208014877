<template>
  <div class="my-30">
    <div class="flex">
      <span class="small_text mb-30 w-1/2">Дата и время </span>
      <span class="w-1/2 underline">{{ item.consist.date_time }}</span>
    </div>

    <div class="flex-col w-full">
      <div v-for="(ticket, idx) in tickets" :key="idx" class="mb-10 flex">
        <div class="w-1/2 mb-3">
          <span class="small_text">Билет</span>
          <span class="small_text">Цена </span>
          <span class="small_text">Количество </span>
        </div>

        <div class="w-1/2 mb-3">
          <span class="small_text-outcome">{{ ticket.name }} </span>
          <span class="small_text-outcome"
            >{{ ticket.price }} {{ item.currency }}</span
          >
          <span class="small_text-outcome">{{ ticket.select_ticket }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderGroupSameSeat",
  props: {
    tickets: {
      type: Object,
    },
    item: {
      type: Object,
    },
  },
};
</script>
<style lang="scss" scoped>
.rotate {
  transform: rotate(180deg);
}
.drop-wrapp {
  display: flex;
  cursor: pointer;
}
.small_text {
  &-total {
    font-size: 12px;

    color: rgba(255, 190, 61, 1);
  }
  &-drop {
    margin-right: 8px;
    font-size: 12px;
  }
  &-outcome {
    font-weight: 500;
    opacity: 1;
    font-size: 12px;
    line-height: 20px;
    display: block;
  }
}
.utf {
  &-form {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    margin: 10px 0px;
    padding: 8px 15px;
  }
  &-icon {
    color: rgba(255, 255, 255, 0.5);
    position: relative;
    top: 3px;
  }
}
</style>
