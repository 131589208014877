<template>
  <BookingTemplate
    ref="template"
    class="notification__block"
    type="new_reserve"
    subtitle="Отправляется клиенту при отмене бронирования."
    :items-admin="items.admin"
    :items-user="items.user"
    :default-items-user="defaultItems.user"
    :default-items-admin="defaultItems.admin"
    :loading-admin="loading.admin"
    :loading-user="loading.user"
    @save="save"
  />
</template>

<script>
import BookingTemplate from "@/components/Notifications/Booking/BookingTemplate";

export default {
  components: {
    BookingTemplate,
  },
  props: {
    items: Object,
    defaultItems: Object,
    loading: Object,
    type: {
      type: String,
      required: true,
      default: "booking",
    },
  },
  methods: {
    save(item) {
      this.$emit("save", {
        ...item.items,
        type: `${this.$props.type}_${item.key}`,
      });
    },
  },
};
</script>

<style lang="scss">
.notification {
  display: flex;
  justify-content: space-between;
  &__block {
    width: calc(50% - 20px);
  }
}
</style>
