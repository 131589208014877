<template>
  <div class="bk-full">
    <div class="row-info">
      <div
        class="row-info__title"
        :style="{
          color: isThemeWidget ? customStyle.active_text_color : '',
        }"
      >
        Осталось билетов
      </div>
      <div
        v-if="ticketsRemained"
        class="row-info__value"
        :style="{
          color: isThemeWidget ? customStyle.active_text_color : '',
        }"
      >
        {{ ticketsRemained }}
      </div>
    </div>
    <p
      class="title-widget"
      :style="{
        color: isThemeWidget ? customStyle.active_text_color : '',
      }"
    >
      Билеты
    </p>
    <div v-for="(ticket, i) in listTicket" :key="i" class="input-number">
      <div class="input-number__info">
        <span
          class="input-number__info-title"
          :style="{
            color: isThemeWidget ? customStyle.inactive_text_color : '',
          }"
          >{{ ticket.name }}</span
        >
        <span
          class="input-number__info-subtitle"
          :style="{
            color: isThemeWidget ? customStyle.active_text_color : '',
          }"
        >
          {{
            isHoliday
              ? ticket.holidays_price + selectCell.weekend_price_additional
              : ticket.base_price + selectCell.price_additional
          }}
          {{ selectCell.currency }}
        </span>
      </div>
      <el-input-number
        v-model="ticket.select_ticket"
        class="input-number__input"
        :class="{ 'custom-style': isThemeWidget }"
        :style="{
          background: isThemeWidget ? customStyle.background_cells_fields : '',
        }"
        :min="0"
        :max="differenceSeats || valMax"
        :controls="true"
        @input="emitData()"
      />
    </div>
    <el-alert
      v-if="!isSel"
      title="Скорректируйте данные"
      type="warning"
      :closable="false"
      class="w-full"
      center
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { AJAX_GET, AJAX_POST } from "@/units/ajax";

export default {
  name: "GroupLimitType",
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    selectCell: {
      type: Object,
      default: () => {},
    },
    settings: {
      type: Object,
      default: () => {},
    },
    hash: { type: Object },
    customStyle: { type: Object },
    isThemeWidget: { type: String },
  },
  data() {
    return {
      valMax: 0,
      listTicket: [],
      sel: null,
      ticketsRemained: null,
    };
  },
  computed: {
    ...mapState("widget", ["remainedTickets"]),

    isHoliday() {
      return this.selectCell.is_holiday;
    },
    seats() {
      return this.selectCell.seats;
    },
    allSelectTicket() {
      return this.listTicket.reduce((sum, current) => {
        return sum + current.select_ticket;
      }, 0);
    },
    differenceSeats() {
      if (this.ticketsRemained) {
        return this.settings.data.tickets;
      }
      return 0;
    },
    isSel() {
      const isDis = this.listTicket.find((sel) => sel.select_ticket >= 1);
      this.$emit("isDis", isDis);
      return isDis;
    },
  },

  created() {
    this.valMax = 1;
    this.listTicket = this.settings.data.group.map((el, idx) => {
      return idx === 0
        ? { ...el, select_ticket: 1 }
        : { ...el, select_ticket: null };
    });
  },
  methods: {
    ...mapActions("widget", ["saveBooking", "getRemainedTickets"]),

    async getSlotCheck(param) {
      await AJAX_POST("/api/v1/booking/check_slots_validity", {
        ...param,
      }).then((result) => {
        this.ticketsRemained = result.data.tickets;
      });
    },

    async emitData() {
      this.$emit("isLoadingEmit", true);

      this.$emit(
        "setTickets",
        this.listTicket
          .filter((el) => el.select_ticket)
          .map((item) => {
            return {
              name: item.name,
              price: this.isHoliday
                ? item.holidays_price + this.selectCell.weekend_price_additional
                : item.base_price + this.selectCell.price_additional,
              select_ticket: item.select_ticket,
              id: item.id,
              maxVal: this.seats - item.select_ticket,
            };
          })
      );

      await this.getSlotCheck({
        book_info: {
          tickets: this.listTicket,
        },
        tickets: this.listTicket,
        id: this.selectCell.id,
        resourceHash: this.hash.hash,
        date: this.selectCell.date,
        time: this.selectCell.time,
      });
      this.valMax = 0;

      this.$emit("isLoadingEmit", false);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.row-info {
  margin-bottom: 20px;
  &__title {
    width: auto;
    flex-grow: 1;
  }
  &__value {
    flex-grow: 0;
  }
}
.input-number__info-subtitle span {
  &:first-child {
    color: $--color-white;
    margin-right: 6px;
  }
  &:last-child {
    color: $--color-white-5;
    margin-left: 6px;
  }
}
.custom-style {
  ::v-deep .el-input__inner {
    color: var(--colorCustom);
  }

  ::v-deep .el-input-number__increase,
  ::v-deep .el-input-number__decrease {
    background: var(--colorInactive);
    color: var(--colorCustom);
  }
}
</style>
