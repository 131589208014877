<template>
  <div class="bg-violet-900 px-20 h-auto min-h-full reserve-edit-wrapper">
    <simplebar data-simplebar-auto-hide="false">
      <div class="h-full relative">
        <el-form
          ref="item"
          hide-required-asterisk
          label-position="top"
          :rules="rules"
          :model="itemForm"
          class="relative"
        >
          <el-col :span="24">
            <el-form-item label="Название" class="mb-20" prop="name">
              <el-input
                v-model="itemForm.name"
                placeholder="Введите название источника"
                type="text"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Домен" class="mb-20" prop="domen">
              <el-input
                v-model="itemForm.domen"
                placeholder="Введите домен"
                type="text"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="Комиссия" class="mb-20" prop="commission">
              <el-input
                v-model="itemForm.commission"
                placeholder="Введите комиссию"
                type="number"
              >
                <template slot="append"
                  ><span class="text-white-500 pr-15">%</span></template
                ></el-input
              >
            </el-form-item>
          </el-col>
        </el-form>
      </div>
    </simplebar>
    <el-button
      type="primary"
      class="bg-gradient-b-yellow border-0 w-full save-button"
      @click="saveChanges"
      >Сохранить изменения</el-button
    >
  </div>
</template>

<script>
import simplebar from "simplebar-vue";

export default {
  components: {
    simplebar,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      itemForm: {
        id: null,
        name: "",
        domen: "",
        commission: "",
      },
      rules: {
        name: [
          {
            required: true,
            message: "Введите название источника",
            trigger: ["change", "blur"],
          },
        ],
        domen: [
          {
            required: true,
            message: "Введите домен",
            trigger: ["change", "blur"],
          },
        ],
        commission: [
          {
            required: true,
            message: "Введите комиссию",
            trigger: ["change", "blur"],
          },
        ],
      },
    };
  },
  mounted() {
    this.setInfo();
  },
  methods: {
    saveChanges() {
      this.$refs.item.validate((valid) => {
        if (valid) this.$emit("update", this.itemForm);
      });
    },
    setInfo() {
      if (this.item) {
        this.itemForm.id = this.item.id;
        this.itemForm.name = this.item.name;
        this.itemForm.domen = this.item.domen;
        this.itemForm.commission = this.item.commission;
      }
    },
  },
};
</script>
