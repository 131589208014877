const listDuration = [
  {
    label: "минуты",
    shortLabel: "мин",
    value: "minutes",
  },
  {
    label: "часы",
    shortLabel: "ч",
    value: "hours",
  },
  {
    label: "дни",
    shortLabel: "д",
    value: "days",
  },
];

const listPrice = [
  {
    title: "За место",
    value: "for_a_place",
  },
  {
    title: "За группу",
    value: "for_a_group",
  },
];

const typesTickets = [
  {
    title: "Общий",
    value: "general_limits",
    popoverText: "Задается лимит на общее количество доступных мест.",
  },
  {
    title: "На тип билета",
    value: "ticket_type_limit",
    popoverText: "Задается лимит на каждый отдельно взятый тип билета.",
  },
];
const typesServices = [
  {
    title: "Есть дочерние услуги",
    value: "general_limits",
    // popoverText: "Задается лимит на общее количество доступных мест.",
  },
  {
    title: "Нет дочерних услуг",
    value: "ticket_type_limit",
    // popoverText: "Задается лимит на каждый отдельно взятый тип билета.",
  },
];
const typeService = [
  {
    id: 0,
    name: "Кредит",
    key: "cred",
    subService: [
      { id: 1, name: "Кредит", subName: "Оформить кредит" },
      { id: 2, name: "Кредит", subName: "Погасить кредит" },
      { id: 3, name: "Кредит", subName: "Реструктуризация" },
    ],
  },
  {
    id: 4,
    name: "Вклад",
    key: "vklad",
    subService: [
      { id: 5, name: "Вклад", subName: "Открыть вклад" },
      { id: 6, name: "Вклад", subName: "Закрыть вклад" },
    ],
  },
  {
    id: 8,
    name: "Банковские карты",
    key: "cards",
    subService: [
      { id: 9, subName: "Оформить кредит" },
      { id: 10, subName: "Погасить кредит" },
      { id: 11, subName: "Реструктуризация" },
    ],
  },
  {
    id: 12,
    name: "Ипотека",
    key: "ipoteka",
    subService: [
      { id: 13, subName: "Оформить кредит" },
      { id: 14, subName: "Погасить кредит" },
      { id: 15, subName: "Реструктуризация" },
    ],
  },
];

const listDays = [
  { value: 1, shortTitle: "Пн", title: "Понедельник" },
  { value: 2, shortTitle: "Вт", title: "Вторник" },
  { value: 3, shortTitle: "Ср", title: "Среда" },
  { value: 4, shortTitle: "Чт", title: "Четверг" },
  { value: 5, shortTitle: "Пт", title: "Пятница" },
  { value: 6, shortTitle: "Сб", title: "Суббота" },
  { value: 7, shortTitle: "Вс", title: "Воскресенье" },
];

export {
  listDuration,
  listPrice,
  typesTickets,
  listDays,
  typesServices,
  typeService,
};
