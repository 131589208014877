<template>
  <div class="input-check">
    <el-input
      class="input-check__input"
      :value="value"
      :placeholder="placeholder"
      @input="$emit('input', $event)"
    ></el-input>
    <div class="input-check__icon" @click="$emit('check')">
      <svg-icon icon-name="check" width="16" height="16"></svg-icon>
    </div>
  </div>
</template>

<script>
export default {
  name: "InputCheck",
  props: {
    value: {
      type: [String, Number],
      default: 0,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.input-check {
  display: flex;
  &__input {
    flex-grow: 1;
  }
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 1;
    width: 44px;
    background: $--color-white-35;
    // margin-bottom: 20px;
    border-radius: 0 3px 3px 0;
    cursor: pointer;
    &:hover {
      background: $--color-white-5;
    }
  }
}
::v-deep {
  .svg-icon {
    color: $--color-white;
  }
  .el-input__inner {
    border-radius: 3px 0 0 3px;
  }
}
</style>
