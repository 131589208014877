<template>
  <el-collapse class="filter border-0 mb-40 lg:mb-20">
    <BlockCollapse title="Фильтрация" name="filter">
      <el-row
        type="flex"
        :gutter="!$mq.tablet ? 30 : 0"
        class="w-full flex-wrap filter__content"
      >
        <Date :text="{ label: 'Дата создания' }" @selected="selectedDate" />
        <Date
          :text="{ label: 'Дата оплаты' }"
          @selected="selectedDatePayment"
        />
        <Select
          :text="{ label: 'Тип', placeholder: 'Любой тип' }"
          :items="typeOptions"
          @selected="selectedPayType"
        />
        <Select
          :text="{ label: 'Статус', placeholder: 'Любой статус' }"
          :items="statusOptions"
          @selected="selectedStatus"
        />
      </el-row>
      <el-row>
        <el-col :span="24" class="filter__actions filter__content">
          <el-button @click="clearFilter"> Сбросить </el-button>
          <el-button
            type="primary"
            class="bg-transparent text-white-normal"
            @click="showReserves"
          >
            Применить
          </el-button>
        </el-col>
      </el-row>
    </BlockCollapse>
  </el-collapse>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import BlockCollapse from "@/components/BlockCollapse";
import Select from "@/components/Table/Select";
import Date from "@/components/Table/Date";
import moment from "moment";

export default {
  components: {
    BlockCollapse,
    Select,
    Date,
  },
  data() {
    return {
      checkCreate: [],
      checkCreatePay: [],
      checkStatus: [],
      checkPayType: [],
      typeOptions: [
        { value: 1, label: "type1" },
        { value: 2, label: "type2" },
      ],
      statusOptions: [
        { value: 1, label: "status1" },
        { value: 2, label: "status2" },
      ],
    };
  },

  computed: {
    ...mapState("user", ["user"]),
  },
  created() {
    this.statusOptions = Object.entries(this.user.payment_statuses).map((e) => {
      return { value: e[0], label: e[1] };
    });
    this.typeOptions = this.user.payment_types.map((type) => {
      return { value: type.value, label: type.name };
    });
  },
  methods: {
    ...mapMutations("paymentHistory", ["setQueryPays"]),
    ...mapActions("paymentHistory", ["getPayments"]),

    selectedStatus(status) {
      this.checkStatus = status;
    },
    selectedPayType(type) {
      this.checkPayType = type;
    },
    selectedDate(dateCreate) {
      const date = {
        create_start: moment(dateCreate[0]).format("YYYY-MM-DD"),
        create_end: moment(dateCreate[1]).format("YYYY-MM-DD"),
      };
      this.checkCreate = date;
    },
    selectedDatePayment(datePayment) {
      const datePay = {
        payment_start: moment(datePayment[0]).format("YYYY-MM-DD"),
        payment_end: moment(datePayment[1]).format("YYYY-MM-DD"),
      };

      this.checkCreatePay = datePay;
    },
    async showReserves() {
      const obj = {
        "filter[create_start]": this.checkCreate.create_start,
        "filter[create_end]": this.checkCreate.create_end,
        "filter[payment_start]": this.checkCreatePay.payment_start,
        "filter[payment_end]": this.checkCreatePay.payment_end,
        "filter[payment_status]": this.checkStatus,
        "filter[payment_type]": this.checkPayType,
      };
      this.$router.replace({ query: obj });
      this.setQueryPays(obj);
      await this.getPayments();
    },
    async clearFilter() {
      const obj = {
        "filter[create_start]": "",
        "filter[create_end]": "",
        "filter[payment_start]": "",
        "filter[payment_end]": "",
        "filter[payment_status]": "",
        "filter[payment_type]": "",
      };
      this.$router.replace({ query: obj });
      this.setQueryPays(obj);
      await this.getPayments();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";
::v-deep .collapse-title {
  padding: 20px 0px;
  padding-left: 15px;
  font-size: 18px;
  font-weight: 600;
  align-items: center;
}
::v-deep .collapse-title svg {
  margin-right: 10px;
}
::v-deep .collapse-content {
  padding: 30px 0;
}
.filter {
  &__content {
    padding: 0 30px;
  }
  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 30px;
    margin-top: 15px;
    border-top: 1px solid $--color-light-white;
    button {
      height: 40px;
    }
  }
}
</style>
