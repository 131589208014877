<template>
  <Send
    class="modal"
    :show="show"
    title="Отправить E-mail"
    @close="hiddenModal"
    @send="send"
  >
    <div>
      <p class="hint-title">E-mail</p>
      <el-input v-model="email" :placeholder="params"> </el-input>
      <p class="hint-title">Сообщение</p>
      <div class="textarea_group">
        <el-input
          v-model="message"
          type="textarea"
          show-word-limit
          placeholder="Введите текст сообщения"
          :autosize="{ minRows: 5, maxRows: 6 }"
        >
        </el-input>
        <!-- <div v-if="messageLength >= 1" class="textarea_group__limit">
        {{ messageLength }} символов
      </div> -->
      </div>
    </div>
    <p class="hint-title__medium-white">
      Если у вас не настроен SMTP, то укажите клиенту в письме кто вы, и по
      какому вопросу вы пишите, а также контактные данные, чтобы клиент смог с
      вами связаться.
    </p>
  </Send>
</template>

<script>
import { mapActions } from "vuex";
import Send from "@/components/SendModals/Template";
// import TextareaEdit from "@/components/TextareaEdit";

export default {
  name: "SendMail",
  components: {
    Send,
    //  TextareaEdit,
  },
  props: {
    show: Boolean,
    params: String || Boolean,
  },
  data() {
    return {
      email: null,
      message: null,
      customToolBar: [
        [{ header: [false, 1, 2, 3] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["bold", "italic"],
      ],
    };
  },
  created() {
    this.email = this.params;
  },
  methods: {
    ...mapActions("sms", ["sendMail"]),
    hiddenModal() {
      this.$emit("close");
    },
    async send() {
      this.loading = true;
      await this.sendMail({ text: this.message, email: this.email });
      this.message = "";
      this.email = "";
      this.loading = false;
      this.hiddenModal();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .ql-editor {
  min-height: 120px;
}
::v-deep .quillWrapper .ql-snow.ql-toolbar {
  padding: 0;
}
::v-deep .quillWrapper .ql-snow.ql-toolbar .ql-formats {
  margin: 0 15px 15px 0;
}
::v-deep .ql-snow .ql-toolbar button,
::v-deep .ql-snow.ql-toolbar button,
::v-deep .ql-snow .ql-picker.ql-header {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 3px;
  height: auto;
  width: auto;
  padding: 10px;
  margin-right: 5px;
}
::v-deep .ql-snow .ql-picker.ql-header {
  min-width: 120px;
  margin-right: 0;
  margin-top: -2px;
}
::v-deep .ql-editor {
  color: white;
}
</style>
