var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"widget",staticClass:"custom-block schedule"},[_c('div',{staticClass:"schedule__title",style:({ color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '' })},[_vm._v(" "+_vm._s(_vm.isMaket ? "Игра престолов" : _vm.service.name)+" ")]),_c('div',{staticClass:"schedule__sub-title",style:({
      color: _vm.isIndividualTheme ? _vm.custom.inactive_text_color : '',
    })},[_vm._v(" "+_vm._s(_vm.isMaket ? "Квест в реальности" : _vm.getNamesKindActivity(_vm.service.kind_of_activity_name))+" ")]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"schedule__item-tags",attrs:{"title":_vm.service.age_comments}},[_c('tag',{style:({
          color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
          background: _vm.isIndividualTheme ? _vm.custom.background_cells_fields : '',
        })},[_vm._v(" "+_vm._s(_vm.service.age_limit))])],1),(_vm.isMaket ? _vm.widget.showTag : _vm.isShowTags || _vm.service.tags)?_c('div',{staticClass:"schedule__item-tags"},_vm._l((_vm.service.tags),function(tag,i){return _c('tag',{key:("tag-" + i),style:({
          color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
          background: _vm.isIndividualTheme ? _vm.custom.background_cells_fields : '',
        })},[_vm._v(" "+_vm._s(tag)+" ")])}),1):_vm._e()]),(_vm.isLoading && !_vm.isMaket)?_c('div',[(_vm.isLoading)?_c('spinner',{attrs:{"custom-color":{ border: ("" + (_vm.custom.active_buttons_background)) }}}):_vm._e(),_c('div',{staticClass:"flex justify-center mt-10"},[_c('span',{staticClass:"text-14",style:({
          color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
        })},[_vm._v("Подождите, пожалуйста. Загружаем расписание")])])],1):(true)?[_vm._l((_vm.schedule.data),function(item,i){return _c('div',{key:i,staticClass:"schedule__item md:flex-col flex-row"},[_c('div',{staticClass:"schedule__item-name"},[_c('div',{staticClass:"schedule__item-name__main",style:({
            color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
          })},[_vm._v(" "+_vm._s(_vm.getNameDate(item.date))+" ")]),_c('div',{staticClass:"schedule__item-name__sub",style:([
            {
              color: _vm.isIndividualTheme ? _vm.custom.inactive_text_color : '',
            },
            {
              color:
                item.value[0].day_id === 6 || item.value[0].day_id === 7
                  ? _vm.custom.active_buttons_background
                  : _vm.custom.inactive_text_color,
            } ])},[_vm._v(" "+_vm._s(_vm.getNameDay(item.date))+" ")])]),_c('div',{staticClass:"schedule__item-schedule"},[(!item.value.length)?_c('div',{staticClass:"custom-alert not-set-schedule"},[_vm._v(" "+_vm._s(item.value.msg)+" ")]):_vm._l((item.value),function(slot,index){return _c('CellSchedule',{key:index,attrs:{"value":slot,"is-maket":_vm.isMaket,"custom-style":_vm.custom,"size":_vm.isIndividualTheme ? _vm.custom.font_size : 'small',"is-type-theme":_vm.isIndividualTheme,"show-price":_vm.showPrice,"add-pristavka":_vm.addPristavka},on:{"click":function($event){return _vm.onClick(item, slot)}}})})],2)])}),_c('div',{staticClass:"schedule__actions"},[_c('el-button',{staticClass:"custom-button",style:({
          background: _vm.isIndividualTheme
            ? _vm.custom.active_buttons_background
            : '',
          color: _vm.isIndividualTheme ? _vm.custom.button_text_color : '',
        }),attrs:{"type":"primary","disabled":_vm.schedule.current_page === 1 || _vm.isMaket},on:{"click":function($event){return _vm.toSchedule(-1)}}},[_c('i',{staticClass:"el-icon-back"}),_vm._v(" "+_vm._s(!_vm.$mq.phoneMobileLarge ? "Пред. неделя" : "Предыдущая неделя")+" ")]),_c('el-button',{staticClass:"custom-button",style:({
          background: _vm.isIndividualTheme
            ? _vm.custom.active_buttons_background
            : '',
          color: _vm.isIndividualTheme ? _vm.custom.button_text_color : '',
        }),attrs:{"type":"primary","disabled":_vm.schedule.current_page === _vm.schedule.last_page || _vm.isMaket},on:{"click":function($event){return _vm.toSchedule(1)}}},[_vm._v(" "+_vm._s(!_vm.$mq.phoneMobileLarge ? "След. неделя" : "Следующая неделя")+" "),_c('i',{staticClass:"el-icon-right"})])],1)]:_c('div',{staticClass:"qwerty"},[_vm._v(" Вы пока не настроили расписание. "),_c('br'),_vm._v(" Сделать это можно в разделе Даты и цены ")]),(_vm.isShowForm)?_c('el-dialog',{attrs:{"visible":_vm.isShowForm,"show-close":false},on:{"update:visible":function($event){_vm.isShowForm=$event}}},[_c('TemplateFormBron',{staticClass:"w-full",attrs:{"title":_vm.isMaket ? "Игра престолов" : _vm.service.name,"sub-title":_vm.isMaket
          ? "Квест в реальности"
          : _vm.getNamesKindActivity(_vm.service.kind_of_activity_name),"style-custom":_vm.custom,"is-type-theme":_vm.isIndividualTheme,"total-price":_vm.isMaket ? 1600 : _vm.totalPrice,"list-field-show":_vm.widgetPublic.fieldForm,"select-cell":_vm.selectCell,"is-maket":_vm.isMaket,"loading":_vm.loading,"is-service":_vm.isService,"select-service":_vm.selectService,"disabled":_vm.isDisabled,"price-disc":_vm.priceDisc},on:{"setService":_vm.setService,"close":function($event){_vm.isShowForm = false},"booking":_vm.broni}},[_c('div',{staticClass:"row-info xs:text-12"},[_c('div',{staticClass:"row-info__title",style:({
            color: _vm.isIndividualTheme ? _vm.custom.inactive_text_color : '',
          })},[_vm._v(" Дата ")]),_c('div',{staticClass:"row-info__value",style:({
            color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
          })},[_vm._v(" "+_vm._s(_vm.isMaket ? "04.02.2021" : _vm.formatedDate)+" ")])]),_c('div',{staticClass:"row-info xs:text-12"},[_c('div',{staticClass:"row-info__title",style:({
            color: _vm.isIndividualTheme ? _vm.custom.inactive_text_color : '',
          })},[_vm._v(" Время ")]),_c('div',{staticClass:"row-info__value",style:({
            color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
          })},[_vm._v(" "+_vm._s(_vm.isMaket ? "10:30" : _vm.selectCell.time)+" ")])]),(_vm.originData.isHidePrice && _vm.selectCell.price > 0)?_c('div',{staticClass:"row-info xs:text-12"},[_c('div',{staticClass:"row-info__title",style:({
            color: _vm.isIndividualTheme ? _vm.custom.inactive_text_color : '',
          })},[_vm._v(" Стоимость ")]),_c('div',{staticClass:"row-info__value",style:({
            color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
          })},[_vm._v(" "+_vm._s(_vm.originData.priceAfter)+" "+_vm._s(_vm.isMaket ? "1 600" : _vm.selectCell.price)+" "+_vm._s(_vm.selectCell.currency)+" "+_vm._s(_vm.originData.priceBefore)+" "+_vm._s(_vm.originData.component.name === "DepTarifSeats" ? "за место" : "")+" ")])]):_vm._e(),(_vm.originData.isHidePrice && _vm.selectCell.discount)?_c('div',{staticClass:"row-info xs:text-12"},[_c('div',{staticClass:"row-info__title",style:({
            color: _vm.isIndividualTheme ? _vm.custom.inactive_text_color : '',
          })},[_vm._v(" Скидка ")]),_c('div',{staticClass:"row-info__value",style:({
            color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
          })},[_vm._v(" "+_vm._s(_vm.isMaket ? "20%" : _vm.selectCell.discount ? _vm.selectCell.discount_value : _vm.selectCell.price)+" "+_vm._s(_vm.selectCell.discount_type === "percent" ? "%" : ("" + (_vm.selectCell.currency)))+" ")])]):_vm._e(),_c('div',{staticClass:"hl",style:({
          background:
            _vm.widgetPublic.typeTheme === 'light' ||
            _vm.widgetPublic.typeTheme === 'dark' ||
            _vm.widgetPublic.typeTheme === 'individual'
              ? _vm.custom.background_cells_fields
              : '',
        })}),_c(_vm.originData.component,{tag:"components",attrs:{"select-cell":_vm.selectCell,"hash":_vm.service,"settings":_vm.service.BookingType,"slots-validity":_vm.slotsValidity,"custom-style":_vm.custom,"is-theme-widget":_vm.widgetPublic.typeTheme},on:{"isDis":_vm.isDis,"setPrice":_vm.setPrice,"setService":_vm.setService,"setTariff":_vm.setTariff,"setTickets":_vm.setTickets,"setHours":_vm.setHours,"setPlaces":_vm.setPlaces,"setDiscount":_vm.setDiscount,"setRent":_vm.setRent,"setRentInfo":_vm.setRentInfo,"SetForGroup":_vm.setForGroup,"setSlotParam":_vm.getSlotParam,"isLoadingEmit":_vm.isLoadingEmit},model:{value:(_vm.person),callback:function ($$v) {_vm.person=$$v},expression:"person"}}),(_vm.showHeaderAddOptions)?_c('div',{staticClass:"title-widget",style:({ color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '' })},[_vm._v(" Дополнительные опции ")]):_vm._e(),_c('div',{staticClass:"bk-full"},[_c('el-checkbox-group',{model:{value:(_vm.addOptCheck),callback:function ($$v) {_vm.addOptCheck=$$v},expression:"addOptCheck"}},_vm._l((_vm.listAddOptCheck),function(item,i){return _c('el-checkbox',{key:("addOptCheck-" + i),class:[
              {
                'custom-style':
                  _vm.widgetPublic.typeTheme === 'light' ||
                  _vm.widgetPublic.typeTheme === 'dark' ||
                  _vm.widgetPublic.typeTheme === 'individual',
              },
              {
                'single-grid':
                  _vm.widgetPublic.typeTheme === 'light' ||
                  _vm.widgetPublic.typeTheme === 'dark' ||
                  _vm.widgetPublic.typeTheme === 'individual',
              } ],attrs:{"label":item}},[_c('span',{staticClass:"custom-check__title",style:({
                color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
              })},[_vm._v(_vm._s(item.name))]),(item.price)?_c('span',{staticClass:"custom-check__subtitle",style:({
                color: _vm.isIndividualTheme ? _vm.custom.inactive_text_color : '',
              })},[_vm._v(" "+_vm._s(item.price)+" "+_vm._s(_vm.selectCell.currency)+" ")]):_vm._e()])}),1),_vm._l((_vm.addOptInput),function(item,i){return _c('div',{key:("input-" + i),staticClass:"input-number",style:({
            color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
          }),attrs:{"label":1}},[_c('div',{staticClass:"input-number__info"},[_c('span',{staticClass:"input-number__info-title",style:({
                color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
              })},[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"input-number__info-subtitle",style:({
                color: _vm.isIndividualTheme ? _vm.custom.inactive_text_color : '',
              })},[_vm._v(" "+_vm._s(item.price ? ((item.price) + " ") : "Бесплатно")+" "+_vm._s(_vm.selectCell.currency)+" ")])]),_c('el-input-number',{staticClass:"input-number__input",class:[
              {
                'custom-style':
                  _vm.widgetPublic.typeTheme === 'light' ||
                  _vm.widgetPublic.typeTheme === 'dark' ||
                  _vm.widgetPublic.typeTheme === 'individual',
              },
              {
                'single-grid':
                  _vm.widgetPublic.typeTheme === 'light' ||
                  _vm.widgetPublic.typeTheme === 'dark' ||
                  _vm.widgetPublic.typeTheme === 'individual',
              } ],style:({
              background:
                _vm.widgetPublic.typeTheme === 'light' ||
                _vm.widgetPublic.typeTheme === 'dark' ||
                _vm.widgetPublic.typeTheme === 'individual'
                  ? _vm.custom.background_cells_fields
                  : '',
            }),attrs:{"max":item.amount,"min":0},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})],1)}),(_vm.slotsValidity)?_c('div',{staticClass:"my-10"},[(_vm.slotsValidity.msg)?_c('el-alert',{staticClass:"w-full",attrs:{"title":_vm.slotsValidity.msg,"type":"warning","closable":false,"center":""}}):_vm._e()],1):_vm._e()],2)],1)],1):_vm._e(),(_vm.isShowPay)?_c('el-dialog',{attrs:{"visible":_vm.isShowPay,"show-close":false,"width":"450px","before-close":_vm.reloadWidget},on:{"update:visible":function($event){_vm.isShowPay=$event},"reloadWidget":function($event){_vm.isShowPay = false}}},[(_vm.dataPay.success)?_c('PayWidget',{attrs:{"widget-theme":_vm.widgetPublic.typeTheme,"custom-class":_vm.custom},on:{"close":function($event){_vm.isShowPay = false},"reloadWidget":_vm.reloadWidget}}):_vm._e(),(!_vm.dataPay.success)?_c('PayError',{attrs:{"data-pay":_vm.dataPay.params,"widget-theme":_vm.widgetPublic.typeTheme,"custom-class":_vm.custom,"error-title":_vm.dataPay.params ? _vm.dataPay.params.title : _vm.dataPay.title,"msg":_vm.errorMessage},on:{"close":function($event){_vm.isShowPay = false}}}):_vm._e()],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }