<template>
  <svg :class="svgClass" aria-hidden="true" :width="width" :height="height">
    <use :xlink:href="name"></use>
  </svg>
</template>
<script>
const requireAll = (requireContext) =>
  requireContext.keys().map(requireContext);
const req = require.context("../svg-icons", false, /\.svg$/);
requireAll(req);

export default {
  name: "SvgIcon",
  props: {
    iconName: {
      // icon filename
      type: String,
      required: true,
    },
    className: {
      // css class name
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "20",
    },
    height: {
      type: String,
      default: "20",
    },
  },
  computed: {
    name() {
      const icon = this.iconName;
      return icon ? `#icon-${icon}` : "";
    },
    svgClass() {
      const { className } = this;
      return className ? `svg-icon ${className}` : "svg-icon";
    },
  },
};
</script>

<style>
.svg-icon {
  fill: currentColor; /* important */
}
</style>
