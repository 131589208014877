import { setField, setFieldObj } from "@/helpers/store";
import { AJAX_GET, AJAX_DELETE, AJAX_POST, AJAX_PATCH } from "@/units/ajax";

export default {
  namespaced: true,
  state: {
    company: {
      company_logo: null,
      company_name: "",
      kind_of_activity: [],
      short_description: "",
      detail_description: "",
      cover: null,
      company_photos: [],

      video_name: "",
      video_link: "",
      video_preview: null,

      tel_req: "",
      tel: [],

      email_req: "",
      email: [],

      messengers: [],
      socials: [],

      address: [],
    },
    listReq: [],
    companyList: [],
    typesActivity: [],
  },
  getters: {
    isNewCompany: (state) => !state.company.hash,
    photosActual: (state) =>
      state.company.company_photos.filter((image) => image.src),
    getCategory: (state) => (arrayId) =>
      state.typesActivity
        .filter((type) => arrayId.includes(type.id))
        .map((res) => res.name)
        .join(", "),
    activityCompany: (state) =>
      state.typesActivity
        .filter((activity) =>
          state.company.kind_of_activity.includes(activity.id)
        )
        .map((item) => item.name)
        .join(", "),
    addressCompany: (state) =>
      state.company.address.length ? state.company.address[0].address_name : "",
  },
  mutations: {
    setField,
    setFieldObj,
    addReq(state, data) {
      state.listReq.push(data);
    },
    setDataCompany(state, { field, value }) {
      state.company[field] = value;
    },
  },
  actions: {
    async getCompany({ commit }) {
      try {
        const { data } = await AJAX_GET("/api/v1/getCompanyByOwnerId");
        if (!data.hash) return;
        commit("setField", {
          field: "company",
          value: data,
        });
      } catch (err) {
        console.log(err);
        return err;
      }
    },
    async getCompanyList({ commit }) {
      try {
        const { data } = await AJAX_GET("/api/v1/ApiCompany");
        commit("setField", {
          field: "companyList",
          value: Object.values(data),
        });
      } catch (err) {
        console.log(err);
      }
    },
    async addCompany({ state, commit }) {
      try {
        const { data } = await AJAX_POST(`/api/v1/ApiCompany`, {
          ...state.company,
        });
        commit("setField", {
          field: "company",
          value: data.data,
        });
        commit(
          "setNotif",
          {
            title: data.msg,
          },
          { root: true }
        );
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при сохранении",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async getInfoCompany({ commit }, { id }) {
      try {
        const { data } = await AJAX_GET(`/api/v1/ApiCompany/${id}`);
        commit("setField", {
          field: "company",
          value: data,
        });
      } catch (err) {
        console.log(err);
      }
    },
    async editInfoCompany({ state, commit, dispatch }, type) {
      const data = {};
      if (type === "info") {
        data.company_logo = state.company.company_logo;
        data.company_name = state.company.company_name;
        data.kind_of_activity = state.company.kind_of_activity;
        data.short_description = state.company.short_description;
        data.detail_description = state.company.detail_description;
        data.cover = state.company.cover;
        data.company_photos = state.company.company_photos;
        data.video_name = state.company.video_name;
        data.video_link = state.company.video_link;
        data.video_preview = state.company.video_preview;
        data.block = 1;
      } else if (type === "contact") {
        data.tel_req = state.company.tel_req;
        data.tel = state.company.tel.filter((tel) => !!tel);
        data.email_req = state.company.email_req;
        data.email = state.company.email.filter((email) => !!email);
        data.whatsapp = state.company.whatsapp;
        data.viber = state.company.viber;
        data.telegram = state.company.telegram;
        data.facebook_messenger = state.company.facebook_messenger;
        data.vk = state.company.vk;
        data.instagram = state.company.instagram;
        data.facebook = state.company.facebook;
        data.messengers = state.company.messengers;
        data.socials = state.company.socials;
        data.block = 2;
      } else if (type === "address") {
        data.address = state.company.address;
        data.block = 3;
      }

      try {
        const resp = await AJAX_PATCH(
          `/api/v1/ApiCompany/${state.company.hash}`,
          {
            ...data,
          }
        );
        if (data.block === 3) dispatch("user/getInfo", null, { root: true });
        commit(
          "setNotif",
          {
            title: resp.data.msg,
          },
          { root: true }
        );
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при сохранении",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async deleteCompany({ dispatch }, { id }) {
      try {
        await AJAX_DELETE(`/api/v1/ApiCompany/${id}`);
        dispatch("getCompanyList");
      } catch (err) {
        console.log(err);
      }
    },
    async getTypesActivity({ state, commit }) {
      try {
        if (state.typesActivity.length) return;
        const { data } = await AJAX_GET(`/api/v1/getActivities`, {
          noCancel: true,
        });
        commit("setField", {
          field: "typesActivity",
          value: data,
        });
      } catch (err) {
        console.log(err);
      }
    },
  },
};
