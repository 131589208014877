<template>
  <el-col :span="$mq.tablet ? mq.mobile : mq.desktop" class="lg:mb-15">
    <label class="text-12 font-medium text-white-normal flex flex-col">
      <span class="mb-7">{{ text.label }}</span>
      <el-select
        v-model="selected"
        :placeholder="text.placeholder"
        multiple
        value-key="label"
        @change="changeSelect"
      >
        <el-option
          v-for="item in items"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </label>
  </el-col>
</template>

<script>
export default {
  props: {
    text: Object,
    items: Array,
    mq: {
      type: Object,
      default: () => ({
        mobile: 24,
        desktop: 6,
      }),
    },
  },
  data() {
    return {
      selected: null,
    };
  },

  methods: {
    changeSelect() {
      this.$emit("selected", this.selected);
    },
  },
};
</script>
