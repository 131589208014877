<template>
  <div v-loading="scheduleIsLoading || isLoad" class="schedule">
    <div v-for="(item, i) in schedule" :key="i" class="schedule__item">
      <div class="schedule__item-name">
        <el-popover
          class="dots my-8"
          placement="bottom-start"
          popper-class="control-poper"
          trigger="click"
        >
          <span slot="reference">
            <svg-icon
              icon-name="dots"
              class-name="text-white-500"
              width="20"
              height="30"
            ></svg-icon>
          </span>
          <div class="services-drag__wrapper">
            <router-link
              :to="{
                name: 'resource',
                params: { id: item.id, hash: item.ids },
                query: { tab: 'schedule' },
              }"
              class="services-drag__item-icon"
            >
              <div class="wrapper-icon">
                <div
                  v-if="!isResourceBull(user.resources, item.ids).schedule"
                  class="wrapper-icon__point"
                ></div>
                <svg-icon icon-name="eye" width="20" height="14"></svg-icon>
              </div>
              <span>Расписание </span>
            </router-link>

            <router-link
              :to="{
                name: 'resource',
                params: { id: item.id, hash: item.ids },
                query: { tab: 'description' },
              }"
              class="services-drag__item-icon"
            >
              <div class="wrapper-icon">
                <svg-icon
                  icon-name="warning-outline"
                  width="20"
                  height="20"
                ></svg-icon>
                <!-- <div class="wrapper-icon__point"></div> -->
              </div>

              <span>Описание</span>
            </router-link>

            <router-link
              :to="{
                name: 'resource',
                params: { id: item.id, hash: item.ids },
                query: { tab: 'datetime' },
              }"
              class="services-drag__item-icon"
              ><div class="wrapper-icon">
                <div
                  v-if="!isResourceBull(user.resources, item.ids).schedule"
                  class="wrapper-icon__point"
                ></div>
                <svg-icon
                  icon-name="datetime"
                  width="20"
                  height="16"
                ></svg-icon>
              </div>
              <span>Даты и цены</span>
            </router-link>

            <router-link
              :to="{
                name: 'resource',
                params: { id: item.id, hash: item.ids },
                query: { tab: 'settings' },
              }"
              class="services-drag__item-icon"
            >
              <svg-icon icon-name="settings" width="22" height="20"></svg-icon>
              <span>Настройки</span>
            </router-link>

            <router-link
              :to="{
                name: 'resource',
                params: { id: item.id, hash: item.ids },
                query: { tab: 'notifications' },
              }"
              class="services-drag__item-icon"
              ><div class="wrapper-icon">
                <div
                  v-if="!isResourceBull(user.resources, item.ids).notifications"
                  class="wrapper-icon__point"
                ></div>
                <svg-icon icon-name="news2" width="22" height="21"></svg-icon>
              </div>
              <span>Уведомления</span>
            </router-link>

            <router-link
              :to="{
                name: 'resource',
                params: { id: item.id, hash: item.ids },
                query: { tab: 'integration' },
              }"
              class="services-drag__item-icon"
              ><div class="wrapper-icon">
                <div
                  v-if="!isResourceBull(user.resources, item.ids).widgets"
                  class="wrapper-icon__point"
                ></div>
                <svg-icon
                  icon-name="integration"
                  width="22"
                  height="16"
                ></svg-icon>
              </div>
              <span>Виджеты</span>
            </router-link>
          </div>
        </el-popover>
        <span class="schedule__item-title"> {{ item.name }}</span>
      </div>
      <div class="mb-10">
        <div v-if="relatedServices(item).name" class="inline-flex items-center">
          <svg-icon icon-name="overlap" width="20" height="20"></svg-icon>
          <span class="items__bind"
            >{{
              relatedServices(item).relat === "child"
                ? "Дочерняя услуга:"
                : "Родительская услуга:"
            }}
            <span>
              <span
                v-for="(serv, idx) in relatedServices(item).name"
                :key="serv.id"
              >
                <router-link
                  class="services-drag__item-icon inline-flex underline px-5"
                  :to="{
                    name: 'resource',
                    params: { hash: serv.hash },
                    query: { tab: 'schedule' },
                  }"
                  target="_blank"
                  >{{ serv.name
                  }}{{
                    relatedServices(item).name.length - 1 === idx ? " " : ","
                  }}</router-link
                ></span
              ></span
            >
          </span>
        </div>
      </div>
      <div class="schedule__item-schedule">
        <div v-if="!!item.msg[0]" class="custom-alert not-set-schedule">
          {{ item.msg[0] }}
          <!-- Расписание на этот день не задано -->
        </div>
        <div
          v-for="(slot, index) in item.slots"
          :key="index"
          class="schedule-item text-center cell relative mr-7 flex flex-col items-center mb-17"
          :class="{
            disabledSlot: disabledTime(slot),
            pointsSlot:
              slot ||
              slot.clientHash ||
              slot.bookingCount ||
              slot.is_blocked ||
              slot.booking_hash,
          }"
        >
          <div v-if="!item.msg[0]">
            <SlotStatus
              :item="slot"
              :data="dateSlide"
              :dis="disabledTime(slot)"
              :name-service="item.name"
              :hash="item.ids"
              @openForm="openFormInSlot"
            />

            <!-- <div
              :class="[
                'schedule__item-cell__time',
                { disabledSlot: slot.block === true },
              ]"
              @click="onReserveSlot(item, slot)"
            > -->
            <div>
              <!-- <span :class="{ test: slot.booking_hash }"
                >{{ slot.booking_hash }} {{ index }}</span
              > -->
              <div
                class="cell__time bg-white-150 text-white-normal flex items-center justify-center font-semibold w-65 h-35 rounded-sm mb-5 cursor-pointer"
                :class="{ disabledSlotTime: slot.active === false }"
              >
                {{ slot.time }}
              </div>
              <span class="cell__price text-white-500 text-12"
                >{{ slot.price }} {{ slot.currency.toUpperCase() }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title="Бронирование услуги"
      :visible.sync="reserveSlot.state"
      width="100%"
      :show-close="false"
      custom-class="reserve-slot-form bg-gray-1100"
      @closed="reserveSlot.data = null"
    >
      <div
        slot="title"
        class="absolute fast-add-close"
        @click="reserveSlot.state = false"
      >
        <div class="cursor-pointer">
          <svg-icon
            icon-name="close"
            width="12"
            height="12"
            class-name="text-white-normal"
          ></svg-icon>
        </div>
      </div>
      <form-reserve
        :data="{ resource, slot: reserveSlot.data }"
        @onReserve="reserveSlot.state = false"
      ></form-reserve>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import FormReserve from "@/components/FormReserve.vue";
import SlotStatus from "@/components/Schedule/SlotStatus.vue";
import moment from "moment";
import { setField, setFieldObj } from "@/helpers/store";

export default {
  name: "AppSchedule",
  components: { FormReserve, SlotStatus },
  props: {
    dateSlide: String,
  },
  data() {
    return {
      isLoad: false,
      reserveSlot: {
        state: false,
        data: null,
      },
    };
  },

  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("resource", {
      resource: "resource",
    }),
    ...mapGetters("schedule", {
      schedule: "schedule",
      scheduleIsLoading: "scheduleIsLoading",
    }),
  },

  methods: {
    ...mapActions("service", [
      "getResourceSlots",
      "getSettings",
      // "getScheduleAdminCreate",
    ]),
    ...mapActions("widget", ["getSlotDetails"]),
    ...mapActions({ getScheduleCalendar: "schedule/getScheduleCalendar" }),

    relatedServices(item) {
      const searchRes = this.user.resources.find(
        (res) => res.hash === item.ids
      );
      const obj = {};

      if (searchRes.parent_data.length) {
        obj.name = searchRes.parent_data.map((e) => e);
        obj.relat = "parent";
      } else if (searchRes.children_data.length) {
        obj.name = searchRes.children_data.map((e) => e);
        obj.relat = "child";
      }
      return obj;
    },

    isResourceBull(param, id) {
      const bull = param.find((element) => element.hash === id).is_filled;
      return bull;
    },
    disabledTime(item) {
      // if (!item.active) return true;
      if (
        moment(this.dateSlide).format("YYYY-DD-MM") ===
        moment().format("YYYY-DD-MM")
      ) {
        return moment(item.time, "HH:mm") < moment();
      }
      if (
        moment(this.dateSlide).format("YYYY-DD-MM") <
          moment().format("YYYY-DD-MM") &&
        moment(this.dateSlide).format("MM") <= moment().format("MM")
      ) {
        return true;
      }
    },

    async openFormInSlot(status) {
      this.isLoad = true;
      await this.getSettings({
        hash: status.hash,
        id: status.item.resource_id,
      });
      await this.getSlotDetails({
        id: status.hash,
        date: this.dateSlide,
        time: status.item.time,
        bookingHash:
          status.item.booking_type_sub !== "rent_depends_on_time_and_place"
            ? status.item.booking_hash
            : null,
      });
      await this.getScheduleCalendar({
        hash: status.hash,
        withSlots: true,
        noClosed: false,
      });
      this.isLoad = false;
      this.$emit("openFormSchedul", status);
      this.$store.commit("service/setField", {
        field: "freeTimeDate",
        value: this.dateSlide,
      });
      this.$store.commit("widget/setField", { field: "errorMsg", value: "" });
    },
    onReserveSlot(item, slot) {
      this.reserveSlot.state = true;
      this.reserveSlot.data = { item, slot };
    },
    onCloseReserveSlot() {
      this.reserveSlot.state = false;
      this.reserveSlot.data = null;
    },
  },
};
</script>

<style lang="scss">
@import "element-variables.scss";
@media (max-width: 456px) {
  .schedule {
    width: 100%;
    &__item {
      font-size: 12px !important;
      width: 100%;
      margin: auto;
      &-schedule {
        // justify-content: center;
      }
      &-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
  .custom-alert {
    font-size: 12px !important;
  }
}
.schedule {
  &__item {
    display: flex;
    flex-direction: column;
    margin-bottom: 18px;
    &-name {
      display: flex;
      align-items: center;
      color: $--color-white;
      font-weight: 600;
      font-size: 18px;
      // margin-bottom: 20px;

      @screen ssm {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .dots {
        margin-right: 10px;
      }
    }
    &-schedule {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
    }
  }
}

.reserve-slot-form {
  max-width: 670px;
}

.services-drag {
  &__wrapper {
    padding: 5px 0;
  }
  &__item-icon {
    display: flex;
    align-items: center;
    padding: 5px 15px;
    cursor: pointer;
    svg {
      color: $--color-white-5;
    }
    span {
      margin-left: 10px;
      color: $--color-white;
      font-size: 14px;
      line-height: 24px;
    }
  }
}
.wrapper-icon {
  position: relative;
  &__point {
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    width: 7px;
    height: 7px;
    border-radius: 50%;
  }
}
.disabledSlot .cell__time {
  background: rgba(51, 14, 71, 0.9);
}
.disabledSlotTime {
  color: $--color-white-5 !important;
}
.pointsSlot {
  pointer-events: auto !important;
}
.items__bind {
  color: rgba(255, 255, 255, 0.5);
  padding: 0 10px;
}
</style>
