var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"slot":"reference"},slot:"reference"},[_c('el-popover',{staticClass:"dots my-8",attrs:{"placement":"bottom-start","popper-class":"control-poper","trigger":"click"}},[_c('div',{staticClass:"block"},[_c('h2',{staticClass:"block__text"},[_vm._v("Оплата на месте")]),_c('el-input',{attrs:{"placeholder":"","type":"number"},model:{value:(_vm.prePayValue),callback:function ($$v) {_vm.prePayValue=$$v},expression:"prePayValue"}}),_c('el-button',{staticClass:"block__btn",attrs:{"slot":"reference"},on:{"click":_vm.addPrePay},slot:"reference"},[_vm._v("Сохранить ")])],1),_c('div',{staticClass:"popover__wrapp flex",attrs:{"slot":"reference"},slot:"reference"},[_c('div',{staticClass:"slot__wrapp"},[_c('span',{class:[
            _vm.row.payment ? 'small_text' : 'text-12 table_section__label' ]},[_vm._v("На месте ")])]),(_vm.row.payment)?_c('div',{class:[
          _vm.row.paid_on_place_summ === 0
            ? 'small_text'
            : 'text-12 table_section__label' ]},[_c('div',{staticClass:"flex items-center"},[(_vm.payData.type === 'payment_locally')?_c('span',[_c('span',{staticClass:"dashed",class:{
                'text-gray-500 text-14': _vm.payData.type === 'payment_locally',
              }},[_vm._v(" "+_vm._s(_vm.row.payment ? _vm.payData.paid_on_place : _vm.row.full_amount)+" "+_vm._s(_vm.row.currency))])]):_vm._e(),(_vm.payData.type === 'prepayment')?_c('span',{staticClass:"mr-10"},[_c('span',{staticClass:"dashed",class:{
                'text-gray-500 text-14': _vm.payData.type === 'prepayment',
              }},[_vm._v(_vm._s(_vm.prePay > 0 ? _vm.prePay : _vm.payData.full_amount - _vm.payData.prepayment)+" "+_vm._s(_vm.row.currency))])]):_vm._e(),(_vm.payData.type === 'full_payment')?_c('span',{class:[_vm.prePay === 0 ? 'small_text' : 'text-gray-500 text-14']},[_c('span',{staticClass:"dashed pr-5"},[_vm._v(_vm._s(_vm.prePay)+" "+_vm._s(_vm.row.currency))])]):_vm._e()])]):_vm._e(),_c('div',{},[(_vm.row.type === 'payment_locally')?_c('span',{},[_c('span',{staticClass:"dashed",class:{
              'text-gray-500 text-14': _vm.row.type === 'payment_locally',
            }},[_vm._v(" "+_vm._s(_vm.row.paid_on_place ? _vm.row.paid_on_place : _vm.row.full_amount)+" "+_vm._s(_vm.row.currency))])]):_vm._e(),(_vm.row.type === 'full_payment')?_c('span',{class:[_vm.prePay === 0 ? 'small_text' : 'text-gray-500']},[_c('span',{staticClass:"dashed"},[_vm._v(_vm._s(_vm.prePay)+" "+_vm._s(_vm.row.currency)+" ")])]):_vm._e(),_c('span')]),_c('div',{},[(_vm.row.type !== 'payment_locally')?_c('span',{},[_c('span',{staticClass:"dashed",class:{
              'text-gray-500 text-14': _vm.row.type === 'payment_locally',
            }},[_vm._v(" "+_vm._s(_vm.row.paid_on_place >= 0 ? _vm.row.paid_on_place : _vm.row.full_amount)+" ")])]):_vm._e()])]),(_vm.row.payment)?_c('div',{staticClass:"popover__wrapp",attrs:{"slot":"reference"},slot:"reference"},[_c('div',{staticClass:"slot__wrapp flex"},[(_vm.paym.payment_info.refunds)?_c('div',{staticClass:"flex"},[_c('span',{staticClass:"small_text"},[_vm._v("Возврат")]),_c('span',{staticClass:"text-yellow-100"},[_vm._v(_vm._s(_vm.refundVal)+" "+_vm._s(_vm.row.currency))])]):_vm._e()])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }