<template>
  <div>
    <div class="text-14 leading-28 mb-10 mt-auto">Текст СМС</div>
    <div
      :class="{ textarea_disable: typeText === 0 }"
      class="textarea_sms textarea_default"
    >
      <div class="overlap"></div>
      <el-input
        ref="sms"
        v-model="item"
        type="textarea"
        :disabled="typeText === 0"
        resize="none"
        class="bg-transparent code-textarea"
        autosize
        placeholder="Текст..."
      ></el-input>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    typeText: Number,
    value: String,
    defaultParam: String,
  },
  computed: {
    item: {
      get() {
        return this.value;
      },
      set(e) {
        this.$emit("input", e);
      },
    },
  },
  watch: {
    typeText() {
      if (this.typeText === 0) {
        this.item = this.defaultParam;
      }
    },
  },
};
</script>
