var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pt-40 pb-80 w-full lg:pt-15 px-10"},[_c('Top',{attrs:{"title":"Уведомления","font":{ size: 22, leading: 22 }}}),(!_vm.info || !_vm.defaultInfo)?_c('spinner'):_c('el-collapse',{on:{"change":_vm.changeCollapse}},[_c('BlockCollapse',{attrs:{"title":"Настройки SMTP","name":"settings","sub-title":"Позволяет настроить отправку писем с вашего электронного адреса, а также использовать в шаблоне ваш логотип, ссылки на социальные сети."}},[_c('Settings',{ref:"settings"})],1),_c('BlockCollapse',{attrs:{"title":"Переменные шаблона","name":"variables","sub-title":"Шаблоны доступны для редактирования только при наличии\n        премиум-аккаунта, в противном случае текст уведомления остается\n        стандартным."}},[_c('Variables',{ref:"variables"})],1),_c('BlockCollapse',{attrs:{"title":"Новое бронирование","name":"booking","sub-title":"При необходимости вы можете изменить шаблон письма для пользователя\n          о бронировании. Отредактируйте тему и текст письма, используя\n          описанные выше переменные"}},[_c('Booking',{ref:"booking",attrs:{"type":"new_reserve","items":{
          admin: _vm.info.new_reserve_admin,
          user: _vm.info.new_reserve_user,
        },"default-items":{
          admin: _vm.defaultInfo.new_reserve_admin,
          user: _vm.defaultInfo.new_reserve_user,
        },"loading":{
          admin: _vm.loading.new_reserve_admin,
          user: _vm.loading.new_reserve_user,
        }},on:{"save":_vm.save}})],1),_c('BlockCollapse',{attrs:{"title":"Новое бронирование с оплатой","name":"bookingPay","sub-title":"При необходимости вы можете изменить шаблон письма для пользователя\n          о бронировании. Отредактируйте тему и текст письма, используя\n          описанные выше переменные"}},[_c('Booking',{ref:"bookingPay",attrs:{"type":"new_prepayment","items":{
          admin: _vm.info.new_prepayment_admin,
          user: _vm.info.new_prepayment_user,
        },"default-items":{
          admin: _vm.defaultInfo.new_prepayment_admin,
          user: _vm.defaultInfo.new_prepayment_user,
        },"loading":{
          admin: _vm.loading.new_prepayment_admin,
          user: _vm.loading.new_prepayment_user,
        }},on:{"save":_vm.save}})],1),_c('BlockCollapse',{attrs:{"title":"Отмена бронирования","name":"cancel","sub-title":"При необходимости вы можете изменить шаблоны уведомлений об отмене бронирования для пользователя и сотрудников."}},[_c('Cancel',{ref:"cancel",attrs:{"items":{
          admin: _vm.info.cancel_reserve_admin,
          user: _vm.info.cancel_reserve_user,
        },"default-items":{
          admin: _vm.defaultInfo.cancel_reserve_admin,
          user: _vm.defaultInfo.cancel_reserve_user,
        },"loading":{
          admin: _vm.loading.cancel_reserve_admin,
          user: _vm.loading.cancel_reserve_user,
        }},on:{"save":_vm.save}})],1),_c('BlockCollapse',{attrs:{"title":"Изменение бронирования","name":"carryover","sub-title":"При необходимости вы можете изменить шаблоны уведомлений об изменении бронирований для пользователя и сотрудников."}},[_c('CarryOver',{ref:"carryover",attrs:{"items":{
          admin: _vm.info.shift_reserve_admin,
          user: _vm.info.shift_reserve_user,
        },"default-items":{
          admin: _vm.defaultInfo.shift_reserve_admin,
          user: _vm.defaultInfo.shift_reserve_user,
        },"loading":{
          admin: _vm.loading.shift_reserve_admin,
          user: _vm.loading.shift_reserve_user,
        }},on:{"save":_vm.save}})],1),_c('BlockCollapse',{attrs:{"title":"Напоминание о бронировании","name":"reminder","sub-title":"При необходимости вы можете изменить шаблоны напоминания о бронировании для пользователя и сотрудников."}},[_c('Reminder',{ref:"reminder",attrs:{"items":{
          admin: _vm.info.booking_reminder_admin,
          user: _vm.info.booking_reminder_user,
        },"default-items":{
          admin: _vm.defaultInfo.booking_reminder_admin,
          user: _vm.defaultInfo.booking_reminder_user,
        },"loading":{
          admin: _vm.loading.booking_reminder_admin,
          user: _vm.loading.booking_reminder_user,
        }},on:{"save":_vm.save}})],1),_c('BlockCollapse',{attrs:{"title":"Текст в модальном окне при успешном бронировании","name":"modal","sub-title":"Вы можете изменить содержимое модального окна, которое увидит пользователь после создания заказа в виджете бронирования."}},[_c('Modal',{ref:"modal",attrs:{"is-modal":"","items":_vm.info.modal,"default-items":_vm.defaultInfo.modal,"loading":_vm.loading.modal},on:{"save":_vm.save}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }