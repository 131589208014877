<template>
  <div class="relative">
    <spinner v-if="isLoading" class="table__spinner" />
    <el-table
      :data="[{}, {}, {}, {}]"
      class="border-b-0 mb-20"
      sortable
      :stripe="true"
    >
      <el-table-column prop="id" label="№" sortable :min-width="62">
        1
      </el-table-column>
      <el-table-column
        prop="sum"
        sortable
        label="Дата"
        class="date"
        :min-width="114"
      >
        <div>
          24.02.2000
          <span class="small_text">14:20</span>
        </div>
      </el-table-column>
      <el-table-column
        prop="phone"
        label="Пользователь"
        sortable
        :min-width="179"
      >
        Дима
      </el-table-column>
      <el-table-column prop="phone" label="№ брони" sortable :min-width="107">
        <router-link to="/" class="dashed">23</router-link>
      </el-table-column>
      <el-table-column
        prop="sum"
        class="sender"
        label="Услуга"
        :min-width="223"
      >
        Обзорная экскурсия «История и пространство Древнего Пскова»
      </el-table-column>
      <el-table-column
        prop="sum"
        sortable
        label="Дата события"
        class="date"
        :min-width="144"
      >
        <div>
          24.02.2000
          <!-- <span class="small_text">14:20</span> -->
        </div>
      </el-table-column>
      <el-table-column
        prop="sum"
        sortable
        label="Сеанс"
        class="date"
        :min-width="85"
      >
        <div>
          22:00
          <!-- <span class="small_text">14:20</span> -->
        </div>
      </el-table-column>
      <el-table-column prop="text" label="Клиент" :min-width="231">
        Сергей Иванов
        <span class="small_text"> +7 (902) 342-00-98 </span>
        <span class="small_text">example@mail.ru</span>
      </el-table-column>
      <el-table-column prop="text" label="Действие" :min-width="167">
        Заказ создан
      </el-table-column>
      <el-table-column prop="status" label="Детали" :min-width="170">
        <el-button class="btn_details" @click="$emit('showDetails')">
          Смотреть детали
        </el-button>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    tableName: String,
    type: {
      type: Object,
      default: () => ({
        code: "online",
        text: "Оплата онлайн",
      }),
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
};
</script>

<style lang="scss" scoped>
.btn_details {
  font-size: 12px;
  max-width: 100%;
  width: 139px;
  height: 30px;
  padding: 0;
}
</style>
