var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"list-fields relative"},[(_vm.isManual && _vm.dayWeek >= 2)?_c('div',{staticClass:"flex justify-center my-10"},[_c('el-button',{staticClass:"list-fields__add-button text-12",on:{"click":function($event){return _vm.copyForMonday('last')}}},[_vm._v(" Скопировать с предыдущего дня ")]),_c('el-button',{staticClass:"list-fields__add-button text-12",on:{"click":function($event){return _vm.copyForMonday('mond')}}},[_vm._v(" Скопировать с понедельника ")])],1):_vm._e(),_vm._l((_vm.listField),function(item,index){return _c('div',{key:index,staticClass:"list-fields__row my-10 md:flex-row xs:flex-wrap"},[_c('div',{staticClass:"list-fields__item xs:w-1/2"},[_c('el-form-item',{staticClass:"w-full",attrs:{"prop":"basePrice"}},[_c('div',{staticClass:"flex",attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.headers[0].title)+" "),(_vm.headers[0].valid)?_c('span',{staticClass:"danger"},[_vm._v("*")]):_vm._e(),(_vm.headers[0].popover)?_c('el-popover',{staticClass:"ml-8",attrs:{"placement":"top","width":"200","trigger":"hover","content":_vm.headers[0].popoverText}},[_c('Tooltip',{attrs:{"slot":"reference"},slot:"reference"})],1):_vm._e()],1),(_vm.changePicker(item.time))?_c('el-time-picker',{attrs:{"placeholder":_vm.changePicker(item.time)}}):_vm._e(),(!_vm.changePicker(item.time))?_c('el-time-picker',{attrs:{"format":"HH:mm","placeholder":_vm.headers[0].placeholder,"disabled":_vm.generatorSessions
              ? _vm.generatorSessions.schedule_type === 'bind'
              : false},on:{"input":function($event){return _vm.emitData()}},model:{value:(item.time),callback:function ($$v) {_vm.$set(item, "time", $$v)},expression:"item.time"}}):_vm._e()],1)],1),(!_vm.isServiceBookingType)?_c('div',{staticClass:"list-fields__item"},[_c('el-form-item',{staticClass:"w-full",attrs:{"prop":"basePrice"}},[_c('div',{staticClass:"flex",attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.headers[1].title)+" "),(_vm.headers[1].valid)?_c('span',{staticClass:"danger"},[_vm._v("*")]):_vm._e(),(_vm.headers[1].popover)?_c('el-popover',{staticClass:"ml-8",attrs:{"placement":"top","width":"200","trigger":"hover","content":_vm.headers[1].popoverText}},[_c('Tooltip',{attrs:{"slot":"reference"},slot:"reference"})],1):_vm._e()],1),_c('el-input',{attrs:{"placeholder":"Введите цену"},on:{"input":function($event){return _vm.emitData()}},model:{value:(item.price),callback:function ($$v) {_vm.$set(item, "price", _vm._n($$v))},expression:"item.price"}},[_c('div',{attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v("₽")])])],1)],1):_vm._e(),(!_vm.isServiceBookingType)?_c('div',{staticClass:"list-fields__item"},[_c('el-form-item',{staticClass:"w-full",attrs:{"prop":"basePrice"}},[_c('div',{staticClass:"flex",attrs:{"slot":"label"},slot:"label"},[_vm._v(" "+_vm._s(_vm.headers[2].title)+" "),(_vm.headers[2].valid)?_c('span',{staticClass:"danger"},[_vm._v("*")]):_vm._e(),(_vm.headers[2].popover)?_c('el-popover',{staticClass:"ml-8",attrs:{"placement":"top","width":"200","trigger":"hover","content":_vm.headers[2].popoverText}},[_c('Tooltip',{attrs:{"slot":"reference"},slot:"reference"})],1):_vm._e()],1),_c('el-input',{attrs:{"placeholder":"Введите цену"},on:{"input":function($event){return _vm.emitData()}},model:{value:(item.holidays_price),callback:function ($$v) {_vm.$set(item, "holidays_price", _vm._n($$v))},expression:"item.holidays_price"}},[_c('div',{attrs:{"slot":"suffix"},slot:"suffix"},[_vm._v("₽")])])],1)],1):_vm._e(),(
        _vm.generatorSessions
          ? _vm.listField.length > 1 && _vm.generatorSessions.schedule_type !== 'bind'
          : true
      )?_c('div',{staticClass:"list-fields__actions lg:m-0",style:({
        width:
          (_vm.$mq.largePhonePlusPlus && _vm.$mq.tablet) || _vm.$mq.phoneMobileLargeMax
            ? '100%'
            : 'auto',
      })},[_c('ButtonIcon',{class:(_vm.$mq.largePhonePlusPlus && _vm.$mq.tablet) || _vm.$mq.phoneMobileLargeMax
            ? 'w-max'
            : 'w-auto',attrs:{"icon-name":"close","text":(_vm.$mq.largePhonePlusPlus && _vm.$mq.tablet) || _vm.$mq.phoneMobileLargeMax
            ? 'Удалить время'
            : '',"width":"16"},on:{"click":function($event){return _vm.deleteField(index)}}})],1):_vm._e()])}),(
      _vm.generatorSessions ? _vm.generatorSessions.schedule_type !== 'bind' : true
    )?_c('el-button',{staticClass:"list-fields__add-button",on:{"click":function($event){return _vm.addField()}}},[_vm._v(" "+_vm._s(_vm.titleButton)+" ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }