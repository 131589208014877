<template>
  <!-- <div class="pt-40 pb-80 w-full lg:pt-15 lg:px-15"> -->
  <div class="pt-40 pb-80 w-full lg:pt-15 px-10">
    <!-- <h1
      class="font-semibold text-white-normal text-28 leading-44 lg:text-22 lg:leading22 mb-20 xs:mb-5"
    >
      Расскажите своим друзьям о Broni.biz!
    </h1> -->
    <Top
      title="      Расскажите своим друзьям о Broni.biz!
"
      :font="{ size: 22, leading: 22 }"
    />
    <section class="mb-30">
      <el-row type="flex" class="mb-40 flex-wrap justify-between">
        <el-col
          v-for="(doc, index) in 2"
          :key="index"
          :span="$mq.tablet ? 24 : 11"
          class="bg-white-40 rounded-sm px-30 pt-20 pb-30 mb-20"
        >
          <h2 class="text-18 font-light">
            {{ index % 2 === 0 ? "Вы получите" : "Ваш друг получит" }}
          </h2>
          <div class="hl mx-0"></div>

          <div class="flex items-center">
            <span class="text-46" :class="{ 'text-yellow-100': index !== 1 }"
              >20%</span
            >
            <p class="ml-20">
              {{
                index % 2 === 0
                  ? "на баланс от суммы каждого платежа вашего друга на протяжении первых 6 месяцев"
                  : "от суммы каждого платежа в виде бонуса на баланс в первые 6 месяцев"
              }}
            </p>
          </div>
        </el-col>
      </el-row>
      <div class="bg-white-40 rounded-sm px-30 pt-20 pb-30 mb-20">
        <el-form
          ref="ruleFormInvite"
          :rules="rules"
          :model="{ email }"
          label-position="top"
          :hide-required-asterisk="true"
        >
          <el-form-item class="mb-20" prop="email">
            <el-row type="flex" class="mb-40" :gutter="20">
              <el-col :span="$mq.tablet ? 24 : 24">
                <span class="text-14 w-full">
                  Отправьте вашему другу персональное приглашение на E-mail...
                </span>
                <div
                  class="flex justify-between my-10"
                  :class="$mq.tablet ? 'flex-col' : ''"
                  prop="email"
                >
                  <el-input
                    v-model="email"
                    placeholder="Введите E-mail"
                    class="my-10 pr-20 sm:pr-0"
                  ></el-input>
                  <el-button
                    size="medium"
                    type="primary"
                    class="my-10 h-40"
                    @click="sendEmailReferral"
                  >
                    Отправить приглашение</el-button
                  >
                </div>
              </el-col>
            </el-row>
            <el-row type="" class="mb-40" :gutter="20">
              <div class="my-10">
                <span class="text-14 w-full px-10">
                  ...или поделитесь реферальной ссылкой
                </span>
              </div>
              <el-col :span="$mq.tablet ? 24 : 11">
                <div class="flex justify-between">
                  <el-input
                    v-model="codeReferral"
                    placeholder="Реферальная ссылка"
                    class="my-10 pr-20 sm:pr-0"
                  ></el-input>
                </div>
              </el-col>
              <el-col :span="$mq.tablet ? 24 : 6">
                <el-button class="px-15 lg:w-full my-10" @click="copyCode">
                  <div class="flex items-center justify-center">
                    <svg-icon
                      icon-name="copy"
                      class-name="text-white-normal mr-8"
                      width="16"
                      height="18"
                    ></svg-icon
                    >Скопировать ссылку
                  </div></el-button
                ></el-col
              >
              <el-col :span="$mq.tablet ? 24 : 6"
                ><div v-if="codeInvite" class="flex justify-between">
                  <a
                    v-for="(social, i) in socials"
                    :key="i"
                    :href="`${social.value}` + `${codeReferral}`"
                    class="item__link my-10"
                    :class="['link', `link-${social.name}`]"
                  >
                    <svg-icon :icon-name="social.name"></svg-icon>
                  </a>
                </div>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
        <div class="hl"></div>
        <div class="flex" :class="$mq.tablet ? 'flex-col' : ''">
          <div class="flex sm:mb-10">
            <svg-icon icon-name="person" width="20" height="20"></svg-icon>
            <span v-if="referralInfo" class="px-10 sm:text-12 sm:self-end"
              >Вы пригласили друзей:
              {{ referralInfo.send_count ? referralInfo.send_count : 0 }}
            </span>
          </div>
          <div class="flex sm:mb-10">
            <svg-icon icon-name="gift" width="20" height="20"></svg-icon>
            <span v-if="referralInfo > 0" class="px-10 sm:text-12 sm:self-end"
              >{{ referralInfo.confirmed_count }}
            </span>
            <span class="px-10 sm:text-12 sm:self-end">
              {{
                `
 Друзей зарегистрировалось: ${referralInfo.confirmed_count}
                `
              }}
            </span>
          </div>
        </div>
      </div>
    </section>
    <section class="my-30">
      <div class="my-20">
        <h2 class="text-22 font-normal my-10">Платежи ваших друзей</h2>
        <span
          >Вы заработали:
          <span class="text-yellow-100">{{ referralInfo.payments_sum }}</span>
        </span>
      </div>
      <div class="relative some__class" style="max-width: auto">
        <spinner v-if="isLoading" class="table__spinner" />

        <el-table
          class="max-w-full border-b-0 mb-20 w-full"
          :data="inviteFriendPays"
          :stripe="true"
        >
          <el-table-column label="ID пользователя" :width="200">
            <div slot-scope="{ row }">
              <span>{{ isRefHash(row) }}</span>
            </div>
          </el-table-column>

          <el-table-column
            prop="val"
            label="Сумма оплаты"
            :width="$mq.tablet ? null : 175"
            :min-width="$mq.tablet ? 146 : null"
          >
            <div slot-scope="{ row }">
              <span>{{ row.full_amount }} {{ row.currency }}</span>
            </div>
          </el-table-column>
          <el-table-column
            prop="date"
            :label="$mq.tablet ? 'Дата оплаты' : 'Дата'"
            :width="$mq.tablet ? null : 170"
            :min-width="$mq.tablet ? 80 : null"
          >
            <template slot-scope="{ row }">
              {{ getDate(row.payment_date) }}
            </template>
          </el-table-column>
          <el-table-column
            prop="procent"
            :label="$mq.tablet ? 'Ваше вознаграждение' : 'Ваше вознаграждение'"
            :width="$mq.tablet ? null : 190"
            :min-width="$mq.tablet ? 80 : null"
          >
            <template slot-scope="{ row }">
              <span class="text-yellow-100"
                >{{ row.amount }} {{ row.currency }}</span
              >
            </template>
          </el-table-column>
        </el-table>
        <el-button
          v-if="
            inviteFriendPays.length > 0 &&
            referralHistoryTotal !== inviteFriendPays.length
          "
          :loading="isLoading"
          class="bg-transparent md:mx-15 mr-auto"
          @click="loadMoreInvite"
          >Показать ещё</el-button
        >
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import moment from "moment";
import Top from "@/components/Pages/Top";

export default {
  name: "InviteFriend",
  components: {
    Top,
  },
  data() {
    return {
      isLoading: false,
      email: null,
      inviteFriendPays: [],
      codeReferral: null,
      codeInvite: null,
      socials: [
        {
          id: 0,
          name: "vk",
          link: `${process.env.VUE_APP_PROD_API}`,
          value: `https://vk.com/share.php?url=`,
        },
        {
          id: 1,
          name: "telegram",
          link: `${process.env.VUE_APP_PROD_API}`,
          value: "https://t.me/share/url?url=",
        },
        {
          id: 2,
          name: "whatsapp",
          link: `${process.env.VUE_APP_PROD_API}`,

          value: "https://api.whatsapp.com/send?text=",
        },
        {
          id: 3,
          name: "odnoklassniki",
          link: `${process.env.VUE_APP_PROD_API}`,

          value: "https://connect.ok.ru/offer?url=",
        },
      ],
      rules: {
        email: { required: true, trigger: "blur", type: "email" },
      },
    };
  },
  computed: {
    ...mapState("company", ["company"]),
    ...mapState("invite", [
      "referralHistory",
      "referralHistoryTotal",
      "referralInfo",
    ]),
    ...mapState("user", ["user"]),
  },

  async created() {
    this.isLoading = true;
    await this.getReferralInfo();
    await this.getReferralHistory();
    this.codeInvite = `${this.referralInfo.invite_code}`;
    this.inviteFriendPays = this.referralHistory;
    this.codeReferral = `${this.referralInfo.invite_code_link}`;
    this.isLoading = false;
  },
  mounted() {},
  methods: {
    ...mapActions("invite", [
      "getReferralHistory",
      "sendReferral",
      "getReferralInfo",
    ]),
    ...mapMutations(["setNotif"]),
    isRefHash(param) {
      if (param.referral_user_hash) {
        return param.referral_user_hash;
      }
    },
    async loadMoreInvite() {
      this.isLoading = true;
      const perPage = this.referralHistory.length + 10;
      await this.getReferralHistory(perPage);
      this.isLoading = false;
    },

    sendEmailReferral() {
      this.$refs.ruleFormInvite.validate((valid) => {
        if (valid) {
          this.sendReferral(this.email);
        } else {
          this.setNotif({
            title: `E-mail не валидный`,
            type: "error",
          });
          console.log("error submit!!");
          return false;
        }
      });
    },

    copyCode() {
      navigator.clipboard.writeText(this.codeReferral);
      this.setNotif({
        title: `Реферальная ссылка успешно скопирована`,
      });
    },

    getDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";
.item__link {
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 3px;
  padding: 10px;
}
::v-deep .el-form-item__error {
  display: none;
}
</style>
