<template>
  <div
    class="min-h-full h-auto pt-30 bg-cover bg-no-repeat bg-center"
    :class="
      theme === 'dark'
        ? 'bg-gray-1000'
        : theme === 'white'
        ? 'bg-white-normal'
        : ''
    "
    :style="
      theme === 'custom'
        ? `background-image: url(${widgetBg('transparent-widget-bg.jpeg')})`
        : ''
    "
  >
    <div class="flex items-start">
      <div
        class="flex items-center h-50 justify-center px-40 mr-45 back-btn cursor-pointer rounded-sm"
        :class="
          theme === 'dark'
            ? 'bg-gray-800'
            : theme === 'white'
            ? 'bg-gray-500 text-black'
            : 'bg-white-150'
        "
        @click="goBack"
      >
        <svg-icon
          icon-name="arrow"
          class-name="rotate-180 mr-10"
          width="13"
          height="8"
        ></svg-icon
        >Вернуться назад
      </div>
      <div class="w-full max-w-lg">
        <div
          class="text-28 leading-44 font-semibold mb-40"
          :class="theme === 'white' ? 'text-black' : ''"
        >
          Пример стандартного темного виджета
        </div>
        <div
          class="text-18 leading-30 font-semibold mb-25"
          :class="
            theme === 'dark' || theme === 'custom'
              ? 'text-yellow-100'
              : 'text-violet-500'
          "
        >
          Для одной услуги
        </div>

        <widget-single :ids="$route.query.ids" :theme="$route.query.theme">
        </widget-single>

        <div class="text-18 leading-30 font-semibold text-yellow-100 mb-25">
          Для нескольких услуг
        </div>
        <widget-multiple></widget-multiple>
      </div>
    </div>
  </div>
</template>

<script>
import WidgetSingle from "../components/WidgetSingle";
import WidgetMultiple from "../components/WidgetMultiple";

export default {
  name: "WidgetExample",
  components: { WidgetMultiple, WidgetSingle },
  data() {
    return {
      theme: "dark",
    };
  },
  computed: {},
  mounted() {
    this.theme = this.$route.query.theme;
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    widgetBg(img) {
      return require(`../assets/${img}`);
    },
  },
};
</script>

<style lang="scss">
.back-btn {
  margin-left: 109px;
}
</style>
