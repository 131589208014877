<template>
  <div class="instructions__item">
    <iframe-video
      :show="showVideo"
      :video-id="doc.video_link"
      @close="showVideo = false"
    />

    <div v-if="!onlyButton && doc.video_link" class="instructions__item-video">
      <div class="video__box" @click="showVideo = !showVideo">
        <img
          v-if="doc.video_preview_link"
          class="video__preview"
          :src="doc.video_preview_link"
          alt="video_preview_link"
        />
        <div class="video__content">
          <div class="video__play">
            <svg-icon
              icon-name="big-play"
              width="20"
              height="20"
              class-name="mb-10"
            ></svg-icon>
          </div>
          <div class="video__desc">Смотреть видео</div>
        </div>
      </div>
    </div>
    <div class="instructions__item-text">
      <template v-if="!onlyButton">
        <p v-if="doc.title" class="title">{{ doc.title }}</p>
        <p v-if="doc.description" class="description">
          {{ doc.description }}
        </p>
      </template>
      <a v-if="doc.link" target="_blank" :href="doc.link">
        <el-button class="ml-auto pl-15 pr-15">
          <div class="btn-content flex items-center">
            <svg-icon
              icon-name="docs-thin"
              width="14"
              height="18"
              class-name="mr-10 text-green-400"
            ></svg-icon>
            <span>{{
              doc.buttonText ? doc.buttonText : "Открыть файл PDF"
            }}</span>
          </div>
        </el-button>
      </a>
    </div>
  </div>
</template>

<script>
import IframeVideo from "@/components/IframeVideo";

export default {
  components: {
    IframeVideo,
  },
  props: {
    doc: {
      type: Object,
    },
    onlyButton: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showVideo: false,
    };
  },
};
</script>

<style lang="scss">
@import "element-variables.scss";

.instructions {
  &__item {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 30px;
    &-video {
      border-radius: 3px;
      border: 1px solid rgba(255, 255, 255, 0.2);
      width: 200px;
      min-width: 200px;
      height: 140px;
      margin-right: 20px;
      margin-bottom: 10px;

      .video {
        &__box {
          display: flex;
          height: 100%;
          width: 100%;
          overflow: hidden;
          position: relative;
          cursor: pointer;
        }
        &__preview {
          min-height: 100%;
          min-width: 100%;
        }
        &__content {
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          box-shadow: 0px 1px 2px rgba(19, 38, 66, 0.1);
          background: linear-gradient(
            0deg,
            rgba(0, 0, 0, 0.3),
            rgba(0, 0, 0, 0.3)
          );
        }
        &__play {
          background: #ffbe3d;
          border-radius: 50%;
          margin-bottom: 10px;
          width: 40px;
          height: 40px;
          position: relative;
          svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-40%, -50%);
          }
        }
        &__desc {
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }

    &-text {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;

      .title {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 5px;
        color: $--color-white;
      }
      .description {
        font-size: 12px;
        line-height: 20px;
        margin-bottom: 20px;
        color: $--color-white-5;
      }
    }
  }
}

@screen lg {
  .instructions {
    &__title {
      margin-bottom: 15px;
    }
    &__wrapper {
      flex-direction: column;
    }
    &__item {
      flex-direction: column;
      margin-bottom: 25px;
      width: 100%;
      &-video {
        margin-right: 0;
      }
    }
  }
}
</style>
