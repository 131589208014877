<template>
  <div class="relative">
    <spinner v-if="isLoading" class="table__spinner" />
    <el-table :data="pays" class="border-b-0 mb-20" sortable :stripe="true">
      <el-table-column label="" :min-width="60" :width="60">
        <div
          slot="header"
          class="flex items-center justify-center cursor-pointer"
          @click="showModalSettings = true"
        >
          <svg-icon icon-name="settings" width="18" height="18"></svg-icon>
        </div>
        <template slot-scope="{ row }">
          <div class="table__icon cursor-pointer" @click="onEditReserve(row)">
            <!-- <svg-icon icon-name="edit-2" width="20" height="20"></svg-icon> -->
          </div>
          <div class="table__icon">
            <!-- <svg-icon
              icon-name="time"
              class-name="mt-20"
              width="20"
              height="20"
            ></svg-icon> -->
          </div>
          <TableSettings
            v-if="showModalSettings"
            :show="showModalSettings"
            :columns="columns"
            :item-list="options"
            @close="showModalSettings = false"
            @update="saveTableSettings"
          />
        </template>
      </el-table-column>

      <!-- <el-table-column prop="id" label="" sortable :min-width="62">
        <div
          slot="header"
          class="table__icon cursor-pointer"
          @click="$emit('show', 'settings')"
        >
          <svg-icon icon-name="settings" width="18" height="18"></svg-icon>
        </div>

        <template slot-scope="{ row }">
          <div class="table__icon cursor-pointer" @click="onEditReserve(row)">
            <svg-icon icon-name="edit-2" width="20" height="20"></svg-icon>
          </div>
        </template>
      </el-table-column> -->
      <el-table-column
        v-if="columns.includes(1)"
        type="index"
        :index="indexMethod"
        label="№"
        sortable
        :width="62"
      >
      </el-table-column>

      <el-table-column
        v-if="columns.includes(2)"
        prop="sum"
        sortable
        label="Дата создания"
        class="date"
        :width="149"
      >
        <template slot-scope="{ row }">
          <div>
            <p>#{{ row.hash }}</p>
            <span> {{ getDate(row.created_at) }}</span>
            <span class="small_text">{{ getTime(row.created_at) }} </span>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="columns.includes(3)"
        prop="hash"
        label="№ брони"
        sortable
        :min-width="107"
      >
        <template slot-scope="{ row }">
          <router-link
            :to="{
              path: '/reserves',
              query: {
                text: `${row.booking_hash}`,
              },
            }"
            class="underline"
            >#{{ row.booking_hash }}</router-link
          >
        </template>
      </el-table-column>
      <el-table-column
        v-if="columns.includes(4)"
        prop="sum"
        class="sender"
        label="Тип оплаты"
        :min-width="220"
      >
        <template slot-scope="{ row }">
          <div class="flex flex-col">
            <span> {{ row.type_name }}</span>
            <!-- <span v-if="row.payment_id" class="small_text">
              {{ row.payment_id }}
            </span> -->
            <!-- <div class="table_section">
            <span class="table_section__label">Стоимость</span>
            <span class="">{{ row.full_amount }} {{ row.currency }} </span>
          </div> -->
            <span
              v-if="row.type_name === 'Оплата онлайн' && row.acquiring_id"
              class="text-12"
              >ID Транзакции</span
            >

            <div
              v-if="row.type_name === 'Оплата онлайн' && row.acquiring_id"
              class="flex justify-between mb-18"
            >
              <el-input
                v-model="row.acquiring_id"
                placeholder=""
                class="text-12"
                disabled
              ></el-input>
              <div class="row-link_pay cursor-pointer payment-link__wrap">
                <!-- <a class="cursor-pointer pt-12" @click="copy(row)">
                  <svg-icon width="20" height="20" icon-name="copy"></svg-icon>
                </a> -->
                <ButtonIcon
                  icon-name="copy"
                  width="16"
                  class="ml-15"
                  @click="copy(row.acquiring_id)"
                />
              </div>
            </div>
          </div>
          <!-- <div
            v-if="row.type_name === 'Оплата онлайн'"
            class="table_section-link text-center my-4"
            :class="{
              'table_section-link-disablead': row.status_name === 'Оплачен',
            }"
          >
            <a target="_self" class="py-3">Скопировать ссылку на оплату</a>
          </div> -->
        </template>
      </el-table-column>
      <el-table-column
        v-if="columns.includes(5)"
        prop="text"
        label="Оплата"
        :min-width="210"
      >
        <template slot-scope="{ row }">
          <div class="table_section">
            <span class="table_section__label text-12">К оплате</span>
            <span class="text-12"
              >{{ row.full_amount }} {{ row.currency }}</span
            >
          </div>
          <div class="table_section">
            <span class="table_section__label text-12">Оплачено</span>
            <span class="text-12"
              >{{ row.paid_amount }} {{ row.currency }}
            </span>
          </div>

          <div class="table_section">
            <div class="">
              <PayInPlacePoppup
                :row="row"
                :payment="row"
                class="text-12"
                @setPrePay="$emit('getReload')"
              />
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="columns.includes(6)"
        prop="text"
        label="Статус"
        :min-width="240"
      >
        <template slot-scope="{ row }">
          <span class="current_status">
            <span
              class="icon_rounded"
              :style="{ background: `${row.status_color}` }"
            >
              <!-- <svg-icon
              :width="getStatus(row.status).width"
              :height="getStatus(row.status).height"
              :icon-name="getStatus(row.status).type"
            ></svg-icon> -->
            </span>
            <span> {{ row.status_name }}</span>
          </span>
          <div v-if="row.status === 2 || row.status === 6" class="my-5">
            <el-button class="w-full text-12" @click="dialogVisib(row)"
              >Информация об ошибке</el-button
            >
            <div class="">
              <Modal
                v-if="dialogVisible"
                title="Информация об ошибке"
                :subtitle="errorMessage"
                :show="dialogVisible && !!errorMessage"
                @close="dialogVisible = false"
              />
            </div>
          </div>
          <div v-if="row.status !== 2 && row.status !== 6" class="">
            <span
              v-if="
                row.type_name === 'Оплата онлайн' &&
                row.status_name !== 'Частичный возврат средств' &&
                row.status_name !== 'Оплачен' &&
                row.acquiring_id
              "
              class="text-12"
              >Ссылка на оплату</span
            >

            <div
              v-if="row.type_name === 'Оплата онлайн'"
              class="table_section-link text-center my-4"
              :class="{
                'table_section-link-disablead': row.status_name === 'Оплачен',
              }"
            ></div>
            <div
              v-if="
                row.type_name === 'Оплата онлайн' &&
                row.status_name !== 'Оплачен' &&
                row.payment_link
              "
              class="flex justify-between mb-18"
            >
              <el-input
                v-model="row.payment_link"
                placeholder=""
                class="text-12"
                disabled
              ></el-input>
              <div class="row-link_pay cursor-pointer payment-link__wrap">
                <!-- <a class="cursor-pointer pt-12" @click="copy(row)">
                <svg-icon width="20" height="20" icon-name="copy"></svg-icon>
              </a> -->
                <ButtonIcon
                  icon-name="copy"
                  width="16"
                  class="ml-15"
                  @click="copy(row.payment_link)"
                />
              </div>
            </div>
          </div>
          <div v-else class="text-12">
            <el-button class="w-full text-12" @click="getNewLink(row.hash)">
              Получить новую ссылку
            </el-button>
          </div>
          <div
            v-if="
              row.status_name === 'Оплачен' && row.type !== 'payment_locally'
            "
          >
            <el-button
              v-if="row.status_name === 'Оплачен'"
              @click="openReturnC(row)"
            >
              Оформить возврат
            </el-button>
            <ReturnCash
              v-if="openReturnCash && row.status_name === 'Оплачен'"
              :currency="row.currency"
              :hash="{ hash: qwer.hash }"
              :show="openReturnCash && row.status_name === 'Оплачен'"
              @close="openReturnCash = false"
            />
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="columns.includes(7)"
        prop="status"
        label="Последняя оплата"
        :min-width="150"
      >
        <template slot-scope="{ row }">
          <div v-if="row.payment_date" class="flex flex-col">
            <span>{{ getDate(row.payment_date) }}</span
            ><span class="table_section__label text-12">{{
              getTime(row.payment_date)
            }}</span>
          </div>
          <div
            v-else-if="row.payment_date || row.paid_on_place_date"
            class="flex flex-col"
          >
            <span>{{
              getDate(row.paid_on_place_date || row.payment_date)
            }}</span
            ><span class="table_section__label text-12">{{
              getTime(row.paid_on_place_date || row.payment_date)
            }}</span>
          </div>

          <div v-else class="w-1/2">—</div>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import moment from "moment";
import Modal from "@/components/Modal";
import PayInPlacePoppup from "@/components/PayInPlacePoppup";
import ReturnCash from "@/components/Orders/ReturnCash";
import TableSettings from "@/components/Orders/TableSettings";

export default {
  components: {
    Modal,
    PayInPlacePoppup,
    ReturnCash,
    TableSettings,
  },
  props: {
    pays: {
      type: [Array, Object],
    },
    // data: Object,
    pageSize: {
      type: Number,
    },
    currentPage: {
      type: Number,
    },

    tableName: String,
    // type: {
    //   type: Object,
    //   default: () => ({
    //     code: "online",
    //     text: "Оплата онлайн",
    //   }),
    // },
  },
  data() {
    return {
      popover: {
        show: true,
      },
      columns: [1, 2, 3, 4, 5, 6, 7],
      options: [
        { id: 1, title: "№" },
        { id: 2, title: "Дата создания" },
        { id: 3, title: "№ бронировани" },
        { id: 4, title: "Тип оплаты" },
        { id: 5, title: "Оплата" },
        { id: 6, title: "Статус" },
        { id: 7, title: "Последняя оплата" },
      ],

      showModalSettings: false,
      qwer: null,
      openReturnCash: false,
      errorMessage: null,
      prePayValue: "",
      prePay: 0,
      hashPay: null,
      dialogVisible: false,
      showEditSidebar: false,
      editableReserve: null,
      isLoading: false,
      visiblePrePay: false,
      statusesPay: [
        {
          value: 0,
          label: "Ожидает платежа",
          class: "rgba(52, 152, 219, 0.2)",
          border: "rgba(52, 152, 219, 0.5)",
        },
        {
          value: 1,
          label: "Оплачен",
          class: "rgba(53, 199, 112, 0.2)",
          border: "rgba(53, 199, 112, 0.5)",
        },
        {
          value: 2,
          label: "Ошибка при оплате",
          class: "rgba(231, 76, 60, 0.2)",
          border: "rgba(231, 76, 60, 0.5)",
        },
        {
          value: 3,
          label: "Возврат средств",
          class: "rgba(233, 154, 27, 0.2)",
          border: "rgba(233, 154, 27, 0.5)",
        },
      ],
    };
  },
  computed: {
    isLinkPay() {
      return this.pays.acquiring_id;
    },
    startListing() {
      if (this.currentPage === 1) return 1;
      return this.currentPage * this.pageSize;
    },
  },
  created() {
    this.hashPay = this.row?.payment[0]?.hash;
    this.prePay = this.row?.payment[0]?.paid_on_place;
    this.prePayValue = this.prePay;
  },
  methods: {
    setTableSettings() {
      const settings = localStorage.getItem("reservTable");
      if (settings) this.columns = JSON.parse(settings);
    },
    saveTableSettings(items) {
      localStorage.setItem("reservTable", JSON.stringify(items));
      this.columns = items;
      // this.closeSettingsModal();
      this.hiddenModal("settings");
    },

    dialogVisib(row) {
      this.dialogVisible = true;
      this.errorMessage = row?.error_message;
    },
    openReturnC(row) {
      this.openReturnCash = true;
      this.qwer = row;
      this.$emit("getReload");
    },
    ...mapMutations(["setNotif"]),
    ...mapActions("paymentHistory", ["getNewPaymentLink", "getPaymentOnPlace"]),

    getDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    getTime(time) {
      return moment(time).format("HH:mm");
    },
    onEditReserve(reserve) {
      this.showEditSidebar = true;
      this.editableReserve = reserve;
    },
    async addPrePay() {
      // this.$emit("isLoading", true);
      this.prePay = this.prePayValue;
      await this.getPaymentOnPlace({
        hash: this.hashPay,
        value: Number(this.prePay),
      });

      // this.$emit("setPrePay", {});
    },
    copy(copyLink) {
      navigator.clipboard.writeText(copyLink);
      this.setNotif({ title: `Данные скопированы` });
    },
    indexMethod(index) {
      // const curpage = this.page.pagination.pagedata.page;
      // const honventpage = this.page.pagination.pagedata.limit;
      // return index + 1 + (curpage - 1) * limitpage;
      if (this.currentPage === 1) return 1 + index;
      return this.startListing - this.pageSize + 1 + index;
    },

    async getNewLink(hash) {
      await this.getNewPaymentLink({ hash });
      this.$emit("newLinkVal");
    },
  },
};
</script>
<style lang="scss">
.table__name {
  font-weight: 600;
  font-size: 22px;
  line-height: 36px;
  margin: 40px 0 20px;
}
.dashed {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
}
.table_section-link {
  background: rgba(255, 255, 255, 0.1);
  font-size: 10px;
  color: #ffffff;
  border-radius: 3px;
  &-disablead {
    opacity: 0.5;
    pointer-events: none;
  }
}

.table__popover {
  background: rgba(0, 0, 0, 0.9);
  border-radius: 3px;
  padding: 10px 15px 15px;
  &-wrap {
    margin-bottom: 8px;
  }
  &-title {
    color: #ffffff;
    font-size: 14px;
    line-height: 24px;
  }
  &-btn {
    border: 1px solid #ffbe3d;
  }
}
.el-table td {
  vertical-align: top !important;
}
::v-deep .select {
  &_status {
    .el-input__inner {
      @apply px-45;
    }
  }
}
</style>
