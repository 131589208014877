import { render, staticRenderFns } from "./FinishCreateWidget.vue?vue&type=template&id=1c84f45b&scoped=true&"
import script from "./FinishCreateWidget.vue?vue&type=script&lang=js&"
export * from "./FinishCreateWidget.vue?vue&type=script&lang=js&"
import style0 from "./FinishCreateWidget.vue?vue&type=style&index=0&id=1c84f45b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c84f45b",
  null
  
)

export default component.exports