<template>
  <el-form
    ref="CreateService"
    hide-required-asterisk
    label-position="top"
    :model="{ ...service }"
    :rules="rules"
    class="form"
  >
    <h4 class="title">Описание</h4>
    <el-row :gutter="$mq.large ? 10 : 30" class="flex-wrap" type="flex">
      <el-col :span="$mq.large ? 24 : 12">
        <el-form-item prop="name">
          <span slot="label">Название услуги<span class="danger">*</span></span>
          <el-input
            :value="service.name"
            placeholder="Напишите название новой услуги"
            @input="setService({ field: 'name', value: $event })"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="$mq.large ? 24 : 12">
        <el-form-item prop="kind_of_activity">
          <span slot="label"> Категория услуги </span>
          <el-select
            :value="service.kind_of_activity"
            multiple
            filterable
            placeholder="Выберите категория"
            class="w-full"
            @input="setService({ field: 'kind_of_activity', value: $event })"
          >
            <el-option
              v-for="item in typesActivity"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item label="Анонс услуги в виджете">
      <el-input
        :value="service.short_description"
        maxlength="100"
        show-word-limit
        placeholder="Введите анонс"
        @input="setService({ field: 'short_description', value: $event })"
      ></el-input>
    </el-form-item>
    <textarea-edit
      placeholder="Опишите вашу услугу"
      title="Описание услуги"
      :value="service.detail_description"
      @input="setService({ field: 'detail_description', value: $event })"
    />
    <div class="hl"></div>
    <h4 class="title">Медиа</h4>
    <h4 class="sub-title">Фотографии</h4>
    <UploadPhoto
      :value="service.services_photos"
      @input="setService({ field: 'services_photos', value: $event })"
    />
    <h4 class="sub-title">Видео</h4>
    <el-row :gutter="$mq.large ? 10 : 30" class="flex-wrap" type="flex">
      <el-col :span="$mq.large ? 24 : 8">
        <UploadPhoto
          is-logo
          :value="service.services_video_preview"
          text="Перетащите превью в эту область или загрузите"
          sub-text="Минимальный рекомендованный размер: 500x500 пикселей"
          @input="
            setService({
              field: 'services_video_preview',
              value: $event,
            })
          "
        />
      </el-col>
      <el-col :span="$mq.large ? 24 : 16">
        <el-row :gutter="$mq.large ? 10 : 30" class="flex-wrap" type="flex">
          <el-col :span="24">
            <el-form-item label="Название видео">
              <el-input
                :value="service.video_name"
                placeholder="Введите название"
                @input="
                  setService({
                    field: 'video_name',
                    value: $event,
                  })
                "
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="24">
            <el-form-item label="Ссылка на видео">
              <el-input
                :value="service.video_link"
                placeholder="Вставьте сюда ссылку"
                @input="
                  setService({
                    field: 'video_link',
                    value: $event,
                  })
                "
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <div class="hl"></div>
    <h4 class="title">Контактные данные</h4>
    <switch-custom
      :value="service.use_company_contacts"
      title="Использовать контактные данные из настроек компании"
      @input="setService({ field: 'use_company_contacts', value: $event })"
    >
      Отметьте, если хотите использовать данные, введенные вами ранее в
      настройках компании. Просмотреть введенные данные вы можете
      <a
        class="text-white-normal cursor-pointer"
        @click="$router.push({ path: '/company' })"
      >
        здесь.
      </a>
    </switch-custom>
    <div class="wrapper-contacts">
      <el-form-item prop="emails">
        <div slot="label" class="flex">
          <div>Контактный E-mail<span class="danger">*</span></div>
          <el-popover
            class="ml-8"
            placement="top"
            width="200"
            trigger="click"
            content="Укажите e-mail, который будет приходить клиенту в почтовом уведомлении при бронировании."
          >
            <Tooltip slot="reference" />
          </el-popover>
        </div>
        <el-input
          :value="service.emails"
          placeholder="example@mail.ru"
          @input="setService({ field: 'emails', value: $event })"
        ></el-input>
      </el-form-item>
      <el-form-item prop="tel_req">
        <div slot="label" class="flex">
          <div>Контактный номер телефона<span class="danger">*</span></div>
          <el-popover
            class="ml-8"
            placement="top"
            width="200"
            trigger="click"
            content="Укажите телефон, который будет приходить клиенту в почтовом уведомлении при бронировании."
          >
            <Tooltip slot="reference" />
          </el-popover>
        </div>
        <el-input
          v-mask="maskPhone"
          placeholder="+7 (999) 999-99-99"
          :value="service.tel_req"
          @input="setService({ field: 'tel_req', value: $event })"
        ></el-input>
      </el-form-item>
      <ListField
        :value="service.tel"
        title-for-line="Дополнительный номер телефона"
        title-button="Добавить номер телефона"
        :mask="maskPhone"
        placeholder="+7 (999) 999-99-99"
        @input="setService({ field: 'tel', value: $event })"
      />
    </div>
    <h4 class="title">Адрес</h4>
    <map-info
      ref="map"
      class="mb-20"
      @validate="isValidMap = $event"
    ></map-info>
    <div class="hl"></div>
    <h4 class="title">Особенности</h4>
    <el-row :gutter="$mq.large ? 10 : 30" class="flex-wrap" type="flex">
      <el-col :span="$mq.large ? 24 : 12">
        <el-form-item prop="age_limit">
          <span slot="label">
            Возрастные ограничения<span class="danger">*</span>
          </span>
          <el-select
            :value="service.age_limit"
            placeholder="Select"
            class="w-full"
            @input="
              setService({
                field: 'age_limit',
                value: $event,
              })
            "
          >
            <el-option
              v-for="(item, i) in ageLimitList"
              :key="`age_limit-${i}`"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="$mq.large ? 24 : 12">
        <el-form-item label="Комментарий к возрасту">
          <el-input
            :value="service.age_comments"
            placeholder="Введите комментарий"
            @input="setService({ field: 'age_comments', value: $event })"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="$mq.large ? 24 : 12">
        <el-form-item prop="lang">
          <span slot="label">Язык<span class="danger">*</span></span>
          <el-select
            :value="service.language"
            placeholder="Select"
            class="w-full"
            @input="
              setService({
                field: 'language',
                value: $event,
              })
            "
          >
            <el-option
              v-for="(item, i) in languageList"
              :key="`language-${i}`"
              :label="item.label.name_rus"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="$mq.large ? 24 : 12">
        <el-form-item prop="currency">
          <span slot="label">Валюта<span class="danger">*</span></span>
          <el-select
            :value="service.currency"
            placeholder="Select"
            class="w-full"
            @input="
              setService({
                field: 'currency',
                value: $event,
              })
            "
          >
            <el-option
              v-for="(item, i) in currencyList"
              :key="`currency-${i}`"
              :label="item.label.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="$mq.large ? 24 : 12">
        <el-form-item prop="textButton">
          <span slot="label">
            Текст основной кнопки<span class="danger">*</span>
          </span>
          <el-select
            placeholder="Select"
            class="w-full"
            :value="service.button_name"
            @input="
              setService({
                field: 'button_name',
                value: $event,
              })
            "
          >
            <el-option
              v-for="(item, i) in buttonNameList"
              :key="`button_name-${i}`"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="$mq.large ? 24 : 12">
        <el-form-item prop="timezone">
          <span slot="label"> Часовой пояс<span class="danger">*</span> </span>

          <el-select
            :value="service.timezone"
            placeholder="Выбрать"
            value-key="item"
            class="w-full"
            @input="setService({ field: 'timezone', value: $event })"
          >
            <el-option
              v-for="(item, i) in timezoneList"
              :key="`timezone-${i}`"
              :label="item.label"
              :value="item.label"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <switch-custom
      :value="service.tags_switcher"
      title="Добавить тэги"
      @input="
        setService({
          field: 'tags_switcher',
          value: $event,
        })
      "
    >
      Для каждой услуги можно указать тэги, которые будут отображаться в виджете
      бронирования (не более 5 штук).
    </switch-custom>
    <el-form-item v-if="service.tags_switcher" label="Добавленные тэги">
      <el-select
        :value="service.tags"
        class="w-full"
        multiple
        filterable
        allow-create
        default-first-option
        @input="setService({ field: 'tags', value: $event })"
      />
    </el-form-item>
    <switch-custom
      title="Использовать социальные сети и мессенджеры, указанные в
      настройках компании"
      :value="service.use_messengers_socials_from_company"
      @input="
        setService({
          field: 'use_messengers_socials_from_company',
          value: $event,
        })
      "
    >
      Ссылки на социальные сети и месседжеры будут отправляться клиенту в
      почтовых уведомлениях.
    </switch-custom>
    <div class="hl"></div>
    <el-button type="primary" class="custom-button" @click="submit()">
      Сохранить
    </el-button>
  </el-form>
</template>

<script>
import ListField from "@/components/Services/ListField.vue";
import UploadPhoto from "@/components/UploadPhoto";
import SwitchCustom from "@/components/SwitchCustom";
import TextareaEdit from "@/components/TextareaEdit";
import MapInfo from "@/views/Services/Create/components/Map.vue";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";

export default {
  name: "Info",
  components: {
    MapInfo,
    SwitchCustom,
    TextareaEdit,
    UploadPhoto,
    ListField,
  },
  data() {
    return {
      isValidMap: null,
      loading: false,
      maskPhone: "+7 (###) ###-##-##",
    };
  },
  computed: {
    ...mapState("company", ["typesActivity"]),
    ...mapState("settings", [
      "ageLimitList",
      "languageList",
      "currencyList",
      "buttonNameList",
      "timezoneList",
    ]),
    ...mapState("service", ["service"]),
    ...mapGetters("service", ["isNew"]),
    ...mapState("user", ["user"]),
  },
  watch: {
    "service.use_company_contacts": function (val) {
      if (val) this.fromSetting();
    },
  },
  created() {
    this.rules = {
      name: [
        { required: true, message: "заполните поле" },
        {
          min: 3,
          message: "Укажите название (минимум 3 символа)",
          trigger: ["blur"],
        },
      ],
      kind_of_activity: [
        { required: false, message: "заполните поле", trigger: "blur" },
      ],
      tel_req: [{ required: true, message: "заполните поле", trigger: "blur" }],
      emails: [{ required: true, message: "заполните поле", trigger: "blur" }],
    };
    this.namesTranslate = {
      name: "название услуги",
      kind_of_activity: "вид деятельности",
      tel_req: "телефон",
      emails: "эл. почту",
    };
  },
  mounted() {
    if (this.service.use_company_contacts) this.fromSetting();
  },
  methods: {
    setTime(e) {
      // console.log(e);
    },
    ...mapMutations("service", ["setService"]),
    ...mapActions("service", ["createService", "editService"]),
    ...mapMutations(["setNotif"]),
    submit() {
      this.$refs.map.validateMap();
      this.$refs.CreateService.validate((valid, object) => {
        if (valid && this.isValidMap) {
          if (this.isNew) this.createdNewService();
          else this.editService("all");
        } else {
          let str = Object.values(object)
            .map((value) => this.namesTranslate[value[0].field])
            .join(", ");

          if (!this.isValidMap) {
            str = str ? `${str}, укажите адрес услуги` : "укажите адрес услуги";
          }

          this.setNotif({
            title: `Заполните следующие поля: ${str}.`,
            type: "warning",
          });
        }
      });
    },
    async createdNewService() {
      await this.createService();
      this.$router.push({
        name: "edit-service",
        params: { id: this.service.id, hash: this.service.hash },
      });
    },
    fromSetting() {
      this.setService({
        field: "tel_req",
        value: this.user.company.tel[0],
      });
      this.setService({ field: "emails", value: this.user.company.email[0] });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.wrapper-contacts {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.sub-title {
  margin-bottom: 15px;
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
}

::v-deep .el-select__tags .el-select__input {
  color: $--color-white;
}

@screen lg {
  .title {
    margin-bottom: 30px;
    font-size: 18px;
    line-height: 28px;
  }
  .wrapper-contacts {
    width: 100%;
  }
}
</style>
