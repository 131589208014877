<template>
  <el-form-item>
    <div v-if="!hiddenLabel" slot="label" class="flex">
      <div>{{ headers ? headers.title : "Количество мест" }}</div>
      <span v-if="headers && headers.valid" class="danger">*</span>
      <el-popover
        v-if="headers && headers.popover"
        class="ml-8"
        placement="top"
        width="200"
        trigger="hover"
        :content="
          headers.popoverText ||
          'Укажите телефон, который будет приходить клиенту в почтовом уведомлении при бронировании.'
        "
      >
        <Tooltip slot="reference" />
      </el-popover>
    </div>
    <el-input
      :value="value.from"
      type="number"
      placeholder="0"
      @input="$emit('input', { ...value, from: $event })"
    >
      <template slot="prepend">От</template>
    </el-input>
    <el-input
      :value="value.to"
      type="number"
      placeholder="0"
      @input="$emit('input', { ...value, to: $event })"
    >
      <template slot="prepend">До</template>
    </el-input>
  </el-form-item>
</template>

<script>
export default {
  name: "DoubleInput",
  props: {
    value: {
      type: Object,
      default: () => ({ to: null, from: null }),
    },
    headers: {
      type: Object,
      default: () => {},
    },
    hiddenLabel: Boolean,
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

::v-deep .el-form-item__content {
  display: flex;
  background-color: $--color-light-white;
  border-radius: 3px;
}

::v-deep .el-form-item__content .el-input-group {
  width: 50%;
}
::v-deep .el-input-group__prepend,
::v-deep .el-input__inner {
  background-color: transparent;
}
::v-deep .el-input-group__prepend {
  color: $--color-white;
}
</style>
