import { setField, setFieldObj } from "@/helpers/store";
import { AJAX_GET, AJAX_DELETE, AJAX_POST, AJAX_PATCH } from "@/units/ajax";
import { set } from "date-fns";

const getPrice = (state, field) => {
  switch (state.settings.booking_type_sub) {
    case "individual_does_not_depend_on_the_number_of_seats":
      return state.settings.data[field];
    case "individual_depends_on_the_number_of_seats":
    case "group_the_seat_limit_is_set_for_each_session":
    case "rent_depends_on_time_and_place":
    case "rent_depends_only_on_time":
      return state.settings.data.group[0][field];
    case "individual_depends_on_the_service_and_its_duration":
      return state.settings.data[0][field];
    case "individual_depends_on_tariff_and_number_of_seats":
      return state.settings.data[0].data[0][field];
    case "group_same_seat_limit_for_all_sessions":
      return state.settings.data.seat_limit === "general_limits"
        ? state.settings.data.group_general_limits.group[0][field]
        : state.settings.data.group_ticket_type_limit[0][field];
    default:
      return 0;
  }
};

export default {
  namespaced: true,
  state: {
    listAddressInService: [],
    service: {},
    settings: {},
    schedule: {},
    generator: {},
    sessions: {},
    addDates: {},
    listSchedule: null,
    freeTime: null,
    settingsSchedule: null,
    notifClient: null,
    notifAdmin: null,
    errorMsgService: null,
    alertMsg: null,
    periodError: false,
    isBranch: false,
  },
  getters: {
    isListAddressServices: (state) => state.listAddressInService,
    isFreeTime: (state) =>
      Object.entries(state.freeTime).filter((e) =>
        e.includes(state.freeTimeDate)
      ),
    isNew: (state) => !state.service.hash,
    isHaveSettings: (state) => !!state.settings.data,
    isHaveSessions: (state) =>
      state.sessions.data && !!state.sessions.data.length,
    isHaveAddOpt: (state) => !!state.service.additional_options.length,
    basePrice: (state) => getPrice(state, "base_price"),
    holidaysPrice: (state) => getPrice(state, "holidays_price"),
    isAddCost: (state) =>
      state.settings.booking_type_sub !==
      "individual_does_not_depend_on_the_number_of_seats",
    addPristavka: (state) =>
      [
        "individual_depends_on_the_number_of_seats",
        "individual_depends_on_tariff_and_number_of_seats",
        "group_same_seat_limit_for_all_sessions",
        "group_the_seat_limit_is_set_for_each_session",
      ].includes(state.settings.booking_type_sub),
  },
  mutations: {
    setField,
    setFieldObj,
    setServiceAddress(state, { index, value }) {
      state.service.address[index] = { ...value };
    },
    deleteAddress(state, index) {
      state.service.address = state.service.address.filter(
        (address, i) => index !== i
      );
    },
    async setFullAddressService(state, { field, value, index, autoSave }) {
      if (autoSave) await this.dispatch("service/autoSave", { field, value });
      // state.service.address[index][field] = value;
      state.service.address[index] = {
        ...state.service.address[index],
        ...value,
      };
    },
    setServiceRoute(state, { allRoute = false, index, field, value }) {
      if (allRoute) {
        state.service.address_route[index] = { ...value };
      } else state.service.address_route[index][field] = value;
    },
    async setService(state, { field, value, autoSave }) {
      if (autoSave) await this.dispatch("service/autoSave", { field, value });
      state.service[field] = value;
    },
    setNewService(state) {
      state.service = {
        name: "",
        kind_of_activity: [],
        short_description: "",
        detail_description: "",
        services_photos: [],
        services_video_preview: null, // "",
        video_name: "",
        video_link: "",
        use_company_contacts: false,
        emails: "",
        tel_req: "",
        tel: [],
        use_messengers_socials_from_company: false,
        address_type: "writeAddress", // "fromSetting",
        address_from_settings_id: [],
        address: [
          {
            address_name: "",
            address_city: "",
            address_country: "",
            address_timezone: "",
            address_comment: "",
            address_street: "",
            address_house_number: "",
            map: [59.939095, 30.315868],
          },
        ],
        service_provided_online: false,
        service_provided_exit: false,
        request_address_on_form: false,
        address_route: [
          {
            address_route_name: "",
            address_route_duration: 0,
            address_route_unit: "minutes",
            address_route_city: "",
            address_route_country: "",
            address_route_timezone: "",
            map: [],
          },
          {
            address_route_name: "",
            address_route_duration: 0,
            address_route_unit: "minutes",
            address_route_city: "",
            address_route_country: "",
            address_route_timezone: "",
            map: [],
          },
        ],
        age_limit: "0+",
        age_comments: "",
        language: "1", // "Русский",
        currency: "1", // "Рубль",
        button_name: "bronirovat",
        tags_switcher: false,
        tags: [],
        timezone: "(UTC+03:00) Moscow", // "Europe/Moscow",
      };
      state.settings = {
        booking_type: "individual",
        booking_type_sub: "individual_does_not_depend_on_the_number_of_seats",
      };
      state.schedule = {
        schedule_type: "regular",
        generator: {
          regular: null,
          dates: null,
          manual: null,
          bind: null,
        },
        schedule: null,
      };
      state.addDates = {
        slots_date: [],
        weekend: [],
        nonworking: [],
      };
      state.generator = {
        data: {
          regular: null,
          dates: null,
          manual: null,
          bind: null,
          old_period: null,
        },
        schedule_type: "regular",
      };

      state.sessions = { data: [], schedule_type: "regular" };

      state.settingsSchedule = null;
      state.notifClient = null;
      state.notifAdmin = null;
    },
    addItemRoute(state) {
      state.service.address_route.push({
        address_route_name: "",
        address_route_duration: 0,
        address_route_unit: "minutes",
        address_route_city: "",
        address_route_country: "",
        address_route_timezone: "",
        map: [],
      });
    },
    addAddress(state) {
      state.service.address.push({
        address_name: "",
        address_city: "",
        address_country: "",
        address_timezone: "",
        address_comment: "",
        map: [59.939095, 30.315868],
      });
    },
    deleteField(state, index) {
      state.service.address_route.splice(index, 1);
    },
    setSettings(state, { field, value }) {
      state.settings[field] = value;
    },
    setGenerator(state, { field, value }) {
      // generator = { ...generator, data: { ...generator.data, someelse } };
      state.generator.data[field] = value;
    },
    setOldPeriod(state, { field, value }) {
      if (field === "dates") {
        state.generator.old_period = value.data[
          field
        ].calendar.selectedDates.map((element) => {
          return element.date;
        });
      } else {
        state.generator.old_period = value.data[field].map((element) => {
          return element.date;
        });
      }
    },

    setErrorMsg(state, errorMsg) {
      state.errorMsgService = errorMsg;
    },
  },
  actions: {
    async createService({ state, commit, dispatch }) {
      try {
        const originObj = { ...state.service };
        if (originObj.address_type !== "route") originObj.address_route = [];
        const { data } = await AJAX_POST("/api/resource/create", {
          ...originObj,
        });
        if (data.success === false) {
          commit(
            "setNotif",
            {
              title: data.msg,
              type: "error",
            },
            { root: true }
          );
        } else {
          commit("setField", {
            field: "service",
            value: data.resource,
          });
          commit(
            "setNotif",
            {
              title: data.msg || "Данные сохранены",
            },
            { root: true }
          );

          commit("setField", { field: "isBranch", value: false });
        }
        dispatch("getAllSettings", { hash: state.service.hash });
      } catch (err) {
        console.log(err);
        // commit(
        //   "setNotif",
        //   {
        //     title: "Ошибка при сохранении данных. Повторите еще",
        //     type: "error",
        //   },
        //   { root: true }
        // );
      }
    },
    async editService({ state, commit }, block) {
      try {
        let origin = {};
        if (block === "сontact") {
          origin = {
            use_company_contacts: state.service.use_company_contacts,
            emails: state.service.emails,
            tel_req: state.service.tel_req,
            tel: state.service.tel,
            use_messengers_socials_from_company:
              state.service.use_messengers_socials_from_company,
            address_type: state.service.address_type,
            address_from_settings_id: state.service.address_from_settings_id,
            address: state.service.address,
            address_route: state.service.address_route,
            service_provided_online: state.service.service_provided_online,
            service_provided_exit: state.service.service_provided_exit,
            request_address_on_form: state.service.request_address_on_form,
          };
          if (origin.address_type !== "route") origin.address_route = [];
        } else if (block === "qualit") {
          origin = { ...state.service };
        } else if (block === "all") {
          origin = { ...state.service };
        }

        const { data } = await AJAX_POST("/api/resource/update", {
          action: "mainSettings",
          hash: state.service.hash,
          ...origin,
        });
        commit("setField", {
          field: "service",
          value: { ...state.service, ...data },
        });
        commit(
          "setNotif",
          {
            title: "Данные сохранены",
          },
          { root: true }
        );
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при сохранении данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async autoSave({ state, commit }, { field, value }) {
      console.log(
        // {
        // action: "mainSettings",
        // hash: state.service.hash,
        // [field]:
        //   // eslint-disable-next-line no-constant-condition
        // eslint-disable-next-line eqeqeq
        [field] == "services_video_preview",
        [field]
        // ? value
        // : value || state.service[field],
        // }
      );
      try {
        await AJAX_POST(
          "/api/resource/update",
          {
            action: "mainSettings",
            hash: state.service.hash,
            [field]:
              // eslint-disable-next-line no-constant-condition
              // eslint-disable-next-line eqeqeq
              [field] == "services_video_preview"
                ? value
                : value || state.service[field],
          },
          { noCancel: true }
        );
        commit(
          "setNotif",
          {
            title: "Данные сохранены",
          },
          { root: true }
        );
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при сохранении данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async updateTgCode({ state, commit }, { id = null }) {
      try {
        const resourceId = id || state.service.hash;
        const { data } = await AJAX_POST("/api/resource/update/telegram_code", {
          resource_id: resourceId,
        });
        commit("setFieldObj", {
          field: "service",
          subField: "telegram_code",
          value: data.code,
        });
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при получении данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async updateActive({ state, commit }, { id, active }) {
      try {
        const resourceId = id || state.service.hash;
        const { data } = await AJAX_POST("/api/resource/update", {
          action: "updateActive",
          active,
          hash: resourceId,
        });
        commit("setService", { field: "active", value: active });
        commit(
          "setNotif",
          {
            title: "Данные сохранены",
          },
          { root: true }
        );
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при получении данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async getService({ state, commit }, id) {
      const api = `/api/me/resource?id=${id}`;
      try {
        const { data } = await AJAX_GET(api, { noCancel: true });
        if (data.success === false) {
          commit("setErrorMsg", data.msg);
          commit(
            "setNotif",
            {
              title: `${data.msg}`,
              type: "error",
            },
            { root: true }
          );
        } else {
          commit("setErrorMsg", null);
        }

        commit("setField", {
          field: "service",
          value: data,
        });
        commit("setField", {
          field: "listAddressInService",
          value: data.address_from_settings_id_data,
        });

        if (data.ubrir_city_id) {
          commit("setField", { field: "isBranch", value: true });
        }
        if (state.service.address_type !== "route") {
          commit("addItemRoute");
          commit("addItemRoute");
        }
      } catch (err) {
        console.log(err);

        // commit(
        //   "setNotif",
        //   {
        //     title: "Ошибка",
        //     type: "error",
        //   },
        //   { root: true }
        // );
      }
    },
    async getSettings({ commit }, { hash, id }) {
      try {
        const { data } = await AJAX_POST("/api/resource/getBookingTypeById", {
          resource_id: hash,
          schedule_id: id || null,
        });
        if (data.data)
          commit("setField", {
            field: "settings",
            value: data,
            hash,
          });
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async saveSettings({ state, getters, commit }, { nameRadio, info }) {
      try {
        await AJAX_POST("/api/resource/newBookingType", {
          hash: state.service.hash || state.sessions.hash,
          booking_type: state.settings.booking_type,
          booking_type_sub: nameRadio,
          update_booking_type: getters.isHaveSettings,
          data: info,
        }).then((resp) =>
          resp.data
            ? commit(
                "setNotif",
                {
                  title: resp.data.msg,
                },
                { root: true }
              )
            : console.log(resp)
        );
        commit("setField", {
          field: "settings",
          value: {
            ...state.settings,
            booking_type_sub: nameRadio,
            data: info,
            hash: state.sessions.hash,
          },
        });
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при сохранении данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async saveSessions({ state, commit }, sessions) {
      try {
        const { data } = await AJAX_POST("/api/schedule/save", {
          id: state.service.hash,
          schedule_type:
            state.generator.schedule_type || state.sessions.schedule_type,
          data: sessions,
        });
        if (data.success === false) {
          commit(
            "setNotif",
            {
              title: data.msg,
              type: "error",
            },
            { root: true }
          );
        }
        if (data.success !== false) {
          commit(
            "setNotif",
            {
              title: data.msg || "Данные сохранены",
            },
            { root: true }
          );
        }
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при сохранении данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async getSessions({ state, commit }, { hash, childHash }) {
      try {
        const { data } = await AJAX_POST("/api/schedule/getResourceSchedule", {
          hash,
          child_hash: childHash,
        });
        if (data.success === false) return;
        if (data.msg) {
          commit("setField", {
            field: "alertMsg",
            value: data.msg,
          });
        } else {
          commit("setField", {
            field: "alertMsg",
            value: "",
          });
        }

        let formatData = [];
        if (data.schedule_type === "dates") {
          formatData = data.data.map((date) => {
            const schedule = date.schedule.map((seans) => {
              const [hours, minutes] = seans.time.split(":");
              return {
                ...seans,
                time: set(new Date(), {
                  hours,
                  minutes,
                }),
              };
            });
            return {
              date: date.date,
              schedule,
            };
          });
        } else {
          formatData = data.data.map((period) => {
            const days = period.days.map((day) => {
              const schedule = day.schedule.map((opt) => {
                const [hours, minutes] = opt.time.split(":");
                return {
                  ...opt,
                  time: set(new Date(), {
                    hours,
                    minutes,
                  }),
                };
              });
              return {
                ...day,
                schedule,
              };
            });

            return {
              ...period,
              days,
            };
          });
        }
        commit("setField", {
          field: "sessions",
          value: {
            data: formatData,
            schedule_type: data.schedule_type,
            hash,
          },
        });
      } catch (err) {
        console.log(err);
        // commit(
        //   "setNotif",
        //   {
        //     title: "Ошибка при получении данных. Повторите еще",
        //     type: "error",
        //   },
        //   { root: true }
        // );
      }
    },
    async saveGenerator({ state, commit }, data) {
      try {
        await AJAX_POST("/api/schedule/saveScheduleType", {
          id: state.service.hash,
          active_schedule_type:
            state.generator.schedule_type || state.sessions.schedule_type,
          data,
        }).then((resp) => {
          if (resp.data.success !== true) {
            commit("setField", {
              field: "periodError",
              value: true,
            });
            commit(
              "setNotif",
              {
                title: resp.data.msg,
                type: "error",
              },
              { root: true }
            );
          } else {
            commit("setField", {
              field: "periodError",
              value: false,
            });
            commit(
              "setNotif",
              {
                title: resp.data.msg || "Данные сохранены",
              },
              { root: true }
            );
          }
        });
        commit("setGenerator", {
          field: state.generator.schedule_type,
          value: data,
        });
        // commit(
        //   "setNotif",
        //   {
        //     title: data.msg || "Данные сохранены",
        //   },
        //   { root: true }
        // );
      } catch (err) {
        console.log(err);
        // commit(
        //   "setNotif",
        //   {
        //     title: "Ошибка при сохранении данных. Повторите еще",
        //     type: "error",
        //   },
        //   { root: true }
        // );
      }
    },
    async getGenerator({ state, commit }, id) {
      try {
        const { data } = await AJAX_POST("/api/schedule/getScheduleType", {
          id: id || state.service.hash,
        });
        if (data.success === false) return;

        commit("setFieldObj", {
          field: "generator",
          subField: "schedule_type",
          value: data.active_schedule_type,
        });
        commit("setFieldObj", {
          field: "generator",
          subField: "data",
          value: data.data,
        });
        if (state.generator.schedule_type !== "bind")
          commit("setOldPeriod", {
            field: state.generator.schedule_type,
            value: data,
          });
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при получении данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async getSchedule({ state, commit }, { hash, week, widgetHash }) {
      try {
        const hashOff = hash || state.service.hash;
        const { data } = await AJAX_POST(
          "/api/schedule/getScheduleByPeriodOrByDate",
          {
            widget_hash: widgetHash,
            hash: hashOff,
            week,
          }
        );
        if (!data) return;
        if (data.success === false) {
          commit(
            "setNotif",
            {
              title: data.msg,
              type: "error",
            },
            { root: true }
          );
          commit("setErrorMsg", data.msg);
        } else commit("setErrorMsg", null);

        const dataFormatting = Object.entries(data.data).map((el) => {
          return {
            date: el[0],
            value: el[1][hashOff],
          };
        });
        commit("setField", {
          field: "schedule",
          value: {
            ...data,
            data: dataFormatting,
          },
        });
      } catch (err) {
        console.log(err);
        // commit(
        //   "setNotif",
        //   {
        //     title: err.msg,
        //     type: "error",
        //   },
        //   { root: true }
        // );
      }
    },
    async widgetGetScheduleByPeriodOrByDate(
      { state, commit },
      { hash, page = 1, widgetHash, noPaginate }
    ) {
      try {
        const hashOff = hash || state.service.hash;

        const { data } = await AJAX_POST(
          "/api/schedule/widgetGetScheduleByPeriodOrByDate",
          {
            widget_hash: widgetHash,
            hash: hashOff,
            no_paginate: noPaginate,
            page,
          }
        );
        if (!data) return;
        if (data.success === false) {
          commit(
            "setNotif",
            {
              title: data.msg,
              type: "error",
            },
            { root: true }
          );
          commit("setErrorMsg", data.msg);
        }
        const dataFormatting = Object.entries(data.data).map((el) => {
          return {
            date: el[0],
            value: el[1][hashOff],
          };
        });
        commit("setField", {
          field: "schedule",
          value: {
            ...data,
            data: dataFormatting,
          },
        });
      } catch (err) {
        console.log(err);
        // commit(
        //   "setNotif",
        //   {
        //     title: err.msg,
        //     type: "error",
        //   },
        //   { root: true }
        // );
      }
    },
    // async getScheduleAdminCreate({ state, commit }, { hash }) {
    //   try {
    //     const hashOff = hash;
    //     const { data } = await AJAX_POST(
    //       "/api/schedule/getScheduleByPeriodOrByDate",
    //       {
    //         hash: hashOff,
    //         // page,
    //       }
    //     );
    //     if (!data) return;
    //     const dataFormatting = Object.entries(data.data).map((el) => {
    //       return {
    //         date: el[0],
    //         value: el[1][hashOff],
    //       };
    //     });

    //     // commit("setField", {
    //     //   field: "schedule",
    //     //   value: {
    //     //     ...data,
    //     //     data: dataFormatting,
    //     //   },
    //     // });
    //   } catch (err) {
    //     console.log(err);
    //     commit(
    //       "setNotif",
    //       {
    //         title: "Ошибка при получении данных. Повторите еще",
    //         type: "error",
    //       },
    //       { root: true }
    //     );
    //   }
    // },
    async getAllSettings({ dispatch }, { hash }) {
      await dispatch("getAddDates", { hash, type: "weekend" });
      await dispatch("getAddDates", { hash, type: "nonworking" });
      await dispatch("getAddDates", { hash, type: "slots_date" });
      await dispatch("getSettingsSchedule", { hash });
      await dispatch("getNotifClient", { hash });
      await dispatch("getNotifAdmin", { hash });
    },
    async getAddDates({ state, commit }, { hash = null, type }) {
      try {
        const idOff = hash || state.service.hash;
        const { data } = await AJAX_POST("/api/schedule/getAdditionalDates", {
          resource_id: idOff,
          type,
        });
        if (data)
          commit("setFieldObj", {
            field: "addDates",
            subField: type,
            value: data,
          });
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при получении данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async saveAddDates({ state, commit }, { id = null, type, dates }) {
      try {
        const idOff = id || state.service.hash;

        const resp = await AJAX_POST("/api/schedule/add/additionalDates", {
          resource_id: idOff,
          type,
          dates,
        });
        if (!resp.data.success) {
          commit(
            "setNotif",
            {
              title: resp.data.msg,
              type: "error",
            },
            { root: true }
          );
        } else {
          commit(
            "setNotif",
            {
              title: resp.data.msg,
            },
            { root: true }
          );
        }
        // commit(
        //   "setNotif",
        //   {
        //     title: "Данные сохранены",
        //   },
        //   { root: true }
        // );
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при сохранении данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async getSettingsSchedule({ state, commit }, { hash = null }) {
      try {
        const idOff = hash || state.service.hash;
        const { data } = await AJAX_POST("/api/resource/getScheduleSettings", {
          resource_id: idOff,
        });
        commit("setField", {
          field: "settingsSchedule",
          value: data,
        });
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при получении данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async updateSettingsSchedule({ state, commit }, { type, value }) {
      try {
        const { data } = await AJAX_POST("/api/resource/update/settings", {
          resource_id: state.service.hash,
          type,
          value,
        });

        commit("setFieldObj", {
          field: "settingsSchedule",
          subField: type,
          value,
        });
        commit(
          "setNotif",
          {
            title: data.msg || "Данные сохранены",
          },
          { root: true }
        );
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при сохранении данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async saveSettingsSchedule({ state, commit }, { id = null, data }) {
      try {
        const idOff = id || state.service.hash;

        await AJAX_POST("/api/resource/setScheduleSettings", {
          resource_id: idOff,
          data,
        });
        commit("setField", {
          field: "settingsSchedule",
          value: data,
        });
        commit(
          "setNotif",
          {
            title: "Данные сохранены",
          },
          { root: true }
        );
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при сохранении данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },

    async getNotifClient({ state, commit }, { hash }) {
      try {
        const { data } = await AJAX_POST(
          "/api/resource/getClientSmsNotifications",
          {
            resource_id: hash || state.service.hash,
          }
        );
        commit("setField", {
          field: "notifClient",
          value: data,
        });
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при получении данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },

    async saveNotifClient({ state, commit }, { id = null, data }) {
      try {
        // const idOff = id || state.service.id;
        const idOff = id || state.service.hash;

        await AJAX_POST("/api/resource/setClientSmsNotifications", {
          resource_id: idOff,
          data,
        }).then((res) => {
          commit("setField", {
            field: "notifClient",
            value: data,
          });
          commit(
            "setNotif",
            {
              title: res.data.msg,
              type: res.data.success ? "success" : "error",
            },
            { root: true }
          );
          return res;
        });
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при сохранении данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },

    async getNotifAdmin({ state, commit }, { hash }) {
      try {
        const { data } = await AJAX_POST("/api/resource/getNotifications", {
          resource_id: hash || state.service.hash,
        });
        commit("setField", {
          field: "notifAdmin",
          value: data,
        });
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при получении данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },

    async saveNotifAdmin({ state, commit }, { id = null, data }) {
      try {
        const idOff = id || state.service.hash;

        await AJAX_POST("/api/resource/addNotificationPhonesEmails", {
          resource_id: idOff,
          data,
        });
        commit("setField", {
          field: "notifAdmin",
          value: {
            ...state.notifAdmin,
            current: { ...data },
          },
        });

        commit(
          "setNotif",
          {
            title: "Данные сохранены",
          },
          { root: true }
        );
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при сохранении данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async saveNotifAdminSms({ state, commit }, { id = null, dataSms }) {
      try {
        const idOff = id || state.service.hash;

        const { data } = await AJAX_POST(
          "/api/resource/setAdminSmsNotifications",
          {
            resource_id: idOff,
            data: dataSms,
          }
        );
      } catch (error) {
        console.log(error);
      }
    },

    async createAddOptions({ state, commit }, listOptions) {
      try {
        const { data } = await AJAX_POST("/api/resource/setAdditionalOptions", {
          resource_id: state.service.hash,
          data: listOptions,
        });
        commit(
          "setNotif",
          {
            title: "Данные сохранены",
          },
          { root: true }
        );
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при сохранении данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async getResourceSlots({ commit }, id, dateSearch) {
      try {
        await AJAX_POST("/api/resource/getResourceSlots", {
          resourceId: id.hash,
        }).then((response) => {
          if (response.status === 200) {
            commit("setField", {
              field: "freeTime",
              value: response.data,
            });
          }
        });
      } catch (error) {
        console.log(error);
      }
    },
    async updatePayment({ state, commit }, { type, value, hash }) {
      try {
        await AJAX_POST("/api/resource/updatePayment", {
          hash,
          type,
          value,
        });
        commit("setFieldObj", {
          field: "service",
          subField: "payment",
          value: { ...state.service.payment, [type]: value },
        });
        commit(
          "setNotif",
          {
            title: "Данные сохранены",
          },
          { root: true }
        );
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при сохранении данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },

    addBranchId({ commit }, param) {
      AJAX_POST(`/api/resource/ubrir/addBranchId`, param).then((res) => {
        commit(
          "setNotif",
          {
            title: res.data.msg,
            type: res.data.success !== true ? "error" : "",
          },
          { root: true }
        );
        if (res.data.success === true) {
          commit("setField", { field: "isBranch", value: true });
        }
      });
    },
  },
};
