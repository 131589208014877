<template>
  <div class="list-fields">
    <div
      v-for="(item, index) in listField"
      :key="index"
      class="list-fields__row"
    >
      <div class="list-fields__item">
        <DoubleInput
          :value="{
            from: item.from,
            to: item.to,
          }"
          :headers="headers[0]"
          @input="setNumber({ index, value: $event })"
        ></DoubleInput>
      </div>
      <div class="list-fields__item input-select">
        <InputSelect
          v-model="item.discount"
          class="w-full"
          prop="durationService"
          :label="headers[1].title"
          :list-value="listDuration"
          :select-value="item.discount_unit"
          :placeholder="headers[1].placeholder"
          :tooltip-text="headers[1].popoverText"
          @setValue="listField[index].discount_unit = $event"
          @blur="emitData()"
        ></InputSelect>
      </div>
      <div class="list-fields__actions">
        <ButtonIcon icon-name="close" width="16" @click="deleteField(index)" />
      </div>
    </div>
    <el-button class="add-button" @click="addField()">{{
      titleButton
    }}</el-button>
  </div>
</template>

<script>
import DoubleInput from "@/components/DoubleInput.vue";

export default {
  name: "ListField2",
  components: {
    DoubleInput,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    titleButton: {
      type: String,
      default: "Добавить новое поле",
    },
    placeholder: {
      type: String,
      default: "Введите значение",
    },
    headers: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      listField: [
        {
          id: null,
          sale: null,
          typeTime: "rub",
          rangeNumber: {
            from: 1,
            before: null,
          },
        },
      ],
    };
  },
  watch: {
    value() {
      this.listField = [...this.value];
    },
    listField() {
      this.listField.find((el) => {
        if (el.discount.length === 0) {
          el.discount = 0;
          return el.discount;
        }
        return el;
      });
    },
  },
  created() {
    this.listField = [...this.value];
    this.listDuration = [
      {
        label: "₽",
        value: "rub",
      },
      {
        label: "%",
        value: "procent",
      },
    ];
  },
  methods: {
    deleteField(index) {
      this.listField.splice(index, 1);
      this.emitData();
    },
    addField() {
      this.listField.push({
        id: null,
        from: 1,
        to: null,
        discount: 0,
        discount_unit: "rub",
      });
    },
    emitData() {
      this.$emit("input", [...this.listField]);
    },
    setNumber({ index, value }) {
      this.listField[index].from = value.from;
      this.listField[index].to = value.to;
      this.emitData();
    },
  },
};
</script>

<style lang="scss" scoped>
.input-select ::v-deep .el-form-item {
  width: 100%;
}
</style>
