<template>
  <div class="bk-full">
    <p
      class="title-widget"
      :style="{
        color: isThemeWidget ? customStyle.active_text_color : '',
      }"
    >
      Выберите тариф
    </p>
    <el-radio-group v-model="radio" @input="emitTariff($event)">
      <el-radio
        v-for="(tariff, i) in settings.data"
        :key="i"
        :label="tariff"
        :class="{ 'custom-style': isThemeWidget }"
      >
        <div
          class="radio__title"
          :style="{
            color: isThemeWidget ? customStyle.active_text_color : '',
          }"
        >
          {{ tariff.tariff_name }} •
          <span>{{ `${tariff.duration} минут` }}</span>
        </div>
        <div
          class="radio__sub-title"
          :style="{
            color: isThemeWidget ? customStyle.active_text_color : '',
          }"
        >
          <span> {{ getCost(tariff) }} {{ selectCell.currency }} </span>
          •

          <span
            :style="{
              color: isThemeWidget ? customStyle.inactive_text_color : '',
            }"
            >за 1 место</span
          >
        </div>
        <div
          v-if="
            tariff.data.length > 1 &&
            radio &&
            radio.tariff_name === tariff.tariff_name
          "
          class="radio__description"
        >
          <div
            v-for="(item, index) in tariff.data"
            :key="index"
            class="bk-info"
          >
            <p
              class="bk-info__title"
              :style="{
                color: isThemeWidget ? customStyle.active_text_color : '',
              }"
            >
              от {{ item.from }} {{ item.to ? `до ${item.to}` : "" }}
            </p>
            <p
              class="bk-info__info"
              :style="{
                color: isThemeWidget ? customStyle.inactive_text_color : '',
              }"
            >
              {{
                isHoliday
                  ? item.holidays_price + selectCell.weekend_price_additional
                  : item.base_price + selectCell.price_additional
              }}
              {{ selectCell.currency }}
            </p>
          </div>
        </div>
      </el-radio>
    </el-radio-group>
    <p
      class="title-widget"
      :style="{
        color: isThemeWidget ? customStyle.active_text_color : '',
      }"
    >
      Количество участников
    </p>
    <el-input-number
      :value="value"
      class="w-full"
      :class="{ 'custom-style': isThemeWidget }"
      :style="{
        background: isThemeWidget ? customStyle.background_cells_fields : '',
      }"
      :min="minCount"
      :max="maxCount"
      @input="changePersons($event)"
    ></el-input-number>
  </div>
</template>

<script>
export default {
  name: "DepTarifSeats",
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    selectCell: {
      type: Object,
      default: () => {},
    },
    settings: {
      type: Object,
      default: () => {},
    },
    customStyle: {
      type: Object,
    },
    isThemeWidget: {
      type: String,
    },
  },
  data() {
    return {
      radio: null,
    };
  },
  computed: {
    isHoliday() {
      return this.selectCell.is_holiday;
    },
    maxCount() {
      if (this.radio) {
        const lastChild = this.radio.data[this.radio.data.length - 1];
        return Number(lastChild.to) || 99999;
      }
      return 99999;
    },
    minCount() {
      if (this.radio) {
        const lastChild = this.radio.data[this.radio.data.length - 1];
        return Number(lastChild.from) || 1;
      }
      return 1;
    },
    currentPrice() {
      if (!this.radio) return null;
      let base;
      let holidays;
      if (this.radio.data.length > 1) {
        const findInterval = this.radio.data.find((el) => {
          const to = el.to ? Number(el.to) : this.maxCount;
          return this.value <= to;
        });

        holidays = findInterval.holidays_price;
        base = findInterval.base_price;
      } else {
        holidays = this.radio.data[0].holidays_price;
        base = this.radio.data[0].base_price;
      }
      return this.isHoliday ? holidays : base;
    },
  },
  created() {
    this.radio = this.settings.data.find((el, idx) => idx === 0);
    //   this.minVal = Number(this.radio.data.find((e) => e.from)?.from);
    const data = {
      data: this.radio.data,
      duration: this.radio.duration,
      id: this.radio.id,
      tariff_name: this.radio.tariff_name,
      unit: this.radio.time_unit,
      price: this.isHoliday
        ? this.currentPrice + this.selectCell.weekend_price_additional ?? 0
        : this.currentPrice + this.selectCell.price_additional ?? 0,
      persons: this.value,
    };
    this.emitTariff(data);
    this.isClass();
  },
  methods: {
    isClass() {
      document.body.style.setProperty(
        "--colorCustom",
        `${this.customStyle.active_text_color}`
      );
      document.body.style.setProperty(
        "--colorCustomBackground",
        `${this.customStyle.inactive_text_color}`
      );
    },
    getCost(tariff) {
      if (tariff.data.length > 1) {
        const findInterval = tariff.data.find((el) => {
          const to = el.to ? Number(el.to) : 999999;
          return this.value <= to;
        });
        return this.isHoliday
          ? findInterval.holidays_price +
              this.selectCell.weekend_price_additional
          : findInterval.base_price + this.selectCell.price_additional;
      }
      return this.isHoliday && tariff.data[0].holidays_price
        ? tariff.data[0].holidays_price +
            this.selectCell.weekend_price_additional
        : tariff.data[0].base_price + this.selectCell.price_additional;
    },
    emitTariff(tariff) {
      const lastChild = tariff.data[tariff.data.length - 1].to || 99999;
      this.minVal = tariff.data[tariff.data.length - 1].from || 99999;

      if (Number(lastChild) < this.value)
        this.$emit("input", Number(lastChild));
      if (this.value < Number(this.minVal))
        this.$emit("input", Number(this.minVal));

      this.$emit("setPrice", this.currentPrice);
      tariff = {
        duration: tariff.duration,
        id: tariff.id,
        name: tariff.tariff_name,
        unit: tariff.time_unit || tariff.unit,
        price: this.isHoliday
          ? this.currentPrice + this.selectCell.weekend_price_additional ?? 0
          : this.currentPrice + this.selectCell.price_additional ?? 0,
        persons: this.value,
      };
      this.$emit("setTariff", tariff);
      this.$emit("setSlotParam", {
        resourceHash: this.selectCell.resource_id,
        date: this.selectCell.date,
        time: this.selectCell.time,
        book_info: { tariff },
      });
    },
    changePersons(value) {
      if (!this.radio) return;
      const data = {
        // data: this.radio.data,
        duration: this.radio.duration,
        id: this.radio.id,
        name: this.radio.tariff_name,
        unit: this.radio.time_unit,
        price: this.isHoliday
          ? this.currentPrice + this.selectCell.weekend_price_additional ?? 0
          : this.currentPrice + this.selectCell.price_additional ?? 0,
        persons: this.value,
      };
      this.$emit("setTariff", data);
      this.$emit("input", value);
      this.$emit("setPrice", this.currentPrice);
      this.$emit("isDis", true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.radio {
  &__title {
    margin-bottom: 4px;
  }
  &__sub-title {
    margin-bottom: 12px;
    span {
      &:first-child {
        margin-right: 6px;
      }
      &:last-child {
        color: $--color-white-5;
        margin-left: 6px;
      }
    }
  }
  &__description {
    height: auto;
    margin-bottom: 18px;
  }
}
:root {
  --colorCustom: "white";
  --colorCustomBackground: "green";
}
.custom-style {
  ::v-deep .el-input__inner {
    color: var(--colorCustom);
  }
  ::v-deep .el-input-number__increase,
  ::v-deep .el-input-number__decrease {
    background: var(--colorCustomBackground);
    color: var(--colorCustom);
  }
  ::v-deep .el-radio__inner {
    border-color: var(--colorCustom);
  }
  ::v-deep .el-radio__input.is-checked > .el-radio__inner {
    border-color: var(--colorCustomButton);
    background: var(--colorCustomButton);
  }
}
</style>
