<template>
  <div>
    <span class="return__text">{{
      `или выберите количество участников `
    }}</span>
    <div class="input-number my-10">
      <div class="input-number__info">
        <span class="input-number__info-title">участников </span>
      </div>
      <el-input-number
        v-model="itemCount"
        :disabled="isDisabled"
        class="input-number__input"
        :max="item.individual.persons"
        :min="0"
        @input="$emit('setItemCount', itemCount)"
      />
    </div>
    <!-- <span class="return__text">{{
      `количество участников : ${item.individual.persons}`
    }}</span>
    
    <div class="input-number my-10">
      <div class="input-number__info"> -->
    <!-- <span class="input-number__info-title">участников: </span> -->
    <!-- <div class="">
          <el-radio-group>
            <el-radio v-model="model" class="flex">
              <p>
                {{
                  `Группа от ${item.group.from} до ${item.group.to} участников`
                }}
              </p>
              <p>{{ item.group.price }}</p>
            </el-radio>
          </el-radio-group>
        </div> -->
    <!-- </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "IndOnNumSet",
  props: {
    item: {
      type: Object,
    },
    count: { type: String },
    settings: { type: Object },
    isDisabled: {
      type: Boolean,
    },
  },

  data() {
    return {
      model: null,
    };
  },

  created() {
    this.itemCount = this.item.individual.persons;
    //  this.$emit("setItemCount", this.itemCount);
  },
};
</script>

<style lang="scss" scoped>
.return {
  &__text {
    color: #fff;
  }
}
</style>
