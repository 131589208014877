<template>
  <div class="wrapper-switch">
    <div class="wrapper-switch__switch">
      <el-switch v-model="isActive" /> {{ listSocial[value.name].title }}
      <svg-icon
        :icon-name="value.name"
        width="12"
        height="12"
        class-name="text-white-normal mx-10"
      ></svg-icon>
    </div>
    <div v-if="isActive" class="wrapper-switch__input">
      <el-input
        v-model="localValue"
        :type="['viber', 'whatsapp'].includes(value.name) ? 'number' : 'text'"
        :placeholder="listSocial[value.name].placeholder"
      ></el-input>
      <i v-if="localValue" class="el-icon-close" @click="localValue = ''"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "SwitchSocialNetwork",
  props: {
    value: { type: Object, default: () => ({}) },
  },
  computed: {
    isActive: {
      get() {
        return this.value.show;
      },
      set(value) {
        this.$emit("input", { ...this.value, show: value });
      },
    },
    localValue: {
      get() {
        return this.value.value;
      },
      set(value) {
        this.$emit("input", { ...this.value, value });
      },
    },
  },
  created() {
    this.listSocial = {
      whatsapp: {
        title: "WhatsApp",
        placeholder: "Введите ваш номер: 7999123456",
      },
      viber: {
        title: "Viber",
        placeholder: "Введите ваш номер: 7999123456",
      },
      telegram: {
        title: "Telegram",
        placeholder: "Введите имя пользователя: BroniBizBot",
      },
      facebook_messenger: {
        title: "Facebook Messenger",
        placeholder: "Введите идентификатор страницы",
      },
      vk_messenger: {
        title: "Вконтакте мессенджер",
        placeholder: "Введите идентификатор страницы",
      },

      instagram: {
        title: "Instagram",
        placeholder: "Вставьте ссылку на профиль",
      },
      vk: {
        title: "Вконтакте",
        placeholder: "Вставьте ссылку на профиль или группу",
      },
      facebook: {
        title: "Facebook",
        placeholder: "Вставьте ссылку на профиль или группу",
      },
      odnoklassniki: {
        title: "ОК",
        placeholder: "Вставьте ссылку на профиль или группу",
      },
      twitter: {
        title: "Twitter",
        placeholder: "Вставьте ссылку на аккаунт",
      },
    };
  },
  methods: {
    qwe() {
      console.log(this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.wrapper-switch {
  display: flex;
  flex-direction: column;
  margin-bottom: 27px;

  &__switch {
    display: flex;
    align-items: center;
    color: $--color-white;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    ::v-deep .el-switch {
      margin-right: 15px;
    }
  }
  &__input {
    position: relative;
    padding-top: 17px;
  }
}

::v-deep .el-icon-close {
  position: absolute;
  top: calc(50% + 7px);
  right: 10px;
  transform: translateY(-50%);
  text-align: center;
  color: #c0c4cc;
  transition: all 0.3s;
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
}
</style>
