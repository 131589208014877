const dayNames = [
  { value: 1, shortTitle: "Пн", title: "Понедельник" },
  { value: 2, shortTitle: "Вт", title: "Вторник" },
  { value: 3, shortTitle: "Ср", title: "Среда" },
  { value: 4, shortTitle: "Чт", title: "Четверг" },
  { value: 5, shortTitle: "Пт", title: "Пятница" },
  { value: 6, shortTitle: "Сб", title: "Суббота" },
  { value: 7, shortTitle: "Вс", title: "Воскресенье" },
];
const monthNames = [
  { value: 1, titleBasic: "Январь", title: "Января" },
  { value: 2, titleBasic: "Февраль", title: "Февраля" },
  { value: 3, titleBasic: "Март", title: "Марта" },
  { value: 4, titleBasic: "Апрель", title: "Апреля" },
  { value: 5, titleBasic: "Май", title: "Мая" },
  { value: 6, titleBasic: "Июнь", title: "Июня" },
  { value: 7, titleBasic: "Июль", title: "Июля" },
  { value: 8, titleBasic: "Август", title: "Августа" },
  { value: 9, titleBasic: "Сентябрь", title: "Сентября" },
  { value: 10, titleBasic: "Октябрь", title: "Октября" },
  { value: 11, titleBasic: "Ноябрь", title: "Ноября" },
  { value: 12, titleBasic: "Декабрь", title: "Декабря" },
];

export { dayNames, monthNames };
