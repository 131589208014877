/* eslint-disable no-restricted-syntax */
import { setField, setFieldObj } from "@/helpers/store";
import { AJAX_GET, AJAX_POST } from "@/units/ajax";

export default {
  namespaced: true,
  state: {
    user: null,
    dashboardStep: 1,
    hiddenProgressBlock: true,
    minBalanceNotify: 0,
    minSmsNotify: 0,
    servicesList: [],
    partnersList: [],
    taxRate: [],
    taxSystem: [],
    tariffsList: [],
    fixedService: [],
    ubrirCities: [],
    ubrirBranches: [],
    ymk: null,
    isAuth: JSON.parse(localStorage.getItem("isAuth")) || false,
    token: localStorage.getItem("token") || "",
    showProgressBar: true,
    import_url: null,
    isCheckServiceCreate: true,
  },
  getters: {
    listAddress: (state) => state.user.company.address ?? "",
    allServicesId: (state) => state.servicesList.map((service) => service.hash),
    resourcesAsArray: (state) =>
      Object.values(state.user.resources).sort((a, b) => {
        return a.position - b.position;
      }),
    ordersSources: (state) => state.user.orders_sources,
    priceTariffSms: (state) => state.user.sms_price,
    isUBRR: (state) => state.user.is_ubrir,
  },
  mutations: {
    setField,
    setFieldObj,
    setServiceList(state, list) {
      state.servicesList = Object.values(list).sort((a, b) => {
        return a.position - b.position;
      });
    },
    addResource(state, resource) {
      state.user.resources.push(resource);
    },
    setUrl(state, payload) {
      state.import_url = payload;
    },
    isShowProgress(state, payload) {
      state.showProgressBar = payload;
    },
    copyResource(state, resourceId) {
      const target = state.user.resources.find(
        (resource) => resource.id === resourceId
      );
      const targetCopy = JSON.parse(JSON.stringify(target));

      state.user.resources.push(targetCopy);
    },
    delService(state, hash) {
      state.servicesList = state.servicesList.filter(
        (item) => item.hash !== hash
      );
    },
  },
  actions: {
    async getInfo({ commit }) {
      try {
        const { data } = await AJAX_POST("/api/me");
        if ("success" in data) {
          commit("setField", { field: "isAuth", value: false });
          commit("setField", { field: "user", value: null });
          localStorage.setItem("isAuth", false);
          return false;
        }
        commit("setField", {
          field: "ymk",
          value: data.ymk,
        });
        commit("setField", { field: "user", value: data });
        commit("setField", {
          field: "partnersList",
          value: data.orders_sources,
        });
        commit("setServiceList", data.resources);
        commit(
          "menu/setMenuCounts",
          {
            services: data.resources_count,
            balance: data.balance,
            reserves: data.orders_count,
            mail: data.sms,
            news: data.counters.news,
            support: data.counters.tickets_count,
          },
          { root: true }
        );
        commit(
          "menu/setAddLinksCounts",
          { support: data.counters.tickets_new },
          { root: true }
        );
        commit("setField", {
          field: "fixedService",
          value: data.fixed_service,
        });
        commit("setField", {
          field: "ubrirCities",
          value: data.ubrir_cities,
        });
        commit("setField", {
          field: "ubrirBranches",
          value: data.ubrir_branches,
        });

        return true;
      } catch (err) {
        console.error(err);
      }
    },
    setUser({ commit }, user) {
      commit("setField", { field: "user", value: user });
      commit("setField", { field: "isAuth", value: true });
      localStorage.setItem("isAuth", true);
    },
    clearUser({ commit }) {
      commit("setField", { field: "user", value: null });
      commit("setField", { field: "isAuth", value: false });
      localStorage.setItem("isAuth", false);
    },
    addResource({ commit }, resource) {
      commit("addResource", resource);
    },
    async getDasboardStep({ state, commit }) {
      try {
        const { data } = await AJAX_POST("/api/dashboard/faststart");
        commit("setField", {
          field: "dashboardStep",
          value: data.step || 6,
        });
        if (state.dashboardStep > 6) {
          commit("setField", {
            field: "hiddenProgressBlock",
            value: data.complited || false,
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
    async closeProgressBlock({ state, commit }) {
      try {
        const { data } = await AJAX_POST("/api/dashboard/complete/update", {
          complete: true,
        });
        commit(
          "setNotif",
          {
            title: "Изменения успешно сохранены",
          },
          { root: true }
        );
        commit("isShowProgress", data);
      } catch (err) {
        console.error(err);
        commit(
          "setNotif",
          {
            title: "Произошла ошибка, попробуйте еще раз",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async getMinValue({ commit }, key) {
      try {
        const { data } = await AJAX_POST(`/api/dashboard/${key}/get`);
        if (data.success) {
          // eslint-disable-next-line no-shadow
          const setFieldObj =
            key === "minsms"
              ? {
                  field: "minSmsNotify",
                  value: data.min_sms_notify,
                }
              : {
                  field: "minBalanceNotify",
                  value: data.min_balance_notify,
                };
          commit("setField", setFieldObj);
        } else {
          commit(
            "setNotif",
            {
              title: "Произошла ошибка, попробуйте еще раз",
              type: "error",
            },
            { root: true }
          );
        }
      } catch (err) {
        commit(
          "setNotif",
          {
            title: "Произошла ошибка, попробуйте еще раз",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async setMinValue({ commit }, item) {
      if (item.value === 0) {
        commit(
          "setNotif",
          {
            title: "Введите значение больше нуля",
            type: "error",
          },
          { root: true }
        );
        return;
      }
      try {
        const payload =
          item.key === "minsms"
            ? { min_sms_notify: item.value }
            : { min_balance_notify: item.value };

        const { data } = await AJAX_POST(
          `/api/dashboard/${item.key}/update`,
          payload
        );

        if (data.success) {
          commit(
            "setNotif",
            {
              title: "Значение обновлено",
            },
            { root: true }
          );
        } else {
          commit(
            "setNotif",
            {
              title: data.msg || "Произошла ошибка, попробуйте еще раз",
              type: "error",
            },
            { root: true }
          );
        }
      } catch (err) {
        commit(
          "setNotif",
          {
            title: "Произошла ошибка, попробуйте еще раз",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async getServicesList({ state, commit }) {
      try {
        const { data } = await AJAX_GET("/api/me/resources");
        if (data.success === false) {
          commit(
            "setNotif",
            {
              title: `${data.msg}`,
              type: "error",
            },
            { root: true }
          );
        }

        commit("setField", {
          field: "user",
          value: { ...state.user, resources: data },
        });
        commit("setServiceList", data);
      } catch (err) {
        console.error(err);
      }
    },
    copyResource({ commit }, id) {
      commit("copyResource", id);
    },
    async deleteService({ commit }, params) {
      try {
        const { hash } = params;
        await AJAX_POST("/api/resource/delete", { hash });
        commit("delService", params.hash);
        commit(
          "setNotif",
          {
            title: "Услуга удалена",
          },
          { root: true }
        );
      } catch (err) {
        console.error(err);
        commit(
          "setNotif",
          {
            title: "Произошла ошибка, попробуйте еще раз",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async getAllTextRate({ commit }) {
      try {
        const { data } = await AJAX_POST("/api/taxrate/get/all");
        commit("setField", {
          field: "taxRate",
          value: data,
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getAllTaxSystem({ commit }) {
      try {
        const { data } = await AJAX_POST("/api/taxsystem/get/all");

        commit("setField", {
          field: "taxSystem",
          value: data,
        });
      } catch (error) {
        console.log(error);
      }
    },
    async getCheckServiceCreate({ commit }) {
      try {
        const { data } = await AJAX_POST("/api/tariff/checkservicecreate", {});
        if (data.success === false) {
          commit("setField", {
            field: "isCheckServiceCreate",
            value: data.success,
          });
          commit(
            "setNotif",
            {
              title: data.msg,
              type: "error",
            },
            { root: true }
          );
        } else {
          commit("setField", {
            field: "isCheckServiceCreate",
            value: true,
          });
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getUserTariff({ commit }) {
      try {
        const { data } = await AJAX_POST("/api/tariff/getusertariffs");
        commit("setField", {
          field: "tariffsList",
          value: data,
        });
      } catch (error) {
        console.log(error);
      }
    },
    async changeUserTariff({ commit }, id) {
      try {
        const { data } = await AJAX_POST("/api/tariff/change", { id });

        commit(
          "setNotif",
          {
            title: `${data.msg}`,
            type: data.success !== true ? "error" : "",
          },
          { root: true }
        );
      } catch (error) {
        console.log(error);
      }
    },
    async getIntegration({ commit }) {
      try {
        const { data } = await AJAX_POST("/api/integration/bitrix/get");
        commit("setField", {
          field: "import_url",
          value: data.import_url,
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
