<template>
  <div class="pt-40 pb-80 w-full max-w-lg api-page lg:pt-15">
    <h1
      class="text-36 leading-48 mb-20 font-semibold text-white-normal lg:mb-15 lg:px-15 xs:leading-30 lg:text-22 lg:leading-36"
    >
      API для агрегаторов
    </h1>
    <div class="text-white-700 mb-40 lg:px-15">
      Мы предоставляем API для строронних агрегаторов. Вы сможете интегрировать
      все ваши услуги на ваш сайт или портал. Для интеграции партнерам
      необходимо получить token и посылать его значение в качестве GET-параметра
      при каждом запросе к API. Ниже будут приведены примеры возможных запросов
      к API.
    </div>

    <article class="bg-white-40 rounded-sm mb-20 px-30 pt-20 pb-30 lg:px-15">
      <div
        class="text-22 font-semibold leading-36 mb-20 lg:text-18 lg:leading-30"
      >
        Ваш токен
      </div>
      <div class="flex items-end xl:flex-wrap">
        <label
          class="text-12 font-medium mr-20 text-white-normal block flex flex-col lg:mr-0 token-input input-disabled-filled xl:w-full xl:mb-20"
        >
          <span class="mb-7 flex items-center"> API Токен </span>
          <span class="flex">
            <el-input
              ref="token"
              v-model="token"
              placeholder="Нет токена"
            ></el-input>
          </span>
        </label>
        <div class="mr-20 lg:w-full lg:mr-0">
          <el-button
            type="info"
            class="px-12 lg:w-full lg:mb-10"
            @click="generateToken"
            >Сгенерировать токен</el-button
          >
        </div>
        <div class="lg:w-full lg:ml-0">
          <el-button class="px-15 lg:w-full" @click="copyToken">
            <div class="flex items-center justify-center">
              <svg-icon
                icon-name="copy"
                class-name="text-white-normal mr-8"
                width="16"
                height="18"
              ></svg-icon
              >Скопировать токен
            </div></el-button
          >
        </div>
      </div>
    </article>

    <article
      v-for="(demo, index) in demos"
      :key="index"
      class="bg-white-40 rounded-sm mb-20 px-30 pt-20 pb-30 lg:px-15"
    >
      <div
        class="text-22 font-semibold leading-36 mb-20 lg:text-18 lg:leading-30"
      >
        {{ demo.title }}
      </div>

      <div class="text-yellow-100 font-bold leading-25">
        {{ demo.method }} {{ demo.url }}
      </div>
      <div class="text-white-500 text-12 leading-20 mt-4">{{ demo.desc }}</div>

      <div class="mt-20 -mb-4">
        <div v-for="(param, name) in demo.params" :key="name" class="mb-4">
          <span class="text-yellow-100">{{ name }}:</span> {{ param.title }}
        </div>
      </div>

      <div class="mt-20">
        <div class="text-18 font-semibold leading-30 mb-10">Демо</div>
        <template v-if="!demo.disabled">
          <div class="flex mb-10 flex-wrap">
            <el-button
              v-for="(apiUrl, i) in demo.urls"
              :key="i"
              class="mb-10 api-page__button mr-10"
              @click="getApiDemoOf(apiUrl, demo)"
              >{{ apiUrl }}</el-button
            >
          </div>
          <div class="rounded-sm border border-blue-100 bg-blue-10">
            <div
              v-if="!demo.response"
              class="text-blue-200 py-18 px-20 leading-none"
            >
              Нажмите на демо, чтобы увидеть пример ответа
            </div>
            <el-input
              v-else
              ref="code"
              type="textarea"
              :value="demo.response"
              resize="none"
              class="bg-transparent max-h-500 overflow-auto"
              :autosize="true"
            ></el-input>
          </div>
        </template>
        <div v-else class="text-white-500 text-12 leading-20">
          {{ demo.disabledText }}
        </div>
      </div>
    </article>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { AJAX_GET, AJAX_POST } from "../units/ajax";
import moment from "moment";
import { copyHandle } from "../units/main";

export default {
  name: "API",
  data() {
    return {
      token: "",
      demos: {
        resources: {
          title: "Получение услуг",
          url: "/api/v1/resources",
          urls: [],
          disables: false,
          disabledText: "",
          method: "GET",
          desc: "",
          params: null,
          response: "",
        },
        schedule: {
          title: "Расписание услуг",
          url: "/api/v1/schedule",
          disabled: false,
          disabledText: "У вас пока нет ни одной услуги",
          urls: [],
          method: "GET",
          desc:
            "Необходимо указывать в качестве параметра массив { ids } услуг, для которых будет сформировано расписание.",
          params: {
            ids: {
              title: "Массив id ресурсов (обязательно)",
              value: null,
            },
            filter: {
              title:
                'Тип фильтрации. Может быть "today" или "day". По умолчанию: today',
              value: ["today", "day"],
            },
            day: {
              title:
                'Только при типе фильтрации "day". Дата в формате "DD-MM-YYYY"',
              value: null,
            },
            toDates: {
              title:
                "Возвращает расписание по датам. Значение должно быть 1 (число)",
              value: 1,
            },
            page: {
              title:
                "Номер страницы пагинации расписания (число >= 1). Работает только, если указан параметр { toDates }",
              value: 1,
            },
          },
          response: "",
        },
      },
    };
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  async mounted() {
    await this.getToken();
    this.getDemos();
  },
  methods: {
    jsonObj(data) {
      return JSON.stringify(data, null, 2);
    },
    generateToken() {
      AJAX_POST("/api/integration/token/create").then((response) => {
        this.token = response.data.token;
      });
    },
    clearAPI() {},
    getToken() {
      return AJAX_POST("/api/integration/token").then((response) => {
        this.token = response.data.token;
      });
    },
    copyToken() {
      const copyText = this.$refs.token.$refs.input;
      copyText.select();
      copyText.setSelectionRange(0, 99999); /* For mobile devices */

      /* Copy the text inside the text field */
      document.execCommand("copy");
      // copyText.blur();

      this.$notify({
        title: "Скопировано",
        type: "success",
        showClose: false,
        duration: 3000,
      });
    },
    getApiDemoOf(url, demo) {
      const func = demo.method === "GET" ? AJAX_GET : AJAX_POST;

      func(url, { params: { token: this.token } }).then((response) => {
        console.log(response);
        demo.response = this.jsonObj(response.data);
      });
    },
    getDemos() {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.demos) {
        switch (key) {
          case "schedule":
            this.demos[key].urls.push(
              `${this.demos[key].url}?ids=[${this.user.resources[0].id}]`
            );
            this.demos[key].urls.push(
              `${this.demos[key].url}?ids=[${this.user.resources[0].id}]`
            );
            this.demos[key].urls.push(
              `${this.demos[key].url}?ids=[${this.user.resources[0].id}]&toDates=${this.demos[key].params.toDates.value}&page=${this.demos[key].params.page.value}`
            );
            this.demos[key].urls.push(
              `${this.demos[key].url}?ids=[${
                this.user.resources[0].id
              }]&filter=${
                this.demos[key].params.filter.value[1]
              }&day=${moment().format("YYYY-MM-DD")}`
            );
            break;
          default:
            this.demos[key].urls.push(this.demos[key].url);
            break;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.code-textarea {
  max-width: 90%;
  .el-textarea__inner {
    background-color: transparent;
    padding: 30px;

    @screen lg {
      max-height: 300px;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      color: white;
    }
  }
}

.code-close {
  top: 20px;
  right: 20px;
}

.token-input {
  flex-basis: 525px;

  @screen xl {
    flex-basis: 100%;
  }
}

.api-page {
  .el-button.api-page__button {
    height: auto;
    white-space: normal;
    padding-top: 12px;
    padding-bottom: 12px;
    margin-left: 0;
    word-break: break-all;
  }
}
</style>
