<template>
  <TemplateBlock
    title="Шаг 1 — Выберите услуги"
    is-hidden-back
    @next="$emit('input', value + 1)"
  >
    <Tabs
      :value="widget.service_type"
      :list-link="listPays"
      @input="
        setWidget({ field: 'service_type', value: $event }),
          setWidget({
            field: 'is_ubrir_widget',
            value: widget.service_type === 'services' ? true : false,
          })
      "
    />
    <div v-if="widget.service_type === 'single'">
      <p class="hint-title">Выбор услуги<span class="danger">*</span></p>
      <el-select
        :value="widget.service_id"
        filterable
        placeholder="Выберите услугу"
        class="w-full mb-20"
        @input="setWidget({ field: 'service_id', value: $event })"
      >
        <el-option
          v-for="item in servicesList"
          :key="item.hash"
          :label="item.name"
          :value="item.hash"
        >
        </el-option>
      </el-select>
    </div>
    <div
      v-if="
        widget.service_type === 'multi' || widget.service_type === 'services'
      "
    >
      <div class="title-widget">Выберите услуги</div>
      <el-button size="small" class="all-service" @click="selectAllService">
        {{ isAllSelect ? "Очистить" : "Выбрать все" }}
      </el-button>
      <el-checkbox-group
        :value="widget.service_ids"
        @input="setWidget({ field: 'service_ids', value: $event })"
      >
        <el-checkbox
          v-for="(item, i) in servicesList"
          :key="i"
          :label="item.hash"
        >
          {{ item.name }}
        </el-checkbox>
      </el-checkbox-group>
    </div>
    <switch-custom
      :value="widget.is_partner_widget"
      title="Виджет предоставляется партнеру"
      @input="setWidget({ field: 'is_partner_widget', value: $event })"
    />
    <div v-if="widget.is_partner_widget" class="list-fields">
      <div class="list-fields__row">
        <div class="list-fields__item">
          <p class="hint-title">
            Наименование партнера<span class="danger">*</span>
          </p>
          <el-select
            :value="widget.partner_name"
            filterable
            placeholder="Выберите источник"
            @input="setWidget({ field: 'partner_name', value: $event })"
          >
            <el-option
              v-for="item in partnersList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>

          <!-- <el-input
            :value="widget.partner_name"
            placeholder="Введите наименование"
            @input="setWidget({ field: 'partner_name', value: $event })"
          /> -->
        </div>

        <!-- <div class="list-fields__item">
          <p class="hint-title">Комиссионное вознаграждение</p>
          <el-input
            :value="widget.partner_percent"
            placeholder="Введите сумму"
            @input="setWidget({ field: 'partner_percent', value: $event })"
          >
            <span slot="suffix">%</span>
          </el-input>
        </div> -->
      </div>
    </div>
    <!-- <switch-custom
      :value="widget.service_type"
      title="Виджет для дерева услуг"
      @input="setServiceType"
    /> -->
  </TemplateBlock>
</template>

<script>
import { mapState, mapGetters, mapMutations } from "vuex";
import TemplateBlock from "@/views/Widgets/components/TemplateBlock.vue";

export default {
  name: "SelectService",
  components: { TemplateBlock },
  props: {
    value: {
      type: Number,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("user", ["servicesList", "partnersList"]),
    ...mapState("widget", ["widget"]),
    ...mapGetters("user", ["allServicesId"]),
    ...mapGetters("widget", ["isNew", "isCheckedService"]),
    isAllSelect() {
      return this.servicesList.length === this.widget.service_ids.length;
    },
  },
  created() {
    if (!this.widget.partner_name && this.widget.is_partner_widget !== false) {
      this.setWidget({
        field: "partner_name",
        value: this.partnersList[0].name,
      });
    }
    this.listPays = [
      { title: "Для одной услуги", value: "single" },
      { title: "Для нескольких услуг", value: "multi" },
      // { title: "Виджет для дерева услуг", value: "services" },
    ];
    if (this.isNew) {
      this.setWidget({
        field:
          this.widget.service_type === "single" ? "service_id" : "service_ids",
        value:
          this.widget.service_type === "single"
            ? this.servicesList[0].hash
            : this.allServicesId,
      });
    }
  },
  methods: {
    ...mapMutations("widget", ["setWidget"]),
    selectAllService() {
      this.setWidget({
        field: "service_ids",
        value: this.isAllSelect ? [] : this.allServicesId,
      });
    },
    // setServiceType() {
    //   this.setWidget({
    //     field: "service_type",
    //     value: "services",
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";
::v-deep {
  .tabs__item {
    flex-grow: 0;
  }
  .wrapper-switch {
    margin-bottom: 15px;
  }
  .el-checkbox {
    margin-bottom: 12px;
  }
  .el-checkbox-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
  .el-radio__inner {
    border: 2px solid rgba(255, 255, 255, 0.5);
  }
}

.list-fields {
  margin-bottom: 0;
  &__row {
    margin-bottom: 0;
  }
  &__item {
    display: flex;
    flex-direction: column;
  }
}

.all-service {
  margin-bottom: 20px;
}

.title-widget {
  margin-bottom: 25px;
}
</style>
