<template>
  <div class="wrapper-switch">
    <el-switch
      :value="value"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
    />
    <div class="wrapper-switch__content">
      <div class="wrapper-switch__content-title">
        {{ title }}
      </div>
      <div class="wrapper-switch__content-description">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SwitchCustom",
  props: {
    title: {
      type: String,
      default: "",
    },
    content: {
      type: String,
      default: "",
    },
    value: Boolean,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.wrapper-switch {
  display: flex;
  margin-bottom: 30px;

  &__content {
    margin-left: 15px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;

    &-title {
      font-size: 14px;
      line-height: 24px;
      color: $--color-white;
    }

    &-description {
      font-size: 12px;
      line-height: 20px;
      color: $--color-white-5;
    }
  }
}

::v-deep .el-switch {
  padding-top: 5px;
}
</style>
