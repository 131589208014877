<template>
  <el-dialog
    :visible.sync="show"
    width="50%"
    append-to-body
    @close="$emit('close')"
  >
    <iframe
      v-if="show"
      ref="youtube"
      class="iframe-video"
      :src="`https://www.youtube.com/embed/${videoId}`"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    videoId: String,
  },
};
</script>

<style lang="scss" scoped>
.iframe-video {
  width: 100%;
  height: 500px;
}
@media (max-width: 1000px) {
  .iframe-video {
    height: 300px;
  }
}
@media (max-width: 600px) {
  .iframe-video {
    height: 300px;
  }
  ::v-deep .el-dialog {
    width: 80% !important;
  }
}
@media (max-width: 420px) {
  ::v-deep .el-dialog {
    width: 100% !important;
  }
}
</style>
