/* eslint-disable no-restricted-syntax */
export default {
  namespaced: true,
  state: {
    cancel_icon: {
      height: "8",
      width: "8",
      type: "cancel",
    },
    success_icon: {
      height: "8",
      width: "10",
      type: "success",
    },
    question_icon: {
      height: "10",
      width: "6",
      type: "question",
    },
  },
};
