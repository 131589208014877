<template>
  <el-button
    :class="[{ 'w-auto': text }]"
    :disabled="disabled"
    @click="$emit('click')"
  >
    <svg-icon :icon-name="iconName" :width="width" :height="height"></svg-icon>
    <span v-if="text" class="text">{{ text }}</span>
  </el-button>
</template>
<script>
export default {
  name: "ButtonIcon",
  props: {
    iconName: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "20",
    },
    height: {
      type: String,
      default: "20",
    },
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep.el-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  width: 40px;
  height: 40px;
}
.w-auto {
  width: auto;
  padding: 0 12px;
  &::v-deep span {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
.w-max {
  width: 100%;
  padding: 0 12px;
  &::v-deep span {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}
.text {
  margin-left: 12px;
}
</style>
