<template>
  <div>
    <div class="m-30" v-html="userAgreeText"></div>
  </div>
</template>

<script>
import { AJAX_POST } from "../units/ajax";

export default {
  name: "UserAgreeNew",
  data() {
    return {
      userAgreeText: null,
      nameLink: null,
    };
  },
  created() {
    if (this.$route.name) this.nameLink = this.$route.name;
    this.getUserAgree();
  },
  methods: {
    getUserAgree() {
      AJAX_POST(`/api/${this.nameLink}`).then((response) => {
        this.userAgreeText = response.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
