<template>
  <div
    class="settings__section bg-white-40 rounded-sm px-30 pt-20 pb-30 mb-40 lg:p-15 lg:mb-20"
  >
    <div class="text-22 leading-36 font-semibold text-white-normal mb-2">
      {{ title }}
    </div>
    <div v-if="subtitle" class="text-12 text-white-500 mb-20">
      {{ subtitle }}
    </div>
    <div class="section_content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Title",
    },
    subtitle: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.settings {
  &__section {
    border-radius: 3px;
  }
}
</style>
<style lang="scss">
.section_content {
  display: flex;
  margin-top: 24px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  padding-top: 30px;
  background: none;
}
@media (max-width: 1024px) {
  .section_content {
    flex-direction: column;
  }
}
</style>
