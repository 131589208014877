<template>
  <el-drawer
    :show-close="false"
    :visible="show"
    direction="rtl"
    :size="$mq.phone ? '96%' : '430px'"
    class="bookform"
    @close="$emit('update:isOpen', false)"
  >
    <div
      slot="title"
      class="bookform-header flex items-center justify-between px-15"
    >
      <div class="bookform-header__title text-white-normal py-20 text-16">
        <!-- Добавить сотрудника  -->
        {{ correctUserTitle }}
      </div>
      <svg-icon
        class="bookform-header__close text-white-normal cursor-pointer"
        icon-name="close"
        width="16"
        height="16"
        @click.native="closeModal()"
      ></svg-icon>
    </div>
    <div class="px-15 overflow-x-hidden">
      <el-form
        ref="form"
        :model="form"
        direction="rtl"
        :hide-required-asterisk="true"
        label-position="top"
        :rules="formAddingRules"
        @keyup.native.enter="createPersonal()"
      >
        <el-row :gutter="40" class="mb-20">
          <el-col :span="$mq.tablet ? 24 : 12">
            <el-form-item label="E-mail" class="mb-0 lg:mb-20" prop="email">
              <el-input
                v-model="form.email"
                type="email"
                placeholder="Введите e-mail сотрудника"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="$mq.tablet ? 24 : 12">
            <el-form-item label="Имя" class="mb-0" prop="name">
              <el-input
                v-model="form.name"
                type="text"
                placeholder="Введите имя сотрудника"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :span="$mq.tablet ? 24 : 12">
            <el-form-item label="Пароль" class="mb-0 lg:mb-20" prop="password">
              <el-input
                v-model="form.password"
                type="password"
                placeholder="Придумайте пароль"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="$mq.tablet ? 24 : 12">
            <el-form-item
              label="Повтор пароля"
              class="mb-0"
              prop="password_confirmation"
            >
              <el-input
                v-model="form.password_confirmation"
                type="password"
                placeholder="Повторите пароль"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="pt-30 pb-10">
          <div class="flex items-center mb-5 text-white-normal">
            За какие услуги отвечает
          </div>
        </div>
        <div>
          <el-button
            size="medium"
            class="mb-20 xs:w-full px-12"
            @click="toggleAll"
            >{{
              user.resources_count === form.resources.length
                ? "Снять все"
                : "Выбрать все"
            }}</el-button
          >
          <el-form-item prop="resources" class="mb-0">
            <el-checkbox-group v-model="form.resources" class="flex flex-col">
              <el-checkbox
                v-for="(resource, index) in sortResources"
                :key="index"
                :label="resource.hash"
                class="mb-10"
                >{{ resource.name }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
        </div>
        <div class="modal__actions">
          <el-button :loading="loading" type="primary" @click="createPersonal"
            >Создать сотрудника</el-button
          >
        </div>
      </el-form>
    </div>
  </el-drawer>
  <!-- </Modal> -->
</template>

<script>
// import Modal from "@/components/Modal";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    // Modal,
  },
  props: {
    correctUserTitle: Boolean,
    show: Boolean,
    person: {
      type: Object,
    },
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Введите пароль"));
      } else if (value.length < 8) {
        callback(new Error("Минимальная длина пароля 8 символов"));
      } else {
        if (this.form.password_confirmation !== "") {
          this.$refs.form.validateField("password_confirmation");
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Введите пароль повторно"));
      } else if (value.length < 8) {
        callback(new Error("Минимальная длина пароля 8 символов"));
      } else if (value !== this.form.password) {
        callback(new Error("Пароли не совпадают"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      form: {
        email: "",
        name: "",
        password: "",
        password_confirmation: "",
        resources: [],
      },
      formAddingRules: {
        email: [
          {
            type: "email",
            required: true,
            message: "Введите правильный E-mail",
            trigger: ["blur", "change"],
          },
        ],
        name: [
          {
            required: true,
            min: 1,
            message: "Введите имя",
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            required: true,
            validator: validatePass,
          },
        ],
        password_confirmation: [
          {
            required: true,
            validator: validatePass2,
          },
        ],
        resources: [
          {
            type: "array",
            required: true,
            message: "Выберите хотя бы одну услугу",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState("user", ["user"]),

    sortResources() {
      return [...this.user.resources].sort((a, b) => a.position - b.position);
    },
  },
  watch: {
    person() {
      this.setPerson();
    },
  },
  methods: {
    ...mapActions("personals", ["create"]),
    closeModal() {
      this.$emit("close");
      this.form = {
        email: "",
        name: "",
        password: "",
        password_confirmation: "",
        resources: [],
      };
    },
    setPerson() {
      if (this.person) {
        const { name, email, resources } = this.person;
        this.form = { name, email, resources };
      }
    },
    toggleAll() {
      if (this.user.resources_count !== this.form.resources.length) {
        const ids = Object.values(this.user.resources).map((el) => el.hash);
        this.form.resources = ids;
      } else this.form.resources = [];
    },
    createPersonal() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          const success = await this.create(this.form);
          this.loading = false;
          if (success) this.$emit("created");
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "element-variables.scss";
.modal {
  &__actions {
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 20px;
    margin-top: 20px;
  }
}
</style>
