<template>
  <el-form hide-required-asterisk label-position="top">
    <h4 class="title">Настройки расписания</h4>
    <el-row :gutter="$mq.large ? 10 : 30" class="flex-wrap" type="flex">
      <el-col :span="$mq.large ? 24 : 12">
        <el-form-item prop="age_limit">
          <div slot="label" class="flex">
            Количество дней в расписании<span class="danger">*</span>
            <el-popover
              class="ml-8"
              placement="top"
              width="200"
              trigger="click"
              content="Укажите кол-во дней, которое будет выведено в расписании на странице бронирования в личном кабинете."
            >
              <Tooltip slot="reference" />
            </el-popover>
          </div>
          <el-input
            v-model.number="days_schedule_admin"
            type="number"
            :select-value="days_schedule_admin"
            :placeholder="placeholder"
            @blur="autoSave({ type: 'days_schedule_admin' })"
          >
            <div slot="suffix">дней</div>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="$mq.large ? 24 : 12">
        <el-form-item prop="age_limit">
          <div slot="label" class="flex">
            Количество дней в виджете<span class="danger">*</span>
            <el-popover
              class="ml-8"
              placement="top"
              width="200"
              trigger="click"
              content="Укажите количество дней, которое будет выведено в расписании в виджете бронирования."
            >
              <Tooltip slot="reference" />
            </el-popover>
          </div>
          <el-input
            v-model.number="days_schedule_widget"
            :select-value="days_schedule_widget"
            type="number"
            :placeholder="placeholder"
            @blur="autoSave({ type: 'days_schedule_widget' })"
          >
            <div slot="suffix">дней</div>
          </el-input>
        </el-form-item>
        <el-form-item prop="age_limit">
          <div slot="label" class="flex">
            Количество дней по API<span class="danger">*</span>
            <el-popover
              class="ml-8"
              placement="top"
              width="200"
              trigger="click"
              content="Укажите количество дней, которое будет доступно для бронирования по API. Максимальное значение: 56 дней."
            >
              <Tooltip slot="reference" />
            </el-popover>
          </div>
          <el-input
            v-model.number="days_schedule_api"
            :select-value="days_schedule_api"
            :max="5"
            type="number"
            :placeholder="placeholder"
            @blur="autoSave({ type: 'days_schedule_api' })"
          >
            <div slot="suffix">дней</div>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="$mq.large ? 24 : 12">
        <!--  -->
        <switch-custom
          v-model="next_day_booking"
          title="Разрешать бронировать только на следующий день"
          @change="autoSave({ type: 'next_day_booking' })"
        >
          В расписании временные слоты на текущий день будут не доступны для
          бронирования.
        </switch-custom>
      </el-col>
      <el-col v-if="!next_day_booking" :span="$mq.large ? 24 : 12">
        <el-form-item prop="age_limit">
          <div slot="label" class="flex">
            Автоблокировка сеанса
            <el-popover
              class="ml-8"
              placement="top"
              width="200"
              trigger="click"
              content="Укажите, за какое количество минут до начала услуги сеанс станет недоступен для бронирования."
            >
              <Tooltip slot="reference" />
            </el-popover>
          </div>
          <el-input
            v-model.number="autolock"
            :select-value="autolock"
            type="number"
            :placeholder="placeholder"
            @blur="autoSave({ type: 'autolock' })"
          >
            <div slot="suffix">мин</div>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="$mq.large ? 24 : 12">
        <el-form-item prop="age_limit">
          <div slot="label" class="flex">
            Время автоотмены заказа
            <el-popover
              class="ml-8"
              placement="top"
              width="200"
              trigger="click"
              content="Укажите количество часов, через которое заказ будет автоматически отменена, если администратор не перевел статус заказа из состояния «Новый» в состояние «Подтвержден»."
            >
              <Tooltip slot="reference" />
            </el-popover>
          </div>
          <el-input
            v-model.number="autocancel"
            :select-value="autocancel"
            type="number"
            :placeholder="placeholder"
            @blur="autoSave({ type: 'autocancel' })"
          >
            <div slot="suffix">часов</div>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="$mq.large ? 24 : 12">
        <el-form-item prop="age_limit">
          <div slot="label" class="flex">
            Закрывать время До бронирования за N минут
            <el-popover
              class="ml-8"
              placement="top"
              width="200"
              trigger="click"
              content="Например, если клиент бронирует на 12:00, а услуга длится 1 час и вы установили закрывать время за 60 минут, то время с 11:00 до 12:00 станет не доступным для бронирования."
            >
              <Tooltip slot="reference" />
            </el-popover>
          </div>
          <el-input
            v-model.number="close_before"
            :select-value="close_before"
            type="number"
            :placeholder="placeholder"
            @blur="autoSave({ type: 'close_before' })"
          >
            <div slot="suffix">мин</div>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="$mq.large ? 24 : 12">
        <el-form-item prop="age_limit">
          <div slot="label" class="flex">
            Закрывать время После бронирования на N минут
            <el-popover
              class="ml-8"
              placement="top"
              width="200"
              trigger="click"
              content="Например, если клиент бронирует на 12:00, а услуга длится 1 час и вы установили закрывать время после бронирования на 60 минут, то время с 13:00 до 14:00 станет не доступным для бронирования."
            >
              <Tooltip slot="reference" />
            </el-popover>
          </div>
          <el-input
            v-model.number="close_after"
            :select-value="close_after"
            type="number"
            :placeholder="placeholder"
            @blur="autoSave({ type: 'close_after' })"
          >
            <div slot="suffix">мин</div>
          </el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <div v-if="!isTab" class="hl"></div>
    <el-button
      v-if="!isTab"
      type="primary"
      class="custom-button"
      @click="submit()"
    >
      Сохранить
    </el-button>
  </el-form>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { listDuration } from "@/helpers/services.js";

export default {
  name: "AddSettings",
  components: {},
  props: {
    isTab: Boolean,
  },
  data() {
    return {
      next_day_booking: null,
      autolock: null,
      autocancel: null,
      close_after: null,
      close_before: null,
      days_schedule_admin: null,
      days_schedule_widget: null,
      days_schedule_api: null,
    };
  },
  computed: {
    ...mapState("service", ["settings", "settingsSchedule"]),
  },
  watch: {
    settingsSchedule() {
      this.setData();
    },
    days_schedule_api() {
      if (this.days_schedule_api > 56) this.days_schedule_api = 56;
    },
  },
  created() {
    this.listDuration = listDuration;
    this.placeholder = "Введите количество";
    this.setData();
  },
  methods: {
    ...mapActions("service", [
      "saveSettingsSchedule",
      "updateSettingsSchedule",
    ]),

    setData() {
      if (this.settingsSchedule) {
        this.next_day_booking = this.settingsSchedule.next_day_booking;
        this.autolock = this.settingsSchedule.autolock;
        this.autocancel = this.settingsSchedule.autocancel;
        this.close_after = this.settingsSchedule.close_after;
        this.close_before = this.settingsSchedule.close_before;
        this.days_schedule_admin = this.settingsSchedule.days_schedule_admin;
        this.days_schedule_widget = this.settingsSchedule.days_schedule_widget;
        this.days_schedule_api = this.settingsSchedule.days_schedule_api;
      }
    },
    submit() {
      this.saveSettingsSchedule({
        data: {
          next_day_booking: this.next_day_booking,
          autolock: this.autolock,
          autocancel: this.autocancel,
          close_after: this.close_after,
          close_before: this.close_before,
          days_schedule_admin: this.days_schedule_admin,
          days_schedule_widget: this.days_schedule_widget,
          days_schedule_api: this.days_schedule_api,
        },
      });
    },
    autoSave({ type }) {
      if (this.isTab) this.updateSettingsSchedule({ type, value: this[type] });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

::v-deep .el-input__suffix {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: $--color-white-5;
  }
}

@screen lg {
  .title {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 28px;
  }
}
</style>
