<template>
  <div class="pt-40 pb-80 w-full max-w-lg resource lg:pt-15">
    <div class="lg:px-15">
      <el-button size="medium" class="mb-30 lg:mb-20 px-12" @click="goBack"
        ><span class="flex items-center">
          <svg-icon
            icon-name="arrow"
            class="text-white-normal rotate-180 mr-10"
            width="13"
            height="8"
          ></svg-icon
          >{{ mode === "single" ? "К настройкам интеграции" : "К виджетам" }}
        </span></el-button
      >

      <h1
        class="text-28 leading-44 font-semibold text-white-normal mb-20 lg:text-22 lg:leading-36"
      >
        Создание виджета для интеграции в группу Вконтакте
      </h1>
      <el-alert
        title="Виджет создан"
        type="success"
        class="mb-40"
        :closable="false"
        show-icon
      >
      </el-alert>
    </div>

    <div class="bg-white-40 p-30 lg:px-15">
      <change-widget-mode v-if="mode === 'multiple'"></change-widget-mode>
      <div class="leading-25 mb-3">Инструкция по интеграции виджета в ВК</div>
      <div class="text-white-500 text-12 leading-16 mb-20">
        Чтобы ваше расписание стало доступно для бронирования в группе в ВК,
        скачайте инструкцию по интеграции виджета в ВК и действуйте в
        соответствии с информацией, изложенной в ней.
      </div>
      <el-button class="ml-auto pl-15 pr-15 mb-30">
        <div class="btn-content flex items-center">
          <svg-icon
            icon-name="docs-thin"
            width="14"
            height="18"
            class-name="mr-10 text-green-400"
          ></svg-icon>
          <span>Открыть файл PDF</span>
        </div>
      </el-button>
      <div class="leading-25 mb-10">Код виджета для ВК для одной услуги</div>
      <div
        class="rounded-sm border border-blue-100 bg-blue-10 widget-code widget-code__short mb-20"
      >
        <el-input
          ref="code"
          type="textarea"
          :value="value"
          resize="none"
          class="bg-transparent max-h-500 overflow-auto text-12"
          :autosize="{ minRows: 4 }"
        ></el-input>
      </div>
      <div class="flex items-center flex-wrap">
        <el-button
          class="px-15 lg:w-full mr-24 lg:mb-10 lg:mr-0"
          @click="copyCode"
        >
          <div class="flex items-center justify-center">
            <svg-icon
              icon-name="copy"
              class-name="text-white-normal mr-8"
              width="16"
              height="18"
            ></svg-icon
            >Скопировать код
          </div></el-button
        >
        <div
          class="border-b border-white-350 border-dashed cursor-pointer lg:selt-center"
        >
          Смотреть пример
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChangeWidgetMode from "../components/ChangeWidgetMode";

export default {
  name: "CreateVkWidget",
  components: { ChangeWidgetMode },
  data() {
    return {
      value: "https://broni.biz/order/vk/?questId=24&userId=9&theme=gray",
      mode: "single",
    };
  },
  mounted() {
    this.mode = this.$route.query.mode || "single";
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss">
.widget-code {
  &__short {
    max-width: 525px;
  }
}
</style>
