var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"widget",staticClass:"custom-block schedule",style:({ height: _vm.isShowForm ? (_vm.formHeight + "px") : false })},[_c('div',[_c('div',{staticClass:"flex mb-30 flex-row swiper__wrapper",class:{ 'flex-col': _vm.isMaket }},[_c('div',{staticClass:"schedule__dates-datepicker sm:mb-10",style:({
           background: _vm.isIndividualTheme ? _vm.custom.background_cells_fields : '',
           color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
           width: _vm.$mq.largePhone ? '100%' : '',
         })},[_c('el-date-picker',{class:{ customPoper: _vm.isIndividualTheme },attrs:{"type":"date","clearable":false,"format":"dd.MM.yyyy","picker-options":_vm.scheduleDateOptions,"placeholder":"Выберите дату"},on:{"change":function($event){return _vm.changeScheduleDate($event)}},model:{value:(_vm.scheduleDate),callback:function ($$v) {_vm.scheduleDate=$$v},expression:"scheduleDate"}})],1),_c('div',{staticClass:"schedule__dates-swiper",class:{ 'isMaket-swiper': _vm.isMaket },style:({ width: _vm.$mq.largePhone ? '100%' : '' })},[_c('div',{staticClass:"schedule__slide-prev",style:({
             background: _vm.isIndividualTheme
               ? _vm.custom.background_cells_fields
               : '',
             color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
           }),on:{"click":function($event){return _vm.sliderNavigate({ isMore: false })}}},[_c('svg-icon',{attrs:{"icon-name":"chevron","class-name":"rotate-minus-90","width":"12","height":"7"}})],1),_c('swiper',{ref:"swiperDates",staticClass:"swiper",attrs:{"options":_vm.swiperOption}},_vm._l((_vm.slides),function(slide,index){return _c('swiper-slide',{key:("slide-" + index),class:{ active: slide.active },style:([
               (_vm.widgetPublic.typeTheme === 'light' && slide.active) ||
               (_vm.widgetPublic.typeTheme === 'dark' && slide.active) ||
               (_vm.widgetPublic.typeTheme === 'individual' && slide.active)
                 ? _vm.customBlockActive
                 : _vm.customBlock ]),nativeOn:{"click":function($event){return _vm.clickOnSlider(slide, index)}}},[_c('span',{staticClass:"schedule__date",class:{ active: slide.active },style:({
                 color:
                   _vm.isIndividualTheme && !slide.active
                     ? _vm.custom.active_text_color
                     : _vm.custom.button_text_color,
               })},[_vm._v(" "+_vm._s(_vm.slideDate(slide.date))+" ")]),_c('span',{staticClass:"schedule__day",style:({
                 color:
                   (_vm.widgetPublic.typeTheme === 'light' && slide.active) ||
                   (_vm.widgetPublic.typeTheme === 'dark' &&
                     slide.active &&
                     !slide.active)
                     ? _vm.custom.button_text_color
                     : _vm.custom.inactive_text_color,
               })},[_vm._v(" "+_vm._s(slide.weekDay)+" ")])])}),1),_c('div',{staticClass:"schedule__slide-next",style:({
             background: _vm.isIndividualTheme
               ? _vm.custom.background_cells_fields
               : '',
             color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
           }),on:{"click":function($event){return _vm.sliderNavigate({ isMore: true })}}},[_c('svg-icon',{attrs:{"icon-name":"chevron","class-name":"rotate-90","width":"12","height":"7"}})],1)],1)]),(_vm.errorMsgDate && !_vm.isMaket)?_c('div',{staticClass:"flex justify-center",style:({ color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '' })},[_vm._v(" "+_vm._s(_vm.errorMsgDate)+" ")]):_vm._e(),(_vm.isLoading && !_vm.errorMsgDate && !_vm.isMaket)?_c('div',[(_vm.isLoading)?_c('spinner',{attrs:{"custom-color":{ border: ("" + (_vm.custom.active_buttons_background)) }}}):_vm._e(),_c('div',{staticClass:"flex justify-center mt-10"},[_c('span',{staticClass:"text-14",style:({
             color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
           })},[_vm._v("Подождите, пожалуйста. Загружаем расписание")])])],1):_c('div',{staticClass:"list-item"},_vm._l((_vm.schedule),function(item,i){return _c('div',{key:("schedule-" + i),staticClass:"item"},[_c('div',{staticClass:"item__header"},[(_vm.isMaket ? _vm.widget.showPhoto : item.photo && _vm.isShowPhoto)?_c('div',_vm._l((item.photo.length > 1
                 ? item.photo.splice(1, 2)
                 : item.photo),function(img,idx){return _c('div',{key:idx,staticClass:"wrapper__photo"},[(
                   _vm.isMaket
                     ? _vm.widget.showPhoto && img.src
                     : _vm.isShowPhoto && img.src
                 )?_c('img',{attrs:{"width":"130","height":"90","src":img.src,"alt":""}}):_vm._e()])}),0):_vm._e(),_c('div',{staticClass:"item__header-info"},[(item.name)?_c('p',{staticClass:"item__header-name",style:({
                 color: _vm.isIndividualTheme ? _vm.custom.active_text_color : 'red',
               })},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e(),(
                 _vm.isMaket ? _vm.widget.showAddress : _vm.isShowAddress || item.address
               )?_c('p',{staticClass:"item__header-address",style:({
                 color: _vm.isIndividualTheme ? _vm.custom.inactive_text_color : '',
               })},[_vm._v(" "+_vm._s(item.address)+" ")]):_vm._e(),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"item__header-tags mr-10"},[_c('div',{staticClass:"mb-10 p-5 rounded-sm cursor-pointer",style:({
                     color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
                     background: _vm.isIndividualTheme
                       ? _vm.custom.background_cells_fields
                       : '',
                   }),attrs:{"title":item.age_comments}},[_vm._v(" "+_vm._s(item.age_limit)+" ")])]),(_vm.isMaket ? _vm.widget.showTag : _vm.isShowTags || item.tags)?_c('div',{staticClass:"item__header-tags mr-10"},_vm._l((item.tags),function(tag,i){return _c('tag',{key:("tag-" + i),style:({
                     color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
                     background: _vm.isIndividualTheme
                       ? _vm.custom.background_cells_fields
                       : '',
                   })},[_vm._v(" "+_vm._s(tag)+" ")])}),1):_vm._e()])])]),_c('div',{staticClass:"item__schedule"},[(item.schedule.success === false)?_c('div',[_c('no-data',{style:({
                 color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
               }),attrs:{"size":"small","text":item.schedule.msg || 'Расписание на этот день отсутствует'}})],1):_c('div',{staticClass:"item__schedule"},_vm._l((item.schedule),function(slot,index){return _c('CellSchedule',{key:index,attrs:{"value":slot,"is-maket":_vm.isMaket,"custom-style":_vm.custom,"size":_vm.isIndividualTheme ? _vm.custom.font_size : 'small',"is-type-theme":_vm.isIndividualTheme,"show-price":""},on:{"click":function($event){return _vm.onClick(item, slot)}}})}),1)])])}),0),_c('div',{staticClass:"relative"},[(_vm.isShowForm)?_c('el-dialog',{ref:"widgetDialogModal",staticClass:"customModal",attrs:{"visible":_vm.isShowForm,"show-close":false},on:{"update:visible":function($event){_vm.isShowForm=$event}}},[_c('TemplateFormBron',{staticClass:"w-full",attrs:{"title":_vm.isMaket ? "Игра престолов" : _vm.selectCell.name,"style-custom":_vm.custom,"is-type-theme":_vm.isIndividualTheme,"total-price":_vm.isMaket ? 1600 : _vm.totalPrice,"list-field-show":_vm.widgetPublic.fieldForm,"select-cell":_vm.selectCell,"is-maket":_vm.isMaket,"loading":_vm.loading,"disabled":_vm.isDisabled},on:{"close":function($event){_vm.isShowForm = false},"booking":_vm.broni}},[_c('div',{staticClass:"row-info xs:text-12"},[_c('div',{staticClass:"row-info__title",style:({
                 color: _vm.isIndividualTheme ? _vm.custom.inactive_text_color : '',
               })},[_vm._v(" Дата ")]),_c('div',{staticClass:"row-info__value",style:({
                 color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
               })},[_vm._v(" "+_vm._s(_vm.isMaket ? "04.02.2021" : _vm.formatedDate)+" ")])]),_c('div',{staticClass:"row-info xs:text-12"},[_c('div',{staticClass:"row-info__title",style:({
                 color: _vm.isIndividualTheme ? _vm.custom.inactive_text_color : '',
               })},[_vm._v(" Время ")]),_c('div',{staticClass:"row-info__value",style:({
                 color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
               })},[_vm._v(" "+_vm._s(_vm.isMaket ? "10:30" : _vm.selectCell.time)+" ")])]),(_vm.selectCell.price > 0)?_c('div',{staticClass:"row-info xs:text-12"},[_c('div',{staticClass:"row-info__title",style:({
                 color: _vm.isIndividualTheme ? _vm.custom.inactive_text_color : '',
               })},[_vm._v(" Стоимость ")]),_c('div',{staticClass:"row-info__value",style:({
                 color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
               })},[_vm._v(" "+_vm._s(_vm.originData.priceAfter)+" "+_vm._s(_vm.isMaket ? "1 600" : _vm.selectCell.price)+" "+_vm._s(_vm.selectCell.currency)+" "+_vm._s(_vm.originData.priceBefore)+" "+_vm._s(_vm.originData.component.name === "DepTarifSeats" ? "за место" : "")+" ")])]):_vm._e(),(_vm.originData.isHidePrice && _vm.selectCell.discount)?_c('div',{staticClass:"row-info xs:text-12"},[_c('div',{staticClass:"row-info__title",style:({
                 color: _vm.isIndividualTheme ? _vm.custom.inactive_text_color : '',
               })},[_vm._v(" Скидка ")]),_c('div',{staticClass:"row-info__value",style:({
                 color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
               })},[_vm._v(" "+_vm._s(_vm.isMaket ? "20%" : _vm.selectCell.discount ? _vm.selectCell.discount_value : _vm.selectCell.price)+" "+_vm._s(_vm.selectCell.discount_type === "percent" ? "%" : ("" + (_vm.selectCell.currency)))+" ")])]):_vm._e(),_c('div',{staticClass:"hl",style:({
               background: _vm.widgetPublic.typeTheme
                 ? _vm.custom.background_cells_fields
                 : '',
             })}),_c(_vm.originData.component,{tag:"components",attrs:{"select-cell":_vm.selectCell,"hash":_vm.selectCell,"settings":_vm.selectCell.BookingType,"slots-validity":_vm.slotsValidity,"custom-style":_vm.custom,"is-theme-widget":_vm.widgetPublic.typeTheme},on:{"isDis":_vm.isDis,"setPrice":_vm.setPrice,"setService":_vm.setService,"setTariff":_vm.setTariff,"setTickets":_vm.setTickets,"setHours":_vm.setHours,"setPlaces":_vm.setPlaces,"setDiscount":_vm.setDiscount,"setRent":_vm.setRent,"setRentInfo":_vm.setRentInfo,"SetForGroup":_vm.setForGroup,"setSlotParam":_vm.getSlotParam},model:{value:(_vm.person),callback:function ($$v) {_vm.person=$$v},expression:"person"}}),(_vm.showHeaderAddOptions)?_c('div',{staticClass:"title-widget",style:({
               color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
             })},[_vm._v(" Дополнительные опции ")]):_vm._e(),_c('div',{staticClass:"bk-full"},[_c('el-checkbox-group',{model:{value:(_vm.addOptCheck),callback:function ($$v) {_vm.addOptCheck=$$v},expression:"addOptCheck"}},_vm._l((_vm.listAddOptCheck),function(item,i){return _c('el-checkbox',{key:("addOptCheck-" + i),staticClass:"mb-20",class:[
                   {
                     'custom-style':
                       _vm.widgetPublic.typeTheme === 'light' ||
                       _vm.widgetPublic.typeTheme === 'dark' ||
                       _vm.widgetPublic.typeTheme === 'individual',
                   },
                   {
                     'single-grid':
                       _vm.widgetPublic.typeTheme === 'light' ||
                       _vm.widgetPublic.typeTheme === 'dark' ||
                       _vm.widgetPublic.typeTheme === 'individual',
                   } ],attrs:{"label":item}},[_c('span',{staticClass:"custom-check__title",style:({
                     color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
                   })},[_vm._v(_vm._s(item.name))]),(item.price)?_c('span',{staticClass:"custom-check__subtitle flex flex-col",style:({
                     color: _vm.isIndividualTheme
                       ? _vm.custom.inactive_text_color
                       : '',
                   })},[_vm._v(" "+_vm._s(item.price)+" "+_vm._s(_vm.selectCell.currency)+" ")]):_vm._e()])}),1),_vm._l((_vm.addOptInput),function(item,i){return _c('div',{key:("input-" + i),staticClass:"input-number",style:({
                 color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
               }),attrs:{"label":1}},[_c('div',{staticClass:"input-number__info"},[_c('span',{staticClass:"input-number__info-title",style:({
                     color: _vm.isIndividualTheme ? _vm.custom.active_text_color : '',
                   })},[_vm._v(_vm._s(item.name))]),_c('span',{staticClass:"input-number__info-subtitle",style:({
                     color: _vm.isIndividualTheme
                       ? _vm.custom.inactive_text_color
                       : '',
                   })},[_vm._v(" "+_vm._s(item.price ? ((item.price) + " ") : "Бесплатно")+" "+_vm._s(_vm.selectCell.currency)+" ")])]),_c('el-input-number',{staticClass:"input-number__input",class:[
                   {
                     'custom-style':
                       _vm.widgetPublic.typeTheme === 'light' ||
                       _vm.widgetPublic.typeTheme === 'dark' ||
                       _vm.widgetPublic.typeTheme === 'individual',
                   },
                   {
                     'single-grid':
                       _vm.widgetPublic.typeTheme === 'light' ||
                       _vm.widgetPublic.typeTheme === 'dark' ||
                       _vm.widgetPublic.typeTheme === 'individual',
                   } ],style:({
                   background:
                     _vm.widgetPublic.typeTheme === 'light' ||
                     _vm.widgetPublic.typeTheme === 'dark' ||
                     _vm.widgetPublic.typeTheme === 'individual'
                       ? _vm.custom.background_cells_fields
                       : '',
                 }),attrs:{"max":item.amount,"min":0},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})],1)}),(_vm.slotsValidity)?_c('div',{staticClass:"my-10"},[(_vm.slotsValidity.msg)?_c('el-alert',{staticClass:"w-full",attrs:{"title":_vm.slotsValidity.msg,"type":"warning","closable":false,"center":""}}):_vm._e()],1):_vm._e()],2)],1)],1):_vm._e()],1)]),(_vm.isShowPay)?_c('el-dialog',{attrs:{"visible":_vm.isShowPay,"show-close":false,"width":"450px","before-close":_vm.reloadWidget},on:{"update:visible":function($event){_vm.isShowPay=$event},"reloadWidget":function($event){_vm.isShowPay = false}}},[(_vm.dataPay.success)?_c('PayWidget',{attrs:{"widget-theme":_vm.widgetPublic.typeTheme,"custom-class":_vm.custom},on:{"close":function($event){_vm.isShowPay = false},"reloadWidget":_vm.reloadWidget}}):_vm._e(),(!_vm.dataPay.success)?_c('PayError',{attrs:{"widget-theme":_vm.widgetPublic.typeTheme,"custom-class":_vm.custom,"data-pay":_vm.dataPay.params,"msg":_vm.dataPay.msg || _vm.dataPay.params.error_title},on:{"close":function($event){_vm.isShowPay = false}}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }