<template>
  <div class="wrapper">
    <spinner v-if="isLoading" />

    <template v-else>
      <div class="pt-40 pb-80 w-full lg:pt-15 px-10">
        <ButtonIcon
          icon-name="back"
          text="К списку услуг"
          class="mb-12"
          @click="$router.push({ name: 'services' })"
        />

        <!-- <h1
        class="font-semibold text-white-normal text-28 leading-44 lg:text-22 lg:leading22 mb-30 xs:mb-5"
      >
        {{
          isNew ? "Добавление новой услуги" : `Редактирование ${service.name}`
        }}
      </h1> -->
        <Top
          :title="
            isNew
              ? 'Добавление новой услуги'
              : `Редактирование
      ${service.name}`
          "
          :font="{ size: 22, leading: 22 }"
        />
        <div class="wrapper__content">
          <div class="wrapper__content-ww">
            <Settings></Settings>
            <div
              :class="[
                'widget-custom',
                { 'widget-custom__open': isShowWidget },
              ]"
            >
              <WidgetService :service="service" />
              <div class="wrapper-hint">
                <div class="wrapper-hint__text">
                  Пример виджета <br />
                  для данной услуги
                </div>
                <svg-icon
                  class="wrapper-hint__icon"
                  icon-name="arrowWidgetUp"
                  class-name="wrapper-hint__icon"
                ></svg-icon>
              </div>
            </div>
            <el-button
              v-if="$mq.tablet"
              :icon="isShowWidget ? 'el-icon-close' : 'el-icon-mobile'"
              :class="['show-widget', { 'show-widget__close': isShowWidget }]"
              @click="isShowWidget = !isShowWidget"
            ></el-button>
          </div>
          <Instructions :docs="docs"></Instructions>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Top from "@/components/Pages/Top";

import Settings from "@/views/Services/Create/components/Settings.vue";
import WidgetService from "@/views/Services/Create/components/WidgetService.vue";
import Instructions from "@/components/Instructions";
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";

export default {
  name: "CreateService",
  components: {
    Top,
    Settings,
    WidgetService,
    Instructions,
  },
  data() {
    return {
      isShowWidget: false,
      isLoading: true,
      docs: [
        {
          title: "Инструкции для квестов",
          description: "Как добавить, настроить и управлять бронированиями",
          linkVideos: true,
        },
        {
          title: "Инструкции для событий и экскурсий",
          description:
            "Как добавить, настроить и управлять бронированием билетов ",
          linkVideos: true,
        },
      ],
    };
  },
  computed: {
    ...mapState("service", ["service"]),
    ...mapState("user", ["user"]),

    ...mapGetters("service", ["isNew"]),
  },
  async created() {
    this.setNewService();
    await this.getTypesActivity();
    await this.getSettingsService();

    const { hash } = this.$route.params;
    // await this.getSessions(hash);
    if (hash) {
      await this.getService(hash);
      await this.getSettings({ hash });
      await this.getSessions({ hash });

      await this.getAllSettings({ hash });
    }
    this.docs = this.user.instructions.services;
    this.isLoading = false;
  },
  methods: {
    ...mapActions("company", ["getTypesActivity"]),
    ...mapActions("settings", ["getSettingsService"]),
    ...mapActions("service", [
      "getService",
      "getSettings",
      "getSessions",
      "getAllSettings",
    ]),
    ...mapMutations("service", ["setNewService"]),
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

::v-deep .create-service-wrapper {
  flex-grow: 1;
  min-width: calc(100% - 420px);
}

.wrapper-hint {
  display: flex;
  flex-direction: row;
  color: $--color-white-5;
  margin-top: 30px;

  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    position: relative;
    top: 8px;
  }
  &__icon {
    margin-left: 10px;
    width: 40px;
    height: 40px;
  }
}

@screen lg {
  ::v-deep .widget {
    margin-left: 0;
  }
  .is-open {
    transform: translateX(0);
  }
  .wrapper-hint {
    display: none;
  }
}
</style>
