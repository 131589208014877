<template>
  <div>
    <div
      v-for="(item, index) in settings.data.group"
      :key="index"
      class="bk-info"
    >
      <p
        class="bk-info__title"
        :style="{
          color: isThemeWidget ? customStyle.active_text_color : '',
        }"
      >
        от {{ item.from }} до {{ item.to }} мест
      </p>
      <p
        class="bk-info__info"
        :style="{
          color: isThemeWidget ? customStyle.active_text_color : '',
        }"
      >
        {{
          isHoliday && item.holidays_price
            ? item.holidays_price + selectCell.weekend_price_additional
            : item.base_price + selectCell.price_additional
        }}
        {{ selectCell.currency }} {{ `за место` }}
      </p>
    </div>
    <div class="bk-full">
      <p
        class="hint-title"
        :style="{
          color: isThemeWidget ? customStyle.active_text_color : '',
        }"
      >
        Количество участников
      </p>
      <el-input-number
        :value="value"
        class="w-full"
        :class="{ 'custom-style': isThemeWidget }"
        :style="{
          background: isThemeWidget ? customStyle.background_cells_fields : '',
        }"
        :min="minCount"
        :max="maxCount"
        @input="changePersons($event)"
      ></el-input-number>
    </div>
  </div>
</template>

<script>
export default {
  name: "DepSeatsPlace",
  props: {
    value: {
      type: [Number, String, Object],
      default: null,
    },
    selectCell: {
      type: Object,
      default: () => {},
    },
    settings: {
      type: Object,
      default: () => {},
    },
    customStyle: { type: Object },
    isThemeWidget: { type: String },
  },
  data() {
    return {
      dateParam: "",
      timeParam: "",
    };
  },
  computed: {
    isHoliday() {
      return this.selectCell.is_holiday;
    },
    maxCount() {
      const lastChild = this.settings.data.group[
        this.settings.data.group.length - 1
      ];
      return Number(lastChild.to) || 99999;
    },
    minCount() {
      const lastChild = this.settings.data.group[
        this.settings.data.group.length - this.settings.data.group.length
      ];

      return Number(lastChild.from) || 99999;
    },
  },
  watch: {
    value() {
      this.$emit("isDis", this.value);
    },
  },
  created() {
    this.$emit("isDis", this.value);
    this.isClass();
    this.dateParam = this.$props.selectCell.date;
    this.timeParam = this.$props.selectCell.time;
  },
  methods: {
    isClass() {
      document.body.style.setProperty(
        "--colorCustom",
        `${this.customStyle.active_text_color}`
      );
      document.body.style.setProperty(
        "--colorCustomBackground",
        `${this.customStyle.inactive_text_color}`
      );
    },
    changePersons(value) {
      this.$emit("input", value);

      const findInterval = this.settings.data.group.find((el) => {
        const to = el.to ? Number(el.to) : this.maxCount;
        return this.value <= to;
      });

      this.$emit(
        "setPrice",
        this.isHoliday ? findInterval.holidays_price : findInterval.base_price
      );
      this.$emit("setSlotParam", {
        resourceHash: this.selectCell.resource_id,
        date: this.dateSlot ?? this.selectCell.date,
        time: this.timeSlot ?? this.selectCell.time,
        book_info: {
          individual: {
            persons: value,
            price: this.isHoliday
              ? findInterval.holidays_price
              : findInterval.base_price,
          },
        },
      });
    },
    emitData(e) {
      this.dateSlot = "";
      this.timeSlot = "";
      if (e.dateParam || e.timeParam) {
        const { dateParam, timeParam } = e;
        this.dateSlot = dateParam;
        this.timeSlot = timeParam;
      } else {
        this.dateSlot = this.selectCell.date;
        this.timeSlot = this.selectCell.time;
      }

      this.$emit("setSlotParam", {
        resourceHash: this.selectCell.resource_id,
        date: this.dateSlot, // this.selectCell.date,
        time: this.timeSlot, // this.selectCell.time,
        cnt: this.hours,
        book_info: {
          individual: {
            persons: e === Number ? e : this.value,
            price: this.selectCell.is_holiday
              ? this.selectCell.price_weekend + this.selectCell.price_additional
              : this.selectCell.price +
                this.selectCell.weekend_price_additional,
          },
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";
:root {
  --colorCustom: "black";
  --colorCustomBackground: "green";
}
.bk-info {
  margin-bottom: 6px;
  &__title {
    flex-grow: 1;
    width: auto !important;
  }
  &__info {
    flex-grow: 0;
  }
}

.custom-style {
  ::v-deep .el-input__inner {
    color: var(--colorCustom);
  }

  ::v-deep .el-input-number__increase,
  ::v-deep .el-input-number__decrease {
    background: var(--colorCustomBackground);
    color: var(--colorCustom);
  }
  ::v-deep .el-input-number__increase:hover,
  ::v-deep .el-input-number__decrease:hover {
    color: var(--colorCustomButton);
  }
}
</style>
