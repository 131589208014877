<template>
  <div class="bk-full">
    <p
      class="title-widget"
      :style="{
        color: isThemeWidget ? customStyle.active_text_color : '',
      }"
    >
      Билеты
    </p>
    <div v-for="(ticket, i) in listTicket" :key="i" class="input-number">
      <div class="input-number__info">
        <span
          class="input-number__info-title"
          :style="{
            color: isThemeWidget ? customStyle.active_text_color : '',
          }"
          >{{ ticket.name }}</span
        >
        <span class="input-number__info-subtitle">
          <span
            :style="{
              color: isThemeWidget ? customStyle.active_text_color : '',
            }"
          >
            {{
              isHoliday && ticket.holidays_price
                ? ticket.holidays_price + selectCell.weekend_price_additional
                : ticket.base_price + selectCell.price_additional
            }}
            {{ selectCell.currency }}
          </span>
          •
          <span
            :style="{
              color: isThemeWidget ? customStyle.inactive_text_color : '',
            }"
            >{{ ticket.tickets_remained }} шт.</span
          >
        </span>
      </div>
      <el-input-number
        v-model="ticket.select_ticket"
        class="input-number__input"
        :class="{ 'custom-style': isThemeWidget }"
        :style="{
          background: isThemeWidget ? customStyle.background_cells_fields : '',
        }"
        :min="0"
        :max="Number(ticket.tickets_remained) + 1"
        :controls="true"
        @input="emitData()"
      />
    </div>
    <el-alert
      v-if="!isSel"
      title="Скорректируйте данные"
      type="warning"
      :closable="false"
      class="w-full"
      center
    />
  </div>
</template>

<script>
import { AJAX_GET, AJAX_POST } from "@/units/ajax";
import { mapState, mapActions } from "vuex";

export default {
  name: "GroupLimitAll",
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    selectCell: {
      type: Object,
      default: () => {},
    },
    settings: {
      type: Object,
      default: () => {},
    },
    hash: { type: Object },
    customStyle: { type: Object },
    isThemeWidget: { type: String },
  },
  data() {
    return {
      listTicket: [],
    };
  },
  computed: {
    ...mapState("widget", ["remainedTickets"]),

    allSelectTicket() {
      return this.listTicket.reduce((sum, current) => {
        return sum + current.select_ticket;
      }, 0);
    },
    isHoliday() {
      return this.selectCell.is_holiday;
    },
    seats() {
      return this.selectCell.seats;
    },
    isSel() {
      const isDis = this.listTicket.find((sel) => sel.select_ticket >= 1);
      this.$emit("isDis", isDis);
      return isDis;
    },
  },
  async created() {
    this.listTicket = this.settings.data.group_ticket_type_limit.map(
      (el, idx) => {
        return idx === 0
          ? { ...el, select_ticket: 1 }
          : { ...el, select_ticket: null };
      }
    );
    // await this.getRemainedTickets({
    //   hash: this.hash.hash,
    //   date: this.selectCell?.date,
    //   time: this.selectCell?.time,
    // });
    // this.getSlotCheck({
    //   book_info: {
    //     tickets: this.listTicket,
    //   },
    //   resourceHash: this.hash.hash,
    //   date: this.selectCell?.date,
    //   time: this.selectCell?.time,
    //   cnt: null,
    // });
  },
  methods: {
    ...mapActions("widget", ["saveBooking", "getRemainedTickets"]),
    async getSlotCheck(param) {
      await AJAX_POST("/api/v1/booking/check_slots_validity", { ...param })
        .then((result) => {
          this.validTick = result.data.tickets.map((e) => e);
          this.listTicket = this.listTicket.map((e, idx) => {
            return idx === 0
              ? {
                  ...e,
                  select_ticket: e.select_ticket,
                  ...this.validTick[idx],
                }
              : {
                  ...e,
                  select_ticket: e.select_ticket,
                  ...this.validTick[idx],
                };
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    emitData() {
      this.$emit(
        "setTickets",
        this.listTicket
          .filter((el) => el.select_ticket)
          .map((item) => {
            return {
              name: item.name,
              price: this.isHoliday
                ? item.holidays_price + this.selectCell.weekend_price_additional
                : item.base_price + this.selectCell.price_additional,
              select_ticket: item.select_ticket,
              id: item.id,
            };
          })
      );
      this.getSlotCheck({
        book_info: {
          tickets: this.listTicket
            // eslint-disable-next-line no-shadow
            .filter((el) => el)
            .map((item) => {
              return {
                name: item.name,
                price: this.isHoliday
                  ? item.holidays_price +
                    this.selectCell.weekend_price_additional
                  : item.base_price + this.selectCell.price_additional,

                select_ticket: item.select_ticket,
                id: item.id,
              };
            }),
        },
        tickets: this.listTicket,

        resourceHash: this.hash.hash,
        date: this.selectCell?.date,
        time: this.selectCell?.time,
        cnt: 1,
      });
      // this.$emit("setSlotParam", {
      //   tickets: this.listTicket,
      //   resourceHash: this.hash.hash,
      //   date: this.selectCell.date,
      //   time: this.selectCell.time,
      //   cnt: null,
      // });
      this.listTicket = this.listTicket.map((e, idx) => {
        return idx === 0
          ? { ...e, select_ticket: e.select_ticket, ...this.validTick[idx] }
          : { ...e, select_ticket: e.select_ticket, ...this.validTick[idx] };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";
:root {
  --colorCustom: "white";
  --colorCustomBackground: "green";
}
.row-info {
  margin-bottom: 20px;
  &__title {
    width: auto;
    flex-grow: 1;
  }
  &__value {
    flex-grow: 0;
  }
}
.input-number__info-subtitle span {
  &:first-child {
    color: $--color-white;
    margin-right: 6px;
  }
  &:last-child {
    color: $--color-white-5;
    margin-left: 6px;
  }
}
.custom-style {
  ::v-deep .el-input__inner {
    color: var(--colorCustom);
  }
  ::v-deep .el-input-number__increase,
  ::v-deep .el-input-number__decrease {
    background: var(--colorInactive);
    color: var(--colorCustom);
  }
  ::v-deep .el-input-number__increase:hover,
  ::v-deep .el-input-number__decrease:hover {
    color: var(--colorCustomButton);
  }
}
</style>
