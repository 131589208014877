import axios from "axios";

axios.defaults.withCredentials = false;

const host =
  process.env.NODE_ENV === "production"
    ? process.env.VUE_APP_PROD_API
    : process.env.VUE_APP_LOCAL_API;

function ajaxGet() {
  let call;

  // eslint-disable-next-line func-names
  return function (url, params) {
    if (params && params.noCancel) {
      return axios.get(host + url, params);
    }
    if (call) {
      call.cancel();
    }
    call = axios.CancelToken.source();
    return axios.get(host + url, { ...params, cancelToken: call.token });
  };
}

function ajaxPost() {
  let call;
  // eslint-disable-next-line func-names
  return function (url, data) {
    if (call) {
      call.cancel();
    }
    call = axios.CancelToken.source();
    return axios.post(host + url, data, { cancelToken: call.token });
  };
}

function ajaxPatch() {
  let call;
  // eslint-disable-next-line func-names
  return function (url, data) {
    if (call) {
      call.cancel();
    }
    call = axios.CancelToken.source();
    return axios.patch(host + url, data, { cancelToken: call.token });
  };
}

function ajaxDelete() {
  let call;
  // eslint-disable-next-line func-names
  return function (url, data) {
    if (call) {
      call.cancel();
    }
    call = axios.CancelToken.source();
    return axios.delete(host + url, data, { cancelToken: call.token });
  };
}

const AJAX_GET = ajaxGet();
const AJAX_POST = ajaxPost();
const AJAX_PATCH = ajaxPatch();
const AJAX_DELETE = ajaxDelete();

export { AJAX_GET, AJAX_POST, AJAX_PATCH, AJAX_DELETE, host };
