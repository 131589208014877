<template>
  <div class="individual">
    <p class="hint-title__medium-white">
      Сеанс бронируется одним клиентом или группой людей. Например, для записи к
      врачу или в салон красоты, для бронирования квеста или других развлечений.
    </p>
    <h4 class="title">Цена и места</h4>
    <div class="individual-radio">
      <el-radio
        v-model="activeRadio"
        label="individual_does_not_depend_on_the_number_of_seats"
      >
        Не зависит от кол-ва мест
      </el-radio>
      <p class="hint-title__medium-white mb-10">
        Цена за услугу является фиксированной за сеанс и не зависит от
        количества мест, фактически просто бронируется время.
      </p>
      <el-form
        v-if="isNotDepSeats"
        ref="baseInfo"
        class="individual-radio__funct"
        hide-required-asterisk
        label-position="top"
      >
        <!-- <p class="hint-title__medium-white">
          Цена за услугу является фиксированной за сеанс и не зависит от
          количества мест, фактически просто бронируется время.
        </p> -->
        <el-row :gutter="$mq.large ? 10 : 30" class="flex-wrap" type="flex">
          <el-col :span="$mq.large ? 24 : 8">
            <InputSelect
              v-model="data0.service_duration"
              prop="durationService"
              :placeholder="indNotSeats[0].placeholder"
              :label="indNotSeats[0].title"
              :list-value="listDuration"
              :select-value="
                data0.time_unit === 'minute' ? 'минут' : data0.time_unit
              "
              @setValue="data0.time_unit = $event"
            ></InputSelect>
          </el-col>
          <el-col :span="16" :offset="16"></el-col>
          <el-col :span="$mq.large ? 24 : 8">
            <DoubleInput
              :value="{
                from: data0.from,
                to: data0.to,
              }"
              :headers="indNotSeats[1]"
              @input="setNumber({ value: $event })"
            ></DoubleInput>
          </el-col>
          <el-col :span="$mq.large ? 24 : 8">
            <el-form-item prop="basePrice">
              <div slot="label" class="flex">
                {{ indNotSeats[2].title }}
                <span v-if="indNotSeats[2].valid" class="danger">*</span>
                <el-popover
                  v-if="indNotSeats[2].popover"
                  class="ml-8"
                  placement="top"
                  width="200"
                  trigger="hover"
                  :content="indNotSeats[2].popoverText"
                >
                  <Tooltip slot="reference" />
                </el-popover>
              </div>
              <el-input
                v-model.number="data0.base_price"
                type="number"
                :placeholder="indNotSeats[2].placeholder"
              >
                <div slot="suffix">₽</div>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="$mq.large ? 24 : 8">
            <el-form-item prop="holidaysPrice">
              <div slot="label" class="flex">
                <div>
                  {{ indNotSeats[3].title }}
                  <span v-if="indNotSeats[3].valid" class="danger">*</span>
                </div>
                <el-popover
                  class="ml-8"
                  placement="top"
                  width="200"
                  trigger="hover"
                  :content="indNotSeats[3].popoverText"
                >
                  <Tooltip slot="reference" />
                </el-popover>
              </div>
              <el-input
                v-model.number="data0.holidays_price"
                type="number"
                :placeholder="indNotSeats[3].placeholder"
              >
                <div slot="suffix">₽</div>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-radio
        v-model="activeRadio"
        label="individual_depends_on_the_number_of_seats"
      >
        Зависит от количества мест
      </el-radio>
      <p class="hint-title__medium-white mb-10">
        Цена указывается за место или группу и зависит от количества участников.
        Можно настроить оплату за дополнительные места.
      </p>
      <div v-if="isDepSeats" class="individual-radio__funct">
        <!-- <p class="hint-title__medium-white">
          Цена указывается за место или группу и зависит от количества
          участников. Можно настроить оплату за дополнительные места.
        </p> -->
        <el-row :gutter="$mq.large ? 10 : 30" class="flex-wrap" type="flex">
          <el-col :span="$mq.large ? 24 : 12">
            <InputSelect
              v-model="data1.service_duration"
              prop="durationService"
              :placeholder="indNotSeats[0].placeholder"
              :label="indNotSeats[0].title"
              :list-value="listDuration"
              :select-value="data0.time_unit"
              @setValue="data0.time_unit = $event"
            />
          </el-col>
          <el-col :span="$mq.large ? 24 : 12">
            <p class="hint-title">Цена</p>
            <Tabs v-model="data1.price" :list-link="listPrice" />
          </el-col>
        </el-row>
        <ListField3
          v-model="data1.group"
          title-button="Добавить группу"
          :headers="data1.price === 'for_a_place' ? indSeat : indGroup"
        ></ListField3>
        <template v-if="data1.price === 'for_a_group'">
          <switch-custom
            v-model="data1.include_surcharge_for_seats"
            title="Включить доплату за места"
          >
            Если предусмотрены дополнительные места, вы можете указать их
            стоимость.
          </switch-custom>
          <ListField3
            v-if="data1.include_surcharge_for_seats"
            v-model="data1.group_surcharge"
            title-button="Добавить доп. места"
            :headers="indAddSeats"
          ></ListField3>
        </template>
      </div>
      <el-radio
        v-model="activeRadio"
        label="individual_depends_on_the_service_and_its_duration"
      >
        Зависит от услуги и ее продолжительности
      </el-radio>
      <p class="hint-title__medium-white mb-10">
        Можно указать любое количество услуг, стоимость за них и
        продолжительность.
      </p>
      <div v-if="isDepServDurat" class="individual-radio__funct">
        <!-- <p class="hint-title__medium-white">
          Можно указать любое количество услуг, стоимость за них и
          продолжительность.
        </p> -->
        <ListField4
          v-model="data2"
          title-button="Добавить услугу"
          :headers="indDuration"
        ></ListField4>
      </div>
      <el-radio
        v-model="activeRadio"
        label="individual_depends_on_tariff_and_number_of_seats"
      >
        Зависит от тарифа и количества мест
      </el-radio>
      <p class="hint-title__medium-white mb-10">
        Можно указать несколько тарифов и для каждого из них задать цены,
        зависящие от кол-ва мест.
      </p>
      <div v-if="isDepTarifSeats" class="individual-radio__funct">
        <!-- <p class="hint-title__medium-white">
          Можно указать несколько тарифов и для каждого из них задать цены,
          зависящие от кол-ва мест.
        </p> -->
        <template v-for="(item, i) in data3">
          <ListField5
            :key="`ListField5-${i}`"
            :value="item"
            title-button="Добавить места"
            :headers="indTarif"
            :show-delet-button="data3.length > 2"
            @input="setData($event, i)"
            @delete="deleteTariff(i)"
          ></ListField5>
        </template>
        <el-button class="custom-button__outline" @click="addField()">
          Добавить тариф
        </el-button>
      </div>
    </div>
    <div v-if="changePrice && schedule.data">
      <el-alert
        v-if="changePrice && schedule.data"
        title="Обратите внимание, что после изменения цены вам необходимо будет заново сгенерировать расписание."
        type="warning"
        show-icon
        :closable="false"
      />
    </div>
  </div>
</template>

<script>
import DoubleInput from "@/components/DoubleInput.vue";
import ListField3 from "@/components/ListField3.vue";
import ListField4 from "@/components/ListField4.vue";
import ListField5 from "@/components/ListField5.vue";
import InputSelect from "@/components/InputSelect.vue";
import { listDuration, listPrice } from "@/helpers/services.js";
import {
  indNotSeats,
  indSeat,
  indGroup,
  indAddSeats,
  indDuration,
  indTarif,
} from "@/helpers/typeSchedule.js";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  name: "Individual",
  components: {
    DoubleInput,
    ListField3,
    ListField4,
    ListField5,
    InputSelect,
  },

  data() {
    return {
      changePrice: false,
      basePriceSetting: 0,
      holidPriceSetting: 0,
      activeRadio: "individual_does_not_depend_on_the_number_of_seats",
      data0: {
        service_duration: 60,
        time_unit: "minutes",
        from: 1,
        to: null,
        base_price: 0,
        holidays_price: null,
      },
      data1: {
        service_duration: 60,
        time_unit: "minutes",
        price: "for_a_place",
        include_surcharge_for_seats: false,
        group: [
          {
            id: null,
            from: 1,
            to: null,
            base_price: 0,
            holidays_price: null,
          },
        ],
        group_surcharge: [
          {
            id: null,
            from: 1,
            to: null,
            base_price: 0,
            holidays_price: null,
          },
        ],
      },
      data2: [
        {
          id: null,
          name: "",
          duration: 60,
          time_unit: "minutes",
          base_price: 0,
          holidays_price: null,
        },
      ],
      data3: [
        {
          id: null,
          tariff_name: "",
          duration: 60,
          time_unit: "minutes",
          data: [
            {
              id: null,
              from: 1,
              to: null,
              base_price: 0,
              holidays_price: null,
            },
          ],
        },
        {
          id: null,
          tariff_name: "",
          duration: 60,
          time_unit: "minutes",
          data: [
            {
              id: null,
              from: 1,
              to: null,
              base_price: 0,
              holidays_price: null,
            },
          ],
        },
      ],
    };
  },
  computed: {
    ...mapState("service", ["settings", "schedule"]),
    ...mapGetters("service", ["isHaveSettings", "isNew"]),

    isNotDepSeats() {
      return (
        this.activeRadio === "individual_does_not_depend_on_the_number_of_seats"
      );
    },
    isDepSeats() {
      return this.activeRadio === "individual_depends_on_the_number_of_seats";
    },
    isDepServDurat() {
      return (
        this.activeRadio ===
        "individual_depends_on_the_service_and_its_duration"
      );
    },
    isDepTarifSeats() {
      return (
        this.activeRadio === "individual_depends_on_tariff_and_number_of_seats"
      );
    },
    activeRadioIndex() {
      return this.radioList.findIndex((item) => item === this.activeRadio);
    },
  },
  watch: {
    data0: {
      handler(newValue) {
        this.checkValuePrice({ newValue });
      },
      deep: true,
    },
  },
  created() {
    this.rules = {
      durationService: [
        { required: true, message: "заполните поле", trigger: ["blur"] },
      ],
      email: [{ required: true, message: "заполните поле", trigger: ["blur"] }],
    };
    this.listDuration = listDuration;
    this.listPrice = listPrice;
    this.indNotSeats = indNotSeats;
    this.indSeat = indSeat;
    this.indGroup = indGroup;
    this.indAddSeats = indAddSeats;
    this.indDuration = indDuration;
    this.indTarif = indTarif;
    this.radioList = [
      "individual_does_not_depend_on_the_number_of_seats",
      "individual_depends_on_the_number_of_seats",
      "individual_depends_on_the_service_and_its_duration",
      "individual_depends_on_tariff_and_number_of_seats",
    ];
    this.setRadio();
    this.basePriceSetting = this.settings.data?.base_price;
    this.holidPriceSetting = this.settings.data?.holidays_price;
  },
  mounted() {},

  methods: {
    ...mapActions("service", ["saveSettings"]),
    checkValuePrice({ newValue }) {
      if (this.isHaveSettings) {
        this.changePrice =
          this.basePriceSetting !== newValue.base_price ||
          this.holidPriceSetting !== newValue.holidays_price;
      }
    },
    setRadio() {
      if (
        this.isHaveSettings &&
        this.radioList.includes(this.settings.booking_type_sub)
      ) {
        this.activeRadio = this.settings.booking_type_sub;
        const index = this.radioList.findIndex(
          (item) => item === this.settings.booking_type_sub
        );
        this[`data${index}`] = this.settings.data;
      } else {
        this.activeRadio = "individual_does_not_depend_on_the_number_of_seats";
      }
    },
    submit() {
      this.$refs.baseInfo.validate((valid) => {
        if (valid) {
          console.log("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    setNumber({ value }) {
      this.data0.from = value.from;
      this.data0.to = value.to;
    },
    addField() {
      this.data3.push({
        tariff_name: "",
        duration: 60,
        time_unit: "minutes",
        data: [
          {
            from: 1,
            to: null,
            base_price: null,
            holidays_price: null,
          },
        ],
      });
    },
    deleteTariff(index) {
      this.data3.splice(index, 1);
    },
    setData(value, index) {
      this.data3.splice(index, 1, { ...value });
    },
    save() {
      this.saveSettings({
        nameRadio: this.activeRadio,
        info: this[`data${this.activeRadioIndex}`],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.individual {
  .hint-title__medium-white {
    padding: 0 0 20px 0;
  }
  .title {
    font-size: 22px;
    line-height: 44px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  &-radio {
    display: flex;
    flex-direction: column;

    &__funct {
      display: flex;
      flex-direction: column;
      margin: -20px 0 0 25px;
    }
  }
}

.custom-button__outline {
  width: fit-content;
}

::v-deep .el-radio {
  margin-bottom: 20px;
}

::v-deep .el-input__suffix {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $--color-white-5;

  svg {
    color: $--color-white-5;
  }
}

::v-deep .el-input-group__prepend {
  padding-right: 10px;
}

::v-deep .tabs .el-radio {
  margin-bottom: 0;
}

@screen lg {
  .individual {
    &-radio__funct {
      margin: 0;
    }
    .hint-title__medium-white {
      padding: 0 0 20px 0;
    }
    .title {
      margin-bottom: 30px;
      font-size: 18px;
      line-height: 28px;
    }
  }
}
</style>
