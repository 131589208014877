<template>
  <div>
    <span class="return__text">{{ `выберите количество ` }}</span>
    <div
      v-for="(ticket, idx) in item.ticket"
      :key="idx"
      class="input-number my-10 flex flex-col"
    >
      <div class="flex">
        <div class="input-number__info">
          <span class="input-number__info-title">{{ ticket.name }}</span>
        </div>
        <el-input-number
          v-model="ticket.select_ticket"
          class="input-number__input"
          :max="maxVal"
          :min="0"
          @input="$emit('setTicketVal', ticket.select_ticket)"
        />
      </div>
      <div class="">
        <span class="custom-check__subtitle">
          {{ ticket.price }} {{ currency }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GroupSeatLimit",
  props: {
    item: {
      type: Object,
    },
    count: String,
    currency: String,
  },

  data() {
    return {
      itemCount: null,
      maxVal: 0,
    };
  },

  created() {
    this.maxVal = this.item.ticket[0].select_ticket;

    // this.itemCount = this.item.ticket[idx].select_ticket;
  },
};
</script>

<style lang="scss" scoped>
.return {
  &__text {
    color: #fff;
  }
}
</style>
