import { setField, setFieldObj, getError } from "@/helpers/store";
import { AJAX_GET, AJAX_DELETE, AJAX_POST, AJAX_PATCH } from "@/units/ajax";

export default {
  namespaced: true,
  state: {
    clients: [
      {
        id: 1,
        date: "29.10.2019",
        time: "21:45:55",
        name: "Иванов Иван Иванович",
        phone: "+7 (904) 451-02-42",
        email: "example@mail.ru",
        reserve_count: 1,
      },
    ],
  },
  mutations: {
    setField,
    setFieldObj,
  },
  actions: {
    async getClients({ commit }) {
      try {
        const { data } = await AJAX_GET("/api/reservation/get/clients");
      } catch (err) {
        commit(
          "setNotif",
          {
            title: "Произошла ошибка, попробуйте еще раз",
            type: "error",
          },
          { root: true }
        );
      }
    },
  },
};
