<template>
  <div class="custom-block">
    <div class="header">Ссылки для агрегаторов</div>
    <div class="hl"></div>
    <div v-for="(link, i) in listLink" :key="i" class="link">
      <p class="hint-title">{{ link.title }}</p>
      <el-input
        ref="token"
        :value="link.link"
        placeholder="Нет ссылки"
      ></el-input>
    </div>
    <el-button @click="copy">Скопировать ссылки</el-button>
  </div>
</template>

<script>
import { mapMutations } from "vuex";

export default {
  name: "IntegrationEntry",
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  created() {
    const { hash } = this.$route.params;
    this.listLink = [
      {
        title: "Ссылка для получения расписания в формате JSON",
        link: `${process.env.VUE_APP_PROD_API}/api/prices/${hash}/true`,
      },
      // {
      //   title: "Ссылка для получения расписания в формате JSON с тарифами",
      //   link: `${process.env.VUE_APP_PROD_API}/api/prices/${hash}/true`,
      // },
      {
        title: "Ссылка для обработки данных о заказе в формате POST",
        link: `${process.env.VUE_APP_PROD_API}/api/create/${hash}`,
      },
    ];
  },
  methods: {
    ...mapMutations(["setNotif"]),
    copy() {
      const text = this.listLink
        .map((el) => `${el.link} - ${el.title}`)
        .join("\n");
      navigator.clipboard.writeText(text);
      this.setNotif({
        title: `Данные скопированны`,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.custom-block {
  padding: 30px;
}
.header {
  font-weight: 600;
  font-size: 22px;
  line-height: 36px;
}
.hl {
  margin-top: 20px;
  margin-bottom: 20px;
}
.link {
  width: 100%;
  padding-right: 0;
  padding-left: 0;
}

::v-deep .el-button {
  margin-top: 20px;
}
</style>
