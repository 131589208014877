<template>
  <div>
    <div v-if="isGroup">
      <div class="flex flex-col justify-between">
        <div class="flex justify-between">
          <span class="order__row-text mb-10">Цена за группу </span>
          <span class="order__row px-5"
            >{{ service.consist.group.price }} {{ service.currency }}
          </span>
        </div>
        <div class="flex justify-between">
          <span class="order__row-text mb-10">Группа </span>
          <span class="order__row px-5"
            >{{
              `от ${service.consist.group.persons_from} до ${service.consist.group.persons_to} человек `
            }}
          </span>
        </div>
        <div class="flex justify-between">
          <span class="order__row-text mb-10">Цена за доп. уч. </span
          ><span class="order__row mb-10"
            >{{ service.consist.group.price_add_persons }}
            {{ service.currency }}
          </span>
        </div>
        <div class="flex justify-between">
          <span class="order__row-text mb-10">Доп. участников </span
          ><span class="order__row mb-10"
            >{{ service.consist.group.add_persons }}
          </span>
        </div>
      </div>
      <!-- addedd option check -->
      <div
        v-for="(item, idx) in isAdditionalCheck"
        :key="idx"
        class="flex flex-row justify-between"
      >
        <!-- <span class="order__row-text mb-10"
          >{{ ` ${item.name}, ${item.selected}  шт.` }}
        </span>
        <span>{{ item.price }} {{ service.currency }} </span> -->
      </div>
    </div>
    <div v-else-if="isForPlace">
      <div class="flex flex-col justify-between">
        <div class="flex justify-between">
          <span class="order__row-text mb-10">Цена за участника </span>
          <span class="order__row px-5"
            >{{ service.consist.individual.price }} {{ service.currency }}
          </span>
        </div>
        <div class="flex justify-between">
          <span class="order__row-text mb-10">Участников </span>
          <span class="order__row px-5"
            >{{ service.consist.individual.persons }}
          </span>
        </div>
      </div>
      <div
        v-for="(item, idx) in isAdditionalInput"
        :key="idx"
        class="flex flex-row justify-between"
      >
        <span class="order__row-text mb-10"
          >{{ ` ${item.name}, ${item.selected}  шт.` }}
        </span>
        <span class="order__row px-5"
          >{{ item.price }} {{ service.currency }}
        </span>
      </div>
    </div>
    <div
      v-if="!isGroup && !isForPlace"
      class="flex justify-between mb-20 flex-col xs:w-full"
    >
      <div class="flex flex-row justify-between">
        <span class="order__row-text mb-10">Тариф: </span>
        <span class="order__row px-5"
          >{{
            isTariff
              ? service.consist.tariff.name
              : service.consist.service.name
          }}
        </span>
      </div>
      <div class="flex flex-row justify-between">
        <span class="order__row-text mb-10">Цена: </span>
        <span class="order__row px-5"
          >{{
            isTariff
              ? service.consist.tariff.price
              : service.consist.service.base_price ||
                service.consist.service.price
          }}
          {{ service.currency }}</span
        >
      </div>
      <div class="flex flex-row justify-between">
        <span class="order__row-text mb-10"> Продолжит. </span>
        <span class="order__row px-5"
          >{{
            isTariff
              ? service.consist.tariff.duration
              : service.consist.service.duration
          }}
          {{
            isTariff
              ? unitChange(service.consist.tariff.unit).textSecond
              : unitChange(service.consist.service.time_unit).textSecond
          }}
        </span>
      </div>
      <div v-if="isTariff" class="flex flex-row justify-between">
        <span class="order__row-text mb-10"> Участников</span>
        <span class="order__row mb-10">
          {{ service.consist.tariff.persons }}</span
        >
      </div>
      <!-- <div class="button__actions pb-30">
                <el-button class="block__button" type="primary" @click="sendPay"
                  >Оплатить онлайн
                </el-button>
              </div> -->
    </div>
    <div class="hl"></div>
    <div class="flex flex-row justify-between pb-30">
      <span class="order__row-text">Итоговая стоимость </span>
      <span>{{ service.final_price }} {{ service.currency }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceOrder",
  props: {
    service: {
      type: Object,
      default: () => {},
    },
    isAdditionalCheck: { type: Array, default: () => {} },
    isAdditionalInput: { type: Array, default: () => {} },
  },
  computed: {
    isTariff() {
      return (
        this.service.booking_type_sub ===
        "individual_depends_on_tariff_and_number_of_seats"
      );
    },
    isGroup() {
      return this.service.booking_type_sub_price_type === "for_a_group";
    },
    isForPlace() {
      return this.service.booking_type_sub_price_type === "for_a_place";
    },
  },
  created() {},
  methods: {
    unitChange(key) {
      switch (key) {
        case "days":
          return {
            text: "дня",
            textSecond: "дней",
            textThird: "день",
          };
        case "hours":
          return {
            text: "часа",
            textSecond: "часов",
            textThird: "час",
          };
        case "hour":
          return {
            text: "часа",
            textSecond: "часов",
            textThird: "час",
          };

        case "minutes":
          return {
            text: "минут",
            textSecond: "минут",
            textThird: "минут",
          };
        case "minute":
          return {
            text: "минут",
            textSecond: "минут",
            textThird: "минут",
          };
        default:
          return "No UNIT";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.order {
  font-weight: 400;
  font-family: Montserrat;
  &__wrap {
    // width: 860px;
    margin-top: 100px;
    margin-bottom: 100px;
  }
  &__block {
    background: rgba(255, 255, 255, 0.04);
    border-radius: 3px;
    margin-bottom: 30px;
    &--top {
      text-align: left;
      margin-bottom: 40px;
    }
  }
  &__row {
    font-size: 12px;
    &-text {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}
</style>
