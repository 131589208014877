<template>
  <div class="wrapper">
    <spinner v-if="isLoading" />
    <div
      v-else-if="service.success === false"
      class="custom-alert not-set-schedule"
    >
      {{ service.msg }}
    </div>
    <template v-else>
      <div class="pt-40 pb-80 w-full lg:pt-15 px-10">
        <div class="">
          <ButtonIcon
            icon-name="back"
            text="К списку услуг"
            class="mb-12"
            @click="$router.push({ name: 'services' })"
          />
        </div>
        <!-- <h1
          v-if="serviceInUserResource"
          class="font-semibold text-white-normal text-28 leading-44 lg:text-22 lg:leading22 mb-30 xs:mb-5"
        >
          {{ serviceInUserResource }} 
          {{ service.name }}
        </h1> -->
        <Top
          v-if="serviceInUserResource"
          :title="service.name"
          :font="{ size: 22, leading: 22 }"
        />
        <div
          v-if="relatedServices(service).name"
          class="inline-flex items-center"
        >
          <div class="mb-10">
            <div
              v-if="relatedServices(service).name"
              class="inline-flex items-center"
            >
              <svg-icon icon-name="overlap" width="20" height="20"></svg-icon>
              <span class="items__bind"
                >{{
                  relatedServices(service).relat === "child"
                    ? "Дочерняя услуга:"
                    : "Родительская услуга:"
                }}
                <span
                  v-for="(serv, idx) in relatedServices(service).name"
                  :key="serv.hash"
                >
                  <router-link
                    class="services-drag__item-icon inline-flex underline px-5"
                    target="_blank"
                    :to="{
                      name: 'resource',
                      params: { hash: serv.hash },
                      query: { tab: 'schedule' },
                    }"
                    >{{ serv.name }}
                    {{
                      relatedServices(service).name.length - 1 === idx
                        ? " "
                        : ","
                    }}</router-link
                  >
                </span></span
              >
            </div>
          </div>
        </div>

        <div class="wrapper__content">
          <div class="wrapper__content-ww">
            <el-tabs v-model="resourceActiveTab" @tab-click="clickOnTab">
              <el-tab-pane name="schedule">
                <div slot="label" class="label">
                  <div class="wrapper-icon">
                    <svg-icon icon-name="eye" width="20" height="20"></svg-icon>
                    <div
                      v-if="isFilled.schedule === false"
                      class="wrapper-icon__point"
                    ></div>
                  </div>
                  <span>Расписание</span>
                </div>
                <div v-if="errorMsg">
                  <div class="custom-alert not-set-schedule">
                    <div>
                      {{ errorMsg }}
                    </div>
                  </div>
                </div>
                <schedule v-else ref="schedule" is-tab />
              </el-tab-pane>
              <el-tab-pane name="description">
                <div slot="label" class="label">
                  <div class="wrapper-icon">
                    <svg-icon
                      icon-name="warning-outline"
                      width="20"
                      height="20"
                    ></svg-icon>
                    <!-- <div class="wrapper-icon__point"></div> -->
                  </div>
                  <span>Описание</span>
                </div>
                <resource-description />
              </el-tab-pane>
              <el-tab-pane name="datetime">
                <div slot="label" class="label">
                  <div class="wrapper-icon">
                    <svg-icon
                      icon-name="datetime"
                      width="20"
                      height="20"
                    ></svg-icon>
                    <div
                      v-if="isFilled.schedule === false"
                      class="wrapper-icon__point"
                    ></div>
                  </div>
                  <span>Даты и цены</span>
                </div>
                <resource-datetime ref="resourceDatetime" />
              </el-tab-pane>
              <el-tab-pane name="settings">
                <div slot="label" class="label">
                  <div class="wrapper-icon">
                    <svg-icon
                      icon-name="settings"
                      width="20"
                      height="20"
                    ></svg-icon>
                    <!-- <div class="wrapper-icon__point"></div> -->
                  </div>
                  <span>Настройки</span>
                </div>
                <resource-settings ref="resourceSettings" />
              </el-tab-pane>
              <el-tab-pane name="notifications">
                <div slot="label" class="label">
                  <div class="wrapper-icon">
                    <svg-icon
                      icon-name="news2"
                      width="20"
                      height="20"
                    ></svg-icon>
                    <div
                      v-if="isFilled.notifications === false"
                      class="wrapper-icon__point"
                    ></div>
                  </div>
                  <span>Уведомления</span>
                </div>
                <resource-notifications ref="resourceNotifications" />
              </el-tab-pane>

              <el-tab-pane name="integration">
                <div slot="label" class="label">
                  <div class="wrapper-icon">
                    <svg-icon
                      icon-name="integration"
                      width="20"
                      height="20"
                    ></svg-icon>
                    <div
                      v-if="isFilled.widgets === false"
                      class="wrapper-icon__point"
                    ></div>
                  </div>
                  <span>Виджеты</span>
                </div>
                <resource-integration ref="resourceWidgets" />
              </el-tab-pane>
            </el-tabs>

            <Widget :class="['widget', { 'is-open': isShowWidget }]"></Widget>

            <!-- <el-button
              v-if="$mq.tablet"
              :icon="isShowWidget ? 'el-icon-close' : 'el-icon-mobile'"
              :class="['show-widget', { 'show-widget__close': isShowWidget }]"
              @click="isShowWidget = !isShowWidget"
            ></el-button> -->
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { AJAX_POST } from "@/units/ajax";
import Top from "@/components/Pages/Top";

import ResourceDatetime from "@/components/ResourceDatetime";
import Schedule from "@/components/Schedule.vue";
import ResourceDescription from "@/components/ResourceDescription";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import ResourceSettings from "@/components/ResourceSettings";
import ResourceNotifications from "@/components/ResourceNotifications";
import ResourceIntegration from "@/components/ResourceIntegration";
import Widget from "@/views/Services/Create/components/WidgetService.vue";

export default {
  name: "Resource",
  components: {
    Top,
    ResourceIntegration,
    ResourceNotifications,
    ResourceSettings,
    Schedule,
    ResourceDatetime,
    ResourceDescription,
    Widget,
  },
  data() {
    return {
      resourceActiveTab: "datetime",
      isShowWidget: false,
      isLoading: true,
      resourceHash: "",
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("service", ["service", "generator", "errorMsg"]),
    ...mapState("resource", ["isFilled"]),
    ...mapGetters("service", ["isHaveSettings"]),
    ...mapGetters("resource", {
      resource: "resource",
    }),
    serviceInUserResource() {
      return (
        this.user.resources.find((e) => e.hash === this.resourceHash).name ||
        this.service.name
      );
    },
  },

  async created() {
    this.setNewService();
    this.resourceActiveTab = this.$route.query.tab || "datetime";
    const { id, hash } = this.$route.params;
    this.resourceHash = hash;
    const { page } = this.$route.query;
    // some methods repeat in  getAllSettings
    await this.getService(hash);
    if (this.service.success === true || this.service.success === undefined) {
      // ДОБАВИЛ ПРИ КЛИКЕ НА ТАБ
      // await this.getSettings({ hash });
      const week = this.$route.query.week ?? this.service.nearestWeek;
      // REPEAT REQUEST IN PROJECT
      // await this.getSchedule({ hash, week });
      // ДОБАВИЛ ПРИ КЛИКЕ НА ТАБ

      // await this.getGenerator(hash);
      await this.getIsFilled(hash);
      // ДОБАВИЛ ПРИ КЛИКЕ НА ТАБ
      // await this.getSettingsService();
      // ДОБАВИЛ ПРИ КЛИКЕ НА ТАБ
      // await this.getTypesActivity();
      // 1.  await this.getSettingsSchedule({ hash });
      // ДОБАВИЛ ПРИ КЛИКЕ НА ТАБ
      // await this.getAllSettings({ hash });
      // ДОБАВИЛ ПРИ КЛИКЕ НА ТАБ
      // await this.getWidgetsService(hash);
      // await this.getSettingWidget();
      // 2. await this.getNotifClient({ hash });
      // 3. await this.getNotifAdmin({ hash });

      // ДОБАВИЛ ИЗ-ЗА НЕОБХОДИМОСТИ ОТПРАВКИ ХЕША СВЯЗАННОЙ УСЛУГИ
      // await this.getSessions(
      //   this.generator.schedule_type === "bind"
      //     ? this.generator.data.bind
      //     : hash
      // );

      if (this.$route.query.tab === "notifications") {
        await this.getNotifClient({ hash });
        await this.getNotifAdmin({ hash });
      }
      if (this.$route.query.tab === "settings") {
        await this.getSettingsService();
        await this.getSettingsSchedule({ hash });
      }
      if (this.$route.query.tab === "integration") {
        await this.getSettingWidget();
        await this.getWidgetsService(hash);
      }
      if (this.$route.query.tab === "description") {
        await this.getTypesActivity();
      }
      if (this.$route.query.tab === "datetime") {
        await this.getSettings({ hash });
        await this.getGenerator(hash);
        await this.getAddDates({ hash, type: "weekend" });
        await this.getAddDates({ hash, type: "nonworking" });
        await this.getAddDates({ hash, type: "slots_date" });

        if (this.generator.schedule_type === "bind") {
          let obj = {
            hash: this.isHaveSettings ? hash : this.generator.data.bind,
            childHash: this.isHaveSettings ? null : hash,
          };
          if (!obj.childHash) {
            obj = { hash };
          }

          console.log(obj);
          await this.getSessions({ ...obj });
        } else {
          await this.getSessions({ hash });
        }
      }

      // REPEAT REQUEST IN PROJECT
      //   await this.getSettings({ hash });
      this.isLoading = false;
    }
    this.isLoading = false;
  },
  methods: {
    ...mapActions("service", [
      "getService",
      "getSettingsSchedule",
      "getSchedule",
      "getAllSettings",
      "getNotifClient",
      "getNotifAdmin",
      "getGenerator",
      "getAddDates",
      "getSessions",
      "getSettings",
    ]),

    ...mapActions("widget", ["getWidgetsService", "getSettingWidget"]),
    ...mapActions("schedule", ["getScheduleByDates"]),
    ...mapActions("settings", ["getSettingsService"]),
    ...mapActions("company", ["getTypesActivity"]),
    ...mapActions("resource", ["getIsFilled"]),

    ...mapMutations("service", ["setNewService"]),

    async clickOnTab(tab) {
      this.$router.push({ query: { tab: tab.name } });
      const { hash } = this.$route.params;
      //  this.isLoading = true;
      if (
        tab.name === "schedule" &&
        (this.service.success === true || this.service.success === undefined)
      ) {
        const week = this.$route.query.week ?? this.service.nearestWeek;
        this.$refs.schedule.getWeekSchedule({ week });
      }
      if (
        tab.name === "datetime" &&
        (this.service.success === true || this.service.success === undefined)
      ) {
        this.$refs.resourceDatetime.$data.isLoading = true;
        await this.getSettings({ hash });
        await this.getGenerator(hash);
        await this.getAddDates({ hash, type: "weekend" });
        await this.getAddDates({ hash, type: "nonworking" });
        await this.getAddDates({ hash, type: "slots_date" });

        // await this.getSessions(
        //   this.generator.schedule_type === "bind"
        //     ? this.generator.data.bind
        //     : hash
        // );
        if (this.generator.schedule_type === "bind") {
          let obj = {
            hash: this.isHaveSettings ? hash : this.generator.data.bind,
            childHash: this.isHaveSettings ? null : hash,
          };
          if (!obj.childHash) {
            obj = { hash };
          }

          console.log(obj);
          await this.getSessions({ ...obj });
        } else {
          await this.getSessions({ hash });
        }
        this.$refs.resourceDatetime.$data.isLoading = false;
      }
      if (
        tab.name === "settings" &&
        (this.service.success === true || this.service.success === undefined)
      ) {
        await this.getSettingsService();
        await this.getSettingsSchedule({ hash });
      }
      if (
        tab.name === "notifications" &&
        (this.service.success === true || this.service.success === undefined)
      ) {
        this.$refs.resourceNotifications.$data.isLoading = true;
        await this.getNotifClient({ hash });
        await this.getNotifAdmin({ hash });
        this.$refs.resourceNotifications.$data.isLoading = false;
      }
      if (
        tab.name === "integration" &&
        (this.service.success === true || this.service.success === undefined)
      ) {
        this.$refs.resourceWidgets.$data.isLoading = true;
        await this.getSettingWidget();
        await this.getWidgetsService(hash);
        this.$refs.resourceWidgets.$data.isLoading = false;
      }
      if (
        tab.name === "description" &&
        (this.service.success === true || this.service.success === undefined)
      ) {
        await this.getTypesActivity();
      }
      this.isLoading = false;
    },

    relatedServices(item) {
      const obj = {};
      if (item.parent_data.length) {
        obj.name = item.parent_data.map((e) => e);
        obj.relat = "parent";
      } else if (Object.keys(item.children_data).length) {
        obj.name = item.children_data.map((e) => e);
        obj.relat = "child";
      }
      return obj;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";
.items__bind {
  color: rgba(255, 255, 255, 0.5);
  padding: 0 10px;
}
::v-deep .el-tabs {
  &__item .label {
    padding: 0 17px;
  }
  &__content {
    height: 100%;
  }
}

.label {
  display: flex;
  align-items: center;
  padding: 0 15px;
  cursor: pointer;
  svg {
    color: #3498db;
  }
  span {
    margin-left: 10px;
  }
  @screen lg {
    font-size: 14px;
    line-height: 24px;
    min-height: 34px;
  }
}

.widget {
  width: 360px;
  flex-shrink: 0;
  height: fit-content;
  position: sticky;
  top: 20px;
  margin: 85px 0 30px 60px;
  z-index: 9;
}

::v-deep .el-tabs {
  flex-grow: 1;
  min-width: calc(100% - 420px);
}
::v-deep .el-tab-pane {
  padding-top: 30px;
  height: inherit;
}

@screen xl {
  .widget {
    margin-left: 50px;
  }
}

@screen lg {
  .widget {
    position: fixed;
    padding-bottom: 40px;
    margin: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    transform: translateX(100%);
    transition: all 0.3s;
    background: $--color-violet-300;
  }
  .is-open {
    transform: translateX(0);
  }
}
</style>
