<template>
  <div class="group">
    <p class="hint-title__medium-white">
      На один и тот же сеанс доступна запись нескольких клиентов. Он остается
      доступным до тех пор, пока есть свободные места. Например, для записи на
      консультации.
    </p>
    <h4 class="title">Цена и места</h4>
    <div class="group-radio">
      <el-radio v-model="activeRadio" label="service_tree_is_given">
        Дерево услуг задается (одинаковый лимит по количеству мест для всех
        сеансов)
      </el-radio>
      <p class="hint-title__medium-white mb-10">
        Задается общее количество мест для всех сеансов.
      </p>
      <div v-if="activeRadioIndex === 0" class="group-radio__funct">
        <!-- <p class="hint-title__medium-white">
          Задается общее количество мест для всех сеансов.
        </p> -->
        <el-row :gutter="$mq.large ? 10 : 30" class="flex-wrap" type="flex">
          <el-col :span="$mq.large ? 12 : 12">
            <InputSelect
              v-model.number="data0.service_duration"
              prop="durationService"
              :label="servicesType.headers[0].title"
              :placeholder="servicesType.headers[0].placeholder"
              :list-value="listDuration"
              :select-value="data0.time_unit"
              @setValue="data0.time_unit = $event"
            ></InputSelect>
          </el-col>
          <el-col :span="$mq.large ? 12 : 12">
            <el-form-item prop="basePrice">
              <div slot="label" class="flex">
                {{ servicesType.headers[1].title }}
                <span v-if="servicesType.headers[1].valid" class="danger"
                  >*</span
                >
              </div>
              <el-input
                v-model.number="data0.group_general_limits.tickets"
                :placeholder="servicesType.headers[0].placeholder"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="$mq.large ? 24 : 12" class="my-10">
            <!-- <p class="hint-title">Лимит по количеству мест</p> -->
            <Tabs v-model="data0.seat_limit" :list-link="typesServices" />
          </el-col>
        </el-row>
        <div v-for="(item, i) in servicesList" :key="i" class="my-30">
          <div class="list-fields my-15">
            <div class="list-fields__row">
              <div class="list-fields__item">
                <el-form-item prop="basePrice" class="w-full">
                  <div slot="label" class="flex">
                    <span> Название услуги</span>
                    <span class="danger">*</span>
                  </div>
                  <el-input
                    v-model="item.name"
                    placeholder="Введите название"
                    @input="emitData()"
                  >
                  </el-input>
                </el-form-item>
              </div>
              <div class="list-fields__item">
                <el-form-item prop="basePrice" class="w-full">
                  <div slot="label" class="flex">
                    <span> Базовая цена</span> <span class="danger">*</span>
                    <el-popover
                      class="ml-8"
                      placement="top"
                      width="200"
                      trigger="hover"
                      :content="`Базовая цена`"
                    >
                      <Tooltip slot="reference" />
                    </el-popover>
                  </div>
                  <el-input
                    v-model.number="item.base_price"
                    placeholder="Введите цену"
                    @input="emitData()"
                  >
                    <div slot="suffix">₽</div>
                  </el-input>
                </el-form-item>
              </div>
              <div class="list-fields__item">
                <el-form-item prop="basePrice" class="w-full">
                  <div slot="label" class="flex">
                    <span> Цена в праздники</span>
                    <span class="danger">*</span>
                    <el-popover
                      class="ml-8"
                      placement="top"
                      width="200"
                      trigger="hover"
                      :content="`Цена в праздники`"
                    >
                      <Tooltip slot="reference" />
                    </el-popover>
                  </div>
                  <el-input
                    v-model.number="item.holidays_price"
                    placeholder="Введите цену"
                    @input="emitData()"
                  >
                    <div slot="suffix">₽</div>
                  </el-input>
                </el-form-item>
              </div>
              <div class="list-fields__actions">
                <ButtonIcon
                  v-if="servicesList.length > 1"
                  icon-name="close"
                  width="16"
                  @click="deleteField(i)"
                />
              </div>
            </div>
            <!-- <el-button class="list-fields__add-button" @click="addField()">{{
              titleButton
            }}</el-button> -->
          </div>
          <div v-if="data0.seat_limit === 'general_limits'">
            <ListField3
              v-model="data0.group_general_limits.group"
              class="ListField3"
              title-button="Добавить подслугу"
              :headers="servicesType.forGeneral"
            ></ListField3>
          </div>
        </div>
        <div class="my-30">
          <el-button
            type="primary"
            class="custom-button__outline"
            @click="addField()"
          >
            Добавить услугу
          </el-button>
        </div>
      </div>

      <el-radio v-model="activeRadio" label="service_tree_from_settings">
        Дерево услуг из настроек системы управления
      </el-radio>
      <p class="hint-title__medium-white mb-10">
        Услуги выводятся из справочники и задается общее количество мест для
        всех сеансов.
      </p>
      <div v-if="activeRadioIndex === 1" class="group-radio__funct">
        <!-- <p class="hint-title__medium-white">
          Услуги выводятся из справочники и задается общее количество мест для
          всех сеансов.
        </p> -->
        <el-row :gutter="$mq.large ? 10 : 30" class="flex-wrap" type="flex">
          <el-col :span="$mq.large ? 12 : 12">
            <InputSelect
              v-model="data1.service_duration"
              prop="durationService"
              :label="servicesType.headers[0].title"
              :placeholder="servicesType.headers[0].placeholder"
              :list-value="listDuration"
              :select-value="data1.time_unit"
              @setValue="data1.time_unit = $event"
            />
          </el-col>
          <el-col :span="$mq.large ? 12 : 12">
            <el-form-item prop="basePrice">
              <div slot="label" class="flex">
                Кол-во мест
                <span class="danger">*</span>
                <el-popover
                  class="ml-8"
                  placement="top"
                  width="200"
                  trigger="hover"
                  content="Необходимо указать минимальное кол-во билетов, которое будет проставляться для
сеанса на следующем шаге при генерации расписания. Если лимита на кол-во нет,
укажите 0."
                >
                  <Tooltip slot="reference" />
                </el-popover>
              </div>
              <el-input
                v-model.number="data1.places"
                placeholder="кол-во билетов"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="$mq.large ? 24 : 12">
            <h3 class="text-16 my-10 font-normal">Выберите услугу</h3>
            <div class="group-radio w-full">
              <div v-for="type in typeService" :key="type.id" class="w-full">
                <el-checkbox
                  v-model="type.isActive"
                  :label="type.category_name"
                  class="radio__style"
                  @change="handleCheckAllChange(type.category_id)"
                />

                <div style="margin: 15px 0"></div>

                <el-checkbox-group v-model="type.selectedChild" class="ml-30">
                  <el-checkbox
                    v-for="child in type.child_data"
                    :key="child.id"
                    :label="child.name"
                    @change="handleCheckChild(type, type.selectedChild)"
                  />
                </el-checkbox-group>
              </div>
            </div>
          </el-col>
        </el-row>

        <!-- <ListField3
          v-model="data1.group"
          class="ListField3"
          title-button="Добавить тип билета"
          :headers="servicesType.forGeneral"
        ></ListField3> -->
      </div>
    </div>
  </div>
</template>

<script>
import ListField3 from "@/components/ListField3v2.vue";

import {
  listDuration,
  typesServices,
  typeService,
} from "@/helpers/services.js";
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import { servicesType } from "@/helpers/typeSchedule.js";

export default {
  name: "AppServices",
  components: {
    ListField3,
  },
  data() {
    return {
      radio: [],
      servicesList: [
        {
          tickets: null,
          group: [
            {
              id: null,
              name: "",
              base_price: 0,
              holidays_price: null,
            },
          ],
        },
      ],
      typeService: [],
      activeRadio: "service_tree_is_given",
      data0: {
        service_duration: 30,
        time_unit: "minutes",
        seat_limit: "general_limits",
        group_general_limits: {
          tickets: null,
          service: [
            {
              child_data: [],
              category_id: null,
              category_name: null,
              child_data_check: null,
              isActive: true,
              selectedChild: null,
            },
          ],
        },
        group_ticket_type_limit: [
          {
            id: null,
            name: "",
            tickets: null,
            base_price: 0,
            holidays_price: null,
          },
        ],
      },
      data1: {
        service_duration: 60,
        time_unit: "minutes",
        places: 1,
        service: [
          {
            child_data: [],
            category_id: null,
            category_name: null,
          },
        ],
      },
    };
  },

  computed: {
    ...mapState("service", ["settings"]),
    ...mapState("user", ["user", "fixedService"]),
    ...mapGetters("service", ["isHaveSettings"]),
    isServiceTreeGiven() {
      return this.activeRadio === "service_tree_is_given";
    },
    isServiceTreeForSettings() {
      return this.activeRadio === "service_tree_from_settings";
    },
    activeRadioIndex() {
      return this.radioList.findIndex((item) => item === this.activeRadio);
    },
  },
  watch: {
    deep: true,
    user() {
      this.setNotif({ title: `Вы вышли из аккаунта`, type: "error" });

      console.log(this.user);
    },
  },
  created() {
    if (this.settings.data && this.settings.data.service.length) {
      const keys = this.settings.data.service.map((e) => e.category_id);
      // this.typeService = this.settings.data.service;
      this.typeService = this.fixedService.map((e, idx) => {
        if (keys.includes(e.category_id)) {
          console.log(
            this.settings.data.service.find(
              (serv) => serv.category_id === e.category_id
            )
          );
          return {
            ...e,
            isActive: true,
            selectedChild: this.settings.data.service.find(
              (serv) => serv.category_id === e.category_id
            ).selectedChild, // e.child_data.map((child) => child.name),
          };
        }
        return {
          ...e,
          isActive: false,
          selectedChild: [],
        };
      });
    }
    if (!this.settings.data || this.settings.data.service.length < 1) {
      this.typeService = this.fixedService.map((e) => ({
        ...e,
        isActive: true,
        selectedChild: e.child_data.map((child) => child.name),
      }));
    }

    this.typesServices = typesServices;
    this.listDuration = listDuration;
    this.servicesType = servicesType;
    this.radioList = ["service_tree_is_given", "service_tree_from_settings"];
    this.setRadio();
    console.log(this.settings);
  },
  methods: {
    ...mapActions("service", ["saveSettings"]),
    ...mapMutations(["setNotif"]),

    handleCheckAllChange(id) {
      const service = this.typeService.find((serv) => serv.category_id === id);
      if (!service) return;
      this.typeService = this.typeService.map((e) => {
        if (e.category_id === id) {
          return {
            ...e,
            selectedChild: service.isActive
              ? e.child_data.map((child) => child.name)
              : [],
          };
        }
        return e;
      });
    },
    handleCheckChild(id, name) {
      const service = this.typeService.find(
        (serv) => serv.category_id === id.category_id
      );
      this.typeService = this.typeService.map((e) => {
        if (e.selectedChild.length) {
          return {
            ...e,
            isActive: true,
          };
        }
        if (e.selectedChild.length === 0) {
          return {
            ...e,
            isActive: false,
          };
        }
        return e;
      });
      console.log(service, name);
    },

    addField() {
      this.servicesList.push({
        id: null,
        name: "",
        base_price: 0,
        holidays_price: null,
      });
    },
    deleteField(index) {
      this.servicesList.splice(index, 1);
    },

    setRadio() {
      if (
        this.isHaveSettings &&
        this.radioList.includes(this.settings.booking_type_sub)
      ) {
        this.activeRadio = this.settings.booking_type_sub;
        const index = this.radioList.findIndex(
          (item) => item === this.settings.booking_type_sub
        );
        this[`data${index}`] = this.settings.data;
      } else {
        this.activeRadio = "service_tree_is_given";
      }
    },
    save() {
      const checkParam = this.typeService
        .filter((e) => e.isActive === true)
        .map((q) => ({
          ...q,
          child_data_check: q.selectedChild.map((ch) =>
            q.child_data.find((selCh) => {
              if (selCh.name === ch) return selCh;
              return "";
            })
          ),
        }));
      this[`data${this.activeRadioIndex}`].service = checkParam;
      // console.log({
      //   ...this[`data${this.activeRadioIndex}`],
      //   ...(this[`data${this.activeRadioIndex}`] = { service: checkParam }),
      // });

      this.saveSettings({
        nameRadio: this.activeRadio,
        info: {
          ...this[`data${this.activeRadioIndex}`],
          // ,
          // ...(this[`data${this.activeRadioIndex}`] = { service: checkParam }),
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.group {
  .hint-title__medium-white {
    padding: 0 0 20px 0;
  }
  .title {
    font-size: 22px;
    line-height: 44px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  &-radio {
    display: flex;
    flex-direction: column;

    &__funct {
      display: flex;
      flex-direction: column;
      margin: -20px 0 20px 25px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

::v-deep .el-radio {
  margin-bottom: 20px;
}

::v-deep .el-input__suffix {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: $--color-white-5;
  }
}

::v-deep .el-input-group__prepend {
  padding-right: 10px;
}

::v-deep .tabs .el-radio {
  margin-bottom: 0;
}

.ListField3 {
  margin-bottom: 0;
}

@screen lg {
  .group {
    &-radio__funct {
      margin: 0;
    }
    .hint-title__medium-white {
      padding: 0 0 20px 0;
    }
    .title {
      margin-bottom: 30px;
      font-size: 18px;
      line-height: 28px;
    }
  }
}

::v-deep .el-checkbox__label {
  font-size: 14px;
  font-weight: 500;
}
::v-deep .el-checkbox {
  padding: 5px 0;
}
</style>
