<template>
  <div class="resource-settings">
    <div v-if="isLoading" class=""><spinner v-if="isLoading" /></div>
    <el-collapse v-else>
      <BlockCollapse title="Для администраторов" name="forClient">
        <ForAdmin is-one />
      </BlockCollapse>
      <BlockCollapse title="Для клиентов" name="forAdmin">
        <ForClient is-one />
      </BlockCollapse>
    </el-collapse>
  </div>
</template>

<script>
import ForClient from "@/views/Services/Create/components/ForClient.vue";
import ForAdmin from "@/views/Services/Create/components/ForAdmin.vue";

export default {
  name: "ResourceNotifications",
  components: { ForAdmin, ForClient },
  data() {
    return {
      isLoading: false,
    };
  },
};
</script>

<style lang="scss" scoped></style>
