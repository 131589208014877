<template>
  <div
    class="flex items-center justify-between xs:flex-col xs:items-start xs:justify-start mb-20"
  >
    <h1
      class="font-semibold text-white-normal"
      :class="[
        `text-${font.size} leading-${font.leading}`,
        font.mq
          ? `${font.mq.value}:text-${font.mq.size} ${font.mq.value}:leading${font.mq.size}`
          : null,
        font.secondMq
          ? `${font.mq.value}:text-${font.mq.size} ${font.mq.value}:leading${font.mq.size}`
          : null,
      ]"
    >
      {{ title }}
    </h1>
    <el-button
      v-if="buttonText"
      type="primary"
      class="h-40 xs:mt-20"
      @click="$emit('onClick')"
      ><span class="flex items-center justify-center">
        <svg-icon
          icon-name="plus"
          width="10"
          height="10"
          class="mr-10"
        ></svg-icon>
        {{ buttonText }}
      </span>
    </el-button>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    buttonText: String,
    font: {
      type: Object,
      default: () => ({
        size: 36,
        leading: 48,
        mq: {
          value: "lg",
          size: 22,
          leading: 36,
        },
        secondMq: {
          value: "xs",
          size: 30,
          leading: 36,
        },
      }),
    },
  },
};
</script>
