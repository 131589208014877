<template>
  <div class="list-fields">
    <div class="flex">
      <p v-if="title" class="hint-title">
        {{ title }}
      </p>
      <!-- <span class="danger">*</span> -->
      <el-popover
        v-if="popoverText"
        class="ml-8"
        placement="top"
        width="200"
        trigger="hover"
        :content="popoverText"
      >
        <Tooltip slot="reference" />
      </el-popover>
    </div>
    <template v-for="(item, index) in listField">
      <div
        v-if="titleForLine && index"
        :key="`${index}-titleForLine`"
        class="hint-title"
      >
        {{ titleForLine }}
      </div>
      <div :key="index" class="list-fields__row">
        <el-autocomplete
          v-model="listField[index]"
          :placeholder="placeholder"
          class="list-fields__row-input lg:w-full"
          popper-class="app-autocomplete"
          :fetch-suggestions="querySearch"
          type="tel"
          @select="selectedItem($event, index)"
          @input="$emit('input', listField)"
        >
          <template slot-scope="{ item }">
            <div class="flex items-center justify-between text-white-normal">
              <div class="value font-medium">{{ item }}</div>
              <a
                href="#"
                class="link text-white-500 text-12 leading-16 hover:text-white-normal"
                >Удалить</a
              >
            </div>
          </template>
        </el-autocomplete>
        <ButtonIcon
          v-if="listField.length > 1"
          icon-name="close"
          width="16"
          :text="$mq.tablet ? textDelete : ''"
          class="list-fields__row-delete-button"
          @click="deleteField(index)"
        />
      </div>
    </template>

    <el-button v-if="!addFieldDisabled" @click="addField()">{{
      titleButton
    }}</el-button>
  </div>
</template>

<script>
export default {
  name: "ListForAdmin",
  props: {
    addFieldDisabled: Boolean,
    title: {
      type: String,
      default: "",
    },
    titleForLine: {
      type: String,
      default: "",
    },
    titleButton: {
      type: String,
      default: "Добавить новое поле",
    },
    placeholder: {
      type: String,
      default: "Введите значение",
    },
    value: {
      type: Array,
      default: () => [],
    },
    suggestions: {
      type: Array,
      default: () => [],
    },
    textDelete: {
      type: String,
      default: "Удалить",
    },
    isPhone: Boolean,
    isEmail: Boolean,
    popoverText: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      listField: [],
    };
  },
  watch: {
    value: {
      handler() {
        this.listField = [...this.value];
      },
      immediate: true,
    },
  },
  methods: {
    deleteField(index) {
      this.listField.splice(index, 1);
      this.$emit("input", this.listField);
    },
    addField() {
      this.listField.push("");
      this.$emit("input", this.listField);
    },
    selectedItem(value, index) {
      this.listField[index] = value;
      this.$emit("input", this.listField);
    },
    querySearch(queryString, cb) {
      const list = [...this.suggestions];
      const results = queryString
        ? list.filter(this.createFilter(queryString))
        : list;
      cb(results);
    },
    createFilter(queryString) {
      if (this.isPhone) {
        return (item) => {
          if (!item) return false;
          return item.indexOf(queryString) === 0;
        };
      }
      if (this.isEmail) {
        return (item) => {
          if (!item) return false;
          return item.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
        };
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.list-fields {
  display: flex;
  flex-direction: column;
  margin: 0 0 40px;

  &__row {
    display: flex;
    margin-bottom: 20px;

    &-input {
      flex-grow: 1;
    }

    &-delete-button {
      margin-left: 20px;
    }
  }
}
@media (max-width: 468px) {
  .list-fields__row {
    width: 100%;
  }
}
@screen lg {
  .list-fields {
    margin: 0 0 20px;

    &__row {
      flex-direction: column;
      margin-bottom: 15px;

      &-input {
        margin-bottom: 15px;
      }

      &-delete-button {
        margin-left: 0;
        width: 100% !important;
      }
    }
  }
}
</style>
