<template>
  <div class="bk-full">
    <div class="row-info">
      <div class="row-info__title">Осталось билетов</div>
      <div v-if="remainedTickets >= 0" class="row-info__value">
        {{ remainedTickets }}
      </div>
    </div>
    <spinner v-if="isLoad" class="table__spinner limit__spinner" />

    <p class="title-widget">Билеты</p>
    <div v-for="(ticket, i) in listTicket" :key="i" class="input-number">
      <div class="input-number__info">
        <span class="input-number__info-title">{{ ticket.name }}</span>
        <span class="input-number__info-subtitle">
          {{
            isHoliday
              ? ticket.holidays_price
              : (ticket.base_price || ticket.price) +
                itemList.item.price_additional
          }}
          {{ itemList.item.currency || itemList.currency }}
        </span>
      </div>
      <el-input-number
        v-model="ticket.select_ticket"
        class="input-number__input"
        :min="0"
        :max="Number(differenceSeats || valMax)"
        :controls="true"
        @input="emitData"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { AJAX_GET, AJAX_POST } from "@/units/ajax";
import { setField, setFieldObj } from "@/helpers/store";

export default {
  name: "LimitTypeGroup",
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    itemList: {
      type: Object,
    },
    settings: {
      type: Object,
    },
    dateParam: {
      type: String,
    },
    hash: {
      type: String,
    },
  },
  data() {
    return {
      valMax: 1,
      isLoad: false,
      listTicket: [],
      // sel: null,
      slotParam: null,
      remainedTickets: null,
      errMsg: null,
    };
  },

  computed: {
    ...mapState("widget", ["slotDetails"]),

    isHoliday() {
      return this.itemList?.item.is_holiday;
    },

    seats() {
      return this.itemList.item.seats;
    },
    // allSelectTicket() {
    //   return this.listTicket.reduce((sum, current) => {
    //     return sum + current.select_ticket;
    //   }, 0);
    // },

    // differenceSeats() {
    //   if (this.remainedTickets) {
    //     return this.settings.data.tickets;
    //   }
    //   return 0;
    // },
    // isSel() {
    //   const isDis = this.listTicket.find((sel) => sel.select_ticket >= 1);
    //   this.$emit("isDis", isDis);
    //   return isDis;
    // },
  },
  watch: {
    errMsg() {
      if (this.errMsg) {
        console.log("+");
      } else {
        console.log("-");
      }
    },
  },
  async created() {
    this.setListTickets();
    const key = Object.keys(this.slotDetails.slots);
    this.slotParam = this.slotDetails.slots[key].find(
      (el) => el.time === this.itemList.item.time
    );
    await this.getSlotCheck({
      book_info: {
        tickets: this.listTicket,
      },
      resourceHash: this.hash,
      date: this.dateParam, // this.itemList.item.dateBlock,
      time: this.itemList.item.time,
      cnt: null,
      tickets: this.listTicket,
      schedule_id: this.slotParam.schedule_id,
      bookingHash: this.itemList.item.booking_hash,
    });
  },
  methods: {
    ...mapActions("widget", ["saveBooking"]),
    ...mapMutations("widget", ["setField", "slotsValidity"]),
    differenceSeats() {
      if (this.remainedTickets) {
        return this.settings.data.tickets;
      }
      return 0;
    },
    setListTickets() {
      if (!this.itemList.status) {
        this.listTicket = this.settings.data.group.map((el, idx) => {
          return idx === 0
            ? { ...el, select_ticket: 1 }
            : { ...el, select_ticket: 0 };
        });
      } else {
        this.listTicket = this.slotDetails.booking.book_info.tickets.map(
          (e) => {
            return { ...e, select_ticket: e.select_ticket };
          }
        );
      }
    },
    emitData(value) {
      this.$emit(
        "setTickets",
        this.listTicket
          .filter((el) => el.select_ticket)
          .map((item) => {
            return {
              name: item.name,
              price: this.isHoliday
                ? item.holidays_price
                : (item.base_price || item.price) +
                  this.itemList.item.price_additional,
              select_ticket: item.select_ticket,
              id: item.id,
            };
          })
      );
      this.getSlotCheck({
        book_info: {
          tickets: this.listTicket,
        },
        tickets: this.listTicket,
        schedule_id: this.slotParam.schedule_id,
        bookingHash: this.itemList.status
          ? this.itemList.item.booking_hash
          : null,
        resourceHash: this.hash,
        date: this.dateParam, // this.itemList.item.dateBlock,
        time: this.itemList.item.time,
        cnt: null,
      });
      this.valMax = 0;
    },

    async getSlotCheck(param) {
      this.isLoad = true;

      await AJAX_POST("/api/v1/booking/check_slots_validity", {
        ...param,
      })
        .then((result) => {
          if (result.data.success === false) {
            this.errMsg = result.data.msg;
            this.setField({
              field: "slotsValidity",
              value: result.data,
            });
          } else {
            this.errMsg = "";
            this.setField({
              field: "slotsValidity",
              value: result.data,
            });
          }
          this.remainedTickets = result.data.tickets;

          this.isLoad = false;
        })

        .catch((err) => {
          // this.isLoad = false;
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.row-info {
  margin-bottom: 20px;
  &__title {
    width: auto;
    flex-grow: 1;
  }
  &__value {
    flex-grow: 0;
  }
}
.input-number__info-subtitle span {
  &:first-child {
    color: $--color-white;
    margin-right: 6px;
  }
  &:last-child {
    color: $--color-white-5;
    margin-left: 6px;
  }
}
.limit__spinner {
  background-color: rgba(56, 25, 78, 0.6);
  height: 100vh !important;
  z-index: 99;
}
</style>
