<template>
  <Modal
    class="modal"
    :show="show"
    title="Настройка таблицы"
    @close="$emit('close')"
  >
    <div class="flex items-center mb-30">
      <span
        class="small_text border-b dashed mr-20 cursor-pointer"
        @click="select('all')"
        >Выбрать все</span
      >
      <span class="small_text border-b dashed cursor-pointer" @click="select"
        >Отменить все</span
      >
    </div>
    <el-form
      ref="item"
      :model="item"
      :hide-required-asterisk="true"
      label-position="top"
      :rules="rules"
    >
      <el-form-item prop="selected" class="mb-0">
        <el-checkbox-group v-model="item.selected" class="flex flex-col">
          <el-checkbox
            v-for="(option, index) in options"
            :key="index"
            :label="option.id"
            class="mb-10 h-24 flex items-center checkbox"
            >{{ option.title }}</el-checkbox
          >
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <div class="flex items-center mt-35">
      <el-button @click="$emit('close')">Отмена</el-button>
      <el-button class="btn_save" type="primary" @click="save"
        >Применить</el-button
      >
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";

export default {
  components: {
    Modal,
  },
  props: {
    show: Boolean,
    columns: Array,
    itemList: Array,
  },
  data() {
    const validate = (rule, value, callback) => {
      if (value.length < 1) {
        callback(new Error("Выберите минимум одну"));
      } else {
        callback();
      }
    };
    return {
      item: {
        selected: [],
      },
      options: [
        { id: 1, title: "№ и дата" },
        { id: 2, title: "Клиент" },
        { id: 3, title: "Бронирование" },
        { id: 4, title: "Статус" },
        { id: 5, title: "Оплата" },
        { id: 6, title: "Источник заказа" },
      ],
      rules: {
        selected: [
          {
            required: true,
            trigger: ["blur", "change"],
            validator: validate,
          },
        ],
      },
    };
  },
  created() {
    if (!this.itemList) {
      this.item.selected = this.columns;
      return;
    }
    this.options = this.itemList;

    this.item.selected = this.columns;
  },
  methods: {
    save() {
      this.$refs.item.validate((valid) => {
        if (valid) this.$emit("update", this.item.selected);
      });
    },
    select(type) {
      this.item.selected = [];
      if (type === "all") {
        this.options.forEach((el) => {
          this.item.selected.push(el.id);
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

::v-deep .el-dialog {
  width: 378px;
  padding-top: 10px;
}
.small_text {
  color: $--color-white-5;
  opacity: 1;
}
::v-deep .modal__title {
  margin-bottom: 10px;
  padding-top: 30px;
}
.checkbox:last-of-type {
  margin-bottom: 0;
}
.btn {
  &_save {
    margin-left: auto !important;
  }
}
</style>
