<template>
  <div :class="{ 'lg:px-16 mt-60': size !== 'small' && size !== 'big-full' }">
    <div class="no-data" :class="`no-data_${size}`">
      <span>{{ text }}</span>
      <el-button
        v-if="button"
        type="primary"
        class="bg-transparent text-white-normal mt-20"
        @click="$emit('onClick')"
        >{{ button }}</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "Нет данных",
    },
    button: String,
    size: {
      type: String,
      default: "big",
    },
    top: {
      type: String,
      default: "60",
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.no-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $--color-ultra-white;
  border: 1px solid $--color-sm-white;
  font-size: 14px;
  line-height: 24px;
  color: $--color-white-5;
  padding: 33px;
  width: 100%;
  box-sizing: border-box;
  &_small {
    width: 100%;
    max-height: 48px;
    padding: 12px;
  }
}
</style>
