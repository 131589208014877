<template>
  <div class="list-fields">
    <div
      v-for="(item, index) in listField"
      :key="index"
      class="list-fields__row"
    >
      <div class="list-fields__item">
        <el-form-item prop="basePrice" class="w-full">
          <div slot="label" class="flex">
            {{ headers[0].title }}
            <span v-if="headers[0].valid" class="danger">*</span>
            <el-popover
              v-if="headers[0].popover"
              class="ml-8"
              placement="top"
              width="200"
              trigger="hover"
              :content="headers[0].popoverText"
            >
              <Tooltip slot="reference" />
            </el-popover>
          </div>
          <el-time-picker
            v-model="item.time"
            format="HH:mm"
            :placeholder="headers[0].placeholder"
            :disabled="isBind"
            @input="emitData()"
          >
          </el-time-picker>
        </el-form-item>
      </div>
      <div class="list-fields__item">
        <el-form-item prop="basePrice" class="w-full">
          <div slot="label" class="flex">
            {{ headers[3].title }}
            <span v-if="headers[3].valid" class="danger">*</span>
            <el-popover
              v-if="headers[3].popover"
              class="ml-8"
              placement="top"
              width="200"
              trigger="hover"
              :content="headers[3].popoverText"
            >
              <Tooltip slot="reference" />
            </el-popover>
          </div>
          <el-input-number
            v-model="item.seats"
            type="number"
            class="w-full"
            @input="emitData()"
          ></el-input-number>
        </el-form-item>
      </div>
      <div class="list-fields__item">
        <el-form-item prop="basePrice" class="w-full">
          <div slot="label" class="flex">
            {{ headers[1].title }}
            <span v-if="headers[1].valid" class="danger">*</span>
            <el-popover
              v-if="headers[1].popover"
              class="ml-8"
              placement="top"
              width="200"
              trigger="hover"
              :content="headers[1].popoverText"
            >
              <Tooltip slot="reference" />
            </el-popover>
          </div>
          <el-input
            v-model.number="item.price"
            placeholder="Введите цену"
            @input="emitData()"
          >
            <div slot="suffix">₽</div>
          </el-input>
        </el-form-item>
      </div>
      <div class="list-fields__item">
        <el-form-item prop="basePrice" class="w-full">
          <div slot="label" class="flex">
            {{ headers[2].title }}
            <span v-if="headers[2].valid" class="danger">*</span>
            <el-popover
              v-if="headers[2].popover"
              class="ml-8"
              placement="top"
              width="200"
              trigger="hover"
              :content="headers[2].popoverText"
            >
              <Tooltip slot="reference" />
            </el-popover>
          </div>
          <el-input
            v-model.number="item.holidays_price"
            placeholder="Введите цену"
            @input="emitData()"
          >
            <div slot="suffix">₽</div>
          </el-input>
        </el-form-item>
      </div>
      <div v-if="listField.length > 1 && !isBind" class="list-fields__actions">
        <ButtonIcon icon-name="close" width="16" @click="deleteField(index)" />
      </div>
    </div>
    <el-button
      v-if="!isBind"
      class="list-fields__add-button"
      @click="addField()"
    >
      {{ titleButton }}
    </el-button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "SlotScheduleGroup",
  components: {},
  props: {
    title: {
      type: String,
      default: "",
    },
    titleButton: {
      type: String,
      default: "Добавить время",
    },
    placeholder: {
      type: String,
      default: "Введите значение",
    },
    headers: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: null,
    },
    basePrice: {
      type: [String, Number],
      default: null,
    },
    holidaysPrice: {
      type: [String, Number],
      default: null,
    },
    numberSeats: {
      type: Number,
      default: null,
    },
    isBind: Boolean,
  },
  data() {
    return {
      listField: [
        {
          time: null,
          price: null,
          holidays_price: null,
          seats: null,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("service", ["isAddCost"]),
  },
  watch: {
    value() {
      this.changeValue();
    },
  },
  created() {
    this.changeValue();
  },
  methods: {
    changeValue() {
      this.listField = this.value.map((el) => {
        return { ...el, seats: el.seats || this.numberSeats };
      });
    },
    deleteField(index) {
      this.listField.splice(index, 1);
      this.emitData();
    },
    addField() {
      this.listField.push({
        time: null,
        price: this.isAddCost ? null : this.basePrice,
        holidays_price: this.isAddCost ? null : this.holidaysPrice,
        seats: this.numberSeats,
      });

      this.emitData();
    },
    emitData() {
      this.$emit("input", [...this.listField]);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__suffix-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
</style>
