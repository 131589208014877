<template>
  <el-container>
    <el-header class="bg-transparent m-30">
      <span class="font-bold text-46 text-white-normal">Типография</span>
    </el-header>
    <hr class="text-white-100" />
    <section class="kit-section m-30">
      <h1 class="text-46 leading-60 font-semibold text-white-normal">
        A quick brown fox jumps over a lazy dog.
      </h1>
      <h2 class="text-36 leading-48 font-semibold text-white-normal">
        A quick brown fox jumps over a lazy dog.
      </h2>
      <h3 class="text-28 leading-44 font-semibold text-white-normal">
        A quick brown fox jumps over a lazy dog.
      </h3>
      <h4 class="text-22 leading-36 font-semibold text-white-normal">
        A quick brown fox jumps over a lazy dog.
      </h4>
      <h5 class="text-18 leading-30 font-semibold text-white-normal">
        A quick brown fox jumps over a lazy dog.
      </h5>
      <h6 class="text-16 leading-28 font-semibold text-white-normal">
        A quick brown fox jumps over a lazy dog.
      </h6>
      <p class="text-14 leading-25 font-medium text-white-normal">
        С другой стороны сложившаяся структура организации влечет за собой
        процесс внедрения и модернизации существенных финансовых и
        административных условий. Товарищи! консультация с широким активом
        играет важную роль в формировании форм развития. Таким образом
        дальнейшее развитие различных форм деятельности требуют определения и
        уточнения системы обучения кадров, соответствует насущным потребностям.
      </p>
      <p></p>
    </section>
    <el-header class="bg-transparent m-30">
      <span class="font-bold text-46 text-white-normal">Кнопки</span>
    </el-header>
    <hr class="text-white-100" />
    <section class="kit-section m-30">
      <el-button type="primary" class="bg-gradient-b-yellow border-0"
        >Button</el-button
      >
      <el-button
        type="primary"
        size="medium"
        class="bg-gradient-b-yellow border-0"
        >Button</el-button
      >
      <el-button
        type="primary"
        size="small"
        class="bg-gradient-b-yellow border-0"
        >Button</el-button
      >
      <el-button
        type="primary"
        size="mini"
        class="bg-gradient-b-yellow border-0"
        >Button</el-button
      >
    </section>
    <section class="kit-section m-30">
      <el-button type="success" class="bg-gradient-b-green border-0"
        >Button</el-button
      >
      <el-button
        type="success"
        size="medium"
        class="bg-gradient-b-green border-0"
        >Button</el-button
      >
      <el-button
        type="success"
        size="small"
        class="bg-gradient-b-green border-0"
        >Button</el-button
      >
      <el-button type="success" size="mini" class="bg-gradient-b-green border-0"
        >Button</el-button
      >
    </section>
    <section class="kit-section m-30">
      <el-button type="info">Button</el-button>
      <el-button type="info" size="medium">Button</el-button>
      <el-button type="info" size="small">Button</el-button>
      <el-button type="info" size="mini">Button</el-button>
    </section>
    <section class="kit-section m-30">
      <el-button type="warning">Button</el-button>
      <el-button type="warning" size="medium">Button</el-button>
      <el-button type="warning" size="small">Button</el-button>
      <el-button type="warning" size="mini">Mini</el-button>
    </section>

    <section class="kit-section m-30">
      <el-button>Button</el-button>
      <el-button size="medium">Button</el-button>
      <el-button size="small">Button</el-button>
      <el-button size="mini">Mini</el-button>
    </section>

    <el-header class="bg-transparent m-30">
      <span class="font-bold text-46 text-white-normal">Collapse</span>
    </el-header>
    <hr class="text-white-100" />

    <section class="kit-section m-30">
      <el-collapse v-model="activeNames" class="border-0">
        <el-collapse-item title="Consistency" name="1">
          <div
            slot="title"
            class="collapse-title pl-30 font-semibold text-18 flex items-center"
          >
            Фильтрация
            <svg-icon
              icon-name="chevron"
              width="12"
              height="12"
              class-name="ml-10 text-white-normal"
            ></svg-icon>
          </div>
          <div class="collapse-content px-30 pb-30 font-medium">
            <div>
              Consistent with real life: in line with the process and logic of
              real life, and comply with languages and habits that the users are
              used to;
            </div>
            <div>
              Consistent within interface: all elements should be consistent,
              such as: design style, icons and texts, position of elements, etc.
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </section>

    <el-header class="bg-transparent m-30">
      <span class="font-bold text-46 text-white-normal">Инпуты</span>
    </el-header>
    <hr class="text-white-100" />
    <section class="kit-section m-30">
      <el-input
        v-model="createResourceSettings.time"
        placeholder="Число"
        class="input-apend-select mb-20"
      >
        <el-select
          slot="append"
          v-model="createResourceSettings.range"
          placeholder="Select"
          class="w-100 border-l border-white-200"
        >
          <el-option
            v-for="item in timeRanges"
            :key="`time-${item.value}`"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-input>
      <div class="mb-20">
        <label
          for="input"
          class="text-12 font-medium text-white-normal mb-7 block"
          >Sign</label
        >
        <el-input id="input" v-model="input" class="input-white"></el-input>
      </div>
      <div class="mb-20">
        <label
          for="input2"
          class="text-12 font-medium text-white-normal mb-7 block"
          >Sign</label
        >
        <el-input
          id="input2"
          v-model="input"
          class="input-white input-green"
        ></el-input>
      </div>
      <div class="mb-20">
        <label
          for="input3"
          class="text-12 font-medium text-white-normal mb-7 block"
          >Sign</label
        >
        <el-input
          id="input3"
          v-model="input"
          class="input-white input-red"
        ></el-input>
      </div>
      <div class="mb-20">
        <label
          for="input4"
          class="text-12 font-medium text-white-normal mb-7 block"
          >Sign</label
        >
        <el-input
          id="input4"
          v-model="input"
          placeholder="Placeholder"
        ></el-input>
      </div>
      <div class="mb-20">
        <el-input
          id="input5"
          v-model="input"
          placeholder="Placeholder"
        ></el-input>
      </div>
      <div class="mb-20">
        <label
          for="input6"
          class="text-12 font-medium text-white-normal mb-7 block"
          >Sign</label
        >
        <el-input
          id="input6"
          v-model="input"
          placeholder="Placeholder"
          class="input-bordered font-medium"
        ></el-input>
      </div>
      <div class="mb-20">
        <el-input v-model="input" placeholder="Please input">
          <template slot="prepend">
            <svg-icon
              icon-name="search"
              width="15"
              height="16"
              class-name="fill-current text-white-normal"
            >
            </svg-icon>
          </template>
        </el-input>
      </div>
    </section>

    <el-header class="bg-transparent m-30">
      <span class="font-bold text-46 text-white-normal">Селекты</span>
    </el-header>
    <hr class="text-white-100" />

    <section class="kit-section m-30">
      <div class="mb-20">
        <el-select v-model="value" placeholder="Select">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="mb-20">
        <div class="mb-10">
          <el-select v-model="value" placeholder="Select" class="bordered">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>

        <div class="mb-10">
          <el-select
            v-model="value"
            placeholder="Select"
            class="bordered bordered-green"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="mb-10">
          <el-select
            v-model="value"
            placeholder="Select"
            class="bordered bordered-red"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="mb-10">
          <el-select
            v-model="value"
            placeholder="Select"
            class="bordered bordered-blue"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="mb-20">
        <label
          for="input6"
          class="text-12 font-medium text-white-normal mb-7 block"
          >Sign</label
        >
        <el-select v-model="value" placeholder="Select">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="mb-20">
        <label
          for="input6"
          class="text-12 font-medium text-white-normal mb-7 block"
          >Multiple select</label
        >
        <el-select v-model="valueMultiple" placeholder="Select" multiple>
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </section>

    <el-header class="bg-transparent m-30">
      <span class="font-bold text-46 text-white-normal">Пагинация</span>
    </el-header>
    <hr class="text-white-100" />

    <section class="kit-section m-30">
      <el-pagination background layout="prev, pager, next" :total="1000">
      </el-pagination>
    </section>

    <el-header class="bg-transparent m-30">
      <span class="font-bold text-46 text-white-normal"
        >Дата пикеры и таймпикеры</span
      >
    </el-header>
    <hr class="text-white-100" />
    <section class="kit-section m-30">
      <div class="mb-20">
        <el-date-picker
          v-model="valueDate"
          type="daterange"
          format="dd.MM.yyyy"
          range-separator="По"
          start-placeholder="Любая"
          end-placeholder="Любая"
        >
        </el-date-picker>
      </div>

      <div class="mb-20">
        <el-date-picker
          v-model="valueDate"
          type="date"
          placeholder="Pick a day"
        >
        </el-date-picker>
      </div>
      <div class="mb-20">
        <el-time-picker
          v-model="valueTime"
          format="HH:mm"
          :picker-options="{
            selectableRange: '18:30:00 - 20:30:00',
          }"
          placeholder="Arbitrary time"
        >
        </el-time-picker>
      </div>
      <div class="mb-20">
        <el-time-picker
          v-model="value1"
          is-range
          format="HH:mm"
          range-separator="По"
          start-placeholder="Любое"
          end-placeholder="Любое"
        >
        </el-time-picker>
      </div>
    </section>

    <el-header class="bg-transparent m-30">
      <span class="font-bold text-46 text-white-normal">Чекбоксы</span>
    </el-header>
    <hr class="text-white-100" />
    <section class="kit-section m-30">
      <div class="mb-20">
        <el-radio-group v-model="radio">
          <el-radio :label="3">Option A</el-radio>
          <el-radio :label="6">Option B</el-radio>
          <el-radio disabled :label="9">Option C</el-radio>
        </el-radio-group>
      </div>
      <div class="mb-20">
        <el-radio-group v-model="radio" class="flex">
          <el-radio :label="3" border class="m-0">Option A</el-radio>
          <el-radio :label="6" border class="m-0">Option B</el-radio>
          <el-radio disabled :label="9" border class="m-0">Option C</el-radio>
        </el-radio-group>
      </div>
      <div class="mb-20">
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="Option A"></el-checkbox>
          <el-checkbox label="Option B"></el-checkbox>
          <el-checkbox label="Option C"></el-checkbox>
          <el-checkbox label="disabled" disabled></el-checkbox>
          <el-checkbox label="selected and disabled" disabled></el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="mb-20">
        <el-checkbox-group v-model="checkboxGroup1">
          <el-checkbox-button
            v-for="city in cities"
            :key="city"
            :label="city"
            >{{ city }}</el-checkbox-button
          >
        </el-checkbox-group>
      </div>
    </section>

    <el-header class="bg-transparent m-30">
      <span class="font-bold text-46 text-white-normal">Таблицы</span>
    </el-header>
    <hr class="text-white-100" />
    <section class="kit-section m-30">
      <el-table
        :data="tableData"
        :stripe="true"
        :default-sort="{ prop: 'date', order: 'descending' }"
        style="width: 100%"
      >
        <el-table-column prop="date" label="Номер" sortable width="180">
        </el-table-column>
        <el-table-column prop="name" label="Клиент" width="180">
        </el-table-column>
        <el-table-column
          prop="address"
          label="Бронирование"
          :formatter="formatter"
        >
        </el-table-column>
      </el-table>
    </section>

    <el-header class="bg-transparent m-30">
      <span class="font-bold text-46 text-white-normal"
        >Tooltips and popovers</span
      >
    </el-header>
    <hr class="text-white-100" />
    <section class="kit-section m-30">
      <div class="mb-30">
        <el-popover
          placement="top"
          title="Title"
          width="200"
          trigger="hover"
          content="this is content, this is content, this is content"
        >
          <div
            slot="reference"
            class="tooltip-btn rounded-full w-17 h-17 bg-blue-100 flex items-center justify-center"
          >
            <svg-icon
              icon-name="question"
              class-name="text-white-normal"
              width="5"
              height="9"
            ></svg-icon>
          </div>
        </el-popover>
      </div>

      <el-popover
        class="border border-white-200 py-5 cursor-pointer"
        placement="bottom-end"
        popper-class="control-poper"
        width="184"
        trigger="click"
      >
        <span slot="reference" class="px-15 inline-flex outline-none h-full">
          По клику
        </span>
        <div>
          <div
            class="px-15 py-7 border-b border-white-100 flex items-center cursor-pointer h-40"
          >
            <svg-icon
              icon-name="copy"
              class-name="text-white-350 mr-8"
              width="16"
              height="18"
            ></svg-icon>
            Скопировать услугу
          </div>
          <div class="px-15 py-7 flex items-center cursor-pointer h-40">
            <svg-icon
              icon-name="close"
              class-name="text-white-350 mr-8"
              width="12"
              height="12"
            ></svg-icon>
            Удалить услугу
          </div>
        </div>
      </el-popover>
    </section>

    <el-header class="bg-transparent m-30">
      <span class="font-bold text-46 text-white-normal">Alerts</span>
    </el-header>
    <hr class="text-white-100" />
    <section class="kit-section m-30">
      <el-alert
        title="warning alert"
        type="warning"
        :closable="false"
        show-icon
      >
      </el-alert>
    </section>

    <el-header class="bg-transparent m-30">
      <span class="font-bold text-46 text-white-normal"
        >Messages and notifications</span
      >
    </el-header>
    <hr class="text-white-100" />
    <section class="kit-section m-30">
      <el-button @click="showSuccessMessage">Show success message</el-button>
      <el-button @click="showErrorMessage">Show error message</el-button>
      <el-button @click="showSuccessNotification"
        >Show success notification</el-button
      >
      <el-button @click="showErrorNotification"
        >Show error notification</el-button
      >
    </section>

    <el-header class="bg-transparent m-30">
      <span class="font-bold text-46 text-white-normal">Steps</span>
    </el-header>
    <hr class="text-white-100" />
    <section class="kit-section m-30">
      <el-steps :active="activeStep" finish-status="success">
        <el-step title="Шаг 1"></el-step>
        <el-step title="Шаг 2"></el-step>
        <el-step title="Шаг 3"></el-step>
      </el-steps>
    </section>
    <el-header class="bg-transparent m-30">
      <span class="font-bold text-46 text-white-normal">Progress bars</span>
    </el-header>
    <hr class="text-white-100" />
    <section class="kit-section m-30">
      <el-progress
        type="circle"
        :percentage="100"
        status="success"
      ></el-progress>
    </section>
    <el-header class="bg-transparent m-30">
      <span class="font-bold text-46 text-white-normal">Slider</span>
    </el-header>
    <hr class="text-white-100" />
    <section class="kit-section m-30">
      <el-slider
        v-model="slider"
        :marks="marks"
        :max="sliderMax"
        tooltip-class="slider-tooltip"
      >
      </el-slider>
    </section>
    <el-header class="bg-transparent m-30">
      <span class="font-bold text-46 text-white-normal">Image preview</span>
    </el-header>
    <hr class="text-white-100" />
    <section class="kit-section m-30">
      <el-button type="mini" class="relative preview-button">
        <el-image
          src="https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg"
          class="absolute w-full h-full top-0 left-0"
          fit="none"
          :preview-src-list="srcList"
        >
        </el-image>
        screenshot_230942.jpg</el-button
      >
    </section>
  </el-container>
</template>

<script>
const cityOptions = ["Shanghai", "Beijing", "Guangzhou", "Shenzhen"];
// import SvgIcon from '../components/SvgIcon'
export default {
  name: "UiKit",
  data() {
    return {
      activeNames: ["1"],
      srcList: [
        "https://vuejs.org/images/logo.png",
        "https://vuejs.org/images/logo.png",
      ],
      input: "",
      activeStep: 1,
      checkboxGroup1: ["Shanghai"],
      cities: cityOptions,
      createResourceSettings: {
        time: "",
      },
      timeRanges: [
        {
          value: "Option1",
          label: "Option1",
        },
        {
          value: "Option2",
          label: "Option2",
        },
        {
          value: "Option3",
          label: "Option3",
        },
        {
          value: "Option4",
          label: "Option4",
        },
        {
          value: "Option5",
          label: "Option5",
        },
      ],
      slider: 150,
      sliderMax: 150,
      marks: {
        30: "30",
        60: "60",
        90: "90",
        120: "120",
      },
      tableData: [
        {
          date: "2016-05-03",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },
        {
          date: "2016-05-02",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },
        {
          date: "2016-05-04",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },
        {
          date: "2016-05-01",
          name: "Tom",
          address: "No. 189, Grove St, Los Angeles",
        },
      ],
      options: [
        {
          value: "Option1",
          label: "Option1",
        },
        {
          value: "Option2",
          label: "Option2",
        },
        {
          value: "Option3",
          label: "Option3",
        },
        {
          value: "Option4",
          label: "Option4",
        },
        {
          value: "Option5",
          label: "Option5",
        },
      ],
      value: "",
      value1: "",
      valueMultiple: "",
      valueDate: "",
      valueTime: "",
      radio: 3,
      checkList: ["selected and disabled", "Option A"],
    };
  },
  methods: {
    formatter(row) {
      return row.address;
    },
    showSuccessMessage() {
      this.$message({
        message: `Успешное сообщение`,
        type: "success",
        duration: 5000,
      });
    },
    showErrorMessage() {
      this.$message({
        message: "Ошибка",
        type: "error",
        duration: 5000,
      });
    },
    showErrorNotification() {
      this.$notify({
        title: "Произошла ошибка, попробуйте позже",
        type: "error",
        showClose: false,
        duration: 2000,
      });
    },
    showSuccessNotification() {
      this.$notify({
        title: "Успешное сообщение",
        type: "success",
        showClose: false,
        duration: 2000,
      });
    },
  },
  // components: {
  //     SvgIcon
  // }
};
</script>

<style></style>
