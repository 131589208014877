<template>
  <!-- <div class="pt-40 pb-80 w-full lg:pt-15 lg:px-15"> -->
  <div class="pt-40 pb-80 w-full lg:pt-15 px-10">
    <!-- <h1
      class="font-semibold text-white-normal text-28 leading-44 lg:text-22 lg:leading22 mb-20 xs:mb-5"
    >
      Что нового
    </h1> -->
    <Top title="  Что нового" :font="{ size: 22, leading: 22 }" />
    <!-- <h1
      class="text-36 leading-48 text-white-normal font-semibold lg:text-22 lg:leading-36 mb-20"
    >
      Что нового
    </h1> -->
    <spinner v-if="loading" />
    <template v-else>
      <article
        v-for="(item, index) in news"
        :key="index"
        class="news-item mb-40 lg:mb-30"
      >
        <div class="text-yellow-100 mb-5">
          {{ newsDate(item.published_at) }}
        </div>
        <h2 class="mb-5 leading-30 text-18 font-semibold lg:leading-25">
          {{ item.title }}
        </h2>
        <p class="text-white-700 leading-22">{{ item.desc }}</p>
      </article>
    </template>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
import Top from "@/components/Pages/Top";

export default {
  name: "News",
  components: {
    Top,
  },
  data() {
    return {
      loading: true,
    };
  },
  async created() {
    await this.getNews();
    this.loading = false;
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapState("news", ["news"]),
  },
  methods: {
    ...mapActions("news", ["getNews"]),

    newsDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
  },
};
</script>

<style scoped lang="scss">
.news-item {
  max-width: 840px;
}
</style>
