<template>
  <div
    class="custom-block form"
    :style="{
      background: widgetTheme ? customClass.block_background : '',
      color: widgetTheme ? customClass.active_text_color : '',
    }"
  >
    <svg-icon
      width="40"
      height="40"
      class="form__icon"
      icon-name="close-cicle"
      @click="$emit('close')"
    ></svg-icon>
    <div
      v-if="errorTitle"
      class="title-widget"
      :style="{
        color: widgetTheme ? customClass.active_text_color : '',
      }"
    >
      {{ errorTitle }}
    </div>
    <div
      v-else
      class="title-widget"
      :style="{
        color: widgetTheme ? customClass.active_text_color : '',
      }"
    >
      Что то пошло не так
    </div>
    <p
      class="form__text"
      :style="{
        color: widgetTheme ? customClass.active_text_color : '',
      }"
    >
      {{ msg }}
    </p>

    <p
      v-if="
        (dataPay && dataPay.error_msg) ||
        (dataPay && dataPay.payment_error_text)
      "
      class="form__text text-center"
      :style="{
        color: widgetTheme ? customClass.active_text_color : '',
      }"
    >
      {{ dataPay.error_msg || dataPay.payment_error_text }}
    </p>

    <el-button
      :style="{
        background: widgetTheme
          ? customClass.active_buttons_background
          : '#FEBB17',
        color: widgetTheme ? customClass.button_text_color : 'black',
      }"
      @click="getAction"
      >{{
        `${dataPay ? dataPay.payment_error_button_text : "Хорошо"}`
      }}</el-button
    >
  </div>
</template>

<script>
export default {
  name: "PayError",
  props: {
    customClass: { type: Object },
    widgetTheme: {
      type: String,
    },
    dataPay: {
      type: Object,
    },
    msg: {
      type: String,
      default: "",
    },
    errorTitle: {
      type: String,
    },
  },
  methods: {
    getAction() {
      if (this.dataPay && this.dataPay.payment_link)
        this.redirectToLink(this.dataPay.payment_link);
      else this.$emit("close");
    },
    redirectToLink(link) {
      if (this.dataPay.payment_target === "blank") {
        window.open(`${link}`, "_blank");
      } else {
        window.location = link;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.title-widget {
  margin-bottom: 20px;
}

.form {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__icon {
    margin-bottom: 20px;
  }
  &__text {
    color: $--color-white-5;
    margin-bottom: 20px;
  }
}
</style>
