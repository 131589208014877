<template>
  <div>
    <div
      v-for="(item, index) in settings.data.group"
      :key="index"
      class="bk-info"
    >
      <div
        v-if="
          (isHoliday && item.holidays_price
            ? item.holidays_price + selectCell.weekend_price_additional
            : item.base_price + selectCell.price_additional) > 0
        "
        class="flex justify-between w-full"
      >
        <p
          class="bk-info__title"
          :style="{
            color: isThemeWidget ? customStyle.inactive_text_color : '',
          }"
        >
          {{ item.from ? `от ${item.from}` : "" }}
          {{ item.to ? `до  ${item.to} ` : "" }}
          <span
            :style="{
              color: isThemeWidget ? customStyle.inactive_text_color : '',
            }"
            >{{ `${unitCahnge.text}` }}</span
          >
        </p>
        <p
          class="bk-info__info"
          :style="{
            color: isThemeWidget ? customStyle.active_text_color : '',
          }"
        >
          {{
            isHoliday && item.holidays_price
              ? item.holidays_price + selectCell.weekend_price_additional
              : item.base_price + selectCell.price_additional
          }}
          {{ selectCell.currency }}
          {{ `за ${settings.data.session_duration} ${unitCahnge.short}` }}
        </p>
      </div>
    </div>
    <p
      class="title-widget"
      :style="{
        color: isThemeWidget ? customStyle.active_text_color : '',
      }"
    >
      Количество {{ unitCahnge.text }}
    </p>
    <el-input-number
      v-if="
        settings.data.time_unit === 'hours' ||
        settings.data.time_unit === 'days'
      "
      v-model="hours"
      class="w-full mb-12"
      :class="{ 'custom-style': isThemeWidget }"
      :style="{
        color: isThemeWidget ? customStyle.active_text_color : '',
        background: isThemeWidget ? customStyle.background_cells_fields : '',
      }"
      :min="minHours"
      :step="step"
      :max="maxHours"
      @input="changePersons"
    ></el-input-number>
    <el-input-number
      v-if="settings.data.time_unit === 'minutes'"
      v-model="hours"
      class="w-full mb-12"
      :class="{ 'custom-style': isThemeWidget }"
      :style="{
        color: isThemeWidget ? customStyle.active_text_color : '',
        background: isThemeWidget ? customStyle.background_cells_fields : '',
      }"
      :min="minMinutes"
      :max="maxMinutes"
      :step="step"
      @input="changePersons"
    ></el-input-number>

    <p
      class="title-widget"
      :style="{
        color: isThemeWidget ? customStyle.active_text_color : '',
      }"
    >
      Количество мест
    </p>
    <div class="bk-info">
      <p
        class="bk-info__title"
        :style="{
          color: isThemeWidget ? customStyle.active_text_color : '',
        }"
      >
        Осталось мест
      </p>
      <p
        v-if="slotsValidity"
        class="bk-info__info"
        :style="{
          color: isThemeWidget ? customStyle.active_text_color : '',
        }"
      >
        {{ slotsValidity.tickets }} из {{ remainedTickets.total }}
      </p>
    </div>
    <el-input-number
      v-model="places"
      class="w-full"
      :class="{ 'custom-style': isThemeWidget }"
      :style="{
        background: isThemeWidget ? customStyle.background_cells_fields : '',
      }"
      :min="1"
      :max="remainedTickets.remained"
      @input="changePlaces"
    ></el-input-number>
    <div v-if="settings.data.group_2" class="my-5">
      <div
        v-for="sale in settings.data.group_2"
        :key="sale.id"
        class="bk-info"
        :style="{
          color: isThemeWidget ? customStyle.active_text_color : '',
        }"
      >
        <p
          v-if="sale.discount > 0"
          class="bk-info__title"
          :style="{
            color: isThemeWidget ? customStyle.inactive_text_color : '',
          }"
        >
          {{ sale.from ? `от ${sale.from}` : "" }}
          {{ sale.to ? `до  ${sale.to} ` : "" }}
          <span
            :style="{
              color: isThemeWidget ? customStyle.inactive_text_color : '',
            }"
            >{{ `мест` }}</span
          >
        </p>
        <p
          v-if="sale.discount > 0"
          class="bk-info__info"
          :style="{
            color: isThemeWidget ? customStyle.active_text_color : '',
          }"
        >
          {{ `скидка: ${sale.discount}` }}
          {{ convertSale(sale.discount_unit) }}
          {{ `за ${settings.data.session_duration} ${unitCahnge.short}` }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "RentDepTimeSeats",
  props: {
    selectCell: {
      type: Object,
      default: () => {},
    },
    settings: {
      type: Object,
      default: () => {},
    },
    hash: {
      type: Object,
    },
    slotsValidity: { type: Object },
    customStyle: { type: Object },
    isThemeWidget: { type: String },
  },
  data() {
    return {
      hours: 0,
      places: 1,
      discount: null,
      prices: null,
    };
  },
  computed: {
    ...mapState("widget", ["remainedTickets"]),
    isHoliday() {
      return this.selectCell.is_holiday;
    },
    step() {
      return Number(this.settings.data.session_duration);
    },

    maxPlaces() {
      const lastChild = this.settings.data.group_2[
        this.settings.data.group_2.length - 1
      ];
      return Number(lastChild.to) || 99999;
    },
    maxHours() {
      const lastChild = this.settings.data.group[
        this.settings.data.group.length - 1
      ];
      return Number(lastChild.to) || 99999;
    },
    minHours() {
      return Number(this.settings.data.minimum_rent) || 1;
    },
    minMinutes() {
      return Number(this.settings.data.group[0].from) || 1;
    },
    maxMinutes() {
      const lastChild = this.settings.data.group[
        this.settings.data.group.length - 1
      ];
      return Number(lastChild.to) || 99999;
    },

    unitCahnge() {
      switch (this.settings.data.time_unit) {
        case "days":
          return {
            text: "дней",
            short: "день",
            param: "day",
            time: this.hours,
          };
        case "hours":
          return {
            text: "часов",
            short: "час",
            param: "hour",
            time: this.hours,
          };
        case "minutes":
          return {
            text: "минут",
            short: "минут",
            param: "minute",
            time: this.hours,
          };

        default:
          return this.settings.data.time_unit;
      }
    },
  },
  async created() {
    this.hours = this.settings.data.minimum_rent;
    await this.getRemainedTickets({
      hash: this.hash.hash,
      date: this.selectCell?.date,
      time: this.selectCell?.time,
    });
    this.isClass();
  },

  methods: {
    ...mapActions("widget", ["saveBooking", "getRemainedTickets"]),
    convertSale(param) {
      switch (param) {
        case "procent":
          return "%";
        case "rub":
          return "₽";
        default:
          return "";
      }
    },

    changePersons() {
      const findInterval = this.settings.data.group.find((el) => {
        const to = el.to ? Number(el.to) : this.maxHours;
        return this.hours <= to;
      });
      const findIntervalDiscount = this.settings.data.group_2.find((el) => {
        const to = el.to ? Number(el.to) : 99999;
        return this.places <= to;
      });
      this.$emit("setSlotParam", {
        book_info: {
          rent: {
            places: this.places,
            price: this.prices,
            duration: this.unitCahnge.time,
            unit: this.unitCahnge.param,
          },
        },
        resourceHash: this.selectCell.resource_id,
        date: this.selectCell.date,
        time: this.selectCell.time,
        cnt: this.hours,
        tickets: this.places,
      });
      this.prices = this.isHoliday
        ? findInterval.holidays_price // + this.selectCell.weekend_price_additional
        : findInterval.base_price; // + this.selectCell.price_additional;
      if (findIntervalDiscount.discount) {
        this.$emit("setDiscount", {
          discount: findIntervalDiscount.discount,
          unit: findIntervalDiscount.discount_unit,
        });
      }
      this.$emit("setRent", {
        price: this.prices,
        discount: findIntervalDiscount.discount
          ? findIntervalDiscount.discount
          : null,
        discount_unit: findIntervalDiscount.discount
          ? findIntervalDiscount.discount_unit
          : null,
        places: this.places,
        duration: this.unitCahnge.time,
        unit: this.unitCahnge.param,
      });

      this.$emit("setPlaces", this.places);

      this.$emit(
        "setHours",
        this.settings.data.time_unit === "minutes" ? this.hours : this.hours
      );
      this.$emit(
        "setPrice",
        this.isHoliday ? findInterval.holidays_price : findInterval.base_price
      );
    },
    changePlaces() {
      const findInterval = this.settings.data.group_2.find((el) => {
        const to = el.to ? Number(el.to) : 99999;
        return this.places <= to;
      });
      this.$emit("setSlotParam", {
        book_info: {
          rent: {
            places: this.places,
            price: this.prices,
            duration: this.unitCahnge.time,
            unit: this.unitCahnge.param,
          },
        },

        resourceHash: this.selectCell.resource_id,
        date: this.selectCell.date,
        time: this.selectCell.time,
        cnt: this.hours,
        tickets: this.places,
      });

      if (findInterval.discount) {
        this.$emit("setDiscount", {
          discount: findInterval.discount,
          unit: findInterval.discount_unit,
        });
      }

      this.$emit("setPlaces", this.places);
      this.$emit("setRent", {
        price: this.prices,

        discount: findInterval.discount ? findInterval.discount : null,
        discount_unit: findInterval.discount
          ? findInterval.discount_unit
          : null,
        places: this.places,
        duration: this.unitCahnge.time,
        unit: this.unitCahnge.param,
      });
    },
    isClass() {
      document.body.style.setProperty(
        "--colorCustom",
        `${this.customStyle.active_text_color}`
      );
      document.body.style.setProperty(
        "--colorCustomBackground",
        `${this.customStyle.inactive_text_color}`
      );
      document.body.style.setProperty(
        "--colorCustomButton",
        `${this.customStyle.active_buttons_background}`
      );
      document.body.style.setProperty(
        "--colorCustomTextInactive",
        `${this.customStyle.active_buttons_background}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";
.bk-info {
  margin-bottom: 6px;
  &__title {
    flex-grow: 1;
    width: auto;
  }
  &__info {
    flex-grow: 0;
  }
}
.title-widget {
  margin-top: 12px;
}
.custom-style {
  ::v-deep .el-input__inner {
    color: var(--colorCustom);
  }

  ::v-deep .el-input-number__increase,
  ::v-deep .el-input-number__decrease {
    background: var(--colorCustomBackground);
    color: var(--colorCustom);
  }
  ::v-deep .el-input-number__increase:hover,
  ::v-deep .el-input-number__decrease:hover {
    color: var(--colorCustomButton);
  }
}
</style>
