var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"custom-block form",style:({
    background: _vm.widgetTheme ? _vm.customClass.block_background : '',
    color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
  })},[(_vm.isAdmin)?_c('div',[_c('div',{staticClass:"form__wrapper-header"},[_c('svg-icon',{staticClass:"form__icon-done",attrs:{"width":"40","height":"40","icon-name":"isDone"}}),_c('div',{staticClass:"form__close",on:{"click":_vm.closeModal}},[_c('svg-icon',{style:({
            color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
          }),attrs:{"icon-name":"close","width":"14","height":"14"}})],1)],1),_c('div',{staticClass:"title-widget",style:({
        color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
      })},[_vm._v(" "+_vm._s(_vm.titlePay)+" ")]),_c('p',{staticClass:"form__sub-title"},[_vm._v("Вы забронировали")]),_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title"},[_vm._v("Услуга")]),_c('div',{staticClass:"row-info__value"},[_vm._v(" "+_vm._s(_vm.dataPay.name)+" ")])]),_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title"},[_vm._v("Дата и время")]),_c('div',{staticClass:"row-info__value"},[_vm._v(" "+_vm._s(_vm.dateTime ? _vm.dateTime : ((_vm.getDate(_vm.dataPay.dateTime)) + " в " + (_vm.getTime(_vm.dataPay.dateTime)) + " "))+" ")])]),_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title"},[_vm._v("Стоимость")]),_c('div',{staticClass:"row-info__value"},[_vm._v(" "+_vm._s(_vm.totalPrice ? _vm.totalPrice : _vm.dataPay.price || 0)+" "+_vm._s(_vm.dataPay.currency)+" ")])]),(_vm.isAdmin)?_c('div',[_c('p',{staticClass:"form__sub-title"},[_vm._v("Данные клиента")]),_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title"},[_vm._v("Имя")]),_c('div',{staticClass:"row-info__value"},[_vm._v(" "+_vm._s(_vm.dataPay.request.client_info.name)+" "+_vm._s(_vm.dataPay.request.client_info.surname)+" ")])]),_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title"},[_vm._v("Телефон")]),_c('div',{staticClass:"row-info__value"},[_vm._v(" "+_vm._s(_vm.dataPay.request.client_info.tel)+" ")])]),_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title"},[_vm._v("Электронная почта")]),_c('div',{staticClass:"row-info__value"},[_vm._v(" "+_vm._s(_vm.dataPay.request.client_info.email)+" ")])])]):_vm._e(),(
        _vm.isAdmin &&
        _vm.dataPay.booking_type_sub !==
          'individual_does_not_depend_on_the_number_of_seats'
      )?_c('div',[(
          _vm.dataPay.payment_data.length
            ? _vm.dataPay.payment_data.type_name ||
              _vm.dataPay.payment_data[0].type_name
            : false
        )?_c('p',{staticClass:"form__sub-title"},[_vm._v(" Оплата ")]):_vm._e(),(
          _vm.dataPay.payment_data.length
            ? _vm.dataPay.payment_data.type_name ||
              _vm.dataPay.payment_data[0].type_name
            : false
        )?_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title"},[_vm._v("Тип оплаты")]),(_vm.dataPay.payment_data.length)?_c('div',{staticClass:"row-info__value"},[_vm._v(" "+_vm._s(_vm.dataPay.payment_data.type_name || _vm.dataPay.payment_data[0].type_name)+" ")]):_vm._e()]):_vm._e(),(_vm.dataPay.payment_data.payment_type === 'full_payment')?_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title"},[_vm._v("Онлайн")]),_c('div',{staticClass:"row-info__value"},[_vm._v(" "+_vm._s(_vm.dataPay.payment_data.full_amount)+" "+_vm._s(_vm.dataPay.currency)+" ")])]):_vm._e()]):_vm._e(),(_vm.dataPay.msg)?_c('div',[(_vm.dataPay.msg)?_c('el-alert',{attrs:{"center":"","title":_vm.dataPay.msg,"type":"warning","show-icon":"","closable":false}}):_vm._e()],1):_vm._e(),(
        (!!_vm.dataPay.payment_data.payment_link || !!_vm.dataPay.payment_link) &&
        (_vm.dataPay.payment_data.payment_type === 'full_payment' ||
          _vm.dataPay.payment_data.payment_type === 'prepayment' ||
          _vm.dataPay.payment_link)
      )?_c('div',{staticClass:"row-link_pay cursor-pointer"},[_c('svg-icon',{attrs:{"width":"20","height":"20","icon-name":"copy"}}),_c('a',{on:{"click":function($event){return _vm.copy(_vm.dataPay.payment_link || _vm.dataPay.payment_data.payment_link)}}},[_vm._v(" Скопировать ссылку на оплату ")])],1):_vm._e()]):_vm._e(),(!_vm.isAdmin && !_vm.isRequiredPay && !_vm.onlinePaymentRequired && _vm.payInPlace)?_c('div',[_c('div',{staticClass:"form__wrapper-header",style:({
        color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
      })},[_c('svg-icon',{staticClass:"form__icon-done",attrs:{"width":"40","height":"40","icon-name":"isDone"}}),_c('div',{staticClass:"form__close",on:{"click":_vm.closeModal}},[_c('svg-icon',{style:({
            color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
          }),attrs:{"icon-name":"close","width":"14","height":"14"}})],1)],1),_c('div',{staticClass:"title-widget",style:({
        color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
      })},[_vm._v(" Услуга успешно забронирована! ")]),_c('p',{staticClass:"form__sub-title",style:({
        color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
      })},[_vm._v(" Вы забронировали ")]),_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title",style:({
          color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
        })},[_vm._v(" "+_vm._s(_vm.dataPay.booking_type_sub === "service_tree_from_settings" ? "Отделение" : "Услуга")+" ")]),_c('div',{staticClass:"row-info__value",style:({
          color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
        })},[_vm._v(" "+_vm._s(_vm.dataPay.name)+" ")])]),(_vm.dataPay.booking_type_sub === 'service_tree_from_settings')?_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title",style:({
          color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
        })},[_vm._v(" Услуга ")]),(_vm.isServiceName)?_c('div',{staticClass:"row-info__value",style:({
          color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
        })},[_vm._v(" "+_vm._s(_vm.isServiceName)+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title",style:({
          color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
        })},[_vm._v(" Дата и время ")]),_c('div',{staticClass:"row-info__value",style:({
          color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
        })},[_vm._v(" "+_vm._s(_vm.dateTime ? _vm.dateTime : ((_vm.getDate(_vm.dataPay.dateTime)) + " в " + (_vm.dataPay.dateAndTime) + " "))+" ")])]),((_vm.totalPrice ? _vm.totalPrice : _vm.dataPay.price) > 0)?_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title",style:({
          color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
        })},[_vm._v(" Стоимость ")]),_c('div',{staticClass:"row-info__value",style:({
          color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
        })},[_vm._v(" "+_vm._s(_vm.totalPrice ? _vm.totalPrice : _vm.dataPay.price || 0)+" "+_vm._s(_vm.dataPay.currency)+" ")])]):_vm._e(),(_vm.dataPay.booking_type_sub === 'service_tree_from_settings')?_c('div',{},[_c('div',{staticClass:"form__text",style:({
          color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
        }),domProps:{"innerHTML":_vm._s(_vm.dataPay.modal_text)}})]):_vm._e(),(!_vm.isAdmin)?_c('div',[_c('p',{staticClass:"form__sub-title",style:({
          color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
        })},[_vm._v(" Контактные данные ")]),_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title",style:({
            color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
          })},[_vm._v(" Телефон ")]),_c('div',{staticClass:"row-info__value",style:({
            color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
          })},[_vm._v(" "+_vm._s(_vm.dataPay.phone)+" ")])]),_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title",style:({
            color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
          })},[_vm._v(" Адрес ")]),_c('div',{staticClass:"row-info__value",style:({
            color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
          })},[_vm._v(" "+_vm._s(_vm.dataPay.address)+" ")])])]):_vm._e(),(
        !_vm.isAdmin &&
        _vm.dataPay.booking_type_sub !==
          'individual_does_not_depend_on_the_number_of_seats'
      )?_c('div',[(!!_vm.dataPay.payment_type_name)?_c('p',{staticClass:"form__sub-title",style:({
          color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
        })},[_vm._v(" Оплата ")]):_vm._e(),(!!_vm.dataPay.payment_type_name)?_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title",style:({
            color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
          })},[_vm._v(" Тип оплаты ")]),_c('div',{staticClass:"row-info__value",style:({
            color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
          })},[_vm._v(" "+_vm._s(_vm.dataPay.payment_type_name)+" ")])]):_vm._e()]):_vm._e(),(
        !_vm.isAdmin && _vm.dataPay.booking_type_sub !== 'service_tree_from_settings'
      )?_c('div',{staticClass:"hl",style:({
        background: _vm.widgetTheme ? _vm.customClass.background_cells_fields : '',
      })}):_vm._e(),(_vm.dataPay.booking_type_sub !== 'service_tree_from_settings')?_c('div',{staticClass:"form__text",style:({
        color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
      }),domProps:{"innerHTML":_vm._s(_vm.dataPay.modal_text)}}):_vm._e(),(!_vm.isAdmin && !_vm.dataPay.hidden)?_c('div',{staticClass:"form__actions"},[(_vm.payOnlineButton)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.redirectToLink(_vm.dataPay.payment_link)}}},[_vm._v(" Оплатить онлайн ")]):_vm._e(),(
          _vm.payPlaceButton &&
          _vm.payOnlineButton &&
          !_vm.dataPay.payment.payment_required
        )?_c('el-button',{style:({
          background: _vm.widgetTheme ? _vm.customClass.background_cells_fields : '',
          color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
        }),on:{"click":_vm.closeModal}},[_vm._v(" Оплатить на месте ")]):_vm._e()],1):_vm._e()]):_vm._e(),(!_vm.isAdmin && !_vm.payInPlace && _vm.isRequiredPay && _vm.onlinePaymentRequired)?_c('div',[_c('div',{staticClass:"form__wrapper-header",style:({
        color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
      })},[_c('svg-icon',{staticClass:"form__icon-done",attrs:{"width":"40","height":"40","icon-name":"isDone"}}),_c('div',{staticClass:"form__close",on:{"click":_vm.closeModal}},[_c('svg-icon',{style:({
            color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
          }),attrs:{"icon-name":"close","width":"14","height":"14"}})],1)],1),_c('div',{staticClass:"title-widget",style:({
        color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
      })},[_vm._v(" Услуга успешно забронирована! ")]),_c('p',{staticClass:"form__sub-title",style:({
        color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
      })},[_vm._v(" Вы забронировали ")]),_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title",style:({
          color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
        })},[_vm._v(" "+_vm._s(_vm.dataPay.booking_type_sub === "service_tree_from_settings" ? "Отделение" : "Услуга")+" ")]),_c('div',{staticClass:"row-info__value",style:({
          color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
        })},[_vm._v(" "+_vm._s(_vm.dataPay.name)+" ")])]),(_vm.dataPay.booking_type_sub === 'service_tree_from_settings')?_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title",style:({
          color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
        })},[_vm._v(" Услуга ")]),(_vm.isServiceName)?_c('div',{staticClass:"row-info__value",style:({
          color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
        })},[_vm._v(" "+_vm._s(_vm.isServiceName)+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title",style:({
          color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
        })},[_vm._v(" Дата и время ")]),_c('div',{staticClass:"row-info__value",style:({
          color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
        })},[_vm._v(" "+_vm._s(_vm.dateTime ? _vm.dateTime : ((_vm.getDate(_vm.dataPay.dateTime)) + " в " + (_vm.dataPay.dateAndTime) + " "))+" ")])]),((_vm.totalPrice ? _vm.totalPrice : _vm.dataPay.price) > 0)?_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title",style:({
          color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
        })},[_vm._v(" Стоимость ")]),_c('div',{staticClass:"row-info__value",style:({
          color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
        })},[_vm._v(" "+_vm._s(_vm.totalPrice ? _vm.totalPrice : _vm.dataPay.price || 0)+" "+_vm._s(_vm.dataPay.currency)+" ")])]):_vm._e(),(!_vm.isAdmin)?_c('div',[_c('p',{staticClass:"form__sub-title",style:({
          color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
        })},[_vm._v(" Контактные данные ")]),_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title",style:({
            color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
          })},[_vm._v(" Телефон ")]),_c('div',{staticClass:"row-info__value",style:({
            color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
          })},[_vm._v(" "+_vm._s(_vm.dataPay.phone)+" ")])]),_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title",style:({
            color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
          })},[_vm._v(" Адрес ")]),_c('div',{staticClass:"row-info__value",style:({
            color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
          })},[_vm._v(" "+_vm._s(_vm.dataPay.address)+" ")])])]):_vm._e(),(
        !_vm.isAdmin &&
        _vm.dataPay.booking_type_sub !==
          'individual_does_not_depend_on_the_number_of_seats'
      )?_c('div',[(!!_vm.dataPay.payment_type_name)?_c('p',{staticClass:"form__sub-title",style:({
          color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
        })},[_vm._v(" Оплата ")]):_vm._e(),(!!_vm.dataPay.payment_type_name)?_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title",style:({
            color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
          })},[_vm._v(" Тип оплаты ")]),_c('div',{staticClass:"row-info__value",style:({
            color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
          })},[_vm._v(" "+_vm._s(_vm.dataPay.payment_type_name)+" ")])]):_vm._e()]):_vm._e(),(!_vm.isAdmin)?_c('div',{staticClass:"hl",style:({
        background: _vm.widgetTheme ? _vm.customClass.background_cells_fields : '',
      })}):_vm._e(),_c('div',{staticClass:"form__text",style:({
        color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
      }),domProps:{"innerHTML":_vm._s(_vm.dataPay.modal_text)}}),(!_vm.isAdmin && !_vm.dataPay.hidden)?_c('div',{staticClass:"form__actions"},[(_vm.payOnlineButton)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.redirectToLink(_vm.dataPay.payment_link)}}},[_vm._v(" Оплатить онлайн ")]):_vm._e(),(_vm.payPlaceButton && _vm.payOnlineButton)?_c('el-button',{style:({
          background: _vm.widgetTheme ? _vm.customClass.background_cells_fields : '',
          color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
        }),on:{"click":_vm.closeModal}},[_vm._v(" Оплатить на месте ")]):_vm._e()],1):_vm._e()]):_vm._e(),(!_vm.isAdmin && !_vm.isRequiredPay && _vm.onlinePaymentRequired && _vm.payInPlace)?_c('div',[_c('div',{staticClass:"form__wrapper-header",style:({
        color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
      })},[_c('svg-icon',{staticClass:"form__icon-done",attrs:{"width":"40","height":"40","icon-name":"isDone"}}),_c('div',{staticClass:"form__close",on:{"click":_vm.closeModal}},[_c('svg-icon',{style:({
            color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
          }),attrs:{"icon-name":"close","width":"14","height":"14"}})],1)],1),_c('div',{staticClass:"title-widget",style:({
        color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
      })},[_vm._v(" Услуга успешно забронирована! ")]),_c('p',{staticClass:"form__sub-title",style:({
        color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
      })},[_vm._v(" Вы забронировали ")]),_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title",style:({
          color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
        })},[_vm._v(" "+_vm._s(_vm.dataPay.booking_type_sub === "service_tree_from_settings" ? "Отделение" : "Услуга")+" ")]),_c('div',{staticClass:"row-info__value",style:({
          color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
        })},[_vm._v(" "+_vm._s(_vm.dataPay.name)+" ")])]),(_vm.dataPay.booking_type_sub === 'service_tree_from_settings')?_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title",style:({
          color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
        })},[_vm._v(" Услуга ")]),(_vm.isServiceName)?_c('div',{staticClass:"row-info__value",style:({
          color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
        })},[_vm._v(" "+_vm._s(_vm.isServiceName)+" ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title",style:({
          color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
        })},[_vm._v(" Дата и время ")]),_c('div',{staticClass:"row-info__value",style:({
          color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
        })},[_vm._v(" "+_vm._s(_vm.dateTime ? _vm.dateTime : ((_vm.getDate(_vm.dataPay.dateTime)) + " в " + (_vm.dataPay.dateAndTime) + " "))+" ")])]),((_vm.totalPrice ? _vm.totalPrice : _vm.dataPay.price) > 0)?_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title",style:({
          color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
        })},[_vm._v(" Стоимость ")]),_c('div',{staticClass:"row-info__value",style:({
          color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
        })},[_vm._v(" "+_vm._s(_vm.totalPrice ? _vm.totalPrice : _vm.dataPay.price || 0)+" "+_vm._s(_vm.dataPay.currency)+" ")])]):_vm._e(),(!_vm.isAdmin)?_c('div',[_c('p',{staticClass:"form__sub-title",style:({
          color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
        })},[_vm._v(" Контактные данные ")]),_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title",style:({
            color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
          })},[_vm._v(" Телефон ")]),_c('div',{staticClass:"row-info__value",style:({
            color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
          })},[_vm._v(" "+_vm._s(_vm.dataPay.phone)+" ")])]),_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title",style:({
            color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
          })},[_vm._v(" Адрес ")]),_c('div',{staticClass:"row-info__value",style:({
            color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
          })},[_vm._v(" "+_vm._s(_vm.dataPay.address)+" ")])])]):_vm._e(),(
        !_vm.isAdmin &&
        _vm.dataPay.booking_type_sub !==
          'individual_does_not_depend_on_the_number_of_seats'
      )?_c('div',[(!!_vm.dataPay.payment_type_name)?_c('p',{staticClass:"form__sub-title",style:({
          color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
        })},[_vm._v(" Оплата ")]):_vm._e(),(!!_vm.dataPay.payment_type_name)?_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title",style:({
            color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
          })},[_vm._v(" Тип оплаты ")]),_c('div',{staticClass:"row-info__value",style:({
            color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
          })},[_vm._v(" "+_vm._s(_vm.dataPay.payment_type_name)+" ")])]):_vm._e()]):_vm._e(),(!_vm.isAdmin)?_c('div',{staticClass:"hl",style:({
        background: _vm.widgetTheme ? _vm.customClass.background_cells_fields : '',
      })}):_vm._e(),_c('div',{staticClass:"form__text",style:({
        color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
      }),domProps:{"innerHTML":_vm._s(_vm.dataPay.modal_text)}}),(!_vm.isAdmin && !_vm.dataPay.hidden)?_c('div',{staticClass:"form__actions"},[(_vm.payOnlineButton)?_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.redirectToLink(_vm.dataPay.payment_link)}}},[_vm._v(" Оплатить онлайн ")]):_vm._e(),(
          _vm.payPlaceButton &&
          _vm.payOnlineButton &&
          !_vm.dataPay.payment.payment_required
        )?_c('el-button',{style:({
          background: _vm.widgetTheme ? _vm.customClass.background_cells_fields : '',
          color: _vm.widgetTheme ? _vm.customClass.inactive_text_color : '',
        }),on:{"click":_vm.closeModal}},[_vm._v(" Оплатить на месте ")]):_vm._e()],1):_vm._e()]):_vm._e(),(!_vm.isAdmin && _vm.isRequiredPay)?_c('div',[(!_vm.isAdmin && (_vm.isRequiredPay || _vm.isPrePayment) && _vm.payInPlace)?_c('div',[_c('div',{staticClass:"form__wrapper-header form__pre-pay"},[_c('svg-icon',{staticClass:"form__icon-done",attrs:{"width":"36","height":"36","icon-name":"isDone"}}),_c('div',{staticClass:"form__pre-pay_title",style:({
            color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
          })},[_vm._v(" Услуга успешно забронирована! ")]),_c('span',{staticClass:"form__pre-pay_text",style:({
            color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
          })},[_vm._v(_vm._s(_vm.prePay ? " Вам необходимо внести предоплату" : " Вам необходимо внести оплату")+" ")]),_c('span',{staticClass:"form__pre-pay_title-widget",style:({
            color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
          })},[_vm._v(_vm._s(_vm.prePay ? _vm.isPrePayment : _vm.dataPay.chek_sum || _vm.dataPay.payment_data.full_amount)+" "+_vm._s(_vm.dataPay.currency))]),(_vm.isPeriod)?_c('p',{staticClass:"form__pre-pay_text",style:({
            color: _vm.widgetTheme ? _vm.customClass.active_text_color : '',
          })},[_vm._v(" Если оплата не будет завершена в течение "),_c('br'),_vm._v(" "+_vm._s(_vm.isPeriod)+" минут , бронирование автоматически "),_c('br'),_vm._v(" будет отменено. ")]):_vm._e(),_c('div',{staticClass:"form__actions"},[(_vm.payOnlineButton)?_c('el-button',{style:({
              background: _vm.widgetTheme
                ? _vm.customClass.active_buttons_background
                : '',
              color: _vm.widgetTheme ? _vm.customClass.button_text_color : 'black',
            }),attrs:{"type":"primary"},on:{"click":function($event){return _vm.redirectToLink(_vm.dataPay.payment_link)}}},[_vm._v(" "+_vm._s(_vm.prePay ? "Внести предоплату" : " Оплатить")+" ")]):_vm._e()],1)],1)]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }