<template>
  <div>
    <div
      v-for="(item, index) in settings.data.group"
      :key="index"
      class="bk-info"
    >
      <p class="bk-info__title">
        {{ item.from ? `от ${item.from}` : "" }}
        {{ item.to ? `до  ${item.to} ` : "" }}
        <span>{{ `${unitCahnge.text}` }}</span>
      </p>
      <p class="bk-info__info">
        {{
          isHoliday && item.holidays_price
            ? item.holidays_price
            : item.base_price
        }}
        {{ itemList.item.currency }}
        {{ item.from ? `за ${settings.data.session_duration}` : "" }}
        <span>{{ `${unitCahnge.text}` }}</span>
      </p>
    </div>

    <p class="title-widget">Количество {{ unitCahnge.text }}</p>
    <el-input-number
      v-if="
        settings.data.time_unit === 'hours' ||
        settings.data.time_unit === 'days'
      "
      v-model="hours"
      class="w-full mb-12"
      :min="minHours"
      :max="maxHours"
      :step="step"
      @input="changePersons"
    ></el-input-number>
    <el-input-number
      v-if="settings.data.time_unit === 'minutes'"
      v-model="hours"
      class="w-full mb-12"
      :min="minMinutes"
      :max="maxMinutes"
      :step="step"
      @input="changePersons"
    ></el-input-number>
  </div>
</template>

<script>
export default {
  name: "RenDepTim",
  props: {
    itemList: {
      type: Object,
      default: () => {},
    },
    slotDetails: {
      type: Object,
      default: () => {},
    },
    slotsValidity: {
      type: Object,
      default: () => {},
    },
    settings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      hours: 0,
    };
  },
  computed: {
    isHoliday() {
      return this.itemList?.item.is_holiday;
    },

    step() {
      return Number(this.settings.data.session_duration);
    },
    maxHours() {
      const lastChild = this.settings.data.group[
        this.settings.data.group.length - 1
      ];
      // console.log(
      //   this.slotsValidity.booking_type.maxmum_rent,
      //   this.hours
      //   // this.settings.minimum_rent
      // );
      return Number(lastChild.to) || 99999;
    },
    minHours() {
      return Number(this.settings.data.minimum_rent);
      // return Number(this.settings.data.group[0].from) || 1;
    },
    minMinutes() {
      return Number(this.settings.data.group[0].from) || 1;
    },
    maxMinutes() {
      const lastChild =
        !this.settings.data.maxmum_rent && this.slotsValidity
          ? this.slotsValidity.booking_type.maxmum_rent
          : this.settings.data.maxmum_rent || 99999;
      // this.settings.data.group[this.settings.data.group.length - 1];

      return typeof lastChild === "number" || typeof lastChild === "string"
        ? Number(lastChild) || 99999
        : Number(lastChild.to) || 99999;
    },

    unitCahnge() {
      switch (this.settings.data.time_unit) {
        case "days":
          return {
            text: "дней",
            param: "day",
            time: this.hours,
            price: this.hours,
          };
        case "hours":
          return {
            text: "часов",
            param: "hour",
            time: this.hours,
            price: this.hours,
          };
        case "minutes":
          return {
            text: "минут",
            param: "minute",
            time: this.hours,
            price: this.hours,
          };

        default:
          return this.settings.data.time_unit;
      }
    },
  },
  watch: {
    hours() {
      if (
        this.settings.data.time_unit === "minutes" &&
        this.hours % this.settings.data.session_duration
      ) {
        this.hours = Math.round(this.hours / 10) * 10;
        console.log(this.hours, this.settings.data.time_unit);
      }
    },
  },
  created() {
    if (this.itemList.status) {
      this.hours =
        this.slotDetails?.item?.book_info?.rent?.duration ||
        this.slotDetails.booking.book_info.rent?.duration;
    }
  },

  methods: {
    changePersons() {
      const findInterval = this.settings.data.group.find((el) => {
        const to = el.to ? Number(el.to) : this.maxHours;
        // 71.1)
        // if (this.itemList.status) {
        //   this.hours =
        //     this.slotDetails?.item?.book_info?.rent?.duration ||
        //     this.slotDetails.booking.book_info.rent?.duration;
        // }
        return this.hours <= to;
      });

      // const prices = [];
      // if (this.settings.data.group.length) {
      //   this.settings.data.group.forEach((e) => {
      //     const obj = {};
      //     obj.name = `от ${e.from} до ${e.to} часов`;
      //     obj.price = e.base_price;
      //     obj.unit = this.unitCahnge();
      //     // this.settings.data.time_unit;
      //     prices.push(obj);
      //   });
      // }

      if (findInterval.discount) {
        this.$emit("setDiscount", {
          discount: findInterval.discount,
          unit: findInterval.discount_unit,
        });
      }

      this.$emit("setSlotParam", {
        hash: this.itemList.hash,
        date: this.itemList.date,
        time: this.itemList.item.time,
        cnt: this.hours,
      });

      this.$emit("setRent", {
        duration: this.unitCahnge.time,
        unit: this.unitCahnge.param,
        price: this.isHoliday
          ? findInterval.holidays_price
          : findInterval.base_price,
      });

      this.$emit(
        "setHours",
        this.settings.data.time_unit === "minutes" ? this.hours : this.hours
      );

      this.$emit(
        "setPrice",
        this.isHoliday ? findInterval.holidays_price : findInterval.base_price
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";
.bk-info {
  margin-bottom: 6px;
  &__title {
    flex-grow: 1;
    width: auto;
  }
  &__info {
    flex-grow: 0;
  }
}
.title-widget {
  margin-top: 12px;
}
</style>
