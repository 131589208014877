<template>
  <div class="mb-30">
    <h5 v-if="title">{{ title }}</h5>
    <vue-editor
      ref="editor"
      :value="value"
      :editor-toolbar="customToolbar"
      :placeholder="placeholder"
      @input="$emit('input', $event)"
      @blur="$emit('blur', $event)"
    >
      ></vue-editor
    >
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
// import VuePlainClipboard from "vue-quill-plain-clipboard";

// Quill.register("modules/clipboard", VuePlainClipboard, true);

export default {
  components: {
    VueEditor,
  },
  props: {
    value: { type: String, default: "" },
    title: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    customToolbar: {
      type: Array,
      default: () => [
        [{ header: [false, 1, 2, 3] }],
        [{ list: "ordered" }, { list: "bullet" }],
        ["bold", "italic"],
      ],
    },
  },
  // data() {
  //   return { editorText: "" };
  // },
  created() {
    // this.editorSettings = {
    //   modules: {
    //     clipboard: {
    //       matchVisual: false,
    //     },
    //   },
    // };
  },
  // mounted() {
  //   const Editor = this.$refs.editor;
  //   const $quillContainer = Editor.$refs.quillContainer;
  //   $quillContainer.onpaste = (event) => {
  //     const text = event.clipboardData.getData("Text");
  //     // this.$emit("input", text);
  //   };

  //   window.addEventListener("paste", (event) => {
  //     const paste = (event.clipboardData || window.clipboardData).getData(
  //       "text"
  //     );
  //     console.log("paste", paste);
  //   });
  // },
  methods: {
    blur(e) {
      console.log(e);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

::v-deep .ql-snow .ql-picker {
  color: $--color-white;
}
::v-deep .ql-picker-label {
  padding-left: 0;
}
::v-deep .ql-toolbar.ql-snow .ql-picker-label {
  border: none;
}
::v-deep .ql-picker-options {
  color: black;
}
::v-deep .quillWrapper .ql-snow .ql-stroke {
  stroke: $--color-white;
}

::v-deep .ql-toolbar.ql-snow {
  border: none;
  padding: 0 0 10px;
}

::v-deep #quill-container {
  border: none;
  background-color: $--color-light-white;
  border-radius: 3px;
  font-family: Montserrat;
  font-size: 14px;
  line-height: 24px;
}
::v-deep .ql-snow.ql-toolbar .ql-picker-label.ql-active,
::v-deep .ql-snow.ql-toolbar button.ql-active,
::v-deep .ql-snow.ql-toolbar .ql-picker-item:hover,
::v-deep .ql-snow.ql-toolbar .ql-picker-label:hover,
::v-deep .ql-snow.ql-toolbar button:hover {
  color: $--color-primary;
}
::v-deep .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
::v-deep .ql-snow.ql-toolbar button.ql-active .ql-stroke,
::v-deep .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
::v-deep .ql-snow.ql-toolbar button:hover .ql-stroke {
  stroke: $--color-primary;
}

::v-deep .ql-editor.ql-blank::before {
  color: $--color-medium-white;
  font-style: normal;
}
</style>
