<template>
  <el-form
    ref="baseInfo"
    hide-required-asterisk
    label-position="top"
    class="regular"
  >
    <p class="hint-title__medium-white">
      В данном варианте вам необходимо будет вручную указать в какие дни, и в
      какое время оказывается услуга.
    </p>
    <div class="list-fields">
      <div v-for="(item, i) in list" :key="i" class="list-fields__row">
        <div class="list-fields__item w-full">
          <el-form-item prop="basePrice">
            <div slot="label" class="flex">
              <div>Выберите период</div>
              <el-popover
                class="ml-8"
                placement="top"
                width="200"
                trigger="hover"
                content="Если у услуги нет даты окончания, то поле «По» необходимо оставить
пустым. Если расписание и цены зависят от сезона, то можно добавить несколько временных периодов."
              >
                <Tooltip slot="reference" />
              </el-popover>
            </div>
            <div class="wrapper-calendar">
              <div class="wrapper-calendar__from">
                C<span class="danger">*</span>
              </div>
              <el-date-picker
                v-model="list[i].date[0]"
                type="date"
                placeholder="выберите дату"
                :picker-options="scheduleDateOptions"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
              >
              </el-date-picker>
              <div class="wrapper-calendar__to">По</div>
              <el-date-picker
                v-model="list[i].date[1]"
                type="date"
                placeholder="выберите дату"
                :picker-options="scheduleDateOptions"
                value-format="yyyy-MM-dd"
                format="yyyy-MM-dd"
              >
              </el-date-picker>
            </div>
          </el-form-item>
        </div>
        <div v-if="list.length > 1" class="list-fields__actions">
          <ButtonIcon
            icon-name="close"
            width="16"
            @click="deleteField(i)"
          ></ButtonIcon>
        </div>
      </div>
    </div>
    <el-button class="custom-button__outline" @click="addField()">
      Добавить период
    </el-button>
  </el-form>
</template>

<script>
import moment from "moment";
import { mapState, mapActions, mapMutations } from "vuex";
import { format } from "date-fns";

export default {
  name: "Manual",
  data() {
    return {
      list: [
        {
          date: [format(new Date(), "yyyy-MM-dd"), null],
        },
      ],
      scheduleDateOptions: {
        disabledDate: (time) => {
          return time < moment().subtract(1, "day").toDate();
        },
      },
    };
  },
  computed: {
    ...mapState("service", ["generator"]),
  },
  created() {
    if (this.generator.data.manual) this.list = [...this.generator.data.manual];
  },
  methods: {
    ...mapActions("service", ["saveGenerator"]),
    ...mapMutations("service", ["setGenerator"]),

    async saveData() {
      this.setGenerator({
        field: this.generator.schedule_type,
        value: [...this.list.filter((item) => item)],
      });
      // REMOVE TO DEL request

      //  await this.saveGenerator([...this.list.filter((item) => item)]);
    },
    deleteField(index) {
      this.list.splice(index, 1);
    },
    addField() {
      this.list.push({
        date: [format(new Date(), "yyyy-MM-dd"), null],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";
.wrapper-calendar {
  display: flex;
  align-items: center;
  &__from {
    margin-right: 12px;
  }
  &__to {
    margin: 0 12px;
  }
}
.hint-title__medium-white {
  padding-bottom: 30px;
}

.list-fields {
  margin-bottom: 0;

  &__item {
    flex-grow: 0;
  }
  &__row:last-child {
    margin-bottom: 10px;
  }
}

.time-picker {
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
}

@screen lg {
  .hint-title__medium-white {
    padding-bottom: 25px;
  }
}
</style>
