<template>
  <div class="wrapper">
    <div class="header-wrapper">
      <div class="header-wrapper__text">
        <h1
          class="font-semibold text-white-normal text-28 leading-44 lg:text-22 lg:leading22 xs:mb-5"
        >
          Услуги
        </h1>
        <p class="sub-title">Всего услуг: {{ servicesList.length }}</p>
      </div>
      <div class="header-wrapper__actions">
        <el-button
          type="primary"
          class="custom-button header-wrapper__actions-button"
          @click="getCheckService"
        >
          <svg-icon
            icon-name="plus"
            width="10"
            height="10"
            class="mr-12"
          ></svg-icon>
          Добавить услугу
        </el-button>
      </div>
    </div>
    <div class="wrapper__content">
      <spinner v-if="loading && !servicesList.length" />

      <div v-if="servicesList.length" class="services-drag">
        <draggable
          tag="ul"
          :list="servicesList"
          class="services-drag__draggable"
          handle=".handle"
          v-bind="dragOptions"
          @start="drag = true"
          @end="drag = false"
          @change="onDragChange"
        >
          <transition-group
            type="transition"
            :name="!drag ? 'flip-list' : null"
          >
            <li
              v-for="(element, idx) in servicesList"
              :key="idx"
              class="services-drag__item"
              :class="{ 'bg-white-60': (idx + 1) % 2 === 0 }"
            >
              <span class="handle services-drag__item-handle">
                <svg-icon
                  icon-name="dragger"
                  class-name="text-white-500"
                  width="6"
                  height="16"
                ></svg-icon>
              </span>
              <router-link
                class="services-drag__item-name"
                :to="{
                  name: 'resource',
                  params: { id: element.id, hash: element.hash },
                  query: { tab: 'schedule' },
                }"
              >
                <span
                  class="services-drag__item-name"
                  :title="element.name"
                  :style="{
                    color: !element.active ? 'rgba(255, 255, 255, 0.25)' : '',
                  }"
                  >{{ element.name }}
                </span></router-link
              >

              <router-link
                v-if="!$mq.largePhone"
                :to="{
                  name: 'resource',
                  params: { id: element.id, hash: element.hash },
                  query: { tab: 'schedule' },
                }"
                class="services-drag__item-icon"
              >
                <div class="wrapper-icon">
                  <svg-icon
                    icon-name="schedule2"
                    width="16"
                    height="16"
                  ></svg-icon>

                  <div
                    v-if="element.is_filled.schedule === false"
                    class="wrapper-icon__point"
                  ></div>
                </div>

                <span
                  :style="{
                    color: !element.active ? 'rgba(255, 255, 255, 0.25)' : '',
                  }"
                  >Расписание</span
                >
              </router-link>

              <router-link
                v-if="!$mq.largePhone"
                :to="{
                  name: 'resource',
                  params: { id: element.id, hash: element.hash },
                  query: { tab: 'description' },
                }"
                class="services-drag__item-icon"
              >
                <div class="wrapper-icon">
                  <svg-icon
                    icon-name="warning-outline"
                    width="20"
                    height="20"
                  ></svg-icon>
                </div>

                <span
                  :style="{
                    color: !element.active ? 'rgba(255, 255, 255, 0.25)' : '',
                  }"
                  >Описание</span
                >
              </router-link>

              <router-link
                v-if="!$mq.largePhone"
                :to="{
                  name: 'resource',
                  params: { id: element.id, hash: element.hash },
                  query: { tab: 'datetime' },
                }"
                class="services-drag__item-icon"
              >
                <div class="wrapper-icon">
                  <svg-icon
                    icon-name="datetime"
                    width="16"
                    height="16"
                  ></svg-icon>
                  <div
                    v-if="element.is_filled.schedule === false"
                    class="wrapper-icon__point"
                  ></div>
                </div>
                <span
                  :style="{
                    color: !element.active ? 'rgba(255, 255, 255, 0.25)' : '',
                  }"
                  >Даты и цены</span
                >
              </router-link>

              <router-link
                v-if="!$mq.tablet"
                :to="{
                  name: 'resource',
                  params: { id: element.id, hash: element.hash },
                  query: { tab: 'settings' },
                }"
                class="services-drag__item-icon"
              >
                <div class="wrapper-icon">
                  <svg-icon
                    icon-name="settings"
                    width="20"
                    height="20"
                  ></svg-icon>
                </div>
                <span
                  :style="{
                    color: !element.active ? 'rgba(255, 255, 255, 0.25)' : '',
                  }"
                  >Настройки</span
                >
              </router-link>

              <router-link
                v-if="!$mq.desktop"
                :to="{
                  name: 'resource',
                  params: { id: element.id, hash: element.hash },
                  query: { tab: 'notifications' },
                }"
                class="services-drag__item-icon"
              >
                <div class="wrapper-icon">
                  <svg-icon icon-name="news2" width="16" height="21"></svg-icon>

                  <div
                    v-if="element.is_filled.notifications === false"
                    class="wrapper-icon__point"
                  ></div>
                </div>
                <span
                  :style="{
                    color: !element.active ? 'rgba(255, 255, 255, 0.25)' : '',
                  }"
                >
                  Уведомления</span
                >
              </router-link>

              <router-link
                v-if="!$mq.desktop"
                :to="{
                  name: 'resource',
                  params: { id: element.id, hash: element.hash },
                  query: { tab: 'integration' },
                }"
                class="services-drag__item-icon"
              >
                <div class="wrapper-icon">
                  <svg-icon
                    icon-name="integration"
                    width="22"
                    height="16"
                  ></svg-icon>
                  <div
                    v-if="element.is_filled.widgets === false"
                    class="wrapper-icon__point"
                  ></div>
                </div>
                <span
                  :style="{
                    color: !element.active ? 'rgba(255, 255, 255, 0.25)' : '',
                  }"
                  >Виджеты</span
                >
              </router-link>

              <el-popover
                style="flex-shrink: 0"
                placement="bottom-end"
                popper-class="control-poper"
                trigger="click"
              >
                <span slot="reference" class="services-drag__item-handle">
                  <svg-icon
                    icon-name="dots"
                    class-name="text-white-500"
                    width="20"
                    height="4"
                  ></svg-icon>
                </span>
                <div>
                  <el-switch
                    v-model="element.active"
                    class="px-10 border-b border-white-100 flex items-center h-45"
                    active-text="Активна"
                    @change="changeResourceStatus($event, element.hash)"
                  >
                  </el-switch>
                  <router-link
                    v-if="$mq.tablet"
                    :to="{
                      name: 'resource',
                      params: { id: element.id, hash: element.hash },
                      query: { tab: 'schedule' },
                    }"
                    class="services-drag__item-icon mobile"
                  >
                    <div class="wrapper-icon">
                      <svg-icon
                        icon-name="schedule2"
                        width="16"
                        height="16"
                      ></svg-icon>
                      <div
                        v-if="element.is_filled.schedule === false"
                        class="wrapper-icon__point"
                      ></div>
                    </div>
                    <span class="">Расписание</span>
                  </router-link>
                  <router-link
                    v-if="$mq.tablet"
                    :to="{
                      name: 'resource',
                      params: { id: element.id, hash: element.hash },
                      query: { tab: 'schedule' },
                    }"
                    class="services-drag__item-icon mobile"
                  >
                    <div class="wrapper-icon">
                      <svg-icon
                        icon-name="warning-outline"
                        width="16"
                        height="20"
                      ></svg-icon>
                      <!--                      <div class="wrapper-icon__point"></div>-->
                    </div>

                    <span class="">Описание</span>
                  </router-link>

                  <router-link
                    v-if="$mq.desktop"
                    :to="{
                      name: 'resource',
                      params: { id: element.id, hash: element.hash },
                      query: { tab: 'datetime' },
                    }"
                    class="services-drag__item-icon mobile"
                  >
                    <div class="wrapper-icon">
                      <svg-icon
                        icon-name="datetime"
                        width="16"
                        height="16"
                      ></svg-icon>
                      <div
                        v-if="element.is_filled.schedule === false"
                        class="wrapper-icon__point"
                      ></div>
                    </div>
                    <span class="">Даты и цены</span>
                  </router-link>

                  <router-link
                    v-if="$mq.desktop"
                    :to="{
                      name: 'resource',
                      params: { id: element.id, hash: element.hash },
                      query: { tab: 'settings' },
                    }"
                    class="services-drag__item-icon mobile"
                  >
                    <svg-icon
                      icon-name="settings"
                      width="16"
                      height="20"
                    ></svg-icon>
                    <span class="">Настройки</span>
                  </router-link>

                  <router-link
                    v-if="$mq.large"
                    :to="{
                      name: 'resource',
                      params: { id: element.id, hash: element.hash },
                      query: { tab: 'notifications' },
                    }"
                    class="services-drag__item-icon mobile"
                  >
                    <div class="wrapper-icon">
                      <svg-icon
                        icon-name="news2"
                        width="16"
                        height="21"
                      ></svg-icon>
                      <div
                        v-if="element.is_filled.notifications === false"
                        class="wrapper-icon__point"
                      ></div>
                    </div>
                    <span class="">Уведомления</span>
                  </router-link>

                  <router-link
                    v-if="$mq.large"
                    :to="{
                      name: 'resource',
                      params: { id: element.id, hash: element.hash },
                      query: { tab: 'integration' },
                    }"
                    class="services-drag__item-icon mobile"
                  >
                    <div class="wrapper-icon">
                      <svg-icon
                        icon-name="integration"
                        width="16"
                        height="16"
                      ></svg-icon>
                      <div
                        v-if="element.is_filled.widgets === false"
                        class="wrapper-icon__point"
                      ></div>
                    </div>
                    <span class="">Виджеты</span>
                  </router-link>

                  <div
                    class="services-drag__item-icon mobile"
                    @click="editService({ id: element.id, hash: element.hash })"
                  >
                    <svg-icon
                      icon-name="edit"
                      width="16"
                      height="18"
                    ></svg-icon>
                    <span> Редактировать</span>
                  </div>
                  <div
                    class="services-drag__item-icon services-drag__item-icon-remove mobile"
                    @click="
                      removeResourceAt({ id: element.id, hash: element.hash })
                    "
                  >
                    <svg-icon
                      icon-name="close"
                      width="16"
                      height="12"
                    ></svg-icon>
                    <span> Удалить</span>
                  </div>
                </div>
              </el-popover>
            </li>
          </transition-group>
        </draggable>
      </div>
      <NoData
        v-else-if="!loading && !servicesList.length"
        size="small"
        class="mt-50 sm:mt-35"
        text="Вы пока не добавили ни одной услуги."
      />
      <Instructions
        v-if="docs.length"
        :docs="docs"
        class="mt-50 sm:mt-35 instr"
      ></Instructions>
    </div>
    <el-dialog :visible.sync="isModalDelete" width="500">
      <p class="title">Удалить услугу?</p>
      <el-alert
        title="Внимание! При удалении услуги вместе с ней будут удалены все виджеты,
        промокоды и скидки созданные для нее, а также она будет удалена из
        списка доступных услуг у сотрудников, в мульти-виджетах, промокодах и
        скидках."
        type="warning"
        :closable="false"
        class="w-full mb-40"
      />
      <div class="flex justify-end">
        <el-button @click="isModalDelete = false"> Отмена </el-button>
        <el-button
          type="primary"
          @click="
            deleteService(selectedId);
            isModalDelete = false;
          "
          >Удалить</el-button
        >
      </div>
    </el-dialog>
    <el-dialog :visible.sync="isModalCopy" width="500">
      <p class="title">Что вы хотите скопировать?</p>
      <p class="descriprion">
        Информация будет скопирована из услуги: Звездные войны
      </p>
      <el-checkbox-group v-model="checkList">
        <el-checkbox label="Расписание"></el-checkbox>
        <el-checkbox label="Описание"></el-checkbox>
        <el-checkbox label="Даты и цены"></el-checkbox>
        <el-checkbox label="Настройки"></el-checkbox>
        <el-checkbox label="Уведомления"></el-checkbox>
        <el-checkbox label="Виджеты"></el-checkbox>
      </el-checkbox-group>
      <el-button type="primary" class="custom-button">Скопировать</el-button>
    </el-dialog>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import { AJAX_POST } from "@/units/ajax";
import Instructions from "@/components/Instructions";
import NoData from "@/components/NoData";

export default {
  name: "Services",
  components: {
    Instructions,
    draggable,
    NoData,
  },
  data() {
    return {
      drag: false,
      dragging: false,
      isModalCopy: false,
      isModalDelete: false,
      loading: true,
      checkList: [],
      docs: [
        // {
        //   title: "Инструкции для квестов",
        //   description: "Как добавить, настроить и управлять бронированиями",
        //   video_link: true,
        //   link: true,
        // },
        // {
        //   title: "Инструкции для событий и экскурсий",
        //   description:
        //     "Как добавить, настроить и управлять бронированием билетов ",
        //   video_link: true,
        //   link: true,
        // },
      ],
    };
  },
  computed: {
    ...mapState("user", ["servicesList", "isCheckServiceCreate"]),
    ...mapState("user", ["user"]),
    ...mapGetters("menu", {
      menuItem: "menuItem",
    }),
    dragOptions() {
      return {
        animation: 300,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  async created() {
    await this.getServicesList();

    this.docs = [
      ...this.user.instructions.services,
      ...[this.user.instructions.company],
    ];

    this.loading = false;
  },
  methods: {
    ...mapActions("user", ["getServicesList", "deleteService", "copyResource"]),
    ...mapActions("user", ["getCheckServiceCreate"]),
    ...mapMutations("menu", ['decrementCount"']),
    ...mapMutations(["setNotif"]),
    changeResourceStatus(event, resourceId) {
      AJAX_POST("/api/resource/update", {
        hash: resourceId,
        action: "updateActive",
        active: event ? 1 : 0,
      });
    },
    onDragChange() {
      const result = {};

      this.servicesList.forEach((resource, index) => {
        resource.position = index;
        result[resource.hash] = resource.position;
      });

      AJAX_POST("/api/resource/set/positions", { positions: result }).then(
        (response) => {
          console.log(response);
        }
      );
    },
    // Поменял id на hash
    removeResourceAt(hash) {
      this.isModalDelete = true;
      this.selectedId = hash;
    },
    editService({ id, hash }) {
      this.$router.push({
        name: "edit-service",
        params: { id, hash },
      });
    },
    async getCheckService() {
      await this.getCheckServiceCreate();
      // добавить переход если true
      if (this.isCheckServiceCreate)
        this.$router.push({ name: "create-service" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.header-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 0 50px;

  &__text {
    font-family: Montserrat;

    .title {
      font-weight: 600;
      font-size: 28px;
      line-height: 44px;
      color: $--color-white;
      margin-bottom: 5px;
    }
    .sub-title {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $--color-white-5;
    }
  }

  &__actions {
    display: flex;
    &-button {
      margin-left: 20px !important;
    }
    ::v-deep .el-button i {
      font-weight: bold;
    }
  }
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.services-drag {
  &__draggable {
    border-bottom: 1px solid $--color-sm-white;
    border-top: 1px solid $--color-sm-white;
  }
  &__item {
    display: flex;
    height: 50px;
    &-handle {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 11px;
      cursor: pointer;
      border-right: 1px solid $--color-sm-white;
      border-left: 1px solid $--color-sm-white;
      height: 100%;
      flex-shrink: 0;
    }
    &-name {
      min-width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $--color-white;
      flex-grow: 1;
      margin: auto 15px;
    }
    &-icon {
      display: flex;
      align-items: center;
      padding: 0 10px;
      cursor: pointer;
      flex-shrink: 0;
      svg {
        color: #3498db;
      }
      &-remove {
        svg {
          color: #ff0000;
        }
      }
      span {
        margin-left: 10px;
        border-bottom: 1px solid $--color-white-35;
      }
    }
  }
}
.mobile {
  font-size: 14px;
  line-height: 24px;
  min-height: 34px;

  span {
    border: none;
  }
}
.wrapper-icon {
  position: relative;
  &__point {
    position: absolute;
    top: 0;
    right: 0;
    background-color: red;
    width: 7px;
    height: 7px;
    border-radius: 50%;
  }
}
::v-deep .el-dialog {
  padding: 30px 40px 40px;
  max-width: 500px;
  width: 100%;
  &__headerbtn {
    top: 20px;
    right: 20px;
  }
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    color: $--color-white;
    margin-bottom: 10px;
  }
  .descriprion {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: $--color-white-5;
    margin-bottom: 30px;
  }

  @screen lg {
    padding: 20px 15px;
  }
}

::v-deep .el-checkbox-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

::v-deep .el-checkbox {
  margin-right: 0;
  margin-bottom: 10px;
}

@screen lg {
  .header-wrapper {
    flex-direction: column;
    padding: 0 10px 20px;
    align-items: start;
    &__text {
      margin-bottom: 15px;

      .title {
        margin-bottom: 0;
      }
    }
    &__actions {
      flex-direction: column;
      &-button {
        margin-left: 0 !important;
        margin-bottom: 15px !important;
      }
    }
  }

  .services-drag {
    padding: 0 10px;
  }
}
</style>

<style lang="scss">
@import "element-variables.scss";

.actions-item {
  display: flex;
  align-items: center;
  height: 34px;
  padding-right: 15px;
  padding-left: 15px;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  svg {
    margin-right: 11px;
    color: $--color-white-35;
  }
  &:hover {
    background: #4e2565;
  }
}
.instr {
  @media (max-width: 1024px) {
    margin: 10px;
  }
}
</style>
