<template>
  <el-form hide-required-asterisk label-position="top">
    <ForAdmin ref="forAdmin" />
    <div class="hl"></div>
    <ForClient ref="forClient" />
    <div class="hl"></div>
    <el-button type="primary" class="custom-button" @click="submit">
      Сохранить
    </el-button>
  </el-form>
</template>

<script>
import ForClient from "@/views/Services/Create/components/ForClient.vue";
import ForAdmin from "@/views/Services/Create/components/ForAdmin.vue";
import { mapMutations } from "vuex";

export default {
  name: "Notification",
  components: { ForClient, ForAdmin },
  methods: {
    ...mapMutations(["setNotif"]),
    async submit() {
      await this.$refs.forClient.submit();
      await this.$refs.forAdmin.submit();
      this.setNotif({
        title: "Данные сохранены",
      });
    },
  },
};
</script>
