<template>
  <div class="bk-full">
    <p class="title-widget">Выберите тариф</p>
    <el-radio-group v-model="radio" @input="emitTariff($event)">
      <el-radio v-for="(tariff, i) in settings.data" :key="i" :label="tariff">
        <div class="radio__title">
          {{ tariff.tariff_name }} •
          <span>{{ `${tariff.duration} минут` }}</span>
        </div>
        <div class="radio__sub-title">
          <span> {{ getCost(tariff) }} {{ itemList.item.currency }} </span>
          •
          <span>за 1 место</span>
        </div>
        <div
          v-if="
            tariff.data.length > 1 &&
            radio &&
            radio.tariff_name === tariff.tariff_name
          "
          class="radio__description"
        >
          <div
            v-for="(item, index) in tariff.data"
            :key="index"
            class="bk-info"
          >
            <p class="bk-info__title">
              от {{ item.from }} {{ item.to ? `до ${item.to}` : "" }}
            </p>
            <p class="bk-info__info">
              {{ isHoliday ? item.holidays_price : item.base_price }}
              {{ itemList.item.currency }}
            </p>
          </div>
        </div>
      </el-radio>
    </el-radio-group>
    <p class="title-widget">Количество участников</p>
    <el-input-number
      v-model="valueCount"
      class="w-full"
      :min="1"
      :max="maxCount"
      @input="changePersons($event)"
    ></el-input-number>
  </div>
</template>

<script>
export default {
  name: "TarifSeat",
  props: {
    settings: {
      type: Object,
      default: () => {},
    },
    dateParam: {
      type: String,
    },
    itemList: {
      type: Object,
      default: () => {},
    },
    slotDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      radio: null,
      valueCount: 1,
    };
  },
  computed: {
    currentPrice() {
      if (!this.radio) return null;
      let base;
      let holidays;
      if (this.radio.data.length > 1) {
        const findInterval = this.radio.data.find((el) => {
          const to = el.to ? Number(el.to) : this.maxCount;
          return this.valueCount <= to;
        });
        holidays = findInterval.base_price; // + this.itemList.item.weekend_price_additional;
        base = findInterval.base_price; // + this.itemList.item.price_additional;
      } else {
        holidays = this.radio.data[0].holidays_price; // +this.itemList.item.weekend_price_additional;

        base = this.radio.data[0].base_price; // + this.itemList.item.price_additional;
      }
      return this.isHoliday ? holidays : base;
    },

    isHoliday() {
      return this.itemList.item.is_holiday;
    },
    maxCount() {
      if (this.radio) {
        const lastChild = this.radio.data[this.radio.data.length - 1];
        return Number(lastChild.to) || 99999;
      }
      return 99999;
    },
  },
  created() {
    // Changed tariff_name from name
    const checkValue = this.slotDetails?.booking?.book_info?.tariff?.name;
    const val = this.settings.data.find((el) => el.tariff_name === checkValue);
    const person = this.slotDetails?.booking?.book_info?.tariff?.persons;
    this.valueCount = this.itemList.status ? person : 1;
    this.radio = this.itemList.status
      ? val
      : this.settings.data.find((el, idx) => idx === 0);
    this.emitTariff(this.radio);
  },

  methods: {
    getCost(tariff) {
      if (tariff.data.length > 1) {
        const findInterval = tariff.data.find((el) => {
          const to = el.to ? Number(el.to) : 999999;
          return this.valueCount <= to;
        });
        // console.log(
        //   this.isHoliday
        //     ? findInterval.holidays_price +
        //         this.itemList.item.weekend_price_additional
        //     : findInterval.base_price + this.itemList.item.price_additional
        // );
        return this.isHoliday
          ? findInterval.holidays_price
          : // +
            //     this.itemList.item.weekend_price_additional
            findInterval.base_price; // + this.itemList.item.price_additional;
      }
      return this.isHoliday && tariff.data[0].holidays_price
        ? tariff.data[0].holidays_price
        : // +
          //     this.itemList.item.weekend_price_additional
          tariff.data[0].base_price; // + this.itemList.item.price_additional;
    },
    emitTariff(tariff) {
      if (this.itemList.status) {
        return;
      }
      this.valueCount = 0;
      const lastChild = tariff.data[tariff.data.length - 1].to || 99999;
      if (Number(lastChild) < this.valueCount)
        this.$emit("input", Number(lastChild));
      // this.$emit("setServTarif", {
      //   base_price: this.currentPrice,
      //   price: this.currentPrice,
      //   person: this.valueCount,
      //   tariff,
      // });
      this.$emit("isDis", true);
    },
    changePersons(value) {
      const totalPrice = this.currentPrice * this.valueCount;
      if (!this.radio) return;
      // this.$emit("input", value);
      this.$emit(
        "setServTarif",
        {
          duration: this.radio.duration,
          id: this.radio.id,
          name: this.radio.tariff_name,
          unit: this.radio.time_unit,
          price: this.isHoliday
            ? this.currentPrice // this.itemList.item.weekend_price_additional + this.currentPrice
            : this.currentPrice, // this.itemList.item.price_additional + this.currentPrice,
          persons: this.valueCount,
        },
        totalPrice
        //
        //   base_price: this.currentPrice,
        //   price: this.currentPrice * this.valueCount,
        //   person: this.valueCount,
        //   tariff: this.radio,
        // }
      );
      this.$emit("setSlotParam", {
        bookingHash: this.itemList.item.booking_hash,
        book_info: { tickets: this.radio },
        resourceHash: this.itemList.hash,
        time: this.itemList.item.time,
        date: this.dateParam,
        cnt: null,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "element-variables.scss";

.radio {
  &__title {
    margin-bottom: 4px;
  }
  &__sub-title {
    margin-bottom: 12px;
    span {
      &:first-child {
        margin-right: 6px;
      }
      &:last-child {
        color: $--color-white-5;
        margin-left: 6px;
      }
    }
  }
  &__description {
    height: auto;
    margin-bottom: 18px;
  }
}
</style>
