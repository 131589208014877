<template>
  <el-popover placement="top-end" popper-class="control-poper" trigger="click">
    <div slot="reference" class="popover__wrapp">
      <div class="slot__wrapp">
        <div
          class="rounded-full w-20 h-20 icon__schedule"
          :class="{
            icon__active: !isBlocked && isType,
            icon__active_blur: !isBlocked && isType && !blurSlot,
            icon__close: isBlocked,
            icon__empty: !isBlocked && !isType,
            // icon__disabled: dis && !isBlocked && isType,
            slot__doule: isType && isBlocked,
          }"
        >
          <svg-icon
            v-if="isBlocked === true"
            icon-name="lock-1"
            width="8"
            height="10"
          ></svg-icon>

          <span v-else-if="!isBookingHash && isReserv" class="text-10">
            {{
              isType === "individual" ||
              item.booking_type_sub === "rent_depends_only_on_time"
                ? 1
                : item.bookingLength
            }}
          </span>

          <span v-if="isBookingHash && !isBlocked" class="text-10">
            {{ item.bookingLength ? item.bookingLength : 1 }}
          </span>
        </div>
        <div
          v-if="
            isBlocked &&
            isBookingType !== 'individual' &&
            item.booking_type_sub !==
              'individual_does_not_depend_on_the_number_of_seats'
          "
          class="rounded-full w-20 h-20 icon__schedule"
          :class="{
            icon__active: isType && !!item.bookingLength,
            icon__close: isBlocked,
            icon__empty: !isBlocked && !isType,
            slot__doule: isType && isBlocked && !!item.bookingLength,
          }"
        >
          <svg-icon
            v-if="isBlocked === true && !item.bookingLength"
            icon-name="lock-1"
            width="8"
            height="10"
          ></svg-icon>
          <span v-else-if="isType" class="text-10">
            {{
              isType === "individual" ||
              item.booking_type_sub === "rent_depends_only_on_time"
                ? 1
                : item.bookingLength
            }}
          </span>
        </div>
      </div>
    </div>
    <div class="block">
      <!-- Расписание тултип - Нет броней -->
      <div
        v-if="!isBlocked && !isType"
        class="block__item_position mb-8 flex-col"
      >
        <span class="block__time">{{ item.time }}</span>
        <span class="block__status">
          {{ !isBlocked && !isType ? "Нет бронирований" : "" }}
        </span>
      </div>
      <div
        v-if="!isBlocked && !isType && !item.is_passed"
        class="flex flex-col"
      >
        <el-button
          v-if="!isBlocked && !isType"
          type="primary"
          :disabled="dis"
          class="block__btn mt-10 mb-10"
          @click="addNewBooking(item)"
          >Забронировать
        </el-button>

        <el-button
          class="block__btn"
          @click="changeBlockSlot(status.type, item)"
          >{{ "Заблокировать время" }}</el-button
        >
      </div>

      <!--  Расписание тултип - Слот заблокирован -->

      <div
        v-if="isBlocked && isType !== 'group'"
        class="block__item_position mb-8"
      >
        <span class="block__time">{{ item.time }}</span>

        <span
          class="block__status"
          :class="{ 'block__status close': isBlocked }"
        >
          {{ isBlocked ? "Заблокирован" : "" }}
        </span>
      </div>

      <div v-if="isBlocked && isType !== 'group'" class="mb-5">
        <div class="small_text">Кем заблокирован</div>
        <span class="block__text_border">{{ item.userBlock }}</span>
      </div>

      <div v-if="isBlocked && isType !== 'group'" class="mb-15">
        <div class="small_text">Дата и время блокировки</div>
        <div v-if="isBlocked" class="flex items-center">
          <span v-if="isBlocked" class="block__text_border mb-0">{{
            dateConvert(item.dateBlock)
          }}</span>
          <!-- <span class="block__text_border mb-0 px-5"> (UTC+03:00)</span> -->
        </div>
      </div>
      <div v-if="isBlocked && isType !== 'group'" class="flex flex-col">
        <el-button
          v-if="isBlocked"
          class="block__btn"
          @click="changeBlockSlot(status, item)"
          >{{ "Разблокировать время" }}</el-button
        >
      </div>

      <!--  Расписание тултип - 1 бронирование -->
      <!-- <span>rent_depends_only_on_time </span> -->
      <!-- added  item.booking_type_sub !== 'group_same_seat_limit_for_all_sessions'
-->
      <div
        v-if="
          (isBookingHash || isType === 'individual') &&
          !isBlocked &&
          item.booking_type_sub !== 'rent_depends_on_time_and_place' &&
          item.booking_type_sub !== 'group_same_seat_limit_for_all_sessions'
        "
        class="block__item mb-8"
      >
        <span v-if="!isBookingHash" class="block__time">{{ item.time }}</span>
        <span v-else class="block__time">{{ item.reservation[0].time }}</span>

        <span
          v-if="
            (isBookingHash ||
              isType === 'individual' ||
              item.booking_type_sub === 'rent_depends_only_on_time') &&
            !isBlocked
          "
          class="block__status"
          :class="{
            block__status:
              isBookingHash || (isType === 'individual' && !isBlocked),
          }"
          :style="{ background: item.statusColor }"
        >
          {{ item.statusName }}
        </span>
      </div>

      <div
        v-if="
          (isBookingHash ||
            isType === 'individual' ||
            item.booking_type_sub === 'rent_depends_only_on_time') &&
          !isBlocked &&
          item.booking_type_sub !== 'rent_depends_on_time_and_place' &&
          item.booking_type_sub !== 'group_same_seat_limit_for_all_sessions'
        "
        class="flex flex-col"
      >
        <div
          v-if="
            (isBookingHash || isType === 'individual') &&
            !isBlocked &&
            item.booking_type_sub !== 'rent_depends_on_time_and_place'
          "
          class="block__text_blue mb-8"
        >
          <router-link
            :to="{
              path: '/reserves',
              query: {
                text: item.booking_hash ? item.booking_hash : item.clientHash,
              },
            }"
            target="_blank"
          >
            1 бронь - #{{
              item.booking_hash ? item.booking_hash : item.clientHash
            }}</router-link
          >
        </div>

        <div
          v-if="item.reservation.find((el) => el.bind_resource_hash)"
          class="mb-15"
        >
          <span>
            <svg-icon icon-name="overlap" width="20" height="20"></svg-icon>
          </span>
          <router-link
            target="_blank"
            :to="{
              name: 'resource',
              params: { hash: item.reservation[0].bind_resource_hash },
              query: { tab: 'schedule' },
            }"
          >
            <span class="item__text">
              {{ `Услуга: ${item.reservation[0].bind_resource_name}` }}</span
            >
          </router-link>
        </div>
      </div>

      <div
        v-if="
          (isBookingHash || isType === 'individual') &&
          !isBlocked &&
          item.booking_type_sub !== 'rent_depends_on_time_and_place' &&
          item.booking_type_sub !== 'group_same_seat_limit_for_all_sessions'
        "
        class="flex flex-col"
      >
        <div class="small_text">Клиент</div>
        <div
          v-if="
            (isBookingHash ||
              isType === 'individual' ||
              item.booking_type_sub === 'rent_depends_only_on_time') &&
            !isBlocked
          "
        >
          <div class="block__text inline-block border-b">
            <router-link
              :to="{
                name: 'clients',
                //params: { id: item.clientHash },
                query: { id: item.clientHash },
              }"
            >
              <span
                v-if="
                  (isBookingHash ||
                    isType === 'individual' ||
                    item.booking_type_sub === 'rent_depends_only_on_time') &&
                  !isBlocked
                "
                class="pr-10"
                >{{ isClientName }}</span
              >

              <span
                v-if="
                  (isBookingHash ||
                    isType === 'individual' ||
                    item.booking_type_sub === 'rent_depends_only_on_time') &&
                  !isBlocked
                "
                >{{ isSurname }}</span
              >
            </router-link>
          </div>
        </div>
        <div
          v-if="
            (isBookingHash ||
              isType === 'individual' ||
              item.booking_type_sub === 'rent_depends_only_on_time') &&
            !isBlocked
          "
        >
          <div
            v-if="
              (isBookingHash ||
                isType === 'individual' ||
                item.booking_type_sub === 'rent_depends_only_on_time') &&
              !isBlocked
            "
            class="block__text inline-block border-b"
          >
            <a :href="`tel:${isPhone}`"> {{ isPhone }}</a>
          </div>
        </div>

        <div
          v-if="
            (isBookingHash ||
              isType === 'individual' ||
              item.booking_type_sub === 'rent_depends_only_on_time') &&
            !isBlocked
          "
        >
          <div class="block__text inline-block border-b">
            <a :href="'mailto:' + isMail"> {{ isMail }}</a>
          </div>
        </div>
      </div>
      <div
        v-if="
          (isBookingHash || isType === 'individual') &&
          !isBlocked &&
          item.booking_type_sub !== 'rent_depends_on_time_and_place' &&
          item.booking_type_sub !== 'group_same_seat_limit_for_all_sessions'
        "
        class="mb-15"
      >
        <div class="flex items-center">
          <span
            v-if="
              (isBookingHash ||
                isType === 'individual' ||
                item.booking_type_sub === 'rent_depends_only_on_time') &&
              !isBlocked
            "
            class="block__text block__text_border mb-0 pr-3"
          >
            {{ item.reservation[0].final_price }}
          </span>
          <span
            v-if="
              (isBookingHash ||
                isType === 'individual' ||
                item.booking_type_sub === 'rent_depends_only_on_time') &&
              !isBlocked
            "
            class="block__text block__text_border mb-0 pr-3"
          >
            {{ item.currency }}
          </span>

          <div
            v-if="(isBookingHash || isType === 'individual') && !isBlocked"
            class="flex items-center opacity-50 mx-8"
          >
            <span>.</span>
          </div>
          <!--   <span
            v-if="
              (isBookingHash ||
                isType === 'individual' ||
                item.booking_type_sub === 'rent_depends_only_on_time') &&
              !isBlocked
            "
            class="block__text block__text_border mb-0"
            >{{ item.time }}</span
          > -->
          <span
            v-if="(isBookingHash || isType === 'individual') && !isBlocked"
            class="block__text block__text_border mb-0"
          >
            {{ item.reservation[0].time }}
          </span>
          <span
            v-if="
              (isBookingHash ||
                isType === 'individual' ||
                item.booking_type_sub === 'rent_depends_only_on_time') &&
              !isBlocked
            "
            class="block__text block__text_border mb-0 px-3"
            >-
          </span>

          <span
            v-if="
              (isBookingHash ||
                isType === 'individual' ||
                item.booking_type_sub === 'rent_depends_only_on_time') &&
              !isBlocked
            "
            class="block__text block__text_border mb-0"
          >
            {{ item.time_end }}</span
          >

          <span class="block__text block__text_border mb-0 pl-3">
            ({{ item.duration || isDuration }})
          </span>
        </div>
      </div>
      <div
        v-if="
          (isBookingHash || isType === 'individual') &&
          !isBlocked &&
          item.booking_type_sub !== 'rent_depends_on_time_and_place' &&
          item.booking_type_sub !== 'group_same_seat_limit_for_all_sessions'
        "
        class="flex flex-col"
      >
        <el-button
          v-if="!item.reservation[0].bind_resource_hash"
          class="block__btn"
          @click="changeStatus(item)"
          >{{ "Редактировать" }}</el-button
        >
      </div>

      <!--  -->
      <!-- Расписание тултип - Несколько броней, групповая запись -->
      <!-- <RentDepTimePlace
        :item="item"
        :is-disabled="isDisabled"
        :is-blocked="isBlocked"
      /> -->

      <div v-if="isType === 'service'">
        <router-link
          class="block__btn mt-15"
          :to="{
            path: '/reserves',
            query: {
              page: 1,
              pagination: 10,
              service_time: `${item.time}`,
              service_from: `${item.reservation[0].date}`,
              service_to: `${item.reservation[0].date}`,
              names: `${hash}`,
            },
          }"
          ><span>Смотреть бронирования </span></router-link
        >
      </div>

      <div
        v-if="
          (isType === 'group' ||
            (isType === 'rent' &&
              item.booking_type_sub !== 'rent_depends_only_on_time')) &&
          isBookingHash
          // (!isBookingHash || isBookingHash) Т К ПОЯВЛЯЕТСЯ ДВА БЛОКА !!!
        "
        class="block__item_position mb-8 flex-col"
      >
        <span class="block__time">{{ item.time }}</span>

        <span
          class="block__status"
          :class="{
            'block__status active':
              isType === 'group' ||
              (isType === 'rent' &&
                item.booking_type_sub !== 'rent_depends_only_on_time'),
            'block__status blur': isBlocked,
          }"
        >
          {{ item.bookingLength }} бронирования
        </span>
      </div>
      <div
        v-if="
          (isType === 'group' ||
            (isType === 'rent' &&
              item.booking_type_sub !== 'rent_depends_only_on_time')) &&
          isBookingHash
          // (!isBookingHash || isBookingHash)
        "
        class=""
      >
        <div v-if="item.tickets_places_count" class="flex justify-between">
          <span class="small-text">Места </span>
          <!-- <span class="block__text_border">{{ item.totalSeats }}</span> -->
          <span class="block__text_border">{{
            item.tickets_places_count.total
          }}</span>
        </div>
        <div v-if="item.tickets_places_count" class="flex justify-between">
          <span class="small_text">Забронировано </span>
          <span class="block__text_border"
            >{{ item.tickets_places_count.booked }}
          </span>
        </div>
        <div v-if="item.tickets_places_count" class="flex justify-between">
          <span class="small_text">Осталось </span>
          <span class="block__text_border">{{
            item.tickets_places_count.remained
          }}</span>
        </div>
      </div>
      <div
        v-if="
          (isType === 'group' ||
            (isType === 'rent' &&
              item.booking_type_sub !== 'rent_depends_only_on_time')) &&
          isBookingHash
          // (!isBookingHash || isBookingHash)
        "
        class="flex flex-col"
      >
        <el-button
          v-if="
            (isType === 'group' ||
              (isType === 'rent' &&
                item.booking_type_sub !== 'rent_depends_only_on_time')) &&
            isBookingHash
            // (!isBookingHash || isBookingHash)
          "
          :disabled="isDisabled || isBlocked"
          type="primary"
          class="block__btn mt-10"
          @click="addNewBooking(item)"
          >Добавить бронирование
        </el-button>
        <router-link
          class="block__btn mt-15"
          :to="{
            path: '/reserves',
            query: {
              page: 1,
              pagination: 10,
              service_time: `${item.time}`,
              service_from: `${item.reservation[0].date}`,
              service_to: `${item.reservation[0].date}`,
              names: `${hash}`,
            },
          }"
          ><span>Смотреть бронирования </span></router-link
        >
        <el-button
          :disabled="isDisabled"
          class="block__btn mt-15"
          @click="changeBlockSlot(status, item)"
          >{{
            (isType === "group" || isType === "rent") &&
            isBlocked &&
            !isBookingHash
              ? "Разблокировать время"
              : "Заблокировать время"
          }}</el-button
        >
      </div>
      <spinner v-if="isLoading" class="table__spinner--slot" />
    </div>
  </el-popover>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { AJAX_GET, AJAX_POST } from "@/units/ajax.js";
// import RentDepTimePlace from "@/components/Schedule/RentDepTimePlace";

export default {
  components: {
    // RentDepTimePlace,
  },
  props: {
    item: Object,
    nameService: String,
    data: String,
    dis: Boolean,
    hash: String,
  },
  data() {
    return {
      isLoading: false,
      disabl: false,
      status: null,
      blocked: null,
    };
  },
  computed: {
    isBookingType() {
      return this.item;
    },
    isBlocked() {
      return this.item?.block || this.item?.is_blocked;
    },
    isType() {
      return this.item?.type;
    },

    isClientName() {
      return this.item?.reservation?.[0]?.client_info?.name;
    },
    isSurname() {
      return this.item?.reservation?.[0]?.client_info?.surname;
    },
    isMail() {
      return this.item?.reservation?.[0]?.client_info?.email;
    },
    isPhone() {
      return this.item?.reservation?.[0]?.client_info?.tel;
    },
    isReserv() {
      return this.item?.reservation.length > 0;
    },
    isDuration() {
      return (
        this.item?.reservation?.[0]?.book_info?.tariff?.duration ||
        this.item?.reservation[0]?.book_info?.service?.duration
      );
    },
    isTime() {
      return (
        this.item?.reservation?.book_info?.tariff?.time_unit ||
        this.item?.reservation[0]?.book_info?.tariff?.duration
      );
    },
    diffSeats() {
      return this.item.totalSeats - this.item.seats;
    },

    isDisabled() {
      return this.item.totalSeats > 0
        ? this.item.totalSeats === this.diffSeats
        : false;
    },
    blurSlot() {
      return !!(
        this.item.reservation.length >= 1 &&
        this.item.time === this.item?.reservation[0]?.time
      );
    },
    isBookingHash() {
      return this.item.booking_hash?.length > 1;
    },
    isClientInfo() {
      return this.item?.reservation?.[0]?.client_info;
    },
    isRent() {
      return this.item?.reservation?.[0]?.book_info?.rent?.duration;
    },
  },

  async created() {
    this.status = this.getStatus();
  },
  methods: {
    ...mapActions("schedule", ["getSchedule"]),
    ...mapActions("settings", ["getSettingsSite"]),
    ...mapMutations(["setNotif"]),

    addNewBooking(item) {
      this.$emit("openForm", {
        nameService: this.nameService,
        hash: this.hash,
        item,
      });
    },
    changeStatus(item) {
      this.$emit("openForm", {
        status: "update",
        nameService: this.nameService,
        hash: this.hash,
        item,
      });
    },
    unitCahnge(param) {
      switch (param) {
        case "days":
          return {
            text: "дней",
            textShot: "д",
            param: "day",
            time: this.hours,
          };
        case "hours":
          return {
            text: "часов",
            textShot: "ч",
            param: "hour",
            time: this.hours,
          };
        case "minutes":
          return {
            text: "минут",
            textShot: "мин",
            param: "minute",
            time: this.hours,
          };
        case "minute":
          return {
            text: "минут",
            textShot: "мин",
            param: "minute",
            time: this.hours,
          };

        default:
          return this.settings?.data?.time_unit;
      }
    },

    async changeBlockSlot(status, item) {
      this.isLoading = true;
      if (this.item.block === true) {
        await AJAX_POST("/api/v1/booking/admin/slot/unblock", {
          time: item.time,
          date: this.getDate(this.data),
          schedule_id: item.resource_id,
        })
          .then((result) => {
            if (result.data.success === true) {
              const date = this.getDate(this.data);
              this.getSchedule(result.data.date);
              this.getSettingsSite();
              this.isLoading = false;
            }
          })

          .catch((err) => {
            console.log(err);
          });
      } else {
        AJAX_POST("/api/v1/booking/admin/slot/block", {
          time: item.time,
          date: this.getDate(this.data),
          schedule_id: item.resource_id,
        })
          .then((result) => {
            if (result.data.success === true) {
              this.getSchedule(result.data.date);
              this.getSettingsSite();
              this.isLoading = false;
            } else {
              this.setNotif({ title: `${result.data.msg}`, type: "warning" });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      this.isLoading = false;
    },

    dateConvert(param) {
      const date = new Date(param);
      return date.toLocaleString("ru-RU");
    },
    getDate(param) {
      const date = new Date(param);
      return date.toLocaleDateString("en-CA");
    },
    getStatus() {
      if (this.item.active) return { type: "active", text: "Подтвержден" };
      return { type: "close", text: "Заблокирован" };
    },
  },
};
</script>

<style lang="scss" scoped>
.popover {
  &__wrapp {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.el-button + .el-button {
  margin-left: 0;
}
::v-deep .el-popover {
  background: #000;
  border-color: #000;
}
.icon {
  &__schedule {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
  }
  &__close {
    background: #8a949d;
  }
  &__active {
    background: #3498db;
    color: #3c1452;
    &_blur {
      background: #385696;
      //rgba(52, 152, 219, 0.5);
    }
  }
  &__empty {
    background: transparent;
    top: inherit;
    width: 100%;
    height: 50%;
  }
  &__disabled {
    background: rgba(52, 152, 219, 0.5);
    color: rgba(0, 0, 0, 0.9);
  }
}
.block {
  border-radius: 3px;
  padding: 16px;
  background: #000;
  &__btn {
    height: 30px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    font-size: 12px;
    min-width: 218px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__item {
    display: flex;
    align-items: center;
    &_position {
      display: flex;
      align-items: flex-start;
    }
  }
  &__text {
    font-size: 14px;
    line-height: 24px;
    color: white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 5px;
    &_blue {
      color: #3498db;
    }
    &_border {
      font-size: 14px;
      line-height: 24px;
      color: white;
      border-bottom: none;
    }
  }

  &__status {
    line-height: 20px;
    font-size: 12px;
    padding: 2px 8px;
    border-radius: 3px;
    &.active {
      background: rgba(52, 152, 219, 0.2);
      color: #3498db;
    }
    &.blur {
      color: rgba(52, 152, 219, 0.4);
      background: transparent;
    }
    &.close {
      color: #e74c3c;
      background: rgba(231, 76, 60, 0.2);
    }
  }
  &__time {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    margin-right: 10px;
    color: #fff;
  }
}
.slot {
  &__wrapp {
    display: flex;
    justify-content: center;
  }
  &__doule {
    position: relative;
    margin: 0 3px;
    left: 0px;
    transform: translateX(0%);
  }
  &__mask {
    background: rgba(0, 0, 0, 0.9);
  }
}
.table__spinner--slot {
  position: absolute;
  left: 50vh;
}
.bachUp {
  width: 100%;
  height: 100%;
  background: rgba(52, 152, 219, 0.5);
}
.item__text {
  display: inline-block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
