<template>
  <el-form
    ref="baseInfo"
    hide-required-asterisk
    label-position="top"
    class="regular"
  >
    <p class="hint-title__medium-white">
      Вы можете связать расписание новой услуги с расписанием ранее добавленной.
      При бронировании дочерней услуги будет закрываться сеанс в расписании у
      родительской и наоборот. Данный тип расписания доступен только для
      Индивидуальной записи.
    </p>
    <el-form-item prop="category">
      <span slot="label">Выберите услугу<span class="danger">*</span> </span>
      <el-select
        v-model="associateService"
        placeholder="Выберите услугу"
        filterable
        class="w-full"
      >
        <el-option
          v-for="(item, idx) in listFilter"
          :key="`cat-${item.hash}-${idx}`"
          :label="item.name"
          :value="item.hash"
        >
        </el-option>
      </el-select>
    </el-form-item>
    <div v-if="isHaveSessions" class="attention">
      <div class="text-white-normal mb-10">
        <el-alert
          center
          title="Внимание!!! У вас уже есть расписание и оно связанно с родительской услугой.

После нажатия на кнопку «Сгенерировать», будет создано временное расписание на базе расписания выбранной услуги и вам необходимо будет проверить и при необходимости обновить цены и добавочную стоимость. Обратите внимание, что изменения вступят в силу только после сохранения расписания на следующем шаге.

После нажатия на кнопку «К сеансам», вы вернетесь к ручному редактированию ранее созданного расписания.
"
          type="warning"
          show-icon
          :closable="false"
          class="mt-15 text-16"
        />
      </div>
    </div>
  </el-form>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from "vuex";

export default {
  name: "Bind",
  data() {
    return {
      associateService: null,
    };
  },

  computed: {
    ...mapState("user", ["user", "servicesList"]),
    ...mapState("service", ["service", "generator", "sessions"]),
    ...mapGetters("service", ["isHaveSessions"]),

    listFilter() {
      return this.user.resources_individuals.filter(
        (ser) => ser.hash !== this.service.hash
      );
    },
  },
  created() {
    if (this.generator.data.bind)
      this.associateService = this.generator.data.bind;

    this.listFilter.sort((a, b) => {
      return a.position - b.position;
    });
  },
  methods: {
    ...mapActions("service", ["saveGenerator", "getSessions"]),
    ...mapMutations("service", ["setGenerator"]),

    async saveData() {
      const param = this.generator.schedule_type ?? this.sessions.schedule_type;
      this.setGenerator({
        field: param,
        value: this.associateService,
      });
      // await this.saveGenerator(this.associateService);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.hint-title__medium-white {
  padding-bottom: 30px;
}

.list-fields {
  margin-bottom: 0;

  &__item {
    flex-grow: 0;
  }
  &__row:last-child {
    margin-bottom: 10px;
  }
}

.time-picker {
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;
}

@screen lg {
  .hint-title__medium-white {
    padding-bottom: 25px;
  }
}
</style>
