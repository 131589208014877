<template>
  <div class="wrapper px-10">
    <spinner v-if="isLoading" />
    <template v-else>
      <h1
        class="font-semibold text-white-normal text-28 leading-44 lg:text-22 lg:leading22 mb-30 xs:mb-5"
      >
        {{
          isNewCompany
            ? "Добавление компании"
            : `Данные компании ${company.company_name}`
        }}
      </h1>
      <div class="wrapper__content">
        <div class="wrapper__content-ww">
          <el-collapse>
            <BlockCollapse
              title="Компания"
              name="InfoData"
              sub-title="Название, категория бизнеса, логотип и описание"
            >
              <InfoData />
            </BlockCollapse>
            <BlockCollapse
              name="ContactData"
              :disabled="isNewCompany"
              title="Контактные данные"
              sub-title="Контактные телефоны, e-mail, мессенджеры и социальные сети"
            >
              <ContactData />
            </BlockCollapse>
            <BlockCollapse
              name="AddressData"
              :disabled="isNewCompany"
              :class="{ 'not-mb': company.address.length }"
              title="Адреса и филиалы"
              sub-title="Адреса и филиалы, где оказываются услуги"
            >
              <AddressData key="createMap" is-new />
            </BlockCollapse>
            <BlockCollapse
              v-for="(item, index) in company.address"
              :key="`address ${index}`"
              :name="`AddressData-${index}`"
              :class="[
                'address',
                { 'not-mb': index != company.address.length - 1 },
              ]"
              :title="item.address_name"
              :sub-title="`Привязанно ${
                item.linked_services ? item.linked_services.count : 0
              } услуг`"
            >
              <AddressData :key="`Map` + index" :index="index" />
            </BlockCollapse>
            <!-- <BlockCollapse
            name="RequisitesData"
            :disabled="isNewCompany"
            :class="{ 'not-mb': listReq.length }"
            title="Реквизиты"
            sub-title="Рекомендуем заполнить, если вы или ваши клиенты планируют оплачивать услуги по безналичному расчету"
          >
            <RequisitesData is-new />
          </BlockCollapse> -->
            <BlockCollapse
              v-for="(item, index) in listReq"
              :key="`listReq ${index}`"
              :name="`RequisitesData-${index}`"
              :class="['address', { 'not-mb': index != listReq.length - 1 }]"
              :title="item.name"
              sub-title="Привязанно 0 услуг"
            >
              <RequisitesData :index="index" />
            </BlockCollapse>
          </el-collapse>
          <div
            :class="['widget-custom', { 'widget-custom__open': isShowWidget }]"
          >
            <Widget />
            <div class="wrapper-hint">
              <div class="wrapper-hint__text">
                Пример мини-сайта <br />
                для компании
              </div>
              <svg-icon
                class="wrapper-hint__icon"
                icon-name="arrowWidgetUp"
                class-name="wrapper-hint__icon"
              ></svg-icon>
            </div>
          </div>
          <el-button
            v-if="$mq.tablet"
            :icon="isShowWidget ? 'el-icon-close' : 'el-icon-mobile'"
            :class="['show-widget', { 'show-widget__close': isShowWidget }]"
            @click="isShowWidget = !isShowWidget"
          ></el-button>
        </div>
        <Instructions :docs="docs"></Instructions>
      </div>
    </template>
  </div>
</template>

<script>
import BlockCollapse from "@/components/BlockCollapse";
import InfoData from "@/views/Company/components/InfoData.vue";
import ContactData from "@/views/Company/components/ContactData.vue";
import AddressData from "@/views/Company/components/AddressData.vue";
import RequisitesData from "@/views/Company/components/RequisitesData.vue";
import Instructions from "@/components/Instructions";
import Widget from "@/views/Company/components/WidgetCompany.vue";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "AddCompany",
  components: {
    BlockCollapse,
    InfoData,
    ContactData,
    AddressData,
    RequisitesData,
    Instructions,
    Widget,
  },
  data() {
    return {
      isShowWidget: false,
      activeBlock: "1",
      isLoading: true,
      docs: [
        {
          title: "Инструкции для квестов",
          description: "Как добавить, настроить и управлять бронированиями",
          linkVideos: true,
        },
        {
          title: "Инструкции для событий и экскурсий",
          description:
            "Как добавить, настроить и управлять бронированием билетов ",
          linkVideos: true,
        },
      ],
    };
  },
  computed: {
    ...mapState("company", ["company", "listReq"]),
    ...mapState("user", ["user"]),

    ...mapGetters("company", ["isNewCompany"]),
  },
  async created() {
    await this.getCompany();
    this.getTypesActivity();
    this.docs = [];
    this.docs.push(this.user.instructions.company);
    this.isLoading = false;
  },
  methods: {
    ...mapActions("company", ["getTypesActivity", "getCompany"]),
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

::v-deep .el-collapse {
  border-color: transparent !important;
  flex-grow: 1;
}

.address ::v-deep .collapse-title h2 {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}
.not-mb {
  margin-bottom: 0 !important;
  border-bottom: 1px solid $--color-light-white;
}

.widget-custom {
  max-width: 550px;
  width: 20%;
  min-width: 320px;
}

.wrapper-hint {
  display: flex;
  flex-direction: row;
  color: $--color-white-5;
  margin-top: 30px;

  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    position: relative;
    top: 8px;
  }
  &__icon {
    margin-left: 10px;
    width: 40px;
    height: 40px;
  }
}

::v-deep .widget {
  &__header {
    &-wrapper {
      display: flex;
      flex-direction: column;

      .img-stub,
      img {
        margin-bottom: 40px;
      }
      .info {
        flex-direction: column;

        &-header {
          font-size: 18px;
          line-height: 28px;
        }
      }
    }

    .button-play {
      top: 70px;
      right: 20px;
    }
    .text-cover {
      top: 125px;
      left: 20px;
    }
  }
  .el-tabs__nav-scroll {
    margin: 0 10px;
  }
}

::v-deep .contact__info-item {
  width: 100%;
}
::v-deep .wrapper-gallery {
  &__video {
    width: 100%;
  }

  &__img {
    width: calc(50% - 20px);
  }
}

@screen lg {
  ::v-deep .widget .el-tabs__nav-scroll {
    margin: 0 20px -15px;
  }
  .widget-custom {
    max-width: none;
  }
  .wrapper-hint {
    display: none;
  }
  ::v-deep .contact__info-item {
    width: 50%;
  }
  ::v-deep .wrapper-gallery {
    &__video,
    &__img {
      width: calc(25% - 20px);
    }
  }
}
@media (max-width: 800px) {
  .wrapper-gallery {
    &__video,
    &__img {
      width: calc(33% - 20px);
    }
  }
}
@media (max-width: 600px) {
  ::v-deep .contact__info-item {
    width: 100%;
  }
  ::v-deep .wrapper-gallery {
    &__video {
      width: 100%;
    }

    &__img {
      width: calc(50% - 20px);
    }
  }
}
@media (max-width: 420px) {
  ::v-deep .widget .el-tabs__nav-scroll {
    margin: 0 10px;
  }
}
</style>
