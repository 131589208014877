<template>
  <Modal :show="show" title="Детали действия" @close="$emit('close')">
    <el-table
      :data="[{}, {}, {}, {}]"
      class="border-b-0 mb-20"
      sortable
      :stripe="true"
    >
      <el-table-column prop="id" label="№" sortable :min-width="62">
        1
      </el-table-column>
      <el-table-column
        prop="id"
        label="Категория действия"
        sortable
        :min-width="62"
      >
        Категория действия
      </el-table-column>
      <el-table-column prop="id" label="Действие" :min-width="62">
        Категория действия
      </el-table-column>
      <el-table-column
        prop="id"
        label="Предыдущее значение"
        sortable
        :min-width="62"
      >
        Предыдущее значение
      </el-table-column>
      <el-table-column
        prop="id"
        label="Новое значение"
        sortable
        :min-width="62"
      >
        Новое значение
      </el-table-column>
    </el-table>
  </Modal>
</template>

<script>
import Modal from "@/components/Modal";

export default {
  components: {
    Modal,
  },
  props: {
    show: Boolean,
  },
};
</script>
