<template>
  <el-form hide-required-asterisk label-position="top">
    <p class="header">
      <!-- Вы можете добавить дополнительные опции к услуге: -->
      <el-popover
        class="ml-8"
        placement="top"
        width="200"
        trigger="hover"
        content="Отметьте, если оплата или предоплата услуги онлайн является обязательной. Если она не будет оплачена в течении указанного времени, бронирование будет автоматически отменено."
      >
        <!-- <Tooltip slot="reference" /> -->
      </el-popover>
    </p>
    <div class="el-form-item__label my-10">Выбор города</div>
    <el-select v-model="checkTown" class="w-full">
      <el-option
        v-for="(item, index) in ubrirCities"
        :key="`type-${index}`"
        :label="item.name"
        :value="item.id"
      >
      </el-option>
    </el-select>
    <div class="el-form-item__label my-10">Выбор отделения</div>
    <el-select v-model="checkBranch" class="w-full" :disabled="!checkTown">
      <el-option
        v-for="item in ubrirBranches[checkTown]"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      >
      </el-option>
    </el-select>
    <div class="hl"></div>
    <el-button type="primary" class="custom-button" @click="addBranch">
      Связать
    </el-button>
  </el-form>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "LinkUBRR",
  props: {
    serviceId: {
      type: String,
    },
  },
  data() {
    return {
      checkTown: null,
      checkBranch: null,
    };
  },
  computed: {
    ...mapState("user", ["user", "ubrirCities", "ubrirBranches"]),
    ...mapState("service", ["service"]),
  },
  watch: {
    checkTown() {
      if (!this.service.ubrir_city_id) {
        this.checkBranch = null;
      }
    },
  },
  created() {
    if (this.service.ubrir_city_id) {
      this.checkTown = this.ubrirCities.find(
        (cities) => cities.id === this.service.ubrir_city_id
      ).id;

      this.checkBranch = this.service.ubrir_id;
    }
  },
  methods: {
    ...mapActions("service", ["addBranchId"]),
    addBranch() {
      this.addBranchId({
        hash: this.service.hash ?? this.serviceId,
        branch_id: this.checkBranch,
      });
    },
  },
};
</script>
