/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import moment from "moment";
import { AJAX_GET, AJAX_POST } from "@/units/ajax";
import { setField, setFieldObj } from "@/helpers/store";

export default {
  namespaced: true,
  state: {
    errorMsgSchedule: null,
    schedule: {},
    scheduleCalendarDate: [],
    scheduleIsLoading: false,
    currentSchedulePage: null,
    lastSchedulePage: null,
  },
  getters: {
    schedule: (state) => state.schedule,
    scheduleIsLoading: (state) => state.scheduleIsLoading,
    currentSchedulePage: (state) => state.currentSchedulePage,
    lastSchedulePage: (state) => state.lastSchedulePage,
    isScheduleEmpty: (state) => {
      let result = true;
      for (const key in state.schedule) {
        if (state.schedule[key].slots.length) {
          result = false;
          break;
        }
      }
      return result;
    },
    isScheduleByDatesEmpty: (state) => {
      let result = true;
      for (let i = 0; i < state.schedule.length; i++) {
        if (state.schedule[i].slots.length) {
          result = false;
          break;
        }
      }
      return result;
    },
  },
  mutations: {
    setField,
    setFieldObj,

    setSchedule(state, { allSchedule, servicesList }) {
      const schedule = {};
      servicesList.forEach((service) => {
        if (service.active) {
          schedule[service.hash] = {
            name: service.name,
            id: service.id,
            ids: service.hash,
            msg: [],
            slots: [],
          };

          if (Array.isArray(allSchedule[service.hash])) {
            allSchedule[service.hash].forEach((slot) => {
              schedule[service.hash].msg.push(slot.msg);
              schedule[service.hash].slots.push({
                price_additional: slot.price_additional,
                weekend_price_additional: slot.weekend_price_additional,
                type: slot.booking_type,
                booking_type_sub: slot.booking_type_sub,
                duration: slot.duration,
                bookingLength: slot.booking_count,
                booking_hash: slot.booking_hash,
                resource_id: slot.id,
                time: slot.time,
                time_end: slot.time_end,
                is_holiday: slot.is_holiday,
                tickets_places_count: slot.tickets_places_count,
                price: slot.price,
                price_weekend: slot.price_weekend,
                total_price: slot.total_price,
                currency: slot.currency,
                clientHash: slot.client_hash,
                bookingCount: slot.booking_count,
                active: slot.active,
                block: slot.is_blocked,
                userBlock: slot.user_blocked,
                dateBlock: slot.date_blocked,
                reservation: slot.reservation,
                totalSeats: slot.total_seats,
                seats: slot.seats,
                statusName: slot.status_name,
                statusColor: slot.status_color,
              });
            });
          }
        }
      });
      // for (const key in allSchedule) {
      //   if (schedule[key]) {
      //     schedule[key].slots = [];
      //     allSchedule[key].forEach((slot) => {
      //       schedule[key].slots.push({
      //         time: slot.time,
      //         price: slot.price,
      //         currency: "₽",
      //         active: slot.active,
      //         reservation: slot.reservation,
      //       });
      //     });
      //   }
      //
      // }

      state.schedule = schedule;
      state.scheduleIsLoading = false;
    },
    setErrorMsg(state, errorMsg) {
      state.errorMsgSchedule = errorMsg;
    },
    setScheduleByDate(state, response) {
      state.currentSchedulePage = response.current_page;
      state.lastSchedulePage = response.last_page;

      const schedule = [];

      for (const key in response.data) {
        schedule.push({
          name: moment(key),
          weekDay: moment(key),
          id: Object.keys(response.data[key])[0],
          slots: [],
        });

        for (const jey in response.data[key]) {
          schedule[schedule.length - 1].slots = [];
          response.data[key][jey].forEach((slot) => {
            schedule[schedule.length - 1].slots.push({
              id: slot.id,
              time: slot.time,
              price:
                slot.day_id === 7 || slot.day_id === 6
                  ? slot.price_weekend
                  : slot.price,
              currency: "₽",
            });
          });
        }
      }

      state.schedule = schedule;
      state.scheduleIsLoading = false;
    },
    changeLoadingStatus(state) {
      state.scheduleIsLoading = !state.scheduleIsLoading;
    },
    clearSchedule(state) {
      state.schedule = {};
    },
  },
  actions: {
    async getSchedule({ commit, rootState, rootGetters }, date) {
      try {
        commit("changeLoadingStatus");
        const allServicesId = rootGetters["user/allServicesId"];
        const { data } = await AJAX_POST(`/api/schedule/get`, {
          ids: allServicesId,
          filter: "day",
          day: moment(date).format("YYYY-MM-DD"),
        });
        if (data.success === true) {
          commit("setErrorMsg", null);
        } else {
          commit("setErrorMsg", data.msg);

          commit("setSchedule", {
            allSchedule: data,
            servicesList: rootState.user.servicesList,
          });
        }
      } catch (err) {
        commit("changeLoadingStatus");
      }
    },
    getScheduleByDates({ commit }, payload) {
      commit("changeLoadingStatus");

      AJAX_GET(
        `/api/schedule/get?ids=[${payload.resourceId}]&toDates=1&page=${payload.page}`,
        { noCancel: true }
      )
        .then((response) => {
          commit("setScheduleByDate", response.data);
        })
        .catch((err) => {
          commit("changeLoadingStatus");
        });
    },
    async getScheduleCalendar({ commit }, { hash, withSlots, noClosed }) {
      try {
        const { data } = await AJAX_POST(`/api/schedule/getScheduleCalendar`, {
          hash,
          with_slots: withSlots,
          no_closed: noClosed,
        });
        commit("setField", {
          field: "scheduleCalendarDate",
          value: data,
        });
      } catch (err) {
        console.log(err);
      }
    },

    clearSchedule({ commit }) {
      commit("clearSchedule");
    },
  },
};
