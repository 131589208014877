<template>
  <section>
    <div class="notifications-variables">
      <h2 class="leading-28 font-semibold text-16 mb-14">
        Переменные, доступные для использования
      </h2>
      <el-row
        :gutter="40"
        class="notifications-variables__tables flex-wrap"
        type="flex"
      >
        <el-col :span="$mq.tablet ? 24 : 12" class="lg:mb-20">
          <el-table :data="variablesData.table1" :stripe="true">
            <el-table-column prop="command" label="Команда" width="180">
            </el-table-column>
            <el-table-column prop="value" width="259" label="Значение">
            </el-table-column>
          </el-table>
        </el-col>

        <el-col :span="$mq.tablet ? 24 : 12">
          <el-table :data="variablesData.table2" :stripe="true">
            <el-table-column prop="command" label="Команда" width="180">
            </el-table-column>
            <el-table-column prop="value" width="259" label="Значение">
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      variablesData: {
        table1: [
          {
            command: "{orderId}",
            value: "№ заказа",
          },
          {
            command: "{orderServiсe}",
            value: "Название услуги",
          },
          {
            command: "{orderDate}",
            value: "Забронированная дата",
          },
          {
            command: "{orderTime}",
            value: "Забронированное время",
          },
          {
            command: "{orderСomposition}",
            value: "Состав заказа",
          },
          {
            command: "{serviceAddress}",
            value: "Адрес услуги",
          },
          {
            command: "{servicePhone}",
            value: "Ваш контактный телефон",
          },
          {
            command: "{serviceEmail}",
            value: "Ваш контактный e-mail",
          },
          {
            command: "{serviceSource}",
            value: "Источник заказа",
          },
        ],
        table2: [
          {
            command: "{userName}",
            value: "Имя клиента",
          },
          {
            command: "{userSurname}",
            value: "Фамилия клиента",
          },
          {
            command: "{userPhone}",
            value: "Телефон клиента",
          },
          {
            command: "{userEmail}",
            value: "E-mail клиента",
          },
          {
            command: "{userAddress}",
            value: "Адрес клиента",
          },
          {
            command: "{userComment}",
            value: "Коментарий клиента",
          },
          {
            command: "{adminComment}",
            value: "Комментарий сотрудника",
          },
          {
            command: "<p>, <b>",
            value: "Абзац, жирный шрифт",
          },
          {
            command: "<br>",
            value: "Знак переноса строки",
          },
        ],
      },
    };
  },
};
</script>
