<template>
  <div>
    <h2>{{ `${titleLink}` }}</h2>
  </div>
</template>

<script>
export default {
  name: "AppLink",
  props: {
    titleLink: {
      type: String,
      default: "AppLink",
    },
    linkPay: {
      type: Object,
    },
  },
  data() {
    return {
      lin: null,
    };
  },
  computed: {
    isLink() {
      return this.lin;
    },
  },
  created() {
    this.isLinkpay();
    this.$emit("isLinkpay", this.isLink);
  },
  methods: {
    isLinkpay() {
      // const data = this.linkPay.payment.find((e) =>
      //   e.payment_info.payments.find((link) => !!link.payment_url)
      // );
      // if (data.payment_info) {
      //   this.lin = data.payment_info.payments.find((q) => q.payment_url);
      // }
      const data = this.linkPay.payment_data.find((e) => {
        if (e.payment_link) {
          this.lin = e.payment_link;
          return this.lin;
        }
        return this.lin;
      });
      // if (data.payment_link) {
      //   this.lin = data.payment_link;
      //   return this.lin;
      // }
    },
  },
};
</script>

<style lang="scss" scoped></style>
