<template>
  <div class="custom-block form">
    <div class="form__title">
      <div
        class="form__title-text"
        :style="{
          color: isMaket
            ? styleCustom.active_text_color || custom.active_text_color
            : '',
        }"
      >
        Бронирование услуги
      </div>
      <svg-icon
        icon-name="close"
        class="form__title-close"
        width="13"
        @click.native="close()"
      />
    </div>
    <div
      class="form__sub-title"
      :style="{
        color: isMaket
          ? styleCustom.active_text_color || custom.active_text_color
          : '',
      }"
    >
      Обзорная экскурсия «История и пространство Древнего Пскова»
    </div>
    <functional-calendar
      v-if="showCalendar"
      v-model="calendar"
      :configs.sync="calendarConfigs"
      class="mb-30"
    ></functional-calendar>

    <div class="form__row">
      <div class="form__row-column">
        <div class="row-info">
          <div
            class="row-info__title"
            :style="{
              color:
                isIndividualTheme || isMaket ? custom.inactive_text_color : '',
            }"
          >
            Дата
          </div>
          <div
            class="row-info__value"
            :style="{
              color:
                isIndividualTheme || isMaket ? custom.active_text_color : '',
            }"
          >
            04.02.2021
          </div>
        </div>
        <div class="row-info">
          <div
            class="row-info__title"
            :style="{
              color:
                isIndividualTheme || isMaket ? custom.inactive_text_color : '',
            }"
          >
            Время
          </div>
          <div
            class="row-info__value"
            :style="{
              color:
                isIndividualTheme || isMaket ? custom.active_text_color : '',
            }"
          >
            10:30
          </div>
        </div>
        <div class="row-info">
          <div
            class="row-info__title"
            :style="{
              color:
                isIndividualTheme || isMaket ? custom.inactive_text_color : '',
            }"
          >
            Стоимость
          </div>
          <div
            class="row-info__value"
            :style="{
              color:
                isIndividualTheme || isMaket ? custom.active_text_color : '',
            }"
          >
            1 600 ₽
          </div>
        </div>
        <div
          class="hl"
          :style="{
            background: isMaket
              ? styleCustom.background_cells_fields ||
                custom.background_cells_fields
              : '',
          }"
        ></div>
        <div class="input-number">
          <div class="input-number__info">
            <span
              class="input-number__info-title"
              :style="{
                color:
                  isIndividualTheme || isMaket ? custom.active_text_color : '',
              }"
              >Стандартный</span
            >
            <span
              class="input-number__info-subtitle"
              :style="{
                color:
                  isIndividualTheme || isMaket
                    ? custom.inactive_text_color
                    : '',
              }"
              >Неограниченно</span
            >
          </div>
          <el-input-number
            v-model="ticketStandart"
            class="input-number__input"
            :class="{
              'custom-style': isTypeTheme || isMaket,
            }"
            :style="{
              background:
                isTypeTheme || isMaket
                  ? styleCustom.background_cells_fields ||
                    custom.background_cells_fields
                  : '',
            }"
            :min="1"
          />
        </div>
        <div class="input-number">
          <div class="input-number__info">
            <span
              class="input-number__info-title"
              :style="{
                color:
                  isIndividualTheme || isMaket ? custom.active_text_color : '',
              }"
              >Расширенный</span
            >
            <span
              class="input-number__info-subtitle"
              :style="{
                color:
                  isIndividualTheme || isMaket
                    ? custom.inactive_text_color
                    : '',
              }"
              >240 шт.</span
            >
          </div>
          <el-input-number
            v-model="ticketExtended"
            class="input-number__input"
            :class="{
              'custom-style': isTypeTheme || isMaket,
            }"
            :style="{
              background:
                isTypeTheme || isMaket
                  ? styleCustom.background_cells_fields ||
                    custom.background_cells_fields
                  : '',
            }"
            :min="1"
          />
        </div>
        <div class="input-number">
          <div class="input-number__info">
            <span
              class="input-number__info-title"
              :style="{
                color:
                  isIndividualTheme || isMaket ? custom.active_text_color : '',
              }"
              >Полный</span
            >
            <span
              class="input-number__info-subtitle"
              :style="{
                color:
                  isIndividualTheme || isMaket
                    ? custom.inactive_text_color
                    : '',
              }"
              >10 шт.</span
            >
          </div>
          <el-input-number
            v-model="ticketFull"
            class="input-number__input"
            :class="{
              'custom-style': isTypeTheme || isMaket,
            }"
            :style="{
              background:
                isTypeTheme || isMaket
                  ? styleCustom.background_cells_fields ||
                    custom.background_cells_fields
                  : '',
            }"
            :min="1"
          />
        </div>
      </div>
      <div class="form__row-column">
        <el-input
          v-if="setCheckValue('fieldName')"
          placeholder="Имя*"
          :class="{
            'custom-style': isTypeTheme || isMaket,
          }"
          :style="{
            background:
              isTypeTheme || isMaket
                ? styleCustom.background_cells_fields ||
                  custom.background_cells_fields
                : '',
          }"
        ></el-input>
        <el-input
          v-if="setCheckValue('fieldFamaly')"
          placeholder="Фамилия"
          :class="{
            'custom-style': isTypeTheme || isMaket,
          }"
          :style="{
            background:
              isTypeTheme || isMaket
                ? styleCustom.background_cells_fields ||
                  custom.background_cells_fields
                : '',
          }"
        ></el-input>
        <el-input
          v-if="setCheckValue('fieldEmail')"
          placeholder="E-mail*"
          :class="{
            'custom-style': isTypeTheme || isMaket,
          }"
          :style="{
            background:
              isTypeTheme || isMaket
                ? styleCustom.background_cells_fields ||
                  custom.background_cells_fields
                : '',
          }"
        ></el-input>
        <el-input
          v-if="setCheckValue('fieldPhone')"
          placeholder="Номер телефона*"
          :class="{
            'custom-style': isTypeTheme || isMaket,
          }"
          :style="{
            background:
              isTypeTheme || isMaket
                ? styleCustom.background_cells_fields ||
                  custom.background_cells_fields
                : '',
          }"
        ></el-input>
        <el-input
          v-if="setCheckValue('fieldComment')"
          placeholder="Комментарий"
          :class="{
            'custom-style': isTypeTheme || isMaket,
          }"
          :style="{
            background:
              isTypeTheme || isMaket
                ? styleCustom.background_cells_fields ||
                  custom.background_cells_fields
                : '',
          }"
        ></el-input>
        <el-input
          v-if="setCheckValue('fieldPromo')"
          placeholder="Промо-код"
          :class="{
            'custom-style': isTypeTheme || isMaket,
          }"
          :style="{
            background:
              isTypeTheme || isMaket
                ? styleCustom.background_cells_fields ||
                  custom.background_cells_fields
                : '',
          }"
        ></el-input>
        <el-input
          v-if="setCheckValue('fieldСlient') && isUBRR"
          placeholder="Код клиента"
          :class="{
            'custom-style': isTypeTheme || isMaket,
          }"
          :style="{
            background:
              isTypeTheme || isMaket
                ? styleCustom.background_cells_fields ||
                  custom.background_cells_fields
                : '',
          }"
        ></el-input>
        <el-input
          v-if="setCheckValue('fieldUbrirTel') && isUBRR"
          placeholder="Последние 7 цифр телефона*"
          :class="{
            'custom-style': isTypeTheme || isMaket,
          }"
          :style="{
            background:
              isTypeTheme || isMaket
                ? styleCustom.background_cells_fields ||
                  custom.background_cells_fields
                : '',
          }"
        ></el-input>
        <div v-if="add_params">
          <div
            v-for="item in add_params"
            :key="item.param_id"
            class="mb-20"
            :class="{
              'custom-style': isTypeTheme || isMaket,
            }"
            :style="{
              background:
                isTypeTheme || isMaket
                  ? styleCustom.background_cells_fields ||
                    custom.background_cells_fields
                  : '',
            }"
          >
            <el-input
              v-model="item.param_value"
              :class="{
                'custom-style': isTypeTheme,
              }"
              :style="{
                background: isTypeTheme
                  ? styleCustom.background_cells_fields ||
                    custom.background_cells_fields
                  : '',
              }"
              :placeholder="item.param_name"
            ></el-input>
          </div>
        </div>
      </div>
    </div>
    <div class="hl"></div>
    <div class="form__row">
      <p
        class="form__row-column hint-title__medium-white"
        :style="{
          color:
            isTypeTheme || isMaket
              ? styleCustom.inactive_text_color || custom.inactive_text_color
              : '',
        }"
      >
        Нажимая на кнопку “Забронировать”, вы автоматически соглашаетесь с
        условиями пользовательского соглашения
      </p>
      <div class="form__row-column actions">
        <div
          class="actions__info"
          :style="{
            // background: isIndividualTheme
            //   ? custom.active_buttons_background
            //   : '',
            color: isIndividualTheme ? custom.button_text_color : '',
          }"
        >
          <p
            class="actions__info-title"
            :style="{
              color:
                isTypeTheme || isMaket
                  ? styleCustom.active_text_color || custom.active_text_color
                  : '',
            }"
          >
            К оплате
          </p>
          <p
            class="actions__info-content"
            :style="{
              color:
                isTypeTheme || isMaket
                  ? styleCustom.active_text_color || custom.active_text_color
                  : '',
            }"
          >
            1 600 ₽
          </p>
        </div>
        <el-button
          class="actions__button"
          type="primary"
          :style="{
            // background: isIndividualTheme
            //   ? custom.active_buttons_background
            //   : '',
            color: isIndividualTheme ? custom.button_text_color : '',
          }"
        >
          Забронировать
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { FunctionalCalendar } from "vue-functional-calendar";
import { mapState, mapGetters } from "vuex";

export default {
  name: "FormCenter",
  components: { FunctionalCalendar },
  props: {
    isMaket: Boolean,
    showCalendar: Boolean,
    isIndividualTheme: String,
    styleCustom: {
      type: Object,
    },
  },
  data() {
    return {
      selectTime: "11:00",
      add_params: [],
      date: new Date(),
      ticketStandart: 0,
      ticketExtended: 0,
      ticketFull: 0,
      calendar: {
        currentDate: new Date(),
        selectedDate: false,
        selectedDateTime: false,
        selectedHour: "00",
        selectedMinute: "00",
        selectedDatesItem: "",
        selectedDates: [],
        dateRange: { start: "", end: "" },
        multipleDateRange: [],
      },
      calendarConfigs: {
        dateFormat: "yyyy-mm-dd",
        disabledDates: ["beforeToday"],
        isMultiple: true,
        calendarsCount: 2,
        isMultipleDatePicker: true,
        dayNames: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
        monthNames: [
          "Январь",
          "Февраль",
          "Март",
          "Апрель",
          "Май",
          "Июнь",
          "Июль",
          "Август",
          "Сентябрь",
          "Октябрь",
          "Ноябрь",
          "Декабрь",
        ],
      },
    };
  },
  computed: {
    ...mapState("widget", ["widget", "widgetPublic"]),
    ...mapGetters("user", ["isUBRR"]),

    custom() {
      const designWidgetParams = this.widgetPublic.design ?? this.widget.design;
      return {
        ...designWidgetParams,
      };
    },
  },
  watch: {
    "widget.fieldForm": function () {
      return this.setCheckValue();
    },
    deep: true,
  },
  created() {
    this.listTime = [
      {
        value: "11:00",
        label: "11:00",
      },
    ];
    if (this.widgetPublic.add_data) {
      this.add_params = [...this.widgetPublic.add_data];
    }
  },

  methods: {
    close() {
      this.$emit("close");
    },
    setCheckValue(key) {
      return this.widget.fieldForm.includes(key);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.form {
  padding: 30px 30px 20px;
  margin-bottom: 20px;
  &__title {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    &-text {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: $--color-white;
      margin-bottom: 5px;
      flex-grow: 1;
    }
    &-close {
      flex-shrink: 0;
      margin-left: 12px;
      color: $--color-white-5;
      cursor: pointer;
    }
  }
  &__sub-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 40px;
    color: $--color-white;
  }
  &__row {
    display: flex;
    flex-direction: row;
    margin: 0 -20px;
    &-column {
      display: flex;
      flex-direction: column;
      width: 50%;
      padding: 0 20px;

      .hl {
        margin: 25px 0 25px;
      }
      .qwerty {
        flex-grow: 1;
        margin: 0;
      }
    }
  }
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  &__info {
    display: flex;
    flex-direction: column;
    &-title {
      color: $--color-white-5;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
    }
    &-content {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: white;
    }
  }
  &__button {
    margin-left: 20px;
  }
}

::v-deep {
  .el-input {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .vfc-calendar {
    border: 1px solid $--color-sm-white;
    &:nth-last-child(1) {
      border-radius: 0 3px 3px 0;
    }
    &:nth-last-child(2) {
      border-left: 1px solid rgba(255, 255, 255, 0.2);
      // border-right: 1px solid rgba(255, 255, 255, 0.2);
    }
    &:nth-last-child(3) {
      border-radius: 3px 0 0 3px;
    }
  }
}
@media (min-width: 320px) {
  .form__row {
    flex-wrap: wrap;
    &-column {
      width: 100%;
    }
  }
  .actions {
    margin-top: 10px;
    justify-content: space-between;
  }
}
.custom-style {
  ::v-deep .el-input__inner {
    color: var(--colorCustom, black);
  }
  ::v-deep .el-input__inner::-webkit-input-placeholder {
    color: var(--colorInactive, black);
  }
  ::v-deep .el-input__inner::placeholder {
    color: var(--colorInactive, black);
  }
  ::v-deep .el-input__inner:focus {
    background: var(--colorCustomBackground, black);
  }
}
.custom-style {
  ::v-deep .input-check__icon {
    background: var(--colorCustomBackground, black);
  }
}
.custom-style {
  ::v-deep .input-check__icon > svg {
    color: var(--colorCustom, black);
  }
}
.input-check.custom-style {
  height: 40px;
}
</style>
