<template>
  <el-form
    ref="baseInfo"
    hide-required-asterisk
    label-position="top"
    :model="{ ...company }"
    :rules="rules"
    show-message
    class="form"
  >
    <el-row :gutter="$mq.large ? 10 : 30" class="flex-wrap" type="flex">
      <el-col :span="$mq.large ? 24 : 6">
        <UploadPhoto
          is-logo
          :value="company.company_logo"
          text="Перетащите логотип в эту область или загрузите"
          @input="setDataCompany({ field: 'company_logo', value: $event })"
      /></el-col>
      <el-col :span="$mq.large ? 24 : 18">
        <el-form-item prop="company_name">
          <span slot="label">
            Название компании<span class="danger">*</span>
          </span>
          <el-input
            :value="company.company_name"
            placeholder="Напишите название компании"
            @input="setDataCompany({ field: 'company_name', value: $event })"
          ></el-input>
        </el-form-item>
        <el-form-item prop="kind_of_activity">
          <span slot="label"> Вид деятельности </span>
          <el-select
            :value="company.kind_of_activity"
            class="w-full custom"
            multiple
            filterable
            @input="
              setDataCompany({ field: 'kind_of_activity', value: $event })
            "
          >
            <el-option
              v-for="item in typesActivity"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
    </el-row>
    <el-form-item label="Краткое описание деятельности компании" class="mb-30">
      <el-input
        :value="company.short_description"
        class="input-limit"
        maxlength="100"
        show-word-limit
        placeholder="Введите краткое описание компании"
        @input="setDataCompany({ field: 'short_description', value: $event })"
      >
      </el-input>
    </el-form-item>
    <textarea-edit
      placeholder="Опишите область деятельности комании"
      title="Полное описание деятельности компании"
      :value="company.detail_description"
      @input="setDataCompany({ field: 'detail_description', value: $event })"
    />
    <h4 class="title">Обложка</h4>
    <UploadPhoto
      is-logo
      :value="company.cover"
      text="Перетащите обложку в эту область или загрузите"
      sub-text="Минимальный рекомендованный размер: 1100x250 пикселей"
      class="cover"
      @input="setDataCompany({ field: 'cover', value: $event })"
    />
    <h4 class="title">Фотографии</h4>
    <UploadPhoto
      :value="company.company_photos"
      text="Перетащите ваши фотографии в эту область или загрузите их"
      sub-text="Минимальный рекомендованный размер: 500x500 пикселей"
      @input="setDataCompany({ field: 'company_photos', value: $event })"
    />
    <h4 class="title">Видео</h4>
    <el-row :gutter="$mq.large ? 10 : 30" class="flex-wrap" type="flex">
      <el-col :span="$mq.large ? 24 : 8">
        <UploadPhoto
          is-logo
          :value="company.video_preview"
          text="Перетащите превью в эту область или загрузите"
          sub-text="Минимальный рекомендованный размер: 500x500 пикселей"
          @input="
            setDataCompany({
              field: 'video_preview',
              value: $event,
            })
          "
        />
      </el-col>
      <el-col :span="$mq.large ? 24 : 16">
        <el-row :gutter="$mq.large ? 10 : 30" class="flex-wrap" type="flex">
          <el-col :span="24">
            <el-form-item label="Название видео">
              <el-input
                :value="company.video_name"
                placeholder="Введите название"
                @input="
                  setDataCompany({
                    field: 'video_name',
                    value: $event,
                  })
                "
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="24">
            <el-form-item label="Ссылка на видео">
              <el-input
                :value="company.video_link"
                placeholder="Вставьте сюда ссылку"
                @input="
                  setDataCompany({
                    field: 'video_link',
                    value: $event,
                  })
                "
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <div class="hl"></div>
    <el-button
      type="primary"
      class="bg-gradient-b-yellow border-0"
      @click="submit()"
    >
      Сохранить
    </el-button>
  </el-form>
</template>

<script>
import TextareaEdit from "@/components/TextareaEdit";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import UploadPhoto from "@/components/UploadPhoto";

export default {
  name: "InfoData",
  components: {
    TextareaEdit,
    UploadPhoto,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState("company", ["company", "typesActivity"]),
    ...mapGetters("company", ["isNewCompany"]),
  },
  created() {
    this.rules = {
      company_name: [
        {
          required: true,
          message: "заполните поле",
        },
        {
          min: 3,
          message: "Укажите название (минимум 3 символа)",
          trigger: ["blur"],
        },
      ],
      kind_of_activity: [
        { required: false, message: "заполните поле", trigger: ["blur"] },
      ],
    };
    this.namesTranslate = {
      company_name: "название компании",
      kind_of_activity: "вид деятельности",
    };
  },
  methods: {
    ...mapActions("company", ["addCompany", "editInfoCompany"]),
    ...mapMutations("company", ["setDataCompany"]),
    ...mapMutations(["setNotif"]),
    async save() {
      if (this.isNewCompany) {
        await this.addCompany();
      } else {
        await this.editInfoCompany("info");
      }
    },
    async submit() {
      this.$refs.baseInfo.validate((valid, object) => {
        if (valid) {
          this.save();
        } else {
          const str = Object.values(object)
            .map((value) => this.namesTranslate[value[0].field])
            .join(", ");
          this.setNotif({
            title: `Заполните следующие поля: ${str}.`,
            type: "warning",
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

::v-deep .el-select__input {
  color: #ffffff;
}
::v-deep .input-apend-select .el-input-group__append .el-select {
  margin: 0;
}

::v-deep .el-input .el-input__count .el-input__count-inner {
  background: inherit !important;
}

::v-deep .input-apend-select {
  .el-input-group__append {
    width: 100px;
  }

  > .el-input__inner {
    text-align: right;
    padding-right: 0;
  }
}

::v-deep .el-textarea textarea {
  background-color: rgba(255, 255, 255, 0.1);
}

::v-deep .gallery {
  margin-bottom: 30px;
}

::v-deep .el-tag.el-tag--info .el-tag__close:hover {
  color: #ffffff;
  background-color: red !important;
}
.title {
  margin-bottom: 30px;
  font-size: 22px;
  line-height: 44px;
  font-weight: 600;
  margin-bottom: 15px;
}

.input-limit ::v-deep input {
  padding: 0 80px 0 15px;
}
.cover ::v-deep .wrapper-upload {
  height: 120px !important;
}

@screen lg {
  .title {
    margin-bottom: 15px;
    font-size: 18px;
    line-height: 28px;
  }
}
</style>
