<template>
  <el-drawer
    v-if="show"
    :close-on-click-modal="false"
    :before-close="handleClose"
    :show-close="false"
    :visible.sync="show"
    direction="rtl"
    size="340px"
  >
    <div class="relative overflow-auto min-h-full px-30">
      <div slot="title" class="pt-20 pb-23">
        <div class="flex items-center justify-between">
          <div
            class="font-semibold text-18 leading-30 text-white-normal sidebar__title"
          >
            {{ title }}
          </div>
          <div class="cursor-pointer" @click="$emit('close')">
            <svg-icon
              icon-name="close"
              width="12"
              height="12"
              class-name="text-white-normal sidebar__close-icon"
            ></svg-icon>
          </div>
        </div>
      </div>
      <div class="sidebar__content">
        <slot />
      </div>
      <!-- @click="$emit('save'), (loading = true)"
 -->
      <el-button
        v-if="title === 'Комментарии'"
        :loading="loading"
        :disabled="disable"
        type="primary"
        class="border-0 w-full mb-30 save-button absolute bottom-0"
        style="max-width: 280px"
        @click="addNewComment"
        >{{ buttonText }}</el-button
      >
    </div>
  </el-drawer>
</template>

<script>
export default {
  props: {
    title: String,
    buttonText: {
      type: String,
      default: "Сохранить изменения",
    },
    disable: {
      type: Boolean,
    },
    show: Boolean,
  },
  data() {
    return {
      loading: false,
    };
  },
  mounted() {},
  methods: {
    handleClose(done) {
      this.$emit("close");
      done();
    },
    addNewComment() {
      this.loading = true;
      this.$emit("addNewComment", { send: true });
      this.loading = false;
    },
  },
};
</script>

<style scoped lang="scss">
.sidebar {
  &__content {
    height: calc(100vh - 143px);
    & > div {
      height: 100%;
    }
  }
}
.poPup {
  background: olive;
}
</style>
