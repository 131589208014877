var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bk-full"},[_c('p',{staticClass:"title-widget",style:({
      color: _vm.isThemeWidget ? _vm.customStyle.active_text_color : '',
    })},[_vm._v(" Выберите услугу ")]),_c('el-radio-group',{on:{"input":function($event){return _vm.emitData($event)}},model:{value:(_vm.radio),callback:function ($$v) {_vm.radio=$$v},expression:"radio"}},_vm._l((_vm.settings.data),function(service,i){return _c('el-radio',{key:i,class:{ 'custom-style': _vm.isThemeWidget },attrs:{"label":service}},[_c('div',{staticClass:"radio__title",style:({
          color: _vm.isThemeWidget ? _vm.customStyle.inactive_text_color : '',
        })},[_vm._v(" "+_vm._s(service.name)+" ")]),_c('div',{staticClass:"radio__sub-title"},[_c('span',{style:({
            color: _vm.isThemeWidget ? _vm.customStyle.active_text_color : '',
          })},[_vm._v(" "+_vm._s(_vm.isHoliday && service.holidays_price ? +service.holidays_price + _vm.selectCell.weekend_price_additional : +service.base_price + _vm.selectCell.price_additional)+" "+_vm._s(_vm.selectCell.currency)+" ")]),_vm._v(" • "),_c('span',{style:({
            color: _vm.isThemeWidget ? _vm.customStyle.active_text_color : '',
          })},[_vm._v(_vm._s(service.duration)+" "+_vm._s(_vm.getUnitTime(service)))])])])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }