<template>
  <div
    class="tariff-card"
    :class="{ tariffCheck: checked }"
    @click="$emit('click')"
  >
    <div class="flex items-center justify-center mb-30">
      <svg-icon
        icon-name="pay-success"
        width="35"
        height="35"
        :class="checked ? 'tariff-card__svg' : ''"
      ></svg-icon>
    </div>
    <div class="flex flex-col items-center">
      <h3 class="tariff-card__title pb-10">{{ tariff.name }}</h3>
      <span class="tariff-card__subtitle pb-10">{{ tariff.description }}</span>
      <span class="tariff-card__price pb-10">{{ tariff.price }} </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "TariffCard",
  props: {
    tariff: {
      type: Object,
    },
    checked: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.tariff-card {
  cursor: pointer;
  min-width: 225px;
  padding: 40px 20px 40px 20px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  border-radius: 3px;

  &__title {
    color: #ffffff;
    font-size: 22px;
    line-height: 36px;
    text-align: center;
  }
  &__subtitle {
    text-align: center;
    color: rgba(255, 255, 255, 0.5);
  }
  &__price {
    color: white;
    text-align: center;
  }
}
.tariffCheck {
  border: 2px solid rgba(255, 190, 61, 1);
}
.tariff-card__svg {
  color: rgba(255, 190, 61, 1);
}
</style>
