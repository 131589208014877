<template>
  <el-form
    ref="formAddReserve"
    v-loading="reserveLoading"
    hide-required-asterisk
    :model="formAddReserve"
    label-position="top"
    :rules="formAddReserveRules"
    @keyup.native.enter="reserve()"
  >
    <div class="pt-20 px-30 pb-30 text-white-normal lg:px-15">
      <div class="text-white-500 mb-5">Бронирование услуги</div>
      <div class="leading-30 text-18 font-semibold mb-15">
        {{ data.resource.name }}
      </div>
      <div class="flex items-center mb-30 xs:flex-col xs:items-start">
        <div
          class="flex flex-col items-start mr-20 xs:flex-row xs:items-center"
        >
          <span class="text-12 mb-2 text-white-500 xs:mr-10"
            >Дата<span class="hidden xs:inline">:</span></span
          >
          <span class="text-16 leading-28 font-semibold">{{
            reserveDate
          }}</span>
        </div>
        <div class="bg-white-150 w-1 h-30 mr-20 xs:hidden"></div>
        <div
          class="flex flex-col items-start mr-20 xs:flex-row xs:items-center"
        >
          <span class="text-12 mb-2 text-white-500 xs:mr-10"
            >Время<span class="hidden xs:inline">:</span></span
          >
          <span class="text-16 leading-28 font-semibold">{{
            data.slot.slot.time
          }}</span>
        </div>
        <div
          v-if="data.resource.type === 1 && data.resource.sub_type !== 3"
          class="bg-white-150 w-1 h-30 mr-20 xs:hidden"
        ></div>
        <div
          v-if="data.resource.type === 1 && data.resource.sub_type !== 3"
          class="flex flex-col items-start mr-20 xs:flex-row xs:items-center"
        >
          <span class="text-12 mb-2 text-white-500 xs:mr-10"
            >Стоимость<span class="hidden xs:inline">:</span></span
          >
          <span class="text-16 leading-28 font-semibold"
            >{{ data.slot.slot.price }} {{ data.slot.slot.currency }}</span
          >
        </div>
      </div>
      <div class="border-t border-white-100 -mt-10 pt-22 mb-20">
        <div
          v-if="data.resource.type === 2 && data.resource.sub_type === 1"
          class="flex flex-col border-b border-white-100 pb-22"
        >
          <div
            v-for="(tariff, index) in tariffs"
            :key="index"
            class="grid reserve-tickets mb-5"
          >
            <el-radio
              v-model="formAddReserve.tariff"
              class="mr-0"
              :label="tariff.key"
              @change="onChangeTarrifType"
              >{{ tariff.type }}</el-radio
            >
            <div class="reserve-tickets__price">{{ tariff.price }} ₽</div>
            <el-form-item class="mb-0">
              <el-input-number
                v-model="tariff.count"
                size="small"
                :min="1"
                :max="+tariff.amount"
                type="number"
                class="w-full"
              ></el-input-number>
            </el-form-item>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-2 col-gap-30 row-gap-20 lg:grid-cols-1">
        <el-form-item class="mb-0" prop="name">
          <el-input v-model="formAddReserve.name" placeholder="Имя*">
          </el-input>
        </el-form-item>
        <el-form-item class="mb-0" prop="surname">
          <el-input v-model="formAddReserve.surname" placeholder="Фамилия">
          </el-input>
        </el-form-item>
        <el-form-item class="mb-0" prop="phone">
          <el-input
            v-model="formAddReserve.phone"
            placeholder="Номер телефона*"
          >
          </el-input>
        </el-form-item>
        <el-form-item class="mb-0" prop="email">
          <el-input v-model="formAddReserve.email" placeholder="E-mail*">
          </el-input>
        </el-form-item>
        <el-form-item
          v-if="
            data.resource.type === 1 &&
            (data.resource.sub_type === 1 || data.resource.sub_type === 2)
          "
          class="mb-0"
          prop="count"
        >
          <el-input-number
            v-model="formAddReserve.count"
            :min="seats.seatsMin"
            :max="seats.seatsMax"
            class="w-full"
          ></el-input-number>
        </el-form-item>
        <el-form-item
          v-if="data.resource.type === 1 && data.resource.sub_type === 3"
          class="mb-0"
        >
          <el-select
            v-model="formAddReserve.custom"
            placeholder="Тариф"
            class="w-full"
          >
            <el-option
              v-for="item in customs"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <div class="flex">
            <el-input
              v-model="formAddReserve.promo"
              placeholder="Промокод"
              class="mr-20"
            ></el-input>
            <el-button class="px-13" @click="submitPromo">Применить</el-button>
          </div>
        </el-form-item>
      </div>
      <el-input
        v-model="formAddReserve.comment"
        type="textarea"
        placeholder="Комментарий"
        :autosize="{ minRows: 3 }"
        resize="none"
        class="bg-white-100 rounded-sm mb-30"
      ></el-input>
      <div class="text-16 leading-28 mb-12">Дополнительные услуги</div>
      <div class="flex flex-col">
        <div class="reserve-dop">
          <el-checkbox
            v-model="formAddReserve.dops"
            label="Услуги аниматора"
            class="mb-8"
          ></el-checkbox>
          <div>500 ₽</div>
        </div>
        <div class="reserve-dop">
          <el-checkbox
            v-model="formAddReserve.dops"
            label="Фотограф на 2 часа"
          ></el-checkbox>
          <div>3 000 ₽</div>
        </div>
      </div>
    </div>
    <div
      class="border-t border-white-100 px-30 py-25 flex items-center text-white-normal lg:flex-col lg:items-start lg:px-15 lg:py-20"
    >
      <div class="text-12 leading-18 text-white-500 mr-25 lg:mb-10 pr-70">
        Нажимая на кнопку “Забронировать”, вы автоматически соглашаетесь с
        условиями пользовательского соглашения
      </div>
      <div class="flex flex-col items-start mr-30 flex-shrink-0 lg:mb-10">
        <span class="text-12 leading-16 mb-2">К оплате</span>
        <span class="text-16 leading-28 font-semibold text-yellow-100"
          >{{ price }} {{ data.slot.slot.currency }}</span
        >
      </div>
      <el-button
        type="primary"
        class="bg-gradient-b-yellow border-0"
        @click="reserve"
        >Забронировать</el-button
      >
    </div>
  </el-form>
</template>

<script>
import moment from "moment";
import { AJAX_POST } from "@/units/ajax";
import { errorMessage } from "@/units/main";

export default {
  name: "FormReserve",
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      formAddReserve: {
        name: "",
        surname: "",
        phone: "",
        email: "",
        count: "",
        promo: "",
        comment: "",
        dops: [1],
        tariff: 1,
        custom: 1,
      },
      promoPrice: {
        price: "",
        price_weekend: "",
      },
      formAddReserveRules: {
        name: [
          {
            required: true,
            min: 1,
            message: "Введите имя",
            trigger: ["blur", "change"],
          },
        ],
        surname: [
          {
            required: false,
            min: 1,
            message: "Введите фамилию",
            trigger: ["blur", "change"],
          },
        ],
        phone: [
          {
            required: true,
            min: 1,
            message: "Введите телефон",
            trigger: ["blur", "change"],
          },
        ],
        email: [
          {
            type: "email",
            required: true,
            message: "Введите правильный E-mail",
            trigger: ["blur", "change"],
          },
        ],
        count: [
          {
            required: true,
            min: 1,
            type: "number",
            message: "Введите кол-во",
            trigger: ["blur", "change"],
          },
        ],
      },
      customs: [],
      tariffs: [],
      reserveLoading: false,
    };
  },
  computed: {
    reserveDate() {
      return moment(this.data.slot.item.name).format("DD.MM.YYYY");
    },

    // eslint-disable-next-line vue/return-in-computed-property
    price() {
      if (this.data.resource.type === 1) {
        // eslint-disable-next-line default-case
        switch (this.data.resource.sub_type) {
          case 1:
          case 2:
            if (
              this.formAddReserve.count >
                this.data.resource.type_settings.seats_max &&
              this.data.resource.type_settings.additional_seats
            ) {
              return (
                this.data.slot.slot.price * this.formAddReserve.count +
                this.data.resource.type_settings.additional_seats_price *
                  (this.formAddReserve.count -
                    this.data.resource.type_settings.seats_max)
              );
            }
            return this.data.slot.slot.price * this.formAddReserve.count;

          case 3:
            // eslint-disable-next-line no-case-declarations
            const target = this.customs.find(
              (tariff) => tariff.key === this.formAddReserve.custom
            );
            return target.amount * target.price;
          case 4:
            return this.data.slot.slot.price;
        }
      } else if (this.data.resource.type === 2) {
        const target = this.tariffs.find(
          (tariff) => tariff.key === this.formAddReserve.tariff
        );
        return target.count * target.price;
      }
    },
    seats() {
      const result = {
        seatsMax: Infinity,
        seatsMin: 1,
      };

      if (this.data.resource.type === 1) {
        // eslint-disable-next-line default-case
        switch (this.data.resource.sub_type) {
          case 1:
          case 2:
            result.seatsMin = this.data.resource.type_settings.seats_min;
            result.seatsMax = this.data.resource.type_settings.additional_seats
              ? this.data.resource.type_settings.additional_seats +
                this.data.resource.type_settings.seats_max
              : this.data.resource.type_settings.seats_max;
            break;
        }
      }

      return result;
    },
  },
  created() {
    // eslint-disable-next-line no-prototype-builtins
    if (this.data.resource.type_settings.hasOwnProperty("tickets")) {
      const tickets = JSON.parse(
        JSON.stringify(this.data.resource.type_settings.tickets)
      );

      tickets.forEach((ticket) => {
        this.tariffs.push({
          ...ticket,
          count: 1,
        });
      });
    } else if (this.data.resource.type_settings.seats) {
      const customs = JSON.parse(
        JSON.stringify(this.data.resource.type_settings.seats)
      );
      customs.forEach((tariff) => {
        this.customs.push({
          label: `${tariff.amount} человек (${tariff.price} рублей)`,
          value: tariff.key,
          ...tariff,
        });
      });
    }

    // this.$set(this, 'tariffs', this.data.resource.type_settings.tickets);
    // this.tariffs.push(...this.data.resource.type_settings.tickets);
    // this.tariffs.forEach(tariff => {
    //     tariff.count = 1;
    //     tariff.totalPrice = tariff.price * tariff.count;
    // });

    // this.$set(this.tariffs, 'count', 1);
    // this.setTariffs();
  },
  methods: {
    // changeTariffPrice(tariff) {
    //     tariff.totalPrice = tariff.count * tariff.price;
    // },
    setTariffs() {},
    submitPromo() {
      AJAX_POST("/api/reservation/promocode", {
        schedule_id: this.data.slot.slot.id,
        code: this.formAddReserve.promo,
      }).then((response) => {
        // eslint-disable-next-line no-prototype-builtins
        if (response.data.hasOwnProperty("success") && response.data.success) {
          this.$notify({
            title: response.data.msg,
            type: "error",
            showClose: false,
            duration: 3000,
          });
        } else {
          this.$notify({
            title: "Промокод применен",
            type: "success",
            showClose: false,
            duration: 3000,
          });

          this.promoPrice = { ...response.data };
        }
      });
    },
    reserve() {
      this.$refs.formAddReserve.validate((valid) => {
        if (valid) {
          const params = {
            date: moment(this.data.slot.slot.date).format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            schedule_id: this.data.slot.item.ids,
            // resource_id: this.data.slot.item.id,

            first_name: this.formAddReserve.name,
            last_name: this.formAddReserve.surname,
            phone: this.formAddReserve.phone,
            email: this.formAddReserve.email,
            comment: this.formAddReserve.comment,
          };

          if (this.data.resource.type === 1) {
            if (this.data.resource.sub_type === 3) {
              params.count = this.customs.find(
                (custom) => custom.key === this.formAddReserve.custom
              ).amount;
              params.seat_key = this.formAddReserve.custom;
            } else {
              params.count = this.formAddReserve.count;
            }
          } else if (this.data.resource.type === 2) {
            params.tickets = [
              {
                key: this.formAddReserve.tariff,
              },
            ];
            params.tickets[0].count = this.tariffs.find(
              (tariff) => tariff.key === this.formAddReserve.tariff
            ).count;
          }

          this.reserveLoading = true;

          // AJAX_POST("/api/reservation/create", params)
          AJAX_POST("v1/booking/create", params)
            .then((response) => {
              this.reserveLoading = false;
              this.$emit("onReserve");
              if (response.data.success) {
                this.$notify({
                  title: "Бронь успешно создана",
                  type: "success",
                  showClose: false,
                  duration: 3000,
                });
              } else {
                this.$notify({
                  title: errorMessage,
                  type: "error",
                  showClose: false,
                  duration: 3000,
                });
              }
            })
            .catch((e) => {
              this.$notify({
                title: errorMessage,
                type: "error",
                showClose: false,
                duration: 3000,
              });
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
.reserve-dop {
  max-width: 290px;
  display: flex;

  .el-checkbox,
  .el-radio {
    flex-grow: 1;
    padding-right: 10px;
  }
}

.reserve-tickets {
  max-width: 90%;
  grid-template-columns: 250px 1fr 1fr;
  grid-gap: 10px;
  align-items: center;

  &__price {
    justify-self: end;
    padding-right: 20px;
  }
}
</style>
