<template>
  <div class="group">
    <p class="hint-title__medium-white">
      На один и тот же сеанс доступна запись нескольких клиентов. Он остается
      доступным до тех пор, пока есть свободные места. Например, для продажи
      билетов на мероприятия, для групповой записи на экскурсии, занятия йогой
      т.п.
    </p>
    <h4 class="title">Цена и места</h4>
    <div class="group-radio">
      <el-radio
        v-model="activeRadio"
        label="group_same_seat_limit_for_all_sessions"
      >
        Одинаковый лимит по количеству мест для всех сеансов
      </el-radio>
      <p class="hint-title__medium-white mb-10">
        Задается общее количество мест для всех сеансов или для каждого отдельно
        типа билета.
      </p>
      <div v-if="isLimitForAllSession" class="group-radio__funct">
        <!-- <p class="hint-title__medium-white">
          Задается общее количество мест для всех сеансов или для каждого
          отдельно типа билета.

          
        </p> -->
        <el-row :gutter="$mq.large ? 10 : 30" class="flex-wrap" type="flex">
          <el-col :span="$mq.large ? 24 : 12">
            <InputSelect
              v-model.number="data0.duration"
              prop="durationService"
              :label="groupLimit.headers[0].title"
              :placeholder="groupLimit.headers[0].placeholder"
              :list-value="listDuration"
              :select-value="data0.time_unit"
              @setValue="data0.time_unit = $event"
            ></InputSelect>
          </el-col>
          <el-col :span="$mq.large ? 24 : 12">
            <p class="hint-title">Лимит по количеству мест</p>
            <Tabs v-model="data0.seat_limit" :list-link="typesTickets" />
          </el-col>
          <el-col
            v-if="data0.seat_limit === 'general_limits'"
            :span="$mq.large ? 24 : 12"
          >
            <el-form-item prop="basePrice">
              <div slot="label" class="flex">
                {{ groupLimit.headers[1].title }}
                <span v-if="groupLimit.headers[1].valid" class="danger">*</span>
              </div>
              <el-input
                v-model.number="data0.group_general_limits.tickets"
                :placeholder="groupLimit.headers[0].placeholder"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <ListField3
          v-if="data0.seat_limit === 'general_limits'"
          v-model="data0.group_general_limits.group"
          class="ListField3"
          title-button="Добавить тип билета"
          :headers="groupLimit.forGeneral"
        ></ListField3>
        <ListField4
          v-else
          v-model="data0.group_ticket_type_limit"
          title-button="Добавить тип билета"
          :headers="groupLimit.forTicket"
        ></ListField4>
      </div>
      <el-radio
        v-model="activeRadio"
        label="group_the_seat_limit_is_set_for_each_session"
      >
        Лимит мест (без лимита) задается на каждый сеанс
      </el-radio>
      <p class="hint-title__medium-white mb-10">
        Задается лимит мест на каждый отдельно взятый сеанс.
      </p>
      <div v-if="isLimitEverySession" class="group-radio__funct">
        <!-- <p class="hint-title__medium-white">
          Задается лимит мест на каждый отдельно взятый сеанс.

       
        </p> -->
        <el-row :gutter="$mq.large ? 10 : 30" class="flex-wrap" type="flex">
          <el-col :span="$mq.large ? 24 : 12">
            <InputSelect
              v-model.number="data1.duration"
              prop="durationService"
              :label="groupLimit.headers[0].title"
              :placeholder="groupLimit.headers[0].placeholder"
              :list-value="listDuration"
              :select-value="data1.time_unit"
              @setValue="data1.time_unit = $event"
            />
          </el-col>
          <el-col :span="$mq.large ? 24 : 12">
            <el-form-item prop="basePrice">
              <div slot="label" class="flex">
                Минимальное кол-во билетов на сеанс
                <span class="danger">*</span>
                <el-popover
                  class="ml-8"
                  placement="top"
                  width="200"
                  trigger="hover"
                  content="Необходимо указать минимальное кол-во билетов, которое будет проставляться для
сеанса на следующем шаге при генерации расписания. Если лимита на кол-во нет,
укажите 0."
                >
                  <Tooltip slot="reference" />
                </el-popover>
              </div>
              <el-input
                v-model.number="data1.tickets"
                placeholder="кол-во билетов"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <ListField3
          v-model="data1.group"
          class="ListField3"
          title-button="Добавить тип билета"
          :headers="groupLimit.forGeneral"
        ></ListField3>
      </div>
    </div>
  </div>
</template>

<script>
import ListField3 from "@/components/ListField3v2.vue";
import ListField4 from "@/components/ListField4v2.vue";
import { listDuration, typesTickets } from "@/helpers/services.js";
import { mapActions, mapState, mapGetters } from "vuex";
import { groupLimit } from "@/helpers/typeSchedule.js";

export default {
  name: "Group",
  components: {
    ListField4,
    ListField3,
  },
  data() {
    return {
      qwerty: 1,
      activeRadio: "group_same_seat_limit_for_all_sessions",
      data0: {
        duration: 60,
        time_unit: "minutes",
        seat_limit: "general_limits",
        group_general_limits: {
          tickets: null,
          group: [
            {
              id: null,
              name: "",
              base_price: 0,
              holidays_price: null,
            },
          ],
        },
        group_ticket_type_limit: [
          {
            id: null,
            name: "",
            tickets: null,
            base_price: 0,
            holidays_price: null,
          },
        ],
      },
      data1: {
        duration: 60,
        time_unit: "minutes",
        tickets: 1,
        group: [
          {
            id: null,
            name: "",
            base_price: 0,
            holidays_price: null,
          },
        ],
      },
    };
  },
  computed: {
    ...mapState("service", ["settings"]),
    ...mapGetters("service", ["isHaveSettings"]),
    isLimitForAllSession() {
      return this.activeRadio === "group_same_seat_limit_for_all_sessions";
    },
    isLimitEverySession() {
      return (
        this.activeRadio === "group_the_seat_limit_is_set_for_each_session"
      );
    },
    activeRadioIndex() {
      return this.radioList.findIndex((item) => item === this.activeRadio);
    },
  },
  created() {
    this.typesTickets = typesTickets;
    this.listDuration = listDuration;
    this.groupLimit = groupLimit;
    this.radioList = [
      "group_same_seat_limit_for_all_sessions",
      "group_the_seat_limit_is_set_for_each_session",
    ];
    this.setRadio();
  },
  methods: {
    ...mapActions("service", ["saveSettings"]),
    setRadio() {
      if (
        this.isHaveSettings &&
        this.radioList.includes(this.settings.booking_type_sub)
      ) {
        this.activeRadio = this.settings.booking_type_sub;
        const index = this.radioList.findIndex(
          (item) => item === this.settings.booking_type_sub
        );
        this[`data${index}`] = this.settings.data;
      } else {
        this.activeRadio = "group_same_seat_limit_for_all_sessions";
      }
    },
    save() {
      this.saveSettings({
        nameRadio: this.activeRadio,
        info: { ...this[`data${this.activeRadioIndex}`] },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.group {
  .hint-title__medium-white {
    padding: 0 0 20px 0;
  }
  .title {
    font-size: 22px;
    line-height: 44px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  &-radio {
    display: flex;
    flex-direction: column;

    &__funct {
      display: flex;
      flex-direction: column;
      margin: -20px 0 20px 25px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

::v-deep .el-radio {
  margin-bottom: 20px;
}

::v-deep .el-input__suffix {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: $--color-white-5;
  }
}

::v-deep .el-input-group__prepend {
  padding-right: 10px;
}

::v-deep .tabs .el-radio {
  margin-bottom: 0;
}

.ListField3 {
  margin-bottom: 0;
}

@screen lg {
  .group {
    &-radio__funct {
      margin: 0;
    }
    .hint-title__medium-white {
      padding: 0 0 20px 0;
    }
    .title {
      margin-bottom: 30px;
      font-size: 18px;
      line-height: 28px;
    }
  }
}
</style>
