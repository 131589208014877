<template>
  <div>
    <span class="return__text">{{
      `или выберите количество участников : `
    }}</span>
    <div class="input-number my-10">
      <div class="input-number__info">
        <span class="input-number__info-title">участников:</span>
      </div>
      <el-input-number v-model="itemCount" class="input-number__input" />
    </div>
  </div>
</template>

<script>
export default {
  name: "IndividNotNumbSeat",
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      itemCount: null,
    };
  },
  created() {
    this.itemCount = this.item.individual.persons;
  },
};
</script>

<style lang="scss" scoped>
.return {
  &__text {
    color: #fff;
  }
}
</style>
