var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-form',{ref:"ruleForm",staticClass:"custom-block form",style:({
    background: _vm.isTypeTheme
      ? _vm.styleCustom.block_background || _vm.custom.block_background
      : '',
    color: _vm.isTypeTheme
      ? _vm.styleCustom.active_text_color || _vm.custom.active_text_color
      : '',
  }),attrs:{"rules":_vm.rules,"model":{ name: _vm.name, surname: _vm.surname, tel: _vm.tel, email: _vm.email }}},[(_vm.loading)?_c('div',{staticClass:"mask_load"}):_vm._e(),_c('div',{staticClass:"form__wrapper-header"},[_c('div',{staticClass:"form__title",style:({
        color: _vm.isTypeTheme
          ? _vm.styleCustom.active_text_color || _vm.custom.active_text_color
          : '',
      })},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(!_vm.isHiddenClose)?_c('div',{staticClass:"form__close",on:{"click":function($event){return _vm.$emit('close')}}},[_c('svg-icon',{style:({
          color: _vm.isTypeTheme
            ? _vm.styleCustom.active_text_color || _vm.custom.active_text_color
            : '',
        }),attrs:{"icon-name":"close","width":"14","height":"14"}})],1):_vm._e()]),(_vm.subTitle)?_c('div',{staticClass:"form__sub-title",style:({
      color: _vm.isTypeTheme
        ? _vm.styleCustom.inactive_text_color || _vm.custom.inactive_text_color
        : '',
    })},[_vm._v(" "+_vm._s(_vm.subTitle)+" ")]):_vm._e(),_c('div',{staticClass:"hl",style:({
      background: _vm.isTypeTheme
        ? _vm.styleCustom.background_cells_fields ||
          _vm.custom.background_cells_fields
        : '',
    })}),_vm._t("calendar"),_c('div',{staticClass:"form__row flex-row"},[_c('div',{staticClass:"form__row-column flex-col"},[_vm._t("default")],2),_c('div',{staticClass:"form__row-column flex-col"},[(
          !!_vm.selectCell.BookingType
            ? _vm.selectCell.BookingType.booking_type_sub ===
              'service_tree_from_settings'
            : false
        )?_c('div',{staticClass:"mb-3"},[_c('div',{staticClass:"row-info xs:text-12"},[_c('div',{staticClass:"row-info__title",style:({
              color: _vm.isTypeTheme ? _vm.custom.inactive_text_color : '',
            })},[_vm._v(" Дата ")]),_c('div',{staticClass:"row-info__value",style:({
              color: _vm.isTypeTheme ? _vm.custom.active_text_color : '',
            })},[_vm._v(" "+_vm._s(_vm.isMaket ? "04.02.2021" : _vm.formatedDate)+" ")])]),_c('div',{staticClass:"row-info xs:text-12"},[_c('div',{staticClass:"row-info__title",style:({
              color: _vm.isTypeTheme ? _vm.custom.inactive_text_color : '',
            })},[_vm._v(" Время ")]),_c('div',{staticClass:"row-info__value",style:({
              color: _vm.isTypeTheme ? _vm.custom.active_text_color : '',
            })},[_vm._v(" "+_vm._s(_vm.isMaket ? "10:30" : _vm.selectCell.time)+" ")])]),(_vm.selectCell.address)?_c('div',{staticClass:"row-info xs:text-12"},[_c('div',{staticClass:"row-info__title",style:({
              color: _vm.isTypeTheme ? _vm.custom.inactive_text_color : '',
            })},[_vm._v(" Адрес ")]),_c('div',{staticClass:"row-info__value",style:({
              color: _vm.isTypeTheme ? _vm.custom.active_text_color : '',
            })},[_vm._v(" "+_vm._s(_vm.selectCell.address)+" ")])]):_vm._e()]):_vm._e(),_c('el-form-item',{class:{
          'custom-style': _vm.isTypeTheme,
        },style:({
          background: _vm.isTypeTheme
            ? _vm.styleCustom.background_cells_fields ||
              _vm.custom.background_cells_fields
            : '',
        }),attrs:{"prop":"name"}},[_c('el-input',{attrs:{"placeholder":"Имя*","maxlength":"12"},on:{"input":function($event){return _vm.patternValue($event)}},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1),(_vm.listFieldShow.includes('fieldFamaly'))?_c('el-form-item',{class:{
          'custom-style': _vm.isTypeTheme,
        },style:({
          background: _vm.isTypeTheme
            ? _vm.styleCustom.background_cells_fields ||
              _vm.custom.background_cells_fields
            : '',
        })},[_c('el-input',{attrs:{"placeholder":"Фамилия"},model:{value:(_vm.surname),callback:function ($$v) {_vm.surname=$$v},expression:"surname"}})],1):_vm._e(),(_vm.listFieldShow.includes('fieldEmail'))?_c('el-form-item',{class:{
          'custom-style': _vm.isTypeTheme,
        },style:({
          background: _vm.isTypeTheme
            ? _vm.styleCustom.background_cells_fields ||
              _vm.custom.background_cells_fields
            : '',
        }),attrs:{"prop":"email"}},[_c('el-input',{attrs:{"placeholder":"E-mail*"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1):_vm._e(),(_vm.listFieldShow.includes('fieldPhone'))?_c('el-form-item',{class:{
          'custom-style': _vm.isTypeTheme,
        },style:({
          background: _vm.isTypeTheme
            ? _vm.styleCustom.background_cells_fields ||
              _vm.custom.background_cells_fields
            : '',
        }),attrs:{"prop":"tel"}},[_c('el-input',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.valueMask),expression:"valueMask"}],key:_vm.valueMask,attrs:{"placeholder":"Номер телефона*"},model:{value:(_vm.tel),callback:function ($$v) {_vm.tel=$$v},expression:"tel"}})],1):_vm._e(),(_vm.listFieldShow.includes('fieldComment'))?_c('el-form-item',{class:{
          'custom-style': _vm.isTypeTheme,
        },style:({
          background: _vm.isTypeTheme
            ? _vm.styleCustom.background_cells_fields ||
              _vm.custom.background_cells_fields
            : '',
        })},[_c('el-input',{class:{
            'custom-style': _vm.isTypeTheme,
          },style:({
            background: _vm.isTypeTheme
              ? _vm.styleCustom.background_cells_fields ||
                _vm.custom.background_cells_fields
              : '',
          }),attrs:{"placeholder":"Комментарий"},model:{value:(_vm.comments),callback:function ($$v) {_vm.comments=$$v},expression:"comments"}})],1):_vm._e(),(_vm.listFieldShow.includes('fieldСlient'))?_c('el-form-item',{class:{
          'custom-style': _vm.isTypeTheme,
        },style:({
          background: _vm.isTypeTheme
            ? _vm.styleCustom.background_cells_fields ||
              _vm.custom.background_cells_fields
            : '',
        })},[_c('el-input',{attrs:{"placeholder":"Код клиента"},model:{value:(_vm.add_params[1].param_value),callback:function ($$v) {_vm.$set(_vm.add_params[1], "param_value", $$v)},expression:"add_params[1].param_value"}})],1):_vm._e(),(_vm.listFieldShow.includes('fieldUbrirTel'))?_c('el-form-item',{class:{
          'custom-style': _vm.isTypeTheme,
        },style:({
          background: _vm.isTypeTheme
            ? _vm.styleCustom.background_cells_fields ||
              _vm.custom.background_cells_fields
            : '',
        }),attrs:{"prop":"add_params"}},[_c('el-input',{directives:[{name:"mask",rawName:"v-mask",value:('+7 (***) ###-##-##'),expression:"'+7 (***) ###-##-##'"}],attrs:{"placeholder":"Последние 7 цифр телефона*"},model:{value:(_vm.add_params[0].param_value),callback:function ($$v) {_vm.$set(_vm.add_params[0], "param_value", $$v)},expression:"add_params[0].param_value"}})],1):_vm._e(),(_vm.listFieldShow.includes('fieldPromo'))?_c('InputCheck',{class:{
          'custom-style': _vm.isTypeTheme,
        },style:({
          background: _vm.isTypeTheme
            ? _vm.styleCustom.background_cells_fields ||
              _vm.custom.background_cells_fields
            : '',
        }),attrs:{"placeholder":"Промо-код"},on:{"check":function($event){return _vm.checkPromoCode({
            id: _vm.selectCell.id,
            date: _vm.selectCell.date,
            time: _vm.selectCell.time,
            code: _vm.promocode,
          })}},model:{value:(_vm.promocode),callback:function ($$v) {_vm.promocode=$$v},expression:"promocode"}}):_vm._e(),(_vm.isHavePromocode)?_c('el-alert',{attrs:{"title":"Промокод применен!","type":"success","show-icon":"","closable":false}}):_vm._e(),(_vm.isNotPromocode)?_c('el-alert',{attrs:{"title":_vm.promocodeInfo.msg || "Промокод не найден!","type":"error","show-icon":"","closable":false}}):_vm._e(),(_vm.selectService)?_c('div',[(_vm.selectService.comment)?_c('el-alert',{staticClass:"customService w-full text-left",attrs:{"title":_vm.selectService.comment,"center":"","show-icon":"","closable":false}}):_vm._e()],1):_vm._e()],1)]),_c('div',{staticClass:"hl",style:({
      // REMOVE widgetPublic.typeTheme === 'light'
      background: _vm.isTypeTheme
        ? _vm.styleCustom.background_cells_fields ||
          _vm.custom.background_cells_fields
        : '',
    })}),_c('div',{staticClass:"form__row xs:flex-col flex-row"},[_c('p',{staticClass:"form__row-column hint-title__medium-white",style:({
        color: _vm.isTypeTheme
          ? _vm.styleCustom.inactive_text_color || _vm.custom.inactive_text_color
          : '',
      })},[_c('span',[_vm._v(" Нажимая на кнопку “Забронировать”, вы "),_c('br'),_vm._v("автоматически соглашаетесь с условиями "),_c('br'),_c('a',{staticClass:"linkUserRigth",style:({
            color: _vm.isTypeTheme
              ? _vm.styleCustom.inactive_text_color || _vm.custom.inactive_text_color
              : '',
          }),attrs:{"target":"_blank","href":_vm.widgetPublic.user_agreement}},[_c('span',{staticClass:"underline"},[_vm._v("пользовательского соглашения ")])]),_vm._v("и"),_c('a',{staticClass:"linkUserRigth",style:({
            color: _vm.isTypeTheme
              ? _vm.styleCustom.inactive_text_color || _vm.custom.inactive_text_color
              : '',
          }),attrs:{"target":"_blank","href":_vm.widgetPublic.privacy_policy}},[_c('span',{staticClass:"underline"},[_c('br'),_vm._v(" политикой конфиденциальности.")])])])]),_c('div',{staticClass:"form__row-column actions justify-end xs:mt-3"},[(
          Math.ceil(_vm.isHavePromocode ? _vm.totalPriceDiscount : _vm.totalPrice) > 0
        )?_c('div',{staticClass:"actions__info"},[_c('p',{staticClass:"actions__info-title",style:({
            color: _vm.isTypeTheme
              ? _vm.styleCustom.inactive_text_color || _vm.custom.inactive_text_color
              : '',
          })},[_vm._v(" К оплате ")]),_c('p',{staticClass:"actions__info-content",style:({
            color: _vm.isTypeTheme
              ? _vm.styleCustom.active_text_color || _vm.custom.active_text_color
              : '',
          })},[_vm._v(" "+_vm._s(Math.ceil( _vm.isHavePromocode || _vm.isHaveDiscountPrice ? _vm.totalPriceDiscount : _vm.totalPrice ))+" "+_vm._s(_vm.selectCell.currency)+" ")])]):_vm._e(),(_vm.isHavePromocode || _vm.isHaveDiscountPrice)?_c('div',{staticClass:"actions__info-oldPrice",style:({
          color: _vm.isTypeTheme
            ? _vm.styleCustom.active_text_color || _vm.custom.active_text_color
            : '',
        })},[_vm._v(" "+_vm._s(_vm.isHaveDiscountPrice ? _vm.totalPrice : _vm.selectCell.price)+" "+_vm._s(_vm.selectCell.currency)+" ")]):_vm._e(),_c('el-button',{staticClass:"actions__button",style:({
          background: _vm.isTypeTheme
            ? _vm.styleCustom.active_buttons_background ||
              _vm.custom.active_buttons_background
            : '',
          color: _vm.isTypeTheme
            ? _vm.styleCustom.button_text_color || _vm.custom.button_text_color
            : '',
        }),attrs:{"type":"primary","loading":_vm.loading,"disabled":!_vm.disabled},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v(" "+_vm._s("Забронировать")+" ")])],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }