var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bk-full"},[_c('p',{staticClass:"title-widget",style:({
      color: _vm.isThemeWidget ? _vm.customStyle.active_text_color : '',
    })},[_vm._v(" Билеты ")]),_vm._l((_vm.listTicket),function(ticket,i){return _c('div',{key:i,staticClass:"input-number"},[_c('div',{staticClass:"input-number__info"},[_c('span',{staticClass:"input-number__info-title",style:({
          color: _vm.isThemeWidget ? _vm.customStyle.active_text_color : '',
        })},[_vm._v(_vm._s(ticket.name))]),_c('span',{staticClass:"input-number__info-subtitle"},[_c('span',{style:({
            color: _vm.isThemeWidget ? _vm.customStyle.active_text_color : '',
          })},[_vm._v(" "+_vm._s(_vm.isHoliday && ticket.holidays_price ? ticket.holidays_price + _vm.selectCell.weekend_price_additional : ticket.base_price + _vm.selectCell.price_additional)+" "+_vm._s(_vm.selectCell.currency)+" ")]),_vm._v(" • "),_c('span',{style:({
            color: _vm.isThemeWidget ? _vm.customStyle.inactive_text_color : '',
          })},[_vm._v(_vm._s(ticket.tickets_remained)+" шт.")])])]),_c('el-input-number',{staticClass:"input-number__input",class:{ 'custom-style': _vm.isThemeWidget },style:({
        background: _vm.isThemeWidget ? _vm.customStyle.background_cells_fields : '',
      }),attrs:{"min":0,"max":Number(ticket.tickets_remained) + 1,"controls":true},on:{"input":function($event){return _vm.emitData()}},model:{value:(ticket.select_ticket),callback:function ($$v) {_vm.$set(ticket, "select_ticket", $$v)},expression:"ticket.select_ticket"}})],1)}),(!_vm.isSel)?_c('el-alert',{staticClass:"w-full",attrs:{"title":"Скорректируйте данные","type":"warning","closable":false,"center":""}}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }