<template>
  <div class="">
    <div class="flex my-30">
      <div class="w-1/2">
        <span class="small_text">Дата и время</span>
        <span class="small_text">Цена </span>
        <span class="small_text">Участников </span>
        <!-- <span class="small_text">Продолжит. </span> -->
      </div>
      <div class="w-1/2 mb-3">
        <span class="underline">{{ rent.date_time }}</span>

        <div class="small_text-outcome">
          <span>{{ rent.individual.price }} {{ item.currency }} </span>
        </div>
        <span class="small_text-outcome"> </span>

        <span class="small_text-outcome"> </span>
        <span class="small_text-outcome">{{ rent.individual.persons }} </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderNotSeat",
  props: {
    item: {
      type: Object,
    },
    rent: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.rotate {
  transform: rotate(180deg);
}
.drop-wrapp {
  display: flex;
  cursor: pointer;
}
.small_text {
  &-total {
    font-size: 12px;
    color: rgba(255, 190, 61, 1);
  }
  &-drop {
    margin-right: 8px;
    font-size: 12px;
  }
  &-outcome {
    font-weight: 500;
    opacity: 1;
    font-size: 12px;
    line-height: 20px;
    display: block;
  }
}
.utf {
  &-form {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    margin: 10px 0px;
    padding: 8px 15px;
  }
  &-icon {
    color: rgba(255, 255, 255, 0.5);
    position: relative;
    top: 3px;
  }
}
</style>
