<template>
  <div>
    <div class="bk-fullpll">
      <div class="">
        <p class="hint">Тип оплаты<span class="danger">*</span></p>
        <el-select
          v-model="typePay"
          value-key="value"
          filterable
          placeholder="Выберите тип оплаты"
          class="w-full"
          :disabled="!!param.status"
        >
          <el-option
            v-for="(item, idx) in listsPaysType"
            :key="idx"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>

      <div v-if="isTransaction" class="mb-8">
        <div class="bk-info">
          <p class="bk-info__title">Создано</p>
          <p class="px-5">
            {{ getDates(isTransaction.created_at) }}
            {{ getTime(isTransaction.created_at) }}
          </p>
          <p class="bk-info__utc">UTC: +3:00</p>
        </div>
        <div class="bk-info">
          <p class="bk-info__title">ID оплаты</p>

          <p class="px-5">
            <router-link
              class="dashed"
              target="_blank"
              :to="{ path: '/payments', query: { text: isTransaction.hash } }"
              >#{{ isTransaction.hash }}
            </router-link>
          </p>
        </div>
        <div class="bk-info">
          <p class="bk-info__title">К оплате</p>
          <p class="px-5">{{ isTransaction.full_amount }}</p>
          <span>{{ param.item.currency }}</span>
        </div>
      </div>
    </div>
    <div v-if="isTransaction">
      <div class="bk-fullpll'">
        <div class="bk-info flex-col">
          <p
            v-if="
              isPaymentType.type === 'full_payment' &&
              isPaymentType.status_name !== 'Оплачен'
            "
            class="bk-info-pay__title w-full mb-8 mt-15"
          >
            Ссылка на оплату
          </p>
          <div
            v-if="
              isPaymentType.type === 'full_payment' &&
              isPaymentType.status_name !== 'Оплачен'
            "
            class="flex justify-between mb-18"
          >
            <el-input
              v-model="isLink"
              placeholder=""
              class="w-4/5"
              :disabled="isPaymentType.status_name === 'Оплачен'"
            ></el-input>

            <div class="row-link_pay cursor-pointer payment-link__wrap">
              <ButtonIcon
                icon-name="copy"
                width="16"
                :disabled="isPaymentType.status_name === 'Оплачен'"
                @click="copy(isLink)"
              />
            </div>
          </div>
          <div
            v-if="dataPayment"
            class="payment__block flex justify-center mb-10"
            :style="convertColor(dataPayment.status_color_rgba)"
          >
            <span> {{ dataPayment.status_name }} </span>
          </div>
          <div
            v-if="
              dataPayment.status_name === 'Оплачен' ||
              dataPayment.status_name === 'Частично оплачен'
            "
            class=""
          >
            <div class="bk-info">
              <p class="bk-info__title">Дата оплаты</p>
              <p class="px-5">
                {{ getDates(isTransaction.payment_date) }}
                {{ getTime(isTransaction.payment_date) }}
              </p>
              <p class="bk-info__utc">UTC: +3:00</p>
            </div>
            <!-- <div class="bk-info">
              <p class="bk-info__title">ID транзакции</p>
              <p class="px-5">
                {{ isTransaction }}
              </p>
            </div> -->
            <div class="bk-info">
              <p class="bk-info__title">Оплачено</p>
              <p class="px-5">
                {{ dataPayment.paid_amount }} {{ param.item.currency }}
              </p>
            </div>
          </div>
          <div v-if="isRefound" class="">
            <div class="payment__block flex justify-center mb-10"></div>
            <div
              v-for="(refund, idx) in isRefound.refunds"
              :key="`refund-${idx}`"
              class="mb-8"
            >
              <div class="bk-info">
                <p class="bk-info__title">Дата возврата</p>
                <p class="px-5">
                  {{ getDates(refund.date) }} {{ getTime(refund.date) }}
                </p>
                <p class="bk-info__utc">UTC: +3:00</p>
              </div>
              <div class="bk-info">
                <p class="bk-info__title">Возвращено</p>
                <p class="px-5">
                  {{ refund.amount }} {{ param.item.currency }}
                </p>
              </div>
            </div>
          </div>
          <!-- <div
            v-if="
              dataPayment.status_name === 'Оплачен' ||
              dataPayment.status_name === 'Частично оплачен'
            "
            class="my-10"
          >
            <el-button class="w-full to-orders" @click="refundPayment"
              >Оформить возврат
            </el-button>
          </div> -->
          <div class="">
            <div class="bk-info justify-between mb-5">
              <p class="bk-info__title">Оплата на месте</p>
              <el-input v-model="prePayVal" placeholder="" class="w-1/2">
              </el-input>
              <el-button @click="setPayPlaceVal">
                <svg-icon icon-name="check" width="14" height="14"></svg-icon>
              </el-button>
            </div>
            <div class="bk-info">
              <p class="bk-info__title">Дата опл. на месте</p>
              <p v-if="isTransaction.paid_on_place_date" class="px-5">
                {{ getDates(isTransaction.paid_on_place_date) }}
                {{ getTime(isTransaction.paid_on_place_date) }}
              </p>
              <!-- <p class="bk-info__utc">UTC: +3:00</p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapMutations } from "vuex";

export default {
  name: "PaymentsBook",
  props: {
    listsPaysType: {
      type: Array,
    },
    slotDetails: {
      type: Object,
    },
    status: {
      type: String,
    },
    param: {
      type: Object,
    },
    payS: {
      type: Object,
    },
    isPaymentType: {
      type: Object,
    },
  },
  data() {
    return {
      typePay: null,
      prePayVal: null,
    };
  },
  computed: {
    isTransaction() {
      return this.slotDetails.booking?.transactions.find((e) => e);
    },
    isLink() {
      return this.dataPayment?.payment_link;
    },
    dataPayment() {
      return this.slotDetails?.payment_data.find((e) => e);
    },
    isRefound() {
      return this.slotDetails.payment_data?.find((ref) => ref);
    },
    isValPay() {
      return this.isTransaction?.paid_on_place;
    },
  },
  watch: {
    isValPay: {
      handler() {
        this.prePayVal = this.isValPay;
      },
    },
  },
  created() {
    this.typePay = this.status
      ? this.slotDetails.payment_data[0].type
      : this.listsPaysType[0].name;
    this.prePayVal = this.status && this.isTransaction ? this.isValPay : 0;
  },
  methods: {
    ...mapActions("paymentHistory", [
      "getRefundData",
      "getRefund",
      "getPaymentOnPlace",
    ]),
    ...mapActions("widget", ["getSlotDetails"]),
    ...mapMutations(["setNotif"]),

    getDates(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    getTime(time) {
      return moment(time).format("HH:mm");
    },
    convertColor(color) {
      const [r, g, b] = color.split(",");
      return {
        border: `1px solid rgba(${r},${g},${b},${0.5})`,
        background: `rgba(${r},${g},${b},${0.2})`,
      };
    },
    copy(copyLink) {
      navigator.clipboard.writeText(copyLink);
      this.setNotif({ title: `Данные скопированны` });
    },

    async refundPayment() {
      console.log(this.isTransaction.paid_amount);
      const param = this.slotDetails.payment_data.find((e) => e.hash);
      const amount =
        this.isTransaction.paid_amount >= 1
          ? this.isTransaction.paid_amount
          : this.isTransaction.full_amount;
      await this.getRefund({
        hash: param.hash, // this.param.item.payment[0].hash,
        amount: Number(amount),
      });
      this.hiddenRefund = !this.hiddenRefund;
    },
    async setPayPlaceVal() {
      this.$emit("isLoad", true);
      const param = this.slotDetails.payment_data.find((e) => e.hash);
      await this.getPaymentOnPlace({
        hash: param.hash,
        value: Number(this.prePayVal),
      });
      await this.getSlotDetails({
        id: this.param.hash,
        date: this.slotDetails.booking.date,
        time: this.slotDetails.booking.time, // this.scheduleTime.time,
        bookingHash: this.slotDetails.booking.hash,
      });
      this.$emit("isLoad", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.payment {
  &__block {
    padding: 12px 16px;
    //background: rgba(52, 152, 219, 0.2);
    //border: 1px solid rgba(52, 152, 219, 0.5);
    align-items: center;
  }
}
.inBlur {
  color: rgba(255, 255, 255, 0.5) !important;
}
.dashed {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.5);
  &:hover {
    border-bottom: 1px solid rgba(255, 255, 255, 1);
  }
}
</style>
