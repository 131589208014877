<template>
  <div class="top">
    <el-switch
      v-model="useSwitch"
      :active-text="title ? title : 'Использовать промо-коды'"
      class="top__switch md:w-full"
      @change="changeUseSwitch"
    >
    </el-switch>
    <div v-if="exportLinks" class="top__actions md:w-full md:mt-10">
      <div class="top__actions md:mr-10 xs:mr-0">
        <a :href="`${exportLink[0].link}`">
          <el-button size="large" class="md:w-full">Экспорт в Excel </el-button>
        </a>
      </div>
      <!-- 'disable-links': index === 1 -->
      <!-- <a
        v-for="(item, index) in exportLinks"
        :key="item.link"
        :href="item.link"
        :class="{ 'ml-15': index > 0 }"
        target="_blank"
        class="search__export"
      >
        <el-button size="medium">
          <div class="flex items-center">
            <span>{{ item.name }}</span>

            <span class="icon_rounded icon_rounded_question ml-10">
              <el-popover
                placement="top"
                width="200"
                trigger="hover"
                :content="`${item.title}`"
              >
                <Tooltip slot="reference" />
              </el-popover>
            </span>
          </div>
        </el-button>
      </a> -->
      <!-- </div> -->

      <el-button type="primary" class="text-black" @click="$emit('create')">
        <span class="flex items-center">
          <svg-icon
            icon-name="plus"
            class-name="mr-10 text-black"
            width="10"
            height="10"
          ></svg-icon>
          <span>{{ createButtonText }}</span>
        </span>
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    exportLink: {
      type: [String, Array],
    },
    createButtonText: String,
    title: String,
    switchPosition: Object,
    activeTab: String,
  },

  data() {
    return {
      useSwitch: false,
      exportLinks: [
        // {
        //   link: "/api/promocode/export/excel",
        //   code: "promocode",
        //   name: "Экспорт промокодов в Excel",
        //   title: "Экспорт промокодов в Excel. Максимальное кол-во строк: 1000.",
        // },
        // {
        //   link: "/api/discount/export/excel",
        //   code: "discount",
        //   name: "Экспорт скидок в Excel",
        //   title:
        //     "Экспорт промокодов в скидок. Максимальное кол-во строк: 1000.",
        // },
      ],
    };
  },

  created() {
    this.useSwitch =
      this.activeTab === "promocode"
        ? !this.switchPosition.promoActive
        : !this.switchPosition.discountActive;
  },
  methods: {
    changeUseSwitch() {
      this.$emit("change", !this.useSwitch);
    },
    setExportLinks() {
      this.exportLinks = this.exportLinks.filter(
        (link) => link.code === this.activeTab
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@media (max-width: 480px) {
  .top {
    flex-direction: column;
    &__actions {
      flex-direction: column;
      button {
        margin-top: 10px;
      }
    }
  }
}
@media (max-width: 680px) {
  .top {
    flex-wrap: wrap;
  }
}
.top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 45px 0 40px;
  &__actions {
    display: flex;
    align-items: center;
    @media (max-width: 480px) {
      align-items: normal;
    }
    button {
      height: 40px;
      margin-left: 20px;
      @media (max-width: 680px) {
        margin-left: 0px;
      }
    }
  }
}
</style>
