import { setField, setFieldObj } from "@/helpers/store";
import { AJAX_GET, AJAX_DELETE, AJAX_POST, AJAX_PATCH } from "@/units/ajax";
import { el } from "date-fns/locale";

export default {
  namespaced: true,
  state: {
    personals: null,
    personalsError: null,
  },
  getters: {},
  mutations: {
    setField,
    setFieldObj,
  },
  actions: {
    async getPersonals({ state, commit }) {
      try {
        const { data } = await AJAX_POST("/api/personal/get", {
          noCancel: true,
        });
        if (!data.success) {
          commit("setField", {
            field: "personalsError",
            value: data.msg,
          });
          commit("setNotif", {
            title: data.msg,
            type: "error",
          });
        }

        // eslint-disable-next-line no-shadow
        const items =
          data &&
          data.map((item) => ({
            edit: false,
            opened: false,
            ...item,
          }));

        commit("setField", {
          field: "personals",
          value: items,
        });
        commit("setField", {
          field: "personalsError",
          value: data.msg,
        });
      } catch (err) {
        console.log(err);
        // commit(
        //   "setNotif",
        //   {
        //     title: 'data',
        //     type: "error",
        //   },
        //   { root: true }
        // );
      }
    },
    async changeResources({ commit }, item) {
      try {
        const { data } = await AJAX_POST("/api/personal/edit/resources", {
          id: item.id,
          resources: item.resources,
        });
        if (data.success) {
          commit(
            "setNotif",
            {
              title: "Данные обновлены",
            },
            { root: true }
          );
        } else {
          commit(
            "setNotif",
            {
              title: data.msg || "Ошибка при отправке данных. Повторите еще",
              type: "error",
            },
            { root: true }
          );
        }
      } catch (err) {
        commit(
          "setNotif",
          {
            title: "Ошибка при отправке данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },
    delete({ commit }, id) {
      try {
        const { data } = AJAX_POST("/api/personal/delete", { id });
        //   if (data.success) {
        commit(
          "setNotif",
          {
            title: "Сотрудник удалён",
          },
          { root: true }
        );
        // } else {
        //   commit(
        //     "setNotif",
        //     {
        //       title: data.msg || "Ошибка при удалении. Повторите еще",
        //       type: "error",
        //     },
        //     { root: true }
        //   );
        // }
      } catch (err) {
        commit(
          "setNotif",
          {
            title: "Ошибка при удалении. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async create({ commit }, item) {
      try {
        const payload = { ...item };
        const resources = { ...payload.resources };
        payload.resources = Object.values(resources).join(",");
        const { data } = await AJAX_POST("/api/personal/add", item);
        if (data.success) {
          commit(
            "setNotif",
            {
              title: "Сотрудник создан",
            },
            { root: true }
          );
          return true;
        }
        commit(
          "setNotif",
          {
            title: data.msg,
            type: "error",
          },
          { root: true }
        );
      } catch (err) {
        commit(
          "setNotif",
          {
            title: data.msg,
            type: "error",
          },
          { root: true }
        );
      }
    },
  },
};
