<template>
  <Block title="Соглашение">
    <el-radio-group
      v-model="items.agreementKind"
      class="flex items-start lg:flex-col lg:mb-20"
      @change="save"
    >
      <el-radio :label="1" class="w-full standart-agreement lg:mb-15"
        >Использовать стандартное
        <router-link
          target="_blank"
          to="/useragreementw"
          class="text-yellow-100 underline"
          >пользовательское соглашение
        </router-link>
      </el-radio>
      <el-radio :label="2" class="w-full own-agreement"
        >Использовать ссылку на собственное пользовательское соглашение

        <el-popover
          :tabindex="40"
          placement="top"
          title="Title"
          class="inline-flex"
          trigger="hover"
          content="this is content, this is content, this is content"
        >
        </el-popover>
      </el-radio>
      <el-form
        v-if="items.agreementKind === 2"
        ref="formAgreement"
        :model="items"
        :hide-required-asterisk="true"
        :rules="formAgreementRules"
        class="section_content__link"
      >
        <el-form-item prop="agreementLink" class="mb-0">
          <el-input
            v-model="items.agreementLink"
            placeholder="Добавьте сюда ссылку на соглашение"
            @blur="setOwnAgreement"
          ></el-input>
        </el-form-item>
      </el-form>
    </el-radio-group>
  </Block>
</template>

<script>
import Block from "./Block";
import { mapState, mapMutations } from "vuex";
import { AJAX_POST } from "../../units/ajax";

export default {
  components: {
    Block,
  },
  data() {
    return {
      items: {
        timeout: "",
        agreementKind: 1,
        agreementLink: "",
      },
      formAgreementRules: {
        agreementLink: [
          {
            required: true,
            message: "Введите ссылку",
            trigger: ["blur", "change"],
          },
        ],
      },
      link: "",
    };
  },
  computed: {
    ...mapState("user", ["user"]),
  },
  mounted() {
    this.setUserAgreement();
  },
  methods: {
    ...mapMutations(["setNotif"]),
    setOwnAgreement() {
      clearTimeout(this.items.timeout);

      this.items.timeout = setTimeout(() => {
        this.$refs.formAgreement.validate((valid) => {
          if (valid) {
            AJAX_POST("/api/me/change/agreement", {
              agreement: this.items.agreementLink,
            }).then(() => {
              this.setNotif({
                title: `Данные сохранены`,
              });
            });
          }
        });
      }, 1000);
    },
    saveDefaultAgreement() {
      AJAX_POST("/api/me/change/agreement", {}).then((response) => {
        this.setNotif({
          title: `Данные сохранены`,
        });
      });
    },
    save(value) {
      if (value === 1) this.saveDefaultAgreement();
      else this.setOwnAgreement();
    },
    setUserAgreement() {
      if (this.user.additional.agreement) {
        this.items.agreementLink = this.user.additional.agreement;
        this.items.agreementKind = 2;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.standart-agreement {
  margin-bottom: 10px;
}
.section_content {
  & > div {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__link {
    margin-top: 20px;
    width: 100%;
  }
}
</style>
