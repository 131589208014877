<template>
  <div class="list-fields">
    <div
      v-for="(item, index) in listField"
      :key="index"
      class="list-fields__row"
    >
      <div class="list-fields__item w-name">
        <el-form-item prop="basePrice" class="w-full">
          <div slot="label" class="flex">
            <div>Название</div>
          </div>
          <el-input
            v-model="item.name"
            placeholder="Введите название"
            @input="emitData()"
          >
          </el-input>
        </el-form-item>
      </div>
      <div class="list-fields__item w-others">
        <el-form-item prop="basePrice" class="w-full">
          <div slot="label" class="flex">
            <div>Стоимость</div>
            <el-popover
              class="ml-8"
              placement="top"
              width="200"
              trigger="hover"
              content="Оставьте поле пустым, если услуга оказывается бесплатно."
            >
              <Tooltip slot="reference" />
            </el-popover>
          </div>
          <el-input
            v-model.number="item.price"
            placeholder="Введите цену"
            @input="emitData()"
          >
            <div slot="suffix">₽</div>
          </el-input>
        </el-form-item>
      </div>
      <div class="list-fields__item w-others">
        <el-form-item prop="basePrice" class="w-full">
          <div slot="label" class="flex">
            <div>Количество</div>
            <el-popover
              class="ml-8"
              placement="top"
              width="200"
              trigger="hover"
              content="Оставьте поле пустым, если услуга оказывается в единственном числе."
            >
              <Tooltip slot="reference" />
            </el-popover>
          </div>
          <el-input
            v-model.number="item.amount"
            placeholder="Введите количество"
            @input="emitData()"
          >
            <div slot="suffix">шт</div>
          </el-input>
        </el-form-item>
      </div>
      <div v-if="listField.length > 1" class="list-fields__actions lg:w-full">
        <ButtonIcon
          icon-name="close"
          width="16"
          :class="$mq.tablet ? 'w-max' : 'w-auto'"
          :text="$mq.tablet ? 'Удалить опцию' : ''"
          @click="deleteField(index)"
        />
      </div>
    </div>
    <el-button class="list-fields__add-button" @click="addField()">
      {{ titleButton }}
    </el-button>
  </div>
</template>

<script>
export default {
  name: "AddOptions",
  props: {
    title: {
      type: String,
      default: "",
    },
    titleButton: {
      type: String,
      default: "Добавить новое поле",
    },
    placeholder: {
      type: String,
      default: "Введите значение",
    },
    value: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      listField: [
        {
          id: null,
          name: "",
          price: null,
          amount: null,
        },
      ],
    };
  },
  watch: {
    value() {
      this.listField = [...this.value];
    },
  },
  created() {
    if (this.value) this.listField = [...this.value];
  },
  methods: {
    deleteField(index) {
      this.listField.splice(index, 1);
      this.emitData();
    },
    addField() {
      this.listField.push({
        id: null,
        name: "",
        price: null,
        amount: null,
      });
    },
    emitData() {
      this.$emit("input", [...this.listField]);
    },
  },
};
</script>

<style lang="scss" scoped>
.list-fields {
  margin-bottom: 10px;
}

.w-name {
  width: 50%;
  // width: 100%;
}
.w-others {
  width: 25%;
}
@media (max-width: 1024px) {
  .w-name {
    width: 100%;
  }
  .w-others {
    width: 100%;
  }
}
</style>
