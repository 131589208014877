var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"flex my-30"},[_c('div',{staticClass:"w-1/2"},[_c('span',{staticClass:"small_text"},[_vm._v("Дата и время")]),(_vm.slotInfo.length < 2)?_c('span',{staticClass:"small_text"},[_vm._v(_vm._s(("Цена за " + (_vm.unitCahnge(_vm.rent.rent.unit).text))))]):_vm._e(),(_vm.rent.rent.discount)?_c('span',{staticClass:"small_text"},[_vm._v(_vm._s(("Скидка за " + (_vm.unitCahnge(_vm.rent.rent.unit).text) + " ")))]):_vm._e(),_c('span',{staticClass:"small_text"},[_vm._v(_vm._s(("Кол-во " + (_vm.unitCahnge(_vm.rent.rent.unit).textPlurals)))+" ")]),(
            _vm.item.booking_type.booking_type_sub ===
            'rent_depends_on_time_and_place'
          )?_c('span',{staticClass:"small_text"},[_vm._v("Кол-во мест ")]):_vm._e()]),_c('div',{staticClass:"w-1/2 mb-3"},[_c('span',{staticClass:"underline"},[_vm._v(_vm._s(_vm.rent.date_time))]),(_vm.slotInfo.length < 2)?_c('span',{staticClass:"small_text-outcome"},[_vm._v(_vm._s(_vm.rent.rent.price)+" "+_vm._s(_vm.item.currency)+" ")]):_vm._e(),(_vm.rent.rent.discount)?_c('span',{staticClass:"small_text-outcome"},[_vm._v(_vm._s(_vm.rent.rent.discount)+" "+_vm._s(_vm.rent.rent.discount_unit === "procent" ? "%" : "₽")+" ")]):_vm._e(),_c('span',{staticClass:"small_text-outcome"},[_vm._v(_vm._s(_vm.rent.rent.duration)+" ")]),(
            _vm.item.booking_type.booking_type_sub ===
            'rent_depends_on_time_and_place'
          )?_c('span',{staticClass:"small_text-outcome"},[_vm._v(_vm._s(_vm.rent.rent.places)+" ")]):_vm._e()])]),(_vm.slotInfo.length >= 2)?_c('div',[_c('div',{staticClass:"flex justify-between mb-3"},[_c('span',{staticClass:"small_text w-3/12 text-10"},[_vm._v("Единицы ")]),_c('span',{staticClass:"small_text w-2/12 text-10"},[_vm._v("Кол-во ")]),_c('span',{staticClass:"small_text w-3/12 text-10"},[_vm._v("Стоимость ")]),(_vm.isDiscount)?_c('span',{staticClass:"small_text w-2/12 text-10"},[_vm._v("Скидка ")]):_vm._e(),_c('span',{staticClass:"small_text w-2/12 text-10"},[_vm._v("Итого ")])]),_vm._l((_vm.slotInfo),function(i){return _c('div',{key:i.id,staticClass:"flex justify-between p-10 mb-3",style:({
          background: i.id % 2 == 0 ? 'rgba(255, 255, 255, 0.04)' : '',
        })},[_c('span',{staticClass:"small_text-outcome w-3/12 leading-1"},[_vm._v(_vm._s(i.time_unit)+" ")]),_c('span',{staticClass:"small_text-outcome w-2/12 leading-1"},[_vm._v(_vm._s(i.count)+" ")]),_c('span',{staticClass:"small_text-outcome w-3/12 leading-1"},[_vm._v(_vm._s(i.slot_price)+" "+_vm._s(_vm.item.currency))]),(_vm.rent.rent.discount_unit)?_c('span',{staticClass:"small_text-outcome w-2/12 leading-1"},[_vm._v(_vm._s(_vm.rent.rent.discount)+" "+_vm._s(_vm.rent.rent.discount_unit === "procent" ? "%" : "₽"))]):_vm._e(),_c('span',{staticClass:"small_text-outcome w-2/12 leading-1"},[_vm._v(_vm._s(i.price)+" "+_vm._s(_vm.item.currency))])])})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }