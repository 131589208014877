<template>
  <el-popover placement="top-end" popper-class="control-poper" trigger="click">
    <div slot="reference" class="popover__wrapp">
      <div class="slot__wrapp">
        <div
          class="rounded-full w-20 h-20 icon__schedule"
          :class="{
            icon__active: !isBlocked && isBookingType,
            icon__active_blur: !isBlocked && isBookingType && !blurSlot,
            icon__close: isBlocked,
            icon__empty: !isBlocked && !isBookingType,
            // icon__disabled: dis && !isBlocked && isBookingType,
            slot__doule: isBookingType && isBlocked,
          }"
        >
          <svg-icon
            v-if="isBlocked === true"
            icon-name="lock-1"
            width="8"
            height="10"
          ></svg-icon>

          <span v-else-if="!isBookingHash && isReserv" class="text-10"
            >{{
              isBookingType === "individual" ||
              item.booking_type_sub === "rent_depends_only_on_time"
                ? 1
                : isBookingLength
            }}
          </span>
          <span v-if="isBookingHash && !isBlocked" class="text-10">
            <!-- {{ item.booking_count }} -->
            {{ item.bookingLength ? item.bookingLength : isBookingLength || 1 }}
          </span>
        </div>
        <div
          v-if="isBlocked && isBookingType !== 'individual'"
          class="rounded-full w-20 h-20 icon__schedule"
          :class="{
            icon__active: isBookingType,
            icon__close: isBlocked,
            icon__empty: !isBlocked && !isBookingType,
            slot__doule: isBookingType && isBlocked,
          }"
        >
          <svg-icon
            v-if="isBlocked === true && !isBookingType"
            icon-name="lock-1"
            width="8"
            height="10"
          ></svg-icon>
          <span v-else-if="isBookingType" class="text-10"
            >{{
              isBookingType === "individual" ||
              item.booking_type_sub === "rent_depends_only_on_time"
                ? 1
                : isBookingLength
            }}
          </span>
        </div>
      </div>
    </div>
    <div class="block">
      <!-- Расписание тултип - Нет броней -->
      <div
        v-if="!isBlocked && !isBookingType"
        class="block__item_position mb-8 flex-col"
      >
        <span class="block__time">{{ item.time }}</span>
        <div v-if="item.discount">
          <span class="text-14 text-white-normal pr-5">
            {{ item.discount_price }} {{ item.currency }}
          </span>
          <span class="text-14 text-white-600 line-through pr-5">
            {{ item.price }} {{ item.currency }}
          </span>
          <span class="text-14 text-yellow-200 pr-5">
            -{{ item.discount_value }}
            {{ item.discount_type === "percent" ? "%" : `${item.currency}` }}
          </span>
        </div>
        <span class="block__status">
          {{ !isBlocked && !isBookingType ? "Нет бронирований" : "" }}
        </span>
      </div>
      <div
        v-if="!isBlocked && !isBookingType && !item.is_passed"
        class="flex flex-col"
      >
        <el-button
          v-if="!isBlocked && !isBookingType"
          type="primary"
          class="block__btn mt-10 mb-10"
          @click="addNewBooking(item)"
          >Забронировать
        </el-button>

        <el-button
          class="block__btn"
          @click="changeBlockSlot(status.type, item)"
          >{{ "Заблокировать время" }}</el-button
        >
      </div>
      <!--  Расписание тултип - Слот заблокирован -->
      <div
        v-if="isBlocked && isBookingType !== 'group'"
        class="block__item_position mb-8"
      >
        <span class="block__time">{{ item.time }}</span>

        <span
          class="block__status"
          :class="{ 'block__status close': isBlocked }"
        >
          {{ isBlocked ? "Заблокирован" : "" }}
        </span>
      </div>

      <div v-if="isBlocked && isBookingType !== 'group'" class="mb-5">
        <div class="small_text">Кем заблокирован</div>
        <span class="block__text">{{ item.user_blocked }}</span>
      </div>

      <div v-if="isBlocked && isBookingType !== 'group'" class="mb-15">
        <div class="small_text">Дата и время блокировки</div>
        <div v-if="isBlocked" class="flex items-center">
          <span v-if="isBlocked" class="block__text mb-0">{{
            dateConvert(item.date_blocked)
          }}</span>
          <span class="block__text mb-0 px-5"> (UTC+03:00)</span>
        </div>
      </div>
      <div v-if="isBlocked && isBookingType !== 'group'" class="flex flex-col">
        <el-button
          v-if="isBlocked"
          class="block__btn"
          @click="changeBlockSlot(status, item)"
          >{{ "Разблокировать время" }}</el-button
        >
      </div>
      <!--  Расписание тултип - 1 бронирование -->
      <div
        v-if="
          (isBookingHash ||
            isBookingType === 'individual' ||
            item.booking_type_sub === 'rent_depends_only_on_time') &&
          !isBlocked &&
          item.booking_type !== 'group' &&
          item.booking_type_sub !==
            'group_the_seat_limit_is_set_for_each_session' &&
          item.booking_type_sub !== 'rent_depends_on_time_and_place'
        "
        class="block__item mb-8"
      >
        <span v-if="!isBookingHash" class="block__time">{{ item.time }}</span>
        <span v-else class="block__time">{{ item.reservation[0].time }}</span>
        <span
          v-if="
            (isBookingHash ||
              isBookingType === 'individual' ||
              item.booking_type_sub === 'rent_depends_only_on_time') &&
            !isBlocked
          "
          class="block__status"
          :class="{
            block__status:
              isBookingHash || (isBookingType === 'individual' && !isBlocked),
          }"
          :style="{ background: item.status_color }"
        >
          {{ item.status_name }}
        </span>
      </div>
      <div
        v-if="
          (isBookingHash ||
            isBookingType === 'individual' ||
            item.booking_type_sub === 'rent_depends_only_on_time') &&
          !isBlocked &&
          item.booking_type !== 'group' &&
          item.booking_type_sub !==
            'group_the_seat_limit_is_set_for_each_session' &&
          item.booking_type_sub !== 'rent_depends_on_time_and_place'
        "
        class="flex flex-col"
      >
        <div
          v-if="
            (isBookingHash ||
              isBookingType === 'individual' ||
              item.booking_type_sub === 'rent_depends_only_on_time') &&
            !isBlocked
          "
          class="block__text_blue mb-8"
        >
          1 бронь - #{{ reservHash }}
        </div>
        <div v-if="item.reservation[0].bind_resource_hash">
          <span>
            <svg-icon icon-name="overlap" width="20" height="20"></svg-icon>
          </span>
          <router-link
            target="_blank"
            :to="{
              name: 'resource',
              params: { hash: item.reservation[0].bind_resource_hash },
              query: { tab: 'schedule' },
            }"
          >
            <span class="item__text">
              {{ `Услуга: ${item.reservation[0].bind_resource_name}` }}</span
            >
          </router-link>
        </div>
      </div>

      <div
        v-if="
          (isBookingHash ||
            isBookingType === 'individual' ||
            item.booking_type_sub === 'rent_depends_only_on_time') &&
          !isBlocked &&
          item.booking_type !== 'group' &&
          item.booking_type_sub !==
            'group_the_seat_limit_is_set_for_each_session' &&
          item.booking_type_sub !== 'rent_depends_on_time_and_place'
        "
        class="flex flex-col"
      >
        <div class="small_text">Клиент</div>

        <div
          v-if="
            (isBookingHash ||
              isBookingType === 'individual' ||
              item.booking_type_sub === 'rent_depends_only_on_time') &&
            !isBlocked
          "
        >
          <div class="block__text inline-block border-b">
            <!-- УДАЛИЛ ПОКА НЕ ИСПРАВЯТ БЕК -->
            <!-- <router-link
              :to="{
                name: 'clients',
                //params: { id: item.clientHash },
                query: { id: item.client_hash },
              }"
            > -->
            <span class="pl-5">{{ userReservClient }}</span>
            <span
              v-if="
                (isBookingHash ||
                  isBookingType === 'individual' ||
                  item.booking_type_sub === 'rent_depends_only_on_time') &&
                !isBlocked
              "
              class="pl-5"
              >{{ isSurname }}</span
            >
            <!-- </router-link> -->
          </div>
        </div>

        <div
          v-if="
            (isBookingHash ||
              isBookingType === 'individual' ||
              item.booking_type_sub === 'rent_depends_only_on_time') &&
            !isBlocked
          "
        >
          <div
            v-if="
              (isBookingHash ||
                isBookingType === 'individual' ||
                item.booking_type_sub === 'rent_depends_only_on_time') &&
              !isBlocked
            "
            class="block__text inline-block border-b"
          >
            <a
              :href="
                item.client_tel ? `tel:${item.client_tel}` : `tel:${isPhone}`
              "
            >
              {{ isPhone }}</a
            >
          </div>
        </div>
        <div
          v-if="
            (isBookingHash ||
              isBookingType === 'individual' ||
              item.booking_type_sub === 'rent_depends_only_on_time') &&
            !isBlocked
          "
        >
          <div class="block__text inline-block border-b">
            <a
              :href="
                item.client_email
                  ? `mailto:${item.client_email}`
                  : `mailto:${isMail}`
              "
            >
              {{ isMail }}</a
            >
          </div>
        </div>
      </div>
      <div
        v-if="
          (isBookingHash ||
            isBookingType === 'individual' ||
            item.booking_type_sub === 'rent_depends_only_on_time') &&
          !isBlocked &&
          item.booking_type !== 'group' &&
          item.booking_type_sub !==
            'group_the_seat_limit_is_set_for_each_session' &&
          item.booking_type_sub !== 'rent_depends_on_time_and_place'
        "
        class="mb-15"
      >
        <div class="flex items-center">
          <span
            v-if="
              (isBookingHash ||
                isBookingType === 'individual' ||
                item.booking_type_sub === 'rent_depends_only_on_time') &&
              !isBlocked
            "
            class="block__text block__text_border mb-0 pr-1"
          >
            {{ item.total_price ? item.book_info.total_price : 0 }}
          </span>
          <span
            v-if="
              (isBookingHash ||
                isBookingType === 'individual' ||
                item.booking_type_sub === 'rent_depends_only_on_time') &&
              !isBlocked
            "
            class="block__text block__text_border mb-0"
            >{{ item.currency }}
          </span>

          <div
            v-if="
              (isBookingHash ||
                isBookingType === 'individual' ||
                item.booking_type_sub === 'rent_depends_only_on_time') &&
              !isBlocked
            "
            class="flex items-center opacity-50 mx-8"
          >
            <span>.</span>
          </div>
          <span
            v-if="
              (isBookingHash ||
                isBookingType === 'individual' ||
                item.booking_type_sub === 'rent_depends_only_on_time') &&
              !isBlocked
            "
            class="block__text block__text_border mb-0"
            >{{ item.reservation[0].time }}
          </span>
          <span
            v-if="
              (isBookingHash ||
                isBookingType === 'individual' ||
                item.booking_type_sub === 'rent_depends_only_on_time') &&
              !isBlocked
            "
            class="block__text block__text_border mb-0 px-3"
            >-
          </span>

          <span
            v-if="
              (isBookingHash ||
                isBookingType === 'individual' ||
                item.booking_type_sub === 'rent_depends_only_on_time') &&
              !isBlocked
            "
            class="block__text block__text_border mb-0"
            >{{ item.time_end }}
          </span>
          <span
            v-if="
              (isBookingHash ||
                item.booking_type_sub === 'rent_depends_only_on_time') &&
              !isBlocked
            "
            class="block__text block__text_border mb-0 pl-3"
          >
            ({{ item.duration || isDuration }}</span
          >
          <span
            v-if="
              (isBookingHash ||
                isBookingType === 'individual' ||
                item.booking_type_sub === 'rent_depends_only_on_time') &&
              !isBlocked
            "
            class="block__text block__text_border mb-0"
          >
            {{ isTime }} )</span
          >
        </div>
      </div>
      <div
        v-if="
          (isBookingHash ||
            isBookingType === 'individual' ||
            item.booking_type_sub === 'rent_depends_only_on_time') &&
          !isBlocked &&
          item.booking_type !== 'group' &&
          item.booking_type_sub !==
            'group_the_seat_limit_is_set_for_each_session' &&
          item.booking_type_sub !== 'rent_depends_on_time_and_place'
        "
        class="flex flex-col"
      >
        <el-button
          v-if="!item.reservation[0].bind_resource_hash"
          class="block__btn"
          @click="changeStatus(item)"
          >{{ "Редактировать" }}</el-button
        >
      </div>

      <!-- Расписание тултип - Несколько броней, групповая запись -->
      <div
        v-if="
          (isBookingType === 'group' ||
            (isBookingType === 'rent' &&
              item.booking_type_sub !== 'rent_depends_only_on_time')) &&
          (!isBookingHash || isBookingHash)
        "
        class="block__item_position mb-8 flex-col"
      >
        <span
          v-if="
            (isBookingType === 'group' ||
              (isBookingType === 'rent' &&
                item.booking_type_sub !== 'rent_depends_only_on_time')) &&
            (!isBookingHash || isBookingHash)
          "
          class="block__time"
          >{{ item.time }}</span
        >
        <div
          v-if="
            (isBookingType === 'group' ||
              (isBookingType === 'rent' &&
                item.booking_type_sub !== 'rent_depends_only_on_time')) &&
            (!isBookingHash || isBookingHash)
          "
          class="block__text_blue"
        >
          {{ item.booking_count }}
          бронирования
        </div>
      </div>
      <div
        v-if="
          (isBookingType === 'group' ||
            (isBookingType === 'rent' &&
              item.booking_type_sub !== 'rent_depends_only_on_time')) &&
          (!isBookingHash || isBookingHash)
        "
      >
        <div v-if="item.tickets_places_count" class="flex justify-between">
          <span class="small-text">Места </span>
          <!-- <span class="block__text_border">{{ item.total_seats }}</span> -->
          <span class="block__text_border">{{
            item.tickets_places_count.total
          }}</span>
        </div>
        <div class="flex justify-between">
          <span class="small_text">Забронировано </span>
          <span class="block__text_border"
            >{{ item.tickets_places_count.booked }}
          </span>
        </div>
        <div class="flex justify-between">
          <span class="small_text">Осталось </span>
          <span class="block__text_border">{{
            item.tickets_places_count.remained
          }}</span>
        </div>
      </div>

      <div
        v-if="
          (isBookingType === 'group' ||
            isBookingType === 'service' ||
            (isBookingType === 'rent' &&
              item.booking_type_sub !== 'rent_depends_only_on_time')) &&
          (!isBookingHash || isBookingHash)
        "
        class="flex flex-col"
      >
        <el-button
          v-if="
            (isBookingType === 'group' ||
              isBookingType === 'service' ||
              (isBookingType === 'rent' &&
                item.booking_type_sub !== 'rent_depends_only_on_time')) &&
            (!isBookingHash || isBookingHash)
          "
          type="primary"
          class="block__btn mt-10"
          :disabled="
            isDisabled ||
            isBlocked ||
            !isCounTicketNull ||
            item.is_passed === true
          "
          @click="addNewBooking(item)"
          >Добавить бронирование
        </el-button>
        <router-link
          v-if="item.reservation.length > 1"
          class="block__btn mt-15"
          :to="{
            path: '/reserves',
            query: {
              page: 1,
              pagination: 10,
              service_time: `${item.time}`,
              service_from: `${item.reservation[0].date}`,
              service_to: `${item.reservation[0].date}`,
              names: `${item.resource_id}`,
            },
          }"
          ><span>Смотреть бронирования</span></router-link
        >
        <el-button
          v-if="
            (isBookingType === 'group' ||
              (isBookingType === 'rent' &&
                item.booking_type_sub !== 'rent_depends_only_on_time')) &&
            (!isBookingHash || isBookingHash)
          "
          :class="{ disabled__style: !isCounTicketNull }"
          class="block__btn mt-15"
          :disabled="isDisabled"
          @click="changeBlockSlot(status, item)"
          >{{
            (isBookingType === "group" || isBookingType === "rent") && isBlocked
              ? "Разблокировать время"
              : "Заблокировать время"
          }}</el-button
        >
      </div>
    </div>
    <spinner v-if="isLoading" class="table__spinner" />
  </el-popover>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import { AJAX_GET, AJAX_POST } from "@/units/ajax.js";

export default {
  components: {},
  props: {
    item: Object,
    // nameService: String || Object,
    data: String,
    dis: Boolean,
  },
  data() {
    return {
      status: null,
      isLoading: false,
      blocked: null,
    };
  },
  computed: {
    ...mapState("service", ["service"]),

    isBookingHash() {
      return this.item.booking_hash;
    },
    isReserv() {
      return this.item?.reservation?.length > 0;
    },
    isBlocked() {
      return this.item?.block || this.item?.is_blocked;
    },
    isCounTicketNull() {
      return !!this.item?.tickets_places_count?.remained;
    },
    isBookingType() {
      return this.item?.booking_type;
    },
    isBookingLength() {
      return this.item?.booking_count;
    },
    userReservClient() {
      return this.item?.reservation?.[0]?.client_info?.name;
    },
    isSurname() {
      return this.item?.reservation?.[0]?.client_info?.surname;
    },
    isPhone() {
      return this.item?.reservation?.[0]?.client_info?.tel;
    },
    isMail() {
      return this.item?.reservation?.[0]?.client_info?.email;
    },

    diffSeats() {
      return this.item.total_seats - this.item.seats;
    },
    isDuration() {
      return (
        this.item?.reservation[0]?.book_info?.tariff?.duration ||
        this.item?.reservation[0]?.book_info?.service?.duration
      );
    },
    isTime() {
      return this.item?.reservation?.book_info?.tariff?.time_unit;
    },
    reservHash() {
      return this.item?.reservation?.[0]?.hash;
    },
    isDisabled() {
      return this.item.total_seats > 0
        ? this.item.total_seats === this.diffSeats
        : false;
    },
    isBookingInfo() {
      return this.item?.reservation?.[0]?.booking_info;
    },
    blurSlot() {
      return !!(
        this.item.reservation.length >= 1 &&
        this.item.time === this.item?.reservation[0]?.time
      );
    },
  },

  created() {
    this.status = this.getStatus();
  },
  methods: {
    ...mapActions("service", ["getSchedule"]),
    ...mapActions("settings", ["getSettingsSite"]),
    ...mapMutations(["setNotif"]),

    addNewBooking(item) {
      this.$emit("openForm", {
        nameService: this.name,
        hash: this.item.resource_id,
        item,
        date: this.data,
      });
    },
    changeStatus(item) {
      this.$emit("openForm", {
        status: "update",
        nameService: this.name,
        hash: this.item.resource_id,
        item,
        date: this.data,
      });
    },

    changeBlockSlot(status, item) {
      if (this.item.is_blocked === true) {
        this.isLoading = true;
        AJAX_POST("/api/v1/booking/admin/slot/unblock", {
          time: item.time,
          date: this.data,
          schedule_id: item.id,
          week: this.$route.query.week ?? this.service.nearestWeek,
        })
          .then((result) => {
            if (result.data.success === true) {
              this.getSchedule({
                hash: this.hash,
                date: this.date,
                week: this.$route.query.week ?? this.service.nearestWeek,
              });
              this.getSettingsSite();
            }
          })
          .catch((err) => {
            console.log(err);
          });
        this.isLoading = false;
      } else {
        this.isLoading = true;
        AJAX_POST("/api/v1/booking/admin/slot/block", {
          time: item.time,
          date: this.data,
          schedule_id: item.id,
          week: this.$route.query.week ?? this.service.nearestWeek,
        })
          .then((result) => {
            if (result.data.success === true) {
              this.getSchedule({
                hash: this.hash,
                date: this.date,
                week: this.$route.query.week ?? this.service.nearestWeek,
              });
              this.getSettingsSite();
            } else {
              this.setNotif({
                title: `${result.data.msg}`,
                type: "warning",
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
        this.isLoading = false;
      }
    },

    getDate() {
      const date = new Date();
      return date.toLocaleDateString("en-CA");
    },

    dateConvert(param) {
      const date = new Date(param);
      return date.toLocaleString("ru-RU");
    },
    getStatus() {
      if (this.item.active) return { type: "active", text: "Подтвержден" };
      return { type: "close", text: "Заблокирован" };
    },
    unitCahnge(param) {
      switch (param) {
        case "days":
          return {
            text: "дней",
            textShot: "д",
            param: "day",
            time: this.hours,
          };
        case "hours":
          return {
            text: "часов",
            textShot: "ч",
            param: "hour",
            time: this.hours,
          };
        case "minutes":
          return {
            text: "минут",
            textShot: "мин",
            param: "minute",
            time: this.hours,
          };
        case "minute":
          return {
            text: "минут",
            textShot: "мин",
            param: "minute",
            time: this.hours,
          };

        default:
          return this.settings?.data?.time_unit;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.is-disabled {
  background: rgba(255, 190, 61, 0.5);
}
.disabled__style {
  color: rgba(255, 255, 255, 0.3) !important;
  pointer-events: none;
}

.popover {
  &__wrapp {
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.el-button + .el-button {
  margin-left: 0;
}
::v-deep .el-popover {
  background: #000;
  border-color: #000;
}
.icon {
  &__schedule {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
  }
  &__close {
    background: #8a949d;
  }
  &__active {
    background: #3498db;
    color: #3c1452;
    &_blur {
      background: #385696;
      //rgba(52, 152, 219, 0.5);
    }
  }
  &__empty {
    background: transparent;
    top: inherit;
    width: 100%;
    height: 50%;
  }
  &__disabled {
    background: rgba(52, 152, 219, 0.5);
    color: rgba(0, 0, 0, 0.9);
  }
}
.block {
  border-radius: 3px;
  padding: 16px;
  background: #000;
  &__btn {
    height: 30px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    font-size: 12px;
    min-width: 218px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__item {
    display: flex;
    align-items: center;
    &_position {
      display: flex;
      align-items: flex-start;
    }
  }
  &__text {
    font-size: 14px;
    line-height: 24px;
    color: white;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 5px;
    &_blue {
      margin-bottom: 8px;
      color: #3498db;
    }
    &_blur {
      margin-bottom: 8px;
      color: rgba(52, 152, 219, 0.4) !important;
      background: transparent;
    }

    &_border {
      font-size: 14px;
      line-height: 24px;
      color: white;
      border-bottom: none;
    }
  }

  &__status {
    line-height: 20px;
    font-size: 12px;
    padding: 2px 8px;
    padding-left: 0px;
    border-radius: 3px;
    &.active {
      background: rgba(52, 152, 219, 0.2);
      color: #3498db;
    }
    &.blur {
      color: rgba(52, 152, 219, 0.4);
      background: transparent;
    }

    &.close {
      color: #e74c3c;
      background: rgba(231, 76, 60, 0.2);
    }
  }
  &__time {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    margin-right: 10px;
    color: #fff;
  }
}
.slot {
  &__wrapp {
    display: flex;
    justify-content: center;
  }
  &__doule {
    position: relative;
    margin: 0 3px;
    left: 0px;
    transform: translateX(0%);
  }
}
.item__text {
  display: inline-block;
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
