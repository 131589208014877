<template>
  <div class="list-fields relative">
    <div v-if="isManual && dayWeek >= 2" class="flex justify-center my-10">
      <el-button
        class="list-fields__add-button text-12"
        @click="copyForMonday('last')"
      >
        Скопировать с предыдущего дня
      </el-button>
      <el-button
        class="list-fields__add-button text-12"
        @click="copyForMonday('mond')"
      >
        Скопировать с понедельника
      </el-button>
    </div>
    <div
      v-for="(item, index) in listField"
      :key="index"
      class="list-fields__row my-10 md:flex-row xs:flex-wrap"
    >
      <div class="list-fields__item xs:w-1/2">
        <el-form-item prop="basePrice" class="w-full">
          <div slot="label" class="flex">
            {{ headers[0].title }}
            <span v-if="headers[0].valid" class="danger">*</span>
            <el-popover
              v-if="headers[0].popover"
              class="ml-8"
              placement="top"
              width="200"
              trigger="hover"
              :content="headers[0].popoverText"
            >
              <Tooltip slot="reference" />
            </el-popover>
          </div>

          <el-time-picker
            v-if="changePicker(item.time)"
            :placeholder="changePicker(item.time)"
          >
          </el-time-picker>

          <el-time-picker
            v-if="!changePicker(item.time)"
            v-model="item.time"
            format="HH:mm"
            :placeholder="headers[0].placeholder"
            :disabled="
              generatorSessions
                ? generatorSessions.schedule_type === 'bind'
                : false
            "
            @input="emitData()"
          >
          </el-time-picker>
        </el-form-item>
      </div>
      <!-- <div class="list-fields__item">
        <el-form-item prop="basePrice" class="w-full">
          <div slot="label" class="flex">
            {{ headers[3].title }}
            <span v-if="headers[3].valid" class="danger">*</span>
            <el-popover
              v-if="headers[3].popover"
              class="ml-8"
              placement="top"
              width="200"
              trigger="hover"
              :content="headers[3].popoverText"
            >
              <Tooltip slot="reference" />
            </el-popover>
          </div>
          <el-input-number
            v-model="item.amount"
            type="number"
            class="w-full"
          ></el-input-number>
        </el-form-item>
      </div> -->

      <div v-if="!isServiceBookingType" class="list-fields__item">
        <el-form-item prop="basePrice" class="w-full">
          <div slot="label" class="flex">
            {{ headers[1].title }}
            <span v-if="headers[1].valid" class="danger">*</span>
            <el-popover
              v-if="headers[1].popover"
              class="ml-8"
              placement="top"
              width="200"
              trigger="hover"
              :content="headers[1].popoverText"
            >
              <Tooltip slot="reference" />
            </el-popover>
          </div>
          <el-input
            v-model.number="item.price"
            placeholder="Введите цену"
            @input="emitData()"
          >
            <div slot="suffix">₽</div>
          </el-input>
        </el-form-item>
      </div>
      <div v-if="!isServiceBookingType" class="list-fields__item">
        <el-form-item prop="basePrice" class="w-full">
          <div slot="label" class="flex">
            {{ headers[2].title }}
            <span v-if="headers[2].valid" class="danger">*</span>
            <el-popover
              v-if="headers[2].popover"
              class="ml-8"
              placement="top"
              width="200"
              trigger="hover"
              :content="headers[2].popoverText"
            >
              <Tooltip slot="reference" />
            </el-popover>
          </div>
          <el-input
            v-model.number="item.holidays_price"
            placeholder="Введите цену"
            @input="emitData()"
          >
            <div slot="suffix">₽</div>
          </el-input>
        </el-form-item>
      </div>
      <div
        v-if="
          generatorSessions
            ? listField.length > 1 && generatorSessions.schedule_type !== 'bind'
            : true
        "
        :style="{
          width:
            ($mq.largePhonePlusPlus && $mq.tablet) || $mq.phoneMobileLargeMax
              ? '100%'
              : 'auto',
        }"
        class="list-fields__actions lg:m-0"
      >
        <ButtonIcon
          icon-name="close"
          :class="
            ($mq.largePhonePlusPlus && $mq.tablet) || $mq.phoneMobileLargeMax
              ? 'w-max'
              : 'w-auto'
          "
          :text="
            ($mq.largePhonePlusPlus && $mq.tablet) || $mq.phoneMobileLargeMax
              ? 'Удалить время'
              : ''
          "
          width="16"
          @click="deleteField(index)"
        />
      </div>
    </div>
    <el-button
      v-if="
        generatorSessions ? generatorSessions.schedule_type !== 'bind' : true
      "
      class="list-fields__add-button"
      @click="addField()"
    >
      {{ titleButton }}
    </el-button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "SlotSchedule",

  props: {
    isServiceBookingType: {
      type: Boolean,
    },
    generatorSessions: {
      type: Object,
    },
    periodDays: {
      type: Array,
    },
    title: {
      type: String,
      default: "",
    },
    titleButton: {
      type: String,
      default: "Добавить время",
    },
    placeholder: {
      type: String,
      default: "Введите значение",
    },
    dataSession: Array,

    headers: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: null,
    },
    basePrice: {
      type: [String, Number],
      default: null,
    },
    holidaysPrice: {
      type: [String, Number],
      default: null,
    },
    isBind: Boolean,
    isManual: Boolean,
    dayWeek: Number,
  },
  data() {
    return {
      listField: [
        {
          time: null,
          price: null,
          holidays_price: null,
        },
      ],
    };
  },
  computed: {
    ...mapGetters("service", ["isAddCost"]),
    ...mapGetters("service", ["isHaveSettings"]),
  },
  watch: {
    value() {
      this.listField = [...this.value];
    },
  },
  created() {
    this.listField = [...this.value];
  },
  methods: {
    changePicker(q) {
      if (typeof q === "string") {
        return moment().format(q);
      }
    },
    copyForMonday(key) {
      let day = this.dayWeek;
      if (key === "last" && this.dayWeek !== 1) {
        day -= 1;
      } else {
        day = 1;
      }
      const copyParams = this.periodDays.find((el) => el.value === day);
      this.listField = [...copyParams.schedule];
      this.emitData();
    },
    deleteField(index) {
      this.listField.splice(index, 1);
      this.emitData();
    },
    addField() {
      this.listField.push({
        time: null,
        price: this.isAddCost ? null : this.basePrice,
        holidays_price: this.isAddCost ? null : this.holidaysPrice,
      });
      this.emitData();
    },
    emitData() {
      this.$emit("input", [...this.listField]);
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-input__suffix-inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
</style>
