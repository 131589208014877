<template>
  <div class="flex h-full">
    <el-dialog
      title="Задать вопрос"
      :visible.sync="askQuestion"
      :show-close="false"
      custom-class="ask-question w-1/2"
    >
      <div
        slot="title"
        class="absolute fast-add-close"
        @click="askQuestion = false"
      >
        <div class="cursor-pointer">
          <svg-icon
            icon-name="close"
            width="12"
            height="12"
            class-name="text-white-normal"
          ></svg-icon>
        </div>
      </div>
      <el-form
        ref="formAskQuestion"
        v-loading="askQuestionLoading"
        hide-required-asterisk
        :model="formAskQuestion"
        label-position="top"
        :rules="formAskQuestionRules"
        @keyup.native.enter="onAskQuestion()"
      >
        <div class="pt-20 px-30 pb-30 border-b border-white-150 xs:px-15">
          <div class="text-18 font-semibold leading-30 mb-3 text-white-normal">
            Задать вопрос
          </div>
          <div class="text-12 text-white-500 leading-19 mb-30">
            Есть вопросы? Напишите нам, и мы ответим в ближайшее время
          </div>
          <el-form-item label="Ваше имя" class="mb-20" prop="name">
            <el-input
              v-model="formAskQuestion.name"
              placeholder="Напишите ваше имя"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="Ваш E-mail" class="mb-20" prop="email">
            <el-input
              v-model="formAskQuestion.email"
              placeholder="Введите ваш e-mail"
              type="email"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="Ваш вопрос" class="mb-0" prop="question">
            <el-input
              v-model="formAskQuestion.question"
              placeholder="Напишите ваш вопрос"
              :rows="3"
              resize="none"
              type="textarea"
              class="custom__area"
            >
            </el-input>
          </el-form-item>
        </div>
        <div
          class="p-30 flex justify-between items-start xs:p-15 xs:py-20 xs:flex-col"
        >
          <p class="text-white-normal text-12 pr-40 xs:pr-0 xs:mb-20">
            Нажимая кнопку "Отправить", Вы принимаете условия
            <router-link
              target="_blank"
              to="/useragreement"
              class="text-yellow-100"
              >пользовательское соглашение
            </router-link>
          </p>
          <el-button
            type="primary"
            class="bg-gradient-b-yellow border-0 xs:w-full"
            @click="onAskQuestion()"
          >
            <span class="flex items-center justify-center"> Отправить </span>
          </el-button>
        </div>
      </el-form>
    </el-dialog>

    <div class="login-form flex items-center justify-center w-full h-full">
      <div
        class="login-form__wrapper flex items-center justify-center p-10 lg:p-30"
      >
        <div class="login-form__form w-full">
          <img
            v-if="srcLogo"
            width="227"
            :src="srcLogo"
            alt=""
            class="mb-50 xxl:mb-10 lg:mb-50 lg:mr-auto"
          />
          <svg-icon
            v-else
            icon-name="logo"
            width="227"
            height="38"
            class-name="mb-50 xxl:mb-10 lg:mb-50 lg:mr-auto"
          ></svg-icon>

          <el-tabs
            v-if="!forgotOpened"
            v-model="activeName"
            :class="[
              {
                hiddenHeader: !settingLicense.allow_register,
              },
            ]"
          >
            <el-tab-pane label="Авторизация" name="login">
              <!-- <div
                v-if="settingLicense.allow_register"
                class="leading-25 mb-10 mt-15"
              >
                Войти с помощью соцсетей
              </div>
              <div v-if="settingLicense.allow_register" class="flex mb-30">
                <a
                  v-for="item in socials"
                  :key="item.id"
                  :href="item.value"
                  :class="`link link-${item.name}`"
                >
                  <svg-icon :icon-name="item.name"></svg-icon>
                </a>
              </div>
              <div
                v-if="settingLicense.allow_register"
                class="flex items-center"
              >
                или
                <div class="h-2 bg-white-100 flex-grow ml-7 mt-1"></div>
              </div> -->
              <el-form
                ref="formLogin"
                label-position="top"
                hide-required-asterisk
                :model="formLogin"
                class="mb-20 mt-25"
                :rules="formLoginRules"
                @keyup.native.enter="loginUser()"
              >
                <el-form-item label="E-mail" class="mb-20" prop="email">
                  <el-input
                    v-model="formLogin.email"
                    placeholder="Введите ваш e-mail"
                    type="email"
                  >
                  </el-input>
                </el-form-item>

                <el-form-item label="Пароль" class="mb-20" prop="password">
                  <el-input
                    v-model="formLogin.password"
                    placeholder="Введите ваш пароль"
                    type="password"
                  ></el-input>
                </el-form-item>
                <el-form-item class="mb-30">
                  <el-checkbox
                    v-model="formLogin.remember"
                    label="Запомнить меня"
                  ></el-checkbox>
                </el-form-item>
                <el-button
                  type="primary"
                  class="bg-gradient-b-yellow border-0 w-full"
                  @click="loginUser()"
                >
                  <span class="flex items-center justify-center">
                    <svg-icon
                      icon-name="arrow"
                      width="16"
                      height="10"
                      class-name="text-black mr-10"
                    ></svg-icon
                    >Войти
                  </span>
                </el-button>
              </el-form>
              <div class="flex justify-between">
                <span
                  class="cursor-pointer text-white-700 text-12 border-b border-white-350 hover:text-white-normal"
                  @click="forgotOpened = true"
                  >Восстановить пароль</span
                >
                <span
                  class="cursor-pointer text-white-700 text-12 border-b border-white-350 hover:text-white-normal"
                  @click="askQuestion = true"
                  >Задать вопрос</span
                >
              </div>
            </el-tab-pane>
            <el-tab-pane label="Регистрация" name="register">
              <el-form
                ref="formRegister"
                :model="formRegister"
                hide-required-asterisk
                label-position="top"
                class="mb-20 mt-25 xxl:mt-10 lg:mt-25 flex flex-col"
                :rules="formRegisterRules"
              >
                <el-form-item label="Имя" class="mb-20" prop="name">
                  <el-input
                    v-model="formRegister.name"
                    placeholder="Введите ваше имя"
                    type="text"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item label="E-mail" class="mb-20" prop="email">
                  <el-input
                    v-model="formRegister.email"
                    placeholder="Введите ваш e-mail"
                    type="email"
                  >
                  </el-input>
                </el-form-item>
                <el-form-item
                  ref="password"
                  label="Пароль"
                  class="mb-20"
                  prop="password"
                >
                  <el-input
                    v-model="formRegister.password"
                    placeholder="Введите ваш пароль"
                    type="password"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="Пароль повторно"
                  class="mb-20"
                  prop="password_confirmation"
                >
                  <el-input
                    v-model="formRegister.password_confirmation"
                    placeholder="Введите пароль еще раз"
                    type="password"
                  ></el-input>
                </el-form-item>

                <el-form-item label="Промокод" class="mb-20" prop="promocod">
                  <el-input
                    v-model="formRegister.referral"
                    placeholder="Введите промокод"
                    type="text"
                  ></el-input>
                </el-form-item>
                <p class="mb-30 xxl:mb-15 lg:mb-30 text-white-normal text-12">
                  Нажимая кнопку "Регистрация", Вы принимаете условия
                  <a href="#" class="text-yellow-100 hover:text-yellow-300"
                    >соглашения о персональных данных</a
                  >
                </p>
                <el-button
                  type="primary"
                  class="bg-gradient-b-yellow border-0 w-full mb-20"
                  @click="registerUser()"
                >
                  <span class="flex items-center justify-center">
                    <svg-icon
                      icon-name="arrow"
                      class-name="text-black mr-10"
                      width="16"
                      height="10"
                    ></svg-icon
                    >Регистрация
                  </span>
                </el-button>
                <span
                  class="cursor-pointer text-white-700 text-12 border-b border-white-350 hover:text-white-normal self-center"
                  @click="askQuestion = true"
                  >Задать вопрос</span
                >
              </el-form>
            </el-tab-pane>
          </el-tabs>

          <template v-else>
            <el-form
              ref="formForgot"
              label-position="top"
              hide-required-asterisk
              :model="formForgot"
              class="mb-20 mt-25"
              :rules="formForgotRules"
              @keyup.native.enter="resetUser()"
            >
              <el-form-item label="E-mail" class="mb-20" prop="email">
                <el-input
                  v-model="formForgot.email"
                  placeholder="Введите ваш e-mail"
                  type="email"
                >
                </el-input>
              </el-form-item>
              <el-button
                type="primary"
                class="bg-gradient-b-yellow border-0 w-full"
                @click="resetUser()"
              >
                <span class="flex items-center justify-center">
                  <svg-icon
                    icon-name="arrow"
                    width="16"
                    height="10"
                    class-name="text-black mr-10"
                  ></svg-icon
                  >Восстановить
                </span>
              </el-button>
            </el-form>
            <div class="flex justify-start">
              <span
                class="cursor-pointer text-white-700 text-12 border-b border-white-350 hover:text-white-normal"
                @click="forgotOpened = false"
                >Авторизация</span
              >
            </div>
          </template>
        </div>
      </div>
      <div class="login-slider h-full lg:hidden">
        <spinner v-if="!settingLicense.slider.length" />
        <el-carousel
          v-else
          height="100%"
          class="h-full"
          arrow="always"
          trigger="click"
        >
          <el-carousel-item
            v-for="(item, index) in settingLicense.slider"
            :key="index"
          >
            <img
              :src="item.image"
              alt=""
              class="object-cover h-full w-full absolute"
            />
            <div class="px-100 w-full h-full flex flex-col">
              <div
                class="flex flex-col items-start text-white-normal absolute z-20 login-slider-content w-full"
              >
                <span class="text-46 leading-60 font-semibold mb-15">{{
                  item.title
                }}</span>
                <p class="block" :class="{ 'mb-45': item.button }">
                  {{ item.subtitle }}
                </p>
                <router-link v-if="item.button" :to="item.button.path">
                  <el-button>{{ item.button.label }}</el-button>
                </router-link>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import { AJAX_GET, AJAX_POST } from "@/units/ajax";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "Login",
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Введите пароль"));
      } else if (value.length < 6) {
        callback(new Error("Минимальная длина пароля 6 символов"));
      } else {
        if (this.formRegister.password_confirmation !== "") {
          this.$refs.formRegister.validateField("password_confirmation");
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Введите пароль повторно"));
      } else if (value.length < 6) {
        callback(new Error("Минимальная длина пароля 6 символов"));
      } else if (value !== this.formRegister.password) {
        callback(new Error("Пароли не совпадают"));
      } else {
        callback();
      }
    };

    return {
      socials: [
        {
          id: 0,
          name: "vk",
          value: `${process.env.VUE_APP_PROD_API}/api/flynsarmy/sociallogin/Vk`,
        },
        {
          id: 1,
          name: "google",
          value: `${process.env.VUE_APP_PROD_API}/api/flynsarmy/sociallogin/Google`,
        },
        {
          id: 2,
          name: "facebook",
          value: `${process.env.VUE_APP_PROD_API}/api/flynsarmy/sociallogin/Facebook`,
        },
        {
          id: 3,
          name: "odnoklassniki",
          value: `${process.env.VUE_APP_PROD_API}/api/flynsarmy/sociallogin/Ok`,
        },
        {
          id: 4,
          name: "yandex",
          value: `${process.env.VUE_APP_PROD_API}/api/flynsarmy/sociallogin/Yandex`,
        },
      ],
      activeName: "login",
      forgotOpened: false,
      askQuestion: false,
      askQuestionLoading: false,
      formRegister: {
        email: "",
        name: "",
        password: "",
        password_confirmation: "",
        referral: "",
      },
      formAskQuestion: {
        name: "",
        email: "",
        question: "",
      },
      formForgot: {
        email: "",
      },
      formLogin: {
        email: "",
        password: "",
        remember: true,
      },
      formForgotRules: {
        email: [
          {
            type: "email",
            required: true,
            message: "Введите правильный E-mail",
            trigger: ["blur", "change"],
          },
        ],
      },
      formLoginRules: {
        email: [
          {
            type: "email",
            required: true,
            message: "Введите правильный E-mail",
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            required: true,
            min: 6,
            message: "Введите пароль",
          },
        ],
      },
      formRegisterRules: {
        email: [
          {
            type: "email",
            required: true,
            message: "Введите правильный E-mail",
            trigger: ["blur", "change"],
          },
        ],
        name: [
          {
            required: true,
            min: 1,
            message: "Введите ваше имя",
            trigger: ["blur", "change"],
          },
        ],
        password: [
          {
            required: true,
            validator: validatePass,
          },
        ],
        password_confirmation: [
          {
            required: true,
            validator: validatePass2,
          },
        ],
      },
      formAskQuestionRules: {
        email: [
          {
            type: "email",
            required: true,
            message: "Введите правильный E-mail",
            trigger: ["blur", "change"],
          },
        ],
        name: [
          {
            required: true,
            min: 1,
            message: "Введите ваше имя",
            trigger: ["blur", "change"],
          },
        ],
        question: [
          {
            required: true,
            min: 1,
            message: "Введите ваш вопрос",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("settings", ["settingLicense"]),
    ...mapGetters("settings", ["srcLogo"]),
  },
  async created() {
    const activateCode = this.$route.query.user_activation;

    if (!this.$route.params.id && document.cookie.length) {
      const searchCook = document.cookie
        ?.split(";")
        .filter((i) => i.trim().includes("referral="));
      const [first, second] = searchCook[0].split("=");
      this.formRegister.referral = `${second}`;
    }

    if (activateCode) {
      await AJAX_POST(
        `/api/activate`,
        { code: activateCode },
        { noCancel: true }
      )
        .then((result) => {
          this.setNotif({ title: `${result.data.msg}` });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  },
  mounted() {
    if (this.user) {
      this.clearUser();
    }
  },
  methods: {
    ...mapActions("user", ["setUser", "clearUser"]),
    ...mapMutations("menu", ["setMenuCounts", "setAddLinksCounts"]),
    ...mapMutations(["setNotif"]),
    ...mapMutations("user", ["setServiceList"]),

    onAskQuestion() {
      this.$refs.formAskQuestion.validate((valid) => {
        if (valid) {
          this.askQuestionLoading = true;
          AJAX_POST("/api/support/quest/send", this.formAskQuestion)
            .then((response) => {
              if (response.data.success) {
                this.$message({
                  message: `Ваше сообщение успешно отправлено. В скором времени мы с вами свяжемся.`,
                  type: "success",
                  duration: 5000,
                });
                this.askQuestion = false;
                this.$refs.formAskQuestion.resetFields();
                this.askQuestionLoading = false;
              } else {
                this.$message({
                  message:
                    response.data.msg || "Произошла ошибка, попробуйте позже",
                  type: "error",
                  duration: 5000,
                });
                this.askQuestionLoading = false;
              }
            })
            .catch(() => {
              this.$message({
                message: "Произошла ошибка, попробуйте позже",
                type: "error",
                duration: 5000,
              });
              this.askQuestionLoading = false;
            });
        }
      });
    },
    resetUser() {
      this.$refs.formForgot.validate((valid) => {
        if (valid) {
          AJAX_POST("/api/reset", this.formForgot)
            .then((response) => {
              if (response.data.success) {
                this.$message({
                  message: `Инструкция по восстановлению пароля была отправлена на ${this.formForgot.email}`,
                  type: "success",
                  duration: 5000,
                });
                this.forgotOpened = false;
                this.$refs.formForgot.resetFields();
              } else if (response.data.msg) {
                this.$message({
                  message: response.data.msg,
                  type: "error",
                  duration: 5000,
                });
              } else {
                this.$message({
                  message: "Произошла ошибка, попробуйте позже",
                  type: "error",
                  duration: 5000,
                });
              }
            })
            .catch(() => {
              this.$message({
                message: "Произошла ошибка, попробуйте позже",
                type: "error",
                duration: 5000,
              });
            });
        }
      });
    },
    registerUser() {
      this.$refs.formRegister.validate((valid) => {
        if (valid) {
          AJAX_POST("/api/register", this.formRegister)
            .then((response) => {
              if (!response.data.success) {
                this.$message({
                  message: `${response.data.email}`,
                  type: "error",
                  duration: 5000,
                });
              }
              if (response.data.success) {
                this.setUser(response.data.me);
                this.setMenuCounts({
                  services: response.data.me.resources_count,
                  balance: response.data.me.balance,
                  reserves: response.data.me.orders_count,
                  mail: response.data.me.sms,
                  news: response.data.me.counters.news,
                  support: response.data.me.counters.tickets_count,
                });
                this.setServiceList(response.data.me.resources);
                this.setAddLinksCounts({
                  support: response.data.me.counters.tickets_new,
                });

                this.$router.push({ name: "dashboard" });
                // this.$message({
                //   message: `${response.data.msg}`,
                //   type: "success",
                //   duration: 5000,
                // });
                this.setNotif({
                  title: `${response.data.msg}`,
                  type: "success",
                });
                this.$refs.formRegister.resetFields();
                this.activeNames = "login";
              }
            })
            .catch(() => {
              this.$message({
                message: "Произошла ошибка, попробуйте позже",
                type: "error",
                duration: 5000,
              });
            });
        } else {
          return false;
        }
      });
    },
    loginUserRequest(payload) {
      AJAX_POST("/api/login", payload || this.formLogin)
        .then((response) => {
          if (!response.data.success) {
            this.$message.error({
              message: response.data.msg,
              type: "error",
              duration: 5000,
            });
          }
          if (response.data.success) {
            this.setUser(response.data.me);
            this.setMenuCounts({
              services: response.data.me.resources_count,
              balance: response.data.me.balance,
              reserves: response.data.me.orders_count,
              mail: response.data.me.sms,
              news: response.data.me.counters.news,
              support: response.data.me.counters.tickets_count,
            });
            this.setServiceList(response.data.me.resources);
            this.setAddLinksCounts({
              support: response.data.me.counters.tickets_new,
            });

            this.$router.push({ name: "dashboard" });
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.msg);
        });
    },
    loginUser() {
      this.$refs.formLogin.validate((valid) => {
        if (valid) {
          this.loginUserRequest();
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.custom__area {
  background-color: rgba(255, 255, 255, 0.1);
}

.hiddenHeader ::v-deep .el-tabs__header {
  display: none;
}
.ask-question {
  max-width: 500px;
}

#app {
  @screen lg {
    height: 100%;
  }
}

.login-slider {
  width: 63%;

  &-content {
    max-width: 550px;
    bottom: 180px;
  }

  .el-carousel__button {
    opacity: 0.35;
    outline: none;
  }
}

::v-deep .el-carousel__indicators--horizontal {
  transform: translateX(0px);
  left: 100px;
  top: 8%;
  .is-active {
    .el-carousel__button {
      width: 20px;
      border-radius: 5px;
    }
  }
}

.login-form {
  &__form {
    max-width: 330px;
  }
  &__wrapper {
    width: 37%;

    @screen lg {
      width: 100%;
    }
  }
}
::v-deep .el-carousel__arrow {
  position: absolute;
  left: 100px;
  bottom: 90px;
  top: auto;
  border-radius: 3px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  transform: translate(0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  &--right {
    left: 120px;
    transform: translate(100%, 0);
  }
  i {
    font-size: 14px;
    font-weight: 600;
  }
}
.link {
  color: $--color-white;
  border-radius: 3px;
  padding: 10px;
  margin-right: 10px;
  width: fit-content;
  cursor: pointer;
  &-whatsapp {
    background: #128c7e;
  }
  &-telegram {
    background: #0088cc;
  }
  &-vk_messenger {
    background: #45668e;
  }
  &-viber {
    background: #8f5db7;
  }
  &-facebook_messenger {
    background: #2196f3;
  }
  &-instagram {
    background: #e1306c;
  }
  &-vk {
    background: #45668e;
  }
  &-facebook {
    background: #3b5998;
  }
  &-odnoklassniki {
    background: #ed812b;
  }
  &-twitter {
    background: #1da1f2;
  }
  &-google {
    background: #ea4335;
  }
  &-yandex {
    background: #fc3f1d;
  }
}
</style>
