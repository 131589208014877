<template>
  <TemplateBlock
    title="Шаг 2 — Настройка расписания"
    @back="$emit('input', value - 1)"
    @next="$emit('input', value + 1)"
  >
    <el-radio
      :value="widget.typeSingle"
      label="grid"
      @input="setWidget({ field: 'typeSingle', value: $event })"
    >
      Сетка с временными сеансами
    </el-radio>
    <switch-custom
      v-show="widget.typeSingle === 'grid'"
      :value="widget.isShowPrice"
      title="Отображать цену под сеансом"
      @input="setWidget({ field: 'isShowPrice', value: $event })"
    />
    <el-radio
      :value="widget.typeSingle"
      label="form"
      @input="setWidget({ field: 'typeSingle', value: $event })"
    >
      Форма бронирования с календарем
    </el-radio>
    <switch-custom
      v-show="widget.typeSingle === 'form'"
      :value="widget.showCalendarOpen"
      title="Показывать календарь развернуто"
      @input="setWidget({ field: 'showCalendarOpen', value: $event })"
    />
  </TemplateBlock>
</template>

<script>
import TemplateBlock from "@/views/Widgets/components/TemplateBlock.vue";
import { mapState, mapMutations } from "vuex";

export default {
  name: "ScheduleOneService",
  components: { TemplateBlock },
  props: {
    value: {
      type: Number,
    },
  },
  data() {
    return {
      param: "",
    };
  },
  computed: {
    ...mapState("widget", ["widget"]),
  },

  created() {},
  methods: {
    ...mapMutations("widget", ["setWidget"]),
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

::v-deep {
  .el-radio {
    margin-bottom: 17px;
  }
  .wrapper-switch {
    margin-bottom: 17px;
    margin-left: 15px;
  }
  .el-radio__inner {
    border: 2px solid rgba(255, 255, 255, 0.5);
  }
}
</style>
