<template>
  <div>
    <div>
      <spinner v-if="isLoading" class="table__spinner spin__wrapp" />
    </div>
    <Modal
      class="modal"
      :show="show"
      title="Оформление возврата"
      @close="$emit('close')"
    >
      <el-input
        v-model="cashValue"
        max="100"
        type="number"
        placeholder="Введите сумму"
      >
        <template slot="append"
          ><span class="text-white-500 pr-15"
            >{{ reserve ? reserve.currency : currency }}
          </span></template
        >
      </el-input>
      <!-- СДЕЛАТЬ НОРМАЛЬНЫЙ ПОДСЧЕТ -->
      <div v-if="showComponent" class="hl"></div>
      <component
        :is="isTypeComponent.component"
        v-if="showComponent"
        :item="refundData"
        :count="isParamCount.text"
        :is-disabled="isDisabled"
        :currency="currency || reserve.currency"
        @setItemCount="setCount"
        @setItemPlace="setPlace"
        @setTicketVal="setTicket"
      ></component>

      <div v-if="showComponent" class="hl"></div>
      <!-- Пока без реализации -->

      <!-- <div
        v-if="
          isAdditionalBookingInput.length || isAdditionalBookingCheck.length
        "
        class=""
      >
        <span class="return__text">Дополнительные опции</span>

        <el-checkbox-group v-model="addOptCheck" class="pt-5">
          <el-checkbox
            v-for="(i, idx) in isAdditionalBookingCheck"
            :key="`addOptCheck-${idx}`"
            :label="i.amount"
          >
            <div class="input-number__info">
              <span class="custom-check__title">{{ i.name }}</span>
              <span class="custom-check__subtitle">
                {{ i.price }} {{ i.currency }}
              </span>
            </div>
          </el-checkbox>
        </el-checkbox-group>

        <div
          v-for="(opt, i) in isAdditionalBookingInput"
          :key="`input-${i}`"
          class="input-number my-10"
          :label="1"
        >
          <div class="input-number__info">
            <span class="input-number__info-title">{{ opt.name }}</span>
            <span class="input-number__info-subtitle">
              {{ opt.price }} {{ reserve ? reserve.currency : currency }}
            </span>
          </div>
          <el-input-number
            v-model="opt.selected"
            class="input-number__input"
            :disabled="isDisabled"
            :max="opt.selected"
            :min="0"
            @input="correctPrice"
          />
        </div>
        <div class="hl"></div>
      </div> -->

      <!-- Пока без реализации -->
      <!-- <div class="mt-20">
        <el-checkbox v-model="check">Включая комиссию эквайринга</el-checkbox>
      </div> -->

      <el-alert
        v-if="(cashValue || itemTicket) > refundData.paid_amount"
        center
        :title="`Сумма возврата не может быть более ${refundData.paid_amount}`"
        type="warning"
        show-icon
        :closable="false"
        class="mt-15"
      /><el-alert
        else
        center
        :title="`Будет возвращено ${cashValue}`"
        type="success"
        show-icon
        :closable="false"
        class="mt-15 w-full"
      />
      <div class="flex justify-between mt-30">
        <el-button @click="$emit('close')"> Отмена </el-button>
        <el-button
          type="primary"
          :disabled="(cashValue || itemTicket) > refundData.total_price"
          @click="refoundCash"
          >Вернуть
        </el-button>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Modal from "@/components/Modal";
import IndNotNumbrSeat from "@/components/Orders/Refound/IndNotNumbrSeat";
import RentDepOnlTime from "@/components/Orders/Refound/RentDepOnlTime";
import RentDepSetTime from "@/components/Orders/Refound/RentDepSetTime";
import IndOnNumSet from "@/components/Orders/Refound/IndOnNumSet";
import IndService from "@/components/Orders/Refound/IndService";
import GroupSeatLimit from "@/components/Orders/Refound/GroupSeatLimit";

export default {
  name: "ReturnCash",
  components: {
    Modal,
    IndNotNumbrSeat,
    RentDepOnlTime,
    RentDepSetTime,
    IndOnNumSet,
    IndService,
    GroupSeatLimit,
  },
  props: {
    reserve: {
      type: [Object, Array],
    },
    hash: {
      type: Object,
    },
    currency: String,
    show: Boolean,
  },
  data() {
    return {
      showComponent: false,
      typeBooking: null,
      cashValue: null,
      itemCount: null,
      itemPlace: null,
      itemTicket: null,
      check: false,
      item: null,
      addOptInput: [],
      addOptCheck: [],
      listAddOptCheck: [],
      load: false,
    };
  },
  computed: {
    ...mapState("paymentHistory", ["refundData", "isLoading"]),
    ...mapState("service", ["schedule"]),

    isDisabled() {
      return this.refundData?.type === "prepayment";
    },

    isAdditionalBooking() {
      return (
        this.refundData.add_options_input || this.refundData.additional_options
      );
    },
    isAdditionalBookingCheck() {
      return this.refundData.add_options_check;
    },
    isAdditionalBookingInput() {
      return this.refundData.add_options_input;
    },

    isHashRefound() {
      if (this.reserve) {
        return this.reserve.payment.find((payment) => payment.hash);
      }
      return this.hash;
    },
    isParamCount() {
      switch (this.refundData.rent?.unit) {
        case "days":
          return {
            text: "дней",
          };
        case "hour":
          return {
            text: "часов",
          };
        case "hours":
          return {
            text: "часов",
          };
        case "minutes":
          return {
            text: "минут",
          };
        case "minute":
          return {
            text: "минут",
          };
        default:
          return "";
      }
    },
    isTypeComponent() {
      switch (this.refundData.booking_type_sub) {
        case "individual_does_not_depend_on_the_number_of_seats":
          return {
            component: IndNotNumbrSeat,
          };
        case "rent_depends_only_on_time":
          return {
            component: RentDepOnlTime,
          };
        case "rent_depends_on_time_and_place":
          return {
            component: RentDepSetTime,
          };
        case "individual_depends_on_the_number_of_seats":
          return {
            component: IndOnNumSet,
          };
        case "individual_depends_on_the_service_and_its_duration":
          return {
            component: IndService,
          };
        case "group_same_seat_limit_for_all_sessions":
          return {
            component: GroupSeatLimit,
          };
        case "group_the_seat_limit_is_set_for_each_session":
          return {
            component: GroupSeatLimit,
          };

        default:
          return "";
      }
    },

    totalCash() {
      let total = this.itemCount; // * this.refundData.price;
      // const sumCheck = this.listAddOptCheck.reduce((sum, current) => {
      //   return sum + current.price;
      // });
      const paidSum = this.refundData.paid_amount;
      const sumInput = this.isAdditionalBookingInput.reduce((sum, current) => {
        return sum + current.selected * current.price;
      }, 0);
      if (
        this.refundData.booking_type_sub === "rent_depends_on_time_and_place"
      ) {
        total =
          (this.refundData.rent.price - this.refundData.rent.discount || 0) *
          (this.itemCount * this.itemPlace);
      }
      if (
        this.refundData.booking_type_sub ===
        "individual_does_not_depend_on_the_number_of_seats"
      ) {
        total = this.refundData.individual.price;
      }
      if (
        this.refundData.booking_type_sub ===
        "individual_depends_on_the_number_of_seats"
      ) {
        total = this.itemCount * this.refundData.individual.price;
      }

      if (
        this.refundData.booking_type_sub ===
        "group_same_seat_limit_for_all_sessions"
      ) {
        total = this.itemTicket * this.refundData.price;
      }
      if (
        this.refundData.booking_type_sub ===
        "group_the_seat_limit_is_set_for_each_session"
      ) {
        const arr = [];
        this.refundData.ticket.map((i) => {
          return arr.push(i.price * i.select_ticket);
        });

        total = arr.reduce((previousValue, currentValue) => {
          return previousValue + currentValue;
        });
        // this.itemTicket * this.refundData.price;
      }
      return paidSum - (sumInput + total);
    },
  },
  watch: {
    cashValue() {
      if (
        this.cashValue &&
        this.cashValue > this.reserve &&
        this.reserve.payed_summ
      ) {
        this.cashValue = this.reserve.payed_summ;
      }
    },
  },

  async created() {
    this.typeBooking = IndNotNumbrSeat;
    await this.getRefundData(this.isHashRefound.hash);
    this.cashValue = this.refundData.paid_amount;
    this.itemCount = this.refundData?.rent?.duration;
    this.itemTicket = this.refundData?.ticket
      ? this.refundData?.ticket[0].select_ticket
      : "";
    this.addOptInput = this.isAdditionalBookingInput;
    this.listAddOptCheck = this.isAdditionalBookingCheck;
    this.addOptCheck = !!this.isAdditionalBookingCheck;
  },
  mounted() {
    // this.setReserveInfo();
  },
  methods: {
    ...mapActions("paymentHistory", ["getRefundData", "getRefund"]),
    // setReserveInfo() {
    //   console.log(this.isHashRefound, "ReturnCash");
    // },
    async refoundCash() {
      await this.getRefund({
        hash: this.isHashRefound.hash,
        amount: Number(this.cashValue),
      });
      this.$emit("setReload");
      this.$emit("close");
    },
    correctPrice() {
      this.cashValue = this.totalCash;
    },
    setCount(item) {
      this.itemCount = item;
      this.correctPrice();
    },
    setTicket(item) {
      this.itemTicket = item;
      this.correctPrice();
    },
    setPlace(item) {
      this.itemPlace = item.itemPlace;
      this.itemCount = item.itemCount;
      this.correctPrice();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";
::v-deep .el-dialog {
  width: 378px;
  padding-top: 10px;
}
.custom-block {
  line-height: 24px;
  @apply mb-0 py-8 px-15 text-white-normal text-14;
}
.return {
  &__text {
    color: #fff;
  }
}
.spin__wrapp {
  top: 47%;
  z-index: 9999;
}
</style>
