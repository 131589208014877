<template>
  <el-form hide-required-asterisk label-position="top">
    <h4 class="title">Настройки расписания</h4>
    <functional-calendar
      v-model="calendar"
      :configs.sync="calendarConfigs"
      class="mb-20"
    ></functional-calendar>
    <p class="hint">Выбранные даты</p>

    <div class="dates">
      <tag
        v-for="(date, i) in calendar.selectedDates"
        :key="i"
        close
        @closed="deleteTag(i)"
        >{{ date.date }}</tag
      >
    </div>
    <div class="hl"></div>
    <el-button type="primary" class="custom-button" @click="submit()">
      Сохранить
    </el-button>
  </el-form>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
import { listDuration } from "@/helpers/services.js";
import { FunctionalCalendar } from "vue-functional-calendar";
import Tag from "@/components/Tag";
import { getDate, getMonth, format } from "date-fns";
import { monthNames } from "@/helpers/date.js";

export default {
  name: "AddDates",
  components: { FunctionalCalendar, Tag },
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dateForConvert: [],
      calendar: {
        currentDate: new Date(),
        selectedDate: false,
        selectedDateTime: false,
        selectedHour: "00",
        selectedMinute: "00",
        selectedDatesItem: "",
        selectedDates: [],
        dateRange: { start: "", end: "" },
        multipleDateRange: [],
      },
      calendarConfigs: {
        dateFormat: "yyyy-mm-dd",
        disabledDates: ["beforeToday"],
        isMultiple: true,
        calendarsCount: 3,
        isMultipleDatePicker: true,
        dayNames: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
        monthNames: [
          "Январь",
          "Февраль",
          "Март",
          "Апрель",
          "Май",
          "Июнь",
          "Июль",
          "Август",
          "Сентябрь",
          "Октябрь",
          "Ноябрь",
          "Декабрь",
        ],
      },
    };
  },
  computed: {
    ...mapState("service", ["settings", "addDates"]),
    ...mapGetters("service", ["isHaveSettings"]),
  },
  created() {
    this.listDuration = listDuration;
    this.placeholder = "Введите количество";
    this.monthNames = monthNames;
  },
  mounted() {
    const dates = this.addDates[this.type].map((date) => {
      return {
        date: format(new Date(date.date), "yyyy-M-d"),
        dateName: date.date_name,
        dateTime: false,
        hours: "00",
        minute: "00",
      };
    });
    this.calendar.selectedDates = [...dates];
  },
  methods: {
    ...mapActions("service", ["saveAddDates", "getGenerator"]),
    ...mapMutations("service", ["setSettings"]),

    formattingDate(date) {
      // PROBLEM IOS FORMAT DATE
      // return `${getDate(new Date(obj.dateConvert))} ${this.monthNames[
      //   getMonth(new Date(obj.dateConvert))
      // ].title.toLowerCase()}`;

      return date;
    },
    deleteTag(i) {
      this.calendar.selectedDates = this.calendar.selectedDates.filter(
        (date, index) => i !== index
      );
    },
    submit() {
      const dates = this.calendar.selectedDates.map((date) => {
        return date.date; // format(new Date(date.date), "yyyy-M-dd");
      });
      this.saveAddDates({ type: this.type, dates });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";
.dates {
  max-height: 500px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
}
.hint {
  margin-bottom: 12px;
}
::v-deep .vfc {
  &-content {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin: 0 10px 10px;
  }
  &-main-container {
    background-color: transparent;
    box-shadow: none;
    border-radius: none;
    border: none;
  }
  &-top-date span {
    color: $--color-white;
  }
  &-arrow-right,
  &-arrow-left {
    border-color: $--color-white !important;
  }
  &-dayNames {
    padding-bottom: 10px;
    border-bottom: 1px solid $--color-sm-white;
    .vfc-day {
      color: $--color-white-5;
    }
  }
  &-calendar {
    border: 1px solid $--color-sm-white;
    &:nth-last-child(1) {
      border-radius: 0 3px 3px 0;
    }
    &:nth-last-child(2) {
      border-left: none;
      border-right: none;
    }
    &:nth-last-child(3) {
      border-radius: 3px 0 0 3px;
    }
  }
  &-marked {
    background: #3498db !important;
  }
  &-week .vfc-day {
    cursor: pointer;
    &:last-child {
      color: $--color-white;
    }
  }
  &-hide {
    color: $--color-white-5 !important;
  }
}
</style>
