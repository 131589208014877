<template>
  <div slot="reference" class="">
    <el-popover
      class="dots my-8"
      placement="bottom-start"
      popper-class="control-poper"
      trigger="click"
    >
      <div class="block">
        <h2 class="block__text">Оплата на месте</h2>
        <el-input v-model="prePayValue" placeholder="" type="number">
        </el-input>
        <el-button slot="reference" class="block__btn" @click="addPrePay"
          >Сохранить
        </el-button>
      </div>
      <div slot="reference" class="popover__wrapp flex">
        <div class="slot__wrapp">
          <span
            :class="[
              row.payment ? 'small_text' : 'text-12 table_section__label',
            ]"
            >На месте
          </span>
        </div>
        <div
          v-if="row.payment"
          :class="[
            row.paid_on_place_summ === 0
              ? 'small_text'
              : 'text-12 table_section__label',
          ]"
        >
          <div class="flex items-center">
            <span v-if="payData.type === 'payment_locally'">
              <span
                class="dashed"
                :class="{
                  'text-gray-500 text-14': payData.type === 'payment_locally',
                }"
              >
                {{ row.payment ? payData.paid_on_place : row.full_amount }}
                {{ row.currency }}</span
              ></span
            >
            <span v-if="payData.type === 'prepayment'" class="mr-10">
              <span
                class="dashed"
                :class="{
                  'text-gray-500 text-14': payData.type === 'prepayment',
                }"
                >{{
                  prePay > 0 ? prePay : payData.full_amount - payData.prepayment
                }}
                {{ row.currency }}</span
              ></span
            >
            <span
              v-if="payData.type === 'full_payment'"
              :class="[prePay === 0 ? 'small_text' : 'text-gray-500 text-14']"
              ><span class="dashed pr-5">{{ prePay }} {{ row.currency }}</span>
            </span>
          </div>
          <!-- <div v-for="(p, idx) in row.payment_data" :key="idx" class="flex items-center">
            <span v-if="p.type === 'payment_locally'" class="">
              <span
                class="dashed"
                :class="{
                  'text-gray-500 text-14': p.type === 'payment_locally',
                }"
              >
                {{ row.payment ? p.paid_on_place : row.full_amount }}
                {{ row.currency }}</span
              ></span
            >
            <span v-if="p.type === 'prepayment'" class="mr-10">
              <span
                class="dashed"
                :class="{ 'text-gray-500 text-14': p.type === 'prepayment' }"
                >{{ prePay > 0 ? prePay : p.full_amount - p.prepayment }}
                {{ row.currency }}</span
              ></span
            >
            <span
              v-if="p.type === 'full_payment'"
              :class="[prePay === 0 ? 'small_text' : 'text-gray-500 text-14']"
              ><span class="dashed pr-5">{{ prePay }} {{ row.currency }}</span>
            </span>

            <div
              v-if="prePay !== 0"
              slot="reference"
              class="tooltip-btn rounded-full w-18 h-18 bg-green-500 flex items-center justify-center ml-10"
            >
              <svg-icon
                icon-name="success"
                class-name="text-white-normal"
                width="10"
                height="10"
              ></svg-icon>
            </div>
          </div> -->
        </div>
        <div class="">
          <span v-if="row.type === 'payment_locally'" class="">
            <span
              class="dashed"
              :class="{
                'text-gray-500 text-14': row.type === 'payment_locally',
              }"
            >
              {{ row.paid_on_place ? row.paid_on_place : row.full_amount }}
              {{ row.currency }}</span
            ></span
          >
          <span
            v-if="row.type === 'full_payment'"
            :class="[prePay === 0 ? 'small_text' : 'text-gray-500']"
            ><span class="dashed">{{ prePay }} {{ row.currency }} </span> </span
          ><span> </span>
        </div>
        <div class="">
          <span v-if="row.type !== 'payment_locally'" class="">
            <span
              class="dashed"
              :class="{
                'text-gray-500 text-14': row.type === 'payment_locally',
              }"
            >
              {{ row.paid_on_place >= 0 ? row.paid_on_place : row.full_amount }}
            </span></span
          >
        </div>
      </div>
      <div v-if="row.payment" slot="reference" class="popover__wrapp">
        <div class="slot__wrapp flex">
          <div v-if="paym.payment_info.refunds" class="flex">
            <span class="small_text">Возврат</span>
            <span class="text-yellow-100"
              >{{ refundVal }} {{ row.currency }}</span
            >
          </div>
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "PrePayPopup",
  props: {
    row: {
      type: Object,
    },
    payData: {
      type: Object,
    },
    paym: {
      type: Object,
    },
    payment: {
      type: Object,
    },
    idxParam: {
      type: Number,
    },
  },
  data() {
    return {
      prePayValue: "",
      prePay: 0,
      hashPay: null,
      refundVal: 0,
    };
  },
  watch: {
    payData: {
      handler() {
        this.setRefundValue();
      },
      deep: true,
    },
  },
  created() {
    // console.log(this.refundVal, this.payData);

    this.hashPay = this.row.hash || this.row.payment[0]?.hash;
    // this.prePay = this.row.paid_on_place || this.row.payment[0]?.paid_on_place;
    this.prePay = this.row.paid_on_place || this.payData.paid_on_place;
    this.prePayValue = this.prePay;
    // if (this.row.payment[0].payment_info.refunds) {
    // if (this.paym.payment_info.refunds) {
    //   const arr = this.paym.payment_info.refunds;
    //   this.refundVal =
    //     // arr.reduce((acc, obj) => {
    //     //   return acc + obj.amount;
    //     //   // return a.amount + b.amount;
    //     // }, 0) ??
    //     this.payData.refunds_sum;
    //   console.log(this.refundVal);
    // }

    if (this.paym) {
      this.setRefundValue();
    }
  },
  methods: {
    ...mapActions("paymentHistory", ["getPaymentOnPlace"]),

    setRefundValue() {
      if (this.paym.payment_info.refunds) {
        const arr = this.paym.payment_info.refunds;
        this.refundVal =
          arr.reduce((acc, obj) => {
            return acc + obj.amount;
            // return a.amount + b.amount;
          }, 0) ?? this.payData.refunds_sum;
      }
    },
    async addPrePay() {
      // this.$emit("isLoading", true);
      this.prePay = this.prePayValue;
      await this.getPaymentOnPlace({
        hash: this.hashPay,
        value: Number(this.prePay),
      });

      this.$emit("setPrePay", {});
    },
  },
};
</script>

<style lang="scss" scoped>
.small_text {
  width: 120px;
  font-size: 14px;
  line-height: 24px;
  margin-right: 5px;
  &.w-auto {
    width: auto !important;
  }
}

.block {
  border-radius: 3px;
  padding: 16px;
  background: #000;
  &__btn {
    margin-top: 15px;
    height: 30px;
    border: 1px solid rgba(255, 190, 61, 1);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    min-width: 218px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__text {
    font-size: 14px;
    line-height: 24px;
    color: white;
    margin-bottom: 8px;
  }
}
</style>
