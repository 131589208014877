<template>
  <div class="pt-40 pb-80 w-full lg:pt-15 px-10">
    <!-- button-text="Добавить заказ" удалил -->
    <Top
      title="Заказы"
      :font="{ size: 22, leading: 22 }"
      @onClick="showModal = true"
    />
    <el-collapse class="border-0 mb-40 lg:mb-20">
      <el-collapse-item
        v-if="!$mq.tablet"
        title="Filter"
        name="filter"
        class="mb-20"
      >
        <div
          slot="title"
          class="collapse-title pl-30 font-semibold text-18 flex items-center"
        >
          <svg-icon
            icon-name="chevron"
            width="12"
            height="12"
            class-name="mr-10 text-white-normal"
          ></svg-icon>
          Фильтрация
        </div>
        <div class="collapse-content pb-30 font-medium">
          <home-filter
            @getStatPar="statParams"
            @resetFilter="resetFilter"
          ></home-filter>
        </div>
      </el-collapse-item>

      <el-collapse-item title="Statistic" name="stat">
        <div
          slot="title"
          class="collapse-title pl-30 font-semibold text-18 flex items-center lg:text-white-normal lg:pl-15"
        >
          <svg-icon
            icon-name="chevron"
            width="12"
            height="12"
            class-name="mr-10 text-white-normal"
          ></svg-icon>
          Статистика
        </div>
        <Statistic :stats="stats" />
      </el-collapse-item>
    </el-collapse>
    <div class="">
      <el-button
        v-if="$mq.tablet"
        type="info"
        class="w-full mb-25"
        @click="mobileFiter = true"
      >
        <span class="flex justify-center items-center">
          <svg-icon
            icon-name="filter"
            class-name="text-white-normal mr-10"
            width="14"
            height="14"
          ></svg-icon>
          Фильтрация бронирований
        </span>
      </el-button>
    </div>

    <el-drawer
      :show-close="false"
      :visible.sync="mobileFiter"
      direction="ltr"
      size="100%"
    >
      <div slot="title">
        <div class="bg-violet-800 px-25 py-15">
          <div class="flex items-center justify-between">
            <div class="font-semibold text-18 leading-30 text-white-normal">
              Фильтрация
            </div>
            <div @click="mobileFiter = false">
              <svg-icon
                icon-name="close"
                width="18"
                height="18"
                class-name="text-white-normal"
              ></svg-icon>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gradient-b-violet p-15 h-auto min-h-full xs:p-0">
        <home-filter @onAcceptFilter="mobileFiter = false"></home-filter>
      </div>
    </el-drawer>
    <TableSettings
      v-if="showModals.settings"
      :show="showModals.settings"
      :columns="columns"
      @close="hiddenModal('settings')"
      @update="saveTableSettings"
    />

    <UTM
      v-if="showModals.utm"
      :show="showModals.utm"
      :data="modalData"
      @close="hiddenModal('utm')"
    />

    <ClientInfo
      v-if="showModals.clientInfo"
      :show="showModals.clientInfo"
      @close="hiddenModal('clientInfo')"
    />

    <SendSms
      v-if="showModals.sendSms"
      :show="showModals.sendSms"
      :params="modalData"
      @close="hiddenModal('sendSms')"
    />
    <SendEmail
      v-if="showModals.sendEmail"
      :show="showModals.sendEmail"
      :params="modalData"
      @close="hiddenModal('sendEmail')"
    />
    <Comments
      v-if="modalData && showModals.comments"
      :show="modalData && showModals.comments"
      :reserve="modalData"
      @close="hiddenModal('comments')"
    />
    <OrderList
      v-if="modalData && showModals.orderList"
      :reserve="modalData"
      :s-show="modalData && showModals.orderList"
      @close="hiddenModal('orderList')"
    />

    <ReturnCash
      v-if="modalData && showModals.returnCash"
      :show="modalData && showModals.returnCash"
      :reserve="modalData"
      @close="hiddenModal('returnCash')"
      @setReload="isReload"
    />

    <app-reserves
      :columns="columns"
      :rel="rel"
      :item-list="options"
      :reset-filter-search="reset"
      @changeStatusFilter="resetFilter"
      @show="showModal"
      @getStatParams="statParams"
    ></app-reserves>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import HomeFilter from "@/components/HomeFilter";
import AppReserves from "@/components/AppReserves";
import TableSettings from "@/components/Orders/TableSettings";
import UTM from "@/components/Orders/UTM";
import ClientInfo from "@/components/Orders/ClientInfo";
import SendSms from "@/components/SendModals/SendSms";
import SendEmail from "@/components/SendModals/SendEmail";
import Comments from "@/components/Orders/Comments";
import Statistic from "@/components/Orders/Statistic";
import Top from "@/components/Pages/Top";
import OrderList from "@/components/Orders/ClientInfo/OrderList";
import ReturnCash from "@/components/Orders/ReturnCash";

export default {
  name: "Orders",
  components: {
    AppReserves,
    HomeFilter,
    TableSettings,
    UTM,
    ClientInfo,
    SendSms,
    SendEmail,
    Comments,
    Statistic,
    Top,
    OrderList,
    ReturnCash,
  },
  data() {
    return {
      rel: null,
      mobileFiter: false,
      modalData: null,
      reset: null,
      showModals: {
        settings: false,
        clientInfo: false,
        sendSms: false,
        sendEmail: false,
        utm: false,
        comments: false,
        orderList: false,
        returnCash: false,
      },
      columns: [1, 2, 3, 4, 5, 6],
      options: [
        { id: 1, title: "№ и дата" },
        { id: 2, title: "Клиент" },
        { id: 3, title: "Бронирование" },
        { id: 4, title: "Статус" },
        { id: 5, title: "Оплата" },
        { id: 6, title: "Источник заказа" },
      ],
    };
  },
  computed: {
    ...mapState("orders", ["stats"]),
  },
  created() {
    this.getStat();
    this.setTableSettings();
  },
  methods: {
    ...mapActions("orders", ["getStat"]),
    resetFilter(param) {
      this.reset = param;
    },
    show(el) {
      console.log("show", el);
    },
    showModal(item) {
      console.log(item);
      if (typeof item === "object") {
        this.modalData = item.data;
        this.showModals[item.key] = true;
      } else this.showModals[item] = true;
    },
    hiddenModal(key) {
      this.showModals[key] = false;
      if (this.modalData) this.modalData = false;
    },

    setTableSettings() {
      const settings = localStorage.getItem("reservesTable");
      if (settings) this.columns = JSON.parse(settings);
    },
    saveTableSettings(items) {
      this.columns = items;
      localStorage.setItem("reservesTable", JSON.stringify(items));
      this.$forceUpdate();

      this.$nextTick(() => {
        this.columns = items;
      });

      // this.closeSettingsModal();
      this.hiddenModal("settings");
    },
    statParams() {
      this.getStat();
    },
    isReload() {
      this.rel = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

::v-deep .el-collapse-item__header {
  color: $--color-white;
  .is_active {
    color: $--color-primary;
  }
}
</style>
<style lang="scss">
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}

.el-aside {
  color: #333;
}

.stat-item-count {
  @screen lg {
    display: grid;
    grid-template-columns: 140px 1fr;
    grid-column-gap: 26px;
  }
}
</style>
