<template>
  <div>
    <el-row>
      <el-col :span="$mq.large ? 24 : 12">
        <h3 class="text-16 my-10 font-normal">Выберите услугу</h3>
        <div class="group-radio">
          <div v-for="type in typeService" :key="type.id" class="w-full mb-10">
            <el-checkbox
              v-model="type.isActive"
              :label="type.category_name"
              class="radio__style"
              @change="handleCheckAllChange(type.category_id)"
            />
            <el-radio-group
              v-if="type.isActive"
              v-model="type.selectedChild"
              class="ml-30"
            >
              <el-radio
                v-for="child in type.child_data"
                :key="child.id"
                v-model="type.isActive"
                :label="child.name"
                :disabled="!type.isActive"
                @change="handleCheckChange(child, type.category_id)"
              />
            </el-radio-group>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "ServicesTreeGiven",
  props: {
    selectCell: {
      type: Object,
      default: () => {},
    },
    settings: {
      type: Object,
      default: () => {},
    },
    itemList: {
      type: Object,
    },
    hash: {
      type: Object,
    },
    dateParam: {
      type: String,
    },
    slotsValidity: { type: Object },
    customStyle: { type: Object },
    isThemeWidget: { type: String },
  },
  data() {
    return {
      checkType: [],
      checkSubType: [],
      typeService: [],
      discount: null,
      prices: null,
      service: [],
      slotParam: null,
    };
  },
  computed: {
    ...mapState("widget", ["remainedTickets", "slotDetails"]),
    ...mapState("user", ["fixedService"]),
  },
  created() {
    const key = Object.keys(this.slotDetails.slots);
    this.slotParam = this.slotDetails.slots[key].find(
      (el) => el.time === this.itemList.item.time
    );
    this.typeService = this.settings.data.service.map((e) => ({
      ...e,
      isActive: false,
      selectedChild: [], // e.child_data.map((child) => child.name),
    }));
    this.handleCheckAllChange();
  },
  methods: {
    ...mapActions("widget", ["saveBooking", "getRemainedTickets"]),
    handleCheckAllChange(id) {
      const service = this.typeService.find((serv) => serv.category_id === id);
      if (!service) return;
      this.typeService = this.typeService.map((e) => {
        if (e.category_id === id) {
          return {
            ...e,
            isActive: true,
            selectedChild: e.child_data.find((el) => el.id).name,
            child_data_check: e.child_data.find((el) => el.id),
          };
        }
        return {
          ...e,
          isActive: false,
          child_data_check: [],
          selectedChild: [],
        };
      });
      this.handleCheckChange();
    },

    handleCheckChange(obj, id) {
      const checkParam = this.typeService
        .filter((e) => e.isActive === true)
        .map((q) => {
          //  if (q.category_id === id) {
          return {
            ...q,
            child_data_check: id ? obj : q.child_data_check,
          };
          //  }
          // return q;
        });

      // this.$emit("setSlotParam", {
      //   book_info: {
      //     service: {
      //       ...checkParam[0],
      //       name: checkParam.category_name,
      //       id: checkParam.category_id,
      //       price: 0,
      //       duration: this.slotDetails.booking_type.data.service_duration,
      //       unit: this.slotDetails.booking_type.data.time_unit,
      //     },
      //   },
      //   resourceHash: this.hash,
      //   date: this.dateParam,
      //   // time: this.itemList.item.time,
      //   schedule_id: this.slotParam.schedule_id,
      //   bookingHash: this.itemList.item.booking_hash,
      // });
      this.$emit("setServices", {
        service: {
          // ...checkParam[0],
          name: checkParam[0].child_data_check.name,
          id: checkParam[0].child_data_check.id,
          price: 0,
          duration: this.slotDetails.booking_type.data.service_duration,
          unit: this.slotDetails.booking_type.data.time_unit,
        },

        resourceHash: this.hash,
        // date: this.dateParam,
        //  time: this.itemList.item.time,
        schedule_id: this.slotParam.schedule_id,
        bookingHash: this.itemList.item.booking_hash,
      });
    },
  },
};
</script>

<style></style>
