<template>
  <div>
    <spinner v-if="loading" />
    <Promo
      v-show="!loading"
      ref="template"
      type="promocode"
      :elements="promocodeItems"
    >
      <template #top>
        <Top
          create-button-text="Создать промокод"
          :switch-position="switchPosition"
          :active-tab="activeTab"
          :export-link="[
            {
              link: '/api/promocode/export/excel',
              name: 'Экспорт в Excel',
            },
          ]"
          @change="isPromoActive"
          @create="openCreateModal"
        />
      </template>
    </Promo>
  </div>
</template>

<script>
import Promo from "@/components/Promo/Template.vue";
import Top from "@/components/Promo/Top.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    Promo,
    Top,
  },
  props: { activeTab: String },

  data() {
    return {
      // useSwitch: false,
      loading: true,
      switchPosition: null,
    };
  },
  computed: {
    ...mapState("promo", ["promocodeItems", "discountItemsCount"]),
    ...mapState("user", ["user"]),
  },
  created() {
    this.switchPosition = {
      promoActive: this.user.promocodes_disabled,
      discountActive: this.user.discounts_disabled,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    ...mapActions("promo", ["getItems", "changeStatus", "promoDiscountAll"]),
    async getData() {
      await this.getItems("promocode");
      this.$refs.template.setData();
      this.$emit("setCount", {
        count: this.discountItemsCount?.promocodes,
        key: "promocode",
      });
      if (this.loading) this.loading = false;
    },
    onChange() {
      this.changeStatus("promocode");
    },
    openCreateModal() {
      this.$refs.template.showCreateModal();
    },
    isPromoActive(useSwitch) {
      this.promoDiscountAll({ key: "promodiscount", flag: useSwitch });
    },
  },
};
</script>
