<template>
  <!-- <div class="pt-40 pb-80 w-full max-w-lg settings lg:pt-15 lg:px-15"> -->
  <div class="pt-40 pb-80 w-full lg:pt-15 px-10">
    <!-- <h1
      class="font-semibold text-white-normal text-28 leading-44 lg:text-22 lg:leading22 mb-30 xs:mb-5"
    >
      История платежей
    </h1> -->
    <Top
      title=" История платежей"
      :font="{ size: 22, leading: 22 }"
      @onClick="showModal = true"
    />
    <Search />

    <PreTables
      :text="`Всего записей: ${pays_stats.total || ''}`"
      :start="pays_stats.from"
      :end="pays_stats.to"
    />
    <SearchBar input-placeholder="Поиск по счетам" @submit="createReq" />

    <div>
      <Table
        v-if="pays.length"
        table-name="На месте"
        :pays="pays"
        :current-page="page"
        :page-size="showParam"
        @newLinkVal="setData"
        @getReload="setData"
      />
    </div>
    <Pagination
      class="w-full"
      background
      hide-on-single-page
      :current-page="pagination.current"
      :pager-count="$mq.tablet ? 5 : 11"
      :page-size="showParam"
      layout="prev, pager, next"
      :total="pays_stats.total"
      @change="setPagin"
    />
    <!-- <NoData text="Оплаты не настроены" button="Настроить оплаты" /> -->
    <!-- <NoData text="Нет проведенных оплат" /> -->
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
import Search from "@/components/PaymentHistory/SearchPanel";
import SearchBar from "@/components/Table/SearchBar";
import PreTables from "@/components/Table/PreTables";
import Table from "@/components/PaymentHistory/Table";
import Pagination from "@/components/Table/Pagination";
import Top from "@/components/Pages/Top";
// import NoData from "@/components/NoData";

export default {
  name: "HistoryPayment",

  components: {
    Search,
    Top,
    SearchBar,
    PreTables,
    Table,
    Pagination,
    // NoData,
  },

  data() {
    return {
      showParam: 10,
      page: 1,
      search: "",

      pagination: {
        total: null,
        current: 1,
      },
    };
  },

  computed: {
    ...mapState("paymentHistory", ["pays", "pays_stats"]),
    isPaginPage() {
      return Math.ceil(this.pays_stats.total / this.showParam);
    },
    isTypePayLocal() {
      return this.pays.data.filter((e) => {
        if (e.type === "payment_locally") {
          return e.type;
        }

        return e;
      });
    },
  },

  created() {
    this.createReq({
      page: this.$route.query.page || 1,
      search: this.$route.query.text || "",
      perPage: 10,
      create_end: this.$route.query["filter[create_end]"] || "",
      create_start: this.$route.query["filter[create_start]"] || "",
      payment_end: this.$route.query["filter[payment_end]"] || "",
      payment_start: this.$route.query["filter[payment_start]"] || "",
      payment_status: this.$route.query["filter[payment_status]"] || "",
      payment_type: this.$route.query["filter[payment_type]"] || "",
    });
  },

  methods: {
    ...mapActions("paymentHistory", ["getPayments"]),
    ...mapMutations("paymentHistory", ["setQueryPays"]),

    async setData() {
      await this.getPayments();
    },

    async setPagin(val) {
      this.page = val;
      const obj = {
        "filter[paginate]": this.showParam,
        "filter[show]": this.search,
      };
      obj["filter[text]"] = this.search && JSON.stringify(this.search);

      this.setQueryPays({ pagenum: this.page, ...obj });
      this.setData();
    },

    async createReq(items) {
      const { perPage, search, filter } = items;
      // this.page = items;
      this.showParam = perPage;
      this.search = filter;
      const obj = {
        // "filter[paginate]": perPage,
        paginate: perPage,
        "filter[show]": filter,
      };
      // obj["filter[text]"] = search && JSON.stringify(search);
      obj["filter[text]"] = search;
      obj["filter[create_start]"] = items.create_start;
      obj["filter[create_end]"] = items.create_end;
      obj["filter[payment_end]"] = items.payment_end;
      obj["filter[payment_start]"] = items.payment_start;
      obj["filter[payment_status]"] = items.payment_status;
      obj["filter[payment_type]"] = items.payment_type;

      this.setQueryPays({ pagenum: this.page, ...obj });
      this.setData();
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .preTables {
  margin-bottom: 20px;
  margin-top: 10px;
}
.history-content {
  .el-table {
    .cell {
      font-size: 12px;
      line-height: 16px;
      border-radius: 3px;
    }

    td {
      vertical-align: middle;
    }
  }
}
.table_first {
  ::v-deep .table__name {
    margin-top: 20px;
  }
}
</style>
