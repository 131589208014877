<template>
  <div class="custom-block">
    <div class="title">{{ title }}</div>
    <div v-if="subTitle" class="hint-title__medium-white">{{ subTitle }}</div>
    <div class="hl hl-mt"></div>
    <slot></slot>
    <div class="hl"></div>
    <div class="actions">
      <ButtonIcon
        v-if="!isHiddenBack"
        class="button-back"
        width="10"
        icon-name="arrowLeft"
        text="Назад"
        @click="$emit('back')"
      />
      <div v-else></div>
      <el-button type="primary" class="custom-button" @click="$emit('next')">
        {{ buttonTextSave ? buttonTextSave : "Далее" }}
        <svg-icon icon-name="arrowRight" width="10" class="ml-12"></svg-icon>
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "TemplateBlock",
  props: {
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    isHiddenBack: Boolean,
    buttonTextSave: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      show: true,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.actions {
  display: flex;
  justify-content: space-between;
}
.custom-button ::v-deep span {
  display: flex;
  align-items: center;
}
.title {
  margin-bottom: 0;
}
.hl-mt {
  margin-top: 20px;
}
</style>
