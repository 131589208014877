<template>
  <!-- <div class="pt-40 pb-80 w-full max-w-lg balance lg:pt-15"> -->
  <div class="pt-40 pb-80 w-full lg:pt-15 px-10">
    <!-- <div class="lg:px-10"> -->
    <div class="flex justify-between">
      <!-- <h1
          class="font-semibold text-white-normal text-28 leading-44 lg:text-22 lg:leading22 mb-30 xs:mb-5"
        >
          Баланс
        </h1> -->
      <Top title="Баланс" :font="{ size: 22, leading: 22 }" />
      <div>
        <el-button type="primary" class="flex" @click="changeTariff()">
          <div class="flex">
            <svg-icon
              icon-name="plus"
              width="10"
              height="10"
              class="mr-12 mt-1"
            ></svg-icon>
            <span>Сменить тариф </span>
          </div>
        </el-button>
      </div>
    </div>

    <div class="flex justify-between wrapp-balance">
      <MinBalance />

      <!-- <div> -->
      <div class="wrapper__content my-3">
        <div class="custom-block notification justify-start max-w-full">
          <div class="flex flex-col mx-5 text-12">
            <span class="pb-3 tariff__text">Текущий тариф:</span>
            <span class="pb-3 tariff__text">Стоимость: </span>
            <span class="pb-3 tariff__text">Лимит: </span>
          </div>
          <div class="flex flex-col mx-5 text-12">
            <span class="pb-3"> {{ user.tariff.tariff_name }}</span>
            <span class="pb-3">{{ user.tariff.tariff_price }}</span>
            <span class="pb-3">{{ user.tariff.tariff_limit }} </span>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
    <!-- </div> -->

    <filling-balance />

    <div class="w-full">
      <PreTables
        text="История платежей"
        class="w-full"
        :value="historyPayTotal"
      />
      <div class="relative some__class" style="max-width: auto">
        <spinner v-if="isLoading" class="table__spinner" />

        <el-table
          :data="historyPay"
          class="max-w-full border-b-0 mb-20 w-full"
          :stripe="true"
        >
          <el-table-column type="index" label="№" sortable :width="62">
          </el-table-column>

          <el-table-column
            prop="date"
            sortable
            label="Дата создания"
            :width="$mq.tablet ? null : 175"
            :min-width="$mq.tablet ? 146 : null"
          >
            <div slot-scope="{ row }">
              {{ row.date.date }}
              <span class="small_text">{{ row.date.time }}</span>
            </div>
          </el-table-column>
          <el-table-column
            prop="sum"
            sortable
            label="Сумма"
            :width="$mq.tablet ? null : 170"
            :min-width="$mq.tablet ? 80 : null"
          >
            <template slot-scope="{ row }">
              {{ row.amount }} {{ row.currency }}
            </template>
          </el-table-column>
          <el-table-column
            prop="client"
            label="Пользователь"
            :width="$mq.tablet ? null : 170"
            :min-width="$mq.tablet ? 80 : null"
          >
            <template slot-scope="{ row }">
              {{ row.user_name }}
              <span class="small_text">{{ row.user_email }}</span>
            </template>
          </el-table-column>
          <el-table-column
            prop="sum"
            :label="$mq.tablet ? 'Тип' : 'Тип платежа'"
            :width="$mq.tablet ? null : 190"
            :min-width="$mq.tablet ? 80 : null"
          >
            <template slot-scope="{ row }">
              {{ row.type || row.type_price_name }}
              <div v-if="row.pdf_invoice_file_path" class="my-12 rounded-sm">
                <a
                  :href="`${row.pdf_invoice_file_path}`"
                  download="download"
                  class="p-10 text-12 bg-white-60 rounded-sm"
                  >Скачать счет в PDF
                </a>
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="status"
            label="Статус"
            :width="$mq.tablet ? null : 203"
            :min-width="$mq.tablet ? 102 : null"
          >
            <template slot-scope="{ row }">
              <div class="current_status">
                <span
                  class="icon_rounded"
                  :class="`icon_rounded_${getStatus(row.status_name).type}`"
                >
                  <svg-icon
                    :width="getStatus(row.status_name).width"
                    :height="getStatus(row.status_name).height"
                    :icon-name="getStatus(row.status_name).type"
                  ></svg-icon>
                </span>
                {{ row.status_name }}
              </div>
              <div
                v-if="
                  row.invoice_finish_files_paths &&
                  row.invoice_finish_files_paths.length >= 1
                "
                class="my-12 rounded-sm"
              >
                <a
                  :href="`${row.invoice_finish_files_paths}`"
                  download="download"
                  class="p-10 text-12 bg-white-60 rounded-sm"
                  >Скачать документы
                </a>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="date"
            sortable
            label="Дата зачисления"
            :width="$mq.tablet ? null : 175"
            :min-width="$mq.tablet ? 146 : null"
          >
            <div v-if="row.payment_date" slot-scope="{ row }">
              {{ getDate(row.payment_date) }}
              <span class="small_text">{{
                row.payment_date ? row.date.time : ""
              }}</span>
            </div>
            <div v-else>—</div>
          </el-table-column>
        </el-table>
        <el-button
          v-if="historyPay.length > 0 && historyPayTotal !== historyPay.length"
          :loading="isLoading"
          class="bg-transparent md:mx-15 mr-auto"
          @click="loadMore"
          >Показать ещё</el-button
        >
      </div>
    </div>
    <div class="w-full">
      <PreTables
        text="История списаний"
        class="w-full"
        :value="historyDebitTotal"
      />
      <div class="relative some__class" style="max-width: auto">
        <spinner v-if="isLoading" class="table__spinner" />

        <el-table
          :data="historyPayDebit"
          class="max-w-full border-b-0 mb-20 w-full"
          :stripe="true"
        >
          <el-table-column type="index" label="№" sortable :width="62">
          </el-table-column>

          <el-table-column
            prop="date"
            sortable
            label="Дата создания"
            :width="$mq.tablet ? null : 175"
            :min-width="$mq.tablet ? 146 : null"
          >
            <div slot-scope="{ row }">
              {{ row.date.date }}
              <span class="small_text">{{ row.date.time }}</span>
            </div>
          </el-table-column>
          <el-table-column
            prop="sum"
            sortable
            :label="$mq.tablet ? 'Баланс' : 'Баланс'"
            :width="$mq.tablet ? null : 170"
            :min-width="$mq.tablet ? 80 : null"
          >
            <template slot-scope="{ row }">
              {{ row.balans }} {{ row.currency }}
            </template>
          </el-table-column>
          <el-table-column
            prop="sum"
            :label="$mq.tablet ? 'Сумма' : 'Сумма списания'"
            :width="$mq.tablet ? null : 190"
            :min-width="$mq.tablet ? 80 : null"
          >
            <template slot-scope="{ row }">
              {{ row.sum }}
            </template>
          </el-table-column>

          <!-- <el-table-column
            prop="status"
            label="Статус"
            :width="$mq.tablet ? null : 203"
            :min-width="$mq.tablet ? 102 : null"
          >
            <template slot-scope="{ row }">
              <div class="current_status">
                <span
                  class="icon_rounded"
                  :class="`icon_rounded_${getStatus(row.status_name).type}`"
                >
                  <svg-icon
                    :width="getStatus(row.status_name).width"
                    :height="getStatus(row.status_name).height"
                    :icon-name="getStatus(row.status_name).type"
                  ></svg-icon>
                </span>
                {{ row.status_name }}
              </div>
            </template>
          </el-table-column> -->
          <!-- <el-table-column
            prop="date"
            sortable
            label="Дата зачисления"
            :width="$mq.tablet ? null : 175"
            :min-width="$mq.tablet ? 146 : null"
          >
            <div v-if="row.payment_date" slot-scope="{ row }">
              {{ getDate(row.payment_date) }}
              <span class="small_text">{{
                row.payment_date ? row.date.time : ""
              }}</span>
            </div>
            <div v-else>—</div>
          </el-table-column> -->
        </el-table>
        <el-button
          v-if="
            historyPayDebit.length > 0 &&
            historyDebitTotal !== historyPayDebit.length
          "
          :loading="isLoading"
          class="bg-transparent md:mx-15 mr-auto"
          @click="loadMoreDebit"
          >Показать ещё</el-button
        >
      </div>
    </div>
    <TariffChangeForm
      v-if="changeTariffForm"
      :dialog-visible="changeTariffForm"
      :tariffs="listTariff"
      :checked-tariff="user.tariff"
      @close="changeTariffForm = false"
    />
  </div>
</template>

<script>
import { AJAX_GET, AJAX_POST } from "../units/ajax";
import { mapState, mapActions } from "vuex";
import moment from "moment";
import FillingBalance from "@/components/FillingBalance";
import PreTables from "@/components/Table/PreTables";
import MinBalance from "@/views/MinBalance";
import TariffChangeForm from "@/components/TariffChangeForm";
import Top from "@/components/Pages/Top";

export default {
  name: "Balance",
  components: { FillingBalance, PreTables, MinBalance, TariffChangeForm, Top },
  data() {
    return {
      paymentData: [],
      isLoading: true,
      changeTariffForm: false,
      listTariff: null,
    };
  },
  computed: {
    ...mapState("user", ["user", "tariffsList"]),
    ...mapState("balance", [
      "historyPay",
      "historyPayDebit",
      "historyPayTotal",
      "historyDebitTotal",
    ]),
    ...mapState("icons", ["question_icon", "success_icon", "cancel_icon"]),
  },
  async created() {
    this.isLoading = true;
    await this.getHistoryPay();
    await this.getHistoryDebit();
    this.isLoading = false;
  },
  methods: {
    ...mapActions("balance", ["getHistoryPay", "getHistoryDebit"]),
    ...mapActions("user", ["getUserTariff"]),

    async loadMore() {
      this.isLoading = true;
      const perPage = this.historyPay.length + 10;
      await this.getHistoryPay(perPage);
      this.isLoading = false;
    },
    async loadMoreDebit() {
      this.isLoading = true;
      const flag =
        this.historyPayDebit.length > 0 &&
        this.historyDebitTotal !== this.historyPayDebit.length;
      const perPage = this.historyPayDebit.length + 10;
      await this.getHistoryDebit(perPage);
      this.isLoading = false;
    },

    getStatus(status) {
      let value;
      // eslint-disable-next-line default-case
      switch (status) {
        case "Оплачен": {
          value = this.success_icon;
          break;
        }
        case "Ожидает платежа": {
          value = this.question_icon;
          break;
        }
        default: {
          value = this.cancel_icon;
          break;
        }
      }
      return value;
    },
    getDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
    getTime(time) {
      return moment(time).format("HH:mm");
    },
    async changeTariff() {
      await this.getUserTariff();
      if (this.tariffsList) {
        this.listTariff = this.tariffsList.sort((a, b) => a.price - b.price);
      }
      this.changeTariffForm = !this.changeTariffForm;
    },
  },
};
</script>

<style lang="scss">
@import "element-variables.scss";

.some__class .el-table__header {
  width: 100% !important;
}
.some__class .el-table__body {
  width: 100% !important;
}
.tariff {
  &__text {
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
  }
}
.notification {
  width: 400px;
}
.wrapp-balance {
  @media (max-width: 680px) {
    flex-direction: column;
  }
}
</style>
