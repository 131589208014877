/* eslint-disable block-scoped-var */
<template>
  <!-- <div class="pt-40 pb-80 w-full max-w-lg settings lg:pt-15 px-10"> -->
  <div class="pt-40 pb-80 w-full lg:pt-15 px-10">
    <!-- 
    <h1
      class="text-22 leading-36 mb-30 font-semibold text-white-normal lg:mb-10"
    >
      Настройки профиля
    </h1> -->
    <Top title=" Настройки профиля" :font="{ size: 22, leading: 22 }" />
    <ContactInfo id="contact" />
    <ChangePassword id="password" />
    <!-- <GlobalSettings /> -->
    <Agreement id="agreement" />
    <Politics id="politics" />
    <Analytic id="analytic" />
    <Notifications id="notifications" />
    <Payment id="payment" />
    <Orders id="orders" />
    <IntegrationBitrix id="integrationbitrix" class="my-30" />
  </div>
</template>

<script>
import Top from "@/components/Pages/Top";

import ContactInfo from "@/components/Settings/ContactInfo";
import ChangePassword from "@/components/Settings/ChangePassword";
// import GlobalSettings from "@/components/Settings/GlobalSettings";
import Agreement from "@/components/Settings/Agreement";
import Politics from "@/components/Settings/Politics";
import Analytic from "@/components/Settings/Analytic.vue";
import Notifications from "@/components/Settings/Notifications.vue";
import Orders from "@/components/Settings/Orders.vue";
import Payment from "@/components/Settings/Payment.vue";
import IntegrationBitrix from "@/components/Settings/IntegrationBitrix.vue";

export default {
  name: "Settings",
  components: {
    Top,
    ContactInfo,
    ChangePassword,
    // GlobalSettings,
    Agreement,
    Politics,
    Analytic,
    Notifications,
    Orders,
    Payment,
    IntegrationBitrix,
  },
  watch: {
    $route(to) {
      const { name } = to.query;
      if (name) this.scrollToSection(name);
    },
  },
  mounted() {
    const { name } = this.$route.query;
    if (name) this.scrollToSection(name);
  },
  methods: {
    scrollToSection(name) {
      document.getElementById(name).scrollIntoView({ block: "center" });
    },
  },
};
</script>

<style scoped lang="scss">
.setting-payment-mobile-btn {
  width: 189px;
}

.settings-payment {
  min-width: 332px;
}

.settings-payment-form {
  min-height: 250px;

  @screen lg {
    min-height: 0;
  }
}
</style>

<style lang="scss">
.settings__section {
  .bg-transparent {
    color: #fff;
  }
}
.input-appended {
  button {
    margin-left: 20px;
  }
}
</style>
