<template>
  <div>
    <ListSlotsDate
      v-for="(item, i) in list"
      :key="i"
      :value="item ? { ...item } : null"
      class="list-slot"
      :is-show-delet-btn="list.length"
      @formValid="formValid"
      @input="setData($event, i)"
      @delete="deleteItem(i)"
    />
    <el-button
      class="custom-button__outline"
      :disabled="isValid === false"
      @click="addField()"
    >
      Добавить дату
    </el-button>
    <div class="hl"></div>
    <el-button
      type="primary"
      class="custom-button"
      :disabled="isValid === false"
      @click="submit()"
    >
      Сохранить
    </el-button>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { format, set } from "date-fns";
import ListSlotsDate from "@/components/ListSlotsDate.vue";

export default {
  name: "SlotsDate",
  components: { ListSlotsDate },
  data() {
    return {
      list: [null],
      isValid: true,
    };
  },
  computed: {
    ...mapState("service", ["settings", "addDates"]),
  },
  created() {
    if (this.addDates.slots_date.length > 0) {
      const list = this.addDates.slots_date.map((date) => {
        const schedule = date.schedule.map((timeData) => {
          const [hours, minutes] = timeData.time.split(":");
          return {
            time: set(new Date(), {
              hours,
              minutes,
            }),
            price: timeData.price,
          };
        });
        return {
          day: date.date,
          schedule,
        };
      });
      this.list = list;
    }
  },
  methods: {
    ...mapActions("service", ["saveAddDates"]),
    ...mapMutations("service", ["setSettings"]),
    ...mapMutations(["setNotif"]),

    addField() {
      this.isValid = false;
      this.list.push(null);
    },
    deleteItem(index) {
      this.list.splice(index, 1);
    },
    setData(value, index) {
      this.list.splice(index, 1, { ...value });
    },
    formValid(e) {
      this.isValid = e;
    },
    submit() {
      const dates = this.list
        .filter((el) => el)
        .map((date) => {
          const schedule = date.schedule.map((timeData) => {
            return {
              time: format(timeData.time, "HH:mm"),
              price: timeData.price,
            };
          });
          return {
            day: date.day,
            schedule,
          };
        });
      if (this.isValid) {
        this.saveAddDates({ type: "slots_date", dates });
      }

      // this.saveAddDates({ type: "slots_date", dates });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";
.list-slot {
  width: 80%;
}
::v-deep .vfc {
  &-content {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin: 0 10px 10px;
  }
  &-main-container {
    background-color: transparent;
    box-shadow: none;
    border-radius: none;
    border: none;
  }
  &-top-date span {
    color: $--color-white;
  }
  &-arrow-right,
  &-arrow-left {
    border-color: $--color-white !important;
  }
  &-dayNames {
    padding-bottom: 10px;
    border-bottom: 1px solid $--color-sm-white;
    .vfc-day {
      color: $--color-white-5;
    }
  }
  &-calendar {
    border: 1px solid $--color-sm-white;
    &:nth-last-child(1) {
      border-radius: 0 3px 3px 0;
    }
    &:nth-last-child(2) {
      border-left: none;
      border-right: none;
    }
    &:nth-last-child(3) {
      border-radius: 3px 0 0 3px;
    }
  }
  &-marked {
    background: #3498db !important;
  }
  &-week .vfc-day {
    cursor: pointer;
    &:last-child {
      color: $--color-white;
    }
  }
  &-hide {
    color: $--color-white-5 !important;
  }
}
</style>
