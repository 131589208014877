<template>
  <div id="app">
    <Header
      :width-diff="asideCollapse ? '65px' : '280px'"
      @openMenu="mobileSidebar = true"
      @showModal="showModal"
    />
    <el-drawer
      :show-close="false"
      :visible.sync="mobileSidebar"
      direction="ltr"
      size="100%"
      class="sidebar__position"
    >
      <div slot="title" class="bg-violet-800 px-25 py-15">
        <div class="flex items-center justify-between">
          <div class="font-semibold text-18 leading-30 text-white-normal">
            Меню
          </div>
          <div @click="mobileSidebar = false">
            <svg-icon
              icon-name="close"
              width="18"
              height="18"
              class-name="text-white-normal"
            ></svg-icon>
          </div>
        </div>
      </div>
      <app-sidebar
        class="h-full mobile-sidebar bg-gradient-r-violet"
        @close="mobileSidebar = false"
      ></app-sidebar>
    </el-drawer>

    <el-container
      v-if="!$route.meta.plainLayout"
      v-onmedia.desktop="onCollapse"
      class="h-full main-container lg:h-auto"
    >
      <el-aside
        v-if="!$mq.tablet"
        :width="asideCollapse ? '65px' : '280px'"
        class="h-full main-sidebar"
        :class="{ collapsed: asideCollapse }"
      >
        <app-sidebar
          :collapsed="asideCollapse"
          @collapse="onCollapse"
        ></app-sidebar>
      </el-aside>

      <el-main class="home w-full h-full flex flex-col">
        <el-container
          class="px-75 inner-container lg:px-0 custom__class"
          :class="{
            'overflow-hidden': $route.name === 'schedule' && $mq.tablet,
          }"
        >
          <router-view />
        </el-container>
        <el-backtop target=".home"></el-backtop>
        <!-- <el-footer v-if="!$mq.tablet" class="bg-white-100 p-0">
          <el-container class="flex items-center h-full px-75">
            <span class="text-white-normal">
              © Broni.biz, 2016 - {{ nowYear }}
            </span>
          </el-container>
        </el-footer> -->

        <nav
          v-if="$mq.tablet"
          class="mobile-nav bg-black h-55 flex fixed bottom-0 w-full z-50"
        >
          <router-link
            v-for="(item, index) in mobileMenu"
            :key="`m-item-${index}`"
            :to="item.path"
            class="mobile-nav-item w-1/4 text-gray-200 flex items-center justify-center flex-col"
            :class="{ active: $route.path.indexOf(item.path) !== -1 }"
          >
            <svg-icon
              :icon-name="item.icon"
              width="16"
              height="18"
              class-name="mb-5"
            ></svg-icon>
            <div
              v-if="$mq.phoneMobile"
              class="mobile-nav__text text-11 leading-none"
            >
              {{ item.label }}
            </div>
          </router-link>
          <div
            class="mobile-nav-item w-1/4 text-gray-200 flex items-center justify-center flex-col"
            :class="{ active: mobileSidebar }"
            @click="mobileSidebar = !mobileSidebar"
          >
            <svg-icon
              icon-name="menu"
              width="16"
              height="18"
              class-name="mb-5"
            ></svg-icon>
            <div class="mobile-nav__text text-11 leading-none">
              <span v-if="$mq.phoneMobile">Меню </span>
            </div>
          </div>
        </nav>
      </el-main>
    </el-container>
    <router-view v-else></router-view>

    <Modal v-if="showBalance" :show="showBalance" @close="showBalance = false">
      <FillingBalance></FillingBalance>
    </Modal>
    <Modal v-if="showSms" :show="showSms" @close="showSms = false">
      <FillingSms></FillingSms>
    </Modal>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import AppSidebar from "@/components/AppSidebar";
import { loadYmap } from "vue-yandex-maps";
import { mapSettings } from "@/helpers/SettingsYandex.js";
import Header from "@/components/Layout/Header.vue";
import FillingBalance from "@/components/FillingBalance";
import FillingSms from "@/components/FillingSms.vue";
import Modal from "@/components/Modal";
import { setFavicon } from "@/helpers/forFavicon.js";

export default {
  components: { AppSidebar, Header, FillingSms, FillingBalance, Modal },
  data() {
    return {
      asideCollapse: false,
      mobileSidebar: false,
      showBalance: false,
      showSms: false,
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("settings", ["settingLicense"]),
    ...mapState(["notif"]),
    ...mapGetters("menu", ["mobileMenu"]),
    ...mapGetters("settings", ["getFaviconLicense"]),
    ...mapState("widget", ["widgetPublic"]),
  },
  watch: {
    notif: {
      handler(val, oldVal) {
        this.$notify({
          title: this.notif.title,
          type: this.notif.type,
          duration: this.notif.duration,
          showClose: this.notif.showClose,
          customClass: this.notif.type,
        });
      },
      deep: true,
    },
  },
  async created() {
    if (this.$route.meta.isPublic && this.$route.name === "public-widget") {
      document.body.style.setProperty(
        "--colorCustomBackgroundDynamic",
        "transparent"
      );
    }
    if (!this.$route.meta.isPublic) {
      await this.getSettingsSite();
      const { title } = this.settingLicense;
      const href = this.getFaviconLicense;
      setFavicon({ title, href });
    }
  },
  async mounted() {
    await loadYmap(mapSettings);
  },
  methods: {
    ...mapActions("settings", ["getSettingsSite"]),
    onCollapse() {
      this.asideCollapse = !this.asideCollapse;
    },
    showModal(type) {
      this.$data[`show${type}`] = true;
    },
  },
};
</script>

<style lang="scss">
@import "element-variables.scss";
:root {
  --colorCustomBackgroundDynamic: rgba(60, 20, 82, 1);
}
#app {
  max-height: 100vh;
  //overflow: hidden;
  min-width: 320px;
}
.el-button + .el-button {
  margin-left: 0;
}
.sidebar__position {
  margin-top: -76px;
}
.mobile-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s;
  z-index: 1011;
  width: 100%;
  height: 100%;
  &.outside {
    transform: translateX(-100%);
  }

  .sidebar-expand-feedback,
  .sidebar-expand-chevron,
  .sidebar-header__logo--min,
  .sidebar-expand-count {
    display: none;
  }
}
.main-sidebar {
  transition: width 0.3s linear;
  white-space: nowrap;
  margin-top: -76px;
  min-height: calc(100% + 76px);

  .simplebar-mask {
    z-index: unset;
  }

  .sidebar-expand-feedback,
  .sidebar-expand-chevron,
  .sidebar-header__logo--min {
    display: none;
  }

  .sidebar-expand-count {
    position: absolute;
    top: 0;
    right: 8px;
    opacity: 0;
    transition: opacity 0.5s;
  }

  .sidebar-expand-feedback {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
  }

  .sidebar-burger {
    transition: all 0.2s;
  }

  &.collapsed {
    overflow: hidden;

    .sidebar-expand-feedback {
      display: block;
    }

    .sidebar-expand-count {
      opacity: 1;
    }
    /** {
      opacity: 0;
      transition: opacity 0.2s;
    }*/

    .sidebar-link__label,
    .el-button {
      transition: opacity 0.6s;
      opacity: 0;
    }

    .sidebar-logo {
      display: flex;
      flex-direction: column;
    }

    .sidebar-burger {
      margin-right: 0;
      transform: scale(-1, 1);
    }

    .sidebar-expand-chevron {
      position: absolute;
      top: 50%;
      left: 8px;
      margin-top: -4px;
      display: block;
    }

    .sidebar-body__link,
    .sidebar-header {
      padding-left: 23px;
      padding-right: 22px;
    }

    .sidebar-link__icon {
      min-width: 18px;
    }

    .sidebar-header__period,
    .sidebar-footer,
    .sidebar-header__user {
      display: none;
    }

    .sidebar-header__logo--full {
      opacity: 0;
      position: absolute;
    }
  }

  .simplebar-content,
  [data-simplebar] {
    height: 100%;
  }
}

.mobile-nav-item {
  &.active {
    color: white;
    svg {
      @apply .text-yellow-100;
    }
  }
}
// .custom__class {
//   ::-webkit-scrollbar {
//     width: 0px;
//   }
// }
::-webkit-scrollbar {
  width: 0px;
  background: rgba(255, 255, 255, 0);
}
::-moz-scrollbar {
  width: 0px;
  background: rgba(255, 255, 255, 0);
}
body {
  background: var(--colorCustomBackgroundDynamic, rgba(60, 20, 82, 1));
  background-image: none !important;
}
</style>
