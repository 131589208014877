<template>
  <div class="rent">
    <p class="hint-title__medium-white">
      Клиент выбирает начало и период аренды, а также при необходимости
      количество единиц, если требуется. Например, для сдачи в аренду помещений,
      транспорта, инвентаря и т.д.
    </p>
    <h4 class="title">Цена и места</h4>
    <div class="rent-radio">
      <el-radio v-model="activeRadio" label="rent_depends_only_on_time">
        Зависит только от времени
      </el-radio>
      <p class="hint-title__medium-white mb-10">
        Данный тип подходит для тех, у кого цена формируется от количества
        времени аренды при бронировании: отели, сауны, транспорт, оборудование и
        т.п. Время аренды в расписании после бронирования становится
        недоступным.
      </p>
      <div v-if="isDepTime" class="rent-radio__funct">
        <!-- <p class="hint-title__medium-white">
          Данный тип подходит для тех, у кого цена формируется от количества
          времени аренды при бронировании: отели, сауны, транспорт, оборудование
          и т.п. Время аренды в расписании после бронирования становится
          недоступным.
        </p> -->
        <div class="list-fields__row">
          <div class="list-fields__item">
            <el-form-item prop="name" class="w-full">
              <span slot="label">
                {{ rent.headers[0].title }}
                <span v-if="rent.headers[0].valid" class="danger">*</span>
              </span>
              <el-select v-model="data0.time_unit" class="w-full">
                <el-option
                  v-for="item in listDuration"
                  :key="`cat-${item.value}`"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="list-fields__item">
            <el-form-item prop="basePrice" class="w-full">
              <div slot="label" class="flex">
                <div>
                  {{ rent.headers[1].title }}
                  <span v-if="rent.headers[1].valid" class="danger">*</span>
                </div>
                <el-popover
                  v-if="rent.headers[1].popover"
                  class="ml-8"
                  placement="top"
                  width="200"
                  trigger="hover"
                  :content="rent.headers[1].popoverText"
                >
                  <Tooltip slot="reference" />
                </el-popover>
              </div>
              <el-input v-model="data0.session_duration" type="number">
                <div slot="suffix">{{ typeUnitDepTime }}</div>
              </el-input>
            </el-form-item>
          </div>
          <div class="list-fields__item">
            <el-form-item prop="basePrice" class="w-full">
              <div slot="label" class="flex">
                <div>
                  {{ rent.headers[2].title }}
                  <span v-if="rent.headers[2].valid" class="danger">*</span>
                </div>
                <el-popover
                  v-if="rent.headers[2].popover"
                  class="ml-8"
                  placement="top"
                  width="200"
                  trigger="hover"
                  :content="rent.headers[2].popoverText"
                >
                  <Tooltip slot="reference" />
                </el-popover>
              </div>
              <el-input v-model="data0.minimum_rent" type="number">
                <div slot="suffix">{{ typeUnitDepTime }}</div>
              </el-input>
            </el-form-item>
          </div>
          <div v-if="data0.group.length > 1" class="list-fields__actions">
            <div class="list-fields__actions-stub"></div>
          </div>
        </div>
        <ListField3
          v-model="data0.group"
          title-button="Добавить период аренды"
          :headers="rent.general"
        ></ListField3>
      </div>
      <el-radio v-model="activeRadio" label="rent_depends_on_time_and_place">
        Зависит от времени и мест
      </el-radio>
      <p class="hint-title__medium-white mb-10">
        Данный тип подходит для тех, у кого цена зависит от времени аренды и
        количества мест. Сеанс в расписании станет недоступным только тогда,
        когда все будет сдано в аренду. Подходит для аренды велосипедов,
        экипировки, инвентаря и т.п.
      </p>
      <div v-if="isDepTimeSeats" class="rent-radio__funct">
        <!-- <p class="hint-title__medium-white">
          Данный тип подходит для тех, у кого цена зависит от времени аренды и
          количества мест. Сеанс в расписании станет недоступным только тогда,
          когда все будет сдано в аренду. Подходит для аренды велосипедов,
          экипировки, инвентаря и т.п.
        </p> -->
        <div class="list-fields__row list-fields__wrapper">
          <div class="list-fields__item">
            <el-form-item prop="name" class="w-full">
              <span slot="label">
                Единица измерения времени<span class="danger">*</span>
              </span>
              <el-select
                v-model="data1.time_unit"
                placeholder="Выберите категория"
                class="w-full"
              >
                <el-option
                  v-for="item in listDuration"
                  :key="`cat-${item.value}`"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </div>
          <div class="list-fields__item">
            <el-form-item prop="basePrice" class="w-full">
              <div slot="label" class="flex">
                <div>Продолжительность сеанса<span class="danger">*</span></div>
                <el-popover
                  class="ml-8"
                  placement="top"
                  width="200"
                  trigger="hover"
                  content="Фактически это размер шага, который будет использоваться при выборе времени в
бронировании
"
                >
                  <Tooltip slot="reference" />
                </el-popover>
              </div>
              <el-input v-model="data1.session_duration" type="number">
                <div slot="suffix">{{ typeUnitDepTimeSeats }}</div>
              </el-input>
            </el-form-item>
          </div>
          <div class="list-fields__item">
            <el-form-item prop="basePrice" class="w-full">
              <div slot="label" class="flex">
                <div>Минимальная аренда<span class="danger">*</span></div>
                <el-popover
                  class="ml-8"
                  placement="top"
                  width="200"
                  trigger="hover"
                  content="Укажите минимальную продолжительность аренды"
                >
                  <Tooltip slot="reference" />
                </el-popover>
              </div>
              <el-input v-model="data1.minimum_rent" type="number">
                <div slot="suffix">{{ typeUnitDepTimeSeats }}</div>
              </el-input>
            </el-form-item>
          </div>
          <div v-if="data1.group.length > 1" class="list-fields__actions">
            <div class="list-fields__actions-stub"></div>
          </div>
        </div>
        <ListField3
          v-model="data1.group"
          title-button="Добавить период аренды"
          :headers="rent.general"
        ></ListField3>
        <ListField2
          v-model="data1.group_2"
          class="ListField2"
          title-button="Добавить диапазон мест"
          :headers="rent.sale"
        ></ListField2>
      </div>
    </div>
  </div>
</template>

<script>
import ListField2 from "@/components/ListField2.vue";
import ListField3 from "@/components/ListField3.vue";
import { listDuration } from "@/helpers/services.js";
import { mapState, mapGetters, mapActions } from "vuex";
import { rent } from "@/helpers/typeSchedule.js";

export default {
  name: "Rent",
  components: {
    ListField2,
    ListField3,
  },
  data() {
    return {
      activeRadio: "rent_depends_only_on_time",
      data0: {
        time_unit: "minutes",
        session_duration: null,
        minimum_rent: null,
        group: [
          {
            id: null,
            from: 1,
            to: null,
            base_price: 0,
            holidays_price: null,
          },
        ],
      },
      data1: {
        time_unit: "minutes",
        session_duration: null,
        minimum_rent: null,
        group: [
          {
            id: null,
            from: 1,
            to: null,
            base_price: 0,
            holidays_price: null,
          },
        ],
        group_2: [
          {
            id: null,
            from: 1,
            to: null,
            discount: 0,
            discount_unit: "rub",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState("service", ["settings"]),
    ...mapGetters("service", ["isHaveSettings"]),
    isDepTime() {
      return this.activeRadio === "rent_depends_only_on_time";
    },
    isDepTimeSeats() {
      return this.activeRadio === "rent_depends_on_time_and_place";
    },
    activeRadioIndex() {
      return this.radioList.findIndex((item) => item === this.activeRadio);
    },
    typeUnitDepTime() {
      return this.listDuration.find((el) => el.value === this.data0.time_unit)
        .label;
    },
    typeUnitDepTimeSeats() {
      return this.listDuration.find((el) => el.value === this.data1.time_unit)
        .label;
    },
    rent() {
      return {
        ...rent,
        general: rent.general.map((el, index) => {
          if (index) return { ...el };
          return {
            ...el,
            title: `Длительность ${
              this.isDepTime ? this.typeUnitDepTime : this.typeUnitDepTimeSeats
            }`,
          };
        }),
      };
    },
  },
  created() {
    this.listDuration = listDuration;
    this.radioList = [
      "rent_depends_only_on_time",
      "rent_depends_on_time_and_place",
    ];
    this.setRadio();
  },
  methods: {
    ...mapActions("service", ["saveSettings"]),
    setRadio() {
      if (
        this.isHaveSettings &&
        this.radioList.includes(this.settings.booking_type_sub)
      ) {
        this.activeRadio = this.settings.booking_type_sub;
        const index = this.radioList.findIndex(
          (item) => item === this.settings.booking_type_sub
        );
        this[`data${index}`] = this.settings.data;
      } else {
        this.activeRadio = "rent_depends_only_on_time";
      }
    },
    save() {
      this.saveSettings({
        nameRadio: this.activeRadio,
        info: { ...this[`data${this.activeRadioIndex}`] },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.rent {
  .hint-title__medium-white {
    padding: 0 0 20px 0;
  }
  .title {
    font-size: 22px;
    line-height: 44px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  &-radio {
    display: flex;
    flex-direction: column;

    &__funct {
      display: flex;
      flex-direction: column;
      margin: -20px 0 0 25px;
    }
  }
}

.ListField2 {
  margin-bottom: 0;
}

.list-fields__row {
  margin: 0 -10px;
}

.custom-button__outline {
  width: fit-content;
}

::v-deep .el-radio {
  margin-bottom: 20px;
}

::v-deep .el-input__suffix {
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: $--color-white-5;
  }
}

::v-deep .el-input-group__prepend {
  padding-right: 10px;
}

::v-deep .tabs .el-radio {
  margin-bottom: 0;
}

@screen lg {
  .rent {
    &-radio__funct {
      margin: 0;
    }
    .hint-title__medium-white {
      padding: 0 0 20px 0;
    }
    .title {
      margin-bottom: 30px;
      font-size: 18px;
      line-height: 28px;
    }
  }
}
</style>
