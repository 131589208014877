<template>
  <div class="flex my-30">
    <div class="w-1/2">
      <span class="small_text">Дата и время</span>
      <span class="small_text">Тариф</span>
      <span class="small_text">Цена </span>
      <span class="small_text">Продолжит. </span>
    </div>
    <div class="w-1/2 mb-3">
      <span class="underline">{{ service.date_time }}</span>
      <span class="small_text-outcome">{{ service.service.name }} </span>
      <span class="small_text-outcome"
        >{{ service.service.price || service.service.base_price }}
        {{ item.currency }}
      </span>
      <span class="small_text-outcome"
        >{{ service.service.duration }} {{
      }}</span>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "OrderServiceDur",
  props: {
    service: {
      type: Object,
    },
    item: {
      type: Object,
    },
  },
  computed: {
    unitCahnge() {
      switch (this.service.service.unit) {
        case "days":
          return {
            text: "дней",
            textShot: "д",
            param: "day",
            time: this.hours,
          };
        case "hours":
          return {
            text: "часов",
            textShot: "ч",
            param: "hour",
            time: this.hours,
          };
        case "minutes":
          return {
            text: "минут",
            textShot: "мин",
            param: "minute",
            time: this.hours,
          };
        case "minute":
          return {
            text: "минут",
            textShot: "мин",
            param: "minute",
            time: this.hours,
          };

        default:
          return this.settings?.data?.time_unit;
      }
    },
  },
  created() {
    console.log(this.service);
  },
  methods: {
    getDate(date) {
      return moment(date).format("DD.MM.YYYY");
    },
  },
};
</script>
<style lang="scss" scoped>
.rotate {
  transform: rotate(180deg);
}
.drop-wrapp {
  display: flex;
  cursor: pointer;
}
.small_text {
  &-total {
    font-size: 12px;

    color: rgba(255, 190, 61, 1);
  }
  &-drop {
    margin-right: 8px;
    font-size: 12px;
  }
  &-outcome {
    font-weight: 500;
    opacity: 1;
    font-size: 12px;
    line-height: 20px;
    display: block;
  }
}
.utf {
  &-form {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 3px;
    margin: 10px 0px;
    padding: 8px 15px;
  }
  &-icon {
    color: rgba(255, 255, 255, 0.5);
    position: relative;
    top: 3px;
  }
}
</style>
