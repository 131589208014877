<template>
  <div>
    <div class="promocode bg-white-40 rounded-sm mb-20">
      <div
        class="item-head pl-25 pr-10 py-20 flex items-center xs:flex-wrap xs:flex-row lg:items-start lg:p-20 relative"
      >
        <div
          v-if="
            !$mq.tablet &&
            items.service_ids.length >= 1 &&
            items.service_type === 'multi'
          "
          class="item-head__left flex items-center"
        >
          <el-button class="mr-20 px-0 w-40" @click="isOpened = !isOpened">
            <span class="flex items-center justify-center">
              <svg-icon
                icon-name="chevron"
                width="12"
                height="8"
                class-name="text-white-normal"
                :class="{ 'rotate-180': !isOpened }"
              ></svg-icon>
            </span>
          </el-button>
        </div>
        <div class="person xs:order-1">
          <div class="person__status person__status_main">
            <a :href="urlWidget" target="_blank" class="card__id">
              {{ items.hash }}
            </a>
          </div>
          <div
            class="person__name"
            :title="
              items.service_type === 'multi'
                ? items.services_name
                : items.service_name
            "
          >
            {{
              items.service_type === "multi"
                ? items.services_name[0]
                : items.service_name
            }}
            <!-- {{ getNameService(items) }} -->
          </div>
          <div
            v-if="items.is_partner_widget"
            class="card__parent flex justify-between"
          >
            <div class="card__parent-item">
              <span class="text-white-500 text-12 leading-16 mb-3"
                >Партнер:</span
              >
              {{ items.partner_name }}
            </div>
            <div class="card__parent-item">
              <span class="text-white-500 text-12 leading-16 mb-3"
                >Комиссия:</span
              >
              {{ items.partner_percent }} %
            </div>
          </div>
        </div>
        <div v-if="!$mq.tablet" class="item__stats">
          <div class="item__stat">
            <div class="text-white-500 text-12 leading-16 mb-3">Услуг</div>
            <div class="font-medium">
              {{
                items.service_type === "single" ? 1 : items.service_ids.length
              }}
            </div>
          </div>

          <div class="item__stat">
            <div class="text-white-500 text-12 leading-16 mb-3">Тип</div>
            <div class="font-semibold">Виджет для сайта</div>
          </div>
          <div class="item__stat pl-25">
            <div class="text-white-500 text-12 leading-16 mb-3">Дизайн:</div>
            <div class="font-semibold">
              {{ `${listTheme[items.typeTheme]}` }}
            </div>
          </div>
        </div>

        <div v-else class="w-full item__stat xs:order-3">
          <div class="flex xs:flex-col">
            <div
              class="item__stat--border flex items-center mb-6 xs:justify-between"
            >
              <div class="text-white-500 text-12 leading-16 pr-5">Услуг</div>
              <div class="text-12 font-medium leading-20">
                {{
                  items.service_ids.length === 0 ? 1 : items.service_ids.length
                }}
              </div>
            </div>
            <div
              class="item__stat--border flex items-center mb-6 xs:justify-between"
            >
              <div class="flex items-center mb-6 xs:justify-between md:w-full">
                <div class="text-white-500 text-12 leading-16 pr-5">Тип</div>
                <div class="text-12 font-medium leading-20">
                  Виджет для сайта
                </div>
              </div>
            </div>
            <div
              class="item__stat--border flex items-center mb-6 xs:justify-between"
            >
              <div class="flex items-center mb-6 xs:justify-between md:w-full">
                <div class="text-white-500 text-12 leading-16 pr-5">Дизайн</div>
                <div class="text-12 font-medium leading-20">
                  {{ `${listTheme[items.typeTheme]}` }}
                </div>
              </div>
            </div>
          </div>
        </div>

        <el-popover
          class="ml-70 xs:ml-0 xs:order-2 xs:flex xs:justify-end xs:w-1/2"
          placement="bottom-end"
          popper-class="control-poper"
          width="171"
          trigger="click"
        >
          <span
            slot="reference"
            class="px-15 flex items-center outline-none h-20 lg:pr-0"
          >
            <svg-icon
              icon-name="dots"
              class-name="text-white-500"
              width="20"
              height="4"
            ></svg-icon>
          </span>
          <div>
            <div
              class="services-drag__item-icon mobile"
              @click="toEdit(items.hash)"
            >
              <svg-icon
                title="Редактировать виджет"
                icon-name="edit"
                width="16"
                height="18"
                :style="{
                  color: '#3498db',
                }"
              />
              <span class="">Редактировать</span>
            </div>
            <div
              class="services-drag__item-icon mobile"
              @click="copyCode(textCopy, { param: 'textCopy' })"
            >
              <svg-icon
                :style="{
                  color: '#3498db',
                }"
                icon-name="copy"
                width="24"
                height="24"
                title="Скопировать код виджета"
              />
              <span class="">Копировать код виджета</span>
            </div>
            <div
              class="services-drag__item-icon mobile"
              @click="copyCode(textCopySocial, { param: 'textCopySocial' })"
            >
              <svg-icon
                :style="{
                  color: '#3498db',
                }"
                icon-name="copy"
                width="20"
                height="24"
                title="Скопировать код виджета"
              />
              <span class="">Копировать ссылку</span>
            </div>

            <div
              class="services-drag__item-icon mobile"
              @click="remove(items.hash)"
            >
              <svg-icon
                title="Удалить виджет"
                icon-name="close"
                width="16"
                height="12"
                :style="{
                  color: '#ff0000',
                }"
              />
              <span class="ml-8">Удалить</span>
            </div>
          </div>
        </el-popover>
      </div>
      <div
        v-if="isOpened"
        class="item-content px-30 pt-25 pb-30 border-t border-white-150 lg:p-20"
      >
        <el-button
          type="mini"
          class="mb-25"
          @click="
            setServiceWidget({
              hash: items.hash,
              value:
                isAllSelect(items) || items.service_ids.length
                  ? []
                  : allServicesId,
            })
          "
        >
          {{
            items.service_ids.length ? "Снять все" : "Выбрать все"
          }}</el-button
        >
        <el-checkbox-group
          :value="items.service_ids"
          class="flex flex-col mb-30"
          @input="setServiceWidget({ hash: items.hash, value: $event })"
        >
          <el-checkbox
            v-for="resource in servicesList"
            :key="resource.id"
            :label="resource.hash"
            class="mb-10"
            >{{ resource.name }}</el-checkbox
          >
        </el-checkbox-group>
        <el-button
          class="custom-button__outline"
          @click="addServiceWidget(items)"
        >
          Сохранить
        </el-button>
      </div>
      <div
        v-if="
          $mq.tablet &&
          items.service_ids.length >= 1 &&
          items.service_type === 'multi'
        "
        class="cursor-pointer border-t border-white-150 pt-12 pb-12 leading-25 flex items-center justify-center"
      >
        <el-button
          class="xs:mx-5 mr-20 px-0 w-40 xs:w-full border-none"
          @click="isOpened = !isOpened"
        >
          <span class="flex items-center justify-center">
            <span class="px-10">
              {{ isOpened ? "Скрыть" : "Развернуть" }}
            </span>
            <svg-icon
              icon-name="chevron"
              width="12"
              height="8"
              class-name="text-white-normal"
              :class="{ 'rotate-180': !isOpened }"
            ></svg-icon>
          </span>
        </el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import SvgIcon from "../../components/SvgIcon.vue";

export default {
  components: { SvgIcon },
  props: {
    elements: Object,
    user: Object,
  },
  data() {
    return {
      items: null,
      isOpened: false,
      loading: {
        select: false,
      },
    };
  },
  computed: {
    ...mapState("user", ["servicesList"]),
    ...mapGetters("user", ["allServicesId"]),

    urlWidget() {
      return `${
        process.env.NODE_ENV === "production"
          ? process.env.VUE_APP_PROD_API
          : process.env.VUE_APP_LOCAL_API
      }/wd/${this.items.hash}`;
    },
    textCopy() {
      // ИЗ-ЗА ТОГО ЧТО ПЕРЕМЕННАЯ НЕ МОЖЕТ НАЧИНАТЬСЯ С ЧИСЛА ЧАСТЬ ВИДЖЕТОВ НЕ ОТОБРАЖАЛОСЬ
      //  let ${this.items.hash} = new Wbb('${this.items.hash}');
      // ${this.items.hash}.init();
      return `<div class="wbb" id="${this.items.hash}"></div> \n <script src="${process.env.VUE_APP_PROD_API}/widgetbb.js" type="text/javascript"><\/script> \n <script type="text/javascript">
         new Wbb('${this.items.hash}').init()       ;
  <\/script>`;
    },
    textCopySocial() {
      return `${process.env.VUE_APP_PROD_API}/wd/${this.items.hash}`;
    },
  },

  created() {
    this.setData();
    this.listTheme = {
      individual: "индивидуальный",
      light: "светлый",
      dark: "темный",
    };
  },

  methods: {
    ...mapActions("personals", ["changeResources"]),
    ...mapMutations("widget", ["setServiceWidget"]),

    ...mapActions("widget", ["deleteWidget", "addServiceWidget"]),
    ...mapMutations(["setNotif"]),

    isAllSelect(items) {
      return this.servicesList.length === items.service_ids.length;
    },

    toEdit(hash) {
      this.$router.push({ name: "widget", params: { hash } });
    },

    copyCode(e, param) {
      navigator.clipboard.writeText(e);
      this.setNotif({
        title:
          param.param === "textCopy"
            ? `Код виджета успешно скопирован`
            : `Ссылка на виджет успешно скопирована`,
      });
    },
    remove(hash) {
      this.$confirm("", "Удалить виджет?", {
        confirmButtonText: "Удалить",
        cancelButtonText: "Отмена",
        type: "warning",
      }).then(() => {
        this.deleteWidget(hash);
      });
    },

    setData() {
      this.items = this.elements;
    },
    getNameService(widget) {
      if (widget.service_type === "single") {
        return (
          this.servicesList.find((el) => el.hash === widget.service_id)?.name ||
          ""
        );
      }
      return this.servicesList
        .filter((el) => widget.service_ids.includes(el.hash))
        .map((item) => item.name)
        .join(", ");
    },
    async toggleAllResourcesIn(index) {
      const item = this.items[index];
      const resourcesCount = item.resources.length;
      this.loading.select = true;
      item.resources = [];
      if (resourcesCount >= 1) {
        item.resources = [];
      } else if (resourcesCount !== this.user.resources_count) {
        Object.values(this.user.resources).forEach((resource) => {
          item.resources.push(resource.hash);
        });
      }
      await this.changeResources(item);
      this.loading.select = false;
    },
    async updateResources(item) {
      this.loading.select = true;
      await this.changeResources(item);
      this.loading.select = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  &__name {
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.person {
  width: 250px;
  @media (max-width: 480px) {
    width: 50%;
  }
  &__status {
    font-size: 12px;
    line-height: 20px;
    &_main {
      color: #ffbe3d;
    }
  }
  &__name {
    font-size: 16px;
    line-height: 28px;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.item {
  &-head {
    //padding-bottom: 10px;
    // border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  &__stats {
    min-width: 300px;
    display: flex;
    margin-left: auto;
  }
  &__stat {
    //  min-width: 156px;
    padding-right: 25px;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    // &--border {
    //   padding-bottom: 10px;
    //   border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    // }

    &:nth-child(2) {
      min-width: 200px;
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      @media (max-width: 480px) {
        margin-top: 15px;
        margin-left: 0px;
      }
    }
    &:last-of-type {
      min-width: 156px;
      border-right: none;
      padding-right: 0;
    }
  }
}
.mobile {
  font-size: 14px;
  line-height: 24px;
  min-height: 34px;

  span {
    border: none;
  }
}
.services-drag__item-icon.mobile {
  padding: 0px 10px;
  display: flex;
  align-items: center;

  cursor: pointer;
}
</style>
