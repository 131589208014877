<template>
  <div
    class="custom-block form"
    :style="{
      background: widgetTheme ? customClass.block_background : '',
      color: widgetTheme ? customClass.active_text_color : '',
    }"
  >
    <div v-if="isAdmin">
      <div class="form__wrapper-header">
        <svg-icon
          width="40"
          height="40"
          class="form__icon-done"
          icon-name="isDone"
        ></svg-icon>
        <div class="form__close" @click="closeModal">
          <svg-icon
            icon-name="close"
            width="14"
            height="14"
            :style="{
              color: widgetTheme ? customClass.active_text_color : '',
            }"
          ></svg-icon>
        </div>
      </div>
      <div
        class="title-widget"
        :style="{
          color: widgetTheme ? customClass.active_text_color : '',
        }"
      >
        {{ titlePay }}
      </div>
      <p class="form__sub-title">Вы забронировали</p>

      <div class="row-info">
        <div class="row-info__title">Услуга</div>
        <div class="row-info__value">
          {{ dataPay.name }}
        </div>
      </div>
      <div class="row-info">
        <div class="row-info__title">Дата и время</div>
        <div class="row-info__value">
          {{
            dateTime
              ? dateTime
              : `${getDate(dataPay.dateTime)} в ${getTime(dataPay.dateTime)} `
          }}
        </div>
      </div>
      <div class="row-info">
        <div class="row-info__title">Стоимость</div>
        <div class="row-info__value">
          {{ totalPrice ? totalPrice : dataPay.price || 0 }}
          {{ dataPay.currency }}
        </div>
      </div>
      <div v-if="isAdmin">
        <p class="form__sub-title">Данные клиента</p>
        <div class="row-info">
          <div class="row-info__title">Имя</div>
          <div class="row-info__value">
            {{ dataPay.request.client_info.name }}
            {{ dataPay.request.client_info.surname }}
          </div>
        </div>
        <div class="row-info">
          <div class="row-info__title">Телефон</div>
          <div class="row-info__value">
            {{ dataPay.request.client_info.tel }}
          </div>
        </div>
        <div class="row-info">
          <div class="row-info__title">Электронная почта</div>
          <div class="row-info__value">
            {{ dataPay.request.client_info.email }}
          </div>
        </div>
      </div>
      <div
        v-if="
          isAdmin &&
          dataPay.booking_type_sub !==
            'individual_does_not_depend_on_the_number_of_seats'
        "
      >
        <p
          v-if="
            dataPay.payment_data.length
              ? dataPay.payment_data.type_name ||
                dataPay.payment_data[0].type_name
              : false
          "
          class="form__sub-title"
        >
          Оплата
        </p>

        <div
          v-if="
            dataPay.payment_data.length
              ? dataPay.payment_data.type_name ||
                dataPay.payment_data[0].type_name
              : false
          "
          class="row-info"
        >
          <div class="row-info__title">Тип оплаты</div>
          <div v-if="dataPay.payment_data.length" class="row-info__value">
            {{
              dataPay.payment_data.type_name ||
              dataPay.payment_data[0].type_name
            }}
          </div>
        </div>
        <div
          v-if="dataPay.payment_data.payment_type === 'full_payment'"
          class="row-info"
        >
          <div class="row-info__title">Онлайн</div>
          <div class="row-info__value">
            {{ dataPay.payment_data.full_amount }}
            {{ dataPay.currency }}
          </div>
        </div>
      </div>
      <!-- <div
        v-if="
          (!!dataPay.payment_data.payment_link || !!dataPay.payment_link) &&
          (dataPay.payment_data.payment_type === 'full_payment' ||
            dataPay.payment_data.payment_type === 'prepayment' ||
            dataPay.payment_link)
        "
      > -->
      <div v-if="dataPay.msg">
        <el-alert
          v-if="dataPay.msg"
          center
          :title="dataPay.msg"
          type="warning"
          show-icon
          :closable="false"
        />
      </div>
      <div
        v-if="
          (!!dataPay.payment_data.payment_link || !!dataPay.payment_link) &&
          (dataPay.payment_data.payment_type === 'full_payment' ||
            dataPay.payment_data.payment_type === 'prepayment' ||
            dataPay.payment_link)
        "
        class="row-link_pay cursor-pointer"
      >
        <svg-icon width="20" height="20" icon-name="copy"></svg-icon>
        <a
          @click="
            copy(dataPay.payment_link || dataPay.payment_data.payment_link)
          "
        >
          Скопировать ссылку на оплату
        </a>
      </div>
    </div>
    <!--  оплата на месте -->
    <div
      v-if="!isAdmin && !isRequiredPay && !onlinePaymentRequired && payInPlace"
    >
      <div
        class="form__wrapper-header"
        :style="{
          color: widgetTheme ? customClass.active_text_color : '',
        }"
      >
        <svg-icon
          width="40"
          height="40"
          class="form__icon-done"
          icon-name="isDone"
        ></svg-icon>
        <div class="form__close" @click="closeModal">
          <svg-icon
            icon-name="close"
            width="14"
            height="14"
            :style="{
              color: widgetTheme ? customClass.active_text_color : '',
            }"
          ></svg-icon>
        </div>
      </div>

      <div
        class="title-widget"
        :style="{
          color: widgetTheme ? customClass.active_text_color : '',
        }"
      >
        Услуга успешно забронирована!
      </div>
      <p
        class="form__sub-title"
        :style="{
          color: widgetTheme ? customClass.active_text_color : '',
        }"
      >
        Вы забронировали
      </p>
      <div class="row-info">
        <div
          class="row-info__title"
          :style="{
            color: widgetTheme ? customClass.inactive_text_color : '',
          }"
        >
          {{
            dataPay.booking_type_sub === "service_tree_from_settings"
              ? "Отделение"
              : "Услуга"
          }}
        </div>
        <div
          class="row-info__value"
          :style="{
            color: widgetTheme ? customClass.active_text_color : '',
          }"
        >
          {{ dataPay.name }}
        </div>
      </div>
      <div
        v-if="dataPay.booking_type_sub === 'service_tree_from_settings'"
        class="row-info"
      >
        <div
          class="row-info__title"
          :style="{
            color: widgetTheme ? customClass.inactive_text_color : '',
          }"
        >
          Услуга
        </div>
        <div
          v-if="isServiceName"
          class="row-info__value"
          :style="{
            color: widgetTheme ? customClass.active_text_color : '',
          }"
        >
          {{ isServiceName }}
        </div>
      </div>
      <div class="row-info">
        <div
          class="row-info__title"
          :style="{
            color: widgetTheme ? customClass.inactive_text_color : '',
          }"
        >
          Дата и время
        </div>
        <div
          class="row-info__value"
          :style="{
            color: widgetTheme ? customClass.active_text_color : '',
          }"
        >
          {{
            dateTime
              ? dateTime
              : `${getDate(dataPay.dateTime)} в ${dataPay.dateAndTime} `
          }}
        </div>
      </div>
      <div
        v-if="(totalPrice ? totalPrice : dataPay.price) > 0"
        class="row-info"
      >
        <div
          class="row-info__title"
          :style="{
            color: widgetTheme ? customClass.inactive_text_color : '',
          }"
        >
          Стоимость
        </div>
        <div
          class="row-info__value"
          :style="{
            color: widgetTheme ? customClass.active_text_color : '',
          }"
        >
          {{ totalPrice ? totalPrice : dataPay.price || 0 }}
          {{ dataPay.currency }}
        </div>
      </div>
      <div
        v-if="dataPay.booking_type_sub === 'service_tree_from_settings'"
        class=""
      >
        <div
          class="form__text"
          :style="{
            color: widgetTheme ? customClass.inactive_text_color : '',
          }"
          v-html="dataPay.modal_text"
        />
      </div>
      <div v-if="!isAdmin">
        <p
          class="form__sub-title"
          :style="{
            color: widgetTheme ? customClass.active_text_color : '',
          }"
        >
          Контактные данные
        </p>

        <div class="row-info">
          <div
            class="row-info__title"
            :style="{
              color: widgetTheme ? customClass.inactive_text_color : '',
            }"
          >
            Телефон
          </div>
          <div
            class="row-info__value"
            :style="{
              color: widgetTheme ? customClass.active_text_color : '',
            }"
          >
            {{ dataPay.phone }}
          </div>
        </div>
        <div class="row-info">
          <div
            class="row-info__title"
            :style="{
              color: widgetTheme ? customClass.inactive_text_color : '',
            }"
          >
            Адрес
          </div>
          <div
            class="row-info__value"
            :style="{
              color: widgetTheme ? customClass.active_text_color : '',
            }"
          >
            {{ dataPay.address }}
          </div>
        </div>
      </div>
      <div
        v-if="
          !isAdmin &&
          dataPay.booking_type_sub !==
            'individual_does_not_depend_on_the_number_of_seats'
        "
      >
        <p
          v-if="!!dataPay.payment_type_name"
          class="form__sub-title"
          :style="{
            color: widgetTheme ? customClass.inactive_text_color : '',
          }"
        >
          Оплата
        </p>

        <div v-if="!!dataPay.payment_type_name" class="row-info">
          <div
            class="row-info__title"
            :style="{
              color: widgetTheme ? customClass.inactive_text_color : '',
            }"
          >
            Тип оплаты
          </div>
          <div
            class="row-info__value"
            :style="{
              color: widgetTheme ? customClass.active_text_color : '',
            }"
          >
            {{ dataPay.payment_type_name }}
          </div>
        </div>
      </div>
      <div
        v-if="
          !isAdmin && dataPay.booking_type_sub !== 'service_tree_from_settings'
        "
        class="hl"
        :style="{
          background: widgetTheme ? customClass.background_cells_fields : '',
        }"
      ></div>
      <div
        v-if="dataPay.booking_type_sub !== 'service_tree_from_settings'"
        class="form__text"
        :style="{
          color: widgetTheme ? customClass.inactive_text_color : '',
        }"
        v-html="dataPay.modal_text"
      />

      <div v-if="!isAdmin && !dataPay.hidden" class="form__actions">
        <el-button
          v-if="payOnlineButton"
          type="primary"
          @click="redirectToLink(dataPay.payment_link)"
        >
          Оплатить онлайн
        </el-button>
        <el-button
          v-if="
            payPlaceButton &&
            payOnlineButton &&
            !dataPay.payment.payment_required
          "
          :style="{
            background: widgetTheme ? customClass.background_cells_fields : '',
            color: widgetTheme ? customClass.inactive_text_color : '',
          }"
          @click="closeModal"
        >
          Оплатить на месте
        </el-button>
      </div>
    </div>
    <!--  оплата на месте c выбором оплат? -->
    <div
      v-if="!isAdmin && !payInPlace && isRequiredPay && onlinePaymentRequired"
    >
      <div
        class="form__wrapper-header"
        :style="{
          color: widgetTheme ? customClass.active_text_color : '',
        }"
      >
        <svg-icon
          width="40"
          height="40"
          class="form__icon-done"
          icon-name="isDone"
        ></svg-icon>
        <div class="form__close" @click="closeModal">
          <svg-icon
            icon-name="close"
            width="14"
            height="14"
            :style="{
              color: widgetTheme ? customClass.active_text_color : '',
            }"
          ></svg-icon>
        </div>
      </div>

      <div
        class="title-widget"
        :style="{
          color: widgetTheme ? customClass.active_text_color : '',
        }"
      >
        Услуга успешно забронирована!
      </div>
      <p
        class="form__sub-title"
        :style="{
          color: widgetTheme ? customClass.active_text_color : '',
        }"
      >
        Вы забронировали
      </p>
      <div class="row-info">
        <div
          class="row-info__title"
          :style="{
            color: widgetTheme ? customClass.inactive_text_color : '',
          }"
        >
          {{
            dataPay.booking_type_sub === "service_tree_from_settings"
              ? "Отделение"
              : "Услуга"
          }}
        </div>
        <div
          class="row-info__value"
          :style="{
            color: widgetTheme ? customClass.active_text_color : '',
          }"
        >
          {{ dataPay.name }}
        </div>
      </div>
      <div
        v-if="dataPay.booking_type_sub === 'service_tree_from_settings'"
        class="row-info"
      >
        <div
          class="row-info__title"
          :style="{
            color: widgetTheme ? customClass.inactive_text_color : '',
          }"
        >
          Услуга
        </div>
        <div
          v-if="isServiceName"
          class="row-info__value"
          :style="{
            color: widgetTheme ? customClass.active_text_color : '',
          }"
        >
          {{ isServiceName }}
        </div>
      </div>
      <div class="row-info">
        <div
          class="row-info__title"
          :style="{
            color: widgetTheme ? customClass.inactive_text_color : '',
          }"
        >
          Дата и время
        </div>
        <div
          class="row-info__value"
          :style="{
            color: widgetTheme ? customClass.active_text_color : '',
          }"
        >
          {{
            dateTime
              ? dateTime
              : `${getDate(dataPay.dateTime)} в ${dataPay.dateAndTime} `
          }}
        </div>
      </div>
      <div
        v-if="(totalPrice ? totalPrice : dataPay.price) > 0"
        class="row-info"
      >
        <div
          class="row-info__title"
          :style="{
            color: widgetTheme ? customClass.inactive_text_color : '',
          }"
        >
          Стоимость
        </div>
        <div
          class="row-info__value"
          :style="{
            color: widgetTheme ? customClass.active_text_color : '',
          }"
        >
          {{ totalPrice ? totalPrice : dataPay.price || 0 }}
          {{ dataPay.currency }}
        </div>
      </div>

      <div v-if="!isAdmin">
        <p
          class="form__sub-title"
          :style="{
            color: widgetTheme ? customClass.active_text_color : '',
          }"
        >
          Контактные данные
        </p>

        <div class="row-info">
          <div
            class="row-info__title"
            :style="{
              color: widgetTheme ? customClass.inactive_text_color : '',
            }"
          >
            Телефон
          </div>
          <div
            class="row-info__value"
            :style="{
              color: widgetTheme ? customClass.active_text_color : '',
            }"
          >
            {{ dataPay.phone }}
          </div>
        </div>
        <div class="row-info">
          <div
            class="row-info__title"
            :style="{
              color: widgetTheme ? customClass.inactive_text_color : '',
            }"
          >
            Адрес
          </div>
          <div
            class="row-info__value"
            :style="{
              color: widgetTheme ? customClass.active_text_color : '',
            }"
          >
            {{ dataPay.address }}
          </div>
        </div>
      </div>
      <div
        v-if="
          !isAdmin &&
          dataPay.booking_type_sub !==
            'individual_does_not_depend_on_the_number_of_seats'
        "
      >
        <p
          v-if="!!dataPay.payment_type_name"
          class="form__sub-title"
          :style="{
            color: widgetTheme ? customClass.inactive_text_color : '',
          }"
        >
          Оплата
        </p>

        <div v-if="!!dataPay.payment_type_name" class="row-info">
          <div
            class="row-info__title"
            :style="{
              color: widgetTheme ? customClass.inactive_text_color : '',
            }"
          >
            Тип оплаты
          </div>
          <div
            class="row-info__value"
            :style="{
              color: widgetTheme ? customClass.active_text_color : '',
            }"
          >
            {{ dataPay.payment_type_name }}
          </div>
        </div>
      </div>
      <div
        v-if="!isAdmin"
        class="hl"
        :style="{
          background: widgetTheme ? customClass.background_cells_fields : '',
        }"
      ></div>
      <div
        class="form__text"
        :style="{
          color: widgetTheme ? customClass.inactive_text_color : '',
        }"
        v-html="dataPay.modal_text"
      />

      <div v-if="!isAdmin && !dataPay.hidden" class="form__actions">
        <el-button
          v-if="payOnlineButton"
          type="primary"
          @click="redirectToLink(dataPay.payment_link)"
        >
          Оплатить онлайн
        </el-button>
        <el-button
          v-if="payPlaceButton && payOnlineButton"
          :style="{
            background: widgetTheme ? customClass.background_cells_fields : '',
            color: widgetTheme ? customClass.inactive_text_color : '',
          }"
          @click="closeModal"
        >
          Оплатить на месте
        </el-button>
      </div>
    </div>
    <!-- 3 -->
    <div
      v-if="!isAdmin && !isRequiredPay && onlinePaymentRequired && payInPlace"
    >
      <div
        class="form__wrapper-header"
        :style="{
          color: widgetTheme ? customClass.active_text_color : '',
        }"
      >
        <svg-icon
          width="40"
          height="40"
          class="form__icon-done"
          icon-name="isDone"
        ></svg-icon>
        <div class="form__close" @click="closeModal">
          <svg-icon
            icon-name="close"
            width="14"
            height="14"
            :style="{
              color: widgetTheme ? customClass.active_text_color : '',
            }"
          ></svg-icon>
        </div>
      </div>

      <div
        class="title-widget"
        :style="{
          color: widgetTheme ? customClass.active_text_color : '',
        }"
      >
        Услуга успешно забронирована!
      </div>
      <p
        class="form__sub-title"
        :style="{
          color: widgetTheme ? customClass.active_text_color : '',
        }"
      >
        Вы забронировали
      </p>
      <div class="row-info">
        <div
          class="row-info__title"
          :style="{
            color: widgetTheme ? customClass.inactive_text_color : '',
          }"
        >
          {{
            dataPay.booking_type_sub === "service_tree_from_settings"
              ? "Отделение"
              : "Услуга"
          }}
        </div>
        <div
          class="row-info__value"
          :style="{
            color: widgetTheme ? customClass.active_text_color : '',
          }"
        >
          {{ dataPay.name }}
        </div>
      </div>
      <div
        v-if="dataPay.booking_type_sub === 'service_tree_from_settings'"
        class="row-info"
      >
        <div
          class="row-info__title"
          :style="{
            color: widgetTheme ? customClass.inactive_text_color : '',
          }"
        >
          Услуга
        </div>
        <div
          v-if="isServiceName"
          class="row-info__value"
          :style="{
            color: widgetTheme ? customClass.active_text_color : '',
          }"
        >
          {{ isServiceName }}
        </div>
      </div>
      <div class="row-info">
        <div
          class="row-info__title"
          :style="{
            color: widgetTheme ? customClass.inactive_text_color : '',
          }"
        >
          Дата и время
        </div>
        <div
          class="row-info__value"
          :style="{
            color: widgetTheme ? customClass.active_text_color : '',
          }"
        >
          {{
            dateTime
              ? dateTime
              : `${getDate(dataPay.dateTime)} в ${dataPay.dateAndTime} `
          }}
        </div>
      </div>
      <div
        v-if="(totalPrice ? totalPrice : dataPay.price) > 0"
        class="row-info"
      >
        <div
          class="row-info__title"
          :style="{
            color: widgetTheme ? customClass.inactive_text_color : '',
          }"
        >
          Стоимость
        </div>
        <div
          class="row-info__value"
          :style="{
            color: widgetTheme ? customClass.active_text_color : '',
          }"
        >
          {{ totalPrice ? totalPrice : dataPay.price || 0 }}
          {{ dataPay.currency }}
        </div>
      </div>

      <div v-if="!isAdmin">
        <p
          class="form__sub-title"
          :style="{
            color: widgetTheme ? customClass.active_text_color : '',
          }"
        >
          Контактные данные
        </p>

        <div class="row-info">
          <div
            class="row-info__title"
            :style="{
              color: widgetTheme ? customClass.inactive_text_color : '',
            }"
          >
            Телефон
          </div>
          <div
            class="row-info__value"
            :style="{
              color: widgetTheme ? customClass.active_text_color : '',
            }"
          >
            {{ dataPay.phone }}
          </div>
        </div>
        <div class="row-info">
          <div
            class="row-info__title"
            :style="{
              color: widgetTheme ? customClass.inactive_text_color : '',
            }"
          >
            Адрес
          </div>
          <div
            class="row-info__value"
            :style="{
              color: widgetTheme ? customClass.active_text_color : '',
            }"
          >
            {{ dataPay.address }}
          </div>
        </div>
      </div>
      <div
        v-if="
          !isAdmin &&
          dataPay.booking_type_sub !==
            'individual_does_not_depend_on_the_number_of_seats'
        "
      >
        <p
          v-if="!!dataPay.payment_type_name"
          class="form__sub-title"
          :style="{
            color: widgetTheme ? customClass.inactive_text_color : '',
          }"
        >
          Оплата
        </p>

        <div v-if="!!dataPay.payment_type_name" class="row-info">
          <div
            class="row-info__title"
            :style="{
              color: widgetTheme ? customClass.inactive_text_color : '',
            }"
          >
            Тип оплаты
          </div>
          <div
            class="row-info__value"
            :style="{
              color: widgetTheme ? customClass.active_text_color : '',
            }"
          >
            {{ dataPay.payment_type_name }}
          </div>
        </div>
      </div>
      <div
        v-if="!isAdmin"
        class="hl"
        :style="{
          background: widgetTheme ? customClass.background_cells_fields : '',
        }"
      ></div>
      <div
        class="form__text"
        :style="{
          color: widgetTheme ? customClass.inactive_text_color : '',
        }"
        v-html="dataPay.modal_text"
      />
      <div v-if="!isAdmin && !dataPay.hidden" class="form__actions">
        <el-button
          v-if="payOnlineButton"
          type="primary"
          @click="redirectToLink(dataPay.payment_link)"
        >
          Оплатить онлайн
        </el-button>
        <el-button
          v-if="
            payPlaceButton &&
            payOnlineButton &&
            !dataPay.payment.payment_required
          "
          :style="{
            background: widgetTheme ? customClass.background_cells_fields : '',
            color: widgetTheme ? customClass.inactive_text_color : '',
          }"
          @click="closeModal"
        >
          Оплатить на месте
        </el-button>
      </div>
    </div>
    <!--  если оплата/предоплата обязательная -->
    <div v-if="!isAdmin && isRequiredPay">
      <div v-if="!isAdmin && (isRequiredPay || isPrePayment) && payInPlace">
        <div class="form__wrapper-header form__pre-pay">
          <svg-icon
            width="36"
            height="36"
            class="form__icon-done"
            icon-name="isDone"
          ></svg-icon>
          <div
            class="form__pre-pay_title"
            :style="{
              color: widgetTheme ? customClass.active_text_color : '',
            }"
          >
            Услуга успешно забронирована!
          </div>
          <span
            class="form__pre-pay_text"
            :style="{
              color: widgetTheme ? customClass.active_text_color : '',
            }"
            >{{
              prePay
                ? ` Вам необходимо внести предоплату`
                : ` Вам необходимо внести оплату`
            }}
          </span>
          <span
            class="form__pre-pay_title-widget"
            :style="{
              color: widgetTheme ? customClass.active_text_color : '',
            }"
            >{{
              prePay
                ? isPrePayment
                : dataPay.chek_sum || dataPay.payment_data.full_amount
            }}
            {{ dataPay.currency }}</span
          >
          <p
            v-if="isPeriod"
            class="form__pre-pay_text"
            :style="{
              color: widgetTheme ? customClass.active_text_color : '',
            }"
          >
            Если оплата не будет завершена в течение <br />
            {{ isPeriod }} минут , бронирование автоматически <br />
            будет отменено.
          </p>
          <div class="form__actions">
            <el-button
              v-if="payOnlineButton"
              :style="{
                background: widgetTheme
                  ? customClass.active_buttons_background
                  : '',
                color: widgetTheme ? customClass.button_text_color : 'black',
              }"
              type="primary"
              @click="redirectToLink(dataPay.payment_link)"
            >
              {{ prePay ? `Внести предоплату` : ` Оплатить` }}
            </el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { format } from "date-fns";
import moment from "moment";

export default {
  name: "PayWidget",
  props: {
    customClass: { type: Object },
    widgetTheme: {
      type: String,
    },
  },
  computed: {
    ...mapState("widget", ["dataPay"]),
    ...mapState("user", ["user"]),
    totalPrice() {
      return this.dataPay
        ? this.dataPay.request?.book_info?.total_price ??
            this.dataPay.request?.book_info?.final_price
        : 0;
    },
    isServiceName() {
      return this.dataPay
        ? this.dataPay.request?.book_info?.service?.name
        : " ";
    },
    titlePay() {
      return this.dataPay.data?.updated_at
        ? "Заказ успешно отредактирован!"
        : "Услуга успешно забронирована!";
    },
    serviceName() {
      if (!this.dataPay.request) return true;
      return "";
    },
    dateTime() {
      if (!this.dataPay.request) return "";
      const date = format(new Date(this.dataPay.request.date), "dd.MM.yyyy");
      return `${date} в ${this.dataPay.request.time}`;
    },

    isAdmin() {
      return this.dataPay.is_admin;
    },
    isRequiredPay() {
      return this.dataPay.payment_required;
    },
    payInPlace() {
      return this.dataPay.payment.payment_at_place;
    },
    onlinePaymentRequired() {
      return this.dataPay.payment.online_payment;
    },
    prePay() {
      return this.dataPay.payment
        ? this.dataPay.payment.online_payment_type === "prepayment"
        : false;
    },
    isPreNotRequired() {
      return this.dataPay;
    },
    payOnlineButton() {
      return this.dataPay.payment ? this.dataPay.payment.online_payment : true;
    },
    payPlaceButton() {
      if (!this.dataPay.payment) return true;
      return (
        this.dataPay.payment?.payment_at_place ||
        this.dataPay.payment.online_payment !== "full_payment"
      );
    },

    isPrePayment() {
      if (
        this.dataPay.payment &&
        this.dataPay.payment.online_payment_type === "prepayment"
      ) {
        const price = this.dataPay.request.book_info.total_price;
        return this.dataPay.payment.prepayment_unit === "percent"
          ? (price * this.dataPay.payment.prepayment_amount) / 100
          : this.dataPay.payment.prepayment_amount;
      }
      return false;
    },
    isPeriod() {
      return this.dataPay.payment
        ? this.dataPay?.payment?.payment_required_period
        : true;
    },
  },
  methods: {
    ...mapActions("widget", ["getLinkPay"]),
    ...mapMutations(["setNotif"]),

    getDate(date) {
      // return date.toLocaleDateString("en-CA");
      return moment(date).format("DD.MM.YYYY");
    },
    getTime(date) {
      return moment(date).format("hh:mm");
    },
    copy(copyLink) {
      navigator.clipboard.writeText(copyLink);
      this.setNotif({ title: `Данные скопированы` });
    },
    redirectToLink(link) {
      if (this.dataPay.payment_target === "blank") {
        window.open(`${link}`, "_blank");
      } else {
        window.location = link;
      }
    },
    closeModal() {
      this.$emit("reloadWidget");
      this.$emit("close", "qwe");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.hl {
  margin: 30px 0;
}

.title-widget {
  margin-bottom: 20px;
}

.form {
  &__wrapper-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  &__close {
    cursor: pointer;

    svg {
      color: $--color-white;
    }
  }
  &__sub-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin: 20px 0 10px;
    color: $--color-white;
  }
  &__text {
    font-size: 12px;
    margin-bottom: 30px;
    color: rgba(255, 255, 255, 0.5);
  }
  &__actions {
    display: flex;
    justify-content: space-between;
  }
  &__pre-pay {
    display: flex;
    flex-direction: column;
    padding-top: 30px;
    &_title {
      padding: 14px 0px;
      font-size: 18px;
      line-height: 28px;
      color: rgba(255, 255, 255, 1);
      &-widget {
        font-size: 16px;
        color: rgba(255, 255, 255, 1);
        line-height: 24px;
        margin-bottom: 10px;
      }
    }
    &_text {
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      margin-bottom: 20px;
      color: rgba(255, 255, 255, 0.5);
    }
  }
}
.row-link_pay {
  display: flex;
  justify-content: space-between;
  background: #ffbe3d;
  padding: 8px 20px;
  margin: 10px 0px;
  width: 295px;
  border-radius: 3px;
  color: #000000;
}
</style>
