<template>
  <div class="item-address">
    <div class="list-fields__row">
      <div class="list-fields__item">
        <el-form-item prop="textInput">
          <p slot="label">Адрес<span class="danger">*</span></p>
          <el-input
            :id="`map-search-${index}`"
            v-model="textInput"
            placeholder="Введите адрес"
            autocomplete="off"
            @blur="changeAddress"
          ></el-input>
        </el-form-item>
      </div>
      <div v-if="service.address.length > 1" class="list-fields__actions">
        <ButtonIcon icon-name="close" width="16" @click="deleteAdrs" />
      </div>
    </div>
    <div class="map-wrapper">
      <div class="text-12 leading-19 text-white-500 mb-10">
        Вы можете передвинуть точку на карте для более точного отображения
        адреса
      </div>
      <yandex-map
        key="qwerty"
        :controls="[]"
        :settings="mapSettings"
        :coords="address.map || mapCoords"
        :options="{ autoFitToViewport: 'always' }"
        @map-was-initialized="initMapHandler"
      >
        <ymap-marker
          :key="markerKey"
          :coords="address.map || mapCoords"
          marker-id="123"
          :options="{ draggable: true }"
          @dragend="onDragEnd"
        />
      </yandex-map>
    </div>
    <el-form-item label="Комментарий к адресу" class="mb-30">
      <el-input
        v-model="address.address_comment"
        placeholder="Введите комментарий"
      ></el-input>
    </el-form-item>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { mapSettings } from "@/helpers/SettingsYandex.js";
import { yandexMap, ymapMarker } from "vue-yandex-maps";

const tzlookup = require("tz-lookup");

export default {
  name: "ItemAddress",
  components: { yandexMap, ymapMarker },
  props: {
    index: {
      type: Number,
    },
    listAddress: {
      type: Array,
    },
  },
  data() {
    return {
      suggestView: "",
      textInput: "",
      markerKey: 1,
      mapCoords: [59.939095, 30.315868],
      address: {},
    };
  },
  computed: {
    ...mapState("service", ["service"]),
    mapSettings() {
      return mapSettings;
    },
  },
  watch: {
    address: {
      deep: true,
      handler() {
        this.setServiceAddress({
          index: this.index,
          value: this.address,
        });
      },
    },
  },
  created() {
    this.setAddress();
  },
  methods: {
    ...mapMutations("service", [
      "setServiceAddress",
      "setFullAddressService",
      "setService",
      "deleteAddress",
    ]),
    setAddress() {
      this.address = { ...this.service.address[this.index] };
      this.textInput = this.address.address_name;
    },
    deleteAdrs() {
      this.deleteAddress(this.index);
      this.setAddress();
    },
    initMapHandler() {
      // eslint-disable-next-line no-new
      this.suggestView = new ymaps.SuggestView(`map-search-${this.index}`, {
        results: 5,
      });
    },
    getCityName(geoObject) {
      return geoObject.getLocalities().length
        ? geoObject.getLocalities().join(", ")
        : geoObject.getAdministrativeAreas().join(", ");
    },
    async changeAddress() {
      this.suggestView.events.add("select", async (e) => {
        this.textInput = e.get("item").value;
        const res = await ymaps.geocode(this.textInput, { results: 1 });
        const firstGeoObject = res.geoObjects.get(0);
        const map = firstGeoObject.geometry.getCoordinates();
        this.markerKey += 1;

        if (this.textInput.length > 5) {
          // eslint-disable-next-line no-undef
          // const res = await ymaps.geocode(this.textInput, { results: 1 });
          // const firstGeoObject = res.geoObjects.get(0);

          // const map = firstGeoObject.geometry.getCoordinates();
          // this.markerKey += 1;

          this.address = {
            ...this.address,
            address_name: firstGeoObject.getAddressLine(),
            address_city: this.getCityName(firstGeoObject),
            address_country: firstGeoObject.getCountry(),
            address_timezone: tzlookup(map[0], map[1]),
            address_house_number: firstGeoObject.getPremiseNumber(),
            address_street: firstGeoObject.getThoroughfare(),
            map,
          };
        } else {
          this.address = {
            address_name: "",
            address_city: "",
            address_country: "",
            address_timezone: "",
            address_street: "",
            address_house_number: "",
          };
        }
      });
    },
    onDragEnd(event) {
      const coords = event.originalEvent.target.geometry.getCoordinates();
      this.setServiceAddress({
        field: "map",
        value: coords,
      });
      this.setAddressByCoords(coords);
    },
    async setAddressByCoords(coords) {
      try {
        const res = await ymaps.geocode(coords, { results: 1 });
        const firstGeoObject = res.geoObjects.get(0);
        this.text = firstGeoObject.getAddressLine();
        this.setService({
          field: "address",
          value: {
            ...this.service.address,
            ddress_house_number: firstGeoObject.getPremiseNumber(),
            address_street: firstGeoObject.getThoroughfare(),
            address_name: firstGeoObject.getAddressLine(),
            address_city: this.getCityName(firstGeoObject),
            address_country: firstGeoObject.getCountry(),
            address_timezone: tzlookup(
              this.service.address.map[0],
              this.service.address.map[1]
            ),
          },
        });
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.item-address {
  padding-top: 15px;
  border-top: 1px solid $--color-white-15;
}

.list-fields {
  &__row {
    margin: 0 -10px;
    flex-direction: row;
    &__actions {
      margin-bottom: 22px;
    }
  }
}

.map-wrapper {
  min-width: 100%;
  height: 250px;
  border-radius: 3px;
  overflow: hidden;
  margin-bottom: 30px;
}

::v-deep .el-form-item {
  width: 100%;
}
</style>
