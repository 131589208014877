<template>
  <div>
    <div v-if="isStatusError || isPaymentError" class="">
      <el-button class="w-full h-30 p-0 text-14" @click="isErrorInfo = true"
        ><span class="text-12">Информация об ошибке</span></el-button
      >
    </div>
    <el-dialog
      v-if="isErrorInfo"
      class="info-error"
      :visible.sync="isErrorInfo"
      :show-close="false"
      width="450px"
      :modal="false"
    >
      <PayError
        v-if="isStatusError || isPaymentError"
        :msg="isStatusError || isPaymentError"
        @close="isErrorInfo = false"
      />
    </el-dialog>
  </div>
</template>

<script>
import PayError from "@/views/Widgets/components/PayError";

export default {
  name: "InfoError",
  components: {
    PayError,
  },
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      isErrorInfo: false,
    };
  },
  computed: {
    isStatusError() {
      return this.item.booking_error_reason;
    },
    isPaymentError() {
      return this.item?.payment_data[0]?.error_message;
    },
  },
};
</script>
