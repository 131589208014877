<template>
  <div>
    <el-row>
      <el-col :span="24">
        <h3
          class="text-16 my-10 font-normal"
          :style="{
            color: isThemeWidget ? customStyle.active_text_color : '',
          }"
        >
          Выберите услугу
        </h3>
        <div class="group-radio">
          <div v-for="type in typeService" :key="type.id" class="w-full mb-10">
            <el-checkbox
              v-model="type.isActive"
              :label="type.category_name"
              class="radio__style"
              :class="{ 'custom-style': isThemeWidget }"
              @change="handleCheckAllChange(type.category_id)"
            />
            <el-radio-group
              v-if="type.isActive"
              v-model="type.selectedChild"
              class="ml-30"
            >
              <el-radio
                v-for="child in type.child_data"
                :key="child.id"
                v-model="type.isActive"
                :label="child.name"
                class="w-full"
                :class="{ 'custom-style': isThemeWidget }"
                :disabled="!type.isActive"
                @change="handleCheckChange(child, type.category_id)"
              />
            </el-radio-group>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "ServiceTreeGiven",
  props: {
    selectCell: {
      type: Object,
      default: () => {},
    },
    settings: {
      type: Object,
      default: () => {},
    },
    hash: {
      type: Object,
    },
    slotsValidity: { type: Object },
    customStyle: { type: Object },
    isThemeWidget: { type: String },
  },
  data() {
    return {
      checkType: [],
      checkSubType: [],
      typeService: [],
      isIndeterminate: false,
      hours: 0,
      places: 1,
      discount: null,
      prices: null,
      service: [],
    };
  },
  computed: {
    ...mapState("widget", ["remainedTickets"]),
    ...mapState("user", ["fixedService"]),

    isHoliday() {
      return this.selectCell.is_holiday;
    },
    step() {
      return Number(this.settings.data.session_duration);
    },

    maxPlaces() {
      const lastChild = this.settings.data.group_2[
        this.settings.data.group_2.length - 1
      ];
      return Number(lastChild.to) || 99999;
    },
    maxHours() {
      const lastChild = this.settings.data.group[
        this.settings.data.group.length - 1
      ];
      return Number(lastChild.to) || 99999;
    },
    minHours() {
      return Number(this.settings.data.minimum_rent) || 1;
    },
    minMinutes() {
      return Number(this.settings.data.group[0].from) || 1;
    },
    maxMinutes() {
      const lastChild = this.settings.data.group[
        this.settings.data.group.length - 1
      ];
      return Number(lastChild.to) || 99999;
    },

    unitCahnge() {
      switch (this.settings.data.time_unit) {
        case "days":
          return {
            text: "дней",
            param: "day",
            time: this.hours,
          };
        case "hours":
          return {
            text: "часов",
            param: "hour",
            time: this.hours,
          };
        case "minutes":
          return {
            text: "минут",
            param: "minute",
            time: this.selectCell.BookingType
              ? this.selectCell.BookingType.data.service_duration
              : this.hash.BookingType.data.service_duration,
          };

        default:
          return this.selectCell.BookingType
            ? this.selectCell.BookingType.data.time_unit
            : this.hash.BookingType.data.time_unit;
      }
    },
  },
  created() {
    const arr = this.settings.data.service ?? this.settings.data;
    this.typeService = arr.map((e, idx) => ({
      ...e,
      child_data: e.child_data_check,
      isActive: idx === 0,
      selectedChild: [], // e.child_data.map((child) => child.name),
    }));

    this.isClass();
    const selectType = this.typeService.find((e) => e.isActive);

    this.handleCheckAllChange(selectType.category_id);
  },

  methods: {
    ...mapActions("widget", ["saveBooking", "getRemainedTickets"]),
    handleCheckAllChange(id) {
      const service = this.typeService.find((serv) => serv.category_id === id);
      if (!service) return;
      this.typeService = this.typeService.map((e) => {
        if (e.category_id === id) {
          return {
            ...e,
            isActive: true,
            selectedChild: e.child_data.find((el) => el.id).name,
            child_data_check: e.child_data.find((el) => el.id),
          };
        }
        return {
          ...e,
          isActive: false,
          child_data_check: [],
          selectedChild: [],
        };
      });
      this.handleCheckChange();
    },

    handleCheckChange(obj, id) {
      const checkParam = this.typeService
        .filter((e) => e.isActive === true)
        .map((q) => {
          //  if (q.category_id === id) {
          return {
            ...q,
            child_data_check: id ? obj : q.child_data_check,
          };
          //  }
          // return q;
        });
      // const findInterval = this.settings.data.group.find((el) => {
      //   const to = el.to ? Number(el.to) : this.maxHours;
      //   return this.hours <= to;
      // });
      // const findIntervalDiscount = this.settings.data.group_2.find((el) => {
      //   const to = el.to ? Number(el.to) : 99999;
      //   return this.places <= to;
      // });

      this.$emit("setSlotParam", {
        book_info: {
          service: {
            //  ...checkParam[0],
            // places: this.places,
            name: checkParam[0].child_data_check.name,
            id: checkParam[0].child_data_check.id,
            price: 0,
            duration: this.selectCell.BookingType
              ? this.selectCell.BookingType.data.service_duration
              : this.hash.BookingType.data.service_duration,
            unit: this.unitCahnge.param,
          },
        },
        resourceHash: this.selectCell.resource_id,
        date: this.selectCell.date,
        time: this.selectCell.time,
        // cnt: this.hours,
        // tickets: this.places,
      });

      this.$emit("setService", {
        // ...checkParam[0],
        // places: this.places,
        name: checkParam[0].child_data_check.name,
        id: checkParam[0].child_data_check.id,
        price: 0,
        duration: this.selectCell.BookingType
          ? this.selectCell.BookingType.data.service_duration
          : this.hash.BookingType.data.service_duration,
        unit: this.unitCahnge.param,
        comment: checkParam[0].child_data_check.comment,
      });
    },
    changePlaces() {
      const findInterval = this.settings.data.group_2.find((el) => {
        const to = el.to ? Number(el.to) : 99999;
        return this.places <= to;
      });
      this.$emit("setSlotParam", {
        book_info: {
          rent: {
            places: this.places,
            price: this.prices,
            duration: this.unitCahnge.time,
            unit: this.unitCahnge.param,
          },
        },

        resourceHash: this.selectCell.resource_id,
        date: this.selectCell.date,
        time: this.selectCell.time,
        cnt: this.hours,
        tickets: this.places,
      });

      if (findInterval.discount) {
        this.$emit("setDiscount", {
          discount: findInterval.discount,
          unit: findInterval.discount_unit,
        });
      }

      this.$emit("setPlaces", this.places);
      this.$emit("setRent", {
        price: this.prices,

        discount: findInterval.discount ? findInterval.discount : null,
        discount_unit: findInterval.discount
          ? findInterval.discount_unit
          : null,
        places: this.places,
        duration: this.unitCahnge.time,
        unit: this.unitCahnge.param,
      });
    },
    isClass() {
      document.body.style.setProperty(
        "--colorCustom",
        `${this.customStyle.active_text_color}`
      );
      document.body.style.setProperty(
        "--colorCustomBackground",
        `${this.customStyle.inactive_text_color}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";
:root {
  --colorCustom: "white";
  --colorCustomBackground: "green";
}
.bk-info {
  margin-bottom: 6px;
  &__title {
    flex-grow: 1;
    width: auto;
  }
  &__info {
    flex-grow: 0;
  }
}
.title-widget {
  margin-top: 12px;
}
.custom-style {
  ::v-deep .el-input__inner {
    color: var(--colorCustom);
  }

  ::v-deep .el-input-number__increase,
  ::v-deep .el-input-number__decrease {
    background: var(--colorCustomBackground);
    color: var(--colorCustom);
  }
  ::v-deep .el-input-number__increase:hover,
  ::v-deep .el-input-number__decrease:hover {
    color: var(--colorCustomButton);
  }
  ::v-deep .el-radio__label {
    border-color: var(--colorCustom);
  }
  ::v-deep .el-radio__input > .el-radio__inner {
    border-color: var(--colorInactive);
  }
  ::v-deep .el-radio__input.is-checked > .el-radio__inner {
    border-color: var(--colorCustomButton);
    background: var(--colorCustomButton);
  }
  ::v-deep .el-checkbox__input + .el-checkbox__label {
    color: var(--colorCustom);
  }

  ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
    color: var(--colorCustom);
  }
  ::v-deep .el-checkbox__input .el-checkbox__inner {
    border-color: var(--colorCustom);
  }
  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: var(--colorCustomButton);
    border-color: var(--colorCustomButton);
  }
  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner::after {
    border-color: #fff;
  }
  ::v-deep
    .el-checkbox:hover
    .el-checkbox__input.is-checked
    .el-checkbox__inner {
    border-color: var(--colorCustomButton);
  }
  ::v-deep .el-radio__inner::after {
    background-color: #fff;
  }
  ::v-deep .el-radio:hover.el-radio__inner {
    border-color: var(--colorCustomButton);
  }
}
::v-deep .el-radio__input + .el-radio__label {
  color: var(--colorCustom);
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: var(--colorCustom);
}
</style>
