<template>
  <div
    ref="widget"
    class="custom-block schedule"
    :style="{ height: isShowForm ? `${formHeight}px` : false }"
  >
    <div class="relative">
      <div class="my-15">
        <el-row :gutter="10">
          <el-col :span="12">
            <p
              class="text-14 mb-7 leading-16"
              :style="{
                color: isIndividualTheme ? custom.active_text_color : '',
              }"
            >
              Город<span class="danger">*</span>
            </p>
            <el-select
              v-model="checkTown"
              class="w-full"
              popper-class="customSelect"
              :style="{
                background: isIndividualTheme
                  ? custom.background_cells_fields
                  : '',
                color: isIndividualTheme ? custom.active_text_color : '',
              }"
              :class="{ customPoperSelec: isIndividualTheme }"
            >
              <el-option
                v-for="(item, index) in isCitiesUbrr"
                :key="`type-${index}`"
                :label="item.name"
                :value="item.id"
                :class="{ customPoperSelec: isIndividualTheme }"
              >
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="12">
            <p
              class="text-14 mb-7 leading-16 text-white-500"
              :style="{
                color: isIndividualTheme ? custom.active_text_color : '',
              }"
            >
              Отделение<span class="danger">*</span>
            </p>

            <el-select
              ref="selectCustom"
              v-model="checkDepartment"
              class="w-full"
              popper-class="customSelect"
              :style="{
                background: isIndividualTheme
                  ? custom.background_cells_fields
                  : '',
                color: isIndividualTheme ? custom.active_text_color : '',
              }"
              :class="{ customPoperSelec: isIndividualTheme }"
              @change="setCheckDepartment(checkDepartment)"
            >
              <el-option
                v-for="(item, index) in isMaket
                  ? null
                  : isBranchesUbrr[checkTown]"
                :key="`type-${index}`"
                :label="item.name"
                :value="item.id"
                :class="{ customPoperSelec: isIndividualTheme }"
              >
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{
                  item.address_short
                }}</span>
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
      <div
        v-if="checkTown && checkDepartment"
        class="flex my-30 flex-row swiper__wrapper justify-between"
        :class="{ 'flex-col': isMaket }"
      >
        <div
          class="schedule__dates-datepicker sm:mb-10"
          :style="{
            background: isIndividualTheme ? custom.background_cells_fields : '',
            color: isIndividualTheme ? custom.active_text_color : '',
            width: $mq.largePhone ? '100%' : '',
          }"
        >
          <el-date-picker
            v-model="scheduleDate"
            type="date"
            :class="{ customPoper: isIndividualTheme }"
            popper-class="customSelect"
            :clearable="false"
            format="dd.MM.yyyy"
            :picker-options="scheduleDateOptions"
            placeholder="Выберите дату"
            @change="changeScheduleDate($event)"
          />
        </div>
        <div
          class="schedule__dates-swiper"
          :style="{ width: $mq.largePhone ? '100%' : '' }"
          :class="{ 'isMaket-swiper': isMaket }"
        >
          <div
            class="schedule__slide-prev"
            :style="{
              background: isIndividualTheme
                ? custom.background_cells_fields
                : '',
              color: isIndividualTheme ? custom.active_text_color : '',
            }"
            @click="sliderNavigate({ isMore: false })"
          >
            <svg-icon
              icon-name="chevron"
              class-name="rotate-minus-90"
              width="12"
              height="7"
            ></svg-icon>
          </div>
          <swiper ref="swiperDates" class="swiper" :options="swiperOption">
            <swiper-slide
              v-for="(slide, index) in slides"
              :key="`slide-${index}`"
              :class="{ active: slide.active }"
              :style="[
                (widgetPublic.typeTheme === 'light' && slide.active) ||
                (widgetPublic.typeTheme === 'dark' && slide.active) ||
                (widgetPublic.typeTheme === 'individual' && slide.active)
                  ? customBlockActive
                  : customBlock,
              ]"
              @click.native="clickOnSlider(slide, index)"
            >
              <span
                class="schedule__date"
                :class="{ active: slide.active }"
                :style="{
                  color:
                    isIndividualTheme && !slide.active
                      ? custom.active_text_color
                      : custom.button_text_color,
                }"
              >
                {{ slideDate(slide.date) }}
              </span>

              <span
                class="schedule__day"
                :style="{
                  color:
                    (widgetPublic.typeTheme === 'light' && slide.active) ||
                    (widgetPublic.typeTheme === 'dark' &&
                      slide.active &&
                      !slide.active)
                      ? custom.button_text_color
                      : custom.inactive_text_color,
                }"
              >
                {{ slide.weekDay }}
              </span>
            </swiper-slide>
          </swiper>

          <div
            class="schedule__slide-next"
            :style="{
              background: isIndividualTheme
                ? custom.background_cells_fields
                : '',
              color: isIndividualTheme ? custom.active_text_color : '',
            }"
            @click="sliderNavigate({ isMore: true })"
          >
            <svg-icon
              icon-name="chevron"
              class-name="rotate-90"
              width="12"
              height="7"
            ></svg-icon>
          </div>
        </div>
      </div>
      <div class="w-full">
        <el-alert
          class="customService w-full text-center"
          center
          show-icon
          :closable="false"
          ><span
            v-if="!checkTown || !checkDepartment"
            class="customService link_style"
          >
            Для записи онлайн выберите, пожалуйста, город и отделение.
          </span>
          <span v-else-if="selectSell.address" class="customService link_style">
            Обратите внимание, что запись онлайн доступна только на следующий
            день. <br />
            Адрес отделения: {{ selectSell.address }}.
          </span>

          <a
            v-if="checkTown && checkDepartment && selectSell"
            class="underline link_style"
            :href="selectSell.address_link"
            current
            target="_blank"
            >Посмотреть на карте
          </a></el-alert
        >
      </div>
      <div
        v-if="errorMsgDate && !isMaket"
        :style="{ color: isIndividualTheme ? custom.active_text_color : '' }"
        class="mt-30 flex justify-center"
      >
        {{ errorMsgDate }}
      </div>
      <div v-if="isLoading && !errorMsgDate && !isMaket">
        <spinner
          v-if="isLoading"
          :custom-color="{ border: `${custom.active_buttons_background}` }"
        />
        <div class="flex justify-center mt-10">
          <span
            class="text-14"
            :style="{
              color: isIndividualTheme ? custom.active_text_color : 'red',
            }"
            >Подождите, пожалуйста. Загружаем расписание</span
          >
        </div>
      </div>
      <div v-if="checkTown && checkDepartment && !isLoading">
        <div
          v-for="(item, i) in schedule"
          :key="`schedule-${i}`"
          class="schedule__item xs:flex-col"
        >
          <div class="item__header">
            <div class="item__header-info"></div>
          </div>
          <div class="item__schedule">
            <div
              v-if="item.schedule.success === false"
              class="w-full justify-center"
            >
              <no-data
                :style="{
                  color: isIndividualTheme ? custom.active_text_color : '',
                }"
                size="small"
                :text="
                  item.schedule.msg || 'Расписание на этот день отсутствует'
                "
              />
            </div>
            <div v-else class="schedule__item-schedule">
              <CellSchedule
                v-for="(slot, index) in item.schedule"
                :key="index"
                :value="slot"
                :is-maket="isMaket"
                :custom-style="custom"
                :size="isIndividualTheme ? custom.font_size : 'small'"
                :is-type-theme="isIndividualTheme"
                :show-price="showPrice"
                :add-pristavka="addPristavka"
                @click="onClick(item, slot)"
              />
            </div>
          </div>
        </div>
      </div>
      <el-dialog
        v-if="isShowForm"
        ref="widgetDialogModal"
        class="customModal"
        :visible.sync="isShowForm"
        :show-close="false"
      >
        <TemplateFormBron
          class="w-full"
          :title="isMaket ? `Игра престолов` : isNameBranchServices"
          :style-custom="custom"
          :is-type-theme="isIndividualTheme"
          :total-price="isMaket ? 1600 : totalPrice"
          :list-field-show="widgetPublic.fieldForm"
          :select-cell="selectCell"
          :is-maket="isMaket"
          :loading="loading"
          :is-service="isService"
          :disabled="isDisabled"
          :select-service="selectService"
          :formated-date="formatedDate"
          @close="isShowForm = false"
          @booking="broni"
        >
          <div
            v-if="
              selectCell.BookingType.booking_type_sub !==
              'service_tree_from_settings'
            "
          >
            <div class="row-info xs:text-12">
              <div
                class="row-info__title"
                :style="{
                  color: isIndividualTheme ? custom.inactive_text_color : '',
                }"
              >
                Дата
              </div>
              <div
                class="row-info__value"
                :style="{
                  color: isIndividualTheme ? custom.active_text_color : '',
                }"
              >
                {{ isMaket ? "04.02.2021" : formatedDate }}
              </div>
            </div>
            <div class="row-info xs:text-12">
              <div
                class="row-info__title"
                :style="{
                  color: isIndividualTheme ? custom.inactive_text_color : '',
                }"
              >
                Время
              </div>
              <div
                class="row-info__value"
                :style="{
                  color: isIndividualTheme ? custom.active_text_color : '',
                }"
              >
                {{ isMaket ? "10:30" : selectCell.time }}
              </div>
            </div>
            <div v-if="isAdressServices" class="row-info xs:text-12">
              <div
                class="row-info__title"
                :style="{
                  color: isIndividualTheme ? custom.inactive_text_color : '',
                }"
              >
                Адрес
              </div>

              <div
                class="row-info__value"
                :style="{
                  color: isIndividualTheme ? custom.active_text_color : '',
                }"
              >
                {{ isAdressServices }}
              </div>
            </div>
          </div>
          <div
            v-if="
              selectCell.BookingType.booking_type_sub !==
              'service_tree_from_settings'
            "
            class="hl"
            :style="{
              background: widgetPublic.typeTheme
                ? custom.background_cells_fields
                : '',
            }"
          ></div>
          <components
            :is="originData.component"
            v-model="person"
            :select-cell="selectCell"
            :hash="selectCell"
            :settings="selectCell.BookingType"
            :slots-validity="slotsValidity"
            :custom-style="custom"
            :is-theme-widget="widgetPublic.typeTheme"
            @isDis="isDis"
            @setPrice="setPrice"
            @setService="setService"
            @setTariff="setTariff"
            @setTickets="setTickets"
            @setHours="setHours"
            @setPlaces="setPlaces"
            @setDiscount="setDiscount"
            @setRent="setRent"
            @setRentInfo="setRentInfo"
            @SetForGroup="setForGroup"
            @setSlotParam="getSlotParam"
          ></components>
        </TemplateFormBron>
      </el-dialog>
    </div>
    <el-dialog
      v-if="isShowPay"
      :visible.sync="isShowPay"
      :show-close="false"
      width="450px"
      @reloadWidget="isShowPay = false"
    >
      <!-- v-if="dataPay.success && !isErrorDataPay"
 -->
      <PayWidget
        v-if="dataPay.success"
        :widget-theme="widgetPublic.typeTheme"
        :custom-class="custom"
        @close="isShowPay = false"
        @reloadWidget="reloadWidget"
      />
      <PayError
        v-if="!dataPay.success"
        :widget-theme="widgetPublic.typeTheme"
        :custom-class="custom"
        :data-pay="dataPay.params"
        :msg="dataPay.msg || dataPay.params.error_title"
        @close="isShowPay = false"
      />
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import { AJAX_GET } from "../../../units/ajax";

import NoData from "@/components/NoData.vue";
import TemplateFormBron from "@/components/TemplateFormBron";
import Tag from "@/components/Tag";
import CellSchedule from "@/components/CellSchedule.vue";
import { format } from "date-fns";
import { scheduleOneGrid, scheduleMultiGrid } from "@/helpers/maket.js";

import moment from "moment";
import ServiceTreeGiven from "@/views/Widgets/components/forWidget/ServiceTreeGiven";
import NotLimitSeats from "@/views/Widgets/components/forWidget/NotLimitSeats.vue";
import DepSeatsPlace from "@/views/Widgets/components/forWidget/DepSeatsPlace.vue";
import DepSeatsGroup from "@/views/Widgets/components/forWidget/DepSeatsGroup.vue";
import DepServiceDuration from "@/views/Widgets/components/forWidget/DepServiceDuration.vue";
import DepTarifSeats from "@/views/Widgets/components/forWidget/DepTarifSeats.vue";
import GroupLimitAll from "@/views/Widgets/components/forWidget/GroupLimitAll.vue";
import GroupLimitType from "@/views/Widgets/components/forWidget/GroupLimitType.vue";
import GroupLimitGeneral from "@/views/Widgets/components/forWidget/GroupLimitGeneral.vue";
import RentDepTime from "@/views/Widgets/components/forWidget/RentDepTime.vue";
import RentDepTimeSeats from "@/views/Widgets/components/forWidget/RentDepTimeSeats.vue";
import PayWidget from "@/views/Widgets/components/PayWidget.vue";
import PayError from "@/views/Widgets/components/PayError.vue";

import { setFavicon } from "@/helpers/forFavicon.js";

export default {
  name: "ScheduleWidgetServicesForm",
  components: {
    ServiceTreeGiven,
    NotLimitSeats,
    DepSeatsPlace,
    DepSeatsGroup,
    DepServiceDuration,
    DepTarifSeats,
    GroupLimitAll,
    GroupLimitType,
    GroupLimitGeneral,
    RentDepTime,
    RentDepTimeSeats,
    PayWidget,
    PayError,
    CellSchedule,
    Tag,
    NoData,
    TemplateFormBron,
  },
  props: {
    isMaket: Boolean,
    showPrice: Boolean,
  },
  data() {
    return {
      formHeight: "",
      isNameBranchServices: null,
      checkTown: null,
      checkDepartment: null,
      swiperOption: {
        slidesPerView: this.isMaket ? 3 : 10,
        slidesPerGroup: 8,
        breakpoints: {
          2500: {
            slidesPerView: 14,
          },
          1440: {
            slidesPerView: 10,
          },
          1024: {
            slidesPerView: 7,
          },
          // 1000: {
          //   slidesPerView: 7,
          //   slidesPerGroup: 5,
          // },
          768: {
            slidesPerView: 7,
            slidesPerGroup: 5,
          },
          480: {
            slidesPerView: 5,
            slidesPerGroup: 5,
          },

          379: {
            slidesPerView: 3,
            slidesPerGroup: 3,
          },
        },

        slideToClickedSlide: true,
      },

      scheduleDateOptions: {
        disabledDate: (time) => {
          return time < moment().subtract(1, "day").toDate();
        },
      },
      dis: null,
      isLoading: false,
      loading: false,
      isShowForm: false,
      isShowPay: false,
      places: 1,
      person: 1,
      hours: null,
      rent: null,
      rentPlacesInfo: null,
      selectService: null,
      selectTariff: null,
      selectTickets: null,
      group: null,
      price: null,
      discount: 0,
      isOpen: false,
      scheduleDate: null,
      addOptCheck: [],
      addOptInput: [],
      addPersons: 0,
      priceAddPersons: 0,
      analyticsYandex: null,
      analyticsGoogle: null,

      slides: [],
      selectCell: {},
      schedule: [],
    };
  },

  computed: {
    ...mapState("widget", [
      "widget",
      "widgetMulti",
      "widgetPublic",
      "dataPay",
      "slotsValidity",
      "errorMsgDate",
    ]),
    ...mapGetters("widget", [
      "getFaviconLicense",
      "isNew",
      "isCitiesUbrr",
      "isBranchesUbrr",
    ]),
    ...mapGetters("service", ["isHaveAddOpt", "addPristavka"]),
    // isNameBranchServices() {
    //   return this.isBranchesUbrr[this.checkTown].find(
    //     (e) => e.id === this.selectCell.ubrir_id ?? this.checkDepartment
    //   )?.name;
    // },
    isAdressServices() {
      return "";
      // return `г. ${
      //   this.widgetPublic.ubrir_cities.find((e) => e.name).name
      // }, отделение ${
      //   this.widgetPublic.ubrir_branches[
      //     this.widgetPublic.ubrir_cities[0].id
      //   ].find((e) => e.name).name
      // } `;
    },
    service() {
      if (!this.isMaket) {
        return this.widgetPublic?.resources[0]; // this.setServiceForSchedule();
      }
      return {
        BookingType: {
          booking_type: "individual",
          booking_type_sub: "individual_does_not_depend_on_the_number_of_seats",
        },
        active: true,
        additional_options: [],
      };
    },
    customBlockActive() {
      return {
        background: this.custom.active_buttons_background,
        color: this.custom.active_text_color,
      };
    },
    customBlock() {
      return {
        background: this.custom.background_cells_fields,
        color: this.custom.active_text_color,
      };
    },
    isIndividualTheme() {
      // УБРАЛ ДЛЯ КАСТОМИЗАЦИИ ВИДЖЕТА ВНЕ ЗАВИСИМОСТИ ОТ  ТЕМЫ
      // this.widgetPublic.typeTheme === "individual"
      return !!this.widgetPublic.typeTheme || this.isMaket;
    },

    custom() {
      const designWidgetParams = this.widgetPublic.design ?? this.widget.design;
      return {
        ...designWidgetParams,
        // ...this.widgetPublic.design,
      };
      // return {
      //   ...this.widgetPublic.design,
      // };
      // return {
      //   background: `${this.widgetPublic.design.block_background}`,
      //   color: `${this.widgetPublic.design.button_text_color}`,
      //   background_fields: `${this.widgetPublic.design.background_cells_fields}`,
      //   // opacity: `0.${this.widgetPublic.design.opacity}`,
      // };
    },
    isDisabled() {
      return this.slotsValidity || !this.dis
        ? this.slotsValidity?.success
        : true;
    },

    isErrorDataPay() {
      return this.dataPay?.payment_data[0]?.status_name ===
        "Время оплаты истекло"
        ? this.dataPay?.payment_data[0]?.status_name
        : false;
    },
    isService() {
      return (
        this.service.BookingType.booking_type_sub === "service_tree_is_given" ||
        this.service.BookingType.booking_type_sub ===
          "service_tree_from_settings"
      );
    },
    isGroup() {
      return (
        this.selectCell.BookingType.booking_type_sub ===
          "group_same_seat_limit_for_all_sessions" ||
        this.selectCell.BookingType.booking_type_sub ===
          "group_the_seat_limit_is_set_for_each_sess" ||
        this.selectCell.BookingType.booking_type_sub ===
          "individual_depends_on_the_number_of_seats"
      );
    },

    isIndividual() {
      return (
        this.selectCell.BookingType.booking_type_sub ===
          "individual_does_not_depend_on_the_number_of_seats" ||
        this.selectCell.BookingType.booking_type_sub ===
          "individual_depends_on_the_number_of_seats"
      );
    },

    formatedDate() {
      return this.selectCell.date
        ? format(new Date(this.selectCell.date), "dd.MM.yyyy")
        : "";
    },
    originData() {
      switch (this.selectCell.BookingType.booking_type_sub) {
        case "service_tree_is_given":
          return {
            component: ServiceTreeGiven,
            priceAfter: "",
            priceBefore: "",
            isHidePrice: true,
            totalPrice: (srcObj) => 0, // srcObj.price,
          };
        case "service_tree_from_settings":
          return {
            component: ServiceTreeGiven,
            priceAfter: "",
            priceBefore: "",
            isHidePrice: true,
            totalPrice: (srcObj) => 0, // srcObj.price,
          };
        case "individual_does_not_depend_on_the_number_of_seats":
          return {
            component: NotLimitSeats,
            priceAfter: "",
            priceBefore: "",
            isHidePrice: true,
            totalPrice: (srcObj) => srcObj.price,
          };
        case "individual_depends_on_the_number_of_seats":
          // eslint-disable-next-line no-case-declarations
          const isForPlace =
            this.selectCell.BookingType.data.price === "for_a_place";
          return {
            component: isForPlace ? DepSeatsPlace : DepSeatsGroup,
            priceAfter: "от",
            priceBefore: isForPlace ? "за участника" : "за группу",
            isHidePrice: true,
            totalPrice: (srcObj) => {
              if (isForPlace)
                return (
                  srcObj.person * srcObj.price
                  // +
                  // srcObj.selectCell?.price_additional
                );
              // eslint-disable-next-line no-nested-ternary
              return srcObj.group
                ? srcObj.selectCell.is_holiday
                  ? srcObj.group.price +
                    srcObj.addPersons * srcObj.priceAddPersons +
                    srcObj.selectCell.weekend_price_additional
                  : // srcObj.selectCell?.weekend_price_additional +
                    // srcObj.selectCell?.weekend_price_additional
                    srcObj.group.price +
                    srcObj.addPersons * srcObj.priceAddPersons +
                    // srcObj.selectCell?.weekend_price_additional +
                    srcObj.selectCell?.price_additional
                : 0;
            },
          };
        case "individual_depends_on_the_service_and_its_duration":
          return {
            component: DepServiceDuration,
            priceAfter: "",
            priceBefore: "",
            isHidePrice: false,
            // totalPrice: (srcObj) => srcObj.person * srcObj.price,
            totalPrice: (srcObj) => srcObj.selectService?.price,
          };
        case "individual_depends_on_tariff_and_number_of_seats":
          return {
            component: DepTarifSeats,
            priceAfter: "от",
            priceBefore: "",
            isHidePrice: true,
            totalPrice: (srcObj) => srcObj.person * srcObj.price,
          };
        case "group_same_seat_limit_for_all_sessions":
          return {
            component:
              this.selectCell.BookingType.data.seat_limit === "general_limits"
                ? GroupLimitGeneral
                : GroupLimitAll,
            priceAfter: "от",
            priceBefore: "",
            isHidePrice: true,
            totalPrice: (srcObj) => {
              return srcObj.selectTickets
                ? srcObj.selectTickets.reduce((sum, current) => {
                    return sum + current.select_ticket * current.price;
                  }, 0)
                : 0;
            },
          };
        case "group_the_seat_limit_is_set_for_each_session":
          return {
            component: GroupLimitType,
            priceAfter: "от",
            priceBefore: "",
            isHidePrice: true,
            totalPrice: (srcObj) => {
              return srcObj.selectTickets
                ? srcObj.selectTickets.reduce((sum, current) => {
                    return sum + current.select_ticket * current.price;
                  }, 0)
                : 0;
            },
          };
        case "rent_depends_only_on_time":
          return {
            component: RentDepTime,
            priceAfter: "от",
            priceBefore: "за час",
            isHidePrice: true,
            totalPrice: (srcObj) => srcObj.price * srcObj.hours,
          };
        case "rent_depends_on_time_and_place":
          return {
            component: RentDepTimeSeats,
            priceAfter: "от",
            priceBefore: "за место в час",
            isHidePrice: true,

            totalPrice: (srcObj) => {
              const isProcent = srcObj?.discount?.unit === "procent";
              const isDiscount =
                srcObj?.discount && isProcent
                  ? (srcObj?.price * srcObj?.discount?.discount) / 100
                  : srcObj?.discount?.discount;
              const isHoliday = this.selectCell.is_holiday;

              return isDiscount
                ? (isHoliday
                    ? srcObj.price +
                      this.selectCell.weekend_price_additional -
                      isDiscount
                    : srcObj.price +
                      this.selectCell.price_additional -
                      isDiscount) *
                    srcObj.places *
                    srcObj.hours
                : srcObj.price;

              //       // (srcObj.price - isDiscount) * srcObj.hours * srcObj.places +
              //       // Number(isDiscount)
            },
            //     // srcObj.price * (srcObj.hours * srcObj.places)),
          };
        default:
          return {};
      }
    },
    showHeaderAddOptions() {
      return this.listAddOptCheck.length || this.addOptInput.length;
    },

    totalPrice() {
      const sumCheck = this.addOptCheck.reduce((sum, current) => {
        return sum + current.price;
      }, 0);
      const sumInput = this.addOptInput.reduce((sum, current) => {
        return sum + current.selected * current.price;
      }, 0);
      const isProcent = this.discount?.unit === "procent";
      const isDiscount =
        this.discount && isProcent
          ? (this.originData.totalPrice(this.$data) * this.discount.discount) /
            100
          : this.discount?.discount;
      return isDiscount
        ? this.originData.totalPrice(this.$data) - sumCheck + sumInput
        : this.originData.totalPrice(this.$data) + sumCheck + sumInput;
    },
    listAddOptCheck() {
      return this.selectCell.additional_options.filter((item) => !item.amount);
    },

    isShowTags() {
      return this.widgetPublic.showTag;
    },
    isShowAddress() {
      return this.widgetPublic.showAddress;
    },
    isShowPhoto() {
      return this.widgetPublic.showPhoto;
    },

    sheduleDatesSlider() {
      return this.$refs.sheduleDates.swiper;
    },
    // errorDateMsg() {
    //   return console.log();
    // },
  },
  watch: {
    checkTown() {
      if (this.isBranchesUbrr[this.checkTown].length === 1) {
        this.checkDepartment = this.isBranchesUbrr[this.checkTown][0].id;
        this.setCheckDepartment(this.isBranchesUbrr[this.checkTown][0].id);
      } else {
        this.checkDepartment = null;
      }
    },
  },
  async created() {
    if (!this.isMaket) {
      this.getPayInfo();
      // this.hashs = this.widgetPublic.resources.map((resource) => resource.hash);
      setFavicon({
        title: this.widgetPublic.title,
        href: this.getFaviconLicense,
      });
      this.isLoading = true;

      if (this.$route.query.day) {
        const day = this.$route.query.day.split("?", 1).find((el) => {
          return el;
        });
        this.scheduleDate = day;
        this.$router.replace(`?day=${this.$route.query.day}`);
      } else {
        this.scheduleDate = moment().add(1, "days").format("YYYY-MM-DD"); // moment().format("YYYY-MM-DD");
      }

      await this.generateDatesSlider();
      // await this.getScheduleByPeriodOrByDateMulti({
      //   hashs: [this.service.hash],
      //   date: this.scheduleDate,
      // });
      // await this.widgetGetScheduleByPeriodOrByDate({
      //   hash: this.service.hash,
      //   widgetHash: this.$route.params.hash,
      // });

      // if (
      //   this.widgetPublic.analytics_yandex &&
      //   this.widgetPublic.analytics_google
      // ) {
      //   this.analyticsYandex = this.widgetPublic.analytics_yandex;
      //   this.analyticsGoogle = this.widgetPublic.analytics_google;
      // }
    }
    this.getParamsSchedule();

    if (this.isMaket) {
      const slideTestParam = [
        {
          date: moment().format("YYYY-MM-DD"),
          weekDay: "Пн",
          active: true,
        },
        {
          date: moment().format("YYYY-MM-DD"),
          weekDay: "Вт",
          active: false,
        },
        {
          date: moment().format("YYYY-MM-DD"),
          weekDay: "Ср",
          active: false,
        },
      ];
      this.slides = slideTestParam;
      this.setField({
        field: "widgetMulti",
        value: {
          ...scheduleMultiGrid,
        },
      });
      this.schedule = this.widgetMulti;
      this.scheduleDate = moment().format("YYYY-MM-DD");
    }
    this.isClass();
    this.isLoading = false;
  },
  updated() {
    this.postMessage(
      this.isShowForm
        ? this.$refs.widgetDialogModal.$refs?.dialog.clientHeight
        : this.$refs.widget.clientHeight
    );
  },

  methods: {
    ...mapMutations("service", ["setField"]),
    ...mapMutations("widget", ["setField"]),
    ...mapActions("service", ["widgetGetScheduleByPeriodOrByDate"]),
    ...mapActions("widget", [
      "saveBooking",
      "checkSlotsValidity",
      "getScheduleByPeriodOrByDateMulti",
    ]),
    setServiceForSchedule(obj) {
      return obj || this.widgetPublic?.resources[0];
    },
    async getScheduleByParams(hash) {
      this.isLoading = true;
      await this.getScheduleByPeriodOrByDateMulti({
        hashs: [hash],
        date: this.scheduleDate,
      });
      this.isLoading = false;
    },
    async setCheckDepartment(id) {
      this.slides.forEach((slide) => {
        slide.active = false;
      });
      const searchBranchHash = this.isBranchesUbrr[this.checkTown].find(
        (e) => e.id === id
      );
      const resourceCheck = this.widgetPublic.resources.find(
        (res) => res.hash === searchBranchHash.resource_hash
      );
      this.selectSell = resourceCheck;
      this.isNameBranchServices = this.selectSell.name;

      this.setServiceForSchedule(resourceCheck);
      await this.generateDatesSlider(searchBranchHash.resource_hash);
      await this.getScheduleByParams(searchBranchHash.resource_hash);
      this.getParamsSchedule();
    },
    isClass() {
      document.body.style.setProperty(
        "--colorCustom",
        `${this.custom.active_text_color}`
      );
      document.body.style.setProperty(
        "--colorCustomBackground",
        `${this.custom.inactive_text_color}`
      );
      document.body.style.setProperty(
        "--colorCustomButton",
        `${this.custom.active_buttons_background}`
      );
      document.body.style.setProperty(
        "--colorCustomTextInactive",
        `${this.custom.active_buttons_background}`
      );
      if (this.custom.background_cells_fields) {
        document.body.style.setProperty(
          "--colorCustomInput",
          `${this.custom.background_cells_fields}`
        );
      } else {
        document.body.style.setProperty(
          "--colorCustomInput",
          "rgba(255, 255, 255, 0.1)"
        );
      }

      document.body.style.setProperty(
        "--colorCustomBlockBackground",
        `${this.custom.block_background}`
      );
    },
    reloadWidget() {
      this.$emit("reloadWidget");
      this.getSchedule({
        hash: this.selectCell.hash,
        widgetHash: this.$route.params.hash,
      });
    },
    postMessage(height) {
      this.formHeight = height;
      const win = window.parent;
      win.postMessage({ hash: this.widgetPublic.hash, height }, "*");
    },
    getSlotParam(obj) {
      this.checkSlotsValidity(obj);
    },
    setPrice(price) {
      const addCoast = this.selectCell.is_holiday
        ? this.selectCell.weekend_price_additional
        : this.selectCell.price_additional;
      this.price = price + addCoast;
    },
    setService(service) {
      this.price = null;
      this.person = null;
      this.selectService = { ...service };
    },
    setTariff(tariff) {
      this.selectTariff = { ...tariff };
    },
    setTickets(tickets) {
      this.selectTickets = [...tickets];
    },
    setHours(hours) {
      this.hours = hours;
    },
    setPlaces(places) {
      this.places = places;
    },
    setRent(rent) {
      this.rent = rent;
    },

    setRentInfo(info) {
      this.rentPlacesInfo = info;
    },
    setDiscount(discount) {
      this.discount = discount;
    },
    setForGroup(obj) {
      this.price = null;
      this.person = null;
      this.group = obj.group;
      this.addPersons = obj.addPersons;
      this.priceAddPersons = obj.priceAddPersons;
    },
    async getPayInfo() {
      const hashParam = this.$route.query.day?.split("?hash=")
        ? this.$route.query.hash // day.split("?hash=")
        : this.$route.params.hash;

      const param = this.$route.query;
      if (param.hash) {
        await AJAX_GET("/api/v1/booking/get", {
          params: {
            booking_id: hashParam || this.$route.params.hash, // this.$route.query.hash
          }, // hashParam[1] },
        }).then((response) => {
          this.setField({ field: "dataPay", value: response.data });
          this.dataPay.hidden = true;
          this.dataPay.success = response.data.is_paid;
          this.dataPay.name = response.data.name;
          this.dataPay.dateTime = response.data.date;
          this.dataPay.dateAndTime = response.data.time;
          this.dataPay.phone = response.data.phone;
          this.dataPay.price = response.data.final_price;
          this.dataPay.address = response.data.address;
          this.dataPay.currency = response.data.currency;
          this.dataPay.payment = response.data.payment_type;
          this.dataPay.payment_type_name = response.data.payment_type_name;
          this.dataPay.modal_text = response.data.modal_text;
          this.dataPay.consist = response.data.consist;
          this.dataPay.payment_data = response.data.payment_data;
          this.dataPay.params = response.data;
          this.isShowPay = true;

          this.$router.push({ name: "public-widget" });
        });
      }
    },

    isDisabledDate(slot) {
      if (
        !!slot &&
        moment(this.scheduleDate).format("YYYY-DD-MM") ===
          moment().format("YYYY-DD-MM")
      ) {
        return moment(slot.time, "HH:mm") < moment();
      }
      if (
        moment(this.scheduleDate).format("YYYY-DD-MM") <
          moment().format("YYYY-DD-MM") &&
        moment(this.scheduleDate).format("MM") < moment().format("MM")
      ) {
        return true;
      }
    },

    changeScheduleDate(date) {
      this.slides.forEach((slide) => {
        slide.active = false;
      });
      const slideId = this.slides.findIndex(
        (slide) =>
          moment(slide.date).format("DD.MM.YYYY") ===
          moment(date).format("DD.MM.YYYY")
      );

      this.scheduleDate = moment(date).format("YYYY-MM-DD");
      this.clickOnSlider(this.slides[slideId], slideId);
    },

    async generateDatesSlider(hash) {
      // const { data } = await AJAX_POST("/api/schedule/getAllDatesByHash");
      const keyHash = hash || this.service.hash;
      this.slides = await [...this.widgetPublic.allDatesByHash[keyHash]];
      this.slides.forEach((slide) => {
        if (slide.date === this.scheduleDate) {
          slide.active = true;
        }
      });

      // this.slides = [...data];
    },

    getParamsSchedule() {
      const arr = [];
      if (this.isMaket) {
        this.schedule = this.widgetMulti;
      } else {
        const arrKeys = Object.keys(this.widgetMulti).map((el, index) => {
          this.widgetPublic.resources.forEach((e) => {
            if (e.hash === el) {
              return arr.push({
                name: e.name,
                address: e.address,
                tags: e.tags,
                photo: e.services_photos,
                ...e,
                schedule: this.widgetMulti[e.hash],
              });
            }
          });
          return el;
        });
        this.schedule = arr;
      }
      // this.schedule = this.schedule.map((element, idx) => {
      //   const arr = arrKeys.find((el, i) => i === idx);
      //   const obj = this.widgetPublic.resources.find(
      //     (e, index) => index === idx
      //   );

      //   return {
      //     ...element,
      //     ...arr,
      //     name: obj.name,
      //     address: obj.address,
      //     tags: obj.tags,
      //     ...obj,
      //   };
      // });
    },

    srcImg(img) {
      return require(`${img}`);
    },
    sliderNavigate({ isMore }) {
      if (isMore) this.$refs.swiperDates.swiper.slideNext();
      else this.$refs.swiperDates.swiper.slidePrev();
    },
    slideDate(date) {
      return format(new Date(date), "dd.MM");
    },
    async clickOnSlider(slidePram, slideId) {
      this.slides.forEach((slide) => {
        slide.active = false;
      });
      const searchBranchHash = this.isBranchesUbrr[this.checkTown].find(
        (e) => e.id === this.checkDepartment
      );
      const resourceCheck = this.widgetPublic.resources.find(
        (res) => res.hash === searchBranchHash.resource_hash
      );
      this.selectSell = resourceCheck;
      this.isNameBranchServices = this.selectSell.name;
      this.isLoading = true;
      this.scheduleDate = slidePram.date;
      this.$router.push({ query: { day: this.scheduleDate } });
      await this.getScheduleByPeriodOrByDateMulti({
        hashs: [this.selectSell.hash],
        date: this.scheduleDate,
      });
      await this.getParamsSchedule();
      this.isLoading = false;

      this.slides[slideId].active = true;

      // this.scheduleDate = this.slides[slideId].date;
      // this.$refs.swiperDates.swiper.slideTo(slideId);
    },
    reserve() {
      this.isOpen = true;
    },
    onClick(item, slot) {
      if (this.analyticsYandex && this.analyticsGoogle) {
        const dinamicId = this.analyticsYandex;
        const dinamicIdGoogle = this.analyticsGoogle;
        ym(`${dinamicId}`, "init", {
          clickmap: true,
          trackLinks: true,
          accurateTrackBounce: true,
          webvisor: true,
          trackHash: true,
        });
        ym(`${dinamicId}`, "reachGoal", "slotClick");

        gtag("config", `${dinamicIdGoogle}`);
        gtag("event", "select_content", {
          event_category: "bookingCell",
          event_action: "clickSlot",
          event_label: "booking",
        });
      }

      this.addOptCheck = [];
      this.isShowForm = true;
      this.selectCell = { date: this.scheduleDate, ...item, ...slot };
      if (this.selectCell.additional_options) {
        this.addOptInput = this.selectCell.additional_options
          .filter((i) => i.amount)
          .map((el) => {
            return { ...el, selected: null };
          });
      }

      this.price = slot.price;
    },
    isDis(item) {
      this.dis = !!item;
    },

    async broni(clientInfo) {
      const data = {
        visitor_id: this.widgetPublic?.visitor_id,
        analyticsYandex: this.analyticsYandex,
        resource_id: this.selectCell.resource_id,
        schedule_id: this.selectCell.id,
        date: this.selectCell.date,
        time: this.selectCell.time,
        client_info: clientInfo,
        widget_hash: this.$route.params.hash,
        book_info: {
          totalPriceDiscount: clientInfo.promocode ? this.totalPrice : null,
          individual: this.isIndividual
            ? {
                price: this.price,
                persons: this.person,
              }
            : null,
          //  price: this.price,
          // person: this.person,
          total_price: clientInfo.promocode
            ? clientInfo.totalPriceDiscount
            : this.totalPrice,

          add_options_check: this.addOptCheck,
          add_options_input: this.addOptInput,
          tariff: this.selectTariff,
          service: this.selectService,
          tickets: this.selectTickets,
          rent: !this.isGroup ? { ...this.rent } : null,
          //   rentPlacesInfo: this.rentPlacesInfo,
          group: this.isGroup
            ? // &&
              // this.selectCell.BookingType.booking_type_sub !==
              //   "group_same_seat_limit_for_all_sessions"
              {
                ...this.group,
                add_persons: this.addPersons,
                price_add_persons: this.priceAddPersons,
              }
            : null,
        },
      };
      this.loading = true;
      // console.log("Loading", data);
      await this.saveBooking(data);

      this.loading = false;
      this.isShowForm = false;
      this.isShowPay = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";
:root {
  --colorCustom: "black";
  --colorCustomTextInactive: "black";
  --colorCustomBackground: "green";
  --colorCustomButton: "yellow";
  --colorCustomInput: "white";
  --colorCustomBlockBackground: "white";
}
.custom-block {
  border-radius: 0;
  // padding: 30px;
}
.custom-block.schedule {
  min-height: 500px;
}
.custom-check__title {
  line-height: 1;
}

.item {
  display: flex;
  flex-direction: column;
  margin-bottom: 34px;
  padding-bottom: 10px;
  border-bottom: 1px solid $--color-white-5;
  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
  &__header {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;

    img {
      object-fit: contain;
      margin-right: 20px;
    }

    &-info {
      display: flex;
      flex-direction: column;
    }
    &-name {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
    }
    &-address {
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: $--color-white-5;
      margin-bottom: 10px;
    }

    &-tags {
      display: flex;
    }
  }
  &__schedule {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.schedule {
  &__dates {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    &-datepicker {
      width: 260px;
      height: 60px;
      margin-top: 15px;
      margin-bottom: 15px;
      flex-shrink: 0;
      margin-right: 40px;
      padding: 10px;
      background-color: $--color-light-white;
      border-radius: 3px;
      @media (max-width: 1024px) {
        width: 200px;
        margin-right: 10px;
      }
      @media (max-width: 480px) {
        width: calc(100% - 30px);
      }
    }
    &-swiper {
      display: flex;
      width: calc(100% - 290px);
    }
  }

  &__dates-swiper {
    margin-bottom: 15px;
    margin-top: 15px;
    display: flex;
    width: calc(100% - 340px);

    @media (max-width: 480px) {
      width: calc(100% - 30px);
    }
    @media (max-width: 1024px) {
      width: calc(100% - 240px);
    }

    //width: 100%;
  }
  &__date {
    font-weight: 600;
    line-height: 25px;
  }
  &__day {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: $--color-white-5;
  }
  &__slide-prev,
  &__slide-next {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    cursor: pointer;
    color: $--color-white;
    border-color: $--color-light-white;
    background-color: $--color-light-white;
    outline: 2px solid transparent;
    outline-offset: 2px;
    &:hover {
      color: rgba(255, 189, 26, 1);
    }
  }

  &__slide-next {
    border-left-width: 1px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &__slide-prev {
    border-right-width: 1px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
}
.isMaket-swiper {
  width: calc(100% - 130px);
}
::v-deep .cell {
  margin-bottom: 27px;
}

::v-deep .swiper-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  cursor: pointer;
  box-sizing: border-box;
  transition: all 0.2s;
  border-color: $--color-light-white;
  background-color: $--color-light-white;
  border-right-width: 1px;
  &:hover {
    @apply .bg-yellow-100 .text-black;

    .schedule-weekday {
      @apply .text-black;
    }
  }

  &.active {
    @apply .bg-yellow-100 .text-black;

    .schedule__day {
      @apply .text-black;
    }

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #ffbd1a;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
.disabledSlot {
  height: 50px;
}
.button__wrap {
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (max-width: 480px) {
  // .swiper__wrapper {
  //   flex-direction: column;
  // }
  .el-dialog {
    width: 95% !important;
  }
  .schedule__actions {
    flex-direction: column;
  }
  .disabledSlot {
    height: 60px;
  }
}

::v-deep
  .customPoper.el-date-editor.el-input.el-input--prefix.el-input--suffix.el-date-editor--date
  > input {
  color: var(--colorCustom);
  font-weight: 600;
}
::v-deep
  .customPoper.el-date-editor.el-input.el-input--prefix.el-input--suffix.el-date-editor--date
  > .el-input__prefix {
  color: var(--colorCustom);
}
::v-deep .el-select__caret.el-input__icon.el-icon-arrow-up {
  color: var(--colorCustom);
}

.custom-style.single-grid {
  .el-input__inner {
    color: var(--colorCustom);
  }
  .el-input-number__increase,
  .el-input-number__decrease {
    background: var(--colorCustomBackground);
    color: var(--colorCustom);
  }
  .el-input-number__increase:hover,
  .el-input-number__decrease:hover {
    color: var(--colorCustomButton);
  }
  ::v-deep .el-input {
    color: var(--colorCustom);
  }
}
.el-checkbox.is-checked.custom-style.single-grid
  > .el-checkbox__input.is-checked
  > .el-checkbox__inner {
  //::v-deep .el-checkbox__input {
  border-color: var(--colorCustomButton);
  color: var(--colorCustom);
  //}
}
.el-checkbox.custom-style.single-grid
  > .el-checkbox__input
  > .el-checkbox__inner {
  //::v-deep .el-checkbox__input {
  border-color: var(--colorCustom);
  color: var(--colorCustom);
  //}
}
::v-deep .no-data > span {
  color: var(--colorCustom);
}
.wrapper__photo {
  width: 130px;
  margin-right: 30px;
}

::v-deep .el-alert.customService.el-alert--info.is-light {
  background: rgba(52, 152, 219, 0.1);
  border: 1px solid rgba(52, 152, 219, 0.35);
  color: #3498db;
}

::v-deep .el-select-dropdown__item.hover,
.el-select-dropdown__item:hover {
  color: var(--colorCustom);
  background: var(--colorCustomButton);
}
::v-deep.el-select-dropdown__item.hover.customPoperSelec {
  color: var(--colorCustom);
  background: var(--colorCustomButton);
}
::v-deep .customPoperSelec.el-select .el-input--suffix > .el-input__inner {
  color: var(--colorCustom);
  background: var(--colorCustomInput);
}

::v-deep .customSelect.el-select-dropdown__list {
  background: var(--colorCustomInput);
}
::v-deep
  .customPoperSelec.el-select
  .el-input--suffix
  > .el-input__inner::placeholder {
  color: var(--colorCustom);
  background: var(--colorCustomInput);
}

::v-deep.el-select-dropdown__item {
  background: var(--colorCustomInput);
  color: var(--colorCustom);
}
::v-deep .el-picker-panel__body {
  background: var(--colorCustomInput);
}
.link_style {
  color: #3498db;
}

::v-deep .el-dialog {
  margin-top: 1vh !important;
}
::v-deep .el-dialog__wrapper.customModal {
  scrollbar-width: none;
  overflow: -webkit-paged-x;
}
@media (max-width: 640px) {
  ::v-deep.el-select-dropdown__item.customPoperSelec {
    padding-top: 5px;
    padding-bottom: 5px;
    height: auto;
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 760px) {
  .swiper__wrapper {
    flex-direction: column;
  }

  // ::v-deep .el-dialog__wrapper.customModal {
  //   position: fixed;
  //   top: 10px;
  // }
}
</style>
