<template>
  <Send
    ref="modal"
    :show="show"
    title="Отправить SMS"
    @close="hiddenModal"
    @send="send"
  >
    <p class="hint-title">Номер телефона</p>
    <el-input
      v-model="phone"
      v-mask="'+7 (###) ###-##-##'"
      :placeholder="params"
    >
    </el-input>

    <p class="hint-title">Сообщение</p>
    <div class="textarea_group">
      <el-input
        v-model="message"
        type="textarea"
        show-word-limit
        placeholder="Введите текст сообщения"
        :autosize="{ minRows: 5, maxRows: 6 }"
      >
      </el-input>
      <div v-if="messageLength >= 1" class="textarea_group__limit">
        {{ messageLength }} символов
      </div>
    </div>
    <span class="hint mt-20 block">Сообщений - {{ countSms }}</span>
    <p class="hint-title__medium-white">
      Длина 1 смс: 70 символов в кириллице и 160 — в латинице. При превышении
      указанных объемов формируется несколько СМС, которые склеиваются в одно
      сообщение. При склеивании сообщений, каждое из них становится меньше на
      три символа для кириллицы (67 символов вместо 70) и семь символов для
      латиницы (153 символа вместо 160).
    </p>
  </Send>
</template>

<script>
import { mapActions } from "vuex";
import Send from "@/components/SendModals/Template";

export default {
  name: "SendSms",
  components: {
    Send,
  },
  props: {
    show: Boolean,
    params: {
      type: String,
    },
  },
  data() {
    return {
      phone: null,
      message: null,
    };
  },
  computed: {
    rusLang() {
      return /[а-яА-ЯЁё]/.test(this.message);
    },
    limit() {
      return this.rusLang ? 70 : 160;
    },
    messageLength() {
      return this.message ? this.message.length : 0;
    },
    countSms() {
      return Math.trunc(this.messageLength / this.limit) + 1;
    },
  },
  created() {
    this.phone = this.params;
  },
  methods: {
    ...mapActions("sms", ["sendSms"]),
    hiddenModal() {
      this.$emit("close");
    },
    async send() {
      if (!this.phone) {
        this.phone = this.params;
      }

      this.$refs.modal.loading = true;
      this.$parent.loading = true;
      await this.sendSms({
        message: this.message,
        phone: this.phone,
      });
      this.phone = null;
      this.message = null;
      this.$refs.modal.loading = false;
      this.$emit("reloadSms");
      this.$emit("close");
    },
  },
};
</script>
