<template>
  <el-form
    ref="formReq"
    hide-required-asterisk
    label-position="top"
    :model="companyInfo"
    :rules="rules"
    class="RequisitesData"
  >
    <el-button
      v-if="isNew && listReq.length"
      :class="['add-button', { 'add-button__mb': isShowMap }]"
      @click="isShowMap = !isShowMap"
    >
      Добавить организацию
      <i v-show="isShowMap" class="el-icon-arrow-up"></i>
      <i v-show="!isShowMap" class="el-icon-arrow-down"></i>
    </el-button>
    <template v-if="isShowMap">
      <Tabs v-model="companyInfo.type" :list-link="listOptions" />

      <h4>Юридическое лицо</h4>
      <el-row class="flex-wrap" :gutter="$mq.large ? 10 : 30" type="flex">
        <el-col :span="$mq.large ? 24 : 12">
          <el-form-item prop="region">
            <span slot="label">
              Страна резидентства<span class="danger">*</span>
            </span>
            <el-select
              v-model="companyInfo.region"
              filterable
              allow-create
              default-first-option
              class="w-full"
            >
              <el-option
                v-for="item in listRegions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="$mq.large ? 24 : 12">
          <el-form-item prop="inn">
            <span slot="label">ИНН <span class="danger">*</span></span>
            <el-input v-model="companyInfo.inn"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item prop="name">
            <span slot="label">
              Название организации<span class="danger">*</span>
            </span>
            <el-input v-model="companyInfo.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="$mq.large ? 24 : 12">
          <el-form-item prop="kpp">
            <span slot="label">КПП<span class="danger">*</span></span>
            <el-input v-model="companyInfo.kpp"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="$mq.large ? 24 : 12">
          <el-form-item prop="ogrn">
            <span slot="label">ОГРН (ИП)<span class="danger">*</span></span>
            <el-input v-model="companyInfo.ogrn"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <h4>Адреса</h4>
      <el-row :gutter="$mq.large ? 10 : 30" class="flex-wrap" type="flex">
        <el-col :span="$mq.large ? 24 : 12">
          <el-form-item prop="postcode">
            <span slot="label">
              Почтовый индекс<span class="danger">*</span>
            </span>
            <el-input
              :value="companyInfo.postcode"
              @input="setAddress($event, 'postcode', 'postcodeMailbox')"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="$mq.large ? 24 : 12">
          <el-form-item prop="ULaddresses">
            <span slot="label">
              Юридический адрес<span class="danger">*</span>
            </span>
            <el-input
              :value="companyInfo.ULaddresses"
              @input="setAddress($event, 'ULaddresses', 'mailbox')"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <switch-custom
            v-model="isSetDataUser"
            title="Почтовый адрес совпадает с юридическим?"
          />
        </el-col>
        <el-col :span="$mq.large ? 24 : 12">
          <el-form-item prop="postcodeMailbox">
            <span slot="label">
              Почтовый индекс<span class="danger">*</span>
            </span>
            <el-input
              v-model="companyInfo.postcodeMailbox"
              :disabled="isSetDataUser"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="$mq.large ? 24 : 12">
          <el-form-item prop="mailbox">
            <span slot="label">
              Почтовый адрес<span class="danger">*</span>
            </span>
            <el-input
              v-model="companyInfo.mailbox"
              :disabled="isSetDataUser"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="Получатель (необязательно)">
            <el-input v-model="companyInfo.recipient"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <h4>Банковские реквизиты</h4>
      <el-row :gutter="$mq.large ? 10 : 30" class="flex-wrap" type="flex">
        <el-col :span="$mq.large ? 24 : 12">
          <el-form-item label="Наименование банка">
            <el-input v-model="companyInfo.nameBank"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="$mq.large ? 24 : 12">
          <el-form-item label="БИК банка">
            <el-input v-model="companyInfo.bik"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="$mq.large ? 24 : 12">
          <el-form-item label="Расчетный счет">
            <el-input v-model="companyInfo.invoice"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="$mq.large ? 24 : 12">
          <el-form-item label="Корреспондентский счёт">
            <el-input v-model="companyInfo.ksBank"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <h4 v-if="companyInfo.type === 'contract'">Подписант</h4>
      <el-row
        v-if="companyInfo.type === 'contract'"
        :gutter="$mq.large ? 10 : 30"
        class="flex-wrap"
        type="flex"
      >
        <el-col :span="$mq.large ? 24 : 12">
          <el-form-item prop="FIOsignatory">
            <span slot="label">
              ФИО подписанта<span class="danger">*</span>
            </span>
            <el-input v-model="companyInfo.FIOsignatory"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="$mq.large ? 24 : 12">
          <el-form-item prop="FIOsignatoryP">
            <span slot="label">
              ФИО подписанта в родительном падеже<span class="danger">*</span>
            </span>
            <el-input v-model="companyInfo.FIOsignatoryP"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="$mq.large ? 24 : 12">
          <el-form-item prop="position">
            <span slot="label"> Должность<span class="danger">*</span> </span>
            <el-input v-model="companyInfo.position"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="$mq.large ? 24 : 12">
          <el-form-item prop="positionP">
            <span slot="label">
              Должность в родительном падеже<span class="danger">*</span>
            </span>
            <el-input v-model="companyInfo.positionP"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="$mq.large ? 24 : 12">
          <el-form-item prop="typeDocs">
            <span slot="label">
              Действует на основании<span class="danger">*</span>
            </span>
            <el-select
              v-model="companyInfo.typeDocs"
              filterable
              allow-create
              default-first-option
              class="w-full"
            >
              <el-option
                v-for="item in listDocs"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-button type="primary" class="custom-button" @click="submit()">
        Сохранить
      </el-button>
      <el-button v-if="!isNew && isOneEl" @click="deleteAddress()">
        Удалить
      </el-button>
    </template>
  </el-form>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import SwitchCustom from "@/components/SwitchCustom";
import Tabs from "@/components/Tabs.vue";

export default {
  name: "RequisitesData",
  components: {
    SwitchCustom,
    Tabs,
  },
  props: {
    isNew: Boolean,
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isShowMap: true,
      isSetDataUser: true,
      companyInfo: {
        type: "offerta",
        region: "russian",
        inn: "",
        name: "",
        kpp: "",
        ogrn: "",
        invoice: "",
        bik: "",
        nameBank: "",
        ksBank: "",
        ULaddresses: "",
        postcodeMailbox: "",
        mailbox: "",
        postcode: "",
        recipient: "",
        FIOsignatory: "",
        FIOsignatoryP: "",
        position: "",
        positionP: "",
        typeDocs: "ustav",
      },
    };
  },
  computed: {
    ...mapState("company", ["listReq"]),
    isOneEl() {
      return this.listReq.length > 1;
    },
  },
  watch: {
    isSetDataUser(val) {
      if (val) {
        this.companyInfo.postcodeMailbox = this.companyInfo.postcode;
        this.companyInfo.mailbox = this.companyInfo.ULaddresses;
      }
    },
  },
  created() {
    if (!this.isNew) this.companyInfo = { ...this.listReq[this.index] };
    this.rules = {
      region: [
        {
          required: true,
          message: "Please select Activity zone",
          trigger: "blur",
        },
      ],
      inn: [
        { required: true, message: "заполните поле" },
        {
          min: 8,
          message: "Укажите название (минимум 3 символа)",
          trigger: ["blur"],
        },
      ],
      name: { required: true, message: "заполните поле" },
      kpp: { required: true, message: "заполните поле" },
      ogrn: { required: true, message: "заполните поле" },
      ULaddresses: { required: true, message: "заполните поле" },
      postcodeMailbox: { required: true, message: "заполните поле" },
      mailbox: { required: true, message: "заполните поле" },
      postcode: { required: true, message: "заполните поле" },
      recipient: { required: true, message: "заполните поле" },
      FIOsignatory: { required: true, message: "заполните поле" },
      FIOsignatoryP: { required: true, message: "заполните поле" },
      position: { required: true, message: "заполните поле" },
      positionP: { required: true, message: "заполните поле" },
    };
    this.listRegions = [{ value: "russian", label: "Россия" }];
    this.listDocs = [{ value: "ustav", label: "Устав" }];
    this.listOptions = [
      {
        title: "Оферта",
        value: "offerta",
      },
      {
        title: "Договор",
        value: "contract",
      },
    ];
  },
  methods: {
    ...mapMutations("company", ["setField", "setFieldObj", "addReq"]),
    setData(obj, key, $event) {
      this.setFieldObj({
        field: obj,
        subField: key,
        value: $event,
      });
    },
    submit() {
      this.$refs.formReq.validate((valid) => {
        if (valid) {
          if (this.isNew) {
            this.addReq({ ...this.companyInfo });
            this.resetForm();
            this.isShowMap = false;
          } else {
            this.listReq.splice(this.index, 1, { ...this.companyInfo });
            this.setField({
              field: "listReq",
              value: this.listReq,
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.formReq.resetFields();
    },
    deleteAddress() {
      this.setField({
        field: "listReq",
        value: this.listReq.filter((item, index) => index !== this.index),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.add-button {
  border-color: $--color-primary;
  color: $--color-white;

  &__mb {
    margin-bottom: 30px;
  }
}

.flex-wrap {
  flex-wrap: wrap;
  margin-bottom: 30px;
}

h4 {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 36px;
  color: $--color-white;
  margin-bottom: 20px;
}
::v-deep .wrapper-switch {
  align-items: center;
  margin-bottom: 15px;
}

@screen lg {
}
</style>
