<template>
  <div class="wrapper__content">
    <div class="custom-block notification max-w-full">
      <spinner v-if="!item" />
      <template v-else>
        <div class="notification__first-column">
          <div class="text">Доступно СМС</div>
          <div class="total">{{ user.sms || 0 }} шт.</div>
        </div>
        <div class="notification__second-column">
          <el-form ref="item" :model="item" :rules="rules" class="mt-auto">
            <el-form-item prop="value" class="mb-0">
              <div class="text">Уведомлять при</div>
              <el-input
                v-model.number="item.value"
                placeholder="Введите кол-во смс"
                type="number"
              >
                <el-button
                  slot="append"
                  class="notification__btn"
                  icon="el-icon-check"
                  @click="save"
                ></el-button>
              </el-input>
            </el-form-item>
          </el-form>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      item: {
        value: null,
      },
      rules: {
        value: [
          {
            type: "number",
            required: true,
            min: 0,
            message: "Введите целое число",
            trigger: ["blur", "change"],
          },
        ],
      },
    };
  },
  computed: {
    ...mapState("user", ["user", "minSmsNotify"]),
  },
  async created() {
    await this.getMinValue("minsms");
    this.setData();
  },
  methods: {
    ...mapActions("user", ["getMinValue", "setMinValue"]),
    setData() {
      this.item.value = this.minSmsNotify;
    },
    save() {
      this.setMinValue({ value: this.item.value, key: "minsms" });
    },
  },
};
</script>

<style scoped lang="scss">
@import "element-variables.scss";

.notification {
  display: flex;
  flex-direction: row;
  width: 400px;
  margin-bottom: 0;
  .text {
    font-size: 12px;
    color: $--color-white;
    margin-bottom: 8px;
  }

  &__first-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 40px;
    flex-shrink: 0;
    .text {
      font-size: 12px;
      color: $--color-white-5;
    }
    .total {
      font-weight: 600;
      font-size: 22px;
      line-height: 36px;
    }
  }
  &__second-column {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 210px;
  }
  ::v-deep .el-input-group {
    &__append {
      padding-left: 0;
    }
  }
  &__btn {
    width: 40px;
    height: 40px;
    background: rgba(255, 255, 255, 0.15) !important;
    border-radius: 0px 3px 3px 0px;
    margin: 0 !important;
    padding: 0 !important;
    ::v-deep i {
      font-size: 20px;
      font-weight: bold;
      color: $--color-white;
    }
  }
}
@media (max-width: 768px) {
  .notification {
    flex-direction: column;
    &__second-column {
      margin-top: 20px;
    }
  }
}
</style>
