<template>
  <el-drawer
    :visible.sync="show"
    :show-close="false"
    :close-on-click-modal="false"
    :size="$mq.phone ? '96%' : '430px'"
    :before-close="handleClose"
    custom-class=""
  >
    <!-- <div slot="title" class="absolute fast-add-close">
      <div class="cursor-pointer" @click="$emit('close')">
        <svg-icon
          icon-name="close"
          width="12"
          height="12"
          class-name="text-white-normal"
        ></svg-icon>
      </div>
    </div> -->
    <div class="text-white-normal bg-white-40 rounded-sm" :class="{}">
      <div class="pb-30 px-15">
        <div class="flex justify-between items-center">
          <div class="mb-20 pt-20 text-16">{{ title }}</div>
          <div class="cursor-pointer" @click="$emit('close')">
            <svg-icon
              icon-name="close"
              width="16"
              height="16"
              class-name="text-white-normal"
            ></svg-icon>
          </div>
        </div>

        <el-form
          ref="form"
          :model="form"
          label-width="120px"
          label-position="top"
          hide-required-asterisk
          :rules="formRules"
          @keyup.native.enter="createPromo()"
        >
          <el-row type="flex" class="flex-wrap">
            <el-col :span="24" class="mb-20">
              <el-radio-group v-model="form.type">
                <el-radio :label="1" class="mb-8">В процентах</el-radio>
                <el-radio :label="2">Фиксированная (₽)</el-radio>
              </el-radio-group>
            </el-col>
            <el-col :span="24">
              <el-form-item prop="value">
                <span slot="label"
                  >Размер скидки<span class="danger">*</span></span
                >

                <el-input
                  v-model.number="form.value"
                  placeholder="Введите размер скидки"
                  type="number"
                  step="1"
                >
                  <template v-if="form.type === 1" slot="append"
                    ><span class="text-white-500 pr-15">%</span></template
                  >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col
              v-if="
                title !== 'Создание скидки' && title !== 'Редактирование скидки'
              "
              :span="24"
              class="mb-20"
            >
              <el-form-item prop="promocode">
                <span slot="label">Промокод<span class="danger">*</span></span>
                <el-input
                  v-model="form.code"
                  placeholder="Придумайте промокод"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col
              v-if="
                title !== 'Создание скидки' && title !== 'Редактирование скидки'
              "
              :span="24"
              class="mb-20"
            >
              <el-form-item prop="count">
                <span slot="label"
                  >Количество<span class="danger">*</span></span
                >

                <el-input
                  v-model.number="form.count"
                  placeholder="Введите количество, шт."
                  type="number"
                  step="1"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" :gutter="40" class="flex-wrap">
            <el-col
              v-if="
                title !== 'Создание скидки' && title !== 'Редактирование скидки'
              "
              :span="24"
            >
              <el-form-item prop="dates">
                <span class="text-12">Срок действия</span
                ><span class="danger">*</span>
                <el-date-picker
                  v-model="form.dates"
                  type="daterange"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  range-separator="По"
                  start-placeholder="Любая"
                  end-placeholder="Любая"
                  class="mt-7"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="Распространяется на даты" prop="apply">
                <el-date-picker
                  v-model="form.apply"
                  type="daterange"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  range-separator="По"
                  start-placeholder="Любая"
                  end-placeholder="Любая"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col v-if="type !== 'promocode'" :span="24">
              <div>
                <el-form-item label="Срок действия C" prop="dates">
                  <div class="flex">
                    <el-date-picker
                      v-model="form.apply[0]"
                      type="date"
                      format="yyyy-MM-dd"
                      start-placeholder="Любая"
                      end-placeholder="Любая"
                      placeholder="Выберите дату"
                    >
                    </el-date-picker>
                    <el-time-select
                      v-model="startTime"
                      placeholder="Выберите время"
                      :picker-options="{
                        start: '01:00',
                        step: '01:00',
                        end: '23:30',
                      }"
                    >
                    </el-time-select>
                  </div>
                </el-form-item>
              </div>
              <div class="flex">
                <el-form-item label="Срок действия До" prop="dates">
                  <div class="flex">
                    <el-date-picker
                      v-model="form.apply[1]"
                      type="date"
                      format="yyyy-MM-dd"
                      start-placeholder="Любая"
                      end-placeholder="Любая"
                      placeholder="Выберите дату"
                    >
                    </el-date-picker>

                    <el-time-select
                      v-model="endTime"
                      placeholder="Выберите время"
                      :picker-options="{
                        start: '01:00',
                        step: '01:00',
                        end: '23:30',
                      }"
                    >
                    </el-time-select>
                  </div>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="24">
              <el-form-item
                :label="`Описание ${typeDiscount ? 'скидки' : 'промо-кода'}`"
                prop="desc"
              >
                <el-input
                  v-model="form.description"
                  type="textarea"
                  :rows="3"
                  placeholder="Введите описание"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <div class="text-16 leading-28 font-semibold mb-10 mt-8">
            Применить к услугам
          </div>
          <el-button
            v-if="user.resources_count"
            type="mini"
            class="mb-20"
            @click="toggleAllResourcesIn('form')"
            >{{ form.resources.length ? "Снять все" : "Выбрать все" }}
          </el-button>
          <div v-else class="text-12 text-white-500">
            <router-link :to="{ name: 'resources' }" class="text-yellow-100"
              >Добавьте услуги</router-link
            >
            для применения
            {{ typeDiscount ? "скидок" : "промокодов" }}
          </div>
          <el-form-item prop="resources" class="mb-0">
            <el-checkbox-group v-model="form.resources" class="flex flex-col">
              <el-checkbox
                v-for="(resource, index) in sortArr"
                :key="index"
                :label="resource.hash"
                class="mb-10"
                >{{ resource.name }}
              </el-checkbox>
            </el-checkbox-group>
          </el-form-item>
        </el-form>
      </div>
      <div v-if="errorMsg.length" class="mb-15 pl-20">
        <el-alert
          center
          :title="errorMsg"
          type="warning"
          show-icon
          :closable="false"
        />
      </div>
      <div class="p-30 border-t border-white-150 lg:px-15 flex justify-end">
        <el-button
          :loading="loading"
          type="primary"
          class="border-0"
          @click="createPromo"
          >{{ promo ? "Сохранить" : "Добавить" }}
        </el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { AJAX_POST } from "@/units/ajax";
import moment from "moment";

export default {
  name: "CreatePromo",
  props: {
    promo: {
      type: Object || Array,
      default: null,
    },
    show: Boolean,
    title: String,
    type: String,
  },
  data() {
    const ruleDiscount = (rule, value, callback) => {
      if (value === "" || !/^\d+$/.test(value)) {
        callback(new Error("Укажите целое число"));
      } else {
        callback();
      }
    };

    return {
      sortArr: [],
      errorMsg: "",

      form: {
        hash: "",
        code: "",
        type: 1,
        value: null,
        count: "",
        dates: [],
        apply: [],
        description: "",
        resources: [],
      },
      startTime: null,
      endTime: null,

      loading: false,
      formRules: {
        code: [
          {
            required: true,
            message: "Введите название",
            trigger: ["blur", "change"],
          },
        ],
        value: [{ required: true, validator: ruleDiscount }],
        count: [
          {
            required: true,
            pattern: /^\d+$/,
            message: "Укажите целое число",
            trigger: ["blur", "change"],
          },
        ],
        dates: [
          {
            required: false,
            message: "Укажите срок действия",
            trigger: ["blur", "change"],
          },
        ],
        apply: [
          {
            required: false,
            message: "Укажите срок распространения",
            trigger: ["blur", "change"],
          },
        ],

        resources: [
          {
            type: "array",
            required: true,
            message: "Выберите хотя бы одну услугу",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    ...mapState("user", ["user"]),

    addingPromoInputGrid() {
      if (this.$mq.phone) {
        return 24;
      }
      if (this.$mq.tablet) {
        return 12;
      }
      return 6;
    },
    typeDiscount() {
      return this.type === "discount";
    },
  },

  mounted() {
    this.sortResource();
    if (this.promo) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.form) {
        switch (key) {
          case "salePercent":
            this.form[key] = this.promo.type === 1 ? this.promo.value : "";
            break;
          case "saleCurrency":
            this.form[key] = this.promo.type === 2 ? this.promo.value : "";
            break;
          case "dates":
            this.form[key].push(
              this.promo.work_from

              // moment(this.promo.work_from).format("DD-MM-YYYY")
            );
            this.form[key].push(
              this.promo.work_to

              // moment(this.promo.work_to).format("DD-MM-YYYY")
            );
            break;

          case "apply":
            this.form[key].push(
              this.promo.apply_from

              // moment(this.promo.work_from).format("DD-MM-YYYY")
            );
            this.form[key].push(
              this.promo.apply_to

              // moment(this.promo.work_to).format("DD-MM-YYYY")
            );
            break;
          case "resources":
            this.promo.resources.forEach((resource) => {
              this.form.resources.push(resource);
            });
            break;
          default:
            this.form[key] = this.promo[key];
        }
      }
      if (this.title === "Редактирование скидки" && !this.form.apply[0]) {
        this.form.apply = [];
        this.form.apply.push(this.promo.work_from);
        this.form.apply.push(this.promo.work_to);
        this.startTime = moment(this.form.apply[0]).format("LT");
        this.endTime = moment(this.form.apply[1]).format("LT");
      }
    }
  },

  beforeUpdate() {
    // if (this.promo) {
    //   // this.form.resources = [];
    //   this.form.active = this.promo.active;
    //   this.form.type = this.promo.type;
    //   // eslint-disable-next-line prefer-destructuring
    //   this.form.dates[0] = this.promo.work_to;
    //   // eslint-disable-next-line prefer-destructuring
    //   this.form.dates[1] = this.promo.work_from;
    //   // eslint-disable-next-line prefer-destructuring
    //   this.form.apply[0] = this.promo.work_from;
    //   // eslint-disable-next-line prefer-destructuring
    //   this.form.apply[1] = this.promo.work_to;
    //   this.promo.resources.forEach((el) => {
    //     if (!this.form.resources.includes(el)) {
    //       this.form.resources = [];
    //       this.form.resources.push(el);
    //     }
    //   });
    //   // this.form.resources = this.promo.resources;
    //   this.form.opened = this.promo.opened;
    //   this.form.edit = this.promo.edit;
    //   this.form.value = this.promo.value;
    //   this.form.description = this.promo.description;
    // }
  },
  methods: {
    ...mapMutations(["setNotif"]),
    sortResource() {
      this.sortArr = this.user.resources.sort(
        (a, b) => a.position - b.position
      );
    },
    handleClose(done) {
      this.$emit("close");
      done();
    },
    toggleAllResourcesIn(form) {
      if (this[form].resources.length) {
        this[form].resources = [];
      } else {
        this[form].resources = [];
        this.user.resources.forEach((resource) => {
          this[form].resources.push(resource.hash);
        });
      }
    },
    cancelAdding() {
      this.$emit("cancelAdding");
    },
    createPromo() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          const result = {
            // code: this.form.code,
            // count: this.form.count,
            type: this.form.type,
            value: this.form.value,
            resources: this.form.resources,
            description: this.form.description,
            apply_from: moment(this.form.apply[0]).format("yyyy-MM-DD"),
            apply_to: moment(this.form.apply[1]).format("yyyy-MM-DD"),
            work_from:
              this.title === "Создание скидки" ||
              this.title === "Редактирование скидки"
                ? `${moment(this.form.apply[0]).format("yyyy-MM-DD")} ${
                    this.startTime
                  }:00`
                : `${moment(this.form.apply[0]).format("yyyy-MM-DD")}`,
            work_to:
              this.title === "Создание скидки" ||
              this.title === "Редактирование скидки"
                ? `${moment(this.form.apply[1]).format("yyyy-MM-DD")} ${
                    this.endTime
                  }:00`
                : `${moment(this.form.apply[1]).format("yyyy-MM-DD")}`,
          };

          if (this.title === "Создание скидки") {
            result.work_from = `${moment(this.form.apply[0]).format(
              "yyyy-MM-DD"
            )} ${this.startTime}:00`;
            result.work_to = `${moment(this.form.apply[1]).format(
              "yyyy-MM-DD"
            )} ${this.endTime}:00`;
          } else {
            result.code = this.form.code;
            result.count = this.form.count;
            // eslint-disable-next-line prefer-destructuring
            //  result.work_from = this.form.dates[0];
            // eslint-disable-next-line prefer-destructuring
            //  result.apply_from = this.form.apply[0];
            // eslint-disable-next-line prefer-destructuring
            // result.work_to = this.form.dates[1];
            // eslint-disable-next-line prefer-destructuring
            // result.apply_to = this.form.apply[1];
          }

          if (this.promo) {
            result.hash = this.form.hash;
            result.active = this.form.active || true;
            AJAX_POST(`/api/${this.type}/edit`, result)
              .then((response) => {
                if (response.data.success) {
                  this.setNotif({
                    title: response.data.msg,
                  });
                  this.$emit("editPromo", result);
                } else {
                  this.setNotif({
                    title:
                      response.data.msg || `Произошла ошибка, попробуйте позже`,
                    type: "error",
                  });
                }
                this.loading = false;
              })
              .catch((err) => {
                this.setNotif({
                  title: err.msg || `Произошла ошибка, попробуйте позже`,
                  type: "error",
                });
                this.loading = false;
              });
          } else {
            AJAX_POST(`/api/${this.type}/add`, result)
              .then((response) => {
                if (response.data.success) {
                  this.$refs.form.resetFields();
                  this.setNotif({
                    title: this.typeDiscount
                      ? `Скидка добавлена`
                      : `Промокод ${result.code} добавлен`,
                  });
                  this.errorMsg = "";
                  this.$emit("createdPromo", response.data.promo);
                } else {
                  this.errorMsg = response.data.msg;
                  this.setNotif({
                    title: response.data.msg,
                    type: "error",
                  });
                }
                this.form.code = "";
                this.loading = false;
              })
              .catch((err) => {
                this.setNotif({
                  title: `Произошла ошибка, попробуйте позже`,
                  type: "error",
                });
                this.loading = false;
              });
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.el-textarea {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
::v-deep .el-drawer__body {
  overflow: overlay;
  overflow-x: hidden;
}
</style>
