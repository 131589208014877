<template>
  <div>
    <div
      v-for="(item, index) in settings.data.group"
      :key="index"
      class="bk-info"
    >
      <div
        v-if="
          (isHoliday && item.holidays_price
            ? item.holidays_price + selectCell.weekend_price_additional
            : item.base_price + selectCell.price_additional) > 0
        "
        class="flex justify-between w-full"
      >
        <p
          class="bk-info__title"
          :style="{
            color: isThemeWidget ? customStyle.inactive_text_color : '',
          }"
        >
          {{ item.from ? `от ${item.from}` : "" }}
          {{ item.to ? `до  ${item.to} ` : "" }}
          <span
            :style="{
              color: isThemeWidget ? customStyle.inactive_text_color : '',
            }"
            >{{ `${unitCahnge.text}` }}</span
          >
        </p>
        <p
          class="bk-info__info"
          :style="{
            color: isThemeWidget ? customStyle.active_text_color : '',
          }"
        >
          {{
            isHoliday && item.holidays_price
              ? item.holidays_price + selectCell.weekend_price_additional
              : item.base_price + selectCell.price_additional
          }}
          {{ selectCell.currency }}
          {{ item.from ? `за ${settings.data.session_duration}` : "" }}
          <span>{{ `${unitCahnge.text}` }}</span>
        </p>
      </div>
    </div>
    <p
      class="title-widget"
      :style="{
        color: isThemeWidget ? customStyle.active_text_color : '',
      }"
    >
      Количество {{ unitCahnge.text }}
    </p>
    <el-input-number
      v-if="
        settings.data.time_unit === 'hours' ||
        settings.data.time_unit === 'days'
      "
      v-model="hours"
      :style="{
        color: isThemeWidget ? customStyle.active_text_color : '',
        background: isThemeWidget ? customStyle.background_cells_fields : '',
      }"
      class="w-full mb-12"
      :class="{ 'custom-style': isThemeWidget }"
      :min="minHours"
      :max="maxHours"
      :step="step"
      @input="changePersons"
    ></el-input-number>
    <el-input-number
      v-if="settings.data.time_unit === 'minutes'"
      v-model="hours"
      :style="{
        color: isThemeWidget ? customStyle.active_text_color : '',
        background: isThemeWidget ? customStyle.background_cells_fields : '',
      }"
      class="w-full mb-12"
      :min="minMinutes"
      :max="maxMinutes"
      :step="step"
      @input="changePersons"
    ></el-input-number>
  </div>
</template>

<script>
export default {
  name: "RentDepTime",
  props: {
    selectCell: {
      type: Object,
      default: () => {},
    },
    settings: {
      type: Object,
      default: () => {},
    },
    customStyle: { type: Object },
    isThemeWidget: { type: String },
  },
  data() {
    return {
      hours: 0,
    };
  },
  computed: {
    isHoliday() {
      return this.selectCell.is_holiday;
    },
    step() {
      return Number(this.settings.data.session_duration);
    },
    maxHours() {
      const lastChild = this.settings.data.group[
        this.settings.data.group.length - 1
      ];
      return Number(lastChild.to) || 99999;
    },
    minHours() {
      return Number(this.settings.data.minimum_rent);
      // return Number(this.settings.data.group[0].from) || 1;
    },
    minMinutes() {
      return Number(this.settings.data.group[0].from) || 1;
    },
    maxMinutes() {
      const lastChild = this.settings.data.group[
        this.settings.data.group.length - 1
      ];
      return Number(lastChild.to) || 99999;
    },

    unitCahnge() {
      switch (this.settings.data.time_unit) {
        case "days":
          return {
            text: "дней",
            param: "day",
            time: this.hours,
            price: this.hours,
          };
        case "hours":
          return {
            text: "часов",
            param: "hour",
            time: this.hours,
            price: this.hours,
          };
        case "minutes":
          return {
            text: "минут",
            param: "minute",
            time: this.hours,
            price: this.hours,
          };

        default:
          return this.settings.data.time_unit;
      }
    },
  },

  watch: {
    hours() {
      if (this.settings.data.time_unit === "minutes") {
        this.hours =
          this.hours % this.settings.data.session_duration
            ? Math.round(this.hours / 10) * 10
            : this.hours;
      }
    },
    unitCahnge() {
      this.$emit("unitCahnge", this.unitCahnge);
    },
  },
  created() {
    this.isClass();
  },
  methods: {
    changePersons() {
      const findInterval = this.settings.data.group.find((el) => {
        const to = el.to ? Number(el.to) : this.maxHours;
        return this.hours <= to;
      });

      // const prices = [];
      // if (this.settings.data.group.length) {
      //   this.settings.data.group.forEach((e) => {
      //     const obj = {};
      //     obj.name = `от ${e.from} до ${e.to} часов`;
      //     obj.price = e.base_price;
      //     obj.unit = this.unitCahnge();
      //     // this.settings.data.time_unit;
      //     prices.push(obj);
      //   });
      // }

      if (findInterval.discount) {
        this.$emit("setDiscount", {
          discount: findInterval.discount,
          unit: findInterval.discount_unit,
        });
      }
      this.$emit("setSlotParam", {
        book_info: {
          rent: {
            duration: this.unitCahnge.time,
            unit: this.unitCahnge.param,
            price: this.isHoliday
              ? findInterval.holidays_price
              : findInterval.base_price,
          },
        },
        rent: {
          duration: this.unitCahnge.time,
          unit: this.unitCahnge.param,
          price: this.isHoliday
            ? findInterval.holidays_price
            : findInterval.base_price,
        },

        resourceHash: this.selectCell.resource_id,
        date: this.selectCell.date,
        time: this.selectCell.time,
        cnt: this.hours,
      });

      this.$emit("setRent", {
        duration: this.unitCahnge.time,
        unit: this.unitCahnge.param,
        price: this.isHoliday
          ? findInterval.holidays_price
          : findInterval.base_price,
      });
      this.$emit(
        "setHours",
        this.settings.data.time_unit === "minutes" ? this.hours : this.hours
      );
      this.$emit(
        "setPrice",
        this.isHoliday ? findInterval.holidays_price : findInterval.base_price
      );
    },
    isClass() {
      document.body.style.setProperty(
        "--colorCustom",
        `${this.customStyle.active_text_color}`
      );
      document.body.style.setProperty(
        "--colorCustomBackground",
        `${this.customStyle.inactive_text_color}`
      );
      document.body.style.setProperty(
        "--colorCustomButton",
        `${this.customStyle.active_buttons_background}`
      );
      document.body.style.setProperty(
        "--colorCustomTextInactive",
        `${this.customStyle.active_buttons_background}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";
:root {
  --colorCustom: "white";
  --colorCustomBackground: "green";
  --colorInactive: "grey";
}
.bk-info {
  margin-bottom: 6px;
  &__title {
    flex-grow: 1;
    width: auto;
  }
  &__info {
    flex-grow: 0;
  }
}
.title-widget {
  margin-top: 12px;
}
.custom-style {
  ::v-deep .el-input__inner {
    color: var(--colorCustom);
  }
}
::v-deep .el-input-number__increase,
::v-deep .el-input-number__decrease {
  background: var(--colorInactive);
  color: var(--colorCustom);
}

::v-deep .el-input-number__increase:hover,
::v-deep .el-input-number__decrease:hover {
  color: var(--colorCustomButton);
}

::v-deep .el-input > .el-input__inner {
  color: var(--colorCustom);
}
</style>
