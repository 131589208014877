<template>
  <div>
    <el-switch v-model="item" :active-text="text"> </el-switch>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    value: Number,
  },
  computed: {
    item: {
      get() {
        return this.value === 1;
      },
      set(num) {
        const value = num ? 1 : 0;
        this.$emit("input", value);
      },
    },
  },
};
</script>
