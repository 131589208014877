<template>
  <div>
    <div :class="['buy-sms', { 'custom-block': modal }, { isModal: modal }]">
      <spinner v-if="isLoading" />
      <template v-else>
        <div class="title">Купить пакет SMS-уведомлений</div>
        <!-- <Tabs v-model="typePay" :list-link="listPays" class="tabs-pay" /> -->
        <p v-if="sms_tariffs" class="hint-title__medium-white mb-10">
          {{
            `Выберите пакет СМС и оплатите его онлайн. Стоимость 1 части СМС (70
          символов в кириллице и 160 в латинице) - ${priceTariffSms} руб. `
          }}
        </p>
        <Tabs
          v-model="activeGroupSms"
          :select-icon="true"
          :list-link="listOptions"
        />
        <p v-if="typePay === 'order'" class="hint-title">Юридическое лицо</p>
        <el-input
          v-if="typePay === 'order'"
          v-model="UL"
          placeholder="Введите Юр лицо"
        />
        <div class="hl"></div>
        <div class="buy-sms__actions">
          <div class="flex">
            <div class="total-sms">
              <div class="text-header">СМС</div>
              <div class="number">
                {{ sms_tariffs[activeIndex].count }}
              </div>
            </div>
            <div>
              <div class="text-header">К оплате</div>
              <div class="number">{{ sms_tariffs[activeIndex].cost }} ₽</div>
            </div>
          </div>

          <el-button type="primary" @click="paySMS">Оплатить</el-button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { AJAX_POST } from "@/units/ajax";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "FillingSms",
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      typePay: "online",
      activeGroupSms: 30,
      UL: "",
      isLoading: true,
    };
  },
  computed: {
    ...mapState("settings", ["sms_tariffs"]),
    ...mapGetters("user", ["priceTariffSms"]),

    listOptions() {
      return this.sms_tariffs.map((item) => {
        return {
          title: `${item.count} сообщений`,
          value: item.count,
          cost: item.cost,
        };
      });
    },
    activeIndex() {
      return this.sms_tariffs.findIndex(
        (el) => el.count === this.activeGroupSms
      );
    },
  },
  async created() {
    this.listPays = [
      { title: "Оплатить онлайн", value: "online" },
      { title: "Оплатить по счету", value: "order" },
    ];
    if (!this.sms_tariffs.length) await this.getSettings();
    this.isLoading = false;
  },
  methods: {
    ...mapActions("settings", ["getSettings"]),
    async paySMS() {
      try {
        const { data } = await AJAX_POST("/api/pay/sms", {
          type: this.activeIndex,
        });
        window.location.href = data.redirect;
        this.setNotif({ title: `Данные скопированны ${data}` });
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.buy-sms {
  width: 100%;
  &__actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    .total-sms {
      margin-right: 30px;
    }

    .text-header {
      color: $--color-white-5;
      font-size: 12px;
      line-height: 20px;
    }
    .number {
      color: $--color-white;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
    }
  }
}

.tabs-pay ::v-deep .tabs {
  &__item {
    flex-grow: 0;
  }
}

.isModal {
  width: auto;
  min-width: auto;

  ::v-deep .tabs {
    flex-direction: column;
    &__item {
      border-right: 1px solid rgba(255, 255, 255, 0.2);
    }
  }
  .title {
    color: $--color-white !important;
  }
}
</style>
