<template>
  <el-form hide-required-asterisk label-position="top">
    <h4 class="title">Тип записи</h4>
    <p class="hint-title__medium-white">
      Выберите тип записи, указанной в расписании
    </p>
    <Tabs v-model="activeTab" :list-link="listOptionsAddress" class="tabs" />
    <components :is="activeTab" ref="component" />
    <div class="hl"></div>
    <el-button type="primary" class="custom-button" @click="submit">
      Сохранить
    </el-button>
  </el-form>
</template>

<script>
import Tabs from "@/components/Tabs.vue";
import Individual from "@/views/Services/Create/components/Individual";
import Group from "@/views/Services/Create/components/Group";
import Rent from "@/views/Services/Create/components/Rent";
import Service from "@/views/Services/Create/components/AppServices";

import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "OnlineSchedule",
  components: {
    Tabs,
    Individual,
    Group,
    Rent,
    Service,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("service", ["settings"]),
    ...mapGetters("service", ["isHaveSettings"]),
    ...mapGetters("user", ["isUBRR"]),

    activeTab: {
      get() {
        return this.settings.booking_type;
      },
      set(value) {
        this.setSettings({ field: "booking_type", value });
      },
    },
  },
  watch: {
    isHaveSettings(val) {
      if (val) {
        this.$refs.component.setRadio();
      }
    },
  },
  created() {
    this.rules = {
      name: [
        { required: true, message: "заполните поле" },
        {
          min: 3,
          message: "Укажите название (минимум 3 символа)",
          trigger: ["blur"],
        },
      ],
    };
    this.listOptionsAddress = [
      {
        title: "Индивидуальная",
        value: "individual",
      },
      {
        title: "Групповая",
        value: "group",
      },
      {
        title: "Аренда",
        value: "rent",
      },
      {
        title: "Услуги",
        value: "service",
      },
    ];
    if (!this.isUBRR) {
      this.listOptionsAddress.splice(-1, 1);
    }

    this.saveSettings();
  },
  methods: {
    ...mapActions("service", ["saveSettings"]),
    ...mapMutations("service", ["setSettings"]),
    submit() {
      this.$refs.component.save();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.title {
  font-size: 22px;
  line-height: 44px;
  font-weight: 600;
}

.hint-title__medium-white {
  padding-bottom: 30px;
}

.tabs {
  margin-bottom: 30px;
}

@screen lg {
  .title {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 28px;
  }
  .hint-title__medium-white {
    padding-bottom: 25px;
  }
  .tabs {
    margin-bottom: 10px;
  }
}
</style>
