<template>
  <!-- <div class="pt-40 pb-80 w-full max-w-lg notifications-page lg:pt-15"> -->
  <div class="pt-40 pb-80 w-full lg:pt-15 px-10">
    <Top title="Уведомления" :font="{ size: 22, leading: 22 }" />
    <spinner v-if="!info || !defaultInfo" />
    <el-collapse v-else @change="changeCollapse">
      <BlockCollapse
        title="Настройки SMTP"
        name="settings"
        sub-title="Позволяет настроить отправку писем с вашего электронного адреса, а также использовать в шаблоне ваш логотип, ссылки на социальные сети."
      >
        <Settings ref="settings" />
      </BlockCollapse>
      <BlockCollapse
        title="Переменные шаблона"
        name="variables"
        sub-title="Шаблоны доступны для редактирования только при наличии
          премиум-аккаунта, в противном случае текст уведомления остается
          стандартным."
      >
        <Variables ref="variables" />
      </BlockCollapse>

      <BlockCollapse
        title="Новое бронирование"
        name="booking"
        sub-title="При необходимости вы можете изменить шаблон письма для пользователя
            о бронировании. Отредактируйте тему и текст письма, используя
            описанные выше переменные"
      >
        <Booking
          ref="booking"
          type="new_reserve"
          :items="{
            admin: info.new_reserve_admin,
            user: info.new_reserve_user,
          }"
          :default-items="{
            admin: defaultInfo.new_reserve_admin,
            user: defaultInfo.new_reserve_user,
          }"
          :loading="{
            admin: loading.new_reserve_admin,
            user: loading.new_reserve_user,
          }"
          @save="save"
        />
      </BlockCollapse>
      <BlockCollapse
        title="Новое бронирование с оплатой"
        name="bookingPay"
        sub-title="При необходимости вы можете изменить шаблон письма для пользователя
            о бронировании. Отредактируйте тему и текст письма, используя
            описанные выше переменные"
      >
        <Booking
          ref="bookingPay"
          type="new_prepayment"
          :items="{
            admin: info.new_prepayment_admin,
            user: info.new_prepayment_user,
          }"
          :default-items="{
            admin: defaultInfo.new_prepayment_admin,
            user: defaultInfo.new_prepayment_user,
          }"
          :loading="{
            admin: loading.new_prepayment_admin,
            user: loading.new_prepayment_user,
          }"
          @save="save"
        />
      </BlockCollapse>
      <BlockCollapse
        title="Отмена бронирования"
        name="cancel"
        sub-title="При необходимости вы можете изменить шаблоны уведомлений об отмене бронирования для пользователя и сотрудников."
      >
        <Cancel
          ref="cancel"
          :items="{
            admin: info.cancel_reserve_admin,
            user: info.cancel_reserve_user,
          }"
          :default-items="{
            admin: defaultInfo.cancel_reserve_admin,
            user: defaultInfo.cancel_reserve_user,
          }"
          :loading="{
            admin: loading.cancel_reserve_admin,
            user: loading.cancel_reserve_user,
          }"
          @save="save"
        />
      </BlockCollapse>

      <BlockCollapse
        title="Изменение бронирования"
        name="carryover"
        sub-title="При необходимости вы можете изменить шаблоны уведомлений об изменении бронирований для пользователя и сотрудников."
      >
        <CarryOver
          ref="carryover"
          :items="{
            admin: info.shift_reserve_admin,
            user: info.shift_reserve_user,
          }"
          :default-items="{
            admin: defaultInfo.shift_reserve_admin,
            user: defaultInfo.shift_reserve_user,
          }"
          :loading="{
            admin: loading.shift_reserve_admin,
            user: loading.shift_reserve_user,
          }"
          @save="save"
        />
      </BlockCollapse>

      <BlockCollapse
        title="Напоминание о бронировании"
        name="reminder"
        sub-title="При необходимости вы можете изменить шаблоны напоминания о бронировании для пользователя и сотрудников."
      >
        <Reminder
          ref="reminder"
          :items="{
            admin: info.booking_reminder_admin,
            user: info.booking_reminder_user,
          }"
          :default-items="{
            admin: defaultInfo.booking_reminder_admin,
            user: defaultInfo.booking_reminder_user,
          }"
          :loading="{
            admin: loading.booking_reminder_admin,
            user: loading.booking_reminder_user,
          }"
          @save="save"
        />
      </BlockCollapse>

      <BlockCollapse
        title="Текст в модальном окне при успешном бронировании"
        name="modal"
        sub-title="Вы можете изменить содержимое модального окна, которое увидит пользователь после создания заказа в виджете бронирования."
      >
        <Modal
          ref="modal"
          is-modal
          :items="info.modal"
          :default-items="defaultInfo.modal"
          :loading="loading.modal"
          @save="save"
        />
      </BlockCollapse>
    </el-collapse>
  </div>
</template>

<script>
import { AJAX_POST } from "@/units/ajax";
import Variables from "@/components/Notifications/Variables";
import Booking from "@/components/Notifications/Booking";
import CarryOver from "@/components/Notifications/CarryOver";
import Cancel from "@/components/Notifications/Cancel";
import Reminder from "@/components/Notifications/Reminder";
import Modal from "@/components/Notifications/Modal";
import Settings from "@/components/Notifications/Settings";
import { mapMutations, mapActions, mapState } from "vuex";
import Top from "@/components/Pages/Top";

export default {
  name: "Notifications",
  components: {
    Top,
    Variables,
    Booking,
    CarryOver,
    Reminder,
    Modal,
    Settings,
    Cancel,
  },
  data() {
    return {
      loading: {
        booking_reminder_admin: false,
        booking_reminder_user: false,
        cancel_reserve_admin: false,
        cancel_reserve_user: false,
        shift_reserve_admin: false,
        shift_reserve_user: false,
        new_prepayment_admin: false,
        new_prepayment_user: false,
        new_reserve_admin: false,
        new_reserve_user: false,
        modal: false,
      },
    };
  },
  computed: {
    ...mapState("notifications", ["info", "defaultInfo"]),
  },
  async created() {
    await this.getInfo();
    await this.getDefaultInfo();
  },
  methods: {
    ...mapMutations(["setNotif"]),
    ...mapActions("notifications", ["getInfo", "getDefaultInfo"]),
    async save(item) {
      this.loading[item.type] = true;
      try {
        const res = await AJAX_POST("/api/notify/templates/save", {
          ...item,
        });
        if (res.data.success) {
          this.setNotif({
            title: `Шаблон сохранен`,
          });

          await this.getInfo();
          await this.getDefaultInfo();
        } else {
          this.setNotif({
            title: `Произошла ошибка, попробуйте позже`,
            type: "error",
          });
        }
      } catch (err) {
        this.setNotif({
          title: `Произошла ошибка, попробуйте позже`,
          type: "error",
        });
      }

      this.loading[item.type] = false;
    },
    changeCollapse(e) {
      const el = e[e.length - 1];
      if (el && el !== "settings" && el !== "variables") {
        const { template } = this.$refs[el].$refs;
        if (!template.isModal) template.setText();
        else template.setModalText();
      }
    },
  },
};
</script>

<style lang="scss">
.notifications {
  max-height: 311px;
}

.notifications-page {
  .code-textarea {
    height: 100%;
    max-width: 100%;
    .el-textarea__inner {
      max-height: 311px;
      padding: 16px 20px 20px;
      line-height: 25px;

      &::-webkit-input-placeholder {
        line-height: 1;
      }
      &::-moz-placeholder {
        line-height: 1;
      }
      &:-moz-placeholder {
        line-height: 1;
      }
      &:-ms-input-placeholder {
        line-height: 1;
      }
    }
  }
}

.notifications-variables {
  max-width: 923px;

  &__tables {
    .el-table {
      &:before {
        background-color: transparent;
      }
      .cell {
        padding-left: 12px;
        padding-right: 12px;
      }

      th:nth-last-child(2) {
        border-right: 1px solid rgba(255, 255, 255, 0.1);
      }

      th {
        height: 30px;
        border-right: 0;

        &.is-leaf {
          border-bottom: 0;
        }
      }

      td {
        border: 0;
        padding: 8px 0;

        &:first-child {
          .cell {
            @apply .text-yellow-100;
          }
        }
      }
    }

    .el-table--enable-row-hover .el-table__body tr > td {
      border-right: 0;
    }

    .el-table--enable-row-hover .el-table__body tr > td:first-child {
      border-left: 0;
      border-right: 1px solid rgba(255, 255, 255, 0.1);
    }
  }
}
</style>
