<template>
  <TemplateBlock
    title="Шаг 2 — Настройка расписания"
    @back="$emit('input', value - 1)"
    @next="$emit('input', value + 1)"
  >
    <div class="title-widget">Тип формы</div>
    <el-radio
      :value="widget.typeMulti"
      label="grid"
      @input="setWidget({ field: 'typeMulti', value: $event })"
    >
      Услуги с расписанием
    </el-radio>
    <el-radio
      :value="widget.typeMulti"
      label="tree"
      @input="setWidget({ field: 'typeMulti', value: $event })"
    >
      Выбор города и филиала
    </el-radio>
    <el-radio
      :value="widget.typeMulti"
      label="card"
      disabled
      class="last-child"
      @input="setWidget({ field: 'typeMulti', value: $event })"
    >
      Карточки услуг
    </el-radio>

    <div class="title-widget">Дополнительные настройки</div>

    <div>
      <switch-custom
        :value="widget.showPhoto"
        title="Показывать фотографии"
        @input="setWidget({ field: 'showPhoto', value: $event })"
      />
      <switch-custom
        :value="widget.showAddress"
        title="Показывать адрес"
        @input="setWidget({ field: 'showAddress', value: $event })"
      />
      <switch-custom
        :value="widget.showTag"
        title="Показывать теги"
        @input="setWidget({ field: 'showTag', value: $event })"
      />
      <switch-custom
        :value="widget.isShowPrice"
        title="Отображать цену под сеансом"
        @input="setWidget({ field: 'isShowPrice', value: $event })"
      />
    </div>
  </TemplateBlock>
</template>

<script>
import TemplateBlock from "@/views/Widgets/components/TemplateBlock.vue";
import { mapState, mapMutations } from "vuex";

export default {
  name: "ScheduleMultiService",
  components: {
    TemplateBlock,
  },
  props: {
    value: {
      type: Number,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState("widget", ["widget"]),
  },
  created() {},
  methods: {
    ...mapMutations("widget", ["setWidget"]),
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

::v-deep {
  .el-radio {
    margin-bottom: 17px;
  }
  .wrapper-switch {
    margin-bottom: 17px;
  }
}

.last-child {
  margin-bottom: 30px !important;
}
</style>
