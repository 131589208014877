var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"title-widget",style:({
      color: _vm.isThemeWidget ? _vm.customStyle.active_text_color : '',
    })},[_vm._v(" Количество участников ")]),_c('el-radio-group',{on:{"input":function($event){return _vm.emitData($event)}},model:{value:(_vm.group),callback:function ($$v) {_vm.group=$$v},expression:"group"}},_vm._l((_vm.settings.data.group),function(item,i){return _c('el-radio',{key:i,attrs:{"label":item}},[_c('div',{staticClass:"radio__title",style:({
          color: _vm.isThemeWidget ? _vm.customStyle.active_text_color : '',
        })},[_vm._v(" Группа от "+_vm._s(item.from)+" до "+_vm._s(item.to)+" человек ")]),_c('div',{staticClass:"radio__sub-title",style:({
          color: _vm.isThemeWidget ? _vm.customStyle.inactive_text_color : '',
        })},[_vm._v(" "+_vm._s(_vm.isHoliday && item.holidays_price >= 0 ? item.holidays_price + _vm.selectCell.weekend_price_additional : item.base_price + _vm.selectCell.price_additional)+" "+_vm._s(_vm.selectCell.currency)+" ")])])}),1),(_vm.isShowAddSeats)?_c('p',{staticClass:"title-widget",style:({
      color: _vm.isThemeWidget ? _vm.customStyle.active_text_color : '',
    })},[_vm._v(" Дополнительные участники ")]):_vm._e(),(_vm.isShowAddSeats)?_c('div',{staticClass:"input-number"},[_c('div',{staticClass:"input-number__info"},[_c('span',{staticClass:"input-number__info-title",style:({
          color: _vm.isThemeWidget ? _vm.customStyle.active_text_color : '',
        })},[_vm._v("Количество")]),_c('span',{staticClass:"input-number__info-subtitle",style:({
          color: _vm.isThemeWidget ? _vm.customStyle.inactive_text_color : '',
        })},[_vm._v(" "+_vm._s(_vm.priceAddPersons)+" "+_vm._s(_vm.selectCell.currency)+" ")])]),_c('el-input-number',{staticClass:"input-number__input",class:{ 'custom-style': _vm.isThemeWidget },style:({
        background: _vm.isThemeWidget ? _vm.customStyle.background_cells_fields : '',
      }),attrs:{"max":_vm.maxCount,"min":0},on:{"input":_vm.changePersons},model:{value:(_vm.addPersons),callback:function ($$v) {_vm.addPersons=$$v},expression:"addPersons"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }