<template>
  <div class="pt-40 pb-80 w-full resource lg:pt-15">
    <div class="lg:px-15">
      <el-button size="medium" class="mb-30 lg:mb-20 px-12" @click="goBack"
        ><span class="flex items-center">
          <svg-icon
            icon-name="arrow"
            class="text-white-normal rotate-180 mr-10"
            width="13"
            height="8"
          ></svg-icon
          >К списку клиентов
        </span></el-button
      >
    </div>

    <app-reserves>
      <div class="mb-40 lg:px-15 lg:mb-20">
        <h1 class="text-22 leading-36 font-semibold mb-4">
          {{ clientInfo.first_name }} {{ clientInfo.last_name }}
        </h1>
        <p class="text-12 leading-16 text-white-500">
          Количество бронирований: {{ totalReserves }}
        </p>
      </div>
    </app-reserves>
  </div>
</template>

<script>
import AppReserves from "../components/AppReserves";
import { AJAX_GET } from "../units/ajax";

export default {
  name: "Client",
  components: { AppReserves },
  data() {
    return {
      clientInfo: null,
      totalReserves: null,
    };
  },
  mounted() {
    AJAX_GET(
      `/api/reservation/get/client?email=${this.$route.query.email}`
    ).then((response) => {
      this.clientInfo = response.data.data[0].client_info;
      this.totalReserves = response.data.data.length;
    });
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style lang="scss"></style>
