<template>
  <div class="w-full">
    <div class="text-14 leading-28 mb-10">{{ text }}</div>
    <div class="textarea_default" :class="{ textarea_disable: typeText === 0 }">
      <div class="overlap"></div>
      <el-input
        ref="email"
        v-model="item"
        type="textarea"
        resize="none"
        class="bg-transparent code-textarea text-texarea"
        autosize
        placeholder="Текст..."
        @input="$emit('input', $event)"
      ></el-input>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    typeText: { type: Number },
    text: {
      type: String,
      default: "Текст письма",
    },
    value: String,
  },
  computed: {
    item: {
      get() {
        return this.value;
      },
      set(e) {
        this.$emit("input", e);
      },
    },
  },
  watch: {
    // typeText() {
    //   if (this.typeText === 0) {
    //     this.item = this.defaultParam;
    //   }
    // },
  },

  created() {
    // if (this.typeText === 0) {
    //   this.item = this.defaultParam;
    // }
  },
};
</script>
