import { setField, setFieldObj, getError } from "@/helpers/store";
import { AJAX_GET } from "@/units/ajax";

const changeArray = function (obj) {
  return Object.entries(obj).map((item) => {
    return { value: item[0], label: item[1] };
  });
};

const checkSrcFavicon = (state, field) => {
  if (!state.settingLicense[field]) return;
  const newSrc = state.settingLicense[field].replace(
    process.env.VUE_APP_LOCAL_API,
    process.env.VUE_APP_PROD_API
  );
  return process.env.NODE_ENV === "production"
    ? state.settingLicense[field]
    : newSrc;
};

export default {
  namespaced: true,
  state: {
    ageLimitList: [],
    languageList: [],
    currencyList: [],
    buttonNameList: [],
    timezoneList: [],
    cost_per_day: null,
    sms_tariffs: [],
    settingLicense: {
      allow_register: true,
      favicon: "",
      logo: "",
      title: "Broni.biz",
      slider: [],
    },
  },
  getters: {
    srcLogo: (state) => checkSrcFavicon(state, "logo"),
    getFaviconLicense: (state) => checkSrcFavicon(state, "favicon"),
  },
  mutations: {
    setField,
    setFieldObj,
  },
  actions: {
    async getSettingsService({ state, commit }) {
      try {
        if (state.ageLimitList.length > 0) return;
        const { data } = await AJAX_GET("/api/resource/getDataForSelect", {
          noCancel: true,
        });
        commit("setField", {
          field: "ageLimitList",
          value: changeArray(data.age_limit),
        });
        commit("setField", {
          field: "languageList",
          value: changeArray(data.language),
        });
        commit("setField", {
          field: "currencyList",
          value: changeArray(data.currency),
        });
        commit("setField", {
          field: "buttonNameList",
          value: changeArray(data.button_name),
        });
        commit("setField", {
          field: "timezoneList",
          value: changeArray(data.timezone),
        });
      } catch (err) {
        console.log(err);
      }
    },
    async getSettings({ commit }) {
      try {
        const { data } = await AJAX_GET("/api/pay/settings", {
          noCancel: true,
        });
        commit("setField", {
          field: "cost_per_day",
          value: data.cost_per_day,
        });
        commit("setField", {
          field: "sms_tariffs",
          value: data.sms_tariffs,
        });
      } catch (err) {
        console.log(err);
        commit(
          "setNotif",
          {
            title: "Ошибка при получении данных. Повторите еще",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async getSettingsSite({ commit }) {
      try {
        const { data } = await AJAX_GET("/api/site/settings", {
          noCancel: true,
        });
        data.slider = data.slider.map((item) => ({
          ...item,
          image: `${process.env.VUE_APP_PROD_API}${item.slide_image}`,
        }));

        commit("setField", {
          field: "settingLicense",
          value: data,
        });
      } catch (err) {
        console.log(err);
        getError(commit, {
          title: "Ошибка при получении сохранении. Повторите еще",
          type: "error",
        });
      }
    },
  },
};
