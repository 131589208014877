<template>
  <div>
    <div
      v-if="!$route.meta.plainLayout"
      class="header flex ml-auto justify-between w-full items-center md:px-20 px-75"
      :style="{ width: $mq.tablet ? '100%' : `calc(100% - ${widthDiff})` }"
    >
      <router-link v-if="$mq.tablet" :to="{ name: 'dashboard' }">
        <svg-icon
          v-if="!$mq.phoneMobileLarge"
          icon-name="logo-mobile-path"
          width="29"
          height="21"
          class-name=""
        ></svg-icon>
        <img v-else-if="srcLogo" width="120" :src="srcLogo" alt="" />

        <svg-icon
          v-else-if="$mq.phoneMobileLarge"
          icon-name="logo"
          width="121"
          height="21"
          class-name=""
        ></svg-icon>
      </router-link>

      <div class="ml-auto flex items-center">
        <div class="header__item">
          <router-link :to="{ name: 'sms' }" class="text-right">
            <span class="small_text">СМС</span>
            <div v-if="user" class="num">{{ user.sms || 0 }} шт.</div>
          </router-link>
          <div
            v-if="!$mq.phone"
            class="table__icon_border table__icon_rounded cursor-pointer p-12"
            @click="$emit('showModal', 'Sms')"
          >
            <svg-icon
              icon-name="big-plus"
              width="20"
              height="20"
              class-name="text-white-normal"
            ></svg-icon>
          </div>
        </div>
        <div class="header__item header__balance">
          <router-link
            :to="{ name: 'balance' }"
            class="text-right flex items-center"
          >
            <div>
              <span class="small_text">Баланс</span>
              <div v-if="user" class="num">{{ user.balance || 0 }} ₽</div>
            </div>
          </router-link>

          <div
            v-if="!$mq.phone"
            class="table__icon_border table__icon_rounded cursor-pointer p-12"
            @click="$emit('showModal', 'Balance')"
          >
            <svg-icon
              icon-name="big-plus"
              width="20"
              height="20"
              class-name="text-white-normal"
            ></svg-icon>
          </div>
        </div>
        <div
          v-if="!$mq.phone"
          class="header__item header__notif table__icon table__icon_border p-12"
        >
          <router-link :to="{ name: 'news' }">
            <svg-icon
              icon-name="notif"
              width="20"
              height="20"
              class-name=""
            ></svg-icon
          ></router-link>
        </div>
        <div
          class="flex items-center text-14 leading-25"
          :class="{ 'ml-30': !$mq.tablet }"
        >
          <div v-if="!$mq.tablet" class="text-right">
            <router-link
              :to="{ name: 'settings' }"
              class="sidebar-header__user font-medium leading-20 text-white-normal block"
            >
              {{ user && user.email ? user.email : "" }}
            </router-link>
            <router-link
              :to="{ name: 'balance' }"
              class="sidebar-header__user font-medium leading-20 text-white-normal block"
            >
              <span v-if="isTariffName" class="small_text"
                >{{ isTariffName }}: {{ `${daysLeft} дн.` }}
              </span>
            </router-link>
          </div>
          <div
            class="relative w-40 h-40 ml-15 flex items-center justify-center"
          >
            <el-popover
              v-model="openModal"
              class="ml-auto cursor-pointer lg:absolute promo-tooltip"
              placement="bottom-end"
              popper-class="control-poper"
              width="auto"
              trigger="click"
            >
              <div slot="reference" class="header__item header__user">
                <img
                  v-if="user && user.avatar_path"
                  :src="user.avatar_path"
                  alt="avatar"
                />
              </div>
              <div
                class="header__menu px-13 py-5 text-white-normal"
                @click="openModal = !openModal"
              >
                <router-link
                  v-for="item in avatarMenu"
                  :key="item.title"
                  :to="{
                    path: item.linkName,
                    query: { name: item.linkParams },
                  }"
                  class="block px-2 py-5 text-xl"
                  >{{ item.title }}</router-link
                >
                <div
                  class="px-2 py-5 pt-10 mt-10 flex items-center border-t cursor-pointer"
                  @click="logout"
                >
                  <svg-icon
                    icon-name="logout"
                    width="18"
                    height="18"
                    class-name="mr-10 text-white-350 sidebar-link__icon"
                  ></svg-icon>
                  Выйти из аккаунта
                </div>
              </div>
            </el-popover>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { AJAX_POST } from "@/units/ajax";

export default {
  props: {
    widthDiff: String,
  },
  data() {
    return {
      openModal: false,
      showSms: false,
      showBalance: false,
      avatarMenu: [
        {
          title: "Контактные данные",
          linkName: "/settings",
          linkParams: "contact",
        },
        {
          title: "Смена пароля",
          linkName: "/settings",
          linkParams: "password",
        },
        {
          title: "Соглашение",
          linkName: "/settings",
          linkParams: "agreement",
        },
        {
          title: "Политика",
          linkName: "/settings",
          linkParams: "politics",
        },
        {
          title: "Системы аналитики",
          linkName: "/settings",
          linkParams: "analytic",
        },
        {
          title: "Уведомления",
          linkName: "/settings",
          linkParams: "notifications",
        },
        {
          title: "Эквайринг",
          linkName: "/settings",
          linkParams: "payment",
        },
        {
          title: "Источники заказов",
          linkName: "/settings",
          linkParams: "orders",
        },
        {
          title: "Битрикс24",
          linkName: "/settings",
          linkParams: "integrationbitrix",
        },
      ],
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("settings", ["srcLogo"]),

    isTariffName() {
      return this.user ? this.user.tariff.tariff_name : "";
    },

    daysLeft() {
      const value =
        this.user.balance / (this.cost_per_day * this.user.resources_count);
      // if (this.user.balance <= 0 || !value) return 0;
      // return Math.floor(value);
      return this.user.tariff_days_remaining;
    },
  },
  methods: {
    async logout() {
      await AJAX_POST("/api/logout");
      this.$router.push("/login");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";
.header {
  background: rgba(255, 255, 255, 0.04);
  height: 76px;
  &__menu {
    font-size: 14px;
  }
  &__item {
    .num {
      font-size: 10px;
    }
  }
  &__user {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    background: grey;
    overflow: hidden;
    img {
      max-width: 100%;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    margin-left: 15px;
    font-size: 12px;
    line-height: 20px;
  }
  &__icon {
    &_rounded {
      border-radius: 3px;
    }
  }
  &__notif {
    margin-left: 30px !important;
  }
  &__balance {
    margin-left: 25px;
    .num {
      color: $--color-primary;
      font-size: 10px;
    }
  }
}
.table {
  &__icon {
    &_border {
      margin-right: 0;
      margin-left: 15px;
    }
  }
}
@media (min-width: 640px) and (max-width: 1025px) {
  .header {
    height: 60px;
    padding: 15px;
    &__item {
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}
@media (min-width: 640px) {
  .header {
    height: 60px;
    &__item {
      .num {
        font-size: 14px;
      }
    }
    &__balance {
      .num {
        font-size: 14px;
      }
    }
  }
}
</style>
