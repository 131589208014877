var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'cell',
    _vm.size,
    { classObject: !_vm.value.active },
    { disabledSlot: !_vm.value.active } ],on:{"click":function($event){return _vm.$emit('click')}}},[_c('div',{staticClass:"cell__time relative",style:({
      background: _vm.isTypeTheme ? _vm.customStyle.background_cells_fields : '',
      opacity:
        _vm.isTypeTheme && !_vm.value.active
          ? _vm.customStyle.opacity === 100
            ? ("" + (_vm.customStyle.opacity))
            : ("0." + (_vm.customStyle.opacity))
          : '',
      color: _vm.isTypeTheme ? _vm.customStyle.active_text_color : '',
    })},[(_vm.value.discount)?_c('span',{staticClass:"proc"},[_vm._v("%")]):_vm._e(),_vm._v(" "+_vm._s(_vm.value.time)+" ")]),(_vm.isMaket ? _vm.widget.isShowPrice : _vm.widgetPublic.isShowPrice)?_c('span',{staticClass:"cell__price",style:({
      opacity: _vm.isTypeTheme && !_vm.value.active ? ("0." + (_vm.customStyle.opacity)) : '',
      color: _vm.isTypeTheme ? _vm.customStyle.active_text_color : '',
    })},[_vm._v(" "+_vm._s(_vm.addPristavka ? "от" : "")+" "+_vm._s(_vm.value.discount ? _vm.value.discount_price : _vm.value.price)+" "+_vm._s(_vm.value.currency)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }