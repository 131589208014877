import { setField, setFieldObj } from "@/helpers/store";
import { AJAX_GET, AJAX_DELETE, AJAX_POST, AJAX_PATCH } from "@/units/ajax";
import moment from "moment";

export default {
  namespaced: true,
  state: {
    historyPay: [],
    historyPayDebit: [],
    historyPayTotal: null,
    historyDebitTotal: null,
  },
  getters: {},
  mutations: {
    setField,
    setFieldObj,
  },
  actions: {
    async getHistoryPay({ state, commit }, perPage = 15) {
      const api = `/api/pay/history?type=1&page=1&perPage=${perPage}`;
      const { length } = state.historyPay;
      try {
        const { data } = await AJAX_GET(api, { noCancel: true });
        commit("setField", {
          field: "pay",
          value: data,
        });

        const historyPay =
          data.data &&
          data.data.map((el) => ({
            date: {
              date: moment(el.created_at).format("DD.MM.YYYY"),
              time: moment(el.created_at).format("HH:mm:ss"),
            },
            ...el,
          }));

        commit("setField", {
          field: "historyPayTotal",
          value: data.total,
        });

        commit("setField", {
          field: "historyPay",
          value: historyPay,
        });
      } catch (err) {
        console.error(api, err);
        commit(
          "setNotif",
          {
            title: "Ошибка при получении данных.",
            type: "error",
          },
          { root: true }
        );
      }
    },
    async getHistoryDebit({ state, commit }, perPage = 15) {
      const api = `/api/tariff/getlog?page=1&perPage=${perPage}`;
      const { length } = state.historyPayDebit;

      try {
        const { data } = await AJAX_POST(api, { noCancel: true });
        commit("setField", {
          field: "pay",
          value: data,
        });
        const historyPayDebit =
          data.data &&
          data.data.map((el) => ({
            date: {
              date: moment(el.created_at).format("DD.MM.YYYY"),
              time: moment(el.created_at).format("HH:mm:ss"),
            },
            ...el,
          }));
        commit("setField", {
          field: "historyDebitTotal",
          value: data.total,
        });

        commit("setField", {
          field: "historyPayDebit",
          value: historyPayDebit,
        });
      } catch (err) {
        console.error(api, err);
        commit(
          "setNotif",
          {
            title: "Ошибка при получении данных.",
            type: "error",
          },
          { root: true }
        );
      }
    },
  },
};
