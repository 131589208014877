<template>
  <div class="xs:px-10">
    <svg-icon
      width="50"
      height="50"
      class="icon-done"
      icon-name="isDone"
    ></svg-icon>
    <div class="title">Виджет успешно создан!</div>
    <p class="hint sub-title">
      Далее скопируйте полученный код и вставьте на страницу Вашего сайта
    </p>
    <div class="custom-block">
      <p class="hint">
        Чтобы внедрить виджет с расписанием, скопируйте полученный код и
        вставьте его на внутреннюю страницу вашего сайта.
      </p>
      <el-input
        class="custom-alert is-output"
        :value="textCopy"
        type="textarea"
        :rows="7"
      />
      <el-button
        type="primary"
        class="custom-button"
        @click="copy(textCopy, { param: 'textCopy' })"
      >
        <svg-icon class="mr-10" icon-name="copy"></svg-icon>
        Скопировать код
      </el-button>
      <p class="hint mt-30">
        Также вы можете скопировать ссылку на виджет и разместить ее в
        социальных сетях.
      </p>
      <el-input
        class="custom-alert is-output"
        :value="textCopySocial"
        type="textarea"
        :rows="1"
      />
      <el-button
        type="primary"
        class="custom-button"
        @click="copy(textCopySocial, { param: 'textCopySocial' })"
      >
        <svg-icon class="mr-10" icon-name="copy"></svg-icon>
        Скопировать ссылку
      </el-button>
    </div>
    <div class="flex">
      <el-button @click="$router.push({ name: 'widgets' })">
        К виджетам
      </el-button>
      <el-button @click="createYet"> Создать еще </el-button>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "FinishCreateWidget",
  computed: {
    ...mapState("widget", ["widget"]),
    textCopy() {
      // ИЗ-ЗА ТОГО ЧТО ПЕРЕМЕННАЯ НЕ МОЖЕТ НАЧИНАТЬСЯ С ЧИСЛА ЧАСТЬ ВИДЖЕТОВ НЕ ОТОБРАЖАЛОСЬ
      //   let ${this.widget.hash} = new Wbb('${this.widget.hash}');
      // ${this.widget.hash}.init();
      return `<div class="wbb" id="${this.widget.hash}"></div> \n <script src="${process.env.VUE_APP_PROD_API}/widgetbb.js" type="text/javascript"><\/script> \n <script type="text/javascript">
      new Wbb('${this.widget.hash}').init()
  <\/script>`;
    },
    textCopySocial() {
      return `${process.env.VUE_APP_PROD_API}/wd/${this.widget.hash}`;
    },
  },
  methods: {
    ...mapMutations("widget", ["setWidgetEmpty"]),
    ...mapMutations(["setNotif"]),
    copy(e, param) {
      navigator.clipboard.writeText(e);
      this.setNotif({
        title:
          param.param === "textCopy"
            ? `Код виджета успешно скопирован`
            : `Ссылка на виджет успешно скопирована`,
      });
    },
    createYet() {
      this.setWidgetEmpty();
      this.$emit("toStart");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.icon-done {
  margin-bottom: 20px;
}

.title {
  margin-bottom: 0;
}
.sub-title {
  color: $--color-white-5;
  margin-bottom: 40px;
}
.custom-block {
  padding: 30px;
}
.hint {
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 30px;
}
.custom-alert {
  margin-bottom: 30px;
  padding: 0;
}

::v-deep {
  .el-textarea {
    // height: 155px;
    &__inner {
      padding: 12px 20px;
    }
  }
}
</style>
