<template>
  <el-col
    :span="$mq.tablet ? mq.mobile : mq.desktop"
    class="large:mb-20 lg:w-full lg:mb-15"
  >
    <label class="text-12 font-medium text-white-normal flex flex-col">
      <span class="mb-7">{{ text.label }}</span>
      <el-input :placeholder="text.placeholder" />
    </label>
  </el-col>
</template>

<script>
export default {
  props: {
    text: Object,
    items: Array,
    mq: {
      type: Object,
      default: () => ({
        mobile: 24,
        desktop: 6,
      }),
    },
  },
  data() {
    return {
      date: null,
    };
  },
  changeDate() {
    // this.$emit("selected", this.date);
  },
};
</script>
