<template>
  <Modal
    class="modal"
    :show="show"
    :show-close="false"
    title="+7 (921) 342-09-33"
    subtitle="Екатерина Константинопольская"
    @close="$emit('close')"
  >
    <Tabs v-model="activeTab" :items="tabs" class="my-20" />
    <component :is="activeTab" />
  </Modal>
</template>

<script>
import Sms from "@/components/Orders/ClientInfo/Sms.vue";
import Calls from "@/components/Orders/ClientInfo/Calls.vue";
import Orders from "@/components/Orders/ClientInfo/Orders.vue";
import Email from "@/components/Orders/ClientInfo/Email.vue";
import Chats from "@/components/Orders/ClientInfo/Chats.vue";
import Tabs from "@/components/Promo/Tabs.vue";
import Modal from "@/components/Modal.vue";

export default {
  components: {
    Sms,
    Calls,
    Orders,
    Chats,
    Email,
    Tabs,
    Modal,
  },
  props: {
    show: Boolean,
  },
  data() {
    return {
      activeTab: "sms",
      tabs: [
        { label: "SMS", name: "sms" },
        { label: "Звонки", name: "calls" },
        { label: "Заказы", name: "orders" },
        { label: "Чаты", name: "chats" },
        { label: "E-mail", name: "email" },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  padding-top: 10px;
}
</style>
