<template>
  <div>
    <el-pagination
      layout="prev, pager, next"
      background
      :total="total"
      :page-size="pageSize"
      :current-page="currentPage"
      :pager-count="pagerCount"
      @current-change="$emit('change', $event)"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    pageSize: {
      type: Number,
      default: 10,
    },
    total: {
      type: Number,
      default: 1000,
    },
    currentPage: {
      type: Number,
    },
    pagerCount: {
      type: Number,
    },
  },
  data() {
    return {
      // pagerCount: 1,
    };
  },
  created() {
    // this.pagerCount = this.currentPage;
  },
};
</script>

<style lang="scss" scoped>
.pagination {
  padding: 20px;
  box-sizing: content-box;
  background: rgba(255, 255, 255, 0.04);
}

@media (max-width: 768px) {
  .pagination {
    padding: 15px;
  }
}
::v-deep .el-pager {
  li {
    font-size: 14px;
    width: 40px;
    height: 40px;
    line-height: 40px;
    vertical-align: middle;
    background: none;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-left: none;
    color: white;
    font-weight: 400;
    &:first-of-type {
      border-left: 1px solid rgba(255, 255, 255, 0.2);
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }
    &:last-of-type {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
    &.active {
      background: #ffbe3d;
      color: black;
    }
  }
}
@media (max-width: 500px) {
  ::v-deep {
    .el-pagination.is-background {
      display: flex;
    }
    .el-pager {
      display: flex;
      flex-wrap: wrap;
    }
    .el-pagination.is-background .btn-prev,
    .el-pagination.is-background .btn-next,
    .el-pagination.is-background .el-pager li {
      margin: 5px;
    }
  }
}
</style>
<style lang="scss">
.pagination {
  &.el-pagination {
    button:disabled {
      display: none;
    }
    .btn-next,
    .btn-prev {
      background: none;
      border: 1px solid rgba(255, 255, 255, 0.2);
      padding: 10px;
      width: 40px;
      height: 40px;
      border-radius: 3px;
      .el-icon {
        font-size: 16px;
      }
    }
    .btn {
      &-next {
        margin-left: 15px;
      }
      &-prev {
        margin-right: 15px;
      }
    }
  }
}
</style>
