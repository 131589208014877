<template>
  <div class="pt-40 pb-80 w-full max-w-lg settings lg:pt-15 lg:px-15">
    <h1
      class="text-36 leading-48 text-white-normal font-semibold lg:text-22 lg:leading-36 mb-20"
    >
      История изменений
    </h1>
    <Search />
    <PreTables text="Всего записей: 255" :start="1" :end="10" />
    <SearchBar input-placeholder="Поиск по истории изменений" />
    <Table @showDetails="showModal = true" />
    <Pagination />
    <Details :show="showModal" @close="showModal = false" />

    <NoData text="Нет изменений в бронированиях" />
  </div>
</template>

<script>
import simplebar from "simplebar-vue";
import Search from "@/components/HistoryChanges/SearchPanel";
import Table from "@/components/HistoryChanges/Table";
import PreTables from "@/components/Table/PreTables";
import SearchBar from "@/components/Table/SearchBar";
import Pagination from "@/components/Table/Pagination";
import Details from "@/components/HistoryChanges/Details";
import NoData from "@/components/NoData";

export default {
  name: "HistoryChanges",
  components: {
    Search,
    Table,
    SearchBar,
    PreTables,
    Pagination,
    Details,
    NoData,
  },
  data() {
    return {
      showModal: false,
    };
  },
  methods: {},
};
</script>

<style lang="scss"></style>
