<template>
  <div class="wrapper">
    <spinner v-if="isLoading" />
    <template v-else>
      <div class="wrapper__content xs:px-5">
        <div class="wrapper__content-ww">
          <div v-if="activeCustomComponent" class="column">
            <ButtonIcon
              class="button-back"
              icon-name="back"
              text="К виджетам"
              @click="$router.push({ name: 'widgets' }), returnToWidgets()"
            />
            <div
              v-if="widget.msg"
              class="custom-alert not-set-schedule absolute w-full"
            >
              {{ widget.msg }}
            </div>
            <div v-if="!widget.msg">
              <h1
                class="font-semibold text-white-normal text-28 leading-44 lg:text-22 lg:leading22 mb-10 xs:mb-5"
              >
                {{
                  isNew
                    ? "Создание виджета для сайта"
                    : `Редактирование виджета ${widget.hash}`
                }}
              </h1>

              <p class="hint">
                {{ isNew ? "Создайте виджет для вашего сайта" : `` }}
              </p>
              <el-steps
                :active="activeStep"
                process-status="process"
                finish-status="success"
                :direction="$mq.tablet ? 'vertical' : 'horizontal'"
              >
                <el-step
                  v-for="(step, index) in allSteps"
                  :key="index"
                  :title="step.value"
                  :class="[{ seans: index === 1 }, { rezult: index === 2 }]"
                >
                </el-step>
              </el-steps>
              <component
                :is="activeCustomComponent"
                v-model="activeStep"
                @addBranch="setBrabch"
              />
            </div>
            <div class="wrapper__content-ww">
              <!-- <Settings></Settings> -->
              <div
                :class="[
                  'widget-custom',
                  { 'widget-custom__open': isShowWidget },
                ]"
              >
                <div v-if="activeCustomComponent && isShowWidget">
                  <component
                    :is="showWidgetComponent"
                    class="qwe"
                    is-maket
                    :style="isCustom"
                    :style-custom="custom"
                    :is-individual-theme="isIndividualTheme"
                    :show-price="widget.isShowPrice"
                    :show-calendar="widget.showCalendarOpen"
                  />
                </div>
              </div>
              <el-button
                v-if="$mq.tablet && activeStep >= 1"
                :icon="isShowWidget ? 'el-icon-close' : 'el-icon-mobile'"
                :class="['show-widget', { 'show-widget__close': isShowWidget }]"
                @click="isShowWidget = !isShowWidget"
              ></el-button>
            </div>
          </div>
          <FinishCreateWidget v-else @toStart="activeStep = 0" />
          <div v-if="!widget.msg && !$mq.phone" class="column">
            <div v-if="activeCustomComponent">
              <component
                :is="showWidgetComponent"
                class="qwe"
                is-maket
                :style="isCustom"
                :style-custom="custom"
                :is-individual-theme="isIndividualTheme"
                :show-price="widget.isShowPrice"
                :show-calendar="widget.showCalendarOpen"
              />
              <p
                v-if="activeStep === 1"
                class="hint-title__medium-white px-30 py-10"
              >
                Ширина виджета меняется в зависимости от ширина контентной
                области вашего сайта
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import SelectService from "@/views/Widgets/components/SelectService.vue";
import ScheduleOneService from "@/views/Widgets/components/ScheduleOneService.vue";
import ScheduleMultiService from "@/views/Widgets/components/ScheduleMultiService.vue";
import ScheduleServices from "@/views/Widgets/components/ScheduleServices.vue";
import WidgetDesign from "@/views/Widgets/components/WidgetDesign.vue";
import SettingsForm from "@/views/Widgets/components/SettingsForm.vue";
import FinishCreateWidget from "@/views/Widgets/components/FinishCreateWidget.vue";

import ShowService from "@/views/Widgets/components/ShowService.vue";
import ScheduleWidgetSingleGrid from "@/views/Widgets/components/ScheduleWidgetSingleGrid.vue";
import ScheduleWidgetSingleForm from "@/views/Widgets/components/ScheduleWidgetSingleForm.vue";
import ScheduleWidgetServicesForm from "@/views/Widgets/components/ScheduleWidgetServicesForm";
import ScheduleWidgetManyGrid from "@/views/Widgets/components/ScheduleWidgetManyGrid.vue";
import ScheduleWidgetManyCard from "@/views/Widgets/components/ScheduleWidgetManyCard.vue";
import FormSticky from "@/views/Widgets/components/FormSticky.vue";
import FormCenter from "@/views/Widgets/components/FormCenter.vue";

import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "CreateWidget",
  components: {
    SelectService,
    ScheduleOneService,
    ScheduleMultiService,
    ScheduleServices,
    WidgetDesign,
    SettingsForm,
    FinishCreateWidget,
    ShowService,
    ScheduleWidgetSingleGrid,
    ScheduleWidgetSingleForm,
    ScheduleWidgetServicesForm,
    ScheduleWidgetManyGrid,
    ScheduleWidgetManyCard,
    FormSticky,
    FormCenter,
  },
  data() {
    return {
      isShowWidget: false,
      isLoading: true,
      activeStep: 0,
      //  custom: {},
    };
  },
  computed: {
    ...mapState("widget", ["widget", "widgetPublic"]),
    ...mapGetters("widget", ["isNew", "isSingle"]),
    isIndividualTheme() {
      return !!this.widgetPublic.typeTheme || this.isMaket;
    },
    custom() {
      const designWidgetParams = this.widgetPublic.design ?? this.widget.design;
      return {
        ...designWidgetParams,
      };
    },
    activeCustomComponent() {
      if (this.activeStep === 0) return SelectService;
      if (this.activeStep === 1) {
        // if (this.isSingle) {
        //   return ScheduleOneService;
        // }
        // if (this.widget.service_type === "services") {
        //   return ScheduleMultiService;
        // }
        // return ScheduleMultiService;

        return this.isSingle ? ScheduleOneService : ScheduleMultiService;
      }
      if (this.activeStep === 2) return WidgetDesign;
      if (this.activeStep === 3) return SettingsForm;
      return null;
    },
    showWidgetComponent() {
      if (this.activeStep === 0) return ShowService;
      if (this.activeStep === 1 || this.activeStep === 2) {
        // if (this.widget.service_type) {
        //   return ScheduleWidgetServicesForm;
        // }
        if (this.isSingle) {
          return this.widget.typeSingle === "grid"
            ? ScheduleWidgetSingleGrid
            : ScheduleWidgetSingleForm;
        }
        if (this.widget.typeMulti === "grid") {
          return ScheduleWidgetManyGrid;
        }
        if (this.widget.typeMulti === "tree") {
          return ScheduleWidgetServicesForm;
        }
        return ScheduleWidgetManyCard;

        // return this.widget.typeMulti === "grid"
        //   ? ScheduleWidgetManyGrid
        //   : ScheduleWidgetManyCard;
      }
      if (this.activeStep === 3) {
        return this.widget.typeBookingForm === "sticky"
          ? FormSticky
          : FormCenter;
      }
      return ShowService;
    },
    isCustom() {
      return {
        // height: "100vh",
        background:
          this.activeStep === 0
            ? "transparent"
            : `${this.widget.design?.block_background}`,
        color: `${this.widget.design?.button_text_color}`,
      };
    },
  },
  async created() {
    this.allSteps = [
      {
        id: 0,
        value: "Услуги",
      },
      {
        id: 1,
        value: "Расписание",
      },
      {
        id: 2,
        value: "Дизайн",
      },
      {
        id: 3,
        value: "Форма",
      },
    ];
    const { hash } = this.$route.params ?? this.widget.service_id;
    if (hash) {
      await this.getWidget(hash);
      if (this.widget.success !== false) {
        await this.getWidgetPublic({ hash });
      }
    } else this.setWidgetEmpty();

    await this.getSettingsService();
    this.isLoading = false;
  },
  methods: {
    ...mapMutations("widget", ["setWidgetEmpty", "setField"]),
    ...mapActions("widget", ["getWidget", "getWidgetPublic"]),
    ...mapActions("settings", ["getSettingsService"]),
    ...mapActions("service", ["addBranchId"]),

    returnToWidgets() {
      this.setField({ field: "widgetPublic", value: {} });
    },
    setBrabch(e) {
      // const obj = { hash: this.widget.service_id, branch_id: e.checkBranch };
      // this.addBranchId(obj);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.button-back {
  margin-bottom: 20px;
}
.title {
  margin-bottom: 5px;
}
.hint {
  color: $--color-white-5;
  margin-bottom: 40px;
}
.wrapper__content-ww {
  justify-content: space-between;
}
.column {
  width: calc(50% - 50px);
  @media (max-width: 456px) {
    width: 100%;
  }
}
::v-deep {
  .el-steps {
    margin-bottom: 40px;
  }
  .form-sticky {
    width: 360px;
  }
}
// @media (max-width: 1380px) and (min-width: 1025px) {
//   ::v-deep .el-step {
//     &__main {
//       max-width: 120px;
//       transform: translateX(-33%) !important;
//       margin-top: 10px;
//       .el-step__title {
//         line-height: 24px;
//       }
//     }
//     &:last-of-type {
//       .el-step__main {
//         text-align: right;
//         transform: translateX(-66%) !important;
//       }
//     }
//     &:first-of-type {
//       .el-step__main {
//         transform: translate(0) !important;
//       }
//     }
//   }
// }
@media (max-width: 768px) {
  ::v-deep .el-step {
    &__head {
      padding: 0 0 20px;
    }
    &__main {
      display: flex;
      align-items: center;
      padding-left: 40px !important;
      padding-bottom: 20px !important;
    }
    &__title {
      padding-bottom: 0px !important;
    }
    &.is-vertical .el-step__line {
      left: 19px;
    }
  }
  .progress {
    border: none;
    padding: 20px 15px 15px;

    .close {
      width: 30px;
      height: 30px;
    }
  }
}
</style>
