<template>
  <div class="list-fields">
    <div class="list-fields__row">
      <div class="list-fields__item">
        <el-form-item prop="basePrice" class="w-full">
          <div slot="label" class="flex">
            {{ headers.headers[0].title
            }}<span v-if="headers.headers[0].valid" class="danger">*</span>
          </div>
          <el-input
            v-model="tariff_name"
            :placeholder="headers.headers[0].placeholder"
            @input="emitData()"
          ></el-input>
        </el-form-item>
      </div>
      <div class="list-fields__item">
        <InputSelect
          v-model="duration"
          prop="durationService"
          :label="headers.headers[1].title"
          :placeholder="headers.headers[1].placeholder"
          :list-value="listDuration"
          :select-value="time_unit"
          @setValue="time_unit = $event"
          @input="emitData()"
        ></InputSelect>
      </div>
      <div class="list-fields__item">
        <el-button
          v-if="showDeletButton"
          class="delete-button"
          icon="el-icon-close"
          @click="$emit('delete')"
        >
          Удалить тариф
        </el-button>
      </div>
      <div v-if="data.length > 1" class="list-fields__actions">
        <div class="list-fields__actions-stub"></div>
      </div>
    </div>
    <ListField3
      v-model="data"
      class="list-fields3"
      :title-button="titleButton"
      :headers="headers.forList"
      @input="emitData()"
    ></ListField3>
  </div>
</template>

<script>
import ListField3 from "@/components/ListField3.vue";
import { listDuration } from "@/helpers/services.js";

export default {
  name: "ListField5",
  components: { ListField3 },
  props: {
    title: {
      type: String,
      default: "",
    },
    titleButton: {
      type: String,
      default: "Добавить новое поле",
    },
    placeholder: {
      type: String,
      default: "Введите значение",
    },
    value: {
      type: Object,
      default: null,
    },
    headers: {
      type: Object,
      default: null,
    },
    showDeletButton: Boolean,
  },
  data() {
    return {
      tariff_name: "",
      duration: 60,
      time_unit: "minutes",
      id: null,
      data: [
        {
          id: null,
          from: 1,
          to: null,
          base_price: null,
          holidays_price: null,
        },
      ],
    };
  },
  watch: {
    value() {
      this.setData();
    },
  },
  created() {
    this.listDuration = listDuration;
    this.setData();
  },
  methods: {
    emitData() {
      this.$emit("input", this.$data);
    },
    deleteField({ id }) {
      this.data = this.data.filter((field) => field.id !== id);
    },
    setData() {
      this.tariff_name = this.value.tariff_name;
      this.duration = this.value.duration;
      this.time_unit = this.value.time_unit;
      this.data = this.value.data;
      this.id = this.value.id;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-fields__row {
  margin-bottom: 0;
}

.delete-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 24px;
  ::v-deep i {
    font-size: 22px;
    margin-bottom: 3px;
  }
  ::v-deep span {
    margin-left: 11px !important;
  }
}

.list-fields3 {
  margin: 0 0 30px;
}

@screen lg {
  .list-fields {
    margin-bottom: 15px;
  }
  .list-fields3 {
    margin: 0px;
  }
  .delete-button {
    margin-bottom: 13px;
  }
}
</style>
