<template>
  <div class="resource-integration">
    <BlockCreateWidget />
    <integration-entry class="mt-50 lg:mt-10 mb-50"></integration-entry>
    <!-- <ItemWidget
      v-for="(widget, index) in widgetsService"
      :key="index"
      :widget="widget"
    >
    </ItemWidget> -->
    <div v-if="isLoading">
      <spinner v-if="isLoading" />
    </div>
    <div v-else>
      <ItemCard
        v-for="(widget, index) in widgetsService"
        :key="index"
        :elements="widget"
      />
    </div>
    <div class="custom-block card text-22">
      Интеграция по API
      <div class="hl"></div>
      <p class="integration__text">
        Вы можете интегрировать расписание услуг на свой сайт. Его можно
        получить в формате JSON, и вывести его в собственном дизайне. Механизм
        получения расписания и формирования запросов бронирования описан в
        инструкции ниже. Данная инструкция так же действительна для интеграции
        расписания с агрегаторами.
      </p>
      <div>
        <instruction-item
          class="mt-20"
          only-button
          :doc="{
            link: user.instructions.api.link,
            buttonText: `Инструкция`,
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import IntegrationEntry from "@/components/IntegrationEntry";
import BlockCreateWidget from "@/components/BlockCreateWidget.vue";
// import ItemWidget from "@/components/ItemWidget.vue";
import ItemCard from "@/views/Widgets/ItemCard";
import InstructionItem from "@/components/InstructionItem";

import { mapState, mapActions } from "vuex";

export default {
  name: "ResourceIntegration",
  components: {
    IntegrationEntry,
    BlockCreateWidget,
    ItemCard,
    InstructionItem,

    // ItemWidget
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState("service", ["service"]),
    ...mapState("widget", ["widgetsService"]),
    ...mapState("user", ["servicesList", "user"]),
  },
};
</script>

<style lang="scss">
.integration__text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
}
</style>
