<template>
  <div>
    <p
      class="title-widget"
      :style="{
        color: isThemeWidget ? customStyle.active_text_color : '',
      }"
    >
      Количество участников
    </p>
    <el-radio-group v-model="group" @input="emitData($event)">
      <el-radio v-for="(item, i) in settings.data.group" :key="i" :label="item">
        <div
          class="radio__title"
          :style="{
            color: isThemeWidget ? customStyle.active_text_color : '',
          }"
        >
          Группа от {{ item.from }} до {{ item.to }} человек
        </div>
        <div
          class="radio__sub-title"
          :style="{
            color: isThemeWidget ? customStyle.inactive_text_color : '',
          }"
        >
          {{
            isHoliday && item.holidays_price >= 0
              ? item.holidays_price + selectCell.weekend_price_additional
              : item.base_price + selectCell.price_additional
          }}
          {{ selectCell.currency }}
        </div>
      </el-radio>
    </el-radio-group>
    <p
      v-if="isShowAddSeats"
      class="title-widget"
      :style="{
        color: isThemeWidget ? customStyle.active_text_color : '',
      }"
    >
      Дополнительные участники
    </p>
    <div v-if="isShowAddSeats" class="input-number">
      <div class="input-number__info">
        <span
          class="input-number__info-title"
          :style="{
            color: isThemeWidget ? customStyle.active_text_color : '',
          }"
          >Количество</span
        >
        <span
          class="input-number__info-subtitle"
          :style="{
            color: isThemeWidget ? customStyle.inactive_text_color : '',
          }"
        >
          {{ priceAddPersons }} {{ selectCell.currency }}
        </span>
      </div>
      <el-input-number
        v-model="addPersons"
        class="input-number__input"
        :class="{ 'custom-style': isThemeWidget }"
        :max="maxCount"
        :min="0"
        :style="{
          background: isThemeWidget ? customStyle.background_cells_fields : '',
        }"
        @input="changePersons"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "DepSeatsGroup",
  props: {
    value: {
      type: [Number, String],
      default: null,
    },
    selectCell: {
      type: Object,
      default: () => {},
    },
    settings: {
      type: Object,
      default: () => {},
    },
    customStyle: { type: Object },
    isThemeWidget: { type: String },
  },
  data() {
    return {
      group: null,
      priceAddPersons: null,
      addPersons: null,
    };
  },
  computed: {
    isShowAddSeats() {
      if (this.settings.data.group.length === 1) return true;
      if (this.group && this.settings.data.include_surcharge_for_seats) {
        const lastChild = this.settings.data.group[
          this.settings.data.group.length - 1
        ];

        return this.group.id === lastChild.id;
      }
      return false;
    },
    isHoliday() {
      return this.selectCell.is_holiday;
    },
    maxCount() {
      const lastChild = this.settings.data.group_surcharge[
        this.settings.data.group_surcharge.length - 1
      ];
      return Number(lastChild.to) || 99999;
    },
  },
  watch: {
    group() {
      this.isDis = !!this.group;
      this.$emit("isDis", this.isDis);
    },
  },
  created() {
    this.group = this.settings.data.group.find((el, idx) => {
      return idx === 0;
    });
    this.emitData(this.group);
  },
  methods: {
    changePersons(value) {
      const findInterval = this.settings.data.group_surcharge.find((el) => {
        const to = el.to ? Number(el.to) : this.maxCount;
        return this.addPersons <= to;
      });
      this.priceAddPersons = this.isHoliday
        ? findInterval.holidays_price
        : findInterval.base_price;
      this.emitData();
    },
    emitData(value) {
      const group = this.group
        ? {
            price: this.isHoliday
              ? this.group.holidays_price
              : this.group.base_price,
            persons_from: this.group.from,
            persons_to: this.group.to,
            id: this.group.id,
          }
        : null;
      this.$emit("SetForGroup", {
        group,
        priceAddPersons: this.isShowAddSeats ? this.priceAddPersons : 0,
        addPersons: this.isShowAddSeats ? this.addPersons : 0,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

::v-deep {
  .el-radio {
    margin-bottom: 12px;
    &-group {
      margin-bottom: 18px;
    }
  }
}
.radio__title {
  margin-bottom: 6px;
}

.bk-info {
  margin-bottom: 6px;
  &__title {
    flex-grow: 1;
    width: auto;
  }
  &__info {
    flex-grow: 0;
  }
}
.custom-style {
  ::v-deep .el-input__inner {
    color: var(--colorCustom);
  }

  ::v-deep .el-input-number__increase,
  ::v-deep .el-input-number__decrease {
    background: var(--colorInactive);
    color: var(--colorCustom);
  }
  ::v-deep .el-input-number__increase:hover,
  ::v-deep .el-input-number__decrease:hover {
    color: var(--colorCustomButton);
  }
}
::v-deep
  .el-radio.is-checked:hover
  > .el-radio__input.is-checked
  > .el-radio__inner {
  border-color: var(--colorCustomButton);
}
::v-deep .el-radio:hover > .el-radio__input > .el-radio__inner {
  border-color: var(--colorCustom);
}
</style>
