<template>
  <div>
    <span class="return__text">{{ `или выберите количество: ` }}</span>
    <div class="input-number my-10">
      <div class="input-number__info">
        <span class="input-number__info-title">{{ count }}</span>
      </div>
      <el-input-number
        v-model="counters.itemCount"
        :disabled="isDisabled"
        class="input-number__input"
        :max="item.rent.duration"
        :min="0"
        @input="$emit('setItemPlace', counters)"
      />
    </div>
    <div class="input-number my-10">
      <div class="input-number__info">
        <span class="input-number__info-title"> мест </span>
      </div>
      <el-input-number
        v-model="counters.itemPlace"
        :disabled="isDisabled"
        class="input-number__input"
        :max="item.rent.places"
        :min="0"
        @input="$emit('setItemPlace', counters)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "RentDepSetTime",
  props: {
    item: {
      type: Object,
    },
    isDisabled: { type: Boolean },

    count: String,
  },
  data() {
    return {
      counters: {
        itemCount: null,
        itemPlace: null,
      },
    };
  },
  created() {
    this.counters.itemCount = this.item.rent.duration;
    this.counters.itemPlace = this.item.rent.places;
  },
};
</script>

<style lang="scss" scoped>
.return {
  &__text {
    color: #fff;
  }
}
</style>
