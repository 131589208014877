<template>
  <el-form
    ref="baseInfo"
    hide-required-asterisk
    label-position="top"
    :model="{ ...company }"
    :rules="rules"
    class="contact-data"
  >
    <div class="contact-data__list-fields">
      <el-form-item prop="tel_req">
        <span slot="label">
          Контактный номер телефона<span class="danger">*</span>
        </span>
        <el-input
          v-mask="maskPhone"
          :value="company.tel_req"
          placeholder="+7 (999) 999-99-99"
          @input="setDataCompany({ field: 'tel_req', value: $event })"
        ></el-input>
      </el-form-item>
      <ListField
        title-for-line="Дополнительный номер телефона"
        :value="company.tel"
        title-button="Добавить номер телефона"
        :mask="maskPhone"
        placeholder="+7 (999) 999-99-99"
        @input="setDataCompany({ field: 'tel', value: $event })"
      />
      <el-form-item prop="email_req">
        <span slot="label">Контактный e-mail<span class="danger">*</span></span>
        <el-input
          :value="company.email_req"
          placeholder="example@mail.ru"
          @input="setDataCompany({ field: 'email_req', value: $event })"
        ></el-input>
      </el-form-item>
      <ListField
        title-for-line="Дополнительный e-mail"
        :value="company.email"
        placeholder="example@mail.ru"
        title-button="Добавить адрес почты"
        @input="setDataCompany({ field: 'email', value: $event })"
      />
    </div>
    <h4 class="title">Мессенджеры</h4>

    <SwitchSocialNetwork
      v-for="(messeg, i) in company.messengers"
      :key="i"
      :value="messeg"
      @input="
        setSocial({ field: 'messengers', messeger: messeg, value: $event })
      "
    />
    <h4 class="title">Социальные сети</h4>
    <SwitchSocialNetwork
      v-for="(social, i) in company.socials"
      :key="`${i}-social`"
      :value="social"
      @input="setSocial({ field: 'socials', messeger: social, value: $event })"
    />
    <div class="hl"></div>
    <el-button type="primary" class="custom-button" @click="submit()">
      Сохранить
    </el-button>
  </el-form>
</template>

<script>
import SwitchSocialNetwork from "@/views/Company/components/SwitchSocialNetwork";
import ListField from "@/components/Services/ListField.vue";
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "ContactData",
  components: {
    SwitchSocialNetwork,
    ListField,
  },
  data() {
    return {
      maskPhone: "+7 (###) ###-##-##",
    };
  },
  computed: {
    ...mapState("company", ["company"]),
    tel_req: {
      get() {
        return this.company.tel_req;
      },
      set(value) {
        this.setDataCompany({ field: "tel_req", value });
      },
    },
  },
  created() {
    this.rules = {
      tel_req: [
        { required: true, message: "заполните поле", trigger: ["blur"] },
      ],
      email_req: [
        { required: true, message: "заполните поле", trigger: ["blur"] },
      ],
    };
    this.namesTranslate = {
      tel_req: "телефон",
      email_req: "электронную почту",
    };
  },
  methods: {
    ...mapMutations("company", ["setDataCompany"]),
    ...mapMutations(["setNotif"]),
    ...mapActions("company", ["editInfoCompany"]),
    setSocial({ field, messeger, value }) {
      this.setDataCompany({
        field,
        value: this.company[field].map((item) => {
          if (item.name === messeger.name) {
            return value;
          }
          return item;
        }),
      });
    },

    async submit() {
      this.$refs.baseInfo.validate((valid, object) => {
        if (valid) {
          this.editInfoCompany("contact");
        } else {
          const str = Object.values(object)
            .map((value) => this.namesTranslate[value[0].field])
            .join(", ");
          this.setNotif({
            title: `Заполните следующие поля: ${str}.`,
            type: "warning",
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.contact-data__list-fields {
  width: 60%;
}

.hint {
  line-height: 18px;
  font-size: 12px;
  color: $--color-white;
  margin-bottom: 7px;
}
@media (max-width: 1400px) {
  .contact-data__list-fields {
    width: 100%;
  }
}
</style>
