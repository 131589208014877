<template functional>
  <div class="wrapper-tooltip">
    <svg-icon
      :icon-name="props.icon || 'question'"
      width="5"
      height="9"
    ></svg-icon>
  </div>
</template>

<script>
export default {
  name: "Tooltip",
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";
.wrapper-tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  height: 17px;
  width: 17px;
  background-color: $--color-blue;

  svg {
    color: $--color-white;
  }
  ::v-deep .el-popper[x-placement^="top"] .popper__arrow::after {
    border-top-color: $--color-violet;
  }

  ::v-deep .el-popper[x-placement^="bottom"] .popper__arrow::after {
    border-bottom-color: $--color-violet;
  }
}
</style>
