var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relative"},[(_vm.isLoading)?_c('spinner',{staticClass:"table__spinner"}):_vm._e(),_c('el-table',{staticClass:"border-b-0 mb-20",attrs:{"data":_vm.pays,"sortable":"","stripe":true}},[_c('el-table-column',{attrs:{"label":"","min-width":60,"width":60},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"table__icon cursor-pointer",on:{"click":function($event){return _vm.onEditReserve(row)}}}),_c('div',{staticClass:"table__icon"}),(_vm.showModalSettings)?_c('TableSettings',{attrs:{"show":_vm.showModalSettings,"columns":_vm.columns,"item-list":_vm.options},on:{"close":function($event){_vm.showModalSettings = false},"update":_vm.saveTableSettings}}):_vm._e()]}}])},[_c('div',{staticClass:"flex items-center justify-center cursor-pointer",attrs:{"slot":"header"},on:{"click":function($event){_vm.showModalSettings = true}},slot:"header"},[_c('svg-icon',{attrs:{"icon-name":"settings","width":"18","height":"18"}})],1)]),(_vm.columns.includes(1))?_c('el-table-column',{attrs:{"type":"index","index":_vm.indexMethod,"label":"№","sortable":"","width":62}}):_vm._e(),(_vm.columns.includes(2))?_c('el-table-column',{staticClass:"date",attrs:{"prop":"sum","sortable":"","label":"Дата создания","width":149},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('p',[_vm._v("#"+_vm._s(row.hash))]),_c('span',[_vm._v(" "+_vm._s(_vm.getDate(row.created_at)))]),_c('span',{staticClass:"small_text"},[_vm._v(_vm._s(_vm.getTime(row.created_at))+" ")])])]}}],null,false,1072909908)}):_vm._e(),(_vm.columns.includes(3))?_c('el-table-column',{attrs:{"prop":"hash","label":"№ брони","sortable":"","min-width":107},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('router-link',{staticClass:"underline",attrs:{"to":{
            path: '/reserves',
            query: {
              text: ("" + (row.booking_hash)),
            },
          }}},[_vm._v("#"+_vm._s(row.booking_hash))])]}}],null,false,170471819)}):_vm._e(),(_vm.columns.includes(4))?_c('el-table-column',{staticClass:"sender",attrs:{"prop":"sum","label":"Тип оплаты","min-width":220},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('div',{staticClass:"flex flex-col"},[_c('span',[_vm._v(" "+_vm._s(row.type_name))]),(row.type_name === 'Оплата онлайн' && row.acquiring_id)?_c('span',{staticClass:"text-12"},[_vm._v("ID Транзакции")]):_vm._e(),(row.type_name === 'Оплата онлайн' && row.acquiring_id)?_c('div',{staticClass:"flex justify-between mb-18"},[_c('el-input',{staticClass:"text-12",attrs:{"placeholder":"","disabled":""},model:{value:(row.acquiring_id),callback:function ($$v) {_vm.$set(row, "acquiring_id", $$v)},expression:"row.acquiring_id"}}),_c('div',{staticClass:"row-link_pay cursor-pointer payment-link__wrap"},[_c('ButtonIcon',{staticClass:"ml-15",attrs:{"icon-name":"copy","width":"16"},on:{"click":function($event){return _vm.copy(row.acquiring_id)}}})],1)],1):_vm._e()])]}}],null,false,4267520861)}):_vm._e(),(_vm.columns.includes(5))?_c('el-table-column',{attrs:{"prop":"text","label":"Оплата","min-width":210},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('div',{staticClass:"table_section"},[_c('span',{staticClass:"table_section__label text-12"},[_vm._v("К оплате")]),_c('span',{staticClass:"text-12"},[_vm._v(_vm._s(row.full_amount)+" "+_vm._s(row.currency))])]),_c('div',{staticClass:"table_section"},[_c('span',{staticClass:"table_section__label text-12"},[_vm._v("Оплачено")]),_c('span',{staticClass:"text-12"},[_vm._v(_vm._s(row.paid_amount)+" "+_vm._s(row.currency)+" ")])]),_c('div',{staticClass:"table_section"},[_c('div',{},[_c('PayInPlacePoppup',{staticClass:"text-12",attrs:{"row":row,"payment":row},on:{"setPrePay":function($event){return _vm.$emit('getReload')}}})],1)])]}}],null,false,2600238083)}):_vm._e(),(_vm.columns.includes(6))?_c('el-table-column',{attrs:{"prop":"text","label":"Статус","min-width":240},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [_c('span',{staticClass:"current_status"},[_c('span',{staticClass:"icon_rounded",style:({ background: ("" + (row.status_color)) })}),_c('span',[_vm._v(" "+_vm._s(row.status_name))])]),(row.status === 2 || row.status === 6)?_c('div',{staticClass:"my-5"},[_c('el-button',{staticClass:"w-full text-12",on:{"click":function($event){return _vm.dialogVisib(row)}}},[_vm._v("Информация об ошибке")]),_c('div',{},[(_vm.dialogVisible)?_c('Modal',{attrs:{"title":"Информация об ошибке","subtitle":_vm.errorMessage,"show":_vm.dialogVisible && !!_vm.errorMessage},on:{"close":function($event){_vm.dialogVisible = false}}}):_vm._e()],1)],1):_vm._e(),(row.status !== 2 && row.status !== 6)?_c('div',{},[(
              row.type_name === 'Оплата онлайн' &&
              row.status_name !== 'Частичный возврат средств' &&
              row.status_name !== 'Оплачен' &&
              row.acquiring_id
            )?_c('span',{staticClass:"text-12"},[_vm._v("Ссылка на оплату")]):_vm._e(),(row.type_name === 'Оплата онлайн')?_c('div',{staticClass:"table_section-link text-center my-4",class:{
              'table_section-link-disablead': row.status_name === 'Оплачен',
            }}):_vm._e(),(
              row.type_name === 'Оплата онлайн' &&
              row.status_name !== 'Оплачен' &&
              row.payment_link
            )?_c('div',{staticClass:"flex justify-between mb-18"},[_c('el-input',{staticClass:"text-12",attrs:{"placeholder":"","disabled":""},model:{value:(row.payment_link),callback:function ($$v) {_vm.$set(row, "payment_link", $$v)},expression:"row.payment_link"}}),_c('div',{staticClass:"row-link_pay cursor-pointer payment-link__wrap"},[_c('ButtonIcon',{staticClass:"ml-15",attrs:{"icon-name":"copy","width":"16"},on:{"click":function($event){return _vm.copy(row.payment_link)}}})],1)],1):_vm._e()]):_c('div',{staticClass:"text-12"},[_c('el-button',{staticClass:"w-full text-12",on:{"click":function($event){return _vm.getNewLink(row.hash)}}},[_vm._v(" Получить новую ссылку ")])],1),(
            row.status_name === 'Оплачен' && row.type !== 'payment_locally'
          )?_c('div',[(row.status_name === 'Оплачен')?_c('el-button',{on:{"click":function($event){return _vm.openReturnC(row)}}},[_vm._v(" Оформить возврат ")]):_vm._e(),(_vm.openReturnCash && row.status_name === 'Оплачен')?_c('ReturnCash',{attrs:{"currency":row.currency,"hash":{ hash: _vm.qwer.hash },"show":_vm.openReturnCash && row.status_name === 'Оплачен'},on:{"close":function($event){_vm.openReturnCash = false}}}):_vm._e()],1):_vm._e()]}}],null,false,815595399)}):_vm._e(),(_vm.columns.includes(7))?_c('el-table-column',{attrs:{"prop":"status","label":"Последняя оплата","min-width":150},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var row = ref.row;
return [(row.payment_date)?_c('div',{staticClass:"flex flex-col"},[_c('span',[_vm._v(_vm._s(_vm.getDate(row.payment_date)))]),_c('span',{staticClass:"table_section__label text-12"},[_vm._v(_vm._s(_vm.getTime(row.payment_date)))])]):(row.payment_date || row.paid_on_place_date)?_c('div',{staticClass:"flex flex-col"},[_c('span',[_vm._v(_vm._s(_vm.getDate(row.paid_on_place_date || row.payment_date)))]),_c('span',{staticClass:"table_section__label text-12"},[_vm._v(_vm._s(_vm.getTime(row.paid_on_place_date || row.payment_date)))])]):_c('div',{staticClass:"w-1/2"},[_vm._v("—")])]}}],null,false,1562089751)}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }