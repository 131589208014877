<template>
  <el-form
    ref="ruleForm"
    class="custom-block form"
    :rules="rules"
    :model="{ name, surname, tel, email }"
    :style="{
      background: isTypeTheme
        ? styleCustom.block_background || custom.block_background
        : '',
      color: isTypeTheme
        ? styleCustom.active_text_color || custom.active_text_color
        : '',
    }"
  >
    <div v-if="loading" class="mask_load" />

    <div class="form__wrapper-header">
      <div
        class="form__title"
        :style="{
          color: isTypeTheme
            ? styleCustom.active_text_color || custom.active_text_color
            : '',
        }"
      >
        {{ title }}
      </div>
      <div v-if="!isHiddenClose" class="form__close" @click="$emit('close')">
        <svg-icon
          icon-name="close"
          width="14"
          height="14"
          :style="{
            color: isTypeTheme
              ? styleCustom.active_text_color || custom.active_text_color
              : '',
          }"
        ></svg-icon>
      </div>
    </div>
    <div
      v-if="subTitle"
      class="form__sub-title"
      :style="{
        color: isTypeTheme
          ? styleCustom.inactive_text_color || custom.inactive_text_color
          : '',
      }"
    >
      {{ subTitle }}
    </div>
    <div
      class="hl"
      :style="{
        background: isTypeTheme
          ? styleCustom.background_cells_fields ||
            custom.background_cells_fields
          : '',
      }"
    ></div>
    <slot name="calendar"></slot>
    <div class="form__row flex-row">
      <div class="form__row-column flex-col">
        <slot />
      </div>
      <div class="form__row-column flex-col">
        <div
          v-if="
            !!selectCell.BookingType
              ? selectCell.BookingType.booking_type_sub ===
                'service_tree_from_settings'
              : false
          "
          class="mb-3"
        >
          <div class="row-info xs:text-12">
            <div
              class="row-info__title"
              :style="{
                color: isTypeTheme ? custom.inactive_text_color : '',
              }"
            >
              Дата
            </div>
            <div
              class="row-info__value"
              :style="{
                color: isTypeTheme ? custom.active_text_color : '',
              }"
            >
              {{ isMaket ? "04.02.2021" : formatedDate }}
            </div>
          </div>
          <div class="row-info xs:text-12">
            <div
              class="row-info__title"
              :style="{
                color: isTypeTheme ? custom.inactive_text_color : '',
              }"
            >
              Время
            </div>
            <div
              class="row-info__value"
              :style="{
                color: isTypeTheme ? custom.active_text_color : '',
              }"
            >
              {{ isMaket ? "10:30" : selectCell.time }}
            </div>
          </div>
          <div v-if="selectCell.address" class="row-info xs:text-12">
            <div
              class="row-info__title"
              :style="{
                color: isTypeTheme ? custom.inactive_text_color : '',
              }"
            >
              Адрес
            </div>

            <div
              class="row-info__value"
              :style="{
                color: isTypeTheme ? custom.active_text_color : '',
              }"
            >
              {{ selectCell.address }}
            </div>
          </div>
        </div>
        <el-form-item
          prop="name"
          :class="{
            'custom-style': isTypeTheme,
          }"
          :style="{
            background: isTypeTheme
              ? styleCustom.background_cells_fields ||
                custom.background_cells_fields
              : '',
          }"
        >
          <el-input
            v-model="name"
            placeholder="Имя*"
            maxlength="12"
            @input="patternValue($event)"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="listFieldShow.includes('fieldFamaly')"
          :class="{
            'custom-style': isTypeTheme,
          }"
          :style="{
            background: isTypeTheme
              ? styleCustom.background_cells_fields ||
                custom.background_cells_fields
              : '',
          }"
        >
          <el-input v-model="surname" placeholder="Фамилия"></el-input>
        </el-form-item>
        <el-form-item
          v-if="listFieldShow.includes('fieldEmail')"
          prop="email"
          :class="{
            'custom-style': isTypeTheme,
          }"
          :style="{
            background: isTypeTheme
              ? styleCustom.background_cells_fields ||
                custom.background_cells_fields
              : '',
          }"
        >
          <el-input v-model="email" placeholder="E-mail*"></el-input>
        </el-form-item>
        <el-form-item
          v-if="listFieldShow.includes('fieldPhone')"
          prop="tel"
          :class="{
            'custom-style': isTypeTheme,
          }"
          :style="{
            background: isTypeTheme
              ? styleCustom.background_cells_fields ||
                custom.background_cells_fields
              : '',
          }"
        >
          <el-input
            :key="valueMask"
            v-model="tel"
            v-mask="valueMask"
            placeholder="Номер телефона*"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="listFieldShow.includes('fieldComment')"
          :class="{
            'custom-style': isTypeTheme,
          }"
          :style="{
            background: isTypeTheme
              ? styleCustom.background_cells_fields ||
                custom.background_cells_fields
              : '',
          }"
        >
          <el-input
            v-model="comments"
            placeholder="Комментарий"
            :class="{
              'custom-style': isTypeTheme,
            }"
            :style="{
              background: isTypeTheme
                ? styleCustom.background_cells_fields ||
                  custom.background_cells_fields
                : '',
            }"
          ></el-input>
        </el-form-item>

        <el-form-item
          v-if="listFieldShow.includes('fieldСlient')"
          :class="{
            'custom-style': isTypeTheme,
          }"
          :style="{
            background: isTypeTheme
              ? styleCustom.background_cells_fields ||
                custom.background_cells_fields
              : '',
          }"
        >
          <el-input
            v-model="add_params[1].param_value"
            placeholder="Код клиента"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="listFieldShow.includes('fieldUbrirTel')"
          :class="{
            'custom-style': isTypeTheme,
          }"
          prop="add_params"
          :style="{
            background: isTypeTheme
              ? styleCustom.background_cells_fields ||
                custom.background_cells_fields
              : '',
          }"
        >
          <el-input
            v-model="add_params[0].param_value"
            v-mask="'+7 (***) ###-##-##'"
            placeholder="Последние 7 цифр телефона*"
          ></el-input>
        </el-form-item>
        <InputCheck
          v-if="listFieldShow.includes('fieldPromo')"
          v-model="promocode"
          placeholder="Промо-код"
          :class="{
            'custom-style': isTypeTheme,
          }"
          :style="{
            background: isTypeTheme
              ? styleCustom.background_cells_fields ||
                custom.background_cells_fields
              : '',
          }"
          @check="
            checkPromoCode({
              id: selectCell.id,
              date: selectCell.date,
              time: selectCell.time,
              code: promocode,
            })
          "
        />
        <!-- <div v-if="add_params">
          <div v-for="(item, index) in add_params" :key="index">
            <el-form-item
              :class="{
                'custom-style': isTypeTheme,
              }"
              :style="{
                background: isTypeTheme
                  ? styleCustom.background_cells_fields ||
                    custom.background_cells_fields
                  : '',
              }"
            >
              <el-input
                v-model="item.param_value"
                :placeholder="item.param_name"
              ></el-input>
            </el-form-item>
          </div>
        </div> -->
        <el-alert
          v-if="isHavePromocode"
          title="Промокод применен!"
          type="success"
          show-icon
          :closable="false"
        />
        <el-alert
          v-if="isNotPromocode"
          :title="promocodeInfo.msg || `Промокод не найден!`"
          type="error"
          show-icon
          :closable="false"
        />
        <div v-if="selectService">
          <el-alert
            v-if="selectService.comment"
            class="customService w-full text-left"
            :title="selectService.comment"
            center
            show-icon
            :closable="false"
          />
        </div>
      </div>
    </div>
    <div
      class="hl"
      :style="{
        // REMOVE widgetPublic.typeTheme === 'light'
        background: isTypeTheme
          ? styleCustom.background_cells_fields ||
            custom.background_cells_fields
          : '',
      }"
    ></div>
    <div class="form__row xs:flex-col flex-row">
      <p
        class="form__row-column hint-title__medium-white"
        :style="{
          color: isTypeTheme
            ? styleCustom.inactive_text_color || custom.inactive_text_color
            : '',
        }"
      >
        <span>
          Нажимая на кнопку “Забронировать”, вы <br />автоматически соглашаетесь
          с условиями <br />
          <a
            :style="{
              color: isTypeTheme
                ? styleCustom.inactive_text_color || custom.inactive_text_color
                : '',
            }"
            target="_blank"
            :href="widgetPublic.user_agreement"
            class="linkUserRigth"
          >
            <span class="underline">пользовательского соглашения </span></a
          >и<a
            :style="{
              color: isTypeTheme
                ? styleCustom.inactive_text_color || custom.inactive_text_color
                : '',
            }"
            target="_blank"
            :href="widgetPublic.privacy_policy"
            class="linkUserRigth"
          >
            <span class="underline">
              <br />
              политикой конфиденциальности.</span
            >
          </a>
        </span>
      </p>
      <div class="form__row-column actions justify-end xs:mt-3">
        <div
          v-if="
            Math.ceil(isHavePromocode ? totalPriceDiscount : totalPrice) > 0
          "
          class="actions__info"
        >
          <p
            class="actions__info-title"
            :style="{
              color: isTypeTheme
                ? styleCustom.inactive_text_color || custom.inactive_text_color
                : '',
            }"
          >
            К оплате
          </p>
          <p
            class="actions__info-content"
            :style="{
              color: isTypeTheme
                ? styleCustom.active_text_color || custom.active_text_color
                : '',
            }"
          >
            {{
              Math.ceil(
                isHavePromocode || isHaveDiscountPrice
                  ? totalPriceDiscount
                  : totalPrice
              )
            }}
            {{ selectCell.currency }}
          </p>
        </div>
        <div
          v-if="isHavePromocode || isHaveDiscountPrice"
          class="actions__info-oldPrice"
          :style="{
            color: isTypeTheme
              ? styleCustom.active_text_color || custom.active_text_color
              : '',
          }"
        >
          {{ isHaveDiscountPrice ? totalPrice : selectCell.price }}
          {{ selectCell.currency }}
        </div>
        <el-button
          class="actions__button"
          type="primary"
          :style="{
            background: isTypeTheme
              ? styleCustom.active_buttons_background ||
                custom.active_buttons_background
              : '',
            color: isTypeTheme
              ? styleCustom.button_text_color || custom.button_text_color
              : '',
          }"
          :loading="loading"
          :disabled="!disabled"
          @click="submitForm()"
        >
          {{ "Забронировать" }}
        </el-button>
      </div>
    </div>
  </el-form>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import InputCheck from "@/components/InputCheck.vue";

export default {
  name: "TemplateFormBron",
  components: { InputCheck },
  props: {
    formatedDate: {
      type: String,
    },
    selectService: {
      type: Object,
    },
    isService: Boolean,
    isMaket: Boolean,
    isHiddenClose: Boolean,
    loading: Boolean,
    disabled: Boolean,
    selectCell: {
      type: Object,
      default: () => {},
    },
    totalPrice: {
      type: [String, Number],
      default: null,
    },
    title: {
      type: String,
      default: "",
    },
    subTitle: {
      type: String,
      default: "",
    },
    listFieldShow: {
      type: Array,
      default: () => [],
    },
    styleCustom: {
      type: Object,
    },
    isTypeTheme: {
      type: Boolean,
    },
    priceDisc: {
      type: [String, Number],
    },
  },
  data() {
    return {
      lastVal: "",
      name: null,
      surname: null,
      tel: null,
      email: null,
      comments: null,
      promocode: null,
      // ubrrCode: null,
      // ubrrTel: null,
      add_params: [
        {
          // ubrrCode: null,
          param_id: "ubrir_tel",
          param_name: "Последние 7 цифр телефона",
          param_value: "",
          param_required: true,
          param_type: "input",
        },
        {
          //  ubrrTel: null,
          param_id: "ubrir_id",
          param_name: "Идентификатор в системе УБРиР",
          param_value: "",
          param_required: false,
          param_type: "input",
        },
      ],
      rules: {
        name: [
          {
            required: true,
            message: "",
            trigger: "blur",
            max: 12,
            pattern: /\D*(^[a-zA-Zа-яА-Я]+$)\D*/,
          },
        ],
        surname: { required: true, trigger: "blur" },
        tel: { required: !this.isRequiredPhone, trigger: "blur" },
        email: { required: true, trigger: "blur" },
      },
      customClass: {},
    };
  },

  computed: {
    ...mapState("widget", ["widget", "promocodeInfo", "widgetPublic"]),
    valueMask() {
      return this.listFieldShow.includes("maskRussian")
        ? "+7 (###) ###-##-##"
        : "############";
    },
    custom() {
      const designWidgetParams = this.widgetPublic.design ?? this.widget.design;
      return {
        ...designWidgetParams,
      };
    },
    isRequiredPhone() {
      return !!this.widgetPublic.fieldForm.find((f) => f === "fieldPhone");
    },
    isHavePromocode() {
      return this.promocodeInfo && this.promocodeInfo.active;
    },
    isHaveDiscountPrice() {
      return this.selectCell.discount;
    },
    isNotPromocode() {
      return this.promocodeInfo && !this.promocodeInfo.active;
    },
    totalPriceDiscount() {
      if (!this.isHavePromocode) return this.totalPrice;
      if (this.promocodeInfo.unit === "percent")
        return (
          this.totalPrice - (this.totalPrice * this.promocodeInfo.value) / 100
        );
      return this.totalPrice - this.promocodeInfo.value;
    },
    isWhiteTheme() {
      return this.widgetPublic.typeTheme === "light";
    },
  },

  created() {
    this.setField({ field: "promocodeInfo", value: null });
    this.isClass();
    if (this.widgetPublic.add_data) {
      this.add_params = [...this.widgetPublic.add_data];
    }
  },
  methods: {
    ...mapActions("widget", ["checkPromoCode"]),
    ...mapMutations("widget", ["setField"]),
    patternValue(event) {
      const regexp = this.rules.name.find((e) => e.pattern).pattern;
      if (event.match(regexp)) {
        this.lastVal = this.name;
      } else {
        this.name = this.lastVal;
      }
      if (event.length === 0) this.name = "";
    },
    hexToRgbA(hex, opacity) {
      let c;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split("");
        if (c.length === 3) {
          c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = `0x${c.join("")}`;
        // eslint-disable-next-line no-bitwise
        return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
          ","
        )},0.${opacity})`;
      }
      throw new Error("Bad Hex");
    },
    isClass() {
      document.body.style.setProperty(
        "--colorCustom",
        `${this.styleCustom.active_text_color}`
      );
      document.body.style.setProperty(
        "--colorCustomBackground",
        `${this.styleCustom.block_background}`
      );
      document.body.style.setProperty(
        "--colorInactive",
        `${this.styleCustom.inactive_text_color}`
      );
    },
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.promocodeInfo?.active) {
            const data = {
              ...this.$data,
              totalPriceDiscount: this.totalPriceDiscount,
            };
            delete data.rules;
            this.$emit("booking", data);
            return this.promocode;
          }
          this.promocode = null;
          const data = { ...this.$data };
          delete data.rules;
          this.$emit("booking", data);
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";
:root {
  --colorCustom: red;
  --colorCustomBackground: "green";
  --colorInactive: "grey";
}

.linkUserRigth {
  text-decoration-line: underline;
  display: contents;
  cursor: pointer;
  color: $--color-white;
}
.form {
  padding: 30px 30px 20px;
  margin-bottom: 20px;
  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    color: $--color-white;
    margin-bottom: 5px;
  }
  &__sub-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 40px;
    color: $--color-white;
  }
  &__close {
    cursor: pointer;

    svg {
      color: $--color-white;
    }
  }
  &__wrapper-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__row {
    display: flex;
    // flex-direction: row;
    margin: 0 -20px;
    &-column {
      display: flex;
      // flex-direction: column;
      width: 50%;
      padding: 0 20px;

      .hl {
        margin: 25px 0 25px;
      }
      .qwerty {
        flex-grow: 1;
        margin: 0;
      }
    }
  }
}
@media (max-width: 520px) {
  .form {
    padding: 20px 10px;
    &__row {
      flex-direction: column;
    }
  }
}

.actions {
  display: flex;
  flex-direction: row;
  //justify-content: flex-end;
  align-items: center;
  &__info {
    display: flex;
    flex-direction: column;
    &-title {
      color: $--color-white-5;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
    }
    &-content {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $--color-white;
    }
    &-oldPrice {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $--color-white-5;
      text-decoration: line-through;
      margin-top: 20px;
      margin-left: 20px;
    }
  }
  &__button {
    margin-left: 20px;
  }
}
// ::v-deep .el-input__inner:focus {
//   background: red;
// }
::v-deep {
  .vfc-calendar {
    border: 1px solid $--color-sm-white;
    &:nth-last-child(1) {
      border-radius: 0 3px 3px 0;
    }
    &:nth-last-child(2) {
      border-left: 1px solid rgba(255, 255, 255, 0.2);
      // border-right: 1px solid rgba(255, 255, 255, 0.2);
    }
    &:nth-last-child(3) {
      border-radius: 3px 0 0 3px;
    }
  }
  .el-form-item.is-error {
    .el-form-item__error {
      display: none;
    }
    input {
      border: 1px solid $--color-danger;
    }
  }
  .input-check {
    margin-bottom: 22px;
  }
}
@media (max-width: 520px) {
  .form__row-column {
    width: 95% !important;
  }
}
.mask_load {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(56, 25, 78, 0.3);
  z-index: 3000;
}

.custom-style {
  ::v-deep .el-input__inner {
    color: var(--colorCustom, black);
  }
  ::v-deep .el-input__inner::-webkit-input-placeholder {
    color: var(--colorInactive, black);
  }
  ::v-deep .el-input__inner::-moz-placeholder {
    color: var(--colorInactive, black);
  }
  ::v-deep .el-input__inner:-moz-placeholder {
    color: var(--colorInactive, black);
  }

  ::v-deep .el-input__inner:focus {
    background: var(--colorCustomBackground, black);
  }
}
.custom-style {
  ::v-deep .input-check__icon {
    background: var(--colorInactive, black);
  }
}
.custom-style {
  ::v-deep .input-check__icon > svg {
    color: var(--colorCustom, black);
  }
}
.input-check.custom-style {
  height: 40px;
}
.custom-block.form {
  background: var(--colorCustomBackground);
  // color: #00b3ee;
  color: #ffffff;
}
.row-info__title {
  width: 100px !important;
}
.row-info__value {
  word-break: break-word;
}
</style>
