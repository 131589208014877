<template>
  <div>
    <spinner v-if="loading" />
    <Discount
      v-show="!loading"
      ref="template"
      type="discount"
      :elements="discountItems"
    >
      <template #top>
        <Top
          create-button-text="Создать скидку"
          title="Использовать скидки"
          :switch-position="switchPosition"
          :export-link="[
            { link: '/api/discount/export/excel', name: 'Экспорт в Excel' },
          ]"
          @change="isPromoActive"
          @create="openCreateModal"
        />
      </template>
    </Discount>
  </div>
</template>

<script>
import Discount from "@/components/Promo/Template.vue";
import Top from "@/components/Promo/Top.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    Discount,
    Top,
  },
  props: {
    activeTab: String,
  },
  data() {
    return {
      loading: true,
      switchPosition: null,
    };
  },
  computed: {
    ...mapState("promo", ["discountItems", "discountItemsCount"]),
    ...mapState("user", ["user"]),
  },
  created() {
    this.switchPosition = {
      promoActive: this.user.promocodes_disabled,
      discountActive: this.user.discounts_disabled,
    };
  },

  mounted() {
    this.getData();
  },
  methods: {
    ...mapActions("promo", ["getItems", "promoDiscountAll"]),
    ...mapActions("user", ["getInfo"]),

    async getData() {
      await this.getItems("discount");
      this.$refs.template.setData();
      this.$emit("setCount", {
        count: this.discountItemsCount.discounts,
        key: "discount",
      });
      if (this.loading) this.loading = false;
    },

    openCreateModal() {
      this.$refs.template.showCreateModal();
    },
    isPromoActive(useSwitch) {
      this.promoDiscountAll({ key: "discount", flag: useSwitch });
    },
  },
};
</script>
