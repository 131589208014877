<template>
  <div class="widget">
    <div :class="['widget__gallery', { 'opacity-zero': isShowContent }]">
      <el-carousel
        v-if="photos.length"
        :autoplay="false"
        arrow="never"
        trigger="click"
        height="220px"
      >
        <el-carousel-item v-for="(image, i) in photos" :key="i">
          <img class="slide" :src="image.src" alt="" />
        </el-carousel-item>
        <el-carousel-item v-if="service.video_link">
          <div
            class="wrapper-gallery__video"
            :style="{
              backgroundImage: service.services_video_preview
                ? `linear-gradient(rgba(0,0,0,.5), rgba(0,0,0,.5)), url(${service.services_video_preview})`
                : '',
            }"
            @click="showVideo = true"
          >
            <div class="wrapper-gallery__video-button">
              <svg-icon icon-name="play"></svg-icon>
            </div>
            Смотреть видео
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div :class="['widget__content', { 'opacity-zero': isShowContent }]">
      <p v-if="service.kind_of_activity.length" class="widget__content-type">
        {{ category }}
      </p>
      <h4 class="widget__content-name">{{ service.name }}</h4>
      <p v-if="isShowAddress" class="widget__content-address">
        {{ getAddress }}
      </p>
      <div class="widget__content-tags">
        <tag class="flex">
          {{ tagAge }}
          <el-popover
            v-if="service.age_comments"
            class="ml-8"
            placement="top"
            width="200"
            trigger="hover"
            :content="service.age_comments"
          >
            <Tooltip slot="reference" />
          </el-popover>
        </tag>
        <template v-if="service.tags_switcher">
          <tag v-for="(tag, i) in service.tags" :key="i">{{ tag }}</tag>
        </template>
      </div>
      <p v-if="service.short_description" class="widget__content-anons">
        {{ service.short_description }}
      </p>
      <button
        v-if="service.detail_description"
        class="widget__content-button-more"
        @click="isShowContent = true"
      >
        Подробнее
      </button>
      <div></div>
      <h5 class="widget__content-total">от 3400 ₽</h5>
      <el-button type="primary" class="mb-12">{{ buttonName }}</el-button>
      <p class="widget__content-info">Система бронирования Broni.Biz</p>
    </div>
    <div :class="['widget__overflow', { 'is-open': isShowContent }]">
      <div class="widget__overflow-header">
        <p>Описание услуги</p>
        <el-button
          icon="el-icon-close"
          class="widget__overflow-close"
          @click="isShowContent = false"
        ></el-button>
      </div>
      <div
        class="widget__overflow-content"
        v-html="service.detail_description"
      />
    </div>
    <el-dialog :visible.sync="showVideo" width="50%" append-to-body>
      <iframe
        v-if="showVideo"
        ref="youtube"
        class="iframe-video"
        :src="`https://www.youtube.com/embed/${getIdFromURL}`"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </el-dialog>
  </div>
</template>

<script>
import Tag from "@/components/Tag";
import { mapState } from "vuex";

export default {
  name: "WidgetService",
  components: {
    Tag,
  },
  props: {
    service: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isShowContent: false,
      showVideo: false,
    };
  },
  computed: {
    ...mapState("company", ["typesActivity"]),
    ...mapState("settings", ["buttonNameList", "ageLimitList"]),
    buttonName() {
      return this.buttonNameList.find(
        (name) => name.value === this.service.button_name
      ).label;
    },
    tagAge() {
      // return this.service.age_limit;
      // if (!this.service.age_limit) return "0+";
      return (
        this.ageLimitList.find((age) => age.value === this.service.age_limit)
          .label || "0+"
      );
    },
    getIdFromURL() {
      const youtubeRegexp = /https?:\/\/(?:[0-9A-Z-]+\.)?(?:youtu\.be\/|youtube(?:-nocookie)?\.com\S*[^\w\s-])([\w-]{11})(?=[^\w-]|$)(?![?=&+%\w.-]*(?:['"][^<>]*>|<\/a>))[?=&+%\w.-]*/gi;
      return this.service.video_link.replace(youtubeRegexp, "$1");
    },
    photos() {
      return this.service?.services_photos.filter((item) => item.src);
    },

    isShowAddress() {
      return (
        ["fromSettings", "writeAddress"].includes(this.service.address_type) &&
        (this.service.address_from_settings_id ||
          this.service.address.address_name)
      );
    },
    getAddress() {
      return this.service.address_type === "writeAddress"
        ? this.service.address.address_name
        : this.service.address.address_name;
    },
    category() {
      return this.typesActivity
        .filter((type) => this.service.kind_of_activity.includes(type.id))
        .map((res) => res.name)
        .join(", ");
    },
  },
  created() {},
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.widget {
  display: flex;
  flex-direction: column;
  background-color: $--color-ultra-white;
  overflow-wrap: break-word;
  position: relative;
  border-radius: 3px;
  overflow: hidden;

  &__gallery {
    opacity: 1;
    display: block;
    transition: all 0.2s;

    .slide {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &__content {
    opacity: 1;
    display: flex;
    flex-direction: column;
    padding: 20px;
    transition: all 0.2s;

    &-type {
      color: $--color-primary;
    }
    &-name {
      font-size: 22px;
      color: $--color-white;
      margin-bottom: 10px;
      font-weight: 600;
    }
    &-button-more {
      padding: 6px 10px;
      color: $--color-white;
      font-weight: bold;
      font-size: 10px;
      line-height: 12px;
      background: $--color-light-white;
      border-radius: 3px;
      cursor: pointer;
      width: fit-content;
      margin-bottom: 20px;
    }
    &-address {
      font-size: 12px;
      color: $--color-white-7;
      margin-bottom: 10px;
    }
    &-tags {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 10px;
    }
    &-anons {
      font-size: 14px;
      color: $--color-white-7;
      line-height: 24px;
      margin-bottom: 10px;
    }
    &-total {
      font-size: 18px;
      color: $--color-white;
      margin-bottom: 20px;
      font-weight: 600;
    }
    &-info {
      color: $--color-white-5;
      text-align: center;
      font-size: 12px;
      line-height: 20px;
    }

    ::v-deep .el-button {
      background-color: $--color-primary;
      border-color: $--color-primary;
    }
  }

  &__overflow {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    padding: 20px;
    transform: translateX(100%);
    transition: all 0.2s;

    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      p {
        font-weight: 600;
        font-size: 16px;
        line-height: 28px;
        color: $--color-white;
      }
    }
    &-close {
      height: auto;
      padding: 3px;
      font-size: 20px;
    }
    &-content {
      ::v-deep h1 {
        font-size: 24px !important;
      }
      ::v-deep h2 {
        font-size: 24px;
      }
      ::v-deep h3 {
        font-size: 20px;
      }
      ::v-deep strong {
        font-weight: bold;
      }
      ::v-deep ol {
        list-style-type: decimal;
      }
      ::v-deep ul {
        list-style-type: disc;
      }
    }
  }

  .is-open {
    transform: translateX(0);
  }

  .opacity-zero {
    opacity: 0;
  }
}
.wrapper-gallery__video {
  // width: calc(25% - 20px);
  height: 100%;
  margin: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 3px;

  &-button {
    background: #ffbe3d;
    border-radius: 20px;
    margin-bottom: 20px;

    svg {
      margin: 10px;
      position: relative;
      left: 2px;
    }
  }

  &__img {
    margin: 0 10px 20px;
    width: calc(25% - 20px);
    height: fit-content;
    cursor: pointer;
    border-radius: 3px;
  }
}

.iframe-video {
  width: 100%;
  height: 500px;
}

@media (max-width: 1000px) {
  .iframe-video {
    height: 300px;
  }
}

@media (max-width: 600px) {
  ::v-deep .el-dialog {
    width: 80% !important;
  }
  .iframe-video {
    height: 300px;
  }
}
@media (max-width: 420px) {
  ::v-deep .el-tabs {
    &__nav-scroll {
      margin: 0 10px;
    }
  }
  ::v-deep .el-dialog {
    width: 100% !important;
  }
}
</style>
