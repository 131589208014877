<template>
  <div class="tabs">
    <div
      v-for="(item, i) in listLink"
      :key="i"
      :class="['tabs__item', { active: item.value === value }]"
      @click="$emit('input', item.value)"
    >
      <el-radio
        :value="value"
        :label="item.value"
        @input="$emit('input', item.value)"
      >
        {{ item.title }}
      </el-radio>
      <el-popover
        v-if="item.popoverText"
        class="tabs__item-tooltip"
        placement="top"
        width="300"
        trigger="hover"
        :content="item.popoverText"
      >
        <Tooltip slot="reference" />
      </el-popover>
    </div>
  </div>
</template>

<script>
export default {
  name: "Tabs",
  components: {},
  props: {
    listLink: {
      type: Array,
      default: () => [],
    },
    value: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.tabs {
  display: flex;
  flex-direction: row;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 30px;

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 10px;
    border: 1px solid $--color-sm-white;
    border-right: 1px solid transparent;
    color: $--color-white;
    flex-grow: 1;
    cursor: pointer;

    &:first-child {
      border-right: 1px solid $--color-sm-white;
      border-radius: 3px 0px 0px 3px;
    }

    &:last-child {
      border-right: 1px solid $--color-sm-white;
      border-radius: 0px 3px 3px 0px;
    }
    ::v-deep .el-radio {
      display: flex;
      justify-content: center;
      margin-right: 0;
      &__input {
        display: block;
      }
      &__label {
        padding-left: 10px;
      }
    }
    &-tooltip {
      margin-left: 20px;
    }
  }
  .active {
    background-color: $--color-primary;

    ::v-deep .el-radio__label {
      color: $--color-black;
    }
    ::v-deep .el-radio__input.is-checked .el-radio__inner {
      border-color: $--color-black;
      background: $--color-black;
    }
    ::v-deep .el-radio__input.is-checked .el-radio__inner::after {
      background-color: #fff;
    }
  }
}

@screen lg {
  .tabs {
    width: 100%;
    flex-direction: column;
    margin-bottom: 30px;
    border: none;
    &__item {
      justify-content: start;
      border: 1px solid $--color-sm-white;
      color: $--color-white;
      margin-bottom: 10px;
      border-radius: 3px !important;

      ::v-deep .el-radio {
        display: flex;
        justify-content: start;
        &__input {
          display: block;
        }
        &__label {
          padding-left: 10px;
        }
      }
      &-tooltip {
        margin-left: 10px;
      }
    }
  }

  ::v-deep .el-radio__input.is-checked .el-radio__inner {
    border-color: $--color-black;
    background: $--color-black;
  }
  ::v-deep .el-radio__input.is-checked .el-radio__inner::after {
    background-color: #fff;
  }
}
</style>
