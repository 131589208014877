<template>
  <div class="home-filter">
    <div
      v-if="!$mq.tablet"
      class="border-b border-white-150 mb-30 -mx-30"
    ></div>

    <el-row
      :gutter="$mq.large ? 10 : 30"
      class="mb-30 lg:flex-wrap lg:mb-0"
      type="flex"
    >
      <el-col :span="$mq.large ? 12 : 6" class="large:mb-20 lg:w-full lg:mb-15">
        <label
          class="text-12 font-medium text-white-normal block flex flex-col"
        >
          <span class="mb-7">Дата бронирования</span>
          <div v-if="!$mq.phone">
            <el-date-picker
              v-model="filterForm.bron"
              type="daterange"
              :picker-options="dateRangeOptions1"
              format="dd.MM.yyyy"
              range-separator="По"
              start-placeholder="Любая"
              end-placeholder="Любая"
              @change="(monthValue.bron = ''), filterReserves"
            >
            </el-date-picker>
          </div>
          <div v-if="$mq.phone">
            <el-date-picker
              v-model="filterForm.bron[0]"
              :picker-options="dateRangeOptions1"
              type="date"
              class="mb-5"
              format="dd.MM.yyyy"
              placeholder="Любая"
              @change="(monthValue.bron = ''), filterReserves"
            >
            </el-date-picker>
            <el-date-picker
              v-model="filterForm.bron[1]"
              :picker-options="dateRangeOptions1"
              type="date"
              format="dd.MM.yyyy"
              placeholder="Любая"
              @change="(monthValue.bron = ''), filterReserves"
            >
            </el-date-picker>
          </div>
        </label>
      </el-col>
      <el-col :span="$mq.large ? 12 : 6" class="lg:w-full mb-7">
        <label
          class="text-12 font-medium text-white-normal block flex items-center mb-7"
        >
          <span class="mr-6">Показать за месяц</span>
          <el-popover
            placement="top"
            title="Привет"
            width="200"
            trigger="hover"
            content="Нажимаешь на месяц и показывает за месяц"
          >
            <div
              slot="reference"
              class="tooltip-btn rounded-full w-17 h-17 bg-blue-100 flex items-center justify-center"
            >
              <svg-icon
                icon-name="question"
                class-name="text-white-normal"
                width="5"
                height="9"
              ></svg-icon>
            </div>
          </el-popover>
        </label>
        <el-radio-group
          v-model="monthValue.bron"
          class="filled w-full"
          @change="changeMonthValue($event, 'bron')"
        >
          <el-radio-button
            v-for="month in monthes"
            :key="month.name"
            class="w-1/3"
            :label="month.value"
            >{{ month.name }}</el-radio-button
          >
        </el-radio-group>
      </el-col>
      <el-col :span="$mq.large ? 12 : 6" class="large:mb-20 lg:w-full lg:mb-15">
        <label
          class="text-12 font-medium text-white-normal block flex flex-col"
        >
          <span class="mb-7">Даты услуги </span>
          <!-- <el-date-picker
            v-model="filterForm.service"
            type="daterange"
            format="dd.MM.yyyy"
            range-separator="По"
            start-placeholder="Любая"
            end-placeholder="Любая"
            @change="(monthValue.service = ''), filterReserves"
          >
          </el-date-picker> -->
          <div v-if="!$mq.phone">
            <el-date-picker
              v-model="filterForm.service"
              :picker-options="dateRangeOptions1"
              type="daterange"
              format="dd.MM.yyyy"
              range-separator="По"
              start-placeholder="Любая"
              end-placeholder="Любая"
              @change="(monthValue.service = ''), filterReserves"
            >
            </el-date-picker>
          </div>
          <div v-if="$mq.phone">
            <el-date-picker
              v-model="filterForm.service[0]"
              :picker-options="dateRangeOptions1"
              class="mb-5"
              type="date"
              format="dd.MM.yyyy"
              placeholder="Любая"
              @change="(monthValue.service = ''), filterReserves"
            >
            </el-date-picker>
            <el-date-picker
              v-model="filterForm.service[1]"
              :picker-options="dateRangeOptions1"
              type="date"
              format="dd.MM.yyyy"
              placeholder="Любая"
              @change="(monthValue.service = ''), filterReserves"
            >
            </el-date-picker>
          </div>
        </label>
      </el-col>
      <el-col :span="$mq.large ? 12 : 6" class="large:mb-20 lg:w-full lg:mb-15">
        <label
          class="text-12 font-medium text-white-normal block flex flex-col"
        >
          <span class="mb-7">Время услуги </span>

          <el-time-picker
            v-model="filterForm.service_time"
            placeholder="Выберите время"
            format="HH:mm"
            @change="(monthValue.service_time = ''), filterReserves"
          >
          </el-time-picker>
        </label>
      </el-col>
    </el-row>

    <el-row
      :gutter="$mq.large ? 10 : 30"
      class="mb-30 lg:flex-wrap lg:mb-0"
      type="flex"
    >
      <el-col :span="$mq.tablet ? 24 : 6" class="lg:mb-15">
        <label
          class="text-12 font-medium text-white-normal block flex flex-col"
        >
          <span class="mb-7 lg:mt-7">Услуги</span>
          <el-select
            v-model="filterForm.name"
            placeholder="Все"
            multiple
            @change="filterReserves"
          >
            <el-option
              v-for="item in names"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </label>
      </el-col>
      <el-col :span="$mq.tablet ? 24 : 6" class="lg:mb-15">
        <label
          class="text-12 font-medium text-white-normal block flex flex-col"
        >
          <span class="mb-7">Источник</span>
          <el-select
            v-model="filterForm.source"
            placeholder="Все"
            multiple
            @remove-tag="resetReserves"
            @change="filterReserves"
          >
            <el-option
              v-for="item in sources"
              :key="item.value"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </label>
      </el-col>
      <el-col :span="$mq.tablet ? 24 : 6" class="lg:mb-15">
        <label
          class="text-12 font-medium text-white-normal block flex flex-col"
        >
          <span class="mb-7">Статус бронирования</span>
          <el-select
            v-model="filterForm.status"
            placeholder="Все"
            multiple
            value-key="label"
            @change="filterReserves"
          >
            <el-option
              v-for="(item, index) in statusOrders"
              :key="index"
              :label="item"
              :value="index"
            >
            </el-option>
          </el-select>
        </label>
      </el-col>
      <el-col :span="$mq.tablet ? 24 : 6" class="lg:mb-0">
        <label
          class="text-12 font-medium text-white-normal block flex flex-col"
        >
          <span class="mb-7">Статус оплаты</span>
          <el-select
            v-model="filterForm.payment_status"
            placeholder="Все"
            multiple
            value-key="label"
            @change="filterReserves"
          >
            <el-option
              v-for="(item, index) in statuses"
              :key="index"
              :label="item"
              :value="index"
            >
            </el-option>
          </el-select>
        </label>
      </el-col>
    </el-row>

    <div
      v-if="!$mq.tablet"
      class="border-b border-white-150 mb-30 -mx-30"
    ></div>

    <el-row>
      <el-col
        :span="24"
        class="flex justify-end lg:w-full lg:mt-20 lg:flex-col"
      >
        <el-button class="mr-10 lg:mb-20" @click="resetReserves">
          Сбросить
        </el-button>
        <el-button
          type="primary"
          :loading="isLoading"
          class="bg-transparent lg:-ml-10 h-40 text-white-normal"
          @click="showReserves"
        >
          {{ $mq.tablet ? "Показать " : "Применить" }}
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { mapActions, mapState, mapMutations } from "vuex";
import moment from "moment";
import { AJAX_GET, AJAX_POST } from "../units/ajax";

export default {
  name: "HomeFilter",
  data() {
    return {
      monthValue: {
        service_time: "",
        service: "",
        bron: "",
      },
      dateRangeOptions1: {
        firstDayOfWeek: 1,
      },
      monthes: [],
      names: [],
      filterForm: {
        service: [],
        bron: [],
        name: "",
        service_time: "",
        source: "",
        status: "",
        status_payment: "",
      },
      reserves: "",
      sources: [
        {
          value: "https://dev.broni.biz",
          label: "https://dev.broni.biz",
        },
      ],
      statusOrders: [],
      statuses: [
        {
          value: 0,
          label: "Ожидает платежа",
        },
        {
          value: 1,
          label: "Оплачен",
        },
        {
          value: 2,
          label: "Ошибка при оплате",
        },
        {
          value: 3,
          label: "Банковский перевод",
        },
      ],
    };
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("orders", ["queryReserves", "isLoading"]),
  },
  mounted() {
    this.setNames();
    this.setMonthes();
  },
  created() {
    this.sources = this.user.orders_sources;
    this.statusOrders = this.user.order_statuses;
    this.statuses = this.user.payment_statuses;
    this.filterForm.name = this.$route.query.names;
    this.filterForm.bron_from = this.queryReserves["filter[bron_from]"]; // this.$route.query.bron_from || "";
    this.filterForm.bron_to = this.queryReserves["filter[bron_to]"];
    this.monthValue.bron = this.queryReserves["filter[bron_month]"];
    this.filterForm.status = this.queryReserves["filter[statuses]"];
    this.filterForm.status_payment = this.queryReserves[
      "filter[payment_status]"
    ];
    this.filterForm.service_time = this.$route.query.service_time;
    this.filterForm.service_from = this.queryReserves["filter[service_from]"];
    this.filterForm.service_to = this.queryReserves["filter[service_to]"];
  },
  methods: {
    ...mapActions("orders", ["getReserves", "sendChanges", "getStat"]),
    ...mapMutations("orders", ["setQueryReserves", "queryReserves"]),

    ...mapActions("homeFilter", {
      setFilteredReserves: "setFilteredReserves",
      setFilteredUrl: "setFilteredUrl",
    }),
    async resetReserves() {
      this.$emit("resetFilter", "clear");
      this.reserves = [];
      this.setFilteredReserves(null);
      this.setFilteredUrl(null);
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.filterForm) {
        this.filterForm[key] = [];
      }

      this.monthValue = "";
      const params = {
        "filter[paginate]": "10",
        "filter[show]": "",
        "filter[names]": "",
        "filter[statuses]": "",
        "filter[bron_month]": "",
        "filter[service_month]": "",
        "filter[service_from]": "",
        "filter[service_to]": "",
        "filter[bron_from]": "",
        "filter[bron_to]": "",
        "filter[sources]": "",
        "filter[payment_status]": "",
        "filter[text]": "",
        "filter[service_time]": "",
      };
      this.setQueryReserves(params);

      this.$router.push({ name: "orders" });
      // this.filterReserves();

      await this.getReserves();
      await this.getStat();
    },
    setNames() {
      this.names = Object.values(this.user.resources).map((resource) => ({
        value: resource.hash,
        label: resource.name,
      }));
    },
    async showReserves() {
      this.$emit("onAcceptFilter");
      const params = await this.filterReserves();
      const query = {
        page: `${this.queryReserves.page}`,
        paginate: `${this.queryReserves["filter[paginate]"]}`,
        names: params["filter[names]"],
        from: params["filter[bron_from]"], // `${this.queryReserves["filter[bron_from]"]}`, // this.queryReserves["filter[bron_from]"],
        to: params["filter[bron_to]"],
        month: params["filter[bron_month]"],
        statuses: params["filter[statuses]"],
        payment_status: params["filter[payment_status]"],
        filter_source: params["filter[sources]"],
        service_time: params["filter[service_time]"],
        service_from: params["filter[service_from]"],
        service_to: params["filter[service_to]"],
      };

      this.$router.replace({
        query,
      });

      this.$emit("getStatPar", query);
      this.setQueryReserves(params);

      await this.getReserves();
      await this.getStat();
    },
    filterReserves() {
      const params = {
        "filter[names]": "",
        "filter[statuses]": "",
        "filter[bron_month]": "",
        "filter[service_month]": "",
        "filter[bron_from]": "",
        "filter[bron_to]": "",
        "filter[sources]": "",
        "filter[payment_status]": "",
        "filter[service_time]": " ",
        "filter[service_from]": "",
        "filter[service_to]": "",
      };
      if (this.filterForm.name.length) {
        params["filter[names]"] = JSON.stringify(this.filterForm.name);
      }
      if (this.filterForm.source.length) {
        params["filter[sources]"] = JSON.stringify(this.filterForm.source);
      }
      if (this.filterForm.payment_status !== undefined) {
        params["filter[payment_status]"] =
          // JSON.stringify(
          this.filterForm.payment_status;
        // );
      }

      if (
        // this.filterForm.service_time !== undefined &&
        // this.filterForm.service_time !== "Invalid date" &&
        JSON.stringify(this.filterForm.service_time)?.length > 0 &&
        this.filterForm.service_time?.length === undefined
      ) {
        params["filter[service_time]"] = moment(
          this.filterForm.service_time
        ).format("HH:mm");
      }
      if (this.filterForm.status.length) {
        params["filter[statuses]"] = JSON.stringify(this.filterForm.status);
      }

      if (this.monthValue.bron) {
        params["filter[bron_month]"] = this.filterForm.bron;
      }

      if (this.monthValue.service) {
        params["filter[service_month]"] = this.filterForm.service;
      }

      if (this.filterForm.service && this.filterForm.service.length) {
        params["filter[service_from]"] = moment(
          this.filterForm.service[0]
        ).format("YYYY-MM-DD");
        params["filter[service_to]"] = moment(
          this.filterForm.service[1]
        ).format("YYYY-MM-DD");
      }
      if (
        !this.monthValue.bron &&
        this.filterForm.bron &&
        this.filterForm.bron.length
      ) {
        params["filter[bron_from]"] = moment(this.filterForm.bron[0]).format(
          "YYYY-MM-DD"
        );
        params["filter[bron_to]"] = moment(this.filterForm.bron[1]).format(
          "YYYY-MM-DD"
        );
      }
      return params;
    },
    setMonthes() {
      for (let i = 0; i < 3; i++) {
        const result = {
          name:
            moment()
              .subtract(i, "months")
              .format("MMMM")
              .charAt(0)
              .toUpperCase() +
            moment().subtract(i, "months").format("MMMM").substr(1),
          value: `${moment()
            .subtract(i, "months")
            .date(1)
            .toDate()}-${moment()
            .subtract(i, "months")
            .endOf("month")
            .toDate()}`,
        };
        this.monthes.unshift(result);
      }
    },
    changeMonthValue(value, type) {
      const month = moment(value.split("-")[0]).format("M");
      this.filterForm[type] = month;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-select .el-tag {
  white-space: normal;
  height: auto;
}
.el-button + .el-button {
  margin-left: 0;
}
::v-deep .el-radio-group.filled .el-radio-button {
  .el-radio-button__inner {
    border-top-width: 1px;
    border-bottom-width: 1px;
    background: none;
    &:first-of-type {
      border-left-width: 1px;
    }
    &:last-of-type {
      border-right-width: 1px;
    }
  }
  &.is-active {
    .el-radio-button__inner {
      background: #febb17;
    }
  }
}
.el-picker-panel__content {
  background-color: red !important;
  font-weight: bold;
  cursor: pointer;
}
.home-filter {
  padding: 15px;
}
</style>
