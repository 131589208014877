<template>
  <TemplateBlock
    title="Шаг 3 — Внешний вид"
    sub-title="В соответствии с дизайном вашего сайта выберите параметры внешнего вида виджета."
    @back="$emit('input', value - 1)"
    @next="$emit('input', value + 1)"
  >
    <p class="hint">Тема</p>
    <div class="list-fields">
      <div class="list-fields__row">
        <div class="list-fields__item">
          <img
            src="../../../assets/themeIndivid.jpg"
            :class="[{ select: widget.typeTheme === 'individual' }]"
            alt=""
            @click="changeTheme('individual')"
          />
          <el-radio
            :value="widget.typeTheme"
            label="individual"
            @input="changeTheme($event)"
          >
            Индивидуальный
          </el-radio>
        </div>
        <div class="list-fields__item">
          <img
            src="../../../assets/themeDark.jpg"
            alt=""
            :class="[{ select: widget.typeTheme === 'dark' }]"
            @click="changeTheme('dark')"
          />
          <el-radio
            :value="widget.typeTheme"
            label="dark"
            @input="changeTheme($event)"
          >
            Темный
          </el-radio>
        </div>
        <div class="list-fields__item">
          <img
            src="../../../assets/themeLigth.jpg"
            alt=""
            :class="[{ select: widget.typeTheme === 'light' }]"
            @click="changeTheme('light')"
          />
          <el-radio
            :value="widget.typeTheme"
            label="light"
            @input="changeTheme($event)"
          >
            Светлый
          </el-radio>
        </div>
      </div>
    </div>
    <p class="hint">Размер шрифта и полей</p>
    <div class="list-fields">
      <div class="list-fields__row">
        <div class="list-fields__item">
          <CellSchedule
            :value="{ price: '1000', time: '11:00', active: true }"
            show-price
            :class="['qwerty', { select: widget.design.font_size === 'small' }]"
            @click="setWidgetDesign({ field: 'font_size', value: 'small' })"
          />
          <el-radio
            :value="widget.design.font_size"
            label="small"
            @input="setWidgetDesign({ field: 'font_size', value: $event })"
          >
            Стандартный
          </el-radio>
        </div>
        <div class="list-fields__item">
          <CellSchedule
            :value="{ price: '1000', time: '11:00', active: true }"
            show-price
            size="medium"
            :class="[
              'qwerty',
              { select: widget.design.font_size === 'medium' },
            ]"
            @click="setWidgetDesign({ field: 'font_size', value: 'medium' })"
          />
          <el-radio
            :value="widget.design.font_size"
            label="medium"
            @input="setWidgetDesign({ field: 'font_size', value: $event })"
          >
            Крупный
          </el-radio>
        </div>
        <div class="list-fields__item">
          <CellSchedule
            :value="{ price: '1000', time: '11:00', active: true }"
            show-price
            size="big"
            :class="['qwerty', { select: widget.design.font_size === 'big' }]"
            @click="setWidgetDesign({ field: 'font_size', value: 'big' })"
          />
          <el-radio
            :value="widget.design.font_size"
            label="big"
            @input="setWidgetDesign({ field: 'font_size', value: $event })"
          >
            Очень крупный
          </el-radio>
        </div>
      </div>
    </div>
    <div class="list-fields">
      <div class="list-fields__row">
        <ColorPick
          :value="widget.design.block_background"
          class="list-fields__item"
          title="Фон блока"
          @input="setWidgetDesign({ field: 'block_background', value: $event })"
        />
        <ColorPick
          :value="widget.design.background_cells_fields"
          class="list-fields__item"
          title="Фон ячеек и полей"
          @input="
            setWidgetDesign({ field: 'background_cells_fields', value: $event })
          "
        />
      </div>
    </div>
    <p class="hint-color">Прозрачность фона ячеек</p>
    <el-slider
      :value="widget.design.opacity"
      :marks="opacityMarks"
      @input="setWidgetDesign({ field: 'opacity', value: $event })"
    >
    </el-slider>
    <div class="list-fields">
      <div class="list-fields__row">
        <ColorPick
          :value="widget.design.active_text_color"
          class="list-fields__item"
          title="Цвет активного текста"
          @input="
            setWidgetDesign({ field: 'active_text_color', value: $event })
          "
        />
        <ColorPick
          :value="widget.design.inactive_text_color"
          class="list-fields__item"
          title="Цвет неактивного текста"
          @input="
            setWidgetDesign({ field: 'inactive_text_color', value: $event })
          "
        />
      </div>
    </div>
    <div class="list-fields">
      <div class="list-fields__row">
        <ColorPick
          :value="widget.design.active_buttons_background"
          class="list-fields__item"
          title="Фон активных кнопок"
          @input="
            setWidgetDesign({
              field: 'active_buttons_background',
              value: $event,
            })
          "
        />
        <ColorPick
          :value="widget.design.button_text_color"
          class="list-fields__item"
          title="Цвет текста кнопок"
          @input="
            setWidgetDesign({ field: 'button_text_color', value: $event })
          "
        />
      </div>
    </div>
  </TemplateBlock>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import TemplateBlock from "@/views/Widgets/components/TemplateBlock.vue";
import CellSchedule from "@/components/CellSchedule.vue";
import ColorPick from "@/components/ColorPick.vue";

export default {
  name: "WidgetDesign",
  components: { TemplateBlock, CellSchedule, ColorPick },
  props: {
    value: {
      type: Number,
    },
  },
  data() {
    return {
      color: "",
      opacityMarks: { 0: "0%", 25: "25%", 50: "50%", 75: "75%", 100: "100%" },
    };
  },
  computed: {
    ...mapState("widget", ["widget", "widgetSettings"]),
  },
  async created() {
    this.listPays = [
      { title: "Для одной услуги", value: "singleService" },
      { title: "Для нескольких услуг", value: "manyService" },
    ];
    if (!this.widgetSettings) {
      await this.getSettingWidget();
    }
    if (!this.widget.hash) {
      this.setWidget({
        field: "design",
        value: { ...this.widgetSettings.individual },
      });
    }
  },
  methods: {
    ...mapMutations("widget", ["setWidget", "setWidgetDesign"]),
    ...mapActions("widget", ["getSettingWidget"]),

    changeTheme(value) {
      this.setWidget({
        field: "design",
        value: { ...this.widgetSettings[value] },
      });
      this.setWidget({ field: "typeTheme", value });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";
::v-deep {
  .cell {
    width: 100%;
    margin-right: 0 !important;
  }
  .el-slider {
    margin-bottom: 40px;
    &__marks-text {
      color: $--color-white-5;
    }
  }
  .el-radio__inner {
    border: 2px solid rgba(255, 255, 255, 0.5);
  }
}
.list-fields {
  &__row:last-child {
    margin-bottom: 0;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 100%;
      height: 100px;
      object-fit: cover;
      border: 2px solid $--color-white-15;
      border-radius: 3px;
      padding: 2px;
      margin-bottom: 15px;
      cursor: pointer;
    }
    .qwerty {
      height: 110px;
    }
  }
}

.hint-color {
  color: $--color-white-5;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  width: 100%;
}

.select {
  border: 2px solid $--color-primary !important;
}
</style>
