<template>
  <el-form hide-required-asterisk label-position="top" class="form relative">
    <el-steps
      :active="activeStep"
      process-status="process"
      finish-status="success"
    >
      <el-step
        v-for="(step, index) in allSteps"
        :key="index"
        :title="step.value"
        :class="[{ seans: index === 1 }, { rezult: index === 2 }]"
      >
      </el-step>
    </el-steps>
    <div v-if="activeStep === 0">
      <Tabs v-model="activeTab" :list-link="listOptionsAddress" class="tabs" />
      <div v-if="alertPopup" class="mb-10">
        <el-alert
          center
          title="Внимание!!! При смене типа расписания, будет создано новое расписание, а старое
будет более не доступно.
"
          type="warning"
          show-icon
          :closable="false"
          class="mt-15 text-16"
        />
      </div>
      <components :is="activeTab" ref="dataSchedule" />
    </div>
    <Sessions v-if="activeStep === 1" ref="createSchedule"></Sessions>
    <div v-if="isLoading" class="preloader__wrap">
      <spinner v-if="isLoading" />
    </div>
    <Schedule v-if="activeStep === 2" ref="finishSchedule"></Schedule>
    <div v-if="isLoading" class="preloader__wrap">
      <spinner v-if="isLoading" />
    </div>
    <div class="hl"></div>

    <div class="wrapper-button flex justify-between">
      <el-button
        v-if="activeStep > 0"
        type="primary"
        class="custom-button__simple"
        @click="toBack()"
      >
        <i class="el-icon-back"></i>
        {{ allSteps[activeStep - 1].text }}
      </el-button>
      <el-button
        v-if="activeStep === 0"
        type="primary"
        :disabled="isDisabled"
        class="custom-button"
        @click="nextStep()"
      >
        Сгенерировать
        <i class="el-icon-arrow-right el-icon-right"></i>
      </el-button>

      <el-button
        v-if="activeStep === 1"
        type="primary"
        class="custom-button"
        @click="nextStep()"
      >
        Сохранить
      </el-button>
      <el-button
        v-if="isHaveSessions && activeStep === 0"
        type="primary"
        class="custom-button__simple"
        @click="
          isHaveSessions && activeStep === 0 ? (activeStep += 1) : nextStep()
        "
      >
        {{ allSteps[activeStep + 1].text }}
        <i class="el-icon-arrow-right el-icon-right"></i>
      </el-button>
    </div>
  </el-form>
</template>

<script>
import regular from "@/views/Services/Create/components/generation/Regular";
import dates from "@/views/Services/Create/components/generation/Dates";
import manual from "@/views/Services/Create/components/generation/Manual";
import bind from "@/views/Services/Create/components/generation/Bind";
import Sessions from "@/views/Services/Create/components/Sessions";
import Schedule from "@/components/Schedule.vue";
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "Generator",
  components: {
    regular,
    dates,
    manual,
    bind,
    Sessions,
    Schedule,
  },
  data() {
    return {
      activeStep: 0,
      isLoading: false,
      isDisabled: false,
      alertPopup: false,
    };
  },
  computed: {
    ...mapState("service", [
      "schedule",
      "generator",
      "sessions",
      "listSchedule",
    ]),
    ...mapState("user", ["user"]),
    ...mapGetters("service", ["isHaveSessions"]),
    activeTab: {
      get() {
        return this.generator.schedule_type || this.sessions.schedule_type;
      },
      set(value) {
        this.setFieldObj({
          field: "generator",
          subField: "schedule_type",
          value,
        });
      },
    },
  },
  watch: {
    activeTab() {
      if (this.isHaveSessions) {
        if (this.sessions.schedule_type !== this.activeTab) {
          this.alertPopup = true;
        } else {
          this.alertPopup = false;
        }
      }
    },
  },

  created() {
    this.allSteps = [
      {
        id: 1,
        value: "График работы",
        text: "К графику работы",
      },
      {
        id: 2,
        value: "Сеансы",
        text: "К сеансам",
      },
      {
        id: 3,
        value: "Результат",
      },
    ];
    this.listOptionsAddress = [
      {
        title: "Регулярное",
        value: "regular",
      },
      {
        title: "По датам",
        value: "dates",
      },
      {
        title: "Ручная настройка",
        value: "manual",
      },
      {
        title: "Связать",
        value: "bind",
      },
    ];
  },
  mounted() {
    if (this.isHaveSessions) this.activeStep = 1;
  },
  updated() {
    // if (this.isHaveSessions) this.activeStep = 2;
  },
  methods: {
    ...mapActions("service", ["getGenerator", "getSessions", "getSchedule"]),
    ...mapMutations("service", ["setField", "setFieldObj", "setGenerator"]),

    async nextStep() {
      if (this.activeStep === 0) {
        this.isLoading = true;
        this.isDisabled = true;
        this.setField({
          field: "sessions",
          value: {
            data: [],
            schedule_type: this.activeTab,
          },
        });
        await this.$refs.dataSchedule.saveData();
        this.isDisabled = false;
        this.isLoading = false;
      }
      if (this.activeStep === 1) {
        this.isLoading = true;
        this.isDisabled = true;

        // this.setField({
        //   field: "sessions",
        //   value: { data: [], schedule_type: this.activeTab },
        // });
        await this.$refs.createSchedule.saveData();
        this.isLoading = false;
        this.isDisabled = false;
      }
      this.activeStep += 1;
      if (this.activeStep === 2) {
        this.isLoading = true;
        this.isDisabled = true;
        await this.getSchedule({ week: this.user.now_week });
        this.isLoading = false;
        this.isDisabled = false;
      }
    },
    async toBack() {
      this.isLoading = true;
      const newStep = this.activeStep - 1;
      const { hash } = this.$route.params;
      if (this.generator.schedule_type === "bind") {
        const obj = { hash: this.generator.data.bind, childHash: hash };
        // await this.getSessions({ ...obj });
        // await this.getGenerator();
      }
      if (!newStep) {
        if (this.listSchedule > this.sessions.data) {
          console.log("new");
        } else {
          // eslint-disable-next-line no-lonely-if
          if (this.generator.schedule_type === "bind") {
            const obj = { hash: this.generator.data.bind, childHash: hash };
            //  await this.getSessions({ ...obj });
          } else {
            await this.getSessions({ hash });
          }
          // await this.getSessions(
          //   this.generator.schedule_type === "bind"
          //     ? this.generator.data.bind
          //     : hash
          // );

          await this.getGenerator();
        }
      }
      this.activeStep = newStep;
      this.isLoading = false;
    },
    newGener() {
      this.activeStep = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.seans ::v-deep .el-step__main {
  transform: translateX(-6px);
}
.rezult ::v-deep .el-step__main {
  position: absolute;
  transform: translateX(-30px);
}

.title {
  font-size: 22px;
  line-height: 44px;
  font-weight: 600;
}

.hint-title__medium-white {
  padding-bottom: 30px;
}

.tabs {
  margin-bottom: 20px;
}

.el-steps {
  margin-bottom: 40px;
}

@screen lg {
  .title {
    margin-bottom: 0;
    font-size: 18px;
    line-height: 28px;
  }
  .hint-title__medium-white {
    padding-bottom: 25px;
  }
  .tabs {
    margin-bottom: 10px;
  }
}
.preloader__wrap {
  background: rgba(255, 255, 255, 0.1);
  position: absolute;
  top: 1vh; // 40vh;
  width: 100%;
  height: 100%;
}
.spinner {
  height: 100vh;
  background: transparent;

  /* pointer-events: none; */
  z-index: 1500;
}
</style>
