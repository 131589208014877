<template>
  <!-- <div class="pt-40 pb-80 w-full max-w-lg"> -->
  <div class="pt-40 pb-80 w-full lg:pt-15 px-10">
    <!-- <div class="lg:px-15"> -->
    <div
      class="flex justify-between items-end mb-40 ssm:flex-col ssm:items-start"
    >
      <div class="ssm:mb-20">
        <h1
          class="font-semibold text-white-normal text-28 lg:text-22 lg:leading22 xs:mb-5"
        >
          Поддержка
        </h1>
        <div class="text-white-500 leading-22">
          Всего вопросов: {{ availableTickets.length }}
        </div>
      </div>
      <router-link :to="{ name: 'support-add' }">
        <el-button
          type="primary"
          class="custom-button header-wrapper__actions-button"
        >
          <svg-icon icon-name="plus" width="10" height="10" class="mr-12">
          </svg-icon>
          Задать вопрос
        </el-button>
      </router-link>
    </div>
    <spinner v-if="loading" />
    <div v-for="(ticket, index) in availableTickets" :key="`ticket-${index}`">
      <router-link
        class="mb-20 block"
        :to="{ name: 'support-ticket', params: { id: ticket.id } }"
      >
        <div
          class="bg-white-40 rounded-sm px-30 py-25 ssm:px-15 hover:bg-white-100 transition-all"
          :class="{ 'border-2 border-green-400': ticket.have_new }"
        >
          <div class="text-white-500 text-12 leading-16 mb-12">
            <span class="text-yellow-100">ID {{ ticket.id }}</span
            ><span class="mx-7">•</span>Создано {{ ticket.date }}
          </div>
          <div
            class="flex justify-between items-center mb-2 ssm:flex-col ssm:items-start"
          >
            <div
              class="text-18 font-semibold leading-30 break-all w-4/5"
              :class="{ 'text-white-500': ticket.closed }"
            >
              {{ ticket.thread.name }}
            </div>
            <div
              class="flex items-center text-12 leading-16 whitespace-no-wrap ssm:order-first ssm:mb-5"
            >
              <template v-if="ticket.have_new">
                <span
                  class="rounded-full w-20 h-20 min-w-20 flex items-center justify-center bg-green-400 mr-6"
                >
                  <svg-icon
                    icon-name="confirmed"
                    class-name="text-black"
                    width="10"
                    height="8"
                  ></svg-icon>
                </span>

                Есть новый ответ
              </template>

              <template v-else-if="ticket.closed">
                <span
                  class="rounded-full w-20 h-20 min-w-20 flex items-center justify-center bg-white-200 mr-6"
                >
                  <svg-icon
                    icon-name="error"
                    class-name="text-white-700"
                    width="8"
                    height="8"
                  ></svg-icon>
                </span>
                Вопрос закрыт
              </template>

              <template v-else>
                <span
                  class="rounded-full w-20 h-20 min-w-20 flex items-center justify-center bg-yellow-100 mr-6"
                >
                  <svg-icon
                    icon-name="waiting"
                    class-name="text-black"
                    width="10"
                    height="10"
                  ></svg-icon>
                </span>
                На рассмотрении
              </template>
            </div>
          </div>
          <div
            class="text-white-500 leading-20 support-introtext"
            v-html="ticket.desc"
          ></div>
        </div>
      </router-link>
    </div>

    <template v-for="(ticket, index) in closedTickets">
      <div
        v-if="index === 0"
        :key="index"
        class="mt-50 mb-20 text-22 font-semibold leading-36"
      >
        Закрытые вопросы
      </div>
      <router-link
        :key="index"
        class="mb-20 block"
        :to="{ name: 'support-ticket', params: { id: ticket.id } }"
      >
        <div class="bg-white-40 rounded-sm px-30 py-25 ssm:px-15">
          <div class="text-white-500 text-12 leading-16 mb-12">
            <span class="text-yellow-100">ID {{ ticket.id }}</span
            ><span class="mx-7">•</span>Создано {{ ticket.date }}
          </div>
          <div
            class="flex justify-between items-center mb-2 ssm:flex-col ssm:items-start"
          >
            <div
              class="text-18 font-semibold leading-30 break-all w-4/5 text-white-500"
            >
              {{ ticket.thread.name }}
            </div>
            <div
              class="flex items-center text-12 leading-16 whitespace-no-wrap ssm:order-first ssm:mb-5"
            >
              <template v-if="ticket.closed">
                <span
                  class="rounded-full w-20 h-20 min-w-20 flex items-center justify-center bg-white-200 mr-6"
                >
                  <svg-icon
                    icon-name="error"
                    class-name="text-white-700"
                    width="8"
                    height="8"
                  ></svg-icon>
                </span>
                Вопрос закрыт
              </template>
            </div>
          </div>
          <div
            class="text-white-500 leading-20 support-introtext"
            v-html="ticket.desc"
          ></div>
        </div>
      </router-link>
    </template>

    <template v-if="!loading && !tickets.length">
      <no-data
        v-if="!noData"
        size="small"
        text="Вы пока не задали ни одного вопроса"
      />

      <!-- <div class="text-14 leading-24">
          Вы не задали пока что ни одного вопроса
        </div> -->
    </template>
    <!-- </div> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
import Spinner from "../components/Spinner.vue";
import { AJAX_GET } from "../units/ajax";
import { formatTicketDate } from "../units/main";
import NoData from "@/components/NoData.vue";

export default {
  name: "Support",
  components: { Spinner, NoData },
  data() {
    return {
      tickets: [],
      loading: true,
    };
  },
  computed: {
    ...mapState("user", ["user"]),

    closedTickets() {
      return this.tickets.filter((ticket) => ticket.closed);
    },
    availableTickets() {
      return this.tickets
        .filter((ticket) => !ticket.closed)
        .sort((a, b) => b.id - a.id);
    },
    noData() {
      return this.tickets.length;
      // this.user && this.user.resources_count === 0;
    },
  },
  /* beforeUpdate() {
            this.getChats();
        }, */
  mounted() {
    this.getChats();
  },
  methods: {
    getChats() {
      AJAX_GET("/api/support/chats")
        .then((response) => {
          response.data.forEach((ticket) => {
            let desc;

            // eslint-disable-next-line no-restricted-syntax
            for (const key in ticket.json.messages) {
              desc = ticket.json.messages[key].text;
              break;
            }

            ticket.desc = desc;
            ticket.date = formatTicketDate(ticket.created_at);

            this.tickets.push(ticket);
            this.loading = false;
          });
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss">
.support-introtext {
  max-width: 648px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
