<template>
  <!-- <div class="wrapper schedule px-10"> -->
  <div class="pt-40 pb-80 w-full lg:pt-15 px-10">
    <Top
      class="schedule__title"
      title="Расписание"
      :button-text="user && noData ? 'Добавить услугу' : null"
      :font="topOption"
      @onClick="$router.push({ name: 'services' })"
    />
    <div class="">
      <spinner v-if="loading && user.resources.length" />
      <template v-else-if="user">
        <no-data
          v-if="noData"
          size="small"
          text="Для начала вам необходимо добавить услуги и сформировать расписание."
        />
        <template v-else-if="slides.length">
          <div class="schedule__dates">
            <div class="schedule__dates-datepicker">
              <el-date-picker
                v-model="scheduleDate"
                type="date"
                :clearable="false"
                format="dd.MM.yyyy"
                :picker-options="scheduleDateOptions"
                placeholder="Выберите дату"
                @change="changeScheduleDate($event)"
              />
            </div>

            <div class="schedule__dates-swiper">
              <div
                class="schedule__slide-prev"
                @click="sliderNavigate({ isMore: false })"
              >
                <svg-icon
                  icon-name="chevron"
                  class-name="rotate-minus-90"
                  width="12"
                  height="7"
                ></svg-icon>
              </div>
              <swiper ref="swiperDates" class="swiper" :options="swiperOption">
                <swiper-slide
                  v-for="(slide, index) in slides"
                  :key="`slide-${index}`"
                  :class="{ active: slide.active }"
                  @click.native="clickOnSlider(index)"
                >
                  <span class="schedule__date">
                    {{ slideDate(slide.date) }}
                  </span>
                  <span class="schedule__day"> {{ slide.weekDay }} </span>
                </swiper-slide>
              </swiper>
              <div
                class="schedule__slide-next"
                @click="sliderNavigate({ isMore: true })"
              >
                <svg-icon
                  icon-name="chevron"
                  class-name="rotate-90"
                  width="12"
                  height="7"
                ></svg-icon>
              </div>
            </div>
          </div>
          <div
            v-if="errorMsg || errorMsgService || errorMsgSchedule"
            class="custom-alert not-set-schedule"
          >
            <div>{{ errorMsg }}{{ errorMsgService }}{{ errorMsgSchedule }}</div>
          </div>
          <app-schedule
            v-else
            :date-slide="getDate"
            @openFormSchedul="changeForm"
          />

          <book-from
            v-if="isOpenFrom"
            :is-open.sync="isOpenFrom"
            :param="params"
            :date-slide="getDate"
            @closeForm="isOpFr"
          />
          <!-- <el-button
          type="primary"
          icon="el-icon-plus"
          class="custom-button"
          @click="isOpenFrom = true"
        ></el-button> -->
        </template>
        <el-dialog
          v-if="isShowPay"
          :visible.sync="isShowPay"
          :show-close="false"
          width="450px"
          @close="isShowPay = false"
        >
          <PayWidget v-if="dataPay.success" @close="isShowPay = false" />
          <!-- <PayError v-else :msg="dataPay.msg" @close="isShowPay = false" /> -->
        </el-dialog>
      </template>
    </div>
  </div>
</template>

<script>
import AppSchedule from "@/components/AppSchedule";
import { mapState, mapActions } from "vuex";
import moment from "moment";
import { AJAX_POST } from "@/units/ajax";
import BookFrom from "@/views/Schedule/BookFrom.vue";
import NoData from "@/components/NoData.vue";
import Top from "@/components/Pages/Top";
// import PayError from "@/views/Widgets/components/PayError";
import PayWidget from "@/views/Widgets/components/PayWidget";

moment.locale("ru");

export default {
  name: "Schedule",
  components: {
    AppSchedule,
    BookFrom,
    NoData,
    Top,
    // PayError,
    PayWidget,
  },
  data() {
    return {
      errorMsg: "",
      visib: true,
      isShowPay: false,
      scheduleDate: "",
      params: null,
      loading: true,
      topOption: {
        size: 28,
        leading: 44,
        mq: { value: "lg", size: 22, leading: 36 },
      },
      swiperOption: {
        slidesPerView: 10,
        slidesPerGroup: 8,
        breakpoints: {
          1024: {
            slidesPerView: 10,
            slidesPerGroup: 8,
          },
          768: {
            slidesPerView: 9,
            slidesPerGroup: 5,
          },
          480: {
            slidesPerView: 5,
            slidesPerGroup: 5,
          },
          320: { slidesPerView: 3, slidesPerGroup: 3 },
        },
        slideToClickedSlide: true,
      },
      isOpenFrom: false,
      scheduleDateOptions: {
        firstDayOfWeek: 1,
        // disabledDate: (time) => {
        //   return (
        //     time < moment().subtract(1, "day").toDate() ||
        //     time > moment().add(this.user.settings.limit_show_site, "day")
        //   );
        // },
      },
      slides: [],
    };
  },
  computed: {
    ...mapState("widget", ["widgetPublic", "dataPay"]),
    ...mapState("service", ["service", "errorMsgService"]),
    ...mapState("schedule", ["errorMsgSchedule"]),
    ...mapState("user", ["user"]),

    getDate() {
      return new Date(this.scheduleDate).toLocaleDateString("fr-CA");
    },

    noData() {
      return this.user && this.user.resources_count === 0;
    },
  },
  async created() {
    if (!this.noData) {
      this.scheduleDate = this.$route.query.day
        ? this.$route.query.day
        : moment().toDate();

      if (
        moment(this.scheduleDate).format("YYYY-MM-DD") >=
        moment().format("YYYY-MM-DD")
      ) {
        await this.getSettingsSite();
        await this.getInfo();
        await this.getSchedule(this.scheduleDate);
        await this.generateDatesSlider();
        this.errorMsg = "";
        this.loading = false;
      } else {
        await this.generateDatesSlider();
        this.loading = false;
        await this.getSchedule(this.scheduleDate);

        //  this.errorMsg = "Запрашиваемая дата уже прошла";
        this.loading = false;
      }
    }
  },
  methods: {
    ...mapActions("schedule", ["getSchedule"]),
    ...mapActions("settings", ["getSettingsSite"]),
    ...mapActions("user", ["getInfo"]),

    isOpFr(param) {
      this.isShowPay = param.isShowPay;
      this.isOpenFrom = param.isOpenFrom;
    },
    changeForm(status) {
      this.params = status;
      this.isOpenFrom = true;
    },

    changeScheduleDate(date) {
      const slideId = this.slides.findIndex(
        (slide) =>
          moment(slide.date).format("DD.MM.YYYY") ===
          moment(date).format("DD.MM.YYYY")
      );
      this.clickOnSlider(slideId, date);
    },
    clickOnSlider(slideId, param) {
      this.errorMsg = "";

      this.slides.forEach((slide) => {
        slide.active = false;
      });
      const selectDate =
        this.slides[slideId]?.date || moment(param).format("YYYY-MM-DD");
      this.$router.push({
        query: {
          day: selectDate,
        },
      });

      if (this.slides[slideId]) {
        this.slides[slideId].active = true;
        this.$refs.swiperDates.swiper.slideTo(slideId);
      }
      this.scheduleDate = selectDate; // this.slides[slideId].date;
      this.getSchedule(this.scheduleDate);
      // this.$refs.swiperDates.swiper.slideTo(slideId);
    },
    sliderNavigate({ isMore }) {
      if (isMore) this.$refs.swiperDates.swiper.slideNext();
      else this.$refs.swiperDates.swiper.slidePrev();
    },
    slideDate(date) {
      return moment(date).format("DD.MM");
    },
    async generateDatesSlider() {
      const { data } = await AJAX_POST("/api/schedule/getAllDates");
      this.slides = [...data];

      if (
        !moment(this.$route.query.day).format("YYYY-MM-DD") >=
        moment().format("YYYY-MM-DD")
      ) {
        const sSlide = this.slides.find((slide) =>
          slide.date.includes(this.$route.query.day)
        );
        sSlide.active = true;
      } else {
        this.slides[0].active = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "element-variables.scss";

.schedule {
  &__title {
    margin-bottom: 33px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .wrapper__title {
      padding-bottom: 0;
    }
  }

  &__dates {
    display: flex;
    align-items: center;
    margin-bottom: 40px;

    &-datepicker {
      width: 220px;
      flex-shrink: 0;
      margin-right: 20px;
      padding: 10px;
      background-color: $--color-light-white;
      border-radius: 3px;
    }
    &-swiper {
      display: flex;
      width: calc(100% - 240px);
    }
  }
  &__date {
    font-weight: 600;
    line-height: 25px;
  }
  &__day {
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: $--color-white-5;
  }
  &__slide-prev,
  &__slide-next {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 60px;
    height: 60px;
    cursor: pointer;
    color: $--color-white;
    border-color: $--color-light-white;
    background-color: $--color-light-white;
    outline: 2px solid transparent;
    outline-offset: 2px;
    &:hover {
      color: rgba(255, 189, 26, 1);
    }
  }

  &__slide-next {
    border-left-width: 1px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &__slide-prev {
    border-right-width: 1px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
}

.schedule-link {
  &:hover {
    .schedule-link__text {
      @apply .border-white-normal;
    }
  }
}
::v-deep .swiper-slide {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  cursor: pointer;
  box-sizing: border-box;
  transition: all 0.2s;
  border-color: $--color-light-white;
  background-color: $--color-light-white;
  border-right-width: 1px;

  &:hover {
    @apply .bg-yellow-100 .text-black;

    .schedule-weekday {
      @apply .text-black;
    }
  }

  &.active {
    @apply .bg-yellow-100 .text-black;

    .schedule__day {
      @apply .text-black;
    }

    &:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #ffbd1a;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.custom-button {
  position: fixed;
  bottom: 100px;
  right: 40px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 24px;
}
@media (max-width: 760px) {
  .schedule {
    &__dates {
      display: flex;
      flex-direction: column;
      &-datepicker {
        width: 100%;
        margin: 5px;
        margin-bottom: 10px;
      }
      &-swiper {
        width: 100%;
      }
    }
  }
}
@media (max-width: 458px) {
  .schedule {
    &__title {
      flex-direction: row;
      margin-bottom: 10px;
    }
    &__dates {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      &-datepicker {
        width: 100%;
        // margin: auto;
        padding: 10px;
        background-color: $--color-light-white;
        border-radius: 3px;
        margin: 0px;
      }
      &-swiper {
        display: flex;
        margin-top: 7px;
        width: 100%;
      }
    }

    &__slide-prev,
    &__slide-next {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      width: 30px;
      height: 60px;
      cursor: pointer;
      color: $--color-white;
      border-color: $--color-light-white;
      background-color: $--color-light-white;
      outline: 2px solid transparent;
      outline-offset: 2px;
      &:hover {
        color: rgba(255, 189, 26, 1);
      }
    }
  }

  ::v-deep .swiper-slide {
    font-size: 10px;
    &:hover {
      @apply .bg-yellow-100 .text-black;
      .schedule-weekday {
        @apply .text-black;
      }
    }
    &.active {
      @apply .bg-yellow-100 .text-black;
      .schedule__day {
        @apply .text-black;
      }
      &:after {
        content: "";
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #ffbd1a;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}
</style>
