var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bk-full"},[_c('div',{staticClass:"row-info"},[_c('div',{staticClass:"row-info__title",style:({
        color: _vm.isThemeWidget ? _vm.customStyle.active_text_color : '',
      })},[_vm._v(" Осталось билетов ")]),(_vm.isSlotTicket)?_c('div',{staticClass:"row-info__value",style:({
        color: _vm.isThemeWidget ? _vm.customStyle.active_text_color : '',
      })},[_vm._v(" "+_vm._s(_vm.slotsValidity.tickets)+" ")]):_vm._e()]),_c('p',{staticClass:"title-widget",style:({
      color: _vm.isThemeWidget ? _vm.customStyle.active_text_color : '',
    })},[_vm._v(" Билеты ")]),_vm._l((_vm.listTicket),function(ticket,i){return _c('div',{key:i,staticClass:"input-number"},[_c('div',{staticClass:"input-number__info"},[_c('span',{staticClass:"input-number__info-title",style:({
          color: _vm.isThemeWidget ? _vm.customStyle.inactive_text_color : '',
        })},[_vm._v(_vm._s(ticket.name))]),_c('span',{staticClass:"input-number__info-subtitle",style:({
          color: _vm.isThemeWidget ? _vm.customStyle.active_text_color : '',
        })},[_vm._v(" "+_vm._s(_vm.isHoliday ? ticket.holidays_price : ticket.base_price)+" "+_vm._s(_vm.selectCell.currency)+" ")])]),_c('el-input-number',{staticClass:"input-number__input",class:{ 'custom-style': _vm.isThemeWidget },style:({
        background: _vm.isThemeWidget ? _vm.customStyle.background_cells_fields : '',
      }),attrs:{"min":0,"max":_vm.differenceSeats ? _vm.seats : ticket.select_ticket,"controls":true},on:{"input":function($event){return _vm.emitData()}},model:{value:(ticket.select_ticket),callback:function ($$v) {_vm.$set(ticket, "select_ticket", $$v)},expression:"ticket.select_ticket"}})],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }