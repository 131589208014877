<template>
  <div class="pt-40 pb-80 w-full max-w-lg">
    <div class="lg:px-15 mb-30 lg:mb-20">
      <ButtonIcon
        icon-name="back"
        text="К списку вопросов"
        class="mb-12"
        @click="$router.push({ name: 'support' })"
      />
    </div>
    <div class="lg:px-15">
      <h1
        class="inline-block text-28 font-semibold leading-44 mb-20 lg:text-22 lg:leading-25"
      >
        Задать новый вопрос
      </h1>
      <support-add-form></support-add-form>
    </div>
  </div>
</template>

<script>
import SupportAddForm from "../components/SupportAddForm";

export default {
  name: "SupportAdd",
  components: { SupportAddForm },
};
</script>

<style scoped></style>
